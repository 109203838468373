import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Toolbar,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { getLocalizedText } from "../../Functions";
import { Typography } from "@mui/material";
import { myTheme } from "../../themeUtils";
import MuiAlert from "@mui/material/Alert";
import { submitFileApi, uploadContactFileApi } from "../../Api";
const dialogWidth = 420;
const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;

const Input = styled("input")`
  width: 100%;
  height: 30px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;

  border: 1px solid #bfbfbf;
  font: normal normal 500 12px Open Sans;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const TextArea = styled("textarea")`
  width: 100%;
  height: 60px;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #bfbfbf;
  color: #4d4e4f;

  resize: none;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 16px;
  flex-direction: column;
  row-gap: 20px;
`;
const Message = styled(Typography)`
  font: normal normal normal 16px Open Sans;
  color: green;
  margin: 0px;
  text-align: center;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const FileLogModal = (props) => {
  const [details, setDetails] = useState({
    title: "",
    desc: "",
    file_password: "",
  });

  const [file, setFile] = useState();

  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

  const getFilePattern = (fileType) => {
    switch (fileType) {
      case "image":
        return ".jpg,.png,.jpeg,.svg";

      case "video":
        return ".mp4,.mov,.avi";

      case "audio":
        return ".mp3,.wav";

      case "pdf":
        return ".pdf";

      case "zip":
        return ".zip";

      case "text":
        return ".txt,.doc,.docx";

      case "excel":
        return ".xls,.xlsx";

      case "csv":
        return ".csv";

      case "all":
        return "*";

      default:
        return ".jpg,.png,.jpeg,.pdf";
    }
  };

  const getFilePatternString = (commaSepratedString) => {
    let resultantString = "";
    if (commaSepratedString !== null) {
      let tempList = commaSepratedString.split(",");
      tempList.forEach((string) => {
        let pattern = getFilePattern(string);
        if (resultantString === "") {
          resultantString = pattern;
        } else {
          resultantString = resultantString + "," + pattern;
        }
      });
    }
    return resultantString;
  };

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const submitLeadFile = async (formData) => {
    let response = await submitFileApi({
      leadID: props.leadID,
      payload: formData,
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      props.setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };

  const submitContactFile = async (formData) => {
    let response = await uploadContactFileApi({
      contactID: props.leadData["contact_id"],
      payload: formData,
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      props.setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();

    formData.append("title", details.title);
    if (details.desc !== "") {
      formData.append("desc", details.desc);
    }
    if (details.file_password !== "") {
      formData.append("file_password", details.file_password);
    }
    file !== undefined && formData.append("file", file);
    file !== undefined && formData.append("file_type", file.type);
    if (props.isEdit) {
      if (
        props.editableData.hasOwnProperty("link_to_contact") &&
        props.editableData["link_to_contact"] === true
      ) {
        await submitContactFile(formData);
      } else {
        formData.append("lead_file_list_id", props.editableData["id"]);
        await submitLeadFile(formData);
      }
    } else {
      await submitLeadFile(formData);
    }
  };
  const handleClose = () => {
    props.setOpen(false);
    props.setIsEdit(false);
  };
  const handleFile = (e) => {
    setFile(e.target.files[0]);
  };

  const init = () => {
    let tempObj = {
      title:
        props.editableData["title"] !== null ? props.editableData["title"] : "",
      desc:
        props.editableData["desc"] !== null ? props.editableData["desc"] : "",
      file_password: "",
    };
    setDetails(tempObj);
  };
  useEffect(() => {
    if (props.isEdit) {
      init();
    }
  }, []);
  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!isSubmitSuccess ? (
          <ModelContainer>
            <Box component={"form"} onSubmit={handleSubmit}>
              <AppBar
                component={"nav"}
                elevation={0}
                position="sticky"
                color="inherit"
                sx={{
                  top: 0,
                  bottom: "auto",
                  width: "100%",
                  right: 0,
                }}
              >
                <Toolbar
                  style={{
                    padding: "16px",
                  }}
                >
                  <HeaderWrapper>
                    <Header>{getLocalizedText("submit_file")}</Header>
                    <SubHeader>
                      {" "}
                      {getLocalizedText("fill_in_the_below_details")}
                    </SubHeader>
                  </HeaderWrapper>
                </Toolbar>
              </AppBar>
              <Divider style={{ margin: "0px 16px" }} />
              <Container>
                <Box
                  style={{
                    height: "100%",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <Label>{getLocalizedText("title")}*</Label>
                        <Input
                          required
                          type="text"
                          placeholder="Enter title here..."
                          name="title"
                          value={details.title}
                          onChange={handleChange}
                          disabled={props.isEdit}
                        />
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <Label>{getLocalizedText("description")}</Label>
                        <TextArea
                          type="text"
                          placeholder="Enter description here..."
                          name="desc"
                          value={details.desc}
                          onChange={handleChange}
                          disabled={props.isEdit}
                        />
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <Label>{getLocalizedText("add_file")}</Label>
                        <Input
                          type="file"
                          onChange={handleFile}
                          style={{
                            fontSize: "12px",
                            padding: "2px",
                            backgroundColor: "#EFEFF4",
                            border: "none",
                            borderRadius: "6px",
                          }}
                          accept={
                            props.isEdit
                              ? getFilePatternString(
                                  props.editableData["file_types"]
                                )
                              : "application/pdf,.zip,.txt,.xls,.csv,image/*"
                          }
                        />
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <Label>Password</Label>
                        <Input
                          value={details.file_password}
                          type="text"
                          placeholder="Enter password"
                          name="file_password"
                          onChange={handleChange}
                        />
                      </InputWrapper>
                    </Grid>
                  </Grid>
                </Box>
              </Container>
              <AppBar
                position="static"
                color="inherit"
                elevation={0}
                sx={{ top: "auto", bottom: 0, width: "100%" }}
              >
                <Toolbar
                  style={{
                    padding: "16px",
                    width: dialogWidth,
                    position: "relative",
                  }}
                >
                  {isSubmitFail && (
                    <ErrorAlert
                      onClose={(e) => {
                        e.stopPropagation();
                        setIsSubmitFail(false);
                      }}
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      {errorMessage}
                    </ErrorAlert>
                  )}
                  <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item>
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        color="default"
                        style={{
                          fontSize: "12px",
                          textTransform: "none",
                          fontWeight: "bold",
                        }}
                      >
                        {getLocalizedText("cancel")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={() => {}}
                        type="submit"
                        variant="contained"
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          width: "fit-content",
                          textTransform: "none",
                          ...myTheme.Button.btnBlue,
                        }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
            </Box>
          </ModelContainer>
        ) : (
          <ModelContainer
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MessageWrapper style={{ marginBottom: "0px", padding: "16px" }}>
              <Message>File submitted successfully!</Message>

              <Button
                onClick={handleClose}
                type="button"
                variant="contained"
                color="default"
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "100px",
                }}
              >
                {getLocalizedText("close")}
              </Button>
            </MessageWrapper>
          </ModelContainer>
        )}
      </Modal>
    </>
  );
};

export default FileLogModal;
