import React from "react";
import { styled } from "@mui/material/styles";
import { AppBar, Box, Chip, Toolbar, Typography } from "@mui/material";
import { Grid, Switch } from "@material-ui/core";
import { IsoToLocalDate, dataRenderer } from "../../Functions";

const ModelWrapper = styled(Box)`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 24px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 8px;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ViewAdminQuoteTemplateSidepanel = ({ details, handleActiveSwitch, handleClose }) => {
  const multilineTextGetter = (raw_string) => {
    return raw_string.replace(/\r\n|\n/g, "\n");
  };

  const getQuotationTitle = (schema) => {
    if (
      schema.quotation_title !== null &&
      schema.quotation_title !== "" &&
      schema.quotation_title !== undefined
    ) {
      return schema.quotation_title;
    } else if (
      schema.title !== null &&
      schema.title !== "" &&
      schema.title !== undefined
    ) {
      return schema.title;
    } else {
      return "-";
    }
  };

  const renderBoolValue = (value) => {
    if (value !== undefined && value !== null && value !== "") {
      if (value === false) {
        return (
          <Chip color="warning" label={"No"} style={{ width: "fit-content" }} />
        );
      } else {
        return (
          <Chip
            color="success"
            label={"Yes"}
            style={{ width: "fit-content" }}
          />
        );
      }
    }
    return "-";
  };
  return (
    <Box sx={{ width: 500 }}>
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          width: 500,
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <ModelHeader>Template Details</ModelHeader>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Switch
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleActiveSwitch({
                    flag: details.active,
                    id: details.id,
                  });
                  handleClose();
                }}
                checked={details.active}
              />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <ModelWrapper>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Name</Label>
              <Detail>{dataRenderer(details["name"])}</Detail>
            </InputWrapper>
          </Grid>

          <Grid item xs={6}>
            <InputWrapper>
              <Label>Created At</Label>
              <Detail>
                {details.create_at !== null && details.create_at !== ""
                  ? IsoToLocalDate(details.create_at)
                  : "-"}
              </Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Quotation Title</Label>
              <Detail>
                {details.schema !== null
                  ? getQuotationTitle(details.schema)
                  : "-"}
              </Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Invoice Title</Label>
              <Detail>
                {details.schema !== null
                  ? dataRenderer(details.schema.invoice_title)
                  : "-"}
              </Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Created By</Label>
              <Detail>
                {details.created_by !== null
                  ? dataRenderer(details.created_by.name)
                  : "-"}
              </Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Project</Label>
              <Detail>
                {details.project !== null
                  ? dataRenderer(details.project.name)
                  : "-"}
              </Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Active</Label>
              {renderBoolValue(details["active"])}
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Default</Label>
              {renderBoolValue(details["is_default"])}
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Show Shipping Address</Label>
              {renderBoolValue(
                details["schema"]["billing_details"]["show_shipping_address"]
              )}
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Show Terms and Conditions</Label>
              {renderBoolValue(
                details["schema"]["additional_details"][
                  "show_terms_and_conditions"
                ]
              )}
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Show Additional Information</Label>
              {renderBoolValue(
                details["schema"]["additional_details"][
                  "show_additional_information"
                ]
              )}
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Show Quotation Number</Label>
              {renderBoolValue(
                details["schema"]["additional_details"]["show_quotation_number"]
              )}
            </InputWrapper>
          </Grid>
          {details["schema"]["additional_details"][
            "show_terms_and_conditions"
          ] === true && (
            <Grid item xs={12}>
              <InputWrapper>
                <Label>Terms and Conditions</Label>
                <Detail
                  component="div"
                  style={{
                    whiteSpace: "pre-line",
                  }}
                >
                  {multilineTextGetter(
                    dataRenderer(
                      details["schema"].additional_details.terms_and_conditions
                    )
                  )}
                </Detail>
              </InputWrapper>
            </Grid>
          )}
          {details["schema"]["additional_details"][
            "show_additional_information"
          ] === true && (
            <Grid item xs={12}>
              <InputWrapper>
                <Label>Additional Information</Label>
                <Detail
                  component="div"
                  style={{
                    whiteSpace: "pre-line",
                  }}
                >
                  {multilineTextGetter(
                    dataRenderer(
                      details["schema"].additional_details
                        .additional_information
                    )
                  )}
                </Detail>
              </InputWrapper>
            </Grid>
          )}
        </Grid>
      </ModelWrapper>
    </Box>
  );
};

export default ViewAdminQuoteTemplateSidepanel;
