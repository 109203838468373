import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import { styled } from "@mui/material/styles";
import {
  AppBar,
  Button,
  ButtonBase,
  Card,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PreviewWebPage from "../../components/webpage_builder_components/PreviewWebPage";
import SectionFieldsPanel from "../../components/webpage_builder_components/SectionFieldsPanel";
import { pageSchemaConfig } from "../../Config";

const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;
const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  row-gap: 10px;
`;
const Message = styled(Typography)`
  font: normal normal normal 20px Open Sans;
  color: #4d4e4f;
  margin: 0px;
  text-align: center;
`;

const Row = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const CustomCard = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60;
  width: 100%;
  cursor: pointer;
  border: ${(e) => (e.flag ? "1px solid green" : "none")};
`;
const drawerWidth = 300;

const WebpageBuilder = () => {
  const navigate = useNavigate();
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [appbarData, setAppbarData] = useState({
    left_logo: null,
    right_logo: null,
  });
  const [topSectionData, setTopSectionData] = useState({
    heading: "Heading1",
    key_points: ["First key point"],
    bg: null,
  });
  const [aboutSectionData, setAboutSectionData] = useState({
    heading: "Heading2",
    key_points: ["Card Title 1"],
  });
  const [bottomSectionData, setBottomSectionData] = useState({
    heading: "Heading3",
    key_points: ["First key point"],
  });
  const [footerData, setFooterData] = useState({
    footer_text_left: "2023 Company",
    footer_text_right: "Private Limited. All Rights Reserved",
  });
  const [sectionScope, setSectionScope] = useState("appbar_section");
  const handleSubmit = (e) => {
    e.preventDefault();
    let payload = {
      top_section: {
        heading: topSectionData.heading,
        key_points: topSectionData.key_points,
        bg: topSectionData.bg,
      },
      about_section: {
        heading: aboutSectionData.heading,
        card_content: aboutSectionData.key_points,
      },
      bottom_section: {
        heading: bottomSectionData.heading,
        key_points: bottomSectionData.key_points,
      },
      footer: `Copyright© ${footerData.footer_text_left} | ${footerData.footer_text_right}`,
      form_id: "b3077d1d-e0b7-442e-bca1-e1421e749b3f",
    };
  };

  const handleCardTap = (scope) => {
    setSectionScope(scope);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <CssBaseline />
        <Box component={"form"} onSubmit={handleSubmit}>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              zIndex: 10,
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
              },
            }}
            variant="permanent"
            anchor="left"
          >
            <AppBar
              component={"nav"}
              position="sticky"
              color="inherit"
              sx={{ top: 0, bottom: "auto", width: drawerWidth, left: 0 }}
            >
              <Toolbar style={{ padding: "16px" }}>
                <Row>
                  <Header>Create Web Page</Header>
                </Row>
              </Toolbar>
            </AppBar>

            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                paddingX: "16px",
                overflowY: "auto",
                height: "100%",
                marginTop: "16px",
              }}
            >
              <Stack direction={"column"} spacing={3}>
                <ButtonBase href="#appbar_section">
                  <CustomCard
                    onClick={() => {
                      handleCardTap("appbar_section");
                    }}
                    sx={{ height: 60, width: "100%" }}
                    flag={sectionScope === "appbar_section"}
                  >
                    <Typography>Appbar Section</Typography>
                  </CustomCard>
                </ButtonBase>
                <ButtonBase href="#top_section">
                  <CustomCard
                    onClick={() => {
                      handleCardTap("top_section");
                    }}
                    sx={{ height: 60, width: "100%" }}
                    flag={sectionScope === "top_section"}
                  >
                    <Typography>Top Section</Typography>
                  </CustomCard>
                </ButtonBase>
                <ButtonBase href="#about_section">
                  <CustomCard
                    onClick={() => {
                      handleCardTap("about_section");
                    }}
                    sx={{ height: 60, width: "100%" }}
                    flag={sectionScope === "about_section"}
                  >
                    <Typography>About Section</Typography>
                  </CustomCard>{" "}
                </ButtonBase>

                <ButtonBase href="#bottom_section">
                  <CustomCard
                    onClick={() => {
                      handleCardTap("bottom_section");
                    }}
                    sx={{ height: 60, width: "100%" }}
                    flag={sectionScope === "bottom_section"}
                  >
                    <Typography>Bottom Section</Typography>
                  </CustomCard>
                </ButtonBase>
                <ButtonBase href="#footer_section">
                  <CustomCard
                    onClick={() => {
                      handleCardTap("footer_section");
                    }}
                    sx={{ height: 60, width: "100%" }}
                    flag={sectionScope === "footer_section"}
                  >
                    <Typography>Footer Section</Typography>
                  </CustomCard>
                </ButtonBase>
              </Stack>
            </List>

            <Divider style={{ marginBottom: "60px" }} />
            <AppBar
              position="fixed"
              color="inherit"
              sx={{ top: "auto", bottom: 0, width: drawerWidth, left: 0 }}
            >
              <Toolbar style={{ padding: "16px" }}>
                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      navigate(-1);
                    }}
                    variant="contained"
                    color="inherit"
                    style={{ textTransform: "capitalize", width: "100%" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ textTransform: "capitalize", width: "100%" }}
                  >
                    Create
                  </Button>
                </ButtonWrapper>
              </Toolbar>
            </AppBar>
          </Drawer>
        </Box>

        <Box
          component="main"
          sx={{ flexGrow: 1, width: "80%", height: "100%" }}
        >
          {!isSubmitSuccess ? (
            <PreviewWebPage
              scope={sectionScope}
              appbarData={appbarData}
              topSectionData={topSectionData}
              aboutSectionData={aboutSectionData}
              bottomSectionData={bottomSectionData}
              pageSchema={pageSchemaConfig}
              footerData={footerData}
            />
          ) : (
            <MessageWrapper>
              <Message>Form created successfully!</Message>
              <Button
                onClick={() => {
                  navigate("/web-forms");
                }}
                variant="outlined"
                color="primary"
                style={{ textTransform: "capitalize", width: "fit-content" }}
              >
                Go Back
              </Button>
            </MessageWrapper>
          )}
        </Box>

        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            zIndex: 10,
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
            },
          }}
          variant="permanent"
          anchor="right"
        >
          <AppBar
            component={"nav"}
            position="sticky"
            color="inherit"
            elevation={0}
            sx={{ top: 0, bottom: "auto", width: drawerWidth, left: 0 }}
          >
            <Toolbar style={{ padding: "16px", width: drawerWidth }}>
              <Row>
                <Header>Settings</Header>
              </Row>
            </Toolbar>
          </AppBar>

          {(() => {
            if (sectionScope === "appbar_section") {
              return (
                <SectionFieldsPanel data={appbarData} setData={setAppbarData} />
              );
            } else if (sectionScope === "top_section") {
              return (
                <SectionFieldsPanel
                  data={topSectionData}
                  setData={setTopSectionData}
                />
              );
            } else if (sectionScope === "about_section") {
              return (
                <SectionFieldsPanel
                  data={aboutSectionData}
                  setData={setAboutSectionData}
                />
              );
            } else if (sectionScope === "bottom_section") {
              return (
                <SectionFieldsPanel
                  data={bottomSectionData}
                  setData={setBottomSectionData}
                />
              );
            } else if (sectionScope === "footer_section") {
              return (
                <SectionFieldsPanel data={footerData} setData={setFooterData} />
              );
            }
          })()}
        </Drawer>
      </Box>
    </>
  );
};

export default observer(WebpageBuilder);
