import { Box, Checkbox, FormControlLabel, TextField } from "@mui/material";

import React, { useEffect, useState } from "react";

const CheckboxWithOtherOptionWidget = ({
  list,
  type,
  handleChange,
  value,
  fieldData,
}) => {
  const [otherField, setOtherField] = useState("");
  useEffect(() => {
    if (value.includes("other_value")) {
      if (fieldData.hasOwnProperty("other_value")) {
        setOtherField(fieldData["other_value"]);
      }
    }
  }, []);
  if (type === "list") {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", rowGap: "8px" }}>
        {list.map((str, index) => {
          return (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={value.includes(str)}
                  onChange={(e) => {
                    let tempList = [...value];
                    if (e.target.checked) {
                      if (!tempList.includes(e.target.value)) {
                        tempList.push(e.target.value);
                      }
                    } else {
                      tempList = tempList.filter(
                        (item) => item !== e.target.value
                      );
                    }
                    handleChange(tempList, otherField);
                  }}
                />
              }
              value={str}
              label={str}
            />
          );
        })}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "20px",
            width: "100%",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={value.includes("other_value")}
                onChange={(e) => {
                  let tempList = [...value];
                  if (e.target.checked) {
                    if (!tempList.includes(e.target.value)) {
                      tempList.push(e.target.value);
                    }
                    handleChange(tempList, otherField);
                  } else {
                    let filteredList = [];
                    tempList.forEach((str) => {
                      let index = list.findIndex((e) => e === str);
                      if (index !== -1) {
                        filteredList.push(str);
                      }
                    });
                    setOtherField("");
                    handleChange(filteredList, "");
                  }
                }}
              />
            }
            value={"other_value"}
            label={"Other"}
          />
          {value.includes("other_value") && (
            <TextField
              required
              value={otherField}
              onChange={(e) => setOtherField(e.target.value)}
              variant="standard"
              style={{ width: "100%" }}
              placeholder="Enter value"
              onBlur={() => {
                let filteredList = [];
                value.forEach((str) => {
                  let index = list.findIndex((e) => e === str);
                  if (index !== -1) {
                    filteredList.push(str);
                  }
                });
                filteredList.push("other_value");
                handleChange(filteredList, otherField);
              }}
            />
          )}
        </Box>
      </Box>
    );
  } else {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", rowGap: "8px" }}>
        {list.map((obj, index) => {
          return (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={value.includes(obj.value)}
                  onChange={(e) => {
                    let tempList = [...value];
                    if (e.target.checked) {
                      if (!tempList.includes(e.target.value)) {
                        tempList.push(e.target.value);
                      }
                    } else {
                      tempList = tempList.filter(
                        (item) => item !== e.target.value
                      );
                    }
                    handleChange(tempList, otherField);
                  }}
                />
              }
              value={obj.value}
              label={obj.name}
            />
          );
        })}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "20px",
            width: "100%",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={value.includes("other_value")}
                onChange={(e) => {
                  let tempList = [...value];
                  if (e.target.checked) {
                    if (!tempList.includes(e.target.value)) {
                      tempList.push(e.target.value);
                    }
                    handleChange(tempList, otherField);
                  } else {
                    let filteredList = [];
                    tempList.forEach((str) => {
                      let index = list.findIndex((obj) => obj["value"] === str);
                      if (index !== -1) {
                        filteredList.push(str);
                      }
                    });
                    setOtherField("");
                    handleChange(filteredList, "");
                  }
                }}
              />
            }
            value={"other_value"}
            label={"Other"}
          />
          {value.includes("other_value") && (
            <TextField
              required
              value={otherField}
              onChange={(e) => setOtherField(e.target.value)}
              variant="standard"
              style={{ width: "100%" }}
              placeholder="Enter value"
              onBlur={() => {
                let filteredList = [];
                value.forEach((str) => {
                  let index = list.findIndex((obj) => obj["value"] === str);
                  if (index !== -1) {
                    filteredList.push(str);
                  }
                });
                filteredList.push("other_value");
                handleChange(filteredList, otherField);
              }}
            />
          )}
        </Box>
      </Box>
    );
  }
};

export default CheckboxWithOtherOptionWidget;
