import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Button } from "@material-ui/core";
import { AppBar, Box, Grid, Toolbar, Typography, Alert } from "@mui/material";
import { observer } from "mobx-react-lite";
import rootStore from "../../stores/RootStore";
import { LoadingButton } from "@mui/lab";
import PublishRuleCardWidget from "./PublishRuleCardWidget";
import {
  publishProjectAdministrationQualityApi,
  unPublishProjectAdministrationQualityApi,
} from "../../Api";

const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 500px;

  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
`;
const MessageSm = styled(Typography)`
  margin: 0px;
  font: normal normal 500 12px Open Sans;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  gap: 20px;
`;

const PublishRuleSidepanel = ({ ruleList, setRefresh, setOpen }) => {
  const columnsList = [...rootStore.userStore.AllColumnsList];
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedRuleIDList, setSelectedRuleIDList] = useState([]);
  const handleClose = () => {
    setOpen(false);
  };

  const getValidStatusValue = (status) => {
    return status ? status.toLowerCase() : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let idListToPublish = [];
    let idListToUnPublish = [];
    selectedRuleIDList.forEach((id) => {
      let index = ruleList.findIndex((rule) => rule["id"] === id);
      if (index !== -1) {
        let ruleData = ruleList[index];
        if (
          getValidStatusValue(ruleData["status"]) === "unpublished" &&
          ruleData["deleted_on"] === null
        ) {
          if (!idListToPublish.includes(ruleData["id"])) {
            idListToPublish.push(ruleData["id"]);
          }
        } else if (
          getValidStatusValue(ruleData["status"]) === "published" &&
          ruleData["deleted_on"] !== null
        ) {
          if (!idListToUnPublish.includes(ruleData["id"])) {
            idListToUnPublish.push(ruleData["id"]);
          }
        }
      }
    });

    if (idListToUnPublish.length > 0) {
      let unpublish_payload = {
        quality_score_ids: idListToUnPublish,
      };
      let unpublish_response = await unPublishProjectAdministrationQualityApi({
        payload: unpublish_payload,
      });
      if (unpublish_response.hasError()) {
        setErrorMessage(unpublish_response.errorMessage);
        setIsSubmitFail(true);
        setIsSubmitted(true);

        return;
      }
    }

    if (idListToPublish.length > 0) {
      let publish_payload = {
        quality_score_ids: idListToPublish,
      };
      let publish_response = await publishProjectAdministrationQualityApi({
        payload: publish_payload,
      });
      if (publish_response.hasError()) {
        setErrorMessage(publish_response.errorMessage);
        setIsSubmitFail(true);
        setIsSubmitted(true);
        return;
      }
    }

    setIsSubmitting(false);
    setIsSubmitFail(false);
    setIsSubmitted(true);
    setRefresh(true);
  };

  return (
    <Box role="presentation">
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          width: 500,
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar>
          <ModelHeader>Publish Rules</ModelHeader>
        </Toolbar>
      </AppBar>
      {!isSubmitted ? (
        <ModelWrapper component={"form"} onSubmit={handleSubmit}>
          <InputContainer>
            <Grid container spacing={2}>
              {ruleList.map((item, idx) => {
                let value = null;
                let dataType = null;
                let index = columnsList.findIndex(
                  (column) => column["id"] === item["column_meta"]["id"]
                );
                if (index !== -1) {
                  dataType = columnsList[index]["data_type"];
                }
                if (
                  item["operator"] === "between" ||
                  item["operator"] === "not between"
                ) {
                  value = {
                    start: item["value1"],
                    end: item["value2"],
                  };
                } else {
                  value = item["value1"];
                }

                return (
                  <Grid item xs={12}>
                    <PublishRuleCardWidget
                      handleClick={() => {
                        let tempList = [...selectedRuleIDList];
                        if (tempList.includes(item["id"])) {
                          tempList = tempList.filter((id) => id !== item["id"]);
                        } else {
                          tempList.push(item["id"]);
                        }
                        console.log(tempList);
                        setSelectedRuleIDList(tempList);
                      }}
                      checked={selectedRuleIDList.includes(item["id"])}
                      canShowBestMatch={item["best_match"]}
                      dataType={dataType}
                      operator={item["operator"]}
                      score={item["score"]}
                      key={idx}
                      value={value}
                      columnName={item["column_meta"]["name"]}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </InputContainer>

          <AppBar
            position="fixed"
            color="inherit"
            elevation={0}
            sx={{ top: "auto", bottom: 0, width: 500 }}
          >
            <Toolbar style={{ width: 500, position: "relative" }}>
              <ButtonWrapper>
                <Button
                  onClick={handleClose}
                  type="button"
                  variant="contained"
                  color="default"
                  style={{
                    textTransform: "none",
                    fontSize: "14px",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  Cancel
                </Button>
                {isSubmitting ? (
                  <LoadingButton
                    loading
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: "none",
                      width: "100%",
                    }}
                  >
                    <span>Update</span>
                  </LoadingButton>
                ) : (
                  <Button
                    disabled={selectedRuleIDList.length === 0}
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: "none",
                      fontSize: "14px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    Publish
                  </Button>
                )}
              </ButtonWrapper>
            </Toolbar>
          </AppBar>
        </ModelWrapper>
      ) : isSubmitFail ? (
        <MessageWrapper>
          <Message style={{ color: "red" }}>{errorMessage}</Message>

          <Button
            onClick={handleClose}
            type="button"
            variant="contained"
            color="default"
            style={{
              marginTop: "20px",
              textTransform: "none",
              fontSize: "14px",
              fontWeight: "bold",
              width: "100px",
            }}
          >
            Close
          </Button>
        </MessageWrapper>
      ) : (
        <MessageWrapper>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Message>Rule(s) published successfully!</Message>

            <Button
              onClick={handleClose}
              type="button"
              variant="contained"
              color="default"
              style={{
                marginTop: "20px",
                textTransform: "none",
                fontSize: "14px",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              Close
            </Button>
          </Box>
          <Alert severity="info">
            <MessageSm>
              Quality score computation may take time, depending on the number
              of leads in your account.
            </MessageSm>
          </Alert>
        </MessageWrapper>
      )}
    </Box>
  );
};

export default observer(PublishRuleSidepanel);
