import React, { useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import LoadingAnimationComponent from "../loading_component/LoadingAnimationComponent";

const Image = styled("img")`
  max-width: 100%;
  max-height: 400px;
  width: auto;
  height: auto;
  object-fit: contain;
`;

const ImageComponent = ({src}) => {
  const [loading, setLoading] = useState(true);

  return (
    <>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
          position: "relative", // Ensures that child elements can be positioned relative to this box
        }}
      >
        <Image
          style={{ display: loading ? "none" : "block" }}
          src={src}
          onLoad={() => {
            setLoading(false);
          }}
        />
        {loading && (
          <Box
            sx={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <LoadingAnimationComponent size={"small"}/>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ImageComponent;
