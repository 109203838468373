import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import { Button } from "@material-ui/core";
import { styled } from "@mui/material/styles";

const Message = styled(Typography)(({ theme }) => ({
  font: "normal normal 400 16px Open Sans",
  color: "#4d4e4f",
}));
const ProgressWrapper = styled(Box)`
  width: 200px;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;
const MessageWithLoadingDialog = ({
  successMessage,
  errorMessage,
  open,
  loading,
  isError,
  handleClose,
}) => {
  return (
    <Dialog
      disableScrollLock={true}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {!loading ? (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: "20px",
            padding: "20px",
          }}
        >
          <DialogContent style={{ padding: "0px" }}>
            <DialogContentText id="alert-dialog-description">
              {isError ? (
                <Message>{errorMessage}</Message>
              ) : (
                <Message>{successMessage}</Message>
              )}
            </DialogContentText>
          </DialogContent>

          <DialogActions style={{ padding: "0px" }}>
            <Button
              color="default"
              variant="contained"
              style={{
                textTransform: "capitalize",
                fontWeight: "bold",
                width: "100px",
              }}
              onClick={handleClose}
            >
              Close
            </Button>
          </DialogActions>
        </Box>
      ) : (
        <ProgressWrapper>
          <CircularProgress />
        </ProgressWrapper>
      )}
    </Dialog>
  );
};
export default MessageWithLoadingDialog;
