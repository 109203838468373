import { Box, Divider, Skeleton, styled } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const TableSkeleton = ({ height }) => {
  const [cellSkeletonList, setCellSkeletonList] = useState([]);
  const parentRef = useRef(null);
  useEffect(() => {
    if (parentRef.current) {
      const parentHeight = parentRef.current.clientHeight;
      const numberOfRows = Math.floor(parentHeight / 40);
      let tempList = [];
      for (let i = 0; i < numberOfRows; i++) {
        tempList.push(`row-${i + 1}`);
      }
      setCellSkeletonList(tempList);
    }
  }, [parentRef.current]);

  const HeaderLayout = () => {
    return (
      <>
        <Box sx={{ flex: 1, height: "50px" }}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "8px",
            }}
          />
        </Box>
        <Divider />
        <Box sx={{ flex: 1, height: "50px" }}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "8px",
            }}
          />
        </Box>
        <Divider />

        <Box sx={{ flex: 1, height: "50px" }}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "8px",
            }}
          />
        </Box>
        <Divider />

        <Box sx={{ flex: 1, height: "50px" }}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "8px",
            }}
          />
        </Box>
        <Divider />

        <Box sx={{ flex: 1, height: "50px" }}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "8px",
            }}
          />
        </Box>
        <Divider />

        <Box sx={{ flex: 1, height: "50px" }}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "8px",
            }}
          />
        </Box>
        <Divider />

        <Box sx={{ flex: 1, height: "50px" }}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "8px",
            }}
          />
        </Box>
      </>
    );
  };
  return (
    <Container
      sx={{
        height: height,
        padding: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "4px",
        }}
      >
        <HeaderLayout />
      </Box>
      <Divider style={{ margin: "8px 0px" }} />
      <Box
        ref={parentRef}
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "4px",
        }}
      >
        {cellSkeletonList.map((cell) => {
          return (
            <Box sx={{ flex: 1, height: "40px" }}>
              <Skeleton
                animation="wave"
                variant="rectangular"
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "8px",
                }}
              />
            </Box>
          );
        })}
      </Box>
    </Container>
  );
};

export default TableSkeleton;
