import { action, makeObservable, observable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { leadListingPageSize } from "../Config";
import {
  getFilterColsApiV2,
  getFilteredLeadsApiV2,
  getFilteredLeadsApiV3,
  getFilterLeadsItemCountApi,
  getLeadsApi,
  getLeadsApiV3,
  getLeadsItemCountApi,
} from "../Api";
import rootStore from "../stores/RootStore";
import { hasAccessToLeadsPaginationV2 } from "../Functions";

class FilterPageState {
  filterPaginataionInfo = {
    page: 0,
    page_size: leadListingPageSize,
    count: 0,
    page_addon: false,
  };
  selectColumns = [];
  filterLeadList = [];
  selectedLeadIDListMapping = {};
  leadDetailsForSelectedIDs = {};
  selectedLeadCount = 0;
  columnsWidthMapping = {};
  leadSortingByColumnInfo = {
    is_sorting: false,
    sorting_data: null,
  };

  constructor() {
    makeObservable(this, {
      selectedLeadIDListMapping: observable,
      leadDetailsForSelectedIDs: observable,
      filterPaginataionInfo: observable,
      filterLeadList: observable,
      selectColumns: observable,
      selectedLeadCount: observable,
      columnsWidthMapping: observable,
      leadSortingByColumnInfo: observable,
      updateSelectedLeadIDListMapping: action,
      updateLeadDetailsForSelectedIDs: action,
      getAllSelectedLeadIDList: action,
      getNumberOfSelectedLeads: action,
      reset: action,
      resetPaginationAndLeadListing: action,
      hasNextLead: action,
      hasPrevLead: action,
      getPrevLeadID: action,
      getNextLeadID: action,
      fetchColumns: action,
      updateSelectedLeadCount: action,
      updateColumnsWidthMapping: action,
      fetchLeads: action,
      fetchLeadsWithCount: action,
      fetchLeadsAndCountSeperate: action,
      fetchLeadsItemCount: action,
      fetchFilterLeadsWithCount: action,
      fetchFilterLeadsAndCountSeperate: action,
      fetchFilterLeadsItemCount: action,
      updateFilterPaginationInfo: action,
      getLeadPaginationCounterData: action,
      updateLeadSortingByColumnInfo: action,
      getColumnSortingDirection: action,
    });

    makePersistable(this, {
      name: FilterPageState,
      storage: window.localStorage,
      expireIn: 86400000,
      removeOnExpiration: true,
      stringify: true,
      debugMode: false,
      properties: [""],
    });
  }

  //------------------------functions related to lead sorting by column---------------

  updateLeadSortingByColumnInfo = ({ columnMeta }) => {
    const currentDirection =
      this.leadSortingByColumnInfo["sorting_data"] !== null
        ? this.leadSortingByColumnInfo["sorting_data"]["direction"]
        : "asc"; //if sorting is for the first time then assuming current direction as ascending order
    const sortingDirection = currentDirection === "desc" ? "asc" : "desc";
    const flag = sortingDirection === "desc"; // flag is true if the new direction is "desc"

    this.leadSortingByColumnInfo = {
      is_sorting: true,
      sorting_data: {
        direction: sortingDirection,
        field_name: columnMeta["field"],
        flag: flag,
      },
    };
  };

  getColumnSortingDirection = () => {
    if (this.leadSortingByColumnInfo["sorting_data"] !== null) {
      return this.leadSortingByColumnInfo["sorting_data"]["direction"];
    }
    return "desc";
  };

  isLeadSortingByColumnApplied = ({ columnMeta }) => {
    if (this.leadSortingByColumnInfo["sorting_data"] !== null) {
      return (
        this.leadSortingByColumnInfo["sorting_data"]["field_name"] ===
        columnMeta["field"]
      );
    }
    return false;
  };
  //------------------------------------end-----------------------------------------

  fetchColumns = async () => {
    let columnsList = [...rootStore.userStore.AllColumnsList];
    const viewId = rootStore.userStore.currentLoadedFilter.hasOwnProperty(
      "viewId"
    )
      ? rootStore.userStore.currentLoadedFilter["viewId"]
      : rootStore.userStore.currentFilterViewId;

    if (viewId === "all") {
      try {
        let response = await getFilterColsApiV2();

        let tempColumnsList = [];
        response.data.forEach((entry) => {
          let index = columnsList.findIndex(
            (column) => column["field"] === entry["field"]
          );
          if (index !== -1) {
            let columnMeta = columnsList[index];
            if (rootStore.userStore.isColumnVisible(columnMeta)) {
              tempColumnsList.push(columnMeta);
            }
          }
        });
        this.selectColumns = tempColumnsList;
      } catch (error) {
        this.selectColumns = [];
      }
    } else {
      let storedTabColumns = rootStore.leadStore.tabInfo[viewId]["columns"];
      if (storedTabColumns.length > 0) {
        this.selectColumns = storedTabColumns;
      } else {
        let index = rootStore.leadStore._tabsDetails.findIndex(
          (tab) => tab["tab"]["id"] === viewId
        );

        let tempColumnList = [];
        if (index !== -1) {
          let tempSelectedColumns = [];
          let roleBasedSelectedColumns =
            rootStore.leadStore._tabsDetails[index]["selected_columns"];
          if (roleBasedSelectedColumns.length > 0) {
            tempSelectedColumns = roleBasedSelectedColumns;
          } else {
            let generalSelectedColumns =
              rootStore.leadStore._tabsDetails[index]["tab"][
                "selected_columns"
              ];
            tempSelectedColumns = generalSelectedColumns;
          }

          tempSelectedColumns.forEach((obj) => {
            let index = columnsList.findIndex(
              (column) => column["field"] === obj["field"]
            );
            if (index !== -1) {
              let columnMeta = columnsList[index];
              if (rootStore.userStore.isColumnVisible(columnMeta)) {
                tempColumnList.push(columnMeta);
              }
            }
          });
        }
        this.selectColumns = tempColumnList;
      }
    }
  };

  //*****************************Lead Navigation related helper functions************ */
  getPrevLeadID = async (leadID) => {
    const pagintaionInfo = this.filterPaginataionInfo;
    const index = this.filterLeadList.findIndex(
      (element) => element.id === leadID
    );

    if (index === 0) {
      let currentPage = pagintaionInfo.page;

      if (currentPage === 0) {
        return "";
      }
      currentPage -= 1;
      this.updateFilterPaginationInfo({ key: "page", value: currentPage });
      await this.fetchLeads();
      if (this.filterLeadList.length > 0) {
        return this.filterLeadList[this.filterLeadList.length - 1].id;
      } else {
        return "";
      }
    } else {
      return this.filterLeadList[index - 1].id;
    }
  };

  getNextLeadID = async (leadID) => {
    const pagintaionInfo = this.filterPaginataionInfo;
    const index = this.filterLeadList.findIndex(
      (element) => element.id === leadID
    );

    if (index === this.filterLeadList.length - 1) {
      let currentPage = pagintaionInfo.page;
      let limit = pagintaionInfo.page_size;
      let numberOfPages = Math.ceil(pagintaionInfo.count / limit);
      if (currentPage >= numberOfPages - 1) {
        return "";
      }
      currentPage += 1;

      this.updateFilterPaginationInfo({ key: "page", value: currentPage });
      await this.fetchLeads();
      if (this.filterLeadList.length > 0) {
        return this.filterLeadList[0].id;
      } else {
        return "";
      }
    } else {
      return this.filterLeadList[index + 1].id;
    }
  };

  hasNextLead = (leadID) => {
    const tabInfo = this.filterPaginataionInfo;
    let currentPage = tabInfo.page;
    let limit = tabInfo.page_size;
    let numberOfPages = Math.ceil(tabInfo.count / limit);

    let index = this.filterLeadList.findIndex(
      (element) => element.id === leadID
    );
    if (index === this.filterLeadList.length - 1) {
      return currentPage >= numberOfPages - 1;
    } else {
      return false;
    }
  };
  hasPrevLead = (leadID) => {
    const tabInfo = this.filterPaginataionInfo;
    let currentPage = tabInfo.page;

    let index = this.filterLeadList.findIndex(
      (element) => element.id === leadID
    );
    if (index === 0) {
      return currentPage === 0;
    }
    return false;
  };

  //**********************************END****************************************** */

  getLeadPaginationCounterData = () => {
    let pageLimit = this.filterPaginataionInfo["page_size"];
    let count = this.filterPaginataionInfo["count"];
    let page = this.filterPaginataionInfo["page"];
    let min = count !== null && count > 0 ? page * pageLimit + 1 : 0;

    // Calculate the actual item count for the current page
    let currentPageCount =
      count !== null && count > 0
        ? Math.min(pageLimit, count - page * pageLimit)
        : 0;
    let max = currentPageCount > 0 ? min + currentPageCount - 1 : 0;

    return {
      min: min,
      max: max,
      count: count,
      page_addon: this.filterPaginataionInfo["page_addon"],
    };
  };

  fetchLeadsItemCount = async ({ payload }) => {
    let response = await getLeadsItemCountApi({ payload: payload });
    if (Object.keys(response).length > 0) {
      this.updateFilterPaginationInfo({ key: "count", value: response.count });
    } else {
      this.updateFilterPaginationInfo({ key: "count", value: null });
    }
    this.updateFilterPaginationInfo({ key: "page_addon", value: false });
  };

  fetchLeadsWithCount = async ({ payload }) => {
    let response = await getLeadsApi({
      page_size: this.filterPaginataionInfo.page_size,
      page: this.filterPaginataionInfo.page,
      payload: payload,
    });
    if (Object.keys(response).length > 0) {
      this.filterLeadList = response.items;
      this.updateFilterPaginationInfo({
        key: "count",
        value: response.item_count,
      });
    } else {
      this.filterLeadList = [];
      this.updateFilterPaginationInfo({ key: "count", value: 0 });
    }
  };

  fetchLeadsAndCountSeperate = async ({ payload }) => {
    let response = await getLeadsApiV3({
      page_size: this.filterPaginataionInfo.page_size,
      page: this.filterPaginataionInfo.page,
      payload: payload,
    });

    if (response.length > this.filterPaginataionInfo.page_size) {
      this.updateFilterPaginationInfo({ key: "page_addon", value: true });
      this.filterLeadList = response.slice(
        0,
        this.filterPaginataionInfo.page_size
      );
    } else {
      this.updateFilterPaginationInfo({ key: "page_addon", value: false });
      this.filterLeadList = response;
    }
    this.fetchLeadsItemCount({ payload: payload });
  };

  fetchFilterLeadsItemCount = async ({ payload }) => {
    let response = await getFilterLeadsItemCountApi({ payload: payload });
    if (Object.keys(response).length > 0) {
      this.updateFilterPaginationInfo({ key: "count", value: response.count });
    } else {
      this.updateFilterPaginationInfo({ key: "count", value: null });
    }
    this.updateFilterPaginationInfo({ key: "page_addon", value: false });
  };

  fetchFilterLeadsWithCount = async ({ payload }) => {
    let response = await getFilteredLeadsApiV2({
      page_size: this.filterPaginataionInfo.page_size,
      page_no: this.filterPaginataionInfo.page,
      payload: payload,
    });
    if (Object.keys(response).length > 0) {
      this.filterLeadList = response.items;
      this.updateFilterPaginationInfo({
        key: "count",
        value: response.item_count,
      });
    } else {
      this.filterLeadList = [];
      this.updateFilterPaginationInfo({ key: "count", value: 0 });
    }
  };

  fetchFilterLeadsAndCountSeperate = async ({ payload }) => {
    let response = await getFilteredLeadsApiV3({
      page_size: this.filterPaginataionInfo.page_size,
      page_no: this.filterPaginataionInfo.page,
      payload: payload,
    });

    if (response.length > this.filterPaginataionInfo.page_size) {
      this.updateFilterPaginationInfo({ key: "page_addon", value: true });
      this.filterLeadList = response.slice(
        0,
        this.filterPaginataionInfo.page_size
      );
    } else {
      this.updateFilterPaginationInfo({ key: "page_addon", value: false });
      this.filterLeadList = response;
    }
    this.fetchFilterLeadsItemCount({ payload: payload });
  };

  fetchLeads = async () => {
    this.updateFilterPaginationInfo({ key: "count", value: null });
    this.updateFilterPaginationInfo({ key: "page_addon", value: false });
    const viewId = rootStore.userStore.currentLoadedFilter.hasOwnProperty(
      "viewId"
    )
      ? rootStore.userStore.currentLoadedFilter["viewId"]
      : rootStore.userStore.currentFilterViewId;
    const columnIdList = [];
    this.selectColumns.forEach((column) => {
      if (!columnIdList.includes(column["id"])) {
        columnIdList.push(column["id"]);
      }
    });

    if (viewId === "all") {
      let payload = {
        query: rootStore.userStore.currentFilterPayload,
        listing_columns: columnIdList,
        sort_on: this.leadSortingByColumnInfo["sorting_data"],
      };
      if (hasAccessToLeadsPaginationV2()) {
        await this.fetchFilterLeadsAndCountSeperate({ payload: payload });
      } else {
        await this.fetchFilterLeadsWithCount({ payload: payload });
      }
    } else {
      let payload = {
        tab_id: viewId,
        query: rootStore.userStore.currentFilterPayload,
        listing_columns: columnIdList,
        sort_on: this.leadSortingByColumnInfo["sorting_data"],
      };
      if (hasAccessToLeadsPaginationV2()) {
        await this.fetchLeadsAndCountSeperate({ payload: payload });
      } else {
        await this.fetchLeadsWithCount({ payload: payload });
      }
    }
    this.leadSortingByColumnInfo["is_sorting"] = false;
  };

  updateColumnsWidthMapping = ({ columnID, width }) => {
    this.columnsWidthMapping[columnID] = width;
  };

  updateFilterPaginationInfo = ({ key, value }) => {
    this.filterPaginataionInfo[key] = value;
  };

  updateSelectedLeadIDListMapping = ({ page, idList }) => {
    this.selectedLeadIDListMapping[page] = idList;
  };
  updateLeadDetailsForSelectedIDs = ({ leadsList }) => {
    this.leadDetailsForSelectedIDs = {
      ...this.leadDetailsForSelectedIDs,
      ...leadsList,
    };
  };

  getAllSelectedLeadIDList = () => {
    const uniqueLeadIds = new Set();
    for (const page in this.selectedLeadIDListMapping) {
      this.selectedLeadIDListMapping[page].forEach((id) =>
        uniqueLeadIds.add(id)
      );
    }
    return Array.from(uniqueLeadIds);
  };

  getNumberOfSelectedLeads = () => {
    let totalSelectedLeads = 0;
    for (const page in this.selectedLeadIDListMapping) {
      totalSelectedLeads += this.selectedLeadIDListMapping[page].length;
    }
    return totalSelectedLeads;
  };

  updateSelectedLeadCount = (count) => {
    this.selectedLeadCount = count;
  };

  resetPaginationAndLeadListing = () => {
    this.filterPaginataionInfo = {
      page: 0,
      page_size: leadListingPageSize,
      count: 0,
      page_addon: false,
    };
    this.filterLeadList = [];
    this.selectColumns = [];
    this.columnsWidthMapping = {};
    this.leadSortingByColumnInfo = {
      is_sorting: false,
      sorting_data: null,
    };
  };

  reset = () => {
    this.selectedLeadIDListMapping = {};
    this.leadDetailsForSelectedIDs = {};
    this.selectedLeadCount = 0;
  };
}

export default FilterPageState;
