import React from "react";
import styled from "styled-components";
import { IsoToLocalTime } from "../../../../Functions";

const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 28px;
  column-gap: 20px;
  width: 428px;
  margin-bottom: 20px;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
`;
const DetailWrapperLg = styled.div`
  display: flex;
  flex-direction: column;
  width: 428px;
  max-height: 40px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #eeeeee;
  }
`;
const Label = styled.span`
  font: normal normal 500 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Detail = styled.span`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const FirstScreen = (props) => {
  return (
    <>
      <InputContainer>
        <InputWrapper>
          <Label>Place</Label>
          <Detail>{props.data.place !== null ? props.data.place : "-"}</Detail>
        </InputWrapper>
        <InputWrapper style={{ width: "428px" }}>
          <Label>Purpose</Label>
          <DetailWrapperLg>
            <Detail>
              {props.data.purpose !== null ? props.data.purpose : "-"}
            </Detail>
          </DetailWrapperLg>
        </InputWrapper>

        <InputWrapper>
          <Label>Checked In</Label>
          <Detail>
            {props.data.checked_in !== null
              ? IsoToLocalTime(props.data.checked_in)
              : "-"}
          </Detail>
        </InputWrapper>
        <InputWrapper>
          <Label>Checked Out</Label>
          <Detail>
            {props.data.checked_out !== null
              ? IsoToLocalTime(props.data.checked_out)
              : "-"}
          </Detail>
        </InputWrapper>

        <InputWrapper style={{ width: "428px" }}>
          <Label>Notes</Label>
          <DetailWrapperLg>
            <Detail>
              {props.data.notes !== null ? props.data.notes : "-"}
            </Detail>
          </DetailWrapperLg>
        </InputWrapper>
        {/* <ImageScroller /> */}
      </InputContainer>
    </>
  );
};

export default FirstScreen;
