import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { visitsTabColumnHeaders } from "../../../Db";
import { pageSize } from "../../../Config";
import { getLeadCheckInsApi } from "../../../Api";
import ViewCheckInModal from "../../check_in_modal/ViewCheckInModal";
import { Drawer } from "@material-ui/core";
import analytics from "../../../Analytics";
import { Box, CircularProgress, styled } from "@mui/material";
import { AgGridReact } from "ag-grid-react";

const Container = styled(Box)`
  width: 100%;
  height: 100%;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 345px);`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const VisitsTab = ({ leadID }) => {
  const [row, setRow] = useState([]);
  const [currData, setCurrData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [viewLog, setViewLog] = useState(false);

  const getLeadCallRecords = async () => {
    setRow([]);
    let payload = { lead_id: leadID };
    let response = await getLeadCheckInsApi({
      payload: payload,
    });
    setRow(response);
  };

  const init = async () => {
    await getLeadCallRecords();
    setLoading(false);
  };

  useEffect(() => {
    init();
    analytics.triggerEvent(
      4625000054,
      "checkins_tab_load",
      "lead_details_page",
      "",
      {}
    );
  }, []);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };
  return (
    <>
      <Container>
        {!loading ? (
          <TableWrapper>
            <div
              className="ag-theme-alpine"
              style={{ width: "100%", cursor: "pointer" }}
            >
              <AgGridReact
                domLayout="autoHeight"
                gridOptions={gridOptions}
                rowData={row}
                defaultColDef={defaultColDef}
                columnDefs={visitsTabColumnHeaders}
                animateRows={true}
                suppressCellFocus
                pagination={false}
                paginationPageSize={pageSize}
                onRowClicked={(row) => {
                  if (row.event.defaultPrevented) {
                    return null;
                  }
                  setCurrData(row.data);
                  setViewLog(true);
                }}
              />
            </div>
          </TableWrapper>
        ) : (
          <TableWrapper
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </TableWrapper>
        )}
      </Container>

      <Drawer
        anchor={"right"}
        open={viewLog}
        onClose={() => {
          setViewLog(false);
        }}
      >
        <ViewCheckInModal data={currData} setData={setCurrData} />
      </Drawer>
    </>
  );
};

export default observer(VisitsTab);
