import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useState } from "react";
import styled from "styled-components";
import MessageTemplateModal from "../../components/campaign_modals/MessageTemplateModal";
import SmsProviderModal from "../../components/campaign_modals/SmsProviderModal";
import Footer from "../../components/landing_page_components/footer/Footer";
import MainFilterModal from "../../components/filter_modal/MainFilterModal";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  text-align: left;
  margin: auto;
  background-color: #f5f9ff;
  height: fit-content;
  padding: 20px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;
const Wrapper = styled.div`
  width: 100%;
  margin-top: 64px;
  padding: 20px;
  ${`min-height:calc(100vh - 139px)`};
  display: flex;
  justify-content: center;
`;
const TextArea = styled.textarea`
  width: 100%;
  height: 100px;
  border: 1px solid rgb(204, 204, 204);
  padding: 6px 12px;
  margin-bottom: 20px;
  resize: none;
  outline: none;
  font: normal normal 500 14px Poppins;
  ::placeholder,
  ::-webkit-input-placeholder {
    font: normal normal normal 14px Poppins;
    color: #b5b5b5;
  }
  :-ms-input-placeholder {
    font: normal normal normal 14px Poppins;
    color: #b5b5b5;
  }
`;
const Form = styled.div`
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled.form`
  display: flex;
  flex-direction: column;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Label = styled.span`
  font: normal normal normal 12px Poppins;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const Input = styled.input`
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  border: 1px solid rgb(204, 204, 204);
  font: normal normal 500 14px Poppins;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal normal 14px Poppins;
    color: #b5b5b5;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const segment = [
  "Highly Engaged",
  "Inactive",
  "Trial Users",
  "Visited Yesterday",
];
const sms = ["Twilio", "Plivo", "Textlocal", "Gupshup"];
const template = ["Welcome", "Offer_365"];
const SmsCampaign = (props) => {
  const [value, setValue] = useState();
  const [openSmsModal, setOpenSmsModal] = useState(false);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState(false);
  const [selectSegment, setSelectSegment] = useState("");
  const [selectSms, setSelectSms] = useState("");
  const [selectTemplate, setSelectTemplate] = useState("");

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const handleSegment = (e) => {
    setSelectSegment(e.target.value);
  };
  const handleSms = (e) => {
    setSelectSms(e.target.value);
  };
  const handleTemplate = (e) => {
    setSelectTemplate(e.target.value);
  };
  return (
    <>
      <Wrapper>
        <Container>
          <Typography
            variant="h4"
            component="h2"
            style={{ marginBottom: "12px" }}
          >
            Create SMS Campaign
          </Typography>
          <Form>
            <InputContainer>
              <InputWrapper>
                <Typography variant="subtitle1" component="h2">
                  Campaign Name
                </Typography>
                <Input
                  type="text"
                  placeholder="enter campaign name"
                  name="name"
                  onChange={handleChange}
                />
              </InputWrapper>
              <InputWrapper>
                <Row>
                  <Typography variant="subtitle1" component="h2">
                    Target Segment
                  </Typography>
                  <Button
                    onClick={() => {
                      setType(true);
                      setOpen(true);
                    }}
                    style={{
                      fontSize: "10px",
                      height: "20px",
                      width: "160px",
                      marginBottom: "10px",
                      textTransform: "capitalize",
                    }}
                    variant="contained"
                    color="primary"
                  >
                    + Create New Segment
                  </Button>
                </Row>
                <Select
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  label="Select Member"
                  onChange={handleSegment}
                  style={{
                    marginBottom: "20px",
                    width: "100%",
                    height: "40px",
                    backgroundColor: "white",
                    padding: "4px 12px",
                  }}
                >
                  {segment.map((e) => {
                    return (
                      <MenuItem id={e} value={e}>
                        {e}
                      </MenuItem>
                    );
                  })}
                </Select>
              </InputWrapper>
              <InputWrapper>
                <Row>
                  <Typography variant="subtitle1" component="h2">
                    Select SMS Provider
                  </Typography>
                  <Button
                    style={{
                      fontSize: "10px",
                      height: "20px",
                      width: "160px",
                      marginBottom: "10px",
                      textTransform: "capitalize",
                    }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setOpenSmsModal(true);
                    }}
                  >
                    + Add Provider
                  </Button>
                </Row>

                <Select
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  label="Select Member"
                  onChange={handleSms}
                  style={{
                    marginBottom: "20px",
                    width: "100%",
                    height: "40px",
                    backgroundColor: "white",
                    padding: "4px 12px",
                  }}
                >
                  {sms.map((e) => {
                    return (
                      <MenuItem id={e} value={e}>
                        {e}
                      </MenuItem>
                    );
                  })}
                </Select>
              </InputWrapper>
              <InputWrapper>
                <Row>
                  <Typography variant="subtitle1" component="h2">
                    Select Message Template
                  </Typography>
                  <Button
                    onClick={() => {
                      setOpenTemplateModal(true);
                    }}
                    style={{
                      fontSize: "10px",
                      height: "20px",
                      width: "160px",
                      marginBottom: "10px",
                      textTransform: "capitalize",
                    }}
                    variant="contained"
                    color="primary"
                  >
                    + Add Template
                  </Button>
                </Row>
                <Select
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  label="Select Member"
                  onChange={handleTemplate}
                  style={{
                    marginBottom: "20px",
                    width: "100%",
                    height: "40px",
                    backgroundColor: "white",
                    padding: "4px 12px",
                  }}
                >
                  {template.map((e) => {
                    return (
                      <MenuItem id={e} value={e}>
                        {e}
                      </MenuItem>
                    );
                  })}
                </Select>
              </InputWrapper>
              <InputWrapper>
                <Typography variant="subtitle1" component="h2">
                  Message
                </Typography>
                <TextArea
                  type="text"
                  placeholder="enter your message"
                  name="message"
                  onChange={handleChange}
                />
              </InputWrapper>
            </InputContainer>
            <ButtonWrapper>
              <Button
                variant="contained"
                color="primary"
                style={{ width: "160px" }}
              >
                Send
              </Button>
            </ButtonWrapper>
          </Form>
        </Container>
      </Wrapper>
      <Footer />
      {openSmsModal && (
        <SmsProviderModal open={openSmsModal} setOpen={setOpenSmsModal} />
      )}
      {openTemplateModal && (
        <MessageTemplateModal
          open={openTemplateModal}
          setOpen={setOpenTemplateModal}
        />
      )}
      {open && (
        <MainFilterModal
          open={open}
          setOpen={setOpen}
          type={type}
          setType={setType}
        />
      )}
    </>
  );
};

export default SmsCampaign;
