import React, { useState, useEffect } from "react";
import { AppBar, Box, Button, Grid, Modal, Toolbar } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import {
  FormControl,
  Typography,
  OutlinedInput,
  Divider,
  CircularProgress,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { getLocalizedText } from "../../Functions";
import { sendAdminAccountWhatsAppBusinessApi } from "../../Api";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  max-height: 90vh;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow-y: auto;
`;
const dialogWidth = 600;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
  overflow-y: auto;
  row-gap: 10px;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const Label = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 16px;
  flex-direction: column;
  row-gap: 20px;
`;
const Message = styled(Typography)`
  font: normal normal normal 16px Open Sans;
  color: green;
  margin: 0px;
  text-align: center;
`;

const SendDialogComponent = ({ open, setOpen, templateData, phoneNumber }) => {
  const [loading, setLoading] = useState(true);
  const [numberOfBodyFields, setNumberOfBodyFields] = useState(0);
  const [details, setDetails] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSend = async (e) => {
    e.preventDefault();
    const bodyPayload = details.map((text) => ({
      type: "text",
      text: text,
    }));

    const incoming_components = [
      // {
      //   type: "header",
      //   parameters: [
      //     { type: "image", image: { link: "https://example.com/image.jpg" } },
      //   ],
      // },
      {
        type: "body",
        parameters: bodyPayload,
      },
      // {
      //   type: "button",
      //   sub_type: "quick_reply",
      //   index: "0",
      //   parameters: [{ type: "payload", payload: "ORDER_CONFIRMATION" }],
      // },
    ];

    const payload = {
      component_parameters: incoming_components,
    };

    const response = await sendAdminAccountWhatsAppBusinessApi({
      templateID: templateData["id"],
      payload: payload,
    });

    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setIsSubmitSuccess(true);
    }
  };

  const init = async () => {
    const bodyData = templateData["components"].find(
      (x) => x.type.toLowerCase() === "body"
    );
    if (
      bodyData.hasOwnProperty("example") &&
      bodyData["example"].hasOwnProperty("body_text")
    ) {
      setNumberOfBodyFields(bodyData["example"]["body_text"][0].length);
    }
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        {!loading ? (
          !isSubmitSuccess ? (
            <ModelContainer>
              <Box component={"form"} onSubmit={handleSend}>
                <AppBar
                  component={"nav"}
                  elevation={0}
                  position="sticky"
                  color="inherit"
                  sx={{
                    top: 0,
                    bottom: "auto",
                    width: "100%",
                    right: 0,
                  }}
                >
                  <Toolbar
                    style={{
                      padding: "16px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <HeaderWrapper>
                        <Header>Send</Header>
                      </HeaderWrapper>
                    </Box>
                  </Toolbar>
                </AppBar>
                <Divider style={{ margin: "0px 16px" }} />
                <Container>
                  <InputWrapper>
                    <Label>Phone Number</Label>
                    <FormControl sx={{ width: "100%" }}>
                      <OutlinedInput
                        name="phone_number"
                        readOnly
                        style={{ height: "30px" }}
                        inputProps={{
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        value={phoneNumber}
                      />
                    </FormControl>
                  </InputWrapper>
                  {Array.from({ length: numberOfBodyFields }).map(
                    (_, index) => (
                      <InputWrapper key={index}>
                        <Label>
                          {"{{"}
                          {index + 1}
                          {"}}"}
                        </Label>
                        <FormControl sx={{ width: "100%" }}>
                          <OutlinedInput
                            required
                            name={`body_text_${index}`}
                            onChange={(e) => {
                              const { value } = e.target;
                              setDetails((prevDetails) => {
                                const newDetails = [...prevDetails];
                                newDetails[index] = value;
                                return newDetails;
                              });
                            }}
                            multiline
                            rows={2}
                            value={details[index] || ""}
                            inputProps={{
                              "aria-label": `body text ${index + 1}`,
                              style: { fontSize: "12px" },
                            }}
                            sx={{ height: "auto" }}
                          />
                        </FormControl>
                      </InputWrapper>
                    )
                  )}
                </Container>
                <Toolbar
                  style={{
                    padding: "16px",
                    width: dialogWidth,
                    position: "relative",
                  }}
                >
                  {isSubmitFail && (
                    <ErrorAlert
                      onClose={(e) => {
                        e.stopPropagation();
                        setIsSubmitFail(false);
                      }}
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      {errorMessage}
                    </ErrorAlert>
                  )}
                  <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item>
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        color="default"
                        style={{
                          fontSize: "12px",
                          textTransform: "none",
                          fontWeight: "bold",
                        }}
                      >
                        {getLocalizedText("cancel")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          width: "fit-content",
                          textTransform: "none",
                        }}
                      >
                        Send
                      </Button>
                    </Grid>
                  </Grid>
                </Toolbar>
              </Box>
            </ModelContainer>
          ) : (
            <ModelContainer
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MessageWrapper style={{ marginBottom: "0px", padding: "16px" }}>
                <Message>Sent successfully!</Message>

                <Button
                  onClick={handleClose}
                  type="button"
                  variant="contained"
                  color="default"
                  style={{
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "100px",
                  }}
                >
                  {getLocalizedText("close")}
                </Button>
              </MessageWrapper>
            </ModelContainer>
          )
        ) : (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </>
    </Modal>
  );
};

export default SendDialogComponent;
