import React from "react";
import { styled } from "@mui/system";
import { Typography, Box } from "@mui/material";
import book_demo_bg from "../../../../assets/book_demo_bg.jpg";
import logo_placeholder from "../../../../assets/logo_placeholder.png";

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${book_demo_bg})`,
  backgroundSize: "cover",
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat",
  [theme.breakpoints.down("md")]: {
    minHeight: "calc(100vh - 484px)",
    width: "100%",
  },
  width: "50%",
  position: "relative",
}));
const BgLayer = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: "rgba(0, 0, 0, 0.6)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  textAlign: "center",
  gap: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    gap: theme.spacing(2),
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  component: "h1",
  width: "80%",

  color: "#ffffff",

  [[theme.breakpoints.between("xs", "sm")]]: {
    font: "normal normal 600 18px open sans",
  },
  [[theme.breakpoints.between("sm", "md")]]: {
    font: "normal normal 600 22px open sans",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    font: "normal normal 600 24px open sans",
  },
  [theme.breakpoints.up("lg")]: {
    font: "normal normal 600 36px open sans",
  },
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  paddingTop: 0,
  component: "span",
  color: "#ffffff",
  width: "80%",
  [[theme.breakpoints.between("xs", "sm")]]: {
    font: "normal normal 500 10px open sans",
    component: "span",
  },
  [[theme.breakpoints.between("sm", "md")]]: {
    font: "normal normal 500 12px open sans",
    component: "span",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    font: "normal normal 500 14px open sans",
    component: "span",
  },
  [theme.breakpoints.up("lg")]: {
    font: "normal normal 500 16px open sans",
    component: "span",
  },
}));
const Logo = styled("img")(({ theme }) => ({
  maxHeight: "28px",
  width: "auto",
  position: "absolute",
  top: theme.spacing(2),
  left: theme.spacing(2),
  [theme.breakpoints.down("lg")]: {
    maxWidth: "50%",
  },
}));

const LeftSection = ({ contentState, logoURL }) => {
  return (
    <Wrapper>
      <BgLayer>
        <Logo src={logoURL !== "" ? logoURL : logo_placeholder} alt="logo" />
        <Title>{contentState.title}</Title>
        <SubTitle>{contentState.sub_title}</SubTitle>
      </BgLayer>
    </Wrapper>
  );
};

export default LeftSection;
