import React from "react";
import { StyleSheet, View, Text, Font } from "@react-pdf/renderer";
import { dataRenderer } from "../../../../Functions";

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/poppins/v21/pxiEyp8kv8JHgFVrFJA.ttf",
      fontWeight: 400,
      fontStyle: "normal",
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLGT9V1s.ttf",
      fontWeight: 500,
      fontStyle: "normal",
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLCz7V1s.ttf",
      fontWeight: 700,
      fontStyle: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  container: {},
  row: {
    flexDirection: "row",
    display: "flex",
    borderTop: "1px solid #D3D3D3",
    borderBottom: "1px solid #D3D3D3",
    paddingLeft: "16px",
  },
  columnWrapper: {
    flexDirection: "column",
    display: "flex",
  },
  rowWrapper: {
    flexDirection: "row",
    display: "flex",
  },
  headerCell: {
    textAlign: "left",
    padding: "4px 8px",
  },
  firstCell: {
    textAlign: "left",
    width: "5%",
    padding: "4px 0px",
  },
  dataText: {
    fontSize: 8,
    fontWeight: 400,
    color: "black",
    fontFamily: "Poppins",
    textAlign: "right",
  },
  horizontalDivider: {
    height: "1px",
    width: "100%",
    backgroundColor: "#D3D3D3",
  },
  verticalDivider: {
    width: "1px",
    backgroundColor: "#D3D3D3",
    alignSelf: "stretch",
  },
});
const TableRow = ({ data, index, showDiscountCol }) => {
  const getValidValue = (value) => {
    if (value !== undefined && value !== null && value !== "") {
      return value;
    }
    return "";
  };
  const getAmount = (percentage) => {
    let tempPercentage = getValidValue(percentage);
    let actualAmount = data["rate"] * data["quantity"];
    let tempAmount = getValidValue(actualAmount);
    if (tempPercentage !== "" && tempAmount !== "") {
      return ((tempAmount * tempPercentage) / 100).toFixed(2);
    }
    return "-";
  };
  return (
    <View style={styles.container} wrap={false}>
      <View style={styles.row}>
        <View style={styles.firstCell}>
          <Text style={{ ...styles.dataText, textAlign: "left" }}>
            {index + 1}
          </Text>
        </View>
        <View style={styles.verticalDivider} />
        <View style={{ ...styles.headerCell, width: "30%" }}>
          <Text style={{ ...styles.dataText, textAlign: "left" }}>
            {dataRenderer(data["name"])}
          </Text>
          {data.hasOwnProperty("desc") &&
            data["desc"] !== null &&
            data["desc"] !== "" && (
              <Text
                style={{ ...styles.dataText, textAlign: "left", marginTop: 4 }}
              >
                {data["desc"]}
              </Text>
            )}
        </View>
        <View style={styles.verticalDivider} />
        <View style={{ ...styles.headerCell, width: showDiscountCol ? "10%" : "15%"  }}>
          <Text style={styles.dataText}>{dataRenderer(data["rate"])}</Text>
        </View>
        <View style={styles.verticalDivider} />

        <View style={{ ...styles.headerCell, width: showDiscountCol ? "5%" : "10%" }}>
          <Text style={styles.dataText}>{dataRenderer(data["quantity"])}</Text>
        </View>
        <View style={styles.verticalDivider} />

        <View
          style={{
            ...styles.headerCell,
            width: showDiscountCol ? "20%" : "25%",
            padding: "0px",
            textAlign: "center",
            height: "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Text
            style={{ ...styles.dataText, width: "50%", padding: "4px 8px" }}
          >
            {dataRenderer(data["tax"])}
          </Text>
          <View style={styles.verticalDivider} />
          <Text
            style={{ ...styles.dataText, width: "50%", padding: "4px 8px" }}
          >
            {getAmount(data["tax"])}
          </Text>
        </View>
        <View style={styles.verticalDivider} />

        {showDiscountCol && (
          <>
            <View
              style={{
                ...styles.headerCell,
                width: "20%",
                padding: "0px",
                textAlign: "center",
                height: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Text
                style={{ ...styles.dataText, width: "50%", padding: "4px 8px" }}
              >
                {dataRenderer(data["discount"])}
              </Text>
              <View style={styles.verticalDivider} />
              <Text
                style={{ ...styles.dataText, width: "50%", padding: "4px 8px" }}
              >
                {getAmount(data["discount"])}
              </Text>
            </View>
            <View style={styles.verticalDivider} />
          </>
        )}

        <View style={{ ...styles.headerCell, width: showDiscountCol ? "10%" : "15%" }}>
          <Text style={styles.dataText}>{dataRenderer(data["amount"])}</Text>
        </View>
      </View>
    </View>
  );
};

export default TableRow;
