import { AppBar, Box, Button, Grid, Modal, Toolbar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { getLocalizedText } from "../../../Functions";
import { Typography } from "@mui/material";
import { myTheme } from "../../../themeUtils";
import MuiAlert from "@mui/material/Alert";
import ProjectSelectionScreen from "./column_mapping_screens/ProjectSelectionScreen";
import ColumnMappingScreen from "./column_mapping_screens/ColumnMappingScreen";
import CustomConfirmationDialog from "../../alert_dialogue/CustomConfirmationDialog";
import { getAdminSystemUsersApi, saveFacebookPageFormApi } from "../../../Api";
import DefaultParameterScreen from "./column_mapping_screens/DefaultParameterScreen";
const dialogWidth = 600;
const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 16px;
  flex-direction: column;
  row-gap: 20px;
`;
const Message = styled(Typography)`
  font: normal normal normal 16px Open Sans;
  color: green;
  margin: 0px;
  text-align: center;
`;

const ColumnMappingDialog = ({
  open,
  setOpen,
  setRefresh,
  formData,
  pageID,
}) => {
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [projectColumnList, setProjectColumnList] = useState([]);
  const [formQuestionList, setFormQuestionsList] = useState([]);
  const [formAndProjectColumnMapping, setFormAndProjectColumnMapping] =
    useState({});
  const [selectedProjectID, setSelectedProjectID] = useState("");
  const [formStep, setFormStep] = useState(0);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [formQuestionData, setFormQuestionData] = useState({});
  const [selectedDefaultParameterMapping, setSelectedDefaultParameterMapping] =
    useState({
      lead_owner_id: "",
      lead_status_id: "",
      lead_source_id: "",
      lead_stage_id: "",
      team_id: "",
    });

  const handleSave = async () => {
    setShowConfirmationDialog(false);
    let payload = {
      form_id: formData["id"],
      page_id: pageID,
      form_name: formData["name"],
      destination_project_id: selectedProjectID,
      form_schema: formQuestionData,
    };
    const systemUserData = await getAdminSystemUsersApi();
    payload["default_params"] = {
      ...selectedDefaultParameterMapping,
      lead_creator_id: systemUserData.hasOwnProperty("id")
        ? systemUserData["id"]
        : "",
    };
    let tempList = {};
    Object.keys(formAndProjectColumnMapping).forEach((key) => {
      if(formAndProjectColumnMapping[key]["checked"]){
        tempList[key] = formAndProjectColumnMapping[key]["project_lead"]["id"];
      }
    });
    payload["mapping"] = tempList;
    console.log(JSON.stringify(payload));

    let response = await saveFacebookPageFormApi({
      pageID: pageID,
      formID: formData["id"],
      payload: payload,
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setIsSubmitFail(false);
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };

  const isAtleastOneColumnMapped = () => {
    let flag = false;
    Object.keys(formAndProjectColumnMapping).forEach((item) => {
      if (formAndProjectColumnMapping[item]["checked"] === true) {
        flag = true;
      }
    });
    return flag;
  };

  //below function is to check if all the required field of the selected project are mapped or not
  const allProjectRequiredColumnsFilled = () => {
    let required_columns_list = projectColumnList.filter(
      (column) => column.required
    );
    const checkedCurrToDestProjectList = Object.keys(
      formAndProjectColumnMapping
    )
      .filter((key) => formAndProjectColumnMapping[key].checked === true)
      .reduce((result, key) => {
        result[key] = formAndProjectColumnMapping[key];
        return result;
      }, {});

    let flag = required_columns_list.every((item) => {
      return Object.values(checkedCurrToDestProjectList).some(
        (obj) => obj["project_lead"]["id"] === item.id
      );
    });

    return flag;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formStep === 2) {
      if (isAtleastOneColumnMapped()) {
        setIsSubmitFail(false);
        if (allProjectRequiredColumnsFilled()) {
          setIsSubmitSuccess(false);
          setShowConfirmationDialog(true);
        } else {
          setErrorMessage("All required fields are not mapped.");
          setIsSubmitFail(true);
        }
      } else {
        setErrorMessage(
          getLocalizedText("please_map_atleast_one_column_to_proceed")
        );
        setIsSubmitFail(true);
      }
    } else {
      setFormStep(formStep + 1);
      setIsSubmitFail(false);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const RenderButton = () => {
    if (formStep === 0) {
      return (
        <>
          <Grid item>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              variant="contained"
              color="default"
              style={{
                fontSize: "12px",
                textTransform: "none",
                fontWeight: "bold",
              }}
            >
              {getLocalizedText("cancel")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                width: "fit-content",
                textTransform: "none",
              }}
            >
              Next
            </Button>
          </Grid>
        </>
      );
    } else if (formStep === 1) {
      return (
        <>
          <Grid item>
            <Button
              onClick={() => {
                setFormStep(formStep - 1);
              }}
              variant="contained"
              color="default"
              style={{
                fontSize: "12px",
                textTransform: "none",
                fontWeight: "bold",
              }}
            >
              Prev
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                width: "fit-content",
                textTransform: "none",
              }}
            >
              Next
            </Button>
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Grid item>
            <Button
              onClick={() => {
                setFormStep(formStep - 1);
              }}
              variant="contained"
              color="default"
              style={{
                fontSize: "12px",
                textTransform: "none",
                fontWeight: "bold",
              }}
            >
              Prev
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                width: "fit-content",
                textTransform: "none",
                ...myTheme.Button.btnBlue,
              }}
            >
              Save
            </Button>
          </Grid>
        </>
      );
    }
  };

  const init = async () => {
    if (formData["is_connected"] === true) {
      setSelectedProjectID(formData["destination_project"]["id"]);
      let tempObj = {
        lead_owner_id: "",
        lead_status_id: "",
        lead_source_id: "",
        lead_stage_id: "",
        team_id: "",
      };

      if (
        formData.hasOwnProperty("default_params") &&
        formData["default_params"] !== null
      ) {
        tempObj["lead_owner_id"] =
          formData["default_params"].hasOwnProperty("lead_owner_id") &&
          formData["default_params"]["lead_owner_id"] !== null
            ? formData["default_params"]["lead_owner_id"]
            : "";

        tempObj["team_id"] =
          formData["default_params"].hasOwnProperty("team_id") &&
          formData["default_params"]["team_id"] !== null
            ? formData["default_params"]["team_id"]
            : "";

        tempObj["lead_status_id"] =
          formData["default_params"].hasOwnProperty("lead_status_id") &&
          formData["default_params"]["lead_status_id"] !== null
            ? formData["default_params"]["lead_status_id"]
            : "";

        tempObj["lead_source_id"] =
          formData["default_params"].hasOwnProperty("lead_source_id") &&
          formData["default_params"]["lead_source_id"] !== null
            ? formData["default_params"]["lead_source_id"]
            : "";
        tempObj["lead_stage_id"] =
          formData["default_params"].hasOwnProperty("lead_stage_id") &&
          formData["default_params"]["lead_stage_id"] !== null
            ? formData["default_params"]["lead_stage_id"]
            : "";
      }
      setSelectedDefaultParameterMapping(tempObj);
    }
  };
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!isSubmitSuccess ? (
          <ModelContainer>
            <Box component={"form"} onSubmit={handleSubmit}>
              {(() => {
                if (formStep === 0) {
                  return (
                    <ProjectSelectionScreen
                      type={"fb"}
                      selectedProjectID={selectedProjectID}
                      setSelectedProjectID={setSelectedProjectID}
                      setSelectedDefaultParameterMapping={
                        setSelectedDefaultParameterMapping
                      }
                    />
                  );
                } else if (formStep === 1) {
                  return (
                    <DefaultParameterScreen
                      formData={formData}
                      selectedDefaultParameterMapping={
                        selectedDefaultParameterMapping
                      }
                      setSelectedDefaultParameterMapping={
                        setSelectedDefaultParameterMapping
                      }
                      selectedProjectID={selectedProjectID}
                    />
                  );
                } else {
                  return (
                    <ColumnMappingScreen
                      formAndProjectColumnMapping={formAndProjectColumnMapping}
                      formData={formData}
                      pageID={pageID}
                      formQuestionList={formQuestionList}
                      projectColumnList={projectColumnList}
                      projectID={selectedProjectID}
                      setFormAndProjectColumnMapping={
                        setFormAndProjectColumnMapping
                      }
                      setFormQuestionsList={setFormQuestionsList}
                      setProjectColumnList={setProjectColumnList}
                      setFormQuestionData={setFormQuestionData}
                    />
                  );
                }
              })()}

              <AppBar
                position="static"
                color="inherit"
                elevation={0}
                sx={{ top: "auto", bottom: 0, width: "100%" }}
              >
                <Toolbar
                  style={{
                    padding: "16px",
                    width: dialogWidth,
                    position: "relative",
                  }}
                >
                  {isSubmitFail && (
                    <ErrorAlert
                      onClose={(e) => {
                        e.stopPropagation();
                        setIsSubmitFail(false);
                      }}
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      {errorMessage}
                    </ErrorAlert>
                  )}
                  <Grid container spacing={2} justifyContent="flex-end">
                    <RenderButton />
                  </Grid>
                </Toolbar>
              </AppBar>
            </Box>
          </ModelContainer>
        ) : (
          <ModelContainer
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MessageWrapper style={{ marginBottom: "0px", padding: "16px" }}>
              <Message>Saved successfully!</Message>

              <Button
                onClick={handleClose}
                type="button"
                variant="contained"
                color="default"
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "100px",
                }}
              >
                {getLocalizedText("close")}
              </Button>
            </MessageWrapper>
          </ModelContainer>
        )}
      </Modal>
      {showConfirmationDialog && (
        <CustomConfirmationDialog
          handleConfirm={() => {
            handleSave();
          }}
          open={showConfirmationDialog}
          setOpen={setShowConfirmationDialog}
          message={"Are you sure you want to save the form changes?"}
        />
      )}
    </>
  );
};

export default ColumnMappingDialog;
