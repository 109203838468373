import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import TopSection from "./components/TopSection";
import AppBar from "./components/AppBar";
import FooterComponent from "./components/FooterComponent";

const StyledContainer = styled(Box)(({ theme }) => ({
  width: "100%",
}));

const EventRegistrationTemplate = ({
  contentState,
  formState,
  primaryColor,
  secondaryColor,
  logoURL,
  gradientColor,
}) => {
  return (
    <>
      <AppBar logoURL={logoURL} />
      <StyledContainer disableGutters fixed maxWidth="false">
        <TopSection
          gradientColor={gradientColor}
          contentState={contentState}
          formState={formState}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
        />
        <FooterComponent contentState={contentState} />
      </StyledContainer>
    </>
  );
};

export default EventRegistrationTemplate;
