import { Box } from "@mui/material";
import React from "react";
import { MultiSelect } from "react-multi-select-component";

const MultiSelectField = ({
  value,
  list,
  disabled,
  handleChange,
  height = "30px",
  fontSize = "12px",
  optionMaxHeight = "100px",
}) => {
  return (
    <Box
      sx={{
        "&": {
          width: "100%",
          fontSize: fontSize,
          border: "none",
          alignItems: "center",
          color: "black",
        },
        "& .dropdown-heading": {
          height: height,
        },
        "& .options": {
          maxHeight: optionMaxHeight,
        },
        "& .dropdown-content": {
          top: "unset",
          bottom: "100%",
          paddingBottom: "8px",
        },
      }}
    >
      <MultiSelect
        disabled={disabled}
        options={list}
        value={value}
        onChange={(selected) => {
          handleChange(selected);
        }}
      />
    </Box>
  );
};

export default MultiSelectField;
