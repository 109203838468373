import { TextField } from "@mui/material";

import React from "react";

const TextFieldWidget = ({ handleChange, value, regex, disabled }) => {
  return (
    <TextField
      inputProps={{
        pattern: regex,
      }}
      disabled={disabled}
      variant="standard"
      placeholder="Your answer"
      style={{ width: "50%" }}
      value={value}
      onChange={(event) => handleChange(event)}
    />
  );
};

export default TextFieldWidget;
