import { Box, Modal, styled } from "@mui/material";
import { FullscreenExit } from "@material-ui/icons";
import { useEffect, useRef, useState } from "react";
import { Typography } from "@mui/material";
import TableComponent from "../chart_components/dashboard_chart_v2/tabular_widget/TableComponent";
import { getTableSize } from "../../Functions";
import { defaultTableWidgetSize } from "../../Config";

const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 20px;
`;

const ModelWrapper = styled(Box)`
  width: 90vw;
  margin: auto;
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
`;

const Top = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Text = styled(Typography)`
  font: normal normal 600 16px Open Sans;
  margin: 0px;
  text-align: left;
  color: #4d4e4f;
`;

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const TableWidgetFullScreenModal = ({
  open,
  setOpen,
  title,
  columns,
  tableData,
}) => {
  const [tableSize, setTableSize] = useState(defaultTableWidgetSize);
  const componentRef = useRef(null);

  const handleClose = () => {
    setOpen(false);
  };

  const getContainerHeight = () => {
    if (componentRef.current) {
      const height = componentRef.current.getBoundingClientRect().height;
      return height;
    }
    return 0;
  };

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        if (componentRef.current) {
          const tempContainerHeight = getContainerHeight();
          const tempTableSize = getTableSize({ containerHeight: tempContainerHeight });
          setTableSize(tempTableSize);
        }
      }, 0);
      return () => clearTimeout(timer);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModelContainer>
        <Box>
          <Top>
            <Text>{title}</Text>
            <FullscreenExit
              onClick={handleClose}
              style={{ height: "28px", width: "28px", cursor: "pointer" }}
            />
          </Top>
          <ModelWrapper ref={componentRef}>
            {tableData.length > 0 ? (
              <Wrapper>
                <TableComponent
                  row={tableData}
                  columns={columns}
                  pageSize={tableSize}
                  type={"fullscreen_mode"}
                />
              </Wrapper>
            ) : (
              <Wrapper>
                <Typography>No data found!</Typography>
              </Wrapper>
            )}
          </ModelWrapper>
        </Box>
      </ModelContainer>
    </Modal>
  );
};

export default TableWidgetFullScreenModal;
