import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { Info } from "@mui/icons-material";
import {
  dataRenderer,
  dateTimeHandler,
  getLocalizedText,
} from "../../Functions";
import { getImportLogUrlApi, refreshImportRowApi } from "../../Api";
import { MoreHoriz } from "@material-ui/icons";
import { ImportHistoryColumns } from "../../Db";
import analytics from "../../Analytics";

const ModelWrapper = styled(Box)(({ theme }) => ({
  width: "500px",
  margin: "auto",
  display: "flex",
  position: "relative",
  flexDirection: "column",
  height: "100vh",
  paddingTop: "0px",
}));
const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  padding: "30px 16px 30px 16px",
  marginBottom: "20px",
  backgroundColor: "#f9f9fc",
  justifyContent: "space-between",
  alignItems: "center",
}));

const ModelHeader = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 18px Open Sans",
  variant: "span",
  color: "#4d4e4f",
}));

const Title = styled(Typography)(({ theme }) => ({
  font: "normal normal 400 14px Open Sans",
  color: "#5A606680",
}));
const Desc = styled(Typography)(({ theme }) => ({
  font: "normal normal 500 14px Open Sans",
  color: "#5A6066",
  wordWrap: "break-word",
}));

const Column = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  overflowX: "auto",
  padding: "0px 16px 0px 16px",
}));

const IconTextWrapper = styled(Box)`
  display: flex;
  align-items: center;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

const ImportDetailsSidePanel = (props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = React.useState({});
  const getImportDetails = async () => {
    try {
      let response = await refreshImportRowApi({ id: props.id });
      setData(response.data);
    } catch (error) {
      console.log(error);
      setData({});
    }
  };
  const setup = async () => {
    await getImportDetails();
    setLoading(false);
  };
  React.useEffect(() => {
    analytics.triggerEvent(
      4625000115,
      "import_details_form_load",
      "imports_page",
      "",
      {}
    );
    setup();
  }, []);
  const handleDownloadLogFile = async (scope) => {
    let response = await getImportLogUrlApi({ id: props.id, scope: scope });
    if (response.url !== "") {
      window.open(response.url, "_blank");
    }
  };

  function snakeCaseToTitleCase(snakeCaseStr) {
    return snakeCaseStr
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const getImportTypeNameString = (importType) => {
    if (
      importType.toLowerCase() === "create" ||
      importType.toLowerCase() === "create_leads_v1"
    ) {
      return "Create";
    } else if (importType.toLowerCase() === "update" || "update_leads_v1") {
      return "Update";
    }
    return "-";
  };
  return (
    <>
      <Box role="presentation">
        {!loading ? (
          <ModelWrapper>
            <HeaderWrapper>
              <IconTextWrapper>
                <Info color="primary" style={{ marginRight: "8px" }} />
                <ModelHeader>{getLocalizedText("import_details")}</ModelHeader>
              </IconTextWrapper>
              {/* <ButtonWrapper>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  Show Leads
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  style={{
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  Create Delete Job
                </Button>
              </ButtonWrapper> */}
            </HeaderWrapper>
            <Column>
              <Box>
                <Box
                  style={{
                    marginTop: "8px",
                    marginBottom: "8px",
                  }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={4}>
                      <Title>{getLocalizedText("import_id")}</Title>
                    </Grid>
                    <Grid item xs={1}>
                      <Divider
                        orientation="vertical"
                        sx={{ height: "20px", color: "#3535354D" }}
                      />
                    </Grid>
                    <Grid item xs={7} paddingLeft={"8px"}>
                      <Desc>{dataRenderer(data["id"])}</Desc>
                    </Grid>
                  </Grid>
                </Box>
                <Divider orientation="horizontal" />
              </Box>
              {ImportHistoryColumns.map((key, i) => {
                if (
                  key["field"] === "original_file" ||
                  key["field"] === "error_log" ||
                  key["field"] === "full_log"
                ) {
                  return (
                    <Box key={i}>
                      <Box
                        style={{
                          marginTop: "8px",
                          marginBottom: "8px",
                        }}
                      >
                        <Grid container alignItems="center">
                          <Grid item xs={4}>
                            <Title>{snakeCaseToTitleCase(key["field"])}</Title>
                          </Grid>
                          <Grid item xs={1}>
                            <Divider
                              orientation="vertical"
                              sx={{ height: "20px", color: "#3535354D" }}
                            />
                          </Grid>
                          <Grid item xs={7} paddingLeft={"8px"}>
                            <Desc>
                              {" "}
                              <Tooltip title="Download">
                                <MoreHoriz
                                  style={{ cursor: "pointer" }}
                                  color="primary"
                                  onClick={() => {
                                    analytics.triggerEvent(
                                      4625000116,
                                      "download_file_log_button_tap",
                                      "import_details_form",
                                      "download_file_log_button",
                                      {}
                                    );
                                    handleDownloadLogFile(key["field"]);
                                  }}
                                />
                              </Tooltip>
                            </Desc>
                          </Grid>
                        </Grid>
                      </Box>
                      <Divider orientation="horizontal" />
                    </Box>
                  );
                } else if (
                  key["field"] === "start_time" ||
                  key["field"] === "end_time"
                ) {
                  let temp_time;
                  if (data[key["field"]] !== null) {
                    temp_time = dateTimeHandler(data[key["field"]]);
                  }
                  return (
                    <Box key={i}>
                      <Box
                        style={{
                          marginTop: "8px",
                          marginBottom: "8px",
                        }}
                      >
                        <Grid container alignItems="center">
                          <Grid item xs={4}>
                            <Title>{snakeCaseToTitleCase(key["field"])}</Title>
                          </Grid>
                          <Grid item xs={1}>
                            <Divider
                              orientation="vertical"
                              sx={{ height: "20px", color: "#3535354D" }}
                            />
                          </Grid>
                          <Grid item xs={7} paddingLeft={"8px"}>
                            <Desc>
                              {" "}
                              {data[key["field"]] !== null ? temp_time : "-"}
                            </Desc>
                          </Grid>
                        </Grid>
                      </Box>
                      <Divider orientation="horizontal" />
                    </Box>
                  );
                } else {
                  return (
                    <Box key={i}>
                      <Box
                        style={{
                          marginTop: "8px",
                          marginBottom: "8px",
                        }}
                      >
                        <Grid container alignItems="center">
                          <Grid item xs={4}>
                            <Title>{snakeCaseToTitleCase(key["field"])}</Title>
                          </Grid>
                          <Grid item xs={1}>
                            <Divider
                              orientation="vertical"
                              sx={{ height: "20px", color: "#3535354D" }}
                            />
                          </Grid>
                          <Grid item xs={7} paddingLeft={"8px"}>
                            {key["field"] === "import_type" ? (
                              data[key["field"]] !== null ? (
                                <Desc>
                                  {getImportTypeNameString(data[key["field"]])}
                                </Desc>
                              ) : (
                                "-"
                              )
                            ) : (
                              <Desc>{data[key["field"]]}</Desc>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                      <Divider orientation="horizontal" />
                    </Box>
                  );
                }
              })}
            </Column>
          </ModelWrapper>
        ) : (
          <ModelWrapper
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <CircularProgress />
          </ModelWrapper>
        )}
      </Box>
    </>
  );
};

export default ImportDetailsSidePanel;
