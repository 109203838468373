import React, { useEffect, useState } from "react";
import { Add } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import { getLeadNotesApi } from "../../Api";
import { Box } from "@material-ui/core";
import NoteDetailsModal from "../notes_tab_modal/NoteDetailsModal";
import analytics from "../../Analytics";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Snackbar,
  styled,
} from "@mui/material";
import NoteCardWidget from "./notes_tab_components/NoteCardWidget";
import AddNoteCardWidget from "./notes_tab_components/AddNoteCardWidget";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { StickyNote2 } from "@mui/icons-material";

const Container = styled(Box)`
  width: 100%;
  position: relative;
  ${`min-height: calc(100vh - 343px);`};
`;
const Wrapper = styled(Box)`
  display: flex;
`;

const DetailsWrapper = styled(Box)`
  height: fit-content;
  width: 100%;
  background: white;
  border-radius: 10px;
  /* padding: 12px; */
  display: flex;
  flex-direction: column;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 420px);`};
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const NotesTab = (props) => {
  const [open, setOpen] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);


  const getNotes = async () => {
    setRow([]);
    let response = await getLeadNotesApi({ leadID: props.lid });
    setRow(response);
  };

  const init = async () => {
    await getNotes();
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  const refetch = async () => {
    await getNotes();
    setRefresh(false);
  };

  useEffect(() => {
    if (refresh) {
      refetch();
    }
  }, [refresh]);

  useEffect(() => {
    analytics.triggerEvent(
      4625000055,
      "notes_tab_load",
      "lead_details_page",
      "",
      {}
    );
  }, []);

  return (
    <>
      {!loading ? (
        <Container>
          <Wrapper>
            <DetailsWrapper>
              <Accordion defaultExpanded elevation={0} expanded={open}>
                <AccordionSummary
                  aria-controls="panel3-content"
                  id="panel3-header"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    padding: "0px",
                  }}
                >
                  {!open && row.length > 0 && (
                    <Button
                      sx={{
                        textTransform: "none",
                        fontWeight: "bold",
                        margin: "auto",
                      }}
                      color="primary"
                      disabled={!props.canEditLead()}
                      onClick={() => {
                        analytics.triggerEvent(
                          4625000070,
                          "add_note_button_tap",
                          "notes_tab",
                          "add_note_button",
                          {}
                        );
                        setOpen(true);
                      }}
                    >
                      <Add />
                      Add Note
                    </Button>
                  )}
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "0px" }}>
                  {open && (
                    <AddNoteCardWidget
                      leadID={props.lid}
                      setRefresh={setRefresh}
                      setOpen={setOpen}
                      setShowSnackBar={setShowSnackBar}
                    />
                  )}
                </AccordionDetails>
              </Accordion>

              {row.length > 0 ? (
                <Box
                  sx={{
                    "& .MuiTimelineItem-root:before": {
                      flex: 0,
                      padding: "0px",
                    },
                  }}
                >
                  <Timeline position="right">
                    <TableWrapper>
                      {row.map((noteData, idx) => {
                        return (
                          <TimelineItem>
                            <TimelineSeparator>
                              <TimelineDot>
                                <StickyNote2 />
                              </TimelineDot>
                              {idx !== row.length - 1 && <TimelineConnector />}
                            </TimelineSeparator>
                            <TimelineContent>
                              <NoteCardWidget
                                details={noteData}
                                key={idx}
                                handleView={() => {
                                  setSelectedRow(noteData);
                                  setOpenView(true);
                                }}
                              />
                            </TimelineContent>
                          </TimelineItem>
                        );
                      })}
                    </TableWrapper>
                  </Timeline>
                </Box>
              ) : (
                !open && (
                  <Box
                    sx={{
                      height: "calc(100vh - 456px)",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      sx={{
                        textTransform: "none",
                        fontWeight: "bold",
                        margin: "auto",
                      }}
                      color="primary"
                      disabled={!props.canEditLead()}
                      onClick={() => {
                        analytics.triggerEvent(
                          4625000070,
                          "add_note_button_tap",
                          "notes_tab",
                          "add_note_button",
                          {}
                        );
                        setOpen(true);
                      }}
                    >
                      <Add />
                      Add Note
                    </Button>
                  </Box>
                )
              )}
            </DetailsWrapper>
          </Wrapper>
        </Container>
      ) : (
        <Container
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Container>
      )}
      {openView && (
        <NoteDetailsModal
          setOpen={setOpenView}
          open={openView}
          data={selectedRow}
        />
      )}
      <Snackbar
        open={showSnackBar}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => {
          setShowSnackBar(false);
        }}
        message="Note added successfully!"
      />
    </>
  );
};

export default observer(NotesTab);
