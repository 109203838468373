import { Form } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
import { useEffect } from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import FormAppbar from "../../components/form_builder_components/FormAppbar";
import { getWebFormDataApi } from "../../Api";
import { Box, CircularProgress, Typography } from "@material-ui/core";
const Container = styled(Box)`
  ${`height: calc(100vh - 64px);`};
  position: relative;
  width: 100%;
`;
const Message = styled(Typography)`
  font: normal normal normal 20px Open Sans;
  color: #4d4e4f;
  margin: 0px;
  text-align: center;
`;
registerCoreBlocks();
const CustomFormPage = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const getWebFormData = async () => {
    let formId = queryParams.get("formid");
    let response = await getWebFormDataApi(formId);
    setFormData(response);
    setLoading(false);
  };
  useEffect(() => {
    getWebFormData();
  }, []);
  return !loading ? (
    Object.keys(formData).length > 0 ? (
      <Container>
        <FormAppbar />
        <Form
          formId={formData.hasOwnProperty("id") ? formData["id"] : ""}
          formObj={{
            settings: {
              saveAnswersInBrowser: false,
            },
            blocks: formData.hasOwnProperty("schema") ? formData["schema"] : [],
          }}
          onSubmit={(
            data,
            { completeForm, setIsSubmitting, goToBlock, setSubmissionErr }
          ) => {
            setTimeout(() => {
              setIsSubmitting(false);
              completeForm();
            }, 500);
          }}
        />
      </Container>
    ) : (
      <Container
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Message>Something went wrong!</Message>
      </Container>
    )
  ) : (
    <Container
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Container>
  );
};

export default CustomFormPage;
