import React, { useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  makeStyles,
  Modal,
  Select,
} from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import {
  createCampaignApi,
  getCampaignProviderListApi,
  getCampaignTypeListApi,
  getSegmentsApi,
} from "../../Api";
import { useEffect } from "react";
import { getLocalizedText } from "../../Functions";
import AutoDialerCampaign from "../campaign_forms/AutoDialerCampaign";
import EmailCampaign from "../campaign_forms/EmailCampaign";
import SmsCampaign from "../campaign_forms/SmsCampaign";
import WhatsappCampaign from "../campaign_forms/WhatsappCampaign";
import analytics from "../../Analytics";
import { MoreHoriz } from "@material-ui/icons";
import moment from "moment";
import rootStore from "../../stores/RootStore";
import CampaignFilterModal from "./campaign_filter_modal/CampaignFilterModal";
import OfflineCampaign from "../campaign_forms/OfflineCampaign";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;

const Header = styled.span`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled.span`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 340px;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Label = styled.span`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Input = styled.input`
  width: 428px;
  height: 30px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;
  border: 1px solid #bfbfbf;
  font: normal normal 600 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const TextArea = styled.textarea`
  width: 428px;
  height: 60px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #bfbfbf;
  color: #4d4e4f;
  resize: none;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  width: 180px;
  @media (max-width: 700px) {
    width: 140px;
  }
`;
const SelectColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 428px;
  column-gap: 10px;
`;

function ampm(time) {
  if (time.value !== "") {
    var hours = time.split(":")[0];
    var minutes = time.split(":")[1];
    var suffix = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    var displayTime = hours + ":" + minutes + " " + suffix;
    return displayTime;
  }
  return;
}
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const autoDialerCampaignTypeList = [
  { label: "Agentwise - Equally", value: "agent_wise" },
];
const CreateCampaignModal = (props) => {
  const classes = useStyles();
  const [data, setData] = useState({
    title: "",
    desc: "",
  });
  const [selectedCampaignTypeID, setSelectedCampaignTypeID] = useState("");
  const [selectedProviderItemID, setSelectedProviderItemID] = useState("");
  const [segment, setSegment] = useState("");
  const [segmentList, setSegmentList] = useState([]);
  const [campaignTypeList, setCampaignTypeList] = useState([]);
  const [providerList, setProviderList] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const [autodialerCampaignType, setAutodialerCampaignType] = useState("");

  //below are the state variable for select filter popup
  const [selectedSegmentFilterData, setSelectedSegmentFiterData] = useState({});
  const [openFilterSelectionModal, setOpenFilterSelectionModal] =
    useState(false);
  const [dateRangeFilterList, setDateRangeFilterList] = useState(null);
  const [numRangeFilterList, setNumRangeFilterList] = useState({});
  const [selectFieldDataList, setSelectFieldDataList] = useState({});
  const [filterList, setFilterList] = useState({});
  const [segmentFilterFieldList, setSegementFilterFieldList] = useState([]); //this state is maintained to track to make the segemnt filter fields disable/readonly
  const [emailTemplateVariableList, setEmailTemplateVariableList] = useState(
    {}
  ); //this state is maintained for email template variables

  //*********************END******************************* */
  const columnsList = [...rootStore.userStore.AllColumnsList];

  //state variables for email campaign creation
  const [fromAddress, setFromAddress] = useState("");
  const [emailTemplateID, setEmailTemplateID] = useState("");

  //******************END***************** */

  const [campaignDateRange, setCampaignDateRange] = useState({
    start_date: "",
    end_date: "",
  }); //this state is created for camapign type offline

  const [stepCount, setStepCount] = useState(0);
  const [smsText, setSmsText] = useState(
    "Hello {{name}}, Nice to meet you,we have received your {{email}}"
  );
  const [variables, setVariables] = useState({});

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  function getIsoString(date) {
    const momentObj = moment(date);
    const isoString = momentObj.toISOString();
    return isoString;
  }
  const createCampaign = async () => {
    rootStore.userStore.setReportFilters({
      filters: filterList,
      dateRange: dateRangeFilterList === null ? {} : dateRangeFilterList,
      numRange: numRangeFilterList,
      selectedVal: selectFieldDataList,
    });
    let campaign_type_name = getCampaignTypeName();
    let provider_name = getProviderName();
    let payload = {
      title: data.title,
      desc: data.desc,
      campaign_type: campaign_type_name,
    };
    let filters = {
      ...selectedSegmentFilterData,
      ...rootStore.userStore.reportFilters,
    };
    if (campaign_type_name.toLowerCase() !== "offline") {
      payload["campaign_provider_id"] = selectedProviderItemID;
      payload["segment_id"] = segment;
      payload["filter"] = filters;
    }
    if (campaign_type_name.toLowerCase() === "offline") {
      let start_date =
        campaignDateRange["start_date"] !== ""
          ? getIsoString(campaignDateRange["start_date"])
          : null;
      let end_date =
        campaignDateRange["end_date"] !== ""
          ? getIsoString(campaignDateRange["end_date"])
          : null;
      payload["start_date"] = start_date;
      payload["end_date"] = end_date;
    }
    if (
      campaign_type_name.toLowerCase() === "auto dialer" &&
      provider_name.toLowerCase() === "mcube"
    ) {
      payload["input"] = {
        dialer_campaign_type: autodialerCampaignType,
        distribution: "equal",
        agent_list: agentList,
      };
    } else if (
      campaign_type_name.toLowerCase() === "email" &&
      provider_name.toLowerCase() === "sendgrid"
    ) {
      payload["input"] = {
        from_address: fromAddress,
        email_template_id: emailTemplateID,
        variables: emailTemplateVariableList,
      };
    }
    try {
      let response = await createCampaignApi(payload);
      props.setRefresh(true);
      props.setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = () => {
    if (stepCount === 0) {
      setStepCount(1);
    } else {
      createCampaign();
    }
  };

  const getSegments = async () => {
    let response = await getSegmentsApi({
      page_size: 100,
      page: 0,
    });
    if (Object.keys(response).length > 0) {
      setSegmentList(response.items);
    } else {
      setSegmentList([]);
    }
  };
  const getCampaignsTypeList = async () => {
    let response = await getCampaignTypeListApi();
    setCampaignTypeList(response);
  };
  const setup = async () => {
    await getSegments();
    await getCampaignsTypeList();
  };
  useEffect(() => {
    analytics.triggerEvent(
      4625000245,
      "create_campaign_form_load",
      "campaigns_page",
      "",
      {}
    );
    setup();
  }, []);

  const handleStep = () => {
    if (stepCount === 0) {
      setStepCount(1);
    } else {
      setStepCount(0);
    }
  };

  const getProviderList = async (campaign_type_id) => {
    let payload = { campaign_type_id: campaign_type_id };
    const response = await getCampaignProviderListApi(payload);
    setProviderList(response);
  };
  const handleCampaign = (e) => {
    setSelectedCampaignTypeID(e.target.value);
    getProviderList(e.target.value);
  };

  const getCampaignTypeName = () => {
    let index = campaignTypeList.findIndex(
      (campaign) => campaign["id"] === selectedCampaignTypeID
    );
    if (index !== -1) {
      return campaignTypeList[index]["name"];
    }
    return "";
  };

  const getProviderName = () => {
    let index = providerList.findIndex(
      (provider) => provider["id"] === selectedProviderItemID
    );
    if (index !== -1) {
      return providerList[index]["provider"]["provider_name"];
    }
    return "";
  };

  //***********below are helper functions used for filter selection modal ************************** */

  const getFieldMeta = (fieldName) => {
    let data = {};
    let index = columnsList.findIndex(
      (column) => column["field"] === fieldName
    );
    if (index !== -1) {
      data["filter_type"] = columnsList[index]["filter_type"];
      data["data_type"] = columnsList[index]["data_type"];
    }
    return data;
  };

  const initSegmentFilterData = () => {
    if (Object.keys(selectedSegmentFilterData).length > 0) {
      let filterData = selectedSegmentFilterData;
      let normalFilterValues = { ...filterList };
      let dataRangeFilterValues = { ...dateRangeFilterList };
      let numRangeFilterValues = { ...numRangeFilterList };
      let listFilterValues = { ...selectFieldDataList };
      let fieldList = [];
      let customData = selectedSegmentFilterData.hasOwnProperty("custom_params")
        ? selectedSegmentFilterData["custom_params"]
        : {};
      Object.keys(filterData).forEach((e) => {
        let field_meta = getFieldMeta(e);
        let data_type = field_meta.hasOwnProperty("data_type")
          ? field_meta["data_type"]
          : null;
        let filter_type = field_meta.hasOwnProperty("filter_type")
          ? field_meta["filter_type"]
          : null;
        let index = columnsList.findIndex((column) => column["field"] === e);
        if (index !== -1) {
          if (columnsList[index]["is_custom_param"]) {
            Object.keys(customData).forEach((item) => {
              let new_key = "custom_params_" + item;
              let field_meta = getFieldMeta(new_key);
              let data_type = field_meta.hasOwnProperty("data_type")
                ? field_meta["data_type"]
                : null;
              let filter_type = field_meta.hasOwnProperty("filter_type")
                ? field_meta["filter_type"]
                : null;
              if (filter_type === "range") {
                if (data_type === "date" || data_type === "datetime") {
                  let obj = {};
                  let d1 = new Date(customData[item]["start"]);
                  let d2 = new Date(customData[item]["end"]);
                  d1 = moment(d1.toString()).format("YYYY-MM-DD");
                  d2 = moment(d2.toString()).format("YYYY-MM-DD");
                  obj["start"] = d1;
                  obj["end"] = d2;
                  dataRangeFilterValues[new_key] = obj;
                  fieldList.push(new_key);
                } else if (data_type === "integer") {
                  let obj = {};
                  let num1 = customData[item]["start"];
                  let num2 = customData[item]["end"];
                  obj["start"] = num1;
                  obj["end"] = num2;
                  numRangeFilterValues[new_key] = obj;
                  fieldList.push(new_key);
                }
              } else if (filter_type === "list") {
                listFilterValues[new_key] = customData[item];
                fieldList.push(new_key);
              } else {
                if (data_type === "date" || data_type === "datetime") {
                  let d = new Date(customData[item]);
                  d = moment(d.toString()).format("YYYY-MM-DD");
                  normalFilterValues[new_key] = {
                    value: d,
                    type: data_type,
                  };
                  fieldList.push(new_key);
                } else {
                  normalFilterValues[new_key] = {
                    value: customData[item],
                    type: data_type,
                  };
                  fieldList.push(new_key);
                }
              }
            });
          } else {
            let filter_value = filterData[e];
            if (filter_type === "range") {
              if (data_type === "date" || data_type === "datetime") {
                let obj = {};
                let d1 = new Date(filter_value["start"]);
                let d2 = new Date(filter_value["end"]);
                d1 = moment(d1.toString()).format("YYYY-MM-DD");
                d2 = moment(d2.toString()).format("YYYY-MM-DD");
                obj["start"] = d1;
                obj["end"] = d2;
                dataRangeFilterValues[e] = obj;
                fieldList.push(e);
              } else if (data_type === "integer") {
                let obj = {};
                let num1 = filter_value["start"];
                let num2 = filter_value["end"];
                obj["start"] = num1;
                obj["end"] = num2;
                numRangeFilterValues[e] = obj;
                fieldList.push(e);
              }
            } else if (filter_type === "list") {
              listFilterValues[e] = filter_value;
              fieldList.push(e);
            } else {
              if (data_type === "date" || data_type === "datetime") {
                let d = new Date(filter_value);
                d = moment(d.toString()).format("YYYY-MM-DD");
                normalFilterValues[e] = {
                  value: d,
                  type: data_type,
                };
                fieldList.push(e);
              } else {
                normalFilterValues[e] = {
                  value: filter_value,
                  type: data_type,
                };
                fieldList.push(e);
              }
            }
          }
        }
      });

      setSelectFieldDataList(listFilterValues);
      setDateRangeFilterList(dataRangeFilterValues);
      setNumRangeFilterList(numRangeFilterValues);
      setFilterList(normalFilterValues);
      setSegementFilterFieldList(fieldList);
      setOpenFilterSelectionModal(true);
    }
  };

  const resetFilterData = () => {
    setSelectFieldDataList({});
    setDateRangeFilterList({});
    setNumRangeFilterList({});
    setFilterList({});
  };
  //**********************END****************************** */

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <Container>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <Header>{getLocalizedText("create_a_new_campaign")}</Header>
                <SubHeader>
                  {getLocalizedText("fill_in_the_below_details")}
                </SubHeader>
                <Divider style={{ margin: "20px 0px" }} />
                {stepCount === 0 ? (
                  <>
                    <InputContainer>
                      <InputWrapper>
                        <Label>{getLocalizedText("title")}*</Label>
                        <Input
                          required
                          type="text"
                          placeholder="Enter title"
                          name="title"
                          value={data.title}
                          onChange={handleChange}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <Label>{getLocalizedText("description")}</Label>
                        <TextArea
                          value={data.desc}
                          type="text"
                          placeholder="Enter description"
                          name="desc"
                          onChange={handleChange}
                        />
                      </InputWrapper>

                      <SelectColumn>
                        <Label>{getLocalizedText("campaign_type")}*</Label>
                        <FormControl
                          sx={{ m: 1, minWidth: 428 }}
                          classes={{
                            root: classes.quantityRoot,
                          }}
                        >
                          <Select
                            classes={{
                              icon: classes.icon,
                            }}
                            value={selectedCampaignTypeID}
                            name="campaign_type"
                            displayEmpty
                            disableUnderline
                            variant="outlined"
                            required
                            onChange={(e) => {
                              handleCampaign(e);
                              setSelectedProviderItemID("");
                              setSegment("");
                            }}
                            style={{
                              marginBottom: "20px",
                              width: "428px",
                              height: "30px",
                              fontSize: "12px",
                              fontWeight: "bold",
                              borderRadius: "0px",
                            }}
                          >
                            {campaignTypeList.map((e) => {
                              return (
                                <MenuItem id={e} value={e.id}>
                                  {e.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </SelectColumn>
                      <SelectColumn>
                        <Label>
                          {" "}
                          {getCampaignTypeName().toLowerCase() === "offline" ||
                          getCampaignTypeName().toLowerCase() === ""
                            ? getLocalizedText("select_provider")
                            : `${getLocalizedText("select_provider")}*`}
                        </Label>
                        <FormControl
                          sx={{ m: 1, minWidth: 428 }}
                          classes={{
                            root: classes.quantityRoot,
                          }}
                        >
                          <Select
                            classes={{
                              icon: classes.icon,
                            }}
                            value={selectedProviderItemID}
                            name="provider"
                            displayEmpty
                            disableUnderline
                            variant="outlined"
                            required={
                              getCampaignTypeName().toLowerCase() !==
                                "offline" &&
                              getCampaignTypeName().toLowerCase() !== ""
                            }
                            disabled={
                              getCampaignTypeName().toLowerCase() ===
                                "offline" ||
                              getCampaignTypeName().toLowerCase() === ""
                            }
                            onChange={(e) => {
                              setSelectedProviderItemID(e.target.value);
                            }}
                            style={{
                              marginBottom: "20px",
                              width: "428px",
                              height: "30px",
                              fontSize: "12px",
                              fontWeight: "bold",
                              borderRadius: "0px",
                            }}
                          >
                            {providerList.map((e) => {
                              return (
                                <MenuItem id={e} value={e.id}>
                                  {e.provider.provider_name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </SelectColumn>
                      <SelectColumn style={{ width: "100%" }}>
                        <Label>
                          {getCampaignTypeName().toLowerCase() === "offline" ||
                          getCampaignTypeName().toLowerCase() === ""
                            ? getLocalizedText("select_segment")
                            : `${getLocalizedText("select_segment")}*`}
                        </Label>
                        <Row>
                          <FormControl
                            style={{ width: "100%" }}
                            classes={{
                              root: classes.quantityRoot,
                            }}
                          >
                            <Select
                              classes={{
                                icon: classes.icon,
                              }}
                              value={segment}
                              name="segment"
                              disabled={
                                getCampaignTypeName().toLowerCase() ===
                                  "offline" ||
                                getCampaignTypeName().toLowerCase() === ""
                              }
                              displayEmpty
                              disableUnderline
                              variant="outlined"
                              required={
                                getCampaignTypeName().toLowerCase() !==
                                  "offline" &&
                                getCampaignTypeName().toLowerCase() !== ""
                              }
                              onChange={(e) => {
                                setSegment(e.target.value);
                                let index = segmentList.findIndex(
                                  (segment) => segment["id"] === e.target.value
                                );
                                setSelectedSegmentFiterData(
                                  segmentList[index]["filters"]
                                );
                                setSegementFilterFieldList([]);
                                resetFilterData();
                              }}
                              style={{
                                width: "100%",
                                height: "30px",
                                fontSize: "12px",
                                fontWeight: "bold",
                                borderRadius: "0px",
                              }}
                            >
                              {segmentList.map((e) => {
                                return (
                                  <MenuItem id={e} value={e.id}>
                                    {e.title}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                          {segment !== "" && (
                            <MoreHoriz
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                initSegmentFilterData();
                              }}
                              color="primary"
                            />
                          )}
                        </Row>
                      </SelectColumn>
                    </InputContainer>
                  </>
                ) : (
                  (() => {
                    let campaign_type_name = getCampaignTypeName();
                    let provider_name = getProviderName();
                    if (
                      campaign_type_name.toLowerCase() === "auto dialer" &&
                      provider_name.toLowerCase() === "mcube"
                    ) {
                      return (
                        <AutoDialerCampaign
                          autoDialerCampaignTypeList={
                            autoDialerCampaignTypeList
                          }
                          setAutodialerCampaignType={setAutodialerCampaignType}
                          provider={provider_name}
                          setAgentList={setAgentList}
                        />
                      );
                    } else if (
                      campaign_type_name.toLowerCase() === "email" &&
                      provider_name.toLowerCase() === "sendgrid"
                    ) {
                      return (
                        <EmailCampaign
                          campaignTypeName={campaign_type_name}
                          setVariables={setVariables}
                          variables={variables}
                          providerName={provider_name}
                          providerList={providerList}
                          selectedProviderItemID={selectedProviderItemID}
                          setFromAddress={setFromAddress}
                          setEmailTemplateID={setEmailTemplateID}
                          emailTemplateVariableList={emailTemplateVariableList}
                          setEmailTemplateVariableList={
                            setEmailTemplateVariableList
                          }
                        />
                      );
                    } else if (campaign_type_name.toLowerCase() === "offline") {
                      return (
                        <OfflineCampaign
                          campaignTypeName={campaign_type_name}
                          campaignDateRange={campaignDateRange}
                          setCampaignDateRange={setCampaignDateRange}
                        />
                      );
                    } else if (
                      campaign_type_name.toLowerCase() === "sms" &&
                      provider_name.toLowerCase() === "sendgrid"
                    ) {
                      return (
                        <SmsCampaign
                          smsText={smsText}
                          campaignType={campaign_type_name}
                          setVariables={setVariables}
                          variables={variables}
                          provider={provider_name}
                        />
                      );
                    } else if (
                      campaign_type_name.toLowerCase() === "whatsapp" &&
                      provider_name.toLowerCase() === "sendgrid"
                    ) {
                      return (
                        <WhatsappCampaign
                          smsText={smsText}
                          campaignType={campaign_type_name}
                          setVariables={setVariables}
                          variables={variables}
                          provider={provider_name}
                        />
                      );
                    }
                  })()
                )}
                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      if (stepCount === 0) {
                        analytics.triggerEvent(
                          4625000246,
                          "cancel_button_tap",
                          "create_campaign_form",
                          "cancel_button",
                          {}
                        );
                        handleClose();
                      } else {
                        analytics.triggerEvent(
                          4625000248,
                          "previous_button_tap",
                          "create_campaign_form",
                          "previous_button",
                          {}
                        );
                        handleStep();
                      }
                    }}
                    type="button"
                    variant="outlined"
                    color="primary"
                    style={{
                      textTransform: "none",
                      marginRight: "20px",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100px",
                    }}
                  >
                    {stepCount === 0
                      ? getLocalizedText("cancel")
                      : getLocalizedText("prev")}
                  </Button>
                  <Button
                    onClick={() => {
                      if (stepCount === 0) {
                        analytics.triggerEvent(
                          4625000247,
                          "next_button_tap",
                          "create_campaign_form",
                          "next_button",
                          {}
                        );
                      } else {
                        analytics.triggerEvent(
                          4625000249,
                          "create_button_tap",
                          "create_campaign_form",
                          "create_button",
                          {}
                        );
                      }
                    }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    // id="call-log-btn"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100px",
                    }}
                  >
                    {stepCount === 0
                      ? getLocalizedText("next")
                      : getLocalizedText("create")}
                  </Button>
                </ButtonWrapper>
              </Form>
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
      {openFilterSelectionModal && (
        <CampaignFilterModal
          open={openFilterSelectionModal}
          setOpen={setOpenFilterSelectionModal}
          dateRangeFilterList={dateRangeFilterList}
          setDateRangeFilterList={setDateRangeFilterList}
          numRangeFilterList={numRangeFilterList}
          setNumRangeFilterList={setNumRangeFilterList}
          selectFieldDataList={selectFieldDataList}
          setSelectFieldDataList={setSelectFieldDataList}
          filterList={filterList}
          setFilterList={setFilterList}
          fieldList={segmentFilterFieldList}
        />
      )}
    </>
  );
};

export default observer(CreateCampaignModal);
