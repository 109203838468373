import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { Box } from "@mui/material";
import "./style.css";

const MultiSelectField = (props) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    if (props.list.length > 0) {
      let updated_list = updateList(props.list);
      setList(updated_list);
    }
  }, [props.field]);

  const updateList = (val) => {
    let newList = [];
    val.map((e) => {
      let newObj = { label: e, value: e };
      newList.push(newObj);
    });
    return newList;
  };

  return (
    <Box
      sx={{
        "&": {
          width: "100%",
          fontSize: "12px",
          border: "none",
          alignItems: "center",
          color: "black",
        },
        "& .dropdown-heading": {
          height: "30px",
        },
      }}
    >
      <MultiSelect
        options={list}
        value={props.selected}
        onChange={(e) => {
          props.setSelected(e);
          let newList = [];
          e.map((val) => {
            newList.push(val.value);
          });
          props.setSelectedVal({
            ...props.selectedVal,
            [props.field]: newList,
          });
          props.setFilterList({
            ...props.filterList,
            [props.field]: {
              value: newList,
              type: props.filterType,
              dataType: props.dataType,
            },
          });
        }}
        labelledBy="Select Status"
      />
    </Box>
  );
};

export default MultiSelectField;
