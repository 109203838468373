import React, { useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import Carousel from "react-multi-carousel";
import { useState } from "react";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import {
  getNumberOfDaysInCurrentStage,
  isLeadRotten,
  isStageClosed,
} from "../../Functions";
import moment from "moment";

const activeStageColor = "#26599E";
const oldStageColor = "#EDA93B";
const untouchedStageColor = "#E6E6E6";

const Container = styled(Box)`
  width: 100%;
`;
const Text = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: fit-content;
  overflow: hidden;
`;

const IconWrapperLeft = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  background-color: #ddddff;
  display: flex;
  border-radius: 50%;
  height: fit-content;
  width: fit-content;
  cursor: pointer;
`;
const IconWrapperRight = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #ddddff;
  display: flex;
  border-radius: 50%;
  height: fit-content;
  width: fit-content;
  cursor: pointer;
`;
const StepBox = styled(Box)`
  background: ${(e) => e.colorData.bgColor};
  color: ${(e) => e.colorData.color};
  min-width: 100px;
  flex: 100%;
  min-height: 50px;
  padding: 8px 20px;
  padding-left: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 -12px;
  clip-path: ${(props) => props.clipPath};
  flex-direction: row;
  gap: 8px;
`;

const RottenDetailWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: white;
  padding: 2px 8px;
  border-radius: 8px;
  min-width: 30px;
  cursor: pointer;
`;

const RottenDetail = styled(Typography)`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;

const CustomLeft = ({ onClick }) => (
  <IconWrapperLeft onClick={onClick}>
    <IconButton size="small">
      <ArrowBack style={{ color: "#26599E" }} />
    </IconButton>
  </IconWrapperLeft>
);
const CustomRight = ({ onClick }) => (
  <IconWrapperRight onClick={onClick}>
    <IconButton size="small">
      <ArrowForward style={{ color: "#26599E" }} />
    </IconButton>
  </IconWrapperRight>
);

const StageStepperComponentv2 = (props) => {
  const carouselRef = useRef();
  const [showButton, setShowButton] = useState(false);
  const [currentStage, setCurrentStage] = useState("");

  const getItemsCount = (defaultValue) => {
    if (props.stageList.length > defaultValue) {
      return defaultValue;
    }
    return props.stageList.length;
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: getItemsCount(12),
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: getItemsCount(8),
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: getItemsCount(6),
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: getItemsCount(4),
    },
  };

  const init = () => {
    if (carouselRef && carouselRef.current) {
      let index = props.stageList.findIndex(
        (stage) =>
          stage["stage"].toLowerCase() ===
          props.leadData["lead_stage"].toLowerCase()
      );
      if (index !== -1) {
        let max_index = carouselRef.current.state.totalItems;
        let number_of_slides = carouselRef.current.state.slidesToShow;
        let lower_bound = index - number_of_slides / 2;
        let upper_bound = index + number_of_slides / 2;
        if (max_index >= number_of_slides) {
          if (lower_bound < 0) {
            carouselRef.current.goToSlide(0);
          } else if (upper_bound > max_index) {
            carouselRef.current.goToSlide(max_index - number_of_slides);
          } else if (lower_bound > 0 && upper_bound < max_index) {
            carouselRef.current.goToSlide(lower_bound);
          }
        }
      }
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (
        currentStage.toLowerCase() !==
        props.leadData["lead_stage"].toLowerCase()
      ) {
        setCurrentStage(props.leadData["lead_stage"]);
        init();
      }
    }, 100);
  }, [props.leadData]);

  const getStageColorProps = (stageIndex) => {
    let currentStageIndex = props.stageList.findIndex(
      (stage) =>
        stage["stage"].toLowerCase() ===
        props.leadData["lead_stage"].toLowerCase()
    );

    if (currentStageIndex !== -1) {
      if (stageIndex === currentStageIndex) {
        if (isLeadRotten(props.leadData)) {
          return {
            bgColor: "#f50057",
            color: "white",
          };
        } else {
          return {
            bgColor: activeStageColor,
            color: "white",
          };
        }
      } else if (stageIndex > currentStageIndex) {
        return {
          bgColor: untouchedStageColor,
          color: activeStageColor,
        };
      } else if (stageIndex < currentStageIndex) {
        return {
          bgColor: oldStageColor,
          color: activeStageColor,
        };
      }
    }
    return {
      bgColor: untouchedStageColor,
      color: activeStageColor,
    };
  };

  const getTooltipText = () => {
    let index = props.stageList.findIndex(
      (stage) =>
        stage["stage"].toLowerCase() ===
        props.leadData["lead_stage"].toLowerCase()
    );
    if (index !== -1) {
      let stageData = props.stageList[index];
      if (props.leadData["in_current_stage_from"] !== null) {
        let numberOfDays = getNumberOfDaysInCurrentStage(props.leadData);
        if (stageData["rotting_in"] !== null && stageData["rotting_in"] !== 0) {
          if (numberOfDays > 1 && numberOfDays < stageData["rotting_in"]) {
            return `Idle for ${numberOfDays} days`;
          }
        }
        return `Rotting for ${numberOfDays} Day(s)`;
      }
    }
    return "-";
  };

  return (
    <Container
      onMouseEnter={() => {
        setShowButton(true);
      }}
      onMouseLeave={() => {
        setShowButton(false);
      }}
    >
      <Carousel
        ref={carouselRef}
        responsive={responsive}
        swipeable={false}
        draggable={false}
        arrows={showButton}
        containerClass="stage-carousel-container"
        customLeftArrow={<CustomLeft />}
        customRightArrow={<CustomRight />}
      >
        {props.stageList.map((item, index) => {
          const isFirst = index === 0;
          const isLast = index === props.stageList.length - 1;

          let clipPath =
            "polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%)";
          let firstClipPath =
            "polygon(0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%)";
          let lastClipPath =
            "polygon(20px 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%)";

          if (isFirst) {
            clipPath = firstClipPath;
          } else if (isLast) {
            clipPath = lastClipPath;
          }
          if (
            item["stage"].toLowerCase() ===
            props.leadData["lead_stage"].toLowerCase()
          ) {
            return (
              <StepBox
                colorData={getStageColorProps(index)}
                clipPath={clipPath}
              >
                <Tooltip title={item["stage"]}>
                  <Text>{item["stage"]}</Text>
                </Tooltip>
                {!isStageClosed(item) &&
                  props.leadData.hasOwnProperty("in_current_stage_from") &&
                  props.leadData["in_current_stage_from"] !== null &&
                  getNumberOfDaysInCurrentStage(props.leadData) > 0 && (
                    <Tooltip title={getTooltipText()}>
                      <RottenDetailWrapper>
                        <RottenDetail>
                          {`${getNumberOfDaysInCurrentStage(props.leadData)}d`}
                        </RottenDetail>
                      </RottenDetailWrapper>
                    </Tooltip>
                  )}
              </StepBox>
            );
          } else {
            return (
              <StepBox
                colorData={getStageColorProps(index)}
                clipPath={clipPath}
              >
                <Tooltip title={item["stage"]}>
                  <Text>{item["stage"]}</Text>
                </Tooltip>
              </StepBox>
            );
          }
        })}
      </Carousel>
    </Container>
  );
};

export default StageStepperComponentv2;
