import { Box, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";

const CardWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 60px;
  padding: 16px;
  background-color: #f9f9fc;
  border-radius: 8px;
`;
const Text = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  margin: 0px;
`;
const NoDataCardWidget = () => {
  return (
    <CardWrapper>
      <Text>No data.</Text>
    </CardWrapper>
  );
};

export default NoDataCardWidget;
