import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { Box, CircularProgress, IconButton } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { getLeadInteractionLogsListApi } from "../../../Api";
import PaginationWithoutPageSize from "../../pagination/PaginationWithoutPageSize";
import rootStore from "../../../stores/RootStore";
import { AddBoxOutlined } from "@material-ui/icons";
import { Drawer } from "@material-ui/core";
import CreateLeadInteractionComponent from "./components/CreateLeadInteractionComponent";
import { leadOtherInteractionTabColumnHeaders } from "../../../Db";

const Container = styled(Box)`
  width: 100%;
  height: 100%;
`;

const TableWrapper = styled(Box)`
  position: relative;
  width: 100%;
  ${`min-height: calc(100vh - 345px);`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const AddBtnWrapper = styled(Box)`
  position: absolute;
  right: 0px;
  top: 9px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
`;

const OthersTab = ({ leadID, canEditLead }) => {
  const [row, setRow] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [openCreateInteractionSidepanel, setOpenCreateInteractionSidepanel] =
    useState(false);
  const [refresh, setRefresh] = useState(false);

  const getLeadInteractions = async ({ page }) => {
    const pageSize = 25;
    let queryParams = {
      offset: page * pageSize,
      limit: pageSize,
    };
    let response = await getLeadInteractionLogsListApi({
      leadID: leadID,
      queryParams: queryParams,
    });
    if (Object.keys(response).length > 0) {
      setCount(response["item_count"]);
      setRow(response["items"]);
    } else {
      setCount(0);
      setRow([]);
    }
  };
  const init = async () => {
    await getLeadInteractions({ page: 0 });
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  const refetch = async () => {
    setLoading(true);
    await getLeadInteractions({ page: 0 });
    setLoading(false);
    setRefresh(false);
  };

  useEffect(() => {
    if (refresh) {
      refetch();
    }
  }, [refresh]);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const handlePagination = async (e, page) => {
    setLoading(true);
    setPage(page - 1);
    await getLeadInteractions({ page: page - 1 });
    setLoading(false);
  };

  const getPaginationCounterText = () => {
    let total = count !== 0 ? count : 0;
    let itemsPerPage = 25;
    let min = total !== 0 ? page * itemsPerPage + 1 : 0;
    let max =
      (page + 1) * itemsPerPage > total ? total : (page + 1) * itemsPerPage;
    return `${min}-${max} of ${total}`;
  };

  return (
    <Container>
      {!loading ? (
        <TableWrapper>
          <AddBtnWrapper>
            <IconButton
              style={{ padding: "0px" }}
              disabled={!canEditLead()}
              onClick={() => {
                setOpenCreateInteractionSidepanel(true);
              }}
            >
              <AddBoxOutlined
                color={"primary"}
                onClick={() => {
                  rootStore.authStore.updateLastActiveTime();
                }}
                style={{
                  width: "40px",
                  height: "40px",
                }}
              />
            </IconButton>
          </AddBtnWrapper>
          <div className="ag-theme-alpine" style={{ width: "100%" }}>
            <AgGridReact
              domLayout="autoHeight"
              gridOptions={gridOptions}
              rowData={row}
              defaultColDef={defaultColDef}
              columnDefs={leadOtherInteractionTabColumnHeaders}
              animateRows={true}
              suppressCellFocus
              pagination={false}
              paginationPageSize={25}
              onRowClicked={(row) => {
                if (row.event.defaultPrevented) {
                  return null;
                }
              }}
            />
          </div>
          <PaginationWithoutPageSize
            page_no={page}
            row={row}
            count={count}
            handlePage={handlePagination}
            counterText={getPaginationCounterText()}
            page_size={25}
          />
        </TableWrapper>
      ) : (
        <TableWrapper
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </TableWrapper>
      )}
      {openCreateInteractionSidepanel && (
        <Drawer
          anchor={"right"}
          open={openCreateInteractionSidepanel}
          onClose={() => {
            setOpenCreateInteractionSidepanel(false);
          }}
        >
          <CreateLeadInteractionComponent
            setOpen={setOpenCreateInteractionSidepanel}
            setRefresh={setRefresh}
            leadID={leadID}
          />
        </Drawer>
      )}
    </Container>
  );
};

export default observer(OthersTab);
