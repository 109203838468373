import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import rootStore from "../../stores/RootStore";
import spinner from "../../assets/images/gifs/spinner.gif";
import { observer } from "mobx-react-lite";
import { getLocalizedText } from "../../Functions";
import { Box, Grid } from "@material-ui/core";
import { Typography } from "@mui/material";
import GroupFieldWidget from "../create_lead_modal/group_field_widget/GroupFieldWidget";
import NumberField from "../custom_field_components/NumberField";
import MultiSelectField from "../custom_field_components/MultiSelectField";
import DateField from "../custom_field_components/DateField";
import DateTimeField from "../custom_field_components/DateTimeField";
import SearchableCustomSelectField from "../select_dropdown/SearchableCustomSelectField";
const Spinner = styled("img")`
  height: 120px;
  width: 180px;
`;
const SpinnerWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
`;
const Input = styled("input")`
  width: 100%;
  height: 38px;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #e0deca;
  border-radius: 4px;
  font: normal normal 600 10px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal normal 12px Open Sans;
    color: #b5b5b5;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  overflow-y: auto;
  padding: 16px;
  padding-bottom: 74px;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
`;
const Message = styled(Typography)`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
`;

const SecondForm = (props) => {
  const { userStore } = rootStore;
  const [allCols, setAllCols] = useState([]);
  let columnsList = userStore.AllColumnsList;
  const [loading, setLoading] = useState(true);
  const [parentChildData, setParentChildData] = useState({});

  //find parent column and getting its value
  const getParentFieldValue = (parent_field_info) => {
    let index = allCols.findIndex(
      (column) => column.id === parent_field_info.id
    );
    if (index !== -1) {
      let target_column = allCols[index];
      if (props.value.hasOwnProperty(target_column["field"])) {
        return {
          value: props.value[target_column["field"]],
          field_data: target_column,
        };
      }
    }
    return null;
  };
  const handleChange = ({ fieldName, fieldValue, type }) => {
    props.setIsSubmitFail(false);
    let prevState = { ...props.value };
    prevState[fieldName] = { value: fieldValue, type: type };
    let index = columnsList.findIndex(
      (column) => column["field"] === fieldName
    );
    let columnMeta = columnsList[index];
    if (columnMeta["parent"]) {
      let childList = parentChildData[columnMeta["id"]];
      childList.forEach((child) => {
        prevState[child["field"]] = { value: "", type: child["data_type"] };
      });
    }
    props.setValue(prevState);
  };

  function setupColumns() {
    let tempColumns = [...columnsList];

    tempColumns = tempColumns.filter(
      (columnData) =>
        columnData["field"] !== "lead_owner" &&
        columnData["field"] !== "lead_creator" &&
        !columnData["derived"] &&
        columnData["enable_for_create"] &&
        rootStore.userStore.isColumnVisible(columnData) &&
        columnData["is_custom_param"]
    );

    let requiredFields = tempColumns
      .filter((item) => item["required"] && item["child_of"] === null)
      .map((item) => item)
      .sort((a, b) =>
        a["headerName"]
          .toLowerCase()
          .localeCompare(b["headerName"].toLowerCase())
      );

    let parentFields = tempColumns
      .filter((item) => item["parent"])
      .map((item) => item)
      .sort((a, b) =>
        a["headerName"]
          .toLowerCase()
          .localeCompare(b["headerName"].toLowerCase())
      );

    let allFields = [...requiredFields];

    parentFields.forEach((data) => {
      let index = allFields.findIndex(
        (element) => element["id"] === data["id"]
      );
      if (index === -1) {
        allFields.push(data);
      }
    });

    let parentChildMap = {};
    for (let item of tempColumns) {
      if (item["parent"]) {
        let parentId = item["id"];
        let children = tempColumns
          .filter(
            (child) =>
              child["child_of"] !== null && child["child_of"]["id"] === parentId
          )
          .map((child) => child)
          .sort((a, b) =>
            a["headerName"]
              .toLowerCase()
              .localeCompare(b["headerName"].toLowerCase())
          );
        parentChildMap[parentId] = children;
      }
    }
    setParentChildData(parentChildMap);

    let remainingFields = [];
    tempColumns.forEach((item) => {
      let index = allFields.findIndex(
        (element) => element["id"] === item["id"]
      );
      if (index === -1 && item["child_of"] === null) {
        remainingFields.push(item);
      }
    });
    remainingFields.sort((a, b) =>
      a["headerName"].toLowerCase().localeCompare(b["headerName"].toLowerCase())
    );
    remainingFields.forEach((data) => {
      let index = allFields.findIndex(
        (element) => element["id"] === data["id"]
      );
      if (index === -1) {
        allFields.push(data);
      }
    });

    for (const item of allFields) {
      if (item.is_multiline || item.parent) {
        item.width = 2;
        item.modifyWidth = false;
      } else {
        item.width = 1;
        item.modifyWidth = true;
      }
    }
    for (let i = 0; i < allFields.length; i++) {
      let currentItem = allFields[i];

      if (currentItem.modifyWidth) {
        if (i === allFields.length - 1) {
          currentItem.width = 2;
          currentItem.modifyWidth = false;
        } else {
          let nextItem = allFields[i + 1];

          if (nextItem.width === 2) {
            currentItem.width = 2;
            currentItem.modifyWidth = false;
          } else if (nextItem.width === 1) {
            currentItem.width = 1;
            currentItem.modifyWidth = false;
            nextItem.modifyWidth = false;
          }
        }
      }
    }

    return allFields;
  }

  useEffect(() => {
    const getList = () => {
      let filteredColumns = setupColumns();

      setAllCols(filteredColumns);
      setLoading(false);
    };
    getList();
  }, []);
  const getRegex = (item) => {
    if (item.regex === null || item.regex === "") {
      return null;
    } else {
      return item.regex;
    }
  };

  const RenderLabel = ({ item }) => {
    return (
      <Label style={{ color: item["editable"] ? "#4d4e4f" : "lightgray" }}>
        {item.headerName}
        {item.required && (
          <span style={{ color: item["editable"] ? "red" : "lightgray" }}>
            *
          </span>
        )}
      </Label>
    );
  };

  const renderField = ({ item }) => {
    if (item["is_array"] === true) {
      let list = [];
      if (item["filter_input"] !== null && item["filter_input"] !== "") {
        let tempList = item["filter_input"].split(",");
        tempList.forEach((str) => {
          let tempObj = { label: str, value: str };
          list.push(tempObj);
        });
      }

      return (
        <Grid item xs={item["width"] === 1 ? 6 : 12}>
          <InputWrapper>
            <RenderLabel item={item} />
            <MultiSelectField
              disabled={!item["editable"]}
              handleChange={(selected) => {
                handleChange({
                  fieldName: item.field,
                  fieldValue: selected,
                  type: "array",
                });
              }}
              list={list}
              value={
                props.value.hasOwnProperty(item.field)
                  ? props.value[item.field]["value"]
                  : []
              }
            />
          </InputWrapper>
        </Grid>
      );
    } else {
      if (item.filter_input === "" || item.filter_input === null) {
        if (item["data_type"] === "integer") {
          return (
            <Grid item xs={item["width"] === 1 ? 6 : 12}>
              <InputWrapper>
                <RenderLabel item={item} />
                <NumberField
                  disabled={!item["editable"]}
                  value={
                    props.value.hasOwnProperty(item.field)
                      ? props.value[item.field]["value"]
                      : ""
                  }
                  name={item.field}
                  placeholder={
                    item.hint !== null && item.hint !== ""
                      ? item.hint
                      : `Enter ${item.headerName}`
                  }
                  pattern={getRegex(item)}
                  required={item.required}
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      `Please enter a valid ${item.headerName}`
                    )
                  }
                  handleChange={(e) => {
                    e.target.setCustomValidity("");
                    handleChange({
                      fieldName: e.target.name,
                      fieldValue: e.target.value,
                      type: item.data_type,
                    });
                  }}
                  handleBlur={() => {}}
                />
              </InputWrapper>
            </Grid>
          );
        } else if (item["data_type"] === "date") {
          return (
            <Grid item xs={item["width"] === 1 ? 6 : 12}>
              <InputWrapper>
                <RenderLabel item={item} />
                <DateField
                  disabled={!item["editable"]}
                  value={
                    props.value.hasOwnProperty(item.field)
                      ? props.value[item.field]["value"]
                      : null
                  }
                  onChange={(event) => {
                    handleChange({
                      fieldName: item["field"],
                      fieldValue: event,
                      type: item["data_type"],
                    });
                  }}
                  required={item.required}
                />
              </InputWrapper>
            </Grid>
          );
        } else if (item["data_type"] === "datetime") {
          return (
            <Grid item xs={item["width"] === 1 ? 6 : 12}>
              <InputWrapper>
                <RenderLabel item={item} />
                <DateTimeField
                  disabled={!item["editable"]}
                  value={
                    props.value.hasOwnProperty(item.field)
                      ? props.value[item.field]["value"]
                      : null
                  }
                  onChange={(event) => {
                    handleChange({
                      fieldName: item["field"],
                      fieldValue: event,
                      type: item["data_type"],
                    });
                  }}
                  required={item.required}
                />
              </InputWrapper>
            </Grid>
          );
        } else {
          return (
            <Grid item xs={item["width"] === 1 ? 6 : 12}>
              <InputWrapper>
                <RenderLabel item={item} />
                <Input
                  disabled={!item["editable"]}
                  type={item.data_type === "email" ? "email" : "text"}
                  value={
                    props.value.hasOwnProperty(item.field)
                      ? props.value[item.field]["value"]
                      : ""
                  }
                  name={item.field}
                  pattern={getRegex(item)}
                  required={item.required}
                  placeholder={
                    item.hint !== null && item.hint !== ""
                      ? item.hint
                      : `Enter ${item.headerName}`
                  }
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      `Please enter a valid ${item.headerName}`
                    )
                  }
                  onChange={(e) => {
                    e.target.setCustomValidity("");

                    handleChange({
                      fieldName: e.target.name,
                      fieldValue: e.target.value,
                      type: item.data_type,
                    });
                  }}
                />
              </InputWrapper>
            </Grid>
          );
        }
      } else {
        if (item["map_to_parent_selection"]) {
          let parent_field_info = item.child_of;
          let parent_data = getParentFieldValue(parent_field_info); //getting the data of parent field including value
          let parent_value = parent_data !== null ? parent_data["value"] : null;
          let is_required =
            parent_data !== null
              ? parent_data["field_data"]["required"]
              : false;

          return (
            <Grid item xs={item["width"] === 1 ? 6 : 12}>
              <InputWrapper>
                <RenderLabel item={item} />

                <SearchableCustomSelectField
                  columnMeta={item}
                  handleChange={(value) => {
                    handleChange({
                      fieldName: item["field"],
                      fieldValue: value,
                      type: item["data_type"],
                    });
                  }}
                  isDisabled={!item["editable"]}
                  value={
                    props.value.hasOwnProperty(item.field) &&
                    props.value[item.field]["value"] !== null
                      ? props.value[item.field]["value"]
                      : ""
                  }
                  mapToParentSelection={true}
                  isRequired={is_required}
                  parentValue={parent_value}
                />
              </InputWrapper>
            </Grid>
          );
        } else {
          return (
            <Grid item xs={item["width"] === 1 ? 6 : 12}>
              <InputWrapper>
                <RenderLabel item={item} />

                <SearchableCustomSelectField
                  columnMeta={item}
                  handleChange={(value) => {
                    handleChange({
                      fieldName: item["field"],
                      fieldValue: value,
                      type: item["data_type"],
                    });
                  }}
                  isDisabled={!item["editable"]}
                  value={
                    props.value.hasOwnProperty(item.field) &&
                    props.value[item.field]["value"] !== null
                      ? props.value[item.field]["value"]
                      : ""
                  }
                  isRequired={item["required"]}
                />
              </InputWrapper>
            </Grid>
          );
        }
      }
    }
  };

  return !loading ? (
    allCols.length > 0 ? (
      <InputContainer>
        <Grid container spacing={2}>
          {allCols.map((item) => {
            if (item["parent"] === true) {
              return (
                <Grid item xs={12}>
                  <GroupFieldWidget
                    isEdit={false}
                    item={item}
                    renderField={renderField}
                    details={props.value}
                    allChildList={parentChildData[item["id"]]}
                    handleFilledParentChildMap={(parentID, childIDList) => {
                      props.setFilledParentChildMap({
                        ...props.filledParentChildMap,
                        [parentID]: childIDList,
                      });
                    }}
                    handleChange={(value) =>
                      handleChange({
                        fieldName: item["field"],
                        fieldValue: value,
                        type: item["data_type"],
                      })
                    }
                  />
                </Grid>
              );
            } else {
              return renderField({ item: item });
            }
          })}
        </Grid>
      </InputContainer>
    ) : (
      <InputContainer style={{ height: "100%" }}>
        <MessageWrapper
          style={{
            flexGrow: 1,
            height: "100%",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Message
            style={{
              color: "gray",
              fontSize: "20px",
              marginBottom: "10px",
            }}
          >
            {getLocalizedText("no_custom_fields_found")}
          </Message>
        </MessageWrapper>
      </InputContainer>
    )
  ) : (
    <InputContainer style={{ height: "100%" }}>
      <SpinnerWrapper>
        <Spinner src={spinner} alt="loading..." />
      </SpinnerWrapper>
    </InputContainer>
  );
};

export default observer(SecondForm);
