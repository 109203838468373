import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { Button } from "@material-ui/core";
import { Box, Divider, Modal, Typography } from "@mui/material";
import { getLocalizedText } from "../../../Functions";
import { myTheme } from "../../../themeUtils";
import rootStore from "../../../stores/RootStore";
import { createDashboardApi } from "../../../Api";
import ErrorMessageDialog from "../../alert_dialogue/ErrorMessageDialog";
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;

const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const Form = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 400px;
  row-gap: 20px;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const Label = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;

const Input = styled("input")`
  width: 100%;
  height: 30px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;
  border: 1px solid #bfbfbf;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 600 12px Open Sans;
    color: #e1e1e1;
  }
`;
const TextArea = styled("textarea")`
  width: 100%;
  height: 60px;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #bfbfbf;
  color: #4d4e4f;

  resize: none;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 600 12px Open Sans;
    color: #e1e1e1;
  }
`;
const ButtonWrapper = styled(Box)`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const AddDashboardModal = (props) => {
  const [formData, setFormData] = useState({});
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let response = await createDashboardApi(formData);
    if (response.hasError()) {
      setOpenErrorDialog(true);
    } else {
      props.setCurrentDashboardData(response.data);
      props.setCurrentDashboardId(response.data["id"]);
      props.setRefresh(true);
      props.setOpen(false);
    }
  };

  return (
    <>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <Container>
              <Form component={"form"} onSubmit={handleSubmit}>
                <Header>{getLocalizedText("add_a_new_dashboard")}</Header>
                <SubHeader>
                  {getLocalizedText("fill_in_the_below_details")}
                </SubHeader>
                <Divider style={{ margin: "10px 0px" }} />
                <InputContainer>
                  <InputWrapper>
                    <Label>{getLocalizedText("name")}*</Label>
                    <Input
                      required
                      type="text"
                      placeholder="Enter title"
                      name="name"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <Label>{getLocalizedText("description")}</Label>
                    <TextArea
                      type="text"
                      placeholder="Enter description"
                      name="description"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <Label>{getLocalizedText("project")}</Label>
                    <Input
                      value={rootStore.authStore.currentProject["name"]}
                      readOnly
                    />
                  </InputWrapper>
                </InputContainer>

                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      handleClose();
                    }}
                    type="button"
                    variant="outlined"
                    style={{
                      textTransform: "none",
                      marginRight: "20px",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100px",
                      ...myTheme.Button.btnLight,
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    // id="call-log-btn"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100px",
                    }}
                  >
                    {getLocalizedText("submit")}
                  </Button>
                </ButtonWrapper>
              </Form>
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
      {openErrorDialog && (
        <ErrorMessageDialog
          open={openErrorDialog}
          setOpen={setOpenErrorDialog}
        />
      )}
    </>
  );
};

export default observer(AddDashboardModal);
