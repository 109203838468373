import { Drawer, Tooltip } from "@material-ui/core";
import { MoreHoriz } from "@material-ui/icons";
import { CircularProgress, TableCell, TableRow } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import {
  getRawLeadImportLogUrlApi,
  refetchRawLeadImportRowDataApi,
} from "../../Api";
import { RawLeadsImportHistoryTableColumns } from "../../Db";
import { dataRenderer, dateTimeHandler } from "../../Functions";
import "../../pages/import_history/import.css";
import RawLeadImportDetailsPanel from "../raw_lead_page_components/RawLeadImportDetailsPanel";
import analytics from "../../Analytics";

const SpinnerWrapper = styled.div`
  display: flex;
  padding-top: 8px;
  justify-content: center;
  align-items: center;
`;
const RawLeadImportRowComponent = ({ item, idx }) => {
  const [data, setData] = useState();
  const [status, setStatus] = useState("");
  const [openImportDetailPanel, setOpenImportDetailPanel] = useState(false);
  const { id } = item;
  const MINUTE_MS = 30000;

  const getUpdatedData = async () => {
    try {
      let response = await refetchRawLeadImportRowDataApi({ id: id });
      setData(response.data);
      setStatus(response.data["status"]);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (status === "queued" || status === "processing") {
        getUpdatedData();
      }
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, [status]);

  useEffect(() => {
    setData(item);
    setStatus(item["status"]);
  }, []);
  function truncate(str, n) {
    return str.length > n ? str.slice(0, n - 1) + "..." : str;
  }

  const handleDownloadLogFile = async (scope) => {
    let response = await getRawLeadImportLogUrlApi({ id: id, scope: scope });
    if (response.url !== "") {
      window.open(response.url, "_blank");
    }
  };
  return (
    <>
      <TableRow
        key={idx}
        style={{
          width: "100%",
          cursor: "pointer",
          backgroundColor: idx % 2 === 0 ? "white" : "#F9F9FC",
        }}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
        onClick={() => {
          analytics.triggerEvent(
            4625000150,
            "import_row_tap",
            "imports_tab",
            "",
            {}
          );
          setOpenImportDetailPanel(true);
        }}
      >
        {data &&
          RawLeadsImportHistoryTableColumns.map((key) => {
            if (
              key["field"] === "original_file" ||
              key["field"] === "error_log" ||
              key["field"] === "full_log"
            ) {
              return (
                <Tooltip title="Download">
                  <TableCell
                    className="textContainer"
                    style={{
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                      color: "#414141",
                    }}
                  >
                    <MoreHoriz
                      color="primary"
                      onClick={() => {
                        handleDownloadLogFile(key["field"]);
                      }}
                    />
                  </TableCell>
                </Tooltip>
              );
            } else if (key["field"] === "start_time") {
              let new_start_time;
              if (data[key["field"]] !== null) {
                new_start_time = dateTimeHandler(data[key["field"]]);
              }
              return (
                <Tooltip title={new_start_time}>
                  <TableCell
                    className="textContainer"
                    style={{
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                      color: "#414141",
                    }}
                  >
                    {data[key["field"]] !== null ? new_start_time : "-"}
                  </TableCell>
                </Tooltip>
              );
            } else if (key["field"] === "end_time") {
              let new_end_time;
              if (data[key["field"]] !== null) {
                new_end_time = dateTimeHandler(data[key["field"]]);
              }
              return (
                <Tooltip title={new_end_time}>
                  <TableCell
                    className="textContainer"
                    style={{
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                      color: "#414141",
                    }}
                  >
                    {data[key["field"]] !== null ? new_end_time : "-"}
                  </TableCell>
                </Tooltip>
              );
            } else if (key["field"] === "status") {
              if (data[key["field"]] === "processing") {
                return (
                  <SpinnerWrapper>
                    <CircularProgress
                      style={{ width: "18px", height: "18px" }}
                    />
                  </SpinnerWrapper>
                );
              } else {
                return (
                  <Tooltip title={data[key["field"]]}>
                    <TableCell
                      className="textContainer"
                      style={{
                        fontSize: "14px",
                        fontFamily: "Open Sans",
                        color: "#414141",
                      }}
                    >
                      {dataRenderer(data[key["field"]])}
                    </TableCell>
                  </Tooltip>
                );
              }
            } else if (key["field"] === "id") {
              return (
                <Tooltip title={data[key["field"]]}>
                  <TableCell
                    className="textContainer"
                    style={{
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                      color: "#414141",
                    }}
                  >
                    {truncate(data[key["field"]], 10)}
                  </TableCell>
                </Tooltip>
              );
            } else if (key["field"] === "import_type") {
              let value =
                data[key["field"]] === "raw_leads" ? "Create" : "Update";
              return (
                <Tooltip title={value}>
                  <TableCell
                    className="textContainer"
                    style={{
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                      color: "#414141",
                    }}
                  >
                    {value}
                  </TableCell>
                </Tooltip>
              );
            } else {
              let value = dataRenderer(data[key["field"]]);
              return (
                <Tooltip title={value}>
                  <TableCell
                    className="textContainer"
                    style={{
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                      color: "#414141",
                    }}
                  >
                    {value}
                  </TableCell>
                </Tooltip>
              );
            }
          })}
      </TableRow>
      {openImportDetailPanel && (
        <Drawer
          anchor={"right"}
          open={openImportDetailPanel}
          onClose={() => {
            setOpenImportDetailPanel(false);
          }}
        >
          {<RawLeadImportDetailsPanel id={id} />}
        </Drawer>
      )}
    </>
  );
};

export default RawLeadImportRowComponent;
