import { Box, FormControl, MenuItem, Select } from "@mui/material";
import React from "react";

const DropdownColumnWidget = ({
  onChange,
  value,
  options,
  fontSize = "14px",
  height = "30px",
  valueKey,
  labelKey,
  scope,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <FormControl sx={{ width: "100%" }}>
        <Select
          displayEmpty
          required
          onChange={(event) => onChange(event.target.value)}
          disableUnderline
          variant="outlined"
          value={value}
          style={{
            width: "100%",
            height: height,
            fontSize: fontSize,
            borderRadius: "0px",
          }}
        >
          {options.map((item, i) => {
            return (
              <MenuItem
                disabled={scope === "status" && item["is_not_set"] === true}
                value={item[valueKey]}
                key={i}
                style={{ fontSize: fontSize }}
              >
                {item[labelKey]}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default DropdownColumnWidget;
