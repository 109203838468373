import React, { useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import {
  getAccountUserDetailsApi,
  getWidgetDataApiv2,
  removeWidgetApiv2,
} from "../../Api";
import { CircularProgress } from "@material-ui/core";
import TabularWidget from "../dashboard_widget_slideshow/widgets/TabularWidget";
import OneGroupBarGraphWidget from "../dashboard_widget_slideshow/widgets/OneGroupBarGraphWidget";
import TwoGroupBarGraphWidget from "../dashboard_widget_slideshow/widgets/TwoGroupBarGraphWidget";
import { Box } from "@mui/material";
import rootStore from "../../stores/RootStore";
import {
  canFetchWidgetData,
  getCombinedWidgetAndAccountID,
} from "../../Functions";
import { observer } from "mobx-react-lite";
import TopPerformerWidget from "../dashboard_widget_slideshow/widgets/TopPerformerWidget";
import CallActivityTabularWidget from "../dashboard_widget_slideshow/widgets/CallActivityTabularWidget";
import CheckinActivityTabularWidget from "../dashboard_widget_slideshow/widgets/CheckinActivityTabularWidget";
import CounterWidget from "../dashboard_widget_slideshow/widgets/CounterWidget";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
`;
const CarouselWrapper = styled(Box)`
  width: 100%;
  background-color: white;
`;
const WidgetWrapper = styled(Box)`
  height: calc(100vh - 264px);
  width: 100%;
  background-color: white;
`;

const DashboardSlider = ({
  widgetList,
  isDashboardEditable,
  setRefreshWidgets,
}) => {
  const carouselRef = useRef(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [widgetData, setWidgetData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const handleRemove = async (widget) => {
    try {
      let response = await removeWidgetApiv2({ id: widget.id });
      setRefreshWidgets(true);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchWidgetData = async () => {
    setWidgetData(null);
    const widgetID = widgetList[activeSlideIndex].id;
    try {
      let response_data = null;
      if (widgetList[activeSlideIndex]["client_widget"] === true) {
        if (widgetList[activeSlideIndex]["widget_type"] === "top_performer") {
          let response = await getAccountUserDetailsApi({
            userID: widgetList[activeSlideIndex]["input"]["user_id"],
          });
          response_data = response;
        }
      } else {
        let response = await getWidgetDataApiv2({ id: widgetID });
        response_data = response.data;
      }
      rootStore.userStore.updateWidgetData({
        id: getCombinedWidgetAndAccountID(widgetID),
        data: response_data,
      });
      setWidgetData(response_data);
    } catch (error) {
      console.log(error);
      rootStore.userStore.updateWidgetData({
        id: getCombinedWidgetAndAccountID(widgetID),
        data: null,
      });
    }
  };

  const init = async () => {
    setLoading(true);
    if (widgetList.length > 0) {
      if (
        canFetchWidgetData({
          widgetID: widgetList[activeSlideIndex]["id"],
          frequencyString: widgetList[activeSlideIndex]["refresh_frequency"],
        })
      ) {
        await fetchWidgetData();
      } else {
        setWidgetData(
          rootStore.userStore.widgetData[
            getCombinedWidgetAndAccountID(widgetList[activeSlideIndex].id)
          ]["data"]
        );
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, [activeSlideIndex, widgetList]);

  const refetch = async () => {
    setLoading(true);
    await fetchWidgetData();
    setLoading(false);
    setRefresh(false);
  };
  useEffect(() => {
    if (refresh) {
      refetch();
    }
  }, [refresh]);

  const RenderWidget = ({ widget, widgetData }) => {
    if (widget.version === 2) {
      if (widget["widget_type"] === "tabular") {
        if (widget["title"] === "Call Activity Of All Project Users") {
          return (
            <WidgetWrapper>
              <CallActivityTabularWidget
                data={widgetData}
                widgetConfig={widget}
                handleRefresh={() => {
                  setRefresh(true);
                }}
                type={"slider"}
                handleRemove={(widget) => {
                  handleRemove(widget);
                }}
                isDashboardEditable={isDashboardEditable}
              />
            </WidgetWrapper>
          );
        } else if (
          widget["title"] === "Checkin Activity Of All Project Users"
        ) {
          return (
            <WidgetWrapper>
              <CheckinActivityTabularWidget
                data={widgetData}
                widgetConfig={widget}
                handleRefresh={() => {
                  setRefresh(true);
                }}
                type={"slider"}
                handleRemove={(widget) => {
                  handleRemove(widget);
                }}
                isDashboardEditable={isDashboardEditable}
              />
            </WidgetWrapper>
          );
        } else {
          return (
            <WidgetWrapper>
              <TabularWidget
                data={widgetData}
                widgetConfig={widget}
                handleRefresh={() => {
                  setRefresh(true);
                }}
                type={"slider"}
                handleRemove={(widget) => {
                  handleRemove(widget);
                }}
                isDashboardEditable={isDashboardEditable}
              />
            </WidgetWrapper>
          );
        }
      } else if (widget["widget_type"] === "top_performer") {
        return (
          <WidgetWrapper>
            <TopPerformerWidget
              data={widgetData}
              widgetConfig={widget}
              handleRefresh={() => {
                setRefresh(true);
              }}
              type={"slider"}
              handleRemove={(widget) => {
                handleRemove(widget);
              }}
              isDashboardEditable={isDashboardEditable}
            />
          </WidgetWrapper>
        );
      } else if (widget["widget_type"] === "counter") {
          return (
            <WidgetWrapper>
              <CounterWidget
                data={widgetData}
                widgetConfig={widget}
                handleRefresh={() => {
                  setRefresh(true);
                }}
                type={"slider"}
                handleRemove={(widget) => {
                  handleRemove(widget);
                }}
                isDashboardEditable={isDashboardEditable}
              />
            </WidgetWrapper>
          );
      } else {
        let groups = widget["xdata"]["group_by"];
        let size = groups.length;
        if (size === 1) {
          return (
            <WidgetWrapper>
              <OneGroupBarGraphWidget
                data={widgetData}
                widgetConfig={widget}
                handleRefresh={() => {
                  setRefresh(true);
                }}
                type={"slider"}
                handleRemove={(widget) => {
                  handleRemove(widget);
                }}
                isDashboardEditable={isDashboardEditable}
              />
            </WidgetWrapper>
          );
        } else if (size === 2) {
          return (
            <WidgetWrapper>
              <TwoGroupBarGraphWidget
                data={widgetData}
                widgetConfig={widget}
                handleRefresh={() => {
                  setRefresh(true);
                }}
                type={"slider"}
                handleRemove={(widget) => {
                  handleRemove(widget);
                }}
                isDashboardEditable={isDashboardEditable}
              />
            </WidgetWrapper>
          );
        }
      }
    }
  };

  return (
    <>
      <Container>
        <CarouselWrapper
          className="carousel-container"
          style={{ filter: loading ? 'blur("5px")' : "unset" }}
        >
          <Carousel
            showDots={true}
            partialVisbile={true}
            swipeable={false}
            draggable={false}
            responsive={responsive}
            ssr={false}
            infinite={false}
            autoPlay={false}
            customTransition="all 0.2s ease"
            transitionDuration={200}
            keyBoardControl={false}
            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            beforeChange={(index) => {
              setActiveSlideIndex(index);
            }}
            pauseOnHover={false}
            ref={carouselRef}
          >
            {widgetList.map((widget, i) => {
              if (loading) {
                return (
                  <WidgetWrapper
                    key={i}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </WidgetWrapper>
                );
              } else {
                return (
                  <RenderWidget
                    widget={widget}
                    widgetData={i === activeSlideIndex ? widgetData : null}
                    key={i}
                  />
                );
              }
            })}
          </Carousel>
        </CarouselWrapper>
      </Container>
    </>
  );
};

export default observer(DashboardSlider);
