import React from "react";
import { styled } from "@mui/material/styles";
import {
  Alert,
  Box,
  Button,
  MenuItem,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Add, Cancel, Circle, Info } from "@mui/icons-material";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import { useState } from "react";
import { FormControl, Select, makeStyles } from "@material-ui/core";
import FilterInputComponent from "./FilterInputComponent";
import { useEffect } from "react";
import { getLocalizedText } from "../../../Functions";
import rootStore from "../../../stores/RootStore";
import analytics from "../../../Analytics";
const ModelWrapper = styled(Box)(({ theme }) => ({
  width: "450px",
  margin: "auto",
  display: "flex",
  position: "relative",
  /* padding: 30px, */
  flexDirection: "column",
  height: "100vh",
  paddingTop: "0px",
  zIndex: "10",
}));
const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  padding: "30px 0px 30px 16px",
  backgroundColor: "#f9f9fc",
}));

const ModelHeader = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 18px Open Sans",
  variant: "span",
  color: "#4d4e4f",
}));
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const Label = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const FilterListContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ${`max-height: calc(100vh - 480px);`};
  overflow-y: auto;
  column-gap: 10px;
  row-gap: 10px;
  padding: 0px 20px;
  ::-webkit-scrollbar {
    width: 5px;
    background-color: #efeff4;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #999;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #666;
  }
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  chipLabel: {
    whiteSpace: "normal",
  },
  icon: {
    color: "#4D4E4F",
  },
});

const ChipLable = styled(Typography)`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
  margin-right: 4px;
`;
const ChipValue = styled(Typography)`
  margin: 0px;
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  word-wrap: break-word;
  width: 100%;
`;
const CustomChip = styled(Box)`
  display: flex;
  position: relative;
  padding: 20px;
  background-color: #e0e0e0;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  border-radius: 12px;
`;
const Column = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;

const FilterSidePanel = (props) => {
  const { userStore } = rootStore;
  const classes = useStyles();
  const [filters, setFilters] = useState({});
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [filterList, setFilterList] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [dateRange, setDateRange] = useState({});
  const [selected, setSelected] = useState([]);
  const [selectedVal, setSelectedVal] = useState({});

  //************************** Functions **********************************/

  const validateFitlerInputValue = () => {
    return Object.keys(filters).length > 0;
  };
  const handleAdd = () => {
    setShowAlert(false);
    let newList = {};
    if (Object.keys(filters).length > 0) {
      Object.keys(filters).map((item) => {
        newList[item] = filters[item];
      });
    }
    setSelectedFilter(null);
    setFilterList({ ...filterList, ...newList });
    resetFilters();
  };
  const handleCancel = () => {
    props.setOpen(false);
  };
  const handleDelete = (item) => {
    let newFilterList = Object.keys(filterList)
      .filter((key) => !key.includes(item))
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: filterList[key],
        });
      }, {});

    if (Object.keys(newFilterList).length === 0) {
      userStore.resetRawLeadFilters();
      props.setRefresh(true);
      props.setOpen(false);
    }

    setFilterList(newFilterList);
  };

  const handleApply = (e) => {
    e.preventDefault();
    if (Object.keys(filterList).length === 0) {
      setShowAlert(true);
    } else {
      handleFilterSubmit();
    }
  };

  const handleFilterSubmit = () => {
    userStore.updateRawLeadFilters(filterList);
    props.setRefresh(true);
    props.setOpen(false);
  };

  const getHeaderName = (field) => {
    let headerName = "";
    if (props.columns.length > 0) {
      props.columns.forEach((item) => {
        if (item.field === field) {
          headerName = item.headerName;
        }
      });
    }
    return headerName;
  };

  const resetFilters = () => {
    setDateRange({});
    setSelectedVal({});
    setFilters({});
    setSelected([]);
  };

  useEffect(() => {
    analytics.triggerEvent(
      4625000130,
      "filter_raw_leads_form_load",
      "raw_leads_tab",
      "",
      {}
    );
    setFilterList({ ...userStore.rawLeadFilters });
  }, []);
  //******************************END*********************************** */
  return (
    <>
      <Box role="presentation">
        <ModelWrapper>
          <HeaderWrapper>
            <Info color="primary" style={{ marginRight: "8px" }} />
            <ModelHeader>{getLocalizedText("filters")}</ModelHeader>
          </HeaderWrapper>
          <Column component={"form"} onSubmit={handleApply}>
            <List>
              <ListItem>
                <InputWrapper>
                  <Label>{getLocalizedText("select_filter")}</Label>
                  <FormControl
                    classes={{
                      root: classes.quantityRoot,
                    }}
                  >
                    <Select
                      classes={{
                        icon: classes.icon,
                      }}
                      name="segment"
                      displayEmpty
                      disableUnderline
                      defaultValue={null}
                      variant="outlined"
                      onChange={(e) => {
                        setShowAlert(false);
                        resetFilters();
                        setSelectedFilter(e.target.value);
                      }}
                      value={selectedFilter}
                      style={{
                        width: "100%",
                        height: "30px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        borderRadius: "0px",
                      }}
                    >
                      {props.columns.length > 0 &&
                        props.columns
                          .filter(
                            (e) => !Object.keys(filterList).includes(e.field)
                          )
                          .map((item, idx) => {
                            if (item.filterable) {
                              return (
                                <MenuItem
                                  style={{ fontSize: "12px" }}
                                  value={item}
                                  id={idx}
                                >
                                  {item.headerName}
                                </MenuItem>
                              );
                            }
                          })}
                    </Select>
                  </FormControl>
                </InputWrapper>
              </ListItem>
              <ListItem>
                <InputWrapper>
                  <Label>{getLocalizedText("filter_value")}</Label>

                  {selectedFilter === null ? (
                    <OutlinedInput
                      disabled
                      placeholder="Enter Value"
                      style={{
                        width: "100%",
                        height: "30px",
                        fontSize: "12px",
                      }}
                      value=""
                    />
                  ) : (
                    <FilterInputComponent
                      item={selectedFilter}
                      selectedVal={selectedVal}
                      setSelectedVal={setSelectedVal}
                      filters={filters}
                      setFilters={setFilters}
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  )}
                </InputWrapper>
              </ListItem>
              <ListItem>
                <ButtonWrapper
                  style={{ justifyContent: "flex-end", marginBottom: "20px" }}
                >
                  <Button
                    disabled={!validateFitlerInputValue()}
                    onClick={handleAdd}
                    variant="contained"
                    color="primary"
                    style={{ textTransform: "capitalize", width: "100%" }}
                  >
                    <Add /> {getLocalizedText("add_filter")}
                  </Button>
                </ButtonWrapper>
              </ListItem>
              <Divider style={{ marginBottom: "20px" }} />
              <ListItem>
                {Object.keys(filterList).length > 0 && (
                  <FilterListContainer>
                    {Object.keys(filterList).map((item, i) => {
                      let headerName = getHeaderName(item);
                      if (filterList[item]["type"] === "list") {
                        let value = filterList[item]["value"];
                        return (
                          <CustomChip style={{ flexDirection: "column" }}>
                            <Cancel
                              onClick={() => handleDelete(item)}
                              style={{
                                position: "absolute",
                                top: "2px",
                                right: "2px",
                                cursor: "pointer",
                              }}
                            />
                            <ChipLable>{headerName}</ChipLable>
                            <Column>
                              {value.length > 0 &&
                                value.map((list_item) => {
                                  return (
                                    <Row>
                                      <Circle
                                        style={{
                                          width: "8px",
                                          height: "8px",
                                          color: "gray",
                                          marginTop: "2px",
                                        }}
                                      />
                                      <ChipValue>{list_item}</ChipValue>
                                    </Row>
                                  );
                                })}
                            </Column>
                          </CustomChip>
                        );
                      } else if (filterList[item]["type"] === "range") {
                        return (
                          <>
                            <CustomChip>
                              <Cancel
                                onClick={() => handleDelete(item)}
                                style={{
                                  position: "absolute",
                                  top: "2px",
                                  right: "2px",
                                  cursor: "pointer",
                                }}
                              />
                              <ChipLable>{headerName}</ChipLable>
                              <ChipValue>{`${filterList[item]["value"]["start"]} to ${filterList[item]["value"]["end"]}`}</ChipValue>
                            </CustomChip>
                          </>
                        );
                      } else {
                        if (filterList[item]["dataType"] === "boolean") {
                          return (
                            <>
                              <CustomChip>
                                <Cancel
                                  onClick={() => handleDelete(item)}
                                  style={{
                                    position: "absolute",
                                    top: "2px",
                                    right: "2px",
                                    cursor: "pointer",
                                  }}
                                />
                                <ChipLable>{headerName}</ChipLable>
                                <ChipValue>
                                  {filterList[item]["value"] === true
                                    ? "True"
                                    : "False"}
                                </ChipValue>
                              </CustomChip>
                            </>
                          );
                        } else {
                          return (
                            <>
                              <CustomChip>
                                <Cancel
                                  onClick={() => handleDelete(item)}
                                  style={{
                                    position: "absolute",
                                    top: "2px",
                                    right: "2px",
                                    cursor: "pointer",
                                  }}
                                />
                                <ChipLable>{headerName}</ChipLable>
                                <ChipValue>
                                  {filterList[item]["value"]}
                                </ChipValue>
                              </CustomChip>
                            </>
                          );
                        }
                      }
                    })}
                  </FilterListContainer>
                )}
              </ListItem>
            </List>
            <List>
              <Divider style={{ marginBottom: "20px" }} />
              {showAlert && (
                <ListItem>
                  <Alert
                    style={{
                      width: "100%",
                      padding: "0px 20px",
                      fontSize: "12px",
                    }}
                    severity="warning"
                  >
                    {getLocalizedText("select_atleast_one_filter_to_apply")}
                  </Alert>
                </ListItem>
              )}
              <ListItem>
                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      analytics.triggerEvent(
                        4625000131,
                        "cancel_button_tap",
                        "filter_raw_leads_form",
                        "cancel_button",
                        {}
                      );
                      handleCancel();
                    }}
                    variant="contained"
                    color="inherit"
                    style={{ textTransform: "capitalize", width: "100%" }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>

                  <Button
                    onClick={() => {
                      analytics.triggerEvent(
                        4625000132,
                        "apply_button_tap",
                        "filter_raw_leads_form",
                        "apply_button",
                        {}
                      );
                    }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ textTransform: "capitalize", width: "100%" }}
                  >
                    {getLocalizedText("apply")}
                  </Button>
                </ButtonWrapper>
              </ListItem>
            </List>
          </Column>
        </ModelWrapper>
      </Box>
    </>
  );
};

export default FilterSidePanel;
