import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Footer from "../../components/landing_page_components/footer/Footer";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import { Bar } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import { DataGrid } from "@mui/x-data-grid";
import { TargetDetailsColumns } from "../../Db";
import { Brightness1, ChevronRight } from "@material-ui/icons";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Divider, IconButton } from "@material-ui/core";
import { StackedBarConfig } from "../../Config";
import { useEffect } from "react";
import rootStore from "../../stores/RootStore";
Chart.register(ArcElement);

const Container = styled.div`
  /* padding: 30px 0px; */
  width: 100%;
  margin-top: 70px;
  background-color: #f9f9fc;
  ${`min-height: calc(100vh - 139px);`};
`;
const Wrapper = styled.div`
  width: 95%;
  margin: auto;
  /* background-color: white; */
  display: flex;
  flex-direction: column;
  /* padding-top: 20px; */
`;
const Title = styled.p`
  margin: 0px;
  font: normal normal 600 28px Open Sans;
  /* text-align: center; */
  margin-bottom: 20px;
  color: #4d4e4f;
`;
const Label = styled.p`
  margin: 0px;
  font: normal normal normal 12px Open Sans;
  color: #a9a9a9;
`;
const Detail = styled.p`
  margin: 0px;
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const DetailsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: auto;
  margin-bottom: 20px;
  column-gap: 20px;
`;
const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 238px;
  height: 68px;
  background-color: white;
  padding: 0px 20px;
  border-radius: 8px;
`;
const TopSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const ChartsWrapper = styled.div`
  display: flex;
  width: 100%;
  column-gap: 20px;
  margin: auto;
  margin-bottom: 20px;
`;
const ChartTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ChartsTitle = styled.p`
  margin: 0px;
  font: normal normal normal 16px Open Sans;
  color: #a9a9a9;
`;
const BottomSection = styled.div`
  width: 100%;
  margin: auto;
`;
const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
`;
const LeftWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 20px;
  /* padding-top: 0px; */
  flex-direction: column;
  height: 335px;
  width: 100%;
  background-color: white;
  border-radius: 8px;
`;
const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
const RightWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
  /* height: 335px; */
  background-color: white;
  border-radius: 8px;
  /* padding-top: 0px; */

  width: 100%;
`;
const Text = styled.p`
  font: normal normal 600 16px Open Sans;
  margin: 0px;
  text-align: left;
  color: #4d4e4f;
`;
const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
`;
const Header = styled.p`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
const HorizontalDivider = styled.span`
  width: 100%;
  height: 1px;
  background-color: #e3e3e5;
  margin-left: 20px;
`;
const ProgressLabel = styled.div`
  display: flex;
  align-items: center;
`;
const ProgressText = styled.p`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
`;
const ProgressIndicator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 6px;
`;
const emptyData = {
  labels: ["Red", "Orange", "Blue"],
  // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
  datasets: [
    {
      label: "Popularity of colours",
      data: [95, 23, 96],
      // you can set indiviual colors for each bar
      backgroundColor: ["#ffbb11", "#ffbb11", "#ffbb11", "#f3ba2f", "#2a71d0"],
      borderWidth: 1,
    },
    {
      label: "Popularity of colours",
      data: [75, 63, 96],
      // you can set indiviual colors for each bar
      backgroundColor: ["#ecf0f1", "#ecf0f1", "#ecf0f1", "#f3ba2f", "#2a71d0"],
      borderWidth: 1,
    },
    {
      label: "Popularity of colours",
      data: [85, 23, 36],
      // you can set indiviual colors for each bar
      backgroundColor: ["#50AF95", "#50AF95", "#50AF95", "#f3ba2f", "#2a71d0"],
      borderWidth: 1,
    },
  ],
};
const TargetDetails = () => {
  let { id, name } = useParams();
  let navigate = useNavigate();
  const handleBreadCrump = () => {
    navigate(-1);
  };
  useEffect(() => {
    rootStore.authStore.updateLeadDetailStatus();
  }, []);
  return (
    <>
      <Container>
        <Wrapper>
          <TopWrapper>
            <Header
              style={{ color: "#185DD2", cursor: "pointer" }}
              onClick={handleBreadCrump}
            >
              Target
            </Header>
            <ChevronRight style={{ width: "16px", height: "16px" }} />
            <Header>{name && name}</Header>
          </TopWrapper>
          <Title>Target Vs Achievements</Title>
          <DetailsContainer>
            <DetailWrapper>
              <Label>Name</Label>
              <Detail>{name && name}</Detail>
            </DetailWrapper>
            <DetailWrapper>
              <Label>Target</Label>
              <Detail>100</Detail>
            </DetailWrapper>
            <DetailWrapper>
              <Label>Measure</Label>
              <Detail>custom_measure</Detail>
            </DetailWrapper>
            <DetailWrapper>
              <Label>Start Date</Label>
              <Detail>dd/mm/yyyy</Detail>
            </DetailWrapper>
            <DetailWrapper style={{ marginLeft: "-16px" }}>
              <Label>End Date</Label>
              <Detail>dd/mm/yyyy</Detail>
            </DetailWrapper>
          </DetailsContainer>
          <TopSection>
            <Row>
              <ChartsTitle>Dashboard</ChartsTitle>
              <HorizontalDivider />
            </Row>
            <ChartsWrapper>
              <LeftWrapper>
                <ChartTop>
                  <Text>Project Score</Text>
                  {/* <IconButton>
                    <MoreVertIcon />
                  </IconButton> */}
                </ChartTop>
                <Row style={{ justifyContent: "flex-end" }}>
                  <ProgressLabel style={{ marginRight: "30px" }}>
                    <ProgressIndicator style={{ backgroundColor: "#59E3A3" }} />
                    <ProgressText>Target Achieved</ProgressText>
                  </ProgressLabel>
                  <ProgressLabel>
                    <ProgressIndicator style={{ backgroundColor: "#DEDEE5" }} />
                    <ProgressText>Project Target</ProgressText>
                  </ProgressLabel>
                </Row>
                <Left>
                  <SemiCircleProgressBar
                    percentage={50}
                    strokeWidth={20}
                    diameter={350}
                    stroke={"#59E3A3"}
                    background={"#DEDEE5"}
                  />
                  <Text
                    style={{
                      position: "absolute",
                      top: "0px",
                      bottom: "0px",
                      left: "0px",
                      fontSize: "36px",
                      fontWeight: "bold",
                      right: "0px",
                      margin: "auto",
                      width: "auto",
                      inset: "unset",
                      marginBottom: "-50px",
                    }}
                  >
                    50%
                  </Text>
                </Left>
              </LeftWrapper>
              <RightWrapper>
                <ChartTop>
                  <Text>Team Score</Text>
                  {/* <IconButton>
                    <MoreVertIcon />
                  </IconButton> */}
                </ChartTop>
                <Right>
                  <Bar data={emptyData} options={StackedBarConfig.options} />
                  {/* <Text>Members</Text> */}
                </Right>
              </RightWrapper>
            </ChartsWrapper>
          </TopSection>
          <BottomSection>
            <div style={{ width: "100%", backgroundColor: "white" }}>
              <DataGrid
                autoHeight={true}
                rows={[
                  {
                    id: 0,
                    name: "Abhishek Kumar",
                    team: "Red",
                    target: 10,
                    actual: 2,
                    percentage: 20,
                  },
                  {
                    id: 1,
                    name: "Abhishek Kumar",
                    team: "Red",
                    target: 10,
                    actual: 2,
                    percentage: 20,
                  },
                  {
                    id: 2,
                    name: "Abhishek Kumar",
                    team: "Red",
                    target: 10,
                    actual: 2,
                    percentage: 20,
                  },
                  {
                    id: 3,
                    name: "Abhishek Kumar",
                    team: "Red",
                    target: 10,
                    actual: 2,
                    percentage: 20,
                  },
                ]}
                columns={TargetDetailsColumns}
                checkboxSelection={false}
                hideFooter={true}
                // loading={loading}
                disableColumnMenu={true}
                disableSelectionOnClick={true}
              />
            </div>
          </BottomSection>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
};

export default TargetDetails;
