import React from "react";
import { styled } from "@mui/material/styles";

import { observer } from "mobx-react-lite";
import { Box, Button, Modal, Typography } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import {
  getLocalizedText,
  dataRenderer,
  getFormattedMultilineText,
} from "../../../Functions";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const Form = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  width: 320px;
  column-gap: 20px;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 150px;
`;
const Label = styled(Typography)`
  font: normal normal 500 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const ButtonWrapper = styled(Box)`
  margin-top: 30px;
  display: flex;
  justify-content: center;

  align-items: center;
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 30px;
`;

const ViewProductModal = (props) => {
  const handleClose = () => {
    props.setOpen(false);
    props.setData({});
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <Container>
              <Row>
                <Info color="primary" />
                <Header>{getLocalizedText("info")}</Header>
              </Row>
              <Form>
                <InputContainer>
                  <InputWrapper>
                    <Label>Item Name</Label>
                    <Detail>{dataRenderer(props.data.item.name)}</Detail>
                  </InputWrapper>
                  <InputWrapper style={{ width: "100%" }}>
                    <Label>Description</Label>
                    {props.data.hasOwnProperty("desc") &&
                    props.data["desc"] !== null &&
                    props.data["desc"] !== "" ? (
                      <Detail
                        component="div"
                        style={{
                          whiteSpace: "pre-line",
                        }}
                      >
                        {getFormattedMultilineText(props.data["desc"])}
                      </Detail>
                    ) : (
                      <Detail>-</Detail>
                    )}
                  </InputWrapper>
                  <InputWrapper>
                    <Label>Quantity</Label>
                    <Detail>{dataRenderer(props.data.quantity)}</Detail>
                  </InputWrapper>
                  <InputWrapper>
                    <Label>Rate</Label>
                    <Detail>{dataRenderer(props.data.rate)}</Detail>
                  </InputWrapper>
                  <InputWrapper>
                    <Label>Amount</Label>
                    <Detail>{dataRenderer(props.data.amount)}</Detail>
                  </InputWrapper>
                  <InputWrapper>
                    <Label>Tax (%)</Label>
                    <Detail>{dataRenderer(props.data.tax)}</Detail>
                  </InputWrapper>
                  <InputWrapper>
                    <Label>Discount (%)</Label>
                    <Detail>{dataRenderer(props.data.discount)}</Detail>
                  </InputWrapper>
                </InputContainer>

                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      handleClose();
                    }}
                    type="button"
                    variant="outlined"
                    color="primary"
                    style={{
                      width: "120px",
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    {getLocalizedText("close")}
                  </Button>
                </ButtonWrapper>
              </Form>
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default observer(ViewProductModal);
