import { Box, Card, IconButton, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import { Delete } from "@material-ui/icons";
import { IsoToLocalDate, IsoToLocalTime } from "../../Functions";
import { operatorLabelMap } from "../../Db";

const CardWrapper = styled(Card)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  padding: 16px;
  background-color: #f9f9fc;
  border-radius: 8px;
  align-items: flex-start;
  gap: 10px;
  border: 1px solid rgb(218, 220, 224);
  box-shadow: none;
`;
const Text = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  margin: 0px;
  color: #4d4e4f;
`;
const TextSm = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  margin: 0px;
  color: #4d4e4f;
  margin-bottom: 8px;
`;
const TextMd = styled(Typography)`
  font: normal normal 600 12px Open Sans;

  margin: 0px;
  color: #979799;
`;
const TextWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const Wrapper = styled(Box)`
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: space-between;
`;
const CardWidget = ({
  operator,
  score,
  onRemove,
  value,
  columnName,
  dataType,
  canShowBestMatch,
}) => {
  const getValue = () => {
    if (operator === "exists") {
      return "";
    } else {
      if (dataType === "string" || dataType === "integer") {
        if (operator === "in" || operator === "not in") {
          let str = value.toString();
          return str;
        } else if (operator === "between" || operator === "not between") {
          let min = parseInt(value["start"]);
          let max = parseInt(value["end"]);
          return `${min} And ${max}`;
        } else {
          return value;
        }
      } else if (dataType === "date" || dataType === "datetime") {
        if (operator === "between" || operator === "not between") {
          if (dataType === "datetime") {
            let start_time = IsoToLocalTime(value["start"].toString());
            let end_time = IsoToLocalTime(value["end"].toString());
            return `${start_time} And ${end_time}`;
          } else {
            let start_date = IsoToLocalDate(value["start"].toString());
            let end_date = IsoToLocalDate(value["end"].toString());

            return `${start_date} And ${end_date}`;
          }
        } else {
          if (dataType === "datetime") {
            let date = IsoToLocalTime(value.toString());
            return date;
          } else {
            let date = IsoToLocalDate(value.toString());
            return date;
          }
        }
      }
    }
  };

  return (
    <CardWrapper>
      <Wrapper>
        <TextWrapper>
          {canShowBestMatch && <TextSm>Best Match </TextSm>}
          <Text>Score : {score}</Text>
          <TextMd>{`${columnName} ${
            operatorLabelMap[operator]
          } ${getValue()}`}</TextMd>
        </TextWrapper>
      </Wrapper>
      <Box sx={{ display: "flex", columnGap: "10px" }}>
        <IconButton style={{ padding: "0px" }} onClick={onRemove}>
          <Delete color="error" style={{ width: "20px", height: "20px" }} />
        </IconButton>
      </Box>
    </CardWrapper>
  );
};

export default CardWidget;
