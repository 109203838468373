import React from "react";
import { styled } from "@mui/material/styles";
import { AppBar, Box, Chip, Toolbar, Typography } from "@mui/material";
import { Button, Grid } from "@material-ui/core";
import { dataRenderer, validateDisabledSubFeature } from "../../Functions";
import { Circle } from "@mui/icons-material";

const ModelWrapper = styled(Box)`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 24px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 8px;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ViewColumnSidepanel = ({
  data,
  handleEdit,
  handleDelete,
  scope = "columns_listing",
}) => {
  const renderFilterInput = (input) => {
    let list = [];
    if (input !== "" && input !== null) {
      list = input.split(",");
    }
    if (list.length > 1) {
      return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {list.map((item) => {
            return (
              <Box sx={{ display: "flex", columnGap: "8px" }}>
                <Circle
                  style={{
                    width: "8px",
                    height: "8px",
                    marginTop: "6px",
                    color: "gray",
                  }}
                />
                <Detail>{item}</Detail>
              </Box>
            );
          })}
        </Box>
      );
    } else if (list.length === 1) {
      return <Detail>{list[0]}</Detail>;
    } else {
      return "-";
    }
  };

  const renderBoolValue = (value) => {
    if (value !== undefined && value !== null && value !== "") {
      if (value === false) {
        return <Chip color="warning" label={"No"} />;
      } else {
        return <Chip color="success" label={"Yes"} />;
      }
    }
    return "-";
  };

  const canRenderFilterInput = () => {
    if (scope === "columns_listing") {
      return data["child_of"] === null;
    }
    return true;
  };
  return (
    <Box sx={{ width: 500 }}>
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          width: 500,
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <ModelHeader>Column Details</ModelHeader>
            {scope === "columns_listing" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "16px",
                }}
              >
                <Button
                  disabled={data["child_of"] !== null}
                  style={{
                    textTransform: "none",
                    fontSize: "14px",
                    fontWeight: "bold",
                    width: "fit-content",
                  }}
                  onClick={handleEdit}
                  color="primary"
                  variant="outlined"
                >
                  Edit
                </Button>
                <Button
                  disabled={
                    (data.hasOwnProperty("system") &&
                      data["system"] === true) ||
                    data["child_of"] !== null
                  }
                  style={{
                    textTransform: "none",
                    fontSize: "14px",
                    fontWeight: "bold",
                    width: "fit-content",
                  }}
                  onClick={handleDelete}
                  color="secondary"
                  variant="outlined"
                >
                  Delete
                </Button>
              </Box>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <ModelWrapper>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Name</Label>
              <Detail>{dataRenderer(data["headerName"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Group</Label>
              <Detail>{dataRenderer(data["group"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Data Type</Label>
              <Detail>{dataRenderer(data["data_type"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>System Parameter</Label>
              <Detail>{renderBoolValue(data["system"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Derived</Label>
              <Detail>{renderBoolValue(data["derived"])}</Detail>
            </InputWrapper>
          </Grid>

          <Grid item xs={6}>
            <InputWrapper>
              <Label>Filterable</Label>
              <Detail>{renderBoolValue(data["filterable"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Visible</Label>
              <Detail>{renderBoolValue(data["is_visible"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Show in Create Form</Label>
              <Detail>{renderBoolValue(data["enable_for_create"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Show in Edit Form</Label>
              <Detail>{renderBoolValue(data["show_in_edit_form"])}</Detail>
            </InputWrapper>
          </Grid>
          {validateDisabledSubFeature("lead_forms") && (
            <Grid item xs={6}>
              <InputWrapper>
                <Label>Is Form Field</Label>
                <Detail>{renderBoolValue(data["is_form_field"])}</Detail>
              </InputWrapper>
            </Grid>
          )}
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Editable</Label>
              <Detail>{renderBoolValue(data["editable"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Filter Type</Label>
              <Detail>{dataRenderer(data["filter_type"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Required</Label>
              <Detail>{renderBoolValue(data["required"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Array</Label>
              <Detail>{renderBoolValue(data["is_array"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Custom Param</Label>
              <Detail>{renderBoolValue(data["is_custom_param"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Link</Label>
              <Detail>{renderBoolValue(data["is_link"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Multiline</Label>
              <Detail>{renderBoolValue(data["is_multiline"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Visible</Label>
              <Detail>{renderBoolValue(data["is_visible"])}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Parent</Label>
              <Detail>{renderBoolValue(data["parent"])}</Detail>
            </InputWrapper>
          </Grid>
          {canRenderFilterInput() && (
            <Grid item xs={12}>
              <InputWrapper>
                <Label>Filter Input</Label>
                <Detail>{renderFilterInput(data["filter_input"])}</Detail>
              </InputWrapper>
            </Grid>
          )}
        </Grid>
      </ModelWrapper>
    </Box>
  );
};

export default ViewColumnSidepanel;
