import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import rootStore from "../../stores/RootStore";
import LeadsAssignModal from "../../components/lead_assign_modal/LeadsAssignModal";
import { observer } from "mobx-react-lite";
import { pageSize } from "../../Config";
import {
  getNotificationsColumnsApi,
  getNotificationsDetailApi,
} from "../../Api";
import { IconButton } from "@material-ui/core";
import Footer from "../../components/landing_page_components/footer/Footer";
import { Assignment, ChevronRight } from "@material-ui/icons";
import { checkFeaturePermission } from "../../Functions";
import {
  FormattedColumnsFilters,
  leadListingIconsColumn,
} from "../../ColumnUtils";
import { Box, CircularProgress, styled, Typography } from "@mui/material";
import NotesViewWidget from "../../components/notification_page_components/NotesViewWidget";
import LeadsDatatableWidget from "../../components/notification_page_components/LeadsDatatableWidget";
const ParentContainer = styled(Box)`
  ${`min-height: calc(100vh - 148px);`};
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9fc;
  margin-top: 70px;
  width: 100%;
`;
const Wrapper = styled(Box)`
  display: flex;
  margin: auto;
  width: 95%;
`;
const Container = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const TopWrapper = styled(Box)`
  width: 95%;
  display: flex;
  align-items: center;
  min-height: 40px;
  margin: 0px auto;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const MenuWrapper = styled(Box)`
  display: flex;
  align-items: center;
`;

const NotificationSection = () => {
  const [row, setRow] = useState([]);
  const [assignState, setAssignState] = useState(0);
  const { type, title } = useParams();
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedLeadIDs, setSelectedLeadIDs] = useState([]);
  const [columns, setColumns] = useState([]);
  const [size, setSize] = useState(pageSize);
  const columnsList = [...rootStore.userStore.AllColumnsList];

  const fetchNotificationData = async ({ page, page_size }) => {
    setAssignState(0);
    setSelectedLeadIDs([]);
    setRow([]);
    let tempDate = new Date();
    tempDate.setHours(10);
    tempDate.setMinutes(0);
    tempDate = tempDate.toUTCString();
    let payload = { notification_type: type, start_time: tempDate };
    let response = await getNotificationsDetailApi({
      page_no: page,
      page_size: page_size,
      payload: payload,
    });

    if (Object.keys(response).length > 0) {
      setRow(response.items);
      setCount(response.item_count);
    } else {
      setRow([]);
      setCount(0);
    }
  };
  const fetchNotificationColumns = async () => {
    setColumns([]);
    let response = await getNotificationsColumnsApi();

    let tempColumns = [];
    response.forEach((item) => {
      let index = columnsList.findIndex(
        (column) => column["field"] === item["field"]
      );
      if (index !== -1) {
        tempColumns.push(columnsList[index]);
      }
    });

    let formattedColumns = FormattedColumnsFilters({
      columnMetaList: tempColumns,
      scope: "notification_page",
    });
    setColumns(formattedColumns);
  };

  const init = async () => {
    setLoading(true);
    await fetchNotificationColumns();
    await fetchNotificationData({ page: 0, page_size: pageSize });
    setLoading(false);
  };

  const refetch = async () => {
    setLoading(true);
    await fetchNotificationData({ page: 0, page_size: pageSize });
    setLoading(false);
    setRefresh(false);
  };

  useEffect(() => {
    rootStore.authStore.updateLeadDetailStatus();
    window.scrollTo(0, 0);
    init();
  }, [type]);

  useEffect(() => {
    if (refresh) {
      refetch();
    }
  }, [refresh]);
  const handlePageSize = async (e) => {
    setLoading(true);
    setSize(e.target.value);
    setPage(0);
    await fetchNotificationData({ page: 0, page_size: e.target.value });
    setLoading(false);
  };
  const handlePagination = async (e, page) => {
    setLoading(true);
    setPage(page - 1);
    await fetchNotificationData({ page: page - 1, page_size: size });
    setLoading(false);
  };

  const sortIdsBasedOnListingOrder = () => {
    // Create a map to store the index of each object's ID in the list of objects
    const idIndexMap = new Map(row.map((obj, index) => [obj.id, index]));
    let ids = [...selectedLeadIDs];
    // Sort the list of IDs based on their index in the list of objects
    return ids.sort((a, b) => idIndexMap.get(a) - idIndexMap.get(b));
  };

  const handleSelectionChange = (event) => {
    const selectedRows = event.api.getSelectedRows();
    const selectedIds = selectedRows.map((row) => row.id);
    setAssignState(selectedIds.length);
    setSelectedLeadIDs(selectedIds);
  };

  const getColumnListForDatatable = () => {
    let len = columns.length;
    if (len > 0) {
      columns[len - 1]["minWidth"] = columns[len - 1]["width"];
      columns[len - 1]["flex"] = 1;
    }
    if (selectedLeadIDs.length > 0) {
      leadListingIconsColumn[0][
        "headerName"
      ] = `${selectedLeadIDs.length} Selected`;
    } else {
      leadListingIconsColumn[0]["headerName"] = "";
    }
    return leadListingIconsColumn.concat(columns);
  };

  const onCellClicked = (params) => {
    if (params.colDef.field === "icons") {
      params.event.stopPropagation();
      params.event.preventDefault();
    }
  };

  return (
    <>
      <ParentContainer>
        <TopWrapper>
          <Header>Notifications</Header>
          <ChevronRight style={{ width: "16px", height: "16px" }} />
          <Header>{title}</Header>
        </TopWrapper>

        {type !== "notification_lead_note_mentions" &&
          checkFeaturePermission("notification_assign_lead") && (
            <Wrapper
              style={{
                backgroundColor: "white",
                justifyContent: "flex-end",
                marginBottom: "20px",
              }}
            >
              <MenuWrapper>
                <IconButton
                  disabled={assignState < 1}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <Assignment style={{ cursor: "pointer" }} />
                </IconButton>
              </MenuWrapper>
            </Wrapper>
          )}
        <Container>
          <Wrapper>
            {(() => {
              if (!loading) {
                if (type === "notification_lead_note_mentions") {
                  return (
                    <NotesViewWidget
                      count={count}
                      handlePageSize={handlePageSize}
                      handlePagination={handlePagination}
                      notesData={row}
                      page={page}
                      size={size}
                    />
                  );
                } else {
                  return (
                    <LeadsDatatableWidget
                      count={count}
                      handlePageSize={handlePageSize}
                      handlePagination={handlePagination}
                      handleSelectionChange={handleSelectionChange}
                      onCellClicked={onCellClicked}
                      page={page}
                      size={size}
                      tableColumns={getColumnListForDatatable()}
                      tableData={row}
                    />
                  );
                }
              } else {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      height:
                        type === "notification_lead_note_mentions"
                          ? "calc(100vh - 205px)"
                          : "calc(100vh - 253px)",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "white",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                );
              }
            })()}

            {open && (
              <LeadsAssignModal
                open={open}
                setOpen={setOpen}
                leads={sortIdsBasedOnListingOrder()}
                setRefresh={setRefresh}
                refresh={refresh}
                totalLeadCount={count}
                pageSize={size}
                getAllLeadIDsToAssign={() => {}}
                enableAssignAllLead={false}
              />
            )}
          </Wrapper>
        </Container>
      </ParentContainer>
      <Footer />
    </>
  );
};

export default observer(NotificationSection);
