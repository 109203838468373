import React from "react";
import styled from "styled-components";
import { Box, Button, CircularProgress, Modal } from "@material-ui/core";
import { myTheme } from "../../themeUtils";

const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  min-width: 40vw;
  min-height: 40vh;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 20px;
`;
const ModelWrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
`;
const Image = styled.img`
  height: auto;
  width: 100%;
  margin: auto;
`;
const ImageWrapper = styled.div`
  max-height: 80vh;
  max-width: 80vw;
  overflow: auto;
`;
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const ViewImageFile = (props) => {
  const handleClose = () => {
    props.setOpen(false);
  };
  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {Object.keys(props.data).length > 0 ? (
          <ModelContainer>
            <Box>
              <ModelWrapper>
                <ImageWrapper>
                  <Image src={props.data.url} alt="loading..." />
                </ImageWrapper>
              </ModelWrapper>
            </Box>
            <ButtonWrapper>
              <Button
                onClick={handleClose}
                type="button"
                variant="contained"
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  ...myTheme.Button.btnBlue,
                }}
              >
                Close
              </Button>
            </ButtonWrapper>
          </ModelContainer>
        ) : (
          <ModelContainer
            style={{
              display: "flex",
              width: "40vh",
              height: "40vw",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </ModelContainer>
        )}
      </Modal>
    </>
  );
};

export default ViewImageFile;
