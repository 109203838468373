import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, Card, Divider, Typography } from "@mui/material";
import { TaskAlt } from "@mui/icons-material";
import { dataRenderer } from "../../Functions";

const Container = styled(Box)`
  display: flex;
  padding: 20px;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  row-gap: 16px;
`;
const Top = styled(Box)`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;
const TextSpacedBetween = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Text1 = styled(Typography)`
  margin: 0px;
  font: normal normal 600 20px Open Sans;
  color: #4d4e4f;
`;

const IconTextWrapper = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;
const PlanDetailsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;
const Text3 = styled(Typography)`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
  color: #4d4e4f;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: flex-end;
`;
const PlanCardWidget = ({ details }) => {
  return (
    <Card sx={{ height: "100%" }}>
      <Container>
        <Top>
          <TextSpacedBetween>
            <Text1>
              <span style={{ color: "#aca7a6" }}>Plan : </span>
              {dataRenderer(details["name"])}
            </Text1>
            <Text1>
              {`${dataRenderer(details["currency"])} ${dataRenderer(
                details["price_per_seat"]
              )}`}{" "}
              <span style={{ color: "#aca7a6" }}>/ month</span>
            </Text1>
          </TextSpacedBetween>
        </Top>
        <PlanDetailsWrapper>
          {details.hasOwnProperty("key_features") &&
            details["key_features"] !== null &&
            details["key_features"].length > 0 &&
            details["key_features"].map((detail, index) => {
              return (
                <IconTextWrapper key={index}>
                  <TaskAlt
                    style={{ height: "18px", width: "18px" }}
                    color="primary"
                  />{" "}
                  <Text3>{detail}</Text3>
                </IconTextWrapper>
              );
            })}
        </PlanDetailsWrapper>
        {/* <Divider orientation="horizontal" />
        <ButtonWrapper>
          <Button
            variant="text"
            color="primary"
            style={{
              textTransform: "none",
              fontSize: "14px",
              fontWeight: "bold",
              width: "fit-content",
              textDecoration: "underline",
              padding: "0px",
            }}
          >
            View Details
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{
              textTransform: "none",
              fontSize: "14px",
              fontWeight: "bold",
              width: "fit-content",
            }}
          >
            Upgrade
          </Button>
        </ButtonWrapper> */}
      </Container>
    </Card>
  );
};

export default PlanCardWidget;
