import { Box, Card, IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import { Delete, Edit, Visibility } from "@material-ui/icons";

const CardWrapper = styled(Card)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100%;
  height: 60px;
  padding: 16px;
  background-color: #f9f9fc;
  align-items: center;
  border-radius: 8px;
`;
const Text = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  margin: 0px;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  width: 100%;
  overflow: hidden;
`;
const TextWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;
const ChildCardWidget = ({ cardInfo, onRemove, onEdit, onView }) => {
  const getDisplayName = () => {
    if (cardInfo["_update_required"] === true) {
      return cardInfo["display_name"];
    } else {
      return cardInfo["headerName"];
    }
  };
  return (
    <CardWrapper>
      <TextWrapper>
        <Tooltip title={getDisplayName()} placement="top" arrow>
          <Text>{getDisplayName()}</Text>
        </Tooltip>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
            justifyContent: "center",
          }}
        >
          <Tooltip title={"View"} arrow>
            <IconButton style={{ padding: "0px" }} onClick={() => onView()}>
              <Visibility
                color="primary"
                style={{ height: "18px", width: "18px" }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Edit"} arrow>
            <IconButton style={{ padding: "0px" }} onClick={() => onEdit()}>
              <Edit color="primary" style={{ height: "18px", width: "18px" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Remove"} arrow>
            <IconButton style={{ padding: "0px" }} onClick={() => onRemove()}>
              <Delete color="error" style={{ height: "18px", width: "18px" }} />
            </IconButton>
          </Tooltip>
        </Box>
      </TextWrapper>
    </CardWrapper>
  );
};

export default ChildCardWidget;
