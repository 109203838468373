/* eslint-disable no-unused-vars */
import { AddBoxOutlined, Edit, Delete, MoreHoriz } from "@material-ui/icons";
import React, { useState, useEffect, useMemo } from "react";
import { styled } from "@mui/material/styles";
import { deleteStatusToStageApi, getAdminStatusToStageApi } from "../../Api";
import { AdminStatusToStageColumns } from "../../Db";
import AddSatusToStageModal from "../admin_setting_modals/AddSatusToStageModal";
import analytics from "../../Analytics";
import { AgGridReact } from "ag-grid-react";
import { CircularProgress, Drawer } from "@material-ui/core";
import ViewUpdateFieldsModal from "../admin_setting_modals/ViewUpdateFieldsModal";
import { dataRenderer } from "../../Functions";
import { Box, IconButton, Tooltip } from "@mui/material";
import CustomConfirmationDialog from "../alert_dialogue/CustomConfirmationDialog";
import MessageWithLoadingStateCustomDialog from "../alert_dialogue/MessageWithLoadingStateCustomDialog";
import ViewAdminStageDirectiveSidepanel from "../admin_setting_modals/view_sidepanels/ViewAdminStageDirectiveSidepanel";

const Bottom = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
`;

const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 226px);`};
  background-color: white;
  overflow-x: auto;
`;
const AddBtnWrapper = styled(Box)`
  position: absolute;
  right: 5px;
  top: 5px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
`;
const StatusToStageTab = (props) => {
  const [row, setRow] = useState();
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [editableData, setEditableData] = useState({});
  const [viewUpdateFields, setViewUpdateFields] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [selectedRowID, setSelectedRowID] = useState("");
  const [loading, setLoading] = useState(true);
  const [fetchingApi, setFetchingApi] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [openSubmitResponseDialog, setOpenSubmitResponseDialog] =
    useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [
    openViewStageDirectiveDetailsPanel,
    setViewStageDirectiveDetailsPanel,
  ] = useState(false);

  const compareFunction = (a, b) => {
    const firstComparison = a.lead_stage.stage.localeCompare(
      b.lead_stage.stage
    );

    if (firstComparison === 0) {
      return a.lead_status.status.localeCompare(b.lead_status.status);
    }

    return firstComparison;
  };
  const getStatusList = async () => {
    setLoading(true);
    let response = await getAdminStatusToStageApi();
    let sortedData = response.sort(compareFunction);
    setRow(sortedData);
    setLoading(false);
  };
  useEffect(() => {
    getStatusList();
  }, [refresh]);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const handleDelete = async () => {
    setShowConfirmationDialog(false);
    setFetchingApi(true);
    setOpenSubmitResponseDialog(true);
    let response = await deleteStatusToStageApi({
      payload: {
        status_stage_id: selectedRowID,
      },
    });
    if (!response.hasError()) {
      setIsSubmitFail(false);
      setSuccessMessage("Status-Stage mapping deleted successfully!");
      setFetchingApi(false);
      await getStatusList();
    } else {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
      setFetchingApi(false);
    }
    selectedRowID("");
  };

  const actionColumn = [
    {
      field: "update_fields",
      headerName: "FIELDS TO UPDATE",
      width: 150,
      cellRenderer: (params) => {
        if (params.data.update_fields.length > 0) {
          return (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setEditableData(params.data);
                setViewUpdateFields(true);
              }}
            >
              <MoreHoriz color="primary" />
            </IconButton>
          );
        } else {
          return "-";
        }
      },
    },
    {
      field: "lead_stage",
      headerName: "JUMP TO STAGE",
      sortable: false,

      width: 230,
      cellRenderer: (params) => {
        let value =
          params.data.jump_to !== null
            ? dataRenderer(params.data.jump_to.stage)
            : "-";

        return (
          <>
            <Tooltip title={value}>
              <span className="csutable-cell-trucate">{value}</span>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "ACTIONS",
      sortable: false,
      width: 70,
      flex: 1,
      resizable: false,
      cellRenderer: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip title={"Edit"}>
              <IconButton
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  analytics.triggerEvent(
                    4625000221,
                    "edit_button_tap",
                    "project_statustostage_page",
                    "edit_button",
                    {}
                  );
                  setEditableData(params.data);
                  setIsEdit(true);
                  setOpen(true);
                }}
              >
                <Edit
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Delete"}>
              <IconButton
                color="error"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setSelectedRowID(params.data.id);
                  setShowConfirmationDialog(true);
                }}
              >
                <Delete
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];
  return (
    <>
      <Bottom>
        {!loading ? (
          <TableWrapper style={{ position: "relative" }}>
            <AddBtnWrapper>
              <AddBoxOutlined
                onClick={() => {
                  analytics.triggerEvent(
                    4625000217,
                    "add_button_tap",
                    "project_statustostage_page",
                    "add_button",
                    {}
                  );
                  setOpen(true);
                }}
                style={{
                  width: "40px",
                  height: "40px",
                }}
              />
            </AddBtnWrapper>
            <div className="ag-theme-alpine" style={{ width: "100%" }}>
              <AgGridReact
                domLayout="autoHeight"
                gridOptions={gridOptions}
                rowData={row}
                defaultColDef={defaultColDef}
                columnDefs={AdminStatusToStageColumns.concat(actionColumn)}
                animateRows={true}
                suppressCellFocus
                pagination={false}
                className="paginated-ag-grid"
                onRowClicked={(row) => {
                  if (row.event.defaultPrevented) {
                    return null;
                  }
                  setEditableData(row.data);
                  setViewStageDirectiveDetailsPanel(true);
                }}
              />
            </div>
          </TableWrapper>
        ) : (
          <TableWrapper
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </TableWrapper>
        )}
      </Bottom>
      {open && (
        <AddSatusToStageModal
          id={props.id}
          setOpen={setOpen}
          open={open}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          editableData={editableData}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}
      {viewUpdateFields && (
        <ViewUpdateFieldsModal
          setOpen={setViewUpdateFields}
          open={viewUpdateFields}
          editableData={editableData}
        />
      )}{" "}
      {openSubmitResponseDialog && (
        <MessageWithLoadingStateCustomDialog
          open={openSubmitResponseDialog}
          setOpen={setOpenSubmitResponseDialog}
          successMessage={successMessage}
          errorMessage={errorMessage}
          loading={fetchingApi}
          isError={isSubmitFail}
        />
      )}
      {showConfirmationDialog && (
        <CustomConfirmationDialog
          message={"Are you sure you want to delete this mapping?"}
          open={showConfirmationDialog}
          setOpen={setShowConfirmationDialog}
          handleConfirm={() => {
            handleDelete();
          }}
        />
      )}
      {openViewStageDirectiveDetailsPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openViewStageDirectiveDetailsPanel}
          onClose={() => {
            setViewStageDirectiveDetailsPanel(false);
          }}
        >
          <ViewAdminStageDirectiveSidepanel details={editableData} />
        </Drawer>
      )}
    </>
  );
};

export default StatusToStageTab;
