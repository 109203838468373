import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import Chip from "@mui/material/Chip";
import { Box, Tooltip } from "@mui/material";

const ItemType = "CHIP";

const DraggableUpdateFieldChipComponent = ({ label, chipKey, index, moveChip, handleDelete, isDisabled, isRequired }) => {
    const ref = useRef(null);
  
    const [, drop] = useDrop({
      accept: ItemType,
      hover(item, monitor) {
        if (!ref.current) {
          return;
        }
        const dragKey = item.chipKey;  // Use chipKey (which is the object key)
        const hoverKey = chipKey;      // Use chipKey for hover as well
  
        if (dragKey === hoverKey) {
          return;
        }
  
        const hoverBoundingRect = ref.current?.getBoundingClientRect();
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset();
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
  
        if (item.index < index && hoverClientY < hoverMiddleY) {
          return;
        }
  
        if (item.index > index && hoverClientY > hoverMiddleY) {
          return;
        }
  
        moveChip(dragKey, hoverKey);  // Pass keys, not indices
        item.index = index;  // Update index for drag item
      },
    });
  
    const [{ isDragging }, drag] = useDrag({
      type: ItemType,
      item: { chipKey, index },  // Pass chipKey in the item
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
  
    drag(drop(ref));
  
    return (
      <Box
        sx={{ cursor: "pointer" }}
        ref={ref}
        style={{ opacity: isDragging ? 0.5 : 1 }}
      >
        <Tooltip title={label} arrow>
          <Chip
            disabled={isDisabled}
            color={isRequired ? "warning" : "default"}
            label={label}
            onDelete={handleDelete}
          />
        </Tooltip>
      </Box>
    );
  };
  

export default DraggableUpdateFieldChipComponent;
