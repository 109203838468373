import React from "react";
import { Box, MenuItem, styled, Typography } from "@mui/material";
import { FormControl, makeStyles, Select } from "@material-ui/core";

const Text = styled(Typography)`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
  margin-right: 8px;
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #F4F4F4",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #F4F4F4",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #F4F4F4",
      borderRadius: "4px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const PageSizeDropdownV2 = ({
  handlePageSize,
  pageSize,
  pageSizeOptionList,
}) => {
  
  const classes = useStyles();
  return (
    <Row>
      <Text>Row Per Page :</Text>
      <FormControl
        classes={{
          root: classes.quantityRoot,
        }}
      >
        <Select
          classes={{
            icon: classes.icon,
          }}
          variant="outlined"
          disableUnderline
          displayEmpty
          value={pageSize}
          onChange={handlePageSize}
          style={{
            fontSize: "12px",
            width: "100px",
            fontWeight: "bold",
            height: "30px",
            padding: "auto 12px",
          }}
        >
          {pageSizeOptionList.map((option, idx) => {
            return (
              <MenuItem key={idx} value={option}>
                {option}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Row>
  );
};

export default PageSizeDropdownV2;
