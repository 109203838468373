import { action, makeObservable, observable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import moment from "moment";
import { getNotificationsApi } from "../Api";
import rootStore from "../stores/RootStore";

class NotificationState {
  _notificationDuration = 15;
  interval = null;
  notificationList = {};
  notificationState = {
    last_fetched_at: "",
    snooze_details: {},
    canShowBadge: false,
    notitications_viewed: [],
  };
  constructor() {
    makeObservable(this, {
      notificationList: observable,
      notificationState: observable,
      _notificationDuration: observable,
      interval: observable,
      updateNotificationLastFetched: action,
      isInSnooze: action,
      setSnoozeForNotification: action,
      dismissNotification: action,
      fetchNotifications: action,
      resetStore: action,
      canFetchNotifications: action,
      canShowNotificationBadge: action,
      updateNotificationBadgeState: action,
      updateNotificationViewedList: action,
      startFetchNoticationAtIntervals: action,
      stopFetchNotificationAtIntervals: action,
      checkAndFetchNotifications: action,
      isMoreThanNotificationDuration: action,
      fetchNotificationsOnProjectChange: action,
    });

    makePersistable(this, {
      name: NotificationState,
      storage: window.localStorage,
      expireIn: 86400000,
      removeOnExpiration: true,
      stringify: true,
      debugMode: false,
      properties: ["notificationState"],
    });
  }

  isMoreThanNotificationDuration = (datetime) => {
    const now = moment();
    const then = moment(datetime);
    const diffMinutes = now.diff(then, "minutes");
    return diffMinutes > this._notificationDuration;
  };

  canFetchNotifications = () => {
    if (this.notificationState["last_fetched_at"] !== "") {
      if (
        this.isMoreThanNotificationDuration(
          this.notificationState["last_fetched_at"]
        )
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  updateNotificationViewedList = (notificationType) => {
    if (
      !this.notificationState.notitications_viewed.includes(notificationType)
    ) {
      let tempList = [...this.notificationState["notitications_viewed"]];
      tempList.push(notificationType);
      this.notificationState["notitications_viewed"] = tempList;
    }
  };

  updateNotificationLastFetched = () => {
    let currentTime = moment().seconds(0).milliseconds(0).toISOString();
    this.notificationState["last_fetched_at"] = currentTime;
  };

  isInSnooze(notificationType) {
    if (Object.keys(this.notificationState["snooze_details"]).length === 0) {
      return false;
    } else {
      const snoozeEnd = this.notificationState["snooze_details"].hasOwnProperty(
        notificationType
      )
        ? this.notificationState["snooze_details"][notificationType]
        : "";
      if (snoozeEnd !== "") {
        const endDateTime = moment(snoozeEnd);
        return moment().isBefore(endDateTime);
      } else {
        return false;
      }
    }
  }
  setSnoozeForNotification = (notificationType) => {
    let tempSnoozeTime = moment()
      .add(1, "hour")
      .seconds(0)
      .milliseconds(0)
      .toISOString();
    this.notificationState["snooze_details"][notificationType] = tempSnoozeTime;
    let tempList = { items: [], item_count: 0 };
    for (let i = 0; i < this.notificationList["items_count"]; i++) {
      if (
        this.isInSnooze(
          this.notificationList["items"][i]["notification_type"]
        ) === false
      ) {
        tempList["items_count"] += 1;
        tempList["items"].push(this.notificationList["items"][i]);
      }
    }
    this.notificationList = tempList;
  };

  dismissNotification = (notificationType) => {
    let tempList = { items: [], items_count: 0 };
    for (let i = 0; i < this.notificationList["items_count"]; i++) {
      if (
        this.notificationList["items"][i]["notification_type"] !==
        notificationType
      ) {
        tempList["items_count"] += 1;
        tempList["items"].push(this.notificationList["items"][i]);
      }
    }
    this.notificationList = tempList;
  };

  fetchNotifications = async () => {
    let response = await getNotificationsApi();
    if (Object.keys(response).length > 0) {
      this.notificationList["items_count"] = response["items_count"];
      this.notificationList["items"] = response["items"];
      this.notificationState["canShowBadge"] = true;
      this.notificationState["notitications_viewed"] = [];
    } else {
      this.notificationList["items_count"] = 0;
      this.notificationList["items"] = [];
      this.notificationState["canShowBadge"] = false;
      this.notificationState["notitications_viewed"] = [];
    }
    this.updateNotificationLastFetched();
    if (
      Object.keys(this.notificationList).length > 0 &&
      this.notificationList["items_count"] !== 0
    ) {
      let tempList = { items: [], items_count: 0 };
      for (let i = 0; i < this.notificationList["items_count"]; i++) {
        if (
          this.isInSnooze(
            this.notificationList["items"][i]["notification_type"]
          ) === false
        ) {
          tempList["items_count"] += 1;
          tempList["items"].push(this.notificationList["items"][i]);
        }
      }
      this.notificationList = tempList;
    }
  };

  checkAndFetchNotifications = async () => {
    if (this.canFetchNotifications()) {
      await this.fetchNotifications();
    }
  };

  startFetchNoticationAtIntervals = () => {
    let now = moment();
    let minutesRemaining =
      this._notificationDuration - (now.minutes() % this._notificationDuration);
    minutesRemaining = minutesRemaining * 60 * 1000;
    setTimeout(() => {
      this.fetchNotifications();
      this.interval = setInterval(() => {
        this.fetchNotifications();
      }, this._notificationDuration * 60 * 1000);
    }, minutesRemaining);
  };
  stopFetchNotificationAtIntervals = () => {
    if (this.interval != null) {
      clearInterval(this.interval);
    }
  };

  init = async () => {
    await this.fetchNotifications();
    this.startFetchNoticationAtIntervals();
  };

  fetchNotificationsOnProjectChange = async () => {
    await this.fetchNotifications();
  };

  canShowNotificationBadge = () => {
    if (this.notificationState["canShowBadge"] === true) {
      if (
        Object.keys(this.notificationList).length > 0 &&
        this.notificationList["items_count"] > 0
      ) {
        return true;
      }
    }
    return false;
  };

  updateNotificationBadgeState = (flag) => {
    this.notificationState["canShowBadge"] = flag;
  };

  resetStore = () => {
    this.stopFetchNotificationAtIntervals();
    this.notificationList = {};
    this.notificationState = {
      last_fetched_at: "",
      snooze_details: {},
      canShowBadge: false,
      notitications_viewed: [],
    };
  };
}

export default NotificationState;
