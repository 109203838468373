import { IconButton } from "@material-ui/core";
import { AddBoxOutlined, Edit } from "@material-ui/icons";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getAdminTeamsApi } from "../../Api";
import spinner from "../../assets/images/gifs/spinner.gif";
import { TeamSettingColumns } from "../../Db";
import AddTeamModal from "../admin_setting_modals/AddTeamModal";
import analytics from "../../Analytics";
const Spinner = styled.img`
  height: 120px;
  width: 180px;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;
const Container = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 234px);`};

  position: relative;
  /* margin-top: 70px; */
`;
const Wrapper = styled.div`
  display: flex;
`;

const DetailsWrapper = styled.div`
  height: fit-content;
  width: 100%;
  background: white;
  border-radius: 10px;
  /* padding: 12px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;
const TableWrapper = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
`;
const AddBtnWrapper = styled.div`
  position: absolute;
  right: 0px;
  top: 9px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
  @media (max-width: 700px) {
    position: unset;
    margin-left: 12px;
  }
`;
const Text = styled.p`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;
const ProjectTeamTab = (props) => {
  const [row, setRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [editableData, setEditableData] = useState(null);
  const [loading, setLoading] = useState(true);
  const getTeamList = async () => {
    setRow([]);
    let response = await getAdminTeamsApi({ projectID: props.id });
    let temp = [];
    let push_first = null;
    if (response.length > 0) {
      response.forEach((e) => {
        if (e.name !== "Default") {
          temp.push(e);
        } else if (e.name === "Default") {
          push_first = e;
        }
      });
    }
    if (push_first !== null) {
      temp.unshift(push_first);
    }
    console.log(temp);
    setRow(temp);
  };

  const init = async () => {
    setLoading(true);
    await getTeamList();
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);
  const refetchTeams = async () => {
    await init();
    setRefresh(false);
  };
  useEffect(() => {
    if (refresh) {
      refetchTeams();
    }
  }, [refresh]);
  const actionColumn = [
    {
      field: "action",
      headerName: <Text>Actions</Text>,
      sortable: false,
      width: 70,
      renderCell: (params) => {
        if (params.row.name === "Default") {
          return (
            <>
              <Edit
                onClick={(e) => {}}
                style={{ marginRight: "20px", width: "18px", height: "18px" }}
                color="disabled"
              />
            </>
          );
        } else {
          return (
            <>
              <Edit
                onClick={(e) => {
                  analytics.triggerEvent(
                    4625000185,
                    "edit_button_tap",
                    "project_teams_page",
                    "edit_button",
                    {}
                  );
                  setEditableData(params.row);
                  setIsEdit(true);
                  setOpen(true);
                }}
                style={{ marginRight: "20px", width: "18px", height: "18px" }}
                color="primary"
              />
            </>
          );
        }
      },
    },
  ];
  return (
    <>
      <Container>
        <AddBtnWrapper>
          <AddBoxOutlined
            onClick={() => {
              setIsEdit(false);
              setOpen(true);
            }}
            style={{
              width: "40px",
              height: "40px",
            }}
          />
        </AddBtnWrapper>
        <Wrapper>
          <DetailsWrapper>
            <TableWrapper>
              <DataGrid
                autoHeight={true}
                rows={row}
                columns={TeamSettingColumns.concat(actionColumn)}
                checkboxSelection={false}
                style={{ cursor: "pointer" }}
                hideFooter={true}
                disableColumnMenu={true}
                loading={loading}
                // onRowClick={(e) => {
                //   navigate(
                //     `/team-detail/${e.row.name}/${e.id}/${props.id}/${props.name}`
                //   );
                // }}
              />
            </TableWrapper>
          </DetailsWrapper>
        </Wrapper>
      </Container>
      {open && (
        <AddTeamModal
          id={props.id}
          setOpen={setOpen}
          open={open}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          editableData={editableData}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}
    </>
  );
};

export default ProjectTeamTab;
