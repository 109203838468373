import { Box, Grid, Modal } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { FullscreenExit } from "@material-ui/icons";
import { Typography } from "@mui/material";
import placeholderImage from "../../assets/gradient_bg.png";
import frameImage from "../../assets/profile_frame.png";
import congratsImage from "../../assets/congrats_image.png";
import { dataRenderer } from "../../Functions";
import { useEffect } from "react";

const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 20px;
`;
const ModelWrapper = styled(Box)`
  width: 90vw;
  margin: auto;
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
`;
const Top = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
const PlaceHolderImage = styled(Box)`
  width: 100%;
  height: 100%;
  background-image: url(${placeholderImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  padding: 30px;
`;

const ProfileImageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  align-items: center;
  height: 100%;
  position: relative;
`;
const ProfileImage = styled("img")`
  aspect-ratio: 1/1;
  width: 55%;
  height: auto;
  border-radius: 50%;
  object-fit: cover;
`;

const ImageFrame = styled("img")`
  width: 75%;
  height: auto;
  position: absolute;
`;
const TextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  row-gap: 30px;
  text-align: center;
`;
const TextWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const CongratsImage = styled("img")`
  width: auto;
  object-fit: contain;
`;
const TitleText = styled(Typography)`
  font: normal normal 600 42px Open Sans;
  color: white;
  font-size: 4.2vw;
`;
const TextLg = styled(Typography)`
  font: normal normal 600 42px Open Sans;
  color: white;
  font-size: 4.2vw;
  @media (max-width: 1920px) {
    font-size: 3vw;
  }
`;
const TextSm = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: white;
  font-size: 2.2vw;
`;
const TopPerformanceWidgetFullScreenModal = ({
  open: open,
  setOpen: setOpen,
  title: title,
  imageURL: imageURL,
  data: data,
  widgetConfig: widgetConfig,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    console.log(
      "screen width::::",
      window.screen.width,
      "::::screen height:::",
      window.screen.height
    );
  }, []);

  return (
    <>
      {
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModelContainer>
            <Box>
              <Top>
                <FullscreenExit
                  onClick={handleClose}
                  style={{
                    height: "28px",
                    width: "28px",
                    cursor: "pointer",
                  }}
                />
              </Top>
              <ModelWrapper>
                <Wrapper>
                  <PlaceHolderImage>
                    <TitleText
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {widgetConfig.input["header"]}
                    </TitleText>
                    <Grid container style={{ height: "100%" }}>
                      <Grid item xs={6}>
                        <ProfileImageWrapper>
                          <ProfileImage src={imageURL} alt="loading..." />
                          <ImageFrame src={frameImage} alt="loading..." />
                        </ProfileImageWrapper>
                      </Grid>
                      <Grid item xs={6}>
                        <TextContainer>
                          <CongratsImage src={congratsImage} alt="loading..." />
                          <TextWrapper>
                            <TextLg>{data.name}</TextLg>
                            <TextSm>{dataRenderer(data.designation)}</TextSm>
                          </TextWrapper>
                          <TextWrapper>
                            <TextSm>{widgetConfig.input["message"]}</TextSm>
                          </TextWrapper>
                        </TextContainer>
                      </Grid>
                    </Grid>
                  </PlaceHolderImage>
                </Wrapper>
              </ModelWrapper>
            </Box>
          </ModelContainer>
        </Modal>
      }
    </>
  );
};

export default TopPerformanceWidgetFullScreenModal;
