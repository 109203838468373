import { Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import bgImg from "../../../../assets/bike_insurance_bg.webp";
import React from "react";
import FormComponent from "./FormComponent";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.secondary.main,
  backgroundPosition: "right 24% top",
  backgroundImage: `url(${bgImg})`,
  backgroundSize: "cover",
  [theme.breakpoints.down("md")]: {
    minHeight: "calc(100vh - 106px)",
  },
  minHeight: "calc(100vh - 120px)",
  position: "relative",
}));
const BgLayer = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: "rgba(0, 0, 0, 0.4)",
  zIndex: "0",
}));
const InnerContainer = styled(Box)(({ theme }) => ({
  width: "80%",
  margin: "auto",
  height: "100%",
  padding: `${theme.spacing(2)} 0`,
  zIndex: "1",
  position: "relative",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    padding: theme.spacing(2),
  },
}));

const TopSection = ({ formState, contentState }) => {
  return (
    <Container>
      <BgLayer />
      <InnerContainer>
        <Grid container>
          <Grid item md={6}>
            <FormComponent formState={formState} contentState={contentState} />
          </Grid>
        </Grid>
      </InnerContainer>
    </Container>
  );
};

export default TopSection;
