import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { Divider, IconButton } from "@material-ui/core";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import { Refresh } from "@material-ui/icons";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import rootStore from "../../../stores/RootStore";
import {
  getCombinedWidgetAndAccountID,
  getLocalDateTime,
} from "../../../Functions";
const ITEM_HEIGHT = 48;

const Container = styled(Box)({
  flex: 1,
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  backgroundColor: "white",
  border: "1px solid #eceef2",
});

const ChartBottom = styled(Box)({
  width: "100%",
  height: "100%",
});

const Wrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
});

const Text = styled(Typography)({
  fontFamily: "Open Sans",
  fontWeight: 600,
  fontSize: "22px",
  margin: 0,
  textAlign: "left",
  color: "#4d4e4f",
});

const Text2 = styled(Typography)({
  fontFamily: "Open Sans",
  fontWeight: 500,
  fontSize: "12px",
  margin: 0,
  color: "#bbbab6",
});

const CounterText = styled(Typography)({
  fontWeight: 600,
  fontSize: "90px",
  color: "#4d4e4f",
});

//char limit for counter text
const limit = 11;

const CounterWidget = (props) => {
  const counterValue = props.data !== null ? props.data.counter : 0;
  const componentRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const Open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const getTextFontSize = () => {
    if (props.type === "slideshow") {
      return "13vw";
    }
    if (rootStore.userStore.dashboardMode === "grid") {
      return "5vw";
    } else if (rootStore.userStore.dashboardMode === "grid_three") {
      return "4vw";
    } else {
      return "9vw";
    }
  };

  const getCounterText = (value) => {
    if (value != null) {
      if (value.toString().length <= limit) {
        return value;
      } else {
        return value.toString().slice(0, limit - 1) + "..";
      }
    }
  };

  const ChartBottomSection = () => {
    if (props.data !== null) {
      return (
        <Wrapper>
          <CounterText
            style={{
              textAlign: "center",
              fontSize: getTextFontSize(),
            }}
          >
            {getCounterText(counterValue)}
          </CounterText>
        </Wrapper>
      );
    } else {
      return (
        <Wrapper>
          <Typography>No data found!</Typography>
        </Wrapper>
      );
    }
  };

  return (
    <>
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "12px 20px",
            backgroundColor: "#F8F8F8",
          }}
        >
          <Text
            sx={{
              fontSize:
                rootStore.userStore.dashboardMode === "grid_three"
                  ? "16px"
                  : "18px",
            }}
          >
            {props.widgetConfig.title}
          </Text>
          {(props.type === "slider" || props.type === "grid") && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={handleMenuClick}
                aria-label="more"
                id="long-button"
                aria-controls={Open ? "long-menu" : undefined}
                aria-expanded={Open ? "true" : undefined}
                aria-haspopup="true"
                style={{ padding: "0px" }}
              >
                <MoreVertIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        <Divider sx={{ backgroundColor: "#eceef2" }} />

        <ChartBottom ref={componentRef}>
          <ChartBottomSection />
        </ChartBottom>
        <Divider sx={{ backgroundColor: "#eceef2" }} />

        {(props.type === "slider" || props.type === "grid") && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "12px 20px",
            }}
          >
            {rootStore.userStore.widgetData.hasOwnProperty(
              getCombinedWidgetAndAccountID(props.widgetConfig.id)
            ) && (
              <Text2>
                Updated at{" "}
                {getLocalDateTime(
                  rootStore.userStore.widgetData[
                    getCombinedWidgetAndAccountID(props.widgetConfig.id)
                  ]["last_updated"]
                )}
              </Text2>
            )}
            <IconButton
              onClick={() => {
                props.handleRefresh();
              }}
              style={{ padding: "0px" }}
            >
              <Refresh />
            </IconButton>
          </Box>
        )}
      </Container>
      <Menu
        style={{ display: "flex", flexDirection: "column" }}
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={Open}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "10ch",
          },
        }}
      >
        <MenuItem
          style={{ fontSize: "12px" }}
          onClick={() => {
            props.handleRemove(props.widgetConfig);
            handleMenuClose();
          }}
          disabled={!props.isDashboardEditable()}
        >
          Remove
        </MenuItem>
      </Menu>
    </>
  );
};

export default observer(CounterWidget);
