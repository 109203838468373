import { styled } from "@mui/material/styles";
import React from "react";
import { NumericFormat } from "react-number-format";

const Input = styled("input")`
  width: 100%;
  height: 38px;
  padding: 2px 6px;
  outline: none;
  border: 1px solid #e0deca;
  font-weight: 600;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font-weight: 500;
    color: #b5b5b5;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;

const NumberField = ({
  disabled,
  value,
  name,
  placeholder,
  pattern,
  required,
  onInvalid,
  handleChange,
  handleBlur,
  fontSize = "12px",
}) => {
  return (
    <NumericFormat
      disabled={disabled}
      value={value}
      name={name}
      placeholder={placeholder}
      pattern={pattern}
      required={required}
      onInvalid={(event) => onInvalid(event)}
      onChange={(event) => {
        handleChange(event);
      }}
      style={{
        fontSize: fontSize,
      }}
      customInput={Input}
      onBlur={(event) => handleBlur(event)}
    />
  );
};

export default NumberField;
