import { Straight } from "@mui/icons-material";
import { Box, styled, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
const Container = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  outline: none;
`;
const Text = styled(Typography)`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #363636;
`;
const ColumnHeader = ({ columnMeta, sortingDirection, handlePostClick }) => {
  return (
    <Container onClick={handlePostClick}>
      <Text>{columnMeta["headerName"]}</Text>

      {sortingDirection === "desc" ? (
        <Straight
          style={{
            marginLeft: "20px",
            color: "#CCCCCC",
            transform: "rotateX(180deg)",
          }}
        />
      ) : (
        <Straight
          style={{
            marginLeft: "20px",
            color: "#CCCCCC",
          }}
        />
      )}
    </Container>
  );
};

export default observer(ColumnHeader);
