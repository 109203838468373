import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Box, TextField, styled } from "@mui/material";
const CustomizedDateTimePicker = styled(DateTimePicker)`
  & .MuiInputBase-input {
    font-size: 14px;
  }
`;
const DateTimePickerWidget = ({ value, handleChange }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CustomizedDateTimePicker
          value={value}
          onChange={(event) => {
            handleChange(event);
          }}
          inputFormat="YYYY-MM-DD HH:mm A"
          renderInput={(params) => (
            <TextField sx={{ width: "100%" }} size="small" {...params} />
          )}
          label="Pick Date and Time"
        />
      </LocalizationProvider>
    </Box>
  );
};

export default DateTimePickerWidget;
