import {
  Box,
  Button,
  FormControl,
  Grid,
  makeStyles,
  Modal,
  Select,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import rootStore from "../../stores/RootStore";
import { changeLeadSourceApi } from "../../Api";
import { consoleLogger, getLocalizedText } from "../../Functions";
import { Alert, MenuItem, OutlinedInput } from "@mui/material";
import { myTheme } from "../../themeUtils";
import analytics from "../../Analytics";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
`;
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled.span`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled.span`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
  margin-bottom: 30px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 320px;
  position: relative;
`;

const Label = styled.span`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;

const ButtonWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const SelectColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 700px) {
    width: 280px;
  }
`;

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const UpdateSourceModal = (props) => {
  const classes = useStyles();
  const [source, setSource] = useState("");
  const [comments, setComments] = useState("");
  const [prevSource, setPrevSource] = useState("");
  const [btnText, setBtnText] = useState("Update");
  const [showAlert, setShowAlert] = useState(false);
  const sourceList = [...rootStore.authStore.sourcesList];
  const handleChange = (e) => {
    setSource(e.target.value);
  };
  const hasChange = () => {
    let flag = true;
    if (prevSource === source) {
      flag = false;
    }
    return flag;
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    setShowAlert(false);
    const updateLeadSource = async () => {
      setBtnText("Loading...");
      let payload = { source: source };
      if (comments !== "") {
        payload["comments"] = comments;
      }
      console.log("udpate source payload:::", payload);

      try {
        let response = await changeLeadSourceApi({
          id: props.id,
          data: payload,
        });
        consoleLogger(response.data);
        setBtnText("Updated");
        setTimeout(() => {
          props.setRefreshLeadDetails(true);
          props.setOpen(false);
        }, [800]);
      } catch (error) {
        console.log(error);
      }
    };
    if (hasChange()) {
      updateLeadSource();
    } else {
      setShowAlert(true);
    }
  };

  const init = () => {
    if (Object.keys(props.data).length > 0) {
      setSource(props.data.lead_source);
      setPrevSource(props.data.lead_source);
    }
  };

  useEffect(() => {
    analytics.triggerEvent(
      4625000060,
      "update_source_form_load",
      "lead_details_page",
      "",
      {}
    );
    init();
  }, []);
  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <Container>
              <Header>
                {getLocalizedText("update_lead_source", "project")}
              </Header>
              <SubHeader>
                {getLocalizedText("select_from_below_dropdown")}
              </SubHeader>
              <Form onSubmit={handleUpdate}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <SelectColumn>
                      <Label>{getLocalizedText("select_source")}*</Label>
                      <FormControl
                        sx={{ width: "100%" }}
                        classes={{
                          root: classes.quantityRoot,
                        }}
                      >
                        <Select
                          classes={{
                            icon: classes.icon,
                          }}
                          name="status"
                          value={source}
                          displayEmpty
                          disableUnderline
                          variant="outlined"
                          required
                          onChange={handleChange}
                          style={{
                            width: "100%",
                            height: "30px",
                            fontSize: "12px",
                            fontWeight: "bold",
                            borderRadius: "0px",
                          }}
                        >
                          {sourceList.map((source, index) => {
                            return (
                              <MenuItem key={index} value={source["source"]}>
                                {source["source"]}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </SelectColumn>
                  </Grid>

                  <Grid item xs={12}>
                    <SelectColumn>
                      <Label>Comments</Label>
                      <OutlinedInput
                        multiline={true}
                        rows={3}
                        inputProps={{
                          min: 0,
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        value={comments}
                        name={"comments"}
                        type={"text"}
                        placeholder={"Enter Value"}
                        onChange={(e) => {
                          setComments(e.target.value);
                        }}
                      />
                    </SelectColumn>
                  </Grid>
                  {showAlert && (
                    <Grid item xs={12}>
                      <Alert
                        style={{
                          width: "100%",
                          fontSize: "12px",
                        }}
                        severity="warning"
                      >
                        {getLocalizedText(
                          "please_make_changes_before_updating"
                        )}
                      </Alert>
                    </Grid>
                  )}
                </Grid>
                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      analytics.triggerEvent(
                        4625000061,
                        "cancel_button_tap",
                        "update_source_form",
                        "cancel_button",
                        {}
                      );
                      props.setOpen(false);
                    }}
                    variant="contained"
                    color="default"
                    style={{
                      font: "normal normal 600 10px Open Sans",
                      marginRight: "10px",
                      textTransform: "none",
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                  <Button
                    onClick={() => {
                      analytics.triggerEvent(
                        4625000062,
                        "update_button_tap",
                        "update_source_form",
                        "update_button",
                        {}
                      );
                    }}
                    type="submit"
                    variant="contained"
                    style={{
                      font: "normal normal 600 10px Open Sans",
                      width: "fit-content",
                      textTransform: "none",
                      ...myTheme.Button.btnBlue,
                    }}
                  >
                    {btnText}
                  </Button>
                </ButtonWrapper>
              </Form>
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default UpdateSourceModal;
