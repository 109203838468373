import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Call,
  Edit,
  Mail,
  Phone,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import spinner from "../../assets/images/gifs/spinner.gif";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import SelectTagsModal from "../select_tags_modal/SelectTagsModal";
import {
  Divider,
  Drawer,
  Grid,
  IconButton,
  Snackbar,
  Tooltip,
} from "@material-ui/core";
import { Box, Typography } from "@mui/material";
import {
  editContactApi,
  editLeadApi,
  getPhoneApi,
  uploadLeadProfileImageApi,
} from "../../Api";
import {
  canShowSkypeCallIcon,
  consoleLogger,
  dataRenderer,
  dateHandler,
  dateTimeHandler,
  getLocalizedText,
  IsoToLocalDate,
  IsoToLocalTime,
  validateUnmaskColumns,
} from "../../Functions";
import moment from "moment";
import { BadgeSharp, CopyAll, MarkEmailRead } from "@mui/icons-material";
import EditLeadComponent from "../edit_lead_component/EditLeadComponent";
import { myTheme } from "../../themeUtils";
import analytics from "../../Analytics";
import BasicContactDetailsSidepanel from "../contact_forms_sidepanl/BasicContactDetailsSidepanel";
import MessageWithLoadingStateCustomDialog from "../alert_dialogue/MessageWithLoadingStateCustomDialog";
import ImageWithUploadComponent from "../file_upload_component/ImageWithUploadComponent";
import PreviewImageDialog from "../files_page_components/PreviewImageDialog";
import LinearProgressWidget from "./detail_components/LinearProgressWidget";
import SwitchPhoneComponent from "../switch_phone_component/SwitchPhoneComponent";
const Container = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 242px);`};

  /* padding: 12px; */
  @media (max-width: 700px) {
    padding: 0px;
  }
`;
const Wrapper = styled(Box)`
  /* width: 80%; */
  margin: auto;
  display: flex;
  flex-direction: column;
`;

const SectionWrapper = styled(Box)`
  width: 100%;
  background: #f9f9fc 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  position: relative;
  @media (max-width: 700px) {
    width: 100%;
  }
`;

const LeftWrapper = styled(Box)`
  flex: 3;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  /* row-gap: 20px; */
`;
const RightWrapper = styled(Box)`
  flex: 7;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;
const WrapperRow = styled(Box)`
  display: flex;
  column-gap: 20px;
  margin-bottom: 20px;
`;
const DetailsHeader = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  margin-bottom: 20px;
  color: #979797;
`;
const Message = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #979797;
`;
const InfoWrapper = styled(Box)`
  width: 200px;
  height: fit-content;
`;

const Label = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  margin-bottom: 8px;
  color: #979799;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const DetailLg = styled(Box)`
  font: normal normal 600 24px Open Sans;
  color: white;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
`;

const Row = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
`;
const IconTextWrapper = styled(Box)`
  display: flex;
  width: 100%;
  column-gap: 20px;
  align-items: center;
`;
const IconWrapper = styled(Box)`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
`;
const Column = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const Spinner = styled("img")`
  height: 120px;
  width: 180px;
`;
const SpinnerWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  padding-top: 10px;
  width: 100%;
`;
const TextHyperLink = styled("a")`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  color: ${myTheme.Button.colorBlue};
`;

const BulletIcon = styled(Box)`
  margin-top: 6px;
  width: 8px;
  height: 8px;
  background-color: gray;
  border-radius: 50%;
`;

const primaryInfoFieldOrderChecker = ["name", "phone", "email", "flid"];

const ProfileTab = ({
  lid,
  tab_type,
  groups,
  groupsToColumns,
  leadData,
  setRefreshLeadDetails,
  canEditLead,
  isEmailVerified,
  setOpenSkypeCallConfirmationDialog,
  setSelectedPhoneFieldValue,
  isCallDisabled,
  setTextData,
  setOpenViewTextDialog,
}) => {
  const [data, setData] = useState();
  const [phone, setPhone] = useState();
  const { authStore, userStore } = rootStore;
  const [open, setOpen] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openEditInfo, setOpenEditInfo] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [groupsDetails, setGroupsDetails] = useState({});
  const [editGroupColumnIDs, setEditGroupColumnIDs] = useState([]);
  const [leadPhoneNumber, setLeadPhoneNumber] = useState("");
  const [openContactInfoPanel, setOpenContactInfoPanel] = useState(false);
  const columnsList = [...userStore.AllColumnsList];

  //******state variables related profile image upload *******************/
  const [fetchingApi, setFetchingApi] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSubmitResponseDialog, setOpenSubmitResponseDialog] =
    useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [viewImageDialog, setViewImageDialog] = useState(false);
  //***********************END************************************ */

  //****************state variables related phone switch*************** */
  const [phoneNumberColumnList, setPhoneNumberColumnList] = useState([]);
  const [selectedPhoneNumberColumnID, setSelectedPhoneNumberColumnID] =
    useState("");
  //****************************END********************************** */

  const setupPhoneFieldList = () => {
    let tempList = [];
    columnsList.forEach((column) => {
      if (column["is_phone"] === true) {
        if (column["is_custom_param"]) {
          let customData = leadData.hasOwnProperty("custom_params")
            ? JSON.parse(leadData["custom_params"])
            : {};
          let targetKey = column["field"].replace("custom_params_", "");
          if (customData.hasOwnProperty(targetKey)) {
            if (
              customData[targetKey] !== null &&
              customData[targetKey] !== ""
            ) {
              let tempObj = { ...column, value: customData[targetKey] };
              tempList.push(tempObj);
            }
          }
        } else {
          if (leadData.hasOwnProperty(column["field"])) {
            if (
              leadData[column["field"]] !== null &&
              leadData[column["field"]] !== ""
            ) {
              let tempObj = { ...column, value: leadData[column["field"]] };
              tempList.push(tempObj);
            }
          }
        }
      }
    });
    let index = tempList.findIndex((column) => column["field"] === "phone");
    if (index !== -1) {
      setSelectedPhoneNumberColumnID(tempList[index]["id"]);
    } else {
      setSelectedPhoneNumberColumnID("");
    }
    tempList.sort((a, b) => {
      if (a["field"] === "phone" && b["field"] !== "phone") return -1;
      if (a["field"] !== "phone" && b["field"] === "phone") return 1;
      return 0;
    });
    setPhoneNumberColumnList(tempList);
  };

  const identifyDateFormat = (dateString) => {
    if (!isNaN(dateString)) {
      const m = moment(parseInt(dateString));
      if (m.isValid()) {
        return "epoch";
      }
    } else {
      const m = moment(dateString);
      if (m.isValid()) {
        return "ISO";
      }
    }
    return "unknown";
  };

  const dateFieldHandler = ({ item, type }) => {
    let value = null;
    let dateStringType = identifyDateFormat(item);
    if (item !== null) {
      if (dateStringType !== "unknown") {
        if (dateStringType === "epoch") {
          if (type === "datetime") {
            value = dateTimeHandler(item);
          } else {
            value = dateHandler(item);
          }
        } else {
          if (type === "datetime") {
            value = IsoToLocalTime(item);
          } else {
            value = IsoToLocalDate(item);
          }
        }
      }
    }
    return value;
  };
  const handleVisibility = () => {
    const getLeadPhone = async () => {
      let response = await getPhoneApi(lid);
      setPhone(response.data);
      setVisibility(true);
    };
    getLeadPhone();
  };

  const setupGroupDetails = () => {
    let tempGroupsDetails = {};
    consoleLogger(
      "groups in setupGroupDetails:::",
      groups,
      "groups in groupToColumns:::",
      groupsToColumns
    );
    groups.forEach((group) => {
      let groupDetails = [];
      let columnIds = groupsToColumns[group];
      columnIds.forEach((id) => {
        let index = columnsList.findIndex((element) => element["id"] === id);
        if (index !== -1) {
          let item = {
            headerName: columnsList[index]["headerName"],
            value: getColumnValue(
              columnsList[index]["field"],
              columnsList[index]["data_type"]
            ),
            is_link: columnsList[index]["is_link"],
            field: columnsList[index]["field"],
            is_array: columnsList[index]["is_array"],
            is_phone: columnsList[index]["is_phone"],
          };
          groupDetails.push(item);
        }
      });
      groupDetails.sort((a, b) => {
        let A = a.headerName.toLowerCase();
        let B = b.headerName.toLowerCase();
        if (A < B) {
          return -1;
        }
        if (A > B) {
          return 1;
        }
        return 0;
      });
      tempGroupsDetails[group] = groupDetails;
    });
    setGroupsDetails(tempGroupsDetails);
    consoleLogger("all group data to render ::::", tempGroupsDetails);
  };
  const getColumnValue = (field, data_type) => {
    let data = leadData;
    let key = field;
    let index = columnsList.findIndex((column) => column["field"] === field);
    if (index !== -1) {
      if (columnsList[index]["is_custom_param"]) {
        key = field.replace("custom_params_", "");
        let customData = leadData.hasOwnProperty("custom_params")
          ? JSON.parse(leadData["custom_params"])
          : {};
        data = customData;
      }
    }
    if (data.hasOwnProperty(key)) {
      let value = data[key];
      if (data_type === "date" || data_type === "datetime") {
        value = dateFieldHandler({ item: value, type: data_type });
      }
      if (key === "lead_owner" || key === "team" || key === "lead_creator") {
        return value !== null ? value["name"] : "";
      }

      return value !== null ? value : "";
    } else {
      return "";
    }
  };

  useEffect(() => {
    setupPhoneFieldList();
    setupGroupDetails();
  }, [leadData]);

  function truncate(str, n, is_link) {
    if (str.length > n) {
      return is_link ? (
        <Tooltip title={str}>
          <IconTextWrapper style={{ columnGap: "0px" }}>
            <TextHyperLink href={str} target="_blank">
              {str.slice(0, n - 1) + "..."}
            </TextHyperLink>
          </IconTextWrapper>
        </Tooltip>
      ) : (
        <IconTextWrapper style={{ columnGap: "0px" }}>
          <Detail>{str.slice(0, n - 1) + "..."}</Detail>
          <CopyAll
            color="primary"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigator.clipboard.writeText(str);
              setOpenSnackBar(true);
            }}
          />
          <Visibility
            color="primary"
            style={{ cursor: "pointer", height: "20px", width: "20px" }}
            onClick={() => {
              setTextData(str);
              setOpenViewTextDialog(true);
            }}
          />
        </IconTextWrapper>
      );
    } else {
      return is_link ? (
        <TextHyperLink href={str} target="_blank">
          {str}
        </TextHyperLink>
      ) : (
        <Detail>{str}</Detail>
      );
    }
  }

  const isColumnGroupVisible = (group_name) => {
    return userStore.isColumnGroupVisible(group_name);
  };

  const isGroupEditable = (group_name) => {
    if (canEditLead()) {
      return userStore.isColumnGroupEditable(group_name);
    }
    return false;
  };

  const fetchLeadPhone = async () => {
    try {
      let response = await getPhoneApi(lid);
      return response.data;
    } catch (error) {
      console.log(error);
      return "";
    }
  };

  useEffect(() => {
    analytics.triggerEvent(
      4625000049,
      "basic_information_tab_load",
      "lead_details_page",
      "",
      {}
    );
  }, []);

  const handlePrimaryInfoDataCopy = async () => {
    let list = [];

    for (let i = 0; i < primaryInfoFieldOrderChecker.length; i++) {
      let index = columnsList.findIndex(
        (column) => column["field"] === primaryInfoFieldOrderChecker[i]
      );
      if (index !== -1) {
        let columnMeta = columnsList[index];
        if (columnMeta["field"] === "phone") {
          let phoneNumber = await fetchLeadPhone();
          let newStr = `${columnMeta["headerName"]}:${phoneNumber}`;
          list.push(newStr);
        } else {
          let newStr = `${columnMeta["headerName"]}:${dataRenderer(
            leadData[columnMeta["field"]]
          )}`;
          list.push(newStr);
        }
      }
    }
    groupsDetails["Primary Information"].forEach((data) => {
      if (!primaryInfoFieldOrderChecker.includes(data["field"])) {
        let newStr = `${data["headerName"]}:${data["value"]}`;
        list.push(newStr);
      }
    });
    let formattedData = list.join("\n");
    navigator.clipboard.writeText(formattedData);
    setOpenSnackBar(true);
  };

  const handleSectionDataCopy = (groupData) => {
    let list = [];
    groupData.forEach((data) => {
      let newStr = `${data["headerName"]}:${data["value"]}`;
      list.push(newStr);
    });
    let formattedData = list.join("\n");
    navigator.clipboard.writeText(formattedData);
    setOpenSnackBar(true);
  };

  const handleProfileImageUpload = async (event) => {
    let file = event.target.files[0];
    const uploadFile = async () => {
      setFetchingApi(true);
      setOpenSubmitResponseDialog(true);
      let formData = new FormData();
      formData.append("file", file);
      let response = await uploadLeadProfileImageApi({
        leadID: lid,
        payload: formData,
      });
      if (!response.hasError()) {
        setIsSubmitFail(false);
        setRefreshLeadDetails(true);
        setSuccessMessage("Image uploaded successfully!");
        setFetchingApi(false);
      } else {
        setErrorMessage(response.errorMessage);
        setIsSubmitFail(true);
        setFetchingApi(false);
      }
    };
    if (file !== null && file !== undefined) {
      uploadFile();
    }
  };

  const getProfileImageUrl = () => {
    if (
      leadData.hasOwnProperty("profile_pic") &&
      leadData["profile_pic"] !== null &&
      leadData["profile_pic"] !== ""
    ) {
      return leadData["profile_pic"];
    } else {
      return null;
    }
  };

  const canShowPhoneDirectly = () => {
    let index = columnsList.findIndex((column) => column["field"] === "phone");
    if (index !== -1) {
      let columnMeta = columnsList[index];
      if (columnMeta["masked"] === false) {
        return true;
      } else {
        return validateUnmaskColumns();
      }
    }
    return false;
  };

  const renderPhoneField = () => {
    if (canShowPhoneDirectly()) {
      return (
        <Detail
          style={{
            color: "white",
          }}
        >
          {dataRenderer(leadData["phone"])}
        </Detail>
      );
    } else {
      if (visibility) {
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <Detail
              style={{
                color: "white",
              }}
            >
              {phone ? phone : null}
            </Detail>
            <VisibilityOff
              style={{
                cursor: "pointer",
                color: "#DBE9FA",
                width: "20px",
                height: "20px",
              }}
              onClick={() => {
                setVisibility(false);
              }}
            />
          </Box>
        );
      } else {
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <Detail
              style={{
                color: "white",
              }}
            >
              +91-xxxxxxxxxx
            </Detail>
            <Visibility
              style={{
                cursor: "pointer",
                color: "#DBE9FA",
                width: "20px",
                height: "20px",
              }}
              onClick={handleVisibility}
            />
          </Box>
        );
      }
    }
  };

  const canShowPhoneSelectionMenu = () => {
    if (canEditLead()) {
      if (phoneNumberColumnList.length > 1) {
        let index = phoneNumberColumnList.findIndex(
          (column) => column["field"] === "phone"
        );
        return index !== -1;
      }
    }
    return false;
  };

  const submitEditLead = async (phoneColumnData, currentPhoneNumber) => {
    let payload = { phone: phoneColumnData["value"] };
    if (phoneColumnData["is_custom_param"]) {
      let keyName = phoneColumnData["field"].replace("custom_params_", "");
      payload["custom_params"] = {
        [keyName]: currentPhoneNumber,
      };
    } else {
      payload[phoneColumnData["field"]] = currentPhoneNumber;
    }

    try {
      let response = await editLeadApi({
        id: lid,
        data: payload,
      });
      setIsSubmitFail(false);
      setRefreshLeadDetails(true);
      setSuccessMessage("Primary Phone updated successfully!");
      setFetchingApi(false);
    } catch (error) {
      setErrorMessage(getLocalizedText("some_error_occurred"));
      setIsSubmitFail(true);
      setFetchingApi(false);
    }
  };

  const handleMakePrimary = async (phoneColumnID) => {
    let index = phoneNumberColumnList.findIndex(
      (column) => column["id"] === phoneColumnID
    );
    if (index !== -1) {
      setFetchingApi(true);
      setOpenSubmitResponseDialog(true);
      let currentPhoneNumber = await fetchLeadPhone();
      let phoneNumberColumnData = phoneNumberColumnList[index];
      if (leadData["contact_id"] !== null && leadData["contact_id"] !== "") {
        let contactPayload = {
          phone: phoneNumberColumnData["value"],
        };
        let updateContactResponse = await editContactApi({
          contactID: leadData["contact_id"],
          payload: contactPayload,
        });
        if (!updateContactResponse.hasError()) {
          await submitEditLead(phoneNumberColumnData, currentPhoneNumber);
        } else {
          setErrorMessage(updateContactResponse.errorMessage);
          setIsSubmitFail(true);
          setFetchingApi(false);
        }
      } else {
        await submitEditLead(phoneNumberColumnData, currentPhoneNumber);
      }
    }
  };

  return (
    <>
      <Container>
        <Wrapper>
          {Object.keys(groupsDetails).length > 0 ? (
            <>
              <WrapperRow>
                {tab_type === "basic_info_tab" && (
                  <LeftWrapper>
                    {isColumnGroupVisible("Primary Information") && (
                      <SectionWrapper
                        style={{
                          backgroundColor: "#003B7C",
                          borderRadius: "10px 10px 0px 0px",
                          flexGrow: "0",
                          minHeight: "200px",
                          padding: "0px",
                        }}
                      >
                        <IconWrapper>
                          {rootStore.userStore.isColumnGroupCopyable(
                            "Primary Information"
                          ) && (
                            <CopyAll
                              onClick={handlePrimaryInfoDataCopy}
                              color="primary"
                              style={{ color: "#DBE9FA", cursor: "pointer" }}
                            />
                          )}
                          {isGroupEditable("Primary Information") && (
                            <Edit
                              onClick={() => {
                                setEditGroupColumnIDs(
                                  groupsToColumns["Primary Information"]
                                );
                                setOpenEditInfo(true);
                              }}
                              color="primary"
                              style={{ color: "#DBE9FA", cursor: "pointer" }}
                            />
                          )}
                        </IconWrapper>
                        {groupsDetails.hasOwnProperty(
                          "Primary Information"
                        ) && (
                          <Column style={{ width: "100%", padding: "20px" }}>
                            <DetailsHeader style={{ color: "white" }}>
                              {getLocalizedText("primary_information")}
                            </DetailsHeader>

                            <Row
                              style={{
                                flexWrap: "nowrap",
                                flexDirection: "column",
                              }}
                            >
                              <Box style={{ width: "100%" }}>
                                <Grid container spacing={3} alignItems="center">
                                  <Grid item xs={3}>
                                    <ImageWithUploadComponent
                                      handleUpload={(event) =>
                                        handleProfileImageUpload(event)
                                      }
                                      url={getProfileImageUrl()}
                                      handleImageClick={() => {
                                        setViewImageDialog(true);
                                      }}
                                      canShowUpload={canEditLead()}
                                    />
                                  </Grid>
                                  <Grid item xs={9}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        rowGap: "8px",
                                      }}
                                    >
                                      <Tooltip
                                        title={dataRenderer(leadData["name"])}
                                        placement="bottom-start"
                                      >
                                        <DetailLg>
                                          {dataRenderer(leadData["name"])}
                                        </DetailLg>
                                      </Tooltip>
                                      <IconTextWrapper>
                                        <Tooltip title={"Friendly ID"}>
                                          <BadgeSharp
                                            style={{
                                              color: "#DBE9FA",
                                            }}
                                          />
                                        </Tooltip>
                                        <Detail style={{ color: "white" }}>
                                          {dataRenderer(leadData["flid"])}
                                        </Detail>
                                      </IconTextWrapper>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                              <Divider
                                orientation="horizontal"
                                style={{ backgroundColor: "#DBE9FA" }}
                              />

                              <IconTextWrapper>
                                <Tooltip title={"Phone"}>
                                  <Phone
                                    onClick={() => {
                                      if (
                                        leadData["contact_id"] !== null &&
                                        leadData["contact_id"] !== ""
                                      ) {
                                        setOpenContactInfoPanel(true);
                                      }
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      color: "#DBE9FA",
                                    }}
                                  />
                                </Tooltip>

                                <IconTextWrapper style={{ columnGap: "4px" }}>
                                  {canShowPhoneSelectionMenu() ? (
                                    <SwitchPhoneComponent
                                      phoneNumberColumnList={
                                        phoneNumberColumnList
                                      }
                                      selectedPhoneNumberColumnID={
                                        selectedPhoneNumberColumnID
                                      }
                                      renderPhoneField={renderPhoneField}
                                      handleMakePrimary={(phoneColumnID) =>
                                        handleMakePrimary(phoneColumnID)
                                      }
                                      getPrimaryPhoneNumber={() => {
                                        if (canShowPhoneDirectly()) {
                                          return dataRenderer(
                                            leadData["phone"]
                                          );
                                        } else {
                                          if (visibility) {
                                            return phone !== null ? phone : "-";
                                          } else {
                                            return "+91-xxxxxxxxxx";
                                          }
                                        }
                                      }}
                                    />
                                  ) : (
                                    renderPhoneField()
                                  )}
                                </IconTextWrapper>
                              </IconTextWrapper>

                              <IconTextWrapper>
                                <Tooltip title={"Email"}>
                                  {isEmailVerified() ? (
                                    <MarkEmailRead
                                      onClick={() => {
                                        if (
                                          leadData["contact_id"] !== null &&
                                          leadData["contact_id"] !== ""
                                        ) {
                                          setOpenContactInfoPanel(true);
                                        }
                                      }}
                                      style={{
                                        color: "#DBE9FA",
                                        marginRight: "8px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  ) : (
                                    <Mail
                                      onClick={() => {
                                        if (
                                          leadData["contact_id"] !== null &&
                                          leadData["contact_id"] !== ""
                                        ) {
                                          setOpenContactInfoPanel(true);
                                        }
                                      }}
                                      style={{
                                        color: "#DBE9FA",

                                        cursor: "pointer",
                                      }}
                                    />
                                  )}
                                </Tooltip>
                                <Detail style={{ color: "white" }}>
                                  {dataRenderer(leadData["email"])}
                                </Detail>
                              </IconTextWrapper>

                              {groupsDetails["Primary Information"].length >
                                0 &&
                                groupsDetails["Primary Information"].map(
                                  (item) => {
                                    if (
                                      !primaryInfoFieldOrderChecker.includes(
                                        item["field"]
                                      )
                                    ) {
                                      if (item["is_array"] === true) {
                                        let list =
                                          item["value"] !== ""
                                            ? item["value"].split(",")
                                            : [];
                                        return (
                                          <InfoWrapper>
                                            <Label style={{ color: "#DBE9FA" }}>
                                              {item["headerName"]}
                                            </Label>

                                            {list.length > 0 ? (
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  rowGap: "4px",
                                                }}
                                              >
                                                {list.map((str, index) => {
                                                  return (
                                                    <Box
                                                      sx={{
                                                        display: "flex",
                                                        alignItems:
                                                          "flex-start",
                                                        columnGap: "4px",
                                                      }}
                                                      key={index}
                                                    >
                                                      <BulletIcon
                                                        style={{
                                                          color: "white",
                                                        }}
                                                      />

                                                      <Detail
                                                        style={{
                                                          color: "white",
                                                        }}
                                                      >
                                                        {str}
                                                      </Detail>
                                                    </Box>
                                                  );
                                                })}
                                              </Box>
                                            ) : (
                                              <Detail
                                                style={{ color: "white" }}
                                              >
                                                -
                                              </Detail>
                                            )}
                                          </InfoWrapper>
                                        );
                                      } else {
                                        return (
                                          <InfoWrapper>
                                            <Label style={{ color: "#DBE9FA" }}>
                                              {item["headerName"]}
                                            </Label>
                                            {
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  gap: "10px",
                                                }}
                                              >
                                                <Detail
                                                  style={{ color: "white" }}
                                                >
                                                  {item["value"] !== ""
                                                    ? item["value"]
                                                    : "-"}
                                                </Detail>
                                                {canShowSkypeCallIcon({
                                                  isPhone: item["is_phone"],
                                                  value: item["value"],
                                                }) && (
                                                  <IconButton
                                                    style={{ padding: "0px" }}
                                                    color="primary"
                                                    disabled={isCallDisabled()}
                                                    onClick={() => {
                                                      setSelectedPhoneFieldValue(
                                                        item["value"]
                                                      );
                                                      setOpenSkypeCallConfirmationDialog(
                                                        true
                                                      );
                                                    }}
                                                  >
                                                    <Call />
                                                  </IconButton>
                                                )}
                                              </Box>
                                            }
                                          </InfoWrapper>
                                        );
                                      }
                                    }
                                  }
                                )}
                            </Row>
                          </Column>
                        )}
                        <LinearProgressWidget
                          value={
                            leadData.hasOwnProperty("quality_score") &&
                            leadData["quality_score"] !== null
                              ? leadData["quality_score"]
                              : 0
                          }
                        />
                      </SectionWrapper>
                    )}
                    {isColumnGroupVisible("Secondary Information") && (
                      <SectionWrapper
                        style={{ borderRadius: "0px 0px 10px 10px" }}
                      >
                        <IconWrapper>
                          {rootStore.userStore.isColumnGroupCopyable(
                            "Secondary Information"
                          ) && (
                            <CopyAll
                              onClick={() =>
                                handleSectionDataCopy(
                                  groupsDetails["Secondary Information"]
                                )
                              }
                              color="primary"
                              style={{ cursor: "pointer" }}
                            />
                          )}
                          {isGroupEditable("Secondary Information") && (
                            <Edit
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setEditGroupColumnIDs(
                                  groupsToColumns["Secondary Information"]
                                );
                                setOpenEditInfo(true);
                              }}
                              color="primary"
                            />
                          )}
                        </IconWrapper>
                        <Column>
                          <DetailsHeader>
                            {getLocalizedText("secondary_information")}
                          </DetailsHeader>
                          <Row>
                            {groupsDetails.hasOwnProperty(
                              "Secondary Information"
                            ) &&
                              groupsDetails["Secondary Information"].length >
                                0 &&
                              groupsDetails["Secondary Information"].map(
                                (item) => {
                                  let value = item["value"];

                                  if (item["field"] === "lead_stage") {
                                    let color_value = "";
                                    let index = authStore.stageList.findIndex(
                                      (stageObj) => stageObj.stage === value
                                    );
                                    if (index !== -1) {
                                      color_value =
                                        authStore.stageList[index]["color"];
                                    }

                                    return (
                                      <InfoWrapper>
                                        <Label>{item["headerName"]}</Label>
                                        <Detail
                                          style={{
                                            color:
                                              color_value !== null
                                                ? color_value
                                                : "",
                                          }}
                                        >
                                          {value !== "" ? value : "-"}
                                        </Detail>
                                      </InfoWrapper>
                                    );
                                  } else if (item["field"] === "lead_status") {
                                    let color_value = "";
                                    let index = authStore.statusList.findIndex(
                                      (status) => status.status === value
                                    );
                                    if (index !== -1) {
                                      color_value =
                                        authStore.statusList[index]["color"];
                                    }

                                    return (
                                      <InfoWrapper>
                                        <Label>{item["headerName"]}</Label>
                                        <Detail
                                          style={{
                                            color:
                                              color_value !== null
                                                ? color_value
                                                : "",
                                          }}
                                        >
                                          {value !== "" ? value : "-"}
                                        </Detail>
                                      </InfoWrapper>
                                    );
                                  } else {
                                    if (item["is_array"] === true) {
                                      let list =
                                        value !== "" ? value.split(",") : [];
                                      return (
                                        <InfoWrapper>
                                          <Label>{item["headerName"]}</Label>

                                          {list.length > 0 ? (
                                            <Box
                                              sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                rowGap: "4px",
                                              }}
                                            >
                                              {list.map((str, index) => {
                                                return (
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      alignItems: "flex-start",
                                                      columnGap: "4px",
                                                    }}
                                                    key={index}
                                                  >
                                                    <BulletIcon />

                                                    <Detail>{str}</Detail>
                                                  </Box>
                                                );
                                              })}
                                            </Box>
                                          ) : (
                                            <Detail>-</Detail>
                                          )}
                                        </InfoWrapper>
                                      );
                                    } else {
                                      return (
                                        <InfoWrapper>
                                          <Label>{item["headerName"]}</Label>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "10px",
                                            }}
                                          >
                                            {value !== ""
                                              ? truncate(
                                                  value,
                                                  20,
                                                  item["is_link"]
                                                )
                                              : "-"}
                                            {canShowSkypeCallIcon({
                                              isPhone: item["is_phone"],
                                              value: value,
                                            }) && (
                                              <IconButton
                                                disabled={isCallDisabled()}
                                                style={{ padding: "0px" }}
                                                color="primary"
                                                onClick={() => {
                                                  setSelectedPhoneFieldValue(
                                                    value
                                                  );
                                                  setOpenSkypeCallConfirmationDialog(
                                                    true
                                                  );
                                                }}
                                              >
                                                <Call />
                                              </IconButton>
                                            )}
                                          </Box>
                                        </InfoWrapper>
                                      );
                                    }
                                  }
                                }
                              )}
                          </Row>
                        </Column>
                      </SectionWrapper>
                    )}
                  </LeftWrapper>
                )}
                <RightWrapper>
                  {Object.keys(groupsDetails).length > 0 &&
                    Object.keys(groupsDetails).map((key) => {
                      if (isColumnGroupVisible(key)) {
                        let details = groupsDetails[key];
                        if (
                          key !== "Primary Information" &&
                          key !== "Secondary Information"
                        )
                          return details.length > 0 ? (
                            <SectionWrapper>
                              <IconWrapper>
                                {rootStore.userStore.isColumnGroupCopyable(
                                  key
                                ) && (
                                  <CopyAll
                                    onClick={() =>
                                      handleSectionDataCopy(groupsDetails[key])
                                    }
                                    color="primary"
                                    style={{ cursor: "pointer" }}
                                  />
                                )}
                                {isGroupEditable(key) && (
                                  <Edit
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setEditGroupColumnIDs(
                                        groupsToColumns[key]
                                      );
                                      setOpenEditInfo(true);
                                    }}
                                    color="primary"
                                  />
                                )}
                              </IconWrapper>
                              <Column>
                                <DetailsHeader>{key}</DetailsHeader>
                                <Row>
                                  {details.map((item) => {
                                    let value = item["value"];
                                    if (item["is_array"] === true) {
                                      let list =
                                        value !== "" ? value.split(",") : [];
                                      return (
                                        <InfoWrapper>
                                          <Label>{item["headerName"]}</Label>

                                          {list.length > 0 ? (
                                            <Box
                                              sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                rowGap: "4px",
                                              }}
                                            >
                                              {list.map((str, index) => {
                                                return (
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      alignItems: "flex-start",
                                                      columnGap: "4px",
                                                    }}
                                                    key={index}
                                                  >
                                                    <BulletIcon />

                                                    <Detail>{str}</Detail>
                                                  </Box>
                                                );
                                              })}
                                            </Box>
                                          ) : (
                                            <Detail>-</Detail>
                                          )}
                                        </InfoWrapper>
                                      );
                                    } else {
                                      return (
                                        <InfoWrapper>
                                          <Label>{item["headerName"]}</Label>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "10px",
                                            }}
                                          >
                                            {value !== ""
                                              ? truncate(
                                                  value,
                                                  20,
                                                  item["is_link"]
                                                )
                                              : "-"}
                                            {canShowSkypeCallIcon({
                                              isPhone: item["is_phone"],
                                              value: value,
                                            }) && (
                                              <IconButton
                                                disabled={isCallDisabled()}
                                                style={{ padding: "0px" }}
                                                color="primary"
                                                onClick={() => {
                                                  setSelectedPhoneFieldValue(
                                                    value
                                                  );
                                                  setOpenSkypeCallConfirmationDialog(
                                                    true
                                                  );
                                                }}
                                              >
                                                <Call />
                                              </IconButton>
                                            )}
                                          </Box>
                                        </InfoWrapper>
                                      );
                                    }
                                  })}
                                </Row>
                              </Column>
                            </SectionWrapper>
                          ) : (
                            <SectionWrapper
                              style={{
                                flexDirection: "column",
                                flexWrap: "nowrap",
                              }}
                            >
                              <DetailsHeader>{key}</DetailsHeader>
                              <Row
                                style={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "100%",
                                }}
                              >
                                <Message>No data.</Message>
                              </Row>
                            </SectionWrapper>
                          );
                      }
                    })}
                </RightWrapper>
              </WrapperRow>
            </>
          ) : (
            <SpinnerWrapper>
              <Spinner src={spinner} alt="loading..." />
            </SpinnerWrapper>
          )}
        </Wrapper>
      </Container>
      {open && (
        <SelectTagsModal
          open={open}
          setOpen={setOpen}
          tags={data.tags}
          lid={lid}
        />
      )}
      <Snackbar
        open={openSnackBar}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => {
          setOpenSnackBar(false);
        }}
        message="Copied to clipboard!"
      />

      <Drawer
        anchor={"right"}
        open={openEditInfo}
        onClose={() => {
          setOpenEditInfo(false);
        }}
      >
        {
          <EditLeadComponent
            id={lid}
            leadDetails={leadData}
            groupName={groupName}
            open={openEditInfo}
            columnIds={editGroupColumnIDs}
            setOpen={setOpenEditInfo}
            setRefreshLeadDetails={setRefreshLeadDetails}
          />
        }
      </Drawer>

      <Drawer
        anchor={"right"}
        open={openContactInfoPanel}
        onClose={() => {
          setOpenContactInfoPanel(false);
        }}
      >
        {
          <BasicContactDetailsSidepanel
            contactID={leadData["contact_id"]}
            open={openContactInfoPanel}
            setOpen={setOpenContactInfoPanel}
          />
        }
      </Drawer>
      {openSubmitResponseDialog && (
        <MessageWithLoadingStateCustomDialog
          open={openSubmitResponseDialog}
          setOpen={setOpenSubmitResponseDialog}
          successMessage={successMessage}
          errorMessage={errorMessage}
          loading={fetchingApi}
          isError={isSubmitFail}
        />
      )}
      {viewImageDialog && (
        <PreviewImageDialog
          open={viewImageDialog}
          setOpen={setViewImageDialog}
          file={getProfileImageUrl()}
          isCreate={false}
        />
      )}
    </>
  );
};

export default observer(ProfileTab);
