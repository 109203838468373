import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import { Button, CircularProgress, Grid, Switch } from "@material-ui/core";
import {
  IsSameUser,
  IsoToLocalTime,
  dataRenderer,
  IsoToLocalDate,
} from "../../Functions";
import profile_placeholder from "../../assets/profile_placeholder.png";
import PreviewImageDialog from "../files_page_components/PreviewImageDialog";
import UserProfileImageComponent from "../file_upload_component/user_profile_image/UserProfileImageComponent";

const ModelWrapper = styled(Box)`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 24px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 8px;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ViewAdminUserSidepanel = ({
  data,
  handleEdit,
  handleUserActive,
  list,
  refresh,
}) => {
  const [viewProfileImage, setViewProfileImage] = useState(false);
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const getProfileImageUrl = () => {
    if (
      details.hasOwnProperty("profile_pic") &&
      details["profile_pic"] !== null
    ) {
      return details["profile_pic"];
    }

    return profile_placeholder;
  };
  const init = () => {
    setLoading(true);
    let index = list.findIndex((item) => item["id"] === data["id"]);
    if (index !== -1) {
      setDetails(list[index]);
    }
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, [data, refresh]);
  return (
    <>
      {!loading ? (
        <Box sx={{ width: 500 }}>
          <AppBar
            component={"nav"}
            position="sticky"
            color="inherit"
            elevation={0}
            sx={{
              top: 0,
              bottom: "auto",
              width: 500,
              right: 0,
              bgcolor: "#f9f9fc",
            }}
          >
            <Toolbar>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <ModelHeader>User Details</ModelHeader>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Switch
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleUserActive({
                        flag: details.active,
                        id: details.id,
                      });
                    }}
                    checked={details.active}
                    disabled={
                      IsSameUser(details.id) ||
                      details.role === "owner" ||
                      details.name === "system"
                    }
                  />
                  <Button
                    style={{
                      textTransform: "none",
                      fontSize: "14px",
                      fontWeight: "bold",
                      width: "fit-content",
                    }}
                    onClick={handleEdit}
                    color="primary"
                    variant="outlined"
                  >
                    Edit
                  </Button>
                </Box>
              </Box>
            </Toolbar>
          </AppBar>
          <ModelWrapper>
            <Grid container spacing={3}>
              <Grid
                container
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <UserProfileImageComponent
                    url={getProfileImageUrl()}
                    handleImageClick={() => {
                      setViewProfileImage(true);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <InputWrapper>
                  <Label>ID</Label>
                  <Detail>{dataRenderer(details["flid"])}</Detail>
                </InputWrapper>
              </Grid>
              <Grid item xs={6}>
                <InputWrapper>
                  <Label>Name</Label>
                  <Detail>{dataRenderer(details["name"])}</Detail>
                </InputWrapper>
              </Grid>
              <Grid item xs={6}>
                <InputWrapper>
                  <Label>Phone</Label>
                  <Detail>{dataRenderer(details["phone"])}</Detail>
                </InputWrapper>
              </Grid>
              <Grid item xs={6}>
                <InputWrapper>
                  <Label>Email</Label>
                  <Detail>{dataRenderer(details["email"])}</Detail>
                </InputWrapper>
              </Grid>
              <Grid item xs={6}>
                <InputWrapper>
                  <Label>Employee ID</Label>
                  <Detail>{dataRenderer(details["employee_id"])}</Detail>
                </InputWrapper>
              </Grid>

              <Grid item xs={6}>
                <InputWrapper>
                  <Label>Group</Label>
                  <Detail>{dataRenderer(details["group"])}</Detail>
                </InputWrapper>
              </Grid>
              <Grid item xs={6}>
                <InputWrapper>
                  <Label>Designation</Label>
                  <Detail>{dataRenderer(details["designation"])}</Detail>
                </InputWrapper>
              </Grid>
              <Grid item xs={6}>
                <InputWrapper>
                  <Label>Role</Label>
                  <Detail>{dataRenderer(details["role"])}</Detail>
                </InputWrapper>
              </Grid>
              <Grid item xs={6}>
                <InputWrapper>
                  <Label>Created At</Label>
                  <Detail>
                    {details["created_at"] !== null
                      ? IsoToLocalTime(details["created_at"])
                      : "-"}
                  </Detail>
                </InputWrapper>
              </Grid>
              <Grid item xs={6}>
                <InputWrapper>
                  <Label>Last Action At</Label>
                  <Detail>
                    {details["last_action_at"] !== null
                      ? IsoToLocalTime(details["last_action_at"])
                      : "-"}
                  </Detail>
                </InputWrapper>
              </Grid>
              <Grid item xs={6}>
                <InputWrapper>
                  <Label>Bank Name</Label>
                  <Detail>{dataRenderer(details["bank_name"])}</Detail>
                </InputWrapper>
              </Grid>
              <Grid item xs={6}>
                <InputWrapper>
                  <Label>Bank Account Number</Label>
                  <Detail>
                    {dataRenderer(details["bank_account_number"])}
                  </Detail>
                </InputWrapper>
              </Grid>
              <Grid item xs={6}>
                <InputWrapper>
                  <Label>Bank Account Name</Label>
                  <Detail>{dataRenderer(details["bank_account_name"])}</Detail>
                </InputWrapper>
              </Grid>
              <Grid item xs={6}>
                <InputWrapper>
                  <Label>IFSC Code</Label>
                  <Detail>{dataRenderer(details["ifsc_code"])}</Detail>
                </InputWrapper>
              </Grid>
              <Grid item xs={6}>
                <InputWrapper>
                  <Label>PAN Number</Label>
                  <Detail>{dataRenderer(details["pan_number"])}</Detail>
                </InputWrapper>
              </Grid>
              <Grid item xs={6}>
                <InputWrapper>
                  <Label>Reporting To</Label>
                  <Detail>
                    {details["reporting_to"] !== null
                      ? dataRenderer(details["reporting_to"]["name"])
                      : "-"}
                  </Detail>
                </InputWrapper>
              </Grid>
              <Grid item xs={6}>
                <InputWrapper>
                  <Label>DOB</Label>
                  <Detail>
                    {details.hasOwnProperty("dob") &&
                    details["dob"] !== null &&
                    details["dob"] !== ""
                      ? IsoToLocalDate(details["dob"])
                      : "-"}
                  </Detail>
                </InputWrapper>
              </Grid>
            </Grid>
          </ModelWrapper>
        </Box>
      ) : (
        <Box
          sx={{
            width: 500,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {viewProfileImage && (
        <PreviewImageDialog
          open={viewProfileImage}
          setOpen={setViewProfileImage}
          file={getProfileImageUrl()}
          isCreate={false}
        />
      )}
    </>
  );
};

export default ViewAdminUserSidepanel;
