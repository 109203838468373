import { Box, Card, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { observer } from "mobx-react-lite";
import TextFieldWidget from "./TextFieldWidget";
import RenderOptionFields from "./RenderOptionFields";
import { optionFieldsList } from "../../../Db";
import DateFieldWidget from "./DateFieldWidget";
import DatetimeFieldWidget from "./DatetimeFieldWidget";
import FileUploadWidget from "./FileUploadWidget";
import NumberFieldWidget from "./NumberFieldWidget";
import moment from "moment";
const Text = styled(Typography)`
  font: normal normal 400 16px Open Sans;
`;
const InnerWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  row-gap: 20px;
`;

const QuestionCard = ({
  questionData,
  columnMeta,
  inputData,
  setInputData,
  isLeadFormDisabled,
  prefilledData,
  setPrefilledData,
  listFieldInputMeta,
  getFieldInputMeta,
}) => {
  const getRegex = () => {
    if (columnMeta.regex === null || columnMeta.regex === "") {
      return null;
    } else {
      return columnMeta.regex;
    }
  };

  const getMomentDate = (dateString) => {
    if (dateString !== "" && dateString !== null) {
      return moment(dateString).local();
    }
    return null;
  };

  const renderField = () => {
    if (questionData["question_type"] === "string") {
      return (
        <TextFieldWidget
          value={
            prefilledData.hasOwnProperty(columnMeta["field"])
              ? prefilledData[columnMeta["field"]]["value"]
              : ""
          }
          disabled={isLeadFormDisabled}
          regex={getRegex()}
          handleChange={(event) => {
            setInputData({
              ...inputData,
              [columnMeta["field"]]: {
                value: event.target.value,
                type: questionData["question_type"],
              },
            });
            setPrefilledData({
              ...prefilledData,
              [columnMeta["field"]]: {
                value: event.target.value,
                type: questionData["question_type"],
              },
            });
          }}
        />
      );
    } else if (optionFieldsList.includes(questionData["question_type"])) {
      return (
        <RenderOptionFields
          columnMeta={columnMeta}
          questionData={questionData}
          inputData={inputData}
          setInputData={setInputData}
          prefilledData={prefilledData}
          setPrefilledData={setPrefilledData}
          listFieldInputMeta={listFieldInputMeta}
          isLeadFormDisabled={isLeadFormDisabled}
          getFieldInputMeta={({ columnMeta, inputMeta }) =>
            getFieldInputMeta({ columnMeta, inputMeta })
          }
        />
      );
    } else if (questionData["question_type"] === "date") {
      return (
        <DateFieldWidget
          value={
            prefilledData.hasOwnProperty(columnMeta["field"])
              ? prefilledData[columnMeta["field"]]["value"]
              : null
          }
          disabled={isLeadFormDisabled}
          min={getMomentDate(columnMeta["min"])}
          max={getMomentDate(columnMeta["max"])}
          handleChange={(event) => {
            setInputData({
              ...inputData,
              [columnMeta["field"]]: {
                value: event,
                type: questionData["question_type"],
              },
            });
            setPrefilledData({
              ...prefilledData,
              [columnMeta["field"]]: {
                value: event,
                type: questionData["question_type"],
              },
            });
          }}
        />
      );
    } else if (questionData["question_type"] === "datetime") {
      return (
        <DatetimeFieldWidget
          disabled={isLeadFormDisabled}
          value={
            prefilledData.hasOwnProperty(columnMeta["field"])
              ? prefilledData[columnMeta["field"]]["value"]
              : null
          }
          min={getMomentDate(columnMeta["min"])}
          max={getMomentDate(columnMeta["max"])}
          handleChange={(event) => {
            setInputData({
              ...inputData,
              [columnMeta["field"]]: {
                value: event,
                type: questionData["question_type"],
              },
            });
            setPrefilledData({
              ...prefilledData,
              [columnMeta["field"]]: {
                value: event,
                type: questionData["question_type"],
              },
            });
          }}
        />
      );
    } else if (questionData["question_type"] === "file") {
      return (
        <FileUploadWidget
          disabled={isLeadFormDisabled}
          value={
            prefilledData.hasOwnProperty(columnMeta["field"])
              ? prefilledData[columnMeta["field"]]["value"]
              : null
          }
          handleChange={(event) => {
            setInputData({
              ...inputData,
              [columnMeta["field"]]: {
                value: event.target.files[0],
                type: questionData["question_type"],
              },
            });
            setPrefilledData({
              ...prefilledData,
              [columnMeta["field"]]: {
                value: event.target.files[0],
                type: questionData["question_type"],
              },
            });
          }}
        />
      );
    } else if (questionData["question_type"] === "number") {
      return (
        <NumberFieldWidget
          disabled={isLeadFormDisabled}
          value={
            prefilledData.hasOwnProperty(columnMeta["field"])
              ? prefilledData[columnMeta["field"]]["value"]
              : ""
          }
          handleChange={(event) => {
            setInputData({
              ...inputData,
              [columnMeta["field"]]: {
                value: event.target.value,
                type: questionData["question_type"],
              },
            });
            setPrefilledData({
              ...prefilledData,
              [columnMeta["field"]]: {
                value: event.target.value,
                type: questionData["question_type"],
              },
            });
          }}
          regex={getRegex()}
        />
      );
    }
  };

  return (
    <Card
      sx={{
        boxShadow: "none",
        border: "1px solid rgb(218, 220, 224)",

        borderRadius: "12px",
      }}
    >
      <InnerWrapper id={questionData["id"]}>
        <Text style={{ color: isLeadFormDisabled ? "lightgray" : "#4d4e4f" }}>
          {questionData["required"]
            ? `${questionData["question"]}*`
            : questionData["question"]}
        </Text>
        {Object.keys(columnMeta).length > 0 ? (
          renderField()
        ) : (
          <Text>No field found.</Text>
        )}
      </InnerWrapper>
    </Card>
  );
};

export default observer(QuestionCard);
