import React from "react";
import { AgGridReact } from "ag-grid-react";
import { useMemo } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Box, styled } from "@mui/material";
const TableWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const TableComponent = (props) => {
  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const onFirstDataRendered = (params) => {
    const autoSizeColumns = props.columns
      .filter((col) => col.autoSize)
      .map((col) => col.field);
    params.columnApi.autoSizeColumns(autoSizeColumns);
  };
  return (
    <TableWrapper
      style={{ width: "100%", height: "100%" }}
      sx={{
        "& .ag-root-wrapper": {
          border:props.type==='fullscreen_mode'?'1px solid #babfc7': "none",
        },
      }}
    >
      <div
        className="ag-theme-alpine"
        style={{ width: "100%", height: "100%", overflowY: "auto" }}
      >
        <AgGridReact
          rowData={props.row}
          defaultColDef={defaultColDef}
          columnDefs={props.columns}
          animateRows={true}
          pagination={true}
          paginationPageSize={props.pageSize}
          className="table-widget-pagination"
          suppressColumnVirtualisation={true}
          suppressCellFocus
          onFirstDataRendered={onFirstDataRendered}
        />
      </div>
    </TableWrapper>
  );
};

export default TableComponent;
