import React from "react";
import Footer from "../../components/landing_page_components/footer/Footer";

import styled from "styled-components";
import { observer } from "mobx-react-lite";

import { ChevronRight } from "@material-ui/icons";
import rootStore from "../../stores/RootStore";
import ProjectMemberTab from "../../components/project_tabs/ProjectMemberTab";
import { useEffect } from "react";
import analytics from "../../Analytics";

const ParentContainer = styled.div`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled.div`
  height: 100%;
  margin: auto;
  margin-top: 70px;
  width: 95%;
`;
const Wrapper = styled.div`
  display: flex;
  position: relative;
  ${`min-height: calc(100vh - 188px);`};
  background-color: white;
  padding: 20px 20px 0px 20px;
  border: 2px solid #f9f9fc;
`;
const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 0px;
`;
const Text = styled.p`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
`;

const AdminProjectMembers = () => {
  const { authStore } = rootStore;
  const project_id = authStore.projectId;
  useEffect(() => {
    analytics.triggerEvent(
      4625000176,
      "project_members_page_load",
      "project_members_page",
      "",
      {}
    );
    authStore.updateLeadDetailStatus();
  }, []);
  return (
    <>
      <ParentContainer>
        <Container>
          <TopWrapper>
            <Text>Project Administration</Text>
            <ChevronRight style={{ width: "16px", height: "16px" }} />
            <Text>Members</Text>
          </TopWrapper>

          <Wrapper>
            <ProjectMemberTab id={project_id} />
          </Wrapper>
        </Container>
      </ParentContainer>
      <Footer />
    </>
  );
};

export default observer(AdminProjectMembers);
