import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  FormControl,
  Modal,
  Select,
  makeStyles,
} from "@material-ui/core";
import { editProjectApi } from "../../Api";
import { myTheme } from "../../themeUtils";
import { MenuItem } from "@mui/material";
import analytics from "../../Analytics";
import { getLocalizedText } from "../../Functions";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled.span`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const SelectColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const Label = styled.span`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Input = styled.input`
  width: 428px;
  height: 30px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;

  border: 1px solid #bfbfbf;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const MessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
`;
const Message = styled.p`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
  text-align: center;
`;
const AddProjectModal = (props) => {
  const classes = useStyles();
  const callingModes = ["Manual", "Progressive"];
  const [user, setUser] = useState({
    name: "",
    calling_mode: "",
  });
  const [check, setCheck] = useState(false);
  const [errorVal, setErrorVal] = useState(false);

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const editProject = async () => {
      try {
        let response = await editProjectApi({
          id: props.editableData.id,
          data: user,
        });
        props.setRefresh(!props.refresh);

        setCheck(true);
        setErrorVal(false);
      } catch (error) {
        setCheck(true);
        setErrorVal(true);
        console.log(error);
      }
    };
    editProject();
  };
  const handleClose = () => {
    props.setIsEdit(false);
    props.setOpen(false);
  };
  useEffect(() => {
    if (props.isEdit === true) {
      analytics.triggerEvent(
        4625000170,
        "edit_details_form_load",
        "project_setting_page",
        "",
        {}
      );
      setUser({
        name: props.editableData.name,
        calling_mode: props.editableData.calling_mode,
      });
    }
  }, []);
  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!check ? (
          <ModelContainer>
            <Box>
              <Container>
                {props.isEdit ? (
                  <Header>{getLocalizedText("edit_details")}</Header>
                ) : (
                  <Header>{getLocalizedText("add_a_new_project")}</Header>
                )}
                <Form onSubmit={handleSubmit}>
                  <InputContainer>
                    <InputWrapper>
                      <Label>{getLocalizedText("project_name")}*</Label>
                      <Input
                        value={user.name}
                        required
                        type="text"
                        placeholder="Enter name here..."
                        name="name"
                        onChange={handleChange}
                      />
                    </InputWrapper>
                    <SelectColumn>
                      <Label>{getLocalizedText("calling_mode")}</Label>
                      <FormControl
                        disabled={
                          props.editableData["external_dialer"] === "default"
                        }
                        sx={{ m: 1, minWidth: 428 }}
                        classes={{
                          root: classes.quantityRoot,
                        }}
                      >
                        <Select
                          classes={{
                            icon: classes.icon,
                          }}
                          s
                          name="calling_mode"
                          displayEmpty
                          disableUnderline
                          variant="outlined"
                          required
                          value={user.calling_mode}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          style={{
                            marginBottom: "20px",
                            width: "428px",
                            height: "30px",
                            fontSize: "12px",
                            fontWeight: "bold",
                            borderRadius: "0px",
                          }}
                        >
                          {callingModes.map((e) => {
                            return (
                              <MenuItem id={e} value={e}>
                                {e}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </SelectColumn>
                  </InputContainer>
                  <ButtonWrapper>
                    <Button
                      onClick={() => {
                        analytics.triggerEvent(
                          4625000171,
                          "cancel_button_tap",
                          "edit_details_form",
                          "cancel_button",
                          {}
                        );
                        handleClose();
                      }}
                      type="button"
                      variant="contained"
                      color="default"
                      style={{
                        backgroundColor: "#EFEFF4",
                        textTransform: "none",
                        marginRight: "20px",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      {getLocalizedText("cancel")}
                    </Button>
                    <Button
                      onClick={() => {
                        analytics.triggerEvent(
                          4625000172,
                          "submit_button_tap",
                          "edit_details_form",
                          "submit_button",
                          {}
                        );
                      }}
                      type="submit"
                      variant="contained"
                      id="call-log-btn"
                      style={{
                        textTransform: "none",
                        fontSize: "12px",
                        fontWeight: "bold",
                        ...myTheme.Button.btnBlue,
                      }}
                    >
                      {getLocalizedText("submit")}
                    </Button>
                  </ButtonWrapper>
                </Form>
              </Container>
            </Box>
          </ModelContainer>
        ) : (
          <ModelContainer
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {!errorVal ? (
              <MessageWrapper>
                {props.isEdit ? (
                  <Message>
                    {getLocalizedText("project_info_updated_successfully")}
                  </Message>
                ) : (
                  <Message>
                    {getLocalizedText("project_added_successfully")}
                  </Message>
                )}
                <Button
                  onClick={handleClose}
                  type="button"
                  variant="contained"
                  color="default"
                  style={{
                    marginTop: "20px",
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "100px",
                  }}
                >
                  {getLocalizedText("close")}
                </Button>
              </MessageWrapper>
            ) : (
              <MessageWrapper>
                <Message style={{ color: "red" }}>
                  {getLocalizedText("some_error_occured")}
                </Message>
                <Button
                  onClick={handleClose}
                  type="button"
                  variant="contained"
                  color="default"
                  style={{
                    marginTop: "20px",
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "100px",
                  }}
                >
                  {getLocalizedText("close")}
                </Button>
              </MessageWrapper>
            )}
          </ModelContainer>
        )}
      </Modal>
    </>
  );
};

export default observer(AddProjectModal);
