import { Edit, Person } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import rootStore from "../../stores/RootStore";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { consoleLogger } from "../../Functions";

const Container = styled.div`
  display: flex;
  align-items: center;
  min-height: 100px;
  width: 350px;
  border: 1px solid #efeff4;
  /* border-radius: 0px 10px 0px 10px; */
  background-color: white;
  border-radius: 8px;
`;
const Left = styled.div`
  width: 10px;
  background-color: ${(e) => e.props};
  height: 100%;

  border-radius: 8px 0px 0px 8px;
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px;
  width: 100%;
`;
const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PriceContainer = styled.div`
  width: 100%;
  background-color: #efeff4;
  padding: 4px 12px;
  /* border-radius: 0px 8px 0px 8px; */
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  border-radius: 4px;
`;
const Text1 = styled.span`
  font: normal normal 600 14px Open Sans;
  margin: 0px;
  text-align: left;
`;
const Text2 = styled.span`
  font: normal normal normal 12px Open Sans;
  margin: 0px;
  text-align: left;
  color: #b3b3b3;
  margin-bottom: 20px;
`;
const Text3 = styled.span`
  font: normal normal 600 12px Open Sans;
  margin: 0px;
  text-align: center;
  color: #4d4e4f;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;
const EditableInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 2px 4px;
  display: flex;
  position: absolute;
  text-align: center;
  font: normal normal normal 14px Open Sans;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  margin: auto;
  outline: none;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  /* border-radius: 0px 8px 0px 8px; */
`;
const Label = styled.div`
  font: normal normal 600 12px Open Sans;
  color: #979799;
`;
const Detail = styled.div`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
  margin-left: 4px;
`;
const EditWrapper = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  cursor: pointer;
`;
const FixedText = styled.span`
  font: normal normal normal 14px Open Sans;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
  left: 12px;
  z-index: 9;
`;

const ProjectCard = (props) => {
  const { userStore, authStore } = rootStore;
  const [value, setValue] = useState(0);
  const [flag, setFlag] = useState(false);
  const [total, setTotal] = useState(null);
  let role = authStore.getCurrentProjectRole();
  const handleEdit = () => {
    setValue(value);
    setFlag(false);
  };
  useEffect(() => {
    let total = 0;
    Object.keys(toJS(userStore.teamsTarget)).map((key) => {
      total += parseInt(toJS(userStore.teamsTarget)[key]);
    });

    setTotal(total);
    consoleLogger("team total : ", total);
  }, [userStore.teamsTarget]);
  return (
    <Container>
      <Left props={props.color}>
        {/* <IconWrapper>
          <Person />
        </IconWrapper> */}
      </Left>
      <Right>
        <Text1>{props.project !== null && props.project.name}</Text1>
        {role !== "" && (role === "manager" || role === "lead") ? (
          <Text2>
            {userStore.Basic[0].name}({role && role})
          </Text2>
        ) : (
          <Text2 style={{ marginLeft: "4px", color: "#4d4e4f" }}>-</Text2>
        )}
        <PriceContainer props={props.color}>
          {flag && (
            <>
              <FixedText>{total} + </FixedText>
              <EditableInput
                props={props.color}
                value={value}
                autoFocus={true}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                onKeyUp={(e) =>
                  e.key === "Enter"
                    ? handleEdit()
                    : e.key === "Escape"
                    ? setFlag(false)
                    : null
                }
              />
            </>
          )}
          <Text3>
            <span>
              {total !== null && value !== "" ? total + parseInt(value) : total}
            </span>
          </Text3>
          <EditWrapper
            onClick={() => {
              setFlag(true);
            }}
          >
            <Edit
              style={{
                width: "12px",
                height: "12px",
                marginRight: "4px",
                color: "#185DD2",
              }}
            />
            <Text3 style={{ color: "#185DD2" }}>Edit</Text3>
          </EditWrapper>
        </PriceContainer>
      </Right>
    </Container>
  );
};

export default observer(ProjectCard);
