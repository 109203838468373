import React, { useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  makeStyles,
  Modal,
  Radio,
  RadioGroup,
  Select,
} from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import { addUsersApi, editUsersApi } from "../../Api";
import { useEffect } from "react";
import { myTheme } from "../../themeUtils";
import analytics from "../../Analytics";
import { getLocalizedText } from "../../Functions";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled.span`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled.span`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Label = styled.span`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Input = styled.input`
  width: 428px;
  height: 30px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;

  border: 1px solid #bfbfbf;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;

const ButtonWrapper = styled.div`
  /* margin-top: 30px; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 5px;
`;
const SelectColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  column-gap: 28px;
  margin-bottom: 20px;
`;

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const AddUserModal = (props) => {
  const classes = useStyles();
  const [user, setUser] = useState({
    name: "",
    email: "",
    phone: "",
    role: "member",
    unmask_columns: false,
    employee_id: "",
    designation: "",
    group: "",
  });
  const roleList = ["admin", "member"];
  const handleClick = () => {
    props.setIsEdit(false);

    props.setOpen(false);
  };
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const editUser = async () => {
      let data = {
        ...user,
      };
      try {
        let response = await editUsersApi({
          id: props.editableData.id,
          data: data,
        });

        props.setIsEdit(false);
        props.setRefresh(!props.refresh);
        props.setOpen(false);
      } catch (error) {
        console.log(error);
      }
    };
    const addUser = async () => {
      let data = {
        ...user,
      };

      try {
        let response = await addUsersApi({
          data: data,
        });

        props.setIsEdit(false);
        props.setRefresh(!props.refresh);
        props.setOpen(false);
      } catch (error) {
        console.log(error);
      }
    };
    if (props.isEdit === true) {
      editUser();
    } else {
      addUser();
    }
  };
  const handleClose = () => {
    props.setIsEdit(false);
    props.setOpen(false);
  };
  useEffect(() => {
    analytics.triggerEvent(
      4625000119,
      "add_user_form_load",
      "admin_users_page",
      "",
      {}
    );
    if (props.isEdit === true) {
      setUser({
        name: props.editableData.name,
        email: props.editableData.email,
        phone: props.editableData.phone,
        role: props.editableData.role,
        unmask_columns: props.editableData.unmask_columns,
        employee_id: props.editableData.employee_id,
        designation: props.editableData.designation,
        group: props.editableData.group,
      });
    }
  }, []);
  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <Container>
              {props.isEdit ? (
                <Header>{getLocalizedText("edit_details")}</Header>
              ) : (
                <Header>{getLocalizedText("add_a_new_user")}</Header>
              )}
              <SubHeader>
                {getLocalizedText("fill_in_the_below_details")}
              </SubHeader>
              <Divider style={{ margin: "16px 0px" }} />
              <Form onSubmit={handleSubmit}>
                <InputContainer>
                  <InputWrapper>
                    <Label>{getLocalizedText("name")}*</Label>
                    <Input
                      value={user.name}
                      required
                      type="text"
                      placeholder="Enter name here..."
                      name="name"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <Label>{getLocalizedText("email")}*</Label>
                    <Input
                      pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                      value={user.email}
                      required
                      type="email"
                      placeholder="Enter email here..."
                      name="email"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <Label>{getLocalizedText("phone")}*</Label>
                    <Input
                      pattern="^(\+|00)[1-9][0-9 \-\(\)\.]{7,32}$"
                      value={user.phone}
                      required
                      type="text"
                      placeholder="+91-1231231231"
                      name="phone"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <Label>{getLocalizedText("employee_id")}</Label>
                    <Input
                      value={user.employee_id}
                      type="text"
                      placeholder="Enter employee id"
                      name="employee_id"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <Label>{getLocalizedText("group")}</Label>
                    <Input
                      value={user.group}
                      type="text"
                      placeholder="Enter group"
                      name="group"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <Label>{getLocalizedText("designation")}</Label>
                    <Input
                      value={user.designation}
                      type="text"
                      placeholder="Enter designation"
                      name="designation"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                  <Row>
                    <SelectColumn>
                      <Label>{getLocalizedText("select_role")}</Label>
                      <FormControl
                        sx={{ minWidth: 200 }}
                        classes={{
                          root: classes.quantityRoot,
                        }}
                      >
                        <Select
                          classes={{
                            icon: classes.icon,
                          }}
                          value={user.role}
                          name="role"
                          displayEmpty
                          disableUnderline
                          variant="outlined"
                          disabled={user.role === "owner"}
                          required={user.role !== "owner"}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          style={{
                            width: "200px",
                            height: "30px",
                            fontSize: "12px",
                            fontWeight: "bold",
                            borderRadius: "0px",
                          }}
                        >
                          {user.role !== "owner" ? (
                            roleList.map((e) => {
                              return (
                                <MenuItem id={e} value={e}>
                                  {e}
                                </MenuItem>
                              );
                            })
                          ) : (
                            <MenuItem value="owner">Owner</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </SelectColumn>
                    <InputWrapper style={{ width: "200px" }}>
                      <Label
                        style={{
                          marginBottom: "0px",
                        }}
                      >
                        {getLocalizedText("unmask_columns")}
                      </Label>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio size="small" />}
                          label={
                            <Label style={{ fontSize: "12px" }}>Yes</Label>
                          }
                          checked={user.unmask_columns}
                          onChange={() => {
                            setUser({ ...user, unmask_columns: true });
                          }}
                        />
                        <FormControlLabel
                          value={false}
                          checked={!user.unmask_columns}
                          onChange={() => {
                            setUser({ ...user, unmask_columns: false });
                          }}
                          control={<Radio size="small" />}
                          label={<Label style={{ fontSize: "12px" }}>No</Label>}
                        />
                      </RadioGroup>
                    </InputWrapper>
                  </Row>
                </InputContainer>
                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      analytics.triggerEvent(
                        4625000120,
                        "cancel_button_tap",
                        "add_user_form",
                        "cancel_button",
                        {}
                      );
                      handleClick();
                    }}
                    type="button"
                    variant="contained"
                    color="default"
                    style={{
                      backgroundColor: "#EFEFF4",
                      textTransform: "none",
                      marginRight: "20px",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                  <Button
                    onClick={() => {
                      analytics.triggerEvent(
                        4625000121,
                        "submit_button_tap",
                        "add_user_form",
                        "submit_button",
                        {}
                      );
                    }}
                    type="submit"
                    variant="contained"
                    id="call-log-btn"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      ...myTheme.Button.btnBlue,
                    }}
                  >
                    {getLocalizedText("submit_details")}
                  </Button>
                </ButtonWrapper>
              </Form>
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default observer(AddUserModal);
