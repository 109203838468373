import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import Footer from "../../components/landing_page_components/footer/Footer";
import { useEffect } from "react";

const Container = styled.div`
  min-height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9fc;
  justify-content: center;
  margin-top: 70px;
  width: 100%;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  ${`min-height: calc(100vh - 139px);`};
`;
const Text = styled.p`
  margin: 0px;
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const NoAccessPermissionPage = (props) => {
  return (
    <>
      <Container>
        <Wrapper>
          <Text>You do not have access to this feature.</Text>
          <Text>
            Please contact{" "}
            <span style={{ color: "#0000ff" }}>support@leadpixie.in</span> for
            any queries.
          </Text>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
};

export default observer(NoAccessPermissionPage);
