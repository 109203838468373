import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Toolbar,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { getLocalizedText } from "../../Functions";
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { Rectangle } from "@mui/icons-material";
import { leadPresetColorsWithLabels } from "../../Db";
const dialogWidth = 380;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  gap: 8px;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const NilColorBox = styled(Box)`
  position: relative;
  width: 25px;
  height: 20px;
  background-color: white;
  border: 1px solid #bfbfbf;
  overflow: hidden;
  margin: 0px 3px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 125%;
    height: 100%;
    border-top: 1px solid #bfbfbf;
    transform: rotate(37deg);
    transform-origin: top left;
  }
`;
const CustomLeadColorPickerDialog = ({ open, setOpen, handleApply, value }) => {
  const [color, setColor] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleApply(color);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleColorChange = (event) => {
    setColor(event.target.value);
  };
  useEffect(() => {
    if (value !== null && value !== "") {
      setColor(value);
    } else {
      setColor(leadPresetColorsWithLabels[0].color);
    }
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box component={"form"} onSubmit={handleSubmit}>
            <AppBar
              component={"nav"}
              elevation={0}
              position="sticky"
              color="inherit"
              sx={{
                top: 0,
                bottom: "auto",
                width: "100%",
                right: 0,
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                }}
              >
                <HeaderWrapper>
                  <Header>Set Lead Color</Header>
                </HeaderWrapper>
              </Toolbar>
            </AppBar>
            <Divider style={{ margin: "0px 16px" }} />
            <Container>
              <Box
                style={{
                  height: "100%",
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={color}
                      onChange={handleColorChange}
                    >
                      <Grid container>
                        {leadPresetColorsWithLabels.map((color, index) => {
                          if (color.color === "nil") {
                            return (
                              <Grid item xs={12}>
                                <FormControlLabel
                                  value={color.color}
                                  control={<Radio />}
                                  label={
                                    <InputWrapper>
                                      <NilColorBox />:<Label>-</Label>
                                    </InputWrapper>
                                  }
                                />
                              </Grid>
                            );
                          } else {
                            return (
                              <Grid item xs={12}>
                                <FormControlLabel
                                  value={color.color}
                                  control={<Radio />}
                                  label={
                                    <InputWrapper>
                                      <Rectangle
                                        key={index}
                                        style={{
                                          color: color.color,
                                          cursor: "pointer",
                                          width: "30px",
                                          height: "auto",
                                        }}
                                      />
                                      :<Label>{color.label}</Label>
                                    </InputWrapper>
                                  }
                                />
                              </Grid>
                            );
                          }
                        })}
                      </Grid>
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Box>
            </Container>
            <AppBar
              position="static"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: "100%" }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                  width: dialogWidth,
                  position: "relative",
                }}
              >
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      onClick={() => {
                        setOpen(false);
                      }}
                      variant="contained"
                      color="default"
                      style={{
                        fontSize: "12px",
                        textTransform: "none",
                        fontWeight: "bold",
                      }}
                    >
                      {getLocalizedText("cancel")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => {}}
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        width: "fit-content",
                        textTransform: "none",
                      }}
                    >
                      Apply
                    </Button>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default CustomLeadColorPickerDialog;
