import { Button } from "@material-ui/core";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

const CustomConfirmationDialog = (props) => {
  return (
    <Dialog
      disableEnforceFocus
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ fontWeight: "bold" }}>
        Confirmation
      </DialogTitle>

      <DialogContent>
        <DialogContentText>{props.message}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          style={{ textTransform: "capitalize", fontWeight: "bold" }}
          onClick={() => {
            props.setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          style={{ textTransform: "capitalize", fontWeight: "bold" }}
          onClick={props.handleConfirm}
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomConfirmationDialog;
