import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { editContactApi, editLeadApi, getPhoneApi } from "../../Api";
import {
  IsoToLocalDate,
  IsoToLocalTime,
  getLocalizedText,
  isDateInRange,
  isDateTimeInRange,
} from "../../Functions";
import { myTheme } from "../../themeUtils";
import { useEffect } from "react";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import moment from "moment";
import MuiAlert from "@mui/material/Alert";
import {
  Box,
  Button,
  CircularProgress,
  AppBar,
  Toolbar,
  Typography,
} from "@mui/material";
import GroupFieldWidget from "../create_lead_modal/group_field_widget/GroupFieldWidget";
import { Grid } from "@material-ui/core";
import { titleCase } from "title-case";
import NumberField from "../custom_field_components/NumberField";
import MultiSelectField from "../custom_field_components/MultiSelectField";
import DateField from "../custom_field_components/DateField";
import DateTimeField from "../custom_field_components/DateTimeField";
import SearchableCustomSelectField from "../select_dropdown/SearchableCustomSelectField";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;
const ModelSubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
`;
const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  flex-direction: column;
`;
const Message = styled(Typography)`
  font: normal normal normal 18px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: flex-end;
  column-gap: 10px;
`;
const Column = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: #f9f9fc;
`;

const Label = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
`;
const Input = styled("input")`
  width: 100%;
  height: 38px;
  padding: 2px 6px;
  outline: none;
  border: 1px solid #e0deca;
  border-radius: 4px;
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal normal 14px Open Sans;
    color: #b5b5b5;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const TextArea = styled("textarea")`
  width: 100%;
  height: 60px;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #e0deca;
  color: #4d4e4f;
  border-radius: 4px;
  resize: none;
  font: normal normal 600 14px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal normal 14px Open Sans;
    color: #b5b5b5;
  }
`;

const InputContainer = styled(Box)`
  display: flex;
  column-gap: 20px;
  row-gap: 20px;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  white-space: pre-line;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const EditLeadComponent = (props) => {
  const { userStore } = rootStore;
  const [details, setDetails] = useState({});
  const [changedValues, setChangedValues] = useState({});
  const columnsList = [...userStore.AllColumnsList];
  const [sortedColumnsList, setSortedColumnsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [parentChildData, setParentChildData] = useState({});

  const getParentFieldValue = (parent_field_info) => {
    let index = columnsList.findIndex(
      (column) => column.id === parent_field_info.id
    );
    if (index !== -1) {
      let target_column = columnsList[index];
      if (details.hasOwnProperty(target_column["field"])) {
        return {
          value: details[target_column["field"]],
          field_data: target_column,
        };
      }
    }
    return null;
  };

  const inRange = (num, min, max) => num >= min && num <= max;
  const validateFieldsWithRange = () => {
    let filled_fields = { ...changedValues };

    for (let key in filled_fields) {
      let index = columnsList.findIndex((column) => column["field"] === key);
      if (index !== -1) {
        let columnMeta = columnsList[index];
        if (
          columnMeta["min"] !== null &&
          columnMeta["max"] !== null &&
          columnMeta["min"] !== "" &&
          columnMeta["max"] !== "" &&
          filled_fields[key]["value"] !== null &&
          filled_fields[key]["value"] !== ""
        ) {
          if (columnMeta["data_type"] === "integer") {
            console.log(filled_fields[key]);
            if (
              !inRange(
                parseFloat(filled_fields[key]["value"]),
                parseFloat(columnMeta["min"]),
                parseFloat(columnMeta["max"])
              )
            ) {
              setErrorMessage(
                `Please ensure the '${columnMeta["headerName"]}' value is between ${columnMeta["min"]} and ${columnMeta["max"]}.`
              );
              return false;
            }
          } else if (columnMeta["data_type"] === "datetime") {
            if (
              !isDateTimeInRange(
                columnMeta["min"],
                columnMeta["max"],
                filled_fields[key]["value"]
              )
            ) {
              setErrorMessage(
                `Please ensure the '${
                  columnMeta["headerName"]
                }' value is between ${IsoToLocalTime(
                  columnMeta["min"]
                )} and ${IsoToLocalTime(columnMeta["max"])}.`
              );
              return false;
            }
          } else if (columnMeta["data_type"] === "date") {
            if (
              !isDateInRange(
                columnMeta["min"],
                columnMeta["max"],
                filled_fields[key]["value"]
              )
            ) {
              setErrorMessage(
                `Please ensure the '${
                  columnMeta["headerName"]
                }' value is between ${IsoToLocalDate(
                  columnMeta["min"]
                )} and ${IsoToLocalDate(columnMeta["max"])}.`
              );
              return false;
            }
          }
        }
      }
    }

    return true;
  };

  const handleChange = ({ fieldName, fieldValue, type }) => {
    setIsSubmitFail(false);
    let prevDetailsState = { ...details };
    let prevChangedState = { ...changedValues };
    prevDetailsState[fieldName] = {
      value: fieldValue,
      type: type,
    };
    prevChangedState[fieldName] = {
      value: fieldValue,
      type: type,
    };
    let index = columnsList.findIndex(
      (column) => column["field"] === fieldName
    );
    let columnMeta = columnsList[index];
    if (columnMeta["parent"]) {
      let childList = parentChildData[columnMeta["id"]];

      childList.forEach((child) => {
        prevDetailsState[child["field"]] = {
          value: "",
          type: child["data_type"],
        };

        prevChangedState[child["field"]] = {
          value: "",
          type: child["data_type"],
        };
      });
    }
    setDetails(prevDetailsState);
    setChangedValues(prevChangedState);
  };

  const getLeadPhone = async () => {
    let response = await getPhoneApi(props.id);
    return response.data;
  };

  const dataGetter = async (data, key, type, isArray) => {
    if (data.hasOwnProperty(key)) {
      let value = data[key];
      if (key === "phone") {
        value = await getLeadPhone();
      } else if (type === "date" || type === "datetime") {
        value = data[key] !== "" && data[key] !== null ? moment(data[key]) : "";
      } else if (isArray == true) {
        let tempList =
          data[key] !== null && data[key] !== "" ? data[key].split(",") : [];
        let newValue = [];
        tempList.forEach((str) => {
          let tempObj = { label: str, value: str };
          newValue.push(tempObj);
        });
        value = newValue;
      }
      if (value !== null) {
        return value;
      } else {
        return isArray ? [] : "";
      }
    } else {
      return isArray ? [] : "";
    }
  };

  const generateContactPayloadData = (data) => {
    let contactFields = {};
    for (const key in data) {
      let value = data[key]["value"];
      if (data[key]["type"] === "integer") {
        value = parseInt(data[key]["value"]);
      } else if (data[key]["type"] === "array") {
        let tempList = [];
        data[key]["value"].forEach((item) => {
          tempList.push(item["value"]);
        });
        value = tempList.join(",");
      } else {
        value = data[key]["value"];
        let index = columnsList.findIndex(
          (column) => column["contact_column"] === key
        );
        if (index !== -1) {
          if (columnsList[index].hasOwnProperty("set_title_case")) {
            if (columnsList[index]["set_title_case"] === true) {
              value = titleCase(data[key]["value"]);
            }
          }
        }
      }

      contactFields[key] = value;
    }

    return contactFields;
  };

  const getContactData = () => {
    let tempList = {};
    for (const key in changedValues) {
      let index = columnsList.findIndex((column) => column["field"] === key);
      if (index !== -1) {
        let columnData = columnsList[index];
        if (columnData["contact_column"] !== null) {
          tempList[columnData["contact_column"]] =
            changedValues[columnData["field"]];
        }
      }
    }
    return tempList;
  };

  const submitEditLead = async () => {
    let newValue = {};
    let newCustomValue = {};
    if (Object.keys(changedValues).length !== 0) {
      Object.keys(changedValues).forEach((key) => {
        let index = columnsList.findIndex((column) => column["field"] === key);
        if (index !== -1) {
          if (changedValues[key]["type"] === "integer") {
            let value = parseInt(changedValues[key]["value"]);
            if (columnsList[index]["is_custom_param"]) {
              let new_key_name = key.replace("custom_params_", "");
              newCustomValue[new_key_name] = value;
            } else {
              newValue[key] = value;
            }
          } else if (changedValues[key]["type"] === "array") {
            let tempList = [];
            changedValues[key]["value"].forEach((item) => {
              tempList.push(item["value"]);
            });
            if (columnsList[index]["is_custom_param"]) {
              let new_key_name = key.replace("custom_params_", "");
              newCustomValue[new_key_name] = tempList.join(",");
            } else {
              newValue[key] = tempList.join(",");
            }
          } else {
            let value = changedValues[key]["value"];
            if (columnsList[index].hasOwnProperty("set_title_case")) {
              if (columnsList[index]["set_title_case"] === true) {
                value = titleCase(value);
              }
            }
            if (columnsList[index]["is_custom_param"]) {
              let new_key_name = key.replace("custom_params_", "");
              newCustomValue[new_key_name] = value;
            } else {
              newValue[key] = value;
            }
          }
        }
      });
    }

    let payload = {
      ...newValue,
    };
    if (Object.keys(newCustomValue).length > 0) {
      payload["custom_params"] = { ...newCustomValue };
    }
    payload["updated_fields"] = {
      ...payload,
    };
    try {
      let response = await editLeadApi({
        id: props.id,
        data: payload,
      });
      props.setRefreshLeadDetails(true);
      setIsSubmitSuccess(true);
    } catch (error) {
      setErrorMessage(getLocalizedText("some_error_occurred"));
      setIsSubmitFail(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Object.keys(changedValues).length > 0) {
      if (validateFieldsWithRange()) {
        let contactData = getContactData();
        if (Object.keys(contactData).length > 0) {
          if (
            props.leadDetails["contact_id"] !== null &&
            props.leadDetails["contact_id"] !== ""
          ) {
            let contactPayload = generateContactPayloadData(contactData);
            let updateContactResponse = await editContactApi({
              contactID: props.leadDetails["contact_id"],
              payload: contactPayload,
            });
            if (!updateContactResponse.hasError()) {
              await submitEditLead();
            } else {
              setErrorMessage(updateContactResponse.errorMessage);
              setIsSubmitFail(true);
            }
          }
        } else {
          await submitEditLead();
        }
      } else {
        setIsSubmitFail(true);
      }
    } else {
      setErrorMessage(
        '"Kindly make a modification before submitting the form."'
      );
      setIsSubmitFail(true);
    }
  };

  const initEditableData = async (filteredListByColumn, parentChildData) => {
    let newFieldList = {};
    let custom_data = props.leadDetails.hasOwnProperty("custom_params")
      ? JSON.parse(props.leadDetails["custom_params"])
      : {};
    if (filteredListByColumn.length > 0) {
      for (let i = 0; i < filteredListByColumn.length; i++) {
        let item = filteredListByColumn[i];
        if (item["parent"] === true) {
          let children = parentChildData[item["id"]];
          for (let j = 0; j < children.length; j++) {
            let childItem = children[j];

            if (childItem["is_custom_param"]) {
              let new_field = childItem["field"].replace("custom_params_", "");
              let value = await dataGetter(
                custom_data,
                new_field,
                childItem["data_type"],
                childItem["is_array"]
              );
              newFieldList[childItem["field"]] = {
                value: value,
                type: childItem["data_type"],
              };
            } else {
              let value = await dataGetter(
                props.leadDetails,
                childItem["field"],
                childItem["data_type"],
                childItem["is_array"]
              );
              newFieldList[childItem["field"]] = {
                value: value,
                type: childItem["data_type"],
              };
            }
          }
        }
        if (item["is_custom_param"]) {
          let new_field = item["field"].replace("custom_params_", "");
          let value = await dataGetter(
            custom_data,
            new_field,
            item["data_type"],
            item["is_array"]
          );
          newFieldList[item["field"]] = {
            value: value,
            type: item["data_type"],
          };
        } else {
          let value = await dataGetter(
            props.leadDetails,
            item["field"],
            item["data_type"],
            item["is_array"]
          );
          newFieldList[item["field"]] = {
            value: value,
            type: item["data_type"],
          };
        }
      }
    }
    setDetails(newFieldList);
  };

  function setupColumns() {
    if (columnsList.length > 0) {
      let tempColumns = [];
      for (let id of props.columnIds) {
        let index = columnsList.findIndex((element) => element["id"] === id);
        if (index !== -1) {
          if (
            columnsList[index]["show_in_edit_form"] &&
            columnsList[index]["derived"] === false
          ) {
            tempColumns.push(columnsList[index]);
          }
        }
      }
      let requiredFields = tempColumns
        .filter((item) => item["required"] && item["child_of"] === null)
        .slice();
      requiredFields.sort((a, b) =>
        a["headerName"]
          .toLowerCase()
          .localeCompare(b["headerName"].toLowerCase())
      );

      let parentFields = tempColumns.filter((item) => item["parent"]).slice();
      parentFields.sort((a, b) =>
        a["headerName"]
          .toLowerCase()
          .localeCompare(b["headerName"].toLowerCase())
      );

      let allFields = requiredFields.slice();

      parentFields.forEach((data) => {
        let index = allFields.findIndex(
          (element) => element["id"] === data["id"]
        );
        if (index === -1) {
          allFields.push(data);
        }
      });

      let parentChildMap = {};
      for (let item of tempColumns) {
        if (item["parent"]) {
          let parentId = item["id"];
          let children = tempColumns
            .filter(
              (child) =>
                child["child_of"] !== null &&
                child["child_of"]["id"] === parentId
            )
            .slice()
            .sort((a, b) =>
              a["headerName"]
                .toLowerCase()
                .localeCompare(b["headerName"].toLowerCase())
            );
          parentChildMap[parentId] = children;
        }
      }

      let remainingFields = [];
      tempColumns.forEach((item) => {
        let index = allFields.findIndex(
          (element) => element["id"] === item["id"]
        );
        if (index === -1 && item["child_of"] === null) {
          remainingFields.push(item);
        }
      });
      remainingFields.sort((a, b) =>
        a["headerName"]
          .toLowerCase()
          .localeCompare(b["headerName"].toLowerCase())
      );
      remainingFields.forEach((data) => {
        let index = allFields.findIndex(
          (element) => element["id"] === data["id"]
        );
        if (index === -1) {
          allFields.push(data);
        }
      });

      allFields.sort((a, b) => {
        if (a.is_multiline && !b.is_multiline) {
          return 1;
        } else if (!a.is_multiline && b.is_multiline) {
          return -1;
        } else {
          return 0;
        }
      });

      //*************width arrangement for columns************** */
      for (const item of allFields) {
        if (item.is_multiline || item.parent) {
          item.width = 2;
          item.modifyWidth = false;
        } else {
          item.width = 1;
          item.modifyWidth = true;
        }
      }
      for (let i = 0; i < allFields.length; i++) {
        let currentItem = allFields[i];

        if (currentItem.modifyWidth) {
          if (i === allFields.length - 1) {
            currentItem.width = 2;
            currentItem.modifyWidth = false;
          } else {
            let nextItem = allFields[i + 1];

            if (nextItem.width === 2) {
              currentItem.width = 2;
              currentItem.modifyWidth = false;
            } else if (nextItem.width === 1) {
              currentItem.width = 1;
              currentItem.modifyWidth = false;
              nextItem.modifyWidth = false;
            }
          }
        }
      }
      //***********************END*************************** */

      return { columnMetaList: allFields, parentChildList: parentChildMap };
    }
  }
  const init = async () => {
    let metaData = setupColumns();
    setSortedColumnsList(metaData.columnMetaList);
    await initEditableData(metaData.columnMetaList, metaData.parentChildList);
    setParentChildData(metaData.parentChildList);
    setIsLoading(false);
  };
  useEffect(() => {
    init();
  }, []);
  const getRegex = (item) => {
    if (item.regex === null || item.regex === "") {
      return null;
    } else {
      return item.regex;
    }
  };

  const isColumnEditable = (column) => {
    if (column["editable"]) {
      let columnPermissions = rootStore.userStore.columnPermissions;
      let permIndex = columnPermissions.findIndex(
        (perm) => perm["column_meta"]["id"] === column.id
      );
      if (permIndex !== -1) {
        return columnPermissions[permIndex]["is_editable"];
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const RenderLabel = ({ item }) => {
    let isEditable = isColumnEditable(item);

    return (
      <Label style={{ color: isEditable ? "#4d4e4f" : "lightgray" }}>
        {item.headerName}
        {item.required && (
          <span style={{ color: isEditable ? "red" : "lightgray" }}>*</span>
        )}
      </Label>
    );
  };

  const renderField = ({ item }) => {
    let isEditable = isColumnEditable(item);

    if (item["is_array"] === true) {
      let list = [];
      if (item["filter_input"] !== null && item["filter_input"] !== "") {
        let tempList = item["filter_input"].split(",");
        tempList.forEach((str) => {
          let tempObj = { label: str, value: str };
          list.push(tempObj);
        });
      }

      return (
        <Grid item xs={item["width"] === 1 ? 6 : 12}>
          <InputWrapper>
            <RenderLabel item={item} />
            <MultiSelectField
              disabled={!isEditable}
              handleChange={(selected) => {
                handleChange({
                  fieldName: item.field,
                  fieldValue: selected,
                  type: "array",
                });
              }}
              list={list}
              value={
                details.hasOwnProperty(item.field)
                  ? details[item.field]["value"]
                  : []
              }
            />
          </InputWrapper>
        </Grid>
      );
    } else {
      if (item.filter_input === "" || item.filter_input === null) {
        if (item.is_multiline === false) {
          if (item["data_type"] === "integer") {
            return (
              <Grid item xs={item["width"] === 1 ? 6 : 12}>
                <InputWrapper>
                  <RenderLabel item={item} />
                  <NumberField
                    fontSize="14px"
                    disabled={!isEditable}
                    value={
                      details.hasOwnProperty(item.field)
                        ? details[item.field]["value"]
                        : ""
                    }
                    name={item.field}
                    placeholder={
                      item.hint !== null && item.hint !== ""
                        ? item.hint
                        : `Enter ${item.headerName}`
                    }
                    pattern={getRegex(item)}
                    required={item.required}
                    onInvalid={(e) =>
                      e.target.setCustomValidity(
                        `Please enter a valid ${item.headerName}`
                      )
                    }
                    handleChange={(e) => {
                      e.target.setCustomValidity("");
                      handleChange({
                        fieldName: e.target.name,
                        fieldValue: e.target.value,
                        type: item.data_type,
                      });
                    }}
                    handleBlur={() => {}}
                  />
                </InputWrapper>
              </Grid>
            );
          } else if (item["data_type"] === "date") {
            return (
              <Grid item xs={item["width"] === 1 ? 6 : 12}>
                <InputWrapper>
                  <RenderLabel item={item} />
                  <DateField
                    disabled={!isEditable}
                    value={
                      details.hasOwnProperty(item.field) &&
                      details[item.field]["value"] !== ""
                        ? details[item.field]["value"]
                        : null
                    }
                    onChange={(event) => {
                      handleChange({
                        fieldName: item["field"],
                        fieldValue: event,
                        type: item["data_type"],
                      });
                    }}
                    required={item.required}
                  />
                </InputWrapper>
              </Grid>
            );
          } else if (item["data_type"] === "datetime") {
            return (
              <Grid item xs={item["width"] === 1 ? 6 : 12}>
                <InputWrapper>
                  <RenderLabel item={item} />
                  <DateTimeField
                    disabled={!isEditable}
                    value={
                      details.hasOwnProperty(item.field) &&
                      details[item.field]["value"] !== ""
                        ? details[item.field]["value"]
                        : null
                    }
                    onChange={(event) => {
                      handleChange({
                        fieldName: item["field"],
                        fieldValue: event,
                        type: item["data_type"],
                      });
                    }}
                    required={item.required}
                  />
                </InputWrapper>
              </Grid>
            );
          } else {
            return (
              <Grid item xs={item["width"] === 1 ? 6 : 12}>
                <InputWrapper>
                  <RenderLabel item={item} />
                  <Input
                    type={item.data_type === "email" ? "email" : "text"}
                    disabled={!isEditable}
                    value={
                      details.hasOwnProperty(item.field)
                        ? details[item.field]["value"]
                        : ""
                    }
                    name={item.field}
                    placeholder={
                      item.hint !== null && item.hint !== ""
                        ? item.hint
                        : `Enter ${item.headerName}`
                    }
                    pattern={getRegex(item)}
                    required={item.required}
                    onInvalid={(e) =>
                      e.target.setCustomValidity(
                        `Please enter a valid ${item.headerName}`
                      )
                    }
                    onChange={(e) => {
                      e.target.setCustomValidity("");
                      handleChange({
                        fieldName: e.target.name,
                        fieldValue: e.target.value,
                        type: item.data_type,
                      });
                    }}
                  />
                </InputWrapper>
              </Grid>
            );
          }
        } else {
          return (
            <Grid item xs={item["width"] === 1 ? 6 : 12}>
              <InputWrapper>
                <RenderLabel item={item} />
                <TextArea
                  value={
                    details.hasOwnProperty(item.field)
                      ? details[item.field]["value"]
                      : ""
                  }
                  name={item.field}
                  placeholder={
                    item.hint !== null && item.hint !== ""
                      ? item.hint
                      : `Enter ${item.headerName}`
                  }
                  pattern={getRegex(item)}
                  required={item.required}
                  disabled={!isEditable}
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      `Please enter a valid ${item.headerName}`
                    )
                  }
                  onChange={(e) => {
                    e.target.setCustomValidity("");
                    handleChange({
                      fieldName: e.target.name,
                      fieldValue: e.target.value,
                      type: item.data_type,
                    });
                  }}
                />
              </InputWrapper>
            </Grid>
          );
        }
      } else {
        if (item["map_to_parent_selection"]) {
          let parent_field_info = item.child_of;
          let parent_data = getParentFieldValue(parent_field_info); //getting the data of parent field including value
          let parent_value = parent_data !== null ? parent_data["value"] : null;
          let is_required =
            parent_data !== null
              ? parent_data["field_data"]["required"]
              : false;

          return (
            <Grid item xs={item["width"] === 1 ? 6 : 12}>
              <InputWrapper>
                <RenderLabel item={item} />

                <SearchableCustomSelectField
                  columnMeta={item}
                  handleChange={(value) => {
                    handleChange({
                      fieldName: item["field"],
                      fieldValue: value,
                      type: item["data_type"],
                    });
                  }}
                  isDisabled={!item["editable"]}
                  value={
                    details.hasOwnProperty(item.field) &&
                    details[item.field]["value"] !== null
                      ? details[item.field]["value"]
                      : ""
                  }
                  mapToParentSelection={true}
                  isRequired={is_required}
                  parentValue={parent_value}
                />
              </InputWrapper>
            </Grid>
          );
        } else {
          return (
            <Grid item xs={item["width"] === 1 ? 6 : 12}>
              <InputWrapper>
                <RenderLabel item={item} />

                <SearchableCustomSelectField
                  columnMeta={item}
                  handleChange={(value) => {
                    handleChange({
                      fieldName: item["field"],
                      fieldValue: value,
                      type: item["data_type"],
                    });
                  }}
                  isDisabled={!isEditable}
                  value={
                    details.hasOwnProperty(item.field) &&
                    details[item.field]["value"] !== null
                      ? details[item.field]["value"]
                      : ""
                  }
                  isRequired={item["required"]}
                />
              </InputWrapper>
            </Grid>
          );
        }
      }
    }
  };

  return !isLoading ? (
    !isSubmitSuccess ? (
      <>
        {sortedColumnsList.length > 0 && Object.keys(details).length > 0 ? (
          <Box role="presentation">
            <AppBar
              component={"nav"}
              position="sticky"
              color="inherit"
              elevation={0}
              sx={{
                top: 0,
                bottom: "auto",
                width: 500,
                right: 0,
                bgcolor: "#f9f9fc",
              }}
            >
              <Toolbar sx={{ padding: "24px" }}>
                <Column>
                  <ModelHeader>
                    {getLocalizedText("edit_information")}
                  </ModelHeader>
                  <ModelSubHeader>
                    {getLocalizedText("please_fill_the_below_details")}
                  </ModelSubHeader>
                </Column>
              </Toolbar>
            </AppBar>

            <ModelWrapper
              component={"form"}
              onSubmit={handleSubmit}
              style={{ paddingBottom: "50px" }}
            >
              <>
                <InputContainer>
                  <Grid container spacing={3}>
                    {sortedColumnsList.map((item) => {
                      if (item["parent"] === true) {
                        return (
                          <Grid item xs={12}>
                            <GroupFieldWidget
                              isEdit={true}
                              item={item}
                              renderField={renderField}
                              details={details}
                              allChildList={parentChildData[item["id"]]}
                              handleChange={(value) =>
                                handleChange({
                                  fieldName: item["field"],
                                  fieldValue: value,
                                  type: item["data_type"],
                                })
                              }
                              handleFilledParentChildMap={() => {}}
                            />
                          </Grid>
                        );
                      } else {
                        return renderField({ item: item });
                      }
                    })}
                  </Grid>
                </InputContainer>
              </>
              <AppBar
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{ top: "auto", bottom: 0, width: 500 }}
              >
                <Toolbar style={{ width: 500, position: "relative" }}>
                  {isSubmitFail && (
                    <ErrorAlert
                      onClose={(e) => {
                        e.stopPropagation();
                        setIsSubmitFail(false);
                      }}
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      {errorMessage}
                    </ErrorAlert>
                  )}
                  <ButtonWrapper>
                    <Button
                      onClick={() => {
                        props.setOpen(false);
                      }}
                      variant="outlined"
                      color="primary"
                      style={{ width: "100px", marginRight: "8px" }}
                    >
                      {getLocalizedText("cancel")}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      style={{
                        width: "100px",
                        ...myTheme.Button.btnBlue,
                      }}
                    >
                      {getLocalizedText("submit")}
                    </Button>
                  </ButtonWrapper>
                </Toolbar>
              </AppBar>
            </ModelWrapper>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              width: 500,
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MessageWrapper>
              <Message
                style={{
                  color: "gray",
                  fontSize: "20px",
                  marginBottom: "10px",
                }}
              >
                {getLocalizedText("no_fields_to_edit")}
              </Message>
              <Button
                onClick={() => {
                  props.setOpen(false);
                }}
                variant="outlined"
                style={{
                  ...myTheme.Button.btnLight,
                  width: "fit-content",
                  textTransform: "none",
                }}
              >
                {getLocalizedText("close")}
              </Button>
            </MessageWrapper>
          </Box>
        )}
      </>
    ) : (
      <Box
        sx={{
          display: "flex",
          width: 500,
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <MessageWrapper>
          <Message>{getLocalizedText("info_updated_successfully")}</Message>
          <Button
            onClick={() => {
              props.setOpen(false);
            }}
            variant="outlined"
            style={{
              ...myTheme.Button.btnLight,
              width: "fit-content",
              textTransform: "none",
            }}
          >
            {getLocalizedText("close")}
          </Button>
        </MessageWrapper>
      </Box>
    )
  ) : (
    <Box
      sx={{
        display: "flex",
        width: 500,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default observer(EditLeadComponent);
