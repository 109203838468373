import React from "react";
import { styled } from "@mui/material/styles";
import { Button, Divider, Modal } from "@material-ui/core";
import { getLocalizedText } from "../../../Functions";
import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import CampaignFilterComponent from "../../filter_modal/CampaignFilterComponent";
import { useState } from "react";
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;

const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const Form = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 428px;
`;
const ButtonWrapper = styled(Box)`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const CampaignFilterModal = (props) => {
  const [dateRangeFilterList, setDateRangeFilterList] = useState(null);
  const [numRangeFilterList, setNumRangeFilterList] = useState({});
  const [selectFieldDataList, setSelectFieldDataList] = useState({});
  const [filterList, setFilterList] = useState({});

  const handleClose = () => {
    props.setOpen(false);
  };
  const handleSave = () => {
    props.setFilterList({ ...filterList });
    props.setDateRangeFilterList({ ...dateRangeFilterList });
    props.setNumRangeFilterList({ ...numRangeFilterList });
    props.setSelectFieldDataList({ ...selectFieldDataList });
    props.setOpen(false);
  };

  const init = () => {
    setDateRangeFilterList({ ...props.dateRangeFilterList });
    setFilterList({ ...props.filterList });
    setNumRangeFilterList({ ...props.numRangeFilterList });
    setSelectFieldDataList({ ...props.selectFieldDataList });
  };

  useEffect(() => {
    init();
  }, []);
  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <Container>
              <Form
                component={"form"}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSave();
                }}
              >
                <Header>Select Filters</Header>
                <Divider style={{ margin: "10px 0px" }} />

                <CampaignFilterComponent
                  dateRange={dateRangeFilterList}
                  setDateRange={setDateRangeFilterList}
                  numRange={numRangeFilterList}
                  selectedVal={selectFieldDataList}
                  setSelectedVal={setSelectFieldDataList}
                  filters={filterList}
                  setFilters={setFilterList}
                  fieldList={props.fieldList}
                />

                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      handleClose();
                    }}
                    type="button"
                    variant="outlined"
                    color="primary"
                    style={{
                      textTransform: "none",
                      marginRight: "20px",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100px",
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100px",
                    }}
                  >
                    {getLocalizedText("save")}
                  </Button>
                </ButtonWrapper>
              </Form>
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default CampaignFilterModal;
