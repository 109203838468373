import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { IsoToLocalDate, IsoToLocalTime } from "../../../Functions";
import { Box, IconButton, Typography } from "@mui/material";
import { Grid } from "@material-ui/core";
import { useEffect } from "react";
import rootStore from "../../../stores/RootStore";
import { Edit } from "@material-ui/icons";
const Label = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  margin-bottom: 8px;
  color: #979799;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 276px);`};
  display: flex;
  width: 100%;
  column-gap: 20px;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const SectionWrapper = styled(Box)`
  padding: 16px;
  background: #f9f9fc 0% 0% no-repeat padding-box;
  flex-grow: 1;
  flex-direction: column;
  position: relative;
`;
const SectionHeader = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  margin-bottom: 20px;
  color: #979797;
`;
const Message = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #979797;
`;

const CustomDetailWidget = ({
  details,
  groups,
  groupsToColumns,
  setColumnIds,
  setOpenEditPanel,
}) => {
  const [groupsDetails, setGroupsDetails] = useState({});

  const columnsList = rootStore.customerStore.columnsList;

  const getColumnValue = (field, data_type) => {
    let data = details;

    if (data.hasOwnProperty(field)) {
      let value = data[field];
      if (value !== null && value !== "") {
        if (data_type === "date") {
          value = IsoToLocalDate(value);
        } else if (data_type === "datetime") {
          value = IsoToLocalTime(value);
        }
      }
      return value !== null ? value : "";
    } else {
      return "";
    }
  };

  useEffect(() => {
    setupGroupDetails();
  }, [details]);

  //***********************sections division based on group name function */
  const setupGroupDetails = () => {
    let tempGroupsDetails = {};
    groups.forEach((group) => {
      let groupDetails = [];
      let columnIds = groupsToColumns[group];
      columnIds.forEach((id) => {
        let index = columnsList.findIndex((element) => element["id"] === id);
        if (index !== -1) {
          let item = {
            display_name: columnsList[index]["display_name"],
            value: getColumnValue(
              columnsList[index]["column_name"],
              columnsList[index]["data_type"]
            ),
            column_name: columnsList[index]["column_name"],
          };
          groupDetails.push(item);
        }
      });
      groupDetails.sort((a, b) => {
        let A = a.display_name.toLowerCase();
        let B = b.display_name.toLowerCase();
        if (A < B) {
          return -1;
        }
        if (A > B) {
          return 1;
        }
        return 0;
      });
      tempGroupsDetails[group] = groupDetails;
    });
    setGroupsDetails(tempGroupsDetails);
  };
  //*********************************END******************************** */

  return (
    <>
      <Container>
        {Object.keys(groupsDetails).length > 0 &&
          Object.keys(groupsDetails).map((key) => {
            let details = groupsDetails[key];
            if (
              key !== "Primary Information" &&
              key !== "Secondary Information"
            )
              return details.length > 0 ? (
                <SectionWrapper>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      marginBottom: "20px",
                    }}
                  >
                    <SectionHeader style={{ marginBottom: "0px" }}>
                      {key}
                    </SectionHeader>

                    {rootStore.customerStore.isGroupEditable(key) && (
                      <IconButton
                        style={{ padding: "0px" }}
                        color="primary"
                        onClick={() => {
                          setColumnIds(groupsToColumns[key]);
                          setOpenEditPanel(true);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    )}
                  </Box>
                  <Grid container spacing={3}>
                    {details.map((data, index) => {
                      return (
                        <Grid item xs={12} md={4} lg={3} xl={2} key={index}>
                          <InputWrapper>
                            <Label>{data["display_name"]}</Label>

                            <Detail>
                              {data["value"] !== "" ? data["value"] : "-"}
                            </Detail>
                          </InputWrapper>
                        </Grid>
                      );
                    })}
                  </Grid>
                </SectionWrapper>
              ) : (
                <SectionWrapper
                  style={{
                    flexDirection: "column",
                    flexWrap: "nowrap",
                  }}
                >
                  <SectionHeader>{key}</SectionHeader>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Message>No data.</Message>
                  </Box>
                </SectionWrapper>
              );
          })}
      </Container>
    </>
  );
};

export default CustomDetailWidget;
