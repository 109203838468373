import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import { Box, CircularProgress, Drawer } from "@material-ui/core";
import { Add, ChevronRight } from "@material-ui/icons";
import { taskTypePageColumnHeaders } from "../../Db";
import rootStore from "../../stores/RootStore";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { styled } from "@mui/material/styles";
import { Button, Typography } from "@mui/material";
import CreateTaskTypeComponent from "../../components/tasks_page_components/CreateTaskTypeComponent";
import { getAdminTaskTypeListApi } from "../../Api";
import NoAccessPermissionPage from "../no_access_page/NoAccessPermissionPage";
const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 84px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;

const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const Row = styled(Box)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  margin: 16px 0px;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 214px);`};
  background-color: white;
  padding: 20px;
  position: relative;
`;
const TaskTypesPage = () => {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [openCreateTaskTypePanel, setOpenCreateTaskTypePanel] = useState(false);
  const [tableData, setTableData] = useState([]);

  const getTaskTypeList = async () => {
    let response = await getAdminTaskTypeListApi();
    setTableData(response);
  };

  const init = async () => {
    setLoading(true);
    if (rootStore.subscriptionState.isFeatureAvailableWithAddOns("Tasks")) {
      await getTaskTypeList();
    }

    setLoading(false);
  };
  const refetch = async () => {
    setLoading(true);
    await getTaskTypeList();
    setLoading(false);
    setRefresh(false);
  };
  useEffect(() => {
    if (refresh === true) {
      refetch();
    }
  }, [refresh]);

  useEffect(() => {
    init();
    rootStore.authStore.updateLeadDetailStatus();
  }, []);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  return rootStore.subscriptionState.isFeatureAvailableWithAddOns("Tasks") ? (
    <>
      <ParentContainer>
        {!loading ? (
          <Container>
            <Row>
              <TopWrapper>
                <Header>Admin</Header>
                <ChevronRight style={{ width: "16px", height: "16px" }} />
                <Header>Task Types</Header>
              </TopWrapper>
              {/* <Button
                style={{
                  textTransform: "none",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
                variant="contained"
                color="primary"
                onClick={() => {
                  setOpenCreateTaskTypePanel(true);
                }}
              >
                <Add />
                Create Task Type
              </Button> */}
            </Row>
            <TableWrapper>
              <div className="ag-theme-alpine" style={{ width: "100%" }}>
                <AgGridReact
                  domLayout="autoHeight"
                  gridOptions={gridOptions}
                  rowData={tableData}
                  defaultColDef={defaultColDef}
                  columnDefs={taskTypePageColumnHeaders}
                  animateRows={true}
                  rowSelection="multiple"
                  suppressCellFocus
                  pagination={false}
                  className="paginated-ag-grid"
                  onRowClicked={(row) => {
                    if (row.event.defaultPrevented) {
                      return null;
                    }
                  }}
                />
              </div>
            </TableWrapper>
          </Container>
        ) : (
          <Container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Container>
        )}
      </ParentContainer>
      <Footer />
      {openCreateTaskTypePanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openCreateTaskTypePanel}
          onClose={() => {
            setOpenCreateTaskTypePanel(false);
          }}
        >
          <CreateTaskTypeComponent
            setOpen={setOpenCreateTaskTypePanel}
            setRefresh={setRefresh}
          />
        </Drawer>
      )}
    </>
  ) : (
    <NoAccessPermissionPage />
  );
};

export default observer(TaskTypesPage);
