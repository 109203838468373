import React from "react";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { Box, Grid } from "@material-ui/core";
import {
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import { nextFollowupRelativeDates } from "../../Db";
import SearchableCustomSelectField from "../select_dropdown/SearchableCustomSelectField";
import DateField from "../custom_field_components/DateField";
import DateTimeField from "../custom_field_components/DateTimeField";
import NextFollowUpDateTimeField from "./NextFollowUpDateTimeField";

const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const Input = styled("input")`
  width: 100%;
  height: 38px;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #e0deca;
  font: normal normal 600 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal normal 12px Open Sans;
    color: #b5b5b5;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;

const TextArea = styled("textarea")`
  width: 100%;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #e0deca;
  color: #4d4e4f;
  border-radius: 4px;
  resize: none;
  font: normal normal 600 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal normal 12px Open Sans;
    color: #b5b5b5;
  }
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const UpdateFieldsWidget = (props) => {
  const [fieldList, setFieldList] = useState([]);
  const handleChange = ({ fieldName, fieldValue, dataType }) => {
    props.setValue({
      ...props.value,
      [fieldName]: { value: fieldValue, type: dataType },
    });
  };

  const getRegex = (item) => {
    if (item.regex === null || item.regex === "") {
      return null;
    } else {
      return item.regex;
    }
  };

  function sortColumnFields() {
    let allFields = [...props.fieldList];

    for (const item of allFields) {
      if (item.data_type === "datetime" || item.is_multiline) {
        item.width = 2;
        item.modifyWidth = false;
      } else {
        item.width = 1;
        item.modifyWidth = true;
      }
    }

    for (let i = 0; i < allFields.length; i++) {
      let currentItem = allFields[i];

      if (currentItem.modifyWidth) {
        if (i === allFields.length - 1) {
          currentItem.width = 2;
          currentItem.modifyWidth = false;
        } else {
          let nextItem = allFields[i + 1];

          if (nextItem.width === 2) {
            currentItem.width = 2;
            currentItem.modifyWidth = false;
          } else if (nextItem.width === 1) {
            currentItem.width = 1;
            currentItem.modifyWidth = false;
            nextItem.modifyWidth = false;
          }
        }
      }
    }
    setFieldList(allFields);
  }

  useEffect(() => {
    sortColumnFields();
  }, [props.fieldList]);
  const getTimeOnNextFollowupDateChange = (dateString) => {
    // Parse the ISO date string to a moment object
    const dateMoment = moment(dateString);
    const todayDate = moment().startOf("day"); // Start of today for comparison
    const tenAM = moment().set({ hour: 10, minute: 0, second: 0 }); // Default time for non-today

    // Check if the provided date is today
    if (dateMoment.isSame(todayDate, "day")) {
      // Return a moment object with time set to 1 minute from now
      return moment().add(1, "minutes");
    } else {
      // Return a moment object with time set to 10:00 AM
      return tenAM;
    }
  };

  const getClosestHalfAnHour = () => {
    let now = moment();
    let minutes = now.minutes();

    if (minutes < 30) {
      now.minutes(30);
    } else {
      now.add(1, "hour").minutes(0);
    }
    now.seconds(0);

    return now;
  };

  const getDateFromRelativeDateString = (relativeDateStr) => {
    const today = moment();

    switch (relativeDateStr) {
      case "Today":
        return today;
      case "Tomorrow":
        return today.add(1, "days");
      case "In 3 Days":
        return today.add(3, "days");
      case "In 5 Days":
        return today.add(5, "days");
      case "Next Week":
        return today.add(1, "weeks");
      case "Next Month":
        return today.add(1, "months");

      default:
        return today;
    }
  };

  const getDateAndTimeStringOnRelativeDateChange = (relativeDateStr) => {
    if (relativeDateStr === "Custom") {
      return null;
    }
    try {
      let date = getDateFromRelativeDateString(relativeDateStr);
      let time = getClosestHalfAnHour();

      // Set the hours and minutes of the date object using the closest half-hour time
      date.hours(time.hours()).minutes(time.minutes()).seconds(0);

      // Return the ISO string representation of the updated date-time
      return date;
    } catch (error) {
      return null;
    }
  };

  const getFieldValue = ({ columnMeta }) => {
    let data = props.leadData;
    let key = columnMeta["field"];

    if (columnMeta["is_custom_param"]) {
      key = columnMeta["field"].replace("custom_params_", "");
      let customData = props.leadData.hasOwnProperty("custom_params")
        ? JSON.parse(props.leadData["custom_params"])
        : {};
      data = customData;
    }

    if (data.hasOwnProperty(key)) {
      let value = data[key];
      if (
        columnMeta["data_type"] === "date" ||
        columnMeta["data_type"] === "datetime"
      ) {
        value =
          data[key] !== null && data[key] !== "" ? moment(data[key]) : null;
        return value;
      }
      if (key === "lead_owner" || key === "team" || key === "lead_creator") {
        return value !== null ? value["name"] : "";
      }

      return value !== null ? value : "";
    } else {
      return columnMeta["data_type"] === "date" ||
        columnMeta["data_type"] === "datetime"
        ? null
        : "";
    }
  };

  return (
    <Grid container spacing={2} item>
      {fieldList.map((item) => {
        let value = getFieldValue({ columnMeta: item });
        if (item.filter_input === "" || item.filter_input === null) {
          if (item.is_multiline === true) {
            let placeholderText = `Enter ${item.headerName}`;
            let prefilledHintValue = "";

            if (value !== "") {
              if (item["_overwrite"] === true) {
                placeholderText = value;
              }
              if (item["_append"] === true && item["data_type"] === "string") {
                prefilledHintValue = value;
              }
            }
            return (
              <Grid item xs={12}>
                <InputWrapper>
                  <Label>
                    {item.headerName}
                    {item.required && <span>*</span>}
                  </Label>
                  <TextArea
                    rows={5}
                    type={"text"}
                    value={
                      props.value.hasOwnProperty(item.field)
                        ? props.value[item.field]["value"]
                        : prefilledHintValue
                    }
                    pattern={getRegex(item)}
                    required={item.required}
                    onInvalid={(e) =>
                      e.target.setCustomValidity(
                        `Please enter a valid ${item.headerName}`
                      )
                    }
                    name={item.field}
                    placeholder={placeholderText}
                    onChange={(e) => {
                      e.target.setCustomValidity("");
                      handleChange({
                        fieldName: item.field,
                        fieldValue: e.target.value,
                        dataType: item.data_type,
                      });
                    }}
                    style={{
                      color: props.value.hasOwnProperty(item.field)
                        ? "#4d4e4f"
                        : "#D3D3D3",
                    }}
                  />
                </InputWrapper>
              </Grid>
            );
          } else {
            if (item.data_type === "datetime") {
              if (item.field === "next_follow_up_on") {
                return (
                  <Grid container item xs={12}>
                    <Box
                      component={"fieldset"}
                      sx={{
                        border: "1px solid #cccccc",
                        padding: "12px",
                        width: "100%",
                      }}
                    >
                      <legend
                        style={{
                          width: "auto",
                          marginBottom: "0px",
                          fontSize: "12px",
                        }}
                      >
                        {item.headerName}
                        {item.required && <span>*</span>}
                      </legend>
                      <Grid container item spacing={2}>
                        <Grid item xs={12}>
                          <InputWrapper>
                            <FormControl sx={{ width: "100%" }}>
                              <Select
                                sx={{
                                  "&:focus": {
                                    outline: "none",
                                  },
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      border: "1px solid #bfbfbf",
                                    },
                                }}
                                displayEmpty
                                required={item.required}
                                onChange={(event) => {
                                  props.setNextFollowupRelativeDate(
                                    event.target.value
                                  );
                                  let tempDateAndTime =
                                    getDateAndTimeStringOnRelativeDateChange(
                                      event.target.value
                                    );
                                  props.setValue({
                                    ...props.value,
                                    [item.field]: {
                                      value: tempDateAndTime,
                                      type: item.data_type,
                                    },
                                  });
                                }}
                                name="next_followup_relative_date"
                                disableUnderline
                                variant="outlined"
                                value={props.nextFollowupRelativeDate}
                                style={{
                                  width: "100%",
                                  height: "38px",
                                  fontSize: "12px",
                                  borderRadius: "0px",
                                }}
                              >
                                {nextFollowupRelativeDates.map(
                                  (item, index) => {
                                    return (
                                      <MenuItem
                                        value={item}
                                        key={index}
                                        style={{ fontSize: "12px" }}
                                      >
                                        {item}
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </Select>
                            </FormControl>
                          </InputWrapper>
                        </Grid>
                        <Grid item xs={12}>
                          <InputWrapper>
                            <NextFollowUpDateTimeField
                              item={item}
                              disabled={props.nextFollowupRelativeDate === ""}
                              value={
                                props.value.hasOwnProperty(item.field)
                                  ? props.value[item.field]["value"]
                                  : null
                              }
                              inputData={props.value}
                              handleChange={handleChange}
                              getTimeOnNextFollowupDateChange={getTimeOnNextFollowupDateChange}
                              required={item.required}
                            />
                          </InputWrapper>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                );
              } else {
                return (
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>
                        {item.headerName}
                        {item.required && <span>*</span>}
                      </Label>
                      <DateTimeField
                        fieldValueColor={
                          props.value.hasOwnProperty(item.field)
                            ? "#4d4e4f"
                            : "#D3D3D3"
                        }
                        disabled={false}
                        value={
                          props.value.hasOwnProperty(item.field)
                            ? props.value[item.field]["value"]
                            : value
                        }
                        onChange={(event) => {
                          handleChange({
                            fieldName: item["field"],
                            fieldValue: event,
                            dataType: item["data_type"],
                          });
                        }}
                        required={item.required}
                      />
                    </InputWrapper>
                  </Grid>
                );
              }
            } else if (item.data_type === "date") {
              return (
                <Grid item xs={item["width"] === 1 ? 6 : 12}>
                  <InputWrapper>
                    <Label>
                      {item.headerName}
                      {item.required && <span>*</span>}
                    </Label>
                    <DateField
                      fieldValueColor={
                        props.value.hasOwnProperty(item.field)
                          ? "#4d4e4f"
                          : "#D3D3D3"
                      }
                      disabled={false}
                      value={
                        props.value.hasOwnProperty(item.field)
                          ? props.value[item.field]["value"]
                          : value
                      }
                      onChange={(event) => {
                        handleChange({
                          fieldName: item["field"],
                          fieldValue: event,
                          dataType: item["data_type"],
                        });
                      }}
                      required={item.required}
                    />
                  </InputWrapper>
                </Grid>
              );
            } else {
              let placeholderText = `Enter ${item.headerName}`;

              if (value !== "") {
                if (item["_overwrite"] === true) {
                  placeholderText = value;
                }
              }
              return (
                <Grid item xs={item["width"] === 1 ? 6 : 12}>
                  <InputWrapper>
                    <Label>
                      {item.headerName}
                      {item.required && <span>*</span>}
                    </Label>
                    <Input
                      type={
                        item.data_type === "email"
                          ? "email"
                          : item.data_type === "datetime" ||
                            item.data_type === "date"
                          ? "date"
                          : item.data_type === "integer"
                          ? "number"
                          : "text"
                      }
                      value={
                        props.value.hasOwnProperty(item.field)
                          ? props.value[item.field]["value"]
                          : ""
                      }
                      pattern={getRegex(item)}
                      required={item.required}
                      onInvalid={(e) =>
                        e.target.setCustomValidity(
                          `Please enter a valid ${item.headerName}`
                        )
                      }
                      name={item.field}
                      placeholder={placeholderText}
                      onChange={(e) => {
                        e.target.setCustomValidity("");
                        handleChange({
                          fieldName: item.field,
                          fieldValue: e.target.value,
                          dataType: item.data_type,
                        });
                      }}
                      style={{
                        color: props.value.hasOwnProperty(item.field)
                          ? "#4d4e4f"
                          : "#D3D3D3",
                      }}
                    />
                  </InputWrapper>
                </Grid>
              );
            }
          }
        } else {
          return (
            <Grid item xs={item["width"] === 1 ? 6 : 12}>
              <InputWrapper>
                <Label>
                  {item.headerName}
                  {item.required && <span>*</span>}
                </Label>
                <SearchableCustomSelectField
                  columnMeta={item}
                  handleChange={(value) => {
                    handleChange({
                      fieldName: item["field"],
                      fieldValue: value,
                      type: item["data_type"],
                    });
                  }}
                  scope="update_fields"
                  isValueChanged={props.value.hasOwnProperty(item.field)}
                  isDisabled={false}
                  value={
                    props.value.hasOwnProperty(item.field) &&
                    props.value[item.field]["value"] !== null
                      ? props.value[item.field]["value"]
                      : value
                  }
                  isRequired={item["required"]}
                />
              </InputWrapper>
            </Grid>
          );
        }
      })}
    </Grid>
  );
};

export default observer(UpdateFieldsWidget);
