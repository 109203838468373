import React, { useEffect, useState } from "react";
import Select from "react-select";
import { reportFilterRelativeDates } from "../../../Db";
import { Box, styled } from "@mui/material";
import { DatePicker } from "antd";
import moment from "moment";
const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for options
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for the entire control container, including the search input
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "no options" message
  }),
  loadingMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "loading" message
  }),
};
const DateAndDateTimeFieldComponent = ({ handleChange, details }) => {
  const { RangePicker } = DatePicker;
  const [selectedDateFilter, setSelectedDateFilter] = useState(null);
  useEffect(() => {
    if (details["rolling_date_string"] !== null) {
      setSelectedDateFilter(details["rolling_date_string"]);
    }
  }, []);
  return (
    <Wrapper>
      <Select
        options={reportFilterRelativeDates}
        value={selectedDateFilter}
        onChange={(selectedOption) => {
          handleChange(selectedOption, selectedOption, "rolling_date");
          setSelectedDateFilter(selectedOption);
        }}
        closeMenuOnSelect={true}
        styles={customStyles}
        isClearable={false}
      />
      <RangePicker
        disabled={
          selectedDateFilter === null ||
          selectedDateFilter["value"] !== "Custom"
        }
        getPopupContainer={(triggerNode) => {
          return triggerNode.parentNode;
        }}
        placeholder={["Start Date", "End Date"]}
        size="small"
        style={{
          height: "30px",
          width: "100%",
        }}
        value={details["value"]}
        disabledDate={(current) => current && current > moment().endOf("day")}
        onChange={(value) => {
          handleChange(value, selectedDateFilter, "custom_date");
        }}
      />
    </Wrapper>
  );
};

export default DateAndDateTimeFieldComponent;
