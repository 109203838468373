import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  Toolbar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { getLocalizedText, validateUnmaskColumns } from "../../Functions";
import { Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {
  createJobApi,
  getExportJobDownloadFileApi,
  getJobStatusApi,
  startSpecificJobApi,
} from "../../Api";
import { MultiSelect } from "react-multi-select-component";
import rootStore from "../../stores/RootStore";
import { LoadingButton } from "@mui/lab";
const dialogWidth = 420;
const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px 0px 8px 8px",
      }}
    />
  );
});

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Message = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #4d4e4f;
  margin: 0px;
  text-align: center;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const MINUTE_MS = 10000;
const ExportMultiLeadDialog = ({ setOpen, open, leadIDs }) => {
  const columnsList = [...rootStore.userStore.AllColumnsList];
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [selectColumnList, setSelectColumnList] = useState([]);
  const [selectedColumnList, setSelectedColumnList] = useState([]);
  const [jobData, setJobData] = useState({});
  const [jobStatus, setJobStatus] = useState("");
  const [unmaskColumns, setUnmaskColumns] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    setIsSubmitting(true);
    e.preventDefault();
    let tempList = selectedColumnList.map((column) => column["value"]);
    let payload = {
      job_type: "export_leads_listing",
      title: "Exporting Leads from Listing",
    };
    payload["input"] = {
      columns: tempList,
      all_leads: false,
      lead_ids: leadIDs,
      unmask_columns: unmaskColumns,
    };
    try {
      let response = await createJobApi({
        id: rootStore.authStore.projectId,
        data: payload,
      });
      let start_job_response = await startSpecificJobApi({
        id: rootStore.authStore.projectId,
        jid: response.data["job_id"],
      });
      setJobData(start_job_response.data);
      setJobStatus(start_job_response.data["status"]);
      setIsSubmitSuccess(true);
    } catch (error) {
      setJobData({});
      setJobStatus("");
      setErrorMessage(getLocalizedText("something_went_wrong"));
      setIsSubmitFail(true);
    }
    setIsSubmitting(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const setupColumnsListForMultiSelect = () => {
    let tempList = [...columnsList].filter(
      (column) =>
        column["field"] !== "lead_created_at" &&
        column["field"] !== "lead_creator" &&
        rootStore.userStore.isColumnVisible(column)
    );
    tempList.sort((a, b) =>
      a["headerName"].toLowerCase().localeCompare(b["headerName"].toLowerCase())
    );
    let tempMultiSelectColumnList = [];
    tempList.map((column) => {
      if (
        column.field !== "lead_created_at" &&
        column.field !== "lead_creator"
      ) {
        if (column.data_type === "datetime") {
          if (column.system === true) {
            let obj1 = {
              label: column.headerName,
              value: column.field,
            };
            let obj2 = {
              label: column.headerName + "::DateTime",
              value: column.field + "::datetime",
            };
            tempMultiSelectColumnList.push(obj1);
            tempMultiSelectColumnList.push(obj2);
          } else {
            let newObj = {
              label: column.headerName,
              value: column.field,
            };
            tempMultiSelectColumnList.push(newObj);
          }
        } else {
          let newObj = {
            label: column.headerName,
            value: column.field,
          };
          tempMultiSelectColumnList.push(newObj);
        }
      }
    });
    setSelectColumnList(tempMultiSelectColumnList);
  };

  useEffect(() => {
    setupColumnsListForMultiSelect();
  }, []);
  const getJobStatus = async () => {
    try {
      let response = await getJobStatusApi({
        id: rootStore.authStore.projectId,
        jid: jobData["id"],
      });
      setJobData(response.data);
      setJobStatus(response.data["status"]);
    } catch (error) {
      setJobData({});
      setJobStatus("");
    }
  };
  useEffect(() => {
    console.log("getting called::");
    const interval = setInterval(() => {
      if (jobStatus === "queued" || jobStatus === "processing") {
        getJobStatus();
      }
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, [jobStatus]);

  const getJobStatusMessage = () => {
    if (jobStatus === "queued" || jobStatus === "processing") {
      return "Leads are being exported. Kindly wait and do not refresh the page.";
    } else if (jobStatus === "completed") {
      return "Export Complete. Click here to download your file.";
    } else {
      return "Something went wrong! please contact your administrator";
    }
  };

  const handleDownload = async () => {
    try {
      let response = await getExportJobDownloadFileApi({
        id: rootStore.authStore.projectId,
        jid: jobData["id"],
      });
      const url = response.data.artifact;
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelect = (selectedOptions) => {
    // Group the options by their base name (before "::")
    const groupedOptions = selectedOptions.reduce((acc, option) => {
      const baseName = option.value.split("::")[0];
      if (!acc[baseName]) {
        acc[baseName] = [];
      }
      acc[baseName].push(option);
      return acc;
    }, {});

    // Filter out the options with duplicate base names and keep only the last selected option
    const filteredOptions = Object.values(groupedOptions).map(
      (group) => group[group.length - 1]
    );

    //
    setSelectedColumnList(filteredOptions);
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!isSubmitSuccess ? (
          <ModelContainer>
            <Box component={"form"} onSubmit={handleSubmit}>
              <AppBar
                component={"nav"}
                elevation={0}
                position="sticky"
                color="inherit"
                sx={{
                  top: 0,
                  bottom: "auto",
                  width: "100%",
                  right: 0,
                  borderRadius: "8px 8px 0px 0px",
                }}
              >
                <Toolbar
                  style={{
                    padding: "16px",
                  }}
                >
                  <HeaderWrapper>
                    <Header>Export Leads</Header>
                    <SubHeader>
                      {" "}
                      {getLocalizedText("fill_in_the_below_details")}
                    </SubHeader>
                  </HeaderWrapper>
                </Toolbar>
              </AppBar>
              <Divider style={{ margin: "0px 16px" }} />
              <Container>
                <Box
                  style={{
                    height: "100%",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <Label>Select Column</Label>
                        <Box
                          sx={{
                            "&": {
                              width: "100%",
                              fontSize: "12px",
                              border: "none",
                              alignItems: "center",
                              color: "black",
                              zIndex: "9999",
                            },
                            "& .dropdown-heading": {
                              height: "30px",
                            },
                            "& .options": {
                              maxHeight: "150px",
                            },
                          }}
                        >
                          <MultiSelect
                            options={selectColumnList}
                            value={selectedColumnList}
                            onChange={handleSelect}
                          />
                        </Box>
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <Label
                          style={{
                            marginBottom: "0px",
                          }}
                        >
                          {getLocalizedText("unmask_columns")}
                        </Label>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            disabled={!validateUnmaskColumns()}
                            value={true}
                            control={<Radio size="small" />}
                            label={
                              <Label style={{ fontSize: "12px" }}>
                                {getLocalizedText("yes")}
                              </Label>
                            }
                            checked={unmaskColumns}
                            onChange={() => {
                              setUnmaskColumns(true);
                            }}
                          />
                          <FormControlLabel
                            disabled={!validateUnmaskColumns()}
                            value={false}
                            checked={!unmaskColumns}
                            onChange={() => {
                              setUnmaskColumns(false);
                            }}
                            control={<Radio size="small" />}
                            label={
                              <Label style={{ fontSize: "12px" }}>
                                {getLocalizedText("no")}
                              </Label>
                            }
                          />
                        </RadioGroup>
                      </InputWrapper>
                    </Grid>
                  </Grid>
                </Box>
              </Container>
              <AppBar
                position="static"
                color="inherit"
                elevation={0}
                sx={{
                  top: "auto",
                  bottom: 0,
                  width: "100%",
                  borderRadius: "0px 0px 8px 8px",
                }}
              >
                <Toolbar
                  style={{
                    padding: "16px",
                    width: dialogWidth,
                    position: "relative",
                  }}
                >
                  {isSubmitFail && (
                    <ErrorAlert
                      onClose={(e) => {
                        e.stopPropagation();
                        setIsSubmitFail(false);
                      }}
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      {errorMessage}
                    </ErrorAlert>
                  )}
                  <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item>
                      <Button
                        onClick={() => {
                          setOpen(false);
                        }}
                        variant="contained"
                        color="inherit"
                        style={{
                          fontSize: "12px",
                          textTransform: "none",
                          fontWeight: "bold",
                        }}
                      >
                        {getLocalizedText("cancel")}
                      </Button>
                    </Grid>
                    <Grid item>
                      {isSubmitting ? (
                        <LoadingButton
                          loading
                          variant="contained"
                          color="primary"
                          style={{
                            textTransform: "none",
                            width: "75px",
                          }}
                        >
                          <span> {getLocalizedText("submit")}</span>
                        </LoadingButton>
                      ) : (
                        <Button
                          disabled={selectedColumnList.length === 0}
                          color="primary"
                          type="submit"
                          variant="contained"
                          style={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            width: "fit-content",
                            textTransform: "none",
                          }}
                        >
                          {getLocalizedText("submit")}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
            </Box>
          </ModelContainer>
        ) : (
          <ModelContainer
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AppBar
              component={"nav"}
              elevation={0}
              position="sticky"
              color="inherit"
              sx={{
                top: 0,
                bottom: "auto",
                width: "100%",
                right: 0,
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                }}
              >
                <HeaderWrapper>
                  <Header>Export Leads</Header>
                </HeaderWrapper>
              </Toolbar>
            </AppBar>
            <Divider style={{ margin: "0px 16px" }} />
            <Container
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "4px",
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {(jobStatus === "queued" || jobStatus === "processing") && (
                    <CircularProgress
                      style={{ width: "18px", height: "18px" }}
                    />
                  )}
                  <Message>{getJobStatusMessage()}</Message>
                </Box>
                {jobStatus === "completed" && (
                  <Button
                    onClick={handleDownload}
                    type="button"
                    variant="text"
                    color="primary"
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "fit-content",
                      textTransform: "none",
                    }}
                  >
                    Download
                  </Button>
                )}
              </Box>
            </Container>
            <AppBar
              position="static"
              color="inherit"
              elevation={0}
              sx={{
                top: "auto",
                bottom: 0,
                width: "100%",
                borderRadius: "0px 0px 8px 8px",
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                  width: dialogWidth,
                }}
              >
                {jobStatus !== "processing" && jobStatus !== "queued" && (
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      onClick={handleClose}
                      type="button"
                      variant="contained"
                      color="inherit"
                      style={{
                        textTransform: "none",
                        fontSize: "12px",
                        fontWeight: "bold",
                        width: "100px",
                      }}
                    >
                      {getLocalizedText("close")}
                    </Button>
                  </Box>
                )}
              </Toolbar>
            </AppBar>
          </ModelContainer>
        )}
      </Modal>
    </>
  );
};

export default ExportMultiLeadDialog;
