import { Box, Button, Modal } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import { getLocalizedText } from "../../Functions";
import { Typography } from "@mui/material";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  width: 420px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 16px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;

const ButtonWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  margin-top: 20px;
`;
const Detail = styled(Typography)`
  font: normal normal 500 14px Open Sans;
  color: #4d4e4f;
`;
const TextWrapper = styled(Box)`
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
`;

const ViewTextDialog = ({ open, setOpen, textData }) => {
  const multilineTextGetter = (raw_string) => {
    return raw_string.replace(/\r\n|\n/g, "\n");
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <Container>
              <TextWrapper>
                <Detail
                  component="div"
                  style={{
                    whiteSpace: "pre-line",
                  }}
                >
                  {multilineTextGetter(textData)}
                </Detail>
              </TextWrapper>

              <ButtonWrapper>
                <Button
                  onClick={() => {
                    setOpen(false);
                  }}
                  variant="contained"
                  color="inherit"
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    textTransform: "none",
                  }}
                >
                  {getLocalizedText("close")}
                </Button>
              </ButtonWrapper>
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default ViewTextDialog;
