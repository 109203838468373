import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import { styled } from "@mui/material/styles";
import {
  AppBar,
  Button,
  Card,
  IconButton,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { AddBoxOutlined, Delete } from "@material-ui/icons";
import { useState } from "react";
import { welcomeScreenConfig, groupConfig } from "../../Config";
import QuillFormPreview from "../../components/form_builder_components/QuillFormPreview";
import { useNavigate } from "react-router-dom";
import FormFieldOptions from "../../components/form_builder_components/FormFieldOptions";
import { useRendererStoreActions } from "@quillforms/renderer-core";
import { useEffect } from "react";
import AddBlockDialog from "../../components/form_builder_components/AddBlockDialog";
import { v4 as uuid } from "uuid";
import CreateWebFormAlertDialog from "../../components/alert_dialogue/CreateWebFormAlertDialog";
import SubmitFormWithNameDialog from "../../components/form_builder_components/SubmitFormWithNameDialog";
import { createWebFormApi } from "../../Api";
import CustomErrorMessageDialog from "../../components/alert_dialogue/CustomErrorMessageDialog";

const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;
const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  row-gap: 10px;
`;
const Message = styled(Typography)`
  font: normal normal normal 20px Open Sans;
  color: #4d4e4f;
  margin: 0px;
  text-align: center;
`;

const Row = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const drawerWidth = 300;
const ITEM_HEIGHT = 48;

const displayNameList = {
  ["welcomestep"]: "Welcome Step",
  ["formstep"]: "Form Step",
  ["endstep"]: "End Step",
  ["short-text"]: "Text",
  ["long-text"]: "Long Text",
  ["number"]: "Number",
  ["email"]: "Email",
};

const FormBuilderPage = () => {
  const navigate = useNavigate();
  const [blocksList, setBlocksList] = useState([]);
  const [selectedBlockData, setSelectedBlockData] = useState({});
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const Open = Boolean(anchorEl);
  const { goToBlock } = useRendererStoreActions();
  const [goToBlockID, setgoToBlockID] = useState("");
  const [innerBlockID, setInnerBlockID] = useState("");
  const [openAddBlockDialog, setOpenAddBlockDialog] = useState(false);
  const [selectedGroupBlockID, setSelectedGroupBlockID] = useState("");
  const [openSettingPanel, setOpenSettingPanel] = useState(false);
  const [openWebFormAlertDialog, setOpenWebFormAlertDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openErrorDialog, setOpenErrorDialog] = useState(false);

  const [createWebFormAlertMessage, setCreateWebFormAlertMessage] =
    useState("");
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [openNameDialog, setOpenNameDialog] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const sortBocksList = (blocks) => {
    let sortedBlocks = [];
    const welcomestepIdx = blocks.findIndex(
      (block) => block.id === "welcomestep"
    );
    const endstepIdx = blocks.findIndex((block) => block.id === "endstep");
    const formSteps = blocks.filter(
      (block) => block.id !== "welcomestep" && block.id !== "endstep"
    );

    if (welcomestepIdx !== -1) sortedBlocks.push(blocks[welcomestepIdx]);
    sortedBlocks = sortedBlocks.concat(formSteps);
    if (endstepIdx !== -1) sortedBlocks.push(blocks[endstepIdx]);

    return sortedBlocks;
  };

  const handleDelete = (key) => {
    let list = [...blocksList];
    list = list.filter((item) => item["id"] !== key);
    list = sortBocksList(list);
    if (list.length === 0) {
      setSelectedBlockData({});
      setOpenSettingPanel(false);
    }
    setBlocksList(list);
  };

  const isHavingEndStep = () => {
    let index = blocksList.findIndex((block) => block["id"] === "endstep");

    return index !== -1;
  };
  const isHavingAtleastOneFormStep = () => {
    let list = blocksList.filter(
      (block) => block["id"] !== "welcomestep" && block["id"] !== "endstep"
    );
    return list.length > 0;
  };
  const createWebForm = async (formName) => {
    setOpenNameDialog(false);
    let payload = { name: formName, schema: blocksList };
    let response = await createWebFormApi({ payload: payload });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setOpenErrorDialog(true);
    } else {
      setOpenErrorDialog(false);
      setSelectedBlockData({});
      setOpenSettingPanel(false);
      setBlocksList([]);
      setIsSubmitSuccess(true);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (blocksList.length === 0) {
      setCreateWebFormAlertMessage("Please add steps to proceed!");
      setOpenWebFormAlertDialog(true);
    } else {
      if (isHavingAtleastOneFormStep()) {
        if (!isHavingEndStep()) {
          setCreateWebFormAlertMessage(
            "End Step is missing, please add to proceed."
          );
          setOpenWebFormAlertDialog(true);
        } else {
          setCreateWebFormAlertMessage("");
          setOpenWebFormAlertDialog(false);
          setOpenNameDialog(true);
        }
      } else {
        setCreateWebFormAlertMessage("Add atleast one form step to proceed.");
        setOpenWebFormAlertDialog(true);
      }
    }
  };

  useEffect(() => {
    let index = blocksList.findIndex((block) => block["id"] === goToBlockID);
    if (index !== -1) {
      goToBlock(goToBlockID);
    } else {
      setSelectedBlockData({});
    }
  }, [goToBlockID]);

  const handleGroupBlockRemove = (groupID, blockID) => {
    let list = [...blocksList];
    let index = list.findIndex((block) => block["id"] === groupID);
    if (index !== -1) {
      let blockData = list[index];
      let innerBlocks = blockData["innerBlocks"].filter(
        (block) => block["id"] !== blockID
      );
      blockData["innerBlocks"] = innerBlocks;
      list[index] = blockData;
      setBlocksList(list);
    }
  };

  const isWelcomeStepSelectionDisabled = () => {
    let index = blocksList.findIndex((block) => block["id"] === "welcomestep");
    return index !== -1;
  };

  const isEndStepSelectionDisabled = () => {
    let index = blocksList.findIndex((block) => block["id"] === "endstep");
    return index !== -1;
  };

  const getFormStepDisplayName = (id) => {
    let list = blocksList.filter(
      (block) => block["id"] !== "welcomestep" && block["id"] !== "endstep"
    );
    if (list.length > 1) {
      let index = list.findIndex((block) => block["id"] === id);
      if (index !== -1) {
        return `Form Step-${index + 1}`;
      }
    } else {
      return "Form Step";
    }
  };

  const handleSaveOuterBlockStructure = (structureData) => {
    let list = [...blocksList];
    let index = list.findIndex((block) => block["id"] === goToBlockID);
    if (index !== -1) {
      let indexedData = list[index];
      indexedData["attributes"] = structureData;
      list[index] = indexedData;
      setBlocksList(list);
    }
  };
  const handleSaveInnerBlockStructure = (structureData) => {
    let list = [...blocksList];
    let index = list.findIndex((block) => block["id"] === selectedGroupBlockID);
    if (index !== -1) {
      let indexedData = list[index];
      let innerBlocks = indexedData["innerBlocks"];
      let innerBlockIndex = innerBlocks.findIndex(
        (block) => block["id"] === innerBlockID
      );
      if (innerBlockIndex !== -1) {
        let innerBlockIndexedData = innerBlocks[innerBlockIndex];

        innerBlockIndexedData["attributes"] = structureData;
        innerBlocks[innerBlockIndex] = innerBlockIndexedData;
        indexedData["innerBlocks"] = innerBlocks;
      }
      list[index] = indexedData;
      setBlocksList(list);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <CssBaseline />
        <Box component={"form"} onSubmit={handleSubmit}>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              zIndex: 10,
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
              },
            }}
            variant="permanent"
            anchor="left"
          >
            <AppBar
              component={"nav"}
              position="sticky"
              color="inherit"
              sx={{ top: 0, bottom: "auto", width: drawerWidth, left: 0 }}
            >
              <Toolbar style={{ padding: "16px" }}>
                <Row>
                  <Header>Create Web Form</Header>
                  <IconButton
                    style={{ padding: "0px" }}
                    aria-label="more"
                    id="long-button"
                    aria-controls={Open ? "long-menu" : undefined}
                    aria-expanded={Open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    color="primary"
                  >
                    <AddBoxOutlined />
                  </IconButton>
                </Row>

                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  anchorEl={anchorEl}
                  open={Open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                    },
                  }}
                >
                  <MenuItem
                    disabled={isWelcomeStepSelectionDisabled()}
                    onClick={() => {
                      let welcome_screen_config = { ...welcomeScreenConfig };
                      welcome_screen_config["id"] = "welcomestep";
                      setSelectedBlockData(welcome_screen_config["attributes"]);
                      setOpenSettingPanel(true);

                      let list = [...blocksList, welcome_screen_config];
                      list = sortBocksList(list);

                      setBlocksList(list);
                      setgoToBlockID(welcome_screen_config["id"]);
                      handleClose();
                    }}
                  >
                    Add Welcome Step
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      let group_block_config = { ...groupConfig };
                      group_block_config["id"] = uuid();
                      group_block_config["innerBlocks"] = [];
                      setSelectedBlockData(group_block_config["attributes"]);
                      setOpenSettingPanel(true);

                      let list = [...blocksList, group_block_config];
                      list = sortBocksList(list);
                      setBlocksList(list);
                      setgoToBlockID(group_block_config["id"]);

                      handleClose();
                    }}
                  >
                    Add Form Step
                  </MenuItem>
                  <MenuItem
                    disabled={isEndStepSelectionDisabled()}
                    onClick={() => {
                      let group_block_config = { ...groupConfig };
                      group_block_config["id"] = "endstep";
                      group_block_config["innerBlocks"] = [];
                      setSelectedBlockData(group_block_config["attributes"]);
                      setOpenSettingPanel(true);

                      let list = [...blocksList, group_block_config];
                      list = sortBocksList(list);

                      setBlocksList(list);
                      setgoToBlockID(group_block_config["id"]);

                      handleClose();
                    }}
                  >
                    Add End Step
                  </MenuItem>
                </Menu>
              </Toolbar>
            </AppBar>

            {blocksList.length > 0 ? (
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                  paddingX: "16px",
                  overflowY: "auto",
                  height: "100%",
                  marginTop: "16px",
                }}
              >
                {blocksList.map((item, i) => {
                  if (item["name"] === "group") {
                    let innerBlocks = item["innerBlocks"];
                    return (
                      <Card
                        sx={{ marginBottom: "10px", bgcolor: "#f9f9fc" }}
                        onClick={() => {
                          setInnerBlockID("");
                          setSelectedBlockData(item["attributes"]);
                          setOpenSettingPanel(true);

                          setgoToBlockID(item["id"]);
                        }}
                      >
                        <ListItem key={i} disablePadding disableRipple>
                          <ListItemButton dense disableRipple>
                            <ListItemText
                              primary={
                                item["id"] !== "endstep"
                                  ? getFormStepDisplayName(item["id"])
                                  : displayNameList[item["id"]]
                              }
                            />
                            <IconButton
                              style={{ padding: "0px" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                let tempList = [...blocksList].filter(
                                  (block) => block["id"] !== item["id"]
                                );
                                let lastBlockData =
                                  tempList.length > 0 ? tempList.pop() : null;
                                if (lastBlockData !== null) {
                                  setgoToBlockID(lastBlockData["id"]);
                                  setSelectedBlockData(
                                    lastBlockData["attributes"]
                                  );
                                }
                                handleDelete(item["id"]);
                              }}
                            >
                              <Delete color="error" />
                            </IconButton>
                            <IconButton
                              style={{ padding: "0px" }}
                              onClick={() => {
                                setSelectedGroupBlockID(item["id"]);
                                setOpenAddBlockDialog(true);
                              }}
                            >
                              <AddBoxOutlined color="primary" />
                            </IconButton>
                          </ListItemButton>
                        </ListItem>
                        {innerBlocks.length > 0 && (
                          <List sx={{ padding: "8px" }}>
                            {innerBlocks.map((block) => {
                              return (
                                <Card
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedBlockData(block["attributes"]);
                                    setOpenSettingPanel(true);

                                    setSelectedGroupBlockID(item["id"]);
                                    setInnerBlockID(block["id"]);
                                  }}
                                  sx={{
                                    marginBottom: "10px",
                                    bgcolor: "#f9f9fc",
                                  }}
                                >
                                  <ListItem key={i} disablePadding>
                                    <ListItemButton dense>
                                      <ListItemText
                                        primary={displayNameList[block["name"]]}
                                      />
                                      <IconButton
                                        style={{ padding: "0px" }}
                                        onClick={() => {
                                          handleGroupBlockRemove(
                                            item["id"],
                                            block["id"]
                                          );
                                        }}
                                      >
                                        <Delete color="error" />
                                      </IconButton>
                                    </ListItemButton>
                                  </ListItem>
                                </Card>
                              );
                            })}
                          </List>
                        )}
                      </Card>
                    );
                  } else {
                    return (
                      <Card
                        sx={{ marginBottom: "10px", bgcolor: "#f9f9fc" }}
                        onClick={() => {
                          setInnerBlockID("");
                          setSelectedBlockData(item["attributes"]);
                          setOpenSettingPanel(true);

                          setgoToBlockID(item["id"]);
                        }}
                      >
                        <ListItem key={i} disablePadding>
                          <ListItemButton dense>
                            <ListItemText
                              primary={displayNameList[item["id"]]}
                            />
                            <IconButton
                              style={{ padding: "0px" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                let tempList = [...blocksList].filter(
                                  (block) => block["id"] !== item["id"]
                                );
                                let lastBlockData =
                                  tempList.length > 0 ? tempList.pop() : null;
                                if (lastBlockData !== null) {
                                  setgoToBlockID(lastBlockData["id"]);
                                  setSelectedBlockData(
                                    lastBlockData["attributes"]
                                  );
                                }
                                handleDelete(item["id"]);
                              }}
                            >
                              <Delete color="error" />
                            </IconButton>
                          </ListItemButton>
                        </ListItem>
                      </Card>
                    );
                  }
                })}
              </List>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                  paddingX: "16px",
                  overflowY: "auto",
                  height: "100%",
                  marginTop: "16px",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Message>No steps.</Message>
              </Box>
            )}
            <Divider style={{ marginBottom: "60px" }} />
            <AppBar
              position="fixed"
              color="inherit"
              sx={{ top: "auto", bottom: 0, width: drawerWidth, left: 0 }}
            >
              <Toolbar style={{ padding: "16px" }}>
                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      navigate("/web-forms");
                    }}
                    variant="contained"
                    color="inherit"
                    style={{ textTransform: "capitalize", width: "100%" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ textTransform: "capitalize", width: "100%" }}
                  >
                    Create
                  </Button>
                </ButtonWrapper>
              </Toolbar>
            </AppBar>
          </Drawer>
        </Box>

        <Box
          component="main"
          sx={{ flexGrow: 1, width: "80%", height: "100%" }}
        >
          {!isSubmitSuccess ? (
            blocksList.length > 0 ? (
              <QuillFormPreview blocksList={blocksList} />
            ) : (
              <MessageWrapper>
                <Message>Add atleast one step to preview</Message>
                <Button
                  onClick={() => {
                    let group_block_config = { ...groupConfig };
                    group_block_config["id"] = uuid();
                    group_block_config["innerBlocks"] = [];
                    setSelectedBlockData(group_block_config["attributes"]);
                    setOpenSettingPanel(true);
                    let list = [...blocksList, group_block_config];
                    list = sortBocksList(list);
                    setBlocksList(list);
                    setgoToBlockID(group_block_config["id"]);

                    handleClose();
                  }}
                  variant="text"
                  color="primary"
                  style={{
                    textTransform: "capitalize",
                    width: "fit-content",
                    textDecoration: "underline",
                  }}
                >
                  Add Step
                </Button>
              </MessageWrapper>
            )
          ) : (
            <MessageWrapper>
              <Message>Form created successfully!</Message>
              <Button
                onClick={() => {
                  navigate("/web-forms");
                }}
                variant="outlined"
                color="primary"
                style={{ textTransform: "capitalize", width: "fit-content" }}
              >
                Go Back
              </Button>
            </MessageWrapper>
          )}
        </Box>
        {openSettingPanel && (
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              zIndex: 10,
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
              },
            }}
            variant="permanent"
            anchor="right"
          >
            <List style={{ width: drawerWidth }}>
              <ListItem>
                <Header>Settings</Header>
              </ListItem>
            </List>
            <FormFieldOptions
              selectedFieldStructure={selectedBlockData}
              setSelectedFieldStructure={setSelectedBlockData}
              innerBlockID={innerBlockID}
              handleSave={(structureData) => {
                if (innerBlockID !== "") {
                  handleSaveInnerBlockStructure(structureData);
                } else {
                  handleSaveOuterBlockStructure(structureData);
                }
              }}
            />
          </Drawer>
        )}
      </Box>

      {openAddBlockDialog && (
        <AddBlockDialog
          open={openAddBlockDialog}
          setOpen={setOpenAddBlockDialog}
          setSelectedBlockData={setSelectedBlockData}
          setBlocksList={setBlocksList}
          blocksList={blocksList}
          groupID={selectedGroupBlockID}
          setGroupID={setSelectedGroupBlockID}
        />
      )}

      {openWebFormAlertDialog && (
        <CreateWebFormAlertDialog
          open={openWebFormAlertDialog}
          setOpen={setOpenWebFormAlertDialog}
          message={createWebFormAlertMessage}
        />
      )}

      {openNameDialog && (
        <SubmitFormWithNameDialog
          open={openNameDialog}
          setOpen={setOpenNameDialog}
          handleSubmit={(formName) => {
            createWebForm(formName);
          }}
        />
      )}
      {openErrorDialog && (
        <CustomErrorMessageDialog
          open={openErrorDialog}
          setOpen={setOpenErrorDialog}
          message={errorMessage}
        />
      )}
    </>
  );
};

export default observer(FormBuilderPage);
