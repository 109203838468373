import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "fit-content",
  textAlign: "left",
  padding: theme.spacing(2),
  height: "fit-content",
  border: "2px solid #6FB835",
  borderRadius: "5px",
  backgroundColor: "white",
  [[theme.breakpoints.between("xs", "md")]]: {
    width: "100%",
  },
}));

const IframeWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  // paddingBottom: "56.25%",
  height: "0",
  overflow: "hidden",
  height: "400px",
  width: "400px",
  [[theme.breakpoints.between("xs", "md")]]: {
    width: "100%",
  },
}));

const ContactForm = ({ pageSchema }) => {
  return (
    <Container>
      <IframeWrapper>
        <iframe
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "0",
            left: "0",
          }}
          // src={`https://webform-shell.netlify.app/?formid=${pageSchema.form_id}`}
          src="https://form-without-appbar.netlify.app/"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </IframeWrapper>
    </Container>
  );
};

export default ContactForm;
