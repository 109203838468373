import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
  styled,
} from "@mui/material";
import React, { useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"; // Import the icon

const DetailHighlight = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  color: #2b81d6;
`;

const SwitchPhoneComponent = ({
  phoneNumberColumnList,
  selectedPhoneNumberColumnID,
  renderPhoneField,
  handleMakePrimary,
  getPrimaryPhoneNumber,
}) => {
  const [open, setOpen] = useState(false);
  const arrowRef = useRef(null);

  const handleIconClick = () => {
    setOpen((prevOpen) => !prevOpen); // Toggle the menu open state
  };

  const handleClose = (event) => {
    if (arrowRef.current && arrowRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <FormControl
      sx={{
        position: "relative",
        width: "100%",
        height: "30px",
        backgroundColor: "transparent",
        borderRadius: "8px",
        outline: "none",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none", // Remove the border
        },
        "& .MuiSelect-icon": {
          color: "white", // Change the dropdown arrow icon color to white
        },
      }}
    >
      <Select
        sx={{
          color: "white",
          width: "100%",
          height: "30px",
          font: "normal normal 400 14px Poppins",
          backgroundColor: "transparent",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none", // Remove the border
          },
          "&:focus": {
            outline: "none", // Remove the outline on focus
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none", // Remove the border on focus
          },
        }}
        MenuProps={{
          disableScrollLock: true,
        }}
        open={open}
        IconComponent={() => null}
        onClose={handleClose}
        inputProps={{
          "aria-label": "Without label",
          style: { borderColor: "transparent" },
        }}
        value={selectedPhoneNumberColumnID}
        renderValue={() => renderPhoneField()}
      >
        {phoneNumberColumnList.map((column, index) => {
          if (column["field"] === "phone") {
            return (
              <MenuItem
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
                onMouseDown={(event) => event.preventDefault()}
                disableRipple
                disableTouchRipple
                key={index}
                value={column["id"]}
                sx={{
                  font: "normal normal 400 14px Poppins",
                  display: "flex",
                  justifyContent: "space-between", // To align the button to the right
                  alignItems: "center",
                  gap: "10px",
                  "&:hover": {
                    backgroundColor: "transparent", // Remove hover effect
                  },
                  "&.Mui-selected": {
                    backgroundColor: "transparent !important", // Remove active (focused) style
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: "transparent !important", // Remove hover effect when selected
                  },
                }}
              >
                {getPrimaryPhoneNumber()}
                <DetailHighlight>Primary</DetailHighlight>
              </MenuItem>
            );
          } else {
            return (
              <MenuItem
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
                disableRipple
                disableTouchRipple
                key={index}
                value={column["id"]}
                sx={{
                  font: "normal normal 400 14px Poppins",
                  display: "flex",
                  justifyContent: "space-between", // To align the button to the right
                  alignItems: "center",
                  gap: "10px",
                  "&:hover": {
                    backgroundColor: "transparent", // Remove hover effect
                  },
                  "&.Mui-selected": {
                    backgroundColor: "transparent !important", // Remove active (focused) style
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: "transparent !important", // Remove hover effect when selected
                  },
                }}
              >
                {column["value"]}
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    textTransform: "none",
                    fontWeight: "bold",
                    fontSize: "10px",
                    padding: "4px 8px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMakePrimary(column["id"]);
                    setOpen(false);
                  }}
                >
                  Make Primary
                </Button>
              </MenuItem>
            );
          }
        })}
      </Select>

      <ExpandMoreIcon
        ref={arrowRef}
        onClick={handleIconClick}
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
          height: "30px",
          width: "30px",
          color: "white",
          cursor: "pointer",
        }}
      />
    </FormControl>
  );
};

export default SwitchPhoneComponent;
