import React, { useEffect, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";

import styled from "styled-components";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import ProfileTab from "../../components/user_tabs/ProfileTab";
import UserActivityTab from "../../components/user_tabs/UserActivityTab";
import UserCallDetails from "../../components/user_tabs/UserCallDetails";
import { Button, IconButton, withStyles } from "@material-ui/core";
import whatsapp_icon from "../../assets/campaign_icons/whatsapp.png";

import { ChevronRight } from "@material-ui/icons";
import { fontWeight } from "@mui/system";
import { Divider } from "@mui/material";
import UserFileTab from "../../components/user_tabs/UserFileTab";
import { getPhoneApi } from "../../Api";
import DetailsTab from "../../components/project_tabs/DetailsTab";
import ProjectMemberTab from "../../components/project_tabs/ProjectMemberTab";
import ProjectTeamTab from "../../components/project_tabs/ProjectTeamTab";
import SourcesTab from "../../components/project_tabs/SourcesTab";
import StagesTab from "../../components/project_tabs/StagesTab";
import StatusTab from "../../components/project_tabs/StatusTab";
const ParentContainer = styled.div`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled.div`
  ${`min-height: calc(100vh - 253px);`};
  margin: auto;
  margin-top: 70px;
  width: 95%;
`;
const Wrapper = styled.div`
  display: flex;
  position: relative;
  background-color: white;
  padding: 20px 20px 0px 20px;
  border: 2px solid #f9f9fc;
`;
const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 0px;
`;
const Text = styled.p`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Icon = styled.img`
  height: 42px;
  width: 42px;
  margin-right: 20px;
  cursor: pointer;
`;
const TeamDetail = (props) => {
  const { filterStore } = rootStore;
  let navigate = useNavigate();
  let { id, name, pid, pname } = useParams();
  const [val, setVal] = useState(0);
  useEffect(() => {
    props.setShowBar(true);
  }, []);
  const CustomTab = withStyles({
    root: {
      backgroundColor: "white",
      color: "#4D4E4F",
      font: "normal normal 600 14px Open Sans",
      textTransform: "capitalize",
    },
    selected: {
      backgroundColor: "#E7EEFA",
      color: "#4079DA",
      font: "normal normal 600 14px Open Sans",
      textTransform: "capitalize",
    },
  })(Tab);
  const handleBreadCrump = () => {
    navigate(-1);
  };

  return (
    <>
      <ParentContainer>
        <Container>
          <TopWrapper>
            <Text>Setting</Text>
            <ChevronRight style={{ width: "16px", height: "16px" }} />
            <Text
              style={{ color: "#185DD2", cursor: "pointer" }}
              onClick={() => {
                navigate("/project-setting");
              }}
            >
              Project
            </Text>
            <ChevronRight style={{ width: "16px", height: "16px" }} />
            <Text
              style={{ color: "#185DD2", cursor: "pointer" }}
              onClick={handleBreadCrump}
            >
              {pname}
            </Text>
            <ChevronRight style={{ width: "16px", height: "16px" }} />

            <Text>{name !== "null" ? name : id}</Text>
          </TopWrapper>
          <Row>
            <Tabs
              value={val}
              textColor="primary"
              indicatorColor="primary"
              onChange={(event, newValue) => {
                setVal(newValue);
              }}
            >
              <CustomTab label="Details" />
              <CustomTab label="Members" />
              <CustomTab label="Lead Sources" />
              <CustomTab label="Lead Stages" />
              <CustomTab label="Lead Statuses" />
            </Tabs>
          </Row>
          <Wrapper>
            {(() => {
              if (val === 0) {
                return <DetailsTab id={id} />;
              } else if (val === 1) {
                return <ProjectMemberTab id={pid} tid={id} />;
              } else if (val === 2) {
                return <SourcesTab id={id} name={name} />;
              } else if (val === 3) {
                return <StagesTab id={id} name={name} />;
              } else if (val === 4) {
                return <StatusTab id={id} name={name} />;
              }
            })()}
          </Wrapper>
        </Container>
      </ParentContainer>
      <Footer />
    </>
  );
};

export default observer(TeamDetail);
