import axios from "axios";
import rootStore from "./stores/RootStore";
import Cookies from "js-cookie";
import { domainName } from "./Config";

const SetupInterceptors = (navigate) => {
  const clearLocalStorageWithoutDeviceID = () => {
    let device_id = localStorage.getItem("device_id");
    localStorage.clear();
    localStorage.setItem("device_id", device_id);
  };

  const clearDataStored = () => {
    clearLocalStorageWithoutDeviceID();
    Cookies.remove("token", { path: "/", domain: domainName });
    Cookies.remove("uid", { path: "/", domain: domainName });
    rootStore.authStore.resetUserAuth();
  };

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 403) {
        if (error.response.data["code"] === "1026") {
          clearDataStored();
          navigate("/network-restriction", {
            state: error.response.data["data"],
          });
        }
      } else if (error.response && error.response.status === 401) {
        clearDataStored();
        navigate("/login");
      }

      return Promise.reject(error);
    }
  );
};

export default SetupInterceptors;
