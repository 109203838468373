import { Button, Modal, CircularProgress, Box } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Typography, Alert } from "@mui/material";
import styled from "styled-components";
import { Close } from "@material-ui/icons";
import { Transfer } from "antd";
import "antd/dist/antd.css";
import "../../styles/multiselect.css";
import { MenuOutlined } from "@ant-design/icons";
import { DndProvider, useDrop, useDrag } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { getLocalizedText } from "../../Functions";
import rootStore from "../../stores/RootStore";
import { getAdminFilterColumns, updateFilterColumns } from "../../Api";
import { styled as muiStyled } from "@mui/material/styles";

const ModelContainer = muiStyled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: 600px;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 20px;
  @media (max-width: 700px) {
    height: 80vh;
    overflow-y: scroll;
  }
`;
const ModelWrapper = muiStyled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
}));
const ColumnsWrapper = styled.div`
  /* display: flex; */
  margin-bottom: 20px;
`;
const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  > .label {
    display: inline-block;
    max-width: calc(100% - 20px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &.drop-over-downward {
    border-bottom: 2px dashed #1890ff;
  }
  &.drop-over-upward {
    border-top: 2px dashed #1890ff;
  }
`;

const ModalHeader = muiStyled(Typography)(({ theme }) => ({
  font: "normal normal 700 24px Open Sans",
  marginBottom: "20px",
}));

const ViewTabFilterColumnSelectionModal = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const columnsList = [...rootStore.userStore.AllColumnsList];
  const handleClick = () => {
    props.setOpen(false);
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  useEffect(() => {
    setup();
  }, []);
  const setup = async () => {
    let filterColumnsList = await getAdminFilterColumns();

    let newColumnsList = [];
    if (filterColumnsList.length > 0 && columnsList.length > 0) {
      filterColumnsList.forEach((item) => {
        columnsList.forEach((key) => {
          if (key["field"] === item["field"]) {
            // let newItem = key;
            // newItem["dataType"] = key["data_type"];
            newColumnsList.push(key);
          }
        });
      });
    }
    let newData = [];
    if (columnsList.length > 0) {
      columnsList.forEach((e) => {
        if (rootStore.userStore.isColumnVisible(e)) {
          let newObj = {
            key: e.field,
            title: e.headerName,
          };
          newData.push(newObj);
        }
      });
      setData(newData);
      let targetKeys = [];

      newColumnsList.forEach((column) => {
        targetKeys.push(column.field);
      });
      setTargetKeys(targetKeys);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };
  const handleKeys = (keys) => {
    setShowAlert(false);
    setErrorMessage("");

    setTargetKeys(Array.from(new Set(keys)));
  };
  const handleSubmit = async () => {
    if (targetKeys.length > 0) {
      setShowAlert(false);
      setErrorMessage("");

      let payload = {};
      let tempList = [];
      targetKeys.forEach((item, i) => {
        let field = item;
        let index = columnsList.findIndex(
          (column) => column["field"] === field
        );
        if (index !== -1) {
          tempList.push({
            field: columnsList[index].field,
            dataType: columnsList[index]["data_type"],
            sequence: i,
            headerName: columnsList[index]["headerName"],
          });
        }
      });
      payload["selected_columns"] = tempList;
      let response = await updateFilterColumns({
        payload: payload,
      });
      if (response) {
        props.postSubmitCallback();
        handleClose();
      } else {
        setShowAlert(true);
        setErrorMessage(
          getLocalizedText(
            "some_error_occured_please_retry_or_contact_your_administrator"
          )
        );
      }
    } else {
      setShowAlert(true);
      setErrorMessage(
        getLocalizedText("please_have_atleast_one_column_in_use")
      );
    }
  };

  //order select column related functions

  // change order
  const moveRow = async (dragIndex, hoverIndex) => {
    const clonedList = targetKeys;
    const el = clonedList.splice(dragIndex, 1)[0];
    clonedList.splice(hoverIndex, 0, el);
    handleKeys(clonedList);
  };

  //end//

  return (
    <>
      {
        <Modal
          open={props.open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {isLoading ? (
            <ModelContainer
              style={{
                width: "400px",
                height: "250px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            </ModelContainer>
          ) : (
            <ModelContainer>
              <Box>
                <Close
                  onClick={handleClick}
                  style={{
                    height: "18px",
                    width: "18px",
                    position: "absolute",
                    top: "20px",
                    right: "30px",
                    cursor: "pointer",
                  }}
                />
                <ModelWrapper>
                  <ModalHeader>
                    {getLocalizedText("filter_columns_to_display")}
                  </ModalHeader>
                  <ColumnsWrapper>
                    {data.length > 0 && (
                      <DndProvider backend={HTML5Backend}>
                        <Transfer
                          dataSource={data.sort((a, b) =>
                            a["title"]
                              .toLowerCase()
                              .localeCompare(b["title"].toLowerCase())
                          )}
                          listStyle={{
                            width: "fit-content",
                            height: 300,
                          }}
                          titles={[
                            getLocalizedText("columns_available"),
                            getLocalizedText("columns_in_use"),
                          ]}
                          targetKeys={targetKeys}
                          onChange={handleKeys}
                          render={(it) => (
                            <DraggableItem
                              index={targetKeys.findIndex(
                                (key) => key === it.key
                              )}
                              label={it.title}
                              moveRow={moveRow}
                            />
                          )}
                        />
                      </DndProvider>
                    )}
                  </ColumnsWrapper>
                  {showAlert && (
                    <Box
                      sx={{
                        marginTop: "16px",
                      }}
                    >
                      <Alert
                        style={{
                          width: "100%",
                          padding: "0px 20px",
                        }}
                        severity="warning"
                      >
                        {errorMessage}
                      </Alert>
                    </Box>
                  )}
                  <Box
                    sx={{
                      marginTop: "16px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        handleSubmit();
                      }}
                      variant="contained"
                      style={{
                        marginTop: "20px",
                        backgroundColor: "blue",
                        color: "white",
                        width: "fit-content",
                        margin: "auto",
                      }}
                    >
                      {getLocalizedText("apply")}
                    </Button>
                  </Box>
                </ModelWrapper>
              </Box>
            </ModelContainer>
          )}
        </Modal>
      }
    </>
  );
};

const type = "DraggableItem";

const DraggableItem = ({ index, label, moveRow }) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ` drop-over-downward` : ` drop-over-upward`,
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(ref));

  return (
    <ItemWrapper
      key={label}
      ref={ref}
      className={`${isOver ? dropClassName : ""}`}
    >
      <span className="label">{label}</span>
      {index !== -1 && (
        <span ref={drag}>
          <MenuOutlined />
        </span>
      )}
    </ItemWrapper>
  );
};

export default ViewTabFilterColumnSelectionModal;
