import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, CircularProgress } from "@material-ui/core";
import {
  AppBar,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  OutlinedInput,
  Toolbar,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import MuiAlert from "@mui/material/Alert";

import { useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import {
  createProjectAdministrationViewApi,
  editProjectAdministrationViewApi,
  getListApi,
  getProjectAdministrationViewGroupsApi,
} from "../../Api";
import rootStore from "../../stores/RootStore";
import { smartViewRoleList } from "../../Config";
import Select from "react-select";
import { getIsoString, getSlug } from "../../Functions";
import { filterOperatorMapList, operatorLabelMap } from "../../Db";
import CreateViewTabFilterValueFiledComponent from "./components/CreateViewTabFilterValueFieldComponent";
import { Add, Cancel } from "@material-ui/icons";
import { Circle } from "@mui/icons-material";
import moment from "moment";
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for options
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for the entire control container, including the search input
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "no options" message
  }),
  loadingMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "loading" message
  }),
};

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  gap: 20px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ChipLable = styled(Typography)`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
  margin-right: 4px;
`;
const ChipValue = styled(Typography)`
  margin: 0px;
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  word-wrap: break-word;
  width: 100%;
`;
const CustomChip = styled(Box)`
  display: flex;
  position: relative;
  padding: 20px;
  background-color: #e0e0e0;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  border-radius: 12px;
`;
const FilterListContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ${`max-height: calc(100vh - 580px);`};
  overflow-y: auto;
  column-gap: 10px;
  row-gap: 10px;
  padding: 0px 20px;
  ::-webkit-scrollbar {
    width: 5px;
    background-color: #efeff4;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #999;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #666;
  }
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;
const CreateViewTabSidepanel = ({
  setOpen,
  postCreateCallback,
  scope,
  setScope,
  editableData,
  postEditCallback,
}) => {
  const columnsList = [...rootStore.userStore.AllColumnsList];
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const [tabName, setTabName] = useState("");
  const [groupList, setGroupList] = useState([]);
  const [selectedGroupList, setSelectedGroupList] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);

  const [multiSelectFilterColumnValue, setMultiSelectFilterColumnValue] =
    useState(null);
  const [selectedFilterColumn, setSelectedFilterColumn] = useState(null);
  const [operatorList, setOperatorList] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [filterTypeListMeta, setFilterTypeListMeta] = useState({});
  const [filter, setFilter] = useState({});
  const [selectedFilterList, setSelectedFilterList] = useState([]);
  const [canIncludeClosedLeads, setCanIncludeClosedLeads] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setScope("");
  };

  const generatePresetFilterPayload = () => {
    let normalFields = {};
    let customFields = {};
    selectedFilterList.forEach((item) => {
      let filterData = item["filter"];
      let value = null;
      Object.keys(filterData).map((key) => {
        if (
          filterData[key]["filter_type"] === "normal" ||
          filterData[key]["filter_type"] === ""
        ) {
          if (
            filterData[key]["data_type"] === "date" ||
            filterData[key]["data_type"] === "datetime"
          ) {
            value = getIsoString(filterData[key]["value"]);
          } else {
            value = filterData[key]["value"];
          }
        } else if (filterData[key]["filter_type"] === "range") {
          if (
            filterData[key]["data_type"] === "date" ||
            filterData[key]["data_type"] === "datetime"
          ) {
            let d1 = getIsoString(filterData[key]["value"]["start"]);
            let d2 = getIsoString(filterData[key]["value"]["end"]);
            value = {
              start: d1,
              end: d2,
            };
          } else if (filterData[key]["data_type"] === "integer") {
            let num1 = filterData[key]["value"]["start"];
            let num2 = filterData[key]["value"]["end"];
            value = {
              start: num1,
              end: num2,
            };
          }
        } else if (filterData[key]["filter_type"] === "list") {
          let tempList = [];
          filterData[key]["value"].forEach((obj) => {
            tempList.push(obj["value"]);
          });
          value = tempList;
        }
      });
      const fieldName = Object.keys(filterData)[0];

      let index = columnsList.findIndex(
        (column) => column["field"] === fieldName
      );
      if (index !== -1) {
        if (columnsList[index]["is_custom_param"]) {
          let newKey = fieldName.replace("custom_params_", "");
          customFields[newKey] = {
            value: value,
            op: item["operator"]["value"],
          };
        } else {
          normalFields[fieldName] = {
            value: value,
            op: item["operator"]["value"],
          };
        }
      }
    });

    if (Object.keys(customFields).length > 0) {
      normalFields["custom_params"] = customFields;
    }
    return normalFields;
  };

  const createViewTab = async (payload) => {
    let response = await createProjectAdministrationViewApi({
      projectId: rootStore.authStore.projectId,
      payload: payload,
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      postCreateCallback();
      setIsSubmitSuccess(true);
    }
  };

  const editViewTab = async (payload) => {
    let response = await editProjectAdministrationViewApi({
      projectId: rootStore.authStore.projectId,
      payload: payload,
      itemId: editableData["id"],
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      postEditCallback(response.data);
      setIsSubmitSuccess(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let payload = {
      name: tabName,
    };
    if (scope === "edit") {
      payload["show_closed_leads"] = canIncludeClosedLeads;
    }
    let tempViewGroupList = [];
    selectedGroupList.forEach((group) => {
      tempViewGroupList.push(group["value"]);
    });
    payload["viewgroups"] = tempViewGroupList;
    let tempSelectedRoles = [];
    selectedRoles.forEach((role) => {
      tempSelectedRoles.push(role["value"]);
    });
    payload["roles"] = tempSelectedRoles;

    let tempSelectColumnList = [];
    selectedColumns.forEach((column, idx) => {
      let index = columnsList.findIndex(
        (columnObj) => columnObj["field"] === column["value"]
      );
      if (index !== -1) {
        let columnMeta = columnsList[index];
        let tempObj = {
          field: columnMeta["field"],
          headerName: columnMeta["headerName"],
          dataType: columnMeta["data_type"],
          sequence: idx,
        };
        tempSelectColumnList.push(tempObj);
      }
    });
    payload["selected_columns"] = tempSelectColumnList;

    payload["preset_filters"] = generatePresetFilterPayload();
    if (scope === "create") {
      await createViewTab(payload);
    } else {
      await editViewTab(payload);
    }
  };

  const updateList = (list) => {
    let newList = [];
    list.forEach((item) => {
      let newObj = {
        label: item.name,
        value: item.name,
      };
      newList.push(newObj);
    });
    return newList;
  };

  const init = async () => {
    let group_list = await getGroupList();
    let modified_group_list = updateList([...group_list]);
    setGroupList(modified_group_list);
    if (scope === "edit") {
      if (
        editableData["tab"].hasOwnProperty("show_closed_leads") &&
        editableData["tab"]["show_closed_leads"] !== null
      ) {
        setCanIncludeClosedLeads(editableData["tab"]["show_closed_leads"]);
      }
      setTabName(editableData.tab.name);
      if (Object.keys(editableData["tab"]["preset_filters"]).length > 0) {
        let selectedFilterList = getSelectedFilterList(
          editableData["tab"]["preset_filters"]
        );

        setSelectedFilterList(selectedFilterList);
      }
      let selectedGroups = editableData.tab.viewgroups.map((str) => ({
        label: str,
        value: str,
      }));
      setSelectedGroupList(selectedGroups);

      let selectedColumns = editableData.tab.selected_columns.map((obj) => ({
        label: obj["headerName"],
        value: obj["field"],
      }));
      setSelectedColumns(selectedColumns);
      let tempRoleList = [];
      editableData.tab.roles.forEach((role) => {
        let index = smartViewRoleList.findIndex((obj) => obj["value"] === role);
        if (index !== -1) {
          tempRoleList.push(smartViewRoleList[index]);
        }
      });
      setSelectedRoles(tempRoleList);
    }
    setLoading(false);
  };

  const getGroupList = async () => {
    let response = await getProjectAdministrationViewGroupsApi({
      projectId: rootStore.authStore.projectId,
    });
    return response;
  };
  useEffect(() => {
    init();
  }, []);

  const canShowChildField = ({ parent_field_info, child_field_info }) => {
    let index = columnsList.findIndex(
      (column) => column.id === parent_field_info.id
    );
    if (index !== -1) {
      let targetColumn = columnsList[index];
      let targetcolumnIndex = selectedFilterList.findIndex(
        (filterData) => filterData["column_id"] === targetColumn["id"]
      );
      if (targetcolumnIndex !== -1) {
        let childrenConfig = targetColumn.hasOwnProperty("children_config")
          ? targetColumn["children_config"]
          : null;
        let parentValue =
          selectedFilterList[targetcolumnIndex]["filter"][
            targetColumn["field"]
          ]["value"];

        parentValue = parentValue.map((obj) => obj["value"]);

        if (parentValue.length === 1) {
          if (
            childrenConfig !== null &&
            childrenConfig.hasOwnProperty(parentValue)
          ) {
            let childIdList = childrenConfig[parentValue];
            let index = childIdList.findIndex(
              (id) => id === child_field_info.id
            );

            if (index !== -1) {
              return true;
            }
          }
        }
      }
    }
    return false;
  };

  const getSelectColumnOptions = () => {
    let tempList = [];
    columnsList.forEach((column) => {
      if (rootStore.userStore.isColumnVisible(column)) {
        tempList.push(column);
      }
    });
    tempList.sort((a, b) =>
      a["headerName"].toLowerCase().localeCompare(b["headerName"].toLowerCase())
    );
    return tempList.map((column) => ({
      label: column["headerName"],
      value: column["field"],
    }));
  };

  const getSelectFilterColumnOptions = () => {
    let tempList = [];
    columnsList.forEach((column) => {
      if (column["filterable"] && rootStore.userStore.isColumnVisible(column)) {
        let index = selectedFilterList.findIndex(
          (filterData) => filterData["column_id"] === column["id"]
        );
        if (index === -1) {
          if (column["child_of"] !== null) {
            if (
              canShowChildField({
                parent_field_info: column["child_of"],
                child_field_info: column,
              })
            ) {
              tempList.push(column);
            }
          } else {
            tempList.push(column);
          }
        }
      }
    });
    tempList.sort((a, b) =>
      a["headerName"].toLowerCase().localeCompare(b["headerName"].toLowerCase())
    );
    return tempList.map((column) => ({
      label: column["headerName"],
      value: column["id"],
    }));
  };

  const getOperatorList = (item) => {
    if (item["parent"] === true) {
      return filterOperatorMapList["parent"];
    } else {
      if (item["child_of"] !== null) {
        return filterOperatorMapList["child"];
      } else {
        if (item["data_type"] === "date" || item["data_type"] === "datetime") {
          if (item["filter_type"] === "range") {
            return filterOperatorMapList["date_range"];
          } else {
            return filterOperatorMapList["date"];
          }
        } else if (item["data_type"] === "string") {
          if (item["filter_type"] === "list") {
            return filterOperatorMapList["list"];
          } else {
            return filterOperatorMapList["string"];
          }
        } else if (item["data_type"] === "integer") {
          if (item["filter_type"] === "range") {
            return filterOperatorMapList["integer_range"];
          } else if (item["filter_type"] === "list") {
            return filterOperatorMapList["list"];
          } else {
            return filterOperatorMapList["integer"];
          }
        }
      }
    }
    return [];
  };

  const getListDetailsToRender = async (filter_input) => {
    let tempType = "list";
    let tempList = [];
    if (filter_input.charAt(0) === "/" || filter_input.startsWith("ep:")) {
      let slug = getSlug(filter_input);
      let response = await getListApi({ slug: slug });
      if (response !== null) {
        if (Array.isArray(response)) {
          tempType = "list";
          tempList = response;
        } else if (typeof response === "object") {
          if (Object.keys(response).length > 0) {
            tempType = response["type"];
            tempList = response["values"];
          }
        }
      }
    } else if (
      filter_input.charAt(0) !== "/" &&
      filter_input !== "" &&
      filter_input !== null
    ) {
      try {
        let parseJSON = JSON.parse(filter_input);
        if (Array.isArray(parseJSON)) {
          tempType = "list";
          tempList = parseJSON;
        } else if (typeof parseJSON === "object") {
          if (Object.keys(parseJSON).length > 0) {
            tempType = parseJSON["type"];
            tempList = parseJSON["values"];
          }
        }
      } catch (error) {
        if (filter_input.length > 0) {
          let list = filter_input.split(",");
          tempType = "list";
          tempList = list;
        }
      }
    }
    return { list: tempList, type: tempType };
  };

  const isFilterValuePicked = () => {
    if (Object.keys(filter).length > 0) {
      let data = filter[selectedFilterColumn["field"]];
      if (data["filter_type"] === "list") {
        return data["value"].length > 0;
      } else if (data["filter_type"] === "range") {
        return (
          data["value"].hasOwnProperty("start") &&
          data["value"].hasOwnProperty("end")
        );
      } else {
        return data["value"] !== null && data["value"] !== "";
      }
    }
    return false;
  };

  const isAddButtonEnabled = () => {
    if (isFilterValuePicked()) {
      return selectedOperator !== null;
    }
    return false;
  };

  const handleAdd = () => {
    let tempList = [...selectedFilterList];

    let columnIndex = columnsList.findIndex(
      (column) => column["id"] === selectedFilterColumn["id"]
    );
    if (columnIndex !== -1) {
      let columnMeta = columnsList[columnIndex];
      if (columnMeta["parent"] === true) {
        tempList = resetChildOfParent({
          parentFieldMeta: columnMeta,
          filterList: tempList,
        });
      }
    }

    let tempData = {
      column_id: selectedFilterColumn["id"],
      filter: filter,
      operator: selectedOperator,
    };
    let index = tempList.findIndex(
      (filterData) => filterData["column_id"] === tempData["column_id"]
    );
    if (index !== -1) {
      tempList[index] = tempData;
    } else {
      tempList.push(tempData);
    }
    setSelectedFilterList(tempList);
    setFilterTypeListMeta({});
    setOperatorList([]);
    setSelectedOperator(null);
    setSelectedFilterColumn(null);
    setMultiSelectFilterColumnValue(null);
    setFilter({});
  };

  const handleRemove = (columnID) => {
    let tempList = [...selectedFilterList];

    let columnIndex = columnsList.findIndex(
      (column) => column["id"] === columnID
    );
    if (columnIndex !== -1) {
      let columnMeta = columnsList[columnIndex];
      if (columnMeta["parent"] === true) {
        for (let i = 0; i < selectedFilterList.length; i++) {
          let filterData = selectedFilterList[i];

          let index = columnsList.findIndex(
            (column) => column["id"] === filterData["column_id"]
          );
          if (index !== -1) {
            let childMeta = columnsList[index];
            if (
              childMeta["child_of"] !== null &&
              childMeta["child_of"]["id"] === columnMeta["id"]
            ) {
              tempList = tempList.filter(
                (filterData) => filterData["column_id"] !== childMeta["id"]
              );
            }
          }
        }
      }
    }

    tempList = tempList.filter(
      (filterData) => filterData["column_id"] !== columnID
    );
    setSelectedFilterList(tempList);
  };

  const handleEdit = async (filterData) => {
    let index = columnsList.findIndex(
      (column) => column["id"] === filterData["column_id"]
    );
    if (index !== -1) {
      let columnMeta = columnsList[index];
      if (columnMeta["filter_type"] === "list") {
        let filterTypeListMeta = await getListDetailsToRender(
          columnMeta["filter_input"]
        );

        setFilterTypeListMeta(filterTypeListMeta);
      }
      setFilter(filterData["filter"]);
      setSelectedOperator(filterData["operator"]);
      setSelectedFilterColumn(columnMeta);
      setMultiSelectFilterColumnValue({
        label: columnMeta["headerName"],
        value: columnMeta["id"],
      });
      let tempList = getOperatorList(columnMeta);
      setOperatorList(tempList);
    }
  };

  const getSelectedFilterList = (filterList) => {
    let selectedFilterList = [];

    Object.keys(filterList).forEach((fieldName) => {
      if (fieldName === "custom_params") {
        let customParams = filterList["custom_params"];
        Object.keys(customParams).forEach((customKey) => {
          let customFieldData = customParams[customKey];
          let newKey = `custom_params_${customKey}`;
          let index = columnsList.findIndex(
            (column) => column["field"] === newKey
          );
          if (index !== -1) {
            let columnMeta = columnsList[index];
            let value = null;

            if (columnMeta["filter_type"] === "list") {
              value = customFieldData["value"].map((str) => ({
                label: str,
                value: str,
              }));
            } else if (columnMeta["filter_type"] === "range") {
              if (
                columnMeta["data_type"] === "date" ||
                columnMeta["data_type"] === "datetime"
              ) {
                value = {
                  start: moment(customFieldData["value"]["start"])
                    .local()
                    .format("YYYY-MM-DD"),
                  end: moment(customFieldData["value"]["end"])
                    .local()
                    .format("YYYY-MM-DD"),
                };
              } else {
                value = {
                  start: customFieldData["value"]["start"],
                  end: customFieldData["value"]["end"],
                };
              }
            } else {
              if (
                columnMeta["data_type"] === "date" ||
                columnMeta["data_type"] === "datetime"
              ) {
                value = moment(customFieldData["value"])
                  .local()
                  .format("YYYY-MM-DD");
              } else {
                value = customFieldData["value"];
              }
            }

            let filterItem = {
              filter: {
                [columnMeta["field"]]: {
                  value: value,
                  filter_type: columnMeta["filter_type"],
                  data_type: columnMeta["data_type"],
                },
              },
              operator: {
                label: operatorLabelMap[customFieldData["op"]],
                value: customFieldData["op"],
              },
              column_id: columnMeta["id"],
            };

            selectedFilterList.push(filterItem);
          }
        });
      } else {
        let fieldData = filterList[fieldName];
        let index = columnsList.findIndex(
          (column) => column["field"] === fieldName
        );
        if (index !== -1) {
          let columnMeta = columnsList[index];
          let value = null;

          if (columnMeta["filter_type"] === "list") {
            value = fieldData["value"].map((str) => ({
              label: str,
              value: str,
            }));
          } else if (columnMeta["filter_type"] === "range") {
            if (
              columnMeta["data_type"] === "date" ||
              columnMeta["data_type"] === "datetime"
            ) {
              value = {
                start: moment(fieldData["value"]["start"])
                  .local()
                  .format("YYYY-MM-DD"),
                end: moment(fieldData["value"]["end"])
                  .local()
                  .format("YYYY-MM-DD"),
              };
            } else {
              value = {
                start: fieldData["value"]["start"],
                end: fieldData["value"]["end"],
              };
            }
          } else {
            if (
              columnMeta["data_type"] === "date" ||
              columnMeta["data_type"] === "datetime"
            ) {
              value = moment(fieldData["value"]).local().format("YYYY-MM-DD");
            } else {
              value = fieldData["value"];
            }
          }

          let filterItem = {
            filter: {
              [columnMeta["field"]]: {
                value: value,
                filter_type: columnMeta["filter_type"],
                data_type: columnMeta["data_type"],
              },
            },
            operator: {
              label: operatorLabelMap[fieldData["op"]],
              value: fieldData["op"],
            },
            column_id: columnMeta["id"],
          };

          selectedFilterList.push(filterItem);
        }
      }
    });

    return selectedFilterList;
  };

  const isFieldDisabled = () => {
    if (scope === "edit") {
      return editableData["tab"]["is_editable"] === false;
    } else {
      return false;
    }
  };

  const resetChildOfParent = ({ parentFieldMeta, filterList }) => {
    for (let i = 0; i < filterList.length; i++) {
      let filterData = filterList[i];

      let index = columnsList.findIndex(
        (column) => column["id"] === filterData["column_id"]
      );
      if (index !== -1) {
        let columnMeta = columnsList[index];
        if (
          columnMeta["child_of"] !== null &&
          columnMeta["child_of"]["id"] === parentFieldMeta["id"]
        ) {
          if (
            filterData["filter"]["value"] !==
            filter[parentFieldMeta["field"]]["value"]
          ) {
            filterList = filterList.filter(
              (obj) => obj["column_id"] !== columnMeta["id"]
            );
          }
        }
      }
    }
    return filterList;
  };

  return !loading ? (
    <>
      <Box role="presentation">
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: 500,
            right: 0,
            bgcolor: "#f9f9fc",
          }}
        >
          <Toolbar>
            {scope === "edit" ? (
              <ModelHeader>Edit Details</ModelHeader>
            ) : (
              <ModelHeader>Create View</ModelHeader>
            )}
          </Toolbar>
        </AppBar>
        {!isSubmitSuccess ? (
          <ModelWrapper component={"form"} onSubmit={handleSubmit}>
            <InputContainer>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>Name*</Label>
                    <OutlinedInput
                      style={{ height: "30px" }}
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      onChange={(e) => {
                        setTabName(e.target.value);
                      }}
                      name="name"
                      required
                      value={tabName}
                      placeholder="Enter value"
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <Label>Select Groups*</Label>
                  <Box
                    sx={{
                      "&": {
                        width: "100%",
                        fontSize: "12px",
                        border: "none",
                        alignItems: "center",
                        color: "black",
                      },
                      "& .dropdown-heading": {
                        height: "30px",
                      },
                      "& .options": {
                        maxHeight: "180px",
                      },
                    }}
                  >
                    <MultiSelect
                      options={groupList}
                      value={selectedGroupList}
                      onChange={(e) => {
                        setSelectedGroupList(e);
                      }}
                    />
                  </Box>
                </Grid>
                {scope !== "edit" && (
                  <Grid item xs={6}>
                    <Label>Select Columns*</Label>
                    <Box
                      sx={{
                        "&": {
                          width: "100%",
                          fontSize: "12px",
                          border: "none",
                          alignItems: "center",
                          color: "black",
                        },
                        "& .dropdown-heading": {
                          height: "30px",
                        },
                        "& .options": {
                          maxHeight: "180px",
                        },
                      }}
                    >
                      <MultiSelect
                        disabled={isFieldDisabled()}
                        options={getSelectColumnOptions()}
                        value={selectedColumns}
                        onChange={(e) => {
                          setSelectedColumns(e);
                        }}
                      />
                    </Box>
                  </Grid>
                )}

                <Grid item xs={scope === "edit" ? 12 : 6}>
                  <Label>Select Roles*</Label>
                  <Box
                    sx={{
                      "&": {
                        width: "100%",
                        fontSize: "12px",
                        border: "none",
                        alignItems: "center",
                        color: "black",
                      },
                      "& .dropdown-heading": {
                        height: "30px",
                      },
                      "& .options": {
                        maxHeight: "180px",
                      },
                    }}
                  >
                    <MultiSelect
                      disabled={isFieldDisabled()}
                      options={smartViewRoleList}
                      value={selectedRoles}
                      onChange={(e) => {
                        setSelectedRoles(e);
                      }}
                    />
                  </Box>
                </Grid>
                {scope === "edit" && (
                  <Grid item xs={12}>
                    <InputWrapper>
                      <FormControlLabel
                        checked={canIncludeClosedLeads}
                        control={
                          <Checkbox
                            size="medium"
                            onChange={(event) => {
                              setCanIncludeClosedLeads(event.target.checked);
                            }}
                          />
                        }
                        label={
                          <Detail style={{ fontWeight: "500" }}>
                            Include Closed Leads
                          </Detail>
                        }
                      />
                    </InputWrapper>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Box
                    component={"fieldset"}
                    sx={{ border: "1px solid #cccccc", padding: "12px" }}
                  >
                    <legend
                      style={{
                        width: "auto",
                        marginBottom: "0px",
                        fontSize: "12px",
                      }}
                    >
                      Filters*
                    </legend>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <InputWrapper>
                          <Label>Select Column</Label>
                          <Select
                            isDisabled={
                              isFieldDisabled() || selectedFilterList.length > 5
                            }
                            options={getSelectFilterColumnOptions()}
                            value={multiSelectFilterColumnValue}
                            onChange={async (selectedOption) => {
                              if (selectedOption !== null) {
                                let index = columnsList.findIndex(
                                  (column) =>
                                    column["id"] === selectedOption.value
                                );
                                if (index !== -1) {
                                  let columnMeta = columnsList[index];
                                  if (columnMeta["filter_type"] === "list") {
                                    let filterTypeListMeta =
                                      await getListDetailsToRender(
                                        columnMeta["filter_input"]
                                      );

                                    setFilterTypeListMeta(filterTypeListMeta);
                                  }
                                  let tempList = getOperatorList(columnMeta);
                                  setFilter({});
                                  setSelectedOperator(null);
                                  setOperatorList(tempList);
                                  setMultiSelectFilterColumnValue(
                                    selectedOption
                                  );
                                  setSelectedFilterColumn(columnMeta);
                                }
                              } else {
                                setFilterTypeListMeta({});
                                setOperatorList([]);
                                setSelectedOperator(null);
                                setSelectedFilterColumn(null);
                                setMultiSelectFilterColumnValue(null);
                                setFilter({});
                              }
                            }}
                            closeMenuOnSelect={true}
                            styles={customStyles}
                            isClearable
                          />
                        </InputWrapper>
                      </Grid>
                      <Grid item xs={6}>
                        <InputWrapper>
                          <Label>Select Operator</Label>
                          <Select
                            isDisabled={
                              operatorList.length === 0 ||
                              multiSelectFilterColumnValue === null ||
                              isFieldDisabled() ||
                              selectedFilterList.length > 5
                            }
                            options={operatorList}
                            value={selectedOperator}
                            onChange={(selectedOption) => {
                              setSelectedOperator(selectedOption);
                            }}
                            closeMenuOnSelect={true}
                            styles={customStyles}
                            isClearable
                          />
                        </InputWrapper>
                      </Grid>
                      <Grid item xs={12}>
                        <InputWrapper>
                          <Label>Filter Value</Label>
                          {multiSelectFilterColumnValue === null ? (
                            <OutlinedInput
                              disabled
                              placeholder="Enter Value"
                              style={{
                                width: "100%",
                                height: "30px",
                                fontSize: "12px",
                              }}
                            />
                          ) : (
                            <CreateViewTabFilterValueFiledComponent
                              filter={filter}
                              setFilter={setFilter}
                              filterTypeListMeta={filterTypeListMeta}
                              selectedColumn={selectedFilterColumn}
                              selectedFilterList={selectedFilterList}
                            />
                          )}
                        </InputWrapper>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          disabled={!isAddButtonEnabled() || isFieldDisabled()}
                          onClick={handleAdd}
                          variant="contained"
                          color="primary"
                          style={{ textTransform: "capitalize", width: "100%" }}
                        >
                          <Add /> Add Filter
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  {selectedFilterList.length > 0 && (
                    <FilterListContainer>
                      {selectedFilterList.map((item, i) => {
                        let index = columnsList.findIndex(
                          (column) => column["id"] === item["column_id"]
                        );
                        if (index !== -1) {
                          let columnMeta = columnsList[index];

                          if (columnMeta["filter_type"] === "list") {
                            let value =
                              item["filter"][columnMeta["field"]]["value"];
                            return (
                              <CustomChip
                                onClick={() => {
                                  if (!isFieldDisabled()) {
                                    handleEdit(item);
                                  }
                                }}
                                key={i}
                                style={{ flexDirection: "column" }}
                              >
                                {!isFieldDisabled() && (
                                  <Cancel
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemove(item["column_id"]);
                                    }}
                                    style={{
                                      position: "absolute",
                                      top: "2px",
                                      right: "2px",
                                      cursor: "pointer",
                                    }}
                                  />
                                )}
                                <ChipLable>{`${columnMeta["headerName"]} ${item["operator"]["label"]}`}</ChipLable>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {value.map((list_item, i) => {
                                    return (
                                      <Box
                                        sx={{ display: "flex", gap: "4px" }}
                                        key={i}
                                      >
                                        <Circle
                                          style={{
                                            width: "8px",
                                            height: "8px",
                                            color: "gray",
                                            marginTop: "2px",
                                          }}
                                        />
                                        <ChipValue>
                                          {list_item["label"]}
                                        </ChipValue>
                                      </Box>
                                    );
                                  })}
                                </Box>
                              </CustomChip>
                            );
                          } else if (columnMeta["filter_type"] === "range") {
                            return (
                              <>
                                <CustomChip
                                  onClick={() => {
                                    if (!isFieldDisabled()) {
                                      handleEdit(item);
                                    }
                                  }}
                                >
                                  {!isFieldDisabled() && (
                                    <Cancel
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemove(item["column_id"]);
                                      }}
                                      style={{
                                        position: "absolute",
                                        top: "2px",
                                        right: "2px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  )}
                                  <ChipLable>{`${columnMeta["headerName"]} ${item["operator"]["label"]}`}</ChipLable>
                                  <ChipValue>{`${
                                    item["filter"][columnMeta["field"]][
                                      "value"
                                    ]["start"]
                                  } to ${
                                    item["filter"][columnMeta["field"]][
                                      "value"
                                    ]["end"]
                                  }`}</ChipValue>
                                </CustomChip>
                              </>
                            );
                          } else {
                            return (
                              <>
                                <CustomChip
                                  onClick={() => {
                                    if (!isFieldDisabled()) {
                                      handleEdit(item);
                                    }
                                  }}
                                >
                                  {!isFieldDisabled() && (
                                    <Cancel
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemove(item["column_id"]);
                                      }}
                                      style={{
                                        position: "absolute",
                                        top: "2px",
                                        right: "2px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  )}
                                  <ChipLable>{`${columnMeta["headerName"]} ${item["operator"]["label"]}`}</ChipLable>
                                  <ChipValue>
                                    {
                                      item["filter"][columnMeta["field"]][
                                        "value"
                                      ]
                                    }
                                  </ChipValue>
                                </CustomChip>
                              </>
                            );
                          }
                        }
                      })}
                    </FilterListContainer>
                  )}
                </Grid>
              </Grid>
            </InputContainer>

            <AppBar
              position="fixed"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: 500 }}
            >
              <Toolbar style={{ width: 500, position: "relative" }}>
                {isSubmitFail && (
                  <ErrorAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setIsSubmitFail(false);
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {errorMessage}
                  </ErrorAlert>
                )}
                <ButtonWrapper>
                  <Button
                    onClick={handleClose}
                    type="button"
                    variant="contained"
                    color="default"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={
                      selectedGroupList.length === 0 ||
                      selectedRoles.length === 0 ||
                      selectedColumns.length === 0
                    }
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    Submit
                  </Button>
                </ButtonWrapper>
              </Toolbar>
            </AppBar>
          </ModelWrapper>
        ) : (
          <MessageWrapper>
            {scope === "edit" ? (
              <Message>View updated successfully!</Message>
            ) : (
              <Message>View created successfully!</Message>
            )}
            <Button
              onClick={handleClose}
              type="button"
              variant="contained"
              color="default"
              style={{
                marginTop: "20px",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              Close
            </Button>
          </MessageWrapper>
        )}
      </Box>
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: 500,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default observer(CreateViewTabSidepanel);
