import React, { useEffect, useState } from "react";
import { styled as MuiStyled } from "@mui/material/styles";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";
import {
  IsoToLocalDate,
  consoleLogger,
  dataRenderer,
  getLocalizedText,
} from "../../Functions";
import { getCampaignDetailsApi } from "../../Api";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";
import { AutoDialerCampaignColumnHeaders } from "../../Db";
import { DataGrid } from "@mui/x-data-grid";
import analytics from "../../Analytics";
import WebFormCardWidget from "./webforms_card_widget/WebFormCardWidget";

const ModelWrapper = MuiStyled(Box)(({ theme }) => ({
  width: "500px",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  paddingTop: "0px",
}));
const HeaderWrapper = MuiStyled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  padding: "30px 0px 30px 30px",
  marginBottom: "20px",
  backgroundColor: "#f9f9fc",
}));

const ModelHeader = MuiStyled(Typography)(({ theme }) => ({
  font: "normal normal 600 18px Open Sans",
  variant: "span",
  color: "#4d4e4f",
}));

const Column = MuiStyled(Box)(({ theme }) => ({
  display: "flex",
  padding: "0px 30px 30px 30px",
}));

const CardWidgetWrapper = MuiStyled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  rowGap: "16px",
  padding: "0px 4px",
  marginBottom: "20px",
}));
const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  row-gap: 20px;
  column-gap: 20px;
  max-height: 80vh;
  overflow-y: auto;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
`;
const InputWrapperLg = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled.span`
  font: normal normal 500 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Detail = styled.span`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const CampaignInfoPanel = (props) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    analytics.triggerEvent(
      4625000252,
      "campaign_details_form_load",
      "campaigns_page",
      "",
      {}
    );
    const getData = async () => {
      let response = await getCampaignDetailsApi(props.id);
      consoleLogger(response);
      setData(response);
      setLoading(false);
    };
    getData();
  }, []);
  return (
    <>
      <Box role="presentation">
        <ModelWrapper>
          <HeaderWrapper>
            <Info color="primary" style={{ marginRight: "8px" }} />
            <ModelHeader>{getLocalizedText("info")}</ModelHeader>
          </HeaderWrapper>
          <Column>
            {!loading ? (
              <InputContainer>
                <InputWrapper>
                  <Label>{getLocalizedText("title")}</Label>
                  <Detail>
                    {data.hasOwnProperty("title")
                      ? dataRenderer(data.title)
                      : "-"}
                  </Detail>
                </InputWrapper>
                <InputWrapperLg>
                  <Label>{getLocalizedText("description")}</Label>
                  <Detail>
                    {data.hasOwnProperty("desc")
                      ? dataRenderer(data.desc)
                      : "-"}
                  </Detail>
                </InputWrapperLg>
                <InputWrapper>
                  <Label>{getLocalizedText("campaign_type")}</Label>
                  <Detail>
                    {data.hasOwnProperty("campaign_type")
                      ? dataRenderer(data.campaign_type)
                      : "-"}
                  </Detail>
                </InputWrapper>
                <InputWrapper>
                  <Label>{getLocalizedText("campaign_provider")}</Label>
                  <Detail>
                    {data.hasOwnProperty("campaign_provider") &&
                    data["campaign_provider"] !== null
                      ? dataRenderer(
                          data.campaign_provider["campaign_provider_name"]
                        )
                      : "-"}
                  </Detail>
                </InputWrapper>
                <InputWrapper>
                  <Label>{getLocalizedText("created_at")}</Label>
                  <Detail>
                    {data.hasOwnProperty("created_at") &&
                    data["created_at"] !== null
                      ? IsoToLocalDate(data.created_at)
                      : "-"}
                  </Detail>
                </InputWrapper>
                <InputWrapper>
                  <Label>{getLocalizedText("created_by")}</Label>
                  <Detail>
                    {data.hasOwnProperty("created_by") &&
                    data["created_by"] !== null
                      ? dataRenderer(data.created_by.name)
                      : "-"}
                  </Detail>
                </InputWrapper>
                <InputWrapper>
                  <Label>{getLocalizedText("started_at")}</Label>
                  <Detail>
                    {data.hasOwnProperty("started_at") &&
                    data["started_at"] !== null
                      ? IsoToLocalDate(data.started_at)
                      : "-"}
                  </Detail>
                </InputWrapper>
                <InputWrapper>
                  <Label>{getLocalizedText("started_by")}</Label>
                  <Detail>
                    {data.hasOwnProperty("started_by") &&
                    data["started_by"] !== null
                      ? dataRenderer(data.started_by.name)
                      : "-"}
                  </Detail>
                </InputWrapper>
                <InputWrapper>
                  <Label>{getLocalizedText("completed_at")}</Label>
                  <Detail>
                    {data.hasOwnProperty("completed_at") &&
                    data["completed_at"] !== null
                      ? IsoToLocalDate(data.completed_at)
                      : "-"}
                  </Detail>
                </InputWrapper>
                <InputWrapper>
                  <Label>{getLocalizedText("status")}</Label>
                  <Detail>
                    {data.hasOwnProperty("status")
                      ? dataRenderer(data.status)
                      : "-"}
                  </Detail>
                </InputWrapper>
                <InputWrapper>
                  <Label>{getLocalizedText("segment")}</Label>
                  <Detail>
                    {data.hasOwnProperty("segment") && data["segment"] !== null
                      ? dataRenderer(data.segment.title)
                      : "-"}
                  </Detail>
                </InputWrapper>
                {data.hasOwnProperty("input") &&
                  data["input"].hasOwnProperty("agent_list") && (
                    <InputWrapper
                      style={{
                        width: "100%",
                      }}
                    >
                      <Label>{getLocalizedText("agents")}</Label>
                      <DataGrid
                        autoHeight={true}
                        rows={data["input"]["agent_list"]}
                        columns={AutoDialerCampaignColumnHeaders}
                        checkboxSelection={false}
                        style={{ cursor: "pointer" }}
                        hideFooter={true}
                        disableColumnMenu={true}
                      />
                    </InputWrapper>
                  )}
                {data.hasOwnProperty("web_forms") &&
                  data["web_forms"] !== null &&
                  data["web_forms"].length > 0 && (
                    <InputWrapper style={{ width: "100%" }}>
                      <ModelHeader style={{ marginBottom: "20px" }}>
                        Web Forms
                      </ModelHeader>
                      {data["web_forms"].map((item, i) => {
                        return (
                          <CardWidgetWrapper>
                            <WebFormCardWidget data={item} key={i} />
                          </CardWidgetWrapper>
                        );
                      })}
                    </InputWrapper>
                  )}
              </InputContainer>
            ) : (
              <InputContainer
                style={{
                  justifyContent: "center",
                  height: "100%",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </InputContainer>
            )}
          </Column>
        </ModelWrapper>
      </Box>
    </>
  );
};

export default CampaignInfoPanel;
