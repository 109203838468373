import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  AppBar,
  Box,
  Chip,
  Snackbar,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { CircularProgress, Grid } from "@material-ui/core";
import { getWebFormDataApi } from "../../Api";
import { IsoToLocalDate, dataRenderer } from "../../Functions";
import { CopyAll } from "@mui/icons-material";

const ModelWrapper = styled(Box)`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 24px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 8px;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;
const LinkText = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #3f51b5;
  cursor: pointer;
`;

const QrCode = styled("img")`
  width: 25%;
  height: auto;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const IconTextWrapper = styled(Box)`
  display: flex;
  width: 100%;
  column-gap: 20px;
  align-items: center;
`;

const ViewWebformInfoSidepanel = (props) => {
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const getFormDetails = async () => {
    let response = await getWebFormDataApi(props.formID);
    return response;
  };
  const init = async () => {
    let response = await getFormDetails();

    setDetails(response);
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  const renderBoolValue = (value) => {
    if (value !== undefined && value !== null && value !== "") {
      if (value === false) {
        return <Chip color="warning" label={"No"} />;
      } else {
        return <Chip color="success" label={"Yes"} />;
      }
    }
    return "-";
  };

  const handleLink = (link_str) => {
    if (link_str !== null && link_str !== "") {
      window.open(link_str, "_blank");
    }
  };

  const renderLink = (str, n) => {
    if (str.length > n) {
      return (
        <Tooltip title={str}>
          <IconTextWrapper style={{ columnGap: "0px" }}>
            <LinkText onClick={() => handleLink(str)}>
              {str.slice(0, n - 1) + "..."}
            </LinkText>
            <CopyAll
              color="primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(str);
                setOpenSnackBar(true);
              }}
            />
          </IconTextWrapper>
        </Tooltip>
      );
    } else {
      return <LinkText onClick={() => handleLink(str)}>{str}</LinkText>;
    }
  };

  return !loading ? (
    <Box sx={{ width: 500 }}>
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          width: 500,
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar>
          <ModelHeader>Webform Details</ModelHeader>
        </Toolbar>
      </AppBar>
      <ModelWrapper>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Name</Label>
              <Detail>{dataRenderer(details.name)}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Form Type</Label>
              <Detail>{dataRenderer(details.form_type)}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Full Link</Label>
              {details.hasOwnProperty("full_link") &&
              details["full_link"] !== null &&
              details["full_link"] !== "" ? (
                renderLink(details["full_link"], 20)
              ) : (
                <Detail>-</Detail>
              )}
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Short Link</Label>

              <LinkText onClick={() => handleLink(details.short_link)}>
                {dataRenderer(details.short_link)}
              </LinkText>
            </InputWrapper>
          </Grid>

          <Grid item xs={6}>
            <InputWrapper>
              <Label>External</Label>
              <Detail>{renderBoolValue(details.external)}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Published</Label>
              <Detail>{renderBoolValue(details.published)}</Detail>
            </InputWrapper>
          </Grid>

          <Grid item xs={6}>
            <InputWrapper>
              <Label>Created At</Label>
              <Detail>
                {details.hasOwnProperty("created_at") &&
                details.created_at !== null &&
                details.created_at !== ""
                  ? IsoToLocalDate(details.created_at)
                  : "-"}
              </Detail>
            </InputWrapper>
          </Grid>

          <Grid item xs={6}>
            <InputWrapper>
              <Label>Created By</Label>
              <Detail>
                {details.hasOwnProperty("created_by") &&
                details["created_by"] !== null
                  ? dataRenderer(details["created_by"]["name"])
                  : "-"}
              </Detail>
            </InputWrapper>
          </Grid>

          <Grid item xs={12}>
            <InputWrapper>
              <Label>Description</Label>
              <Detail
                style={{
                  whiteSpace: "pre-line",
                }}
              >
                {dataRenderer(details["desc"])}
              </Detail>
            </InputWrapper>
          </Grid>
          {details.hasOwnProperty("qr_code") &&
            details["qr_code"] !== null &&
            details["qr_code"] !== "" && (
              <Grid item xs={12}>
                <InputWrapper>
                  <Label>QR Code</Label>
                  <QrCode
                    src={`data:image/svg+xml;utf8,${encodeURIComponent(
                      details["qr_code"]
                    )}`}
                    alt="QR Code"
                  />
                </InputWrapper>
              </Grid>
            )}
        </Grid>
      </ModelWrapper>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => {
          setOpenSnackBar(false);
        }}
        message="Copied to clipboard!"
      />
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 500,
        height: "100%",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default ViewWebformInfoSidepanel;
