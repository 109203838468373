import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Modal,
  Select,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Close } from "@material-ui/icons";
import rootStore from "../../stores/RootStore";
import { getMembersApi, leadAssignApi } from "../../Api";
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 50px;
`;
const ModelWrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px;
`;
const Title = styled.span`
  font: normal normal 600 24px Open Sans;
  text-align: center;
`;

const Input = styled.input`
  width: 350px;
  height: 50px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #e0deca;
  font: normal normal 600 16px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal normal 16px Open Sans;
    color: #b5b5b5;
    text-transform: lowercase;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const TextArea = styled.textarea`
  width: 350px;
  height: 100px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #e0deca;
  font: normal normal 600 16px Open Sans;
  resize: none;
  font: normal normal 600 16px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal normal 16px Open Sans;
    color: #b5b5b5;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const MessageTemplateModal = (props) => {
  const handleClose = () => {
    props.setOpen(false);
  };
  const handleClick = () => {
    props.setOpen(false);
  };
  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <Close
              onClick={handleClick}
              style={{
                height: "18px",
                width: "18px",
                position: "absolute",
                top: "20px",
                right: "30px",
                cursor: "pointer",
              }}
            />
            <ModelWrapper>
              <Title>Add Message Template</Title>

              <InputWrapper>
                <InputLabel>Name</InputLabel>
                <Input
                  type="text"
                  name="name"
                  placeholder="enter name"
                  //   onChange={(e) => {
                  //     let dataType = item.data_type;
                  //     handleChange({ e, dataType });
                  //   }}
                />
              </InputWrapper>
              <InputWrapper>
                <InputLabel>Message</InputLabel>
                <TextArea
                  required
                  type="text"
                  placeholder="enter note"
                  name="notes"
                  //   onChange={handleChange}
                />
              </InputWrapper>
              <Button
                variant="contained"
                color="primary"
                id="assign-btn"
                // onClick={handleAssign}
              >
                Add
              </Button>
            </ModelWrapper>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default MessageTemplateModal;
