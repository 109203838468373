import { Box, Button, Grid, Modal } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { getLocalizedText } from "../../Functions";
import { TextField, Typography } from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  StaticDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  width: 352px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 16px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
  margin-bottom: 16px;
`;
const Form = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const ButtonWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  margin-top: 30px;
`;
const CustomizedDatePicker = styled(StaticDatePicker)`
  & .MuiInputBase-input {
    font-size: 12px;
    padding: 7.5px 14px;
  }
`;

const DatePickerDialog = ({ open, setOpen, handleSet, editableDate }) => {
  const [date, setDate] = useState(null);
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    handleSet(date);
  };

  useEffect(() => {
    setDate(editableDate);
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <Container>
              <Header>Pick Date</Header>
              <Form component={"form"} onSubmit={handleFormSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box
                          sx={{
                            border: "1px solid #DDE2EB",
                            "& .MuiDialogActions-root": {
                              display: "none",
                            },
                          }}
                        >
                          <CustomizedDatePicker
                            value={date}
                            onChange={(value) => {
                              setDate(value);
                            }}
                            maxDate={moment()}
                            inputFormat="YYYY-MM-DD"
                            renderInput={(params) => (
                              <TextField
                                required
                                sx={{ width: "100%" }}
                                size="small"
                                {...params}
                              />
                            )}
                          />
                        </Box>
                      </LocalizationProvider>
                    </InputWrapper>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ButtonWrapper>
                      <Button
                        onClick={() => {
                          setOpen(false);
                        }}
                        variant="contained"
                        color="default"
                        style={{
                          fontWeight: "bold",

                          fontSize: "12px",
                          marginRight: "10px",
                          textTransform: "none",
                        }}
                      >
                        {getLocalizedText("cancel")}
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color={"primary"}
                        style={{
                          fontSize: "12px",

                          fontWeight: "bold",
                          width: "fit-content",
                          textTransform: "none",
                        }}
                      >
                        Set
                      </Button>
                    </ButtonWrapper>
                  </Grid>
                </Grid>
              </Form>
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default DatePickerDialog;
