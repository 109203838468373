import { Badge } from "@material-ui/core";
import React, { useEffect } from "react";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { Notifications } from "@material-ui/icons";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import NotificationComponent from "./NotificationComponent";
import { isUnderWorkSchedule } from "../../Functions";
const NotificationIcon = ({ setIsInWorkHours, disabled }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {}, [
    rootStore.notificationState.notificationList,
    rootStore.notificationState.notificationState.canShowBadge,
  ]);
  const handleNotification = (event) => {
    rootStore.notificationState.updateNotificationBadgeState(false);
    rootStore.notificationState.checkAndFetchNotifications();
    setAnchorEl(event.currentTarget);
  };
  const NotificationIconBadge = () => {
    if (rootStore.notificationState.canShowNotificationBadge()) {
      return (
        <Badge
          badgeContent={
            rootStore.notificationState.notificationList["items_count"]
          }
          color="error"
          invisible={!rootStore.notificationState.canShowNotificationBadge()}
        >
          <Notifications color="action" />
        </Badge>
      );
    } else {
      return <Notifications color="action" />;
    }
  };
  return (
    <>
      <IconButton
        disabled={disabled}
        onClick={(e) =>
          isUnderWorkSchedule() ? handleNotification(e) : setIsInWorkHours(true)
        }
        aria-controls={openMenu ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? "true" : undefined}
      >
        <NotificationIconBadge disabled={disabled}/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openMenu}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            bgcolor: "#f9f9fc",
            borderRadius: "0px",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <NotificationComponent setAnchorEl={setAnchorEl} />
      </Menu>
    </>
  );
};
export default observer(NotificationIcon);
