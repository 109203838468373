import React, { useCallback } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart,
  ArcElement,
  LinearScale,
  BarController,
  BarElement,
  Tooltip,
  Legend,
  CategoryScale,
} from "chart.js";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import { useState } from "react";
import rootStore from "../../../stores/RootStore";
import { observer } from "mobx-react-lite";
import {
  CircularProgress,
  IconButton,
  Typography,
  Divider,
} from "@material-ui/core";
import { Box, Menu, MenuItem } from "@mui/material";
import {
  getStageColorCode,
  getStatusColorCode,
  getCombinedWidgetAndAccountID,
  getLocalDateTime,
} from "../../../Functions";
import { Fullscreen, Refresh } from "@material-ui/icons";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FullScreenModal from "../../full_screen_modal/FullScreenModal";
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.register(
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels
);
const ITEM_HEIGHT = 48;

const Container = styled(Box)`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  border: 1px solid #eceef2;
`;

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
`;
const Text = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
  text-align: left;
  color: #4d4e4f;
`;
const Text2 = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  margin: 0px;
  color: #bbbab6;
`;
const OneGroupBarGraphWidget = (props) => {
  const { userStore } = rootStore;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [openFullScreenDialog, setOpenFullScreenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const Open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const generateRandomColor = (val) => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    userStore.updateColorsList({ key: val, color: color });
    return color;
  };

  const updateData = (response, v2Response) => {
    let aggregateBy = v2Response["xdata"].hasOwnProperty(
      "aggregate_by_col_names"
    )
      ? v2Response["xdata"]["aggregate_by_col_names"]
      : "count"; //here if xdata has the aggregate_by_col_names then it will use that else by default it will use "count"
    let newObj = {
      labels: [],
      datasets: [{ label: aggregateBy, data: [], backgroundColor: [] }],
    };
    if (response.length > 0) {
      response.map((item) => {
        let keyname = v2Response["xdata"]["group_by"][0];
        newObj.labels.push(item[keyname]);
        newObj.datasets[0].data.push(item[aggregateBy]);
        if (keyname === "lead_stage") {
          let color = getStageColorCode(item[keyname]);
          newObj.datasets[0].backgroundColor.push(color);
        } else if (keyname === "lead_status") {
          let color = getStatusColorCode(item[keyname]);
          newObj.datasets[0].backgroundColor.push(color);
        } else {
          if (userStore.ColorsList.hasOwnProperty(item[keyname])) {
            let colorVal = userStore.ColorsList[item[keyname]];
            newObj.datasets[0].backgroundColor.push(colorVal);
          } else {
            let colorVal = generateRandomColor(item[keyname]);
            newObj.datasets[0].backgroundColor.push(colorVal);
          }
        }
      });
    } else {
      newObj = {};
    }
    return newObj;
  };

  const getCountLabelFontSize = () => {
    if (openFullScreenDialog === true) {
      return "14px";
    } else {
      if (rootStore.userStore.dashboardMode === "grid_three") {
        return "9px";
      } else if (rootStore.userStore.dashboardMode === "grid") {
        return "11px";
      } else {
        return "14px";
      }
    }
  };

  const ChartBottomSection = useCallback(() => {
    if (Object.keys(data).length === 0) {
      return (
        <Wrapper>
          <Typography>No data found!</Typography>
        </Wrapper>
      );
    } else {
      return (
        <Wrapper>
          <Bar
            data={data}
            options={{
              scales: {
                y: {
                  beginAtZero: true,
                  grace: "10%",
                },
              },
              maintainAspectRatio: false,
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                },
                datalabels: {
                  offset: -2,
                  anchor: "end",
                  align: "top",
                  color: "#A5A8AC",
                  font: {
                    size: getCountLabelFontSize(),
                  },
                  formatter: (value, context) =>
                    context.chart.data.datasets[context.datasetIndex].data[
                      context.dataIndex
                    ],
                },
              },
            }}
          />
        </Wrapper>
      );
    }
  }, [data, openFullScreenDialog]);

  const getData = () => {
    if (props.data !== null) {
      let modifiedData = updateData(props.data, props.widgetConfig);
      setData(modifiedData);
    }
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [props.widgetConfig.id, rootStore.userStore.dashboardMode]);
  return (
    <>
      {!loading ? (
        <Container>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "12px 20px",
              backgroundColor: "#F8F8F8",
            }}
          >
            <Text
              sx={{
                fontSize:
                  rootStore.userStore.dashboardMode === "grid_three"
                    ? "16px"
                    : "18px",
              }}
            >
              {props.widgetConfig.title}
            </Text>
            {(props.type === "slider" || props.type === "grid") && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  style={{ padding: "0px" }}
                  onClick={() => {
                    setOpenFullScreenDialog(true);
                  }}
                >
                  <Fullscreen />
                </IconButton>
                <IconButton
                  onClick={handleMenuClick}
                  aria-label="more"
                  id="long-button"
                  aria-controls={Open ? "long-menu" : undefined}
                  aria-expanded={Open ? "true" : undefined}
                  aria-haspopup="true"
                  style={{ padding: "0px" }}
                >
                  <MoreVertIcon />
                </IconButton>
              </Box>
            )}
          </Box>
          <Divider sx={{ backgroundColor: "#eceef2" }} />
          <ChartBottomSection />
          <Divider sx={{ backgroundColor: "#eceef2" }} />
          {(props.type === "slider" || props.type === "grid") && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "12px 20px",
              }}
            >
              {rootStore.userStore.widgetData.hasOwnProperty(
                getCombinedWidgetAndAccountID(props.widgetConfig.id)
              ) && (
                <Text2>
                  Updated at{" "}
                  {getLocalDateTime(
                    rootStore.userStore.widgetData[
                      getCombinedWidgetAndAccountID(props.widgetConfig.id)
                    ]["last_updated"]
                  )}
                </Text2>
              )}
              <IconButton
                onClick={() => {
                  props.handleRefresh();
                }}
                style={{ padding: "0px" }}
              >
                <Refresh />
              </IconButton>
            </Box>
          )}
        </Container>
      ) : (
        <Container style={{ alignItems: "center" }}>
          <CircularProgress />
        </Container>
      )}

      <Menu
        style={{ display: "flex", flexDirection: "column" }}
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={Open}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "10ch",
          },
        }}
      >
        <MenuItem
          style={{ fontSize: "12px" }}
          onClick={() => {
            props.handleRemove(props.widgetConfig);
            handleMenuClose();
          }}
          disabled={!props.isDashboardEditable()}
        >
          Remove
        </MenuItem>
      </Menu>
      {openFullScreenDialog && (
        <FullScreenModal
          open={openFullScreenDialog}
          setOpen={setOpenFullScreenDialog}
          myComponent={ChartBottomSection}
          title={props.widgetConfig.title}
        />
      )}
    </>
  );
};

export default observer(OneGroupBarGraphWidget);
