import {
  CircularProgress,
  Divider,
  InputLabel,
  Modal,
  Select,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  createLeadForProject,
  getAdminProjectApi,
  getColumnsForMapping,
  getMembersToAssignByProject,
} from "../../Api";
import { Box, Button, MenuItem, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { getLocalizedText } from "../../Functions";
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 30px;
`;
const ModelWrapper = styled(Box)`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 18px Open Sans;
`;
const Message = styled(Typography)`
  margin: 0px;
  font: normal normal 600 16px Open Sans;
  margin-bottom: 10px;
`;
const ResponseMessage = styled(Typography)`
  font: normal normal normal 16px Open Sans;
  color: #4d4e4f;
  margin: 0px;
  text-align: center;
`;
const SubHeader = styled(Typography)`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
  color: #5a5a5a;
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 20px;
  margin-bottom: 30px;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 20px;
`;

const CreateLeadFromCustomerModal = (props) => {
  const [project, setProject] = useState("");
  const [memberList, setMemberList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [member, setMember] = useState("");
  const [loading, setLoading] = useState(false);
  const [creatingLead, setCreatingLead] = useState(false);
  const [message, setMessage] = useState("");

  const [isSubmit, setIsSubmit] = useState(false);
  const handleClose = () => {
    props.setOpen(false);
  };
  const createLead = async () => {
    setCreatingLead(true);
    let projectColumns = await getColumnsForMapping(project); // getAllColsApi
    let flag = hasReqNonContactFields(projectColumns);
    if (flag) {
      setMessage("Some error occurred!\nError code:1019");
    } else {
      let regularData = {
        lead_owner_id: member,
      };
      let customData = {};
      let contactColumns = projectColumns.filter(
        (column) =>
          column["contact_column"] !== null && column["contact_column"] !== ""
      );
      for (let i = 0; i < contactColumns.length; i++) {
        let column = contactColumns[i];
        let contactField = column["contact_column"];
        let projectField = column["field"];
        let value = "";

        if (column["is_custom_param"]) {
          let field_name = contactField.replace("custom_params_", "");

          let customerCustomData = props.customerData.hasOwnProperty(
            "custom_params"
          )
            ? JSON.parse(props.customerData["custom_params"])
            : {};
          value = customerCustomData[field_name] || null;
          if (value !== null) {
            let field_name = projectField.replace("custom_params_", "");
            customData[field_name] = value;
          }
        } else {
          value = props.customerData[contactField] || null;
          if (value !== null) {
            regularData[projectField] = value;
          }
        }
      }
      let createLeadPayload = {
        contact_id: props.customerData["id"],
        lead_data: {
          ...regularData,
          custom_params: customData,
        },
      };
      let response = await createLeadForProject({
        pid: project,
        data: createLeadPayload,
      });

      if (response.hasError()) {
        setMessage(getLocalizedText(response.errorMessage));
      } else {
        setMessage("Lead created succesfully in the project");
      }
    }
    setCreatingLead(false);
    setIsSubmit(true);
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    createLead();
  };
  const getProjectMemberList = async (id) => {
    try {
      let response = await getMembersToAssignByProject(id);
      setMemberList(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getProjects = async () => {
    try {
      let response = await getAdminProjectApi();
      let filteredProjects = response.data.filter((e) => e.id !== props.pid);
      setProjectList(filteredProjects);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleProject = (event) => {
    setMemberList([]);
    getProjectMemberList(event.target.value);
    setMember("");
    setProject(event.target.value);
  };

  //below function is to check if all the required field of the selected project are mapped or not
  const hasReqNonContactFields = (projectColumnList) => {
    let required_columns_list = projectColumnList.filter(
      (column) => column.required
    );
    for (let column of required_columns_list) {
      if (
        column["contact_column"] === null ||
        column["contact_column"] === ""
      ) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    setLoading(true);
    getProjects();
  }, []);
  return (
    <>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!isSubmit ? (
          <ModelContainer>
            {creatingLead || loading ? (
              <Box
                style={{
                  height: "200px",
                  width: "400px",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {creatingLead && (
                  <Message
                    style={{
                      textAlign: "center",
                      fontSize: "16px",
                    }}
                  >
                    Creating Lead...
                  </Message>
                )}
                <CircularProgress />
              </Box>
            ) : (
              <Box>
                <ModelWrapper component={"form"} onSubmit={handleCreate}>
                  <>
                    <Header>{getLocalizedText("create_lead")}</Header>
                    <SubHeader>
                      {getLocalizedText("select_from_below_dropdown")}
                    </SubHeader>
                    <Divider style={{ margin: "10px 0px" }} />

                    <Row>
                      <InputWrapper>
                        <InputLabel
                          id="demo-simple-select-label"
                          style={{
                            font: "normal normal 600 10px Open Sans",
                            color: "#4d4e4f",
                          }}
                        >
                          {getLocalizedText("select_project")}*
                        </InputLabel>

                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={project}
                          label="Select Member"
                          required
                          onChange={handleProject}
                          style={{
                            width: "150px",
                            font: "normal normal 500 12px Open Sans",
                          }}
                        >
                          {projectList.length > 0 &&
                            projectList.map((e, i) => {
                              return (
                                <MenuItem
                                  key={i}
                                  style={{ fontSize: "12px" }}
                                  id={e.id}
                                  value={e.id}
                                >
                                  {e.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel
                          id="demo-simple-select-label"
                          style={{
                            font: "normal normal 600 10px Open Sans",
                            color: "#4d4e4f",
                          }}
                        >
                          {getLocalizedText("select_member")}*
                        </InputLabel>

                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={member}
                          required
                          label="Select Member"
                          onChange={(e) => {
                            setMember(e.target.value);
                          }}
                          style={{
                            width: "150px",
                            font: "normal normal 500 12px Open Sans",
                          }}
                        >
                          {memberList.length > 0 &&
                            memberList.map((e, i) => {
                              return (
                                <MenuItem
                                  key={i}
                                  style={{ fontSize: "12px" }}
                                  id={e.id}
                                  value={e.id}
                                >
                                  {e.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </InputWrapper>
                    </Row>
                  </>

                  <ButtonWrapper>
                    <Button
                      onClick={() => {
                        props.setOpen(false);
                      }}
                      variant="contained"
                      color="inherit"
                      style={{
                        width: "100%",
                        font: "normal normal 600 10px Open Sans",
                        marginRight: "10px",
                        textTransform: "none",
                      }}
                    >
                      {getLocalizedText("cancel")}
                    </Button>
                    <Button
                      type="submit"
                      id="assign-btn"
                      variant="contained"
                      style={{
                        font: "normal normal 600 10px Open Sans",
                        width: "100%",
                        textTransform: "none",
                      }}
                    >
                      {getLocalizedText("create")}
                    </Button>
                  </ButtonWrapper>
                </ModelWrapper>
              </Box>
            )}
          </ModelContainer>
        ) : (
          <ModelContainer sx={{ padding: "20px", width: "400px" }}>
            <Box
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MessageWrapper>
                <ResponseMessage>{message}</ResponseMessage>
                <ButtonWrapper>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="inherit"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100px",
                    }}
                  >
                    Close
                  </Button>
                </ButtonWrapper>
              </MessageWrapper>
            </Box>
          </ModelContainer>
        )}
      </Modal>
    </>
  );
};

export default observer(CreateLeadFromCustomerModal);
