import React, { useRef } from "react";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { Divider, Grid, IconButton } from "@material-ui/core";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import { Fullscreen, Refresh } from "@material-ui/icons";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import rootStore from "../../../stores/RootStore";
import placeholderImage from "../../../assets/gradient_bg.png";
import profilePlaceholder from "../../../assets/profile_placeholder.png";
import congratsImage from "../../../assets/congrats_image.png";

import frameImage from "../../../assets/profile_frame.png";
import {
  dataRenderer,
  getCombinedWidgetAndAccountID,
  getLocalDateTime,
} from "../../../Functions";
import TopPerformanceWidgetFullScreenModal from "../../full_screen_modal/TopPerformanceWidgetFullScreenModal";
const ITEM_HEIGHT = 48;

const Container = styled(Box)`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  border: 1px solid #eceef2;
`;

const ChartBottom = styled(Box)`
  width: 100%;
  height: 100%;
`;

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
const Text = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
  text-align: left;
  color: #4d4e4f;
`;
const Text2 = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  margin: 0px;
  color: #bbbab6;
`;
const PlaceHolderImage = styled(Box)`
  width: 100%;
  height: 100%;
  background-image: url(${placeholderImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ProfileImageWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  /* height: 70%; */
  position: relative;
  flex-direction: column;
  justify-content: center;
`;
const ProfileImage = styled("img")`
  aspect-ratio: 1/1;
  width: ${(e) => e.widthPercentage};
  height: auto;
  border-radius: 50%;
  object-fit: cover;
`;

const ImageFrame = styled("img")`
  width: ${(e) => e.widthPercentage};
  height: auto;
  position: absolute;
`;
const CongratsImage = styled("img")`
  width: auto;
  object-fit: contain;
`;
const DividerImage = styled("img")`
  height: auto;
  width: 100%;
  object-fit: contain;
  filter: invert(100%);
  /* margin-bottom: 8px; */
  opacity: 0.8;
`;
const TextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* height: 70%; */
  width: 100%;
  row-gap: 12px;
  text-align: center;
`;
const TextWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const TextLg = styled(Typography)`
  font-weight: 600;

  color: white;
`;
const TextSm = styled(Typography)`
  font-weight: 600;

  color: white;
`;

const TopPerformerWidget = (props) => {
  const componentRef = useRef(null);
  const [openFullScreenDialog, setOpenFullScreenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const Open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const getProfileImageUrl = () => {
    if (props.data !== null && props.data.hasOwnProperty("profile_pic")) {
      if (props.data["profile_pic"] !== null) {
        return props.data["profile_pic"];
      }
    }
    return profilePlaceholder;
  };

  const getTextFontSize = (variant) => {
    if (variant === "small") {
      if (props.type === "slideshow") {
        return "2.2vw";
      }
      if (rootStore.userStore.dashboardMode === "grid") {
        return "0.625vw";
      } else if (rootStore.userStore.dashboardMode === "grid_three") {
        return "0.521vw";
      } else {
        return "0.833vw";
      }
    } else if (variant === "large") {
      let screenWidth = window.screen.width;
      if (props.type === "slideshow") {
        return screenWidth > 1920 ? "4.2vw" : "3vw";
      }
      if (rootStore.userStore.dashboardMode === "grid") {
        return "1.354vw";
      } else if (rootStore.userStore.dashboardMode === "grid_three") {
        return "0.938vw";
      } else {
        return "1.771vw";
      }
    } else if (variant === "extra-large") {
      if (props.type === "slideshow") {
        return "4.2vw";
      }
      if (rootStore.userStore.dashboardMode === "grid") {
        return "1.354vw";
      } else if (rootStore.userStore.dashboardMode === "grid_three") {
        return "0.938vw";
      } else {
        return "1.771vw";
      }
    }
  };

  const getFrameWidthPercentage = () => {
    if (rootStore.userStore.dashboardMode === "grid") {
      return "50%";
    } else if (rootStore.userStore.dashboardMode === "grid_three") {
      return "60%";
    } else {
      return "40%";
    }
  };
  const getImageWidthPercentage = () => {
    if (rootStore.userStore.dashboardMode === "grid") {
      return "35%";
    } else if (rootStore.userStore.dashboardMode === "grid_three") {
      return "45%";
    } else {
      return "30%";
    }
  };
  const ChartBottomSection = () => {
    if (props.data !== null) {
      return (
        <Wrapper>
          <PlaceHolderImage>
            <TextLg
              style={{
                textAlign: "center",
                marginBottom: "20px",
                fontSize: getTextFontSize("extra-large"),
              }}
            >
              {props.widgetConfig.input["header"]}
            </TextLg>
            <Grid container style={{ height: "100%", alignItems: "center" }}>
              <Grid item xs={6}>
                <ProfileImageWrapper>
                  <ProfileImage
                    widthPercentage={getImageWidthPercentage()}
                    src={getProfileImageUrl()}
                    alt="loading..."
                  />
                  <ImageFrame
                    widthPercentage={getFrameWidthPercentage()}
                    src={frameImage}
                    alt="loading..."
                  />
                </ProfileImageWrapper>
              </Grid>
              <Grid item xs={6} alignItems="flex-start">
                <TextContainer>
                  <CongratsImage src={congratsImage} alt="loading..." />
                  <TextWrapper>
                    <TextLg
                      style={{
                        fontSize: getTextFontSize("large"),
                      }}
                    >
                      {props.data.name}
                    </TextLg>
                    <TextSm
                      style={{
                        fontSize: getTextFontSize("small"),
                      }}
                    >
                      {dataRenderer(props.data.designation)}
                    </TextSm>
                  </TextWrapper>
                  <TextWrapper>
                    <TextSm
                      style={{
                        fontSize: getTextFontSize("small"),
                      }}
                    >
                      {props.widgetConfig.input["message"]}
                    </TextSm>
                  </TextWrapper>
                </TextContainer>
              </Grid>
            </Grid>
          </PlaceHolderImage>
        </Wrapper>
      );
    } else {
      return (
        <Wrapper>
          <Typography>No data found!</Typography>
        </Wrapper>
      );
    }
  };

  useEffect(() => {}, [
    props.widgetConfig.id,
    rootStore.userStore.dashboardMode,
  ]);
  return (
    <>
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "12px 20px",
            backgroundColor: "#F8F8F8",
          }}
        >
          <Text
            sx={{
              fontSize:
                rootStore.userStore.dashboardMode === "grid_three"
                  ? "16px"
                  : "18px",
            }}
          >
            {props.widgetConfig.title}
          </Text>
          {(props.type === "slider" || props.type === "grid") && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                style={{ padding: "0px" }}
                onClick={() => {
                  setOpenFullScreenDialog(true);
                }}
              >
                <Fullscreen />
              </IconButton>
              <IconButton
                onClick={handleMenuClick}
                aria-label="more"
                id="long-button"
                aria-controls={Open ? "long-menu" : undefined}
                aria-expanded={Open ? "true" : undefined}
                aria-haspopup="true"
                style={{ padding: "0px" }}
              >
                <MoreVertIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        <Divider sx={{ backgroundColor: "#eceef2" }} />
        <ChartBottom ref={componentRef}>
          <ChartBottomSection />
        </ChartBottom>
        <Divider sx={{ backgroundColor: "#eceef2" }} />
        {(props.type === "slider" || props.type === "grid") && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "12px 20px",
            }}
          >
            {rootStore.userStore.widgetData.hasOwnProperty(
              getCombinedWidgetAndAccountID(props.widgetConfig.id)
            ) && (
              <Text2>
                Updated at{" "}
                {getLocalDateTime(
                  rootStore.userStore.widgetData[
                    getCombinedWidgetAndAccountID(props.widgetConfig.id)
                  ]["last_updated"]
                )}
              </Text2>
            )}
            <IconButton
              onClick={() => {
                props.handleRefresh();
              }}
              style={{ padding: "0px" }}
            >
              <Refresh />
            </IconButton>
          </Box>
        )}
      </Container>
      <Menu
        style={{ display: "flex", flexDirection: "column" }}
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={Open}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "10ch",
          },
        }}
      >
        <MenuItem
          style={{ fontSize: "12px" }}
          onClick={() => {
            props.handleRemove(props.widgetConfig);
            handleMenuClose();
          }}
          disabled={!props.isDashboardEditable()}
        >
          Remove
        </MenuItem>
      </Menu>
      {openFullScreenDialog && (
        <TopPerformanceWidgetFullScreenModal
          open={openFullScreenDialog}
          setOpen={setOpenFullScreenDialog}
          title={props.widgetConfig.title}
          data={props.data}
          imageURL={getProfileImageUrl()}
          widgetConfig={props.widgetConfig}
        />
      )}
    </>
  );
};

export default observer(TopPerformerWidget);
