import React, { useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect } from "react";
import { DatePicker } from "antd";
import "../../filter_modal/style.css";
import SlugMultiSelectFilter from "../../filter_page_components/filter_table/multi_select_filters/SlugMultiSelectFilter";
import NormalMultiSelectFilter from "../../filter_page_components/filter_table/multi_select_filters/NormalMultiSelectFilter";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
const Input = styled.input`
  width: 100%;
  height: 30px;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  color: #4d4e4f;
  font: normal normal 600 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal normal 12px Open Sans;
    color: #b5b5b5;
    text-transform: lowercase;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;

const Label = styled.span`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 200px;
`;

const FilterInputComponent = (props) => {
  const { RangePicker } = DatePicker;
  const [item, setItem] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [minValue, setMinValue] = useState(""); //this is for integer range filter input

  const handleChange = ({ e, dataType, filterType }) => {
    let value =
      dataType === "integer" ? parseInt(e.target.value) : e.target.value;
    props.setFilters({
      ...props.filters,
      [e.target.name]: { value: value, dataType: dataType, type: filterType },
    });
  };
  useEffect(() => {
    setStartDate("");
    if (props.item !== null) {
      setItem(props.item);
    }
  }, [props.item]);

  return (
    <>
      {(() => {
        if (item !== null) {
          if (item.filter_type === "normal") {
            if (item.data_type !== "boolean") {
              return (
                <InputWrapper>
                  <Input
                    type={
                      item.data_type === "email"
                        ? "email"
                        : item.data_type === "datetime" ||
                          item.data_type === "date"
                        ? "date"
                        : item.data_type === "integer"
                        ? "number"
                        : "text"
                    }
                    name={item.field}
                    value={
                      props.filters.hasOwnProperty(item.field)
                        ? props.filters[item.field]["value"]
                        : ""
                    }
                    placeholder={`enter ${item.headerName}`}
                    onChange={(e) => {
                      let dataType = item.data_type;
                      let filterType = item.filter_type;
                      handleChange({ e, dataType, filterType });
                    }}
                  />
                </InputWrapper>
              );
            } else {
              return (
                <InputWrapper
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    padding: "0px 4px",
                  }}
                >
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio size="small" />}
                      checked={
                        props.filters.hasOwnProperty(item.field)
                          ? props.filters[item.field]["value"] === true
                            ? true
                            : false
                          : false
                      }
                      label={<Label style={{ fontSize: "12px" }}>True</Label>}
                      onChange={() => {
                        props.setFilters({
                          ...props.filters,
                          [item.field]: {
                            value: true,
                            dataType: item.data_type,
                            type: item.filter_type,
                          },
                        });
                      }}
                    />
                    <FormControlLabel
                      value={false}
                      checked={
                        props.filters.hasOwnProperty(item.field)
                          ? props.filters[item.field]["value"] === false
                            ? true
                            : false
                          : false
                      }
                      onChange={() => {
                        props.setFilters({
                          ...props.filters,
                          [item.field]: {
                            value: false,
                            dataType: item.data_type,
                            type: item.filter_type,
                          },
                        });
                      }}
                      control={<Radio size="small" />}
                      label={<Label style={{ fontSize: "12px" }}>False</Label>}
                    />
                  </RadioGroup>
                </InputWrapper>
              );
            }
          } else if (item.filter_type === "range") {
            if (item.data_type === "datetime" || item.data_type === "date") {
              return (
                <InputWrapper
                  style={{ flexDirection: "row", columnGap: "20px" }}
                >
                  <Column>
                    <Input
                      type={"date"}
                      name={item.field}
                      onChange={(e) => {
                        setStartDate(e.target.value);
                      }}
                    />
                  </Column>
                  <Column>
                    <Input
                      type={"date"}
                      name={item.field}
                      min={startDate}
                      disabled={startDate === ""}
                      onChange={(e) => {
                        let dataType = item.data_type;
                        let filterType = item.filter_type;
                        let start_date = moment(startDate).format("YYYY-MM-DD");
                        let end_date = moment(e.target.value).format(
                          "YYYY-MM-DD"
                        );
                        props.setFilters({
                          ...props.filters,
                          [e.target.name]: {
                            value: { start: start_date, end: end_date },
                            dataType: dataType,
                            type: filterType,
                          },
                        });
                      }}
                    />
                  </Column>
                </InputWrapper>
              );
            } else if (item.data_type === "integer") {
              return (
                <InputWrapper
                  style={{ flexDirection: "row", columnGap: "20px" }}
                >
                  <Column>
                    <Input
                      min={0}
                      max={100}
                      placeholder="Min Value"
                      type={"number"}
                      name={item.field}
                      onChange={(e) => {
                        if (e.target.value === "") {
                          props.setFilters({});
                        }
                        setMinValue(e.target.value);
                      }}
                    />
                  </Column>
                  <Column>
                    <Input
                      type={"number"}
                      name={item.field}
                      placeholder="Max Value"
                      min={0}
                      max={100}
                      disabled={minValue === ""}
                      value={
                        props.filters.hasOwnProperty(item.field)
                          ? props.filters[item.field]["value"]["end"]
                          : ""
                      }
                      onChange={(e) => {
                        let dataType = item.data_type;
                        let filterType = item.filter_type;
                        if (e.target.value === "") {
                          props.setFilters({});
                        } else {
                          props.setFilters({
                            ...props.filters,
                            [e.target.name]: {
                              value: { start: minValue, end: e.target.value },
                              dataType: dataType,
                              type: filterType,
                            },
                          });
                        }
                      }}
                    />
                  </Column>
                </InputWrapper>
              );
            }
          } else if (item.filter_type === "list") {
            if (
              item.filter_input.charAt(0) === "/" ||
              item.filter_input.startsWith("ep:")
            ) {
              return (
                <SlugMultiSelectFilter
                  item={item}
                  filterType={item.filter_type}
                  dataType={item.data_type}
                  headerName={item.headerName}
                  setSelectedVal={props.setSelectedVal}
                  selectedVal={props.selectedVal}
                  field={item.field}
                  type={"global"}
                  setFilterList={props.setFilters}
                  filterList={props.filters}
                  selected={props.selected}
                  setSelected={props.setSelected}
                />
              );
            } else if (
              item.filter_input !== "" &&
              item.filter_input.charAt(0) !== "/"
            ) {
              return (
                <NormalMultiSelectFilter
                  filterType={item.filter_type}
                  dataType={item.data_type}
                  list={item.filter_input}
                  field={item.field}
                  headerName={item.headerName}
                  setSelectedVal={props.setSelectedVal}
                  selectedVal={props.selectedVal}
                  type={"global"}
                  setFilterList={props.setFilters}
                  filterList={props.filters}
                  selected={props.selected}
                  setSelected={props.setSelected}
                />
              );
            } else if (item.filter_input === "" || item.filter_input === null) {
              return (
                <InputWrapper>
                  <Input
                    type={
                      item.data_type === "email"
                        ? "email"
                        : item.data_type === "datetime" ||
                          item.data_type === "date"
                        ? "date"
                        : item.data_type === "integer"
                        ? "number"
                        : "text"
                    }
                    name={item.field}
                    placeholder={`enter ${item.headerName}`}
                    onChange={(e) => {
                      let dataType = item.data_type;
                      let filterType = item.filter_type;
                      handleChange({ e, dataType, filterType });
                    }}
                  />
                </InputWrapper>
              );
            }
          }
        }
      })()}
    </>
  );
};

export default observer(FilterInputComponent);
