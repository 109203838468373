import { Button, Divider, InputLabel, Modal } from "@material-ui/core";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, OutlinedInput, Typography } from "@mui/material";
import { getLocalizedText } from "../../Functions";

const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 30px;
`;
const ModelWrapper = styled(Box)`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
`;
const Header = styled(Typography)`
  margin: 0px;

  font: normal normal 600 18px Open Sans;
`;
const SubHeader = styled(Typography)`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
  color: #5a5a5a;
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
`;
const SubmitFormWithNameDialog = (props) => {
  const [name, setName] = useState("");
  const handleClose = () => {
    props.setOpen(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.handleSubmit(name);
  };
  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <ModelWrapper component={"form"} onSubmit={handleSubmit}>
              <Header>Form Name</Header>
              <SubHeader>Fill in the below details</SubHeader>
              <Divider style={{ margin: "16px 0px" }} />

              <InputLabel
                id="demo-simple-select-label"
                style={{
                  font: "normal normal 600 10px Open Sans",
                  color: "#4d4e4f",
                }}
              >
                Enter Name
              </InputLabel>

              <OutlinedInput
                value={name}
                required
                name="name"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                placeholder="Enter Value"
                style={{
                  width: "200px",
                  height: "30px",
                  fontSize: "12px",
                }}
              />

              <ButtonWrapper>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="default"
                  style={{
                    font: "normal normal 600 10px Open Sans",
                    marginRight: "10px",
                    textTransform: "none",
                  }}
                >
                  {getLocalizedText("cancel")}
                </Button>
                <Button
                  id="assign-btn"
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    font: "normal normal 600 10px Open Sans",
                    width: "100%",
                    textTransform: "none",
                  }}
                >
                  Create
                </Button>
              </ButtonWrapper>
            </ModelWrapper>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default SubmitFormWithNameDialog;
