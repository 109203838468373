import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Modal,
  Radio,
  RadioGroup,
  Select as MuiSelect,
} from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import {
  editProjectMemberApi,
  getProjectAdminWorkTimingTemplateListApi,
  getTeamApi,
} from "../../Api";
import { useEffect } from "react";
import { myTheme } from "../../themeUtils";
import { getLocalizedText } from "../../Functions";
import CustomFieldHeader from "../custom_tooltip_headers/CustomFieldHeader";
import rootStore from "../../stores/RootStore";
import { CircularProgress, styled, Typography } from "@mui/material";
import Select from "react-select";
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
  margin-bottom: 30px;
`;
const Form = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 420px;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;

const ButtonWrapper = styled(Box)`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const SelectColumn = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for options
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for the entire control container, including the search input
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "no options" message
  }),
  loadingMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "loading" message
  }),
};
const EditProjectMember = (props) => {
  const classes = useStyles();
  const [user, setUser] = useState({
    team: "",
    calling_mode: "",
    role: "member",
    unmask_columns: false,
  });
  const [teamList, setTeamList] = useState([]);
  const roleList = ["lead", "member", "manager"];
  const callingModes = ["Manual", "Progressive"];
  const [workTimingTemplates, setWorkTimingTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const [loading, setLoading] = useState(true);

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const editUser = async () => {
      let data = {
        user_id: props.data.user_id,
        calling_mode: user.calling_mode,
        role: user.role,
        team_id: user.team,
        unmask_columns: user.unmask_columns,
        work_timings_template_id:
          selectedTemplate !== null ? selectedTemplate["value"] : null,
      };
      try {
        let response = await editProjectMemberApi({
          id: props.pid,
          data: data,
        });
        props.setRefresh(!props.refresh);
        props.setOpen(false);
      } catch (error) {
        console.log(error);
      }
    };

    editUser();
  };
  const handleClose = () => {
    props.setOpen(false);
  };

  const getTemplateListOptions = () => {
    let tempList = [...workTimingTemplates];
    return tempList.map((obj) => ({
      label: obj["name"],
      value: obj["id"],
    }));
  };

  const getWorkTimingTemplateList = async () => {
    let response = await getProjectAdminWorkTimingTemplateListApi({
      projectID: rootStore.authStore.projectId,
    });
    setWorkTimingTemplates(response);
  };

  const getTeams = async () => {
    let response = await getTeamApi(props.pid);
    setTeamList(response);
  };
  const init = async () => {
    await getTeams();
    await getWorkTimingTemplateList();
    setUser({
      team: props.data.team["id"],
      calling_mode: props.data.calling_mode,
      role: props.data.role,
      unmask_columns: props.data.unmask_columns,
    });
    if (
      props.data.hasOwnProperty("work_timings") &&
      props.data["work_timings"] !== null
    ) {
      let tempObj = {
        label: props.data["work_timings"]["name"],
        value: props.data["work_timings"]["id"],
      };
      setSelectedTemplate(tempObj);
    }
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);
  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          {!loading ? (
            <Box>
              <Container>
                <Header>{getLocalizedText("edit_member_details")}</Header>
                <SubHeader>
                  {getLocalizedText("fill_in_the_below_details")}
                </SubHeader>
                <Form component={"form"} onSubmit={handleSubmit}>
                  <InputContainer>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <SelectColumn>
                          <CustomFieldHeader
                            header={getLocalizedText("select_role")}
                            tooltip={"Choose the role assigned to the member."}
                            required={true}
                          />
                          <FormControl
                            sx={{ width: "100%" }}
                            classes={{
                              root: classes.quantityRoot,
                            }}
                          >
                            <MuiSelect
                              classes={{
                                icon: classes.icon,
                              }}
                              value={user.role}
                              name="role"
                              displayEmpty
                              disableUnderline
                              variant="outlined"
                              required
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              style={{
                                width: "100%",
                                height: "30px",
                                fontSize: "12px",
                                fontWeight: "bold",
                                borderRadius: "0px",
                              }}
                            >
                              {roleList.map((e) => {
                                return (
                                  <MenuItem id={e} value={e}>
                                    {e}
                                  </MenuItem>
                                );
                              })}
                            </MuiSelect>
                          </FormControl>
                        </SelectColumn>
                      </Grid>
                      <Grid item xs={6}>
                        <InputWrapper>
                          <CustomFieldHeader
                            header={getLocalizedText("unmask_columns")}
                            tooltip={
                              "Specify whether the column value will be masked for this member."
                            }
                          />
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio size="small" />}
                              label={
                                <Label style={{ fontSize: "12px" }}>Yes</Label>
                              }
                              checked={user.unmask_columns}
                              onChange={() => {
                                setUser({ ...user, unmask_columns: true });
                              }}
                            />
                            <FormControlLabel
                              value={false}
                              checked={!user.unmask_columns}
                              onChange={() => {
                                setUser({ ...user, unmask_columns: false });
                              }}
                              control={<Radio size="small" />}
                              label={
                                <Label style={{ fontSize: "12px" }}>No</Label>
                              }
                            />
                          </RadioGroup>
                        </InputWrapper>
                      </Grid>
                      <Grid item xs={6}>
                        <SelectColumn>
                          <CustomFieldHeader
                            header={getLocalizedText("calling_mode")}
                            tooltip={"Specify the member's calling mode."}
                            required={true}
                          />
                          <FormControl
                            sx={{ width: "100%" }}
                            classes={{
                              root: classes.quantityRoot,
                            }}
                          >
                            <MuiSelect
                              classes={{
                                icon: classes.icon,
                              }}
                              name="calling_mode"
                              displayEmpty
                              disableUnderline
                              variant="outlined"
                              required
                              value={user.calling_mode}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              style={{
                                width: "100%",
                                height: "30px",
                                fontSize: "12px",
                                fontWeight: "bold",
                                borderRadius: "0px",
                              }}
                            >
                              {callingModes.map((e) => {
                                return (
                                  <MenuItem id={e} value={e}>
                                    {e}
                                  </MenuItem>
                                );
                              })}
                            </MuiSelect>
                          </FormControl>
                        </SelectColumn>
                      </Grid>
                      <Grid item xs={6}>
                        <SelectColumn>
                          <CustomFieldHeader
                            header={getLocalizedText("select_team")}
                            tooltip={
                              "Choose the team to which the member belongs."
                            }
                            required={true}
                          />
                          <FormControl
                            sx={{ width: "100%" }}
                            classes={{
                              root: classes.quantityRoot,
                            }}
                          >
                            <MuiSelect
                              classes={{
                                icon: classes.icon,
                              }}
                              value={user.team}
                              name="team"
                              displayEmpty
                              disableUnderline
                              variant="outlined"
                              required
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              style={{
                                width: "100%",
                                height: "30px",
                                fontSize: "12px",
                                fontWeight: "bold",
                                borderRadius: "0px",
                              }}
                            >
                              {teamList.map((e) => {
                                return (
                                  <MenuItem id={e.id} value={e.id}>
                                    {e.name}
                                  </MenuItem>
                                );
                              })}
                            </MuiSelect>
                          </FormControl>
                        </SelectColumn>
                      </Grid>
                      <Grid item xs={12}>
                        <SelectColumn>
                          <CustomFieldHeader
                            header={"Select Work Timing Template"}
                            tooltip={
                              "Choose the work timing assigned to the member."
                            }
                            required={false}
                          />

                          <Select
                            menuPosition="fixed"
                            options={getTemplateListOptions()}
                            value={selectedTemplate}
                            onChange={(selectedOption) => {
                              setSelectedTemplate(selectedOption);
                            }}
                            closeMenuOnSelect={true}
                            styles={customStyles}
                            isClearable
                          />
                        </SelectColumn>
                      </Grid>
                    </Grid>
                  </InputContainer>
                  <ButtonWrapper>
                    <Button
                      onClick={handleClose}
                      type="button"
                      variant="contained"
                      color="default"
                      style={{
                        backgroundColor: "#EFEFF4",
                        textTransform: "none",
                        marginRight: "20px",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      {getLocalizedText("cancel")}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      id="call-log-btn"
                      style={{
                        textTransform: "none",
                        fontSize: "12px",
                        fontWeight: "bold",
                        ...myTheme.Button.btnBlue,
                      }}
                    >
                      {getLocalizedText("submit_details")}
                    </Button>
                  </ButtonWrapper>
                </Form>
              </Container>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                width: "420px",
                height: "320px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </ModelContainer>
      </Modal>
    </>
  );
};

export default observer(EditProjectMember);
