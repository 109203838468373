import React from "react";
import AboutSection from "./preview_form_components/AboutSection";
import MyAppBar from "./preview_form_components/appbar/AppBar";
import MyTopSection from "./preview_form_components/TopSection";
import { Box, Container } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledContainer = styled(Container)(({ theme }) => ({
  width: "80%",
  [[theme.breakpoints.between("xs", "md")]]: {
    width: "90%",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    width: "90%",
  },
  [theme.breakpoints.up("lg")]: {
    width: "80%",
  },
}));

const OuterContainer = styled(Box)`
  width: 100%;
`;
const PreviewWebPage = ({
  pageSchema,
  scope,
  topSectionData,
  bottomSectionData,
  aboutSectionData,
  appbarData,
  footerData,
}) => {
  return (
    <OuterContainer>
      <MyAppBar
        pageSchema={pageSchema}
        isHighlighted={scope === "appbar_section"}
        appbarData={appbarData}
      />
      <StyledContainer disableGutters fixed maxWidth="false">
        <MyTopSection
          topSectionData={topSectionData}
          pageSchema={pageSchema}
          isHighlighted={scope === "top_section"}
        />
        <AboutSection
          pageSchema={pageSchema}
          scope={scope}
          aboutSectionData={aboutSectionData}
          footerData={footerData}
          bottomSectionData={bottomSectionData}
        />
      </StyledContainer>
    </OuterContainer>
  );
};

export default PreviewWebPage;
