import React, { useEffect } from "react";
import { useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";

import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { getPredefinedReportsApi, getReportsApi } from "../../Api";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { pageSize } from "../../Config";
import { Box, Typography, Grid, styled } from "@mui/material";
import analytics from "../../Analytics";
import ReportCardWidget from "../../components/report_page_components/ReportCardWidget";
import { useNavigate } from "react-router-dom";
import { hasReportAccess } from "../../Functions";
import NoAccessPermissionPage from "../no_access_page/NoAccessPermissionPage";
import LoadingAnimationComponent from "../../components/loading_component/LoadingAnimationComponent";
const Container = styled(Box)`
  margin-top: 64px;
  background-color: #f9f9fc;
  ${`min-height: calc(100vh - 139px);`};
`;
const Wrapper = styled(Box)`
  width: 95%;
  margin: auto;
  display: flex;
  flex-direction: column;
`;

const Bottom = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: white;
`;

const Row = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  min-height: 40px;
  padding: 20px 0px;
`;

const TopWrapper = styled(Box)`
  display: flex;
  align-items: center;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 28px Open Sans;
  /* text-align: center; */
  color: #4d4e4f;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 217px);`};
  background-color: white;
  padding: 20px;
`;
const PaginationWrapper = styled(Box)`
  padding: 0px 20px;
`;
const EmptyText = styled(Typography)`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
  /* text-align: center; */
  color: #4d4e4f;
  margin-top: 50px;
`;
const ReportsPage = () => {
  const navigate = useNavigate();
  const { authStore } = rootStore;
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);

  const getReports = async () => {
    setRow([]);
    let response = await getPredefinedReportsApi();
    setRow(response);
  };
  const init = async () => {
    if (hasReportAccess()) {
      await getReports();
    }

    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    analytics.triggerEvent(
      4625000084,
      "reports_page_load",
      "reports_page",
      "",
      {}
    );
    authStore.updateLeadDetailStatus();
  }, []);
  return hasReportAccess() ? (
    <>
      <Container>
        <Wrapper>
          <Row>
            <TopWrapper>
              <Header>Reports</Header>
            </TopWrapper>
          </Row>
          {!loading ? (
            <Bottom>
              <TableWrapper>
                {row.length > 0 ? (
                  <Grid container spacing={2}>
                    {row.map((data, idx) => {
                      return (
                        <Grid item xs={4} key={idx}>
                          <ReportCardWidget
                            details={data}
                            handleClick={() => {
                              navigate(`/reports/details/${data.id}`, {
                                state: {
                                  report_data: data,
                                },
                              });
                            }}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "calc(100vh - 256px)",
                        width: "100%",
                      }}
                    >
                      <EmptyText>No Reports Found!</EmptyText>
                    </Box>
                  </>
                )}
              </TableWrapper>
            </Bottom>
          ) : (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "calc(100vh - 217px)",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white",
              }}
            >
              <LoadingAnimationComponent size={"small"} />
            </Box>
          )}
        </Wrapper>
      </Container>
      <Footer />
    </>
  ) : (
    <NoAccessPermissionPage />
  );
};

export default observer(ReportsPage);
