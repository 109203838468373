import React from "react";
import { styled } from "@mui/material/styles";

import { Box, Tooltip, Typography } from "@mui/material";
import { Info } from "@material-ui/icons";
const Label = styled(Typography)`
  margin: 0px;
  font: normal normal 500 12px Open Sans;
`;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  width: fit-content;
`;
const CustomCheckboxHeader = ({
  header,
  tooltip,
  required = false,
  style = {},
}) => {
  return (
    <Wrapper style={style}>
      <Label>{header}</Label>
      <Tooltip title={tooltip} disableFocusListener arrow>
        <Info
          color="primary"
          style={{
            marginRight: "4px",
            marginLeft: "4px",
            width: "16px",
            cursor: "pointer",
            height: "16px",
          }}
        />
      </Tooltip>
      {required && (
        <Label
          style={{
            color: "red",
          }}
        >
          *
        </Label>
      )}
    </Wrapper>
  );
};

export default CustomCheckboxHeader;
