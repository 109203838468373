import React, { useRef } from "react";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { CircularProgress, Divider, IconButton } from "@material-ui/core";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import TableComponent from "../../chart_components/dashboard_chart_v2/tabular_widget/TableComponent";
import { Fullscreen, Refresh } from "@material-ui/icons";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import rootStore from "../../../stores/RootStore";
import {
  getColumnHeaderWidthByStringLength,
  getCombinedWidgetAndAccountID,
  getLocalDateTime,
  getTableSize,
} from "../../../Functions";
import TableWidgetFullScreenModal from "../../full_screen_modal/TableWidgetFullScreenModal";
const ITEM_HEIGHT = 48;

const Container = styled(Box)`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  border: 1px solid #eceef2;
`;

const ChartBottom = styled(Box)`
  width: 100%;
  height: 100%;
`;

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
const Text = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
  text-align: left;
  color: #4d4e4f;
`;
const Text2 = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  margin: 0px;
  color: #bbbab6;
`;
const TabularWidget = (props) => {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([]);
  const componentRef = useRef(null);
  const [openFullScreenDialog, setOpenFullScreenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const Open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const ChartBottomSection = () => {
    if (!loading) {
      if (tableData.length > 0) {
        return (
          <Wrapper>
            <TableComponent
              row={tableData}
              columns={columns}
              pageSize={getTableSize({
                containerHeight: getContainerHeight(),
              })}
              type={"widget"}
            />
          </Wrapper>
        );
      } else {
        return (
          <Wrapper>
            <Typography>No data found!</Typography>
          </Wrapper>
        );
      }
    } else {
      return (
        <Wrapper>
          <CircularProgress />
        </Wrapper>
      );
    }
  };

  const getContainerHeight = () => {
    if (componentRef.current) {
      const height = componentRef.current.getBoundingClientRect().height;
      return height;
    }
    return 0;
  };

  const setupColumns = (data) => {
    const columns_list = [];
    let dataLength = Object.keys(data).length;
    Object.keys(data).forEach((key, idx) => {
      let newObj = {
        field: key,
        headerName: key,
        cellRenderer: (params) => {
          let data = null;
          if (
            params.data[key] !== null &&
            params.data[key] !== "" &&
            params.data[key] !== undefined
          ) {
            if (typeof params.data[key] === "boolean") {
              if (params.data[key] === true) {
                data = "True";
              } else {
                data = "False";
              }
            } else {
              data = params.data[key];
            }
          }
          return (
            <span className="csutable-cell-trucate">
              {data !== null ? data : "-"}
            </span>
          );
        },
      };
      if (idx === dataLength - 1) {
        newObj["minWidth"] = getColumnHeaderWidthByStringLength(key);
        newObj["flex"] = 1;
      } else {
        newObj["autoSize"] = true;
      }
      columns_list.push(newObj);
    });

    return columns_list;
  };
  const initData = () => {
    if (props.data !== null) {
      if (props.data.length > 0) {
        let columns = setupColumns(props.data[0]);
        setColumns(columns);
        setTableData(props.data);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, [props.widgetConfig.id, rootStore.userStore.dashboardMode]);
  return (
    <>
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "12px 20px",
            backgroundColor: "#F8F8F8",
          }}
        >
          <Text
            sx={{
              fontSize:
                rootStore.userStore.dashboardMode === "grid_three"
                  ? "16px"
                  : "18px",
            }}
          >
            {props.widgetConfig.title}
          </Text>
          {(props.type === "slider" || props.type === "grid") && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                style={{ padding: "0px" }}
                onClick={() => {
                  setOpenFullScreenDialog(true);
                }}
              >
                <Fullscreen />
              </IconButton>
              <IconButton
                onClick={handleMenuClick}
                aria-label="more"
                id="long-button"
                aria-controls={Open ? "long-menu" : undefined}
                aria-expanded={Open ? "true" : undefined}
                aria-haspopup="true"
                style={{ padding: "0px" }}
              >
                <MoreVertIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        <Divider sx={{ backgroundColor: "#eceef2" }} />
        <ChartBottom ref={componentRef}>
          <ChartBottomSection />
        </ChartBottom>
        <Divider sx={{ backgroundColor: "#eceef2" }} />
        {(props.type === "slider" || props.type === "grid") && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "12px 20px",
            }}
          >
            {rootStore.userStore.widgetData.hasOwnProperty(
              getCombinedWidgetAndAccountID(props.widgetConfig.id)
            ) && (
              <Text2>
                Updated at{" "}
                {getLocalDateTime(
                  rootStore.userStore.widgetData[
                    getCombinedWidgetAndAccountID(props.widgetConfig.id)
                  ]["last_updated"]
                )}
              </Text2>
            )}
            <IconButton
              onClick={() => {
                props.handleRefresh();
              }}
              style={{ padding: "0px" }}
            >
              <Refresh />
            </IconButton>
          </Box>
        )}
      </Container>
      <Menu
        style={{ display: "flex", flexDirection: "column" }}
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={Open}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "10ch",
          },
        }}
      >
        <MenuItem
          style={{ fontSize: "12px" }}
          onClick={() => {
            props.handleRemove(props.widgetConfig);
            handleMenuClose();
          }}
          disabled={!props.isDashboardEditable()}
        >
          Remove
        </MenuItem>
      </Menu>
      {openFullScreenDialog && (
        <TableWidgetFullScreenModal
          open={openFullScreenDialog}
          setOpen={setOpenFullScreenDialog}
          title={props.widgetConfig.title}
          columns={columns}
          tableData={tableData}
        />
      )}
    </>
  );
};

export default observer(TabularWidget);
