import {
  Box,
  Button,
  InputLabel,
  Modal,
  Select,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { leadTransferApi, getMembersForTransfer } from "../../Api";
import {
  MenuItem,
  FormControl,
  Radio,
  Divider,
  Avatar,
  OutlinedInput,
} from "@mui/material";
import { getLocalizedText } from "../../Functions";
import { Typography } from "antd";
import profile_image from "../../assets/profile_placeholder.png";

const ModelContainer = styled(Box)({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: "auto",
  height: "fit-content",
  width: "fit-content",
  border: "none",
  outline: "none",
  backgroundColor: "white",
  boxShadow: "0px 3px 6px #0000000d",
  borderRadius: "10px",
  padding: "30px",
});

const ModelWrapper = styled(Box)({
  width: "420px",
  margin: "auto",
  display: "flex",
  position: "relative",
  flexDirection: "column",
});

const Header = styled(Typography)({
  margin: 0,
  fontWeight: 600,
  fontSize: "18px",
  fontFamily: "Open Sans",
});

const SubHeader = styled(Typography)({
  margin: 0,
  fontWeight: 500,
  fontSize: "14px",
  fontFamily: "Open Sans",
  color: "#5a5a5a",
});

const ButtonWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  width: "100%",
});

const ProfileImage = styled("img")({
  aspectRatio: "1 / 1",
  width: "100%",
  height: "auto",
  borderRadius: "50%",
  objectFit: "cover",
});

const ImageWrapper = styled(Avatar)({
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
  border: "1px solid #a7a6a8",
  padding: 0,
  marginRight: "8px",
  position: "relative",
  backgroundColor: "white",
});

const InputWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const LeadTransferModal = (props) => {
  const [member, setMember] = useState([]);
  const [memberName, setMemberName] = useState("");
  const [comments, setComments] = useState("");
  let navigate = useNavigate();
  
  const handleChange = (event) => {
    setMemberName(event.target.value);
  };

  const handleTransfer = async () => {
    document.getElementById("transfer-btn").innerText = "Loading...";
    let payload = {
      transfer_to: memberName,
      comments: comments,
    };

    let response = await leadTransferApi({
      payload: payload,
      leadID: props.leadID,
    });
    if (response.hasError()) {
      console.log(response.error);
    } else {
      document.getElementById("transfer-btn").style.backgroundColor = "green";
      document.getElementById("transfer-btn").innerText = "Transferred";
      setTimeout(() => {
        navigate("/");
        props.setOpen(false);
      }, [800]);
    }
  };

  const getTeamMembers = async () => {
    //filter by role - only managers and leads
    let response = await getMembersForTransfer();
    setMember(response);
  };
  const init = () => {
    getTeamMembers();
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <ModelWrapper>
              <Header>{getLocalizedText("transfer_lead_to")}</Header>
              <SubHeader>
                {getLocalizedText("select_from_below_dropdown")}
              </SubHeader>
              <Divider style={{ margin: "10px 0px" }} />
              <InputLabel
                id="demo-simple-select-label"
                style={{
                  font: "normal normal 600 10px Open Sans",
                  color: "#4d4e4f",
                  marginBottom: "4px",
                }}
              >
                {getLocalizedText("select_member")}
              </InputLabel>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <FormControl
                  sx={{
                    width: "100%",
                  }}
                >
                  <Select
                    variant="outlined"
                    value={memberName}
                    renderValue={(value) =>
                      member.find((x) => x.id === value).name
                    }
                    onChange={(e) => {
                      e.stopPropagation();
                      handleChange(e);
                    }}
                    style={{
                      width: "100%",
                      font: "normal normal 500 12px Open Sans",
                      height: "30px",
                    }}
                  >
                    {member.map((e) => {
                      return (
                        <MenuItem
                          component={Paper}
                          id={e.id}
                          value={e.id}
                          sx={{
                            margin: "1px",
                            marginBottom: "4px",
                            padding: 1,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Radio
                            checked={e.id === memberName}
                            onChange={(e) => {
                              e.stopPropagation();
                            }}
                            value={memberName}
                            name="radio-buttons"
                          />
                          <ImageWrapper>
                            <ProfileImage
                              src={
                                e.profile_pic != null
                                  ? e.profile_pic
                                  : profile_image
                              }
                              alt="loading..."
                            />
                          </ImageWrapper>
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              style={{
                                width: "fit-content",
                                fontSize: "14px",
                                fontWeight: "500",
                                textAlign: "left",
                              }}
                            >
                              {e.name}
                            </Typography>
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                style={{
                                  width: "fit-content",
                                  fontSize: "12px",
                                  textAlign: "left",
                                  color: "#b7b7b7",
                                }}
                              >
                                Role: {e.role}
                              </Typography>
                            </Box>
                            <Box></Box>
                          </Box>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box
                sx={{
                  marginBottom: "20px",
                }}
              >
                <InputWrapper>
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{
                      font: "normal normal 600 10px Open Sans",
                      color: "#4d4e4f",
                    }}
                  >
                    Comments*
                  </InputLabel>
                  <OutlinedInput
                    multiline={true}
                    required
                    rows={3}
                    inputProps={{
                      min: 0,
                      "aria-label": "weight",
                      style: {
                        fontSize: "12px",
                      },
                    }}
                    value={comments}
                    name={"comments"}
                    type={"text"}
                    placeholder={"Enter Value"}
                    onChange={(e) => setComments(e.target.value)}
                  />
                </InputWrapper>
              </Box>
              <ButtonWrapper>
                <Button
                  onClick={() => {
                    props.setOpen(false);
                  }}
                  variant="contained"
                  color="default"
                  style={{
                    width: "100%",
                    font: "normal normal 600 10px Open Sans",
                    marginRight: "10px",
                    textTransform: "none",
                  }}
                >
                  {getLocalizedText("cancel")}
                </Button>
                <Button
                  id="transfer-btn"
                  onClick={handleTransfer}
                  variant="contained"
                  color="primary"
                  disabled={memberName === "" || comments === ""}
                  style={{
                    font: "normal normal 600 10px Open Sans",
                    width: "100%",
                    textTransform: "none",
                  }}
                >
                  {getLocalizedText("transfer")}
                </Button>
              </ButtonWrapper>
            </ModelWrapper>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default LeadTransferModal;
