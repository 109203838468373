import { AgGridReact } from "ag-grid-react";
import React, { useMemo } from "react";
import { dataRenderer } from "../../../../Functions";
import { Box, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { dealsTabProductsColumnHeaders } from "../../../../Db";
const TextHighlight = styled(Typography)`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  color: #3f51b5;
  cursor: pointer;
`;
const Text = styled(Typography)`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const ProductsTab = ({
  tableData,
  setSelectedRowID,
  setOpenViewProductPanel,
}) => {
  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };
  const nameColumn = [
    {
      field: "name",
      headerName: "NAME",
      width: 230,
      cellRenderer: (params) => {
        let value = "-";
        if (params.data.item !== null) {
          value = dataRenderer(params.data.item);
        } else {
          value = dataRenderer(params.data.product.name);
        }
        if (params.data.item !== null) {
          return (
            <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
              <Tooltip title={value}>
                <Text>{value}</Text>
              </Tooltip>
            </Box>
          );
        } else {
          return (
            <Box
              onClick={() => {
                setSelectedRowID(params.data.product.id);
                setOpenViewProductPanel(true);
              }}
              sx={{ display: "flex", alignItems: "center", height: "100%" }}
            >
              <Tooltip title={value}>
                <TextHighlight>{value}</TextHighlight>
              </Tooltip>
            </Box>
          );
        }
      },
    },
  ];
  return (
    <div className="ag-theme-alpine" style={{ width: "100%" }}>
      <AgGridReact
        domLayout="autoHeight"
        gridOptions={gridOptions}
        rowData={tableData}
        defaultColDef={defaultColDef}
        columnDefs={nameColumn.concat(dealsTabProductsColumnHeaders)}
        animateRows={true}
        suppressCellFocus
      />
    </div>
  );
};

export default ProductsTab;
