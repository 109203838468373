import React, { useState } from "react";
import styledComp from "styled-components";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  OutlinedInput,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Typography, Box } from "@mui/material";
import { styled } from "@mui/system";
import InputAdornment from "@mui/material/InputAdornment";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "fit-content",
  textAlign: "left",
  padding: theme.spacing(2),
  height: "fit-content",
  borderRadius: "5px",
  backgroundColor: "white",
  position: "relative",
}));

const Header = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 24px Poppins",
  textAlign: "left",
  width: "100%",
  marginBottom: theme.spacing(2),
  variant: "h6",
  component: "span",
  [[theme.breakpoints.between("xs", "sm")]]: {
    font: "normal normal 600 16px Poppins",
  },
  [[theme.breakpoints.between("sm", "md")]]: {
    font: "normal normal 600 16px Poppins",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    font: "normal normal 600 20px Poppins",
  },
  [theme.breakpoints.up("lg")]: {
    font: "normal normal 600 24px Poppins",
  },
}));

const Label = styled(Typography)(({ theme }) => ({
  font: "normal normal 400 12px Poppins",
  color: "#5a6066",
  variant: "body1",
  component: "span",
  [[theme.breakpoints.between("xs", "md")]]: {
    font: "normal normal 400 10px Poppins",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    font: "normal normal 400 10px Poppins",
  },
  [theme.breakpoints.up("lg")]: {
    font: "normal normal 400 12px Poppins",
  },
}));

const SubHeader = styled(Typography)(({ theme }) => ({
  font: "normal normal 500 12px Poppins",
  textAlign: "left",
  color: "#353535",
  variant: "caption",
  component: "span",
  [[theme.breakpoints.between("xs", "md")]]: {
    font: "normal normal 500 8px Poppins",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    font: "normal normal 500 10px Poppins",
  },
  [theme.breakpoints.up("lg")]: {
    font: "normal normal 500 12px Poppins",
  },
}));

const CountryCode = styled(Typography)(({ theme }) => ({
  font: "normal normal 400 14px Poppins",
  textAlign: "center",
  color: "#353535",
  vaiant: "h6",
  component: "p",
}));

const Form = styledComp.form`
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  backgroundColor: "white",
  width: " 100%",
  borderRadius: "8px",
  height: "30px",
  overflow: "hidden",
}));

const FieldWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

const DisclaimerWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  columnGap: "10px",
  width: "100%",
  marginBottom: theme.spacing(2),
}));

const FormComponent = ({ formState, secondaryColor, contentState }) => {
  const [isCheckedDisclaimer, setIsCheckedDisclaimer] = useState(false);
  const getLabelName = (meta) => {
    if (meta["required"] === true) {
      return `${meta["label"]}*`;
    } else {
      return meta["label"];
    }
  };
  return (
    <>
      <Container onClick={(e) => e.stopPropagation()}>
        <Header
          sx={{
            color: secondaryColor,
          }}
        >
          {contentState.form_title}
        </Header>

        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Grid container spacing={1.5}>
            {formState.map((fieldMeta, index) => {
              if (fieldMeta["field_type"] === "phone") {
                return (
                  <Grid item xs={12}>
                    <FieldWrapper>
                      <Label>{getLabelName(fieldMeta)}</Label>
                      <InputWrapper>
                        <OutlinedInput
                          autoComplete="off"
                          required={fieldMeta["required"]}
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "^[0-9]{10}$",
                            maxLength: 10,
                          }}
                          startAdornment={
                            <InputAdornment position="start">
                              <CountryCode>+91</CountryCode>
                            </InputAdornment>
                          }
                          style={{
                            width: "100%",
                            borderRadius: "8px",
                            font: "normal normal 400 14px Poppins",
                          }}
                        />
                      </InputWrapper>
                    </FieldWrapper>
                  </Grid>
                );
              } else if (fieldMeta["field_type"] === "email") {
                return (
                  <Grid item xs={12}>
                    <FieldWrapper>
                      <Label>{getLabelName(fieldMeta)}</Label>
                      <InputWrapper>
                        <OutlinedInput
                          required={fieldMeta["required"]}
                          autoComplete="off"
                          type="email"
                          style={{
                            width: "100%",
                            height: "30px",
                            borderRadius: "8px",
                            font: "normal normal 400 14px Poppins",
                          }}
                        />
                      </InputWrapper>
                    </FieldWrapper>
                  </Grid>
                );
              } else if (fieldMeta["field_type"] === "text") {
                return (
                  <Grid item xs={12}>
                    <FieldWrapper>
                      <Label>{getLabelName(fieldMeta)}</Label>
                      <InputWrapper>
                        <OutlinedInput
                          autoComplete="off"
                          required={fieldMeta["required"]}
                          style={{
                            width: "100%",
                            height: "30px",
                            borderRadius: "8px",
                            font: "normal normal 400 14px Poppins",
                          }}
                        />
                      </InputWrapper>
                    </FieldWrapper>
                  </Grid>
                );
              } else if (fieldMeta["field_type"] === "integer") {
                return (
                  <Grid item xs={12}>
                    <FieldWrapper>
                      <Label>{getLabelName(fieldMeta)}</Label>
                      <InputWrapper>
                        <OutlinedInput
                          autoComplete="off"
                          required={fieldMeta["required"]}
                          type="number"
                          inputProps={{
                            inputMode: "numeric",
                          }}
                          style={{
                            width: "100%",
                            borderRadius: "8px",
                            font: "normal normal 400 14px Poppins",
                          }}
                        />
                      </InputWrapper>
                    </FieldWrapper>
                  </Grid>
                );
              } else if (fieldMeta["field_type"] === "dropdown") {
                return (
                  <Grid item xs={12}>
                    <FieldWrapper>
                      <Label>{getLabelName(fieldMeta)}</Label>

                      <FormControl
                        sx={{
                          width: "100%",
                          height: "30px",
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                      >
                        <Select
                          style={{
                            width: "100%",
                            height: "30px",
                            font: "normal normal 400 14px Poppins",
                          }}
                          MenuProps={{
                            disableScrollLock: true,
                          }}
                          required={fieldMeta["required"]}
                          inputProps={{
                            "aria-label": "Without label",
                            style: { borderColor: "transparent" },
                          }}
                        >
                          {fieldMeta["inputs"].map((item) => {
                            return (
                              <MenuItem
                                style={{
                                  font: "normal normal 400 14px Poppins",
                                }}
                                value={item}
                              >
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </FieldWrapper>
                  </Grid>
                );
              }
            })}
            <Grid item xs={12}>
              <DisclaimerWrapper>
                <FormControlLabel
                  sx={{ alignItems: "start" }}
                  onChange={() => {
                    setIsCheckedDisclaimer(!isCheckedDisclaimer);
                  }}
                  control={
                    <Checkbox
                      color="primary"
                      checked={isCheckedDisclaimer}
                      sx={{ paddingTop: "0px" }}
                    />
                  }
                  label={<SubHeader>{contentState["disclaimer"]}</SubHeader>}
                />
              </DisclaimerWrapper>
            </Grid>
          </Grid>
          <Button
            disabled={!isCheckedDisclaimer}
            variant="contained"
            color="primary"
            type="submit"
            style={{
              textTransform: "none",
              color: "white",
              font: "normal normal 700 16px open sans",
            }}
          >
            APPLY NOW
          </Button>
        </Form>
      </Container>
    </>
  );
};

export default FormComponent;
