import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { getOrganizationDetailsApi } from "../../Api";
import { CircularProgress, Drawer } from "@material-ui/core";
import OrganizationDetailsWidget from "./OrganizationDetailsWidget";
import EditOrganizationSidepanel from "../organizations_page_sidepanels/EditOrganizationSidepanel";
import rootStore from "../../stores/RootStore";

const Container = styled(Box)`
  width: 100%;
  padding: 20px;
  background-color: white;
  ${`min-height: calc(100vh - 246px);`};
  /* background: #f9f9fc 0% 0% no-repeat padding-box; */
`;

const BasicInfoTab = (props) => {
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [openEditPanel, setOpenEditPanel] = useState(false);

  const getOrganizationDetails = async () => {
    let response = await getOrganizationDetailsApi({
      organizationID: props.id,
    });
    setDetails(response);
  };

  const init = async () => {
    await getOrganizationDetails();
    await rootStore.organizationStore.fetchColumns();
    setLoading(false);
  };
  const refetchOrganizationDetails = async () => {
    setLoading(true);
    await getOrganizationDetails();
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);
  useEffect(() => {
    if (refresh) {
      refetchOrganizationDetails();
      setRefresh(false);
    }
  }, [refresh]);
  return (
    <>
      {!loading ? (
        <Container>
          <OrganizationDetailsWidget
            details={details}
            setRefresh={setRefresh}
            setOpenEditPanel={setOpenEditPanel}
          />
        </Container>
      ) : (
        <Container
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Container>
      )}
      {openEditPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openEditPanel}
          onClose={() => {
            setOpenEditPanel(false);
          }}
        >
          <EditOrganizationSidepanel
            setRefresh={setRefresh}
            setOpen={setOpenEditPanel}
            open={openEditPanel}
            organizationID={props.id}
            editableData={details}
          />
        </Drawer>
      )}
    </>
  );
};

export default BasicInfoTab;
