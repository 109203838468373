import * as React from "react";
import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import { useLocation, useNavigate } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import appLogo from "../../assets/logo.png";
import { toJS } from "mobx";
import Menu from "@mui/material/Menu";
import {
  AccountBalanceWallet,
  AccountCircle,
  AccountTree,
  Assessment,
  Business,
  BusinessCenter,
  Call,
  Collections,
  ContactSupport,
  Contacts,
  Dashboard,
  ExitToApp,
  ExpandLess,
  ExpandMore,
  FilterListRounded,
  Group,
  History,
  LibraryBooks,
  ListAlt,
  LocalOffer,
  Menu as MenuIcon,
  NoteAdd,
  Notifications,
  People,
  Place,
  QueuePlayNext,
  Search,
  Settings,
  TrackChanges,
  Tune,
} from "@material-ui/icons";
import PhoneDisabledIcon from "@mui/icons-material/PhoneDisabled";
import { ChevronLeftOutlined } from "@material-ui/icons";
import { ChevronRightOutlined } from "@material-ui/icons";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import {
  AdminPanelSettings,
  Campaign,
  CellTower,
  ConnectWithoutContact,
  CorporateFare,
  DriveFolderUpload,
  DynamicForm,
  ManageAccountsOutlined,
  ProductionQuantityLimits,
  Segment,
  Upload,
} from "@mui/icons-material";
import UploadModal from "../upload_csv_modal/UploadModal";
import {
  getAdminProjectApi,
  getAllColsApi,
  getFilterColsApi,
  getFilteredLeadsApi,
  getLeadProfileApi,
  getNotificationsApi,
  getProfileDataApi,
  getProjectApi,
  getRoleApi,
  getTeamApi,
  searchLeadsByQueryApi,
  sendUserActiveStatus,
  userLogoutApi,
} from "../../Api";
import { Badge, Select, makeStyles } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import MainFilterComponent from "../filter_modal/MainFilterComponent";
import { useEffect } from "react";
import FilterComponent from "../filter_modal/FilterComponent";
import {
  checkFeaturePermission,
  consoleLogger,
  getLocalizedText,
  isUnderWorkSchedule,
  updateViewList,
  validateDisabledFeature,
} from "../../Functions";
import {
  AdminProjectMenuList,
  TeamLeadAccessNameList,
  pageSize,
  refetch_time,
} from "../../Config";
import NotificationComponent from "../notification_component/NotificationComponent";
import AlertDialogue from "../alert_dialogue/AlertDialogue";
import WorkScheduleAlert from "../alert_dialogue/WorkScheduleAlert";
import CallInfoDialog from "../call/CallInfoDialog";
import { callInfoSheetScope } from "../../utils/enum";
import CallLogModal from "../call_log_modal/CallLogModal";
import moment from "moment";

const drawerWidth = 275;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const Logo = styled("img")(({ theme }) => ({
  // maxWidth: "120px",
  maxHeight: "16px",
  // height: "auto",
  width: "auto",
  cursor: "pointer",
}));
const SearchBarWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "40px",
  width: "100%",
  padding: "0px 20px",
}));
const ListWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
}));
const ProfileWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
}));
const SearchInput = styled("input")(({ theme }) => ({
  height: "40px",
  border: "none",
  outline: "none",
  width: "100%",
  color: "#4D4E4F",
  font: "normal normal 500 14px Poppins",
  padding: "2px",
  backgroundColor: "#EFEFF4",
  "&::placeholder": {
    fontSize: "14px",
    color: "#2B2B2B",
  },
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const useStyles = makeStyles({
  blink: {
    animation: "$blink 1s linear infinite",
  },
  "@keyframes blink": {
    "0%": {
      opacity: 0,
      transform: "scale(1)",
    },
    "50%": {
      opacity: 1,
      transform: "scale(1.5)",
    },
    "100%": {
      opacity: 0,
      transform: "scale(1)",
    },
  },
});

const Appbar = (props) => {
  // let myRef = useRef(null);
  const classes = useStyles();
  const theme = useTheme();
  const { userStore, authStore, filterStore, phoneCallState } = rootStore;
  let pid = authStore.projectId;
  let tid = authStore.teamId;
  let tabId = userStore.TabId;
  const [openCsv, setOpenCsv] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [openMarket, setOpenMarket] = useState(false);
  const [openUserSetting, setOpenUserSetting] = useState(false);
  const [openLeadModal, setOpenLeadModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notify, setNotify] = useState(true);
  const [notifyCount, setNotifyCount] = useState(0);
  const openMenu = Boolean(anchorEl);
  const [notifyData, setNotifyData] = useState([]);
  const location = useLocation();
  const [moduleList, setModuleList] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isInWorkHours, setIsInWorkHours] = useState(false);
  const [showAdmin, setShowAdmin] = useState(false);
  const [showAdminProject, setShowAdminProject] = useState(false);
  const [openCallInfo, setOpenCallInfo] = useState(false);
  const [openCallLogModal, setOpenCallLogModal] = useState(false);
  const [statusList, setStatusList] = useState();
  const [leadData, setLeadData] = useState({});

  const [openAdminProjectMenu, setOpenAdminProjectMenu] = useState(false);
  const [expandSalesMenu, setExpandSalesMenu] = useState(true);
  const [callInfoScope, setCallInfoScope] = useState(
    callInfoSheetScope.home_call_icon
  );
  const uid = localStorage.getItem("uid");

  const view = userStore.view;
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  let navigate = useNavigate();
  const [query, setQuery] = useState("");
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleImport = () => {
    setOpenImport(!openImport);
  };
  const handleUserSetting = () => {
    setOpenUserSetting(!openUserSetting);
  };
  const handleSalesMenu = () => {
    setExpandSalesMenu(!expandSalesMenu);
  };
  const handleAdminProjectMenu = () => {
    setOpenAdminProjectMenu(!openAdminProjectMenu);
  };
  const handleMarket = () => {
    setOpenMarket(!openMarket);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleChange = async (e) => {
    if (phoneCallState.hasPendingCallLog()) {
      setOpenCallInfo(true);
      setCallInfoScope(callInfoSheetScope.home_project);
    } else {
      navigate("/");
      rootStore.leadStore.resetTabInfo();
      filterStore.updateTabValue(0);
      userStore.resetCurrentFilter();
      userStore.resetRawLeadFilters();
      userStore.updateFilterDrawerState(true);
      props.setIsFiltered(false);
      userStore.updateCurrentLoadedFilter({});
      authStore.updateOnProjectChange(e.target.value);
    }
  };

  const clearLocalStorageWithoutDeviceID = () => {
    let device_id = localStorage.getItem("device_id");
    localStorage.clear();
    localStorage.setItem("device_id", device_id);
  };

  const handleLogout = async () => {
    try {
      let response = await userLogoutApi();
      navigate("/intro");
      userStore.resetCurrentFilter();
      userStore.resetRawLeadFilters();
      userStore.updateFilterDrawerState(true);
      clearLocalStorageWithoutDeviceID();
      props.setIsFiltered(false);
      authStore.resetUserAuth();
      userStore.getData(null);
      userStore.resetSavedFilterTemplateList();
      userStore.resetTabCounterList();
      phoneCallState.reset();
      rootStore.leadStore.resetTabInfo();
    } catch (error) {
      authStore.resetUserAuth();
      userStore.getData(null);
      phoneCallState.reset();
      clearLocalStorageWithoutDeviceID();
      props.setIsFiltered(false);
      userStore.resetCurrentFilter();
      userStore.resetRawLeadFilters();
      userStore.updateFilterDrawerState(true);
      userStore.resetSavedFilterTemplateList();
      userStore.resetTabCounterList();
      rootStore.leadStore.resetTabInfo();
      navigate("/intro");
      console.log(error);
    }
  };

  const handleTeam = (e) => {
    navigate("/");
    authStore.setTeamId(e.target.value);
  };

  const MINUTE_MS = 60000;

  const shouldAdminAndProjectVisible = async () => {
    try {
      let response = await getAdminProjectApi();
      if (response.data.length > 0) {
        setShowAdmin(true);
        setShowAdminProject(true);
      } else {
        if (userStore.UserData !== null) {
          if (
            userStore.UserData.role === "admin" ||
            userStore.UserData.role === "owner"
          ) {
            setShowAdmin(true);
          }
        }
      }
    } catch (error) {
      if (error.response.request.status !== 401) {
        setIsError(true);
      }
    }
  };

  const getNotications = async () => {
    try {
      let response = await getNotificationsApi();
      userStore.resetBlackList();
      setNotifyData(response.data.items);
      setNotifyCount(response.data.items_count);
      if (response.data.items_count !== 0) {
        setNotify(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleNotification = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const isQuarterHour = () => {
    const currentMinute = moment().minute();
    return currentMinute % 15 === 0;
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (isQuarterHour()) {
        getNotications();
        updateUserActiveStatus();
      }
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, []);

  const getUserActiveStatus = () => {
    if (authStore.lastActiveTime !== "") {
      const currentTime = moment();
      const lastActiveMoment = moment(authStore.lastActiveTime);
      const diffInMinutes = currentTime.diff(lastActiveMoment, "minutes");
      if (diffInMinutes >= 15) {
        return "Inactive";
      } else {
        return "Active";
      }
    }
    return "Inactive";
  };

  const updateUserActiveStatus = async () => {
    let payload = { status: getUserActiveStatus() };
    let response = await sendUserActiveStatus(payload);
    consoleLogger(response);
  };

  useEffect(() => {
    const getData = async () => {
      if (userStore.UserData !== null) {
        let userAcc = userStore.UserData.account;
        if (userAcc.modules !== null) {
          let str = userAcc.modules;
          let newList = str.split(";");
          setModuleList(newList);
        }
      }
    };

    if (location.pathname !== "/login" && location.pathname !== "/intro") {
      getData();
      shouldAdminAndProjectVisible();
    }
    if (pid !== null) {
      updateViewList();
      getNotications();
    }
  }, [pid]);

  const handleQuery = () => {
    if (query !== "") {
      authStore.updateSearchQuery(query);
      setQuery("");
      navigate("/");
      props.setIsFiltered(true);
    }
  };
  useEffect(() => {
    if (phoneCallState.showProgressiveCallDialog && !openCallLogModal) {
      setOpenCallInfo(true);
      setCallInfoScope(callInfoSheetScope.home_call_icon);
    }
  }, [phoneCallState.callStatus, phoneCallState.showProgressiveCallDialog]);

  const canShowCallBadge = () => {
    consoleLogger(
      `can show call badge ::: ${JSON.stringify(phoneCallState.callStatus)}`
    );
    if (phoneCallState.callStatus.hasOwnProperty("status")) {
      if (phoneCallState.callStatus["status"] === "completed") {
        return true;
      }
    }
    return false;
  };

  const getCallStatusLeadID = () => {
    /*
    this func is being used, because on submit call log, we are resetting call status
    So if we directly pass callstatus lead id, it will result in white screen
    */
    if (phoneCallState.callStatus.hasOwnProperty("lead")) {
      return phoneCallState.callStatus["lead"]["id"];
    } else {
      return "";
    }
  };

  const userAppLogoGetter = () => {
    if (userStore.UserData !== null) {
      if (userStore.UserData.hasOwnProperty("account")) {
        let account_info = userStore.UserData["account"];
        let logo_url = account_info.hasOwnProperty("logo_url")
          ? account_info["logo_url"]
          : "";
        return logo_url !== null && logo_url !== "" ? logo_url : appLogo;
      }
    }
    return appLogo;
  };

  const canShowProjectAdministration = () => {
    let accountRole =
      userStore.UserData !== null ? userStore.UserData.role : "";
    let projectRole = authStore.getCurrentProjectRole();
    if (accountRole === "admin" || accountRole === "owner") {
      return true;
    } else {
      if (projectRole !== "member") {
        return true;
      }
      return false;
    }
  };

  const canShowProjectAdministrationSubmenu = (menuName) => {
    let accountRole =
      userStore.UserData !== null ? userStore.UserData.role : "";
    let projectRole = authStore.getCurrentProjectRole();
    if (accountRole === "admin" || accountRole === "owner") {
      return true;
    } else {
      if (projectRole !== "member") {
        if (projectRole === "manager") {
          return true;
        }
        return TeamLeadAccessNameList.includes(menuName);
      }
      return false;
    }
  };

  const canShowWebFormsTile = () => {
    let accountName =
      userStore.UserData !== null ? userStore.UserData.account.name : "";
    return accountName.toLowerCase() === "demo";
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
          style={{ backgroundColor: "white", height: "64px" }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 0,
                pointerEvents: props.open ? "none" : "unset",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon style={{ color: "#4D4E4F" }} />
            </IconButton>
            <ListItem
              style={{
                justifyContent: "space-between",
                width: "100%",
                paddingRight: "20px",
              }}
              // ref={myRef}
            >
              <Logo src={userAppLogoGetter()} />
              <ListItem
                style={{
                  boxShadow: "0px 4px 10px #B8B8CE26",
                  border: "1px solid #E7E7F0",
                  borderRadius: "8px",
                  height: "40px",
                  backgroundColor: "#EFEFF4",
                  padding: "0px",
                  position: "relative",
                  width: "400px",
                  marginLeft: "75px",
                }}
              >
                <SearchBarWrapper style={{ backgroundColor: "#EFEFF4" }}>
                  <Search
                    style={{
                      width: "22px",
                      height: "22px",
                      color: "#4D4E4F",
                      marginRight: "4px",
                    }}
                  />
                  <SearchInput
                    placeholder="Search"
                    value={query}
                    onChange={(e) => {
                      isUnderWorkSchedule()
                        ? setQuery(e.target.value)
                        : setIsInWorkHours(true);
                    }}
                    onKeyUp={(e) => (e.key === "Enter" ? handleQuery() : null)}
                  />
                  <Tune
                    style={{
                      width: "22px",
                      height: "22px",
                      color: "#4D4E4F",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      userStore.updateFilterDrawerState(true);
                      if (isUnderWorkSchedule()) {
                        navigate("/filter-page");
                      } else {
                        setIsInWorkHours(true);
                      }
                    }}
                  />
                </SearchBarWrapper>
                {props.open &&
                  (view === "All Leads" ||
                  view === "My Leads" ||
                  view === "All Businesses" ||
                  view === "My Businesses" ? (
                    <MainFilterComponent
                      isFiltered={props.isFiltered}
                      open={props.open}
                      setOpen={props.setOpen}
                      setRow={props.setRow}
                      setCount={props.setCount}
                      setLoading={props.setLoading}
                      setIsFiltered={props.setIsFiltered}
                      setPage={props.setPage}
                      setType={props.setType}
                      row={props.row}
                      type={props.type}
                    />
                  ) : (
                    <FilterComponent
                      open={props.open}
                      setOpen={props.setOpen}
                    />
                  ))}
              </ListItem>
              {/* <LogoTitle>Lead Pixie</LogoTitle> */}
            </ListItem>
            <ListItem
              style={{
                justifyContent: "flex-end",
                width: "fit-content",
                paddingLeft: "0px",
              }}
              sx={{
                pointerEvents: props.open ? "none" : "unset",
              }}
            >
              {phoneCallState.hasPendingCallLog() && (
                <IconButton
                  onClick={() => {
                    consoleLogger(
                      "Phone state ::: post submit calllog ::: call end button"
                    );

                    phoneCallState.postSubmitCallLog();
                  }}
                  disabled={!phoneCallState.dispositionFailed}
                  // style={{ marginRight: "16px" }}
                >
                  <PhoneDisabledIcon
                    color={
                      phoneCallState.dispositionFailed ? "error" : "disabled"
                    }
                  />
                </IconButton>
              )}
              {phoneCallState.hasPendingCallLog() && (
                <IconButton
                  onClick={() => {
                    phoneCallState.fetchUserStatus();
                    setOpenCallInfo(true);
                    setCallInfoScope(callInfoSheetScope.home_call_icon);
                  }}
                  style={{ marginRight: "16px" }}
                >
                  {canShowCallBadge() ? (
                    <Badge
                      variant="dot"
                      badgeContent=" "
                      color="error"
                      invisible={!canShowCallBadge()}
                    >
                      <Call color="primary" />
                    </Badge>
                  ) : (
                    <Call color="primary" className={classes.blink} />
                  )}
                </IconButton>
              )}
              {toJS(authStore.projectList).length > 0 && (
                <Select
                  disableUnderline
                  value={pid}
                  variant="standard"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  label="Select Member"
                  onChange={handleChange}
                  style={{
                    minWidth: "125px",
                    backgroundColor: "#EFEFF4",
                    padding: "0px 8px",
                    marginRight: "20px",
                    fontSize: "14px",
                    borderRadius: "4px",
                    height: "40px",
                  }}
                >
                  {toJS(authStore.projectList).map((e) => {
                    return (
                      <MenuItem
                        style={{ fontSize: "14px" }}
                        id={e.id}
                        value={e.id}
                      >
                        {e.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
              {authStore.currentProject !== null && tid !== null && (
                <Select
                  disableUnderline
                  variant="standard"
                  value={tid}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  label="Select Member"
                  onChange={(e) =>
                    isUnderWorkSchedule()
                      ? handleTeam(e)
                      : setIsInWorkHours(true)
                  }
                  style={{
                    minWidth: "125px",
                    backgroundColor: "#EFEFF4",
                    padding: "0px 8px",
                    fontSize: "14px",
                    borderRadius: "4px",
                    height: "40px",
                  }}
                >
                  {authStore.currentProject.hasOwnProperty("teams") &&
                    authStore.currentProject["teams"].length > 0 &&
                    authStore.currentProject["teams"].map((e) => {
                      return (
                        <MenuItem
                          style={{ fontSize: "14px" }}
                          id={e.id}
                          value={e.id}
                        >
                          {e.name}
                        </MenuItem>
                      );
                    })}
                  ;
                </Select>
              )}
              <IconButton
                onClick={(e) =>
                  isUnderWorkSchedule()
                    ? handleNotification(e)
                    : setIsInWorkHours(true)
                }
                aria-controls={openMenu ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? "true" : undefined}
              >
                <Badge
                  badgeContent={notifyCount}
                  color="error"
                  invisible={notify && notifyCount === 0}
                >
                  <Notifications color="action" />
                </Badge>
              </IconButton>
            </ListItem>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <ListItem
              disablePadding
              sx={{
                display: "flex",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 1 : "auto",
                  justifyContent: "center",
                }}
              >
                <AccountCircle style={{ width: "50px", height: "50px" }} />
              </ListItemIcon>
              <ProfileWrapper>
                <p
                  style={{
                    margin: "0px",
                    fontWeight: "bold",
                    opacity: open ? "1" : "0",
                  }}
                >
                  {userStore.UserData !== null ? userStore.UserData.name : ""}
                </p>
                <p
                  style={{
                    color: "#949494",
                    fontSize: "14px",
                    margin: "0px",
                    fontWeight: "bold",
                    opacity: open ? "1" : "0",
                  }}
                >
                  {userStore.UserData !== null
                    ? userStore.UserData.account.name
                    : ""}
                </p>
              </ProfileWrapper>
            </ListItem>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightOutlined />
              ) : (
                <ChevronLeftOutlined />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <ListWrapper style={{ pointerEvents: props.open ? "none" : "unset" }}>
            <List>
              {/* <ListItem
                disablePadding
                sx={{ display: "block" }}
                onClick={() => {
                  if (props.isFiltered) {
                    props.setIsFiltered(false);
                    navigate("/");
                  } else {
                    navigate("/dashboard");
                  }
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <GridView />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Dashboard"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem> */}

              <ListItem
                disablePadding
                sx={{ display: "block" }}
                onClick={() => {
                  navigate("/summary");
                }}
              >
                <ListItemButton
                  selected={location.pathname === "/summary"}
                  sx={{
                    color:
                      location.pathname === "/summary" ? "#185DD2" : "#212121",
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color:
                        location.pathname === "/summary"
                          ? "#185DD2"
                          : "#757575",
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Dashboard />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Dashboard"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>

              {/* ****************************Sales Menu********************************* */}
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton onClick={handleSalesMenu}>
                  <ListItemIcon style={{ marginLeft: "4px" }}>
                    <Business />
                  </ListItemIcon>
                  <ListItemText
                    style={{ marginLeft: "-6px" }}
                    primary="Sales"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                  {expandSalesMenu ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={expandSalesMenu} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton
                      selected={location.pathname === "/"}
                      sx={{
                        color:
                          location.pathname === "/" ? "#185DD2" : "#212121",
                        pl: 4,
                      }}
                      onClick={() => {
                        if (props.isFiltered) {
                          props.setIsFiltered(false);
                          navigate("/");
                        } else {
                          navigate("/");
                        }
                      }}
                    >
                      <ListItemIcon
                        style={{
                          color:
                            location.pathname === "/" ? "#185DD2" : "#757575",
                        }}
                      >
                        <ListAlt />
                      </ListItemIcon>
                      <ListItemText
                        primary={getLocalizedText("leads", "project")}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>

                    <ListItemButton
                      sx={{
                        color:
                          location.pathname === "/deals"
                            ? "#185DD2"
                            : "#212121",
                        pl: 4,
                      }}
                      selected={location.pathname === "/deals"}
                      onClick={() => {
                        navigate("/deals");
                      }}
                    >
                      <ListItemIcon
                        style={{
                          color:
                            location.pathname === "/deals"
                              ? "#185DD2"
                              : "#757575",
                        }}
                      >
                        <LocalOffer />
                      </ListItemIcon>
                      <ListItemText
                        primary="Deals"
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </List>
                </Collapse>
              </ListItem>

              {/* **********************************END********************************** */}

              {/* marketing section ********************************************************/}

              {moduleList.includes("marketing") &&
                checkFeaturePermission("marketing") && (
                  <ListItem disablePadding sx={{ display: "block" }}>
                    <ListItemButton onClick={handleMarket}>
                      <ListItemIcon style={{ marginLeft: "4px" }}>
                        <ConnectWithoutContact />
                      </ListItemIcon>
                      <ListItemText
                        style={{ marginLeft: "-6px" }}
                        primary="Marketing"
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                      {openMarket ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openMarket} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItemButton
                          selected={location.pathname === "/segments"}
                          sx={{
                            color:
                              location.pathname === "/segments"
                                ? "#185DD2"
                                : "#212121",
                            pl: 4,
                          }}
                          onClick={() => {
                            navigate("/segments");
                          }}
                        >
                          <ListItemIcon
                            style={{
                              color:
                                location.pathname === "/segments"
                                  ? "#185DD2"
                                  : "#757575",
                            }}
                          >
                            <Segment />
                          </ListItemIcon>
                          <ListItemText
                            primary="Segments"
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                        <ListItemButton
                          sx={{
                            color:
                              location.pathname === "/campaigns"
                                ? "#185DD2"
                                : "#212121",
                            pl: 4,
                          }}
                          selected={location.pathname === "/campaigns"}
                          onClick={() => {
                            navigate("/campaigns");
                          }}
                        >
                          <ListItemIcon
                            style={{
                              color:
                                location.pathname === "/campaigns"
                                  ? "#185DD2"
                                  : "#757575",
                            }}
                          >
                            <Campaign />
                          </ListItemIcon>
                          <ListItemText
                            primary="Campaigns"
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                        {/* <ListItemButton
                      sx={{
                        color:
                          location.pathname === "/website-templates"
                            ? "#185DD2"
                            : "#212121",
                        pl: 4,
                      }}
                      selected={location.pathname === "/website-templates"}
                      onClick={() => {
                        navigate("/website-templates");
                      }}
                    >
                      <ListItemIcon
                        style={{
                          color:
                            location.pathname === "/website-templates"
                              ? "#185DD2"
                              : "#757575",
                        }}
                      >
                        <LibraryBooks />
                      </ListItemIcon>
                      <ListItemText
                        primary="Website Templates"
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton> */}
                        {/* <ListItemButton
                      sx={{
                        color:
                          location.pathname === "/digital-media"
                            ? "#185DD2"
                            : "#212121",
                        pl: 4,
                      }}
                      selected={location.pathname === "/digital-media"}
                      onClick={() => {
                        navigate("/digital-media");
                      }}
                    >
                      <ListItemIcon
                        style={{
                          color:
                            location.pathname === "/digital-media"
                              ? "#185DD2"
                              : "#757575",
                        }}
                      >
                        <Collections />
                      </ListItemIcon>
                      <ListItemText
                        primary="Digital Media"
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton> */}

                        {canShowWebFormsTile() && (
                          <ListItemButton
                            sx={{
                              color:
                                location.pathname === "/web-forms"
                                  ? "#185DD2"
                                  : "#212121",
                              pl: 4,
                            }}
                            selected={location.pathname === "/web-forms"}
                            onClick={() => {
                              navigate("/web-forms");
                            }}
                          >
                            <ListItemIcon
                              style={{
                                color:
                                  location.pathname === "/web-forms"
                                    ? "#185DD2"
                                    : "#757575",
                              }}
                            >
                              <DynamicForm />
                            </ListItemIcon>
                            <ListItemText
                              primary="Web Forms"
                              sx={{ opacity: open ? 1 : 0 }}
                            />
                          </ListItemButton>
                        )}

                        {/* <ListItemButton
                          sx={{
                            color:
                              location.pathname === "/web-page/create"
                                ? "#185DD2"
                                : "#212121",
                            pl: 4,
                          }}
                          selected={location.pathname === "/web-page/create"}
                          onClick={() => {
                            navigate("/web-page/create");
                          }}
                        >
                          <ListItemIcon
                            style={{
                              color:
                                location.pathname === "/web-page/create"
                                  ? "#185DD2"
                                  : "#757575",
                            }}
                          >
                            <QueuePlayNext />
                          </ListItemIcon>
                          <ListItemText
                            primary="Create Web Page"
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton> */}
                      </List>
                    </Collapse>
                  </ListItem>
                )}

              {checkFeaturePermission("reports") && (
                <ListItem
                  disablePadding
                  sx={{ display: "block" }}
                  onClick={() => {
                    getFilterColsApi();

                    navigate("/reports");
                  }}
                >
                  <ListItemButton
                    selected={location.pathname === "/reports"}
                    sx={{
                      color:
                        location.pathname === "/reports"
                          ? "#185DD2"
                          : "#212121",
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color:
                          location.pathname === "/reports"
                            ? "#185DD2"
                            : "#757575",

                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <Assessment />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Reports"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              )}
              {checkFeaturePermission("imports") && (
                <ListItem
                  disablePadding
                  sx={{ display: "block" }}
                  onClick={() => {
                    navigate("/imports");
                  }}
                >
                  <ListItemButton
                    selected={location.pathname === "/imports"}
                    sx={{
                      color:
                        location.pathname === "/imports"
                          ? "#185DD2"
                          : "#212121",
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color:
                          location.pathname === "/imports"
                            ? "#185DD2"
                            : "#757575",

                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <Upload />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Imports"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              )}

              {/* <ListItem
                    disablePadding
                    sx={{ display: "block" }}
                    onClick={() => {
                      navigate("/marketing");
                    }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <Campaign />
                      </ListItemIcon>
                      <ListItemText
                        primary={"Marketing"}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem> */}

              {moduleList.includes("tva") &&
                checkFeaturePermission("target") && (
                  <ListItem
                    disablePadding
                    sx={{ display: "block" }}
                    onClick={() => {
                      navigate("/target");
                    }}
                  >
                    <ListItemButton
                      selected={location.pathname === "/target"}
                      sx={{
                        color:
                          location.pathname === "/target"
                            ? "#185DD2"
                            : "#212121",
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          color:
                            location.pathname === "/target"
                              ? "#185DD2"
                              : "#757575",
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <TrackChanges />
                      </ListItemIcon>
                      <ListItemText
                        primary={"Target"}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
              {/* <Divider /> */}

              {showAdmin && (
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton onClick={handleImport}>
                    <ListItemIcon style={{ marginLeft: "4px" }}>
                      <AdminPanelSettings />
                    </ListItemIcon>
                    <ListItemText
                      style={{ marginLeft: "-6px" }}
                      primary="Administration"
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                    {openImport ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={openImport} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {/* <ListItemButton
                        selected={
                          location.pathname === "/admin/account-settings"
                        }
                        sx={{
                          color:
                            location.pathname === "/admin/account-settings"
                              ? "#185DD2"
                              : "#212121",
                          pl: 4,
                        }}
                        onClick={() => {
                          navigate("/admin/account-settings");
                        }}
                      >
                        <ListItemIcon
                          style={{
                            color:
                              location.pathname === "/admin/account-settings"
                                ? "#185DD2"
                                : "#757575",
                          }}
                        >
                          <ManageAccounts />
                        </ListItemIcon>
                        <ListItemText
                          primary="General Settings"
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton> */}
                      {checkFeaturePermission("administrator_users") && (
                        <ListItemButton
                          selected={location.pathname === "/admin/users"}
                          sx={{
                            color:
                              location.pathname === "/admin/users"
                                ? "#185DD2"
                                : "#212121",
                            pl: 4,
                          }}
                          onClick={() => {
                            navigate("/admin/users");
                          }}
                        >
                          <ListItemIcon
                            style={{
                              color:
                                location.pathname === "/admin/users"
                                  ? "#185DD2"
                                  : "#757575",
                            }}
                          >
                            <Group />
                          </ListItemIcon>
                          <ListItemText
                            primary="Users"
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      )}

                      {moduleList.includes("raw_leads") && (
                        <ListItemButton
                          sx={{
                            color:
                              location.pathname === "/admin/raw-leads"
                                ? "#185DD2"
                                : "#212121",
                            pl: 4,
                          }}
                          selected={location.pathname === "/admin/raw-leads"}
                          onClick={() => {
                            navigate("/admin/raw-leads");
                          }}
                        >
                          <ListItemIcon
                            style={{
                              color:
                                location.pathname === "/admin/raw-leads"
                                  ? "#185DD2"
                                  : "#757575",
                            }}
                          >
                            <DriveFolderUpload />
                          </ListItemIcon>
                          <ListItemText
                            primary="Raw Leads"
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      )}
                      <ListItemButton
                        selected={location.pathname === "/admin/ip-config"}
                        sx={{
                          color:
                            location.pathname === "/admin/ip-config"
                              ? "#185DD2"
                              : "#212121",
                          pl: 4,
                        }}
                        onClick={() => {
                          navigate("/admin/ip-config");
                        }}
                      >
                        <ListItemIcon
                          style={{
                            color:
                              location.pathname === "/admin/ip-config"
                                ? "#185DD2"
                                : "#757575",
                          }}
                        >
                          <CellTower />
                        </ListItemIcon>
                        <ListItemText
                          primary="IP Whitelisting"
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>

                      {/* <ListItemButton
                          selected={location.pathname === "/admin/billing"}
                          sx={{
                            color:
                              location.pathname === "/admin/billing"
                                ? "#185DD2"
                                : "#212121",
                            pl: 4,
                          }}
                          onClick={() => {
                            navigate("/admin/billing");
                          }}
                        >
                          <ListItemIcon
                            style={{
                              color:
                                location.pathname === "/admin/billing"
                                  ? "#185DD2"
                                  : "#757575",
                            }}
                          >
                            <AccountBalanceWallet />
                          </ListItemIcon>
                          <ListItemText
                            primary="Billing"
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton> */}
                    </List>
                  </Collapse>
                </ListItem>
              )}

              {/* **************************project administration link tile with submenu************************************* */}
              {canShowProjectAdministration() && (
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton onClick={handleAdminProjectMenu}>
                    <ListItemIcon style={{ marginLeft: "4px" }}>
                      <BusinessCenter />
                    </ListItemIcon>
                    <ListItemText
                      style={{ marginLeft: "-6px" }}
                      primary="Project Administration"
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                    {openAdminProjectMenu ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse
                    in={openAdminProjectMenu}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {AdminProjectMenuList.map((menuData) => {
                        if (
                          canShowProjectAdministrationSubmenu(menuData.name)
                        ) {
                          return (
                            <ListItemButton
                              sx={{
                                color:
                                  location.pathname === menuData.path
                                    ? "#185DD2"
                                    : "#212121",
                                pl: 4,
                              }}
                              selected={location.pathname === menuData.path}
                              onClick={() => {
                                navigate(menuData.path);
                              }}
                            >
                              <ListItemIcon
                                style={{
                                  color:
                                    location.pathname === menuData.path
                                      ? "#185DD2"
                                      : "#757575",
                                }}
                              >
                                {menuData.icon}
                              </ListItemIcon>
                              <ListItemText
                                primary={menuData.name}
                                sx={{ opacity: open ? 1 : 0 }}
                              />
                            </ListItemButton>
                          );
                        }
                      })}
                    </List>
                  </Collapse>
                </ListItem>
              )}
              {/* ****************************************END***************************************************************** */}

              {/* ******************************** Products, Contacts page menu tiles***********************************  */}

              <ListItem
                disablePadding
                sx={{ display: "block" }}
                onClick={() => {
                  navigate("/products");
                }}
              >
                <ListItemButton
                  selected={location.pathname === "/products"}
                  sx={{
                    color:
                      location.pathname === "/products" ? "#185DD2" : "#212121",
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color:
                        location.pathname === "/products"
                          ? "#185DD2"
                          : "#757575",
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <ProductionQuantityLimits />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Products"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                sx={{ display: "block" }}
                onClick={() => {
                  navigate("/contacts");
                }}
              >
                <ListItemButton
                  selected={location.pathname === "/contacts"}
                  sx={{
                    color:
                      location.pathname === "/contacts" ? "#185DD2" : "#212121",
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color:
                        location.pathname === "/contacts"
                          ? "#185DD2"
                          : "#757575",
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Contacts />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Contacts"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                sx={{ display: "block" }}
                onClick={() => {
                  navigate("/organizations");
                }}
              >
                <ListItemButton
                  selected={location.pathname === "/organizations"}
                  sx={{
                    color:
                      location.pathname === "/organizations"
                        ? "#185DD2"
                        : "#212121",
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color:
                        location.pathname === "/organizations"
                          ? "#185DD2"
                          : "#757575",
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <CorporateFare />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Organizations"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>

              {/* *******************************END*****************************************              */}

              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton onClick={handleUserSetting}>
                  <ListItemIcon style={{ marginLeft: "4px" }}>
                    <Settings />
                  </ListItemIcon>
                  <ListItemText
                    style={{ marginLeft: "-6px" }}
                    primary="Settings"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                  {openUserSetting ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openUserSetting} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton
                      selected={location.pathname === "/account-setting"}
                      sx={{
                        color:
                          location.pathname === "/account-setting"
                            ? "#185DD2"
                            : "#212121",
                        pl: 4,
                      }}
                      onClick={() => {
                        navigate("/account-setting");
                      }}
                    >
                      <ListItemIcon
                        style={{
                          color:
                            location.pathname === "/account-setting"
                              ? "#185DD2"
                              : "#757575",
                        }}
                      >
                        <ManageAccountsOutlined />
                      </ListItemIcon>
                      <ListItemText
                        primary="User Settings"
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                    {validateDisabledFeature("checkins") && (
                      <ListItemButton
                        sx={{
                          color:
                            location.pathname === "/checkin"
                              ? "#185DD2"
                              : "#212121",
                          pl: 4,
                        }}
                        selected={location.pathname === "/checkin"}
                        onClick={() => {
                          navigate("/checkin");
                        }}
                      >
                        <ListItemIcon
                          style={{
                            color:
                              location.pathname === "/checkin"
                                ? "#185DD2"
                                : "#757575",
                          }}
                        >
                          <Place />
                        </ListItemIcon>
                        <ListItemText
                          primary="CheckIn"
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    )}
                  </List>
                </Collapse>
              </ListItem>
              <Divider />
            </List>

            <List>
              <Divider />
              {/* <ListItem
                disablePadding
                sx={{ display: "block" }}
                onClick={() => {
                  navigate("/support");
                }}
              >
                <ListItemButton
                  selected={location.pathname === "/support"}
                  sx={{
                    color:
                      location.pathname === "/support" ? "#185DD2" : "#212121",
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color:
                        location.pathname === "/support"
                          ? "#185DD2"
                          : "#757575",
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <ContactSupport />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Support"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem> */}
              <ListItem
                disablePadding
                sx={{ display: "block" }}
                onClick={handleLogout}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <ExitToApp />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Logout"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </ListWrapper>
        </Drawer>
      </Box>
      {openCsv && <UploadModal open={openCsv} setOpen={setOpenCsv} />}

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openMenu}
        onClose={handleMenuClose}
        // onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            bgcolor: "#f9f9fc",
            borderRadius: "0px",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <NotificationComponent
          setNotify={setNotify}
          data={notifyData}
          isFiltered={props.isFiltered}
          open={props.open}
          setOpen={props.setOpen}
          setRow={props.setRow}
          setCount={props.setCount}
          setLoading={props.setLoading}
          setIsFiltered={props.setIsFiltered}
          setPage={props.setPage}
          setType={props.setType}
          row={props.row}
          type={props.type}
          setAnchorEl={setAnchorEl}
          setNotifyCount={setNotifyCount}
          notifyCount={notifyCount}
        />
      </Menu>
      {isError && <AlertDialogue open={isError} setOpen={setIsError} />}
      {isInWorkHours && (
        <WorkScheduleAlert open={isInWorkHours} setOpen={setIsInWorkHours} />
      )}
      {openCallInfo && (
        <CallInfoDialog
          open={openCallInfo}
          setOpen={setOpenCallInfo}
          scope={callInfoScope}
          // openCallLogCallback={async () => {
          //   let lead_id = getCallStatusLeadID();
          //   navigate(`/users/${uid}/leads/${lead_id}`, {
          //     state: { scope: "call_info_dialog_submit_log" },
          //   });
          //   setOpenCallInfo(false);
          // }}
          openCallLogCallback={async () => {
            const getLeadDetails = async () => {
              let leadID = getCallStatusLeadID();
              let response = await getLeadProfileApi(leadID);
              setLeadData(response.data.lead);
            };

            await getLeadDetails();
            setOpenCallLogModal(true);
            setOpenCallInfo(false);
          }}
        />
      )}
      {openCallLogModal && (
        <CallLogModal
          scope={"app_bar"}
          open={openCallLogModal}
          setOpen={setOpenCallLogModal}
          lid={getCallStatusLeadID()}
          uid={uid}
          leadData={leadData}
        />
      )}
    </>
  );
};
export default observer(Appbar);
