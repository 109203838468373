import { Box, Modal } from "@material-ui/core";
import React from "react";
import { styled } from "@mui/material/styles";
import { Button, Typography } from "@mui/material";
import { Key } from "@mui/icons-material";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
  row-gap: 16px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;

const Text = styled(Typography)`
  font: normal normal 500 16px Open Sans;
  color: #4d4e4f;
`;

const ViewPasswordModal = (props) => {
  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Container>
            <Box
              style={{
                width: "100%",
                display: "flex",
                columnGap: "6px",
                alignItems: "center",
              }}
            >
              <Key />
              <Text>{props.data["file_password"]}</Text>
            </Box>
            <Box
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <Button
                color="primary"
                variant="contained"
                style={{
                  backgroundColor: "#185DD2",
                  textTransform: "capitalize",
                  fontWeight: "bold",
                  width: "100px",

                  color: "white",
                }}
                onClick={handleClose}
              >
                Close
              </Button>
            </Box>
          </Container>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default ViewPasswordModal;
