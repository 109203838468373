import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getListApi } from "../../Api";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import { Box, Typography, FormControl, Select } from "@mui/material";

import { getSlug } from "../../Functions";
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
`;
const SelectFieldSlug = (props) => {
  const [list, setList] = useState([]);
  const [listType, setListType] = useState(null);

  const init = async (slug) => {
    let response = await getListApi({ slug: slug });
    if (response !== null) {
      if (Array.isArray(response)) {
        setListType("list");
        setList(response);
      } else if (typeof response === "object") {
        if (Object.keys(response).length > 0) {
          setListType(response["type"]);
          setList(response["values"]);
        }
      }
    } else {
      setListType(null);
      setList([]);
    }
  };

  useEffect(() => {
    let slug = getSlug(props.item.filter_input);
    init(slug);
  }, []);
  return (
    <>
      <InputWrapper>
        <Label>
          Select {props.label}
          {props.required && <span>*</span>}
        </Label>
        <FormControl
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none", // Remove the border
            },
          }}
        >
          <Select
            disableUnderline
            displayEmpty
            variant="outlined"
            name={props.field}
            label="Select Member"
            onChange={(e) => props.handleChange(e)}
            style={{
              width: "100%",
              fontSize: "12px",
              height: "30px",
              borderRadius: "4px",
            }}
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.23)", // Ensure border is defined
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(0, 0, 0, 0.23)",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#000", // Change the color on hover
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#1976d2", // Default MUI primary color on focus
                borderWidth: "2px", // Ensure it stands out clearly
              },
            }}
            readOnly={props.readOnly}
            value={
              props.value.hasOwnProperty(props.field)
                ? props.value[props.field]["value"]
                : ""
            }
            required={props.required}
          >
            {list.map((item, index) => {
              if (listType !== null) {
                if (listType === "list") {
                  return (
                    <MenuItem id={index} key={index} value={item}>
                      {item}
                    </MenuItem>
                  );
                } else if (listType === "list_objects") {
                  return (
                    <MenuItem id={index} key={index} value={item.value}>
                      {item.name}
                    </MenuItem>
                  );
                }
              }
            })}
          </Select>
        </FormControl>
      </InputWrapper>
    </>
  );
};

export default SelectFieldSlug;
