import React from "react";
import { Menu, MenuItem } from "@mui/material";
import { leadContextMenuItems } from "../../Db";

const LeadContextMenu = ({
  anchor,
  handleMenuClick,
  handleClose,
  isSetLeadColorContextMenuDisabled,
  isOpenCallLogDisabled,
}) => {
  const open = Boolean(anchor);
  const isMenuDisabled = (menu) => {
    if (menu === "set_lead_color") {
      return isSetLeadColorContextMenuDisabled();
    } else if (menu === "add_call_log") {
      return isOpenCallLogDisabled();
    } else if (menu === "add_remarks") {
      return false;
    }
  };
  return (
    <Menu
      style={{
        display: "flex",
        flexDirection: "column",
      }}
      id="long-menu"
      MenuListProps={{
        "aria-labelledby": "long-button",
      }}
      anchorReference="anchorPosition"
      anchorPosition={
        anchor !== null
          ? { top: anchor.mouseY, left: anchor.mouseX }
          : undefined
      }
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          width: "fit-content",
        },
      }}
    >
      {leadContextMenuItems.map((context, index) => (
        <MenuItem
          disabled={isMenuDisabled(context.value)}
          onClick={() => handleMenuClick(context.value)}
          style={{
            fontSize: "10px",
          }}
          key={index}
        >
          {context.label}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default LeadContextMenu;
