import {
  Box,
  Button,
  FormControl,
  Grid,
  makeStyles,
  Modal,
  Select,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import rootStore from "../../stores/RootStore";
import { getLocalizedText } from "../../Functions";
import { MenuItem, OutlinedInput } from "@mui/material";
import { myTheme } from "../../themeUtils";
import { updateMultipleLeadSourceApi } from "../../Api";
import analytics from "../../Analytics";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
`;
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled.span`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled.span`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
  margin-bottom: 30px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 320px;
  position: relative;
`;

const Label = styled.span`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;

const ButtonWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const SelectColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 700px) {
    width: 280px;
  }
`;

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const MultiLeadUpdateSourceModal = (props) => {
  const classes = useStyles();
  const [details, setDetails] = useState({
    source: "",
    comments: "",
  });
  const [loading, setLoading] = useState(false);
  const [btnText, setBtnText] = useState("Update");
  const sourceList = [...rootStore.authStore.sourcesList];

  const updateSource = async () => {
    setLoading(true);
    setBtnText("Loading...");
    let payload = {
      source: details.source,
      lead_ids: props.selectedLeadIdsData,
    };
    if (details.comments !== "") {
      payload["comments"] = details.comments;
    }
    let response = await updateMultipleLeadSourceApi({
      payload: payload,
    });
    if (response.hasError()) {
      console.log(response.errorMessage);
      setBtnText("Update");
      setLoading(false);
    } else {
      setBtnText("Updated");
      setTimeout(() => {
        props.setRefresh(true);
        props.setOpen(false);
        setLoading(false);
      }, [800]);
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();

    await updateSource();
  };
  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  const handleClose = () => {
    props.setOpen(false);
  };

  useEffect(() => {
    analytics.triggerEvent(
      4625000276,
      "update_source_form_load",
      "leads_page",
      "",
      { order: props.scope }
    );
  }, []);

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <Container>
              <Header>
                {getLocalizedText("update_lead_source", "project")}
              </Header>
              <SubHeader>
                {getLocalizedText("select_from_below_dropdown")}
              </SubHeader>
              <Form onSubmit={handleUpdate}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <SelectColumn>
                      <Label>{getLocalizedText("select_source")}*</Label>
                      <FormControl
                        sx={{ width: "100%" }}
                        classes={{
                          root: classes.quantityRoot,
                        }}
                      >
                        <Select
                          classes={{
                            icon: classes.icon,
                          }}
                          name="source"
                          value={details.source}
                          displayEmpty
                          disableUnderline
                          variant="outlined"
                          required
                          onChange={handleChange}
                          style={{
                            width: "100%",
                            height: "30px",
                            fontSize: "12px",
                            fontWeight: "bold",
                            borderRadius: "0px",
                          }}
                        >
                          {sourceList.map((source, index) => {
                            return (
                              <MenuItem key={index} value={source["source"]}>
                                {source["source"]}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </SelectColumn>
                  </Grid>

                  <Grid item xs={12}>
                    <SelectColumn>
                      <Label>Comments</Label>
                      <OutlinedInput
                        multiline={true}
                        rows={3}
                        inputProps={{
                          min: 0,
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        value={details.comments}
                        name={"comments"}
                        type={"text"}
                        placeholder={"Enter Value"}
                        onChange={handleChange}
                      />
                    </SelectColumn>
                  </Grid>
                </Grid>
                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      analytics.triggerEvent(
                        4625000277,
                        "cancel_button_tap",
                        "update_source_form",
                        "cancel_button",
                        { order: props.scope }
                      );
                      props.setOpen(false);
                    }}
                    variant="contained"
                    color="default"
                    style={{
                      font: "normal normal 600 10px Open Sans",
                      marginRight: "10px",
                      textTransform: "none",
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                  <Button
                    onClick={() => {
                      analytics.triggerEvent(
                        4625000278,
                        "update_button_tap",
                        "update_source_form",
                        "update_button",
                        { order: props.scope }
                      );
                    }}
                    disabled={loading}
                    type="submit"
                    variant="contained"
                    style={{
                      font: "normal normal 600 10px Open Sans",
                      width: "fit-content",
                      textTransform: "none",
                      ...myTheme.Button.btnBlue,
                    }}
                  >
                    {btnText}
                  </Button>
                </ButtonWrapper>
              </Form>
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default MultiLeadUpdateSourceModal;
