import { Box, Button, Chip, Modal } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Close, DoneOutline } from "@material-ui/icons";
import { Stack } from "@mui/material";
import axios from "axios";
import rootStore from "../../stores/RootStore";
import { myTheme } from "../../themeUtils";
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 30px;
`;
const ModelWrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
`;
const TagsWrapper = styled.div`
  display: flex;
  max-width: 25vw;
  flex-wrap: wrap;
  @media (max-width: 700px) {
    max-width: 80vw;
    height: 80vh;
  }
`;
const Header = styled.p`
  margin: 0px;
  margin-bottom: 8px;
  font: normal normal 600 18px Open Sans;
`;
const SubHeader = styled.p`
  margin: 0px;
  margin-bottom: 20px;
  font: normal normal 500 14px Open Sans;
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  /* margin: auto; */
  margin-top: 20px;
  justify-content: flex-end;
`;
const get_leads_url = process.env.REACT_APP_API_BASE_URL;

const SelectTagsModal = (props) => {
  const { authStore } = rootStore;
  const [tags, setTags] = useState();
  let pid = authStore.projectId;
  // const handleClick = () => {
  //   props.setOpen(false);
  // };

  const handleClose = () => {
    props.setOpen(false);
  };
  const handleTags = () => {
    let newList = tags.filter((e) => {
      return e.isSelected === true;
    });
    let finalList = [];
    newList.map((e) => {
      finalList.push(e.value);
    });
    let timeStamp = +new Date();

    axios({
      method: "post",
      url: `${get_leads_url}projects/${pid}/leads/${props.lid}/update/tags`,
      headers: {
        X_TIMESTAMP: timeStamp,
        X_FEATURES: "",
        X_APPID: "1001",
        X_APPVERSION: "1.0.0.0",
        X_DEVICEID: timeStamp,
        X_DEVICEOS: "web",
        X_USERID: localStorage.getItem("uid"),
        X_TOKEN: localStorage.getItem("token"),
        X_PROJECTID: pid,
      },
      data: {
        tags: finalList.length ? finalList : [],
      },
    })
      .then((res) => {
        props.setRefresh(!props.refresh);
        props.setOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    let timeStamp = +new Date();
    axios({
      method: "get",
      url: `${get_leads_url}projects/${pid}/tags`,
      headers: {
        X_TIMESTAMP: timeStamp,
        X_FEATURES: "",
        X_APPID: "1001",
        X_APPVERSION: "1.0.0.0",
        X_DEVICEID: timeStamp,
        X_DEVICEOS: "web",
        X_USERID: localStorage.getItem("uid"),
        X_TOKEN: localStorage.getItem("token"),
        X_PROJECTID: pid,
      },
    }).then((res) => {
      let newList = [];
      if (res.data.length) {
        res.data.map((e) => {
          if (props.tags.includes(e)) {
            let newObj = {};
            newObj["value"] = e;
            newObj["isSelected"] = true;
            newList.push(newObj);
          } else {
            let newObj = {};
            newObj["value"] = e;
            newObj["isSelected"] = false;
            newList.push(newObj);
          }
        });
      }
      setTags(newList);
    });
  }, []);
  return (
    <>
      (
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            {/* <Close
              onClick={handleClick}
              style={{
                height: "18px",
                width: "18px",
                position: "absolute",
                top: "20px",
                right: "30px",
                cursor: "pointer",
              }}
            /> */}
            <ModelWrapper>
              <Header>Add Tags</Header>
              <SubHeader>Select from below tags</SubHeader>
              <TagsWrapper>
                {tags &&
                  tags.map((e, i) => {
                    return (
                      <Chip
                        style={{
                          backgroundColor: e.isSelected ? "green" : "GrayText",
                          color: "white",
                          margin: "8px",
                          fontSize: "12px",
                          fontWeight: "bold",
                          borderRadius: "8px",
                        }}
                        key={e.value}
                        label={e.value}
                        icon={
                          e.isSelected ? (
                            <DoneOutline
                              style={{
                                color: "white",
                              }}
                            />
                          ) : null
                        }
                        onClick={() => {
                          let newList = [...tags];
                          newList[i].isSelected = !newList[i].isSelected;
                          setTags(newList);
                        }}
                      />
                    );
                  })}
              </TagsWrapper>
              <ButtonWrapper>
                <Button
                  variant="outlined"
                  color="default"
                  style={{
                    textTransform: "none",
                    marginRight: "8px",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    props.setOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  style={{
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: "bold",
                    ...myTheme.Button.btnBlue,
                  }}
                  onClick={handleTags}
                >
                  Add Tag
                </Button>
              </ButtonWrapper>
            </ModelWrapper>
          </Box>
        </ModelContainer>
      </Modal>
      )
    </>
  );
};

export default SelectTagsModal;
