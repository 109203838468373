import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Call, Edit, Visibility } from "@material-ui/icons";
import spinner from "../../assets/images/gifs/spinner.gif";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import SelectTagsModal from "../select_tags_modal/SelectTagsModal";
import { Drawer, IconButton, Snackbar, Tooltip } from "@material-ui/core";

import {
  canShowSkypeCallIcon,
  consoleLogger,
  dateHandler,
  dateTimeHandler,
  IsoToLocalDate,
  IsoToLocalTime,
} from "../../Functions";
import moment from "moment";
import { CopyAll } from "@mui/icons-material";
import EditLeadComponent from "../edit_lead_component/EditLeadComponent";
import { myTheme } from "../../themeUtils";
import analytics from "../../Analytics";
import { Box, Typography } from "@mui/material";
const Container = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 242px);`};

  /* padding: 12px; */
  @media (max-width: 700px) {
    padding: 0px;
  }
`;
const Wrapper = styled(Box)`
  /* width: 80%; */
  margin: auto;
  display: flex;
  flex-direction: column;
`;

const SectionWrapper = styled(Box)`
  width: 100%;
  background: #f9f9fc 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  position: relative;
  @media (max-width: 700px) {
    width: 100%;
  }
`;

const RightWrapper = styled(Box)`
  flex: 7;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;
const WrapperRow = styled(Box)`
  display: flex;
  column-gap: 20px;
  margin-bottom: 20px;
`;
const DetailsHeader = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  margin-bottom: 20px;
  color: #979797;
`;
const Message = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #979797;
`;
const InfoWrapper = styled(Box)`
  width: 200px;
  height: fit-content;
`;

const Label = styled(Box)`
  font: normal normal 600 12px Open Sans;
  margin-bottom: 8px;
  color: #979799;
`;
const Detail = styled(Box)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const Row = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
`;
const IconTextWrapper = styled(Box)`
  display: flex;
  width: 100%;
  column-gap: 20px;
  align-items: center;
`;
const IconWrapper = styled(Box)`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
`;
const Column = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const Spinner = styled("img")`
  height: 120px;
  width: 180px;
`;
const SpinnerWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  padding-top: 10px;
  width: 100%;
`;

const TextHyperLink = styled("a")`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  color: ${myTheme.Button.colorBlue};
`;
const BulletIcon = styled(Box)`
  margin-top: 6px;
  width: 8px;
  height: 8px;
  background-color: gray;
  border-radius: 50%;
`;

const CustomDetailsTab = ({
  lid,
  groups,
  groupsToColumns,
  leadData,
  setRefreshLeadDetails,
  canEditLead,
  setOpenSkypeCallConfirmationDialog,
  setSelectedPhoneFieldValue,
  isCallDisabled,
  setTextData,
  setOpenViewTextDialog,
}) => {
  const [data, setData] = useState();
  const { userStore } = rootStore;
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openEditInfo, setOpenEditInfo] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [groupsDetails, setGroupsDetails] = useState({});
  const [editGroupColumnIDs, setEditGroupColumnIDs] = useState([]);

  const columnsList = userStore.AllColumnsList;

  const identifyDateFormat = (dateString) => {
    if (!isNaN(dateString)) {
      const m = moment(parseInt(dateString));
      if (m.isValid()) {
        return "epoch";
      }
    } else {
      const m = moment(dateString);
      if (m.isValid()) {
        return "ISO";
      }
    }
    return "unknown";
  };

  const dateFieldHandler = ({ item, type }) => {
    let value = null;
    let dateStringType = identifyDateFormat(item);
    if (item !== null) {
      if (dateStringType !== "unknown") {
        if (dateStringType === "epoch") {
          if (type === "datetime") {
            value = dateTimeHandler(item);
          } else {
            value = dateHandler(item);
          }
        } else {
          if (type === "datetime") {
            value = IsoToLocalTime(item);
          } else {
            value = IsoToLocalDate(item);
          }
        }
      }
    }
    return value;
  };

  const setupGroupDetails = () => {
    let tempGroupsDetails = {};
    consoleLogger(
      "groups in setupGroupDetails:::",
      groups,
      "groups in groupToColumns:::",
      groupsToColumns
    );
    groups.forEach((group) => {
      let groupDetails = [];
      let columnIds = groupsToColumns[group];
      columnIds.forEach((id) => {
        let index = columnsList.findIndex((element) => element["id"] === id);
        if (index !== -1) {
          let item = {
            headerName: columnsList[index]["headerName"],
            value: getColumnValue(columnsList[index]),
            is_link: columnsList[index]["is_link"],
            is_array: columnsList[index]["is_array"],
            is_phone: columnsList[index]["is_phone"],
          };
          groupDetails.push(item);
        }
      });
      groupDetails.sort((a, b) => {
        let A = a.headerName.toLowerCase();
        let B = b.headerName.toLowerCase();
        if (A < B) {
          return -1;
        }
        if (A > B) {
          return 1;
        }
        return 0;
      });
      tempGroupsDetails[group] = groupDetails;
    });
    setGroupsDetails(tempGroupsDetails);
    consoleLogger("all group data to render ::::", tempGroupsDetails);
  };
  const getColumnValue = (column) => {
    let data = leadData;
    let key = column["field"];
    let data_type = column["data_type"];
    if (column["is_custom_param"]) {
      key = key.replace("custom_params_", "");
      let customData = leadData.hasOwnProperty("custom_params")
        ? JSON.parse(leadData["custom_params"])
        : {};
      data = customData;
    }
    if (data.hasOwnProperty(key)) {
      let value = data[key];
      if (data_type === "date" || data_type === "datetime") {
        value = dateFieldHandler({ item: value, type: data_type });
      }
      if (key === "lead_owner" || key === "team") {
        return value !== null ? value["name"] : "";
      }
      return value !== null ? value : "";
    } else {
      return "";
    }
  };

  useEffect(() => {
    setupGroupDetails();
  }, [leadData]);

  useEffect(() => {
    analytics.triggerEvent(
      4625000050,
      "other_information_tab_load",
      "lead_details_page",
      "",
      {}
    );
  }, []);

  function truncate(str, n, is_link) {
    if (str.length > n) {
      return is_link ? (
        <Tooltip title={str}>
          <IconTextWrapper style={{ columnGap: "0px" }}>
            <TextHyperLink href={str} target="_blank">
              {str.slice(0, n - 1) + "..."}
              {/* <Link color="primary" /> */}
            </TextHyperLink>
          </IconTextWrapper>
        </Tooltip>
      ) : (
        <IconTextWrapper style={{ columnGap: "0px" }}>
          <Detail>{str.slice(0, n - 1) + "..."}</Detail>
          <CopyAll
            color="primary"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigator.clipboard.writeText(str);
              setOpenSnackBar(true);
            }}
          />
          <Visibility
            color="primary"
            style={{ cursor: "pointer", height: "20px", width: "20px" }}
            onClick={() => {
              setTextData(str);
              setOpenViewTextDialog(true);
            }}
          />
        </IconTextWrapper>
      );
    } else {
      return is_link ? (
        <TextHyperLink href={str} target="_blank">
          {str}
        </TextHyperLink>
      ) : (
        <Detail>{str}</Detail>
      );
    }
  }
  const isGroupEditable = (group_name) => {
    if (canEditLead()) {
      return userStore.isColumnGroupEditable(group_name);
    }
    return false;
  };

  const isColumnGroupVisible = (group_name) => {
    return userStore.isColumnGroupVisible(group_name);
  };

  const atleastOneGroupIsVisible = () => {
    const isAnyVisible = Object.keys(groupsDetails).some((key) => {
      return isColumnGroupVisible(key);
    });
    return isAnyVisible;
  };

  const handleSectionDataCopy = (groupData) => {
    let list = [];
    groupData.forEach((data) => {
      let newStr = `${data["headerName"]}:${data["value"]}`;
      list.push(newStr);
    });
    let formattedData = list.join("\n");
    navigator.clipboard.writeText(formattedData);
    setOpenSnackBar(true);
  };
  return (
    <>
      <Container>
        <Wrapper>
          {Object.keys(groupsDetails).length > 0 ? (
            <>
              <WrapperRow>
                <RightWrapper>
                  {atleastOneGroupIsVisible() ? (
                    Object.keys(groupsDetails).length > 0 &&
                    Object.keys(groupsDetails).map((key) => {
                      if (isColumnGroupVisible(key)) {
                        let details = groupsDetails[key];
                        if (
                          key !== "Primary Information" &&
                          key !== "Secondary Information"
                        )
                          return details.length > 0 ? (
                            <SectionWrapper>
                              <IconWrapper>
                                {rootStore.userStore.isColumnGroupCopyable(
                                  key
                                ) && (
                                  <CopyAll
                                    onClick={() =>
                                      handleSectionDataCopy(groupsDetails[key])
                                    }
                                    color="primary"
                                    style={{ cursor: "pointer" }}
                                  />
                                )}
                                {isGroupEditable(key) && (
                                  <Edit
                                    onClick={() => {
                                      setEditGroupColumnIDs(
                                        groupsToColumns[key]
                                      );
                                      setOpenEditInfo(true);
                                    }}
                                    color="primary"
                                    style={{ cursor: "pointer" }}
                                  />
                                )}
                              </IconWrapper>
                              <Column>
                                <DetailsHeader>{key}</DetailsHeader>
                                <Row>
                                  {details.map((item) => {
                                    let value = item["value"];
                                    if (item["is_array"] === true) {
                                      let list =
                                        value !== "" ? value.split(",") : [];
                                      return (
                                        <InfoWrapper>
                                          <Label>{item["headerName"]}</Label>

                                          {list.length > 0 ? (
                                            <Box
                                              sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                rowGap: "4px",
                                              }}
                                            >
                                              {list.map((str, index) => {
                                                return (
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      alignItems: "flex-start",
                                                      columnGap: "4px",
                                                    }}
                                                    key={index}
                                                  >
                                                    <BulletIcon />

                                                    <Detail>{str}</Detail>
                                                  </Box>
                                                );
                                              })}
                                            </Box>
                                          ) : (
                                            <Detail>-</Detail>
                                          )}
                                        </InfoWrapper>
                                      );
                                    } else {
                                      return (
                                        <InfoWrapper>
                                          <Label>{item["headerName"]}</Label>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "10px",
                                            }}
                                          >
                                            {value !== ""
                                              ? truncate(
                                                  value,
                                                  20,
                                                  item["is_link"]
                                                )
                                              : "-"}
                                            {canShowSkypeCallIcon({
                                              isPhone: item["is_phone"],
                                              value: value,
                                            }) && (
                                              <IconButton
                                                disabled={isCallDisabled()}
                                                style={{ padding: "0px" }}
                                                color="primary"
                                                onClick={() => {
                                                  setSelectedPhoneFieldValue(
                                                    value
                                                  );
                                                  setOpenSkypeCallConfirmationDialog(
                                                    true
                                                  );
                                                }}
                                              >
                                                <Call />
                                              </IconButton>
                                            )}
                                          </Box>
                                        </InfoWrapper>
                                      );
                                    }
                                  })}
                                </Row>
                              </Column>
                            </SectionWrapper>
                          ) : (
                            <SectionWrapper
                              style={{
                                flexDirection: "column",
                                flexWrap: "nowrap",
                              }}
                            >
                              <DetailsHeader>{key}</DetailsHeader>
                              <Row
                                style={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "100%",
                                }}
                              >
                                <Message>No data.</Message>
                              </Row>
                            </SectionWrapper>
                          );
                      }
                    })
                  ) : (
                    <Row
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Message>No data.</Message>
                    </Row>
                  )}
                </RightWrapper>
              </WrapperRow>
            </>
          ) : (
            <SpinnerWrapper>
              <Spinner src={spinner} alt="loading..." />
            </SpinnerWrapper>
          )}
        </Wrapper>
      </Container>
      {open && (
        <SelectTagsModal
          open={open}
          setOpen={setOpen}
          tags={data.tags}
          lid={lid}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}
      <Snackbar
        open={openSnackBar}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => {
          setOpenSnackBar(false);
        }}
        message="Copied to clipboard!"
      />

      <Drawer
        anchor={"right"}
        open={openEditInfo}
        onClose={() => {
          setOpenEditInfo(false);
        }}
      >
        {
          <EditLeadComponent
            id={lid}
            leadDetails={leadData}
            groupName={groupName}
            open={openEditInfo}
            columnIds={editGroupColumnIDs}
            setOpen={setOpenEditInfo}
            setRefreshLeadDetails={setRefreshLeadDetails}
          />
        }
      </Drawer>
    </>
  );
};

export default observer(CustomDetailsTab);
