import { Divider } from "@mui/material";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { getMonthCheckInsApi } from "../../Api";
import CheckInCalendar from "../../components/checkin_page_components/CheckInCalendar";
import CheckinTable from "../../components/checkin_page_components/CheckinTable";
import Footer from "../../components/landing_page_components/footer/Footer";
import { isUnderWorkSchedule } from "../../Functions";
import NoAccessPage from "../no_access_page/NoAccessPage";
import rootStore from "../../stores/RootStore";

const Container = styled.div`
  margin-top: 64px;
  background-color: #f9f9fc;
  ${`min-height: calc(100vh - 142px);`};
`;
const Wrapper = styled.div`
  width: 95%;
  margin: auto;
  /* background-color: white; */
  display: flex;
  flex-direction: column;
  /* padding-top: 20px; */
`;

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  padding: 20px 0px;
`;
const CalendarWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  min-height: 70vh;
  background-color: white;
  padding: 30px;
  column-gap: 20px;
`;
const Header = styled.p`
  margin: 0px;
  font: normal normal 600 28px Open Sans;
  /* text-align: center; */
  color: #4d4e4f;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionHeader = styled.p`
  margin: 0px;
  font: normal normal 600 20px Open Sans;
  /* text-align: center; */
  color: #4d4e4f;
  margin-bottom: 20px;
`;
const CheckInPage = () => {
  const [checkinDate, setCheckinDate] = useState(null);
  const [date, setDate] = useState(null);
  const initCheckinDate = () => {
    let now = moment().format("YYYY-MM-DD");
    setCheckinDate(now);
    setDate(now);
  };
  useEffect(() => {
    rootStore.authStore.updateLeadDetailStatus();
    initCheckinDate();
  }, []);
  return isUnderWorkSchedule() ? (
    <>
      <Container>
        <Wrapper>
          <TopWrapper>
            <Header>Check-Ins</Header>
          </TopWrapper>
          <CalendarWrapper>
            <Column>
              <SectionHeader>Select Checkin Date</SectionHeader>
              {date !== null && (
                <CheckInCalendar
                  setDate={setDate}
                  date={date}
                  setCheckinDate={setCheckinDate}
                />
              )}
            </Column>
            <Column style={{ width: "100%" }}>
              <SectionHeader>Checkin History</SectionHeader>
              {checkinDate !== null && (
                <CheckinTable checkinDate={checkinDate} />
              )}
            </Column>
          </CalendarWrapper>
        </Wrapper>
      </Container>
      <Footer />
    </>
  ) : (
    <NoAccessPage />
  );
};

export default observer(CheckInPage);
