import React, { useEffect } from "react";
import { useMap, Marker, Popup, useMapEvents } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";

// Default marker icon
const DefaultIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

const LocationMarker = ({ position, setPosition }) => {
  const map = useMap();

  const init = () => {
    // Request the user's location when the map is rendered
    map.locate({ setView: true, maxZoom: 13 });

    // Handle location events
    map.on("locationfound", (e) => {
      let tempLocationData = [e.latlng.lat, e.latlng.lng];
      setPosition(tempLocationData);
      map.flyTo(e.latlng, map.getZoom());
    });

    // Handle location errors
    map.on("locationerror", () => {
      console.error("Location access denied or unavailable");
    });

    // Clean up event listeners on unmount
    return () => {
      map.off("locationfound");
      map.off("locationerror");
    };
  };
  useEffect(() => {
    if (position === null) {
      init();
    }
  }, [map, position]);

  // Update marker position on map click
  useMapEvents({
    click(event) {
      const { lat, lng } = event.latlng;
      setPosition([lat, lng]);
    },
  });

  return position ? (
    <Marker position={position}>
      <Popup>You are here</Popup>
    </Marker>
  ) : null;
};

export default LocationMarker;
