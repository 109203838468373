import React from "react";
import "../../components/loginLeft/carousel.css";
import { content } from "../../Db";
import { responsive, CustomDotLogin, ChangeIndex } from "../../Functions";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styled from "styled-components";

//carousel for landing page intro section
const Card = styled.div`
  max-width: 320px;
  min-height: 150px;
`;
const Description = styled.div`
  font: normal normal normal 16px Poppins;
`;

const LoginCarousel = () => {
  return (
    <>
      <Carousel
        beforeChange={(previousSlide, { currentSlide, onMove }) => {
          ChangeIndex(currentSlide);
        }}
        partialVisbile={false}
        swipeable={false}
        draggable={false}
        showDots={true}
        customDot={<CustomDotLogin />}
        responsive={responsive}
        ssr={true}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        containerClass="carousel-container-login"
        removeArrowOnDeviceType={["mobile", "tablet", "desktop"]}
        deviceType={["mobile", "tablet", "desktop"]}
        dotListClass="custom-dot-list-style-login"
        itemClass="carousel-item-login"
      >
        {content.map((c, i) => (
          <Card>
            <Description>{c.body}</Description>
          </Card>
        ))}
      </Carousel>
    </>
  );
};

export default LoginCarousel;
