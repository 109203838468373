import {
  CircularProgress,
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Tooltip,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Close } from "@material-ui/icons";
import rootStore from "../../stores/RootStore";
import { uploadCsvApi } from "../../Api";
import { MultiSelect } from "react-multi-select-component";
import "./upload.css";
import { myTheme } from "../../themeUtils";
import { getLocalizedText } from "../../Functions";
import analytics from "../../Analytics";
import { Summarize } from "@mui/icons-material";
import UpdateLeadRuleModal from "./create_lead_import_modal/UpdateLeadRuleModal";
import { updateImportRuleList } from "../../Db";
import Papa from "papaparse";

const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: 600px;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 30px;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const ModelWrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
`;
const Header = styled.p`
  margin: 0px;
  font: normal normal 600 18px Open Sans;
`;
const Message = styled.p`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  margin-bottom: 12px;
`;
const MessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  flex-direction: column;
`;
const SubHeader = styled.p`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
const Input = styled.input`
  width: 100%;
  height: 35px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;

  border: 1px solid #bfbfbf;
  font: normal normal 600 16px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;

const Label = styled.span`
  font: normal normal normal 10px Open Sans;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;

const UploadModal = (props) => {
  const { authStore } = rootStore;
  const [file, setFile] = useState();
  // const [text, setText] = useState("Upload");
  const [selected, setSelected] = useState([]);
  const [selectVal, setSelectedVal] = useState([]);
  const [list, setList] = useState([]);
  const [check, setCheck] = useState(false);
  const [csvResponse, setCsvResponse] = useState();
  const [mandatList, setMandatList] = useState([]);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requiredList, setRequiredList] = useState([]);
  let pid = authStore.projectId;

  let allColumnList = [...rootStore.userStore.AllColumnsList];

  const [openUpdateLeadRule, setOpenUpdateLeadRule] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState({});
  const [selectedUpdateRule, setSelectedUpdateRule] = useState({});
  const [errorMessages, setErrorMessage] = useState([]);
  const [loadingFile, setLoadingFile] = useState(false);

  const handleClose = () => {
    props.setOpen(false);
  };
  const handleChange = async (e) => {
    const file = e.target.files[0];
    checkFile(file);
  };

  function checkFile(file) {
    if (file) {
      setErrorMessage([]);
      setLoadingFile(true);
      let startTime = performance.now();

      Papa.parse(file, {
        header: false,
        skipEmptyLines: true,
        worker: true,
        complete: function (results) {
          let elapsedTime = (performance.now() - startTime) / 1000;
          console.log("Performance time for parsing csv:", elapsedTime, "s");
          console.log("ROW Count:", results.data.length - 1);
          if (
            results.data.length !== undefined &&
            results.data.length - 1 > 100000
          ) {
            let messages = [
              "Error: The number of rows has exceeded the allowed limit.",
            ];
            setErrorMessage(messages);
          } else {
            let headers = results.data[0];
            console.log("CSV Rows:", headers);
            if (headers !== undefined && headers.length > 0) {
              let duplicateHeaders = findDuplicateHeaders(headers);
              console.log("Duplicate headers :", duplicateHeaders);
              let mismatchHeaders = getMismatchHeaders(headers);
              console.log("Mismatch headers :", mismatchHeaders);

              let derivedHeaders = getDerivedHeaders(headers);
              console.log("Derived headers :", derivedHeaders);
              if (duplicateHeaders.length > 0) {
                let messages = [
                  "Error: Duplicate headers found in the CSV file.",
                ];
                if (duplicateHeaders.length > 3) {
                  messages.push(
                    `${duplicateHeaders.slice(0, 3).join(", ")} etc`
                  );
                } else {
                  messages.push(`${duplicateHeaders.slice(0, 3).join(", ")}`);
                }

                setErrorMessage(messages);
              } else if (mismatchHeaders.length > 0) {
                let messages = [
                  "Error: The column headers in the CSV file do not match any predefined lead fields.",
                ];
                if (mismatchHeaders.length > 3) {
                  messages.push(
                    `${mismatchHeaders.slice(0, 3).join(", ")} etc`
                  );
                } else {
                  messages.push(`${mismatchHeaders.slice(0, 3).join(", ")}`);
                }

                setErrorMessage(messages);
              } else if (derivedHeaders.length > 0) {
                let messages = [
                  "Error: Headers in the CSV file cannot override derived or readonly fields.",
                ];
                if (derivedHeaders.length > 3) {
                  messages.push(`${derivedHeaders.slice(0, 3).join(", ")} etc`);
                } else {
                  messages.push(`${derivedHeaders.slice(0, 3).join(", ")}`);
                }

                setErrorMessage(messages);
              }
            }
          }
          setFile(file);
          setLoadingFile(false);
        },
      });
    }
  }

  function getDerivedHeaders(headers) {
    const derivedHeaders = [];

    let ignoreFlid = false;

    if (selectedUpdateRule["slug"] === "option_update_flid") {
      ignoreFlid = true;
    }

    headers.forEach((header) => {
      const index = allColumnList.findIndex((obj) => obj.headerName === header);
      if (index !== -1) {
        if (allColumnList[index].derived) {
          if (!(allColumnList[index].field === "flid" && ignoreFlid)) {
            derivedHeaders.push(header);
          }
        }
      }
    });

    return derivedHeaders;
  }
  function getMismatchHeaders(headers) {
    const missingHeaders = [];
    const allHeaders = allColumnList.map((obj) => obj.headerName);

    headers.forEach((header) => {
      // Check if header is "phone" or "email" and skip if true
      if (header === "Phone" || header === "Email") {
        return; // Skip the rest of the loop iteration
      }
      if (!allHeaders.includes(header)) {
        missingHeaders.push(header);
      }
    });

    return missingHeaders;
  }
  function findDuplicateHeaders(headerList) {
    const seen = new Set();
    const duplicates = new Set();

    for (const header of headerList) {
      if (seen.has(header)) {
        duplicates.add(header);
      } else {
        seen.add(header);
      }
    }

    return Array.from(duplicates);
  }

  const handleUpload = () => {
    // setText("Uploading...");
    setLoading(true);
    let formData = new FormData();
    formData.append("file", file);
    let updateOptions = {
      update_option: selectedUpdateRule["slug"],
    };
    formData.append("xdata", JSON.stringify(updateOptions));
    formData.append("import_type", "update_leads_v1");
    const sendCSV = async () => {
      try {
        let response = await uploadCsvApi(formData);
        setCsvResponse(response.data);
        setLoading(false);
        setCheck(true);
      } catch (error) {
        console.log(error);
        setIsError(true);
      }
    };
    sendCSV();
  };

  const updateList = (val) => {
    let newList = [];
    let otherList = [];
    let requiredList = [];
    val.map((e) => {
      if (
        !e.required &&
        e.field !== "lead_creator" &&
        !e.derived &&
        rootStore.userStore.isColumnVisible(e)
      ) {
        let newObj = {
          label: e.headerName,
          value: e.field,
        };
        newList.push(newObj);
        otherList.push(e.headerName);
      } else {
        if (
          e.field !== "lead_creator" &&
          !e.derived &&
          rootStore.userStore.isColumnVisible(e)
        ) {
          requiredList.push(e.headerName);
        }
      }
    });
    return {
      newList: newList,
      otherList: otherList,
      requiredList: requiredList,
    };
  };
  const isButtonDisabled = () => {
    if (loading || loadingFile) {
      return true;
    } else {
      if (file === undefined) {
        return true;
      } else if (errorMessages.length !== 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const convertToASCII = (str) => {
    return str.replace(/[^\x00-\x7F]/g, "");
  };

  const convertArrayToCSV = (array) => {
    // Convert the array to a single row CSV
    return array.map((item) => convertToASCII(item)).join(",");
  };

  const downloadCSV = (data) => {
    const csv = convertArrayToCSV(requiredList.concat(data));
    const blob = new Blob([csv], { type: "text/plain;charset=us-ascii;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", "sample.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {}, [loadingFile]);
  useEffect(() => {
    checkFile(file);
  }, [selectedUpdateRule]);

  useEffect(() => {
    analytics.triggerEvent(
      4625000109,
      "imports_update_leads_form_load",
      "imports_page",
      "",
      {}
    );
    setSelectedUpdateRule(updateImportRuleList[0]);
    const getColumns = async () => {
      let response = [...rootStore.userStore.AllColumnsList];
      let resultList = updateList(response);
      let newList = resultList.newList;
      let otherList = resultList.otherList;
      let requiredList = resultList.requiredList;
      setList(newList);
      setMandatList(otherList);
      setRequiredList(requiredList);
    };
    getColumns();
  }, []);

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <IconButton
              onClick={() => {
                analytics.triggerEvent(
                  4625000111,
                  "close_icon_tap",
                  "imports_update_leads_form",
                  "close_icon",
                  {}
                );
                props.setOpen(false);
              }}
              style={{
                zIndex: "9999",
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
              }}
            >
              <Close />
            </IconButton>
            {!check ? (
              <ModelWrapper>
                <Header>{getLocalizedText("import_leads", "project")}</Header>
                <SubHeader>
                  {getLocalizedText(
                    "upload_the_data_in_the_same_format_as_the_sample_csv_file"
                  )}
                </SubHeader>
                <Divider style={{ margin: "10px 0px" }} />

                <Column>
                  <Row style={{ columnGap: "10px" }}>
                    <Label style={{ fontSize: "12px" }}>Project:</Label>
                    <Label style={{ fontSize: "12px", fontWeight: "bold" }}>
                      {authStore.currentProject !== null
                        ? authStore.currentProject["name"]
                        : "-"}
                    </Label>
                  </Row>
                  <Box
                    style={{
                      marginBottom: "30px",
                    }}
                  >
                    <Column>
                      <Row>
                        <Label
                          style={{
                            fontSize: "12px",
                            marginRight: "10px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {getLocalizedText("update_lead_rule")}:
                        </Label>
                        <Tooltip title={getLocalizedText("update_lead_rule")}>
                          <Summarize
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setOpenUpdateLeadRule(true);
                            }}
                            color="primary"
                          />
                        </Tooltip>
                      </Row>
                      <Label
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          marginLeft: "16px",
                        }}
                      >
                        {selectedUpdateRule["name"]}
                      </Label>
                    </Column>
                    <Column
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <Label
                        style={{
                          fontSize: "12px",
                          marginRight: "10px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Description:
                      </Label>
                      <Label
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          marginLeft: "16px",
                        }}
                      >
                        {selectedUpdateRule["desc"]}
                      </Label>
                    </Column>
                    {Object.keys(selectedColumn).length > 0 && (
                      <Box
                        style={{ display: "flex", alignItems: "flex-start" }}
                      >
                        <Label
                          style={{
                            fontSize: "12px",
                            marginRight: "10px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Columns:
                        </Label>
                        <Label style={{ fontSize: "12px", fontWeight: "bold" }}>
                          {Object.keys(selectedColumn).length > 0
                            ? `Phone Number, ${selectedColumn["headerName"]}`
                            : "Phone Number"}
                        </Label>
                      </Box>
                    )}
                  </Box>{" "}
                  {loadingFile && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: "8px",
                      }}
                    >
                      <CircularProgress
                        style={{ width: "20px", height: "20px" }}
                      />
                      <Label
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          marginLeft: "8px",
                        }}
                      >
                        Processing File...
                      </Label>
                    </Box>
                  )}
                  {errorMessages.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "4px",
                      }}
                    >
                      {errorMessages.map((message, i) => {
                        return (
                          <Label
                            key={i}
                            style={{ color: "red", marginBottom: "4px" }}
                          >
                            {message}
                          </Label>
                        );
                      })}
                    </Box>
                  )}
                  <Input
                    type="file"
                    style={{
                      fontSize: "12px",
                      padding: "7px",
                      backgroundColor: "#EFEFF4",
                      border: "none",
                      borderRadius: "6px",
                    }}
                    onClick={(e) => (e.target.value = null)}
                    accept={".csv"}
                    onChange={handleChange}
                  />
                  <Button
                    disabled={isButtonDisabled()}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      analytics.triggerEvent(
                        4625000110,
                        "update_leads_button_tap",
                        "imports_update_leads_form",
                        "update_leads_button",
                        {}
                      );
                      handleUpload();
                    }}
                    style={{
                      font: "normal normal 600 12px Open Sans",
                      width: "100%",
                      marginTop: "16px",
                      textTransform: "none",
                    }}
                  >
                    {loading
                      ? "Loading..."
                      : getLocalizedText("update_leads", "project")}
                  </Button>
                  <Row
                    style={{
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}
                  >
                    {list.length > 0 && (
                      <>
                        <InputWrapper>
                          <MultiSelect
                            options={list}
                            value={selected}
                            className="upload_multi_select"
                            onChange={(e) => {
                              setSelected(e);
                              let newList = [];
                              e.map((val) => {
                                if (!requiredList.includes(val.value)) {
                                  newList.push(val.label);
                                }
                              });
                              setSelectedVal(newList);
                            }}
                            labelledBy="Select Columns"
                          />
                        </InputWrapper>

                        {selectVal.length === 0 ? (
                          <Button
                            onClick={() => {
                              analytics.triggerEvent(
                                4625000112,
                                "download_sample_csv_button_tap",
                                "imports_update_leads_form",
                                "download_sample_csv_button",
                                {}
                              );
                              downloadCSV(mandatList);
                            }}
                            variant="text"
                            color="primary"
                            style={{
                              font: "normal normal 600 12px Open Sans",
                              textTransform: "none",
                            }}
                          >
                            {getLocalizedText("download_sample_csv")}
                          </Button>
                        ) : (
                          <Button
                            onClick={downloadCSV(selectVal)}
                            variant="text"
                            color="primary"
                            style={{
                              font: "normal normal 600 12px Open Sans",
                              textTransform: "none",
                            }}
                          >
                            {getLocalizedText("download_sample_csv")}
                          </Button>
                        )}
                      </>
                    )}
                    {/* <Label>Download Sample CSV</Label> */}

                    {/* </Column> */}
                  </Row>
                </Column>
              </ModelWrapper>
            ) : !isError ? (
              <ModelWrapper>
                <MessageWrapper>
                  <Message style={{ textAlign: "center" }}>
                    {getLocalizedText("leads", "project")} are being updated;
                    please check the status in the imports listing.
                  </Message>
                  <Button
                    variant="text"
                    onClick={() => {
                      props.setRefresh(!props.refresh);
                      props.setOpen(false);
                    }}
                    style={{
                      width: "fit-content",
                      backgroundColor: "#185DD2",
                      color: "white",
                      fontSize: "12px",
                      fontWeight: "bold",
                      margin: "auto",
                    }}
                  >
                    {getLocalizedText("ok")}
                  </Button>
                </MessageWrapper>
              </ModelWrapper>
            ) : (
              <ModelWrapper>
                <MessageWrapper>
                  <Message style={{ textAlign: "center" }}>
                    {getLocalizedText(
                      "some_error_occured_please_retry_or_contact_your_administrator"
                    )}
                  </Message>
                  <Button
                    variant="text"
                    onClick={() => {
                      props.setRefresh(!props.refresh);
                      props.setOpen(false);
                    }}
                    style={{
                      width: "fit-content",
                      fontSize: "12px",
                      fontWeight: "bold",
                      margin: "auto",
                      ...myTheme.Button.btnBlue,
                    }}
                  >
                    {getLocalizedText("ok")}
                  </Button>
                </MessageWrapper>
              </ModelWrapper>
            )}
          </Box>
        </ModelContainer>
      </Modal>
      {openUpdateLeadRule && (
        <UpdateLeadRuleModal
          open={openUpdateLeadRule}
          setOpen={setOpenUpdateLeadRule}
          setSelectedColumn={setSelectedColumn}
          selectedColumn={selectedColumn}
          selectedUpdateRule={selectedUpdateRule}
          setSelectedUpdateRule={setSelectedUpdateRule}
          allColumnList={allColumnList}
        />
      )}
    </>
  );
};

export default UploadModal;
