import React from "react";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { Box, Button, Grid, Modal } from "@material-ui/core";

import "./modal.css";
import { getLocalizedText } from "../../Functions";
import { Chip, Divider, Typography } from "@mui/material";
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 320px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;

const ButtonWrapper = styled(Box)`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ViewUpdateFieldsModal = (props) => {
  const handleClose = () => {
    props.setOpen(false);
  };
  console.log(props.editableData);
  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <Container>
              <Header>Update Fields</Header>

              <Divider style={{ margin: "16px 0px" }} />

              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  container
                  spacing={2}
                  style={{ maxHeight: "120px", overflowY: "auto" }}
                >
                  {Object.keys(props.editableData["update_fields"]).map(
                    (key) => {
                      return (
                        <Grid item>
                          <Chip
                            color={
                              props.editableData["update_fields"][key][
                                "required"
                              ]
                                ? "warning"
                                : "default"
                            }
                            label={
                              props.editableData["update_fields"][key][
                                "column_meta"
                              ]["name"]
                            }
                          />
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              </Grid>
              <ButtonWrapper>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        width: "100%",
                      }}
                    >
                      <Button
                        onClick={() => {
                          handleClose();
                        }}
                        type="button"
                        variant="contained"
                        color="default"
                        style={{
                          backgroundColor: "#EFEFF4",
                          textTransform: "none",
                          marginRight: "16px",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        {getLocalizedText("close")}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </ButtonWrapper>
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default observer(ViewUpdateFieldsModal);
