import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import FilterTable from "../../components/filter_page_components/filter_table/FilterTable";
import { useEffect } from "react";
import styled from "styled-components";
import {
  Alert,
  AppBar,
  Button,
  IconButton,
  MenuItem,
  OutlinedInput,
  Toolbar,
  Tooltip,
} from "@mui/material";
import {
  FormControl,
  Grid,
  makeStyles,
  Select as MuiSelect,
} from "@material-ui/core";
import { Add, Cancel, ChevronLeft, Menu, Save } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import rootStore from "../../stores/RootStore";
import { useState } from "react";
import FilterValueComponent from "../../components/filter_page_components/filter_table/FilterValueComponent";
import { getIsoString } from "../../Functions";
import { Circle, SimCardDownload } from "@mui/icons-material";
import LoadFilterModal from "../../components/filter_page_components/modals/LoadFilterModal";
import SaveFilterModal from "../../components/filter_page_components/modals/SaveFilterModal";
import SaveFilterConfirmationDialog from "../../components/alert_dialogue/SaveFilterConfirmationDialog";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { filterOperatorMapList, operatorLabelMap } from "../../Db";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for options
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for the entire control container, including the search input
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "no options" message
  }),
  loadingMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "loading" message
  }),
};
const Header = styled.span`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const FilterTitle = styled.span`
  font: normal normal 600 15px Open Sans;
  color: #4d4e4f;
`;
const VerticalSpaceBetween = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const Label = styled.span`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const FilterListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ${`max-height: calc(100vh - 576px);`};
  overflow-y: auto;
  column-gap: 10px;
  row-gap: 10px;
  padding: 0px 20px;
  ::-webkit-scrollbar {
    width: 5px;
    background-color: #efeff4;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #999;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #666;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  chipLabel: {
    whiteSpace: "normal",
  },
  icon: {
    color: "#4D4E4F",
  },
});
const CustomLabel = styled.div`
  display: flex;
`;
const ChipLable = styled.span`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
  margin-right: 4px;
`;
const ChipValue = styled.span`
  margin: 0px;
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  word-wrap: break-word;
  width: 100%;
`;
const CustomChip = styled.div`
  display: flex;
  position: relative;
  padding: 20px;
  background-color: #e0e0e0;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  border-radius: 12px;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;
const drawerWidth = 300;

const FilterPage = (props) => {
  const { authStore, userStore } = rootStore;
  let pid = authStore.projectId;
  const [selected, setSelected] = useState([]);
  const [dateRange, setDateRange] = useState({});
  const [numRange, setNumRange] = useState({});
  const [selectedVal, setSelectedVal] = useState({});
  const [tabsList, setTabsList] = React.useState([]);
  const [filterColumns, setFilterColumns] = React.useState([]);
  const [flag, setFlag] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedFilterID, setSelectedFilterID] = useState("");
  const [filterList, setFilterList] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const viewId = userStore.currentLoadedFilter.hasOwnProperty("viewId")
    ? userStore.currentLoadedFilter["viewId"]
    : userStore.currentFilterViewId;
  const payload = userStore.currentFilterPayload;
  const page_size =
    rootStore.filterPageState.filterPaginataionInfo["page_size"];
  const page_number = rootStore.filterPageState.filterPaginataionInfo["page"];
  const renderList = userStore.currentFilterRenderList;
  const inUseFiltersList = userStore.inUseFiltersList;
  const [isViewReset, setIsViewReset] = useState(false);
  const [openSaveFilterDialog, setOpenSaveFilterDialog] = useState(false);
  const [openLoadFilterDialog, setOpenLoadFilterDialog] = useState(false);
  const [openSaveConfirmationDialog, setOpenSaveConfirmationDialog] =
    useState(false);
  const columnsList = [...userStore.AllColumnsList];
  const [clearableSelectedFilter, setClearableSelectedFilter] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [filterOperatorList, setFilterOperatorList] = useState([]);
  const [filterOpertor, setFilterOperator] = useState(null);
  const classes = useStyles();
  const navigate = useNavigate();
  const getAllFilterColumns = async () => {
    try {
      let tempFilterList = [];
      if (columnsList.length > 0) {
        columnsList.forEach((item) => {
          if (
            item.filterable === true &&
            item.is_multiline === false &&
            rootStore.userStore.isColumnVisible(item)
          ) {
            tempFilterList.push(item);
          }
        });
      }
      tempFilterList.sort((a, b) =>
        a["headerName"]
          .toLowerCase()
          .localeCompare(b["headerName"].toLowerCase())
      );
      setFilterColumns(tempFilterList);
    } catch (error) {
      console.log(error);
    }
  };

  const updateTabSearchColumns = (selectedTabId) => {
    let searchCols = [];
    tabsList.forEach((item) => {
      if (item["id"] === selectedTabId) {
        searchCols = item["search_columns"];
      }
    });
    if (columnsList.length > 0) {
      let newCols = [];
      columnsList.forEach((item) => {
        if (
          searchCols.includes(item.field) &&
          item.filterable === true &&
          item.is_multiline === false &&
          rootStore.userStore.isColumnVisible(item)
        ) {
          newCols.push(item);
        }
      });
      newCols.sort((a, b) =>
        a["headerName"]
          .toLowerCase()
          .localeCompare(b["headerName"].toLowerCase())
      );
      setFilterColumns(newCols);
    }
  };

  const initTabs = () => {
    let defaultView = [{ name: "All", id: "all" }];
    let tempTabList = [];
    [...rootStore.leadStore._tabsDetails].forEach((tab) => {
      if (tab["tab"]["filterable"] === true) {
        tempTabList.push(tab["tab"]);
      }
    });
    setTabsList(defaultView.concat(tempTabList));
    if (viewId !== null) {
      if (viewId !== "all") {
        updateTabSearchColumns(viewId);
      } else {
        getAllFilterColumns();
      }
      if (Object.keys(renderList).length > 0) {
        setFlag(true);
      }
    }
  };

  useEffect(() => {
    initTabs();
  }, [pid, refresh]);

  useEffect(() => {
    authStore.updateLeadDetailStatus();
    return () => {
      rootStore.filterPageState.reset();
    };
  }, []);

  const resetFilters = () => {
    setDateRange({});
    setNumRange({});
    setSelectedVal({});
    setSelected([]);
    setFilterList({});
  };

  const isFilterValuePicked = () => {
    if (Object.keys(filterList).length > 0) {
      let data = filterList[selectedFilter["field"]];
      if (data["type"] === "list") {
        return data["value"].length > 0;
      } else if (data["type"] === "range") {
        return (
          data["value"].hasOwnProperty("start") &&
          data["value"].hasOwnProperty("end")
        );
      } else {
        return data["value"] !== null && data["value"] !== "";
      }
    }
    return false;
  };
  const isAddFilterButtonEnabled = () => {
    if (isFilterValuePicked()) {
      return filterOpertor !== null;
    }
    return false;
  };

  const resetChildOfParent = ({ parentFieldMeta, list, fieldNameList }) => {
    for (const key in list) {
      let index = columnsList.findIndex((column) => column["field"] === key);
      if (index !== -1) {
        if (
          columnsList[index]["child_of"] !== null &&
          columnsList[index]["child_of"]["id"] === parentFieldMeta["id"]
        ) {
          if (
            list.hasOwnProperty(parentFieldMeta["field"]) &&
            list[parentFieldMeta["field"]]["value"]["value"] !==
              filterList[parentFieldMeta["field"]]["value"]
          ) {
            fieldNameList = fieldNameList.filter((str) => str !== key);

            delete list[key];
          }
        }
      }
    }
    return { list: list, fieldNameList: fieldNameList };
  };

  const canShowChildField = ({ parent_field_info, child_field_info }) => {
    let index = columnsList.findIndex(
      (column) => column.id === parent_field_info.id
    );
    if (index !== -1) {
      let target_column = columnsList[index];
      if (renderList.hasOwnProperty(target_column["field"])) {
        let childrenConfig = target_column.hasOwnProperty("children_config")
          ? target_column["children_config"]
          : null;
        let parentValue = renderList[target_column["field"]]["value"]["value"];
        if (parentValue.length === 1) {
          if (
            childrenConfig !== null &&
            childrenConfig.hasOwnProperty(parentValue)
          ) {
            let childIdList = childrenConfig[parentValue];
            let index = childIdList.findIndex(
              (id) => id === child_field_info.id
            );
            if (index !== -1) {
              return true;
            }
          }
        }
      }
    }
    return false;
  };

  const handleAdd = () => {
    setShowAlert(false);
    let newList = { ...renderList };
    let fieldNameList = [...inUseFiltersList];

    if (Object.keys(filterList).length > 0) {
      Object.keys(filterList).map((item) => {
        let index = columnsList.findIndex((column) => column["field"] === item);
        if (index !== -1) {
          if (columnsList[index]["parent"] === true) {
            let modifieldData = resetChildOfParent({
              parentFieldMeta: columnsList[index],
              list: newList,
              fieldNameList: fieldNameList,
            });
            newList = modifieldData.list;
            fieldNameList = modifieldData.fieldNameList;
          }
        }

        let operator = filterOpertor !== null ? filterOpertor["value"] : null;
        newList[item] = filterList[item];
        newList[item]["value"] = {
          value: filterList[item]["value"],
          op: operator,
        };
        if (!fieldNameList.includes(item)) {
          fieldNameList.push(item);
        }
      });
    }
    userStore.updateInUseFilterList(fieldNameList);
    userStore.updateCurrentFilterRenderList(newList);

    resetFilters();
    setSelectedFilter(null);
    setSelectedFilterID("");
    setClearableSelectedFilter(null);
    setFilterOperator(null);
  };
  const handleReset = () => {
    setShowAlert(false);
    resetFilters();
    setSelectedFilter(null);
    setClearableSelectedFilter(null);
    setFilterOperator(null);
    setFilterList({});
    setFilterColumns([]);
    userStore.resetCurrentFilter();
    userStore.updateCurrentLoadedFilter({});
    setFlag(true);
    setRefresh(!refresh);
    rootStore.filterPageState.reset();
    rootStore.filterPageState.resetPaginationAndLeadListing();
  };
  const handleCancel = () => {
    resetFilters();
    setFilterList({});
    setFilterColumns([]);
    userStore.resetCurrentFilter();
    userStore.updateCurrentLoadedFilter({});
    setFlag(true);
    setRefresh(!refresh);
    rootStore.filterPageState.reset();
    rootStore.filterPageState.resetPaginationAndLeadListing();
    navigate("/");
  };
  const handleDelete = (item) => {
    let newList = { ...renderList };
    let fieldNameList = [...inUseFiltersList];

    let index = columnsList.findIndex((column) => column["field"] === item);
    if (index !== -1) {
      let parentMeta = columnsList[index];
      if (parentMeta["parent"] === true) {
        for (const key in newList) {
          let child_index = columnsList.findIndex(
            (column) => column["field"] === key
          );
          if (child_index !== -1) {
            let childMeta = columnsList[child_index];
            if (
              childMeta["child_of"] !== null &&
              childMeta["child_of"]["id"] === parentMeta["id"]
            ) {
              fieldNameList = fieldNameList.filter((str) => str !== key);
              delete newList[key];
            }
          }
        }
        fieldNameList = fieldNameList.filter((str) => str !== item);
        delete newList[item];
      } else {
        fieldNameList = fieldNameList.filter((str) => str !== item);
        delete newList[item];
      }
    }

    userStore.updateInUseFilterList(fieldNameList);
    userStore.updateCurrentFilterRenderList(newList);
  };

  const generateFiltersPayload = (e) => {
    e.preventDefault();
    let allFiltersList = {};
    if (Object.keys(renderList).length !== 0) {
      Object.keys(renderList).map((key) => {
        if (
          renderList[key]["type"] === "normal" ||
          renderList[key]["type"] === ""
        ) {
          if (
            renderList[key]["dataType"] === "date" ||
            renderList[key]["dataType"] === "datetime"
          ) {
            let newDate = getIsoString(renderList[key]["value"]["value"]);
            allFiltersList[key] = {
              value: newDate,
              op: renderList[key]["value"]["op"],
            };
          } else {
            allFiltersList[key] = {
              value: renderList[key]["value"]["value"],
              op: renderList[key]["value"]["op"],
            };
          }
        } else if (renderList[key]["type"] === "range") {
          if (
            renderList[key]["dataType"] === "date" ||
            renderList[key]["dataType"] === "datetime"
          ) {
            let d1 = getIsoString(renderList[key]["value"]["value"]["start"]);
            let d2 = getIsoString(renderList[key]["value"]["value"]["end"]);
            allFiltersList[key] = {
              value: {
                start: d1,
                end: d2,
              },
              op: renderList[key]["value"]["op"],
            };
          } else if (renderList[key]["dataType"] === "integer") {
            let num1 = renderList[key]["value"]["value"]["start"];
            let num2 = renderList[key]["value"]["value"]["end"];
            allFiltersList[key] = {
              value: {
                start: num1,
                end: num2,
              },
              op: renderList[key]["value"]["op"],
            };
          }
        } else if (renderList[key]["type"] === "list") {
          allFiltersList[key] = {
            value: renderList[key]["value"]["value"],
            op: renderList[key]["value"]["op"],
          };
        }
      });
    }
    if (Object.keys(allFiltersList).length > 0) {
      let custom_params = {};
      Object.keys(allFiltersList).map((key) => {
        let index = columnsList.findIndex((column) => column["field"] === key);
        if (index !== -1) {
          if (columnsList[index]["is_custom_param"]) {
            let keyName = key.replace("custom_params_", "");
            let newValue = allFiltersList[key];
            delete allFiltersList[key];
            custom_params[keyName] = newValue;
          }
        }
      });
      allFiltersList["custom_params"] = custom_params;
    }
    userStore.updateCurrentFilterPayload({ ...allFiltersList });
    if (Object.keys(renderList).length > 0) {
      rootStore.filterPageState.updateFilterPaginationInfo({
        key: "page_size",
        value: rootStore.userStore.defaultLeadListPageSize,
      });
      rootStore.filterPageState.updateFilterPaginationInfo({
        key: "page",
        value: 0,
      });
      rootStore.filterPageState.reset();
      rootStore.filterPageState.resetPaginationAndLeadListing();
      setFlag(true);
    } else {
      setAlertMessage(
        "Kindly apply at least one filter before proceeding with your search."
      );
      setShowAlert(true);
    }
  };

  const handleSave = () => {
    if (userStore.currentLoadedFilter.hasOwnProperty("name")) {
      setOpenSaveConfirmationDialog(true);
    } else {
      setOpenSaveFilterDialog(true);
    }
  };

  const updateFilterSelectionList = (viewId) => {
    if (viewId !== "all") {
      updateTabSearchColumns(viewId);
    } else {
      getAllFilterColumns();
    }
  };

  const isLoadFilterButtonDisabled = () => {
    if (Object.keys(userStore.savedFilterTemplateList).length > 0) {
      if (
        userStore.savedFilterTemplateList.hasOwnProperty(authStore.projectId)
      ) {
        return (
          Object.keys(userStore.savedFilterTemplateList[authStore.projectId])
            .length === 0
        );
      }
    }
    return true;
  };

  //***********below are the helper functions to edit filter chip */

  const handleListFilter = (list) => {
    let newList = [];
    list.map((e) => {
      let newObj = { label: e, value: e };
      newList.push(newObj);
    });
    setSelected(newList);
  };

  const handleRangeFilter = (data, dataType, field) => {
    let newObj = { start: data["start"], end: data["end"] };
    if (dataType === "date" || dataType === "datetime") {
      setDateRange({ [field]: newObj });
    } else if (dataType === "integer") {
      setNumRange({ [field]: { value: newObj } });
    }
  };
  const handleFilterChipEdit = (filterData, field) => {
    let index = filterColumns.findIndex((column) => column["field"] === field);
    let filteredList = inUseFiltersList.filter((item) => item !== field);
    userStore.updateInUseFilterList([...filteredList]);
    setSelectedFilterID(filterColumns[index]["id"]);
    setSelectedFilter(filterColumns[index]);
    setClearableSelectedFilter({
      label: filterColumns[index]["headerName"],
      value: filterColumns[index]["id"],
    });
    let tempObj = {
      [field]: {
        value: filterData["value"]["value"],
        type: filterData["type"],
        dataType: filterData["dataType"],
      },
    };
    setFilterList(tempObj);
    initOperatorListAndValueOnEdit(filterColumns[index], filterData);

    if (filterData["type"] === "list") {
      handleListFilter(filterData["value"]["value"]);
    } else if (filterData["type"] === "range") {
      handleRangeFilter(
        filterData["value"]["value"],
        filterData["dataType"],
        field
      );
    }
  };

  //**********************END*************************** */

  const getFilterListToRender = () => {
    let newList = [];
    filterColumns.forEach((column) => {
      if (!inUseFiltersList.includes(column["field"])) {
        if (column["child_of"] !== null) {
          if (
            canShowChildField({
              parent_field_info: column["child_of"],
              child_field_info: column,
            })
          ) {
            let tempObj = { label: column["headerName"], value: column["id"] };
            newList.push(tempObj);
          }
        } else {
          let tempObj = { label: column["headerName"], value: column["id"] };
          newList.push(tempObj);
        }
      }
    });
    return newList;
  };

  const initOperatorListAndValueOnEdit = (columnMeta, data) => {
    let tempList = [];

    if (columnMeta["parent"] === true) {
      tempList = filterOperatorMapList["parent"];
    } else {
      if (columnMeta["child_of"] !== null) {
        tempList = filterOperatorMapList["child"];
      } else {
        if (
          columnMeta["data_type"] === "date" ||
          columnMeta["data_type"] === "datetime"
        ) {
          if (columnMeta["filter_type"] === "range") {
            tempList = filterOperatorMapList["date_range"];
          } else {
            tempList = filterOperatorMapList["date"];
          }
        } else if (columnMeta["data_type"] === "string") {
          if (columnMeta["filter_type"] === "list") {
            tempList = filterOperatorMapList["list"];
          } else {
            tempList = filterOperatorMapList["string"];
          }
        } else if (columnMeta["data_type"] === "integer") {
          if (columnMeta["filter_type"] === "range") {
            tempList = filterOperatorMapList["integer_range"];
          } else if (columnMeta["filter_type"] === "list") {
            tempList = filterOperatorMapList["list"];
          } else {
            tempList = filterOperatorMapList["integer"];
          }
        }
      }
    }

    let index = tempList.findIndex(
      (operator) => operator["value"] === data["value"]["op"]
    );
    if (index !== -1) {
      setFilterOperatorList(tempList);
      setFilterOperator(tempList[index]);
    } else {
      setFilterOperatorList([]);
      setFilterOperator(null);
    }
  };

  const getOperatorList = (item) => {
    if (item["parent"] === true) {
      return filterOperatorMapList["parent"];
    } else {
      if (item["child_of"] !== null) {
        return filterOperatorMapList["child"];
      } else {
        if (item["data_type"] === "date" || item["data_type"] === "datetime") {
          if (item["filter_type"] === "range") {
            return filterOperatorMapList["date_range"];
          } else {
            return filterOperatorMapList["date"];
          }
        } else if (item["data_type"] === "string") {
          if (item["filter_type"] === "list") {
            return filterOperatorMapList["list"];
          } else {
            return filterOperatorMapList["string"];
          }
        } else if (item["data_type"] === "integer") {
          if (item["filter_type"] === "range") {
            return filterOperatorMapList["integer_range"];
          } else if (item["filter_type"] === "list") {
            return filterOperatorMapList["list"];
          } else {
            return filterOperatorMapList["integer"];
          }
        }
      }
    }
    return [];
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <CssBaseline />
        <Drawer
          sx={{
            width: userStore.isFilterDrawerOpen ? drawerWidth : 70,
            flexShrink: 0,
            zIndex: 10,
            "& .MuiDrawer-paper": {
              width: userStore.isFilterDrawerOpen ? drawerWidth : 70,
              boxSizing: "border-box",
              overflow: userStore.isFilterDrawerOpen ? "visible" : "hidden",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <VerticalSpaceBetween
            onSubmit={(e) => {
              e.preventDefault();
              if (
                isAddFilterButtonEnabled() &&
                Object.keys(renderList).length > 0
              ) {
                setAlertMessage(
                  "Kindly apply the selected filter before proceeding with your search."
                );
                setShowAlert(true);
              } else {
                generateFiltersPayload(e);
              }
            }}
          >
            <List>
              <ListItem
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: userStore.isFilterDrawerOpen ? "flex" : "none",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <ListItemText
                    style={{
                      visibility: userStore.isFilterDrawerOpen
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    <Header>Filters</Header>
                  </ListItemText>
                  <FilterTitle>
                    {userStore.currentLoadedFilter.hasOwnProperty("name")
                      ? userStore.currentLoadedFilter["name"]
                      : "-"}
                  </FilterTitle>
                </div>
                <Tooltip title="Load">
                  <IconButton
                    style={{
                      width: "40px",
                      height: "40px",
                      display: userStore.isFilterDrawerOpen ? "unset" : "none",
                    }}
                    onClick={() => {
                      setOpenLoadFilterDialog(true);
                    }}
                    color="primary"
                    disabled={isLoadFilterButtonDisabled()}
                  >
                    <SimCardDownload />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Save">
                  <IconButton
                    style={{
                      width: "40px",
                      height: "40px",
                      display: userStore.isFilterDrawerOpen ? "unset" : "none",
                    }}
                    onClick={() => {
                      handleSave();
                    }}
                    disabled={Object.keys(renderList).length === 0}
                    color="primary"
                  >
                    <Save />
                  </IconButton>
                </Tooltip>

                {userStore.isFilterDrawerOpen ? (
                  <IconButton
                    onClick={() => {
                      userStore.updateFilterDrawerState(false);
                    }}
                  >
                    {" "}
                    <ChevronLeft />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => {
                      userStore.updateFilterDrawerState(true);
                    }}
                  >
                    {" "}
                    <Menu />
                  </IconButton>
                )}
              </ListItem>
              <ListItem
                style={{
                  visibility: userStore.isFilterDrawerOpen
                    ? "visible"
                    : "hidden",
                }}
              >
                <InputWrapper>
                  <Label>Select View</Label>
                  <FormControl
                    classes={{
                      root: classes.quantityRoot,
                    }}
                  >
                    <MuiSelect
                      classes={{
                        icon: classes.icon,
                      }}
                      name="segment"
                      displayEmpty
                      disableUnderline
                      variant="outlined"
                      value={viewId}
                      onChange={(e) => {
                        setShowAlert(false);
                        handleReset();
                        setFlag(false);
                        setIsViewReset(true);
                        setSelectedFilter(null);
                        userStore.updateCurrentFilterViewId(e.target.value);
                        updateFilterSelectionList(e.target.value);
                        // setTabId(e.target.value["id"]);
                        rootStore.filterPageState.reset();
                        rootStore.filterPageState.resetPaginationAndLeadListing();
                      }}
                      required
                      style={{
                        width: "100%",
                        height: "30px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        borderRadius: "0px",
                      }}
                    >
                      {tabsList.length > 0 &&
                        tabsList.map((item, i) => {
                          return (
                            <MenuItem
                              key={i}
                              style={{ fontSize: "12px" }}
                              value={item.id}
                              id={item.id}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                    </MuiSelect>
                  </FormControl>
                </InputWrapper>
              </ListItem>
              <ListItem
                style={{
                  visibility: userStore.isFilterDrawerOpen
                    ? "visible"
                    : "hidden",
                }}
              >
                <InputWrapper>
                  <Label>Select Filter</Label>
                  <Select
                    options={getFilterListToRender()}
                    value={clearableSelectedFilter}
                    onChange={(selectedOption) => {
                      setClearableSelectedFilter(selectedOption);
                      if (selectedOption !== null) {
                        setSelectedFilterID(selectedOption.value);
                        resetFilters();
                        setIsViewReset(false);
                        setShowAlert(false);
                        setSelectedFilter(null);
                        let index = filterColumns.findIndex(
                          (column) => column["id"] === selectedOption.value
                        );
                        setSelectedFilter(filterColumns[index]);
                        let tempList = getOperatorList(filterColumns[index]);
                        setFilterOperatorList(tempList);
                        setFilterOperator(null);
                      } else {
                        setSelectedFilterID("");
                        resetFilters();
                        setIsViewReset(false);
                        setShowAlert(false);
                        setSelectedFilter(null);
                        setFilterOperatorList([]);
                        setFilterOperator(null);
                      }
                    }}
                    closeMenuOnSelect={true}
                    styles={customStyles}
                    isClearable
                  />
                </InputWrapper>
              </ListItem>
              <ListItem
                style={{
                  visibility: userStore.isFilterDrawerOpen
                    ? "visible"
                    : "hidden",
                }}
              >
                <InputWrapper>
                  <Label>Select Operator</Label>
                  <Select
                    isDisabled={
                      filterOperatorList.length === 0 || selectedFilter === null
                    }
                    options={filterOperatorList}
                    value={filterOpertor}
                    onChange={(selectedOption) => {
                      setFilterOperator(selectedOption);
                    }}
                    closeMenuOnSelect={true}
                    styles={customStyles}
                    isClearable
                  />
                </InputWrapper>
              </ListItem>
              <ListItem
                style={{
                  visibility: userStore.isFilterDrawerOpen
                    ? "visible"
                    : "hidden",
                }}
              >
                <InputWrapper>
                  <Label>Filter Value</Label>
                  {selectedFilter === null ? (
                    <OutlinedInput
                      disabled
                      placeholder="Enter Value"
                      style={{
                        width: "100%",
                        height: "30px",
                        fontSize: "12px",
                      }}
                    />
                  ) : (
                    <FilterValueComponent
                      item={selectedFilter}
                      selectedVal={selectedVal}
                      setSelectedVal={setSelectedVal}
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                      numRange={numRange}
                      setNumRange={setNumRange}
                      filterList={filterList}
                      setFilterList={setFilterList}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  )}
                </InputWrapper>
              </ListItem>

              <ListItem
                style={{
                  visibility: userStore.isFilterDrawerOpen
                    ? "visible"
                    : "hidden",
                }}
              >
                <ButtonWrapper
                  style={{ justifyContent: "flex-end", marginBottom: "20px" }}
                >
                  <Button
                    disabled={!isAddFilterButtonEnabled()}
                    onClick={handleAdd}
                    variant="contained"
                    color="primary"
                    style={{ textTransform: "capitalize", width: "100%" }}
                  >
                    <Add /> Add Filter
                  </Button>
                </ButtonWrapper>
              </ListItem>
              <Divider style={{ marginBottom: "20px" }} />
              <ListItem
                style={{
                  visibility: userStore.isFilterDrawerOpen
                    ? "visible"
                    : "hidden",
                }}
              >
                {Object.keys(renderList).length > 0 && (
                  <FilterListContainer>
                    {Object.keys(renderList).map((item, i) => {
                      let index = columnsList.findIndex(
                        (column) => column["field"] === item
                      );
                      if (index !== -1) {
                        let headerName = columnsList[index]["headerName"];

                        if (renderList[item]["type"] === "list") {
                          let value = renderList[item]["value"]["value"];
                          return (
                            <CustomChip
                              key={i}
                              style={{ flexDirection: "column" }}
                              onClick={() => {
                                handleFilterChipEdit(renderList[item], item);
                              }}
                            >
                              <Cancel
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDelete(item);
                                }}
                                style={{
                                  position: "absolute",
                                  top: "2px",
                                  right: "2px",
                                  cursor: "pointer",
                                }}
                              />
                              <ChipLable>{`${headerName} ${
                                operatorLabelMap[
                                  renderList[item]["value"]["op"]
                                ]
                              }`}</ChipLable>
                              <Column>
                                {value.length > 0 &&
                                  value.map((list_item, i) => {
                                    return (
                                      <Row key={i}>
                                        <Circle
                                          style={{
                                            width: "8px",
                                            height: "8px",
                                            color: "gray",
                                            marginTop: "2px",
                                          }}
                                        />
                                        <ChipValue>{list_item}</ChipValue>
                                      </Row>
                                    );
                                  })}
                              </Column>
                            </CustomChip>
                          );
                        } else if (renderList[item]["type"] === "range") {
                          return (
                            <>
                              <CustomChip
                                onClick={() => {
                                  handleFilterChipEdit(renderList[item], item);
                                }}
                              >
                                <Cancel
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDelete(item);
                                  }}
                                  style={{
                                    position: "absolute",
                                    top: "2px",
                                    right: "2px",
                                    cursor: "pointer",
                                  }}
                                />
                                <ChipLable>{`${headerName} ${
                                  operatorLabelMap[
                                    renderList[item]["value"]["op"]
                                  ]
                                }`}</ChipLable>
                                <ChipValue>{`${renderList[item]["value"]["value"]["start"]} to ${renderList[item]["value"]["value"]["end"]}`}</ChipValue>
                              </CustomChip>
                            </>
                          );
                        } else {
                          return (
                            <>
                              <CustomChip
                                onClick={() => {
                                  handleFilterChipEdit(renderList[item], item);
                                }}
                              >
                                <Cancel
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDelete(item);
                                  }}
                                  style={{
                                    position: "absolute",
                                    top: "2px",
                                    right: "2px",
                                    cursor: "pointer",
                                  }}
                                />
                                <ChipLable>{`${headerName} ${
                                  operatorLabelMap[
                                    renderList[item]["value"]["op"]
                                  ]
                                }`}</ChipLable>
                                <ChipValue>
                                  {renderList[item]["value"]["value"]}
                                </ChipValue>
                              </CustomChip>
                            </>
                          );
                        }
                      }
                    })}
                  </FilterListContainer>
                )}
              </ListItem>
            </List>
            <AppBar
              position="sticky"
              color="inherit"
              elevation={0}
              style={{
                padding: "0px",
                border: "1px solid #E0E0E0",
                visibility: userStore.isFilterDrawerOpen ? "visible" : "hidden",
              }}
              sx={{ top: "auto", left: 0, bottom: 0, width: 300 }}
            >
              <Toolbar
                style={{
                  width: 300,
                  position: "relative",
                  padding: "16px",
                }}
              >
                <Grid container spacing={2}>
                  {showAlert && (
                    <Grid item xs={12}>
                      <Alert
                        style={{
                          width: "100%",
                          padding: "0px 20px",
                          fontSize: "12px",
                        }}
                        severity="warning"
                      >
                        {alertMessage}
                      </Alert>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <ButtonWrapper>
                      <Button
                        onClick={() => {
                          handleCancel();
                        }}
                        variant="contained"
                        color="inherit"
                        style={{ textTransform: "capitalize", width: "100%" }}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          handleReset();
                        }}
                        variant="contained"
                        color="error"
                        style={{ textTransform: "capitalize", width: "100%" }}
                      >
                        Reset
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{ textTransform: "capitalize", width: "100%" }}
                      >
                        Search
                      </Button>
                    </ButtonWrapper>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </VerticalSpaceBetween>
        </Drawer>
        <Box
          component="main"
          sx={{ flexGrow: 1, width: "80%", height: "100%" }}
        >
          <FilterTable
            tabId={viewId}
            flag={flag}
            setFlag={setFlag}
            filterPayload={payload}
            page={page_number}
            size={page_size}
            refresh={refresh}
            isViewReset={isViewReset}
            tabsList={tabsList}
          />
        </Box>
      </Box>

      {openLoadFilterDialog && (
        <LoadFilterModal
          open={openLoadFilterDialog}
          setOpen={setOpenLoadFilterDialog}
          updateFilterSelectionList={updateFilterSelectionList}
        />
      )}
      {openSaveFilterDialog && (
        <SaveFilterModal
          open={openSaveFilterDialog}
          setOpen={setOpenSaveFilterDialog}
          filters={renderList}
          viewId={viewId}
        />
      )}
      {openSaveConfirmationDialog && (
        <SaveFilterConfirmationDialog
          open={openSaveConfirmationDialog}
          setOpen={setOpenSaveConfirmationDialog}
          handleUpdate={() => {
            userStore.updateSavedFilterTemplateList({
              fitlerName: userStore.currentLoadedFilter["name"],
              viewId: viewId,
              filters: renderList,
            });
            userStore.updateCurrentLoadedFilter({
              name: userStore.currentLoadedFilter["name"],
              viewId: viewId,
              filters: renderList,
            });
            setOpenSaveConfirmationDialog(false);
          }}
          handleNew={() => {
            setOpenSaveFilterDialog(true);
            setOpenSaveConfirmationDialog(false);
          }}
        />
      )}
    </>
  );
};

export default observer(FilterPage);
