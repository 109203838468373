import React, { useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect } from "react";
import { DatePicker } from "antd";
import "../../filter_modal/style.css";
import SlugMultiSelectFilter from "./multi_select_filters/SlugMultiSelectFilter";
import NormalMultiSelectFilter from "./multi_select_filters/NormalMultiSelectFilter";
import rootStore from "../../../stores/RootStore";
import NestedChildMultiSelectFilter from "./multi_select_filters/MultiSelectField";
import SingleSelect from "../../select_dropdown/SingleSelect";
import SingleSelectNew from "../../select_dropdown/SingleSelectNew";
import MultiSelectField from "./multi_select_filters/MultiSelectField";

const Label = styled.span`
  font: normal normal normal 12px Open Sans;
  color: black;
`;
const Input = styled.input`
  width: 100%;
  height: 30px;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  color: #4d4e4f;
  font: normal normal 600 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal normal 12px Open Sans;
    color: #b5b5b5;
    text-transform: lowercase;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;

const Row = styled.div`
  display: flex;
  /* width: 100%;
  margin-bottom: 10px; */
  align-items: center;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`;

// this component is being used as global filter as well as to create target segement
const FilterValueComponent = (props) => {
  const { RangePicker } = DatePicker;
  const columnsList = [...rootStore.userStore.AllColumnsList];
  const renderList = { ...rootStore.userStore.currentFilterRenderList };
  const [item, setItem] = useState(null);

  const handleChange = ({ e, dataType, filterType }) => {
    console.log(e.target.name, e.target.value);
    let value =
      dataType === "integer" ? parseInt(e.target.value) : e.target.value;
    props.setFilterList({
      ...props.filterList,
      [e.target.name]: { value: value, type: filterType, data_type: dataType },
    });
  };
  useEffect(() => {
    if (props.item !== null) {
      setItem(props.item);
    }
  }, [props.item]);

  const getParentFieldValue = (parent_field_info) => {
    let index = columnsList.findIndex(
      (column) => column.id === parent_field_info.id
    );
    if (index !== -1) {
      let target_column = columnsList[index];
      if (renderList.hasOwnProperty(target_column["field"])) {
        return {
          value: renderList[target_column["field"]]["value"],
          field_data: target_column,
        };
      }
    }
    return null;
  };

  return (
    <>
      {(() => {
        if (item !== null) {
          if (item.filter_type === "normal" || item.filter_type === "") {
            return (
              <InputWrapper>
                <Input
                  type={
                    item.data_type === "email"
                      ? "email"
                      : item.data_type === "datetime" ||
                        item.data_type === "date"
                      ? "date"
                      : item.data_type === "integer"
                      ? "number"
                      : "text"
                  }
                  name={item.field}
                  value={
                    props.filterList.hasOwnProperty(item.field)
                      ? props.filterList[item.field]["value"]
                      : ""
                  }
                  placeholder={`enter ${item.headerName}`}
                  //   style={{ width: "170px" }}
                  onChange={(e) => {
                    let dataType = item.data_type;
                    let filterType = item.filter_type;
                    handleChange({ e, dataType, filterType });
                  }}
                />
              </InputWrapper>
            );
          } else if (item.filter_type === "range") {
            if (item.data_type === "datetime" || item.data_type === "date") {
              return (
                <InputWrapper>
                  <RangePicker
                    placeholder={["From", "To"]}
                    size="small"
                    // getPopupContainer={(triggerNode) => {
                    //   return triggerNode.parentNode;
                    // }}
                    style={{
                      height: "30px",
                    }}
                    value={
                      props.dateRange.hasOwnProperty(item.field)
                        ? [
                            moment(
                              props.dateRange[item.field]["start"],
                              "YYYY-MM-DD"
                            ),
                            moment(
                              props.dateRange[item.field]["end"],
                              "YYYY-MM-DD"
                            ),
                          ]
                        : [null, null]
                    }
                    onChange={(value) => {
                      let d1 = moment(value[0]).format("YYYY-MM-DD");
                      let d2 = moment(value[1]).format("YYYY-MM-DD");
                      props.setDateRange({
                        ...props.dateRange,
                        [item.field]: { start: d1, end: d2 },
                      });
                      props.setFilterList({
                        ...props.filterList,
                        [item.field]: {
                          value: { start: d1, end: d2 },
                          type: item.filter_type,
                          dataType: item.data_type,
                        },
                      });
                    }}
                  />
                </InputWrapper>
              );
            } else if (item.data_type === "integer") {
              return (
                <InputWrapper>
                  <Row>
                    <Input
                      type="number"
                      name="start"
                      placeholder="min"
                      value={
                        props.numRange.hasOwnProperty(item.field)
                          ? props.numRange[item.field]["value"]["start"]
                          : ""
                      }
                      onChange={(e) => {
                        let start = parseInt(e.target.value);
                        let end =
                          props.numRange.hasOwnProperty(item.field) &&
                          props.numRange[item.field]["value"]["end"] !== ""
                            ? parseInt(
                                props.numRange[item.field]["value"]["end"]
                              )
                            : "";
                        props.setNumRange({
                          ...props.numRange,
                          [item.field]: {
                            value: { start: start, end },
                            type: item.filter_type,
                            dataType: item.data_type,
                          },
                        });
                        props.setFilterList({
                          ...props.filterList,
                          [item.field]: {
                            value: { start: start, end },
                            type: item.filter_type,
                            dataType: item.data_type,
                          },
                        });
                      }}
                      style={{
                        width: "100%",
                        marginRight: "10px",
                      }}
                    />
                    <Input
                      type="number"
                      name="end"
                      placeholder="max"
                      value={
                        props.numRange.hasOwnProperty(item.field)
                          ? props.numRange[item.field]["value"]["end"]
                          : ""
                      }
                      disabled={
                        !props.numRange.hasOwnProperty(item.field) ||
                        (props.numRange.hasOwnProperty(item.field) &&
                          props.numRange[item.field]["value"]["start"] === "")
                      }
                      onChange={(e) => {
                        let start =
                          props.numRange.hasOwnProperty(item.field) &&
                          props.numRange[item.field]["value"]["start"] !== ""
                            ? parseInt(
                                props.numRange[item.field]["value"]["start"]
                              )
                            : "";
                        let end = parseInt(e.target.value);
                        props.setNumRange({
                          ...props.numRange,
                          [item.field]: {
                            value: { start: start, end: end },
                            type: item.filter_type,
                            dataType: item.data_type,
                          },
                        });
                        props.setFilterList({
                          ...props.filterList,
                          [item.field]: {
                            value: { start: start, end: end },
                            type: item.filter_type,
                            dataType: item.data_type,
                          },
                        });
                      }}
                      style={{ width: "100%" }}
                    />
                  </Row>
                </InputWrapper>
              );
            }
          } else if (item.filter_type === "list") {
            if (item["map_to_parent_selection"]) {
              console.log("child field found::::", item);
              let parent_field_info = item.child_of;
              let parent_data = getParentFieldValue(parent_field_info); //getting the data of parent field including value
              let parent_value =
                parent_data !== null ? parent_data["value"] : null;

              let child_render_list = [];
              if (parent_value !== null) {
                let child_data = JSON.parse(item.filter_input);
                child_render_list = child_data.hasOwnProperty(
                  parent_value["value"][0]
                )
                  ? child_data[parent_value["value"][0]]
                  : [];
              }
              return (
                <MultiSelectField
                  filterType={item.filter_type}
                  dataType={item.data_type}
                  list={child_render_list}
                  field={item.field}
                  headerName={item.headerName}
                  setSelectedVal={props.setSelectedVal}
                  selectedVal={props.selectedVal}
                  setFilterList={props.setFilterList}
                  filterList={props.filterList}
                  selected={props.selected}
                  setSelected={props.setSelected}
                />
              );
            } else if (
              item.filter_input.charAt(0) === "/" ||
              item.filter_input.startsWith("ep:")
            ) {
              return (
                <SlugMultiSelectFilter
                  item={item}
                  filterType={item.filter_type}
                  dataType={item.data_type}
                  headerName={item.headerName}
                  setSelectedVal={props.setSelectedVal}
                  selectedVal={props.selectedVal}
                  field={item.field}
                  type={"global"}
                  setFilterList={props.setFilterList}
                  filterList={props.filterList}
                  selected={props.selected}
                  setSelected={props.setSelected}
                />
              );
            } else if (
              item.filter_input.charAt(0) !== "/" &&
              item.filter_input !== "" &&
              item.filter_input !== null
            ) {
              return (
                <NormalMultiSelectFilter
                  filterType={item.filter_type}
                  dataType={item.data_type}
                  list={item.filter_input}
                  field={item.field}
                  headerName={item.headerName}
                  setSelectedVal={props.setSelectedVal}
                  selectedVal={props.selectedVal}
                  type={"global"}
                  setFilterList={props.setFilterList}
                  filterList={props.filterList}
                  selected={props.selected}
                  setSelected={props.setSelected}
                />
              );
            } else if (item.filter_input === "" || item.filter_input === null) {
              return (
                <InputWrapper>
                  <Input
                    type={
                      item.data_type === "email"
                        ? "email"
                        : item.data_type === "datetime" ||
                          item.data_type === "date"
                        ? "date"
                        : item.data_type === "integer"
                        ? "number"
                        : "text"
                    }
                    name={item.field}
                    placeholder={`enter ${item.headerName}`}
                    //   style={{ width: "170px" }}
                    onChange={(e) => {
                      let dataType = item.data_type;
                      let filterType = item.filter_type;
                      handleChange({ e, dataType, filterType });
                    }}
                  />
                </InputWrapper>
              );
            }
          }
        }
      })()}
    </>
  );
};

export default observer(FilterValueComponent);
