import React, { useRef } from "react";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { CircularProgress, Divider } from "@material-ui/core";
import { Avatar, Box, Tooltip, Typography } from "@mui/material";
import rootStore from "../../../stores/RootStore";
import { AgGridReact } from "ag-grid-react";
import { useMemo } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import moment from "moment";
import {
  generateHourListFromHourlyLogs,
  getTableSize,
} from "../../../Functions";
const Container = styled(Box)`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  border: 1px solid #eceef2;
`;

const ChartBottom = styled(Box)`
  width: 100%;
  height: 100%;
`;

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
const Text = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
  text-align: left;
  color: #4d4e4f;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const TextSm = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  margin: 0px;
  text-align: left;
  color: #4d4e4f;
`;

const AutoPagingCheckinActivityTabularWidget = (props) => {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([]);
  const componentRef = useRef(null);
  const intervalRef = useRef(null);
  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));

  const gridRef = useRef(null);

  const onFirstDataRendered = (params) => {
    const autoSizeColumns = columns
      .filter((col) => col.autoSize)
      .map((col) => col.field);
    params.columnApi.autoSizeColumns(autoSizeColumns);
  };
  const onGridReady = (params) => {
    gridRef.current = params.api;
  };
  useEffect(() => {
    // Function to change pages
    const autoPageChange = () => {
      if (gridRef.current) {
        const api = gridRef.current;
        const currentPage = api.paginationGetCurrentPage();
        const totalPages = api.paginationGetTotalPages();

        if (currentPage + 1 >= totalPages) {
          clearInterval(intervalRef.current); // Stop the loop when reaching the last page
          intervalRef.current = null; // Clear reference
        } else {
          api.paginationGoToPage(currentPage + 1);
        }
      }
    };

    const interval = setInterval(autoPageChange, 15000); // Change page every 15 seconds

    // Clean up the interval when the component is unmounted
    return () => clearInterval(interval);
  }, []);

  const ChartBottomSection = () => {
    if (!loading) {
      if (tableData.length > 0) {
        return (
          <Wrapper>
            <TableWrapper
              style={{ width: "100%", height: "100%" }}
              sx={{
                "& .ag-root-wrapper": {
                  border: "none",
                },
                ".ag-paging-page-summary-panel": {
                  display: "none",
                },
                ".table-widget-pagination .ag-paging-panel": {
                  justifyContent: "flex-end",
                },
              }}
            >
              <div
                className="ag-theme-alpine"
                style={{ width: "100%", height: "100%", overflowY: "auto" }}
              >
                <AgGridReact
                  ref={gridRef}
                  onGridReady={onGridReady}
                  rowData={tableData}
                  defaultColDef={defaultColDef}
                  columnDefs={columns}
                  animateRows={true}
                  pagination={true}
                  paginationPageSize={getTableSize({
                    containerHeight: getContainerHeight(),
                  })}
                  onFirstDataRendered={onFirstDataRendered}
                  className="table-widget-pagination"
                />
              </div>
            </TableWrapper>
          </Wrapper>
        );
      } else {
        return (
          <Wrapper>
            <Typography>No data found!</Typography>
          </Wrapper>
        );
      }
    } else {
      return (
        <Wrapper>
          <CircularProgress />
        </Wrapper>
      );
    }
  };

  const getContainerHeight = () => {
    if (componentRef.current) {
      const height = componentRef.current.getBoundingClientRect().height;
      return height;
    }
    return 0;
  };

  const getColorForHourBoxWidget = (value) => {
    if (value == 0) {
      return "#f7fafd";
    } else if (value <= 5) {
      return "#abc6eb";
    } else if (value <= 25) {
      return "#6596da";
    } else {
      return "#26599E";
    }
  };
  const getLastColumnWidth = (numberOfTiles) => {
    return numberOfTiles * 40 + 50; //here 34 -> 24(square grid size) + spacing between grid is 10, 50 is extra spacing
  };

  const formatCheckinValue = (value) => {
    if (value > 99) {
      return "99+";
    }
    return value;
  };

  const setupColumns = ({ data, hourList }) => {
    const columns_list = [];
    if (data.length > 0) {
      Object.keys(data[0]).forEach((key, i) => {
        if (key !== "hour_logs") {
          let newObj = {
            field: key,
            headerName: key,
            cellRenderer: (params) => {
              let data = null;
              if (
                params.data[key] !== null &&
                params.data[key] !== "" &&
                params.data[key] !== undefined
              ) {
                if (typeof params.data[key] === "boolean") {
                  if (params.data[key] === true) {
                    data = "True";
                  } else {
                    data = "False";
                  }
                } else {
                  data = params.data[key];
                }
              }
              return (
                <span className="csutable-cell-trucate">
                  {data !== null ? data : "-"}
                </span>
              );
            },
          };
          newObj["autoSize"] = true;

          columns_list.push(newObj);
        }
      });

      let lastColumn = {
        field: "hour_logs",
        headerName: "Checkins By Hour",
        minWidth: getLastColumnWidth(hourList.length),
        flex: 1,
        cellRenderer: (params) => {
          let hourLogs = params.data.hasOwnProperty("hour_logs")
            ? params.data.hour_logs
            : [];
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "16px",
                width: "fit-content",
                height: "100%",
              }}
            >
              {hourList.map((hourValue, idx) => {
                let index = hourLogs.findIndex(
                  (hour) => parseInt(hour["hour"]) === hourValue
                );
                if (index !== -1) {
                  let hourLogData = hourLogs[index];

                  return (
                    <Tooltip
                      title={`Total Checkins : ${hourLogData["total_checkins"]}`}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <TextSm>
                          {moment({ hour: hourValue % 24 }).format("h A")}
                        </TextSm>
                        <Avatar
                          key={idx}
                          variant="square"
                          sx={{
                            fontWeight: "bold",
                            width: "24px",
                            height: "24px",
                            fontSize: "10px",
                            backgroundColor: getColorForHourBoxWidget(
                              hourLogData["total_checkins"]
                            ),
                            borderRadius: "4px",
                          }}
                        >
                          {formatCheckinValue(hourLogData["total_checkins"])}
                        </Avatar>
                      </Box>
                    </Tooltip>
                  );
                } else {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <TextSm>
                        {moment({ hour: hourValue % 24 }).format("h A")}
                      </TextSm>
                      <Avatar
                        key={idx}
                        variant="square"
                        sx={{
                          fontWeight: "bold",
                          width: "24px",
                          height: "24px",
                          fontSize: "10px",
                          backgroundColor: "#f7fafd",
                          borderRadius: "4px",
                          border: "1px solid #dde2eb",
                        }}
                      >
                        &nbsp;
                      </Avatar>
                    </Box>
                  );
                }
              })}
            </Box>
          );
        },
      };
      columns_list.push(lastColumn);
    }

    return columns_list;
  };
  const combineHourlyAndDailyLog = ({ data }) => {
    let tempDailyLog = data["daily_log"];
    let tempHourlyLog = data["hourly_logs"];
    let userHoursDataMapping = {};
    tempHourlyLog.forEach((log) => {
      if (userHoursDataMapping.hasOwnProperty(log["User Name"])) {
        let userHourData = userHoursDataMapping[log["User Name"]];
        if (userHourData.hasOwnProperty(log["Hour"])) {
          userHourData[log["Hour"]] += log["Total Checkins"];
        } else {
          userHourData[log["Hour"]] = log["Total Checkins"];
        }
      } else {
        userHoursDataMapping[log["User Name"]] = {
          [log["Hour"]]: log["Total Checkins"],
        };
      }
    });
    tempDailyLog.forEach((log) => {
      if (userHoursDataMapping.hasOwnProperty(log["User Name"])) {
        let tempList = [];
        Object.keys(userHoursDataMapping[log["User Name"]]).forEach((key) => {
          let totalCheckins = userHoursDataMapping[log["User Name"]][key];
          let tempObj = { hour: key, total_checkins: totalCheckins };
          tempList.push(tempObj);
        });
        log["hour_logs"] = tempList;
      }
    });
    return tempDailyLog;
  };

  const initData = () => {
    if (props.data !== null) {
      if (
        typeof props.data === "object" &&
        props.data.hasOwnProperty("daily_log") &&
        props.data.hasOwnProperty("hourly_logs")
      ) {
        const hourList = generateHourListFromHourlyLogs(
          props.data["hourly_logs"]
        );
        let tempData = combineHourlyAndDailyLog({ data: props.data });

        let columns = setupColumns({ data: tempData, hourList: hourList });
        setColumns(columns);
        setTableData(tempData);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, [props.widgetConfig.id, rootStore.userStore.dashboardMode]);
  return (
    <>
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "12px 20px",
            backgroundColor: "#F8F8F8",
          }}
        >
          <Text
            sx={{
              fontSize:
                rootStore.userStore.dashboardMode === "grid_three"
                  ? "16px"
                  : "18px",
            }}
          >
            {props.widgetConfig.title}
          </Text>
        </Box>
        <Divider sx={{ backgroundColor: "#eceef2" }} />

        <ChartBottom ref={componentRef}>
          <ChartBottomSection />
        </ChartBottom>
      </Container>
    </>
  );
};

export default observer(AutoPagingCheckinActivityTabularWidget);
