import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  makeStyles,
  Modal,
  Checkbox,
  Select,
  MenuItem,
} from "@material-ui/core";
import { OutlinedInput, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { getLocalizedText } from "../../../Functions";
import { Typography } from "@mui/material";
import { myTheme } from "../../../themeUtils";
import MuiAlert from "@mui/material/Alert";
import analytics from "../../../Analytics";
import { invoiceRecordPaymentsApi } from "../../../Api";
import PreviewPdfDialog from "../../files_page_components/PreviewPdfDialog";
import InputFileViewComponent from "../../add_product_sidepanel/components/InputFileViewComponent";
import AddFileInputComponent from "../../add_product_sidepanel/components/AddFileInputComponent";
import { recordPaymentModes } from "../../../Db";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: auto;
  background-color: white;
  height: 100%;
  width: 100%;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;

const ButtonWrapper = styled(Box)`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-top: 16px;
  flex-shrink: 0;
`;

const Form = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;

const ColumnContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  row-gap: 14px;
  justify-content: space-between;
`;

const InputWrapper = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 8px;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 16px;
  flex-direction: column;
  row-gap: 20px;
`;
const Message = styled(Typography)`
  font: normal normal normal 16px Open Sans;
  color: green;
  margin: 0px;
  text-align: center;
`;
const CustomizedDatePicker = styled(DatePicker)`
  & .MuiInputBase-input {
    font-size: 12px;
  }
`;

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const RecordPaymentDialog = (props) => {
  const classes = useStyles();
  const [details, setDetails] = useState({
    amount_received: null,
    tax_deducted: false,
    tax_withheld: null,
    bank_charges: null,
    payment_date: null,
    reference_number: null,
    payment_mode: null,
    notes: null,
  });
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [selectedFileUrl, setSelectedFileUrl] = useState("");
  const [viewPdfDialog, setViewPdfDialog] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (details["payment_date"] === null || details["payment_date"] === "") {
      setError(true);
      return;
    }

    //check if - amount received + bank charges + tax deducted <= total invoice amount
    let totalAmount =
      Number(details["amount_received"]) + Number(details["bank_charges"]);

    //adding tax_witheld amount only when tax_deducted boolean is true
    if (details["tax_deducted"]) {
      totalAmount = totalAmount + Number(details["tax_withheld"]);
    }

    if (props.details["balance"] !== null) {
      if (totalAmount > Number(props.details["balance"])) {
        setErrorMessage("Amount entered is more than due amount!");
        setIsSubmitFail(true);
        return;
      }
    } else {
      if (totalAmount > props.details["amount"]) {
        setErrorMessage("Amount entered is more than due amount!");
        setIsSubmitFail(true);
        return;
      }
    }

    setError(false);

    let tempPayload = {
      amount_received:
        details["amount_received"] !== null
          ? Number(details["amount_received"])
          : "",
      tax_deducted: details["tax_deducted"],
      payment_date:
        details["payment_date"] !== null
          ? details["payment_date"].toISOString()
          : null,
      payment_mode:
        details["payment_mode"] !== null ? details["payment_mode"] : "",
      reference_number:
        details["reference_number"] !== null ? details["reference_number"] : "",
      bank_charges:
        details["bank_charges"] !== null ? Number(details["bank_charges"]) : "",
      notes: details["notes"],
    };

    if (details["tax_deducted"]) {
      tempPayload["tax_withheld"] =
        details["tax_withheld"] !== null ? Number(details["tax_withheld"]) : "";
    }

    let formData = new FormData();
    Object.keys(tempPayload).forEach((key) => {
      if (tempPayload[key] !== "" && tempPayload[key] !== null) {
        formData.append(key, tempPayload[key]);
      }
    });

    selectedDocuments.forEach((doc, idx) => {
      let key = "file_" + (idx + 1);
      formData.append(key, doc);
    });

    let response = await invoiceRecordPaymentsApi({
      payload: formData,
      invoiceID: props.invoiceID,
    });

    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setIsSubmitSuccess(true);
      props.setRefresh(true);
    }
  };

  const handleDocumentUpload = (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      setSelectedDocuments((prevDocs) => [...prevDocs, ...files]);
    }
  };
  const handleDocumentPreview = (doc) => {
    const objectURL = URL.createObjectURL(doc);
    setSelectedFileUrl(objectURL);
    setViewPdfDialog(true);
  };
  const handleRemoveDoc = (index) => {
    setSelectedDocuments((prevDocs) => prevDocs.filter((_, i) => i !== index));
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!isSubmitSuccess ? (
          <ModelContainer sx={{ height: "640px", width: "920px" }}>
            <Container>
              <Header>Record Payment</Header>
              <Divider style={{ margin: "16px 0px" }} />
              <Form component={"form"} onSubmit={handleSubmit}>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <ColumnContainer>
                        <InputWrapper>
                          <Label sx={{ width: "30%" }}>
                            Amount Received (INR)*
                          </Label>
                          <FormControl
                            style={{ width: "70%" }}
                            classes={{
                              root: classes.quantityRoot,
                            }}
                          >
                            <OutlinedInput
                              type="number"
                              style={{ height: "35px", width: "100%" }}
                              inputProps={{
                                min: 0,
                                "aria-label": "weight",
                                style: {
                                  fontSize: "12px",
                                  textAlign: "right",
                                  appearance: "textfield",
                                },
                              }}
                              onChange={handleChange}
                              name="amount_received"
                              required
                              value={details["amount_received"]}
                              placeholder="Enter value"
                            />
                          </FormControl>
                        </InputWrapper>
                        <InputWrapper>
                          <Label sx={{ width: "30%" }}>Tax Deducted?*</Label>
                          <Box
                            sx={{
                              width: "70%",
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormControl style={{ width: "15%" }}>
                              <Checkbox
                                color="primary"
                                checked={details["tax_deducted"] || false}
                                onChange={(e) => {
                                  setDetails({
                                    ...details,
                                    tax_deducted: e.target.checked,
                                  });
                                }}
                              />
                            </FormControl>
                            <FormControl style={{ width: "85%" }}>
                              <OutlinedInput
                                type="number"
                                required
                                disabled={!details["tax_deducted"]}
                                style={{ height: "35px", width: "100%" }}
                                inputProps={{
                                  min: 0,
                                  "aria-label": "weight",
                                  style: {
                                    fontSize: "12px",
                                    textAlign: "right",
                                  },
                                }}
                                onChange={handleChange}
                                name="tax_withheld"
                                value={details["tax_withheld"] || ""}
                                placeholder="Enter value"
                              />
                            </FormControl>
                          </Box>
                        </InputWrapper>
                      </ColumnContainer>
                    </Grid>
                    <Grid item xs={6}>
                      <ColumnContainer>
                        <InputWrapper>
                          <Label sx={{ width: "30%" }}>
                            Bank Charges (if any)
                          </Label>
                          <FormControl
                            style={{ width: "70%" }}
                            classes={{
                              root: classes.quantityRoot,
                            }}
                          >
                            <OutlinedInput
                              type="number"
                              style={{ height: "35px", width: "100%" }}
                              inputProps={{
                                min: 0,
                                "aria-label": "weight",
                                style: {
                                  fontSize: "12px",
                                  textAlign: "right",
                                },
                              }}
                              onChange={handleChange}
                              name="bank_charges"
                              value={details["bank_charges"]}
                              placeholder="Enter value"
                            />
                          </FormControl>
                        </InputWrapper>
                      </ColumnContainer>
                    </Grid>
                  </Grid>
                  <Divider style={{ margin: "16px 0px" }} />
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <ColumnContainer>
                        <InputWrapper>
                          <Label sx={{ width: "30%" }}>Payment Date*</Label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <CustomizedDatePicker
                              required
                              value={details["payment_date"]}
                              onChange={(event) => {
                                setDetails({ ...details, payment_date: event });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={error}
                                  size="small"
                                  sx={{ width: "70%" }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </InputWrapper>
                        <InputWrapper>
                          <Label sx={{ width: "30%" }}>Reference#</Label>
                          <FormControl
                            style={{ width: "70%" }}
                            classes={{
                              root: classes.quantityRoot,
                            }}
                          >
                            <OutlinedInput
                              style={{ height: "35px", width: "100%" }}
                              inputProps={{
                                "aria-label": "weight",
                                style: {
                                  fontSize: "12px",
                                  textAlign: "left",
                                },
                              }}
                              onChange={handleChange}
                              name="reference_number"
                              value={details["reference_number"]}
                              placeholder="Enter value"
                            />
                          </FormControl>
                        </InputWrapper>
                        <InputWrapper>
                          <Label sx={{ width: "30%" }}>Payment Mode*</Label>
                          <FormControl
                            style={{ width: "70%" }}
                            classes={{
                              root: classes.quantityRoot,
                            }}
                          >
                            <Select
                              classes={{
                                icon: classes.icon,
                              }}
                              displayEmpty
                              onChange={handleChange}
                              name="payment_mode"
                              value={details["payment_mode"]}
                              disableUnderline
                              required
                              variant="outlined"
                              style={{
                                width: "100%",
                                height: "35px",
                                fontSize: "12px",
                                borderRadius: "4px",
                              }}
                            >
                              {recordPaymentModes.map((item, i) => {
                                return (
                                  <MenuItem
                                    value={item}
                                    key={i}
                                    style={{ fontSize: "12px" }}
                                  >
                                    {item}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </InputWrapper>
                      </ColumnContainer>
                    </Grid>
                    <Grid item xs={6}>
                      <ColumnContainer>
                        <InputWrapper sx={{ alignItems: "flex-start" }}>
                          <Label sx={{ width: "30%", paddingTop: "6px" }}>
                            Notes
                          </Label>
                          <FormControl
                            style={{ width: "70%" }}
                            classes={{
                              root: classes.quantityRoot,
                            }}
                          >
                            <OutlinedInput
                              style={{
                                width: "100%",
                                padding: "8px",
                                boxSizing: "border-box",
                              }}
                              inputProps={{
                                "aria-label": "notes",
                                style: {
                                  fontSize: "12px",
                                  textAlign: "left",
                                },
                              }}
                              onChange={handleChange}
                              name="notes"
                              multiline
                              rows={5}
                              value={details["notes"]}
                              placeholder="Enter value"
                            />
                          </FormControl>
                        </InputWrapper>
                      </ColumnContainer>
                    </Grid>
                  </Grid>
                  {/* <Divider style={{ margin: "16px 0px" }} />
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Box>
                        <Label sx={{ width: "30%" }}>Attachments</Label>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "16px",
                            flexWrap: "wrap",
                            marginTop: "2px",
                          }}
                        >
                          {selectedDocuments.map((doc, idx) => {
                            return (
                              <InputFileViewComponent
                                fileURL={URL.createObjectURL(doc)}
                                handleRemove={() => handleRemoveDoc(idx)}
                                handleView={() => handleDocumentPreview(doc)}
                                scope={"pdf"}
                              />
                            );
                          })}
                          {selectedDocuments.length < 2 &&
                            Array.from({
                              length: 2 - selectedDocuments.length,
                            }).map((_, index) => (
                              <AddFileInputComponent
                                key={index}
                                acceptedFiles={"application/pdf,text/plain"}
                                handleChange={(event) =>
                                  handleDocumentUpload(event)
                                }
                                scope={"pdf"}
                              />
                            ))}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid> */}
                </Box>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ButtonWrapper>
                      {isSubmitFail && (
                        <ErrorAlert
                          onClose={(e) => {
                            e.stopPropagation();
                            setIsSubmitFail(false);
                          }}
                          severity="error"
                          sx={{ width: "100%" }}
                        >
                          {errorMessage}
                        </ErrorAlert>
                      )}
                      <Button
                        onClick={() => {}}
                        type="submit"
                        variant="contained"
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          width: "fit-content",
                          textTransform: "none",
                          ...myTheme.Button.btnBlue,
                        }}
                      >
                        Record Payment
                      </Button>
                      <Button
                        onClick={() => {
                          props.setOpen(false);
                        }}
                        variant="contained"
                        color="default"
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          width: "fit-content",
                          textTransform: "none",
                        }}
                      >
                        {getLocalizedText("cancel")}
                      </Button>
                    </ButtonWrapper>
                  </Grid>
                </Grid>
              </Form>
            </Container>
          </ModelContainer>
        ) : (
          <ModelContainer
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MessageWrapper style={{ marginBottom: "0px" }}>
              <Message>Recorded payment successfully!</Message>

              <Button
                onClick={() => {
                  analytics.triggerEvent(
                    4625000259,
                    "close_button_tap",
                    "update_stage_form",
                    "close_button",
                    { order: props.scope }
                  );
                  handleClose();
                }}
                type="button"
                variant="contained"
                color="default"
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "100px",
                }}
              >
                {getLocalizedText("close")}
              </Button>
            </MessageWrapper>
          </ModelContainer>
        )}
      </Modal>
      {viewPdfDialog && (
        <PreviewPdfDialog
          open={viewPdfDialog}
          setOpen={setViewPdfDialog}
          file={selectedFileUrl}
        />
      )}
    </>
  );
};

export default RecordPaymentDialog;
