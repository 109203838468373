import { Button, Divider, InputLabel, Modal, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, MenuItem, Typography } from "@mui/material";
import { myTheme } from "../../themeUtils";
import { getLocalizedText } from "../../Functions";
import { fieldBlockList } from "../../Db";
import { v4 as uuid } from "uuid";

const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 30px;
`;
const ModelWrapper = styled(Box)`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 18px Open Sans;
`;
const SubHeader = styled(Typography)`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
  color: #5a5a5a;
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;
const AddBlockDialog = (props) => {
  const [blockStructure, setBlockStructure] = useState({});
  const [blockID, setBlockID] = useState({});
  const handleClose = () => {
    props.setOpen(false);
  };
  const handleAdd = () => {
    // debugger;
    let list = [...props.blocksList];
    let index = list.findIndex((block) => block["id"] === props.groupID);
    if (index !== -1) {
      let blockData = list[index];
      let innerBlocks = blockData["innerBlocks"];
      let tempStructure = { ...blockStructure };
      tempStructure["id"] = uuid();
      props.setSelectedBlockData(blockData["attributes"]);
      innerBlocks.push(blockStructure);
      blockData["innerBlocks"] = innerBlocks;
      list[index] = blockData;
      props.setBlocksList(list);
      props.setGroupID("");
      props.setOpen(false);
    }
  };
  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <ModelWrapper>
              <Header>Add Block</Header>
              <SubHeader>
                {getLocalizedText("select_from_below_dropdown")}
              </SubHeader>
              <Divider style={{ margin: "16px 0px" }} />
              <InputLabel
                id="demo-simple-select-label"
                style={{
                  font: "normal normal 600 10px Open Sans",
                  color: "#4d4e4f",
                }}
              >
                Select Block
              </InputLabel>

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Member"
                value={blockID}
                onChange={(e) => {
                  let index = fieldBlockList.findIndex(
                    (item) => item["structure"]["id"] === e.target.value
                  );
                  setBlockID(e.target.value);
                  setBlockStructure(fieldBlockList[index]["structure"]);
                }}
                style={{
                  minWidth: "200px",
                  marginBottom: "20px",
                  font: "normal normal 500 12px Open Sans",
                }}
              >
                {fieldBlockList.map((item, i) => {
                  return (
                    <MenuItem value={item.structure.id} key={i}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>

              <ButtonWrapper>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="default"
                  style={{
                    font: "normal normal 600 10px Open Sans",
                    marginRight: "10px",
                    textTransform: "none",
                  }}
                >
                  {getLocalizedText("cancel")}
                </Button>
                <Button
                  id="assign-btn"
                  onClick={handleAdd}
                  variant="contained"
                  style={{
                    font: "normal normal 600 10px Open Sans",
                    width: "100%",
                    textTransform: "none",
                    ...myTheme.Button.btnBlue,
                  }}
                >
                  Add
                </Button>
              </ButtonWrapper>
            </ModelWrapper>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default AddBlockDialog;
