import React from "react";
import { AppBar, Toolbar } from "@mui/material";
import LogoLeft from "../../../../assets/web_page/logo.png";
import LogoRight from "../../../../assets/web_page/logo2.png";
import { styled } from "@mui/material/styles";

const StyledAppBar = styled(AppBar)(({ theme, flag }) => ({
  backgroundColor: "#fff",
  position: "sticky",
  padding: "0 10%",
  border: flag ? "4px dashed #10ff00" : "none",
  [[theme.breakpoints.between("xs", "lg")]]: {
    padding: "10px 5%",
  },
  [theme.breakpoints.up("lg")]: {
    padding: "10px 10%",
  },
}));

const LogoLeftImg = styled("img")(({ theme }) => ({
  height: "100%",
  [[theme.breakpoints.between("xs", "md")]]: {
    maxWidth: "45%",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    maxWidth: "45%",
  },
  [theme.breakpoints.up("lg")]: {
    maxWidth: "40%",
  },
}));
const LogoRightImg = styled("img")(({ theme }) => ({
  height: "100%",
  [[theme.breakpoints.between("xs", "md")]]: {
    maxWidth: "45%",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    maxWidth: "45%",
  },
  [theme.breakpoints.up("lg")]: {
    maxWidth: "40%",
  },
}));

const MyAppBar = ({ isHighlighted, appbarData }) => {
  console.log("appbarData::::", appbarData);
  return (
    <React.Fragment>
      <StyledAppBar id="appbar" elevation={0} flag={isHighlighted}>
        <Toolbar disableGutters style={{ height: 64 }}>
          <LogoLeftImg
            src={
              appbarData.left_logo !== null
                ? URL.createObjectURL(appbarData.left_logo)
                : ""
            }
            alt="Left logo"
          />
          <div style={{ flexGrow: 1 }} />
          <LogoRightImg
            src={
              appbarData.right_logo !== null
                ? URL.createObjectURL(appbarData.right_logo)
                : ""
            }
            alt="Right logo"
          />
        </Toolbar>
      </StyledAppBar>
    </React.Fragment>
  );
};

export default MyAppBar;
