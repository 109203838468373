import * as React from "react";
import { Box, IconButton, styled, Typography } from "@mui/material";
import { ArrowBackIosNew, CalendarMonth } from "@mui/icons-material";
import { ArrowForwardIos } from "@material-ui/icons";
import moment from "moment";

const Container = styled(Box)`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

const DateText = styled(Typography)`
  margin: 0px;
  font: normal normal 600 16px Open Sans;
  color: #4d4e4f;
  white-space: nowrap;
`;
export default function DateNavigationComponent({
  handleNextButton,
  handlePrevButton,
  date,
  handleDatePicker,
}) {
  const isNextButtonDisabled = () => {
    if (date) {
      let dateString = date.toISOString();
      const selectedDate = moment(dateString);
      const today = moment().startOf("day");
      return selectedDate.isSameOrAfter(today);
    }
    return false;
  };
  return (
    <Container>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton color="primary" onClick={handlePrevButton}>
          <ArrowBackIosNew style={{ width: "20px", height: "20px" }} />
        </IconButton>
        {date !== null && <DateText>{date.format("YYYY-MM-DD")}</DateText>}
        <IconButton
          color="primary"
          onClick={handleDatePicker}
          style={{ padding: "0px" }}
        >
          <CalendarMonth style={{ width: "20px", height: "20px" }} />
        </IconButton>
        <IconButton
          color="primary"
          onClick={handleNextButton}
          disabled={isNextButtonDisabled()}
        >
          <ArrowForwardIos style={{ width: "20px", height: "20px" }} />
        </IconButton>
      </Box>
    </Container>
  );
}
