import React, { useState } from "react";
import flag from "../../assets/images/flags/india.png";
import { observer } from "mobx-react-lite";
import { v4 as uuidv4 } from "uuid";
import { useEffect } from "react";
import { sendOtpEmailApi, sendOtpPhoneApi } from "../../Api";
import { Button } from "@material-ui/core";
import { myTheme } from "../../themeUtils";
import {
  getLocalizedText,
} from "../../Functions";
import analytics from "../../Analytics";
import { Box, styled, Typography, Select, MenuItem } from "@mui/material";
import { countryCodeList } from "../../Db";
const Header = styled(Typography)`
  font: normal normal 600 44px Poppins;
  color: ${myTheme.Button.colorBlue};
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 16px Poppins;
  color: #6f6f6f;
  margin-top: 2px;
  margin-bottom: 10px;
`;
const Form = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Label = styled(Typography)`
  font: normal normal 600 12px Poppins;
  margin-bottom: 4px;
`;
const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
  align-items: center;
  border: 2px solid rgb(218, 244, 245);
`;
const InputLeft = styled(Box)`
  display: flex;
  align-items: center;
  border-right: 2px solid rgb(218, 244, 245);
  padding: 4px;
`;
const Image = styled("img")`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 4px;
`;
const Code = styled(Typography)`
  font: normal normal 500 15px Poppins;
  margin-right: 10px;
`;
const Input = styled("input")`
  width: 100%;
  height: 30px;
  padding: 8px 12px;
  outline: none;
  border: none;
  font: normal normal 500 16px/25px Poppins;
  letter-spacing: 2px;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal normal 14px/25px Poppins;
    color: #b5b5b5;
  }
`;
const ButtonWrapper = styled(Box)`
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
`;

const DisclaimerWrapper = styled(Box)`
  width: 80%;
  text-align: center;
  margin: auto;
  margin-top: 10px;
`;
const Disclaimer = styled("span")`
  font: normal normal normal 12px/18px Poppins;
  color: #b5b5b5;
`;
const Highlighter = styled("span")`
  color: #185dd2;
`;
const Error = styled(Box)`
  color: red;
`;
const LoginRight = (props) => {
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const onChange = (e) => {
    props.setValue({ ...props.value, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    if (localStorage.getItem("device_id") === null) {
      let device_id = uuidv4();
      localStorage.setItem("device_id", device_id);
    }
  }, []);

  const handleSendOtp = async ({ payload }) => {
    if (props.inputFieldType === "phone") {
      payload["phone"] = `${props.value.country_code}-${props.value.phone}`;
      let response = await sendOtpPhoneApi(payload);
      return response;
    } else {
      payload["email"] = props.value.email;
      let response = await sendOtpEmailApi(payload);
      return response;
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();
    let payload = { type: "login", account: props.value.account };

    let sendOtpResponse = await handleSendOtp({ payload: payload });
    if (sendOtpResponse.hasError()) {
      setErrorMessage(sendOtpResponse.errorMessage);
      setIsError(true);
    } else {
      props.setStatus(true);
    }
  };
  return (
    <>
      <Header>Log In</Header>
      <SubHeader>
        Need a LeadPixie account?{" "}
        <span style={{ color: "#FFAE42" }}>Contact support@leadpixie.in</span>
      </SubHeader>
      <Form onSubmit={handleClick} component={"form"}>
        <Label>Account</Label>
        <InputContainer>
          <Input
            type="text"
            name="account"
            id="account"
            onChange={onChange}
            value={props.value.account}
            autoFocus={true}
            placeholder="Enter your account name"
            required
          />
        </InputContainer>
        {props.inputFieldType === "phone" ? (
          <>
            <Label>{props.data.label}</Label>
            <InputContainer>
              <InputLeft>
                <Select
                  value={props.value.country_code}
                  name="country_code"
                  id="country_code"
                  disableUnderline
                  variant="standard"
                  onChange={onChange}
                  style={{
                    width: "100%",
                    height: "30px",
                    fontSize: "12px",
                    borderRadius: "0px",
                    border: "none",
                    boxShadow: "none",
                  }}
                >
                  {countryCodeList.map((item, i) => (
                    <MenuItem key={i} value={item}>
                      <Code>{item}</Code>
                    </MenuItem>
                  ))}
                </Select>
              </InputLeft>
              <Input
                type="text"
                name="phone"
                id="phone"
                value={props.value.phone}
                onChange={onChange}
                placeholder="Enter your phone number"
                required
                minLength={props.value.country_code === "+91" ? "10" : "0"}
                pattern={props.value.country_code === "+91" ? "\\d{10}" : null}
                maxLength={props.value.country_code === "+91" ? "10" : null}
                onKeyUp={(e) => (e.key === "Enter" ? handleClick(e) : null)}
              />
            </InputContainer>
          </>
        ) : (
          <>
            <Label>Email</Label>
            <InputContainer>
              <Input
                type="email"
                name="email"
                id="email"
                value={props.value.email}
                onChange={onChange}
                placeholder="Enter your email"
                required
                onKeyUp={(e) => (e.key === "Enter" ? handleClick(e) : null)}
              />
            </InputContainer>
          </>
        )}

        {isError && <Error>{errorMessage}</Error>}
        <ButtonWrapper>
          <Button
            onClick={() => {
              analytics.triggerEvent(
                4625000015,
                "continue_button_tap",
                "login_page",
                "login_continue_button",
                {}
              );
            }}
            variant="contained"
            style={{ width: "100%", height: "100%", ...myTheme.Button.btnBlue }}
            type="submit"
          >
            {getLocalizedText("continue")}
          </Button>
          <Button
            variant="text"
            color="primary"
            style={{
              color: myTheme.Button.colorBlue,
              textTransform: "none",
              textDecoration: "underline",
            }}
            onClick={() => {
              if (props.inputFieldType === "phone") {
                analytics.triggerEvent(
                  4625000016,
                  "switch_to_email_button_tap",
                  "login_page",
                  "switch_to_email_button",
                  {}
                );
                props.setInputFieldType("email");
              } else {
                analytics.triggerEvent(
                  4625000017,
                  "switch_to_phone_button_tap",
                  "login_page",
                  "switch_to_phone_button",
                  {}
                );
                props.setInputFieldType("phone");
              }
            }}
          >
            {props.inputFieldType === "phone"
              ? "Switch to Email"
              : "Switch to phone"}
          </Button>
        </ButtonWrapper>

        <DisclaimerWrapper>
          {props.data.disclaimer ? (
            <Disclaimer>
              {props.data.disclaimer}{" "}
              <a
                href="https://d2dsj7nm5j2dtv.cloudfront.net/lp-tos.html"
                target="_blank"
                style={{ textDecoration: "none" }}
                rel="noreferrer"
              >
                <Highlighter
                  onClick={() => {
                    analytics.triggerEvent(
                      4625000019,
                      "terms_of_use_tap",
                      "login_page",
                      "terms_of_use_link",
                      {}
                    );
                  }}
                >
                  {props.data.t1}{" "}
                </Highlighter>
              </a>
              and{" "}
              <a
                rel="noreferrer"
                href="https://d2dsj7nm5j2dtv.cloudfront.net/lp-pp.html"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <Highlighter
                  onClick={() => {
                    analytics.triggerEvent(
                      4625000018,
                      "privacy_policy_tap",
                      "login_page",
                      "privacy_policy_link",
                      {}
                    );
                  }}
                >
                  {props.data.t2}{" "}
                </Highlighter>
              </a>
            </Disclaimer>
          ) : (
            ""
          )}
        </DisclaimerWrapper>
      </Form>
    </>
  );
};

export default observer(LoginRight);
