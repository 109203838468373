import * as React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import ImportLeadsTable from "../../components/import_details_page_components/ImportLeadsTable";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { ChevronRight, ExpandMore, MoreHoriz } from "@material-ui/icons";
import { getImportLogUrlApi, refreshImportRowApi } from "../../Api";
import { useState } from "react";
import { ImportHistoryColumns } from "../../Db";
import { dateTimeHandler } from "../../Functions";
import Footer from "../../components/landing_page_components/footer/Footer";
import { CopyAll } from "@mui/icons-material";
const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 253px);`};
  margin: auto;
  margin-top: 70px;
  width: 95%;
`;

const SectionWrapper = styled(Box)`
  background: #f9f9fc 0% 0% no-repeat padding-box;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  width: 100%;
`;

const DetailsHeader = styled(Typography)`
  font: normal normal 600 16px Open Sans;
  margin-bottom: 20px;
  color: #979797;
`;
const InfoWrapper = styled(Box)`
  width: 150px;
  height: fit-content;
`;

const BreadcrumbText = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
`;

const Label = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  margin-bottom: 8px;
  color: #979799;
`;

const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;
const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 0px;
`;

const Column = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const Row = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
  background-color: white;
  /* padding: 20px; */
  width: 100%;
`;

const IconTextWrapper = styled(Box)`
  display: flex;
  width: 100%;
  column-gap: 20px;
  align-items: center;
`;

const ImportDetailsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = React.useState({});
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const getImportDetails = async () => {
    try {
      let response = await refreshImportRowApi({ id: id });
      setData(response.data);
    } catch (error) {
      console.log(error);
      setData({});
    }
  };
  const setup = async () => {
    await getImportDetails();
    setLoading(false);
  };
  React.useEffect(() => {
    setup();
  }, []);

  const handleDownloadLogFile = async (scope) => {
    let response = await getImportLogUrlApi({ id: id, scope: scope });
    if (response.url !== "") {
      window.open(response.url, "_blank");
    }
  };

  function truncate(str, n) {
    return (
      <Tooltip title={str}>
        <IconTextWrapper style={{ columnGap: "0px" }}>
          <Detail>{str.slice(0, n - 1) + "..."}</Detail>
          <CopyAll
            color="primary"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigator.clipboard.writeText(str);
              setOpenSnackBar(true);
            }}
          />
        </IconTextWrapper>
      </Tooltip>
    );
  }

  return (
    <>
      <ParentContainer>
        <Container>
          <TopWrapper>
            <BreadcrumbText
              style={{ color: "#185DD2", cursor: "pointer" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              Imports
            </BreadcrumbText>
            <ChevronRight />
            <BreadcrumbText>{id}</BreadcrumbText>
          </TopWrapper>
          {!loading ? (
            <SectionWrapper>
              <Column>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <DetailsHeader style={{ marginBottom: "0px" }}>
                      Import Details
                    </DetailsHeader>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container rowSpacing={3} columnSpacing={3}>
                      <Grid item xs={12 / 7}>
                        <Label>Import ID</Label>

                        {truncate(id, 12)}
                      </Grid>
                      {ImportHistoryColumns.map((key) => {
                        if (
                          key["field"] === "original_file" ||
                          key["field"] === "error_log" ||
                          key["field"] === "full_log"
                        ) {
                          return (
                            <Grid item xs={12 / 7}>
                              <Label>{key["headerName"]}</Label>

                              <Detail style={{ cursor: "pointer" }}>
                                <Tooltip title="Download">
                                  <MoreHoriz
                                    color="primary"
                                    onClick={() => {
                                      handleDownloadLogFile(key["field"]);
                                    }}
                                  />
                                </Tooltip>
                              </Detail>
                            </Grid>
                          );
                        } else if (
                          key["field"] === "start_time" ||
                          key["field"] === "end_time"
                        ) {
                          let temp_time;
                          if (data[key["field"]] !== null) {
                            temp_time = dateTimeHandler(data[key["field"]]);
                          }
                          return (
                            <Grid item xs={12 / 7}>
                              <Label>{key["headerName"]}</Label>

                              <Tooltip title={temp_time}>
                                <Detail>
                                  {data[key["field"]] !== null
                                    ? temp_time
                                    : "-"}
                                </Detail>
                              </Tooltip>
                            </Grid>
                          );
                        } else {
                          return (
                            <Grid item xs={12 / 7}>
                              <Label>{key["headerName"]}</Label>

                              <Tooltip title={data[key["field"]]}>
                                <Detail>{data[key["field"]]}</Detail>
                              </Tooltip>
                            </Grid>
                          );
                        }
                      })}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Column>
            </SectionWrapper>
          ) : (
            <SectionWrapper
              style={{
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </SectionWrapper>
          )}
          {/* <Divider style={{ margin: "20px 0px" }} /> */}
          <Column>
            <DetailsHeader>Leads</DetailsHeader>
            <ImportLeadsTable id={id} />
          </Column>
        </Container>
      </ParentContainer>
      <Footer />
      <Snackbar
        open={openSnackBar}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => {
          setOpenSnackBar(false);
        }}
        message="Copied to clipboard!"
      />
    </>
  );
};

export default observer(ImportDetailsPage);
