import { Drawer } from "@material-ui/core";
import { AddBoxOutlined, Delete, Edit } from "@material-ui/icons";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { deleteAdminCustomColumnApi, getAdminColumnsApi } from "../../Api";
import { AdminViewColumns } from "../../Db";
import { pageSize } from "../../Config";
import PaginationComponent from "../pagination/PaginationComponent";
import analytics from "../../Analytics";
import AddColumnSidepanel from "../admin_setting_modals/AddColumnSidepanel";
import ViewColumnSidepanel from "../view_column_sidepanel/ViewColumnSidepanel";
import MessageWithLoadingStateCustomDialog from "../alert_dialogue/MessageWithLoadingStateCustomDialog";
import CustomConfirmationDialog from "../alert_dialogue/CustomConfirmationDialog";
import { Box, IconButton, styled, Typography } from "@mui/material";
const Container = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 234px);`};

  position: relative;
  /* margin-top: 70px; */
`;
const Wrapper = styled(Box)`
  display: flex;
`;

const DetailsWrapper = styled(Box)`
  height: fit-content;
  width: 100%;
  background: white;
  border-radius: 10px;
  /* padding: 12px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
`;
const AddBtnWrapper = styled(Box)`
  position: absolute;
  right: 0px;
  top: 9px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
  @media (max-width: 700px) {
    position: unset;
    margin-left: 12px;
  }
`;
const Text = styled(Typography)`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;
const ColumnsTab = (props) => {
  const [row, setRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [editableData, setEditableData] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(pageSize);
  const [loading, setLoading] = useState(true);
  const [openInfoSidepanel, setOpenInfoSidepanel] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [openSubmitResponseDialog, setOpenSubmitResponseDialog] =
    useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [fetchingApi, setFetchingApi] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [selectedColumnID, setSelectedColumnID] = useState("");

  const handleCustomColumnDelete = async () => {
    setShowConfirmationDialog(false);
    setFetchingApi(true);
    setOpenSubmitResponseDialog(true);
    let payload = { column_id: selectedColumnID };
    let response = await deleteAdminCustomColumnApi({
      payload: payload,
    });
    if (!response.hasError()) {
      setIsSubmitFail(false);
      await refetchAfterDelete();
      setSuccessMessage("Column deleted successfully!");
      setFetchingApi(false);
    } else {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
      setFetchingApi(false);
    }
    setSelectedColumnID("");
  };

  const getColumns = async ({ page_no, page_size }) => {
    setRow([]);
    setLoading(true);
    try {
      let response = await getAdminColumnsApi({
        pid: props.id,
        page: page_no,
        page_size: page_size,
      });

      setRow(response.data.items);
      setCount(response.data.item_count);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const init = async () => {
    await getColumns({ page_no: 0, page_size: pageSize });
  };
  const refetch = async () => {
    await getColumns({ page_no: page, page_size: size });
    setRefresh(false);
  };

  const refetchAfterDelete = async () => {
    setPage(0);
    await getColumns({ page_no: 0, page_size: size });
  };
  useEffect(() => {
    if (refresh) {
      refetch();
    }
  }, [refresh]);

  useEffect(() => {
    init();
  }, []);
  const actionColumn = [
    {
      field: "action",
      headerName: <Text>Actions</Text>,
      sortable: false,
      width: 70,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <IconButton
              disabled={params.row.child_of !== null}
              color="primary"
              style={{ padding: "0px" }}
              onClick={(e) => {
                e.stopPropagation();
                analytics.triggerEvent(
                  4625000230,
                  "edit_button_tap",
                  "project_columns_page",
                  "edit_button",
                  {}
                );
                setEditableData(params.row);
                setIsEdit(true);
                setOpen(true);
              }}
            >
              <Edit
                style={{  width: "18px", height: "18px" }}
              />
            </IconButton>

            <IconButton
              disabled={params.row.system === true || params.row.child_of !== null}
              color="error"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedColumnID(params.row.id);
                setShowConfirmationDialog(true);
              }}
            >
              <Delete style={{ width: "18px", height: "18px" }} />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const handlePageSize = async (e) => {
    setSize(e.target.value);
    setPage(0);
    await getColumns({ page_no: 0, page_size: e.target.value });
  };
  const handlePagination = async (e, page) => {
    setPage(page - 1);
    await getColumns({ page_no: page - 1, page_size: size });
  };
  return (
    <>
      <Container>
        <AddBtnWrapper>
          <AddBoxOutlined
            onClick={() => {
              analytics.triggerEvent(
                4625000226,
                "add_button_tap",
                "project_columns_page",
                "add_button",
                {}
              );
              setOpen(true);
            }}
            style={{
              width: "40px",
              height: "40px",
            }}
          />
        </AddBtnWrapper>
        <Wrapper>
          <DetailsWrapper>
            <TableWrapper>
              <DataGrid
                autoHeight={true}
                rows={row}
                columns={AdminViewColumns.concat(actionColumn)}
                checkboxSelection={false}
                style={{ cursor: "pointer" }}
                hideFooter={true}
                disableColumnMenu={true}
                loading={loading}
                onRowClick={(e) => {
                  setEditableData(e.row);
                  setOpenInfoSidepanel(true);
                }}
              />
            </TableWrapper>
            <PaginationComponent
              page_no={page}
              row={row}
              page_size={size}
              size={size}
              count={count}
              handlePageSize={handlePageSize}
              handlePage={handlePagination}
            />
          </DetailsWrapper>
        </Wrapper>
      </Container>

      {open && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={open}
          onClose={() => {
            setOpen(false);
            setIsEdit(false);
          }}
        >
          <AddColumnSidepanel
            id={props.id}
            setOpen={setOpen}
            open={open}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            editableData={editableData}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </Drawer>
      )}
      {openInfoSidepanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openInfoSidepanel}
          onClose={() => {
            setOpenInfoSidepanel(false);
          }}
        >
          <ViewColumnSidepanel
            data={editableData}
            handleEdit={() => {
              setIsEdit(true);
              setOpen(true);
              setOpenInfoSidepanel(false);
            }}
            handleDelete={() => {
              setSelectedColumnID(editableData.id);
              setShowConfirmationDialog(true);
              setOpenInfoSidepanel(false);
            }}
          />
        </Drawer>
      )}

      {openSubmitResponseDialog && (
        <MessageWithLoadingStateCustomDialog
          open={openSubmitResponseDialog}
          setOpen={setOpenSubmitResponseDialog}
          successMessage={successMessage}
          errorMessage={errorMessage}
          loading={fetchingApi}
          isError={isSubmitFail}
        />
      )}
      {showConfirmationDialog && (
        <CustomConfirmationDialog
          message={"Are you sure you want to delete this column?"}
          open={showConfirmationDialog}
          setOpen={setShowConfirmationDialog}
          handleConfirm={() => {
            handleCustomColumnDelete();
          }}
        />
      )}
    </>
  );
};

export default ColumnsTab;
