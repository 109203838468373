import React, { useEffect, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import {
  Box,
  CircularProgress,
  Tab,
  Tabs,
  withStyles,
} from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import rootStore from "../../stores/RootStore";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import BasicInfoTab from "../../components/contact_page_tabs/BasicInfoTab";
import LeadsTab from "../../components/contact_page_tabs/LeadsTab";
import FilesTab from "../../components/contact_page_tabs/FilesTab";
import { getContactDetailsApi } from "../../Api";
import CustomInfoTab from "../../components/contact_page_tabs/CustomInfoTab";
import { isAccountIdMatched } from "../../Functions";
const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 84px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;
const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const MessageText = styled(Typography)`
  margin: 0px;
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;

const CustomTab = withStyles({
  root: {
    backgroundColor: "white",
    color: "#4D4E4F",
    font: "normal normal 600 14px Open Sans",
    textTransform: "capitalize",
  },
  selected: {
    backgroundColor: "#E7EEFA",
    color: "#4079DA",
    font: "normal normal 600 14px Open Sans",
    textTransform: "capitalize",
  },
})(Tab);

const ContactsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [tabs, setTabs] = useState([]);
  const [tabsToGroups, setTabsToGroups] = useState({});
  const [groupsToColumns, setGroupsToColumns] = useState({});
  const columnsList = [...rootStore.contactStore.columnsList];
  const columnGroups = rootStore.contactStore.columnGroups;
  const detailTabs = rootStore.contactStore.detailTabs;
  const [loading, setLoading] = useState(true);
  const [loadingContactDetails, setLoadingContactDetails] = useState(true);
  const [contactData, setContactData] = useState({});
  const [refreshContactDetails, setRefreshContactDetails] = useState(false);

  //*************tabs and lead details grouping relted functions*********** */

  const setupTabToGroups = () => {
    const result = {};

    // Create a dictionary of tabs with an empty list for each tab
    detailTabs.forEach((tab) => {
      result[tab["id"]] = [];
    });

    // Loop through the data and add each group name and group_order value to the appropriate tab's list
    columnGroups.forEach((item) => {
      if (result[item["contact_detail_tab_id"]]) {
        result[item["contact_detail_tab_id"]].push({
          name: item.name,
          order: item.sequence,
        });
      }
    });

    // Sort the groups within each tab's list by their group_order property
    Object.keys(result).forEach((tab) => {
      result[tab].sort((a, b) => a.sequence - b.sequence);
      result[tab] = result[tab].map((item) => item.name);
    });

    return result;
  };

  const setupGroupsToColumns = () => {
    // Get a list of all unique group names from the tabs
    const uniqueGroups = Array.from(
      new Set(columnGroups.map((item) => item.name))
    );

    const result = { "Other Information": [] };

    uniqueGroups.forEach((group) => {
      if (!result[group]) {
        result[group] = [];
      }
    });

    // Loop through the data and add each ID to the appropriate group's list
    columnsList.forEach((item) => {
      let group = item.contact_field_groups;
      if (item["is_visible"]) {
        if (group.length === 0) {
          result["Other Information"].push(item["id"]);
        } else {
          group.forEach((groupName) => {
            if (!uniqueGroups.includes(groupName)) {
              result["Other Information"].push(item["id"]);
            } else {
              result[groupName].push(item["id"]);
            }
          });
        }
      }
    });

    return result;
  };

  const setupTabs = () => {
    let tempTabsList = [...detailTabs];
    const itemToMoveIndex = tempTabsList.findIndex(
      (item) => item.name.toLowerCase() === "basic details"
    );
    const restOfData = tempTabsList.filter(
      (item) => item.name.toLowerCase() !== "basic details"
    );
    const sortedData = restOfData.sort((a, b) => a.name.localeCompare(b.name));
    let result = [];
    if (itemToMoveIndex !== -1) {
      result.push(tempTabsList[itemToMoveIndex]);
    }
    result.push({ id: "leads", name: "Leads" });
    result.push({ id: "files", name: "Files" });

    return result.concat(sortedData);
  };

  const setup = () => {
    let tempTabsToGroups = setupTabToGroups();
    setTabsToGroups(tempTabsToGroups);
    let tempGroupsToColumns = setupGroupsToColumns();
    setGroupsToColumns(tempGroupsToColumns);

    let tabsList = setupTabs();
    rootStore.contactStore.updateDetailsCurrentTabID(tabsList[0]["id"]);
    setTabs(tabsList);
  };

  //*************************END************************** */

  const getContactDetails = async () => {
    setLoadingContactDetails(true);
    let response = await getContactDetailsApi({ contactID: id });
    setContactData(response);
    setLoadingContactDetails(false);
  };

  const init = async () => {
    await getContactDetails();
    setup();
    setLoading(false);
  };
  const refetchContactDetails = async () => {
    let response = await getContactDetailsApi({ contactID: id });
    setContactData(response);
    setRefreshContactDetails(false);
  };
  useEffect(() => {
    if (refreshContactDetails) {
      refetchContactDetails();
    }
  }, [refreshContactDetails]);

  useEffect(() => {
    init();
    rootStore.authStore.updateLeadDetailStatus();
  }, []);

  const isContactFromCurrentAccount = () => {
    if (contactData.hasOwnProperty("account_id")) {
      return isAccountIdMatched({ accountID: contactData["account_id"] });
    }
    return false;
  };

  return !loading ? (
    isContactFromCurrentAccount() ? (
      <>
        <ParentContainer>
          <Container>
            <Row>
              <TopWrapper>
                <Header
                  style={{ color: "#185DD2", cursor: "pointer" }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Contacts
                </Header>
                <ChevronRight style={{ width: "16px", height: "16px" }} />
                <Header>{id}</Header>
              </TopWrapper>
            </Row>
            <Tabs
              value={rootStore.contactStore.detailsCurrentTabID}
              textColor="primary"
              indicatorColor="primary"
              onChange={(event, newValue) => {
                rootStore.contactStore.updateDetailsCurrentTabID(newValue);
              }}
            >
              {tabs.map((tab, index) => {
                return (
                  <CustomTab
                    value={tab["id"]}
                    label={tab["name"]}
                    key={index}
                  />
                );
              })}
            </Tabs>

            {(() => {
              if (rootStore.contactStore.detailsCurrentTabID === "leads") {
                return <LeadsTab id={id} />;
              } else if (
                rootStore.contactStore.detailsCurrentTabID === "files"
              ) {
                return <FilesTab id={id} />;
              } else {
                let index = tabs.findIndex(
                  (tab) =>
                    tab["id"] === rootStore.contactStore.detailsCurrentTabID
                );
                if (index !== -1) {
                  let tabName = tabs[index]["name"];
                  console.log("tabname::::::", tabName);
                  if (tabName.toLowerCase() === "basic details") {
                    return (
                      <BasicInfoTab
                        id={id}
                        loading={loadingContactDetails}
                        contactData={contactData}
                        setRefresh={setRefreshContactDetails}
                        groups={
                          tabsToGroups[
                            rootStore.contactStore.detailsCurrentTabID
                          ]
                        }
                        groupsToColumns={groupsToColumns}
                      />
                    );
                  } else {
                    return (
                      <CustomInfoTab
                        id={id}
                        loading={loadingContactDetails}
                        contactData={contactData}
                        setRefresh={setRefreshContactDetails}
                        groups={
                          tabsToGroups[
                            rootStore.contactStore.detailsCurrentTabID
                          ]
                        }
                        groupsToColumns={groupsToColumns}
                      />
                    );
                  }
                }
              }
            })()}
          </Container>
        </ParentContainer>
        <Footer />
      </>
    ) : (
      <Box
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <MessageText>Some error occurred! Error code:1018</MessageText>
      </Box>
    )
  ) : (
    <Box
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default observer(ContactsPage);
