import React, { useState, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import leadPixieLogo from "../../assets/leadpixie_circular_logo.png";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";

const ParentContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
`;
const Flipper = styled(Box)`
  position: relative;
  width: 60%;
  height: 60%;
  perspective: 1000px;
`;

const FlipInner = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; 
  height: 100%; 
  position: relative;
  transform-style: preserve-3d;
  transition: transform 1s ease-in-out;
  transform: ${({ flipped }) => (flipped ? "rotateY(180deg)" : "rotateY(0deg)")};
`;

const Image = styled("img")`
  width: 100%; 
  height: 100%;
  object-fit: contain; 
  backface-visibility: hidden; 
  position: absolute;
  top: 0;
  left: 0;
`;
function StyledSpinner({ spinnerSize }) {
  return (
    <>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#4079DA" stopOpacity="1" />
            <stop offset="100%" stopColor="#4079DA" stopOpacity="0.25" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress
        size={spinnerSize}
        sx={{ "svg circle": { stroke: "url(#gradient)" } }}
      />
    </>
  );
}

function LoaderComponent({ flipped, cobrandLogo, spinnerSize }) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <StyledSpinner spinnerSize={spinnerSize} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Flipper>
          <FlipInner flipped={flipped}>
            <Image
              src={leadPixieLogo}
              style={{
                transform: "rotateY(0deg)",
              }}
            />
            {cobrandLogo !== "" && (
              <Image
                src={cobrandLogo}
                style={{
                  transform: "rotateY(180deg)",
                }}
              />
            )}
          </FlipInner>
        </Flipper>
      </Box>
    </Box>
  );
}

const LoadingAnimationComponent = ({ size = "medium" }) => {
  const [flipped, setFlipped] = useState(false);
  const [brandingLogo, setBrandingLogo] = useState("");
  const sizeValues = {
    small: { width: "40px", height: "40px", spinnerSize: 75 },
    medium: {
      width: "60px",
      height: "60px",
      spinnerSize: 115,
    },
    large: { width: "85px", height: "85px", spinnerSize: 145 },
  };

  useEffect(() => {
    if (brandingLogo !== "") {
      const interval = setInterval(() => {
        setFlipped((prevFlipped) => !prevFlipped);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [brandingLogo]);

  useEffect(() => {
    if (rootStore.userStore.UserData) {
      const circularLogo =
        rootStore.userStore.UserData.account?.cobranding_configuration
          ?.circular_logo;
      if (circularLogo) {
        setBrandingLogo(circularLogo);
      }
    }
  }, [rootStore.userStore.UserData]);

  return (
    <ParentContainer
      sx={{
        width: sizeValues[size].width,
        height: sizeValues[size].height,
      }}
    >
      <LoaderComponent
        flipped={flipped}
        cobrandLogo={brandingLogo}
        spinnerSize={sizeValues[size].spinnerSize}
      />
    </ParentContainer>
  );
};

export default observer(LoadingAnimationComponent);
