import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { CircularProgress, Grid, IconButton, Tooltip } from "@material-ui/core";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import {
  getAdminUsersApi,
  getFolderPermissionListApi,
  removeFolderPermissionApi,
} from "../../Api";
import { pageSize } from "../../Config";
import TableWidgetPagination from "../chart_components/dashboard_chart_v2/tabular_widget/TableWidgetPagination";
import { PersonAdd } from "@material-ui/icons";
import AddPermissionModal from "./AddPermissioinModal";
import rootStore from "../../stores/RootStore";
import NoDataCardWidget from "./NoDataCardWidget";
import PermissionCardWidget from "./PermissionCardWidget";
import MuiAlert from "@mui/material/Alert";
const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;
const TableWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
`;

const Text = styled(Typography)`
  font: normal normal 500 14px Open Sans;
`;
const Wrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;

const ManageFolderSidepanel = (props) => {
  const [loading, setLoading] = useState(true);
  const [loadingPermissionList, setLoadingPermissionList] = useState(false);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(pageSize);
  const [count, setCount] = useState(6);
  const [openAddPermissionModal, setOpenAddPermissionModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [allTeamList, setAllTeamList] = useState([]);
  const projectList = [...rootStore.authStore.projectList];
  const [memberList, setMemberList] = useState([]);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const getPermissionList = async ({ page, page_size }) => {
    setLoadingPermissionList(true);
    let response = await getFolderPermissionListApi({
      folderID: props.folderID,
      page: page,
      page_size: page_size,
    });
    if (Object.keys(response).length > 0) {
      setList(response.items);
      setCount(response.item_count);
    } else {
      setList([]);
      setCount(0);
    }
    setLoadingPermissionList(false);
  };

  const getMembersList = async () => {
    try {
      let response = await getAdminUsersApi();
      let activeUsers = response.data.filter((user) => user.active);
      return activeUsers;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const init = async () => {
    initAllTeamList();
    let member_list = await getMembersList();
    setMemberList(member_list);
    await getPermissionList({ page: 0, page_size: pageSize });
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  const refetchPermissionList = async () => {
    await getPermissionList({ page: 0, page_size: pageSize });
  };
  useEffect(() => {
    if (refresh) {
      refetchPermissionList();
      setRefresh(false);
    }
  }, [refresh]);

  const handlePageSize = async (e) => {
    setPage(0);
    setSize(e.target.value);
    await getPermissionList({ page: 0, page_size: e.target.value });
  };
  const handlePagination = async (e, page) => {
    setPage(page - 1);

    await getPermissionList({ page: page - 1, page_size: size });
  };

  const initAllTeamList = () => {
    let tempList = [];
    projectList.forEach((project) => {
      let teams = project["teams"];
      teams.forEach((team) => {
        if (team["id"] !== "All") {
          const exists = tempList.some((item) => item["id"] === team["id"]);

          if (!exists) {
            tempList.push(team);
          }
        }
      });
    });
    setAllTeamList(tempList);
  };

  const getCardInfo = ({ data }) => {
    if (data["scope"] === "account") {
      let accountName = rootStore.userStore.UserData["account"]["name"];
      return {
        title: accountName,
        desc: `View Access to all users in Account '${accountName}'`,
        scope: "Account",
      };
    } else if (data["scope"] === "project") {
      let index = projectList.findIndex(
        (project) => project["id"] === data["target"]
      );
      if (index !== -1) {
        let projectData = projectList[index];
        return {
          title: projectData["name"],
          desc: `View Access to all members in Project '${projectData["name"]}'`,
          scope: "Project",
        };
      }
    } else if (data["scope"] === "team") {
      let index = allTeamList.findIndex(
        (team) => team["id"] === data["target"]
      );
      if (index !== -1) {
        let teamData = allTeamList[index];
        return {
          title: teamData["name"],
          desc: `View Access to all members in Team '${teamData["name"]}'`,
          scope: "Team",
        };
      }
    } else if (data["scope"] === "user") {
      let index = memberList.findIndex(
        (member) => member["id"] === data["target"]
      );
      if (index !== -1) {
        let userData = memberList[index];
        return {
          title: userData["name"],
          desc: `View Access to user '${userData["name"]}'`,
          scope: "User",
        };
      }
    }
    return null;
  };

  const isSameUserOrOwner = ({ data }) => {
    if (data["scope"] === "user") {
      let index = memberList.findIndex(
        (member) => member["id"] === data["target"]
      );
      if (index !== -1) {
        let userData = memberList[index];
        let currentUserData = rootStore.userStore.UserData;
        return (
          userData["role"].toLowerCase() === "owner" ||
          currentUserData["id"] === userData["id"]
        );
      }
    }
    return false;
  };

  const handlePermissionRemove = async (permissionData) => {
    const payload = {
      permissions: [],
    };
    payload["permissions"].push({
      target: permissionData["target"],
      scope: permissionData["scope"],
    });
    let response = await removeFolderPermissionApi({
      folderID: props.folderID,
      payload: payload,
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsError(true);
    } else {
      setRefresh(true);
    }
  };

  return !loading ? (
    <>
      <Box role="presentation" sx={{ width: 500 }}>
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: 500,
            right: 0,
            bgcolor: "#f9f9fc",
          }}
        >
          <Toolbar style={{ padding: "16px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <ModelHeader>Manage permission</ModelHeader>
              <Box
                sx={{ display: "flex", alignItems: "center", columnGap: "8px" }}
              >
                <Tooltip title={"Add Permission"}>
                  <IconButton
                    style={{ padding: "0px" }}
                    onClick={() => {
                      setOpenAddPermissionModal(true);
                    }}
                  >
                    <PersonAdd />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        {!loadingPermissionList ? (
          list.length > 0 ? (
            <TableWrapper>
              <Grid container spacing={2}>
                {list.map((data, index) => {
                  const cardInfo = getCardInfo({ data: data });
                  if (cardInfo !== null) {
                    return (
                      <Grid item xs={12} key={index}>
                        <PermissionCardWidget
                          canRemove={!isSameUserOrOwner({ data: data })}
                          cardInfo={cardInfo}
                          onRemove={() => handlePermissionRemove(data)}
                        />
                      </Grid>
                    );
                  } else {
                    <Grid item xs={12} key={index}>
                      <NoDataCardWidget />
                    </Grid>;
                  }
                })}
              </Grid>
            </TableWrapper>
          ) : (
            <Wrapper>
              <Text>No data.</Text>
            </Wrapper>
          )
        ) : (
          <Wrapper>
            <CircularProgress />
          </Wrapper>
        )}
        {list.length > 0 && (
          <AppBar
            position="fixed"
            color="inherit"
            elevation={0}
            sx={{ top: "auto", bottom: 0, width: 500 }}
          >
            <Toolbar
              style={{ width: 500, padding: "16px", position: "relative" }}
            >
              {isError && (
                <ErrorAlert
                  onClose={(e) => {
                    e.stopPropagation();
                    setIsError(false);
                  }}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  {errorMessage}
                </ErrorAlert>
              )}
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <TableWidgetPagination
                    page_no={page}
                    row={list}
                    page_size={size}
                    size={size}
                    count={count}
                    handlePageSize={handlePageSize}
                    handlePage={handlePagination}
                  />
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        )}
      </Box>
      {openAddPermissionModal && (
        <AddPermissionModal
          open={openAddPermissionModal}
          setOpen={setOpenAddPermissionModal}
          folderID={props.folderID}
          setRefresh={setRefresh}
          memberList={memberList}
        />
      )}
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: 500,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default observer(ManageFolderSidepanel);
