import {
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  CircularProgress,
} from "@material-ui/core";
import { invoicePaymentColumns } from "../../../Db";
import { getLeadInvoiceDataApi } from "../../../Api";
import React, { useEffect, useState, useMemo } from "react";
import { styled } from "@mui/material/styles";
import { Close } from "@material-ui/icons";
import { Typography, IconButton } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { Attachment } from "@material-ui/icons";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: auto;
  background-color: white;
  height: 100%;
  width: 100%;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;

const GridWrapper = styled(Box)`
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  max-height: calc(80vh - 120px);
`;

const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;

const PaymentsLogsDialog = (props) => {
  const [loading, setLoading] = useState(true);
  const [row, setRow] = useState([]);

  const renderAttachments = (params) => {
    if (params.data.file_1 === null && params.data.file_2 === null) {
      return "-";
    } else {
      return (
        <Box
          sx={{
            display: "flex",
            gap: "12px",
            alignItems: "center",
            height: "100%",
          }}
        >
          {params.data.file_1 !== null && (
            <IconButton
              onClick={() => {
                window.open(params.data.file_1.url, "_blank");
              }}
              style={{
                width: "20px",
                height: "20px",
                padding: "0px",
              }}
            >
              <Attachment
                style={{
                  cursor: "pointer",
                  width: "20px",
                  height: "20px",
                }}
                color="primary"
              />
            </IconButton>
          )}
          {params.data.file_2 !== null && (
            <IconButton
              onClick={() => {
                window.open(params.data.file_2.url, "_blank");
              }}
              style={{
                width: "20px",
                height: "20px",
                padding: "0px",
              }}
            >
              <Attachment
                style={{
                  cursor: "pointer",
                  width: "20px",
                  height: "20px",
                }}
                color="primary"
              />
            </IconButton>
          )}
        </Box>
      );
    }
  };

  const fileColumn = [
    {
      field: "invoice_file",
      headerName: "ATTACHMENTS",
      flex: 1,
      minWidth: 100,
      cellRenderer: (params) => {
        return (
          <Box
            sx={{
              height: "100%",
            }}
          >
            {renderAttachments(params)}
          </Box>
        );
      },
    },
  ];

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  const init = async () => {
    setLoading(true);
    let response = await getLeadInvoiceDataApi({
      leadID: props.leadID,
      invoiceID: props.invoiceID,
    });
    if (response.hasOwnProperty("payment_records")) {
      setRow(response.payment_records);
    }
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer sx={{ width: "1100px", maxHeight: "80vh" }}>
          {!loading ? (
            <Container>
              <Grid container justifyContent="space-between">
                <Header>Payments</Header>
                <Grid item>
                  <IconButton onClick={handleClose} style={{ padding: "0px" }}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>

              <Divider style={{ margin: "16px 0px" }} />

              {/* Scrollable wrapper around the grid */}
              <GridWrapper>
                <div className="ag-theme-alpine" style={{ width: "100%" }}>
                  <AgGridReact
                    domLayout={"autoHeight"}
                    gridOptions={gridOptions}
                    rowData={row}
                    defaultColDef={defaultColDef}
                    columnDefs={invoicePaymentColumns.concat(fileColumn)}
                    animateRows={true}
                    suppressCellFocus
                    suppressColumnVirtualisation={true}
                  />
                </div>
              </GridWrapper>
            </Container>
          ) : (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </ModelContainer>
      </Modal>
    </>
  );
};

export default PaymentsLogsDialog;
