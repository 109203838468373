import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { getLocalizedText } from "../../Functions";
import LocationMarker from "./LocationMarker";
import { GpsFixed } from "@material-ui/icons";
import { IconButton } from "@mui/material";

const dialogWidth = 420;
const center = [28.6139, 77.209];

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const MapLocationDialog = ({ open, setOpen, handleSave, locationData }) => {
  const [position, setPosition] = useState(null);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (locationData !== null) {
      setPosition(locationData);
    }
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus
    >
      <ModelContainer>
        <Box>
          <AppBar
            component={"nav"}
            elevation={0}
            position="sticky"
            color="inherit"
            sx={{
              top: 0,
              bottom: "auto",
              width: "100%",
              right: 0,
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            <Toolbar
              style={{
                padding: "16px",
              }}
            >
              <HeaderWrapper>
                <Header>Mark your location on map</Header>
              </HeaderWrapper>
            </Toolbar>
          </AppBar>
          <Divider style={{ margin: "0px 16px" }} />
          <Container>
            <Box
              sx={{
                position: "relative",
                "& .leaflet-container .leaflet-control-attribution": {
                  display: "none",
                },
              }}
            >
              <MapContainer
                center={center}
                zoom={13}
                style={{ height: "400px", width: "100%" }}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                <LocationMarker setPosition={setPosition} position={position} />
                {/* <IconButton
                  style={{
                    position: "absolute",
                    bottom: "16px",
                    right: "16px",
                    backgroundColor: "#fff",
                    zIndex: 1000,
                  }}
                >
                  <GpsFixed color="primary" />
                </IconButton> */}
              </MapContainer>
            </Box>
          </Container>
          <AppBar
            position="static"
            color="inherit"
            elevation={0}
            sx={{
              top: "auto",
              bottom: 0,
              width: "100%",
              borderRadius: "0px 0px 8px 8px",
            }}
          >
            <Toolbar
              style={{
                padding: "16px",
                width: dialogWidth,
                position: "relative",
              }}
            >
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="default"
                    style={{
                      fontSize: "12px",
                      textTransform: "none",
                      fontWeight: "bold",
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disabled={position === null}
                    type="button"
                    onClick={() => handleSave(position)}
                    variant="contained"
                    color="primary"
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "fit-content",
                      textTransform: "none",
                    }}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </Box>
      </ModelContainer>
    </Modal>
  );
};

export default MapLocationDialog;
