import * as React from "react";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { Badge } from "@mui/material";
import { PickersDay } from "@mui/x-date-pickers";
import styled from "styled-components";
import "./checkin.css";
import { useEffect } from "react";
import { getMonthCheckInsApi, getUserMonthCheckInsApi } from "../../Api";
import moment from "moment";
import { consoleLogger } from "../../Functions";
const Container = styled.div`
  width: fit-content;
  margin-bottom: 20px;
`;
export default function CheckInCalendar(props) {
  const [value, setValue] = React.useState(dayjs(props.date));
  const [highlightedDays, setHighlightedDays] = React.useState(null);
  const monthStartDateGetter = (year, month) => {
    let result = new Date(year, month, 1);
    result = moment(result).format("YYYY-MM-DD");
    props.setDate(result);
  };
  const dateFormatConverter = (year, month, day) => {
    let result = new Date(year, month, day);
    result = moment(result).format("YYYY-MM-DD");
    props.setCheckinDate(result);
  };
  useEffect(() => {
    const getMonthCheckins = async () => {
      try {
        let response = await getMonthCheckInsApi(props.date);
        let data = response.data;
        let result = {};
        if (Object.keys(data).length > 0) {
          Object.keys(data).map((item) => {
            let date = new Date(item);
            let day = date.getDate();
            result[day] = data[item];
          });
        }
        consoleLogger(result);
        setHighlightedDays(result);
      } catch (error) {
        console.log(error);
      }
    };
    const getUserMonthCheckins = async () => {
      try {
        consoleLogger(props.date);
        let response = await getUserMonthCheckInsApi(
          props.date,
          props.pid,
          props.id
        );
        let data = response.data;
        let result = {};
        if (Object.keys(data).length > 0) {
          Object.keys(data).map((item) => {
            let date = new Date(item);
            let day = date.getDate();
            result[day] = data[item];
          });
        }
        consoleLogger(result);
        setHighlightedDays(result);
      } catch (error) {
        console.log(error);
      }
    };
    if (props.id) {
      getUserMonthCheckins();
    } else {
      getMonthCheckins();
    }
  }, [props.date]);
  const generateCurrentMonthLastDate = () => {
    let date = new Date();
    date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    date = moment(date).format("YYYY-MM-DD");

    return date;
  };
  const generateLastYearDate = () => {
    let date = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
    date = new Date(date.getFullYear(), date.getMonth(), 1);
    date = moment(date).format("YYYY-MM-DD");

    return date;
  };
  return (
    <Container>
      {highlightedDays !== null && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDatePicker
            className="checkin-calendar"
            maxDate={generateCurrentMonthLastDate()}
            minDate={generateLastYearDate()}
            // disableHighlightToday={true}
            onMonthChange={(e) => {
              let date = e["$d"];
              let result = new Date(date);
              monthStartDateGetter(result.getFullYear(), result.getMonth());
            }}
            onYearChange={(e) => {
              let date = e["$d"];
              let result = new Date(date);
              monthStartDateGetter(result.getFullYear(), result.getMonth());
            }}
            orientation="portrait"
            openTo="day"
            value={value}
            onChange={(newValue) => {
              let date = newValue["$d"];
              let result = new Date(date);
              dateFormatConverter(
                result.getFullYear(),
                result.getMonth(),
                result.getDate()
              );
              setValue(newValue);
            }}
            renderDay={(day, _value, DayComponentProps) => {
              const isSelected =
                !DayComponentProps.outsideCurrentMonth &&
                highlightedDays[day.date()] >= 0;

              return (
                <Badge
                  color="success"
                  key={day.toString()}
                  overlap="circular"
                  badgeContent={
                    isSelected ? (
                      <span style={{ fontSize: "6px" }}>
                        {highlightedDays[day.date()]}
                      </span>
                    ) : undefined
                  }
                >
                  <PickersDay {...DayComponentProps} />
                </Badge>
              );
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      )}
    </Container>
  );
}
