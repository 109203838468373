import React from "react";
import { makeStyles, Dialog } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { Box, IconButton, Typography } from "@mui/material";
import { Call, RemoveSharp } from "@material-ui/icons";

const useStyles = makeStyles({
  dialog: {
    position: "absolute",
    left: 50,
    bottom: 50,
  },
});

const Container = styled(Box)`
  background-color: white;
  display: flex;
  width: 400px;
  height: 400px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
`;

const Wrapper = styled(Box)`
  background-color: white;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  /* padding: 0px 20px; */
  overflow: auto;
`;
const TopWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
`;
const Title = styled(Typography)`
  font: normal normal 400 16px Open Sans;
  color: #5a606680;
`;
const IconTextWrapper = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

const TeckInfoCallDialog = (props) => {
  const classes = useStyles();
  const handleClose = () => {
    props.setOpen(false);
  };
  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      classes={{
        paper: classes.dialog,
      }}
      style={{
        zIndex: "99991",
      }}
    >
      <Container>
        <TopWrapper>
          <IconTextWrapper>
            <Call
              style={{ width: "20px", height: "20px", color: "#5a606680" }}
            />
            <Title>Phone</Title>
          </IconTextWrapper>
          <IconButton
            onClick={handleClose}
            style={{ width: "30px", height: "30px" }}
          >
            <RemoveSharp />
          </IconButton>
        </TopWrapper>
        <Wrapper>
          <iframe
            src={`https://maps.google.com/maps?q=12.970090, 77.652962&hl=us&z=14&amp&output=embed`}
            frameborder="0"
            style={{
              widht: "400px",
              height: "100%",
            }}
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          />
        </Wrapper>
      </Container>
    </Dialog>
  );
};

export default TeckInfoCallDialog;
