import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Dialog,
  Typography,
  Zoom,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CakeIcon from "@mui/icons-material/Cake";
import blobImage from "../../assets/bg_blob.png";
import { updateUserDetailApi } from "../../Api";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "460px",
  width: "575px",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}));

const BottomWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: "white",
  display: "flex",
  width: "100%",
  height: "66%",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: "20px",
  position: "relative",
  rowGap: "10px",
}));

const TopWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "34%",
  flexDirection: "column",
  backgroundColor: "#eda93b",
  position: "relative",
  boxShadow: "inset 0px -5px 10px rgba(0, 0, 0, 0.2)",
}));

const TitleText = styled(Typography)(({ theme }) => ({
  marginTop: "30px",
  fontWeight: 800,
  fontSize: "28px",
  fontFamily: "Open Sans",
  color: "#3e3e3e",
  textAlign: "center",
}));

const ContentText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "17px",
  fontFamily: "Open Sans",
  color: "#797979",
  textAlign: "center",
  width: "85%",
}));

const TextWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  padding: "10px",
  width: "95%",
  marginBottom: "35px",
}));
const CancelButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  marginRight: "20px",
  fontSize: "18px",
  letterSpacing: "1px",
  fontWeight: "bold",
  height: "48px",
  width: "50%",
  borderRadius: "8px",
  color: "#1976d2",
  backgroundColor: "#e3f2fd",
  transition: "background-color 0.3s",
  "&:hover": {
    backgroundColor: "#bbdefb", // Lighter shade for hover
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={500} ref={ref} {...props} />;
});
const BirthdayPopup = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [birthday, setBirthday] = useState(null);
  const [error, setError] = useState(false);

  const init = async () => {
    setOpen(true);
  };
  useEffect(() => {
    init();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (birthday === null || birthday === "") {
      setError(true);
      return;
    }

    setError(false);

    const data = { dob: birthday.toISOString() };

    let response = await updateUserDetailApi({
      payload: data,
    });

    if (response.hasError()) {
      console.log(response.errorMessage);
    }
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      sx={{
        backdropFilter: "blur(5px)",
      }}
      PaperProps={{
        sx: {
          overflow: "hidden",
          borderRadius: "35px",
        },
      }}
      TransitionComponent={Transition}
    >
      <Container>
        <TopWrapper>
          <Box
            style={{
              position: "relative",
              width: "220px",
              height: "220px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 0,
              margin: 0,
              overflow: "hidden",
              border: "none",
            }}
          >
            <img
              src={blobImage}
              alt="Background"
              style={{
                width: "150px",
                height: "150px",
                objectFit: "contain",
                position: "absolute",
              }}
            />
            <CakeIcon
              style={{
                fontWeight: "bold",
                width: "95px",
                height: "95px",
                fontSize: "10px",
                position: "absolute",
                color: "#fff",
              }}
            />
          </Box>
        </TopWrapper>
        <BottomWrapper>
          <TitleText>Celebrate You!</TitleText>
          <TextWrapper>
            <ContentText>
              Fill in your birthday to make your profile complete! It's the
              final touch to help us bring you tailored updates and exclusive
              perks.
            </ContentText>
          </TextWrapper>
          <Box
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
              width: "95%",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                onChange={(event) => {
                  setBirthday(event);
                }}
                value={birthday}
                label="Enter your birthday"
                inputFormat="YYYY-MM-DD"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "100%" }}
                    error={error}
                    helperText={error ? "Please enter your birthday" : ""}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>

          <ButtonWrapper>
            <CancelButton
              disableElevation
              onClick={handleClose}
              type="button"
              variant="contained"
            >
              Cancel
            </CancelButton>
            <Button
              disableElevation
              onClick={handleSubmit}
              variant="contained"
              type="button"
              color="primary"
              style={{
                textTransform: "none",
                margin: "auto",
                fontSize: "18px",
                letterSpacing: "1px",
                fontWeight: "bold",
                height: "48px",
                width: "50%",
                borderRadius: "8px",
              }}
            >
              Submit
            </Button>
          </ButtonWrapper>
        </BottomWrapper>
      </Container>
    </Dialog>
  );
};

export default observer(BirthdayPopup);
