import React from "react";
import { StyleSheet, View, Text, Font } from "@react-pdf/renderer";

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/poppins/v21/pxiEyp8kv8JHgFVrFJA.ttf",
      fontWeight: 400,
      fontStyle: "normal",
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLGT9V1s.ttf",
      fontWeight: 500,
      fontStyle: "normal",
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLCz7V1s.ttf",
      fontWeight: 700,
      fontStyle: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  container: {},
  row: {
    flexDirection: "row",
    display: "flex",
    borderTop: "1px solid #D3D3D3",
    borderBottom: "1px solid #D3D3D3",
    paddingLeft: "16px",
  },
  columnWrapper: {
    flexDirection: "column",
    display: "flex",
  },
  rowWrapper: {
    flexDirection: "row",
    display: "flex",
  },
  headerCell: {
    textAlign: "left",
    padding: "4px 8px",
  },
  headerCellAlignedBottom: {
    padding: "4px 8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  headerText: {
    fontSize: 8,
    fontWeight: "700",
    color: "black",
    fontFamily: "Poppins",
    textAlign: "right",
  },
  firstCell: {
    textAlign: "left",
    width: "5%",
    padding: "4px 0px",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  horizontalDivider: {
    height: "1px",
    width: "100%",
    backgroundColor: "#D3D3D3",
  },
  verticalDivider: {
    width: "1px",
    backgroundColor: "#D3D3D3",
    alignSelf: "stretch",
  },
});
const TableHeader = ({ showDiscountCol }) => {
  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <View style={styles.firstCell}>
          <Text style={{ ...styles.headerText, textAlign: "left" }}>#</Text>
        </View>
        <View style={styles.verticalDivider} />
        <View
          style={{
            ...styles.headerCellAlignedBottom,
            width: "30%",
            justifyContent: "flex-start",
          }}
        >
          <Text style={styles.headerText}>Item & Description</Text>
        </View>
        <View style={styles.verticalDivider} />
        <View style={{ ...styles.headerCellAlignedBottom, width: showDiscountCol ? "10%" : "15%" }}>
          <Text style={styles.headerText}>Rate</Text>
        </View>
        <View style={styles.verticalDivider} />

        <View style={{ ...styles.headerCellAlignedBottom, width: showDiscountCol ? "5%" : "10%" }}>
          <Text style={styles.headerText}>Qty</Text>
        </View>
        <View style={styles.verticalDivider} />

        <View style={{ ...styles.headerCell, width: showDiscountCol ? "20%" : "25%", padding: "0px" }}>
          <View style={{ ...styles.columnWrapper, textAlign: "center" }}>
            <Text
              style={{
                ...styles.headerText,
                padding: "4px 8px",
                textAlign: "center",
              }}
            >
              Tax
            </Text>
            <View style={styles.horizontalDivider} />
            <View style={{ ...styles.rowWrapper }}>
              <Text
                style={{
                  ...styles.headerText,
                  width: "50%",
                  padding: "4px 8px",
                }}
              >
                %
              </Text>
              <View style={styles.verticalDivider} />
              <Text
                style={{
                  ...styles.headerText,
                  width: "50%",
                  padding: "4px 8px",
                }}
              >
                Amt
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.verticalDivider} />

        {showDiscountCol && (
          <>
            <View
              style={{ ...styles.headerCell, width: "20%", padding: "0px" }}
            >
              <View style={{ ...styles.columnWrapper, textAlign: "center" }}>
                <Text
                  style={{
                    ...styles.headerText,
                    padding: "4px 8px",
                    textAlign: "center",
                  }}
                >
                  Discount
                </Text>
                <View style={styles.horizontalDivider} />
                <View style={{ ...styles.rowWrapper }}>
                  <Text
                    style={{
                      ...styles.headerText,
                      width: "50%",
                      padding: "4px 8px",
                    }}
                  >
                    %
                  </Text>
                  <View style={styles.verticalDivider} />
                  <Text
                    style={{
                      ...styles.headerText,
                      width: "50%",
                      padding: "4px 8px",
                    }}
                  >
                    Amt
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.verticalDivider} />
          </>
        )}

        <View style={{ ...styles.headerCellAlignedBottom, width: showDiscountCol ? "10%" : "15%" }}>
          <Text style={styles.headerText}>Amount</Text>
        </View>
      </View>
    </View>
  );
};

export default TableHeader;
