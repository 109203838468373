import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, Card, Divider, Typography } from "@mui/material";
import { IsoToLocalDate, dataRenderer } from "../../Functions";

const Container = styled(Box)`
  display: flex;
  padding: 20px;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  row-gap: 16px;
`;
const Top = styled(Box)`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;
const TextSpacedBetween = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Text1 = styled(Typography)`
  margin: 0px;
  font: normal normal 600 20px Open Sans;
  color: #4d4e4f;
`;

const TextWrapper = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;
const BillDetailsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;
const Text3 = styled(Typography)`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
  color: #4d4e4f;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: flex-end;
`;
const BillCardWidget = ({ details, dueAmount }) => {
  const RenderBillDueAmount = () => {
    if (dueAmount === null) {
      return <Text3>-</Text3>;
    } else {
      if (dueAmount > 0) {
        return (
          <Text3 style={{ color: "red" }}>{`${dataRenderer(
            details["currency"]
          )} ${dueAmount}`}</Text3>
        );
      }
    }
    return <Text3>{dueAmount}</Text3>;
  };

  return (
    <Card sx={{ height: "100%" }}>
      <Container>
        <Top>
          <TextSpacedBetween>
            <Text1
              style={{
                color: "#aca7a6",
              }}
            >
              Billing Details
            </Text1>
            <Text1>{`${dataRenderer(details["billing_cycle"])} Cycle`}</Text1>
          </TextSpacedBetween>
        </Top>
        <BillDetailsWrapper>
          <TextWrapper>
            <Text3
              style={{
                color: "#aca7a6",
              }}
            >
              Term :
            </Text3>

            <Text3>
              {details.hasOwnProperty("start_date") &&
              details.hasOwnProperty("end_date") &&
              details["start_date"] !== null &&
              details["end_date"] !== null
                ? `${IsoToLocalDate(details["start_date"])} - ${IsoToLocalDate(
                    details["end_date"]
                  )}`
                : "-"}
            </Text3>
          </TextWrapper>
          <TextWrapper>
            <Text3
              style={{
                color: "#aca7a6",
              }}
            >
              Next Billing Date :
            </Text3>
            <Text3>
              {details.hasOwnProperty("next_billing_on") &&
              details["next_billing_on"] !== null
                ? IsoToLocalDate(details)
                : "-"}
            </Text3>
          </TextWrapper>
          <TextWrapper>
            <Text3
              style={{
                color: "#aca7a6",
              }}
            >
              Amount Past Due :
            </Text3>
            <RenderBillDueAmount />
          </TextWrapper>
        </BillDetailsWrapper>
        {/* <Divider orientation="horizontal" />
        <ButtonWrapper>
          <Button
            variant="text"
            color="primary"
            style={{
              textTransform: "none",
              fontSize: "14px",
              fontWeight: "bold",
              width: "fit-content",
              textDecoration: "underline",
              padding: "0px",
            }}
          >
            View Details
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{
              textTransform: "none",
              fontSize: "14px",
              fontWeight: "bold",
              width: "fit-content",
            }}
          >
            Upgrade
          </Button>
        </ButtonWrapper> */}
      </Container>
    </Card>
  );
};

export default BillCardWidget;
