import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";
import { dataRenderer } from "../../Functions";

const ModelWrapper = styled(Box)(({ theme }) => ({
  width: "500px",
  margin: "auto",
  display: "flex",
  position: "relative",
  /* padding: 30px, */
  flexDirection: "column",
  height: "95vh",
  paddingTop: "0px",
}));
const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  padding: "30px 0px 30px 16px",
  marginBottom: "20px",
  backgroundColor: "#f9f9fc",
}));

const ModelHeader = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 18px Open Sans",
  variant: "span",
  color: "#4d4e4f",
}));

const Title = styled(Typography)(({ theme }) => ({
  font: "normal normal 400 14px Open Sans",
  color: "#5A606680",
}));
const Desc = styled(Typography)(({ theme }) => ({
  font: "normal normal 500 14px Open Sans",
  color: "#5A6066",
  wordWrap: "break-word",
}));

const Column = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  overflowX: "auto",
  padding: "0px 16px 0px 16px",
}));

const CallSummaryPanel = (props) => {
  return (
    <>
      <Box role="presentation">
        <ModelWrapper>
          <HeaderWrapper>
            <Info color="primary" style={{ marginRight: "8px" }} />
            <ModelHeader>Call Summary</ModelHeader>
          </HeaderWrapper>
          <Column>
            {Object.keys(props.data).map((key, i) => {
              return (
                <Box key={i}>
                  <Box
                    style={{
                      marginTop: "8px",
                      marginBottom: "8px",
                    }}
                  >
                    <Grid container alignItems="center">
                      <Grid item xs={4}>
                        <Title>{key}</Title>
                      </Grid>
                      <Grid item xs={1}>
                        <Divider
                          orientation="vertical"
                          sx={{ height: "20px", color: "#3535354D" }}
                        />
                      </Grid>
                      <Grid item xs={7} paddingLeft={"8px"}>
                        <Desc>{dataRenderer(props.data[key])}</Desc>
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider orientation="horizontal" />
                  {/* <Row>
                    <TermHeading>{key}</TermHeading>
                    <TermHeading>{props.data[key]}</TermHeading>
                  </Row> */}
                </Box>
              );
            })}
          </Column>
        </ModelWrapper>
      </Box>
    </>
  );
};

export default CallSummaryPanel;
