import React, { useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Modal,
} from "@material-ui/core";
import { editProjectWorkScheduleApi } from "../../Api";
import { useEffect } from "react";
import { myTheme } from "../../themeUtils";
import { daysList } from "../../Db";
import moment from "moment";
import { consoleLogger, getLocalizedText } from "../../Functions";
import analytics from "../../Analytics";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled.span`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled.span`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
  margin-bottom: 30px;
`;
const Label = styled.span`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const DetailsHeader = styled.span`
  font: normal normal 600 16px Open Sans;
  color: #979797;
  margin-bottom: 8px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled.div`
  display: flex;
  width: 450px;
  flex-direction: column;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;
const Detail = styled.div`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const Input = styled.input`
  height: 30px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;

  border: 1px solid #bfbfbf;
  font: normal normal 600 16px Open Sans;
  :disabled {
    color: #d3d3d3;
    font-weight: 300;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const MessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
`;
const Message = styled.p`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
  text-align: center;
`;
const ButtonWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const EditWorkSchedule = (props) => {
  const [user, setUser] = useState({
    work_end_time: null,
    work_start_time: null,
    workday_monday: false,
    workday_tuesday: false,
    workday_wednesday: false,
    workday_thursday: false,
    workday_friday: false,
    workday_saturday: false,
    workday_sunday: false,
  });
  const [check, setCheck] = useState(false);
  const [errorVal, setErrorVal] = useState(false);

  const handleChange = (e) => {
    consoleLogger(e.target.value);
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const getLocalTimeFromIso = (isoDateString) => {
    if (isoDateString !== null && isoDateString !== undefined) {
      return moment.utc(isoDateString).local().format("HH:mm");
    } else {
      return "-";
    }
  };
  const changeTimeToISO = (value) => {
    const isoString = moment(
      `1970-01-01 ${value}`,
      "YYYY-MM-DD HH:mm"
    ).toISOString();
    consoleLogger(isoString);
    return isoString;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const editProject = async () => {
      let data = { ...user };
      data["work_start_time"] = changeTimeToISO(data["work_start_time"]);
      data["work_end_time"] = changeTimeToISO(data["work_end_time"]);

      try {
        let response = await editProjectWorkScheduleApi({
          id: props.data.id,
          data: data,
        });
        props.setRefresh(!props.refresh);

        setCheck(true);
        setErrorVal(false);
      } catch (error) {
        setCheck(true);
        setErrorVal(true);
        console.log(error);
      }
    };
    editProject();
  };

  const handleClose = () => {
    props.setOpen(false);
  };
  useEffect(() => {
    analytics.triggerEvent(
      4625000173,
      "edit_work_schedule_form_load",
      "project_setting_page",
      "",
      {}
    );
    let work_end_time = getLocalTimeFromIso(props.data["work_end_time"]);
    let work_start_time = getLocalTimeFromIso(props.data["work_start_time"]);
    consoleLogger(work_start_time, work_end_time);
    setUser({
      work_end_time: work_end_time,
      work_start_time: work_start_time,
      workday_monday: props.data.workday_monday,
      workday_tuesday: props.data.workday_tuesday,
      workday_wednesday: props.data.workday_wednesday,
      workday_thursday: props.data.workday_thursday,
      workday_friday: props.data.workday_friday,
      workday_saturday: props.data.workday_saturday,
      workday_sunday: props.data.workday_sunday,
    });
  }, []);
  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!check ? (
          <ModelContainer>
            <Box>
              <Container>
                <Header>{getLocalizedText("edit_work_schedule")}</Header>
                <SubHeader>
                  {getLocalizedText("fill_in_the_below_details")}
                </SubHeader>
                <Form onSubmit={handleSubmit}>
                  <InputContainer>
                    {user !== null && (
                      <InputWrapper>
                        <DetailsHeader>
                          {getLocalizedText("work_days")}
                        </DetailsHeader>
                        <Grid container spacing={2} style={{ margin: "2px" }}>
                          {daysList.map((item, i) => {
                            return (
                              <Grid item xs={4}>
                                <FormControlLabel
                                  key={i}
                                  checked={user["workday_" + item["value"]]}
                                  control={
                                    <Checkbox
                                      size="small"
                                      onChange={(event) => {
                                        let key = "workday_" + item["value"];
                                        setUser({
                                          ...user,
                                          [key]: event.target.checked,
                                        });
                                      }}
                                    />
                                  }
                                  label={<Detail>{item["name"]}</Detail>}
                                />
                              </Grid>
                            );
                          })}
                        </Grid>
                      </InputWrapper>
                    )}
                    <InputWrapper>
                      <DetailsHeader>
                        {getLocalizedText("work_time")}
                      </DetailsHeader>
                      <Grid container spacing={2} columnSpacing={2}>
                        <Grid item xs={6}>
                          <InputWrapper>
                            <Label>{getLocalizedText("start_time")}*</Label>
                            <Input
                              required
                              value={user.work_start_time}
                              type="time"
                              max={user.work_end_time}
                              placeholder="enter start time"
                              name="work_start_time"
                              onChange={handleChange}
                            />
                          </InputWrapper>
                        </Grid>
                        <Grid item xs={6}>
                          <InputWrapper>
                            <Label>{getLocalizedText("end_time")}*</Label>
                            <Input
                              required
                              value={user.work_end_time}
                              type="time"
                              placeholder="enter end_time"
                              name="work_end_time"
                              onChange={handleChange}
                            />
                          </InputWrapper>
                        </Grid>
                      </Grid>
                    </InputWrapper>
                  </InputContainer>
                  <ButtonWrapper>
                    <Button
                      onClick={() => {
                        analytics.triggerEvent(
                          4625000174,
                          "cancel_button_tap",
                          "edit_work_schedule_form",
                          "cancel_button",
                          {}
                        );
                        handleClose();
                      }}
                      type="button"
                      variant="contained"
                      color="default"
                      style={{
                        backgroundColor: "#EFEFF4",
                        textTransform: "none",
                        marginRight: "20px",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      {getLocalizedText("cancel")}
                    </Button>
                    <Button
                      onClick={() => {
                        analytics.triggerEvent(
                          4625000175,
                          "submit_button_tap",
                          "edit_work_schedule_form",
                          "submit_button",
                          {}
                        );
                      }}
                      type="submit"
                      variant="contained"
                      id="call-log-btn"
                      style={{
                        textTransform: "none",
                        fontSize: "12px",
                        fontWeight: "bold",
                        ...myTheme.Button.btnBlue,
                      }}
                    >
                      {getLocalizedText("submit_details")}
                    </Button>
                  </ButtonWrapper>
                </Form>
              </Container>
            </Box>
          </ModelContainer>
        ) : (
          <ModelContainer
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MessageWrapper>
              {!errorVal ? (
                <Message>
                  {getLocalizedText("project_info_updated_successfully")}
                </Message>
              ) : (
                <Message style={{ color: "red" }}>
                  {getLocalizedText("some_error_occured")}
                </Message>
              )}
              <Button
                onClick={handleClose}
                type="button"
                variant="contained"
                color="default"
                style={{
                  marginTop: "20px",
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "100px",
                }}
              >
                {getLocalizedText("close")}
              </Button>
            </MessageWrapper>
          </ModelContainer>
        )}
      </Modal>
    </>
  );
};

export default observer(EditWorkSchedule);
