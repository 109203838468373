import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, Grid } from "@material-ui/core";
import {
  AppBar,
  Box,
  FormControl,
  MenuItem,
  Select as MuiSelect,
  OutlinedInput,
  Toolbar,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { taskStatusList } from "../../../Db";
import { LoadingButton } from "@mui/lab";
import { closeTaskApi } from "../../../Api";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  gap: 20px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CloseTaskComponent = ({ setOpen, setRefresh, editableData }) => {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [details, setDetails] = useState({
    remarks: "",
    task_status: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const closeTask = async (payload) => {
    setIsSubmitting(true);
    let response = await closeTaskApi({
      taskID: editableData["id"],
      payload: payload,
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
    setIsSubmitting(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let payload = { ...details };
    await closeTask(payload);
  };

  return (
    <Box role="presentation">
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          width: 500,
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar>
          <ModelHeader>Close Task</ModelHeader>
        </Toolbar>
      </AppBar>
      {!isSubmitSuccess ? (
        <ModelWrapper component={"form"} onSubmit={handleSubmit}>
          <InputContainer>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputWrapper>
                  <Label>Task Status*</Label>
                  <FormControl sx={{ width: "100%" }}>
                    <MuiSelect
                      displayEmpty
                      required
                      onChange={handleChange}
                      name="task_status"
                      disableUnderline
                      variant="outlined"
                      value={details["task_status"]}
                      style={{
                        width: "100%",
                        height: "36px",
                        fontSize: "12px",
                        borderRadius: "4px",
                        borderColor: "#C4C4C4",
                      }}
                    >
                      {taskStatusList.map((item, i) => {
                        if (item !== "Open") {
                          return (
                            <MenuItem
                              value={item}
                              key={i}
                              style={{ fontSize: "12px" }}
                            >
                              {item}
                            </MenuItem>
                          );
                        }
                      })}
                    </MuiSelect>
                  </FormControl>
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper>
                  <Label>Remarks</Label>
                  <OutlinedInput
                    multiline
                    rows={3}
                    inputProps={{
                      min: 0,
                      "aria-label": "weight",
                      style: {
                        fontSize: "12px",
                      },
                    }}
                    onChange={handleChange}
                    name="remarks"
                    value={details["remarks"]}
                    placeholder="Enter value"
                  />
                </InputWrapper>
              </Grid>
            </Grid>
          </InputContainer>

          <AppBar
            position="fixed"
            color="inherit"
            elevation={0}
            sx={{ top: "auto", bottom: 0, width: 500 }}
          >
            <Toolbar style={{ width: 500, position: "relative" }}>
              {isSubmitFail && (
                <ErrorAlert
                  onClose={(e) => {
                    e.stopPropagation();
                    setIsSubmitFail(false);
                  }}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  {errorMessage}
                </ErrorAlert>
              )}
              <ButtonWrapper>
                <Button
                  onClick={handleClose}
                  type="button"
                  variant="contained"
                  color="default"
                  style={{
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  Cancel
                </Button>
                {isSubmitting ? (
                  <LoadingButton
                    loading
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: "none",
                      width: "100%",
                    }}
                  >
                    <span>Submit</span>
                  </LoadingButton>
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    Submit
                  </Button>
                )}
              </ButtonWrapper>
            </Toolbar>
          </AppBar>
        </ModelWrapper>
      ) : (
        <MessageWrapper>
          <Message>Task closed successfully!</Message>

          <Button
            onClick={handleClose}
            type="button"
            variant="contained"
            color="default"
            style={{
              marginTop: "20px",
              textTransform: "none",
              fontSize: "12px",
              fontWeight: "bold",
              width: "100px",
            }}
          >
            Close
          </Button>
        </MessageWrapper>
      )}
    </Box>
  );
};

export default CloseTaskComponent;
