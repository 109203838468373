import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, Grid } from "@material-ui/core";
import {
  AppBar,
  Box,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { LoadingButton } from "@mui/lab";
import { createLeadInteractionLogApi } from "../../../../Api";
import { leadInteractionChannelList } from "../../../../Db";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  gap: 20px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CustomizedDateTimePicker = styled(DateTimePicker)`
  & .MuiInputBase-input {
    font-size: 12px;
    padding: 7.5px 14px;
  }
`;

const CreateLeadInteractionComponent = ({ setOpen, setRefresh, leadID }) => {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [details, setDetails] = useState({
    title: "",
    channel: "",
    interacted_on: moment(),
    comments: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const createInteraction = async () => {
    setIsSubmitting(true);
    let payload = { ...details };
    let response = await createLeadInteractionLogApi({
      leadID: leadID,
      payload: payload,
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
    setIsSubmitting(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValidInteractedOn()) {
      await createInteraction();
    } else {
      setErrorMessage("Invalid interacted on value!");
      setIsSubmitFail(true);
    }
  };

  const isValidInteractedOn = () => {
    if (details.interacted_on !== null) {
      let isoString = details.interacted_on.toISOString();
      return moment(isoString).isSameOrBefore(moment());
    }
    return true;
  };

  return (
    <Box role="presentation">
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          width: 500,
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar>
          <ModelHeader>Create Interaction Log</ModelHeader>
        </Toolbar>
      </AppBar>
      {!isSubmitSuccess ? (
        <ModelWrapper component={"form"} onSubmit={handleSubmit}>
          <InputContainer>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputWrapper>
                  <Label>Title*</Label>
                  <OutlinedInput
                    style={{ height: "30px" }}
                    inputProps={{
                      min: 0,
                      "aria-label": "weight",
                      style: {
                        fontSize: "12px",
                      },
                    }}
                    onChange={handleChange}
                    name="title"
                    required
                    value={details["title"]}
                    placeholder="Enter value"
                  />
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper>
                  <Label>Channel*</Label>
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      displayEmpty
                      required
                      onChange={handleChange}
                      name="channel"
                      disableUnderline
                      variant="outlined"
                      value={details["channel"]}
                      style={{
                        width: "100%",
                        height: "36px",
                        fontSize: "12px",
                        borderRadius: "4px",
                        borderColor: "#C4C4C4",
                      }}
                    >
                      {leadInteractionChannelList.map((item, i) => {
                        return (
                          <MenuItem
                            value={item}
                            key={i}
                            style={{ fontSize: "12px" }}
                          >
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper>
                  <Label>Interacted On*</Label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CustomizedDateTimePicker
                      value={details.interacted_on}
                      onChange={(event) => {
                        setDetails({
                          ...details,
                          interacted_on: event,
                        });
                      }}
                      maxDateTime={moment()}
                      inputFormat="YYYY-MM-DD HH:mm A"
                      renderInput={(params) => (
                        <TextField
                          required
                          sx={{ width: "100%" }}
                          size="small"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper>
                  <Label>Comments</Label>
                  <OutlinedInput
                    multiline
                    rows={3}
                    inputProps={{
                      min: 0,
                      "aria-label": "weight",
                      style: {
                        fontSize: "12px",
                      },
                    }}
                    onChange={handleChange}
                    name="comments"
                    value={details["comments"]}
                    placeholder="Enter value"
                  />
                </InputWrapper>
              </Grid>
            </Grid>
          </InputContainer>

          <AppBar
            position="fixed"
            color="inherit"
            elevation={0}
            sx={{ top: "auto", bottom: 0, width: 500 }}
          >
            <Toolbar style={{ width: 500, position: "relative" }}>
              {isSubmitFail && (
                <ErrorAlert
                  onClose={(e) => {
                    e.stopPropagation();
                    setIsSubmitFail(false);
                  }}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  {errorMessage}
                </ErrorAlert>
              )}
              <ButtonWrapper>
                <Button
                  onClick={handleClose}
                  type="button"
                  variant="contained"
                  color="default"
                  style={{
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  Cancel
                </Button>
                {isSubmitting ? (
                  <LoadingButton
                    loading
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: "none",
                      width: "100%",
                    }}
                  >
                    <span>Submit</span>
                  </LoadingButton>
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    Submit
                  </Button>
                )}
              </ButtonWrapper>
            </Toolbar>
          </AppBar>
        </ModelWrapper>
      ) : (
        <MessageWrapper>
          <Message>Interaction Log created successfully!</Message>

          <Button
            onClick={handleClose}
            type="button"
            variant="contained"
            color="default"
            style={{
              marginTop: "20px",
              textTransform: "none",
              fontSize: "12px",
              fontWeight: "bold",
              width: "100px",
            }}
          >
            Close
          </Button>
        </MessageWrapper>
      )}
    </Box>
  );
};

export default CreateLeadInteractionComponent;
