import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import { Button } from "@material-ui/core";
import { styled } from "@mui/material/styles";

const Message = styled(Typography)(({ theme }) => ({
  font: "normal normal 400 16px Open Sans",
  color: "#4d4e4f",
}));
const ProgressWrapper = styled(Box)`
  width: 200px;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;
const MessageWithLoadingStateCustomDialog = ({
  successMessage,
  errorMessage,
  open,
  setOpen,
  loading,
  isError,
}) => {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {!loading ? (
        <>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {isError ? (
                <Message>{errorMessage}</Message>
              ) : (
                <Message>{successMessage}</Message>
              )}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              style={{
                backgroundColor: "#185DD2",
                textTransform: "capitalize",
                fontWeight: "bold",
                width: "100px",

                color: "white",
              }}
              onClick={() => {
                setOpen(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </>
      ) : (
        <ProgressWrapper>
          <CircularProgress />
        </ProgressWrapper>
      )}
    </Dialog>
  );
};
export default MessageWithLoadingStateCustomDialog;
