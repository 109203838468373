import { Box, OutlinedInput, Typography, styled } from "@mui/material";
import React from "react";
import { Grid } from "@material-ui/core";
import MDEditor, { commands } from "@uiw/react-md-editor";

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const AdditionalInfo = ({
  additionalInformation,
  handleChange,
  showAdditionalInformation,
  showTermsAndConditions,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid container spacing={2}>
        {showTermsAndConditions && (
          <Grid item xs={12}>
            <InputWrapper>
              <Label>Terms and Conditions</Label>
              <OutlinedInput
                multiline
                rows={3}
                inputProps={{
                  min: 0,
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                name="terms_and_conditions"
                value={additionalInformation["terms_and_conditions"]}
                placeholder="Enter value"
              />
            </InputWrapper>
          </Grid>
        )}
        {showAdditionalInformation && (
          <Grid item xs={12}>
            <Label>Additional Information</Label>
             <div className="container" data-color-mode="light">
              <MDEditor
                value={additionalInformation["additional_information"]}
                onChange={(value) => {
                  handleChange("additional_information", value);
                }}
                hideToolbar={false}
                commands={[
                  commands.bold,
                  commands.italic,
                  commands.strikethrough,
                  commands.hr,
                  commands.group([
                    commands.title1,
                    commands.title2,
                    commands.title3,
                    commands.title4,
                    commands.title5,
                    commands.title6,
                  ], {
                    name: "title",
                    groupName: "title",
                    buttonProps: { "aria-label": "Insert title" }
                  }),
                  commands.divider,
                  commands.link,
                  commands.quote,
                  commands.code,
                  commands.codeBlock,
                  commands.divider,
                  commands.unorderedListCommand,
                  commands.orderedListCommand,
                  commands.checkedListCommand,
                ]} 
                preview="edit"
              />
            </div>      
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default AdditionalInfo;
