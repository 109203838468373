import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Modal,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import rootStore from "../../stores/RootStore";
import { useInView } from "react-intersection-observer";
import { Close } from "@material-ui/icons";
import {
  acceptTermsOfServicesApi,
  getUnacceptedTermsOfServicesDetailsApi,
} from "../../Api";

const dialogWidth = 600;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
  padding-bottom: 0px;
`;

const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;

const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;

const Text = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  align-items: "center";
  justify-content: space-between;
  width: 100%;
`;

const TermsAndConditionsDialog = () => {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [termsHTML, setTermsHTML] = useState("");
  const [unAcceptedTOSDetails, setUnAcceptedTOSDetails] = useState({});
  const { ref, inView } = useInView({
    threshold: 0.5, // Intersection threshold
  });
  const fetchTermsHTML = async ({ url }) => {
    try {
      const response = await fetch(url);
      const html = await response.text();
      setTermsHTML(html);
    } catch (error) {
      console.error("Error fetching terms HTML:", error);
    }
  };

  const getUnacceptedTermsOfServicesDetails = async () => {
    let response = await getUnacceptedTermsOfServicesDetailsApi();
    return response;
  };

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);
  const init = async () => {
    let unacceptedTOSData = await getUnacceptedTermsOfServicesDetails();
    setUnAcceptedTOSDetails(unacceptedTOSData);
    await fetchTermsHTML({ url: unacceptedTOSData["tos_link"] });
    setLoading(false);
  };
  useEffect(() => {
    rootStore.userStore.updateLastViewedTermsAndConditionsDate();
    rootStore.authStore.updateLastActiveTime();
    init();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let tosID = unAcceptedTOSDetails.hasOwnProperty("id")
      ? unAcceptedTOSDetails["id"]
      : null;
    let response = await acceptTermsOfServicesApi({ tosID: tosID });
    setOpen(false);
  };
  const handleAccept = () => {
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handlePrint = () => {
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);
    const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
    iframeDoc.write(termsHTML);
    iframeDoc.close();
    iframe.contentWindow.print();
    document.body.removeChild(iframe);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModelContainer>
        {!loading ? (
          <Box component={"form"} onSubmit={handleSubmit}>
            <AppBar
              component={"nav"}
              elevation={0}
              position="sticky"
              color="inherit"
              sx={{
                top: 0,
                bottom: "auto",
                width: "100%",
                right: 0,
              }}
            >
              <Toolbar style={{ padding: "16px" }}>
                <HeaderWrapper>
                  <Header>Terms Of Service</Header>
                  <Close
                    color="primary"
                    style={{ cursor: "pointer" }}
                    onClick={handleClose}
                  />
                </HeaderWrapper>
              </Toolbar>
            </AppBar>
            <Divider sx={{ margin: "0px 16px" }} />
            <Container
              sx={{
                height: "350px",
                overflowY: "auto",
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: termsHTML }} />

              <p ref={ref} />
            </Container>
            <AppBar
              position="static"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: "100%" }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                }}
                sx={{
                  width: dialogWidth,
                  position: "relative",
                }}
              >
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!isVisible}
                      type="submit"
                      sx={{
                        fontSize: "12px",
                        textTransform: "none",
                        fontWeight: "bold",
                      }}
                    >
                      Accept
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={handlePrint}
                      type="button"
                      variant="outlined"
                      color="primary"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        width: "fit-content",
                        textTransform: "none",
                      }}
                    >
                      Print
                    </Button>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              width: dialogWidth,
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </ModelContainer>
    </Modal>
  );
};

export default TermsAndConditionsDialog;
