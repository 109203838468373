import React, { useState } from "react";
import styled from "styled-components";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect } from "react";
import { DatePicker } from "antd";
import "./style.css";
import spinner from "../../assets/images/gifs/spinner.gif";
import MultiselectDropdown from "../multiselect_dropdown/MultiselectDropdown";
import NewMultiselectDropdown from "../multiselect_dropdown/NewMultiselectDropdown";
import ChildMultiSelectDropdown from "../multiselect_dropdown/ChildMultiSelectDropdown";
import { sortAndAssignWidthColumnSetup } from "../../Functions";
import { Grid } from "@mui/material";
const Spinner = styled.img`
  height: 120px;
  width: 180px;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;
const Label = styled.span`
  font: normal normal normal 12px Open Sans;
  color: #4d4e4f;
`;
const Input = styled.input`
  width: 100%;
  height: 30px;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  color: #4d4e4f;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal normal 12px Open Sans;
    color: #b5b5b5;
    text-transform: lowercase;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;

const Row = styled.div`
  display: flex;
  /* width: 100%;
  margin-bottom: 10px; */
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  /* flex-direction: column; */
  /* justify-content: center; */
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: 320px;

  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

// this component is being used as global filter as well as to create target segement
const CampaignFilterComponent = (props) => {
  const { userStore } = rootStore;
  const { RangePicker } = DatePicker;
  const columnsList = userStore.AllColumnsList;

  const [allCols, setAllCols] = useState([]);

  const handleChange = ({ e, dataType }) => {
    let value =
      dataType === "integer" ? parseInt(e.target.value) : e.target.value;
    props.setFilters({
      ...props.filters,
      [e.target.name]: { value: value, type: dataType },
    });
  };

  useEffect(() => {
    let column_meta = [...columnsList];
    if (column_meta.length > 0) {
      column_meta = column_meta.filter(
        (e) =>
          e.field !== "lead_created_at" &&
          e.field !== "created_at" &&
          e.field !== "updated_at"
      );
    }
    let modified_list = sortAndAssignWidthColumnSetup(column_meta);
    setAllCols(modified_list);
  }, []);

  function truncate(str, n) {
    return str.length > n ? str.slice(0, n - 1) + "..." : str;
  }

  return (
    <>
      <Container>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Grid container spacing={2}>
            {allCols.length > 0 ? (
              allCols.map((item) => {
                if (item.filter_type === "normal" || item.filter_type === "") {
                  if (
                    (item.data_type === "date" ||
                      item.data_type === "datetime") &&
                    props.filters.hasOwnProperty(item.field)
                  ) {
                    let value = props.filters[item.field]["value"];
                    return (
                      <Grid item xs={item["width"] === 1 ? 6 : 12}>
                        <InputWrapper>
                          <Label>
                            {item["width"] === 1
                              ? truncate(item.headerName, 20)
                              : item.headerName}
                          </Label>
                          <Input
                            type={
                              item.data_type === "email"
                                ? "email"
                                : item.data_type === "datetime" ||
                                  item.data_type === "date"
                                ? "date"
                                : item.data_type === "integer"
                                ? "number"
                                : "text"
                            }
                            name={item.field}
                            value={value}
                            placeholder={`enter ${item.headerName}`}
                            //   style={{ width: "170px" }}
                            onChange={(e) => {
                              let dataType = item.data_type;
                              handleChange({ e, dataType });
                            }}
                            readOnly={props.fieldList.includes(item["field"])}
                          />
                        </InputWrapper>
                      </Grid>
                    );
                  }
                  return (
                    <Grid item xs={item["width"] === 1 ? 6 : 12}>
                      <InputWrapper>
                        <Label>
                          {item["width"] === 1
                            ? truncate(item.headerName, 20)
                            : item.headerName}
                        </Label>
                        <Input
                          type={
                            item.data_type === "email"
                              ? "email"
                              : item.data_type === "datetime" ||
                                item.data_type === "date"
                              ? "date"
                              : item.data_type === "integer"
                              ? "number"
                              : "text"
                          }
                          name={item.field}
                          value={
                            props.filters.hasOwnProperty(item.field)
                              ? props.filters[item.field]["value"]
                              : ""
                          }
                          placeholder={`enter ${item.headerName}`}
                          //   style={{ width: "170px" }}
                          onChange={(e) => {
                            let dataType = item.data_type;
                            handleChange({ e, dataType });
                          }}
                          readOnly={props.fieldList.includes(item["field"])}
                        />
                      </InputWrapper>
                    </Grid>
                  );
                } else if (item.filter_type === "range") {
                  if (
                    item.data_type === "datetime" ||
                    item.data_type === "date"
                  ) {
                    return (
                      <Grid item xs={item["width"] === 1 ? 6 : 12}>
                        <InputWrapper style={{ width: "100%" }}>
                          <Label>
                            {item["width"] === 1
                              ? truncate(item.headerName, 20)
                              : item.headerName}
                          </Label>
                          <RangePicker
                            placeholder={["From", "To"]}
                            getPopupContainer={(triggerNode) => {
                              return triggerNode.parentNode;
                            }}
                            disabled={props.fieldList.includes(item["field"])}
                            defaultValue={
                              props.dateRange.hasOwnProperty(item.field) && [
                                moment(
                                  props.dateRange[item["field"]]["start"],
                                  "YYYY-MM-DD"
                                ),
                                moment(
                                  props.dateRange[item["field"]]["end"],
                                  "YYYY-MM-DD"
                                ),
                              ]
                            }
                            style={{ height: "30px", width: "100%" }}
                            onChange={(value) => {
                              let d1 = moment(value[0]).format("YYYY-MM-DD");
                              let d2 = moment(value[1]).format("YYYY-MM-DD");
                              props.setDateRange({
                                ...props.dateRange,
                                [item.field]: { start: d1, end: d2 },
                              });
                            }}
                          />
                        </InputWrapper>
                      </Grid>
                    );
                  } else if (item.data_type === "integer") {
                    return (
                      <Grid item xs={item["width"] === 1 ? 6 : 12}>
                        <InputWrapper>
                          <Label>
                            {item["width"] === 1
                              ? truncate(item.headerName, 20)
                              : item.headerName}
                          </Label>
                          <Grid container spacing={2} item xs={12}>
                            <Grid item xs={6}>
                              <Input
                                type="number"
                                name="start"
                                placeholder="min"
                                value={
                                  props.filters.hasOwnProperty(item.field)
                                    ? props.filters[item.field]["value"][
                                        "start"
                                      ]
                                    : ""
                                }
                                onChange={(e) => {
                                  let start = parseInt(e.target.value);
                                  let end =
                                    props.filters.hasOwnProperty(item.field) &&
                                    props.filters[item.field]["value"][
                                      "end"
                                    ] !== ""
                                      ? parseInt(
                                          props.filters[item.field]["value"][
                                            "end"
                                          ]
                                        )
                                      : "";
                                  props.setFilters({
                                    ...props.filters,
                                    [item.field]: {
                                      value: { start: start, end },
                                      type: item.data_type,
                                    },
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Input
                                type="number"
                                name="end"
                                placeholder="max"
                                value={
                                  props.filters.hasOwnProperty(item.field)
                                    ? props.filters[item.field]["value"]["end"]
                                    : ""
                                }
                                disabled={
                                  !props.filters.hasOwnProperty(item.field) ||
                                  (props.filters.hasOwnProperty(item.field) &&
                                    props.filters[item.field]["value"][
                                      "start"
                                    ] === "")
                                }
                                onChange={(e) => {
                                  let start =
                                    props.filters.hasOwnProperty(item.field) &&
                                    props.filters[item.field]["value"][
                                      "start"
                                    ] !== ""
                                      ? parseInt(
                                          props.filters[item.field]["value"][
                                            "start"
                                          ]
                                        )
                                      : "";
                                  let end = parseInt(e.target.value);
                                  props.setFilters({
                                    ...props.filters,
                                    [item.field]: {
                                      value: { start: start, end: end },
                                      type: item.data_type,
                                    },
                                  });
                                }}
                              />
                            </Grid>
                          </Grid>
                        </InputWrapper>
                      </Grid>
                    );
                  }
                } else if (item.filter_type === "list") {
                  if (
                    item.filter_input.charAt(0) === "/" ||
                    item.filter_input.startsWith("ep:")
                  ) {
                    return (
                      <MultiselectDropdown
                        readOnly={props.fieldList.includes(item["field"])}
                        type="report"
                        headerName={item.headerName}
                        setSelectedVal={props.setSelectedVal}
                        selectedVal={props.selectedVal}
                        field={item.field}
                        item={item}
                      />
                    );
                  } else if (item.filter_input.charAt(0) !== "/") {
                    return (
                      <Grid item xs={item["width"] === 1 ? 6 : 12}>
                        <NewMultiselectDropdown
                          readOnly={props.fieldList.includes(item["field"])}
                          type="report"
                          list={item.filter_input}
                          field={item.field}
                          headerName={item.headerName}
                          setSelectedVal={props.setSelectedVal}
                          selectedVal={props.selectedVal}
                          item={item}
                        />
                      </Grid>
                    );
                  }
                }
              })
            ) : (
              <SpinnerWrapper>
                <Spinner src={spinner} alt="loading..." />
              </SpinnerWrapper>
            )}
          </Grid>
        </Form>
      </Container>
    </>
  );
};

export default observer(CampaignFilterComponent);
