import React from "react";
import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";
import logo_placeholder from "../../../../assets/logo_placeholder.png";

const Wrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "100%",
    justifyContent: "center",
  },
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(3),
  backgroundColor: "#1592E6",
  height: "fit-content",
}));

const IconTextWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  alignItems: "center",
}));
const TextWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  alignItems: "center",
}));
const Title = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  component: "h1",
  width: "100%",

  color: "#ffffff",

  [[theme.breakpoints.between("xs", "sm")]]: {
    font: "normal normal 600 14px open sans",
  },
  [[theme.breakpoints.between("sm", "md")]]: {
    font: "normal normal 600 18px open sans",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    font: "normal normal 600 22px open sans",
  },
  [theme.breakpoints.up("lg")]: {
    font: "normal normal 600 28px open sans",
  },
}));

const Logo = styled("img")(({ theme }) => ({
  maxHeight: "60px",
  width: "auto",
  [[theme.breakpoints.between("xs", "md")]]: {
    maxWidth: "45%",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    maxWidth: "45%",
  },
  [theme.breakpoints.up("lg")]: {
    maxWidth: "40%",
  },
}));
const SubTitle = styled(Typography)(({ theme }) => ({
  paddingTop: 0,
  component: "span",
  color: "#ffffff",
  textAlign: "center",
  width: "80%",
  [[theme.breakpoints.between("xs", "sm")]]: {
    font: "normal normal 500 10px open sans",
    component: "span",
  },
  [[theme.breakpoints.between("sm", "md")]]: {
    font: "normal normal 500 12px open sans",
    component: "span",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    font: "normal normal 500 14px open sans",
    component: "span",
  },
  [theme.breakpoints.up("lg")]: {
    font: "normal normal 500 16px open sans",
    component: "span",
  },
}));
const TopSection = ({ logoURL, contentState }) => {
  return (
    <Wrapper>
      <IconTextWrapper>
        <Logo src={logoURL !== "" ? logoURL : logo_placeholder} alt="logo" />
        <TextWrapper>
          <Title>{contentState.title}</Title>
          <SubTitle>{contentState.sub_title}</SubTitle>
        </TextWrapper>
      </IconTextWrapper>
    </Wrapper>
  );
};

export default TopSection;
