import React from "react";
import { FormControl, Modal, Select, makeStyles } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { Box, Button, Divider, MenuItem, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { myTheme } from "../../../themeUtils";
import { useState } from "react";
import rootStore from "../../../stores/RootStore";
import { useEffect } from "react";
const ModalContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 16px;
  margin: auto;
  height: fit-content;
  width: 264px;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 20px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  column-gap: 10px;
  margin-top: 20px;
`;
const Label = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const LoadFilterModal = (props) => {
  const [seletedTemplateName, setSelectedTemplateName] = useState("");
  const [selectedTemplateData, setSelectedTemplateData] = useState({});
  const classes = useStyles();
  const projectId = rootStore.authStore.projectId;
  const handleLoad = () => {
    rootStore.userStore.updateCurrentLoadedFilter(selectedTemplateData);
    rootStore.userStore.updateCurrentFilterRenderList(
      selectedTemplateData["filters"]
    );
    rootStore.userStore.updateCurrentFilterViewId(
      selectedTemplateData["viewId"]
    );
    props.updateFilterSelectionList(selectedTemplateData["viewId"]);
    props.setOpen(false);
  };
  useEffect(() => {
    if (rootStore.userStore.currentLoadedFilter.hasOwnProperty("name")) {
      setSelectedTemplateName(rootStore.userStore.currentLoadedFilter["name"]);
      setSelectedTemplateData(rootStore.userStore.currentLoadedFilter);
    }
  }, []);
  return (
    <Modal
      open={props.open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContainer>
        <Wrapper
          component={"form"}
          onSubmit={(e) => {
            e.preventDefault();
            handleLoad();
          }}
        >
          <Header>Load Filter</Header>
          <Divider style={{ margin: "10px 0px" }} />
          <InputContainer>
            <InputWrapper>
              <Label>Select Filter</Label>
              <FormControl
                classes={{
                  root: classes.quantityRoot,
                }}
              >
                <Select
                  classes={{
                    icon: classes.icon,
                  }}
                  value={seletedTemplateName}
                  displayEmpty
                  disableUnderline
                  variant="outlined"
                  onChange={(e) => {
                    let temp_data =
                      rootStore.userStore.savedFilterTemplateList[projectId][
                        e.target.value
                      ];
                    setSelectedTemplateName(e.target.value);
                    setSelectedTemplateData(temp_data);
                  }}
                  required
                  style={{
                    width: "100%",
                    height: "30px",
                    fontSize: "12px",
                    fontWeight: "bold",
                    borderRadius: "0px",
                  }}
                >
                  {rootStore.userStore.savedFilterTemplateList.hasOwnProperty(
                    projectId
                  ) &&
                    Object.keys(
                      rootStore.userStore.savedFilterTemplateList[projectId]
                    ).map((item, i) => {
                      let data =
                        rootStore.userStore.savedFilterTemplateList[projectId][
                          item
                        ];
                      return (
                        <MenuItem id={i} key={i} value={data["name"]}>
                          {data["name"]}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </InputWrapper>
          </InputContainer>
          <ButtonWrapper>
            <ButtonWrapper>
              <Button
                onClick={() => {
                  props.setOpen(false);
                }}
                variant="contained"
                color="inherit"
                style={{
                  font: "normal normal 600 10px Open Sans",
                  textTransform: "none",
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                style={{
                  font: "normal normal 600 10px Open Sans",
                  width: "fit-content",
                  textTransform: "none",
                  ...myTheme.Button.btnBlue,
                }}
              >
                Load
              </Button>
            </ButtonWrapper>
          </ButtonWrapper>
        </Wrapper>
      </ModalContainer>
    </Modal>
  );
};

export default observer(LoadFilterModal);
