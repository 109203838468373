import React, { useState } from "react";
import styled from "styled-components";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { Box, Modal } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import moment from "moment";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { DatePicker } from "antd";
import "./style.css";
import { toJS } from "mobx";
import spinner from "../../assets/images/gifs/spinner.gif";
import NewMultiselectDropdown from "../multiselect_dropdown/NewMultiselectDropdown";
import MultiselectDropdown from "../multiselect_dropdown/MultiselectDropdown";
import { myTheme } from "../../themeUtils";
const Spinner = styled.img`
  height: 120px;
  width: 180px;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;
const Title = styled.span`
  font: normal normal 600 24px Open Sans;
  text-align: center;
`;
const Label = styled.span`
  font: normal normal normal 12px Open Sans;
  color: black;
`;
const Input = styled.input`
  width: 100%;
  height: 30px;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  color: #4d4e4f;
  font: normal normal 600 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal normal 12px Open Sans;
    color: #b5b5b5;
    text-transform: lowercase;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;

const Row = styled.div`
  display: flex;
  /* width: 100%;
  margin-bottom: 10px; */
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;
  /* padding-right: 12px; */
`;
const InputContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 20px;
  max-height: 60vh;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 5px;
    background-color: #efeff4;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #999;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #666;
  }
  @media (max-width: 700px) {
    max-width: 380px;
  }
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 178px;
  overflow: hidden;
`;
const ModelContainer = styled.div`
  position: absolute;
  top: 40px;
  left: 0px;
  right: 0px;
  margin: auto;
  max-height: 60vh;
  /* overflow-y: auto; */
  width: 100%;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 0px 0px 8px 8px;
  padding: 20px;
  border: 1px solid #c5d7f1;
  border-top: none;
  transition: all 0.2s ease;
`;

const Form = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const MainFilterModal = (props) => {
  const { authStore, userStore } = rootStore;
  const { RangePicker } = DatePicker;
  const [allCols, setAllCols] = useState([]);
  const [dateRange, setDateRange] = useState({});
  const [numRange, setNumRange] = useState({});
  const [selectedVal, setSelectedVal] = useState({});
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  let pid = authStore.projectId;
  let tabId = userStore.TabId;
  const handleClick = () => {
    props.setOpen(false);
  };
  const reArrangeColumns = (list) => {
    let orgList = [];
    let dropDownFiltersList = [];
    let rangeDataList = [];
    if (list.length > 0) {
      list.map((item) => {
        if (item.filterable) {
          if (item.filter_type === "range") {
            rangeDataList.push(item);
          } else if (item.filter_type === "list") {
            dropDownFiltersList.push(item);
          } else {
            orgList.push(item);
          }
        }
      });
    }
    dropDownFiltersList = dropDownFiltersList.concat(orgList);
    if (rangeDataList.length > 0) {
      if (dropDownFiltersList.length % 3 === 1) {
        let popped_val = rangeDataList.shift();
        popped_val["target"] = true;
        rangeDataList.unshift(popped_val);
      }
    }
    dropDownFiltersList = dropDownFiltersList.concat(rangeDataList);
    return dropDownFiltersList;
  };
  const handleChange = ({ e, dataType }) => {
    let value =
      dataType === "integer" ? parseInt(e.target.value) : e.target.value;
    setFilters({
      ...filters,
      [e.target.name]: { value: value, type: dataType },
    });
  };
  const handleClose = () => {
    props.setOpen(false);
  };
  const handleFilter = () => {
    userStore.updatePaging({ id: tabId, page: 0 });
    userStore.setTabFilters({
      id: tabId,
      filters: filters,
      dateRange: dateRange,
      numRange: numRange,
      selectedVal: selectedVal,
    });

    props.setOpen(false);
  };
  useEffect(() => {
    setLoading(true);
    const getAllCols = async () => {
      let searchCols = toJS(userStore.searchColumns);
      let totalCols = [...rootStore.userStore.AllColumnsList];
      if (totalCols.length > 0) {
        let newCols = [];
        totalCols.map((item) => {
          if (searchCols.includes(item.field)) {
            newCols.push(item);
          }
        });
        let result = reArrangeColumns(newCols);
        setAllCols(result);
      }
      setLoading(false);
    };
    getAllCols();
  }, []);
  function truncate(str, n) {
    return str.length > n ? str.slice(0, n - 1) + "..." : str;
  }
  return (
    <>
      <ModelContainer>
        <Box>
          <Container>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <InputContainer>
                {!loading ? (
                  allCols.length > 0 ? (
                    allCols.map((item) => {
                      if (item.filter_type === "normal") {
                        return (
                          <InputWrapper>
                            <Label>{truncate(item.headerName, 20)}</Label>
                            <Input
                              type={
                                item.data_type === "email"
                                  ? "email"
                                  : item.data_type === "datetime" ||
                                    item.data_type === "date"
                                  ? "date"
                                  : item.data_type === "integer"
                                  ? "number"
                                  : "text"
                              }
                              name={item.field}
                              placeholder={`enter ${item.headerName}`}
                              onChange={(e) => {
                                let dataType = item.data_type;
                                handleChange({ e, dataType });
                              }}
                              onKeyUp={(e) =>
                                e.key === "Enter" ? handleFilter() : null
                              }
                            />
                          </InputWrapper>
                        );
                      } else if (item.filter_type === "range") {
                        if (
                          item.data_type === "datetime" ||
                          item.data_type === "date"
                        ) {
                          return (
                            <InputWrapper
                              style={{
                                width: item.hasOwnProperty("target")
                                  ? "378px"
                                  : "278px",
                              }}
                            >
                              <Label>{truncate(item.headerName, 20)}</Label>
                              <RangePicker
                                placeholder={["From", "To"]}
                                getPopupContainer={(triggerNode) => {
                                  return triggerNode.parentNode;
                                }}
                                style={{
                                  height: "30px",
                                  width: item.hasOwnProperty("target")
                                    ? "378px"
                                    : "278px",
                                }}
                                onChange={(value) => {
                                  let d1 = moment(value[0]).format(
                                    "YYYY-MM-DD"
                                  );
                                  let d2 = moment(value[1]).format(
                                    "YYYY-MM-DD"
                                  );
                                  setDateRange({
                                    ...dateRange,
                                    [item.field]: { start: d1, end: d2 },
                                  });
                                }}
                              />
                            </InputWrapper>
                          );
                        } else if (item.data_type === "integer") {
                          return (
                            <InputWrapper>
                              <Label>{truncate(item.headerName, 20)}</Label>
                              <Row>
                                <Input
                                  type="number"
                                  name="start"
                                  placeholder="min"
                                  onChange={(e) => {
                                    let start = parseInt(e.target.value);
                                    setNumRange({
                                      ...numRange,
                                      [item.field]: { start: start },
                                    });
                                  }}
                                  style={{
                                    width: "70px",
                                    marginRight: "10px",
                                  }}
                                  onKeyUp={(e) =>
                                    e.key === "Enter" ? handleFilter() : null
                                  }
                                />
                                <Input
                                  type="number"
                                  name="end"
                                  placeholder="max"
                                  onChange={(e) => {
                                    let start = parseInt(
                                      numRange[item.field]["start"]
                                    );
                                    let end = parseInt(e.target.value);
                                    setNumRange({
                                      ...numRange,
                                      [item.field]: {
                                        start: start,
                                        end: end,
                                      },
                                    });
                                  }}
                                  style={{ width: "70px" }}
                                  onKeyUp={(e) =>
                                    e.key === "Enter" ? handleFilter() : null
                                  }
                                />
                              </Row>
                            </InputWrapper>
                          );
                        }
                      } else if (item.filter_type === "list") {
                        if (item.filter_input.charAt(0) === "/") {
                          let ep = item.filter_input.slice(1);
                          return (
                            <MultiselectDropdown
                              ep={ep}
                              headerName={item.headerName}
                              setSelectedVal={setSelectedVal}
                              selectedVal={selectedVal}
                              field={item.field}
                              type={"global"}
                            />
                          );
                        } else if (
                          item.filter_input !== "" &&
                          item.filter_input.charAt(0) !== "/"
                        ) {
                          return (
                            <NewMultiselectDropdown
                              list={item.filter_input}
                              field={item.field}
                              headerName={item.headerName}
                              setSelectedVal={setSelectedVal}
                              selectedVal={selectedVal}
                              type={"global"}
                            />
                          );
                        } else if (
                          item.filter_input === "" ||
                          item.filter_input === null
                        ) {
                          return (
                            <InputWrapper>
                              <Label>{truncate(item.headerName, 20)}</Label>
                              <Input
                                type={
                                  item.data_type === "email"
                                    ? "email"
                                    : item.data_type === "datetime" ||
                                      item.data_type === "date"
                                    ? "date"
                                    : item.data_type === "integer"
                                    ? "number"
                                    : "text"
                                }
                                name={item.field}
                                placeholder={`enter ${item.headerName}`}
                                //   style={{ width: "170px" }}
                                onChange={(e) => {
                                  let dataType = item.data_type;
                                  handleChange({ e, dataType });
                                }}
                              />
                            </InputWrapper>
                          );
                        }
                      }
                    })
                  ) : (
                    <SpinnerWrapper>
                      <Label
                        style={{
                          font: "normal normal 600 14px Open Sans",
                          color: "GrayText",
                          textAlign: "center",
                        }}
                      >
                        No Search Columns found! Please contact your
                        administrator
                      </Label>
                    </SpinnerWrapper>
                  )
                ) : (
                  <SpinnerWrapper>
                    <Spinner src={spinner} alt="loading..." />
                  </SpinnerWrapper>
                )}
              </InputContainer>
              {allCols.length > 0 && (
                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      props.setOpen(false);
                    }}
                    style={{
                      width: "fit-content",
                      fontSize: "12px",
                      fontWeight: "bold",
                      backgroundColor: "white",
                      color: myTheme.Button.colorBlue,
                    }}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={allCols.length === 0}
                    onClick={handleFilter}
                    color="primary"
                    variant="contained"
                    style={{
                      width: "fit-content",
                      fontSize: "12px",
                      fontWeight: "bold",
                      ...myTheme.Button.btnBlue,
                    }}
                  >
                    Search
                  </Button>
                </ButtonWrapper>
              )}
            </Form>
          </Container>
        </Box>
      </ModelContainer>
    </>
  );
};

export default observer(MainFilterModal);
