import {
  Card,
  CardActionArea,
  Checkbox,
  CircularProgress,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import React, { useState } from "react";
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  text-align: center;
`;
const ImageBox = styled("img")`
  max-height: 60px;
  width: auto;
  object-fit: contain;
`;
const TemplateCard = ({ details, template, handleClick }) => {
  const [loading, setLoading] = useState(true);
  return (
    <Card
      sx={{
        border: "1px solid #f9f9fc",
        bgcolor: "#f9f9fc",
      }}
      onClick={handleClick}
    >
      <CardActionArea
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "8px",
          rowGap: "4px",
          position: "relative",
          minHeight: "80px",
        }}
      >
        <Checkbox
          checked={details["template"] === template["value"]}
          size="small"
          style={{ position: "absolute", top: "-10px", left: "-10px" }}
        />
        <ImageBox
          src={template.img}
          onLoad={() => {
            setLoading(false);
          }}
          alt="loading..."
        />
        {loading && (
          <CircularProgress
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: "auto",
              width: "20px",
              height: "20px",
            }}
          />
        )}
        <Label>{template.label}</Label>
      </CardActionArea>
    </Card>
  );
};

export default TemplateCard;
