import * as React from "react";
import Dialog from "@mui/material/Dialog";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PreviewTemplate({
  open,
  setOpen,
  Component,
  contentState,
  formState,
  primaryColor,
  secondaryColor,
  logoURL,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const getTheme = ({ primaryColor, secondaryColor }) => {
    const theme = createTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
        },
      },
      palette: {
        primary: {
          main: primaryColor,
        },
        secondary: {
          main: secondaryColor,
        },
      },
      typography: {
        fontFamily: "Roboto",
      },
    });
    return theme;
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <IconButton
        style={{
          position: "fixed",
          top: "12px",
          right: "20px",
          zIndex: "99999",
        }}
        onClick={handleClose}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
      <List style={{ padding: "0px" }}>
        <ThemeProvider
          theme={() =>
            getTheme({
              primaryColor: primaryColor,
              secondaryColor: secondaryColor,
            })
          }
        >
          <Component
            contentState={contentState}
            formState={formState}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            logoURL={logoURL}
          />
        </ThemeProvider>
      </List>
    </Dialog>
  );
}
