import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
  TextField,
} from "@mui/material";

import React, { useEffect, useState } from "react";

const RadioGroupWithOtherOptionWidget = ({
  list,
  type,
  handleChange,
  value,
  fieldData,
  disabled,
}) => {
  const [otherField, setOtherField] = useState("");
  useEffect(() => {
    if (value === "other_value") {
      if (fieldData.hasOwnProperty("other_value")) {
        setOtherField(fieldData["other_value"]);
      }
    }
  }, []);
  if (type === "list") {
    return (
      <RadioGroup>
        {list.map((str, index) => {
          return (
            <FormControlLabel
              disabled={disabled}
              key={index}
              value={str}
              checked={str === value}
              control={<Radio />}
              label={str}
              onChange={(event) => handleChange(event.target.value, otherField)}
            />
          );
        })}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "20px",
            width: "100%",
          }}
        >
          <FormControlLabel
            disabled={disabled}
            value={"other_value"}
            checked={value === "other_value"}
            control={<Radio />}
            label={"Other"}
            onChange={(event) => handleChange(event.target.value, otherField)}
          />
          {value === "other_value" && (
            <TextField
              disabled={disabled}
              required
              value={otherField}
              onChange={(e) => setOtherField(e.target.value)}
              variant="standard"
              style={{ width: "100%" }}
              placeholder="Enter value"
              onBlur={() => {
                handleChange(value, otherField);
              }}
            />
          )}
        </Box>
      </RadioGroup>
    );
  } else {
    return (
      <RadioGroup>
        {list.map((obj, index) => {
          return (
            <FormControlLabel
              disabled={disabled}
              key={index}
              value={obj.value}
              checked={obj.value === value}
              control={<Radio />}
              label={obj.name}
              onChange={(event) => handleChange(event.target.value, otherField)}
            />
          );
        })}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "20px",
            width: "100%",
          }}
        >
          <FormControlLabel
            disabled={disabled}
            value={"other_value"}
            control={<Radio />}
            checked={value === "other_value"}
            label={"Other"}
            onChange={(event) => handleChange(event.target.value, otherField)}
          />
          {value === "other_value" && (
            <TextField
              disabled={disabled}
              required
              value={otherField}
              onChange={(e) => setOtherField(e.target.value)}
              variant="standard"
              style={{ width: "100%" }}
              placeholder="Enter value"
              onBlur={() => {
                handleChange(value, otherField);
              }}
            />
          )}
        </Box>
      </RadioGroup>
    );
  }
};

export default RadioGroupWithOtherOptionWidget;
