import React, { useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { Box, Button, Modal } from "@material-ui/core";
import { myTheme } from "../../../themeUtils";
import { useEffect } from "react";
import { Alert } from "@mui/material";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled.span`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
  margin-bottom: 20px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
`;
const Label = styled.span`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Detail = styled.span`
  font: normal normal 500 14px Open Sans;
  color: #4d4e4f;
`;

const ButtonWrapper = styled.div`
  /* margin-top: 30px; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const MemberDetailModal = (props) => {
  const [data, setData] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const handleClose = () => {
    props.setOpen(false);
    setShowAlert(false);
  };
  const handleClick = () => {
    props.setOpenFinal(true);
    props.setOpen(false);
  };
  useEffect(() => {
    setData(props.data.user);
    if (Object.keys(props.data.project_member).length > 0) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, []);
  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <Container>
              <Header>Member Details</Header>
              {data !== null && (
                <Form>
                  <InputContainer>
                    <InputWrapper>
                      <Label>Name</Label>
                      <Detail>{data.name}</Detail>
                    </InputWrapper>
                    <InputWrapper>
                      <Label>Email</Label>
                      <Detail>{data.email}</Detail>
                    </InputWrapper>
                    <InputWrapper>
                      <Label>Phone</Label>
                      <Detail>{data.phone}</Detail>
                    </InputWrapper>
                  </InputContainer>
                  {!showAlert ? (
                    <ButtonWrapper>
                      <Button
                        type="button"
                        variant="contained"
                        id="call-log-btn"
                        style={{
                          textTransform: "none",
                          width: "100%",
                          fontSize: "12px",
                          fontWeight: "bold",
                          ...myTheme.Button.btnBlue,
                        }}
                        onClick={handleClick}
                      >
                        Add user to existing project
                      </Button>
                    </ButtonWrapper>
                  ) : (
                    <>
                      <Alert
                        style={{
                          width: "100%",
                          padding: "0px 10px",
                          fontSize: "14px",
                          textAlign: "center",
                        }}
                        severity="warning"
                      >
                        This user already exist.
                      </Alert>
                      <Button
                        type="button"
                        variant="contained"
                        id="call-log-btn"
                        style={{
                          textTransform: "none",
                          margin: "auto",
                          marginTop: "10px",
                          width: "fit-content",

                          fontSize: "12px",
                          fontWeight: "bold",
                          ...myTheme.Button.btnBlue,
                        }}
                        onClick={handleClose}
                      >
                        Ok
                      </Button>
                    </>
                  )}
                </Form>
              )}
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default observer(MemberDetailModal);
