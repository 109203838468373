import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { Box, TextField, styled } from "@mui/material";
const CustomizedDatePicker = styled(DatePicker)`
  & .MuiInputBase-input {
    font-size: 14px;
  }
`;
const DatePickerWidget = ({ value, handleChange }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CustomizedDatePicker
          label="Pick Date"
          value={value}
          onChange={(event) => {
            handleChange(event);
          }}
          inputFormat="YYYY-MM-DD"
          renderInput={(params) => (
            <TextField sx={{ width: "100%" }} size="small" {...params} />
          )}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default DatePickerWidget;
