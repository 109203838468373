import { AddBoxOutlined } from "@material-ui/icons";
import { Box, Chip, IconButton, OutlinedInput } from "@mui/material";

import React, { useState } from "react";

const MultiInputWidget = ({ handleAdd, value, handleDelete, type }) => {
  const [input, setInput] = useState("");
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
        <OutlinedInput
          style={{ height: "30px", width: "100%" }}
          inputProps={{
            min: 0,
            "aria-label": "weight",
            style: {
              fontSize: "12px",
            },
          }}
          value={input}
          type={type}
          placeholder={"Enter Value"}
          onChange={(e) => {
            setInput(e.target.value);
          }}
        />
        <IconButton
          onClick={() => {
            handleAdd(input);
            setInput("");
          }}
          disabled={input === ""}
          color="primary"
          style={{ padding: "0px" }}
        >
          <AddBoxOutlined style={{ width: "30px", height: "auto" }} />
        </IconButton>
      </Box>
      {value.length > 0 && (
        <Box
          sx={{
            display: "flex",
            padding: "6px 0px",
            gap: "4px",
            flexWrap: "wrap",
            width: "100%",
            maxHeight: "60px",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "0",
            },
          }}
        >
          {value.map((item, index) => {
            return (
              <Chip
                key={index}
                onDelete={() => handleDelete(item)}
                label={item}
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default MultiInputWidget;
