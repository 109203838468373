import { AddBoxOutlined, Edit } from "@material-ui/icons";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getAdminSourcesApi, getTeamApi } from "../../Api";
import spinner from "../../assets/images/gifs/spinner.gif";
import { AdminSourceColumns, TeamSettingColumns } from "../../Db";
import AddSourceModal from "../admin_setting_modals/AddSourceModal";
import AddTeamModal from "../admin_setting_modals/AddTeamModal";
import analytics from "../../Analytics";
const Spinner = styled.img`
  height: 120px;
  width: 180px;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;
const Container = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 234px);`};

  position: relative;
  /* margin-top: 70px; */
`;
const Wrapper = styled.div`
  display: flex;
`;

const DetailsWrapper = styled.div`
  height: fit-content;
  width: 100%;
  background: white;
  border-radius: 10px;
  /* padding: 12px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;
const TableWrapper = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
`;
const AddBtnWrapper = styled.div`
  position: absolute;
  right: 0px;
  top: 9px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
  @media (max-width: 700px) {
    position: unset;
    margin-left: 12px;
  }
`;
const Text = styled.p`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;

const SourcesTab = (props) => {
  const [row, setRow] = useState();
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [editableData, setEditableData] = useState(null);
  let navigate = useNavigate();
  useEffect(() => {
    const getSourcesList = async () => {
      try {
        let response = await getAdminSourcesApi(props.id);
        let newData = [];
        if (response.data.length > 0) {
          newData = response.data.sort(function (a, b) {
            if (a.default > b.default) {
              return -1;
            }
            if (a.default < b.default) {
              return 1;
            }
            return 0;
          });
        }
        setRow(newData);
      } catch (error) {
        console.log(error);
      }
    };
    getSourcesList();
  }, [refresh]);
  const actionColumn = [
    {
      field: "action",
      headerName: <Text>Actions</Text>,
      sortable: false,
      width: 70,
      renderCell: (params) => {
        if (params.row.default) {
          return (
            <>
              <Edit
                onClick={(e) => {}}
                style={{ marginRight: "20px", width: "18px", height: "18px" }}
                color="disabled"
              />
            </>
          );
        } else {
          return (
            <>
              <Edit
                onClick={(e) => {
                  analytics.triggerEvent(
                    4625000194,
                    "edit_button_tap",
                    "project_sources_page",
                    "edit_button",
                    {}
                  );
                  setEditableData(params.row);
                  setIsEdit(true);
                  setOpen(true);
                }}
                style={{ marginRight: "20px", width: "18px", height: "18px" }}
                color="primary"
              />
            </>
          );
        }
      },
    },
  ];
  return (
    <>
      <Container>
        <AddBtnWrapper>
          <AddBoxOutlined
            onClick={() => {
              analytics.triggerEvent(
                4625000190,
                "add_button_tap",
                "project_sources_page",
                "add_button",
                {}
              );
              setOpen(true);
            }}
            style={{
              width: "40px",
              height: "40px",
            }}
          />
        </AddBtnWrapper>
        <Wrapper>
          <DetailsWrapper>
            <TableWrapper>
              {row ? (
                <DataGrid
                  autoHeight={true}
                  rows={row}
                  columns={AdminSourceColumns.concat(actionColumn)}
                  checkboxSelection={false}
                  style={{ cursor: "pointer" }}
                  hideFooter={true}
                  disableColumnMenu={true}
                />
              ) : (
                <SpinnerWrapper>
                  <Spinner src={spinner} alt="loading..." />
                </SpinnerWrapper>
              )}
            </TableWrapper>
          </DetailsWrapper>
        </Wrapper>
      </Container>
      {open && (
        <AddSourceModal
          id={props.id}
          setOpen={setOpen}
          open={open}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          editableData={editableData}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}
    </>
  );
};

export default SourcesTab;
