import { Box, Card, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { observer } from "mobx-react-lite";
import TextFieldWidget from "./TextFieldWidget";
import RenderOptionFields from "./RenderOptionFields";
import { optionFieldsList } from "../../../Db";
import DateFieldWidget from "./DateFieldWidget";
import DatetimeFieldWidget from "./DatetimeFieldWidget";
import FileUploadWidget from "./FileUploadWidget";
import NumberFieldWidget from "./NumberFieldWidget";
import moment from "moment";
import { Delete, Edit } from "@material-ui/icons";
const Text = styled(Typography)`
  font: normal normal 400 16px Open Sans;
  color: #4d4e4f;
`;
const InnerWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  row-gap: 20px;
`;

const QuestionCard = ({
  questionData,
  columnMeta,
  inputData,
  setInputData,
  listFieldInputMeta,
  handleQuestionEdit,
  handleQuestionDelete,
}) => {
  const getRegex = () => {
    if (columnMeta.regex === null || columnMeta.regex === "") {
      return null;
    } else {
      return columnMeta.regex;
    }
  };

  const getMomentDate = (dateString) => {
    if (dateString !== "" && dateString !== null) {
      return moment(dateString).local();
    }
    return null;
  };

  const renderField = () => {
    if (questionData["question_type"] === "string") {
      return (
        <TextFieldWidget
          value={
            inputData.hasOwnProperty(columnMeta["field"])
              ? inputData[columnMeta["field"]]["value"]
              : ""
          }
          regex={getRegex()}
          handleChange={(event) => {
            setInputData({
              ...inputData,
              [columnMeta["field"]]: {
                value: event.target.value,
                type: questionData["question_type"],
              },
            });
          }}
        />
      );
    } else if (optionFieldsList.includes(questionData["question_type"])) {
      return (
        <RenderOptionFields
          columnMeta={columnMeta}
          questionData={questionData}
          inputData={inputData}
          setInputData={setInputData}
          listFieldInputMeta={listFieldInputMeta}
        />
      );
    } else if (questionData["question_type"] === "date") {
      return (
        <DateFieldWidget
          value={
            inputData.hasOwnProperty(columnMeta["field"])
              ? inputData[columnMeta["field"]]["value"]
              : null
          }
          min={getMomentDate(columnMeta["min"])}
          max={getMomentDate(columnMeta["max"])}
          handleChange={(event) => {
            setInputData({
              ...inputData,
              [columnMeta["field"]]: {
                value: event,
                type: questionData["question_type"],
              },
            });
          }}
        />
      );
    } else if (questionData["question_type"] === "datetime") {
      return (
        <DatetimeFieldWidget
          value={
            inputData.hasOwnProperty(columnMeta["field"])
              ? inputData[columnMeta["field"]]["value"]
              : null
          }
          min={getMomentDate(columnMeta["min"])}
          max={getMomentDate(columnMeta["max"])}
          handleChange={(event) => {
            setInputData({
              ...inputData,
              [columnMeta["field"]]: {
                value: event,
                type: questionData["question_type"],
              },
            });
          }}
        />
      );
    } else if (questionData["question_type"] === "file") {
      return (
        <FileUploadWidget
          value={
            inputData.hasOwnProperty(columnMeta["field"])
              ? inputData[columnMeta["field"]]["value"]
              : null
          }
          handleChange={(event) => {
            setInputData({
              ...inputData,
              [columnMeta["field"]]: {
                value: event.target.files[0],
                type: questionData["question_type"],
              },
            });
          }}
        />
      );
    } else if (questionData["question_type"] === "number") {
      return (
        <NumberFieldWidget
          value={
            inputData.hasOwnProperty(columnMeta["field"])
              ? inputData[columnMeta["field"]]["value"]
              : ""
          }
          handleChange={(event) => {
            setInputData({
              ...inputData,
              [columnMeta["field"]]: {
                value: event.target.value,
                type: questionData["question_type"],
              },
            });
          }}
          regex={getRegex()}
        />
      );
    }
  };

  return (
    <Card
      sx={{
        boxShadow: "none",
        border: "1px solid rgb(218, 220, 224)",

        borderRadius: "12px",
      }}
    >
      <InnerWrapper id={questionData["id"]}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
            justifyContent: "space-between",
          }}
        >
          <Text>
            {questionData["required"]
              ? `${questionData["question"]}*`
              : questionData["question"]}
          </Text>
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Tooltip title={"Edit Question"}>
              <Edit
                color="primary"
                style={{ cursor: "pointer", width: "20px", height: "20px" }}
                onClick={handleQuestionEdit}
              />
            </Tooltip>
            <Tooltip title={"Delete Question"}>
              <Delete
                color="error"
                style={{ cursor: "pointer", width: "20px", height: "20px" }}
                onClick={handleQuestionDelete}
              />
            </Tooltip>
          </Box>
        </Box>
        {Object.keys(columnMeta).length > 0 ? (
          renderField()
        ) : (
          <Text>No field found.</Text>
        )}
      </InnerWrapper>
    </Card>
  );
};

export default observer(QuestionCard);
