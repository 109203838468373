import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Toolbar,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  dataRenderer,
  getLocalizedText,
  IsoToLocalTime,
} from "../../Functions";
import { Typography } from "@mui/material";
import { Info } from "@material-ui/icons";
import analytics from "../../Analytics";
import MDEditor from "@uiw/react-md-editor";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  ${`width: calc(50vw - 32px);`}
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
  max-height: 60vh;
  overflow-y: auto;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 500 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  align-items: center;
`;
const NoteDetailsModal = (props) => {
  const handleClose = () => {
    props.setOpen(false);
  };

  useEffect(() => {
    analytics.triggerEvent(
      4625000072,
      "note_information_form_load",
      "notes_tab",
      "",
      {}
    );
  }, []);

  const noteTextGetter = (raw_string) => {
    return raw_string.replace(/\r\n|\n/g, "\n");
  };

  const Mention = ({ children }) => {
    return (
      <span style={{ color: "#0969DA", fontWeight: "bold" }}>{children}</span>
    );
  };
  const RenderNotes = () => {
    if (props.data["version"] === 2) {
      return (
        <div className="container" data-color-mode="light">
          <MDEditor.Markdown
            source={props.data.note}
            components={{
              mention: Mention,
            }}
          />
        </div>
      );
    } else {
      return (
        <Detail
          component="div"
          style={{
            whiteSpace: "pre-line",
          }}
        >
          {noteTextGetter(props.data.note)}
        </Detail>
      );
    }
  };
  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <AppBar
              component={"nav"}
              elevation={0}
              position="sticky"
              color="inherit"
              sx={{
                top: 0,
                bottom: "auto",
                width: "100%",
                right: 0,
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                }}
              >
                <HeaderWrapper>
                  <Info color="primary" style={{ marginRight: "8px" }} />
                  <Header>{getLocalizedText("info")}</Header>
                </HeaderWrapper>
              </Toolbar>
            </AppBar>
            <Divider style={{ margin: "0px 16px" }} />
            <Container>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>{getLocalizedText("note")}</Label>
                    {props.data.note !== "" && props.data.note !== null ? (
                      <RenderNotes />
                    ) : (
                      <Detail>-</Detail>
                    )}
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>{getLocalizedText("created_by")}</Label>
                    <Detail>
                      {props.data.created_by !== null
                        ? dataRenderer(props.data.created_by.name)
                        : "-"}
                    </Detail>
                  </InputWrapper>
                </Grid>
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>{getLocalizedText("created_at")}</Label>
                    <Detail>{IsoToLocalTime(props.data.created_at)}</Detail>
                  </InputWrapper>
                </Grid>
              </Grid>
            </Container>
            <AppBar
              position="static"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: "100%" }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                  width: "100%",
                  position: "relative",
                }}
              >
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      onClick={() => {
                        analytics.triggerEvent(
                          4625000073,
                          "close_button_tap",
                          "note_information_form",
                          "close_button",
                          {}
                        );
                        handleClose();
                      }}
                      type="button"
                      variant="contained"
                      color="default"
                      style={{
                        width: "120px",
                        textTransform: "none",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      {getLocalizedText("close")}
                    </Button>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default NoteDetailsModal;
