import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import TopSection from "./get_quote_components/TopSection";
import BottomSection from "./get_quote_components/BottomSection";
import FooterComponent from "./get_quote_components/FooterComponent";
const StyledContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
}));

const SectionWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  minHeight: "calc(100vh - 54px)",
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    minHeight: "calc(100vh - 44px)",
    width: "100%",
  },
  alignItems: "center",
  gap: theme.spacing(2),
}));

const GetQuoteTemplate = ({
  contentState,
  formState,
  secondaryColor,
  logoURL,
}) => {
  return (
    <>
      <StyledContainer disableGutters fixed maxWidth="false">
        <SectionWrapper>
          <TopSection contentState={contentState} logoURL={logoURL} />
          <BottomSection
            contentState={contentState}
            formState={formState}
            secondaryColor={secondaryColor}
          />
        </SectionWrapper>
        <FooterComponent contentState={contentState} />
      </StyledContainer>
    </>
  );
};

export default GetQuoteTemplate;
