import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import { Box, Chip, Tooltip } from "@material-ui/core";
import { AccountCircle, ChevronRight } from "@material-ui/icons";
import rootStore from "../../stores/RootStore";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { pageSize } from "../../Config";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { getCustomersListApi } from "../../Api";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { useNavigate } from "react-router-dom";
import ExpandableSearchbar from "../../components/expandable_searchbar/ExpandableSearchbar";
import { FormattedAgGridColumns } from "../../ColumnUtils";
import {
  dataRenderer,
  getCustomerListingAvatarIconColor,
  getCustomerListingAvatarTooltipText,
} from "../../Functions";
import LoadingAnimationComponent from "../../components/loading_component/LoadingAnimationComponent";
const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 75px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;
const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 16px 0px;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;

const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 276px);`};

  background-color: white;
  padding: 20px;
  padding-bottom: 0px;
`;

const PaginationWrapper = styled(Box)`
  padding: 0px 20px;
  background-color: white;
`;

const TextHighlight = styled(Typography)`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  color: #3f51b5;
  cursor: pointer;
`;
const ChipsWrapper = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
  height: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const requiredColumns = ["phone", "email", "city", "title", "created_at"];
const chipData = [
  { key: 0, label: "All Customers", value: "all" },
  { key: 1, label: "Customers - Today", value: "customers_today" },
  { key: 2, label: "Customers - This Month", value: "customers_thismonth" },
  { key: 3, label: "Deactivated Customers", value: "deactivated_customers" },
];

const CustomersPage = () => {
  const navigate = useNavigate();
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [columns, setColumns] = useState([]);
  const getCustomersList = async ({ query }) => {
    setLoadingData(true);
    let payload = { view: rootStore.customerStore.listingInfo.scope };
    if (query !== "") {
      payload["query"] = query;
    }
    let response = await getCustomersListApi({
      page: rootStore.customerStore.listingInfo.page_number,
      page_size: rootStore.customerStore.listingInfo.page_size,
      payload: payload,
    });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
      rootStore.customerStore.updateCount(response.item_count);
    } else {
      setRow([]);
      rootStore.customerStore.updateCount(0);
    }
    setLoadingData(false);
  };

  const getCustomerColumns = async () => {
    const columnsList = [...rootStore.customerStore.columnsList];
    let filteredColumns = requiredColumns
      .map((requiredColumn) =>
        columnsList.find((column) => column["column_name"] === requiredColumn)
      )
      .filter(Boolean);

    let formattedColumns = FormattedAgGridColumns(filteredColumns);
    setColumns(formattedColumns);
  };

  const init = async () => {
    setLoading(true);
    await getCustomersList({
      query: searchQuery,
    });
    await getCustomerColumns();
    setLoading(false);
  };

  useEffect(() => {
    init();
    rootStore.authStore.updateLeadDetailStatus();
  }, []);

  const handleChip = async (scope) => {
    rootStore.customerStore.updateScope(scope);
    rootStore.customerStore.updateCount(0);
    rootStore.customerStore.udpatePageNumber(0);
    rootStore.customerStore.updatePageSize(pageSize);
    await getCustomersList({
      query: searchQuery,
    });
  };

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const handlePageSize = async (e) => {
    rootStore.customerStore.udpatePageNumber(0);
    rootStore.customerStore.updatePageSize(e.target.value);
    await getCustomersList({
      query: searchQuery,
    });
  };
  const handlePagination = async (e, page) => {
    rootStore.customerStore.udpatePageNumber(page - 1);

    await getCustomersList({
      query: searchQuery,
    });
  };

  const handleSearchQuery = async (query) => {
    rootStore.customerStore.udpatePageNumber(0);
    rootStore.customerStore.updatePageSize(pageSize);
    await getCustomersList({
      query: query,
    });
  };

  const startingColumns = [
    {
      field: "icons",
      headerName: "",
      width: 60,
      cellRenderer: (params) => {
        return (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            {" "}
            {renderAvatarIcon(params.data)}
          </Box>
        );
      },
    },
    {
      field: "name",
      headerName: "NAME",

      width: 230,
      cellRenderer: (params) => {
        let parts = [params.data.first_name, params.data.last_name].filter(
          Boolean
        );
        let fullName = parts.length ? parts.join(" ") : "-";
        return (
          <>
            <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
              <Tooltip title={fullName}>
                <TextHighlight>{fullName}</TextHighlight>
              </Tooltip>
            </Box>
          </>
        );
      },
    },
  ];

  const renderAvatarIcon = (data) => {
    if (
      data.hasOwnProperty("is_customer") &&
      data.hasOwnProperty("was_customer")
    ) {
      return (
        <Tooltip title={getCustomerListingAvatarTooltipText({ data: data })}>
          <AccountCircle
            fontSize="small"
            style={{
              color: getCustomerListingAvatarIconColor({ data: data }),
            }}
          />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={"Customer"}>
          <AccountCircle
            fontSize="small"
            style={{
              color: "#AAB0B7",
            }}
          />
        </Tooltip>
      );
    }
  };

  return !loading ? (
    <>
      <ParentContainer>
        <Container>
          <TopWrapper>
            <Header>Customers</Header>
            <ChevronRight style={{ width: "16px", height: "16px" }} />
          </TopWrapper>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginY: "16px",
            }}
          >
            <ChipsWrapper>
              {chipData.map((data, index) => {
                return (
                  <Chip
                    key={index}
                    color={
                      rootStore.customerStore.listingInfo.scope === data.value
                        ? "primary"
                        : "default"
                    }
                    label={data.label}
                    onClick={() => {
                      handleChip(data.value);
                    }}
                  />
                );
              })}
            </ChipsWrapper>
            <ExpandableSearchbar
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              handleSearch={(query) => {
                handleSearchQuery(query);
              }}
            />
          </Box>

          {!loadingData ? (
            <TableWrapper>
              <div className="ag-theme-alpine" style={{ width: "100%" }}>
                <AgGridReact
                  domLayout="autoHeight"
                  gridOptions={gridOptions}
                  rowData={row}
                  defaultColDef={defaultColDef}
                  columnDefs={startingColumns.concat(columns)}
                  animateRows={true}
                  suppressCellFocus
                  pagination={false}
                  paginationPageSize={
                    rootStore.customerStore.listingInfo.page_size
                  }
                  className="paginated-ag-grid"
                  onRowClicked={(row) => {
                    if (row.event.defaultPrevented) {
                      return null;
                    }
                    navigate(`/customers/${row.data.id}`);
                  }}
                  overlayNoRowsTemplate={"No Customers found."}
                />
              </div>
            </TableWrapper>
          ) : (
            <TableWrapper
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingAnimationComponent size={"small"} />
            </TableWrapper>
          )}
          <PaginationWrapper>
            <PaginationComponent
              page_no={rootStore.customerStore.listingInfo.page_number}
              row={row}
              page_size={rootStore.customerStore.listingInfo.page_size}
              size={rootStore.customerStore.listingInfo.page_size}
              count={rootStore.customerStore.listingInfo.count}
              handlePageSize={handlePageSize}
              handlePage={handlePagination}
            />
          </PaginationWrapper>
        </Container>
      </ParentContainer>
      <Footer />
    </>
  ) : (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <LoadingAnimationComponent size={"small"} />
    </Box>
  );
};

export default observer(CustomersPage);
