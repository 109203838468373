import { IconButton } from "@material-ui/core";
import { Upload } from "@mui/icons-material";
import React, { useRef } from "react";

const FileUploadComponent = ({ handleFileUpload, getFilePattern }) => {
  const inputRef = useRef(null);

  return (
    <>
      <input
        style={{ display: "none" }}
        type="file"
        accept={getFilePattern()}
        ref={inputRef}
        onChange={(event) => handleFileUpload(event)}
      />
      <IconButton
        style={{ padding: "0px" }}
        onClick={(e) => {
          inputRef.current.click();
        }}
        color="primary"
      >
        <Upload
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      </IconButton>
    </>
  );
};

export default FileUploadComponent;
