import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Button } from "@material-ui/core";
import {
  AppBar,
  Box,
  Select,
  FormControl,
  MenuItem,
  Grid,
  Toolbar,
  Typography,
  OutlinedInput,
  Alert,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import MuiAlert from "@mui/material/Alert";

import { addProjectAdministrationQualityApi } from "../../Api";
import { makeStyles } from "@material-ui/core";
import rootStore from "../../stores/RootStore";
import { qualityOperatorMapList, systemFieldList } from "../../Db";
import { Add } from "@material-ui/icons";
import CardWidget from "./CardWidget";
import ValueWidget from "./ValueWidget";
import { LoadingButton } from "@mui/lab";
import { v4 as uuidv4 } from "uuid";
import { getLocalizedText } from "../../Functions";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 500px;

  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
`;
const MessageSm = styled(Typography)`
  margin: 0px;
  font: normal normal 500 12px Open Sans;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;

const Label = styled(Typography)`
  font: normal normal normal 12px Open Sans;
  color: #4d4e4f;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  gap: 20px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const AddQualitySidepanel = (props) => {
  const classes = useStyles();
  const allColumnsList = [...rootStore.userStore.AllColumnsList];
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [operatorList, setOperatorList] = useState([]);
  const [selectedColumnID, setSelectedColumnID] = useState("");
  const [selectedColumnConditionList, setSelectedColumnConditionList] =
    useState([]);
  const [selectedConditionData, setSelectedConditionData] = useState({
    operator: "",
    score: "",
  });

  const [inputValue, setInputValue] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [columnsList, setColumnsList] = useState([]);

  const handleClose = () => {
    props.setOpen(false);
  };

  const sortColumnFields = () => {
    let tempColumns = allColumnsList.filter(
      (columnData) =>
        columnData["is_custom_param"] ||
        systemFieldList.includes(columnData["field"])
    );
    tempColumns.sort((a, b) =>
      a["headerName"].toLowerCase().localeCompare(b["headerName"].toLowerCase())
    );
    setColumnsList(tempColumns);
  };

  const handleAdd = async () => {
    setIsSubmitting(true);
    selectedColumnConditionList.forEach((item) => (item["best_match"] = false));
    let maxScore = 0;
    for (let i = 0; i < selectedColumnConditionList.length; i++) {
      maxScore = Math.max(
        parseInt(selectedColumnConditionList[i]["score"]),
        maxScore
      );
    }
    let maxScoreConditionIndex = selectedColumnConditionList.findIndex(
      (item) => parseInt(item["score"]) === maxScore
    );
    selectedColumnConditionList[maxScoreConditionIndex]["best_match"] = true;
    for (let i = 0; i < selectedColumnConditionList.length; i++) {
      let conditionData = selectedColumnConditionList[i];
      let payload = {
        column_id: selectedColumnID,
        operator: conditionData["operator"],
        score: parseInt(conditionData["score"]),
        best_match: conditionData["best_match"],
      };
      if (
        conditionData["operator"] === "between" ||
        conditionData["operator"] === "not between"
      ) {
        if (
          conditionData["value"]["data_type"] === "date" ||
          conditionData["value"]["data_type"] === "datetime"
        ) {
          let value1 = conditionData["value"]["value"]["start"];
          let value2 = conditionData["value"]["value"]["end"];
          payload["value1"] = value1;
          payload["value2"] = value2;
        } else if (conditionData["value"]["data_type"] === "integer") {
          let value1 = parseInt(conditionData["value"]["value"]["start"]);
          let value2 = parseInt(conditionData["value"]["value"]["end"]);
          payload["value1"] = value1;
          payload["value2"] = value2;
        }
      } else if (
        conditionData["operator"] === "in" ||
        conditionData["operator"] === "not in"
      ) {
        payload["value1"] = conditionData["value"]["value"].toString();
      } else {
        if (conditionData["operator"] !== "exists") {
          payload["value1"] = conditionData["value"]["value"];
        }
      }
      let response = await addProjectAdministrationQualityApi({
        payload: payload,
      });
      if (response.hasError()) {
        setErrorMessage(response.errorMessage);
        setIsSubmitFail(true);
        setIsSubmitted(true);
        props.setRefresh(true);
        return;
      }
    }
    setIsSubmitFail(false);
    props.setRefresh(true);
    setIsSubmitted(true);
    setIsSubmitting(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleAdd();
  };
  const getOperatorList = (item) => {
    if (item["data_type"] === "date" || item["data_type"] === "datetime") {
      return qualityOperatorMapList["date"];
    } else if (item["data_type"] === "string") {
      return qualityOperatorMapList["string"];
    } else if (item["data_type"] === "integer") {
      return qualityOperatorMapList["integer"];
    }

    return [];
  };

  const isValidNumberRange = () => {
    let columnMeta = getColumnMeta();
    let operator = selectedConditionData["operator"];
    if (
      columnMeta["data_type"] === "integer" &&
      (operator === "between" || operator === "not between")
    ) {
      if (
        inputValue[operator]["value"]["start"] !== "" &&
        inputValue[operator]["value"]["end"] !== "" &&
        !Number.isNaN(inputValue[operator]["value"]["start"]) &&
        !Number.isNaN(inputValue[operator]["value"]["end"])
      ) {
        let start = inputValue[operator]["value"]["start"];
        let end = inputValue[operator]["value"]["end"];
        console.log(start, end);
        if (parseInt(start) > parseInt(end)) {
          setErrorMessage("Please provide valid range values!");
          return false;
        }
      } else {
        setErrorMessage("Please provide valid range values!");
        return false;
      }
    }
    return true;
  };

  const handleAddOperator = () => {
    if (isValidNumberRange()) {
      setIsSubmitFail(false);
      let tempList = [...selectedColumnConditionList];
      let tempObj = { ...selectedConditionData };
      tempObj["value"] = inputValue[selectedConditionData["operator"]];
      tempObj["id"] = uuidv4();
      tempList.push(tempObj);
      setSelectedColumnConditionList(tempList);
      setSelectedConditionData({
        operator: "",
        score: "",
      });
      setInputValue({});
    } else {
      setIsSubmitFail(true);
    }
  };

  const handleOperatorDelete = (item) => {
    let tempList = [...selectedColumnConditionList];
    tempList = tempList.filter((obj) => obj["id"] !== item["id"]);
    setSelectedColumnConditionList(tempList);
  };

  const getColumnMeta = () => {
    let index = columnsList.findIndex(
      (column) => column["id"] === selectedColumnID
    );
    return columnsList[index];
  };

  const isValueFilled = () => {
    if (selectedConditionData.operator === "exists") {
      return true;
    } else {
      if (Object.keys(inputValue).length > 0) {
        if (
          selectedConditionData.operator === "in" ||
          selectedConditionData.operator === "not in"
        ) {
          return (
            inputValue[selectedConditionData["operator"]]["value"].length > 0
          );
        } else if (
          selectedConditionData.operator === "between" ||
          selectedConditionData.operator === "not between"
        ) {
          let value = inputValue[selectedConditionData["operator"]]["value"];
          if (value.hasOwnProperty("start") && value.hasOwnProperty("end")) {
            return (
              value["start"] !== null &&
              value["start"] !== "" &&
              value["end"] !== "" &&
              value["end"] !== null
            );
          } else {
            return false;
          }
        } else {
          return (
            inputValue[selectedConditionData["operator"]]["value"] !== null &&
            inputValue[selectedConditionData["operator"]]["value"] !== ""
          );
        }
      } else {
        return false;
      }
    }
  };

  const isAddConditionButtonDisabled = () => {
    if (isValueFilled()) {
      return (
        selectedConditionData.operator === "" ||
        selectedConditionData.score === "" ||
        selectedColumnID === "" ||
        selectedConditionData.best_match === ""
      );
    }
    return true;
  };

  const canShowColumnInDropdown = (item) => {
    let index = props.list.findIndex(
      (obj) => obj["column_meta"]["id"] === item["id"]
    );
    return index === -1;
  };
  useEffect(() => {
    sortColumnFields();
  }, []);

  return (
    <Box role="presentation">
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          width: 500,
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar>
          <ModelHeader>Add Quality Score Rule</ModelHeader>
        </Toolbar>
      </AppBar>
      {!isSubmitted ? (
        <ModelWrapper component={"form"} onSubmit={handleSubmit}>
          <InputContainer>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputWrapper>
                  <Label>Column</Label>
                  <FormControl
                    sx={{ width: "100%" }}
                    classes={{
                      root: classes.quantityRoot,
                    }}
                  >
                    <Select
                      classes={{
                        icon: classes.icon,
                      }}
                      value={selectedColumnID}
                      name="data_type"
                      displayEmpty
                      disableUnderline
                      variant="outlined"
                      required
                      onChange={(e) => {
                        let index = columnsList.findIndex(
                          (column) => column["id"] === e.target.value
                        );
                        let tempList = getOperatorList(columnsList[index]);
                        setOperatorList(tempList);
                        setSelectedColumnID(e.target.value);
                        setSelectedColumnConditionList([]);
                        setSelectedConditionData({
                          operator: "",
                          score: "",
                        });
                        setInputValue({});
                        setIsSubmitFail(false);
                      }}
                      style={{
                        width: "100%",
                        height: "30px",
                        fontSize: "14px",
                        borderRadius: "0px",
                      }}
                    >
                      {columnsList.map((e, index) => {
                        if (canShowColumnInDropdown(e)) {
                          return (
                            <MenuItem
                              style={{ fontSize: "14px" }}
                              key={index}
                              id={e.id}
                              value={e.id}
                            >
                              {e.headerName}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                  </FormControl>
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <Box
                  component={"fieldset"}
                  sx={{ border: "1px solid #cccccc", padding: "12px" }}
                >
                  <legend
                    style={{
                      width: "auto",
                      marginBottom: "0px",
                      fontSize: "16px",
                    }}
                  >
                    Rule
                  </legend>
                  <Grid item container xs={12} spacing={2}>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <Label>Operator</Label>
                        <FormControl
                          sx={{ width: "100%" }}
                          classes={{
                            root: classes.quantityRoot,
                          }}
                        >
                          <Select
                            classes={{
                              icon: classes.icon,
                            }}
                            disabled={selectedColumnID === ""}
                            value={selectedConditionData["operator"]}
                            name="operator"
                            displayEmpty
                            disableUnderline
                            variant="outlined"
                            onChange={(e) => {
                              setSelectedConditionData({
                                ...selectedConditionData,
                                operator: e.target.value,
                                score: "",
                              });
                              setInputValue({});
                              setIsSubmitFail(false);
                            }}
                            style={{
                              width: "100%",
                              height: "30px",
                              fontSize: "14px",
                              borderRadius: "0px",
                            }}
                          >
                            {operatorList.map((e, index) => {
                              return (
                                <MenuItem
                                  style={{
                                    fontSize: "14px",
                                  }}
                                  key={index}
                                  id={index}
                                  value={e.value}
                                >
                                  {e.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </InputWrapper>
                    </Grid>
                    {selectedConditionData["operator"] !== "exists" && (
                      <Grid item xs={12}>
                        {selectedColumnID === "" ||
                        selectedConditionData["operator"] === "" ? (
                          <InputWrapper>
                            <Label>Value</Label>

                            <OutlinedInput
                              disabled={true}
                              type="text"
                              style={{ height: "30px", width: "100%" }}
                              inputProps={{
                                "aria-label": "weight",
                                style: {
                                  fontSize: "14px",
                                },
                              }}
                              placeholder="Enter value"
                            />
                          </InputWrapper>
                        ) : (
                          <InputWrapper>
                            <ValueWidget
                              inputValue={inputValue}
                              setInputValue={setInputValue}
                              operator={selectedConditionData["operator"]}
                              columnMeta={getColumnMeta()}
                              setIsSubmitFail={setIsSubmitFail}
                            />
                          </InputWrapper>
                        )}
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <InputWrapper>
                        <Label>Score</Label>

                        <OutlinedInput
                          disabled={selectedColumnID === ""}
                          type="number"
                          style={{ height: "30px", width: "100%" }}
                          inputProps={{
                            "aria-label": "weight",
                            style: {
                              fontSize: "14px",
                            },
                          }}
                          onChange={(e) => {
                            setSelectedConditionData({
                              ...selectedConditionData,
                              score: e.target.value,
                            });
                          }}
                          name="score"
                          value={selectedConditionData["score"]}
                          placeholder="Enter value"
                        />
                      </InputWrapper>
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        onClick={() => {
                          handleAddOperator();
                        }}
                        disabled={isAddConditionButtonDisabled()}
                        variant="contained"
                        color="primary"
                        style={{
                          textTransform: "none",
                          fontSize: "14px",
                          fontWeight: "bold",
                          width: "100%",
                        }}
                      >
                        <Add /> Add Rule
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              {selectedColumnConditionList.length > 0 && (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      width: "100%",
                      padding: "12px 0xp",
                      display: "flex",
                      gap: "16px",
                      flexWrap: "wrap",
                      maxHeight: "calc(100vh - 578px)",
                      overflowY: "auto",
                      "&::-webkit-scrollbar": {
                        width: "0",
                      },
                    }}
                  >
                    {selectedColumnConditionList.map((item, index) => {
                      let columnMeta = getColumnMeta();
                      return (
                        <CardWidget
                          canShowBestMatch={false}
                          onRemove={() => handleOperatorDelete(item)}
                          operator={item["operator"]}
                          score={item["score"]}
                          key={index}
                          value={
                            item["operator"] !== "exists"
                              ? item["value"]["value"]
                              : ""
                          }
                          columnName={columnMeta["headerName"]}
                          dataType={
                            item["operator"] !== "exists"
                              ? item["value"]["data_type"]
                              : ""
                          }
                        />
                      );
                    })}
                  </Box>
                </Grid>
              )}
            </Grid>
          </InputContainer>

          <AppBar
            position="fixed"
            color="inherit"
            elevation={0}
            sx={{ top: "auto", bottom: 0, width: 500 }}
          >
            <Toolbar style={{ width: 500, position: "relative" }}>
              {isSubmitFail && (
                <ErrorAlert
                  onClose={(e) => {
                    e.stopPropagation();
                    setIsSubmitFail(false);
                  }}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  {errorMessage}
                </ErrorAlert>
              )}
              <ButtonWrapper>
                <Button
                  onClick={handleClose}
                  type="button"
                  variant="contained"
                  color="default"
                  style={{
                    textTransform: "none",
                    fontSize: "14px",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  Cancel
                </Button>
                {isSubmitting ? (
                  <LoadingButton
                    loading
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: "none",
                      width: "100%",
                    }}
                  >
                    <span>Submit</span>
                  </LoadingButton>
                ) : (
                  <Button
                    disabled={selectedColumnConditionList.length === 0}
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: "none",
                      fontSize: "14px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    Submit
                  </Button>
                )}
              </ButtonWrapper>
            </Toolbar>
          </AppBar>
        </ModelWrapper>
      ) : isSubmitFail ? (
        <MessageWrapper>
          <Message style={{ color: "red" }}>
            {getLocalizedText("something_went_wrong")}
          </Message>

          <Button
            onClick={handleClose}
            type="button"
            variant="contained"
            color="default"
            style={{
              marginTop: "20px",
              textTransform: "none",
              fontSize: "14px",
              fontWeight: "bold",
              width: "100px",
            }}
          >
            Close
          </Button>
        </MessageWrapper>
      ) : (
        <MessageWrapper>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Message>Rule(s) added successfully!</Message>

            <Button
              onClick={handleClose}
              type="button"
              variant="contained"
              color="default"
              style={{
                marginTop: "20px",
                textTransform: "none",
                fontSize: "14px",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              Close
            </Button>
          </Box>
          <Alert severity="info">
            <MessageSm>
              Quality score computation may take time, depending on the number
              of leads in your account.
            </MessageSm>
          </Alert>
        </MessageWrapper>
      )}
    </Box>
  );
};

export default observer(AddQualitySidepanel);
