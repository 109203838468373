import {
  Box,
  Button,
  FormControl,
  makeStyles,
  Modal,
  Select as LinearSelect,
  AppBar,
  Toolbar,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { MenuItem, OutlinedInput, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import {
  addClientWidgetApi,
  addPredefinedWidgetApi,
  getAccountMembersApi,
  getPredefinedWidgetsApiv2,
} from "../../../Api";
import "./addchart.css";
import rootStore from "../../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { getLocalizedText } from "../../../Functions";
import "../../../pages/summary/summary.css";
import { myTheme } from "../../../themeUtils";
import DateRangeFilterComponent from "../daterange_filter_component/DateRangeFilterComponent";
import MuiAlert from "@mui/material/Alert";
import analytics from "../../../Analytics";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 20px;
`;
const Header = styled(Typography)`
  font: normal normal 600 16px Open Sans;
  margin: 0px;
  margin-bottom: 20px;
  /* text-align: center; */
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const ModelWrapper = styled(Box)`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
`;
const Wrapper = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  height: 80vh;
  width: 500px;
`;
const Text1 = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
  margin-bottom: 20px;
  text-align: center;
`;

const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Row = styled(Box)`
  display: flex;
`;
const InputContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
`;

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const CounterWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
const CounterText = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #b5b5b5;
  text-align: "right";
`;

const AddChartModalv2 = (props) => {
  const { authStore } = rootStore;
  const [predefinedList, setPredefinedList] = useState([]);
  const [title, setTitle] = useState("");
  const [selectedWidgetID, setSelectedWidgetID] = useState("");
  const [dateFilterType, setDateFilterType] = useState("");
  const [dateRangeValue, setDateRangeValue] = useState(null);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [teamId, setTeamId] = useState(""); //this state variable is only for predefined widget creation (if the widget has scope - team)
  const [userList, setUserList] = useState([]);
  const classes = useStyles();
  const [messageTextCount, setMessageTextCount] = useState(0);
  const [headerTextCount, setHeaderTextCount] = useState(0);
  const [clientWidgetState, setClientWidgetState] = useState({
    message: "",

    header: "",
    userID: "",
  });
  const [isClientWidget, setIsClientWidget] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleClose = () => {
    props.setOpen(false);
  };
  const handleClick = () => {
    props.setOpen(false);
  };
  const canShowPredefinedWidgetDateFilter = () => {
    //selectedWidgetID variable is the state variable for widget selection

    let index = predefinedList.findIndex(
      (widget) => widget["id"] === selectedWidgetID
    );
    if (index !== -1) {
      return predefinedList[index]["show_date_filter"];
    } else {
      return false;
    }
  };
  const canShowPredefinedWidgetTeamSelection = () => {
    let index = predefinedList.findIndex(
      (widget) => widget["id"] === selectedWidgetID
    );
    if (index !== -1) {
      return predefinedList[index]["scope"] === "team";
    } else {
      return false;
    }
  };

  const getUsers = async () => {
    let response = await getAccountMembersApi();
    setUserList(response);
  };

  const getWidgets = async () => {
    let response = await getPredefinedWidgetsApiv2();
    setPredefinedList(response.data);
    if (response.data.length > 0) {
      setSelectedWidgetID(response.data[0]["id"]);
      setTitle(response.data[0]["name"]);
      setIsClientWidget(response.data[0]["client_widget"]);
    }
  };

  useEffect(() => {
    analytics.triggerEvent(
      4625000081,
      "create_widget_form_load",
      "dashboard_page",
      "",
      {}
    );

    init();
  }, []);

  const init = async () => {
    await getWidgets();
    await getUsers();
    setLoading(false);
  };

  const getSlotFilledAmount = (widgetList) => {
    const slotMax = 4;
    return (
      slotMax -
      widgetList.filter((item) => item.visualization === "add_item").length
    );
  };

  const addPredefinedWidget = async () => {
    let data = {
      title: title,
      predefined_widget_id: selectedWidgetID,
      version: 2,
      input: {},
    };

    if (canShowPredefinedWidgetTeamSelection()) {
      data["team_id"] = teamId;
    }
    if (canShowPredefinedWidgetDateFilter()) {
      data["input"]["leads_date_filter_type"] = dateFilterType;
      if (dateFilterType !== "All Leads") {
        let start_date = new Date(dateRangeValue["start_date"]);
        start_date = start_date.toISOString();
        let end_date = new Date(dateRangeValue["end_date"]);
        end_date = end_date.toISOString();
        data["input"]["start_date"] = start_date;
        data["input"]["end_date"] = end_date;
      }
    }
    const widgetIndex = predefinedList.findIndex(
      (x) => x.id === selectedWidgetID
    );

    let sequence = 0;
    const filledAmount = getSlotFilledAmount(props.counterRow); //filled amount is also last empty slot
    if (predefinedList[widgetIndex]["widget_type"] === "counter") {
      sequence =
        filledAmount < 4
          ? filledAmount
          : props.row.length + props.counterRow.length - 1;
    } else {
      sequence = props.row.length + props.counterRow.length - 1;
    }
    data["widget_sequence"] = sequence;

    let response = await addPredefinedWidgetApi({
      dashboard_id: props.dashboard_id,
      data: data,
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsError(true);
    } else {
      setIsError(false);
      props.setRefresh(!props.refresh);
      props.setOpen(false);
    }
  };

  const addClientWidget = async () => {
    let payload = {
      title: title,
      predefined_widget_id: selectedWidgetID,
      version: 2,
      input: {
        header: clientWidgetState.header,
        message: clientWidgetState.message,
        user_id: clientWidgetState.userID,
      },
      project_id: rootStore.authStore.projectId,
    };

    const widgetIndex = predefinedList.findIndex(
      (x) => x.id === selectedWidgetID
    );

    let sequence = 0;
    const filledAmount = getSlotFilledAmount(props.counterRow); //filled amount is also last empty slot
    if (predefinedList[widgetIndex]["widget_type"] === "counter") {
      sequence =
        filledAmount < 4
          ? filledAmount
          : props.row.length + props.counterRow.length - 1;
    } else {
      sequence = props.row.length + props.counterRow.length - 1;
    }
    payload["widget_sequence"] = sequence;

    let response = await addClientWidgetApi({
      dashboard_id: props.dashboard_id,
      payload: payload,
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsError(true);
    } else {
      setIsError(false);
      props.setRefresh(!props.refresh);
      props.setOpen(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (canShowPredefinedWidgetDateFilter()) {
      if (dateFilterType !== "All Leads") {
        if (dateRangeValue === null) {
          setErrorMessage(getLocalizedText("please_select_a_date_range"));
          setIsError(true);
        } else {
          if (isClientWidget) {
            await addClientWidget();
          } else {
            await addPredefinedWidget();
          }
        }
      } else {
        if (isClientWidget) {
          await addClientWidget();
        } else {
          await addPredefinedWidget();
        }
      }
    } else {
      if (isClientWidget) {
        await addClientWidget();
      } else {
        await addPredefinedWidget();
      }
    }
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <ModelWrapper>
              <Text1>{getLocalizedText("create_a_widget")}</Text1>
              <Row>
                {!loading ? (
                  <Wrapper component={"form"} onSubmit={handleSubmit}>
                    <Header>{getLocalizedText("details")}</Header>
                    <Box
                      sx={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <InputContainer>
                        <InputWrapper>
                          <Label>{getLocalizedText("select_widget")}</Label>
                          <FormControl
                            classes={{
                              root: classes.quantityRoot,
                            }}
                          >
                            <LinearSelect
                              classes={{
                                icon: classes.icon,
                              }}
                              name="predefined_widget"
                              value={selectedWidgetID}
                              displayEmpty
                              disableUnderline
                              variant="outlined"
                              required
                              onChange={(e) => {
                                setSelectedWidgetID(e.target.value);
                                setTeamId("");
                                setIsError(false);
                                setDateRangeValue(null);
                                setDateFilterType("All Leads");
                                setClientWidgetState({
                                  message: "",

                                  header: "",
                                  userID: "",
                                });
                                let index = predefinedList.findIndex(
                                  (widget) => widget["id"] === e.target.value
                                );
                                if (index !== -1) {
                                  setTitle(predefinedList[index]["name"]);
                                  setIsClientWidget(
                                    predefinedList[index]["client_widget"]
                                  );
                                }
                              }}
                              style={{
                                width: "100%",
                                height: "30px",
                                fontSize: "12px",
                                fontWeight: "bold",
                                borderRadius: "0px",
                              }}
                            >
                              {predefinedList.length > 0 &&
                                predefinedList.map((e) => {
                                  return (
                                    <MenuItem
                                      style={{
                                        fontSize: "12px",
                                        height: "30px",
                                      }}
                                      id={e.id}
                                      value={e.id}
                                    >
                                      {e.name}
                                    </MenuItem>
                                  );
                                })}
                            </LinearSelect>
                          </FormControl>
                        </InputWrapper>

                        <InputWrapper>
                          <Label>{getLocalizedText("title")}*</Label>
                          <OutlinedInput
                            style={{ height: "30px" }}
                            inputProps={{
                              min: 0,
                              "aria-label": "weight",
                              style: {
                                padding: "0px 12px",
                                fontSize: "12px",
                              },
                            }}
                            required
                            value={title}
                            onChange={(e) => {
                              setTitle(e.target.value);
                            }}
                            placeholder="enter title"
                            name="title"
                          />
                        </InputWrapper>
                        {isClientWidget && (
                          <>
                            <InputWrapper>
                              <Label>Select User*</Label>
                              <FormControl
                                classes={{
                                  root: classes.quantityRoot,
                                }}
                              >
                                <LinearSelect
                                  classes={{
                                    icon: classes.icon,
                                  }}
                                  name="user"
                                  value={clientWidgetState.userID}
                                  displayEmpty
                                  disableUnderline
                                  variant="outlined"
                                  required
                                  onChange={(e) => {
                                    setClientWidgetState({
                                      ...clientWidgetState,
                                      userID: e.target.value,
                                    });
                                  }}
                                  style={{
                                    width: "100%",
                                    height: "30px",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    borderRadius: "0px",
                                  }}
                                >
                                  {userList.map((user, index) => {
                                    return (
                                      <MenuItem
                                        style={{
                                          fontSize: "12px",
                                          height: "30px",
                                        }}
                                        key={index}
                                        id={user.id}
                                        value={user.id}
                                      >
                                        {user.name}
                                      </MenuItem>
                                    );
                                  })}
                                </LinearSelect>
                              </FormControl>
                            </InputWrapper>

                            <InputWrapper>
                              <Label>Header*</Label>
                              <OutlinedInput
                                required
                                style={{ height: "30px" }}
                                inputProps={{
                                  minLength: 0,
                                  maxLength: 50,
                                  min: 0,
                                  "aria-label": "weight",
                                  style: {
                                    padding: "0px 12px",
                                    fontSize: "12px",
                                  },
                                }}
                                onChange={(e) => {
                                  setClientWidgetState({
                                    ...clientWidgetState,
                                    header: e.target.value,
                                  });
                                  setHeaderTextCount(e.target.value.length);
                                }}
                                name="header"
                                type="text"
                                value={clientWidgetState["header"]}
                                placeholder="Enter value"
                              />
                              <CounterWrapper>
                                <CounterText>{`${headerTextCount}/50 characters`}</CounterText>
                              </CounterWrapper>
                            </InputWrapper>
                            <InputWrapper>
                              <Label>Message*</Label>
                              <OutlinedInput
                                required
                                style={{ height: "30px" }}
                                inputProps={{
                                  minLength: 0,
                                  maxLength: 50,
                                  min: 0,
                                  "aria-label": "weight",
                                  style: {
                                    padding: "0px 12px",
                                    fontSize: "12px",
                                  },
                                }}
                                onChange={(e) => {
                                  setClientWidgetState({
                                    ...clientWidgetState,
                                    message: e.target.value,
                                  });
                                  setMessageTextCount(e.target.value.length);
                                }}
                                name="message"
                                type="text"
                                value={clientWidgetState["message"]}
                                placeholder="Enter value"
                              />
                              <CounterWrapper>
                                <CounterText>{`${messageTextCount}/50 characters`}</CounterText>
                              </CounterWrapper>
                            </InputWrapper>
                          </>
                        )}

                        {canShowPredefinedWidgetDateFilter() && (
                          <DateRangeFilterComponent
                            dateFilterType={dateFilterType}
                            setDateFilterType={setDateFilterType}
                            dateRangeValue={dateRangeValue}
                            setDateRangeValue={setDateRangeValue}
                            setShowDateRangeAlert={setIsError}
                          />
                        )}

                        {canShowPredefinedWidgetTeamSelection() && (
                          <InputWrapper>
                            <Label>{getLocalizedText("select_team")}</Label>
                            <FormControl
                              classes={{
                                root: classes.quantityRoot,
                              }}
                            >
                              <LinearSelect
                                classes={{
                                  icon: classes.icon,
                                }}
                                name="team"
                                value={teamId}
                                displayEmpty
                                disableUnderline
                                variant="outlined"
                                required
                                onChange={(e) => {
                                  setTeamId(e.target.value);
                                }}
                                style={{
                                  width: "100%",
                                  height: "30px",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  borderRadius: "0px",
                                }}
                              >
                                {authStore.currentProject.hasOwnProperty(
                                  "teams"
                                ) &&
                                  authStore.currentProject["teams"].map((e) => {
                                    if (e["name"] !== "All Teams") {
                                      return (
                                        <MenuItem
                                          style={{
                                            fontSize: "12px",
                                            height: "30px",
                                          }}
                                          id={e.id}
                                          value={e.id}
                                        >
                                          {e.name}
                                        </MenuItem>
                                      );
                                    }
                                  })}
                              </LinearSelect>
                            </FormControl>
                          </InputWrapper>
                        )}
                      </InputContainer>

                      <AppBar
                        position="static"
                        color="inherit"
                        elevation={0}
                        sx={{
                          top: "auto",
                          bottom: 0,
                          width: "100%",
                        }}
                      >
                        <Toolbar
                          style={{
                            padding: "0px",
                            width: 500,
                            position: "relative",
                            minHeight: "0px",
                          }}
                        >
                          {isError && (
                            <ErrorAlert
                              onClose={(e) => {
                                e.stopPropagation();
                                setIsError(false);
                              }}
                              severity="error"
                              sx={{ width: "100%" }}
                            >
                              {errorMessage}
                            </ErrorAlert>
                          )}
                          <Grid container spacing={2} justifyContent="flex-end">
                            <Grid item>
                              <Button
                                onClick={() => {
                                  analytics.triggerEvent(
                                    4625000082,
                                    "cancel_button_tap",
                                    "create_widget_form",
                                    "cancel_button",
                                    {}
                                  );
                                  handleClick();
                                }}
                                type="button"
                                variant="contained"
                                color="default"
                                style={{
                                  backgroundColor: "#EFEFF4",
                                  textTransform: "none",

                                  fontSize: "12px",
                                  fontWeight: "bold",
                                }}
                              >
                                {getLocalizedText("cancel")}
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                onClick={() => {
                                  analytics.triggerEvent(
                                    4625000083,
                                    "add_widget_button_tap",
                                    "create_widget_form",
                                    "add_widget_button",
                                    {}
                                  );
                                }}
                                type="submit"
                                variant="contained"
                                id="call-log-btn"
                                style={{
                                  textTransform: "none",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  ...myTheme.Button.btnBlue,
                                }}
                              >
                                {getLocalizedText("add_widget")}
                              </Button>
                            </Grid>
                          </Grid>
                        </Toolbar>
                      </AppBar>
                    </Box>
                  </Wrapper>
                ) : (
                  <Wrapper
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <CircularProgress />
                  </Wrapper>
                )}
              </Row>
            </ModelWrapper>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default observer(AddChartModalv2);
