import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import { AppBar, Box, OutlinedInput, Toolbar, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import MuiAlert from "@mui/material/Alert";
import { editProductApi, getProductDetailsApi } from "../../Api";
import { IsoToLocalDate, getIsoString } from "../../Functions";
import SelectFieldSlug from "../custom_field_components/SelectFieldSlug";
import SelectField from "../custom_field_components/SelectField";
import rootStore from "../../stores/RootStore";
import AddFileInputComponent from "./components/AddFileInputComponent";
import InputFileViewComponent from "./components/InputFileViewComponent";
import PreviewImageDialog from "../files_page_components/PreviewImageDialog";
import PreviewPdfDialog from "../files_page_components/PreviewPdfDialog";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 18px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  gap: 20px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const SectionHeader = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #979797;
  margin-bottom: 16px;
`;

const EditProductSidePanel = (props) => {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [fieldsToRender, setFieldsToRender] = useState([]);
  const [details, setDetails] = useState({});
  const [changedValues, setChangedValues] = useState({});
  const columnsList = [...rootStore.productStore.columnsList];

  const [selectedImages, setSelectedImages] = useState({
    image_1: null,
    image_2: null,
    image_3: null,
    image_4: null,
  });
  const [selectedDocuments, setSelectedDocuments] = useState({
    file_1: null,
    file_2: null,
    file_3: null,
    file_4: null,
  });
  const [viewImageDialog, setViewImageDialog] = useState(false);
  const [viewPdfDialog, setViewPdfDialog] = useState(false);
  const [selectedFileUrl, setSelectedFileUrl] = useState("");

  const handleClose = () => {
    props.setOpen(false);
  };

  const generatePayloadData = (data) => {
    let fieldList = {};
    for (const key in data) {
      let value = data[key]["value"];
      if (value !== "") {
        if (data[key]["type"] === "date" || data[key]["type"] === "datetime") {
          value = getIsoString(value);
        } else if (data[key]["type"] === "integer") {
          value = parseInt(value);
        }

        fieldList[key] = value;
      }
    }

    return fieldList;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let formData = new FormData();
    let tempPayloadData = generatePayloadData({ ...changedValues });
    Object.keys(tempPayloadData).forEach((key) => {
      if (tempPayloadData[key] !== "") {
        formData.append(key, tempPayloadData[key]);
      }
    });

    Object.keys(selectedImages).forEach((key) => {
      let value = selectedImages[key];
      if (value !== null) {
        if (value instanceof File) {
          formData.append(key, selectedImages[key]);
        }
      } else {
        formData.append(key, null);
      }
    });

    Object.keys(selectedDocuments).forEach((key) => {
      let value = selectedDocuments[key];
      if (value !== null) {
        if (value instanceof File) {
          formData.append(key, selectedDocuments[key]);
        }
      } else {
        formData.append(key, null);
      }
    });

    let response = await editProductApi({
      productID: props.productID,
      payload: formData,
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      props.setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };

  const getValue = (data, key, type) => {
    if (data.hasOwnProperty(key)) {
      let value = data[key];

      if (type === "date" || type === "datetime") {
        value = IsoToLocalDate(value);
      }
      return value !== null ? value : "";
    } else {
      return "";
    }
  };

  const initEditableData = (sortedColumnList, editableData) => {
    let tempList = {};

    if (sortedColumnList.length > 0) {
      for (let i = 0; i < sortedColumnList.length; i++) {
        let item = sortedColumnList[i];

        let value = getValue(
          editableData,
          item["column_name"],
          item["data_type"]
        );
        tempList[item["column_name"]] = {
          value: value,
          type: item["data_type"],
        };
      }
    }
    return tempList;
  };

  function sortColumnFields() {
    let tempColumns = columnsList.filter(
      (columnData) =>
        columnData["is_visible"] && columnData["show_in_edit_form"]
    );
    let requiredFields = tempColumns
      .filter((item) => item["required"])
      .map((item) => item)
      .sort((a, b) =>
        a["display_name"]
          .toLowerCase()
          .localeCompare(b["display_name"].toLowerCase())
      );
    let allFields = [...requiredFields];

    let remainingFields = [];
    tempColumns.forEach((item) => {
      let index = allFields.findIndex(
        (element) => element["id"] === item["id"]
      );
      if (index === -1) {
        remainingFields.push(item);
      }
    });
    remainingFields.sort((a, b) =>
      a["display_name"]
        .toLowerCase()
        .localeCompare(b["display_name"].toLowerCase())
    );
    remainingFields.forEach((data) => {
      let index = allFields.findIndex(
        (element) => element["id"] === data["id"]
      );
      if (index === -1) {
        allFields.push(data);
      }
    });
    allFields.sort((a, b) => {
      if (a.is_multiline && !b.is_multiline) {
        return 1;
      } else if (!a.is_multiline && b.is_multiline) {
        return -1;
      } else {
        return 0;
      }
    });

    for (const item of allFields) {
      if (item.is_multiline) {
        item.width = 2;
        item.modifyWidth = false;
      } else {
        item.width = 1;
        item.modifyWidth = true;
      }
    }
    for (let i = 0; i < allFields.length; i++) {
      let currentItem = allFields[i];

      if (currentItem.modifyWidth) {
        if (i === allFields.length - 1) {
          currentItem.width = 2;
          currentItem.modifyWidth = false;
        } else {
          let nextItem = allFields[i + 1];

          if (nextItem.width === 2) {
            currentItem.width = 2;
            currentItem.modifyWidth = false;
          } else if (nextItem.width === 1) {
            currentItem.width = 1;
            currentItem.modifyWidth = false;
            nextItem.modifyWidth = false;
          }
        }
      }
    }
    return allFields;
  }
  const init = async () => {
    let sortedFields = sortColumnFields();
    setFieldsToRender(sortedFields);
    let productDetails = await getProductDetails();
    let preFilledData = initEditableData(sortedFields, productDetails);
    setDetails(preFilledData);
    const tempImageFiles = Object.keys(productDetails)
      .filter((key) => key.startsWith("image_"))
      .reduce((acc, key) => {
        acc[key] = productDetails[key];
        return acc;
      }, {});
    const tempDocFiles = Object.keys(productDetails)
      .filter((key) => key.startsWith("file_"))
      .reduce((acc, key) => {
        acc[key] = productDetails[key];
        return acc;
      }, {});
    setSelectedImages(tempImageFiles);
    setSelectedDocuments(tempDocFiles);
    setLoading(false);
  };

  const getProductDetails = async () => {
    let response = await getProductDetailsApi({ productID: props.productID });
    return response;
  };

  useEffect(() => {
    init();
  }, []);

  const handleChange = ({ e, dataType }) => {
    let value =
      dataType === "integer" ? parseInt(e.target.value) : e.target.value;
    setDetails({
      ...details,
      [e.target.name]: { value: value, type: dataType },
    });
    setChangedValues({
      ...changedValues,
      [e.target.name]: { value: value, type: dataType },
    });
  };

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    const imageKeys = Object.keys(selectedImages);

    files.forEach((file) => {
      const availableKey = imageKeys.find(
        (key) => selectedImages[key] === null
      );
      if (availableKey) {
        setSelectedImages((prevImages) => ({
          ...prevImages,
          [availableKey]: file,
        }));
      }
    });
  };

  const handleDocumentUpload = (event) => {
    const files = Array.from(event.target.files);
    const docKeys = Object.keys(selectedDocuments);

    files.forEach((file) => {
      const availableKey = docKeys.find(
        (key) => selectedDocuments[key] === null
      );
      if (availableKey) {
        setSelectedDocuments((prevDocs) => ({
          ...prevDocs,
          [availableKey]: file,
        }));
      }
    });
  };

  const handleImagePreview = (image) => {
    if (image instanceof File) {
      const objectURL = URL.createObjectURL(image);
      setSelectedFileUrl(objectURL);
    } else {
      setSelectedFileUrl(image["url"]);
    }
    setViewImageDialog(true);
  };
  const handleDocumentPreview = (doc) => {
    if (doc instanceof File) {
      const objectURL = URL.createObjectURL(doc);
      setSelectedFileUrl(objectURL);
    } else {
      setSelectedFileUrl(doc["url"]);
    }
    setViewPdfDialog(true);
  };

  const handleRemoveImage = (key) => {
    setSelectedImages((prevImages) => ({
      ...prevImages,
      [key]: null,
    }));
  };

  const handleRemoveDoc = (key) => {
    setSelectedDocuments((prevDocs) => ({
      ...prevDocs,
      [key]: null,
    }));
  };

  const getFileUrl = (file) => {
    if (file instanceof File) {
      const objectURL = URL.createObjectURL(file);
      return objectURL;
    } else {
      return file["url"];
    }
  };

  const allImageSlotsFilled = () => {
    return !Object.values(selectedImages).some((value) => value === null);
  };

  const allDocSlotsFilled = () => {
    return !Object.values(selectedDocuments).some((value) => value === null);
  };

  return !loading ? (
    <>
      {fieldsToRender.length > 0 ? (
        <Box role="presentation">
          <AppBar
            component={"nav"}
            position="sticky"
            color="inherit"
            elevation={0}
            sx={{
              top: 0,
              bottom: "auto",
              width: 500,
              right: 0,
              bgcolor: "#f9f9fc",
            }}
          >
            <Toolbar>
              <ModelHeader>Edit Details</ModelHeader>
            </Toolbar>
          </AppBar>
          {!isSubmitSuccess ? (
            <ModelWrapper component={"form"} onSubmit={handleSubmit}>
              <InputContainer>
                <Grid container spacing={2}>
                  {fieldsToRender.map((item) => {
                    if (item.input === "" || item.input === null) {
                      if (item.is_multiline) {
                        return (
                          <Grid item xs={12}>
                            <InputWrapper>
                              {item.required ? (
                                <Label>{item.display_name}*</Label>
                              ) : (
                                <Label>{item.display_name}</Label>
                              )}
                              <OutlinedInput
                                multiline={true}
                                rows={3}
                                inputProps={{
                                  min: 0,
                                  "aria-label": "weight",
                                  style: {
                                    fontSize: "12px",
                                  },
                                }}
                                required={item.required}
                                value={
                                  details.hasOwnProperty(item.column_name)
                                    ? details[item.column_name]["value"]
                                    : ""
                                }
                                disabled={!item.editable}
                                name={item.column_name}
                                type={"text"}
                                placeholder={`Enter ${item.display_name}`}
                                onChange={(e) => {
                                  handleChange({ e, dataType: item.data_type });
                                }}
                              />
                            </InputWrapper>
                          </Grid>
                        );
                      } else {
                        return (
                          <Grid item xs={item["width"] === 1 ? 6 : 12}>
                            <InputWrapper>
                              {item.required ? (
                                <Label>{item.display_name}*</Label>
                              ) : (
                                <Label>{item.display_name}</Label>
                              )}
                              <OutlinedInput
                                style={{ height: "30px" }}
                                inputProps={{
                                  min: 0,
                                  "aria-label": "weight",
                                  style: {
                                    fontSize: "12px",
                                  },
                                }}
                                disabled={!item.editable}
                                required={item.required}
                                value={
                                  details.hasOwnProperty(item.column_name)
                                    ? details[item.column_name]["value"]
                                    : ""
                                }
                                name={item.column_name}
                                type={
                                  item.data_type === "email"
                                    ? "email"
                                    : item.data_type === "datetime" ||
                                      item.data_type === "date"
                                    ? "date"
                                    : item.data_type === "integer"
                                    ? "number"
                                    : "text"
                                }
                                placeholder={`Enter ${item.display_name}`}
                                onChange={(e) => {
                                  handleChange({ e, dataType: item.data_type });
                                }}
                              />
                            </InputWrapper>
                          </Grid>
                        );
                      }
                    } else if (item.input.charAt(0) === "/") {
                      let ep = item.input.slice(1);
                      return (
                        <Grid item xs={item["width"] === 1 ? 6 : 12}>
                          <SelectFieldSlug
                            ep={ep}
                            label={item.display_name}
                            value={details}
                            field={item.column_name}
                            required={item.required}
                            readOnly={!item.editable}
                            handleChange={(event) => {
                              handleChange({
                                e: event,
                                dataType: item.data_type,
                              });
                            }}
                          />
                        </Grid>
                      );
                    } else if (
                      item.input.charAt(0) !== "/" &&
                      item.input !== "" &&
                      item.input !== null
                    ) {
                      return (
                        <Grid item xs={item["width"] === 1 ? 6 : 12}>
                          <SelectField
                            list={item.input}
                            field={item.column_name}
                            label={item.display_name}
                            value={details}
                            required={item.required}
                            readOnly={!item.editable}
                            handleChange={(event) => {
                              handleChange({
                                e: event,
                                dataType: item.data_type,
                              });
                            }}
                          />
                        </Grid>
                      );
                    }
                  })}

                  <Grid item xs={12}>
                    <InputWrapper>
                      <SectionHeader>Images</SectionHeader>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "16px",
                          flexWrap: "wrap",
                        }}
                      >
                        {!allImageSlotsFilled() && (
                          <AddFileInputComponent
                            acceptedFiles={"image/jpeg,image/png"}
                            handleChange={(event) => handleImageUpload(event)}
                            scope={"image"}
                          />
                        )}
                        {Object.entries(selectedImages).map(([key, image]) =>
                          image ? (
                            <InputFileViewComponent
                              key={key}
                              fileURL={getFileUrl(image)}
                              handleRemove={() => handleRemoveImage(key)}
                              handleView={() => handleImagePreview(image)}
                              scope={"image"}
                            />
                          ) : null
                        )}
                      </Box>
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <SectionHeader>Documents</SectionHeader>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "16px",
                          flexWrap: "wrap",
                        }}
                      >
                        {!allDocSlotsFilled() && (
                          <AddFileInputComponent
                            acceptedFiles={"application/pdf,text/plain"}
                            handleChange={(event) =>
                              handleDocumentUpload(event)
                            }
                            scope={"pdf"}
                          />
                        )}

                        {Object.entries(selectedDocuments).map(([key, doc]) =>
                          doc ? (
                            <InputFileViewComponent
                              key={key}
                              fileURL={getFileUrl(doc)}
                              handleRemove={() => handleRemoveDoc(key)}
                              handleView={() => handleDocumentPreview(doc)}
                              scope={"pdf"}
                            />
                          ) : null
                        )}
                      </Box>
                    </InputWrapper>
                  </Grid>
                </Grid>
              </InputContainer>

              <AppBar
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{ top: "auto", bottom: 0, width: 500 }}
              >
                <Toolbar style={{ width: 500, position: "relative" }}>
                  {isSubmitFail && (
                    <ErrorAlert
                      onClose={(e) => {
                        e.stopPropagation();
                        setIsSubmitFail(false);
                      }}
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      {errorMessage}
                    </ErrorAlert>
                  )}
                  <ButtonWrapper>
                    <Button
                      onClick={handleClose}
                      type="button"
                      variant="contained"
                      color="default"
                      style={{
                        textTransform: "none",
                        fontSize: "12px",
                        fontWeight: "bold",
                        width: "100%",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      style={{
                        backgroundColor: "#185DD2",
                        color: "white",
                        textTransform: "none",
                        fontSize: "12px",
                        fontWeight: "bold",
                        width: "100%",
                      }}
                    >
                      Submit
                    </Button>
                  </ButtonWrapper>
                </Toolbar>
              </AppBar>
            </ModelWrapper>
          ) : (
            <MessageWrapper>
              <Message>Product updated successfully!</Message>

              <Button
                onClick={handleClose}
                type="button"
                variant="contained"
                color="default"
                style={{
                  marginTop: "20px",
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "100px",
                }}
              >
                Close
              </Button>
            </MessageWrapper>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: 500,
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MessageWrapper>
            <Message
              style={{
                color: "#4d4e4f",
              }}
            >
              No fields to show.
            </Message>
            <Button
              onClick={handleClose}
              type="button"
              variant="contained"
              color="default"
              style={{
                marginTop: "20px",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              Close
            </Button>
          </MessageWrapper>
        </Box>
      )}
      {viewPdfDialog && (
        <PreviewPdfDialog
          open={viewPdfDialog}
          setOpen={setViewPdfDialog}
          file={selectedFileUrl}
        />
      )}
      {viewImageDialog && (
        <PreviewImageDialog
          open={viewImageDialog}
          setOpen={setViewImageDialog}
          file={selectedFileUrl}
          isCreate={false}
        />
      )}
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: 500,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default observer(EditProductSidePanel);
