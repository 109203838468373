import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, styled, Tab, Tabs, tabsClasses } from "@mui/material";
import CallLogsTab from "./interaction_tabs/CallLogsTab";
import VisitsTab from "./interaction_tabs/VisitsTab";
import { validateDisabledFeature } from "../../Functions";
import OthersTab from "./interaction_tabs/OthersTab";

const Container = styled(Box)`
  width: 100%;
  position: relative;
  ${`min-height: calc(100vh - 286px);`};
`;
const InteractionsTab = ({
  uid,
  lid,
  setRefreshLeadDetails,
  isUpdateStatusDisabled,
  leadData,
  canEditLead,
}) => {
  const [tabValue, setTabValue] = useState("calls");
  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Tabs
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 0.3 },
            },
          }}
          variant="scrollable"
          value={tabValue}
          textColor="primary"
          indicatorColor="primary"
          scrollButtons={"auto"}
          onChange={(event, newValue) => {
            setTabValue(newValue);
          }}
        >
          <Tab
            sx={{
              "&.Mui-selected": {
                backgroundColor: "#E7EEFA",
                color: "#4079DA",
                font: "normal normal 600 14px Open Sans",
                textTransform: "capitalize",
              },

              backgroundColor: "white",
              color: "#4D4E4F",
              font: "normal normal 600 14px Open Sans",
              textTransform: "capitalize",
            }}
            value={"calls"}
            label={"Calls"}
          />
          {validateDisabledFeature("checkins") && (
            <Tab
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#E7EEFA",
                  color: "#4079DA",
                  font: "normal normal 600 14px Open Sans",
                  textTransform: "capitalize",
                },

                backgroundColor: "white",
                color: "#4D4E4F",
                font: "normal normal 600 14px Open Sans",
                textTransform: "capitalize",
              }}
              value={"visits"}
              label={"Visits"}
            />
          )}
          <Tab
            sx={{
              "&.Mui-selected": {
                backgroundColor: "#E7EEFA",
                color: "#4079DA",
                font: "normal normal 600 14px Open Sans",
                textTransform: "capitalize",
              },

              backgroundColor: "white",
              color: "#4D4E4F",
              font: "normal normal 600 14px Open Sans",
              textTransform: "capitalize",
            }}
            value={"others"}
            label={"Others"}
          />
        </Tabs>
      </Box>
      {(() => {
        if (tabValue === "calls") {
          return (
            <CallLogsTab
              isUpdateStatusDisabled={isUpdateStatusDisabled}
              leadData={leadData}
              lid={lid}
              setRefreshLeadDetails={setRefreshLeadDetails}
              uid={uid}
            />
          );
        } else if (tabValue === "visits") {
          return <VisitsTab leadID={lid} />;
        } else if (tabValue === "others") {
          return <OthersTab leadID={lid} canEditLead={canEditLead} />;
        }
      })()}
    </Container>
  );
};

export default observer(InteractionsTab);
