import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Toolbar,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { getLocalizedText } from "../../Functions";
import { OutlinedInput, Typography } from "@mui/material";
import { myTheme } from "../../themeUtils";
import MuiAlert from "@mui/material/Alert";
import {
  createProjectAdministrationColumnGroupApi,
  updateProjectAdministrationColumnGroupApi,
} from "../../Api";
import CustomFieldHeader from "../custom_tooltip_headers/CustomFieldHeader";

const dialogWidth = 420;
const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 16px;
  flex-direction: column;
  row-gap: 20px;
`;
const Message = styled(Typography)`
  font: normal normal normal 16px Open Sans;
  color: green;
  margin: 0px;
  text-align: center;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const AddColumnGroupModal = (props) => {
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [details, setDetails] = useState({
    name: "",
    visible: false,
    copyable: false,
    editable: false,
  });

  const createColumnGroup = async ({ payload }) => {
    let response = await createProjectAdministrationColumnGroupApi({
      payload: payload,
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      props.setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };

  const updateColumnGroup = async ({ payload }) => {
    let response = await updateProjectAdministrationColumnGroupApi({
      columnGroupID: props.editableData["id"],
      payload: payload,
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      props.setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let payload = {
      name: details["name"],
    };

    if (props.isEdit === false) {
      payload["tab_name"] = "Basic Info";
    }

    if (props.isEdit === true) {
      payload["sequence"] = props.editableData["group_order"];
    } else {
      payload["sequence"] = props.nextSequence;
    }
    payload["editable"] = details["editable"];
    payload["copyable"] = details["copyable"];
    payload["visible"] = details["visible"];

    if (props.isEdit === true) {
      await updateColumnGroup({ payload: payload });
    } else {
      await createColumnGroup({ payload: payload });
    }
  };
  const handleClose = () => {
    props.setIsEdit(false);
    props.setOpen(false);
  };

  useEffect(() => {
    if (props.isEdit === true) {
      let tempObj = {
        name:
          props.editableData["group_name"] !== null
            ? props.editableData["group_name"]
            : "",
        editable:
          props.editableData["editable"] !== null
            ? props.editableData["editable"]
            : false,
        copyable:
          props.editableData["copyable"] !== null
            ? props.editableData["copyable"]
            : false,
        visible:
          props.editableData["visible"] !== null &&
          props.editableData["visible"] !== undefined
            ? props.editableData["visible"]
            : false,
      };
      setDetails(tempObj);
    }
  }, []);

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!isSubmitSuccess ? (
          <ModelContainer>
            <Box component={"form"} onSubmit={handleSubmit}>
              <AppBar
                component={"nav"}
                elevation={0}
                position="sticky"
                color="inherit"
                sx={{
                  top: 0,
                  bottom: "auto",
                  width: "100%",
                  right: 0,
                }}
              >
                <Toolbar
                  style={{
                    padding: "16px",
                  }}
                >
                  <HeaderWrapper>
                    {props.isEdit ? (
                      <Header>Edit Column Group</Header>
                    ) : (
                      <Header>Create Column Group</Header>
                    )}
                    <SubHeader>
                      {getLocalizedText("fill_in_the_below_details")}
                    </SubHeader>
                  </HeaderWrapper>
                </Toolbar>
              </AppBar>
              <Divider style={{ margin: "0px 16px" }} />
              <Container>
                <Box
                  style={{
                    height: "100%",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <CustomFieldHeader
                          header={"Group Name"}
                          tooltip={"Enter the name of the new column group."}
                          required={true}
                        />
                        <OutlinedInput
                          style={{ height: "30px" }}
                          inputProps={{
                            min: 0,
                            "aria-label": "weight",
                            style: {
                              fontSize: "12px",
                            },
                          }}
                          required
                          onChange={(e) => {
                            setDetails({ ...details, name: e.target.value });
                          }}
                          value={details["name"]}
                          type={"text"}
                          placeholder={"Enter name"}
                        />
                      </InputWrapper>
                    </Grid>
                    <Grid item sx={12}>
                      <InputWrapper
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <CustomFieldHeader
                          header={getLocalizedText("visible")}
                          tooltip={
                            "Specify if this column group should be displayed."
                          }
                          style={{ marginBottom: "0px", marginRight: "20px" }}
                        />
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                size="small"
                                checked={details["visible"]}
                                onChange={(e) => {
                                  setDetails({ ...details, visible: true });
                                }}
                              />
                            }
                            label={
                              <Label style={{ fontSize: "12px" }}>
                                {getLocalizedText("yes")}
                              </Label>
                            }
                          />
                          <FormControlLabel
                            control={
                              <Radio
                                size="small"
                                checked={!details["visible"]}
                                onChange={(e) => {
                                  setDetails({ ...details, visible: false });
                                }}
                              />
                            }
                            label={
                              <Label style={{ fontSize: "12px" }}>
                                {getLocalizedText("no")}
                              </Label>
                            }
                          />
                        </RadioGroup>
                      </InputWrapper>
                    </Grid>
                    <Grid item sx={12}>
                      <InputWrapper
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <CustomFieldHeader
                          header={getLocalizedText("editable")}
                          tooltip={"Specify if this column group is editable."}
                          style={{ marginBottom: "0px", marginRight: "20px" }}
                        />
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                size="small"
                                checked={details["editable"]}
                                onChange={(e) => {
                                  setDetails({ ...details, editable: true });
                                }}
                              />
                            }
                            label={
                              <Label style={{ fontSize: "12px" }}>
                                {getLocalizedText("yes")}
                              </Label>
                            }
                          />
                          <FormControlLabel
                            control={
                              <Radio
                                size="small"
                                checked={!details["editable"]}
                                onChange={(e) => {
                                  setDetails({ ...details, editable: false });
                                }}
                              />
                            }
                            label={
                              <Label style={{ fontSize: "12px" }}>
                                {getLocalizedText("no")}
                              </Label>
                            }
                          />
                        </RadioGroup>
                      </InputWrapper>
                    </Grid>
                    <Grid item sx={12}>
                      <InputWrapper
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <CustomFieldHeader
                          header={getLocalizedText("enable_copy")}
                          tooltip={
                            "Specify if we can do copy in this column group."
                          }
                          style={{ marginBottom: "0px", marginRight: "20px" }}
                        />
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                size="small"
                                checked={details["copyable"]}
                                onChange={(e) => {
                                  setDetails({ ...details, copyable: true });
                                }}
                              />
                            }
                            label={
                              <Label style={{ fontSize: "12px" }}>
                                {getLocalizedText("yes")}
                              </Label>
                            }
                          />
                          <FormControlLabel
                            control={
                              <Radio
                                size="small"
                                checked={!details["copyable"]}
                                onChange={(e) => {
                                  setDetails({ ...details, copyable: false });
                                }}
                              />
                            }
                            label={
                              <Label style={{ fontSize: "12px" }}>
                                {getLocalizedText("no")}
                              </Label>
                            }
                          />
                        </RadioGroup>
                      </InputWrapper>
                    </Grid>
                  </Grid>
                </Box>
              </Container>
              <AppBar
                position="static"
                color="inherit"
                elevation={0}
                sx={{ top: "auto", bottom: 0, width: "100%" }}
              >
                <Toolbar
                  style={{
                    padding: "16px",
                    width: dialogWidth,
                    position: "relative",
                  }}
                >
                  {isSubmitFail && (
                    <ErrorAlert
                      onClose={(e) => {
                        e.stopPropagation();
                        setIsSubmitFail(false);
                      }}
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      {errorMessage}
                    </ErrorAlert>
                  )}
                  <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item>
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        color="default"
                        style={{
                          fontSize: "12px",
                          textTransform: "none",
                          fontWeight: "bold",
                        }}
                      >
                        {getLocalizedText("cancel")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={() => {}}
                        type="submit"
                        variant="contained"
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          width: "fit-content",
                          textTransform: "none",
                          ...myTheme.Button.btnBlue,
                        }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
            </Box>
          </ModelContainer>
        ) : (
          <ModelContainer
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MessageWrapper style={{ marginBottom: "0px", padding: "16px" }}>
              {props.isEdit ? (
                <Message>Column group updated successfully!</Message>
              ) : (
                <Message>Column group added successfully!</Message>
              )}

              <Button
                onClick={handleClose}
                type="button"
                variant="contained"
                color="default"
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "100px",
                }}
              >
                {getLocalizedText("close")}
              </Button>
            </MessageWrapper>
          </ModelContainer>
        )}
      </Modal>
    </>
  );
};

export default AddColumnGroupModal;
