import { Box, Card, Chip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import React from "react";
import { dataRenderer } from "../../Functions";
const Title = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  color: #4d4e4f;
  text-align: left;
`;
const Description = styled(Typography)`
  font: normal normal 500 14px Open Sans;
  color: #4d4e4f;
  text-align: left;
  word-wrap: break-word;
`;
const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  justify-content: space-between;
  height: 100%;
`;
const ButtonWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;
const TextWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;
const IntegrationStatusCard = ({
  details,
  renderButton,
  showStatus = true,
}) => {
  const getStatusChipColor = () => {
    if (details["status"] === "connected") {
      return "#4caf50";
    } else if (details["status"] === "not connected") {
      return "#ff9800";
    } else if (details["status"] === "configured") {
      return "#03a9f4";
    } else if (details["status"] === "errored") {
      return "#ef5350";
    }
    return "";
  };
  return (
    <Card
      sx={{
        border: "1px solid #f9f9fc",
        bgcolor: details["is_enabled"] ? "#f9f9fc" : "#F0F0F0",
        height: "100%",
      }}
    >
      <Wrapper>
        <TextWrapper>
          <Title
            style={{
              color: details["is_enabled"] ? "#4d4e4f" : "lightgray",
            }}
          >
            {dataRenderer(details["title"])}
          </Title>
          <Description
            style={{
              color: details["is_enabled"] ? "#4d4e4f" : "lightgray",
            }}
          >
            {dataRenderer(details["desc"])}
          </Description>
        </TextWrapper>
        {showStatus ? (
          <ButtonWrapper>
            {showStatus &&
            details["status"] !== null &&
            details["status"] !== "" ? (
              <Chip
                disabled={!details["is_enabled"]}
                label={details["status"]}
                style={{
                  fontWeight: "bold",
                  color: "white",
                  textTransform: "capitalize",
                  backgroundColor: getStatusChipColor(),
                }}
              />
            ) : (
              "-"
            )}
            {renderButton()}
          </ButtonWrapper>
        ) : (
          <ButtonWrapper sx={{ justifyContent: "flex-end" }}>
            {renderButton()}
          </ButtonWrapper>
        )}
      </Wrapper>
    </Card>
  );
};

export default IntegrationStatusCard;
