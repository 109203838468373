import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import Chip from "@mui/material/Chip";
import { Box, Tooltip } from "@mui/material";

const ItemType = "CHIP";

const DraggableChipComponent = ({ label, index, moveChip, handleDelete }) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: ItemType,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveChip(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <Box
      sx={{ cursor: "pointer" }}
      ref={ref}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <Tooltip title={label} arrow>
        <Chip label={label} onDelete={handleDelete} />
      </Tooltip>
    </Box>
  );
};

export default DraggableChipComponent;
