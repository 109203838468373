import { AppBar, Box, Button, Grid, Modal, Toolbar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { consoleLogger, getLocalizedText } from "../../../Functions";
import { CircularProgress, Typography } from "@mui/material";
import { myTheme } from "../../../themeUtils";
import MuiAlert from "@mui/material/Alert";
import APIScreen from "./indiamart_mapping_screens/APIScreen";
import ColumnMappingScreen from "./indiamart_mapping_screens/ColumnMappingScreen";
import CustomConfirmationDialog from "../../alert_dialogue/CustomConfirmationDialog";
import ProjectSelectionScreen from "./column_mapping_screens/ProjectSelectionScreen";
import DefaultParameterScreen from "./column_mapping_screens/DefaultParameterScreen";
import { getAdminSystemUsersApi } from "../../../Api";

const dialogWidth = 600;
const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 16px;
  flex-direction: column;
  row-gap: 20px;
`;
const Message = styled(Typography)`
  font: normal normal normal 16px Open Sans;
  color: green;
  margin: 0px;
  text-align: center;
`;

const ViewIntegrationConfigDialog = ({
  open,
  setOpen,
  formData,
  integrationData,
  postSaveCallback,
  postCloseCallback,
  showConfirmationDialog,
  setShowConfirmationDialog,
  errorMessage,
  setErrorMessage,
  isSubmitFail,
  setIsSubmitFail,
  isSubmitSuccess,
  setIsSubmitSuccess,
}) => {
  consoleLogger(integrationData.type);
  const [projectColumnList, setProjectColumnList] = useState([]);
  const [formQuestionList, setFormQuestionsList] = useState([]);
  const [formAndProjectColumnMapping, setFormAndProjectColumnMapping] =
    useState({});
  const [selectedProjectID, setSelectedProjectID] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [formStep, setFormStep] = useState(0);
  const [selectedDefaultParameterMapping, setSelectedDefaultParameterMapping] =
    useState({});

  const isAtleastOneColumnMapped = () => {
    let flag = false;
    Object.keys(formAndProjectColumnMapping).forEach((item) => {
      if (formAndProjectColumnMapping[item]["checked"] === true) {
        flag = true;
      }
    });
    return flag;
  };

  //below function is to check if all the required field of the selected project are mapped or not
  const allProjectRequiredColumnsFilled = () => {
    let required_columns_list = projectColumnList.filter(
      (column) => column.required
    );
    const checkedCurrToDestProjectList = Object.keys(
      formAndProjectColumnMapping
    )
      .filter((key) => formAndProjectColumnMapping[key].checked === true)
      .reduce((result, key) => {
        result[key] = formAndProjectColumnMapping[key];
        return result;
      }, {});

    let flag = required_columns_list.every((item) => {
      return Object.values(checkedCurrToDestProjectList).some(
        (obj) => obj["project_lead"]["id"] === item.id
      );
    });

    return flag;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formStep === 3) {
      if (isAtleastOneColumnMapped()) {
        setIsSubmitFail(false);
        if (allProjectRequiredColumnsFilled()) {
          setIsSubmitSuccess(false);
          setShowConfirmationDialog(true);
        } else {
          setErrorMessage("All required fields are not mapped.");
          setIsSubmitFail(true);
        }
      } else {
        setErrorMessage(
          getLocalizedText("please_map_atleast_one_column_to_proceed")
        );
        setIsSubmitFail(true);
      }
    } else {
      setFormStep(formStep + 1);
      setIsSubmitFail(false);
    }
  };
  const handleClose = () => {
    postCloseCallback();
    setOpen(false);
  };

  const RenderButton = () => {
    if (formStep === 0) {
      return (
        <>
          <Grid item>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              variant="contained"
              color="default"
              style={{
                fontSize: "12px",
                textTransform: "none",
                fontWeight: "bold",
              }}
            >
              {getLocalizedText("cancel")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                width: "fit-content",
                textTransform: "none",
              }}
            >
              Next
            </Button>
          </Grid>
        </>
      );
    } else if (formStep < 3) {
      return (
        <>
          <Grid item>
            <Button
              onClick={() => {
                setFormStep(formStep - 1);
              }}
              variant="contained"
              color="default"
              style={{
                fontSize: "12px",
                textTransform: "none",
                fontWeight: "bold",
              }}
            >
              Prev
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                width: "fit-content",
                textTransform: "none",
              }}
            >
              Next
            </Button>
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Grid item>
            <Button
              onClick={() => {
                setFormStep(formStep - 1);
              }}
              variant="contained"
              color="default"
              style={{
                fontSize: "12px",
                textTransform: "none",
                fontWeight: "bold",
              }}
            >
              Prev
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                width: "fit-content",
                textTransform: "none",
                ...myTheme.Button.btnBlue,
              }}
            >
              Save
            </Button>
          </Grid>
        </>
      );
    }
  };

  const init = async () => {
    if (formData["status"] === "connected" || formData["status"] === "active") {
      setApiKey("*************************");

      if (formData.hasOwnProperty("project_id")) {
        setSelectedProjectID(formData.project_id);
      }

      let tempObj = {
        lead_owner_id: "",
        lead_status_id: "",
        lead_source_id: "",
        lead_stage_id: "",
        team_id: "",
      };

      if (
        formData.hasOwnProperty("default_params") &&
        formData["default_params"] !== null
      ) {
        tempObj["lead_owner_id"] =
          formData["default_params"].hasOwnProperty("lead_owner_id") &&
          formData["default_params"]["lead_owner_id"] !== null
            ? formData["default_params"]["lead_owner_id"]
            : "";

        tempObj["team_id"] =
          formData["default_params"].hasOwnProperty("team_id") &&
          formData["default_params"]["team_id"] !== null
            ? formData["default_params"]["team_id"]
            : "";

        tempObj["lead_status_id"] =
          formData["default_params"].hasOwnProperty("lead_status_id") &&
          formData["default_params"]["lead_status_id"] !== null
            ? formData["default_params"]["lead_status_id"]
            : "";

        tempObj["lead_source_id"] =
          formData["default_params"].hasOwnProperty("lead_source_id") &&
          formData["default_params"]["lead_source_id"] !== null
            ? formData["default_params"]["lead_source_id"]
            : "";
        tempObj["lead_stage_id"] =
          formData["default_params"].hasOwnProperty("lead_stage_id") &&
          formData["default_params"]["lead_stage_id"] !== null
            ? formData["default_params"]["lead_stage_id"]
            : "";
      }
      setSelectedDefaultParameterMapping(tempObj);
    }
  };
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!isSubmitSuccess ? (
          <ModelContainer>
            <Box component={"form"} onSubmit={handleSubmit}>
              {(() => {
                if (formStep === 0) {
                  return (
                    <APIScreen
                      apiKey={apiKey}
                      setApiKey={setApiKey}
                      integrationData={integrationData}
                    />
                  );
                } else if (formStep === 1) {
                  return (
                    <ProjectSelectionScreen
                      type={"integrations"}
                      selectedProjectID={selectedProjectID}
                      setSelectedProjectID={setSelectedProjectID}
                      setSelectedDefaultParameterMapping={
                        setSelectedDefaultParameterMapping
                      }
                    />
                  );
                } else if (formStep === 2) {
                  return (
                    <DefaultParameterScreen
                      formData={formData}
                      selectedDefaultParameterMapping={
                        selectedDefaultParameterMapping
                      }
                      setSelectedDefaultParameterMapping={
                        setSelectedDefaultParameterMapping
                      }
                      selectedProjectID={selectedProjectID}
                    />
                  );
                } else {
                  return (
                    <ColumnMappingScreen
                      projectID={selectedProjectID}
                      formData={formData}
                      projectColumnList={projectColumnList}
                      setProjectColumnList={setProjectColumnList}
                      formQuestionList={formQuestionList}
                      setFormQuestionsList={setFormQuestionsList}
                      formAndProjectColumnMapping={formAndProjectColumnMapping}
                      setFormAndProjectColumnMapping={
                        setFormAndProjectColumnMapping
                      }
                      type={integrationData.type}
                    />
                  );
                }
              })()}

              <AppBar
                position="static"
                color="inherit"
                elevation={0}
                sx={{ top: "auto", bottom: 0, width: "100%" }}
              >
                <Toolbar
                  style={{
                    padding: "16px",
                    width: dialogWidth,
                    position: "relative",
                  }}
                >
                  {isSubmitFail && (
                    <ErrorAlert
                      onClose={(e) => {
                        e.stopPropagation();
                        setIsSubmitFail(false);
                      }}
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      {errorMessage}
                    </ErrorAlert>
                  )}
                  <Grid container spacing={2} justifyContent="flex-end">
                    <RenderButton />
                  </Grid>
                </Toolbar>
              </AppBar>
            </Box>
          </ModelContainer>
        ) : (
          <ModelContainer
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MessageWrapper style={{ marginBottom: "0px", padding: "16px" }}>
              <Message>Saved successfully!</Message>

              <Button
                onClick={handleClose}
                type="button"
                variant="contained"
                color="default"
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "100px",
                }}
              >
                {getLocalizedText("close")}
              </Button>
            </MessageWrapper>
          </ModelContainer>
        )}
      </Modal>
      {showConfirmationDialog && (
        <CustomConfirmationDialog
          handleConfirm={async () => {
            let payload = {
              project_id: selectedProjectID,
            };
            let tempList = {};
            Object.keys(formAndProjectColumnMapping).forEach((key) => {
              if(formAndProjectColumnMapping[key]["checked"]){
                tempList[key] = formAndProjectColumnMapping[key]["project_lead"]["id"];
              }
            });
            payload["mapping"] = tempList;

            if (apiKey !== "" && apiKey != "*************************") {
              payload["api_key"] = apiKey;
            }

            const systemUserData = await getAdminSystemUsersApi();
            payload["default_params"] = {
              ...selectedDefaultParameterMapping,
              lead_creator_id: systemUserData.hasOwnProperty("id")
                ? systemUserData["id"]
                : "",
            };
            postSaveCallback(payload);
          }}
          open={showConfirmationDialog}
          setOpen={setShowConfirmationDialog}
          message={"Are you sure you want to save the form changes?"}
        />
      )}
    </>
  );
};

export default ViewIntegrationConfigDialog;
