import { action, makeObservable, observable } from "mobx";
import { makePersistable } from "mobx-persist-store";

class InvoicesPageState {
  invoicesChipIndex = 0;
  constructor() {
    makeObservable(this, {
      invoicesChipIndex: observable,
      updateInvoicesChipIndex: action,
      init: action,
      resetStore: action,
    });

    makePersistable(this, {
      name: InvoicesPageState,
      storage: window.localStorage,
      expireIn: 86400000,
      removeOnExpiration: true,
      stringify: true,
      debugMode: false,
      properties: ["invoicesChipIndex"],
    });
  }

  updateInvoicesChipIndex = (index) => {
    this.invoicesChipIndex = index;
  };

  resetStore = () => {
    this.invoicesChipIndex = 0;
  };

  init = async () => {
    
  };
}

export default InvoicesPageState;
