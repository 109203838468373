import React, { useEffect, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { styled } from "@mui/material/styles";
import { Add, Edit, Equalizer, Visibility } from "@material-ui/icons";
import { WebFormsColumnHeaders } from "../../Db";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { Button, Drawer } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { getWebFormsListApi } from "../../Api";
import rootStore from "../../stores/RootStore";
import { pageSize } from "../../Config";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useMemo } from "react";
import ViewWebformInfoSidepanel from "../../components/view_webform_info_sidepanel/ViewWebformInfoSidepanel";
import CreateLandingPagePanel from "../../components/create_landingpage_components/CreateLandingPagePanel";
import LoadingAnimationComponent from "../../components/loading_component/LoadingAnimationComponent";

const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 84px);`};
  margin: auto;
  padding-top: 64px;
  width: 95%;
`;

const TopWrapper = styled(Box)`
  width: fit-content;
  display: flex;
  align-items: center;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 28px Open Sans;

  color: #4d4e4f;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 236px);`};
  background-color: white;
  position: relative;
`;
const Row = styled(Box)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0px;
`;
const PaginationWrapper = styled(Box)`
  padding: 0px 20px;
  background-color: white;
`;

const WebFormsListingPage = () => {
  const navigate = useNavigate();
  const [row, setRow] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(pageSize);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openInfoSidepanel, setOpenInfoSidepanel] = useState(false);
  const [selectedFormID, setSelectedFormID] = useState("");
  const [openCreateLandingPagePanel, setOpenCreateLandingPagePanel] =
    useState(false);
  const getWebFormsList = async ({ page_size, page }) => {
    setRow([]);
    setLoading(true);
    let response = await getWebFormsListApi({
      page_size: page_size,
      page: page,
    });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
      setCount(response.item_count);
    } else {
      setCount(0);
    }
    setLoading(false);
  };
  const init = async () => {
    await getWebFormsList({ page_size: pageSize, page: 0 });
  };
  useEffect(() => {
    if (refresh === true) {
      init();
      setRefresh(false);
    }
  }, [refresh]);

  const handlePageSize = async (e) => {
    setSize(e.target.value);
    setPage(0);
    await getWebFormsList({ page_size: e.target.value, page: 0 });
  };
  const handlePagination = async (e, page) => {
    setPage(page - 1);
    await getWebFormsList({ page_size: size, page: page - 1 });
  };

  useEffect(() => {
    rootStore.authStore.updateLeadDetailStatus();
    init();
  }, []);

  const actionColumn = [
    {
      field: "actions",
      headerName: "Actions",
      resizable: false,
      minWidth: 70,
      flex: 1,
      cellRenderer: (params) => {
        return (
          <>
            <Tooltip title={"View"}>
              <IconButton
                color="primary"
                onClick={() => {
                  setSelectedFormID(params.data.id);
                  setOpenInfoSidepanel(true);
                }}
              >
                <Visibility
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>

            <Tooltip title={"Stats"}>
              <IconButton
                color="primary"
                onClick={() => {
                  navigate(`/web-forms/stats/${params.data.id}`);
                }}
              >
                <Equalizer
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>

            <Tooltip title={"Edit"}>
              <IconButton
                disabled={
                  params.data["external"] === true ||
                  params.data["form_type"] === null ||
                  (params.data["form_type"] !== null &&
                    params.data["form_type"].toLowerCase() !== "landing page")
                }
                color="primary"
                onClick={() => {
                  navigate(`/landing-page/update?formID=${params.data["id"]}`);
                }}
              >
                <Edit
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));

  const gridOptions = {
    suppressRowClickSelection: true,
  };

  return (
    <>
      <ParentContainer>
        <Container>
          <Row>
            <TopWrapper>
              <Header>Web Forms</Header>
            </TopWrapper>
            {/* <Button
              onClick={() => {
                navigate("/web-forms/create");
              }}
              variant="contained"
              style={{
                backgroundColor: "#185DD2",
                color: "white",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              Create Form
            </Button> */}

            <Box
              sx={{ display: "flex", alignItems: "center", columnGap: "20px" }}
            >
              <Button
                onClick={() => {
                  setOpenCreateLandingPagePanel(true);
                }}
                variant="contained"
                style={{
                  backgroundColor: "#185DD2",
                  color: "white",
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                <Add />
                Landing Page
              </Button>
          
            </Box>
          </Row>
          {!loading ? (
            <TableWrapper>
              <div className="ag-theme-alpine" style={{ width: "100%" }}>
                <AgGridReact
                  domLayout="autoHeight"
                  gridOptions={gridOptions}
                  rowData={row}
                  defaultColDef={defaultColDef}
                  suppressCellFocus
                  columnDefs={WebFormsColumnHeaders.concat(actionColumn)}
                  animateRows={true}
                  onRowClicked={(row) => {
                    if (row.event.defaultPrevented) {
                      return null;
                    }
                    setSelectedFormID(row.data.id);
                    setOpenInfoSidepanel(true);
                  }}
                />
              </div>
            </TableWrapper>
          ) : (
            <TableWrapper
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LoadingAnimationComponent size={"small"}/>
            </TableWrapper>
          )}
          <PaginationWrapper>
            <PaginationComponent
              page_no={page}
              row={row}
              page_size={size}
              size={size}
              count={count}
              handlePageSize={handlePageSize}
              handlePage={handlePagination}
            />
          </PaginationWrapper>
        </Container>
      </ParentContainer>
      <Footer />
      {openInfoSidepanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openInfoSidepanel}
          onClose={() => {
            setSelectedFormID("");
            setOpenInfoSidepanel(false);
          }}
        >
          <ViewWebformInfoSidepanel
            formID={selectedFormID}
            setOpen={setOpenInfoSidepanel}
          />
        </Drawer>
      )}
      {openCreateLandingPagePanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openCreateLandingPagePanel}
          onClose={() => {
            setOpenCreateLandingPagePanel(false);
          }}
        >
          <CreateLandingPagePanel
            open={openCreateLandingPagePanel}
            setOpen={setOpenCreateLandingPagePanel}
          />
        </Drawer>
      )}
    </>
  );
};

export default WebFormsListingPage;
