import React from "react";
import { styled } from "@mui/system";
import { Box } from "@mui/material";
import FormComponent from "./FormComponent";

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: "#ffffff",
  backgroundRepeat: "no-repeat",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  width: "50%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

const FormWrapper = styled(Box)(({ theme }) => ({
  width: "70%",
  margin: "auto",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const BottomSection = ({ contentState, formState, secondaryColor }) => {
  return (
    <Wrapper>
      <FormWrapper>
        <FormComponent
          contentState={contentState}
          formState={formState}
          secondaryColor={secondaryColor}
        />
      </FormWrapper>
    </Wrapper>
  );
};

export default BottomSection;
