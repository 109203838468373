import React, { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Button,
  Grid,
  Modal,
  Toolbar,
  Snackbar,
} from "@material-ui/core";
import { styled } from "@mui/material/styles";
import {
  FormControl,
  Typography,
  OutlinedInput,
  Divider,
  CircularProgress,
} from "@mui/material";
import { getExternalWebformApiKeyApi } from "../../../../Api";
import { getLocalizedText } from "../../../../Functions";

const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const dialogWidth = 600;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
  overflow-y: auto;
  row-gap: 10px;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const Label = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const APIKeyDialog = ({ open, setOpen, formData }) => {
  const [loading, setLoading] = useState(true);
  const [apiKey, setApiKey] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const init = async () => {
    const response = await getExternalWebformApiKeyApi({
      webhookID: formData["id"],
    });
    if (Object.keys(response).length > 0) {
      setApiKey(response.key);
    }
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        {!loading ? (
          <ModelContainer>
            <Box>
              <AppBar
                component={"nav"}
                elevation={0}
                position="sticky"
                color="inherit"
                sx={{
                  top: 0,
                  bottom: "auto",
                  width: "100%",
                  right: 0,
                }}
              >
                <Toolbar
                  style={{
                    padding: "16px",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <HeaderWrapper>
                      <Header>Webhook API Key</Header>
                    </HeaderWrapper>
                  </Box>
                </Toolbar>
              </AppBar>
              <Divider style={{ margin: "0px 16px" }} />
              <Container>
                <InputWrapper>
                  <Label>API Key</Label>
                  <FormControl sx={{ width: "100%" }}>
                    <OutlinedInput
                      name="api_key"
                      style={{ height: "30px" }}
                      inputProps={{
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      value={apiKey}
                    />
                  </FormControl>
                </InputWrapper>
              </Container>
              <Toolbar
                style={{
                  padding: "16px",
                  width: dialogWidth,
                  position: "relative",
                }}
              >
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      onClick={() => {
                        handleClose();
                      }}
                      variant="contained"
                      color="default"
                      style={{
                        fontSize: "12px",
                        textTransform: "none",
                        fontWeight: "bold",
                      }}
                    >
                      {getLocalizedText("cancel")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={() => {
                        navigator.clipboard.writeText(apiKey);
                        setOpenSnackBar(true);
                      }}
                      color="primary"
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        width: "fit-content",
                        textTransform: "none",
                      }}
                    >
                      Copy
                    </Button>
                  </Grid>
                </Grid>
              </Toolbar>
            </Box>
          </ModelContainer>
        ) : (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <Snackbar
          open={openSnackBar}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          onClose={() => {
            setOpenSnackBar(false);
          }}
          message="Copied to clipboard!"
        />
      </>
    </Modal>
  );
};

export default APIKeyDialog;
