import { Form } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
import FormAppbar from "./FormAppbar";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
const Container = styled(Box)`
  ${`height: calc(100vh - 64px);`};
  position: relative;
  width: 100%;
`;
registerCoreBlocks();
const QuillFormPreview = (props) => {
  return (
    <Container>
      <FormAppbar />
      <Form
        formId="1"
        formObj={{
          messages: {
            "label.button.ok": "Next",
          },
          settings: {
            showQuestionsNumbers: false,
          },
          blocks: props.blocksList,
        }}
        onSubmit={(
          data,
          { completeForm, setIsSubmitting, goToBlock, setSubmissionErr }
        ) => {
          console.log("form data::::", data);
          setTimeout(() => {
            setIsSubmitting(false);
            completeForm();
          }, 500);
        }}
      />
    </Container>
  );
};

export default QuillFormPreview;
