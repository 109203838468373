import { styled } from "@mui/material/styles";
import { Grid, Typography, Box, ListItem, ListItemText } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { CalendarToday } from "@mui/icons-material";
import PercentIcon from "@mui/icons-material/Percent";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
const AboutContainer = styled(Box)(({ theme, flag }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  border: flag ? "4px dashed #10ff00" : "none",
}));
const Container = styled(Box)(({ theme }) => ({
  [[theme.breakpoints.between("xs", "sm")]]: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  [[theme.breakpoints.between("sm", "md")]]: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  [theme.breakpoints.up("lg")]: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  marginBottom: theme.spacing(8),
  width: "100%",
  color: "#6FB835",
  [[theme.breakpoints.between("xs", "sm")]]: {
    font: "normal normal 700 20px open sans",
    marginBottom: theme.spacing(4),
  },
  [[theme.breakpoints.between("sm", "md")]]: {
    font: "normal normal 700 36px open sans",
    marginBottom: theme.spacing(6),
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    font: "normal normal 700 48px open sans",
    marginBottom: theme.spacing(6),
  },
  [theme.breakpoints.up("lg")]: {
    font: "normal normal 700 64px open sans",
    marginBottom: theme.spacing(8),
  },
}));
const Title2 = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  marginBottom: theme.spacing(2),
  font: "normal normal 500 24px open sans",
  [[theme.breakpoints.between("xs", "sm")]]: {
    font: "normal normal 500 16px open sans",
  },
  [[theme.breakpoints.between("sm", "md")]]: {
    font: "normal normal 500 24px open sans",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    font: "normal normal 500 32px open sans",
  },
  [theme.breakpoints.up("lg")]: {
    font: "normal normal 500 40px open sans",
  },
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  color: "#5A6066",
  font: "normal normal 400 20px open sans",
  marginTop: theme.spacing(2),
  height: "fit-content",
  width: "100%",
  textAlign: "center",
  [[theme.breakpoints.between("xs", "sm")]]: {
    font: "normal normal 400 7px open sans",
  },
  [[theme.breakpoints.between("sm", "md")]]: {
    font: "normal normal 400 12px open sans",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    font: "normal normal 400 18px open sans",
  },
  [theme.breakpoints.up("lg")]: {
    font: "normal normal 400 24px open sans",
  },
}));

const StyledIconBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: "#F9F9FB", //
  justifyContent: "center",
  height: "100%",
  flex: "1",
  width: "100%",

  [[theme.breakpoints.between("xs", "sm")]]: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  [[theme.breakpoints.between("sm", "md")]]: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  [theme.breakpoints.up("lg")]: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

const StyledIcon = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  backgroundColor: "#6FB835",
  boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
  // marginTop: theme.spacing(2),
  [[theme.breakpoints.between("xs", "sm")]]: {
    width: "32px",
    height: "32px",
  },
  [[theme.breakpoints.between("sm", "md")]]: {
    width: "48px",
    height: "48px",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    width: "64px",
    height: "64px",
  },
  [theme.breakpoints.up("lg")]: {
    width: "80px",
    height: "80px",
  },
}));

const Circle = styled(CircleIcon)(({ theme }) => ({
  color: "#000000",
  fontSize: "8px",
  [[theme.breakpoints.between("xs", "md")]]: {
    fontSize: "4xp",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    fontSize: "8px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "12px",
  },
}));
const Rupee = styled(CurrencyRupeeIcon)(({ theme }) => ({
  color: "#004C8F",
  fontSize: "24px",
  [[theme.breakpoints.between("xs", "sm")]]: {
    fontSize: "16px",
  },
  [[theme.breakpoints.between("sm", "md")]]: {
    fontSize: "24px",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    fontSize: "32px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "40px",
  },
}));
const Calendar = styled(CalendarToday)(({ theme }) => ({
  color: "#004C8F",
  fontSize: "24px",
  [[theme.breakpoints.between("xs", "sm")]]: {
    fontSize: "16px",
  },
  [[theme.breakpoints.between("sm", "md")]]: {
    fontSize: "24px",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    fontSize: "32px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "40px",
  },
}));
const Percent = styled(PercentIcon)(({ theme }) => ({
  color: "#004C8F",
  fontSize: "24px",
  [[theme.breakpoints.between("xs", "sm")]]: {
    fontSize: "16px",
  },
  [[theme.breakpoints.between("sm", "md")]]: {
    fontSize: "24px",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    fontSize: "32px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "40px",
  },
}));

const ListContainer = styled(Box)(({ theme, flag }) => ({
  width: "100%",
  height: "fit-content",
  textAlign: "left",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  marginBottom: theme.spacing(4),
  marginTop: theme.spacing(8),
  border: flag ? "4px dashed #10ff00" : "none",

  [[theme.breakpoints.between("xs", "sm")]]: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(4),
  },
  [[theme.breakpoints.between("sm", "md")]]: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(4),
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(6),
  },
  [theme.breakpoints.up("lg")]: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(8),
  },
}));

const ListItemContainer = styled(Box)(({ theme }) => ({
  [[theme.breakpoints.between("xs", "lg")]]: {
    paddingLeft: theme.spacing(2),
  },
  [theme.breakpoints.up("lg")]: {
    paddingLeft: theme.spacing(4),
  },
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  "& .MuiTypography-root": {
    font: "normal normal 400 20px open sans",
    paddingLeft: theme.spacing(2),

    [[theme.breakpoints.between("xs", "sm")]]: {
      font: "normal normal 400 12px open sans",
    },
    [[theme.breakpoints.between("sm", "md")]]: {
      font: "normal normal 400 16px open sans",
    },
    [[theme.breakpoints.between("md", "lg")]]: {
      font: "normal normal 400 20px open sans",
    },
    [theme.breakpoints.up("lg")]: {
      font: "normal normal 400 24px open sans",
    },
  },
}));

const StyledCaption = styled(Typography)(({ theme, flag }) => ({
  textAlign: "center",
  marginTop: theme.spacing(8),
  variant: "caption",
  component: "span",
  color: "#424343",
  font: "normal normal 400 16px open sans",
  border: flag ? "4px dashed #10ff00" : "none",
  [[theme.breakpoints.between("xs", "sm")]]: {
    font: "normal normal 400 8px open sans",
    marginTop: theme.spacing(2),
  },
  [[theme.breakpoints.between("sm", "md")]]: {
    font: "normal normal 400 12px open sans",
    marginTop: theme.spacing(4),
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    font: "normal normal 400 16px open sans",
    marginTop: theme.spacing(6),
  },
  [theme.breakpoints.up("lg")]: {
    font: "normal normal 400 20px open sans",
    marginTop: theme.spacing(8),
  },
}));

const renderIcon = (type) => {
  if (type.toLowerCase() === "calendar") {
    return <Calendar />;
  } else if (type.toLowerCase() === "percent") {
    return <Percent />;
  } else if (type.toLowerCase() === "rupee") {
    return <Rupee />;
  }
};

const AboutSection = ({
  pageSchema,
  scope,
  aboutSectionData,
  bottomSectionData,
  footerData,
}) => {
  return (
    <Container>
      <AboutContainer id="about_section" flag={scope === "about_section"}>
        <Title variant="h4">{aboutSectionData.heading}</Title>
        <Grid container spacing={{ xs: 0.5, sm: 0.5, md: 0.75, lg: 1, xl: 1 }}>
          {aboutSectionData.key_points.map((text, i) => {
            return (
              <Grid key={1} item xs={4} md={4} sm={4} lg={4} xl={4}>
                <StyledIconBox>
                  <StyledIcon>{renderIcon("calendar")}</StyledIcon>
                  <Subtitle>{text}</Subtitle>
                </StyledIconBox>
              </Grid>
            );
          })}
        </Grid>
      </AboutContainer>
      <ListContainer id="bottom_section" flag={scope === "bottom_section"}>
        <Title2 variant="h6">{bottomSectionData.heading}</Title2>
        <ListItemContainer>
          {bottomSectionData.key_points.map((text, i) => {
            return (
              <ListItem key={i} disablePadding style={{ textAlign: "start" }}>
                <Circle />
                <StyledListItemText primary={text} />
              </ListItem>
            );
          })}
        </ListItemContainer>
      </ListContainer>
      <StyledCaption id="footer_section" flag={scope === "footer_section"}>
        Copyright© {footerData.footer_text_left} |{" "}
        {footerData.footer_text_right}
      </StyledCaption>
    </Container>
  );
};

export default AboutSection;
