import React, { useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { Avatar, Box } from "@mui/material";
import { Add } from "@material-ui/icons";
import profile_placeholder from "../../../assets/profile_placeholder.png";
import { CircularProgress } from "@material-ui/core";
const ImageWrapper = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 1px solid #a7a6a8;
  padding: 0px;
  position: relative;
  background-color: white;
`;
const Image = styled("img")`
  aspect-ratio: 1/1;
  width: 100%;
  height: auto;
  border-radius: 50%;
  object-fit: cover;
`;

const UserProfileImageWithUploadComponent = ({
  handleUpload,
  url,
  handleImageClick,
  canShowUpload,
  scope,
}) => {
  const [loading, setLoading] = useState(true);
  const inputRef = useRef(null);
  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <ImageWrapper>
        <Image
          src={url !== null ? url : profile_placeholder}
          alt={profile_placeholder}
          onLoad={handleImageLoad}
          onClick={() => {
            if (url !== null) {
              handleImageClick();
            } else {
              inputRef.current.click();
            }
          }}
        />
        {loading && (
          <CircularProgress
            color="primary"
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              margin: "auto",
            }}
          />
        )}
        <input
          style={{ display: "none" }}
          type="file"
          ref={inputRef}
          accept="image/*"
          onClick={() => {
            inputRef.current.value = "";
          }}
          onChange={(event) => handleUpload(event)}
        />
        {!loading && canShowUpload && (
          <Avatar
            onClick={(e) => {
              inputRef.current.click();
            }}
            style={{
              backgroundColor: "white",
              position: "absolute",
              bottom: scope === "settings" ? "15px" : "0px",
              right: scope === "settings" ? "15px" : "0px",
              cursor: "pointer",
              color: "white",
              width: "30px",
              height: "30px",
            }}
          >
            <Add
              color="primary"
              style={{
                width: scope === "settings" ? "28px" : "18px",
                height: scope === "settings" ? "28px" : "18px",
              }}
            />
          </Avatar>
        )}
      </ImageWrapper>
    </Box>
  );
};

export default UserProfileImageWithUploadComponent;
