import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, Grid, makeStyles } from "@material-ui/core";
import {
  AppBar,
  Box,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Toolbar,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import DragDropFieldComponent from "./DragDropFieldComponent";
import PreviewPdfDialog from "./PreviewPdfDialog";
import PreviewImageDialog from "./PreviewImageDialog";
import { createFileApi, updateDocumentApi } from "../../Api";
import MDEditor from "@uiw/react-md-editor";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  gap: 20px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const fileTypeList = [
  { label: "Rich Text", value: "rich_text" },
  { label: "Image", value: "image" },
  { label: "PDF", value: "pdf" },
];

const AddFileSidePanel = (props) => {
  const classes = useStyles();
  const [details, setDetails] = useState({
    title: "",
    desc: "",
  });
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [fileType, setFileType] = useState("");
  const [file, setFile] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);
  const [editorValue, setEditorValue] = useState("");

  const handleClose = () => {
    props.setOpen(false);
    props.setIsEdit(false);
  };

  const submitFile = async () => {
    let formData = new FormData();
    formData.append("title", details.title);
    formData.append("desc", details.desc);
    if (fileType === "image" || fileType === "pdf") {
      formData.append("file_type", file.type);
      formData.append("file", file);
      formData.append("document_type", "file");
    } else {
      formData.append("data", editorValue);
      formData.append("document_type", "text");
    }
    if (props.isEdit) {
      let response = await updateDocumentApi({
        folderID: props.folderID,
        documentID: props.editableData["id"],
        payload: formData,
      });
      if (response.hasError()) {
        setErrorMessage(response.errorMessage);
        setIsSubmitFail(true);
      } else {
        props.setRefresh(true);
        setIsSubmitSuccess(true);
      }
    } else {
      let response = await createFileApi({
        folderID: props.folderID,
        payload: formData,
      });
      if (response.hasError()) {
        setErrorMessage(response.errorMessage);
        setIsSubmitFail(true);
      } else {
        props.setRefresh(true);
        setIsSubmitSuccess(true);
      }
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (fileType === "image" || fileType === "pdf") {
      if (file !== null) {
        submitFile();
      } else {
        setErrorMessage("Please upload a file before submitting!");
        setIsSubmitFail(true);
      }
    } else {
      submitFile();
    }
  };

  const handleFileType = (event) => {
    setFile(null);
    const selectedValue = event.target.value;
    setFileType(selectedValue);
    if (selectedValue === "image") {
      setAcceptedFiles({
        "image/jpeg": [],
        "image/png": [],
      });
    } else if (selectedValue === "pdf") {
      setAcceptedFiles({
        "application/pdf": [],
        "text/plain": [],
      });
    }
  };

  const RenderPreviewDialog = () => {
    if (fileType === "image") {
      return (
        <PreviewImageDialog
          open={openPreview}
          setOpen={setOpenPreview}
          file={file}
          isCreate={true}
        />
      );
    } else if (fileType === "pdf") {
      return (
        <PreviewPdfDialog
          open={openPreview}
          setOpen={setOpenPreview}
          file={URL.createObjectURL(file)}
        />
      );
    }
  };
  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (props.isEdit) {
      setDetails({
        title: props.editableData.title,
        desc: props.editableData.desc,
      });
      setEditorValue(props.editableData.data);
      setFileType("rich_text");
    }
  }, []);

  return (
    <>
      <Box role="presentation">
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: 500,
            right: 0,
            bgcolor: "#f9f9fc",
          }}
        >
          <Toolbar>
            {props.isEdit ? (
              <ModelHeader>Edit Details</ModelHeader>
            ) : (
              <ModelHeader>Add File</ModelHeader>
            )}
          </Toolbar>
        </AppBar>
        {!isSubmitSuccess ? (
          <ModelWrapper component={"form"} onSubmit={handleSubmit}>
            <InputContainer>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>Title*</Label>
                    <OutlinedInput
                      style={{ height: "30px" }}
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      onChange={handleChange}
                      name="title"
                      required
                      disabled={props.isEdit}
                      value={details["title"]}
                      placeholder="Enter value"
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>Description*</Label>
                    <OutlinedInput
                      multiline={true}
                      rows={3}
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      required
                      onChange={handleChange}
                      name="desc"
                      value={details["desc"]}
                      placeholder="Enter value"
                      disabled={props.isEdit}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>File Type*</Label>
                    <FormControl
                      sx={{ width: "100%" }}
                      classes={{
                        root: classes.quantityRoot,
                      }}
                    >
                      <Select
                        classes={{
                          icon: classes.icon,
                        }}
                        displayEmpty
                        value={fileType}
                        required
                        disabled={props.isEdit}
                        onChange={handleFileType}
                        name="file_type"
                        disableUnderline
                        variant="outlined"
                        style={{
                          width: "100%",
                          height: "30px",
                          fontSize: "12px",
                          borderRadius: "0px",
                        }}
                      >
                        {fileTypeList.map((item, i) => {
                          return (
                            <MenuItem
                              value={item.value}
                              key={i}
                              style={{ fontSize: "12px" }}
                            >
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </InputWrapper>
                </Grid>
                {(fileType === "image" || fileType === "pdf") && (
                  <Grid item xs={12}>
                    <DragDropFieldComponent
                      acceptedFiles={acceptedFiles}
                      setAcceptedFiles={setAcceptedFiles}
                      setFile={setFile}
                      file={file}
                      setOpenPreview={setOpenPreview}
                      fileType={fileType}
                    />
                  </Grid>
                )}
                {fileType === "rich_text" && (
                  <Grid item xs={12}>
                    <div className="container" data-color-mode="light">
                      <MDEditor
                        value={editorValue}
                        onChange={setEditorValue}
                        preview="edit"
                      />
                    </div>
                  </Grid>
                )}
              </Grid>
            </InputContainer>

            <AppBar
              position="fixed"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: 500 }}
            >
              <Toolbar style={{ width: 500, position: "relative" }}>
                {isSubmitFail && (
                  <ErrorAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setIsSubmitFail(false);
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {errorMessage}
                  </ErrorAlert>
                )}
                <ButtonWrapper>
                  <Button
                    onClick={handleClose}
                    type="button"
                    variant="contained"
                    color="default"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      backgroundColor: "#185DD2",
                      color: "white",
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    Submit
                  </Button>
                </ButtonWrapper>
              </Toolbar>
            </AppBar>
          </ModelWrapper>
        ) : (
          <MessageWrapper>
            {props.isEdit ? (
              <Message>File updated successfully!</Message>
            ) : (
              <Message>File added successfully!</Message>
            )}
            <Button
              onClick={handleClose}
              type="button"
              variant="contained"
              color="default"
              style={{
                marginTop: "20px",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              Close
            </Button>
          </MessageWrapper>
        )}
      </Box>
      {openPreview && <RenderPreviewDialog />}
    </>
  );
};

export default AddFileSidePanel;
