import React, { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  CircularProgress,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Select,
  makeStyles,
} from "@material-ui/core";
import {
  AppBar,
  Box,
  MenuItem,
  OutlinedInput,
  TextField,
  Toolbar,
  Typography,
  Button,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import MuiAlert from "@mui/material/Alert";
import analytics from "../../Analytics";
import {
  addAdminColumnApi,
  deleteAdminCustomColumnApi,
  editAdminColumnApi,
  editAdminSystemColumnApi,
  getAllColsApi,
  getProjectAdministrationColumnGroupsApi,
} from "../../Api";
import { getLocalizedText, validateDisabledSubFeature } from "../../Functions";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import DraggableChipComponent from "./draggable_chip_component/DraggableChipComponent";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import CustomFieldHeader from "../custom_tooltip_headers/CustomFieldHeader";
import { MultiSelect } from "react-multi-select-component";
import { Add } from "@material-ui/icons";
import ParentFieldConfigurationSidepanel from "./add_column_components/ParentFieldConfigurationSidepanel";
import rootStore from "../../stores/RootStore";
import { LoadingButton } from "@mui/lab";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 18px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  column-gap: 16px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  gap: 20px;
`;
const CustomizedDatePicker = styled(DatePicker)`
  & .MuiInputBase-input {
    font-size: 12px;
    padding: 7.5px 14px;
  }
`;
const CustomizedDateTimePicker = styled(DateTimePicker)`
  & .MuiInputBase-input {
    font-size: 12px;
    padding: 7.5px 14px;
  }
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const flagFields = [
  "filterable",
  "editable",
  "is_visible",
  "enable_for_create",
  "show_in_edit_form",
  "set_title_case",
  "is_form_field",
];
const AddColumnSidepanel = (props) => {
  const classes = useStyles();
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({
    display_name: "",
    group: "",
    filterable: false,
    editable: false,
    is_visible: false,
    data_type: "",
    required: false,
    enable_for_create: false,
    show_in_edit_form: false,
    set_title_case: false,
    is_form_field: false,
    is_multiline: false,
    hint: "",
    max: "",
    min: "",
  });
  const dataTypeList = ["string", "integer", "date", "datetime", "boolean"];
  const filterTypeList = {
    string: ["normal", "list"],
    integer: ["normal", "range", "list"],
    date: ["normal", "range"],
    datetime: ["normal", "range"],
    boolean: ["normal"],
  };
  const [inputList, setInputList] = useState([]);
  const [filterType, setFilterType] = useState("");
  const [radioVal, setRadioVal] = useState("no");
  const [editableVal, setEditableVal] = useState("no");
  const [requiredVal, setRequiredVal] = useState("no");
  const [enableForCreate, setEnableForCreate] = useState("no");
  const [enableForEdit, setEnableForEdit] = useState("no");
  const [isVisible, setIsVisible] = useState("no");
  const [disabledFields, setDisabledFields] = useState([]);
  const [isTitleCase, setIsTitleCase] = useState("no");
  const [isFormField, setIsFormField] = useState("no");
  const [isMultline, setIsMultline] = useState("no");

  const [groupList, setGroupList] = useState([]);
  const [groupListForField, setGroupListForField] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [openParentFieldConfigDialog, setOpenParentFieldConfigDialog] =
    useState(false);
  const [childrenConfig, setChildrenConfig] = useState({});
  const [columnsList, setColumnsList] = useState([]);
  const [childrenToRemove, setChildrenToRemove] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const moveChip = useCallback(
    (dragIndex, hoverIndex) => {
      const updatedChips = [...inputList];
      const [removed] = updatedChips.splice(dragIndex, 1);
      updatedChips.splice(hoverIndex, 0, removed);
      setInputList(updatedChips);
    },
    [inputList]
  );

  const handleClose = () => {
    props.setOpen(false);
    props.setIsEdit(false);
  };

  const isAnyFlagVariableUpdated = () => {
    for (let i = 0; i < flagFields.length; i++) {
      if (user[flagFields[i]] !== props.editableData[flagFields[i]]) {
        return true;
      }
    }
    return false;
  };

  const generatePayload = ({ childrenConfig }) => {
    let data = {
      display_name: user.display_name,
      is_visible: user.is_visible,
      filterable: user.filterable,
      editable: user.editable,
      required: user.required,
      enable_for_create: user.enable_for_create,
      show_in_edit_form: user.show_in_edit_form,
      data_type: user.data_type,
      set_title_case: user.set_title_case,
      is_form_field: user.is_form_field,
      is_multiline: user.is_multiline,
    };

    if (Object.keys(childrenConfig).length > 0) {
      data["parent"] = true;
      let tempList = {};
      Object.keys(childrenConfig).forEach((key) => {
        let idList = childrenConfig[key].map((col) => col["id"]);
        tempList[key] = idList;
      });
      data["children_config"] = tempList;
    }
    let tempList = selectedGroups.map((group) => group["value"]);
    data["group"] = tempList.join(",");

    data["filter_type"] = filterType;
    data["filter_input"] = inputList.toString();

    if (user.hint !== "") {
      data["hint"] = user.hint;
    }

    if (user.data_type === "integer") {
      data["min"] = user.min !== "" ? parseInt(user.min) : null;
      data["max"] = user.max !== "" ? parseInt(user.max) : null;
    } else if (user.data_type === "date" || user.data_type === "datetime") {
      data["min"] = user.min !== "" ? user.min : null;
      data["max"] = user.max !== "" ? user.max : null;
    }

    return data;
  };

  const addColumn = async () => {
    let field = "custom_params_" + user.display_name.replace(" ", "_");
    let payload = generatePayload({ childrenConfig: childrenConfig });
    payload["field"] = field;
    let response = await addAdminColumnApi({
      id: props.id,
      payload: payload,
    });

    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      props.setRefresh(!props.refresh);
      setIsSubmitSuccess(true);
    }
  };

  const getChildPayloadWithUpdatedValues = ({ payloadData }) => {
    let payload = {
      ...payloadData,
      required: false,
    };
    flagFields.forEach((str) => {
      payload[str] = user[str];
    });
    if (payload["_update_required"] === false) {
      payload["display_name"] = payloadData.headerName;
      payload["child_of"] = payload["child_of"].hasOwnProperty("id")
        ? payload["child_of"]["id"]
        : "";
      if (payload.hasOwnProperty("id")) {
        delete payload["id"];
      }
    }
    payload["_update_required"] = true;
    if (!payload.hasOwnProperty("_update_type")) {
      payload["_update_type"] = "edit";
    }

    return payload;
  };

  const updateChildrenWithUpdatedFlagValues = ({ childrenConfig }) => {
    let tempList = { ...childrenConfig };
    for (let key in tempList) {
      let children = [...tempList[key]];

      for (let i = 0; i < children.length; i++) {
        children[i] = getChildPayloadWithUpdatedValues({
          payloadData: children[i],
        });
      }

      tempList[key] = children;
    }
    return tempList;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (props.isEdit) {
      let tempChildrenConfig = { ...childrenConfig };
      if (isAnyFlagVariableUpdated()) {
        tempChildrenConfig = updateChildrenWithUpdatedFlagValues({
          childrenConfig: tempChildrenConfig,
        });
      }
      // await editColumn();
      await checkAndUpdateChildrenConfig({
        childrenConfig: tempChildrenConfig,
      });
    } else {
      await addColumn();
    }
    setIsSubmitting(false);
  };

  const getProjectColumns = async () => {
    try {
      let response = await getAllColsApi(rootStore.authStore.projectId);
      return response.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const init = async () => {
    let response = await getProjectAdministrationColumnGroupsApi();

    let newList = [];
    response.forEach((e) => {
      let newObj = { label: e.group_name, value: e.group_name };
      newList.push(newObj);
    });

    setGroupList(response);
    setGroupListForField(newList);

    if (props.isEdit === true) {
      analytics.triggerEvent(
        4625000231,
        "edit_column_form_load",
        "project_columns_page",
        "",
        {}
      );
      let tempColumns = await getProjectColumns();
      setColumnsList(tempColumns);
      let tempObj = {
        display_name: props.editableData.name,
        filterable: props.editableData.filterable,
        is_visible: props.editableData.is_visible,
        editable: props.editableData.editable,
        data_type: props.editableData.data_type,
        required: props.editableData.required,
        enable_for_create: props.editableData.enable_for_create,
        show_in_edit_form: props.editableData.show_in_edit_form,
        is_form_field: props.editableData.is_form_field,
        is_multiline: props.editableData.is_multiline,
        hint: props.editableData.hint !== null ? props.editableData.hint : "",
        set_title_case:
          props.editableData.hasOwnProperty("set_title_case") &&
          props.editableData.set_title_case === true
            ? true
            : false,
      };
      if (props.editableData.parent === true) {
        if (props.editableData.children_config !== null) {
          let tempList = {};

          Object.keys(props.editableData.children_config).forEach((key) => {
            let tempColumnDataList = [];

            props.editableData.children_config[key].forEach((id) => {
              let index = tempColumns.findIndex((col) => col["id"] === id);

              if (index !== -1) {
                let tempObj = {
                  ...tempColumns[index],
                  _update_required: false,
                  column_id: id,
                };

                // Check for map_to_parent_selection
                if (tempColumns[index]["map_to_parent_selection"] === true) {
                  let tempInputs = tempColumns[index]["filter_input"];
                  let newInputs = [];

                  // Attempt to parse filter_input
                  try {
                    let parsedInputData = tempInputs
                      ? JSON.parse(tempInputs)
                      : {};
                    if (parsedInputData[key]) {
                      newInputs = parsedInputData[key];
                    }
                  } catch (error) {
                    console.error(
                      `Error parsing filter_input for key: ${key}. Input: ${tempInputs}`,
                      error
                    );
                    newInputs = [];
                  }

                  tempObj["filter_input"] = newInputs.join(",");
                }

                tempColumnDataList.push(tempObj);
              }
            });

            tempList[key] = tempColumnDataList;
          });

          setChildrenConfig(tempList);
        }
      }

      if (
        props.editableData.group !== null &&
        props.editableData.group !== ""
      ) {
        let tempList = props.editableData.group.split(",");
        let newList = [];
        tempList.forEach((e) => {
          let newObj = { label: e, value: e };
          newList.push(newObj);
        });
        setSelectedGroups(newList);
      }

      if (props.editableData.data_type === "integer") {
        tempObj["min"] =
          props.editableData.min !== null ? props.editableData.min : "";
        tempObj["max"] =
          props.editableData.max !== null ? props.editableData.max : "";
      } else if (
        props.editableData.data_type === "date" ||
        props.editableData.data_type === "datetime"
      ) {
        tempObj["min"] =
          props.editableData.min !== null && props.editableData.min !== ""
            ? moment(props.editableData.min)
            : "";
        tempObj["max"] =
          props.editableData.max !== null && props.editableData.max !== ""
            ? moment(props.editableData.max)
            : "";
      }
      setUser(tempObj);
      setRadioVal(props.editableData.filterable ? "yes" : "no");
      setEditableVal(props.editableData.editable ? "yes" : "no");
      setRequiredVal(props.editableData.required ? "yes" : "no");
      setEnableForCreate(props.editableData.enable_for_create ? "yes" : "no");
      setEnableForEdit(props.editableData.show_in_edit_form ? "yes" : "no");
      setIsVisible(props.editableData.is_visible ? "yes" : "no");
      setIsTitleCase(
        props.editableData.hasOwnProperty("set_title_case") &&
          props.editableData.set_title_case === true
          ? "yes"
          : "no"
      );
      setIsFormField(
        props.editableData.hasOwnProperty("is_form_field") &&
          props.editableData.is_form_field === true
          ? "yes"
          : "no"
      );
      setIsMultline(
        props.editableData.hasOwnProperty("is_multiline") &&
          props.editableData.is_multiline === true
          ? "yes"
          : "no"
      );
      if (props.editableData.hasOwnProperty("filter_type")) {
        setFilterType(props.editableData.filter_type);
        if (
          props.editableData.filter_input !== "" &&
          props.editableData.filter_input !== null
        ) {
          let newList = props.editableData.filter_input.split(",");
          setInputList(newList);
        }
      }
    } else {
      analytics.triggerEvent(
        4625000227,
        "add_column_form_load",
        "project_columns_page",
        "",
        {}
      );
    }

    setDisabledList();

    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const checkKeyDown = (e) => {
    if (e.code === "Enter") e.preventDefault();
  };
  const handleFilterInput = (e) => {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    if (!inputList.includes(value)) {
      setInputList([...inputList, value]);
    }
    e.target.value = "";
  };
  const handleDelete = (input) => {
    let tempList = inputList.filter((item) => item !== input);
    setInputList(tempList);
  };
  const setDisabledList = () => {
    if (props.isEdit) {
      if (props.editableData["derived"]) {
        setDisabledFields([
          "group",
          "column_name",
          "required",
          "enable_for_create",
          "show_in_edit_form",
          "is_visible",
          "data_type",
          "is_parent",
        ]);
      } else if (props.editableData["system"]) {
        setDisabledFields(["column_name", "group", "data_type"]);
      }
    }
  };

  const isAddChildrenButtonDisabled = () => {
    if (filterType === "list") {
      return inputList.length === 0;
    }
    return true;
  };

  //*******************functions related to children config***************** */ */

  const addChildColumn = async ({ payloadData }) => {
    let payload = { ...payloadData };
    if (payload.hasOwnProperty("_update_required")) {
      delete payload["_update_required"];
    }
    if (payload.hasOwnProperty("_update_type")) {
      delete payload["_update_type"];
    }

    if (payload.hasOwnProperty("column_id")) {
      delete payload["column_id"];
    }
    let response = await addAdminColumnApi({
      id: props.id,
      payload: payload,
    });

    return response;
  };
  const editChildColumn = async ({ payloadData }) => {
    let payload = { ...payloadData };
    if (payload.hasOwnProperty("_update_required")) {
      delete payload["_update_required"];
    }
    if (payload.hasOwnProperty("_update_type")) {
      delete payload["_update_type"];
    }

    let response = await editAdminColumnApi({
      id: props.id,
      payload: payload,
    });

    return response;
  };

  const editParentColumn = async ({ childrenConfig }) => {
    let payload = generatePayload({ childrenConfig: childrenConfig });
    payload["column_id"] = props.editableData.id;
    payload["field"] = props.editableData.field;
    if (Object.keys(childrenConfig).length > 0) {
      payload["parent"] = true;
      let tempList = {};
      Object.keys(childrenConfig).forEach((key) => {
        let idList = childrenConfig[key].map((col) => col["id"]);
        tempList[key] = idList;
      });
      payload["children_config"] = tempList;
    }
    let response;
    if (props.editableData.system) {
      response = await editAdminSystemColumnApi({
        id: props.id,
        payload: payload,
      });
    } else {
      response = await editAdminColumnApi({
        id: props.id,
        payload: payload,
      });
    }

    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      props.setRefresh(!props.refresh);
      setIsSubmitSuccess(true);
    }
  };

  const checkDuplicateChildrenAndUpdateConfig = ({ childrenConfig }) => {
    const tempList = { ...childrenConfig };
    const childrenOccurrence = {};

    // First loop: Track occurrences of children based on column_id
    Object.keys(tempList).forEach((key) => {
      const children = tempList[key];
      children.forEach((child) => {
        const columnId = child.column_id;

        const tempObj = { ...child, _parent_value: key };

        if (childrenOccurrence[columnId]) {
          // If the child column_id already exists, increment count and add to children
          childrenOccurrence[columnId].count += 1;
          childrenOccurrence[columnId].children.push(tempObj);
        } else {
          // Otherwise, initialize with a count of 1 and store the child
          childrenOccurrence[columnId] = {
            count: 1,
            children: [tempObj],
          };
        }
      });
    });

    // Second loop: Update the children based on their occurrence count
    Object.keys(childrenOccurrence).forEach((key) => {
      const { count, children: tempChildren } = childrenOccurrence[key];

      if (count > 1) {
        // Handle duplicates
        const tempInputList = {};

        tempChildren.forEach((child) => {
          const tempInputs = child.filter_input.split(",");
          tempInputList[child._parent_value] = tempInputs;
        });
        let tempChildData = {};
        let updateRequiredChildIndex = tempChildren.findIndex(
          (column) => column["_update_required"] === true
        );
        if (updateRequiredChildIndex !== -1) {
          tempChildData = { ...tempChildren[updateRequiredChildIndex] };
        } else {
          tempChildData = { ...tempChildren[0] };
        }

        tempChildData["filter_input"] = JSON.stringify(tempInputList);
        tempChildData["map_to_parent_selection"] = true;

        childrenOccurrence[key].children = tempChildData;
      } else {
        // No duplicate, retain the single child
        childrenOccurrence[key].children = {
          ...tempChildren[0],
          map_to_parent_selection: false,
        };
      }
    });

    // Third loop: Update the original tempList with the modified childrenOccurrence data
    Object.keys(tempList).forEach((key) => {
      tempList[key] = tempList[key].map((child) => {
        let updatedChild =
          childrenOccurrence[child.column_id]?.children || child;

        // If _parent_value exists, remove it before updating the child
        if (updatedChild._parent_value) {
          const { _parent_value, ...rest } = updatedChild;
          updatedChild = rest; // Exclude _parent_value
        }

        return updatedChild;
      });
    });
    return tempList;
  };

  const updateChildren = async ({ childrenConfig }) => {
    let tempList = { ...childrenConfig };
    let updatedTempList = {};
    for (let key in tempList) {
      let children = tempList[key];
      let tempChildren = [];
      for (let i = 0; i < children.length; i++) {
        if (children[i]["_update_required"] === true) {
          let response;
          if (children[i]["_update_type"] === "add") {
            response = await addChildColumn({ payloadData: children[i] });
          } else {
            response = await editChildColumn({ payloadData: children[i] });
          }
          if (response.hasError()) {
            // setErrorMessage(response.errorMessage);
            // setIsSubmitFail(true);
          } else {
            let tempObj = { ...response.data, _update_required: false };
            tempChildren.push(tempObj);
          }
        } else {
          tempChildren.push(children[i]);
        }
      }
      updatedTempList[key] = tempChildren;
    }
    return updatedTempList;
  };

  const handleChildrenRemove = async () => {
    for (let i = 0; i < childrenToRemove.length; i++) {
      if (childrenToRemove[i]["_update_required"]) {
        if (childrenToRemove[i]["_update_type"] === "edit") {
          let response = await editChildColumn({
            payloadData: childrenToRemove[i],
          });
          if (response.hasError()) {
            //do something
          }
        }
      } else {
        let payloadData = { ...childrenToRemove[i] };
        if (payloadData.hasOwnProperty("headerName")) {
          payloadData["display_name"] = payloadData["headerName"];
          delete payloadData["headerName"];
        }
        if (payloadData.hasOwnProperty("id")) {
          delete payloadData["id"];
        }
        let response = await editChildColumn({
          payloadData: payloadData,
        });
        if (response.hasError()) {
          //do something
        }
      }
    }
  };

  const checkAndUpdateChildrenConfig = async ({ childrenConfig }) => {
    let updatedChildrenConfigWithDuplicateHandled =
      checkDuplicateChildrenAndUpdateConfig({ childrenConfig: childrenConfig });
    if (childrenToRemove.length > 0) {
      await handleChildrenRemove();
      setChildrenToRemove([]);
    }
    let updatedChildrenConfig = await updateChildren({
      childrenConfig: updatedChildrenConfigWithDuplicateHandled,
    });
    await editParentColumn({ childrenConfig: updatedChildrenConfig });
  };
  //**************************end************************************** */

  const renderMinMaxFields = () => {
    if (user.data_type === "integer") {
      return (
        <Grid item xs={12}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <InputWrapper>
              <CustomFieldHeader
                header={"Min"}
                tooltip={"Specify the minimum value allowed for this column."}
              />
              <OutlinedInput
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                  max: user.max,
                }}
                value={user.min}
                type={"number"}
                placeholder="Enter value"
                name="min"
                onChange={handleChange}
              />
            </InputWrapper>

            <InputWrapper>
              <CustomFieldHeader
                header={"Max"}
                tooltip={"Specify the maximum value allowed for this column."}
              />
              <OutlinedInput
                style={{ height: "30px" }}
                inputProps={{
                  min: user.min,
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                required={user.min !== ""}
                value={user.max}
                type={"number"}
                placeholder="Enter value"
                name="max"
                onChange={handleChange}
              />
            </InputWrapper>
          </Box>
        </Grid>
      );
    } else if (user.data_type === "date") {
      return (
        <Grid item xs={12}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <InputWrapper>
              <CustomFieldHeader
                header={"Min"}
                tooltip={"Specify the minimum value allowed for this column."}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomizedDatePicker
                  maxDate={user.max !== "" ? user.max : null}
                  value={user.min !== "" ? user.min : null}
                  onChange={(event) => {
                    setUser({ ...user, min: event });
                  }}
                  inputFormat="YYYY-MM-DD"
                  renderInput={(params) => (
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </InputWrapper>
            <InputWrapper>
              <CustomFieldHeader
                header={"Max"}
                tooltip={"Specify the maximum value allowed for this column."}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomizedDatePicker
                  disabled={user.min === ""}
                  value={user.max !== "" ? user.max : null}
                  minDate={user.min !== "" ? user.min : null}
                  onChange={(event) => {
                    setUser({ ...user, max: event });
                  }}
                  inputFormat="YYYY-MM-DD"
                  renderInput={(params) => (
                    <TextField
                      required={user.min !== ""}
                      sx={{ width: "100%" }}
                      size="small"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </InputWrapper>
          </Box>
        </Grid>
      );
    } else if (user.data_type === "datetime") {
      return (
        <Grid item xs={12}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <InputWrapper>
              <CustomFieldHeader
                header={"Min"}
                tooltip={"Specify the minimum value allowed for this column."}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomizedDateTimePicker
                  maxDateTime={user.max !== "" ? user.max : null}
                  value={user.min !== "" ? user.min : null}
                  onChange={(event) => {
                    setUser({ ...user, min: event });
                  }}
                  inputFormat="YYYY-MM-DD HH:mm A"
                  renderInput={(params) => (
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </InputWrapper>

            <InputWrapper>
              <CustomFieldHeader
                header={"Max"}
                tooltip={"Specify the maximum value allowed for this column."}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomizedDateTimePicker
                  value={user.max !== "" ? user.max : null}
                  minDateTime={user.min !== "" ? user.min : null}
                  onChange={(event) => {
                    setUser({ ...user, max: event });
                  }}
                  inputFormat="YYYY-MM-DD HH:mm A"
                  disabled={user.min === ""}
                  renderInput={(params) => (
                    <TextField
                      required={user.min !== ""}
                      sx={{ width: "100%" }}
                      size="small"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </InputWrapper>
          </Box>
        </Grid>
      );
    } else {
      return (
        <Grid item xs={12}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <InputWrapper>
              <CustomFieldHeader
                header={"Min"}
                tooltip={"Specify the minimum value allowed for this column."}
              />
              <OutlinedInput
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                type="text"
                placeholder="Enter value"
                name="min"
                disabled
              />
            </InputWrapper>

            <InputWrapper>
              <CustomFieldHeader
                header={"Max"}
                tooltip={"Specify the maximum value allowed for this column."}
              />
              <OutlinedInput
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                type="text"
                disabled
                placeholder="Enter value"
                name="max"
              />
            </InputWrapper>
          </Box>
        </Grid>
      );
    }
  };

  const hasAnyChildColumn = () => {
    for (let parentValue in childrenConfig) {
      let children = childrenConfig[parentValue];
      if (children.length > 0) {
        return true;
      }
    }
    return false;
  };

  return !loading ? (
    <>
      <Box role="presentation">
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: 500,
            right: 0,
            bgcolor: "#f9f9fc",
          }}
        >
          <Toolbar>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              {props.isEdit ? (
                <ModelHeader>{getLocalizedText("edit_details")}</ModelHeader>
              ) : (
                <ModelHeader>
                  {getLocalizedText("add_a_new_column")}
                </ModelHeader>
              )}
              {!isSubmitSuccess && props.isEdit && (
                <Button
                  disabled={isAddChildrenButtonDisabled()}
                  style={{
                    textTransform: "none",
                    fontSize: "14px",
                    fontWeight: "bold",
                    width: "fit-content",
                  }}
                  onClick={() => setOpenParentFieldConfigDialog(true)}
                  color="primary"
                  variant="outlined"
                >
                  <Add />{" "}
                  {hasAnyChildColumn() ? "Update Children" : "Add Children"}
                </Button>
              )}
            </Box>
          </Toolbar>
        </AppBar>
        {!isSubmitSuccess ? (
          <ModelWrapper
            component={"form"}
            onSubmit={handleSubmit}
            onKeyDown={(e) => checkKeyDown(e)}
          >
            <InputContainer>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputWrapper>
                    <CustomFieldHeader
                      header={getLocalizedText("column_name")}
                      tooltip={"Enter the name of the new lead column."}
                      required={true}
                    />
                    <OutlinedInput
                      style={{ height: "30px" }}
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                        pattern: "^[a-zA-Z0-9_ ]+$",
                      }}
                      value={user.display_name}
                      disabled={disabledFields.includes("column_name")}
                      required
                      type="text"
                      placeholder="Enter name here..."
                      name="display_name"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </Grid>
                {!disabledFields.includes("group") && (
                  <Grid item xs={12}>
                    <InputWrapper>
                      <CustomFieldHeader
                        header={getLocalizedText("group_name")}
                        tooltip={"Select the group this column belongs to."}
                      />

                      <MultiSelect
                        hasSelectAll={false}
                        options={groupListForField}
                        value={selectedGroups}
                        className={"chart_multi_select"}
                        onChange={(e) => {
                          setSelectedGroups(e);
                        }}
                        labelledBy="Select Group"
                      />
                    </InputWrapper>
                  </Grid>
                )}

                {dataTypeList && (
                  <Grid item xs={6}>
                    <InputWrapper>
                      <CustomFieldHeader
                        header={getLocalizedText("select_data_type")}
                        tooltip={"Specify the column's data type."}
                        required={true}
                      />
                      <FormControl
                        sx={{ width: "100%" }}
                        classes={{
                          root: classes.quantityRoot,
                        }}
                        disabled={props.isEdit === true}
                      >
                        <Select
                          classes={{
                            icon: classes.icon,
                          }}
                          value={user.data_type}
                          name="data_type"
                          displayEmpty
                          disableUnderline
                          variant="outlined"
                          required
                          onChange={(e) => {
                            setIsTitleCase("no");
                            setIsMultline("no");
                            setUser({
                              ...user,
                              data_type: e.target.value,
                              min: "",
                              max: "",
                              set_title_case: false,
                              is_multiline: false,
                            });
                            setFilterType(filterTypeList[e.target.value][0]);
                            setInputList([]);
                          }}
                          style={{
                            width: "100%",
                            height: "30px",
                            fontSize: "12px",
                            fontWeight: "bold",
                            borderRadius: "0px",
                          }}
                        >
                          {dataTypeList.map((e) => {
                            return (
                              <MenuItem id={e} value={e}>
                                {e}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </InputWrapper>
                  </Grid>
                )}
                <Grid item xs={6}>
                  <InputWrapper>
                    <CustomFieldHeader
                      header={getLocalizedText("select_filter_type")}
                      tooltip={"Specify the column's filter type."}
                    />
                    <FormControl
                      sx={{ width: "100%" }}
                      classes={{
                        root: classes.quantityRoot,
                      }}
                    >
                      <Select
                        value={filterType}
                        classes={{
                          icon: classes.icon,
                        }}
                        name="filter_type"
                        displayEmpty
                        disableUnderline
                        variant="outlined"
                        onChange={(e) => {
                          setFilterType(e.target.value);
                          setIsMultline("no");
                          setUser({ ...user, is_multiline: false });
                          setInputList([]);
                        }}
                        style={{
                          width: "100%",
                          height: "30px",
                          fontSize: "12px",
                          fontWeight: "bold",
                          borderRadius: "0px",
                        }}
                      >
                        {filterTypeList[user.data_type] &&
                          filterTypeList[user.data_type].map((e) => {
                            return (
                              <MenuItem id={e} value={e}>
                                {e}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </InputWrapper>
                </Grid>

                <Grid item xs={12}>
                  <InputWrapper
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <CustomFieldHeader
                      header={getLocalizedText("is_visible")}
                      tooltip={
                        "Specify if the column should be displayed in listing and details."
                      }
                      required={true}
                      style={{ marginBottom: "0px", minWidth: "140px" }}
                    />
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="yes"
                        control={
                          <Radio
                            size="small"
                            disabled={disabledFields.includes("is_visible")}
                            checked={isVisible === "yes"}
                            onChange={(e) => {
                              setIsVisible(e.target.value);

                              setUser({ ...user, is_visible: true });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("yes")}
                          </Label>
                        }
                      />
                      <FormControlLabel
                        value="no"
                        control={
                          <Radio
                            size="small"
                            disabled={disabledFields.includes("is_visible")}
                            checked={isVisible === "no"}
                            onChange={(e) => {
                              setIsVisible(e.target.value);

                              setUser({ ...user, is_visible: false });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("no")}
                          </Label>
                        }
                      />
                    </RadioGroup>
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <CustomFieldHeader
                      header={getLocalizedText("required")}
                      tooltip={"Specify if this column is mandatory in forms."}
                      required={true}
                      style={{ marginBottom: "0px", minWidth: "140px" }}
                    />
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="yes"
                        disabled={disabledFields.includes("required")}
                        control={
                          <Radio
                            size="small"
                            checked={requiredVal === "yes"}
                            onChange={(e) => {
                              setRequiredVal(e.target.value);

                              setUser({ ...user, required: true });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("yes")}
                          </Label>
                        }
                      />
                      <FormControlLabel
                        value="no"
                        disabled={disabledFields.includes("required")}
                        control={
                          <Radio
                            size="small"
                            checked={requiredVal === "no"}
                            onChange={(e) => {
                              setRequiredVal(e.target.value);
                              setUser({ ...user, required: false });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("no")}
                          </Label>
                        }
                      />
                    </RadioGroup>
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <CustomFieldHeader
                      header={getLocalizedText("filterable")}
                      tooltip={"Specify if this column is filterable."}
                      required={true}
                      style={{ marginBottom: "0px", minWidth: "140px" }}
                    />
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="yes"
                        control={
                          <Radio
                            size="small"
                            checked={radioVal === "yes"}
                            onChange={(e) => {
                              setRadioVal(e.target.value);

                              setUser({ ...user, filterable: true });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("yes")}
                          </Label>
                        }
                      />
                      <FormControlLabel
                        value="no"
                        control={
                          <Radio
                            size="small"
                            checked={radioVal === "no"}
                            onChange={(e) => {
                              setRadioVal(e.target.value);
                              setUser({ ...user, filterable: false });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("no")}
                          </Label>
                        }
                      />
                    </RadioGroup>
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <CustomFieldHeader
                      header={getLocalizedText("editable")}
                      tooltip={"Specify if this column is editable."}
                      required={true}
                      style={{ marginBottom: "0px", minWidth: "140px" }}
                    />
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="yes"
                        control={
                          <Radio
                            size="small"
                            checked={editableVal === "yes"}
                            onChange={(e) => {
                              setEditableVal(e.target.value);

                              setUser({ ...user, editable: true });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("yes")}
                          </Label>
                        }
                      />
                      <FormControlLabel
                        value="no"
                        control={
                          <Radio
                            size="small"
                            checked={editableVal === "no"}
                            onChange={(e) => {
                              setEditableVal(e.target.value);
                              setUser({ ...user, editable: false });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("no")}
                          </Label>
                        }
                      />
                    </RadioGroup>
                  </InputWrapper>
                </Grid>

                <Grid item xs={12}>
                  <InputWrapper
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <CustomFieldHeader
                      header={"Show in Create Form"}
                      tooltip={"Include this column in the create form."}
                      required={true}
                      style={{ marginBottom: "0px", minWidth: "140px" }}
                    />
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="yes"
                        control={
                          <Radio
                            size="small"
                            disabled={disabledFields.includes(
                              "enable_for_create"
                            )}
                            checked={enableForCreate === "yes"}
                            onChange={(e) => {
                              setEnableForCreate(e.target.value);

                              setUser({ ...user, enable_for_create: true });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("yes")}
                          </Label>
                        }
                      />
                      <FormControlLabel
                        value="no"
                        control={
                          <Radio
                            size="small"
                            disabled={disabledFields.includes(
                              "enable_for_create"
                            )}
                            checked={enableForCreate === "no"}
                            onChange={(e) => {
                              setEnableForCreate(e.target.value);
                              setUser({ ...user, enable_for_create: false });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("no")}
                          </Label>
                        }
                      />
                    </RadioGroup>
                  </InputWrapper>
                </Grid>

                <Grid item xs={12}>
                  <InputWrapper
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <CustomFieldHeader
                      header={"Show in Edit Form"}
                      tooltip={"Include this column in the edit form."}
                      required={true}
                      style={{ marginBottom: "0px", minWidth: "140px" }}
                    />
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="yes"
                        control={
                          <Radio
                            size="small"
                            disabled={disabledFields.includes(
                              "show_in_edit_form"
                            )}
                            checked={enableForEdit === "yes"}
                            onChange={(e) => {
                              setEnableForEdit(e.target.value);

                              setUser({ ...user, show_in_edit_form: true });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("yes")}
                          </Label>
                        }
                      />
                      <FormControlLabel
                        value="no"
                        control={
                          <Radio
                            size="small"
                            disabled={disabledFields.includes(
                              "show_in_edit_form"
                            )}
                            checked={enableForEdit === "no"}
                            onChange={(e) => {
                              setEnableForEdit(e.target.value);
                              setUser({ ...user, show_in_edit_form: false });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("no")}
                          </Label>
                        }
                      />
                    </RadioGroup>
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <CustomFieldHeader
                      header={"Multiline"}
                      tooltip={
                        "Specify if this column render as multline text field."
                      }
                      style={{ marginBottom: "0px", minWidth: "140px" }}
                    />
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="yes"
                        control={
                          <Radio
                            size="small"
                            disabled={
                              user.data_type !== "string" ||
                              filterType !== "normal"
                            }
                            checked={isMultline === "yes"}
                            onChange={(e) => {
                              setIsMultline(e.target.value);

                              setUser({ ...user, is_multiline: true });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("yes")}
                          </Label>
                        }
                      />
                      <FormControlLabel
                        value="no"
                        control={
                          <Radio
                            size="small"
                            disabled={
                              user.data_type !== "string" ||
                              filterType !== "normal"
                            }
                            checked={isMultline === "no"}
                            onChange={(e) => {
                              setIsMultline(e.target.value);
                              setUser({ ...user, is_multiline: false });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("no")}
                          </Label>
                        }
                      />
                    </RadioGroup>
                  </InputWrapper>
                </Grid>

                <Grid item xs={12}>
                  <InputWrapper
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <CustomFieldHeader
                      header={"Title Case"}
                      tooltip={
                        "Specify if the value of this column should be converted to title case."
                      }
                      style={{ marginBottom: "0px", minWidth: "140px" }}
                    />
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="yes"
                        control={
                          <Radio
                            size="small"
                            disabled={user.data_type !== "string"}
                            checked={isTitleCase === "yes"}
                            onChange={(e) => {
                              setIsTitleCase(e.target.value);

                              setUser({ ...user, set_title_case: true });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("yes")}
                          </Label>
                        }
                      />
                      <FormControlLabel
                        value="no"
                        control={
                          <Radio
                            size="small"
                            disabled={user.data_type !== "string"}
                            checked={isTitleCase === "no"}
                            onChange={(e) => {
                              setIsTitleCase(e.target.value);
                              setUser({ ...user, set_title_case: false });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("no")}
                          </Label>
                        }
                      />
                    </RadioGroup>
                  </InputWrapper>
                </Grid>

                {validateDisabledSubFeature("lead_forms") && (
                  <Grid item xs={12}>
                    <InputWrapper
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <CustomFieldHeader
                        header={"Is Form Field"}
                        tooltip={
                          "Include this column in create question in lead form."
                        }
                        style={{ marginBottom: "0px", minWidth: "140px" }}
                      />
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="yes"
                          control={
                            <Radio
                              size="small"
                              checked={isFormField === "yes"}
                              onChange={(e) => {
                                setIsFormField(e.target.value);

                                setUser({ ...user, is_form_field: true });
                              }}
                            />
                          }
                          label={
                            <Label style={{ fontSize: "12px" }}>
                              {getLocalizedText("yes")}
                            </Label>
                          }
                        />
                        <FormControlLabel
                          value="no"
                          control={
                            <Radio
                              size="small"
                              checked={isFormField === "no"}
                              onChange={(e) => {
                                setIsFormField(e.target.value);
                                setUser({ ...user, is_form_field: false });
                              }}
                            />
                          }
                          label={
                            <Label style={{ fontSize: "12px" }}>
                              {getLocalizedText("no")}
                            </Label>
                          }
                        />
                      </RadioGroup>
                    </InputWrapper>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <InputWrapper>
                    <CustomFieldHeader
                      header={"Add Options"}
                      tooltip={
                        "Add available options for this column, if applicable."
                      }
                      required={true}
                    />
                    <OutlinedInput
                      style={{ height: "30px", width: "100%" }}
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      type="text"
                      placeholder="Type something"
                      name="sub_status"
                      onKeyDown={handleFilterInput}
                      disabled={filterType !== "list"}
                    />

                    <Grid container spacing={2} style={{ padding: "12px 0px" }}>
                      <DndProvider backend={HTML5Backend}>
                        {inputList.map((chip, index) => {
                          return (
                            <Grid item key={index} style={{ width: "100%" }}>
                              <DraggableChipComponent
                                index={index}
                                label={chip}
                                moveChip={moveChip}
                                handleDelete={() => handleDelete(chip)}
                              />
                            </Grid>
                          );
                        })}
                      </DndProvider>
                    </Grid>
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <CustomFieldHeader
                      header={"Hint Text"}
                      tooltip={
                        "Hint text to show in create/edit form for this column."
                      }
                    />
                    <OutlinedInput
                      style={{ height: "30px" }}
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      value={user.hint}
                      type="text"
                      placeholder="Enter value"
                      name="hint"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </Grid>
                {renderMinMaxFields()}
              </Grid>
            </InputContainer>
            <AppBar
              position="fixed"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: 500 }}
            >
              <Toolbar style={{ width: 500, position: "relative" }}>
                {isSubmitFail && (
                  <ErrorAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setIsSubmitFail(false);
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {errorMessage}
                  </ErrorAlert>
                )}
                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      if (props.isEdit) {
                        analytics.triggerEvent(
                          4625000232,
                          "cancel_button_tap",
                          "edit_column_form",
                          "cancel_button",
                          {}
                        );
                      } else {
                        analytics.triggerEvent(
                          4625000228,
                          "cancel_button_tap",
                          "add_column_form",
                          "cancel_button",
                          {}
                        );
                      }
                      handleClose();
                    }}
                    type="button"
                    variant="contained"
                    color="inherit"
                    style={{
                      backgroundColor: "#EFEFF4",
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                  {isSubmitting ? (
                    <LoadingButton
                      loading
                      variant="contained"
                      color="primary"
                      style={{
                        textTransform: "none",
                        width: "100%",
                      }}
                    >
                      <span> {getLocalizedText("submit_details")}</span>
                    </LoadingButton>
                  ) : (
                    <Button
                      onClick={() => {
                        if (props.isEdit) {
                          analytics.triggerEvent(
                            4625000233,
                            "submit_button_tap",
                            "edit_column_form",
                            "submit_button",
                            {}
                          );
                        } else {
                          analytics.triggerEvent(
                            4625000229,
                            "submit_button_tap",
                            "add_column_form",
                            "submit_button",
                            {}
                          );
                        }
                      }}
                      type="submit"
                      variant="contained"
                      id="call-log-btn"
                      color="primary"
                      style={{
                        textTransform: "none",
                        fontSize: "12px",
                        fontWeight: "bold",
                        width: "100%",
                      }}
                    >
                      {getLocalizedText("submit_details")}
                    </Button>
                  )}
                </ButtonWrapper>
              </Toolbar>
            </AppBar>
          </ModelWrapper>
        ) : (
          <MessageWrapper>
            {props.isEdit ? (
              <Message>Column updated successfully!</Message>
            ) : (
              <Message>Column created successfully!</Message>
            )}

            <Button
              onClick={handleClose}
              type="button"
              variant="contained"
              color="inherit"
              style={{
                marginTop: "20px",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              Close
            </Button>
          </MessageWrapper>
        )}
      </Box>

      {openParentFieldConfigDialog && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openParentFieldConfigDialog}
        >
          <ParentFieldConfigurationSidepanel
            childrenConfig={childrenConfig}
            setChildrenConfig={setChildrenConfig}
            setOpen={setOpenParentFieldConfigDialog}
            filterInput={inputList.toString()}
            columnGroupsList={groupListForField}
            columnsList={columnsList}
            projectID={props.id}
            setColumnsList={setColumnsList}
            parentFieldData={user}
            parentFieldExistingData={props.editableData}
            filterType={filterType}
            selectedParentColumnGroup={selectedGroups}
            childrenToRemove={childrenToRemove}
            setChildrenToRemove={setChildrenToRemove}
          />
        </Drawer>
      )}
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: 500,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default observer(AddColumnSidepanel);
