import React, { useMemo } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";

import { styled } from "@mui/material/styles";

import { observer } from "mobx-react-lite";

import { AddBoxOutlined, ChevronRight, Delete, Edit } from "@material-ui/icons";
import rootStore from "../../stores/RootStore";
import { useEffect } from "react";
import { Backdrop, Box, IconButton, Tooltip, Typography } from "@mui/material";
import { CircularProgress, Drawer } from "@material-ui/core";
import { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  deleteProjectAdministrationViewGroupApi,
  getProjectAdministrationViewGroupsApi,
} from "../../Api";
import { ProjectAdminViewGroupsColumnHeaders } from "../../Db";
import CustomErrorMessageDialog from "../../components/alert_dialogue/CustomErrorMessageDialog";
import AddGroupSidepanel from "../../components/view_groups_sidepanels/AddGroupSidepanel";

const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  height: 100%;
  margin: auto;
  margin-top: 70px;
  width: 95%;
`;

const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 0px;
`;
const Text = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
`;

const Row = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Bottom = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 226px);`};
  background-color: white;
  overflow-x: auto;
`;

const AddBtnWrapper = styled(Box)`
  position: absolute;
  right: 5px;
  top: 5px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
`;

const AdminProjectViewGroups = () => {
  const { authStore } = rootStore;
  const project_id = authStore.projectId;
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingBackdrop, setLoadingBackdrop] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [openAddGroupPanel, setOpenAddGroupPanel] = useState(false);
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    getViewGroupList();
  }, []);

  useEffect(() => {
    if (refresh) {
      getViewGroupList();
      setRefresh(false);
    }
  }, [refresh]);

  const getViewGroupList = async () => {
    setRow([]);
    setLoading(true);
    let response = await getProjectAdministrationViewGroupsApi({
      projectId: project_id,
    });
    setRow(response);
    setLoading(false);
  };

  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  useEffect(() => {
    authStore.updateLeadDetailStatus();
  }, []);

  const handleDelete = async (id) => {
    setLoadingBackdrop(true);
    let response = await deleteProjectAdministrationViewGroupApi({
      projectId: project_id,
      viewGroupID: id,
    });
    if (response.hasError()) {
      setLoadingBackdrop(false);

      setErrorMessage(response.errorMessage);
      setOpenErrorDialog(true);
    } else {
      setLoadingBackdrop(false);
      await getViewGroupList();
    }
  };

  const actionColumn = [
    {
      field: "actions",
      headerName: "ACTIONS",
      resizable: false,
      minWidth: 130,
      flex: 1,
      cellRenderer: (params) => {
        return (
          <Tooltip title={"Delete"}>
            <IconButton
              color="error"
              onClick={() => {
                handleDelete(params.data.id);
              }}
            >
              <Delete
                style={{
                  width: "18px",
                  height: "18px",
                  cursor: "pointer",
                }}
              />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <ParentContainer>
        <Container>
          <Row>
            <TopWrapper>
              <Text>Project Administration</Text>
              <ChevronRight style={{ width: "16px", height: "16px" }} />
              <Text>View Groups</Text>
            </TopWrapper>
          </Row>

          <Bottom>
            {!loading ? (
              <TableWrapper style={{ position: "relative" }}>
                <AddBtnWrapper>
                  <AddBoxOutlined
                    onClick={() => {
                      setOpenAddGroupPanel(true);
                    }}
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                  />
                </AddBtnWrapper>
                <div className="ag-theme-alpine" style={{ width: "100%" }}>
                  <AgGridReact
                    domLayout="autoHeight"
                    suppressCellFocus
                    gridOptions={gridOptions}
                    loadingOverlayComponent={<CircularProgress />}
                    rowData={row}
                    defaultColDef={defaultColDef}
                    columnDefs={ProjectAdminViewGroupsColumnHeaders.concat(
                      actionColumn
                    )}
                    animateRows={true}
                  />
                </div>
              </TableWrapper>
            ) : (
              <TableWrapper
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </TableWrapper>
            )}
          </Bottom>
        </Container>
      </ParentContainer>
      <Footer />
      <Backdrop
        open={loadingBackdrop}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress style={{ color: "white" }} />
      </Backdrop>
      {openErrorDialog && (
        <CustomErrorMessageDialog
          open={openErrorDialog}
          setOpen={setOpenErrorDialog}
          message={errorMessage}
        />
      )}
      {openAddGroupPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openAddGroupPanel}
          onClose={() => {
            setOpenAddGroupPanel(false);
          }}
        >
          <AddGroupSidepanel
            setOpen={setOpenAddGroupPanel}
            open={openAddGroupPanel}
            groupList={row}
            setRefresh={setRefresh}
          />
        </Drawer>
      )}
    </>
  );
};

export default observer(AdminProjectViewGroups);
