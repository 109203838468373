import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { fbAppID, fbLoginConfigID } from "../../../Config";
import { sendFbLoginToken } from "../../../Api";

const FacebookBusinessLoginButton = () => {
  useEffect(() => {
    const loadFbSdk = () => {
      window.fbAsyncInit = async function () {
        await window.FB.init({
          appId: fbAppID,
          cookie: true,
          xfbml: true,
          version: "v19.0",
        });
      };
      (function (d, s, id) {
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        const js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
    };

    loadFbSdk();
  }, []);

  const statusChangeCallback = async (response) => {
    console.log("statusChangeCallback", response);
    if (response.status === "connected") {
      testAPI();
    } else {
      console.log("Please log into this webpage.");

      window.FB.login(
        (loginResponse) => {
          handleSendToken(loginResponse);
        },
        { config_id: fbLoginConfigID }
      );
    }
  };

  const handleSendToken = async (loginResponse) => {
    const payload = {
      token: loginResponse.authResponse?.accessToken,
    };
    const serverResponse = await sendFbLoginToken({ payload: payload });
  };

  const testAPI = () => {
    console.log("Welcome! Fetching your information....");
    window.FB.api("/me", (response) => {
      console.log("Successful login for:", response.name);
      console.log(`Thanks for logging in, ${response.name}!`);
    });
  };

  const checkLoginState = () => {
    window.FB.getLoginStatus(async (response) => {
      await statusChangeCallback(response);
    });
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        sx={{
          padding: "6px 16px",
          textTransform: "none",
          fontSize: "14px",
          fontWeight: "bold",
        }}
        onClick={() => {
          checkLoginState();
        }}
      >
        Connect To FB
      </Button>
    </div>
  );
};

export default FacebookBusinessLoginButton;
