import React from "react";
import rootStore from "../../stores/RootStore";
import ColumnHeader from "./ColumnHeader";
import { sortableFieldsListForLeads } from "../../Db";
import { Box, styled, Typography } from "@mui/material";
const Text = styled(Typography)`
  margin: 0px;
  font: normal normal 600 12px Poppins;
  color: #4d4e4f;
`;

const LeadListingColumnHeaderRenderCompoent = ({
  columnMeta,
  scope = "leads_page",
}) => {
  const checkIfSortingApplied = () => {
    if (scope === "leads_page") {
      return rootStore.leadStore.isLeadSortingByColumnApplied({
        columnMeta: columnMeta,
      });
    } else if (scope === "filter_page") {
      return rootStore.filterPageState.isLeadSortingByColumnApplied({
        columnMeta: columnMeta,
      });
    }
    return false;
  };

  const getSortingDirection = () => {
    if (scope === "leads_page") {
      return rootStore.leadStore.getColumnSortingDirection();
    } else if (scope === "filter_page") {
      return rootStore.filterPageState.getColumnSortingDirection();
    }
    return "";
  };

  const hasColumnSortingFeatureInDataTable = () => {
    return scope === "leads_page" || scope === "filter_page";
  };

  const handleClick = () => {
    if (scope === "leads_page") {
      rootStore.leadStore.updateTabColumnSortingList({
        columnMeta: columnMeta,
      });
    } else if (scope === "filter_page") {
      rootStore.filterPageState.updateLeadSortingByColumnInfo({
        columnMeta: columnMeta,
      });
    }
  };

  if (
    hasColumnSortingFeatureInDataTable() &&
    sortableFieldsListForLeads.includes(columnMeta.field)
  ) {
    if (checkIfSortingApplied() === true) {
      return (
        <ColumnHeader
          columnMeta={columnMeta}
          sortingDirection={getSortingDirection()}
          handlePostClick={handleClick}
        />
      );
    } else {
      return (
        <Box sx={{ width: "100%" }} onClick={handleClick}>
          <Text>{columnMeta.headerName}</Text>
        </Box>
      );
    }
  } else {
    return <Text>{columnMeta.headerName}</Text>;
  }
};

export default LeadListingColumnHeaderRenderCompoent;
