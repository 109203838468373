import { AppBar, Box, Divider, Toolbar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  findClosestMatchColumn,
  getLocalizedText,
} from "../../../../Functions";
import {
  indiamartIntegrationFields,
  aajjoIntegrationFields,
} from "../../../../Db";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
} from "@mui/material";
import Select from "react-select";
import { getColumnsForMapping } from "../../../../Api";
const dialogWidth = 600;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: 400px;
  padding: 16px;
  overflow-y: auto;
  row-gap: 10px;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const RowWrapper = styled(Box)`
  display: flex;
  column-gap: 10px;
  width: 100%;
`;
const CheckBoxTextWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const ColumnWrapper = styled(Box)`
  display: flex;
  align-items: center;
  padding: 0px 16px;
  margin-top: 16px;
  width: 100%;
`;
const ColumnHeader = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  width: 100%;
`;
const ColumnMappingScreen = ({
  projectID,
  formData,
  projectColumnList,
  setProjectColumnList,
  formQuestionList,
  setFormQuestionsList,
  formAndProjectColumnMapping,
  setFormAndProjectColumnMapping,
  type,
}) => {
  const [loading, setLoading] = useState(true);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      fontWieght: "bold",
      // Font size for options
    }),
    menuList: (base) => ({
      ...base,
      minHeight: "200px", // your desired height
    }),
    control: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      width: "284px",
      fontWieght: "bold",
      // Font size for the entire control container, including the search input
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      // Font size for "no options" message
    }),
    loadingMessage: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      // Font size for "loading" message
    }),
  };

  const getProjectColumnsForMapping = async () => {
    let response = await getColumnsForMapping(projectID);
    return response;
  };
  const initFormToProjectColumns = (formQuestionList, projectColumnList) => {
    let initList = {};
    if (formData["status"] === "connected" || formData["status"] === "active") {
      let mapping = { ...formData["mapping"] };

      formQuestionList.forEach((question) => {
        let checked = false;
        let projectLead = {};

        // let index = findClosestMatchColumn(question["key"], projectColumnList);
        if (mapping.hasOwnProperty(question["key"])) {
          let index = projectColumnList.findIndex(
            (column) => column["id"] === mapping[question["key"]]
          );
          if (index !== -1) {
            checked = true;
            projectLead = {
              id: projectColumnList[index]["id"],
              field: projectColumnList[index]["field"],
              display_name: projectColumnList[index]["headerName"],
              data_type: projectColumnList[index]["data_type"],
              is_custom_param: projectColumnList[index]["is_custom_param"],
              label: projectColumnList[index].headerName,
              value: projectColumnList[index].field,
              isDisabled: isAlreadySelectedProjectField(
                projectColumnList[index].field
              ),
              required: projectColumnList[index].required,
            };
          }
        }

        let tempObj = {
          form_lead: {
            field: question["key"],
            display_name: question["label"],
          },
          project_lead: projectLead,
          checked: checked,
        };

        initList[question["key"]] = tempObj;
      });
    } else {
      formQuestionList.forEach((question) => {
        let checked = false;
        let projectLead = {};
        let index = findClosestMatchColumn(question["key"], projectColumnList);
        if (index !== -1) {
          checked = true;
          projectLead = {
            id: projectColumnList[index]["id"],
            field: projectColumnList[index]["field"],
            display_name: projectColumnList[index]["headerName"],
            data_type: projectColumnList[index]["data_type"],
            is_custom_param: projectColumnList[index]["is_custom_param"],
            label: projectColumnList[index].headerName,
            value: projectColumnList[index].field,
            isDisabled: isAlreadySelectedProjectField(
              projectColumnList[index].field
            ),
            required: projectColumnList[index].required,
          };
        }

        let tempObj = {
          form_lead: {
            field: question["key"],
            display_name: question["label"],
          },
          project_lead: projectLead,
          checked: checked,
        };

        initList[question["key"]] = tempObj;
      });
    }
    return initList;
  };

  const getIntegrationFields = () => {
    if (type === "indiamart") {
      return indiamartIntegrationFields;
    } else if (type === "aajjo") {
      return aajjoIntegrationFields;
    } else {
      return {};
    }
  };

  const setup = async () => {
    let formQuestionList = getIntegrationFields();
    setFormQuestionsList(formQuestionList);
    let projectColumns = await getProjectColumnsForMapping();

    const requiredList = projectColumns.filter((item) => item.required);
    const nonRequiredList = projectColumns.filter((item) => !item.required);
    // Sort each list alphabetically
    const sortedRequiredList = requiredList.sort((a, b) =>
      a["headerName"].toLowerCase().localeCompare(b["headerName"].toLowerCase())
    );
    const sortedNonRequiredList = nonRequiredList.sort((a, b) =>
      a["headerName"].toLowerCase().localeCompare(b["headerName"].toLowerCase())
    );
    projectColumns = [...sortedRequiredList, ...sortedNonRequiredList];

    let new_init_list = initFormToProjectColumns(
      formQuestionList,
      projectColumns
    );
    setProjectColumnList(projectColumns);
    setFormAndProjectColumnMapping(new_init_list);
    setLoading(false);
  };

  const getProjectColumnsForDropdown = () => {
    let tempList = [];
    projectColumnList.forEach((column) => {
      let tempObj = {
        label: column.headerName,
        value: column.field,
        isDisabled: isAlreadySelectedProjectField(column["field"]),
        required: column.required,
      };
      tempList.push(tempObj);
    });
    return tempList;
  };

  const getFormFieldHeaderName = (field) => {
    let index = formQuestionList.findIndex(
      (question) => question["key"] === field
    );
    if (index !== -1) {
      return formQuestionList[index]["label"];
    } else {
      return "";
    }
  };

  useEffect(() => {
    setup();
  }, []);

  const isAlreadySelectedProjectField = (field) => {
    let flag = false;
    Object.keys(formAndProjectColumnMapping).forEach((item) => {
      if (
        formAndProjectColumnMapping[item]["project_lead"].hasOwnProperty(
          "field"
        ) &&
        formAndProjectColumnMapping[item]["project_lead"]["field"] === field
      ) {
        flag = true;
      }
    });
    return flag;
  };

  return (
    <>
      {!loading ? (
        <Box>
          <AppBar
            component={"nav"}
            elevation={0}
            position="sticky"
            color="inherit"
            sx={{
              top: 0,
              bottom: "auto",
              width: "100%",
              right: 0,
            }}
          >
            <Toolbar
              style={{
                padding: "16px",
              }}
            >
              <HeaderWrapper>
                <Header>Map Columns</Header>
                <SubHeader>
                  {" "}
                  {getLocalizedText("fill_in_the_below_details")}
                </SubHeader>
              </HeaderWrapper>
            </Toolbar>
          </AppBar>
          <Divider style={{ margin: "0px 16px" }} />
          <ColumnWrapper>
            <ColumnHeader>Form Field</ColumnHeader>
            <ColumnHeader>Project Field</ColumnHeader>
          </ColumnWrapper>
          <Container>
            {Object.keys(formAndProjectColumnMapping).map((item, index) => {
              return (
                <RowWrapper>
                  <CheckBoxTextWrapper>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            setFormAndProjectColumnMapping({
                              ...formAndProjectColumnMapping,
                              [item]: {
                                ...formAndProjectColumnMapping[item],
                                checked: e.target.checked,
                              },
                            });
                          }}
                          size="small"
                          checked={formAndProjectColumnMapping[item]["checked"]}
                        />
                      }
                      label={
                        <Label>
                          {getFormFieldHeaderName(
                            formAndProjectColumnMapping[item]["form_lead"][
                              "field"
                            ]
                          )}
                        </Label>
                      }
                    />
                  </CheckBoxTextWrapper>
                  <Select
                    menuPosition="fixed"
                    key={index}
                    maxMenuHeight={150}
                    value={formAndProjectColumnMapping[item]["project_lead"]}
                    required={formAndProjectColumnMapping[item]["checked"]}
                    onChange={(e) => {
                      if (e === null) {
                        setFormAndProjectColumnMapping({
                          ...formAndProjectColumnMapping,
                          [item]: {
                            ...formAndProjectColumnMapping[item],
                            project_lead: {},
                          },
                        });
                      } else {
                        let index = projectColumnList.findIndex(
                          (column) => column["field"] === e.value
                        );

                        setFormAndProjectColumnMapping({
                          ...formAndProjectColumnMapping,
                          [item]: {
                            ...formAndProjectColumnMapping[item],
                            project_lead: {
                              ...e,
                              id: projectColumnList[index]["id"],
                              field: projectColumnList[index]["field"],
                              display_name:
                                projectColumnList[index]["headerName"],
                              data_type: projectColumnList[index]["data_type"],
                              is_custom_param:
                                projectColumnList[index]["is_custom_param"],
                            },
                          },
                        });
                      }
                    }}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    options={getProjectColumnsForDropdown()}
                    closeMenuOnSelect={true}
                    isClearable
                    styles={customStyles}
                    menuShouldScrollIntoView={false}
                    formatOptionLabel={(option) => {
                      if (option.required) {
                        return (
                          <Typography sx={{ fontSize: "12px" }}>
                            <span style={{ color: "red" }}>*</span>
                            {option.label}
                          </Typography>
                        );
                      } else {
                        return (
                          <Typography sx={{ fontSize: "12px" }}>
                            {option.label}
                          </Typography>
                        );
                      }
                    }}
                  ></Select>
                </RowWrapper>
              );
            })}
          </Container>
        </Box>
      ) : (
        <Container sx={{ justifyContent: "center", alignItems: "center" }}>
          <CircularProgress />
        </Container>
      )}
    </>
  );
};

export default ColumnMappingScreen;
