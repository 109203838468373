import React, { useEffect, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { Add, ChevronRight, Save } from "@material-ui/icons";
import { Box, CircularProgress, Typography } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { myTheme } from "../../themeUtils";
import CreateFormDialog from "../../components/lead_form_builder_components/dialogs/CreateFormDialog";
import AddSectionDialog from "../../components/lead_form_builder_components/dialogs/AddSectionDialog";
import SectionCard from "../../components/lead_form_builder_components/components/SectionCard";
import AddQuestionDialog from "../../components/lead_form_builder_components/dialogs/AddQuestionDialog";
import { Button } from "@material-ui/core";
import { optionFieldsList } from "../../Db";
import {
  getListApi,
  getProjectAdminLeadFormDetailsApi,
  removeLeadFormSectionApi,
  removeLeadFormSectionQuestionApi,
} from "../../Api";
import rootStore from "../../stores/RootStore";
import { getSlug } from "../../Functions";
import MessageWithLoadingStateCustomDialog from "../../components/alert_dialogue/MessageWithLoadingStateCustomDialog";
const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  height: 100%;
  margin: auto;
  margin-top: 64px;
  width: 95%;
`;

const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: flex-end;
`;
const BottomWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  background-color: white;
  ${`min-height: calc(100vh - 208px);`};
`;
const FormWrapper = styled(Box)`
  width: 80%;
  margin: auto;
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const Text = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
`;

const TextLgHighlight = styled(Typography)`
  font: normal normal 600 16px Open Sans;
  margin: 0px;
`;
const AdminProjectLeadForm = () => {
  const columnsList = [...rootStore.userStore.AllColumnsList];
  const [formData, setFormData] = useState({});
  const [openCreateFormDialog, setOpenCreateFormDialog] = useState(false);
  const [openAddSectionDialog, setOpenAddSectionDialog] = useState(false);
  const [sectionList, setSectionList] = useState([]);
  const [inputData, setInputData] = useState({});
  const [openAddQuestionDialog, setOpenAddQuestionDialog] = useState(false);
  const [selectedSectionID, setSelectedSectionID] = useState("");
  const [isSectionEdit, setIsSectionEdit] = useState(false);
  const [isQuestionEdit, setIsQuestionEdit] = useState(false);
  const [selectedQuestionID, setSelectedQuestionID] = useState("");
  const [listFieldInputMeta, setListFieldInputMeta] = useState({});
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [fetchingApi, setFetchingApi] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSubmitResponseDialog, setOpenSubmitResponseDialog] =
    useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);

  const getSectionDataByID = (sectionID) => {
    let index = sectionList.findIndex((section) => section["id"] === sectionID);
    return sectionList[index];
  };

  const getQuestionData = (sectionID, questionID) => {
    let sectionIndex = sectionList.findIndex(
      (section) => section["id"] === sectionID
    );
    if (sectionIndex !== -1) {
      let sectionData = sectionList[sectionIndex];
      let questionIndex = sectionData["questions"].findIndex(
        (question) => question["id"] === questionID
      );
      if (questionIndex !== -1) {
        return sectionData["questions"][questionIndex];
      }
    }
    return {};
  };

  const getListDetailsToRender = async (columnMeta) => {
    const filter_input = columnMeta["filter_input"];
    let tempType = "list";
    let tempList = [];
    if (filter_input.charAt(0) === "/" || filter_input.startsWith("ep:")) {
      let slug = getSlug(filter_input);
      let response = await getListApi({ slug: slug });
      if (response !== null) {
        if (Array.isArray(response)) {
          tempType = "list";
          tempList = response;
        } else if (typeof response === "object") {
          if (Object.keys(response).length > 0) {
            tempType = response["type"];
            tempList = response["values"];
          }
        }
      }
    } else if (
      filter_input.charAt(0) !== "/" &&
      filter_input !== "" &&
      filter_input !== null
    ) {
      try {
        let parseJSON = JSON.parse(filter_input);
        if (Array.isArray(parseJSON)) {
          tempType = "list";
          tempList = parseJSON;
        } else if (typeof parseJSON === "object") {
          if (columnMeta["map_to_parent_selection"] === true) {
            let tempInputList = [];
            for (let parentValue in parseJSON) {
              parseJSON[parentValue].forEach((str) => {
                if (!tempInputList.includes(str)) {
                  tempInputList.push(str);
                }
              });
            }
            tempType = "list";
            tempList = tempInputList;
          } else {
            if (Object.keys(parseJSON).length > 0) {
              tempType = parseJSON["type"];
              tempList = parseJSON["values"];
            }
          }
        }
      } catch (error) {
        if (filter_input.length > 0) {
          let list = filter_input.split(",");
          tempType = "list";
          tempList = list;
        }
      }
    }
    return { list: tempList, type: tempType };
  };

  const getListFieldInputMeta = async ({ columnMetaList }) => {
    let tempList = {};
    for (let i = 0; i < columnMetaList.length; i++) {
      let columnMeta = columnMetaList[i];
      if (optionFieldsList.includes(columnMeta["question_type"])) {
        let metaData = await getListDetailsToRender(columnMeta);
        tempList[columnMeta["field"]] = metaData;
      }
    }
    return tempList;
  };

  const getFormFieldColumnList = ({ sectionList }) => {
    let tempList = [];
    sectionList.forEach((section) => {
      section["questions"].forEach((question) => {
        let index = columnsList.findIndex(
          (column) => column["id"] === question["column_id"]
        );
        if (index !== -1) {
          let columnData = columnsList[index];
          columnData["question_type"] = question["question_type"];
          tempList.push(columnsList[index]);
        }
      });
    });
    return tempList;
  };

  const getLeadFormData = async () => {
    let response = await getProjectAdminLeadFormDetailsApi();
    setFormData(response);
    if (Object.keys(response).length > 0) {
      setIsEdit(true);
      let columnMetaList = getFormFieldColumnList({
        sectionList: response["sections"],
      });
      let listFieldInputMeta = await getListFieldInputMeta({
        columnMetaList: columnMetaList,
      });
      setListFieldInputMeta(listFieldInputMeta);
      setSectionList(response["sections"]);
    } else {
      setListFieldInputMeta({});
      setSectionList([]);
    }
  };
  const init = async () => {
    await getLeadFormData();
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  const handleSectionDelete = async ({ sectionID }) => {
    setFetchingApi(true);
    setOpenSubmitResponseDialog(true);
    let response = await removeLeadFormSectionApi({
      formID: formData["id"],
      sectionID: sectionID,
    });
    if (!response.hasError()) {
      setIsSubmitFail(false);
      setSuccessMessage("Section removed successfully!");
      setFetchingApi(false);
      removeSectionLocally({ sectionID: sectionID });
    } else {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
      setFetchingApi(false);
    }
    setSelectedSectionID("");
  };

  const handleQuestionDelete = async ({ sectionID, questionID }) => {
    setFetchingApi(true);
    setOpenSubmitResponseDialog(true);

    let response = await removeLeadFormSectionQuestionApi({
      formID: formData["id"],
      questionID: questionID,
      sectionID: sectionID,
    });
    if (!response.hasError()) {
      setIsSubmitFail(false);
      setSuccessMessage("Question removed successfully!");
      setFetchingApi(false);
      removeQuestionLocally({ sectionID: sectionID, questionID: questionID });
    } else {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
      setFetchingApi(false);
    }
    setSelectedSectionID("");
    setSelectedQuestionID("");
  };

  const removeSectionLocally = ({ sectionID }) => {
    let tempList = [...sectionList];
    tempList = tempList.filter((section) => section["id"] !== sectionID);
    tempList.forEach((obj, idx) => {
      obj["sequence"] = idx;
    });
    setSectionList(tempList);
  };

  const removeQuestionLocally = ({ sectionID, questionID }) => {
    let tempSectionList = [...sectionList];
    let index = tempSectionList.findIndex(
      (section) => section["id"] === sectionID
    );
    let tempSectionData = tempSectionList[index];
    let tempQuestionList = [...tempSectionData["questions"]];

    tempQuestionList = tempQuestionList.filter(
      (question) => question["id"] !== questionID
    );
    tempQuestionList.forEach((obj, idx) => {
      obj["sequence"] = idx;
    });
    tempSectionData["questions"] = tempQuestionList;
    tempSectionList[index] = tempSectionData;
    setSectionList(tempSectionList);
  };

  const getBreadCrumbText = () => {
    if (
      formData.hasOwnProperty("title") &&
      formData["title"] !== null &&
      formData["title"] !== ""
    ) {
      return formData["title"];
    }
    return "Lead Form";
  };

  return !loading ? (
    <>
      <ParentContainer>
        <Container>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              columnGap: "20px",
              justifyContent: "space-between",
              paddingY: "16px",
            }}
          >
            <TopWrapper>
              <Text>Project Administration</Text>
              <ChevronRight style={{ width: "16px", height: "16px" }} />
              <Text>{getBreadCrumbText()}</Text>
            </TopWrapper>
            <Box
              sx={{ display: "flex", alignItems: "center", columnGap: "20px" }}
            >
              <Button
                onClick={() => {
                  setIsSectionEdit(false);
                  setOpenAddSectionDialog(true);
                }}
                variant="contained"
                color="primary"
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
                disabled={sectionList.length === 0}
              >
                <Add style={{ marginRight: "6px" }} />
                Section
              </Button>
            </Box>
          </Box>
          {sectionList.length > 0 ? (
            <BottomWrapper>
              <FormWrapper>
                {sectionList.map((sectionData, index) => {
                  return (
                    <SectionCard
                      key={index}
                      questions={sectionData["questions"]}
                      title={sectionData["title"]}
                      inputData={inputData}
                      setInputData={setInputData}
                      listFieldInputMeta={listFieldInputMeta}
                      handleAddQuestion={() => {
                        setIsQuestionEdit(false);
                        setSelectedSectionID(sectionData["id"]);
                        setOpenAddQuestionDialog(true);
                      }}
                      handleSectionDelete={() => {
                        handleSectionDelete({ sectionID: sectionData["id"] });
                      }}
                      handleSectionEdit={() => {
                        setSelectedSectionID(sectionData["id"]);
                        setIsSectionEdit(true);
                        setOpenAddSectionDialog(true);
                      }}
                      handleQuestionEdit={(questionID) => {
                        setSelectedSectionID(sectionData["id"]);
                        setSelectedQuestionID(questionID);
                        setIsQuestionEdit(true);
                        setOpenAddQuestionDialog(true);
                      }}
                      handleQuestionDelete={(questionID) => {
                        handleQuestionDelete({
                          sectionID: sectionData["id"],
                          questionID: questionID,
                        });
                      }}
                    />
                  );
                })}
              </FormWrapper>
            </BottomWrapper>
          ) : (
            <BottomWrapper
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => {
                  if (Object.keys(formData).length > 0) {
                    setIsSectionEdit(false);
                    setOpenAddSectionDialog(true);
                  } else {
                    setIsEdit(false);
                    setOpenCreateFormDialog(true);
                  }
                }}
                variant="text"
                style={{
                  textTransform: "none",
                  fontSize: "16px",
                  color: myTheme.Button.btnBlue.backgroundColor,
                }}
                color="primary"
              >
                <Add
                  style={{
                    marginRight: "6px",
                    color: myTheme.Button.btnBlue.backgroundColor,
                  }}
                />
                {Object.keys(formData).length > 0 ? (
                  <TextLgHighlight>Add a new section</TextLgHighlight>
                ) : (
                  <TextLgHighlight>Create A New Lead Form</TextLgHighlight>
                )}
              </Button>
            </BottomWrapper>
          )}
        </Container>
      </ParentContainer>
      <Footer />
      {openCreateFormDialog && (
        <CreateFormDialog
          open={openCreateFormDialog}
          setOpen={setOpenCreateFormDialog}
          formData={formData}
          isEdit={isEdit}
          sectionList={sectionList}
          setIsEdit={setIsEdit}
          setFormData={setFormData}
        />
      )}
      {openAddSectionDialog && (
        <AddSectionDialog
          sectionData={getSectionDataByID(selectedSectionID)}
          open={openAddSectionDialog}
          setOpen={setOpenAddSectionDialog}
          handleAddSection={(value) => {
            let tempList = [...sectionList];
            let tempObj = value;
            tempObj["questions"] = [];
            tempList.push(tempObj);
            setSectionList(tempList);
          }}
          handleEditSection={(value) => {
            let tempList = [...sectionList];
            let index = tempList.findIndex(
              (item) => item["id"] === selectedSectionID
            );
            if (index !== -1) {
              let tempObj = tempList[index];
              tempObj["title"] = value["title"];
              tempObj["desc"] = value["desc"];
              tempList[index] = tempObj;
            }
            setSectionList(tempList);
            setSelectedSectionID("");
            setIsSectionEdit(false);
          }}
          isEdit={isSectionEdit}
          setIsEdit={setIsSectionEdit}
          sectionList={sectionList}
          selectedSectionID={selectedSectionID}
          formData={formData}
        />
      )}

      {openAddQuestionDialog && (
        <AddQuestionDialog
          open={openAddQuestionDialog}
          setOpen={setOpenAddQuestionDialog}
          handleAddQuestion={async (value) => {
            let columnIndex = columnsList.findIndex(
              (column) => column["id"] === value["column_id"]
            );
            if (columnIndex !== -1) {
              let columnMeta = columnsList[columnIndex];
              if (columnMeta["filter_type"] === "list") {
                let tempList = { ...listFieldInputMeta };
                if (!tempList.hasOwnProperty(columnMeta["field"])) {
                  let metaData = await getListDetailsToRender(columnMeta);
                  tempList[columnMeta["field"]] = metaData;
                  setListFieldInputMeta(tempList);
                }
              }
            }
            let tempList = [...sectionList];
            let index = tempList.findIndex(
              (section) => section["id"] === selectedSectionID
            );
            if (index !== -1) {
              let sectionData = tempList[index];
              let tempObj = { ...value };

              sectionData["questions"].push(tempObj);
              tempList[index] = sectionData;
            }
            setSectionList(tempList);
            setSelectedSectionID("");
          }}
          isEdit={isQuestionEdit}
          setIsEdit={setIsQuestionEdit}
          questionData={getQuestionData(selectedSectionID, selectedQuestionID)}
          handleEditQuestion={async (value) => {
            let columnIndex = columnsList.findIndex(
              (column) => column["id"] === value["column_id"]
            );
            if (columnIndex !== -1) {
              let columnMeta = columnsList[columnIndex];
              if (columnMeta["filter_type"] === "list") {
                let tempList = { ...listFieldInputMeta };
                if (!tempList.hasOwnProperty(columnMeta["field"])) {
                  let metaData = await getListDetailsToRender(columnMeta);
                  tempList[columnMeta["field"]] = metaData;
                  setListFieldInputMeta(tempList);
                }
              }
            }
            let tempSectionList = [...sectionList];
            let index = tempSectionList.findIndex(
              (section) => section["id"] === selectedSectionID
            );

            if (index !== -1) {
              let tempSectionData = tempSectionList[index];
              let questionIndex = tempSectionData["questions"].findIndex(
                (question) => question["id"] === selectedQuestionID
              );

              if (questionIndex !== -1) {
                let questionData = tempSectionData["questions"][questionIndex];
                questionData["question"] = value["question"];
                questionData["question_type"] = value["question_type"];
                questionData["desc"] = value["desc"];
                questionData["column_id"] = value["column_id"];
                questionData["required"] = value["required"];
                tempSectionData["questions"][questionIndex] = questionData;
                tempSectionList[index] = tempSectionData;
                setSectionList(tempSectionList);
              }
            }
            setSelectedSectionID("");
            setSelectedQuestionID("");
            setIsQuestionEdit(false);
          }}
          formData={formData}
          sectionList={sectionList}
          selectedQuestionID={selectedQuestionID}
          selectedSectionID={selectedSectionID}
        />
      )}
      {openSubmitResponseDialog && (
        <MessageWithLoadingStateCustomDialog
          open={openSubmitResponseDialog}
          setOpen={setOpenSubmitResponseDialog}
          successMessage={successMessage}
          errorMessage={errorMessage}
          loading={fetchingApi}
          isError={isSubmitFail}
        />
      )}
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default observer(AdminProjectLeadForm);
