import React from "react";
import { styled } from "@mui/system";
import { Typography, Box } from "@mui/material";
import ContactForm from "./ContactForm";

const Wrapper = styled(Box)(({ theme, gradientColor }) => ({
  background: gradientColor,
  width: "100%",
  minHeight: "calc(100vh - 108px)",
  [theme.breakpoints.down("md")]: {
    minHeight: "calc(100vh - 94px)",
  },
}));

const InnerWrapper = styled(Box)(({ theme }) => ({
  width: "80%",
  display: "flex",
  padding: theme.spacing(2),
  flexDirection: "column",
  margin: "auto",
  rowGap: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    width: "100%",
    rowGap: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));

const RowWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  gap: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
    gap: theme.spacing(2),
    alignItems: "center",
  },
}));

const TextContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "space-between",
  textAlign: "left",
  margin: 0,
  rowGap: theme.spacing(2),
  [[theme.breakpoints.between("xs", "sm")]]: {
    width: "100%",
  },
  [[theme.breakpoints.between("sm", "md")]]: {
    width: "100%",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    width: "50%",
  },
  [theme.breakpoints.up("lg")]: {
    width: "50%",
  },
}));

const FormContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  zIndex: 2,

  [[theme.breakpoints.between("sm", "md")]]: {
    width: "70%",
  },
  [theme.breakpoints.up("md")]: {
    width: "35%",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  component: "h1",
  width: "100%",

  color: "#ffffff",

  [[theme.breakpoints.between("xs", "sm")]]: {
    font: "normal normal 700 18px open sans",
  },
  [[theme.breakpoints.between("sm", "md")]]: {
    font: "normal normal 700 22px open sans",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    font: "normal normal 700 24px open sans",
  },
  [theme.breakpoints.up("lg")]: {
    font: "normal normal 700 38px open sans",
  },
}));

const KeyPointText = styled(Typography)(({ theme }) => ({
  paddingTop: 0,
  component: "span",
  width: "100%",
  [[theme.breakpoints.between("xs", "sm")]]: {
    font: "normal normal 600 12px open sans",
    component: "span",
  },
  [[theme.breakpoints.between("sm", "md")]]: {
    font: "normal normal 600 16px open sans",
    component: "span",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    font: "normal normal 600 20px open sans",
    component: "span",
  },
  [theme.breakpoints.up("lg")]: {
    font: "normal normal 600 24px open sans",
    component: "span",
  },
}));

const KeyPointSubText = styled(Typography)(({ theme }) => ({
  paddingTop: 0,
  component: "span",
  color: "#4d4e4f",
  width: "100%",
  [[theme.breakpoints.between("xs", "sm")]]: {
    font: "normal normal 600 10px open sans",
    component: "span",
  },
  [[theme.breakpoints.between("sm", "md")]]: {
    font: "normal normal 600 12px open sans",
    component: "span",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    font: "normal normal 600 16px open sans",
    component: "span",
  },
  [theme.breakpoints.up("lg")]: {
    font: "normal normal 600 20px open sans",
    component: "span",
  },
}));

const BulletText = styled(Typography)(({ theme }) => ({
  paddingTop: 0,
  component: "span",
  color: "#ffffff",
  [[theme.breakpoints.between("xs", "sm")]]: {
    font: "normal normal 600 12px open sans",
    component: "span",
  },
  [[theme.breakpoints.between("sm", "md")]]: {
    font: "normal normal 600 16px open sans",
    component: "span",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    font: "normal normal 600 20px open sans",
    component: "span",
  },
  [theme.breakpoints.up("lg")]: {
    font: "normal normal 600 24px open sans",
    component: "span",
  },
}));

const TextWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  columnGap: theme.spacing(2),
  width: "100%",
}));

const TopSection = ({
  contentState,
  formState,
  gradientColor,
  primaryColor,
  secondaryColor,
}) => {
  return (
    <Wrapper gradientColor={gradientColor}>
      <InnerWrapper>
        <Title onClick={(e) => e.stopPropagation()}>{contentState.title}</Title>
        <RowWrapper>
          <FormContainer>
            <ContactForm
              formState={formState}
              secondaryColor={secondaryColor}
              contentState={contentState}
            />
          </FormContainer>
          <TextContainer>
            {contentState.hasOwnProperty("key_points") &&
              contentState.key_points.map((item, index) => {
                return (
                  <TextWrapper>
                    <BulletText>{index + 1}</BulletText>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <KeyPointText style={{ color: primaryColor }}>
                        {item.title}
                      </KeyPointText>
                      <KeyPointSubText>{item.sub_title}</KeyPointSubText>
                    </Box>
                  </TextWrapper>
                );
              })}
          </TextContainer>
        </RowWrapper>
      </InnerWrapper>
    </Wrapper>
  );
};

export default TopSection;
