import { action, makeObservable, observable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { getHotLeadApi, getLeadProfileApi } from "../Api";
import {
  IsoToLocalTime,
  getClosestDateTime,
  getLocalTimeStringFromIsoDateString,
  isBeforeCurrentTime,
  isToday,
  validateDisabledSubFeature,
} from "../Functions";
import moment from "moment";
import rootStore from "../stores/RootStore";

class HotLeadState {
  _duration = 30;
  timer = null;
  hotLeadInfo = {};
  constructor() {
    makeObservable(this, {
      hotLeadInfo: observable,
      timer: observable,
      init: action,
      checkAndUpdateHotLead: action,
      fetchHotLeadOnProjectChange: action,
      getMessage: action,
      resetStore: action,
      checkAndFetchHotLeadOnFollowupChange: action,
      startTimer: action,
      startRecursiveTimer: action,
      hasPermission: action,
    });

    makePersistable(this, {
      name: HotLeadState,
      storage: window.localStorage,
      expireIn: 86400000,
      removeOnExpiration: true,
      stringify: true,
      debugMode: false,
      properties: [""],
    });
  }

  hasPermission = () => {
    const projectRole = rootStore.authStore.getCurrentProjectRole();
    if (projectRole.toLowerCase() === "member") {
      if (validateDisabledSubFeature("banner_hotlead")) {
        return true;
      }
    }
    return false;
  };

  fetchLeadData = async (leadID) => {
    let response = await getLeadProfileApi({ leadID: leadID });
    if (response.hasError()) {
      return {};
    } else {
      return response.data;
    }
  };

  fetchHotLead = async () => {
    let response = await getHotLeadApi();
    this.hotLeadInfo = response;
  };

  resetStore = () => {
    this.stopTimer();
    this.hotLeadInfo = {};
  };

  init = async () => {
    if (!this.hasPermission()) {
      return;
    }
    await this.fetchHotLead();
    this.startTimer();
  };

  fetchHotLeadOnProjectChange = async () => {
    if (!this.hasPermission()) {
      return;
    }
    await this.fetchHotLead();
  };

  checkAndUpdateHotLead = async ({ leadID }) => {
    if (!this.hasPermission()) {
      return;
    }
    if (this.hotLeadInfo.hasOwnProperty("id")) {
      let hotLeadID = this.hotLeadInfo["id"];

      if (leadID === hotLeadID) {
        this.hotLeadInfo = {};
        let details = await this.fetchLeadData(leadID);
        if (details.hasOwnProperty("lead")) {
          details = details["lead"];
        }
        if (this.shouldFetchHotLeadData(details)) {
          await this.fetchHotLead();
        } else {
          this.hotLeadInfo = details;
        }
      }
    }
  };

  shouldFetchHotLeadData = (details) => {
    let followupDate = details["next_follow_up_on"] || "";
    let hardUpdateBy = details["hard_update_by"] || "";
    let priorityLead = details["priority_lead"] || false;

    let isPriorityEligible = false;
    let isFollowupEligible = false;

    if (hardUpdateBy != "") {
      if (isToday(hardUpdateBy) && priorityLead) {
        isPriorityEligible = true;
      }
    }
    if (followupDate != "") {
      if (isToday(followupDate)) {
        isFollowupEligible = true;
      }
    }
    return !isPriorityEligible && !isFollowupEligible;
  };

  onRefresh = async () => {
    if (!this.hasPermission()) {
      return;
    }
    if (this.hotLeadInfo.hasOwnProperty("id")) {
      let leadID = this.hotLeadInfo["id"];
      this.hotLeadInfo = {};
      let details = await this.fetchLeadData(leadID);
      if (details.hasOwnProperty("lead")) {
        details = details["lead"];
      }
      if (this.shouldFetchHotLeadData(details)) {
        await this.fetchHotLead();
      } else {
        this.hotLeadInfo = details;
      }
    } else {
      await this.fetchHotLead();
    }
  };

  getLeadIdentificationString = () => {
    if (
      this.hotLeadInfo.hasOwnProperty("name") &&
      this.hotLeadInfo["name"] !== null &&
      this.hotLeadInfo["name"] !== ""
    ) {
      return this.hotLeadInfo["name"];
    } else if (
      this.hotLeadInfo.hasOwnProperty("phone") &&
      this.hotLeadInfo["phone"] !== null &&
      this.hotLeadInfo["phone"] !== ""
    ) {
      return this.hotLeadInfo["phone"];
    } else if (
      this.hotLeadInfo.hasOwnProperty("flid") &&
      this.hotLeadInfo["flid"] !== null &&
      this.hotLeadInfo["flid"] !== ""
    ) {
      return this.hotLeadInfo["flid"];
    } else {
      return "";
    }
  };

  getMessage = () => {
    if (Object.keys(this.hotLeadInfo).length === 0) {
      return "";
    }

    let message = "";
    let followupDate = this.hotLeadInfo["next_follow_up_on"] || "";
    let hardUpdateBy = this.hotLeadInfo["hard_update_by"] || "";
    let priorityLead = this.hotLeadInfo["priority_lead"] || false;
    let isPriorityEligible = false;
    let isFollowupEligible = false;

    if (followupDate !== "") {
      if (isToday(followupDate)) {
        isFollowupEligible = true;
      }
    }

    if (hardUpdateBy !== "") {
      if (isToday(hardUpdateBy) && priorityLead) {
        isPriorityEligible = true;
      }
    }

    if (isFollowupEligible) {
      if (isBeforeCurrentTime(followupDate)) {
        message = `Act Now: A lead follow-up was missed at ${getLocalTimeStringFromIsoDateString(
          followupDate
        )}.`;
      } else {
        let formattedFollowupDate = moment(IsoToLocalTime(followupDate));
        let now = moment();
        let followupDateDifference = moment.duration(
          formattedFollowupDate.diff(now)
        );

        if (
          followupDateDifference.asMinutes() > 0 &&
          followupDateDifference.asMinutes() < 30
        ) {
          message = `Your next follow-up for ${this.getLeadIdentificationString()} is scheduled for ${getLocalTimeStringFromIsoDateString(
            followupDate
          )}`;
        } else {
          if (isPriorityEligible) {
            message = `Don't delay: A priority lead requires your attention by ${getLocalTimeStringFromIsoDateString(
              hardUpdateBy
            )}`;
          }
        }
      }
    } else if (isPriorityEligible) {
      message = `Don't delay: A priority lead requires your attention by ${getLocalTimeStringFromIsoDateString(
        hardUpdateBy
      )}`;
    }

    return message;
  };

  checkAndFetchHotLeadOnFollowupChange = async (
    leadID,
    followupDate,
    leadDetails
  ) => {
    if (!this.hasPermission()) {
      return;
    }

    if (this.hotLeadInfo.hasOwnProperty("id")) {
      const hotLeadID = this.hotLeadInfo["id"];
      if (leadID === hotLeadID) {
        // if followup was updated for the same lead, then it will get handled on lead details pop
        return;
      }
    }
    let minNewLeadTime = "";

    const newLeadHardUpdateBy = leadDetails["hard_update_by"] || "";

    if (followupDate !== "" && newLeadHardUpdateBy !== "") {
      minNewLeadTime = getClosestDateTime(followupDate, newLeadHardUpdateBy);
    } else if (followupDate !== "") {
      minNewLeadTime = followupDate;
    } else if (newLeadHardUpdateBy !== "") {
      minNewLeadTime = newLeadHardUpdateBy;
    }

    if (minNewLeadTime !== "" && isToday(minNewLeadTime)) {
      const hotLeadFollowupDate = this.hotLeadInfo["next_follow_up_on"] || "";
      const hotLeadHardUpdateBy = this.hotLeadInfo["hard_update_by"] || "";

      let minHotLeadTime = "";

      if (hotLeadFollowupDate !== "" && hotLeadHardUpdateBy !== "") {
        minHotLeadTime = getClosestDateTime(
          hotLeadFollowupDate,
          hotLeadHardUpdateBy
        );
      } else if (hotLeadFollowupDate !== "") {
        minHotLeadTime = hotLeadFollowupDate;
      } else if (hotLeadHardUpdateBy !== "") {
        minHotLeadTime = hotLeadHardUpdateBy;
      }

      if (minHotLeadTime === "") {
        await this.fetchHotLead();
      } else {
        if (
          getClosestDateTime(minHotLeadTime, minNewLeadTime) === minNewLeadTime
        ) {
          await this.fetchHotLead();
        }
      }
    }
  };

  stopTimer = () => {
    if (this.timer != null) {
      clearTimeout(this.timer);
    }
  };

  startTimer = () => {
    if (!this.hasPermission()) {
      return;
    }
    const now = moment();
    const currentMinute = now.minute();
    const nextThirtyMinute =
      (currentMinute / this._duration + 1) * this._duration;
    const nextThirtyMinuteDateTime = moment(now)
      .startOf("hour")
      .minutes(nextThirtyMinute);
    const duration = nextThirtyMinuteDateTime.diff(now);
    this.timer = setTimeout(async () => {
      await this.fetchHotLead();
      this.startRecursiveTimer();
    }, duration);
  };

  startRecursiveTimer = () => {
    if (!this.hasPermission()) {
      return;
    }
    // Initial random duration between 25 to 30 minutes
    const randomMinutes = Math.floor(Math.random() * 6) + 25;

    this.timer = setTimeout(async () => {
      await this.fetchHotLead();
      this.startRecursiveTimer();
    }, randomMinutes * 60 * 1000); // Convert minutes to milliseconds
  };
}

export default HotLeadState;
