import {
  Box,
  Chip,
  IconButton,
  List,
  ListItem,
  OutlinedInput,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { AddBoxOutlined } from "@mui/icons-material";

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
  white-space: nowrap;
`;

const Row = styled(Box)`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const FormFieldOptions = ({
  selectedFieldStructure,
  setSelectedFieldStructure,
  handleSave,
}) => {
  const [choice, setChoice] = useState("");
  const handleDelete = (key) => {
    let list = [...selectedFieldStructure["choices"]];

    list = list.filter((item) => item["label"] === key);
    setSelectedFieldStructure({ ...selectedFieldStructure, choices: list });
  };
  const handleAdd = () => {
    let list = [...selectedFieldStructure["choices"]];
    let index = selectedFieldStructure["choices"].findIndex(
      (item) => item["label"] === choice
    );
    if (index === -1) {
      let tempObj = { label: choice, value: choice };
      list.push(tempObj);
    }
    setSelectedFieldStructure({ ...selectedFieldStructure, choices: list });
    setChoice("");
  };
  useEffect(() => {}, [selectedFieldStructure]);
  return (
    <List>
      <ListItem>{selectedFieldStructure["name"]}</ListItem>
      {selectedFieldStructure.hasOwnProperty("label") && (
        <ListItem>
          <InputWrapper>
            <Label>Label Name</Label>
            <OutlinedInput
              value={selectedFieldStructure["label"]}
              name="label"
              onChange={(e) => {
                let newData = {
                  ...selectedFieldStructure,
                  ["label"]: e.target.value,
                };
                setSelectedFieldStructure(newData);
                handleSave(newData);
              }}
              placeholder="Enter Value"
              style={{
                width: "100%",
                height: "30px",
                fontSize: "12px",
              }}
            />
          </InputWrapper>
        </ListItem>
      )}
      {selectedFieldStructure.hasOwnProperty("description") && (
        <ListItem>
          <InputWrapper>
            <Label>Description</Label>
            <OutlinedInput
              value={selectedFieldStructure["description"]}
              name="description"
              onChange={(e) => {
                let newData = {
                  ...selectedFieldStructure,
                  ["description"]: e.target.value,
                };
                setSelectedFieldStructure(newData);
                handleSave(newData);
              }}
              placeholder="Enter Value"
              style={{
                width: "100%",
                height: "30px",
                fontSize: "12px",
              }}
            />
          </InputWrapper>
        </ListItem>
      )}
      {/* {selectedFieldStructure.hasOwnProperty("placeholder") && (
        <ListItem>
          <InputWrapper>
            <Label>Placeholder</Label>
            <OutlinedInput
              value={selectedFieldStructure["placeholder"]}
              name="placeholder"
              onChange={(e) => {
                setSelectedFieldStructure({
                  ...selectedFieldStructure,
                  ["placeholder"]: e.target.value,
                });
              }}
              placeholder="Enter Value"
              style={{
                width: "100%",
                height: "30px",
                fontSize: "12px",
              }}
            />
          </InputWrapper>
        </ListItem>
      )} */}
      <ListItem>
        <Row>
          {selectedFieldStructure.hasOwnProperty("required") && (
            <InputWrapper>
              <Label>Is Required</Label>
              <Switch
                onChange={(e) => {
                  let newData = {
                    ...selectedFieldStructure,
                    ["required"]: e.target.checked,
                  };
                  setSelectedFieldStructure(newData);
                  handleSave(newData);
                }}
                checked={selectedFieldStructure["required"]}
              />
            </InputWrapper>
          )}
          {selectedFieldStructure.hasOwnProperty("multiple") && (
            <ListItem>
              <InputWrapper>
                <Label>Is Multiple</Label>
                <Switch
                  onChange={(e) => {
                    let newData = {
                      ...selectedFieldStructure,
                      ["multiple"]: e.target.value,
                    };
                    setSelectedFieldStructure(newData);
                    handleSave(newData);
                  }}
                  checked={selectedFieldStructure["multiple"]}
                />
              </InputWrapper>
            </ListItem>
          )}
        </Row>
      </ListItem>
      {selectedFieldStructure.hasOwnProperty("defaultValue") && (
        <ListItem>
          <InputWrapper>
            <Label>Default Value</Label>
            <OutlinedInput
              value={selectedFieldStructure["deafultValue"]}
              name="defaultValue"
              onChange={(e) => {
                let newData = {
                  ...selectedFieldStructure,
                  ["defaultValue"]: e.target.value,
                };
                setSelectedFieldStructure(newData);
                handleSave(newData);
              }}
              placeholder="Enter Value"
              style={{
                width: "100%",
                height: "30px",
                fontSize: "12px",
              }}
            />
          </InputWrapper>
        </ListItem>
      )}
      {selectedFieldStructure.hasOwnProperty("minCharacters") &&
        selectedFieldStructure.hasOwnProperty("maxCharacters") && (
          <>
            <ListItem>
              <InputWrapper>
                <Label>Min. Characters</Label>
                <OutlinedInput
                  value={selectedFieldStructure["minCharacters"]}
                  onChange={(e) => {
                    let newData = {
                      ...selectedFieldStructure,
                      ["minCharacters"]: e.target.value,
                    };
                    setSelectedFieldStructure(newData);
                    handleSave(newData);
                  }}
                  placeholder="Enter Value"
                  style={{
                    width: "100%",
                    height: "30px",
                    fontSize: "12px",
                  }}
                />
              </InputWrapper>
            </ListItem>
            <ListItem>
              <InputWrapper>
                <Label>Max. Characters</Label>
                <OutlinedInput
                  value={selectedFieldStructure["maxCharacters"]}
                  onChange={(e) => {
                    let newData = {
                      ...selectedFieldStructure,
                      ["maxCharacters"]: e.target.value,
                    };
                    setSelectedFieldStructure(newData);
                    handleSave(newData);
                  }}
                  placeholder="Enter Value"
                  style={{
                    width: "100%",
                    height: "30px",
                    fontSize: "12px",
                  }}
                />
              </InputWrapper>
            </ListItem>
          </>
        )}

      {selectedFieldStructure.hasOwnProperty("min") &&
        selectedFieldStructure.hasOwnProperty("max") && (
          <>
            <ListItem>
              <InputWrapper>
                <Label>Min.</Label>
                <OutlinedInput
                  value={selectedFieldStructure["min"]}
                  onChange={(e) => {
                    let newData = {
                      ...selectedFieldStructure,
                      ["min"]: e.target.value,
                    };
                    setSelectedFieldStructure(newData);
                    handleSave(newData);
                  }}
                  placeholder="Enter Value"
                  style={{
                    width: "100%",
                    height: "30px",
                    fontSize: "12px",
                  }}
                />
              </InputWrapper>
            </ListItem>
            <ListItem>
              <InputWrapper>
                <Label>Max.</Label>
                <OutlinedInput
                  value={selectedFieldStructure["max"]}
                  onChange={(e) => {
                    let newData = {
                      ...selectedFieldStructure,
                      ["max"]: e.target.value,
                    };
                    setSelectedFieldStructure(newData);
                    handleSave(newData);
                  }}
                  placeholder="Enter Value"
                  style={{
                    width: "100%",
                    height: "30px",
                    fontSize: "12px",
                  }}
                />
              </InputWrapper>
            </ListItem>
          </>
        )}

      {selectedFieldStructure.hasOwnProperty("choices") && (
        <ListItem>
          <InputWrapper>
            <Label>Choices</Label>
            <Row>
              <OutlinedInput
                value={choice}
                name="choices"
                onChange={(e) => {
                  setChoice(e.target.value);
                }}
                placeholder="Enter Value"
                style={{
                  width: "100%",
                  height: "30px",
                  fontSize: "12px",
                }}
              />
              <IconButton
                color="primary"
                disabled={choice === ""}
                onClick={handleAdd}
              >
                <AddBoxOutlined />
              </IconButton>
            </Row>
            {selectedFieldStructure["choices"].length > 0 && (
              <Row style={{ flexWrap: "wrap", padding: "4px", width: "100%" }}>
                {selectedFieldStructure["choices"].map((item, i) => {
                  return (
                    <Chip
                      key={i}
                      label={item["label"]}
                      onDelete={() => handleDelete(item["label"])}
                    />
                  );
                })}
              </Row>
            )}
          </InputWrapper>
        </ListItem>
      )}
    </List>
  );
};

export default FormFieldOptions;
