import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Toolbar,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { OutlinedInput, Typography } from "@mui/material";
import { getLocalizedText } from "../../../Functions";
import { myTheme } from "../../../themeUtils";
import MuiAlert from "@mui/material/Alert";
import {
  createLeadFormSectionApi,
  updateLeadFormSectionApi,
} from "../../../Api";
const dialogWidth = 420;
const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 16px;
  flex-direction: column;
  row-gap: 20px;
`;
const Message = styled(Typography)`
  font: normal normal normal 16px Open Sans;
  color: #4d4e4f;
  margin: 0px;
  text-align: center;
`;
const AddSectionDialog = ({
  open,
  setOpen,
  isEdit,
  setIsEdit,
  sectionData,
  sectionList,
  selectedSectionID,
  formData,
  handleAddSection,
  handleEditSection,
}) => {
  const [details, setDetails] = useState({ title: "", desc: "" });
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const create = async () => {
    let payload = {
      ...details,
      sequence: sectionList.length,
    };
    let response = await createLeadFormSectionApi({
      formID: formData["id"],

      payload: payload,
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      let tempObj = {
        ...details,
        sequence: sectionList.length,
        id: response.data.id,
      };
      handleAddSection(tempObj);
      setIsSubmitSuccess(true);
    }
  };

  const update = async () => {
    let sequence = -1;
    let index = sectionList.findIndex(
      (item) => item["id"] === selectedSectionID
    );
    if (index !== -1) {
      sequence = sectionList[index]["sequence"];
    }
    let payload = {
      ...details,
      sequence: sequence,
    };
    let response = await updateLeadFormSectionApi({
      formID: formData["id"],
      sectionID: selectedSectionID,
      payload: payload,
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setIsSubmitSuccess(true);
      let tempObj = {
        ...details,
        sequence: sequence,
        id: response.data.id,
      };
      handleEditSection(tempObj);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsFetching(true);
    if (isEdit) {
      await update();
    } else {
      await create();
    }
    setIsFetching(false);
  };
  const handleClose = () => {
    setIsEdit(false);
    setOpen(false);
  };
  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (isEdit) {
      let tempObj = { title: sectionData["title"], desc: sectionData["desc"] };
      setDetails(tempObj);
    }
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!isSubmitSuccess ? (
          <ModelContainer>
            <Box component={"form"} onSubmit={handleSubmit}>
              <AppBar
                component={"nav"}
                elevation={0}
                position="sticky"
                color="inherit"
                sx={{
                  top: 0,
                  bottom: "auto",
                  width: "100%",
                  right: 0,
                }}
              >
                <Toolbar
                  style={{
                    padding: "16px",
                  }}
                >
                  <HeaderWrapper>
                    {isEdit ? (
                      <Header>Edit Section</Header>
                    ) : (
                      <Header>Add a new section</Header>
                    )}
                    <SubHeader>
                      {getLocalizedText("fill_in_the_below_details")}
                    </SubHeader>
                  </HeaderWrapper>
                </Toolbar>
              </AppBar>
              <Divider style={{ margin: "0px 16px" }} />
              <Container>
                <Box
                  style={{
                    height: "100%",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <Label>Section Title*</Label>
                        <OutlinedInput
                          style={{ height: "30px" }}
                          inputProps={{
                            min: 0,
                            "aria-label": "weight",
                            style: {
                              fontSize: "12px",
                            },
                          }}
                          onChange={handleChange}
                          value={details.title}
                          required
                          type={"text"}
                          name="title"
                          placeholder={"Enter Value"}
                        />
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <Label>Description*</Label>
                        <OutlinedInput
                          multiline
                          rows={3}
                          inputProps={{
                            min: 0,
                            "aria-label": "weight",
                            style: {
                              fontSize: "12px",
                            },
                          }}
                          onChange={handleChange}
                          value={details.desc}
                          name="desc"
                          type={"text"}
                          required
                          placeholder={"Enter Value"}
                        />
                      </InputWrapper>
                    </Grid>
                  </Grid>
                </Box>
              </Container>
              <AppBar
                position="static"
                color="inherit"
                elevation={0}
                sx={{ top: "auto", bottom: 0, width: "100%" }}
              >
                <Toolbar
                  style={{
                    padding: "16px",
                    width: dialogWidth,
                    position: "relative",
                  }}
                >
                  {isSubmitFail && (
                    <ErrorAlert
                      onClose={(e) => {
                        e.stopPropagation();
                        setIsSubmitFail(false);
                      }}
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      {errorMessage}
                    </ErrorAlert>
                  )}
                  <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item>
                      <Button
                        onClick={() => {
                          setOpen(false);
                        }}
                        variant="contained"
                        color="default"
                        style={{
                          fontSize: "12px",
                          textTransform: "none",
                          fontWeight: "bold",
                        }}
                      >
                        {getLocalizedText("cancel")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        disabled={isFetching}
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          width: "fit-content",
                          textTransform: "none",
                        }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
            </Box>
          </ModelContainer>
        ) : (
          <ModelContainer
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MessageWrapper style={{ marginBottom: "0px", padding: "16px" }}>
              {isEdit ? (
                <Message>Section updated successfully!</Message>
              ) : (
                <Message>Section created successfully!</Message>
              )}

              <Button
                onClick={handleClose}
                type="button"
                variant="contained"
                color="default"
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "100px",
                }}
              >
                {getLocalizedText("close")}
              </Button>
            </MessageWrapper>
          </ModelContainer>
        )}
      </Modal>
    </>
  );
};

export default AddSectionDialog;
