import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 28px;
  column-gap: 20px;
  width: 428px;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
`;

const Label = styled.span`
  font: normal normal 500 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Detail = styled.span`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const SecondScreen = (props) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    if (props.data.detected_location !== null) {
      const tempData = JSON.parse(props.data.detected_location);
      setData(tempData);
    }
  }, []);

  return (
    <>
      {data !== null && (
        <InputContainer>
          <InputWrapper>
            <Label>Location Name</Label>
            <Detail>{data.name}</Detail>
          </InputWrapper>
          <InputWrapper>
            <Label>Street</Label>
            <Detail>{data.street}</Detail>
          </InputWrapper>
          <InputWrapper>
            <Label>Postal Code</Label>
            <Detail>{data.postalCode}</Detail>
          </InputWrapper>
          <InputWrapper>
            <Label>State</Label>
            <Detail>{data.administrativeArea}</Detail>
          </InputWrapper>
          <InputWrapper>
            <Label>Locality</Label>
            <Detail>{data.locality}</Detail>
          </InputWrapper>
          <InputWrapper>
            <Label>Sub-Locality</Label>
            <Detail>{data.subLocality}</Detail>
          </InputWrapper>
        </InputContainer>
      )}
    </>
  );
};

export default SecondScreen;
