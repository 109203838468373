import React from "react";
import { observer } from "mobx-react-lite";
import { sizes } from "../../Config";
import styled from "styled-components";
import rootStore from "../../stores/RootStore";
import { InputLabel, MenuItem } from "@mui/material";
import { FormControl, makeStyles, Select } from "@material-ui/core";

const Text = styled.p`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
  margin-right: 8px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #F4F4F4",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #F4F4F4",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #F4F4F4",
      borderRadius: "4px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const PageSizeDropdown = (props) => {
  const { userStore } = rootStore;
  let tabId = userStore.TabId;
  const classes = useStyles();
  return (
    <Row>
      <Text>Row Per Page :</Text>
      <FormControl
        sx={{ m: 1, minWidth: 70 }}
        classes={{
          root: classes.quantityRoot,
        }}
      >
        <Select
          classes={{
            icon: classes.icon,
          }}
          variant="outlined"
          disableUnderline
          displayEmpty
          value={props.size ? props.size : userStore.PageSize[tabId]}
          onChange={props.handlePageSize}
          style={{
            fontSize: "12px",
            width: "70px",
            fontWeight: "bold",
            height: "30px",
            padding: "auto 12px",
          }}
        >
          {sizes.map((e) => {
            return <MenuItem value={e}>{e}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </Row>
  );
};

export default observer(PageSizeDropdown);
