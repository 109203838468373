import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  Modal,
  Select,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect } from "react";
import styled from "styled-components";
import { Close } from "@material-ui/icons";
import "./rule.css";
import { Alert, MenuItem } from "@mui/material";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import analytics from "../../../Analytics";
import { getLocalizedText } from "../../../Functions";
import { updateImportRuleList } from "../../../Db";
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 30px;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 258px;
`;
const ModelWrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: fit-content;
  width: 398px;
`;
const Header = styled.p`
  margin: 0px;
  font: normal normal 600 18px Open Sans;
`;

const SubHeader = styled.p`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Label = styled.span`
  font: normal normal normal 12px Open Sans;
  margin-bottom: 6px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  column-gap: 10px;
`;

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const UpdateLeadRuleModal = (props) => {
  const classes = useStyles();
  const [selectedColumn, setSelectedColumn] = useState({});
  const [selectedUpdateRule, setSelectedUpdateRule] = useState({});

  useEffect(() => {
    setSelectedUpdateRule(props.selectedUpdateRule);
    // setSelectedColumn(props.selectedColumn);
  }, []);

  const handleSave = (e) => {
    e.preventDefault();
    props.setSelectedUpdateRule(selectedUpdateRule);
    props.setOpen(false);
    // if (selectedColumn === "" || selectedColumn === null) {
    //   props.setOpen(false);
    // } else {
    //   props.setSelectedColumn(selectedColumn);
    //   props.setOpen(false);
    // }
  };

  return (
    <>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <IconButton
              onClick={() => {
                analytics.triggerEvent(
                  4625000105,
                  "close_icon_tap",
                  "assignment_rules_form",
                  "close_icon",
                  {}
                );
                props.setOpen(false);
              }}
              style={{
                zIndex: "9999",
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
              }}
            >
              <Close />
            </IconButton>

            <ModelWrapper>
              <Header>{getLocalizedText("update_lead_rule")}</Header>

              <SubHeader>
                {getLocalizedText("rules_for_updating_leads_on_import")}
              </SubHeader>
              <Divider style={{ margin: "10px 0px" }} />
              <Wrapper onSubmit={handleSave}>
                <Column>
                  <InputWrapper>
                    <Label>
                      {getLocalizedText("rule")}
                      {<span style={{ color: "red" }}>*</span>}
                    </Label>
                    <FormControl
                      classes={{
                        root: classes.quantityRoot,
                      }}
                    >
                      <Select
                        classes={{
                          icon: classes.icon,
                        }}
                        name="update_rule"
                        required
                        displayEmpty
                        variant="outlined"
                        value={selectedUpdateRule}
                        onChange={(e) => {
                          setSelectedUpdateRule(e.target.value);
                        }}
                        style={{
                          marginBottom: "10px",
                          width: "100%",
                          height: "30px",
                          fontSize: "12px",
                          fontWeight: "bold",
                          borderRadius: "0px",
                        }}
                      >
                        {updateImportRuleList.map((item, i) => {
                          return (
                            <MenuItem
                              key={i}
                              id={i}
                              value={item}
                              style={{ fontSize: "12px" }}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </InputWrapper>
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    <Label
                      style={{
                        marginRight: "10px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Description:
                    </Label>
                    <Label
                      style={{
                        fontWeight: "bold",
                        marginLeft: "16px",
                      }}
                    >
                      {selectedUpdateRule["desc"]}
                    </Label>
                  </Box>

                  {/* <InputWrapper>
                    <Label>{getLocalizedText("select_column")}</Label>
                    <FormControl
                      classes={{
                        root: classes.quantityRoot,
                      }}
                    >
                      <Select
                        classes={{
                          icon: classes.icon,
                        }}
                        name="column"
                        required
                        displayEmpty
                        variant="outlined"
                        value={selectedColumn}
                        onChange={(e) => {
                          setSelectedColumn(e.target.value);
                        }}
                        style={{
                          marginBottom: "20px",
                          width: "100%",
                          height: "30px",
                          fontSize: "12px",
                          fontWeight: "bold",
                          borderRadius: "0px",
                        }}
                      >
                        {props.allColumnList.map((item, i) => {
                          return (
                            <MenuItem
                              key={i}
                              id={i}
                              value={item}
                              style={{ fontSize: "12px" }}
                            >
                              {item.headerName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </InputWrapper> */}
                </Column>

                <ButtonWrapper>
                  <Button
                    variant="contained"
                    color="default"
                    onClick={() => {
                      props.setOpen(false);
                    }}
                    style={{
                      font: "normal normal 600 12px Open Sans",
                      width: "130px",
                      textTransform: "none",
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                  <Button
                    onClick={() => {}}
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                      font: "normal normal 600 12px Open Sans",
                      width: "130px",
                      textTransform: "none",
                    }}
                  >
                    {getLocalizedText("save")}
                  </Button>
                </ButtonWrapper>
              </Wrapper>
            </ModelWrapper>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default observer(UpdateLeadRuleModal);
