import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import { styled } from "@mui/material/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  CircularProgress,
  OutlinedInput,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@material-ui/core";
import PreviewTemplate from "../../components/create_landingpage_components/PreviewTemplate";
import EditFormComponent from "../../components/create_landingpage_components/EditFormComponent";
import ColorPickerModal from "../../components/color_picker_modal/ColorPickerModal";
import {
  Close,
  ExpandMore,
  Rectangle,
  Save,
  Visibility,
} from "@mui/icons-material";
import { getWebFormDataApi, updateWebFormApi } from "../../Api";
import MessageWithLoadingDialog from "../../components/create_landingpage_components/MessageWithLoadingDialog";
import BookDemoTemplate from "../../components/create_landingpage_components/templates/BookDemoTemplate";
import GetQuoteTemplate from "../../components/create_landingpage_components/templates/GetQuoteTemplate";
import { templateBgColors } from "../../Db";
import SubmitInformationTemplate from "../../components/create_landingpage_components/templates/SubmitInformationTemplate";
import EventRegistrationTemplate from "../../components/create_landingpage_components/templates/EventRegistrationTemplate";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SchoolTemplate from "../../components/create_landingpage_components/templates/SchoolTemplate";
import InsuranceTemplate from "../../components/create_landingpage_components/templates/InsuranceTemplate.js";
const drawerWidth = 300;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;

const Label = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;
const SectionHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #979799;
  margin-bottom: 8px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const InputLabel = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;

const UpdateLandingPage = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const [contentState, setContentSate] = useState({});
  const [openPreview, setOpenPreview] = useState(false);
  const [formState, setFormState] = useState([]);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [primaryColor, setPrimaryColor] = useState("#21589F");
  const [secondaryColor, setSecondaryColor] = useState("#EEAB38");
  const [openPrimaryColorPicker, setOpenPrimaryColorPicker] = useState(false);
  const [openSecondaryColorPicker, setOpenSecondaryColorPicker] =
    useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [openSubmitResponseDialog, setOpenSubmitResponseDialog] =
    useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [loading, setLoading] = useState(true);
  const [logoURL, setLogoURL] = useState("");
  const [formData, setFormData] = useState({});

  const getWebformDetails = async () => {
    let response = await getWebFormDataApi(queryParams.get("formID"));
    setFormData(response);
    if (response.hasOwnProperty("schema")) {
      setContentSate(response["schema"]["content_meta"]);
      setFormState(response["schema"]["form_meta"]);
      setPrimaryColor(response["schema"]["primary_color"]);
      setSecondaryColor(response["schema"]["secondary_color"]);
      setLogoURL(response["schema"]["logo_url"]);
    }
  };

  const init = async () => {
    await getWebformDetails();
    setLoading(false);
  };
  React.useEffect(() => {
    init();
  }, []);

  const handleSave = async () => {
    setUploadingFile(true);
    setOpenSubmitResponseDialog(true);
    let formMeta = [...formState];
    formMeta.forEach((fieldMeta) => {
      let fieldName = fieldMeta["label"].toLowerCase().replace(/\s+/g, "_");
      fieldMeta["field_name"] = fieldName;
    });
    let payload = {
      name: formData["name"],
      form_type: formData["form_type"],
      desc: formData["desc"],
      template_name: formData["template_name"],
      schema: {
        content_meta: { ...contentState },
        form_meta: formMeta,
        primary_color: primaryColor,
        secondary_color: secondaryColor,
        logo_url: logoURL,
      },
    };

    let response = await updateWebFormApi({
      formID: formData["id"],
      payload: payload,
    });
    if (!response.hasError()) {
      setIsSubmitFail(false);
      setSuccessMessage("Landing page saved successfully!");
      setUploadingFile(false);
      await getWebformDetails();
    } else {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
      setUploadingFile(false);
    }
  };

  const RenderTemplate = () => {
    if (
      formData.hasOwnProperty("template_name") &&
      formData["template_name"] === "book_demo"
    ) {
      return (
        <BookDemoTemplate
          gradientColor={templateBgColors["book_demo"]}
          contentState={contentState}
          formState={formState}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          logoURL={logoURL}
        />
      );
    } else if (
      formData.hasOwnProperty("template_name") &&
      formData["template_name"] === "get_quote"
    ) {
      return (
        <GetQuoteTemplate
          gradientColor={templateBgColors["get_quote"]}
          contentState={contentState}
          formState={formState}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          logoURL={logoURL}
        />
      );
    } else if (
      formData.hasOwnProperty("template_name") &&
      formData["template_name"] === "submit_information"
    ) {
      return (
        <SubmitInformationTemplate
          gradientColor={templateBgColors["submit_information"]}
          contentState={contentState}
          formState={formState}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          logoURL={logoURL}
        />
      );
    } else if (
      formData.hasOwnProperty("template_name") &&
      formData["template_name"] === "event_registration"
    ) {
      return (
        <EventRegistrationTemplate
          gradientColor={templateBgColors["event_registration"]}
          contentState={contentState}
          formState={formState}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          logoURL={logoURL}
        />
      );
    } else if (
      formData.hasOwnProperty("template_name") &&
      formData["template_name"] === "admission_form"
    ) {
      return (
        <SchoolTemplate
          contentState={contentState}
          formState={formState}
          logoURL={logoURL}
          primaryColor={primaryColor}
        />
      );
    } else if (
      formData.hasOwnProperty("template_name") &&
      formData["template_name"] === "test_ride_page"
    ) {
      return (
        <InsuranceTemplate
          contentState={contentState}
          formState={formState}
          logoURL={logoURL}
        />
      );
    }
  };

  const getTheme = ({ primaryColor, secondaryColor }) => {
    const theme = createTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
        },
      },
      palette: {
        primary: {
          main: primaryColor,
        },
        secondary: {
          main: secondaryColor,
        },
      },
      typography: {
        fontFamily: "Roboto",
      },
    });
    return theme;
  };

  const handleLogo = (event) => {
    const file = event.target.files[0];
    if (file) {
      convertImageToBase64(file);
    }
  };

  const convertImageToBase64 = (imageFile) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64 = reader.result;
      setLogoURL(base64);
    };
    reader.readAsDataURL(imageFile);
  };

  return (
    <>
      {!loading ? (
        <Box sx={{ display: "flex" }}>
          <Box
            component="main"
            sx={{ flexGrow: 1, width: "80%", height: "100%" }}
          >
            <ThemeProvider
              theme={() =>
                getTheme({
                  primaryColor: primaryColor,
                  secondaryColor: secondaryColor,
                })
              }
            >
              <RenderTemplate />
            </ThemeProvider>
          </Box>
          <CssBaseline />
          <Box>
            <Drawer
              sx={{
                width: "300px",
                flexShrink: 0,
                zIndex: 10,
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                },
              }}
              variant="permanent"
              anchor="right"
            >
              <AppBar
                elevation={0}
                component={"nav"}
                position="sticky"
                color="inherit"
                sx={{ top: 0, bottom: "auto", width: drawerWidth, right: 0 }}
              >
                <Toolbar style={{ padding: "16px", width: "300px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Header>Properties</Header>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: "10px",
                      }}
                    >
                      <Tooltip title="Preview">
                        <Visibility
                          onClick={() => {
                            setOpenPreview(true);
                          }}
                          color="primary"
                          style={{ cursor: "pointer" }}
                        />
                      </Tooltip>
                      <Tooltip title="Save">
                        <Save
                          onClick={handleSave}
                          color="primary"
                          style={{ cursor: "pointer" }}
                        />
                      </Tooltip>
                      <Tooltip title="Close">
                        <Close
                          onClick={() => {
                            navigate("/web-forms");
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </Tooltip>
                    </Box>
                  </Box>
                </Toolbar>
              </AppBar>
              <Divider />
              <List
                style={{
                  width: drawerWidth,
                }}
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                  overflowY: "auto",
                  height: "100%",
                  padding: "0px",
                  overflowY: "auto",
                  margin: 0,
                  padding: 0,
                  listStyle: "none",
                  height: "100%",
                  "&::-webkit-scrollbar": {
                    width: "0",
                  },
                }}
              >
                <ListItem style={{ padding: "16px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <SectionHeader>Styles</SectionHeader>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <InputWrapper>
                          <InputLabel>Primary Color</InputLabel>
                          <Rectangle
                            style={{
                              color: primaryColor,
                              cursor: "pointer",
                              width: "45px",
                              height: "auto",
                            }}
                            onClick={() => {
                              setOpenPrimaryColorPicker(true);
                            }}
                          />
                        </InputWrapper>
                      </Grid>
                      <Grid item xs={6}>
                        <InputWrapper>
                          <InputLabel>Secondary Color</InputLabel>
                          <Rectangle
                            style={{
                              color: secondaryColor,
                              cursor: "pointer",
                              width: "45px",
                              height: "auto",
                            }}
                            onClick={() => {
                              setOpenSecondaryColorPicker(true);
                            }}
                          />
                        </InputWrapper>
                      </Grid>
                    </Grid>
                  </Box>
                </ListItem>
                <Divider />
                <ListItem style={{ padding: "16px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <InputWrapper>
                          <InputLabel>Logo</InputLabel>

                          <input
                            type="file"
                            onChange={handleLogo}
                            style={{
                              width: "100%",
                              fontSize: "12px",
                              padding: "2px",
                              backgroundColor: "#EFEFF4",
                              border: "none",
                              borderRadius: "6px",
                            }}
                            accept="image/*"
                          />
                        </InputWrapper>
                      </Grid>
                      {contentState.hasOwnProperty("title") && (
                        <Grid item xs={12}>
                          <InputWrapper>
                            <InputLabel>Title</InputLabel>

                            <OutlinedInput
                              style={{ height: "30px" }}
                              inputProps={{
                                min: 0,
                                "aria-label": "weight",
                                style: {
                                  fontSize: "12px",
                                },
                              }}
                              onChange={(e) => {
                                setContentSate({
                                  ...contentState,
                                  title: e.target.value,
                                });
                              }}
                              value={contentState.title}
                              name={"title"}
                              type={"text"}
                              placeholder={"Enter Value"}
                            />
                          </InputWrapper>
                        </Grid>
                      )}
                      {contentState.hasOwnProperty("sub_title") && (
                        <Grid item xs={12}>
                          <InputWrapper>
                            <InputLabel>Sub Title</InputLabel>

                            <OutlinedInput
                              style={{ height: "30px" }}
                              inputProps={{
                                min: 0,
                                "aria-label": "weight",
                                style: {
                                  fontSize: "12px",
                                },
                              }}
                              onChange={(e) => {
                                setContentSate({
                                  ...contentState,
                                  sub_title: e.target.value,
                                });
                              }}
                              value={contentState.sub_title}
                              name={"sub_title"}
                              type={"text"}
                              placeholder={"Enter Value"}
                            />
                          </InputWrapper>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <InputWrapper>
                          <InputLabel>Copyright</InputLabel>

                          <OutlinedInput
                            style={{ height: "30px" }}
                            inputProps={{
                              min: 0,
                              "aria-label": "weight",
                              style: {
                                fontSize: "12px",
                              },
                            }}
                            name={"copyright"}
                            type={"text"}
                            value={contentState.footer_text}
                            onChange={(e) => {
                              setContentSate({
                                ...contentState,
                                footer_text: e.target.value,
                              });
                            }}
                            placeholder={"Enter Value"}
                          />
                        </InputWrapper>
                      </Grid>
                      {contentState.hasOwnProperty("key_points") && (
                        <Grid item xs={12}>
                          <Accordion
                            elevation={0}
                            style={{ padding: "0px", width: "100%" }}
                          >
                            <AccordionSummary
                              style={{ padding: "0px" }}
                              expandIcon={<ExpandMore />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                            >
                              <SectionHeader style={{ marginBottom: "0px" }}>
                                Key Points
                              </SectionHeader>
                            </AccordionSummary>
                            <AccordionDetails style={{ padding: "0px" }}>
                              <Grid container spacing={2}>
                                {contentState.hasOwnProperty("key_points") &&
                                  contentState["key_points"].map(
                                    (item, index) => {
                                      return (
                                        <Grid item xs={12}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "10px",
                                            }}
                                          >
                                            <Box
                                              component={"fieldset"}
                                              sx={{
                                                border: "1px solid #cccccc",
                                                padding: "12px",
                                                width: "100%",
                                              }}
                                            >
                                              <legend
                                                style={{
                                                  width: "auto",
                                                  marginBottom: "0px",
                                                  fontSize: "16px",
                                                }}
                                              >
                                                {index + 1}
                                              </legend>
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  width: "100%",
                                                  gap: "16px",
                                                }}
                                              >
                                                <InputWrapper>
                                                  <Label>Title</Label>
                                                  <OutlinedInput
                                                    style={{ height: "30px" }}
                                                    inputProps={{
                                                      min: 0,
                                                      "aria-label": "weight",
                                                      style: {
                                                        fontSize: "12px",
                                                      },
                                                    }}
                                                    name={"title"}
                                                    type={"text"}
                                                    value={item["title"]}
                                                    placeholder={"Enter Value"}
                                                    onChange={(e) => {
                                                      let tempList =
                                                        contentState.key_points;
                                                      tempList[index]["title"] =
                                                        e.target.value;
                                                      setContentSate({
                                                        ...contentState,
                                                        key_points: tempList,
                                                      });
                                                    }}
                                                  />
                                                </InputWrapper>
                                                <InputWrapper>
                                                  <Label>Sub Title</Label>
                                                  <OutlinedInput
                                                    style={{ height: "30px" }}
                                                    inputProps={{
                                                      min: 0,
                                                      "aria-label": "weight",
                                                      style: {
                                                        fontSize: "12px",
                                                      },
                                                    }}
                                                    name={"sub_title"}
                                                    onChange={(e) => {
                                                      let tempList =
                                                        contentState.key_points;
                                                      tempList[index][
                                                        "sub_title"
                                                      ] = e.target.value;
                                                      setContentSate({
                                                        ...contentState,
                                                        key_points: tempList,
                                                      });
                                                    }}
                                                    type={"text"}
                                                    value={item["sub_title"]}
                                                    placeholder={"Enter Value"}
                                                  />
                                                </InputWrapper>
                                              </Box>
                                            </Box>
                                          </Box>
                                        </Grid>
                                      );
                                    }
                                  )}
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </ListItem>
                <Divider />
                <ListItem style={{ padding: "16px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <SectionHeader>Links</SectionHeader>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <InputWrapper>
                          <InputLabel>Privacy Policy</InputLabel>

                          <OutlinedInput
                            style={{ height: "30px" }}
                            inputProps={{
                              min: 0,
                              "aria-label": "weight",
                              style: {
                                fontSize: "12px",
                              },
                            }}
                            name={"privacy_policy"}
                            type={"text"}
                            placeholder={"Enter Value"}
                            value={contentState.pp_link}
                            onChange={(e) => {
                              setContentSate({
                                ...contentState,
                                pp_link: e.target.value,
                              });
                            }}
                          />
                        </InputWrapper>
                      </Grid>
                    </Grid>
                  </Box>
                </ListItem>
                <Divider />
                <ListItem style={{ padding: "16px", paddingTop: "0px" }}>
                  <Accordion
                    elevation={0}
                    style={{ padding: "0px", width: "100%" }}
                    expanded={openEditForm}
                    onChange={() => {
                      setOpenEditForm(!openEditForm);
                    }}
                  >
                    <AccordionSummary
                      style={{ padding: "0px" }}
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <SectionHeader style={{ marginBottom: "0px" }}>
                        Form Details
                      </SectionHeader>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: "0px" }}>
                      {openEditForm && (
                        <EditFormComponent
                          formState={formState}
                          setFormState={setFormState}
                          contentState={contentState}
                          setContentState={setContentSate}
                        />
                      )}
                    </AccordionDetails>
                  </Accordion>
                </ListItem>
              </List>
            </Drawer>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {openPreview && (
        <PreviewTemplate
          open={openPreview}
          setOpen={setOpenPreview}
          Component={RenderTemplate}
          contentState={contentState}
          formState={formState}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          logoURL={logoURL}
        />
      )}

      {openPrimaryColorPicker && (
        <ColorPickerModal
          color={primaryColor}
          setColor={setPrimaryColor}
          open={openPrimaryColorPicker}
          setOpen={setOpenPrimaryColorPicker}
        />
      )}
      {openSecondaryColorPicker && (
        <ColorPickerModal
          color={secondaryColor}
          setColor={setSecondaryColor}
          open={openSecondaryColorPicker}
          setOpen={setOpenSecondaryColorPicker}
        />
      )}
      {openSubmitResponseDialog && (
        <MessageWithLoadingDialog
          open={openSubmitResponseDialog}
          setOpen={setOpenSubmitResponseDialog}
          successMessage={successMessage}
          errorMessage={errorMessage}
          loading={uploadingFile}
          isError={isSubmitFail}
          handleClose={() => {
            setOpenSubmitResponseDialog(false);
          }}
        />
      )}
    </>
  );
};

export default observer(UpdateLandingPage);
