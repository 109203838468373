import { ArrowForwardIos } from "@material-ui/icons";
import { ArrowBackIosNew } from "@mui/icons-material";
import { Box, IconButton, styled, Tooltip } from "@mui/material";
import React from "react";

const Container = styled(Box)`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 100%;
`;

const LeadDetailsNavigationComponent = ({
  handlePrev,
  handleNext,
  isPrevButtonDisabled,
  isNextButtonDisabled,
}) => {
  return (
    <Container>
      <Tooltip title="Prev Lead">
        <IconButton
          color="primary"
          onClick={handlePrev}
          disabled={isPrevButtonDisabled}
        >
          <ArrowBackIosNew style={{ width: "12px", height: "12px" }} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Next Lead">
        <IconButton
          color="primary"
          onClick={handleNext}
          disabled={isNextButtonDisabled}
        >
          <ArrowForwardIos style={{ width: "12px", height: "12px" }} />
        </IconButton>
      </Tooltip>
    </Container>
  );
};

export default LeadDetailsNavigationComponent;
