import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { Box } from "@material-ui/core";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { IconButton, Typography } from "@mui/material";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import ViewTaskInfoDialog from "./ViewTaskInfoDialog";
const localizer = momentLocalizer(moment);

const Card = styled(Box)`
  display: flex;
  padding: 18px;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

const CalendarLabel = styled(Typography)`
  font: normal normal 600 20px Open Sans;
`;

const CalendarWidget = ({
  eventList,
  onSlotClick,
  lastViewedCalendarDate,
  onCalendarNavigate,
}) => {
  // console.log("event list:::", JSON.stringify(eventList));
  const [selectedDateEventList, setSelectedDateEventList] = useState([]);
  const [showDialog, setShowDialog] = useState(false);

  const handleSelectEvent = (slotInfo) => {
    //if event is task, open task listing - else show popup
    if (slotInfo.event_type === "Task") {
      onSlotClick(slotInfo);
    } else {
      const { start, end } = slotInfo;
      const eventsForThisDay = eventList.filter(
        (event) => event.start >= start && event.start <= end
      );
      setSelectedDateEventList(eventsForThisDay);
      if (eventsForThisDay.length > 0) {
        setShowDialog(true);
      }
    }
  };

  const dayStyleGetter = (date) => {
    // for adding more prominent highlight color for current day
    let currentDate = `${new Date().getDate()} ${
      new Date().getMonth()
    } ${new Date().getFullYear()}`;
    let calendarDate = `${date.getDate()} ${
      date.getMonth() 
    } ${date.getFullYear()}`;

    if (calendarDate === currentDate)
      return {
        style: {
          backgroundColor: "#A7D9FF",
          border: "1px solid gray",
          margin: 0,
          padding: 0,
        },
      };
  };

  const eventStyleGetter = (event) => {
    let backgroundColor = "#3174ad"; // Default color

    // Customize the color based on event properties
    if (event.type === "holiday") {
      backgroundColor = "green"; // Yellow
    } else if (event.type === "leave") {
      backgroundColor = "red"; // Red
    } else if (event.type === "event") {
      backgroundColor = "blue";
    }

    return {
      style: {
        fontSize: "12px",
        backgroundColor: backgroundColor,
      },
    };
  };

  const customToolbar = (toolbar) => {
    const { label } = toolbar;
    return (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <CalendarLabel>{label}</CalendarLabel>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            color="primary"
            onClick={() => toolbar.onNavigate("PREV")}
          >
            <ArrowBack />
          </IconButton>

          <IconButton
            color="primary"
            onClick={() => toolbar.onNavigate("NEXT")}
          >
            <ArrowForward />
          </IconButton>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Card>
        <Calendar
          views={["month"]}
          localizer={localizer}
          components={{ toolbar: customToolbar }}
          events={eventList}
          startAccessor="start"
          endAccessor="end"
          defaultDate={lastViewedCalendarDate}
          dayPropGetter={dayStyleGetter}
          eventPropGetter={eventStyleGetter}
          onSelectEvent={handleSelectEvent}
          onSelectSlot={onSlotClick}
          onNavigate={(newDate) => {
            onCalendarNavigate(newDate);
          }}
          showAllEvents={true}
          style={{ height: "calc(100vh - 300px)", width: "100%", zIndex: 0 }}
          selectable
        />
      </Card>
      {showDialog && (
        <ViewTaskInfoDialog
          open={showDialog}
          setOpen={setShowDialog}
          eventList={selectedDateEventList}
        />
      )}
    </>
  );
};

export default observer(CalendarWidget);
