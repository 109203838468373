import { FormControl, MenuItem, Select } from "@mui/material";

import React from "react";

const DropdownOptionWidget = ({
  list,
  type,
  value,
  handleChange,
  disabled,
}) => {
  if (type === "list") {
    return (
      <FormControl
        sx={{
          width: "250px",
          height: "56px",
          backgroundColor: "white",
          borderRadius: "8px",
        }}
      >
        <Select
          disabled={disabled}
          style={{
            width: "100%",
            height: "56px",
            font: "normal normal 400 16px Poppins",
          }}
          value={value}
          onChange={(event) => handleChange(event)}
          MenuProps={{
            disableScrollLock: true,
          }}
          inputProps={{
            "aria-label": "Without label",
            style: { borderColor: "transparent" },
          }}
        >
          {list.map((str, index) => {
            return (
              <MenuItem
                value={str}
                key={index}
                style={{
                  font: "normal normal 400 16px Poppins",
                }}
              >
                {str}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  } else {
    return (
      <FormControl
        sx={{
          width: "200px",
          height: "30px",
          backgroundColor: "white",
          borderRadius: "8px",
        }}
      >
        <Select
          style={{
            width: "100%",
            height: "30px",
            font: "normal normal 400 14px Poppins",
          }}
          value={value}
          onChange={(event) => handleChange(event)}
          MenuProps={{
            disableScrollLock: true,
          }}
          inputProps={{
            "aria-label": "Without label",
            style: { borderColor: "transparent" },
          }}
        >
          {list.map((obj, index) => {
            return (
              <MenuItem
                value={obj.value}
                key={index}
                style={{
                  font: "normal normal 400 14px Poppins",
                }}
              >
                {obj.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }
};

export default DropdownOptionWidget;
