import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import { Box, Chip, CircularProgress } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import { UserLoginHistoryColumns } from "../../Db";
import { getUserLoginHistoryApi } from "../../Api";
import rootStore from "../../stores/RootStore";
import analytics from "../../Analytics";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  height: 100%;
  margin: auto;
  margin-top: 70px;
  width: 95%;
`;

const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 0px;
`;
const Text = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
`;

const Row = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Bottom = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 276px);`};
  background-color: white;
  overflow-x: auto;
`;
const ChipsWrapper = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  column-gap: 20px;
  height: 100%;
  margin-bottom: 10px;
`;

const UserLoginHistoryPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [row, setRow] = useState([]);
  const [filteredRows, setFilteredRows] = useState();
  const [loading, setLoading] = useState(true);
  const chipData = [
    { key: 1, label: "Today" },
    { key: 2, label: "Yesterday" },
    { key: 3, label: "Last 7 Days" },
    { key: 4, label: "Last 1 Month" },
    { key: 5, label: "Last 3 months" },
  ];

  const [selectedChipIndex, setSelectedChipIndex] = React.useState(1);

  const getMembersList = async () => {
    setRow([]);
    let response = await getUserLoginHistoryApi({ id: id });
    setRow(response);
    filter(response, selectedChipIndex);
  };
  const init = async () => {
    await getMembersList();
    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    analytics.triggerEvent(
      4625000117,
      "admin_users_page_load",
      "admin_users_page",
      "",
      {}
    );
    init();
    rootStore.authStore.updateLeadDetailStatus();
  }, []);

  const defaultColDef = useMemo(() => ({
    resizable: true,
    sortable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const filter = (row, index) => {
    let data = [...row];
    const now = moment();
    let filteredData = [];

    if (index === 1) {
      //today
      let checkDate = now.startOf("day");

      filteredData = data.filter((element) => {
        let parsedDate = moment.utc(element["created_at"]).local();

        if (parsedDate.isSame(checkDate, "day")) {
          return true;
        } else {
          return false;
        }
      });
    }
    if (index === 2) {
      //yesterday
      let checkDate = now.subtract(1, "day").startOf("day");

      filteredData = data.filter((element) => {
        let parsedDate = moment.utc(element["created_at"]).local();
        if (parsedDate.isSame(checkDate, "day")) {
          return true;
        } else {
          return false;
        }
      });
    }
    if (index === 3) {
      // Past week data
      let checkDate = now.subtract(1, "week").startOf("day");

      filteredData = data.filter((element) => {
        let parsedDate = moment.utc(element["created_at"]).local();
        if (parsedDate.isAfter(checkDate)) {
          return true;
        } else {
          return false;
        }
      });
    }
    if (index === 4) {
      // Past 3 month data
      let checkDate = now.subtract(1, "months").startOf("day");

      filteredData = data.filter((element) => {
        let parsedDate = moment.utc(element["created_at"]).local();
        if (parsedDate.isAfter(checkDate)) {
          return true;
        } else {
          return false;
        }
      });
    }
    if (index === 5) {
      // Past 3 month data
      let checkDate = now.subtract(3, "months").startOf("day");

      filteredData = data.filter((element) => {
        let parsedDate = moment.utc(element["created_at"]).local();
        if (parsedDate.isAfter(checkDate)) {
          return true;
        } else {
          return false;
        }
      });
    }
    setFilteredRows(filteredData);
  };

  return (
    <>
      <ParentContainer>
        <Container>
          <Row>
            <TopWrapper>
              <Text>Admin</Text>
              <ChevronRight style={{ width: "16px", height: "16px" }} />
              <Text
                style={{ color: "#185DD2", cursor: "pointer" }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Users Login History
              </Text>
              <ChevronRight style={{ width: "16px", height: "16px" }} />
              <Text>{id}</Text>
            </TopWrapper>
          </Row>
          <ChipsWrapper>
            {chipData.map((data) => {
              return (
                <Chip
                  key={data.key}
                  color={selectedChipIndex === data.key ? "primary" : "default"}
                  label={data.label}
                  onClick={() => {
                    setSelectedChipIndex(data.key);
                    filter(row, data.key);
                  }}
                />
              );
            })}
          </ChipsWrapper>
          <Bottom>
            <TableWrapper>
              {!loading ? (
                <div className="ag-theme-alpine" style={{ width: "100%" }}>
                  <AgGridReact
                    domLayout="autoHeight"
                    gridOptions={gridOptions}
                    rowData={filteredRows}
                    defaultColDef={defaultColDef}
                    columnDefs={UserLoginHistoryColumns}
                    animateRows={true}
                    suppressCellFocus
                    pagination={true}
                    paginationPageSize={50}
                    suppressColumnVirtualisation={true}
                    className="paginated-ag-grid"
                    onRowClicked={(row) => {
                      if (row.event.defaultPrevented) {
                        return null;
                      }
                    }}
                  />
                </div>
              ) : (
                <TableWrapper
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </TableWrapper>
              )}
            </TableWrapper>
          </Bottom>
        </Container>
      </ParentContainer>
      <Footer />
    </>
  );
};

export default observer(UserLoginHistoryPage);
