import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { myTheme } from "../../themeUtils";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const Container = styled.div`
  min-height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9fc;
  justify-content: center;
  width: 100%;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  flex-direction: column;
`;
const Text = styled.p`
  margin: 0px;
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const NetworkRestrictionPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const receivedProps = location.state;
  return (
    <>
      <Container>
        <Wrapper>
          <Text>
            Access denied due to network restriction. Reach out to{" "}
            <span style={{ color: myTheme.Button.colorBlue }}>
              support@leadpixie.in
            </span>{" "}
            or your admin for assistance.
          </Text>
          <Text>IP Address: {receivedProps["ip_address"]}</Text>
          <Button
            style={{
              textTransform: "none",
              ...myTheme.Button.btnBlue,
              marginTop: "20px",
            }}
            variant="text"
            onClick={() => {
              navigate("/login");
            }}
          >
            Go back
          </Button>
        </Wrapper>
      </Container>
    </>
  );
};

export default observer(NetworkRestrictionPage);
