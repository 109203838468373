import React from "react";
import { styled } from "@mui/material/styles";
import ContactForm from "./modals/ContactForm";
import { Grid, Typography, Box, ListItem, ListItemText } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

const TopSection = styled(Box)(({ theme, bg, flag }) => ({
  backgroundImage: `url(${bg})`,
  backgroundSize: "100% auto",
  aspectRatio: "16/9",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  border: flag ? "4px dashed #10ff00" : "none",

  [[theme.breakpoints.between("xs", "sm")]]: {
    backgroundImage: "none",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  [[theme.breakpoints.between("sm", "md")]]: {
    backgroundImage: "none",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "top",
  },
  [theme.breakpoints.up("lg")]: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "top",
  },
}));

const TextContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  textAlign: "left",
  margin: theme.spacing(2),
  [[theme.breakpoints.between("xs", "sm")]]: {
    width: "100%",
  },
  [[theme.breakpoints.between("sm", "md")]]: {
    width: "100%",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    width: "50%",
  },
  [theme.breakpoints.up("lg")]: {
    width: "50%",
  },
}));

const FormContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  justifyContent: "center",
  margin: theme.spacing(2),
  [[theme.breakpoints.between("xs", "sm")]]: {
    width: "100%",
  },
  [[theme.breakpoints.between("sm", "md")]]: {
    width: "70%",
  },
  [theme.breakpoints.up("md")]: {
    width: "35%",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  component: "h1",
  width: "100%",
  color: "#6FB835",

  [[theme.breakpoints.between("xs", "sm")]]: {
    font: "normal normal 700 18px open sans",
    marginBottom: theme.spacing(2),
  },
  [[theme.breakpoints.between("sm", "md")]]: {
    font: "normal normal 700 24px open sans",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    font: "normal normal 700 36px open sans",
    textAlign: "left",
    color: "#ffffff",
  },
  [theme.breakpoints.up("lg")]: {
    font: "normal normal 700 48px open sans",
    textAlign: "left",
    color: "#ffffff",
  },
}));

const Circle = styled(CircleIcon)(({ theme }) => ({
  fontSize: "8px",

  [[theme.breakpoints.between("xs", "md")]]: {
    fontSize: "4xp",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    fontSize: "8px",
    color: "#ffffff",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "12px",
    color: "#ffffff",
  },
}));
const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  "& .MuiTypography-root": {
    font: "normal normal 400 16px open sans",
    paddingLeft: theme.spacing(2),
    paddingTop: 0,
    component: "span",
    color: "#000000",
    [[theme.breakpoints.between("xs", "sm")]]: {
      font: "normal normal 400 10px open sans",
      component: "span",
    },
    [[theme.breakpoints.between("sm", "md")]]: {
      font: "normal normal 400 12px open sans",
      component: "span",
    },
    [[theme.breakpoints.between("md", "lg")]]: {
      font: "normal normal 400 16px open sans",
      component: "span",
      color: "#ffffff",
    },
    [theme.breakpoints.up("lg")]: {
      font: "normal normal 400 20px open sans",
      component: "span",
      color: "#ffffff",
    },
  },
}));

const ListItemContainer = styled(Box)(({ theme }) => ({
  [[theme.breakpoints.between("xs", "md")]]: {
    paddingLeft: theme.spacing(1),
  },
  [theme.breakpoints.up("md")]: {
    paddingLeft: theme.spacing(4),
  },
}));

const MyTopSection = ({ pageSchema, isHighlighted, topSectionData }) => {
  return (
    <TopSection
      bg={
        topSectionData.bg !== null
          ? URL.createObjectURL(topSectionData.bg)
          : pageSchema.top_section.bg
      }
      flag={isHighlighted}
      id="top_section"
    >
      <TextContainer>
        <Title>{topSectionData.heading}</Title>
        <ListItemContainer>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={1}
            rowSpacing={{ xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 0, xl: 0 }}
          >
            {topSectionData.key_points.map((text, i) => {
              return (
                <Grid item xs={6} sm={6} md={12} lg={12} xl={12}>
                  <ListItem disablePadding style={{ textAlign: "start" }}>
                    <Circle />
                    <StyledListItemText primary={text} />
                  </ListItem>
                </Grid>
              );
            })}
          </Grid>
        </ListItemContainer>
      </TextContainer>
      {/* <FormContainer>
        <ContactForm pageSchema={pageSchema} />
      </FormContainer> */}
    </TopSection>
  );
};

export default MyTopSection;
