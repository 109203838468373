import { AgGridReact } from "ag-grid-react";
import React, { useMemo } from "react";
import { leadDealHistoryColumnHeaders } from "../../../../Db";

const HistoryTab = ({
  tableData,
  setSelectedDealData,
  setOpenViewDealPanel,
}) => {
  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  return (
    <div className="ag-theme-alpine" style={{ width: "100%" }}>
      <AgGridReact
        domLayout="autoHeight"
        gridOptions={gridOptions}
        rowData={tableData}
        defaultColDef={defaultColDef}
        columnDefs={leadDealHistoryColumnHeaders}
        animateRows={true}
        suppressCellFocus
        onRowClicked={(row) => {
          if (row.event.defaultPrevented) {
            return null;
          }
          setSelectedDealData(row.data);
          setOpenViewDealPanel(true);
        }}
      />
    </div>
  );
};

export default HistoryTab;
