import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Toolbar,
} from "@material-ui/core";
import { myTheme } from "../../themeUtils";
import { getLocalizedText } from "../../Functions";
import { Typography } from "@mui/material";
import { uploadContactFileApi } from "../../Api";
import ErrorMessageDialog from "../call/ErrorMessageDialog";

const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const Form = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 420px;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Input = styled("input")`
  width: 100%;
  height: 30px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;

  border: 1px solid #bfbfbf;
  font: normal normal 500 12px Open Sans;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const TextArea = styled("textarea")`
  width: 100%;
  height: 60px;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #bfbfbf;
  color: #4d4e4f;

  resize: none;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const ButtonWrapper = styled(Box)`
  /* margin-top: 30px; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 200px;
  @media (max-width: 700px) {
    width: 280px;
  }
`;

const AddFileModal = (props) => {
  const [details, setDetails] = useState({
    title: "",
    desc: "",
    file_password: "",
  });

  const [file, setFile] = useState();
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("title", details.title);
    formData.append("desc", details.desc);
    file !== undefined && formData.append("file", file);
    file !== undefined && formData.append("file_type", file.type);
    if (details.file_password !== "") {
      formData.append("file_password", details.file_password);
    }
    let response = await uploadContactFileApi({
      contactID: props.contactID,
      payload: formData,
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setShowErrorDialog(true);
    } else {
      props.setRefresh(true);
      props.setOpen(false);
    }
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleFile = (e) => {
    setFile(e.target.files[0]);
  };
  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer component={"form"} onSubmit={handleSubmit}>
          <AppBar
            component={"nav"}
            elevation={0}
            position="sticky"
            color="inherit"
            sx={{
              top: 0,
              bottom: "auto",
              width: "100%",
              right: 0,
            }}
          >
            <Toolbar
              style={{
                padding: "16px",
              }}
            >
              <HeaderWrapper>
                <Header>{getLocalizedText("submit_file")}</Header>
                <SubHeader>
                  {getLocalizedText("fill_in_the_below_details")}
                </SubHeader>
              </HeaderWrapper>
            </Toolbar>
          </AppBar>
          <Divider style={{ margin: "0px 16px" }} />
          <Box>
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>{getLocalizedText("title")}*</Label>
                    <Input
                      required
                      type="text"
                      placeholder="Enter title here..."
                      name="title"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>{getLocalizedText("description")}*</Label>
                    <TextArea
                      required
                      type="text"
                      placeholder="Enter description here..."
                      name="desc"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>{getLocalizedText("add_file")}</Label>
                    <Input
                      type="file"
                      onChange={handleFile}
                      style={{
                        width: "100%",
                        fontSize: "12px",
                        padding: "2px",
                        backgroundColor: "#EFEFF4",
                        border: "none",
                        borderRadius: "6px",
                      }}
                      accept="application/pdf,.zip,.txt,.xls,.csv,image/*"
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>Password</Label>
                    <Input
                      value={details.file_password}
                      type="text"
                      placeholder="Enter password"
                      name="file_password"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </Grid>
              </Grid>
            </Form>
            <AppBar
              position="static"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: "100%" }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                  width: 420,
                }}
              >
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      onClick={handleClose}
                      type="button"
                      variant="contained"
                      color="default"
                      style={{
                        backgroundColor: "#EFEFF4",
                        textTransform: "none",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      {getLocalizedText("cancel")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      id="call-log-btn"
                      style={{
                        textTransform: "none",
                        fontSize: "12px",
                        fontWeight: "bold",
                        ...myTheme.Button.btnBlue,
                      }}
                    >
                      {getLocalizedText("submit_details")}
                    </Button>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Box>
        </ModelContainer>
      </Modal>
      {showErrorDialog && (
        <ErrorMessageDialog
          open={showErrorDialog}
          setOpen={setShowErrorDialog}
          message={errorMessage}
        />
      )}
    </>
  );
};

export default observer(AddFileModal);
