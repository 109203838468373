import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import LeftSection from "./submit_information_components/LeftSection";
import RightSection from "./submit_information_components/RightSection";
import FooterComponent from "./submit_information_components/FooterComponent";
import AppBar from "./submit_information_components/AppBar";
const StyledContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
}));

const SectionWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "80%",
  margin: "auto",
  minHeight: "calc(100vh - 118px)",
  flexDirection: "row",
  [theme.breakpoints.down("md")]: {
    minHeight: "calc(100vh - 110px)",
    flexDirection: "column",
    width: "100%",
  },
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(2),
}));

const SubmitInformationTemplate = ({
  contentState,
  formState,
  secondaryColor,
  logoURL,
}) => {
  return (
    <>
      <StyledContainer disableGutters fixed maxWidth="false">
        <AppBar logoURL={logoURL} />
        <SectionWrapper>
          <LeftSection />

          <RightSection
            contentState={contentState}
            secondaryColor={secondaryColor}
            formState={formState}
          />
        </SectionWrapper>
        <FooterComponent contentState={contentState} />
      </StyledContainer>
    </>
  );
};

export default SubmitInformationTemplate;
