import { Call, Mail } from "@material-ui/icons";
import React from "react";
import { FooterLinks } from "../../../Db";
import { app_version } from "../../../Config";
import rootStore from "../../../stores/RootStore";
import { Box, Typography, styled } from "@mui/material";

const Container = styled(Box)`
  width: 100%;
  height: 75px;
  display: flex;
  padding-top: 16px;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  @media (max-width: 700px) {
    height: fit-content;
  }
`;

const Wrapper = styled(Box)`
  width: 95%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media (max-width: 700px) {
    width: 100%;
    flex-direction: column;
    row-gap: 10px;
    padding: 20px;
  }
`;

const Text = styled(Typography)`
  margin: 0px;
  font: normal normal normal 12px poppins;
  letter-spacing: 0px;
  color: #838383;
`;
const IconText = styled("a")`
  margin: 0px;
  margin-left: 8px;
  text-decoration: none;
  list-style: none;
  font: normal normal normal 12px poppins;
  letter-spacing: 0px;
  color: #838383;
`;
const Column = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4px;
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;
const IconWrapper = styled(Box)`
  display: flex;
  align-items: center;
`;
const Footer = () => {
  const { userStore } = rootStore;

  const WithPhone = () => {
    return (
      <Container>
        <Wrapper>
          <Column>
            <Row>
              {FooterLinks.map((item, i) => {
                return (
                  <IconText href={item.link} target="_blank" key={i}>
                    {item.label}
                  </IconText>
                );
              })}
            </Row>
          </Column>
          <Column>
            <Text>
              ©2023 FLZ Private Limited(LeadPixie) All rights reserved.
            </Text>
            <Text style={{ fontSize: "10px" }}>{app_version}</Text>
          </Column>
          <Column>
            {" "}
            <Row>
              <IconWrapper>
                <Mail style={{ height: "18px", width: "18px" }} />
                <IconText href="mailto:hello@leadpixie.in" target="_blank">
                  hello@leadpixie.in
                </IconText>
              </IconWrapper>
              <IconWrapper>
                <Call style={{ height: "18px", width: "18px" }} />
                <IconText href="tel:+91-9591040631">+91-9591040631</IconText>
              </IconWrapper>
            </Row>
          </Column>
        </Wrapper>
      </Container>
    );
  };
  const WithoutPhone = () => {
    return (
      <Container>
        <Wrapper>
          <Column>
            <Row>
              {FooterLinks.map((item, i) => {
                return (
                  <IconText href={item.link} target="_blank" key={i}>
                    {item.label}
                  </IconText>
                );
              })}
            </Row>
          </Column>
          <Column>
            <Text>
              ©2023 FLZ Private Limited(LeadPixie) All rights reserved.
            </Text>
            <Text style={{ fontSize: "10px" }}>{app_version}</Text>
          </Column>
          <Column>
            <IconWrapper>
              <Mail style={{ height: "18px", width: "18px" }} />
              <IconText href="mailto:hello@leadpixie.in" target="_blank">
                hello@leadpixie.in
              </IconText>
            </IconWrapper>
          </Column>
        </Wrapper>
      </Container>
    );
  };

  const BuildFooter = () => {
    let accountName =
      userStore.UserData !== null ? userStore.UserData.account.name : "";
    if (accountName.toLowerCase() === "dbs") {
      return <WithoutPhone />;
    } else {
      return <WithPhone />;
    }
  };

  return <BuildFooter />;
};
// <LinkItem href={item.link} target="_blank" key={i}>
//   {item.label}
// </LinkItem>

export default Footer;
