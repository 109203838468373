import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { leadActivityColumns } from "../../Db";
import { pageSize } from "../../Config";
import { getLeadActivityApi } from "../../Api";
import PaginationComponent from "../pagination/PaginationComponent";
import ActivityLogModal from "../activity_log_modal/ActivityLogModal";
import analytics from "../../Analytics";
import { Drawer } from "@material-ui/core";
import ViewActivityInfoPanel from "../view_activity_info_panel/ViewActivityInfoPanel";
import { Box, CircularProgress, IconButton } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { Visibility } from "@material-ui/icons";

const Container = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 334px);`};

  position: relative;
`;
const Wrapper = styled(Box)`
  display: flex;
`;

const DetailsWrapper = styled(Box)`
  height: fit-content;
  width: 100%;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;

const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 372px);`};
`;

const UserActivityTab = ({ uid, lid }) => {
  const [count, setCount] = useState(0);
  const [row, setRow] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [size, setSize] = useState(pageSize);
  const [open, setOpen] = useState(false);
  const [openActivityInfoPanel, setOpenACtivityInfoPanel] = useState(false);
  const [selectedActivityData, setSelectedActivityData] = useState({});

  const getActivityList = async ({ page, page_size }) => {
    setRow([]);
    let response = await getLeadActivityApi({
      leadID: lid,
      page: page,
      page_size: page_size,
    });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
      setCount(response.item_count);
    } else {
      setRow([]);
      setCount(0);
    }
  };
  const init = async () => {
    await getActivityList({ page: 0, page_size: pageSize });
    setLoading(false);
  };
  useEffect(() => {
    analytics.triggerEvent(
      4625000052,
      "activities_tab_load",
      "lead_details_page",
      "",
      {}
    );
    init();
  }, []);
  const handlePageSize = async (e) => {
    setSize(e.target.value);
    setPage(0);
    setLoading(true);
    await getActivityList({ page: 0, page_size: e.target.value });
    setLoading(false);
  };
  const handlePagination = async (e, page) => {
    setPage(page - 1);
    setLoading(true);
    await getActivityList({ page: page - 1, page_size: size });
    setLoading(false);
  };

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "ACTIONS",
      minWidth: 100,
      cellRenderer: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setSelectedActivityData(params.data);
                setOpenACtivityInfoPanel(true);
              }}
              style={{ padding: "0px" }}
            >
              <Visibility
                style={{
                  cursor: "pointer",
                  width: "20px",
                  height: "20px",
                }}
                color="primary"
              />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Container>
        <Wrapper>
          <DetailsWrapper>
            {!loading ? (
              <TableWrapper>
                <div className="ag-theme-alpine" style={{ width: "100%" }}>
                  <AgGridReact
                    domLayout="autoHeight"
                    gridOptions={gridOptions}
                    rowData={row}
                    defaultColDef={defaultColDef}
                    columnDefs={leadActivityColumns.concat(actionColumn)}
                    animateRows={true}
                    suppressCellFocus
                    pagination={false}
                    paginationPageSize={size}
                    onRowClicked={(row) => {
                      if (row.event.defaultPrevented) {
                        return null;
                      }
                      setSelectedActivityData(row.data);
                      setOpenACtivityInfoPanel(true);
                    }}
                  />
                </div>
              </TableWrapper>
            ) : (
              <TableWrapper
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </TableWrapper>
            )}
            <PaginationComponent
              page_no={page}
              row={row}
              page_size={size}
              size={size}
              count={count}
              handlePageSize={handlePageSize}
              handlePage={handlePagination}
            />
          </DetailsWrapper>
        </Wrapper>
      </Container>

      {open && <ActivityLogModal open={open} setOpen={setOpen} />}
      {openActivityInfoPanel && (
        <Drawer
          anchor={"right"}
          open={openActivityInfoPanel}
          onClose={() => {
            setOpenACtivityInfoPanel(false);
          }}
        >
          {<ViewActivityInfoPanel data={selectedActivityData} />}
        </Drawer>
      )}
    </>
  );
};

export default observer(UserActivityTab);
