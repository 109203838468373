import React from "react";
import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Grid, Modal } from "@material-ui/core";
import { Close } from "@material-ui/icons";

const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  padding: 16px;
  padding-top: 0px;
  height: 100vh;
  width: fit-content;
  overflow: auto;
`;
const ContentWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100vw;
  min-width: 500px;
`;

const Image = styled("img")`
  width: 100%;
  height: auto;
  object-fit: contain;
`;

function PreviewImageDialog(props) {
  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModelContainer>
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          style={{
            height: "50px",
            top: 0,
            bottom: "auto",
            width: "100%",
          }}
        >
          <Toolbar
            style={{
              padding: "0px",
              width: "100%",
              position: "relative",
            }}
          >
            <Grid container justifyContent="flex-end">
              <Grid item>
                <IconButton onClick={handleClose} style={{ padding: "0px" }}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <ContentWrapper>
          <TransformWrapper>
            <TransformComponent>
              <Image
                src={
                  props.isCreate ? URL.createObjectURL(props.file) : props.file
                }
                alt="Preview"
              />
            </TransformComponent>
          </TransformWrapper>
        </ContentWrapper>
      </ModelContainer>
    </Modal>
  );
}

export default PreviewImageDialog;
