import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import "./style.css";
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 0px;
  width: 100%;
`;
const NormalMultiSelectFilter = (props) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    try {
      let parseJSON = JSON.parse(props.list);
      if (Array.isArray(parseJSON)) {
        let updatedList = updateList(parseJSON);
        setList(updatedList);
      } else if (typeof parseJSON === "object") {
        if (Object.keys(parseJSON).length > 0) {
          if (parseJSON["type"] === "list_objects") {
            let tempList = [];
            parseJSON["values"].forEach((obj) => {
              let tempObj = { label: obj["name"], value: obj["value"] };
              tempList.push(tempObj);
            });
            setList(tempList);
          } else if (parseJSON["type"] === "list") {
            let updatedList = updateList(parseJSON["values"]);
            setList(updatedList);
          }
        }
      }
    } catch (error) {
      if (props.list.length > 0) {
        let tempList = props.list.split(",");
        let updatedList = updateList(tempList);
        setList(updatedList);
      }
    }
  }, [props.field]);

  const updateList = (val) => {
    let newList = [];
    val.map((e) => {
      let newObj = { label: e, value: e };
      newList.push(newObj);
    });
    return newList;
  };

  return (
    <>
      <InputWrapper type={props.type}>
        <MultiSelect
          className={"new_filter_multi_select"}
          options={list}
          value={props.selected}
          onChange={(e) => {
            props.setSelected(e);
            let newList = [];
            e.map((val) => {
              newList.push(val.value);
            });
            props.setSelectedVal({
              ...props.selectedVal,
              [props.field]: newList,
            });
            props.setFilterList({
              ...props.filterList,
              [props.field]: {
                value: newList,
                type: props.filterType,
                dataType: props.dataType,
              },
            });
          }}
          labelledBy="Select Status"
        />
      </InputWrapper>
    </>
  );
};

export default NormalMultiSelectFilter;
