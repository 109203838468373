import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import { Button } from "@material-ui/core";
import { styled } from "@mui/material/styles";

const Message = styled(Typography)(({ theme }) => ({
  font: "normal normal 400 16px Open Sans",
  color: "#ff0000",
}));
const ErrorMessageDialog = (props) => {
  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => {
          props.setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Message>
              {"Something went wrong! Please contact your administrator."}
            </Message>
          </DialogContentText>
        </DialogContent>
        {
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              style={{
                backgroundColor: "#185DD2",
                textTransform: "capitalize",
                fontWeight: "bold",
                width: "100px",

                color: "white",
              }}
              onClick={() => {
                props.setOpen(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        }
      </Dialog>
    </>
  );
};
export default ErrorMessageDialog;
