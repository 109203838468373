import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import { CircularProgress, Grid } from "@material-ui/core";
import { getProductDetailsApi } from "../../Api";
import { IsoToLocalDate, IsoToLocalTime } from "../../Functions";
import rootStore from "../../stores/RootStore";

const ModelWrapper = styled(Box)`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 24px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 8px;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const fixedFields = ["name", "product_type", "sku", "desc"];

const ViewDealProductSidepanel = (props) => {
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getProductDetails = async () => {
    let response = await getProductDetailsApi({ productID: props.productID });
    return response;
  };

  const init = async () => {
    let productData = await getProductDetails();
    await rootStore.productStore.fetchColumns();
    let dataToRender = generateDataToRender(productData, [
      ...rootStore.productStore.columnsList,
    ]);
    setDataList(dataToRender);
    setLoading(false);
  };

  const getColumnValue = (field, data_type, data) => {
    if (data.hasOwnProperty(field)) {
      let value = data[field];
      if (value !== null && value !== "") {
        if (data_type === "date") {
          value = IsoToLocalDate(value);
        } else if (data_type === "datetime") {
          value = IsoToLocalTime(value);
        }
      }
      return value !== null ? value : "";
    } else {
      return "";
    }
  };
  const generateDataToRender = (productData, columnsList) => {
    let tempList = [];
    columnsList.forEach((column) => {
      let item = {
        display_name: column["display_name"],
        value: getColumnValue(
          column["column_name"],
          column["data_type"],
          productData
        ),
        column_name: column["column_name"],
      };
      tempList.push(item);
    });
    tempList.sort((a, b) => {
      let A = a.display_name.toLowerCase();
      let B = b.display_name.toLowerCase();
      if (A < B) {
        return -1;
      }
      if (A > B) {
        return 1;
      }
      return 0;
    });
    tempList = tempList.sort(
      (a, b) =>
        fixedFields.indexOf(a.column_name) - fixedFields.indexOf(b.column_name)
    );
    return tempList;
  };
  useEffect(() => {
    init();
  }, []);

  return !loading ? (
    <Box sx={{ width: 500 }}>
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          width: 500,
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar>
          <ModelHeader>Product Details</ModelHeader>
        </Toolbar>
      </AppBar>
      <ModelWrapper>
        <Grid container spacing={3}>
          {dataList.map((item) => {
            return (
              <Grid item xs={12}>
                <InputWrapper>
                  <Label>{item.display_name}</Label>
                  <Detail>{item.value !== "" ? item.value : "-"} </Detail>
                </InputWrapper>
              </Grid>
            );
          })}
        </Grid>
      </ModelWrapper>
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 500,
        height: "100%",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default ViewDealProductSidepanel;
