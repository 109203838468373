import React, { useMemo } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";

import { styled } from "@mui/material/styles";

import { observer } from "mobx-react-lite";

import { AddBoxOutlined, ChevronRight, Delete, Edit } from "@material-ui/icons";
import rootStore from "../../stores/RootStore";
import { useEffect } from "react";
import { Backdrop, Box, IconButton, Tooltip, Typography } from "@mui/material";
import { CircularProgress } from "@material-ui/core";
import { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  deleteProjectAdministrationColumnGroupApi,
  getProjectAdministrationColumnGroupsApi,
  getProjectAdministrationReOrederedColumnGroupsApi,
} from "../../Api";
import { AdminColumnsGroup } from "../../Db";
import CustomErrorMessageDialog from "../../components/alert_dialogue/CustomErrorMessageDialog";
import AddColumnGroupModal from "../../components/admin_setting_modals/AddColumnGroupModal";

const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  height: 100%;
  margin: auto;
  margin-top: 70px;
  width: 95%;
`;

const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 0px;
`;
const Text = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
`;

const Row = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Bottom = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 226px);`};
  background-color: white;
  overflow-x: auto;
`;

const AddBtnWrapper = styled(Box)`
  position: absolute;
  right: 5px;
  top: 5px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
`;

const AdminProjectColumnGroups = () => {
  const { authStore } = rootStore;
  const [row, setRow] = useState([]);
  const [dragableRows, setDragableRow] = useState([]);
  const [pinnedRow, setPinnedRow] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingBackdrop, setLoadingBackdrop] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [openAddGroupPanel, setOpenAddGroupPanel] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedGroupData, setSelectedGroupData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const specifiedGroupNames = [
    "Primary Information",
    "Secondary Information",
    "Followup Details",
  ];
  useEffect(() => {
    getColumnGroupList();
  }, []);

  useEffect(() => {
    if (refresh) {
      getColumnGroupList();
      setRefresh(false);
    }
  }, [refresh]);

  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));

  useEffect(() => {
    authStore.updateLeadDetailStatus();
  }, []);

  const getColumnGroupList = async () => {
    setRow([]);
    setPinnedRow([]);
    setDragableRow([]);
    setLoading(true);
    let response = await getProjectAdministrationColumnGroupsApi();

    const list1 = response.filter((item) =>
      specifiedGroupNames.includes(item.group_name)
    );
    const list2 = response.filter(
      (item) => !specifiedGroupNames.includes(item.group_name)
    );
    setRow(response);
    setPinnedRow(list1);
    setDragableRow(list2);
    setLoading(false);
  };
  const getReOrderedViewList = async ({ groupSequence }) => {
    setRow([]);
    setPinnedRow([]);
    setDragableRow([]);
    setLoading(true);
    let payload = { columngroup_sequences: groupSequence };
    let response = await getProjectAdministrationReOrederedColumnGroupsApi({
      payload: payload,
    });
    const list1 = response.filter((item) =>
      specifiedGroupNames.includes(item.group_name)
    );
    const list2 = response.filter(
      (item) => !specifiedGroupNames.includes(item.group_name)
    );
    setRow(response);
    setPinnedRow(list1);
    setDragableRow(list2);
    setLoading(false);
  };

  const onRowDragEnd = async (event) => {
    const gridApi = event.api;
    let draggedItem = event.node.data;
    let newOrderList = [];

    pinnedRow.forEach((item, index) => {
      let tempObj = { columngroup_id: item.id, sequence: index };
      newOrderList.push(tempObj);
    });

    gridApi.forEachNode((node, i) => {
      let tempObj = {
        columngroup_id: node.data.id,
        sequence: i + pinnedRow.length,
      };
      newOrderList.push(tempObj);
    });
    console.log(newOrderList);
    //*********below getting the prev index and new index to check if there is any order change happened or not */
    let prevIndex = row.findIndex((data) => data["id"] === draggedItem["id"]);
    let newIndex = newOrderList.findIndex(
      (data) => data["columngroup_id"] === draggedItem["id"]
    );
    if (prevIndex !== newIndex) {
      await getReOrderedViewList({
        groupSequence: newOrderList,
      });
    }
  };

  const handleDelete = async (id) => {
    setLoadingBackdrop(true);
    let response = await deleteProjectAdministrationColumnGroupApi({
      groupID: id,
    });
    if (response.hasError()) {
      setLoadingBackdrop(false);

      setErrorMessage(response.errorMessage);
      setOpenErrorDialog(true);
    } else {
      setLoadingBackdrop(false);
      await getColumnGroupList();
    }
  };

  const actionColumn = [
    {
      field: "actions",
      headerName: "ACTIONS",
      resizable: false,
      minWidth: 130,
      flex: 1,
      cellRenderer: (params) => {
        let disabled = false;

        if (specifiedGroupNames.includes(params.data.group_name)) {
          disabled = true;
        }

        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Tooltip title={"Delete"}>
              <IconButton
                color="error"
                disabled={disabled}
                onClick={() => {
                  handleDelete(params.data.id);
                }}
              >
                <Delete
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Edit"}>
              <IconButton
                color="primary"
                disabled={disabled}
                onClick={() => {
                  setSelectedGroupData(params.data);
                  setIsEdit(true);
                  setOpenAddGroupPanel(true);
                }}
              >
                <Edit
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <ParentContainer>
        <Container>
          <Row>
            <TopWrapper>
              <Text>Project Administration</Text>
              <ChevronRight style={{ width: "16px", height: "16px" }} />
              <Text>Column Groups</Text>
            </TopWrapper>
          </Row>

          <Bottom>
            {!loading ? (
              <TableWrapper style={{ position: "relative" }}>
                <AddBtnWrapper>
                  <AddBoxOutlined
                    onClick={() => {
                      setOpenAddGroupPanel(true);
                    }}
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                  />
                </AddBtnWrapper>
                <div className="ag-theme-alpine" style={{ width: "100%" }}>
                  <AgGridReact
                    onRowDragEnd={onRowDragEnd}
                    rowDragManaged={true}
                    domLayout="autoHeight"
                    suppressCellFocus
                    gridOptions={gridOptions}
                    loadingOverlayComponent={<CircularProgress />}
                    rowData={dragableRows}
                    pinnedTopRowData={pinnedRow}
                    defaultColDef={defaultColDef}
                    columnDefs={AdminColumnsGroup.concat(actionColumn)}
                    animateRows={true}
                    getRowStyle={(params) => {
                      if (
                        specifiedGroupNames.includes(params.data.group_name)
                      ) {
                        return { background: "#f0f0f0" }; // Lighter color for specific rows
                      }
                      return null;
                    }}
                  />
                </div>
              </TableWrapper>
            ) : (
              <TableWrapper
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </TableWrapper>
            )}
          </Bottom>
        </Container>
      </ParentContainer>
      <Footer />
      <Backdrop
        open={loadingBackdrop}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress style={{ color: "white" }} />
      </Backdrop>
      {openErrorDialog && (
        <CustomErrorMessageDialog
          open={openErrorDialog}
          setOpen={setOpenErrorDialog}
          message={errorMessage}
        />
      )}
      {openAddGroupPanel && (
        <AddColumnGroupModal
          setOpen={setOpenAddGroupPanel}
          open={openAddGroupPanel}
          setRefresh={setRefresh}
          nextSequence={row.length + 1}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          editableData={selectedGroupData}
        />
      )}
    </>
  );
};

export default observer(AdminProjectColumnGroups);
