import React, { useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { styled } from "@mui/material/styles";
import { Backdrop, Box } from "@mui/material";
import OneGroupBarGraphWidget from "./widgets/OneGroupBarGraphWidget";
import TwoGroupBarGraphWidget from "./widgets/TwoGroupBarGraphWidget";
import { useEffect } from "react";
import { getAccountUserDetailsApi, getWidgetDataApiv2 } from "../../Api";
import { CircularProgress, Dialog, IconButton, Slide } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import rootStore from "../../stores/RootStore";
import appLogo from "../../assets/logo.png";
import TopPerformerWidget from "./widgets/TopPerformerWidget";
import AutoPagingTabularWidget from "./widgets/AutoPagingTabularWidget";
import AutoPagingCallActivityTabularWidget from "./widgets/AutoPagingCallActivityTabularWidget";
import AutoPagingCheckinActivityTabularWidget from "./widgets/AutoPagingCheckinActivityTabularWidget";
import CounterWidget from "./widgets/CounterWidget";

const slideDuration = 30000; //miliseconds
const tableSlidePerPageDuration = 15000;
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 9999999, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
`;
const CarouselWrapper = styled(Box)`
  width: 100%;
  background-color: white;
`;
const WidgetWrapper = styled(Box)`
  height: 100vh;
  width: 100%;
  background-color: white;
`;
const IconButtonWrapper = styled(Box)`
  position: absolute;
  right: 6px;
  top: 2px;
  width: fit-content;
  z-index: 999999;
`;
const BrandLogo = styled("img")`
  width: 50%;
  height: auto;
`;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const brandLogoGetter = () => {
  if (rootStore.userStore.UserData !== null) {
    if (rootStore.userStore.UserData.hasOwnProperty("account")) {
      let account_info = rootStore.userStore.UserData["account"];
      let logo_url = account_info.hasOwnProperty("logo_url")
        ? account_info["logo_url"]
        : "";
      return logo_url !== null && logo_url !== "" ? logo_url : appLogo;
    }
  }
  return appLogo;
};

const DashboardWidgetSlideshow = ({ widgetList, setOpen, open }) => {
  const carouselRef = useRef(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [widgetData, setWidgetData] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchWidgetData = async () => {
      setLoading(true);
      setWidgetData(null);
      setWidgetData(null);
      const widget = widgetList[activeSlideIndex - 1];
      try {
        let response_data = null;
        if (widget["client_widget"] === true) {
          if (widget["widget_type"] === "top_performer") {
            let response = await getAccountUserDetailsApi({
              userID: widget["input"]["user_id"],
            });
            response_data = response;
          }
        } else {
          let response = await getWidgetDataApiv2({ id: widget["id"] });
          response_data = response.data;
        }
        setWidgetData(response_data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    if (widgetList.length > 0 && activeSlideIndex > 0) {
      fetchWidgetData();
    }
  }, [activeSlideIndex, widgetList]);

  const getSlideShowDuration = () => {
    if (widgetData !== null && activeSlideIndex > 0) {
      let widgetConfig = widgetList[activeSlideIndex - 1];
      if (widgetConfig["widget_type"] === "tabular") {
        if (Array.isArray(widgetData)) {
          let numberOfPages = Math.ceil(widgetData.length / 10);
          if (numberOfPages > 0) {
            return tableSlidePerPageDuration * numberOfPages;
          }
        }
      }
    }
    return slideDuration;
  };

  const RenderWidget = ({ widget, widgetData }) => {
    if (widget.version === 2) {
      if (widget["widget_type"] === "tabular") {
        if (widget["title"] === "Call Activity Of All Project Users") {
          return (
            <WidgetWrapper>
              <AutoPagingCallActivityTabularWidget
                data={widgetData}
                widgetConfig={widget}
                handleRefresh={() => {}}
                type={"slideshow"}
                handleRemove={() => {}}
                isDashboardEditable={() => {}}
              />
            </WidgetWrapper>
          );
        } else if (
          widget["title"] === "Checkin Activity Of All Project Users"
        ) {
          return (
            <WidgetWrapper>
              <AutoPagingCheckinActivityTabularWidget
                data={widgetData}
                widgetConfig={widget}
                handleRefresh={() => {}}
                type={"slideshow"}
                handleRemove={() => {}}
                isDashboardEditable={() => {}}
              />
            </WidgetWrapper>
          );
        } else {
          return (
            <WidgetWrapper>
              <AutoPagingTabularWidget
                data={widgetData}
                widgetConfig={widget}
                handleRefresh={() => {}}
                type={"slideshow"}
                handleRemove={() => {}}
                isDashboardEditable={() => {}}
              />
            </WidgetWrapper>
          );
        }
      } else if (widget["widget_type"] === "top_performer") {
        console.log(
          "screen width::::",
          window.screen.width,
          "::::screen height:::",
          window.screen.height
        );
        return (
          <WidgetWrapper>
            <TopPerformerWidget
              data={widgetData}
              widgetConfig={widget}
              handleRefresh={() => {}}
              type={"slideshow"}
              handleRemove={() => {}}
              isDashboardEditable={() => {}}
            />
          </WidgetWrapper>
        );
      } else if (widget["widget_type"] === "counter") {
          return (
            <WidgetWrapper>
              <CounterWidget
                data={widgetData}
                widgetConfig={widget}
                handleRefresh={() => {}}
                type={"slideshow"}
                handleRemove={() => {}}
                isDashboardEditable={() => {}}
              />
            </WidgetWrapper>
          );
      } else {
        let groups = widget["xdata"]["group_by"];
        let size = groups.length;
        if (size === 1) {
          return (
            <WidgetWrapper>
              <OneGroupBarGraphWidget
                data={widgetData}
                widgetConfig={widget}
                handleRefresh={() => {}}
                type={"slideshow"}
                handleRemove={() => {}}
                isDashboardEditable={() => {}}
              />
            </WidgetWrapper>
          );
        } else if (size === 2) {
          return (
            <WidgetWrapper>
              <TwoGroupBarGraphWidget
                data={widgetData}
                widgetConfig={widget}
                handleRefresh={() => {}}
                type={"slideshow"}
                handleRemove={() => {}}
                isDashboardEditable={() => {}}
              />
            </WidgetWrapper>
          );
        }
      }
    }
  };

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Container>
          <IconButtonWrapper>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <Close color="inherit" />
            </IconButton>
          </IconButtonWrapper>
          <CarouselWrapper
            className="carousel-container"
            style={{ filter: loading ? 'blur("5px")' : "unset" }}
          >
            <Carousel
              partialVisbile={true}
              swipeable={false}
              draggable={false}
              responsive={responsive}
              ssr={false}
              infinite={false}
              autoPlay={true}
              customTransition="all 0.2s ease"
              transitionDuration={200}
              autoPlaySpeed={getSlideShowDuration()}
              keyBoardControl={false}
              beforeChange={(index) => {
                setActiveSlideIndex(index);
              }}
              afterChange={(prevSlide) => {
                let duration = getSlideShowDuration();
                if (prevSlide + 1 === widgetList.length) {
                  setTimeout(() => {
                    setActiveSlideIndex(0);
                    carouselRef.current &&
                      carouselRef.current.goToSlide(0, false);
                  }, duration);
                }
              }}
              pauseOnHover={false}
              ref={carouselRef}
            >
              <WidgetWrapper
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <BrandLogo src={brandLogoGetter()} alt="loading..." />
              </WidgetWrapper>
              {widgetList.map((widget, i) => {
                if (widgetData === null) {
                  return (
                    <WidgetWrapper
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress />
                    </WidgetWrapper>
                  );
                } else {
                  return (
                    <RenderWidget
                      widget={widget}
                      widgetData={
                        activeSlideIndex === i + 1 ? widgetData : null
                      }
                      key={i}
                    />
                  );
                }
              })}
            </Carousel>
          </CarouselWrapper>
        </Container>
      </Dialog>
      <Backdrop
        open={loading}
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 999999,
        }}
      >
        <CircularProgress style={{ color: "white" }} />
      </Backdrop>
    </>
  );
};

export default DashboardWidgetSlideshow;
