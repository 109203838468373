import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { Box, Button, makeStyles, Modal } from "@material-ui/core";
import { addNewIpAddressApi, addSourceApi, editSourceApi } from "../../Api";
import { useEffect } from "react";
import { myTheme } from "../../themeUtils";
import { Typography } from "@mui/material";
import analytics from "../../Analytics";
import { getLocalizedText } from "../../Functions";
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
  margin-bottom: 20px;
`;
const Form = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Input = styled("input")`
  width: 428px;
  height: 30px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;

  border: 1px solid #bfbfbf;
  font: normal normal 500 12px Open Sans;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 5px;
`;

const AddWhiteListedIp = (props) => {
  const [ipAddress, setIpAddress] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    let response_flag = await addNewIpAddressApi({ ip_address: ipAddress });
    if (response_flag === true) {
      props.setRefresh(true);
      props.setOpen(false);
    }
  };
  const handleClose = () => {
    props.setOpen(false);
  };

  useEffect(() => {
    analytics.triggerEvent(
      4625000162,
      "add_ip_form_load",
      "ip_whitelist_page",
      "",
      {}
    );
  }, []);

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <Container>
              <Header>{getLocalizedText("add_a_new_ip_address")}</Header>

              <Form component={"form"} onSubmit={handleSubmit}>
                <InputContainer>
                  <InputWrapper>
                    <Label>{getLocalizedText("ip_address")}*</Label>
                    <Input
                      value={ipAddress}
                      required
                      type="text"
                      placeholder="Enter ip here..."
                      name="ip_address"
                      pattern="^(((1?[1-9]?|10|2[0-4])\d|25[0-5])($|\.(?!$))){4}$"
                      onChange={(e) => {
                        setIpAddress(e.target.value);
                      }}
                    />
                  </InputWrapper>
                </InputContainer>
                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      analytics.triggerEvent(
                        4625000163,
                        "cancel_button_tap",
                        "add_ip_form",
                        "cancel_button",
                        {}
                      );
                      handleClose();
                    }}
                    type="button"
                    variant="contained"
                    color="default"
                    style={{
                      backgroundColor: "#EFEFF4",
                      textTransform: "none",
                      marginRight: "20px",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                  <Button
                    onClick={() => {
                      analytics.triggerEvent(
                        4625000164,
                        "submit_button_tap",
                        "add_ip_form",
                        "submit_button",
                        {}
                      );
                    }}
                    type="submit"
                    variant="contained"
                    id="call-log-btn"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      ...myTheme.Button.btnBlue,
                    }}
                  >
                    {getLocalizedText("submit")}
                  </Button>
                </ButtonWrapper>
              </Form>
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default observer(AddWhiteListedIp);
