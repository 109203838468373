import { Box, LinearProgress, Typography, lighten } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
const Container = styled(Box)`
  width: 100%;
  height: fit-content;
  padding: 12px 20px;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  gap: 8px;
  flex-direction: column;
`;
const Label = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  color: white;
  text-align: left;
`;

const IndicatorText = styled(Typography)`
  font: normal normal 600 12px Open Sans;

  color: white;
`;
const IndicatorWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
`;
const lighteningFactor = 0.5;
function generateLinearGradient(color) {
  const lighterColor = lighten(color, 1);
  return `linear-gradient(to right, ${lighterColor}, ${color})`;
}

const LinearProgressWidget = ({ value }) => {
  const getColorValue = () => {
    if (value <= 33) {
      return lighten("#FF0000", (1 - value / 33) * lighteningFactor);
    } else if (value <= 66) {
      return lighten(
        "#FFA500",
        (1 - (value - 33) / (66 - 33)) * lighteningFactor
      );
    } else {
      return lighten(
        "#008000",
        (1 - (value - 66) / (100 - 66)) * lighteningFactor
      );
    }
  };
  const CustomProgressBar = styled(LinearProgress)(({ value }) => {
    let gradient;
    let color = getColorValue();
    if (value <= 33) {
      gradient = generateLinearGradient(color);
    } else if (value <= 66) {
      gradient = generateLinearGradient(color);
    } else {
      gradient = generateLinearGradient(color);
    }
    return {
      width: "100%",
      height: 6,
      borderRadius: 5,
      "& .MuiLinearProgress-bar": {
        backgroundImage: gradient,
      },
    };
  });

  return (
    <Container>
      <Label>Quality Score - {value}</Label>
      <CustomProgressBar variant="determinate" value={value} />
      <IndicatorWrapper>
        <IndicatorText>0</IndicatorText>
        <IndicatorText>100</IndicatorText>
      </IndicatorWrapper>
    </Container>
  );
};

export default LinearProgressWidget;
