import { Box, styled } from "@mui/material";
import React from "react";
import CustomerStageCard from "./cards/CustomerStageCard";
import PurchaseCategoryCard from "./cards/PurchaseCategoryCard";
import ActivationDetailsCard from "./cards/ActivationDetailsCard";
import { Grid } from "@material-ui/core";
const Container = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
  padding: 16px;
`;
const CustomerCurrentStatusInfoWidget = ({
  details,
  setOpenUpdateCustomerStageDialog,
  setOpenUpdatePurchaseCategoryDialog,
}) => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <CustomerStageCard
            details={details}
            setOpenUpdateCustomerStageDialog={setOpenUpdateCustomerStageDialog}
          />
        </Grid>
        <Grid item xs={4}>
          <PurchaseCategoryCard
            details={details}
            setOpenUpdatePurchaseCategoryDialog={
              setOpenUpdatePurchaseCategoryDialog
            }
          />
        </Grid>
        <Grid item xs={4}>
          <ActivationDetailsCard details={details} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default CustomerCurrentStatusInfoWidget;
