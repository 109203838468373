import React, { useMemo } from "react";
import Footer from "../../../components/landing_page_components/footer/Footer";

import { styled } from "@mui/material/styles";

import { observer } from "mobx-react-lite";

import {
  AddBoxOutlined,
  ChevronRight,
  Delete,
  Visibility,
} from "@material-ui/icons";
import rootStore from "../../../stores/RootStore";
import { useEffect } from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { Button, CircularProgress, Drawer } from "@material-ui/core";
import { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  deleteProjectAdministrationQualityApi,
  getProjectAdministrationQualityListApi,
} from "../../../Api";
import { ProjectAdminQualityColumnHeaders } from "../../../Db";
import AddQualitySidepanel from "../../../components/quality_page_components/AddQualitySidepanel";
import MessageWithLoadingStateCustomDialog from "../../../components/alert_dialogue/MessageWithLoadingStateCustomDialog";
import CustomConfirmationDialog from "../../../components/alert_dialogue/CustomConfirmationDialog";
import ViewQualityInfoSidepanel from "../../../components/quality_page_components/ViewQualityInfoSidepanel";
import { IsoToLocalDate, IsoToLocalTime } from "../../../Functions";
import PublishRuleSidepanel from "../../../components/quality_page_components/PublishRuleSidepanel";

const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  height: 100%;
  margin: auto;
  margin-top: 64px;
  width: 95%;
`;

const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: flex-end;
`;
const Text = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
`;

const Row = styled(Box)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 16px 0px;
`;

const Bottom = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 248px);`};
  background-color: white;
  overflow-x: auto;
`;

const AddBtnWrapper = styled(Box)`
  position: absolute;
  right: 5px;
  top: 5px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
`;

const QualityPage = () => {
  const { authStore } = rootStore;
  const columnsList = [...rootStore.userStore.AllColumnsList];
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [openAddQuality, setOpenAddQuality] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedQualityID, setSelectedQualityID] = useState("");
  const [fetchingApi, setFetchingApi] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSubmitResponseDialog, setOpenSubmitResponseDialog] =
    useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [conditionListByColumnID, setConditionListByColumnID] = useState({});
  const [openViewInfoPanel, setOpenViewInfoPanel] = useState(false);
  const [conditionList, setConditionList] = useState([]);
  const [openPublishRuleSidepanel, setOpenPublishRuleSidepanel] =
    useState(false);
  const [allRuleList, setAllRuleList] = useState([]);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (refresh) {
      refetch();
    }
  }, [refresh]);

  const getValidStatusValue = (status) => {
    return status ? status.toLowerCase() : null;
  };

  const getQualityScoreList = async () => {
    setRow([]);
    let response = await getProjectAdministrationQualityListApi();
    response.sort((a, b) =>
      a["column_meta"]["name"]
        .toLowerCase()
        .localeCompare(b["column_meta"]["name"].toLowerCase())
    );

    let columnIDList = [];
    response.forEach((item) => {
      if (!columnIDList.includes(item["column_meta"]["id"])) {
        columnIDList.push(item["column_meta"]["id"]);
      }
    });
    let tempList = {};
    columnIDList.forEach((id) => {
      tempList[id] = [];
    });
    columnIDList.forEach((id) => {
      response.forEach((item) => {
        if (item["column_meta"]["id"] === id) {
          if (item["deleted_on"] === null) {
            tempList[id].push(item);
          }
        }
      });
    });

    setAllRuleList(response);

    let filteredList = [...response].filter(
      (item) => item["best_match"] === true && item["deleted_on"] === null
    );
    setConditionListByColumnID(tempList);
    setRow(filteredList);
  };

  const init = async () => {
    await getQualityScoreList();
    setLoading(false);
  };
  const refetch = async () => {
    setLoading(true);
    await getQualityScoreList();
    setLoading(false);
    setRefresh(false);
  };

  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  useEffect(() => {
    authStore.updateLeadDetailStatus();
  }, []);

  const handleDelete = async () => {
    setShowConfirmationDialog(false);
    setFetchingApi(true);
    setOpenSubmitResponseDialog(true);

    for (let i = 0; i < conditionList.length; i++) {
      let payload = { quality_score_id: conditionList[i]["id"] };
      let response = await deleteProjectAdministrationQualityApi({
        payload: payload,
      });
      if (response.hasError()) {
        setErrorMessage(response.errorMessage);
        setIsSubmitFail(true);
        setFetchingApi(false);
        return;
      }
    }
    setRefresh(true);
    setSuccessMessage("Quality score deleted successfully!");
    setFetchingApi(false);
  };
  const actionColumn = [
    {
      field: "value1",
      headerName: "VALUE 1",
      minWidth: 150,
      cellRenderer: (params) => {
        let index = columnsList.findIndex(
          (column) => column["id"] === params.data["column_meta"]["id"]
        );
        if (index !== -1) {
          if (params.data.value1 !== "" && params.data.value1 !== null) {
            let columnMeta = columnsList[index];
            let value = params.data.value1;

            if (columnMeta["data_type"] === "date") {
              value = IsoToLocalDate(params.data["value1"]);
            } else if (columnMeta["data_type"] === "datetime") {
              value = IsoToLocalTime(params.data["value1"]);
            }
            return value;
          } else {
            return "-";
          }
        } else {
          return "-";
        }
      },
    },
    {
      field: "value2",
      headerName: "VALUE 2",
      minWidth: 150,
      cellRenderer: (params) => {
        let index = columnsList.findIndex(
          (column) => column["id"] === params.data["column_meta"]["id"]
        );
        if (index !== -1) {
          if (params.data.value2 !== "" && params.data.value2 !== null) {
            let columnMeta = columnsList[index];
            let value = params.data.value2;

            if (columnMeta["data_type"] === "date") {
              value = IsoToLocalDate(params.data["value2"]);
            } else if (columnMeta["data_type"] === "datetime") {
              value = IsoToLocalTime(params.data["value2"]);
            }
            return value;
          } else {
            return "-";
          }
        } else {
          return "-";
        }
      },
    },
    {
      field: "status",
      headerName: "STATUS",
      width: 150,
      cellRenderer: (params) => {
        if (isAllRulePublished(params.data["column_meta"]["id"])) {
          return "Published";
        } else {
          return "-";
        }
      },
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      resizable: false,
      minWidth: 130,
      flex: 1,
      cellRenderer: (params) => {
        return (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Tooltip title={"View"}>
              <IconButton
                color="primary"
                onClick={() => {
                  let tempList =
                    conditionListByColumnID[params.data.column_meta["id"]];
                  setConditionList(tempList);
                  setOpenViewInfoPanel(true);
                }}
              >
                <Visibility
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Delete"}>
              <IconButton
                color="error"
                onClick={() => {
                  let tempList =
                    conditionListByColumnID[params.data.column_meta["id"]];
                  setConditionList(tempList);
                  setShowConfirmationDialog(true);
                }}
              >
                <Delete
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const getRuleListForPublishSidepanel = () => {
    let tempList = [];

    allRuleList.forEach((rule) => {
      if (
        (getValidStatusValue(rule["status"]) === "unpublished" &&
          rule["deleted_on"] === null) ||
        (getValidStatusValue(rule["status"]) === "published" &&
          rule["deleted_on"] !== null)
      ) {
        tempList.push(rule);
      }
    });
    return tempList;
  };

  const isAllRulePublished = (columnID) => {
    let ruleList = conditionListByColumnID[columnID];
    let index = ruleList.findIndex(
      (rule) =>
        (getValidStatusValue(rule["status"]) === "unpublished" &&
          rule["deleted_on"] === null) ||
        (getValidStatusValue(rule["status"]) === "published" &&
          rule["deleted_on"] !== null)
    );
    return index === -1;
  };

  const getRowStyle = (params) => {
    if (!isAllRulePublished(params.data["column_meta"]["id"])) {
      return { background: "#F5F4F3", color: "#A3A5A5" };
    }
  };

  return (
    <>
      <ParentContainer>
        <Container>
          <Row>
            <TopWrapper>
              <Text>Project Administration</Text>
              <ChevronRight style={{ width: "16px", height: "16px" }} />
              <Text>Prioritization</Text>
              <ChevronRight style={{ width: "16px", height: "16px" }} />
              <Text>Quality</Text>
            </TopWrapper>

            <Button
              disabled={getRuleListForPublishSidepanel().length === 0}
              onClick={() => {
                setOpenPublishRuleSidepanel(true);
              }}
              type="button"
              variant="contained"
              color="primary"
              style={{
                textTransform: "none",
                fontSize: "14px",
                fontWeight: "bold",
                width: "100px",
                whiteSpace: "nowrap",
              }}
            >
              Publish
            </Button>
          </Row>

          <Bottom>
            {!loading ? (
              <TableWrapper style={{ position: "relative" }}>
                <AddBtnWrapper>
                  <AddBoxOutlined
                    onClick={() => {
                      setOpenAddQuality(true);
                    }}
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                  />
                </AddBtnWrapper>
                <div className="ag-theme-alpine" style={{ width: "100%" }}>
                  <AgGridReact
                    domLayout="autoHeight"
                    suppressCellFocus
                    gridOptions={gridOptions}
                    loadingOverlayComponent={<CircularProgress />}
                    rowData={row}
                    defaultColDef={defaultColDef}
                    columnDefs={ProjectAdminQualityColumnHeaders.concat(
                      actionColumn
                    )}
                    animateRows={true}
                    getRowStyle={getRowStyle}
                  />
                </div>
              </TableWrapper>
            ) : (
              <TableWrapper
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </TableWrapper>
            )}
          </Bottom>
        </Container>
      </ParentContainer>
      <Footer />

      {openSubmitResponseDialog && (
        <MessageWithLoadingStateCustomDialog
          open={openSubmitResponseDialog}
          setOpen={setOpenSubmitResponseDialog}
          successMessage={successMessage}
          errorMessage={errorMessage}
          loading={fetchingApi}
          isError={isSubmitFail}
        />
      )}
      {showConfirmationDialog && (
        <CustomConfirmationDialog
          message={"Are you sure you want to delete this quality?"}
          open={showConfirmationDialog}
          setOpen={setShowConfirmationDialog}
          handleConfirm={() => {
            handleDelete();
          }}
        />
      )}
      {openAddQuality && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openAddQuality}
          onClose={() => {
            setOpenAddQuality(false);
          }}
        >
          <AddQualitySidepanel
            setOpen={setOpenAddQuality}
            open={openAddQuality}
            groupList={row}
            setRefresh={setRefresh}
            qualityID={selectedQualityID}
            list={row}
          />
        </Drawer>
      )}

      {openViewInfoPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openViewInfoPanel}
          onClose={() => {
            setOpenViewInfoPanel(false);
          }}
        >
          <ViewQualityInfoSidepanel
            list={conditionList}
            setOpen={setOpenViewInfoPanel}
            setRefresh={setRefresh}
          />
        </Drawer>
      )}
      {openPublishRuleSidepanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openPublishRuleSidepanel}
          onClose={() => {
            setOpenPublishRuleSidepanel(false);
          }}
        >
          <PublishRuleSidepanel
            ruleList={getRuleListForPublishSidepanel()}
            setOpen={setOpenPublishRuleSidepanel}
            setRefresh={setRefresh}
          />
        </Drawer>
      )}
    </>
  );
};

export default observer(QualityPage);
