import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import { Box, Drawer } from "@material-ui/core";
import { Add, ChevronRight, Edit } from "@material-ui/icons";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  taskPriorityList,
  tasksPageColumnHeaders,
  taskStatusList,
} from "../../Db";
import rootStore from "../../stores/RootStore";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { styled } from "@mui/material/styles";
import { Button, Chip, IconButton, Tooltip, Typography } from "@mui/material";
import TaskIcon from "@mui/icons-material/Task";
import CreateTaskComponent from "../../components/tasks_page_components/CreateTaskComponent";
import CloseTaskComponent from "../../components/tasks_page_components/CloseTaskComponent";
import { editTaskApi, closeTaskApi, getTasksListApi } from "../../Api";
import CalendarWidget from "../../components/tasks_page_components/calendar_components/CalendarWidget";
import moment from "moment";
import {
  dataRenderer,
  isDateInRange,
} from "../../Functions";
import NoAccessPermissionPage from "../no_access_page/NoAccessPermissionPage";
import MessageWithLoadingStateCustomDialog from "../../components/alert_dialogue/MessageWithLoadingStateCustomDialog";
import DropdownWidget from "../../components/user_tabs/tasks_tab_components/DropdownWidget";
import LoadingAnimationComponent from "../../components/loading_component/LoadingAnimationComponent";
const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 84px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;

const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 16px 0px;
  justify-content: space-between;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const ActionsWidgetWrapper = styled(Box)`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  background-color: white;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 268px);`};
  background-color: white;
  display: flex;
  flex-direction: column;
`;
const BottomWrapper = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  background-color: white;
  padding-bottom: 8px;
`;
const ChipsWrapper = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 16px;
  height: 100%;
  padding-left: 16px;
`;
const TasksPage = () => {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [refreshBadge, setRefreshBadge] = useState(false);
  const [openCreateTaskPanel, setOpenCreateTaskPanel] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [taskView, setTaskView] = useState("calendar");
  const [isEdit, setIsEdit] = useState(false);
  const [isClose, setIsClose] = useState(false);
  const [editableData, setEditableData] = useState({});
  const [fetchingApi, setFetchingApi] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSubmitResponseDialog, setOpenSubmitResponseDialog] =
    useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);

  //calendar state
  const [lastViewedCalendarDate, setLastViewedCalendarDate] = useState(null);
  const [calendarRange, setCalendarRange] = useState(null);
  const [lastTappedDate, setLastTappedDate] = useState(null);

  const [statusHoveredRowID, setStatusHoveredRowID] = useState("");
  const [priorityHoveredRowID, setPriorityHoveredRowID] = useState("");

  const getTasksList = async ({ range }) => {
    let queryParams = {
      start_date:
        range.hasOwnProperty("start") && range["start"] !== null
          ? moment(range["start"]).toISOString()
          : null,
      end_date:
        range.hasOwnProperty("end") && range["end"] !== null
          ? moment(range["end"]).toISOString()
          : null,
    };

    let response = await getTasksListApi({
      queryParams,
    });
    setTableData(response);
  };

  const init = async () => {
    setLoading(true);
    const startOfMonth = moment()
      .startOf("month")
      .format("YYYY-MM-DD HH:mm:ss");
    const endOfMonth = moment().endOf("month").format("YYYY-MM-DD HH:mm:ss");
    setLastViewedCalendarDate(startOfMonth);
    //manually set current month range for calendar before api call
    const range = {
      start: startOfMonth,
      end: endOfMonth,
    };
    setCalendarRange(range);

    if (rootStore.subscriptionState.isFeatureAvailableWithAddOns("Tasks")) {
      await getTasksList({ range: range });
    }

    setLoading(false);
  };
  const refetch = async () => {
    setLoading(true);
    await getTasksList({ range: calendarRange });
    if(refreshBadge){
      rootStore.userStore.updateTasksPageTaskList();
    }
    setLoading(false);
    setRefresh(false);
    setRefreshBadge(false);
  };
  useEffect(() => {
    if (lastTappedDate !== null) {
      setFilteredTaskList(lastTappedDate);
    }
  }, [tableData]);
  useEffect(() => {
    if (refresh === true) {
      refetch();
    }
  }, [refresh]);

  useEffect(() => {
    init();
    rootStore.authStore.updateLeadDetailStatus();
  }, []);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const handleCalendarSlotClick = (slotInfo) => {
    //filter tableData to show only tasks for selected date
    const { start } = slotInfo;
    setLastTappedDate(start);
    setFilteredTaskList(start);
    setTaskView("list");
  };

  const setFilteredTaskList = (startDate) => {
    const filteredData = tableData.filter((item) =>
      isDateInRange(item.start_time, item.end_time, startDate)
    );
    setFilteredTableData(filteredData);
  };

  const getEventsList = () => {
    let tempList = {};

    tableData.forEach((obj) => {
      // Convert start and end times to dates
      let startDate = moment(obj["start_time"])
        .startOf("day")
        .format("YYYY-MM-DD");
      let endDate = moment(obj["end_time"]).startOf("day").format("YYYY-MM-DD");

      // Initialize entries for each day between start and end dates
      let current = moment(startDate);
      let end = moment(endDate);

      while (current <= end) {
        let dateKey = current.format("YYYY-MM-DD");

        if (!tempList.hasOwnProperty(dateKey)) {
          tempList[dateKey] = {};
        }

        let taskType = obj["task_type"]["task_name"];

        if (!tempList[dateKey].hasOwnProperty(taskType)) {
          tempList[dateKey][taskType] = {
            count: 0,
            tasks: [],
          };
        }

        tempList[dateKey][taskType].tasks.push(obj);
        tempList[dateKey][taskType].count += 1;

        current.add(1, "day"); // Move to the next day
      }
    });

    let eventList = [];

    Object.keys(tempList).forEach((dateKey) => {
      Object.keys(tempList[dateKey]).forEach((taskType) => {
        let taskGroup = tempList[dateKey][taskType];

        eventList.push({
          title: `${taskType} (${taskGroup.count})`, // Show task type with count
          start: moment(dateKey).toDate(), // Convert back to Date object
          end: moment(dateKey).toDate(), // Set the same date for start and end
          event_type: "Task", // event type for calendar - currently only task
          type: taskType,
        });
      });
    });

    return eventList;
  };

  const actionColumns = [
    {
      field: "task_status",
      headerName: "STATUS",
      sortable: false,
      width: 150,
      cellRenderer: (params) => {
        return (
          <Box
            onMouseEnter={() => {
              setStatusHoveredRowID(params.data["id"]);
            }}
            onMouseLeave={() => {
              setStatusHoveredRowID("");
            }}
            sx={{ height: "100%", display: "flex", alignItems: "center" }}
          >
            {taskStatusRenderer({ rowData: params.data })}
          </Box>
        );
      },
    },
    {
      field: "priority",
      headerName: "PRIORITY",
      sortable: false,
      width: 150,
      cellRenderer: (params) => {
        return (
          <Box
            onMouseEnter={() => {
              setPriorityHoveredRowID(params.data["id"]);
            }}
            onMouseLeave={() => {
              setPriorityHoveredRowID("");
            }}
            sx={{ height: "100%", display: "flex", alignItems: "center" }}
          >
            {taskPriorityRenderer({ rowData: params.data })}
          </Box>
        );
      },
    },

    {
      field: "actions",
      headerName: "ACTIONS",
      resizable: false,
      minWidth: 130,
      flex: 1,
      cellRenderer: (params) => {
        return (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              columnGap: "10px",
            }}
          >
            <Tooltip title={"Edit Configuration"}>
              <IconButton
                color="primary"
                disabled={params.data["task_status"] !== "Open"}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setEditableData(params.data);
                  setIsClose(false);
                  setIsEdit(true);
                  setStatusHoveredRowID("");
                  setPriorityHoveredRowID("");
                  setOpenCreateTaskPanel(true);
                }}
              >
                <Edit
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Close Task"}>
              <IconButton
                disabled={params.data["task_status"] !== "Open"}
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setEditableData(params.data);
                  setIsClose(true);
                  setStatusHoveredRowID("");
                  setPriorityHoveredRowID("");
                  setOpenCreateTaskPanel(true);
                }}
              >
                <TaskIcon
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const renderChipData = (chipValue) => {
    if (chipValue !== null && chipValue !== "") {
      if (chipValue === "Low") {
        return (
          <Chip
            size="small"
            style={{ fontWeight: "bold", width: "100px", fontSize: "12px" }}
            color="default"
            label={chipValue}
          />
        );
      } else if (chipValue === "Medium") {
        return (
          <Chip
            size="small"
            style={{ fontWeight: "bold", width: "100px", fontSize: "12px" }}
            color="warning"
            label={chipValue}
          />
        );
      } else if (chipValue === "High") {
        return (
          <Chip
            size="small"
            style={{ fontWeight: "bold", width: "100px", fontSize: "12px" }}
            color="error"
            label={chipValue}
          />
        );
      }
    } else {
      return "-";
    }
  };

  const taskStatusRenderer = ({ rowData }) => {
    if (rowData["task_status"] === "Open") {
      if (statusHoveredRowID === rowData.id) {
        return (
          <DropdownWidget
            onChange={(value) => {
              handleStatusUpdate(rowData.id, value);
            }}
            options={taskStatusList}
            value={rowData["task_status"]}
          />
        );
      }
    }
    return dataRenderer(rowData["task_status"]);
  };

  const taskPriorityRenderer = ({ rowData }) => {
    if (rowData["task_status"] === "Open") {
      if (priorityHoveredRowID === rowData.id) {
        return (
          <DropdownWidget
            onChange={(value) => {
              handlePriorityUpdate(rowData.id, value);
            }}
            options={taskPriorityList}
            value={rowData["priority"]}
          />
        );
      }
    }

    return renderChipData(rowData["priority"]);
  };

  //****below functions are to quick update task status and task priority************ */

  const handleStatusUpdate = async (taskID, value) => {
    setFetchingApi(true);
    setOpenSubmitResponseDialog(true);
    let payload = {
      task_status: value,
    };
    let response = await closeTaskApi({
      taskID: taskID,
      payload: payload,
    });
    if (!response.hasError()) {
      setIsSubmitFail(false);
      setRefresh(true);
      setSuccessMessage("Task status updated successfully!");
      setFetchingApi(false);
      setRefreshBadge(true);
    } else {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
      setFetchingApi(false);
    }
  };
  const handlePriorityUpdate = async (taskID, value) => {
    setFetchingApi(true);
    setOpenSubmitResponseDialog(true);
    let payload = {
      priority: value,
    };
    let response = await editTaskApi({
      taskID: taskID,
      payload: payload,
    });
    if (!response.hasError()) {
      setIsSubmitFail(false);
      setRefresh(true);
      setSuccessMessage("Task priority updated successfully!");
      setFetchingApi(false);
      setRefreshBadge(true);
    } else {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
      setFetchingApi(false);
    }
  };

  //************************END******************** */

  const getRowStyle = (params) => {
    return {
      opacity: params.data["task_status"] !== "Open" ? "0.4" : "1",
    };
  };

  return rootStore.subscriptionState.isFeatureAvailableWithAddOns("Tasks") ? (
    <>
      <ParentContainer>
        {!loading ? (
          <Container>
            <TopWrapper>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Header>Tasks</Header>
                <ChevronRight style={{ width: "16px", height: "16px" }} />
              </Box>
              <Button
                style={{
                  textTransform: "none",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
                variant="contained"
                color="primary"
                onClick={() => {
                  setIsClose(false);
                  setStatusHoveredRowID("");
                  setPriorityHoveredRowID("");
                  setOpenCreateTaskPanel(true);
                }}
              >
                <Add />
                Create Task
              </Button>
            </TopWrapper>
            <BottomWrapper>
              <ActionsWidgetWrapper>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  {taskView === "list" && (
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setTaskView("calendar");
                      }}
                    >
                      <ArrowBackIosNewIcon />
                    </IconButton>
                  )}
                </Box>
              </ActionsWidgetWrapper>
              {taskView === "calendar" ? (
                <CalendarWidget
                  eventList={getEventsList()}
                  onSlotClick={(slotInfo) => {
                    if (
                      moment(slotInfo.start).isSame(
                        lastViewedCalendarDate,
                        "month"
                      )
                    ) {
                      handleCalendarSlotClick(slotInfo);
                    }
                  }}
                  lastViewedCalendarDate={lastViewedCalendarDate}
                  onCalendarNavigate={(newDate) => {
                    setLastViewedCalendarDate(newDate);

                    const date = moment(newDate);
                    const startOfMonth = date
                      .startOf("month")
                      .format("YYYY-MM-DD HH:mm:ss");
                    const endOfMonth = date
                      .endOf("month")
                      .format("YYYY-MM-DD HH:mm:ss");

                    setCalendarRange({
                      start: startOfMonth,
                      end: endOfMonth,
                    });
                    setRefresh(true);
                  }}
                />
              ) : (
                <TableWrapper>
                  <div className="ag-theme-alpine" style={{ width: "100%" }}>
                    <AgGridReact
                      getRowStyle={getRowStyle}
                      domLayout="autoHeight"
                      gridOptions={gridOptions}
                      rowData={filteredTableData}
                      defaultColDef={defaultColDef}
                      columnDefs={tasksPageColumnHeaders.concat(actionColumns)}
                      animateRows={true}
                      rowSelection="multiple"
                      suppressCellFocus
                      pagination={false}
                      className="paginated-ag-grid"
                      onRowClicked={(row) => {
                        if (row.event.defaultPrevented) {
                          return null;
                        }
                      }}
                    />
                  </div>
                </TableWrapper>
              )}
            </BottomWrapper>
          </Container>
        ) : (
          <Container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingAnimationComponent size={"small"} />
          </Container>
        )}
      </ParentContainer>
      <Footer />
      {openCreateTaskPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openCreateTaskPanel}
          onClose={() => {
            setIsEdit(false);
            setOpenCreateTaskPanel(false);
          }}
        >
          {isClose ? (
            <CloseTaskComponent
              setOpen={setOpenCreateTaskPanel}
              setRefresh={(bool) => {
                setRefresh(bool);
                setRefreshBadge(bool);
              }}
              editableData={editableData}
              setIsClose={setIsClose}
            />
          ) : (
            <CreateTaskComponent
              setOpen={setOpenCreateTaskPanel}
              setRefresh={(bool) => {
                setRefresh(bool);
                setRefreshBadge(bool);
              }}
              editableData={editableData}
              isEdit={isEdit}
              isClose={isClose}
              setIsEdit={setIsEdit}
            />
          )}
        </Drawer>
      )}
      {openSubmitResponseDialog && (
        <MessageWithLoadingStateCustomDialog
          open={openSubmitResponseDialog}
          setOpen={setOpenSubmitResponseDialog}
          successMessage={successMessage}
          errorMessage={errorMessage}
          loading={fetchingApi}
          isError={isSubmitFail}
        />
      )}
    </>
  ) : (
    <NoAccessPermissionPage />
  );
};

export default observer(TasksPage);
