import { Box, Card, CardActionArea, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import React from "react";
import { dataRenderer } from "../../Functions";
const Title = styled(Typography)`
  font: normal normal 600 16px Open Sans;
  color: #4d4e4f;
  text-align: left;
`;
const Description = styled(Typography)`
  font: normal normal 500 14px Open Sans;
  color: #4d4e4f;
  text-align: left;
  word-wrap: break-word;
`;
const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
const TextWrapper = styled(Box)`
  width: 100%;
  padding: 16px;
`;
const ReportCardWidget = ({ details, handleClick }) => {
  return (
    <Card
      sx={{
        bgcolor: "white",
        height: "100%",
      }}
    >
      <CardActionArea
        onClick={handleClick}
        sx={{
          height: "100%",
        }}
      >
        <Wrapper>
          <TextWrapper sx={{ backgroundColor: "#E8E8E8" }}>
            <Title>{dataRenderer(details["name"])}</Title>
          </TextWrapper>
          <TextWrapper>
            <Description>{dataRenderer(details["desc"])}</Description>
          </TextWrapper>
        </Wrapper>
      </CardActionArea>
    </Card>
  );
};

export default ReportCardWidget;
