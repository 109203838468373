import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { Box, TextField } from "@mui/material";
const DateFieldWidget = ({ value, handleChange, min, max, disabled }) => {
  return (
    <Box sx={{ width: "250px" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          disabled={disabled}
          minDate={min}
          maxDate={max}
          label="Pick Date"
          value={value}
          onChange={(event) => {
            handleChange(event);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default DateFieldWidget;
