export const callInfoSheetScope = Object.freeze({
  lead_details: "lead_details",
  lead_details_call_icon: "lead_details_call_icon",
  lead_details_call_log: "lead_details_call_log",
  home_call_icon: "home_call_icon",
  home_project: "home_project",
});
export const digitalLenderState = Object.freeze({
  idle: "idle",
  in_progress: "in_progress",
  success: "success",
  error: "error",
});
