import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import { Box, CircularProgress, Drawer } from "@material-ui/core";
import { AddBoxOutlined, ChevronRight } from "@material-ui/icons";
import { predefinedDealsPageColumnHeaders } from "../../Db";

import rootStore from "../../stores/RootStore";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { pageSize } from "../../Config";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { getAdminPredefinedDealsListApi } from "../../Api";
import CreateAdminPredefinedDealSidepanel from "../../components/add_deal_sidepanel/CreateAdminPredefinedDealSidepanel";
import ViewPredefinedDealSidepanel from "../../components/view_deal_sidepanel/ViewPredefinedDealSidepanel";
import { validateDisabledFeature } from "../../Functions";
import NoAccessPermissionPage from "../no_access_page/NoAccessPermissionPage";

const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 84px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;

const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 236px);`};
  background-color: white;
  padding: 20px;
  position: relative;
`;

const AddBtnWrapper = styled(Box)`
  position: absolute;
  right: 24px;
  top: 24px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
`;
const AdminProjectPredefinedDeals = () => {
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [openCreateDeal, setOpenCreateDeal] = useState(false);
  const [openViewDealPanel, setOpenViewDealPanel] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const teamID =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  const projectID = rootStore.authStore.projectId;
  const getDealsList = async () => {
    setRow([]);
    let response = await getAdminPredefinedDealsListApi({
      projectID: projectID,
      teamID: teamID,
    });
    setRow(response);
  };

  const init = async () => {
    setLoading(true);
    if(rootStore.subscriptionState.isFeatureAvailableWithAddOns("Deals")){
      await getDealsList();
    }
    setLoading(false);
  };

  useEffect(() => {
    if (refresh === true) {
      init();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    init();
    rootStore.authStore.updateLeadDetailStatus();
  }, []);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  return rootStore.subscriptionState.isFeatureAvailableWithAddOns("Deals") ? (
    <>
      <ParentContainer>
        {!loading ? (
          <Container>
            <Row>
              <TopWrapper>
                <Header>Admin</Header>
                <ChevronRight style={{ width: "16px", height: "16px" }} />
                <Header>Predefined Deals</Header>
              </TopWrapper>
            </Row>
            <TableWrapper>
              <AddBtnWrapper
                onClick={() => {
                  setOpenCreateDeal(true);
                }}
              >
                <AddBoxOutlined
                  style={{
                    width: "40px",
                    height: "40px",
                  }}
                />
              </AddBtnWrapper>
              <div className="ag-theme-alpine" style={{ width: "100%" }}>
                <AgGridReact
                  domLayout="autoHeight"
                  gridOptions={gridOptions}
                  rowData={row}
                  defaultColDef={defaultColDef}
                  columnDefs={predefinedDealsPageColumnHeaders}
                  animateRows={true}
                  suppressCellFocus
                  pagination={false}
                  paginationPageSize={pageSize}
                  className="paginated-ag-grid"
                  onRowClicked={(row) => {
                    if (row.event.defaultPrevented) {
                      return null;
                    }
                    setSelectedRowData(row.data);
                    setOpenViewDealPanel(true);
                  }}
                />
              </div>
            </TableWrapper>
          </Container>
        ) : (
          <Container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Container>
        )}
      </ParentContainer>
      <Footer />
      {openCreateDeal && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openCreateDeal}
          onClose={() => {
            setOpenCreateDeal(false);
          }}
        >
          <CreateAdminPredefinedDealSidepanel
            setRefresh={setRefresh}
            setOpen={setOpenCreateDeal}
            open={openCreateDeal}
            projectID={projectID}
            teamID={teamID}
          />
        </Drawer>
      )}

      {openViewDealPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openViewDealPanel}
          onClose={() => {
            setSelectedRowData({});
            setOpenViewDealPanel(false);
          }}
        >
          <ViewPredefinedDealSidepanel
            details={selectedRowData}
            setOpen={setOpenViewDealPanel}
            open={openViewDealPanel}
          />
        </Drawer>
      )}
    </>
  ) : (
    <NoAccessPermissionPage />
  );
};

export default observer(AdminProjectPredefinedDeals);
