import { TextField, styled } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
const CustomizedDatePicker = styled(DatePicker)`
  & .MuiInputBase-input {
    font-size: 12px;
    padding: 10.5px 14px;
    color: ${(e) => e.fieldColor};
  }
`;
const DateField = ({ value, onChange, required, disabled,fieldValueColor="#4d4e4f" }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CustomizedDatePicker
        disabled={disabled}
        value={value}
        onChange={(event) => {
          onChange(event);
        }}
        fieldColor={fieldValueColor}
        inputFormat="YYYY-MM-DD"
        renderInput={(params) => (
          <TextField
            required={required}
            sx={{ width: "100%"}}
            size="small"
            {...params}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DateField;
