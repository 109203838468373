import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Modal,
  OutlinedInput,
  Toolbar,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { getLocalizedText } from "../../../Functions";
import MuiAlert from "@mui/material/Alert";
import moment from "moment";
import CustomFieldHeader from "../../custom_tooltip_headers/CustomFieldHeader";
import { Info } from "@material-ui/icons";
const dialogWidth = 420;
const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Detail = styled(Typography)`
  font: normal normal 500 14px Open Sans;
  color: #4d4e4f;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const AddBreakDialog = ({
  setOpen,
  open,
  handleAdd,
  breakList,
  dayStartTime,
  dayEndTime,
}) => {
  const [details, setDetails] = useState({
    start_time: "",
    end_time: "",
    title: "",
    desc: "",
    secure_break: false,
  });
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (canAddBreak()) {
      handleAdd(details);
    } else {
      setIsSubmitFail(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const canAddBreak = () => {
    const dayStart = moment(dayStartTime, "HH:mm");
    const dayEnd = moment(dayEndTime, "HH:mm");
    const newBreakStart = moment(details["start_time"], "HH:mm");
    const newBreakEnd = moment(details["end_time"], "HH:mm");

    // Check if new break falls within the day start and end times
    if (newBreakStart.isBefore(dayStart) || newBreakEnd.isAfter(dayEnd)) {
      setErrorMessage(
        "Break time should be between the start and end time of the day."
      );
      return false;
    }

    // Check if new break overlaps with any existing breaks in the break list
    for (let i = 0; i < breakList.length; i++) {
      const existingBreakStart = moment(breakList[i].start_time, "HH:mm");
      const existingBreakEnd = moment(breakList[i].end_time, "HH:mm");

      if (
        newBreakStart.isBetween(
          existingBreakStart,
          existingBreakEnd,
          undefined,
          "[]"
        ) ||
        newBreakEnd.isBetween(
          existingBreakStart,
          existingBreakEnd,
          undefined,
          "[]"
        )
      ) {
        setErrorMessage("New break time overlaps with an existing break.");
        return false;
      }
    }
    return true;
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box component={"form"} onSubmit={handleSubmit}>
            <AppBar
              component={"nav"}
              elevation={0}
              position="sticky"
              color="inherit"
              sx={{
                top: 0,
                bottom: "auto",
                width: "100%",
                right: 0,
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                }}
              >
                <HeaderWrapper>
                  <Header>Add Break</Header>
                  <SubHeader>
                    {" "}
                    {getLocalizedText("fill_in_the_below_details")}
                  </SubHeader>
                </HeaderWrapper>
              </Toolbar>
            </AppBar>
            <Divider style={{ margin: "0px 16px" }} />
            <Container>
              <Box
                style={{
                  height: "100%",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Title*</Label>
                      <OutlinedInput
                        style={{ height: "30px" }}
                        inputProps={{
                          min: 0,
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        onChange={handleChange}
                        name="title"
                        required
                        value={details["title"]}
                        placeholder="Enter value"
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Description</Label>
                      <OutlinedInput
                        multiline
                        rows={3}
                        inputProps={{
                          min: 0,
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        onChange={handleChange}
                        name="desc"
                        value={details["desc"]}
                        placeholder="Enter value"
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>{getLocalizedText("start_time")}*</Label>
                      <OutlinedInput
                        style={{ height: "30px" }}
                        inputProps={{
                          max: details.end_time,
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        required
                        value={details.start_time}
                        type="time"
                        placeholder="enter start time"
                        name="start_time"
                        onChange={handleChange}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>{getLocalizedText("end_time")}*</Label>
                      <OutlinedInput
                        style={{ height: "30px" }}
                        inputProps={{
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        required
                        value={details.end_time}
                        type="time"
                        placeholder="enter end_time"
                        name="end_time"
                        onChange={handleChange}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <FormControlLabel
                        checked={details["secure_break"]}
                        control={
                          <Checkbox
                            size="medium"
                            onChange={(event) => {
                              setDetails({
                                ...details,
                                secure_break: event.target.checked,
                              });
                            }}
                          />
                        }
                        label={
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                            }}
                          >
                            <Detail>Secure Break</Detail>
                            <Tooltip
                              title={
                                "Users cannot access LeadPixie during this break time."
                              }
                              disableFocusListener
                              arrow
                            >
                              <Info
                                color="primary"
                                style={{
                                  width: "16px",
                                  cursor: "pointer",
                                  height: "16px",
                                }}
                              />
                            </Tooltip>
                          </Box>
                        }
                      />
                    </InputWrapper>
                  </Grid>
                </Grid>
              </Box>
            </Container>
            <AppBar
              position="static"
              color="inherit"
              elevation={0}
              sx={{
                top: "auto",
                bottom: 0,
                width: "100%",
                borderRadius: "0px 0px 8px 8px",
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                  width: dialogWidth,
                  position: "relative",
                }}
              >
                {isSubmitFail && (
                  <ErrorAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setIsSubmitFail(false);
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {errorMessage}
                  </ErrorAlert>
                )}
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      color="inherit"
                      style={{
                        fontSize: "12px",
                        textTransform: "none",
                        fontWeight: "bold",
                      }}
                    >
                      {getLocalizedText("cancel")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        width: "fit-content",
                        textTransform: "none",
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default AddBreakDialog;
