import React, { useEffect, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";

import styled from "styled-components";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ProfileTab from "../../components/user_tabs/ProfileTab";
import UserActivityTab from "../../components/user_tabs/UserActivityTab";
import UserCallDetails from "../../components/user_tabs/UserCallDetails";
import {
  Box,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import update_icon from "../../assets/campaign_icons/update.png";
import update_source_icon from "../../assets/campaign_icons/source.png";
import delete_icon from "../../assets/campaign_icons/delete.png";
import copy_icon from "../../assets/campaign_icons/copy.png";
import mark_icon from "../../assets/campaign_icons/mark.png";
import unmark_icon from "../../assets/campaign_icons/unmark.png";
import Update_stage_icon from "../../assets/campaign_icons/stage.png";
import submit_ops_icon from "../../assets/campaign_icons/submit_ops.png";
import unlocked_icon from "../../assets/campaign_icons/unlocked.png";
import email_icon from "../../assets/campaign_icons/email.png";
import clone_icon from "../../assets/campaign_icons/duplicate.png";
import mark_priority_icon from "../../assets/campaign_icons/mark_priority.png";
import unmark_priority_icon from "../../assets/campaign_icons/mark_regular.png";
import add_deal_icon from "../../assets/campaign_icons/add_deal.png";
import add_predefined_deal_icon from "../../assets/campaign_icons/add_predefined_deal.png";
import deactivate_deal_icon from "../../assets/campaign_icons/deactivate_deal.png";
import edit_lead_icon from "../../assets/campaign_icons/pen.png";
import create_quote_icon from "../../assets/campaign_icons/create_quote.png";
import update_color_icon from "../../assets/campaign_icons/update_color.png";
import task_icon from "../../assets/campaign_icons/task_icon.png";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import IosShareIcon from "@mui/icons-material/IosShare";
import {
  ArrowLeft,
  AssignmentOutlined,
  Call,
  ChevronRight,
  Link,
  Lock,
  LockOpen,
  WhatsApp,
} from "@material-ui/icons";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Backdrop, Menu, MenuItem, Skeleton } from "@mui/material";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import UserFileTab from "../../components/user_tabs/UserFileTab";
import {
  claimLeadApi,
  createContactApi,
  deleteLeadApi,
  getLeadProfileApi,
  getPhoneApi,
  linkContactApi,
  markUnmarkLeadAsFreshLeadApi,
  markUnmarkLeadAsPriorityApi,
  searchContactByPhoneApi,
} from "../../Api";
import LeadAssignModal from "../../components/lead_assign_modal/LeadAssignModal";
import LeadTransferModal from "../../components/lead_transfer_modal/LeadTransferModal";
import UpdateStatusModal from "../../components/update_status_modal/UpdateStatusModal";
import {
  checkFeaturePermission,
  checkFeaturePermissionInModule,
  consoleLogger,
  embedVariableValuesInMessageString,
  formatPhoneNumberForWhatsApp,
  getLocalizedText,
  isProjectAndAccountIdMatched,
  validateDisabledFeature,
  validateDisabledSubFeature,
} from "../../Functions";
import UserCheckInTab from "../../components/user_tabs/UserCheckInTab";
import UpdateSourceModal from "../../components/update_source_modal/UpdateSourceModal";
import NotesTab from "../../components/user_tabs/NotesTab";
import CallConfirmationDailog from "../../components/call/CallConfirmationDailog";
import CallInfoDialog from "../../components/call/CallInfoDialog";
import ErrorMessageDialog from "../../components/call/ErrorMessageDialog";
import { callInfoSheetScope } from "../../utils/enum";
import CallLogModal from "../../components/call_log_modal/CallLogModal";
import { PersonAddAlt } from "@mui/icons-material";
import MoneyViewCreateLead from "../../components/money_view_components/MoneyViewCreateLead";
import CustomDetailsTab from "../../components/user_tabs/CustomDetailsTab";
import RefreshOffersDialog from "../../components/money_view_components/RefreshOffersDialog";
import RefreshJourneyUrlDialog from "../../components/money_view_components/RefreshJourneyUrlDialog";
import analytics from "../../Analytics";
import AdvanceCopySidepanel from "../../components/advance_copy_sidepanel/AdvanceCopySidepanel";
import DealsTab from "../../components/user_tabs/DealsTab";
import CustomErrorMessageDialog from "../../components/alert_dialogue/CustomErrorMessageDialog";
import StageStepperComponentv2 from "../../components/stage_stepper_component/StageStepperComponentv2";
import UpdateStageModal from "../../components/update_stage_modal/UpdateStageModal";
import SubmitLeadModal from "../../components/submit_lead_modal/SubmitLeadModal";
import ConfirmLeadUnlockDialog from "../../components/alert_dialogue/ConfirmLeadUnlockDialog";
import DeleteIndividualLeadConfirmationDialog from "../../components/alert_dialogue/DeleteIndividualLeadConfirmationDialog";
import MessageWithLoadingStateCustomDialog from "../../components/alert_dialogue/MessageWithLoadingStateCustomDialog";
import CloneLeadModal from "../../components/clone_lead/CloneLeadModal";
import PriorityLeadDialog from "../../components/priority_lead_dialog/PriorityLeadDialog";
import moment from "moment";
import LeadFormTab from "../../components/user_tabs/LeadFormTab";
import GlobalEditLeadComponent from "../../components/edit_lead_component/GlobalEditLeadComponent";
import CustomYesNoDialog from "../../components/alert_dialogue/CustomYesNoDialog";
import SetLeadColorDialog from "../../components/update_lead_color_dialog/SetLeadColorDialog";
import WhatsappContextMenu from "../../components/whatsapp_context_menu/WhatsappContextMenu";
import ViewTextDialog from "../../components/view_text_dialog/ViewTextDialog";
import { isMacOs } from "react-device-detect";
import LeadDetailsNavigationComponent from "../../components/lead_details_navigation_component/LeadDetailsNavigationComponent";
import CustomLoadingDialog from "../../components/alert_dialogue/CustomLoadingDialog";
import CustomMessageDialog from "../../components/alert_dialogue/CustomMessageDialog";
import TasksTab from "../../components/user_tabs/TasksTab";
import InteractionsTab from "../../components/user_tabs/InteractionsTab";
import StickyIconsSidebar from "../../components/user_tabs/StickyIconsSidebar";
import SuccessMessageDialog from "../../components/alert_dialogue/SuccessMessageDialog";
import QuotesTab from "../../components/user_tabs/quotes_tab/QuotesTab";
import SendDialogComponent from "../../components/whatsapp_templates_page_components/SendDialogComponent";

const ParentContainer = styled.div`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled.div`
  ${`min-height: calc(100vh - 253px);`};
  margin: auto;
  margin-top: 70px;
  width: 95%;
`;
const Wrapper = styled.div`
  display: flex;
  position: relative;
  background-color: white;
  padding: 20px 20px 0px 20px;
  border: 2px solid #f9f9fc;
`;
const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 0px;
`;
const Text = styled.p`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const Icon = styled.img`
  height: 16px;
  width: 16px;
  cursor: pointer;
  margin-right: 8px;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;
const NoAccessText = styled.p`
  margin: 0px;
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;

const UserDetail = (props) => {
  const { filterStore, phoneCallState, authStore, userStore } = rootStore;
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [transferOpen, setTransferOpen] = useState(false);
  let { lid, uid } = useParams();
  const [refresh, setRefresh] = useState(false);
  const [anchorEl, setAnchorEl] = useState({});
  const [dealMenuAnchor, setDealMenuAnchor] = useState(null);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [openSourceModal, setOpenSourceModal] = useState(false);
  const [openUpdateStageModal, setOpenUpdateStageModal] = useState(false);
  const [openSubmitLeadModal, setOpenSubmitLeadModal] = useState(false);
  const stageStatusList = [...rootStore.authStore.stageStatusList];
  const [leadData, setLeadData] = useState({});
  const [openCallInfo, setOpenCallInfo] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openErrorDialog, setOpenErrorDailog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [callInfoScope, setCallInfoScope] = useState(null);
  const [openCallLogModal, setOpenCallLogModal] = useState(false);
  const [openAddCallLogButton, setAddCallLogButton] = useState(false);
  const statusList = [...rootStore.authStore.statusList];
  const stagesList = [...rootStore.authStore.stageList];
  const [openCreateLeadModal, setOpenCreateLeadModal] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [tabsToGroups, setTabsToGroups] = useState({});
  const [groupsToColumns, setGroupsToColumns] = useState({});
  const [detailsTabs, setDetailsTabs] = useState([]);
  const [refreshLeadDetails, setRefreshLeadDetails] = useState(false);
  const [loading, setLoading] = useState(true);
  const columnsList = userStore.AllColumnsList;
  const columnGroups = userStore.columnGroups;
  const [openRefreshOfferDialog, setOpenRefreshOfferDailog] = useState(false);
  const [openRefreshJourneyDialog, setOpenRefreshJourneyDailog] =
    useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [markLeadErrorMessage, setMarkLeadErrorMessage] = useState("");
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [accessLevel, setAccessLevel] = useState(""); //access level for lead details access permission
  const [openAdvancedCopyPanel, setOpenAdvancedCopyPanel] = useState(false);
  const [sortedColumnIdList, setSortedColumnIdList] = useState({}); //this state is maintained for advance copy side panel
  // const location = useLocation();
  // const receivedProps = location.state; // default is null else if state props is passed through useNavigate then it has the value
  const [_leadLockedOverriden, setLeadLockedOverriden] = useState(false);
  const [openUnlockLeadConfirmDialog, setOpenUnlockLeadConfirmDialog] =
    useState(false);
  const [unlockedTemporarily, setUnlockedTemporarily] = useState(false);
  const [showUnlockLeadSuccess, setShowUnlockLeadSuccess] = useState(false);
  /// state variables related to delete lead
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showResponseDialog, setShowResponseDialog] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [openClone, setOpenClone] = useState(false);
  const [openPriorityLeadDialog, setOpenPriorityLeadDialog] = useState(false);
  ///  END
  //********Functions to check lead details access permissions**************** */

  //************state variable related to deals tab******** */
  const [openAddDealPanel, setOpenAddDealPanel] = useState(false);
  const [openAddPredefinedDealPanel, setOpenAddPredefinedDealPanel] =
    useState(false);
  const [openDeactivateDealDialog, setOpenDeactivateDealDialog] =
    useState(false);
  const [hasDealList, setHasDealList] = useState(false);
  const [hasActiveDeal, setHasActiveDeal] = useState(false);
  const [openAddQuotePanel, setOpenAddQuotePanel] = useState(false);
  const [openAddInvoicePanel, setOpenAddInvoicePanel] = useState(false);

  //**************************end************************** */
  const [openEditLeadPanel, setOpenEditLeadPanel] = useState(false);

  //************state variable related fetch lead data api error handling***** */
  const location = useLocation();
  const receivedProps = location.state;
  const [leadErrorMessage, setLeadErrorMessage] = useState("");
  const [openClaimLeadConfirmDialog, setOpenClaimLeadConfirmDialog] =
    useState(false);
  const [isLeadClaiming, setIsLeadClaiming] = useState(false);
  const [openLeadClaimDialog, setOpenLeadClaimDialog] = useState(false);
  const [claimErrorMessage, setClaimErrorMessage] = useState("");
  const [isClaimErrored, setIsClaimErrored] = useState(false);
  //**********************************END*********************************** */
  const [openSetLeadColorDialog, setOpenSetLeadColorDialog] = useState(false);
  const [whatsAppContextAnchor, setWhatsAppContextAnchor] = useState(null);
  const messageTemplates = [...rootStore.authStore.whatsAppTemplateList];
  const [openSkypeCallConfirmationDialog, setOpenSkypeCallConfirmationDialog] =
    useState(false);
  const [selectedPhoneFieldValue, setSelectedPhoneFieldValue] = useState("");
  const [openViewTextDialog, setOpenViewTextDialog] = useState(false);
  const [textData, setTextData] = useState("");
  const [isNavigating, setIsNavigating] = useState(false);
  const [showNavigationFailedDialog, setShowNavigationFailedDialog] =
    useState(false);
  const [sendDialogOpen, setSendDialogOpen] = useState(false);
  const [selectedWhatsappTemplate, setSelectedWhatsappTemplate] = useState({});
  const [leadPhone, setLeadPhone] = useState("");

  //state variables for tasks tab
  const [openCreateTaskPanel, setOpenCreateTaskPanel] = useState(false);
  const canReadLead = () => {
    let account_role =
      userStore.UserData !== null ? userStore.UserData["role"] : "";
    if (account_role === "owner") {
      return true;
    }
    return leadData["access_read"].includes(accessLevel);
  };
  const canEditLead = () => {
    if (isLeadLocked()) {
      return false;
    } else {
      let account_role =
        userStore.UserData !== null ? userStore.UserData["role"] : "";
      if (account_role === "owner") {
        return true;
      }
      return leadData["access_edit"].includes(accessLevel);
    }
  };
  const canDestroyLead = () => {
    let account_role =
      userStore.UserData !== null ? userStore.UserData["role"] : "";
    if (account_role === "owner") {
      return true;
    }
    return leadData["access_destroy"].includes(accessLevel);
  };

  //*************************END*********************** */

  const isEmailVerified = () => {
    return leadData["email_verified"];
  };

  const handleMenuClick = (event, level) => {
    setAnchorEl({
      ...anchorEl,
      [level]: event.currentTarget,
    });
  };

  const handleClose = (level) => {
    setAnchorEl({
      ...anchorEl,
      [level]: null,
    });
  };

  const setupTabToGroups = (tabs) => {
    const result = {};

    // Create a dictionary of tabs with an empty list for each tab
    tabs.forEach((tab) => {
      result[tab] = [];
    });

    // Loop through the data and add each group name and group_order value to the appropriate tab's list
    columnGroups.forEach((item) => {
      if (result[item.tab_name]) {
        result[item.tab_name].push({
          name: item.group_name,
          order: item.group_order,
        });
      }
    });

    // Sort the groups within each tab's list by their group_order property
    Object.keys(result).forEach((tab) => {
      result[tab].sort((a, b) => a.order - b.order);
      result[tab] = result[tab].map((item) => item.name);
    });

    return result;
  };

  const setupGroupsToColumns = () => {
    // Get a list of all unique group names from the tabs
    const uniqueGroups = Array.from(
      new Set(columnGroups.map((item) => item.group_name))
    );

    const result = { "Other Information": [] };

    uniqueGroups.forEach((group) => {
      if (!result[group]) {
        result[group] = [];
      }
    });

    // Loop through the data and add each ID to the appropriate group's list
    columnsList.forEach((item) => {
      let group = item.group;
      if (rootStore.userStore.isColumnVisible(item)) {
        if (group === null || group === "") {
          result["Other Information"].push(item["id"]);
        } else {
          var groups = group.split(",").map((group) => {
            return group.trim();
          });
          groups.forEach((group) => {
            if (!uniqueGroups.includes(group)) {
              result["Other Information"].push(item["id"]);
            } else {
              result[group].push(item["id"]);
            }
          });
        }
      }
    });

    return result;
  };

  const setupTabs = () => {
    let result = [];
    if (columnGroups.length > 0) {
      columnGroups.forEach((item) => {
        const { tab_name } = item;
        if (tab_name !== "Basic Info") {
          if (!result.includes(tab_name)) {
            result.push(tab_name);
          }
        }
      });

      result.sort();

      let index = columnGroups.findIndex(
        (item) => item["tab_name"] === "Basic Info"
      );
      if (index !== -1) {
        result.unshift("Basic Info");
      }
    }
    return result;
  };

  const setup = () => {
    let tempDetailsTabs = setupTabs();
    setDetailsTabs(tempDetailsTabs);
    let tempTabsToGroups = setupTabToGroups(tempDetailsTabs);
    setTabsToGroups(tempTabsToGroups);
    let tempGroupsToColumns = setupGroupsToColumns();
    setGroupsToColumns(tempGroupsToColumns);

    let allTabs = [...tempDetailsTabs];
    if (validateDisabledSubFeature("lead_forms")) {
      if (Object.keys(rootStore.authStore.leadForms).length > 0) {
        allTabs.push(rootStore.authStore.leadForms["title"]);
      }
    }
    if (canShowActivitiesTab()) {
      allTabs.push("Activities");
    }
    if (rootStore.subscriptionState.isFeatureAvailableWithAddOns("Deals")) {
      allTabs.push("Deal");
    }
    if (rootStore.subscriptionState.isFeatureAvailableWithAddOns("Quotations")) {
      allTabs.push("Quotes");
    }
    if (rootStore.subscriptionState.isFeatureAvailableWithAddOns("Tasks")) {
      allTabs.push("Tasks");
    }
    allTabs.push("Interactions");
    allTabs.push("Files");
    if (validateDisabledSubFeature("leaddetails_notes_tab")) {
      allTabs.push("Notes");
    }
    filterStore.updateDetailTabValue(allTabs[0]);
    setTabs(allTabs);

    consoleLogger(
      "setup done:::",
      allTabs,
      tempGroupsToColumns,
      tempTabsToGroups
    );
  };

  const handleLeadClaim = async () => {
    setIsLeadClaiming(true);
    setOpenClaimLeadConfirmDialog(false);
    setOpenLeadClaimDialog(true);
    let response = await claimLeadApi({
      leadID: lid,
    });
    if (!response.hasError()) {
      await fetchLeadDetails();
      setIsClaimErrored(false);
      setIsLeadClaiming(false);
      setLoading(false);
    } else {
      setClaimErrorMessage(response.errorMessage);
      setIsClaimErrored(true);
      setIsLeadClaiming(false);
      setLoading(false);
    }
  };

  const isStatusAutoReAssignable = () => {
    let scope = getRouteScope();
    if (scope === "leads_page") {
      let index = statusList.findIndex(
        (statusObj) => statusObj["status"] === receivedProps["lead_status"]
      );
      if (index !== -1) {
        return statusList[index]["auto_reassignable"];
      }
    }
    return false;
  };
  const getLeadDetailsApi = async ({ leadID }) => {
    setLoading(true);
    let response = await getLeadProfileApi({ leadID: leadID });
    if (response.hasError()) {
      if (response.errorCode === "1002") {
        if (isStatusAutoReAssignable()) {
          setOpenClaimLeadConfirmDialog(true);
        } else {
          setLeadErrorMessage("You do not have access to this lead!");

          setLoading(false);
        }
      } else {
        setLeadErrorMessage(response.errorMessage);

        setLoading(false);
      }
    } else {
      setAccessLevel(response.data.access_level);
      let updatedLeadData = await checkAndCreateContact(response.data.lead);
      setLeadData(updatedLeadData);
      setLoading(false);
    }
  };

  useEffect(() => {
    getLeadDetailsApi({ leadID: lid });
  }, [refresh, lid]);

  useEffect(() => {
    analytics.triggerEvent(
      4625000036,
      "lead_details_page_load",
      "lead_details_page",
      "",
      {}
    );
    window.scrollTo(0, 0); //this will always load this page component scrolled to top
    setup();
    rootStore.authStore.resetLeadCallDetails();
  }, [lid]); // lead id dependency is added to reset the call log records when we switch the lead details page between leads

  //-----------below useEffect is specific to update the lead detail status
  useEffect(() => {
    authStore.updateLeadDetailStatus(lid); //this function is to update the current status of lead detail in store
  }, [lid]);

  useEffect(() => {
    if (refreshLeadDetails === true) {
      fetchLeadDetails();
    }
  }, [refreshLeadDetails]);

  // useEffect(() => {
  //   console.log("received props:::::", receivedProps);
  //   if (receivedProps !== null) {
  //     if (receivedProps["scope"] === "call_info_dialog_submit_log") {
  //       setOpenCallLogModal(true);
  //     }
  //   }
  // }, []);

  //**********Below useEffect to update hot lead state on page exit******* */
  useEffect(() => {
    return () => {
      rootStore.hotLeadState.checkAndUpdateHotLead({ leadID: lid });
    };
  }, []);
  //************************END******************************************* */
  const fetchLeadDetails = async () => {
    let response = await getLeadProfileApi({ leadID: lid });
    if (response.hasError()) {
      setLeadErrorMessage(response.errorMessage);
    } else {
      setAccessLevel(response.data.access_level);
      setLeadData(response.data.lead);
      setRefreshLeadDetails(false);
    }
  };

  const isStatusLocked = () => {
    let index = statusList.findIndex(
      (element) => element["status"] === leadData.lead_status
    );
    if (index !== -1) {
      return statusList[index]["lock"];
    } else {
      return false;
    }
  };

  const hasMoneyViewLeadID = () => {
    let customData = JSON.parse(leadData["custom_params"]);
    if (customData.hasOwnProperty("moneyview_lead_id")) {
      if (
        customData["moneyview_lead_id"] !== null &&
        customData["moneyview_lead_id"] !== ""
      ) {
        return true;
      }
    }
    return false;
  };

  const handleBreadCrump = () => {
    let scope = getRouteScope();
    if (scope === "leads_page") {
      navigate("/");
    } else if (scope === "filter_page") {
      navigate("/filter-page");
    } else {
      navigate(-1);
    }
  };

  const fetchLeadPhoneForWhatsapp = async () => {
    try {
      let response = await getPhoneApi(lid);
      let phone = formatPhoneNumberForWhatsApp(response.data);
      return phone;
    } catch (error) {
      console.log(error);
      return "";
    }
  };
  const canOpenCallInfoDialog = () => {
    let leadInfo = {};
    if (phoneCallState.callStatus.hasOwnProperty("lead")) {
      leadInfo = phoneCallState.callStatus["lead"];
    }
    if (
      leadInfo.hasOwnProperty("id") &&
      leadInfo["id"] !== null &&
      leadInfo["id"] !== ""
    ) {
      if (authStore.leadDetailStatus["lead_id"] !== leadInfo["id"]) {
        return true;
      }
    }
    return false;
  };

  const handleAddCallLog = () => {
    if (phoneCallState.hasPendingCallLog()) {
      if (canOpenCallInfoDialog()) {
        setOpenCallInfo(true);
        setCallInfoScope(callInfoSheetScope.lead_details_call_log);
      } else {
        setAddCallLogButton(true);
      }
    } else {
      setAddCallLogButton(true);
    }
  };

  const handleWhatsapp = async () => {
    let phone = await fetchLeadPhoneForWhatsapp();
    if (phone !== "") {
      window.open(`https://wa.me/${phone}`, "_blank");
    } else {
      window.open(`https://wa.me`, "_blank");
    }
  };

  const handleWhatsappMessage = async (message) => {
    let messageToSend = embedVariableValuesInMessageString({
      messageString: message,
      template_title: "Demo Confirmation",
      leadData: leadData,
    });

    const encodedTextData = encodeURIComponent(messageToSend);
    let phone = await fetchLeadPhoneForWhatsapp();
    if (phone !== "") {
      window.open(`https://wa.me/${phone}/?text=${encodedTextData}`, "_blank");
    } else {
      window.open(`https://wa.me/?text=${encodedTextData}`, "_blank");
    }
  };

  const fetchLeadPhone = async () => {
    try {
      let response = await getPhoneApi(lid);
      return response.data;
    } catch (error) {
      console.log(error);
      return "";
    }
  };

  const handlePhoneCall = async () => {
    let dialer = authStore.currentProject["external_dialer"].toLowerCase();
    switch (dialer) {
      case "skype":
        let leadPhone = await fetchLeadPhone();
        if (isMacOs) {
          window.open(`callto:${leadPhone}`, "_blank");
        } else {
          window.open(`skype:${leadPhone}?call`, "_blank");
        }

        setOpenConfirmation(false);
        break;
      case "ozonetel":
      case "mcube":
      case "teckinfo":
        let response = await phoneCallState.initiateCall(lid);
        setOpenConfirmation(false);
        if (response["status"] === "success") {
          setOpenCallInfo(true);
          setCallInfoScope(callInfoSheetScope.lead_details);
        } else {
          setErrorMessage(response.message);
          setOpenErrorDailog(true);
        }
        break;
      default:
        setErrorMessage(
          `${dialer} is not supported. Please contact the adminstrator.`
        );
        setOpenErrorDailog(true);
        break;
    }
  };

  const canShowCallIcon = () => {
    if (
      authStore.currentProject["external_dialer"] === "default" ||
      authStore.getCallingMode().toLowerCase() === "progressive"
    ) {
      return false;
    } else {
      return true;
    }
  };

  const isCallDisabled = () => {
    let userID = localStorage.getItem("uid");
    if (canEditLead()) {
      if (leadData["lead_owner"]["id"] === userID) {
        return false;
      }
    }
    return true;
  };

  const openGmailEnabled = () => {
    if (canEditLead()) {
      if (leadData.hasOwnProperty("email")) {
        if (leadData["email"] !== null && leadData["email"] !== "") {
          return true;
        }
      }
    }

    return false;
  };

  //**************functions to check whether to disable or enable actions in 3 dot menu *******/
  const isDeleteLeadDisabled = () => {
    if (checkFeaturePermission("lead_details_delete")) {
      if (canDestroyLead()) {
        return false;
      }
    }
    return true;
  };

  const isUpdateStatusDisabled = () => {
    if (canEditLead()) {
      return isStatusLocked();
    }
    return true;
  };

  const isTransferLeadIconVisible = () => {
    if (checkFeaturePermission("transfer_lead")) {
      if (canEditLead()) {
        return true;
      }
    }
    return false;
  };

  const isAssignLeadIconVisible = () => {
    if (checkFeaturePermission("assign_leads")) {
      if (canEditLead()) {
        return true;
      }
    }
    return false;
  };

  const isMoneyViewCreateLeadDisabled = () => {
    if (hasMoneyViewLeadID()) {
      return true;
    } else {
      if (canEditLead()) {
        return false;
      } else {
        return true;
      }
    }
  };

  const isMoneyViewRefreshOffersDisabled = () => {
    if (hasMoneyViewLeadID()) {
      if (canEditLead()) {
        return false;
      }
    }
    return true;
  };

  const isMoneyViewRefreshJourneyURLDisabled = () => {
    if (hasMoneyViewLeadID()) {
      if (canEditLead()) {
        return false;
      }
    }
    return true;
  };
  const getCallStatusLeadID = () => {
    /*
    this func is being used, because on submit call log, we are resetting call status
    So if we directly pass callstatus lead id, it will result in white screen
    */
    if (phoneCallState.callStatus.hasOwnProperty("lead")) {
      return phoneCallState.callStatus["lead"]["id"];
    } else {
      return "";
    }
  };

  /*********************END****************** */

  const handleAdvanceCopyButtonTap = () => {
    let columnsIDsByGroup = {};
    Object.keys(groupsToColumns).forEach((key) => {
      let columnsIds = [...groupsToColumns[key]];
      columnsIds.sort((id1, id2) => {
        const id1index = columnsList.findIndex((element) => element.id === id1);
        const id2index = columnsList.findIndex((element) => element.id === id2);
        const headerName1 = columnsList[id1index].headerName;
        const headerName2 = columnsList[id2index].headerName;
        return headerName1.localeCompare(headerName2);
      });
      columnsIDsByGroup[key] = columnsIds;
    });
    setSortedColumnIdList(columnsIDsByGroup);
    setOpenAdvancedCopyPanel(true);
  };

  //***********************************Mark unmark related functions */

  const handleMarkUnMarkLeadAsFreshLead = async (scope) => {
    setShowBackDrop(true);
    let payload = { status: scope === "mark" ? true : false };
    let response = await markUnmarkLeadAsFreshLeadApi({
      leadID: lid,
      payload: payload,
    });
    if (response.hasError()) {
      setShowBackDrop(false);
      setMarkLeadErrorMessage(response.errorMessage);
      setShowErrorDialog(true);
    } else {
      setShowBackDrop(false);
      setShowErrorDialog(false);
      setRefreshLeadDetails(true);
    }
  };

  const isMarkUnMarkFreshLeadMenuVisible = () => {
    if (checkFeaturePermission("fresh_lead")) {
      if (canEditLead()) {
        return true;
      }
    }
    return false;
  };

  //***************************END******************* */

  //*****************functions related to lead priority************* */

  const handleMarkUnMarkLeadAsPriority = async (scope) => {
    if (scope === "mark") {
      setOpenPriorityLeadDialog(true);
    } else {
      setShowBackDrop(true);
      let payload = { status: false };
      let response = await markUnmarkLeadAsPriorityApi({
        leadID: lid,
        payload: payload,
      });
      if (response.hasError()) {
        setShowBackDrop(false);
        setMarkLeadErrorMessage(response.errorMessage);
        setShowErrorDialog(true);
      } else {
        setShowBackDrop(false);
        setShowErrorDialog(false);
        setRefreshLeadDetails(true);
      }
    }
  };

  const isToday = (dateTimeString) => {
    const dateTime = moment(dateTimeString);
    return dateTime.isSame(moment(), "day");
  };

  const RenderMarkUnMarkPriorityLeadMenu = () => {
    if (
      leadData.hasOwnProperty("hard_update_by") &&
      leadData["hard_update_by"] !== null &&
      leadData["hard_update_by"] !== ""
    ) {
      if (isToday(leadData["hard_update_by"])) {
        if (leadData["priority_lead"] === true) {
          return (
            <MenuItem
              onClick={() => {
                handleClose(0);
                handleMarkUnMarkLeadAsPriority("unmark");
              }}
            >
              <Icon src={unmark_priority_icon} alt="loading.." />
              Mark as Regular Lead
            </MenuItem>
          );
        }
      }
    }
    return (
      <MenuItem
        onClick={() => {
          handleClose(0);
          handleMarkUnMarkLeadAsPriority("mark");
        }}
      >
        <Icon src={mark_priority_icon} alt="loading.." />
        Mark as Priority Lead
      </MenuItem>
    );
  };

  //****************END************ */

  const RenderMarkUnMarkFreshLeadMenu = () => {
    if (leadData["fresh_lead"] === true) {
      return (
        <MenuItem
          onClick={() => {
            handleClose(0);
            handleMarkUnMarkLeadAsFreshLead("unmark");
          }}
        >
          <Icon src={unmark_icon} alt="loading.." />
          Unmark Fresh Lead
        </MenuItem>
      );
    } else {
      return (
        <MenuItem
          onClick={() => {
            handleClose(0);
            handleMarkUnMarkLeadAsFreshLead("mark");
          }}
        >
          <Icon src={mark_icon} alt="loading.." />
          Mark as Fresh Lead
        </MenuItem>
      );
    }
  };

  //***********************Create contact function if contact is not created ***************************** */
  const checkAndCreateContact = async (details) => {
    if (details["contact_id"] === null || details["contact_id"] === "") {
      let customData = details.hasOwnProperty("custom_params")
        ? JSON.parse(details["custom_params"])
        : {};
      let contactData = {};
      let contactColumns = [...rootStore.contactStore.columnsList];

      contactColumns = contactColumns.filter(
        (columnData) =>
          columnData["show_in_create_form"] &&
          columnData["is_visible"] &&
          columnData["required"]
      );

      let leadsColumns = [...columnsList];

      for (let i = 0; i < contactColumns.length; i++) {
        let column = contactColumns[i];
        let index = leadsColumns.findIndex(
          (element) => element["contact_column"] === column["column_name"]
        );

        if (index !== -1) {
          let leadField = leadsColumns[index]["field"];

          if (leadsColumns[index]["is_custom_param"]) {
            let newField = leadField.replace("custom_params_", "");

            if (customData.hasOwnProperty(newField)) {
              contactData[column["column_name"]] = customData[newField];
            }
          } else {
            if (details.hasOwnProperty(leadField)) {
              if (leadField === "phone") {
                let response = await getPhoneApi(lid);
                contactData[column["column_name"]] = response.data;
              } else {
                contactData[column["column_name"]] = details[leadField];
              }
            }
          }
        } else {
          contactData[column["column_name"]] = null;
        }
      }
      let response = await createContactApi({ payload: contactData });
      if (response.hasError()) {
        if (
          response.data.hasOwnProperty("code") &&
          response.data["code"] === "1029"
        ) {
          if (contactData.hasOwnProperty("phone")) {
            let search_response = await searchContactByPhoneApi({
              payload: {
                phone: contactData["phone"],
              },
            });
            if (search_response.length > 0) {
              let searchedContactData = search_response[0];
              let link_contact_response = await linkContactApi({
                projectID: rootStore.authStore.projectId,
                teamID:
                  rootStore.authStore.teamId !== null
                    ? rootStore.authStore.teamId !== "All"
                      ? rootStore.authStore.teamId
                      : ""
                    : null,
                leadID: lid,
                payload: {
                  contact_id: searchedContactData["id"],
                },
              });
              details["contact_id"] = searchedContactData["id"];
            }
          }
        }
      } else {
        if (response.hasOwnProperty("id")) {
          let link_contact_response = await linkContactApi({
            projectID: rootStore.authStore.projectId,
            teamID:
              rootStore.authStore.teamId !== null
                ? rootStore.authStore.teamId !== "All"
                  ? rootStore.authStore.teamId
                  : ""
                : null,
            leadID: lid,
            payload: {
              contact_id: response["id"],
            },
          });
          details["contact_id"] = response["id"];
        }
      }
    }

    return details;
  };
  //**************************************END************************************************************** */

  const getFinalStageStepInStepper = () => {
    let index = stagesList.findIndex(
      (stage) =>
        stage["stage"].toLowerCase() === leadData["lead_stage"].toLowerCase()
    );
    if (index !== -1) {
      let stageData = stagesList[index];
      if (
        stageData["mark_as_closed"] ||
        stageData["mark_as_won"] ||
        stageData["mark_as_lost"]
      ) {
        return stageData;
      }
    }
    return { stage: "Won" };
  };

  // sorting stage list getter function for stageStepper///////////////////
  const getSortedStageListByRank = () => {
    let stageList = [...rootStore.authStore.stageList];
    stageList = stageList.filter((x) => x.rank != null);

    const currentLeadStage = leadData["lead_stage"];

    // Sort the list based on the "rank" property in ascending order
    stageList.sort((a, b) => a.rank - b.rank);

    // Find the index of the current lead_stage object
    const currentIndex = stageList.findIndex(
      (item) => item.stage === currentLeadStage
    );

    // If the current lead_stage object is found, remove it from the list
    if (currentIndex !== -1) {
      const currentLeadStageObject = stageList.splice(currentIndex, 1)[0];

      // Iterate through the sorted list and find the index where the current lead_stage object should be inserted
      const insertIndex = stageList.findIndex(
        (item) => item.rank > currentLeadStageObject.rank
      );

      // If no such index is found, add the current lead_stage object to the end of the list
      if (insertIndex === -1) {
        stageList.push(currentLeadStageObject);
      } else {
        // Otherwise, insert the current lead_stage object at the appropriate position
        stageList.splice(insertIndex, 0, currentLeadStageObject);
      }
    }
    stageList = stageList.filter(
      (stage) =>
        !stage["mark_as_closed"] &&
        !stage["mark_as_won"] &&
        !stage["mark_as_lost"]
    );
    let finalStep = getFinalStageStepInStepper();
    stageList.push(finalStep);
    return stageList;
  };
  //***************************END********************** */

  const canShowUpdateStatus = () => {
    if (validateDisabledSubFeature("update_status") === false) {
      return false;
    } else {
      let projectRole = authStore.getCurrentProjectRole();
      if (projectRole.toLowerCase() === "member") {
        if (validateDisabledSubFeature("update_status_members") === false) {
          return false;
        }
      }
      return true;
    }
  };

  const canShowActivitiesTab = () => {
    if (validateDisabledFeature("activities") === false) {
      return false;
    } else {
      let projectRole = authStore.getCurrentProjectRole();
      if (projectRole.toLowerCase() === "member") {
        if (validateDisabledFeature("activities_members") === false) {
          return false;
        }
      } else if (projectRole.toLowerCase() === "lead") {
        if (validateDisabledFeature("activities_leads") === false) {
          return false;
        }
      } else if (projectRole.toLowerCase() === "manager") {
        if (validateDisabledFeature("activities_managers") === false) {
          return false;
        }
      }
      return true;
    }
  };

  const canShowUpdateSource = () => {
    if (validateDisabledSubFeature("update_source") === false) {
      return false;
    } else {
      let projectRole = authStore.getCurrentProjectRole();
      if (projectRole.toLowerCase() === "member") {
        if (validateDisabledSubFeature("update_source_members") === false) {
          return false;
        }
      }
      return true;
    }
  };
  const canShowUpdateStage = () => {
    if (validateDisabledSubFeature("update_stage") === false) {
      return false;
    } else {
      let projectRole = authStore.getCurrentProjectRole();
      if (projectRole.toLowerCase() === "member") {
        if (validateDisabledSubFeature("update_stage_members") === false) {
          return false;
        }
      } else if (projectRole.toLowerCase() === "lead") {
        if (validateDisabledSubFeature("update_stage_leads") === false) {
          return false;
        }
      } else if (projectRole.toLowerCase() === "manager") {
        if (validateDisabledSubFeature("update_stage_managers") === false) {
          return false;
        }
      }
      return true;
    }
  };

  const canShowSubmitLead = () => {
    let index = stageStatusList.findIndex(
      (item) =>
        item["lead_stage"]["stage"] === leadData["lead_stage"] &&
        item["lead_status"]["status"] === leadData["lead_status"]
    );
    if (index !== -1) {
      let data = stageStatusList[index];
      return data["submit_enabled"];
    }
    return false;
  };
  function isLeadLocked() {
    if (_leadLockedOverriden) {
      return false;
    } else {
      return leadData["lead_closed"];
    }
  }

  const canShowLeadUnlockMenu = () => {
    if (isLeadLocked()) {
      let account_role =
        userStore.UserData !== null ? userStore.UserData["role"] : "";
      if (account_role === "owner") {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {}, [_leadLockedOverriden]); //this useeffect is called to rerender the page on leadlocked state change

  //*******************functions related delete lead*********************************** */
  const isStageDeleteProtected = () => {
    let index = stagesList.findIndex(
      (stage) => stage["stage"] === leadData["lead_stage"]
    );
    if (index !== -1) {
      return stagesList[index]["delete_protected"];
    }
    return false;
  };
  const isStatusDeleteProtected = () => {
    let index = statusList.findIndex(
      (status) => status["status"] === leadData["lead_status"]
    );
    if (index !== -1) {
      return statusList[index]["delete_protected"];
    }
    return false;
  };

  const isDeleteProtected = () => {
    return isStageDeleteProtected() || isStatusDeleteProtected();
  };

  const deleteLead = async () => {
    setShowConfirmDeleteDialog(false);
    setIsDeleting(true);
    setShowResponseDialog(true);
    let response = await deleteLeadApi({ leadID: lid });
    setIsDeleting(false);
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setIsSubmitFail(false);
      navigate(-1);
    }
  };
  const handleDelete = async () => {
    setShowConfirmDeleteDialog(true);
  };

  //*****************************END********************************************* */

  const isLeadFormDisabled = () => {
    if (canEditLead()) {
      return false;
    } else {
      return true;
    }
  };

  const isLeadFromCurrentProjectAndAccount = () => {
    if (
      leadData.hasOwnProperty("account_id") &&
      leadData.hasOwnProperty("project_id")
    ) {
      return isProjectAndAccountIdMatched({
        accountID: leadData["account_id"],
        projectID: leadData["project_id"],
      });
    }
    return false;
  };

  const isAddCustomDealDisabled = () => {
    if (validateDisabledSubFeature("custom_deals")) {
      if (!hasActiveDeal) {
        return canEditLead() === false;
      }
    }
    return true;
  };

  const isAddPredefinedDealDisabled = () => {
    if (!hasActiveDeal) {
      return canEditLead() === false;
    }

    return true;
  };

  const isDeactivateDealDisabled = () => {
    if (hasActiveDeal) {
      return canEditLead() === false;
    }
    return true;
  };
  const isSetLeadColorDisabled = () => {
    if (canEditLead()) {
      return false;
    } else {
      return true;
    }
  };

  //***************************** Lead details navigation related code ***********************/
  const isNextButtonDisabled = () => {
    let scope = getRouteScope();
    if (scope === "leads_page") {
      return rootStore.leadStore.hasNextLead(lid);
    } else if (scope === "filter_page") {
      return rootStore.filterPageState.hasNextLead(lid);
    }
    return true;
  };

  const isPrevButtonDisabled = () => {
    let scope = getRouteScope();

    if (scope === "leads_page") {
      return rootStore.leadStore.hasPrevLead(lid);
    } else if (scope === "filter_page") {
      return rootStore.filterPageState.hasPrevLead(lid);
    }
    return true;
  };

  const handlePrev = async () => {
    setIsNavigating(true);
    let newLeadID = "";
    let scope = getRouteScope();
    if (scope === "leads_page") {
      newLeadID = await rootStore.leadStore.getPrevLeadID(lid);
    } else if (scope === "filter_page") {
      newLeadID = await rootStore.filterPageState.getPrevLeadID(lid);
    }
    if (newLeadID !== "") {
      navigate(`/users/${uid}/leads/${newLeadID}`, {
        state: receivedProps,
      });
    } else {
      setShowNavigationFailedDialog(true);
    }
    setIsNavigating(false);
  };

  const handleNext = async () => {
    setIsNavigating(true);
    let newLeadID = "";
    let scope = getRouteScope();
    if (scope === "leads_page") {
      newLeadID = await rootStore.leadStore.getNextLeadID(lid);
    } else if (scope === "filter_page") {
      newLeadID = await rootStore.filterPageState.getNextLeadID(lid);
    }
    if (newLeadID !== "") {
      navigate(`/users/${uid}/leads/${newLeadID}`, {
        state: receivedProps,
      });
    } else {
      setShowNavigationFailedDialog(true);
    }
    setIsNavigating(false);
  };

  const canShowLeadNavigation = () => {
    if (validateDisabledSubFeature("lead_details_navigation")) {
      let scope = getRouteScope();
      if (scope === "leads_page") {
        return rootStore.leadStore.leadsForCurrentTab.length > 0;
      } else if (scope === "filter_page") {
        return rootStore.filterPageState.filterLeadList.length > 0;
      }
    }
    return false;
  };

  //*********************************END*********************************** */

  const getRouteScope = () => {
    if (receivedProps !== null && receivedProps.hasOwnProperty("scope")) {
      if (receivedProps["scope"] !== null) {
        return receivedProps["scope"];
      }
    }
    return "";
  };

  const canShowCreateTaskMenuButton = () => {
    if (rootStore.subscriptionState.isFeatureAvailableWithAddOns("Tasks")) {
      return filterStore.detailTabValue === "Tasks";
    }
    return false;
  };

  const getFilteredWhatsappTemplatesByStage = () => {
    //filtering message templates based on current stage
    let index = stagesList.findIndex(
      (stage) =>
        stage["stage"].toLowerCase() === leadData["lead_stage"].toLowerCase()
    );
    let filteredTemplateList = [];
    let currentStageID = index !== -1 ? stagesList[index]["id"] : "";

    //filter templates without whatsapp_provider (TEMPORARY)
    let filteredTemplates = messageTemplates.filter(
      (item) =>
        item.whatsapp_provider === null
    );
    //------------------------------------------------------------

    for (const item of filteredTemplates) {
      if (item["project"] === null) {
        filteredTemplateList.push(item);
      } else if (item["enable_for_stages"] === null) {
        filteredTemplateList.push(item);
      } else {
        if (item["enable_for_stages"].length === 0) {
          filteredTemplateList.push(item);
        } else if (item["enable_for_stages"].includes(currentStageID)) {
          filteredTemplateList.push(item);
        }
      }
    }

    return filteredTemplateList;
  };

  return (
    <>
      {!loading ? (
        Object.keys(leadData).length > 0 ? (
          isLeadFromCurrentProjectAndAccount() ? (
            canReadLead() ? (
              <ParentContainer>
                <Container>
                  <Grid container>
                    <Grid item xs={12}>
                      <TopWrapper>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                height: "100%",
                                gap: "4px",
                              }}
                            >
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Text
                                  style={{
                                    color: "#185DD2",
                                    cursor: "pointer",
                                  }}
                                  onClick={handleBreadCrump}
                                >
                                  {getLocalizedText("leads", "project")}
                                </Text>
                                <ChevronRight
                                  style={{ width: "16px", height: "16px" }}
                                />
                                <Text>{lid}</Text>
                              </Box>
                              {leadData["lead_closed"] && (
                                <Tooltip
                                  title={"Unlock Lead"}
                                  placement="bottom-start"
                                >
                                  <IconButton
                                    disabled={!canShowLeadUnlockMenu()}
                                    onClick={() => {
                                      authStore.updateLastActiveTime();
                                      setOpenUnlockLeadConfirmDialog(true);
                                      handleClose(0);
                                    }}
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  >
                                    {unlockedTemporarily ? (
                                      <LockOpen
                                        style={{
                                          color: "#21589F",
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      />
                                    ) : (
                                      <Lock
                                        style={{
                                          color: "#21589F",
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      />
                                    )}
                                  </IconButton>
                                </Tooltip>
                              )}
                              {canShowLeadNavigation() && (
                                <LeadDetailsNavigationComponent
                                  handleNext={handleNext}
                                  handlePrev={handlePrev}
                                  isNextButtonDisabled={isNextButtonDisabled()}
                                  isPrevButtonDisabled={isPrevButtonDisabled()}
                                />
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </TopWrapper>
                    </Grid>
                    <Grid
                      item
                      spacing={2}
                      container
                      alignItems="center"
                      style={{ marginBottom: "16px" }}
                    >
                      <Grid item xs={12}>
                        <StageStepperComponentv2
                          leadData={leadData}
                          stageList={getSortedStageListByRank()}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      spacing={2}
                      justifyContent="space-between"
                      wrap="nowrap"
                    >
                      <Grid item>
                        <Tabs
                          sx={{
                            [`& .${tabsClasses.scrollButtons}`]: {
                              "&.Mui-disabled": { opacity: 0.3 },
                            },
                          }}
                          variant="scrollable"
                          value={filterStore.detailTabValue}
                          textColor="primary"
                          indicatorColor="primary"
                          scrollButtons={"auto"}
                          onChange={(event, newValue) => {
                            analytics.triggerEvent(
                              4625000037,
                              "lead_details_tab_tap",
                              "lead_details_page",
                              "lead_details_tab",
                              { order: newValue }
                            );
                            authStore.updateLastActiveTime();
                            filterStore.updateDetailTabValue(newValue);
                          }}
                        >
                          {tabs.length > 0 &&
                            tabs.map((tab_name) => {
                              return (
                                <Tab
                                  sx={{
                                    "&.Mui-selected": {
                                      backgroundColor: "#E7EEFA",
                                      color: "#4079DA",
                                      font: "normal normal 600 14px Open Sans",
                                      textTransform: "capitalize",
                                    },

                                    backgroundColor: "white",
                                    color: "#4D4E4F",
                                    font: "normal normal 600 14px Open Sans",
                                    textTransform: "capitalize",
                                  }}
                                  value={tab_name}
                                  label={tab_name}
                                />
                              );
                            })}
                        </Tabs>
                      </Grid>
                      <Grid item>
                        <ButtonWrapper>
                          {canShowCallIcon() && (
                            <IconButton
                              disabled={isCallDisabled()}
                              color="primary"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                analytics.triggerEvent(
                                  4625000048,
                                  "call_button_tap",
                                  "lead_details_page",
                                  "call_button",
                                  {}
                                );
                                authStore.updateLastActiveTime();
                                if (phoneCallState.hasPendingCallLog()) {
                                  setOpenCallInfo(true);
                                  setCallInfoScope(
                                    callInfoSheetScope.lead_details_call_icon
                                  );
                                } else {
                                  setOpenConfirmation(true);
                                }
                              }}
                            >
                              <Call />
                            </IconButton>
                          )}
                          {isTransferLeadIconVisible() && (
                            <IconButton
                              style={{
                                cursor: "pointer",
                              }}
                              color="primary"
                              onClick={() => {
                                analytics.triggerEvent(
                                  4625000084,
                                  "transfer_lead_button_tap",
                                  "lead_details_page",
                                  "transfer_lead_button",
                                  {}
                                );
                                authStore.updateLastActiveTime();
                                setTransferOpen(true);
                              }}
                            >
                              <IosShareIcon />
                            </IconButton>
                          )}
                          {isAssignLeadIconVisible() && (
                            <IconButton
                              disabled={!canEditLead()}
                              style={{
                                cursor: "pointer",
                              }}
                              color="primary"
                              onClick={() => {
                                analytics.triggerEvent(
                                  4625000038,
                                  "assign_lead_button_tap",
                                  "lead_details_page",
                                  "assign_lead_button",
                                  {}
                                );
                                authStore.updateLastActiveTime();
                                setOpen(true);
                              }}
                            >
                              <AssignmentOutlined />
                            </IconButton>
                          )}
                          <Box>
                            <StickyIconsSidebar
                              canEditLead={canEditLead}
                              isUpdateStatusDisabled={isUpdateStatusDisabled}
                              setWhatsAppContextAnchor={
                                setWhatsAppContextAnchor
                              }
                              handleWhatsapp={handleWhatsapp}
                              handleAddCallLog={handleAddCallLog}
                              messageTemplates={getFilteredWhatsappTemplatesByStage()}
                            />
                          </Box>
                          {/* <IconButton
                            disabled={!canEditLead()}
                            style={{
                              cursor: "pointer",
                            }}
                            color="primary"
                            onClick={(e) => {
                              analytics.triggerEvent(
                                4625000039,
                                "whatsapp_button_tap",
                                "lead_details_page",
                                "whatsapp_button",
                                {}
                              );
                              authStore.updateLastActiveTime();
                              if (messageTemplates.length > 0) {
                                setWhatsAppContextAnchor(e.currentTarget);
                              } else {
                                handleWhatsapp();
                              }
                            }}
                          >
                            <WhatsApp
                              style={{
                                color: "#25D267",
                              }}
                            />
                          </IconButton> */}
                          <IconButton
                            onClick={(event) => handleMenuClick(event, 0)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            style={{ display: "flex", flexDirection: "column" }}
                            id="long-menu"
                            MenuListProps={{
                              "aria-labelledby": "long-button",
                            }}
                            anchorEl={anchorEl[0]}
                            open={Boolean(anchorEl[0])}
                            onClose={() => handleClose(0)}
                            PaperProps={{
                              style: {
                                // maxHeight: ITEM_HEIGHT * 4.5,
                                width: "fit-content",
                              },
                            }}
                          >
                            {/* {filterStore.detailTabValue === "Deal" && (
                              <RenderDealTabMenus />
                            )} */}

                            <MenuItem
                              disabled={
                                !rootStore.userStore.hasAnyGroupCopyable()
                              }
                              onClick={() => {
                                handleAdvanceCopyButtonTap();
                                handleClose(0);
                              }}
                            >
                              <Icon src={copy_icon} alt="loading.." />
                              Advance Copy
                            </MenuItem>
                            {validateDisabledSubFeature("clone_lead") && (
                              <MenuItem
                                onClick={() => {
                                  setOpenClone(true);
                                  handleClose(0);
                                }}
                              >
                                <Icon src={clone_icon} alt="loading.." />
                                Clone Lead
                              </MenuItem>
                            )}
                            {filterStore.detailTabValue === "Quotes" && (
                              <>
                                <MenuItem
                                  onClick={() => {
                                    authStore.updateLastActiveTime();
                                    setOpenAddQuotePanel(true);
                                    setDealMenuAnchor(null);
                                    handleClose(0);
                                  }}
                                  disabled={canEditLead() === false}
                                >
                                  <Icon
                                    src={create_quote_icon}
                                    alt="loading.."
                                  />
                                  Create Quote
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    authStore.updateLastActiveTime();
                                    setOpenAddInvoicePanel(true);
                                    setDealMenuAnchor(null);
                                    handleClose(0);
                                  }}
                                  disabled={canEditLead() === false}
                                >
                                  <Icon
                                    src={create_quote_icon}
                                    alt="loading.."
                                  />
                                  Create Invoice
                                </MenuItem>
                              </>
                            )}

                            {canShowCreateTaskMenuButton() && (
                              <MenuItem
                                disabled={!canEditLead()}
                                onClick={() => {
                                  authStore.updateLastActiveTime();
                                  setOpenCreateTaskPanel(true);
                                  handleClose(0);
                                }}
                              >
                                <Icon src={task_icon} alt="loading.." />
                                Create Task
                              </MenuItem>
                            )}

                            {/* deal menu */}
                            {filterStore.detailTabValue === "Deal" && (
                              <MenuItem
                                onClick={(event) => {
                                  setDealMenuAnchor(event.currentTarget);
                                }}
                              >
                                <ArrowLeft />
                                Deal
                              </MenuItem>
                            )}
                            <Menu
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                              id="long-menu"
                              MenuListProps={{
                                "aria-labelledby": "long-button",
                              }}
                              anchorEl={dealMenuAnchor}
                              open={Boolean(dealMenuAnchor)}
                              onClose={() => setDealMenuAnchor(null)}
                              PaperProps={{}}
                              transformOrigin={{
                                horizontal: "right",
                                vertical: "top",
                              }}
                              anchorOrigin={{
                                horizontal: "left",
                                vertical: "top",
                              }}
                            >
                              <MenuItem
                                disabled={isAddCustomDealDisabled()}
                                onClick={() => {
                                  setOpenAddDealPanel(true);
                                  setDealMenuAnchor(null);
                                  handleClose(0);
                                }}
                              >
                                <Icon src={add_deal_icon} alt="loading.." />
                                Add a Deal
                              </MenuItem>
                              <MenuItem
                                disabled={isAddPredefinedDealDisabled()}
                                onClick={() => {
                                  setOpenAddPredefinedDealPanel(true);
                                  setDealMenuAnchor(null);
                                  handleClose(0);
                                }}
                              >
                                <Icon
                                  src={add_predefined_deal_icon}
                                  alt="loading.."
                                />
                                Add Predefined Deal
                              </MenuItem>
                              <MenuItem
                                disabled={isDeactivateDealDisabled()}
                                onClick={() => {
                                  setOpenDeactivateDealDialog(true);
                                  setDealMenuAnchor(null);
                                  handleClose(0);
                                }}
                              >
                                <Icon
                                  src={deactivate_deal_icon}
                                  alt="loading.."
                                />
                                Deactivate Current Deal
                              </MenuItem>
                            </Menu>

                            {/* end */}

                            <MenuItem
                              onClick={() => {
                                analytics.triggerEvent(
                                  4625000040,
                                  "delete_lead_button_tap",
                                  "lead_details_page",
                                  "delete_lead_button",
                                  {}
                                );
                                authStore.updateLastActiveTime();
                                handleDelete();
                                handleClose(0);
                              }}
                              disabled={isDeleteLeadDisabled()}
                            >
                              <Icon src={delete_icon} alt="loading.." />
                              Delete
                            </MenuItem>
                            {canEditLead() && (
                              <MenuItem
                                onClick={() => {
                                  setOpenEditLeadPanel(true);
                                  handleClose(0);
                                }}
                              >
                                <Icon src={edit_lead_icon} alt="loading.." />
                                Edit Lead
                              </MenuItem>
                            )}

                            {/* {isMarkUnMarkFreshLeadMenuVisible() && (
                              <RenderMarkUnMarkFreshLeadMenu />
                            )} */}
                            {canEditLead() && (
                              <RenderMarkUnMarkPriorityLeadMenu />
                            )}
                            <MenuItem
                              disabled={!openGmailEnabled()}
                              onClick={() => {
                                // analytics.triggerEvent(
                                //   4625000039,
                                //   "whatsapp_button_tap",
                                //   "lead_details_page",
                                //   "whatsapp_button",
                                //   {}
                                // );
                                authStore.updateLastActiveTime();

                                const sender = encodeURIComponent(
                                  leadData["email"]
                                );
                                //   const url = `https://mail.google.com/mail/?view=cm&fs=1&su=${subject}&to=${sender}&body=${body}`;
                                const url = `https://mail.google.com/mail/?view=cm&fs=1&to=${sender}`;
                                window.open(url, "_blank");
                                handleClose(0);
                              }}
                            >
                              <Icon src={email_icon} alt="loading.." />
                              Open Gmail
                            </MenuItem>
                            <MenuItem
                              disabled={isSetLeadColorDisabled()}
                              onClick={() => {
                                authStore.updateLastActiveTime();
                                setOpenSetLeadColorDialog(true);
                                handleClose(0);
                              }}
                            >
                              <Icon src={update_color_icon} alt="loading.." />
                              Set Lead Color
                            </MenuItem>

                            {canShowSubmitLead() && (
                              <MenuItem
                                disabled={!canEditLead()}
                                onClick={() => {
                                  authStore.updateLastActiveTime();
                                  setOpenSubmitLeadModal(true);
                                  handleClose(0);
                                }}
                              >
                                <Icon src={submit_ops_icon} alt="loading.." />
                                Submit Lead
                              </MenuItem>
                            )}
                            {canShowUpdateSource() && (
                              <MenuItem
                                onClick={() => {
                                  analytics.triggerEvent(
                                    4625000041,
                                    "update_source_button_tap",
                                    "lead_details_page",
                                    "update_source_button",
                                    {}
                                  );
                                  authStore.updateLastActiveTime();
                                  setOpenSourceModal(true);
                                  handleClose(0);
                                }}
                                disabled={!canEditLead()}
                              >
                                <Icon
                                  src={update_source_icon}
                                  alt="loading.."
                                />
                                Update Source
                              </MenuItem>
                            )}
                            {canShowUpdateStage() && (
                              <MenuItem
                                disabled={!canEditLead()}
                                onClick={() => {
                                  authStore.updateLastActiveTime();
                                  setOpenUpdateStageModal(true);
                                  handleClose(0);
                                }}
                              >
                                <Icon src={Update_stage_icon} alt="loading.." />
                                Update Stage
                              </MenuItem>
                            )}
                            {canShowUpdateStatus() && (
                              <MenuItem
                                disabled={isUpdateStatusDisabled()}
                                onClick={() => {
                                  analytics.triggerEvent(
                                    4625000042,
                                    "update_status_button_tap",
                                    "lead_details_page",
                                    "update_status_button",
                                    {}
                                  );
                                  authStore.updateLastActiveTime();
                                  setOpenStatusModal(true);
                                  handleClose(0);
                                }}
                              >
                                <Icon src={update_icon} alt="loading.." />
                                Update Status
                              </MenuItem>
                            )}
                            {authStore.hasDigitalLender("moneyview") && (
                              <>
                                <MenuItem
                                  onClick={(event) => {
                                    analytics.triggerEvent(
                                      4625000043,
                                      "moneyview_button_tap",
                                      "lead_details_page",
                                      "moneyview_button",
                                      {}
                                    );
                                    handleMenuClick(event, 1);
                                  }}
                                >
                                  <ArrowLeft />
                                  Money View
                                </MenuItem>
                                <Menu
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                  id="long-menu"
                                  MenuListProps={{
                                    "aria-labelledby": "long-button",
                                  }}
                                  anchorEl={anchorEl[1]}
                                  open={Boolean(anchorEl[1])}
                                  onClose={() => handleClose(1)}
                                  PaperProps={{}}
                                  transformOrigin={{
                                    horizontal: "right",
                                    vertical: "top",
                                  }}
                                  anchorOrigin={{
                                    horizontal: "left",
                                    vertical: "top",
                                  }}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      analytics.triggerEvent(
                                        4625000044,
                                        "create_lead_button_tap",
                                        "lead_details_page",
                                        "moneyview_create_lead_button",
                                        {}
                                      );
                                      authStore.updateLastActiveTime();
                                      setOpenCreateLeadModal(true);
                                      setAnchorEl({});
                                    }}
                                    disabled={isMoneyViewCreateLeadDisabled()}
                                  >
                                    <PersonAddAlt
                                      color="primary"
                                      style={{ marginRight: "6px" }}
                                    />
                                    Create Lead
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      analytics.triggerEvent(
                                        4625000045,
                                        "refresh_offers_button_tap",
                                        "lead_details_page",
                                        "moneyview_refresh_offers_button",
                                        {}
                                      );
                                      authStore.updateLastActiveTime();
                                      setOpenRefreshOfferDailog(true);
                                      handleClose(1);
                                    }}
                                    disabled={isMoneyViewRefreshOffersDisabled()}
                                  >
                                    <RefreshIcon
                                      color="primary"
                                      style={{ marginRight: "6px" }}
                                    />
                                    Refresh Offers
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      analytics.triggerEvent(
                                        4625000046,
                                        "refresh_journey_url_button_tap",
                                        "lead_details_page",
                                        "moneyview_refresh_journey_url_button",
                                        {}
                                      );
                                      authStore.updateLastActiveTime();
                                      setOpenRefreshJourneyDailog(true);
                                      handleClose(1);
                                    }}
                                    disabled={isMoneyViewRefreshJourneyURLDisabled()}
                                  >
                                    <RefreshIcon
                                      color="primary"
                                      style={{ marginRight: "6px" }}
                                    />
                                    Refresh Journey URL
                                  </MenuItem>
                                  <MenuItem
                                    disabled={!canEditLead()}
                                    onClick={() => {
                                      analytics.triggerEvent(
                                        4625000047,
                                        "send_journey_url_button_tap",
                                        "lead_details_page",
                                        "moneyview_send_journey_url_button",
                                        {}
                                      );
                                    }}
                                  >
                                    <Link
                                      color="primary"
                                      style={{ marginRight: "6px" }}
                                    />
                                    Send Journey URL
                                  </MenuItem>
                                </Menu>
                              </>
                            )}
                          </Menu>
                        </ButtonWrapper>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Wrapper>
                        {(() => {
                          if (filterStore.detailTabValue === "Activities") {
                            return <UserActivityTab uid={uid} lid={lid} />;
                          } else if (
                            filterStore.detailTabValue ===
                            rootStore.authStore.leadForms["title"]
                          ) {
                            return (
                              <LeadFormTab
                                isLeadFormDisabled={isLeadFormDisabled()}
                                leadData={leadData}
                                leadID={lid}
                                setRefreshLeadDetails={setRefreshLeadDetails}
                              />
                            );
                          } else if (
                            filterStore.detailTabValue === "Interactions"
                          ) {
                            return (
                              <InteractionsTab
                                lid={lid}
                                uid={uid}
                                statusList={statusList}
                                setRefreshLeadDetails={setRefreshLeadDetails}
                                isUpdateStatusDisabled={isUpdateStatusDisabled}
                                leadData={leadData}
                                canEditLead={canEditLead}
                              />
                            );
                          } else if (filterStore.detailTabValue === "Files") {
                            return (
                              <UserFileTab
                                lid={lid}
                                uid={uid}
                                canEditLead={canEditLead}
                                leadData={leadData}
                              />
                            );
                          } else if (
                            filterStore.detailTabValue === "Check-Ins"
                          ) {
                            return <UserCheckInTab lid={lid} uid={uid} />;
                          } else if (filterStore.detailTabValue === "Notes") {
                            return (
                              <NotesTab
                                lid={lid}
                                uid={uid}
                                canEditLead={canEditLead}
                              />
                            );
                          } else if (filterStore.detailTabValue === "Deal") {
                            return (
                              <DealsTab
                                leadID={lid}
                                leadData={leadData}
                                uid={uid}
                                canEditLead={canEditLead}
                                setHasActiveDeal={setHasActiveDeal}
                                openAddDealPanel={openAddDealPanel}
                                setOpenAddDealPanel={setOpenAddDealPanel}
                                openAddPredefinedDealPanel={
                                  openAddPredefinedDealPanel
                                }
                                setOpenAddPredefinedDealPanel={
                                  setOpenAddPredefinedDealPanel
                                }
                                openDeactivateDealDialog={
                                  openDeactivateDealDialog
                                }
                                setOpenDeactivateDealDialog={
                                  setOpenDeactivateDealDialog
                                }
                                setHasDealList={setHasDealList}
                              />
                            );
                          } else if (filterStore.detailTabValue === "Tasks") {
                            return (
                              <TasksTab
                                canEditLead={canEditLead}
                                leadID={lid}
                                openCreateTaskPanel={openCreateTaskPanel}
                                setOpenCreateTaskPanel={setOpenCreateTaskPanel}
                              />
                            );
                          } else if (filterStore.detailTabValue === "Quotes") {
                            return (
                              <QuotesTab
                                leadID={lid}
                                leadData={leadData}
                                canEditLead={canEditLead}
                                openAddQuotePanel={openAddQuotePanel}
                                setOpenAddQuotePanel={setOpenAddQuotePanel}
                                openAddInvoicePanel={openAddInvoicePanel}
                                setOpenAddInvoicePanel={setOpenAddInvoicePanel}
                              />
                            );
                          } else if (
                            filterStore.detailTabValue === "Basic Info"
                          ) {
                            consoleLogger(
                              "tabsToGroups in tab render::::",
                              tabsToGroups
                            );
                            return (
                              <ProfileTab
                                tab_type="basic_info_tab"
                                lid={lid}
                                uid={uid}
                                setRefreshLeadDetails={setRefreshLeadDetails}
                                setLeadData={setLeadData}
                                leadData={leadData}
                                groups={
                                  tabsToGroups[filterStore.detailTabValue]
                                }
                                groupsToColumns={groupsToColumns}
                                canEditLead={canEditLead}
                                isEmailVerified={isEmailVerified}
                                setOpenSkypeCallConfirmationDialog={
                                  setOpenSkypeCallConfirmationDialog
                                }
                                setSelectedPhoneFieldValue={
                                  setSelectedPhoneFieldValue
                                }
                                isCallDisabled={isCallDisabled}
                                setTextData={setTextData}
                                setOpenViewTextDialog={setOpenViewTextDialog}
                              />
                            );
                          } else {
                            return (
                              <CustomDetailsTab
                                key={filterStore.detailTabValue}
                                lid={lid}
                                uid={uid}
                                setRefreshLeadDetails={setRefreshLeadDetails}
                                setLeadData={setLeadData}
                                leadData={leadData}
                                groups={
                                  tabsToGroups[filterStore.detailTabValue]
                                }
                                groupsToColumns={groupsToColumns}
                                canEditLead={canEditLead}
                                setOpenSkypeCallConfirmationDialog={
                                  setOpenSkypeCallConfirmationDialog
                                }
                                setSelectedPhoneFieldValue={
                                  setSelectedPhoneFieldValue
                                }
                                isCallDisabled={isCallDisabled}
                                setTextData={setTextData}
                                setOpenViewTextDialog={setOpenViewTextDialog}
                              />
                            );
                          }
                        })()}
                      </Wrapper>
                    </Grid>
                  </Grid>
                </Container>
              </ParentContainer>
            ) : (
              <SpinnerWrapper>
                <NoAccessText>
                  You do not have permission to view the lead.
                </NoAccessText>
              </SpinnerWrapper>
            )
          ) : (
            <SpinnerWrapper>
              <NoAccessText>Some error occurred! Error code:1017</NoAccessText>
            </SpinnerWrapper>
          )
        ) : (
          <SpinnerWrapper>
            {leadErrorMessage !== "" ? (
              <NoAccessText>{leadErrorMessage}</NoAccessText>
            ) : (
              <NoAccessText>
                Some error occured! please contact your administrator
              </NoAccessText>
            )}
          </SpinnerWrapper>
        )
      ) : (
        <Box
          style={{
            display: "flex",
            width: "95%",
            margin: "auto",
            height: "calc(100vh - 145px)",
            flexDirection: "column",
            marginTop: "70px",
            paddingBottom: "16px",
          }}
        >
          <Grid container spacing={2} sx={{ flexDirection: "column" }}>
            <Grid item xs={4}>
              <Skeleton
                animation="wave"
                variant="text"
                style={{ width: "100%", height: "20px" }}
              />
            </Grid>
            <Grid item xs={12}>
              <Skeleton
                animation="wave"
                variant="rectangular"
                style={{ width: "100%", height: "50px" }}
              />
            </Grid>
            <Grid container spacing={2} item>
              <Grid item xs={3}>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  style={{ width: "100%", height: "calc(100vh - 261px)" }}
                />
              </Grid>
              <Grid item xs={9}>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  style={{ width: "100%", height: "calc(100vh - 261px)" }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
      <Footer />
      {open && (
        <LeadAssignModal
          open={open}
          setOpen={setOpen}
          refresh={refresh}
          setRefresh={setRefresh}
          leads={[lid]}
        />
      )}
      {transferOpen && (
        <LeadTransferModal
          open={transferOpen}
          setOpen={setTransferOpen}
          refresh={refresh}
          setRefresh={setRefresh}
          leadID={lid}
        />
      )}
      {openStatusModal && (
        <UpdateStatusModal
          selectedLeadStatus={""}
          scope={"lead_details_page"}
          id={lid}
          open={openStatusModal}
          setOpen={setOpenStatusModal}
          data={leadData}
          setRefreshLeadDetails={setRefreshLeadDetails}
          handleRefreshLeadListing={() => {}}
        />
      )}
      {openUpdateStageModal && (
        <UpdateStageModal
          id={lid}
          open={openUpdateStageModal}
          setOpen={setOpenUpdateStageModal}
          leadData={leadData}
          setRefreshLeadDetails={setRefreshLeadDetails}
        />
      )}
      {openSubmitLeadModal && (
        <SubmitLeadModal
          id={lid}
          open={openSubmitLeadModal}
          setOpen={setOpenSubmitLeadModal}
          leadData={leadData}
          setRefreshLeadDetails={setRefreshLeadDetails}
        />
      )}

      {openSourceModal && (
        <UpdateSourceModal
          id={lid}
          open={openSourceModal}
          setOpen={setOpenSourceModal}
          data={leadData}
          setRefreshLeadDetails={setRefreshLeadDetails}
        />
      )}
      {openConfirmation && (
        <CallConfirmationDailog
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          handlePhoneCall={handlePhoneCall}
        />
      )}
      {openCallInfo && (
        <CallInfoDialog
          open={openCallInfo}
          setOpen={setOpenCallInfo}
          scope={callInfoScope}
          openCallLogCallback={async () => {
            setOpenCallLogModal(true);
            setOpenCallInfo(false);
          }}
        />
      )}
      {openErrorDialog && (
        <ErrorMessageDialog
          open={openErrorDialog}
          setOpen={setOpenErrorDailog}
          message={errorMessage}
        />
      )}
      {openCreateLeadModal && (
        <MoneyViewCreateLead
          data={leadData}
          open={openCreateLeadModal}
          setOpen={setOpenCreateLeadModal}
          setRefreshLeadDetails={setRefreshLeadDetails}
        />
      )}
      {openRefreshOfferDialog && (
        <RefreshOffersDialog
          data={leadData}
          open={openRefreshOfferDialog}
          setOpen={setOpenRefreshOfferDailog}
          setRefreshLeadDetails={setRefreshLeadDetails}
        />
      )}
      {openRefreshJourneyDialog && (
        <RefreshJourneyUrlDialog
          data={leadData}
          open={openRefreshJourneyDialog}
          setOpen={setOpenRefreshJourneyDailog}
          setRefreshLeadDetails={setRefreshLeadDetails}
        />
      )}
      {openCallLogModal && (
        <CallLogModal
          scope={"user_details"}
          open={openCallLogModal}
          setOpen={setOpenCallLogModal}
          lid={getCallStatusLeadID()}
          uid={uid}
          setRefreshLeadDetails={setRefreshLeadDetails}
          leadData={leadData}
        />
      )}
      {openAddCallLogButton && (
        <CallLogModal
          scope={"user_details"}
          open={openAddCallLogButton}
          setOpen={setAddCallLogButton}
          lid={lid}
          uid={uid}
          setRefreshLeadDetails={setRefreshLeadDetails}
          leadData={leadData}
        />
      )}
      {openAdvancedCopyPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openAdvancedCopyPanel}
          onClose={() => {
            setOpenAdvancedCopyPanel(false);
          }}
        >
          <AdvanceCopySidepanel
            leadData={leadData}
            setOpen={setOpenAdvancedCopyPanel}
            columnsIdsByGroup={sortedColumnIdList}
            leadId={lid}
          />
        </Drawer>
      )}
      <Backdrop
        open={showBackDrop}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress style={{ color: "white" }} />
      </Backdrop>
      {showErrorDialog && (
        <CustomErrorMessageDialog
          message={markLeadErrorMessage}
          open={showErrorDialog}
          setOpen={setShowErrorDialog}
        />
      )}

      {openUnlockLeadConfirmDialog && (
        <ConfirmLeadUnlockDialog
          handleConfirm={() => {
            setLeadLockedOverriden(true);
            setShowUnlockLeadSuccess(true);
            setOpenUnlockLeadConfirmDialog(false);
            setUnlockedTemporarily(true);
          }}
          open={openUnlockLeadConfirmDialog}
          setOpen={setOpenUnlockLeadConfirmDialog}
          title={"Temporarily Unlock This Lead"}
          message={
            "Would you like to temporarily unlock this closed lead for editing?"
          }
        />
      )}
      {showUnlockLeadSuccess && (
        <SuccessMessageDialog
          open={showUnlockLeadSuccess}
          setOpen={setShowUnlockLeadSuccess}
          message={"Lead temporarily unlocked!"}
        />
      )}
      {showConfirmDeleteDialog && (
        <DeleteIndividualLeadConfirmationDialog
          isDeleteProtected={isDeleteProtected}
          open={showConfirmDeleteDialog}
          setOpen={setShowConfirmDeleteDialog}
          handleConfirm={async () => {
            await deleteLead();
          }}
        />
      )}
      {showResponseDialog && (
        <MessageWithLoadingStateCustomDialog
          open={showResponseDialog}
          setOpen={setShowResponseDialog}
          successMessage={"Lead deleted successfully!"}
          errorMessage={errorMessage}
          loading={isDeleting}
          isError={isSubmitFail}
        />
      )}
      {openClone && (
        <CloneLeadModal
          open={openClone}
          setOpen={setOpenClone}
          leadData={leadData}
        />
      )}
      {openPriorityLeadDialog && (
        <PriorityLeadDialog
          open={openPriorityLeadDialog}
          setOpen={setOpenPriorityLeadDialog}
          leadID={lid}
          setRefresh={setRefreshLeadDetails}
        />
      )}

      {openEditLeadPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openEditLeadPanel}
          onClose={() => {
            setOpenEditLeadPanel(false);
          }}
        >
          <GlobalEditLeadComponent
            leadID={lid}
            setRefreshLeadDetails={setRefreshLeadDetails}
            leadData={leadData}
            setOpen={setOpenEditLeadPanel}
          />
        </Drawer>
      )}

      {openClaimLeadConfirmDialog && (
        <CustomYesNoDialog
          open={openClaimLeadConfirmDialog}
          handleClose={() => {}}
          handleNo={() => {
            setOpenClaimLeadConfirmDialog(false);
            navigate("/");
          }}
          handleYes={() => {
            handleLeadClaim();
          }}
          message={"Do you want to claim this lead ?"}
        />
      )}

      {openLeadClaimDialog && (
        <MessageWithLoadingStateCustomDialog
          open={openLeadClaimDialog}
          setOpen={setOpenLeadClaimDialog}
          successMessage={"Lead claimed successfully!"}
          errorMessage={claimErrorMessage}
          loading={isLeadClaiming}
          isError={isClaimErrored}
        />
      )}
      {openSetLeadColorDialog && (
        <SetLeadColorDialog
          open={openSetLeadColorDialog}
          setOpen={setOpenSetLeadColorDialog}
          setRefresh={setRefreshLeadDetails}
          leadID={lid}
        />
      )}

      {whatsAppContextAnchor !== null && (
        <WhatsappContextMenu
          anchor={whatsAppContextAnchor}
          setAnchor={setWhatsAppContextAnchor}
          handleClose={() => {
            setWhatsAppContextAnchor(null);
          }}
          handleMenuClick={async (id) => {
            let index = messageTemplates.findIndex(
              (message) => message["id"] === id
            );
            if (index !== -1) {
              let messageData = messageTemplates[index];
              if (messageData["whatsapp_provider"] === null) {
                handleWhatsappMessage(messageData["message"]);
              } else {
                if(messageData["whatsapp_provider"]["name"] === "Meta WABA"){
                  setSelectedWhatsappTemplate(messageData);
                  let leadPhone = await fetchLeadPhone();
                  setLeadPhone(leadPhone);
                  setSendDialogOpen(true);
                }
              }
            }
          }}
          handleWhatsapp={() => {
            handleWhatsapp();
          }}
          messageTemplates={getFilteredWhatsappTemplatesByStage()}
        />
      )}

      {openSkypeCallConfirmationDialog && (
        <CallConfirmationDailog
          open={openSkypeCallConfirmationDialog}
          setOpen={setOpenSkypeCallConfirmationDialog}
          handlePhoneCall={() => {
            if (isMacOs) {
              window.open(`callto:${selectedPhoneFieldValue}`, "_blank");
            } else {
              window.open(`skype:${selectedPhoneFieldValue}?call`, "_blank");
            }

            setOpenSkypeCallConfirmationDialog(false);
          }}
        />
      )}
      {openViewTextDialog && (
        <ViewTextDialog
          open={openViewTextDialog}
          setOpen={setOpenViewTextDialog}
          textData={textData}
        />
      )}
       {/* {sendDialogOpen && (
        <SendDialogComponent
          open={sendDialogOpen}
          setOpen={setSendDialogOpen}
          templateData={selectedWhatsappTemplate}
          phoneNumber={leadPhone}
        />
      )} */}

      {isNavigating && <CustomLoadingDialog open={isNavigating} />}
      {showNavigationFailedDialog && (
        <CustomMessageDialog
          open={showNavigationFailedDialog}
          setOpen={setShowNavigationFailedDialog}
          message={"Unable to find the lead."}
        />
      )}
    </>
  );
};

export default observer(UserDetail);
