import { Box, styled } from "@mui/material";
import React from "react";
import rootStore from "../../stores/RootStore";
import MuiAlert from "@mui/material/Alert";
import moment from "moment";
import { observer } from "mobx-react-lite";
const CustomAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      variant="filled"
      severity={props.serverityValue}
      sx={{ textAlign: "left" }}
      style={{
        width: "100%",
        height: "100%",
        fontSize: "14px",
        color: "white",
        display: "flex",
        alignItems: "flex-start",
        borderRadius: "0px",
        justifyContent: "flex-start",
      }}
    />
  );
});
const Container = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  width: "100%",
  height: "fit-content",
  display: "flex",
  flexDirection: "column",
  zIndex: "4",
}));

const BannerComponent = () => {
  const canShowBanner = ({ startDatetime, endDatetime }) => {
    const currentDatetime = moment();
    const start = moment(startDatetime);
    const end = moment(endDatetime);

    return currentDatetime.isBetween(start, end);
  };

  const getAlertSeverity = (severity) => {
    if (severity === "info") {
      return "info";
    } else if (severity === "warning") {
      return "warning";
    } else if (severity === "critical") {
      return "error";
    } else {
      return "info";
    }
  };

  const RenderBanner = () => {
    if (rootStore.userStore.bannerData["visible"]) {
      if (
        canShowBanner({
          startDatetime: rootStore.userStore.bannerData["start_time"],
          endDatetime: rootStore.userStore.bannerData["end_time"],
        })
      ) {
        return (
          <CustomAlert
            serverityValue={getAlertSeverity(
              rootStore.userStore.bannerData["severity"]
            )}
            onClose={() => {
              rootStore.userStore.updateBannerVisibility({
                flag: false,
              });
            }}
          >
            {rootStore.userStore.bannerData["content"]}
          </CustomAlert>
        );
      }
    }
  };

  return (
    <>
      {Object.keys(rootStore.userStore.bannerData).length > 0 && (
        <Container>{RenderBanner()}</Container>
      )}
    </>
  );
};

export default observer(BannerComponent);
