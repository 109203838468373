import React, { useEffect, useRef, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { styled } from "@mui/material/styles";
import { ChevronRight } from "@material-ui/icons";
import { Box, Button, Grid, Typography } from "@mui/material";
import ReactFacebookLogin from "react-facebook-login";
import IntegrationStatusCard from "../../components/integrations_page_components/IntegrationStatusCard";
import { integrationsConfigList } from "../../Db";
import ViewFacebookAdsConfigDialog from "../../components/integrations_page_components/dialogs/ViewFacebookAdsConfigDialog";
import ViewWhatsappConfigDialog from "../../components/integrations_page_components/dialogs/ViewWhatsappConfigDialog";
import ViewIntegrationConfigDialog from "../../components/integrations_page_components/dialogs/ViewIntegrationConfigDialog";
import {
  getFacebookLeadsConfigData,
  getIndiamartConfigApi,
  createIndiamartConfigApi,
  updateIndiamartConfigApi,
  getAajjoConfigApi,
  createAajjoConfigApi,
  updateAajjoConfigApi,
  getWhatsappBAListApi,
} from "../../Api";
import { hasAccessDemoFeature, hasIntegrationsAccess } from "../../Functions";
import NoAccessPermissionPage from "../no_access_page/NoAccessPermissionPage";
import LoadingAnimationComponent from "../../components/loading_component/LoadingAnimationComponent";
import ViewExternalWebformConfigDialog from "../../components/integrations_page_components/dialogs/external_webform_config_dialogs/ViewExternalWebformConfigDialog";
import FacebookBusinessLoginButton from "../../components/integrations_page_components/fb_business_login/FacebookBusinessLoginButton";

const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  margin: auto;
  padding-top: 64px;
  width: 95%;
`;

const TopWrapper = styled(Box)`
  width: fit-content;
  display: flex;
  align-items: center;
  margin: 16px 0px;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const BottomWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 20px;
  display: flex;
  ${`min-height: calc(100vh - 188px);`};
`;

const fbConnectButtonStyles = {
  enabled: {
    padding: "6px 16px",
    textTransform: "none",
    borderRadius: "4px",
    backgroundColor: "transparent",
    color: "#1976d2",
    fontSize: "14px",
  },
  disabled: {
    borderColor: "gray",
    padding: "6px 16px",
    textTransform: "none",
    borderRadius: "4px",
    backgroundColor: "transparent",
    color: "gray",
    fontSize: "14px",
    cursor: "unset",
  },
};

const IntegrationsPage = () => {
  const fbLoginButtonRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [fbLeadData, setFbLeadData] = useState({});
  const [indiamartData, setIndiamartData] = useState({});
  const [aajoData, setAaajodata] = useState({});
  const [wabaData, setWabaData] = useState({});
  const [viewFacebookConfig, setViewFacebookConfig] = useState(false);
  const [viewWhatsappConfig, setViewWhatsappConfig] = useState(false);
  const [viewIntegrationConfig, setViewIntegrationConfig] = useState(false);
  const [currentIntegrationTypeData, setCurrentIntegrationTypeData] = useState(
    {}
  );
  const [refresh, setRefresh] = useState(false);
  const [refreshType, setRefreshType] = useState("");
  const [openConfigureExternalFormDialog, setOpenConfigureExternalFormDialog] =
    useState(false);

  //integration config dialog states
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

  const postCloseCallback = () => {
    setIsSubmitSuccess(false);
    setIsSubmitFail(false);
    setErrorMessage("");
    setShowConfirmationDialog(false);
  };

  const postSaveCallback = async (payload) => {
    setShowConfirmationDialog(false);

    let response = {};
    //save or edit
    if (currentIntegrationTypeData.type === "indiamart") {
      if (
        indiamartData["status"] !== "connected" &&
        indiamartData["status"] !== "active"
      ) {
        response = await createIndiamartConfigApi({ payload: payload });
      } else {
        response = await updateIndiamartConfigApi({ payload: payload });
      }
    } else if (currentIntegrationTypeData.type === "aajjo") {
      if (
        aajoData["status"] !== "connected" &&
        aajoData["status"] !== "active"
      ) {
        response = await createAajjoConfigApi({ payload: payload });
      } else {
        response = await updateAajjoConfigApi({ payload: payload });
      }
    }

    // if (
    //   indiamartData["status"] !== "connected" &&
    //   indiamartData["status"] !== "active"
    // ) {
    // } else {
    //   if (currentIntegrationTypeData.type === "indiamart") {
    //     response = await updateIndiamartConfigApi({ payload: payload });
    //   } else if (currentIntegrationTypeData.type === "aajjo") {
    //   }
    // }

    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setIsSubmitFail(false);
      setIsSubmitSuccess(true);
      setRefreshType(currentIntegrationTypeData.type);
      setRefresh(true);
    }
  };

  const handleFacebookCallback = (response) => {
    console.log("response of facebook login::", response);
    if (response?.status === "unknown") {
      console.error("Sorry!", "Something went wrong with facebook Login.");
      return;
    }
    console.log(response);
  };
  const fetchFbLeadData = async () => {
    let response = await getFacebookLeadsConfigData();
    if (response !== null) {
      setFbLeadData(response);
    } else {
      setFbLeadData({});
    }
  };

  const fetchIndiamartData = async () => {
    let response = await getIndiamartConfigApi();
    if (response !== null) {
      if (response["status"] === "" || response["status"] === undefined) {
        response["status"] = "not connected";
      }

      setIndiamartData(response);
    } else {
      setIndiamartData({});
    }
  };
  const fetchAajjoData = async () => {
    let response = await getAajjoConfigApi();
    if (response !== null) {
      if (response["status"] === "" || response["status"] === undefined) {
        response["status"] = "not connected";
      }

      setAaajodata(response);
    } else {
      setAaajodata({});
    }
  };
  const fetchWhatsappBAData = async () => {
    let response = await getWhatsappBAListApi();
    setWabaData(response);
  };
  const init = async () => {
    if (hasIntegrationsAccess()) {
      await fetchFbLeadData();
      await fetchIndiamartData();
      await fetchAajjoData();
      if (hasAccessDemoFeature()) {
        await fetchWhatsappBAData();
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  const refetch = async () => {
    if (refreshType === "indiamart") {
      await fetchIndiamartData();
    } else if (refreshType === "aajjo") {
      await fetchAajjoData();
    } else if (refreshType === "whatsapp") {
      if (hasAccessDemoFeature()) {
        await fetchWhatsappBAData();
      }
    }
    
    setRefreshType("");
    setRefresh(false);
  };

  useEffect(() => {
    if (refresh) {
      refetch();
    }
  }, [refresh]);

  return hasIntegrationsAccess() ? (
    <>
      <ParentContainer>
        <Container>
          <TopWrapper>
            <Header>Administration</Header>
            <ChevronRight style={{ width: "16px", height: "16px" }} />
            <Header>Integrations</Header>
          </TopWrapper>
          {!loading ? (
            <BottomWrapper>
              <Box sx={{ width: "100%" }}>
                <Grid container spacing={2}>
                  {integrationsConfigList.map((data, index) => {
                    if (data["type"] === "facebook") {
                      let details = { ...data };
                      if (fbLeadData.hasOwnProperty("status")) {
                        details["status"] = fbLeadData["status"];
                      } else {
                        details["status"] = "not connected";
                      }
                      return (
                        <Grid item xs={6} key={index}>
                          <IntegrationStatusCard
                            details={details}
                            renderButton={() => {
                              if (
                                details["status"] === "connected" ||
                                details["status"] === "configured"
                              ) {
                                return (
                                  <Button
                                    disabled={!data["is_enabled"]}
                                    onClick={() => {
                                      setViewFacebookConfig(true);
                                    }}
                                    variant="outlined"
                                    color="primary"
                                    sx={{
                                      padding: "6px 16px",
                                      textTransform: "none",
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {details["status"] === "connected"
                                      ? "Configure"
                                      : "Re-configure"}
                                  </Button>
                                );
                              } else {
                                return hasAccessDemoFeature() ? (
                                  <FacebookBusinessLoginButton />
                                ) : (
                                  <ReactFacebookLogin
                                    isDisabled={true}
                                    ref={fbLoginButtonRef}
                                    buttonStyle={fbConnectButtonStyles.disabled}
                                    appId="2413694435490006"
                                    autoLoad={false}
                                    fields="name,email,picture"
                                    callback={handleFacebookCallback}
                                    textButton="Connect"
                                  />
                                );
                              }
                            }}
                          />
                        </Grid>
                      );
                    } else if (data["type"] === "whatsapp") {
                      let details = { ...data };
                      details["status"] = "not connected";
                      if (hasAccessDemoFeature()) {
                        return (
                          <Grid item xs={6} key={index}>
                            <IntegrationStatusCard
                              details={details}
                              showStatus={false}
                              renderButton={() => {
                                return (
                                  <Button
                                    disabled={!data["is_enabled"]}
                                    onClick={() => {
                                      setViewWhatsappConfig(true);
                                    }}
                                    variant="outlined"
                                    color="primary"
                                    sx={{
                                      padding: "6px 16px",
                                      textTransform: "none",
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Open
                                  </Button>
                                );
                              }}
                            />
                          </Grid>
                        );
                      }
                    } else if (data["type"] === "indiamart") {
                      let details = { ...data };
                      if (indiamartData.hasOwnProperty("status")) {
                        details["status"] = indiamartData["status"];
                      } else {
                        details["status"] = "not connected";
                      }
                      return (
                        <Grid item xs={6} key={index}>
                          <IntegrationStatusCard
                            details={details}
                            renderButton={() => {
                              return (
                                <Button
                                  disabled={!data["is_enabled"]}
                                  onClick={() => {
                                    setCurrentIntegrationTypeData(details);
                                    setViewIntegrationConfig(true);
                                  }}
                                  variant="outlined"
                                  color="primary"
                                  sx={{
                                    padding: "6px 16px",
                                    textTransform: "none",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {details["status"] === "connected" ||
                                  details["status"] === "active"
                                    ? "Configure"
                                    : "Connect"}
                                </Button>
                              );
                            }}
                          />
                        </Grid>
                      );
                    } else if (data["type"] === "aajjo") {
                      let details = { ...data };
                      if (aajoData.hasOwnProperty("status")) {
                        details["status"] = aajoData["status"];
                      } else {
                        details["status"] = "not connected";
                      }
                      return (
                        <Grid item xs={6} key={index}>
                          <IntegrationStatusCard
                            details={details}
                            renderButton={() => {
                              return (
                                <Button
                                  disabled={!data["is_enabled"]}
                                  onClick={() => {
                                    setCurrentIntegrationTypeData(details);
                                    setViewIntegrationConfig(true);
                                  }}
                                  variant="outlined"
                                  color="primary"
                                  sx={{
                                    padding: "6px 16px",
                                    textTransform: "none",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {details["status"] === "connected" ||
                                  details["status"] === "active"
                                    ? "Configure"
                                    : "Connect"}
                                </Button>
                              );
                            }}
                          />
                        </Grid>
                      );
                    } else if (data["type"] === "webhooks") {
                      return (
                        <Grid item xs={6} key={index}>
                          <IntegrationStatusCard
                            showStatus={false}
                            details={data}
                            renderButton={() => {
                              return (
                                <Button
                                  disabled={!data["is_enabled"]}
                                  onClick={() => {
                                    setOpenConfigureExternalFormDialog(true);
                                  }}
                                  variant="outlined"
                                  color="primary"
                                  sx={{
                                    padding: "6px 16px",
                                    textTransform: "none",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Open
                                </Button>
                              );
                            }}
                          />
                        </Grid>
                      );
                    } else {
                      return (
                        <Grid item xs={6} key={index}>
                          <IntegrationStatusCard
                            details={data}
                            renderButton={() => {
                              return (
                                <Button
                                  disabled={!data["is_enabled"]}
                                  onClick={() => {}}
                                  variant="outlined"
                                  color="primary"
                                  sx={{
                                    padding: "6px 16px",
                                    textTransform: "none",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Configure
                                </Button>
                              );
                            }}
                          />
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </Box>
            </BottomWrapper>
          ) : (
            <BottomWrapper
              sx={{ justifyContent: "center", alignItems: "center" }}
            >
              <LoadingAnimationComponent size={"small"} />
            </BottomWrapper>
          )}{" "}
        </Container>
      </ParentContainer>
      <Footer />
      {viewFacebookConfig && (
        <ViewFacebookAdsConfigDialog
          open={viewFacebookConfig}
          setOpen={setViewFacebookConfig}
        />
      )}
      {viewWhatsappConfig && (
        <ViewWhatsappConfigDialog
          open={viewWhatsappConfig}
          setOpen={setViewWhatsappConfig}
          formData={wabaData}
          setRefresh={setRefresh}
          setRefreshType={setRefreshType}
        />
      )}
      {viewIntegrationConfig && (
        <>
          {currentIntegrationTypeData.type === "indiamart" && (
            <ViewIntegrationConfigDialog
              open={viewIntegrationConfig}
              setOpen={setViewIntegrationConfig}
              formData={indiamartData}
              integrationData={currentIntegrationTypeData}
              postSaveCallback={postSaveCallback}
              postCloseCallback={postCloseCallback}
              showConfirmationDialog={showConfirmationDialog}
              setShowConfirmationDialog={setShowConfirmationDialog}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              isSubmitFail={isSubmitFail}
              setIsSubmitFail={setIsSubmitFail}
              isSubmitSuccess={isSubmitSuccess}
              setIsSubmitSuccess={setIsSubmitSuccess}
            />
          )}
          {currentIntegrationTypeData.type === "aajjo" && (
            <ViewIntegrationConfigDialog
              open={viewIntegrationConfig}
              setOpen={setViewIntegrationConfig}
              formData={aajoData}
              integrationData={currentIntegrationTypeData}
              postSaveCallback={postSaveCallback}
              postCloseCallback={postCloseCallback}
              showConfirmationDialog={showConfirmationDialog}
              setShowConfirmationDialog={setShowConfirmationDialog}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              isSubmitFail={isSubmitFail}
              setIsSubmitFail={setIsSubmitFail}
              isSubmitSuccess={isSubmitSuccess}
              setIsSubmitSuccess={setIsSubmitSuccess}
            />
          )}
        </>
      )}

      {openConfigureExternalFormDialog && (
        <ViewExternalWebformConfigDialog
          open={openConfigureExternalFormDialog}
          setOpen={setOpenConfigureExternalFormDialog}
        />
      )}
    </>
  ) : (
    <NoAccessPermissionPage />
  );
};

export default IntegrationsPage;
