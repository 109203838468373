import React, { useEffect, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { Button, Drawer, withStyles } from "@material-ui/core";
import { Add, ChevronRight } from "@material-ui/icons";
import ActiveTab from "../../components/target_tabs/ActiveTab";
import AchievedTab from "../../components/target_tabs/AchievedTab";
import FailedTab from "../../components/target_tabs/FailedTab";
import rootStore from "../../stores/RootStore";

const ParentContainer = styled.div`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled.div`
  ${`min-height: calc(100vh - 84px);`};

  margin: auto;
  padding-top: 70px;
  width: 95%;
`;
const Wrapper = styled.div`
  display: flex;
  position: relative;
  background-color: white;
  padding: 20px;
  border: 2px solid #f9f9fc;
  min-height: 60vh;
`;
const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
`;
const Header = styled.p`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TargetPage = (props) => {
  const [tabVal, setTabVal] = useState(0);
  let navigate = useNavigate();
  useEffect(() => {
    rootStore.authStore.updateLeadDetailStatus();
    props.setShowBar(true);
    setTabVal(0);
  }, []);
  const CustomTab = withStyles({
    root: {
      backgroundColor: "white",
      color: "#4D4E4F",
      font: "normal normal 600 14px Open Sans",
      textTransform: "capitalize",
    },
    selected: {
      backgroundColor: "#E7EEFA",
      color: "#4079DA",
      font: "normal normal 600 14px Open Sans",
      textTransform: "capitalize",
    },
  })(Tab);

  return (
    <>
      <ParentContainer>
        <Container>
          <TopWrapper>
            <Header>Target</Header>
            <ChevronRight style={{ width: "16px", height: "16px" }} />
          </TopWrapper>

          <Row style={{ backgroundColor: "white" }}>
            <Tabs
              value={tabVal}
              textColor="primary"
              indicatorColor="primary"
              onChange={(event, newValue) => {
                setTabVal(newValue);
              }}
            >
              <CustomTab label="Active" />
              <CustomTab label="Achieved" />
              <CustomTab label="Failed" />
            </Tabs>
            <ButtonWrapper
              style={{ padding: "10px" }}
              onClick={() => {
                navigate("/create-target");
              }}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#185DD2",
                  color: "white",
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                <Add
                  style={{ width: "16px", height: "16px", marginRight: "4px" }}
                />{" "}
                Create Target
              </Button>
            </ButtonWrapper>
          </Row>
          <Wrapper style={{ padding: "0px" }}>
            {(() => {
              if (tabVal === 0) {
                return <ActiveTab />;
              } else if (tabVal === 1) {
                return <AchievedTab />;
              } else if (tabVal === 2) {
                return <FailedTab />;
              }
            })()}
          </Wrapper>
        </Container>
      </ParentContainer>
      <Footer />
    </>
  );
};

export default observer(TargetPage);
