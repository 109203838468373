import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import Appbar from "./insurance_template_components/Appbar";
import TopSection from "./insurance_template_components/TopSection";
import FooterComponent from "./insurance_template_components/FooterComponent";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
}));

const InsuranceTemplate = ({ formState, contentState, logoURL }) => {
  return (
    <Container>
      <Appbar logoURL={logoURL} />
      <TopSection formState={formState} contentState={contentState} />
      <FooterComponent contentState={contentState} />
    </Container>
  );
};

export default InsuranceTemplate;
