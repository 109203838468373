import { Grid } from "@material-ui/core";
import { Box, Card, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import React from "react";
import { dataRenderer, IsoToLocalTime } from "../../../../../Functions";
const Title = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  color: #ffb234;
  margin-bottom: 16px;
`;
const TextWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;
const InfoWrapper = styled(Box)`
  width: 100%;
`;
const Text = styled(Typography)`
  margin: 0px;
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
`;
const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  height: 100%;
`;
const ActivationDetailsCard = ({ details }) => {
  return (
    <Card
      sx={{
        bgcolor: "white",
        width: "100%",
        height: "100%",
      }}
    >
      <Wrapper>
        <Title>Activation Details</Title>
        <InfoWrapper>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextWrapper>
                <Text
                  style={{
                    color: "#aca7a6",
                  }}
                >
                  First Activated On :
                </Text>

                <Text>
                  {details["first_customer_activated_on"] !== null &&
                  details["first_customer_activated_on"] !== ""
                    ? IsoToLocalTime(details["first_customer_activated_on"])
                    : "-"}
                </Text>
              </TextWrapper>
            </Grid>
            <Grid item xs={12}>
              <TextWrapper>
                <Text
                  style={{
                    color: "#aca7a6",
                  }}
                >
                  Last Activated On :
                </Text>
                <Text>
                  {" "}
                  {details["last_customer_activated_on"] !== null &&
                  details["last_customer_activated_on"] !== ""
                    ? IsoToLocalTime(details["last_customer_activated_on"])
                    : "-"}
                </Text>
              </TextWrapper>
            </Grid>
            <Grid item xs={12}>
              <TextWrapper>
                <Text
                  style={{
                    color: "#aca7a6",
                  }}
                >
                  Deactivated On :
                </Text>
                <Text>
                  {" "}
                  {details["last_customer_deactivated_on"] !== null &&
                  details["last_customer_deactivated_on"] !== ""
                    ? IsoToLocalTime(details["last_customer_deactivated_on"])
                    : "-"}
                </Text>
              </TextWrapper>
            </Grid>
            <Grid item xs={12}>
              <TextWrapper>
                <Text
                  style={{
                    color: "#aca7a6",
                  }}
                >
                  Repeat Customer :
                </Text>
                <Text>
                  {details["repeat_customer"] !== null &&
                  details["repeat_customer"] !== ""
                    ? details["repeat_customer"] === true
                      ? "Yes"
                      : "No"
                    : "-"}
                </Text>
              </TextWrapper>
            </Grid>
          </Grid>
        </InfoWrapper>
      </Wrapper>
    </Card>
  );
};

export default ActivationDetailsCard;
