import React, { useState } from "react";
import styled from "styled-components";
import {
  Box,
  Button,
  Divider,
  FormControl,
  makeStyles,
  Modal,
  Select,
  Tab,
  Tabs,
  withStyles,
} from "@material-ui/core";
import {
  getMembersApi,
  getProjectApi,
  getProjectListApi,
  getTargetMembersApi,
  getTeamApi,
  sendTargetsApi,
  submitLeadApi,
} from "../../Api";
import Footer from "../../components/landing_page_components/footer/Footer";
import MemberCard from "../../components/target_card/MemberCard";
import TeamCard from "../../components/target_card/TeamCard";
import ProjectCard from "../../components/target_card/ProjectCard";
import { useEffect } from "react";
import { DatePicker } from "antd";
import moment from "moment";
import rootStore from "../../stores/RootStore";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import spinner from "../../assets/images/gifs/spinner.gif";
import { ChevronRight } from "@material-ui/icons";
import { toJS } from "mobx";
import { MenuItem } from "@mui/material";
import { getIsoString } from "../../Functions";
const Spinner = styled.img`
  height: 120px;
  width: 180px;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
`;
const Container = styled.div`
  /* padding: 30px 0px; */
  width: 100%;
  margin-top: 70px;
  background-color: #f9f9fc;
  ${`min-height: calc(100vh - 139px);`};
`;
const Wrapper = styled.div`
  width: 95%;
  margin: auto;
  /* background-color: white; */
  display: flex;
  flex-direction: column;
  /* padding-top: 20px; */
`;

const Title = styled.p`
  margin: 0px;
  font: normal normal 600 24px Open Sans;
  /* text-align: center; */
  color: #4d4e4f;
  width: 100%;
`;

const Label = styled.span`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const Input = styled.input`
  width: 200px;
  height: 30px;
  padding: 2px 6px;
  outline: none;
  border: 1px solid #e0deca;
  font: normal normal 600 14px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal normal 14px Open Sans;
    color: #b5b5b5;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 238px;
  height: 72px;
  background-color: white;
  padding: 0px 20px;
  border-radius: 8px;
`;
const InputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: auto;
  padding: 20px;
  column-gap: 20px;
  background-color: white;
`;
const Bottom = styled.div`
  display: flex;
  padding: 30px;
  width: 100%;
  flex-direction: column;
  background-color: white;
  padding-top: 0px;
`;
const ColumnHeader = styled.p`
  margin: 0px;
  font: normal normal normal 18px Open Sans;
  text-align: center;
  flex: 1;
`;
const ColumnHeaderMember = styled.p`
  margin: 0px;
  font: normal normal normal 18px Open Sans;
  text-align: center;
  flex: 1;
  @media (max-width: 1465px) {
    flex: 1;
  }
`;
const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${`min-height:calc(100vh - 440px)`};
  /* overflow-y: auto; */
`;
const RowWrapper = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  column-gap: 20px;
`;
const CardWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  row-gap: 16px;
  ${`max-height:calc(100vh - 440px)`};
  /* height: 100%; */
  overflow-y: auto;
  padding: 8px;
  padding-top: 20px;

  align-items: center;
  background-color: #efeff4;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0px;
  width: 100%;
`;
const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
`;
const Header = styled.p`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 10px;
  justify-content: flex-end;
  width: 100%;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #BFBFBF",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #BFBFBF",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #BFBFBF",
      borderRadius: "4px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const CreateTarget = () => {
  const { authStore, userStore } = rootStore;
  const [members, setMembers] = useState([]);
  const [team, setTeam] = useState([]);
  const [memberCollection, setMemberCollection] = useState({});
  const [projectTarget, setProjectTarget] = useState(0);
  const [flag, setFlag] = useState(false);
  const [project, setProject] = useState(null);
  // const [projectList, setProjectList] = useState([]);
  // const [refresh, setRefresh] = useState(false);
  let navigate = useNavigate();
  const { RangePicker } = DatePicker;
  const [dateRange, setDateRange] = useState({});
  const [value, setValue] = useState({ name: "", measure: "" });
  let uid = localStorage.getItem("uid");
  let pid = authStore.projectId;
  const classes = useStyles();

  function getRandomColor() {
    var letters = "BCDEF".split("");
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  }
  useEffect(() => {
    setMemberCollection({});
    setMembers([]);
    setTeam([]);
    userStore.updateTeamsTarget({});
    const getTeams = async () => {
      let response = await getTeamApi(pid);
      let newList = {};
      if (response.length > 0) {
        response.forEach((e) => {
          newList[e.id] = 0;
          let color = getRandomColor();
          userStore.updateTargetColorList({ key: e.id, color: color });
        });
      }
      userStore.updateTeamsTarget(newList);
      setTeam(response);
    };
    const getTargetMembers = async () => {
      let response = await getTargetMembersApi(pid);

      let orgList = [];
      let newList = {};
      if (response.data.length > 0) {
        response.data.forEach((e) => {
          if (
            e.name !== "system" &&
            e.role !== "manager" &&
            e.role !== "lead"
          ) {
            newList[e.id] = { target: 0, team: e.team.id };
            orgList.push(e);
          }
        });
      }
      setMembers(orgList);
      setMemberCollection(newList);
    };
    if (pid !== null) {
      getTeams();
      getTargetMembers();
    }
  }, [project]);
  useEffect(() => {
    authStore.updateLeadDetailStatus();
    setProject(authStore.currentProject);
  }, []);
  const handleSubmit = async () => {
    let newMemberList = [];
    if (Object.keys(memberCollection).length > 0) {
      Object.keys(memberCollection).map((key) => {
        let newObj = {
          owner: key,
          target_value: parseInt(memberCollection[key].target),
        };
        newMemberList.push(newObj);
      });
    }
    let newTeamList = [];
    if (Object.keys(toJS(userStore.teamsTarget).length > 0)) {
      Object.keys(toJS(userStore.teamsTarget)).map((key) => {
        let newObj = {
          owner: key,
          target_value: parseInt(toJS(userStore.teamsTarget)[key]),
        };
        newTeamList.push(newObj);
      });
    }
    let newDateRange = {};
    if (Object.keys(dateRange).length !== 0) {
      let d1 = getIsoString(dateRange["start_date"]);
      let d2 = getIsoString(dateRange["end_date"]);
      newDateRange["start_date"] = d1;
      newDateRange["end_date"] = d2;
    }
    let innerData = {
      project: {
        owner: uid,
        target_value: parseInt(projectTarget),
      },
      teams: newTeamList,
      members: newMemberList,
    };
    let data = { ...value, ...newDateRange, data: innerData };
    try {
      let response = await sendTargetsApi(data);
      setFlag(true);
      setTimeout(() => {
        navigate("/target");
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };
  const handleBreadCrump = () => {
    navigate(-1);
  };
  const handleChange = (e) => {
    setProject(e.target.value);
  };
  return (
    <>
      <Container>
        <Wrapper>
          <TopWrapper>
            <Header
              style={{ color: "#185DD2", cursor: "pointer" }}
              onClick={handleBreadCrump}
            >
              Target
            </Header>
            <ChevronRight style={{ width: "16px", height: "16px" }} />
            <Header>Create new target</Header>
          </TopWrapper>
          <Row style={{ marginBottom: "20px" }}>
            <Title>Create new target</Title>
            <ButtonWrapper>
              <Button
                onClick={() => {
                  navigate("/target");
                }}
                variant={"contained"}
                color="primary"
                style={{
                  textTransform: "none",
                  fontWeight: "bold",
                  backgroundColor: "white",
                  color: "#185DD2",
                }}
              >
                CANCEL
              </Button>
              <Button
                onClick={handleSubmit}
                variant={"contained"}
                style={{
                  textTransform: "none",
                  fontWeight: "bold",
                  backgroundColor: !flag ? "#185DD2" : "#21B531",
                  color: "white",
                }}
              >
                {!flag ? "SAVE TARGET" : "SAVED"}
              </Button>
            </ButtonWrapper>
          </Row>
          {/* <ModelSubHeader>Please fill the below details</ModelSubHeader> */}
          <InputContainer>
            {/* <InputWrapper>
              <Label>Project</Label>
              <FormControl
                sx={{ m: 1, minWidth: 125 }}
                classes={{
                  root: classes.quantityRoot,
                }}
              >
                {projectList.length > 0 && (
                  <Select
                    classes={{
                      icon: classes.icon,
                    }}
                    disableUnderline
                    value={project}
                    variant="outlined"
                    onChange={handleChange}
                    style={{
                      fontSize: "12px",
                      minWidth: "125px",
                      fontWeight: "bold",
                      height: "30px",
                      padding: "auto 12px",
                    }}
                  >
                    {projectList.map((e) => {
                      return (
                        <MenuItem
                          style={{ fontSize: "14px" }}
                          id={e.id}
                          value={e}
                        >
                          {e.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              </FormControl>
            </InputWrapper> */}
            <InputWrapper>
              <Label>Target Name</Label>
              <Input
                name="name"
                value={value.name}
                required
                placeholder="enter target name"
                onChange={(e) => {
                  setValue({ ...value, [e.target.name]: e.target.value });
                }}
              />
            </InputWrapper>

            <InputWrapper>
              <Label>Measure</Label>
              <Input
                value={value.measure}
                name="measure"
                required
                placeholder="select measure"
                onChange={(e) => {
                  setValue({ ...value, [e.target.name]: e.target.value });
                }}
              />
            </InputWrapper>
            <InputWrapper>
              <Label>Target Period</Label>
              <RangePicker
                placeholder={["From", "To"]}
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                style={{ height: "30px" }}
                onChange={(value) => {
                  let d1 = moment(value[0]).format("YYYY-MM-DD");
                  let d2 = moment(value[1]).format("YYYY-MM-DD");
                  setDateRange({
                    ...dateRange,
                    start_date: d1,
                    end_date: d2,
                  });
                }}
              />
            </InputWrapper>
          </InputContainer>
          <Divider />
          <Bottom>
            <HeaderWrapper>
              <ColumnHeaderMember>Members</ColumnHeaderMember>
              <ColumnHeader>Teams</ColumnHeader>
              <ColumnHeader>Project</ColumnHeader>
            </HeaderWrapper>
            <ColumnWrapper>
              <RowWrapper>
                <CardWrapper style={{ backgroundColor: "white" }}>
                  {members.length > 0 ? (
                    members.map((e) => {
                      if (e.name !== "system") {
                        return (
                          <MemberCard
                            color={"#ADD8E6"}
                            item={e}
                            memberCollection={memberCollection}
                            setMemberCollection={setMemberCollection}
                          />
                        );
                      }
                    })
                  ) : (
                    <SpinnerWrapper>
                      <Spinner src={spinner} alt="loading..." />
                    </SpinnerWrapper>
                  )}
                </CardWrapper>
                <CardWrapper>
                  {team.length > 0 ? (
                    team.map((e) => {
                      return (
                        <TeamCard
                          id={e.id}
                          item={e}
                          color={"#90EE90"}
                          memberCollection={memberCollection}
                          // teamCollection={teamCollection}
                          // setTeamCollection={setTeamCollection}
                        />
                      );
                    })
                  ) : (
                    <SpinnerWrapper>
                      <Spinner src={spinner} alt="loading..." />
                    </SpinnerWrapper>
                  )}
                </CardWrapper>
                <CardWrapper>
                  <ProjectCard
                    project={project}
                    color={"#F4EA56"}
                    memberCollection={memberCollection}
                    setProjectTarget={setProjectTarget}
                    // teamCollection={teamCollection}
                  />
                </CardWrapper>
              </RowWrapper>
            </ColumnWrapper>
          </Bottom>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
};

export default observer(CreateTarget);
