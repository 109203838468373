import React, { useState } from "react";
import { Box, IconButton, TextField } from "@material-ui/core";
import { Clear, Search } from "@material-ui/icons";
import { styled } from "@mui/material/styles";

const SearchInput = styled("input")(({ theme }) => ({
  height: "40px",
  border: "none",
  outline: "none",
  width: "100%",
  color: "#4D4E4F",
  font: "normal normal 500 14px Open Sans",
  padding: "8px 12px",
  backgroundColor: "#efeff4",
  borderRadius: "8px",
  "&::placeholder": {
    fontSize: "14px",
    color: "#2B2B2B",
  },
}));
const Container = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;
const ExpandableSearchbar = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [showCloseIcon, setShowCloseIcon] = useState(false);
  const handleSearchIconClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Container>
      <Box sx={{ display: "flex", position: "relative" }}>
        <SearchInput
          placeholder="Search..."
          value={props.searchQuery}
          style={{
            transition: "width 300ms",
            width: expanded ? "300px" : "0px",
            backgroundColor: expanded ? "#EFEFF4" : "transparent",
          }}
          onChange={(e) => {
            props.setSearchQuery(e.target.value);
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter" && props.searchQuery !== "") {
              props.handleSearch(props.searchQuery);
              setShowCloseIcon(true);
            }
          }}
        />
        {expanded && showCloseIcon === true && (
          <Clear
            onClick={() => {
              props.setSearchQuery("");
              props.handleSearch("");
              setShowCloseIcon(false);
            }}
            color="action"
            style={{
              position: "absolute",
              top: 10,
              right: 44,
              cursor: "pointer",
            }}
          />
        )}
        <IconButton
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            height: "40px",
            width: "40px",
          }}
          onClick={handleSearchIconClick}
        >
          <Search />
        </IconButton>
      </Box>
    </Container>
  );
};

export default ExpandableSearchbar;
