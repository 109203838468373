import { Button, Checkbox, FormControlLabel, Tooltip } from "@material-ui/core";
import {
  Delete,
  Edit,
  FiberManualRecord,
  MoreHoriz,
  PlayArrow,
} from "@material-ui/icons";
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableCell,
  TableRow,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import {
  getExportJobDownloadFileApi,
  getJobSpecificCountApi,
  getJobStatusApi,
  refreshImportRowApi,
  startSpecificJobApi,
} from "../../Api";
import { ImportHistoryColumns, JobListColumns } from "../../Db";
import {
  dataRenderer,
  dateTimeHandler,
  getJobTypeDisplayName,
  IsoToLocalTime,
} from "../../Functions";
import "../../pages/import_history/import.css";
import spinner from "../../assets/images/gifs/spinner.gif";
import CreateJobModal from "../create_job_modal/CreateJobModal";
import { Download } from "@mui/icons-material";
import rootStore from "../../stores/RootStore";

const Spinner = styled.img`
  height: 30px;
  width: 50px;
  color: blue;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  padding-top: 8px;
  justify-content: center;
  align-items: center;
  margin: 0px 2px;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Detail = styled.span`
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;

const Label = styled.span`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const IconTextWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
  margin-bottom: 4px;
`;
const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100px;
  overflow-y: auto;
`;
const JobRowComponent = ({
  item,
  idx,
  pid,
  refresh,
  setRefresh,
  statusList,
}) => {
  const [data, setData] = useState();
  const { id, job_type } = item;
  const [status, setStatus] = useState("");
  const [open, setOpen] = useState(false);
  const MINUTE_MS = 30000;
  const [responseCount, setResponseCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [markedImportantStausList, setMarkedImportantStatusList] = useState([]);
  const [isBothCondtionCheckRequired, setIsBothConditionCheckRequired] =
    useState(false);
  const [firstConditionChecked, setFirstConditionChecked] = useState(false);
  const [secondConditionChecked, setSecondConditionChecked] = useState(false);

  const generateStatusListMarkedImportant = () => {
    let temp_list = [];
    if (
      item.input.filter.hasOwnProperty("lead_status") &&
      item.input.filter["lead_status"].length > 0
    ) {
      item.input.filter["lead_status"].forEach((item) => {
        let index = statusList.findIndex(
          (status_obj) => status_obj["status"] === item
        );
        if (index !== -1) {
          if (statusList[index]["mark_as_important"] === true) {
            temp_list.push(statusList[index]["status"]);
          }
        }
      });
    } else {
      statusList.forEach((status_obj) => {
        if (status_obj["mark_as_important"] === true) {
          temp_list.push(status_obj["status"]);
        }
      });
    }
    return temp_list;
  };

  const canStartDeleteJob = () => {
    if (isBothCondtionCheckRequired) {
      return firstConditionChecked && secondConditionChecked;
    }
    return firstConditionChecked;
  };

  const getJobStatus = async () => {
    try {
      let response = await getJobStatusApi({ id: pid, jid: id });
      setData(response.data);
      setStatus(response.data["status"]);
      // setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getResponseCount = async () => {
    try {
      let response = await getJobSpecificCountApi({ id: pid, jid: id });
      setResponseCount(response.data["item_count"]);
      setOpen(true);
    } catch (error) {
      console.log(error);
    }
  };
  const startJob = async () => {
    try {
      let response = await startSpecificJobApi({ id: pid, jid: id });
      setData(response.data);
      setStatus(response.data["status"]);
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handlePlay = (e) => {
    e.stopPropagation();
    getResponseCount();
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (status === "queued" || status === "processing") {
        getJobStatus();
      }
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, [status]);

  useEffect(() => {
    setData(item);
    setStatus(item["status"]);
    if (item["job_type"] === "delete_leads") {
      let new_list = generateStatusListMarkedImportant();
      setMarkedImportantStatusList(new_list);
      if (new_list.length > 0) {
        setIsBothConditionCheckRequired(true);
      }
    }
  }, []);
  function truncate(str, n) {
    return str.length > n ? str.slice(0, n - 1) + "..." : str;
  }
  const handleClose = () => {
    setOpen(false);
  };
  const validateAction = () => {
    return (
      status === "queued" || status === "processing" || status === "completed"
    );
  };
  const handleDownload = () => {
    setLoading(true);
    const DownloadExportFile = async () => {
      try {
        let response = await getExportJobDownloadFileApi({ id: pid, jid: id });
        const url = response.data.artifact;
        const link = document.createElement("a");
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    DownloadExportFile();
  };
  return (
    <>
      <TableRow
        key={idx}
        style={{
          width: "100%",
          cursor: "pointer",
          backgroundColor: idx % 2 === 0 ? "white" : "#F9F9FC",
        }}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        {data &&
          JobListColumns.map((key) => {
            if (key["field"] === "action") {
              return (
                <TableCell
                  className="textContainer"
                  style={{
                    display: "flex",
                    justiyContent: "center",
                    alignItems: "center",
                    height: "33px",
                    width: "70px",
                  }}
                >
                  {(() => {
                    if (status === "queued" || status === "processing") {
                      if (
                        data["job_type"] === "export_leads" ||
                        data["job_type"] === "export_leads_dialer_mcube" ||
                        data["job_type"] === "export_leads_dialer_ozonetel"
                      ) {
                        return (
                          <>
                            <SpinnerWrapper
                              onClick={(e) => e.stopPropagation()}
                            >
                              <CircularProgress
                                style={{ width: "18px", height: "18px" }}
                              />
                            </SpinnerWrapper>
                            <SpinnerWrapper>
                              <Tooltip title="Download">
                                <Download
                                  color="disabled"
                                  style={{
                                    width: "18px",
                                    height: "18px",
                                  }}
                                />
                              </Tooltip>
                            </SpinnerWrapper>
                          </>
                        );
                      } else {
                        return (
                          <SpinnerWrapper onClick={(e) => e.stopPropagation()}>
                            <CircularProgress
                              style={{ width: "18px", height: "18px" }}
                            />
                          </SpinnerWrapper>
                        );
                      }
                    } else if (status === "completed") {
                      if (
                        data["job_type"] === "export_leads" ||
                        data["job_type"] === "export_leads_dialer_mcube" ||
                        data["job_type"] === "export_leads_dialer_ozonetel"
                      ) {
                        return (
                          <>
                            <SpinnerWrapper
                              onClick={(e) => e.stopPropagation()}
                            >
                              <Tooltip title="Start">
                                <PlayArrow
                                  color="disabled"
                                  style={{ width: "18px", height: "18px" }}
                                />
                              </Tooltip>
                            </SpinnerWrapper>
                            <SpinnerWrapper onClick={handleDownload}>
                              <Tooltip title="Download">
                                <Download
                                  color="primary"
                                  style={{
                                    width: "18px",
                                    height: "18px",
                                  }}
                                />
                              </Tooltip>
                            </SpinnerWrapper>
                          </>
                        );
                      } else {
                        return (
                          <SpinnerWrapper onClick={(e) => e.stopPropagation()}>
                            <Tooltip title="Start">
                              <PlayArrow
                                color="disabled"
                                style={{ width: "18px", height: "18px" }}
                              />
                            </Tooltip>
                          </SpinnerWrapper>
                        );
                      }
                    } else {
                      if (
                        data["job_type"] === "export_leads" ||
                        data["job_type"] === "export_leads_dialer_mcube" ||
                        data["job_type"] === "export_leads_dialer_ozonetel"
                      ) {
                        return (
                          <>
                            <SpinnerWrapper onClick={handlePlay}>
                              <Tooltip title="Start">
                                <PlayArrow
                                  style={{
                                    width: "18px",
                                    height: "18px",
                                    color: "#5cb85c",
                                  }}
                                />
                              </Tooltip>
                            </SpinnerWrapper>
                            <SpinnerWrapper>
                              <Tooltip title="Download">
                                <Download
                                  color="disabled"
                                  style={{
                                    width: "18px",
                                    height: "18px",
                                  }}
                                />
                              </Tooltip>
                            </SpinnerWrapper>
                          </>
                        );
                      } else {
                        return (
                          <>
                            <SpinnerWrapper onClick={handlePlay}>
                              <Tooltip title="Start">
                                <PlayArrow
                                  style={{
                                    width: "18px",
                                    height: "18px",
                                    color: "#5cb85c",
                                  }}
                                />
                              </Tooltip>
                            </SpinnerWrapper>
                          </>
                        );
                      }
                    }
                  })()}
                </TableCell>
              );
            } else if (
              key["field"] === "start_time" ||
              key["field"] === "end_time" ||
              key["field"] === "created_at"
            ) {
              let datetime = IsoToLocalTime(data[key["field"]]);
              return (
                <Tooltip title={datetime}>
                  <TableCell
                    className="textContainer"
                    style={{
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                      color: "#414141",
                      width: "200px",
                    }}
                  >
                    {datetime}
                  </TableCell>
                </Tooltip>
              );
            } else if (key["field"] === "job_type") {
              let value = getJobTypeDisplayName(data[key["field"]]);
              return (
                <Tooltip title={value}>
                  <TableCell
                    className="textContainer"
                    style={{
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                      color: "#414141",
                      width: "200px",
                    }}
                  >
                    {value}
                  </TableCell>
                </Tooltip>
              );
            } else {
              let value = dataRenderer(data[key["field"]]);
              return (
                <Tooltip title={value}>
                  <TableCell
                    className="textContainer"
                    style={{
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                      color: "#414141",
                      width: "200px",
                    }}
                  >
                    {value}
                  </TableCell>
                </Tooltip>
              );
            }
          })}
      </TableRow>
      {responseCount !== null && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {job_type === "delete_leads" ? (
            <DialogTitle id="alert-dialog-title" style={{ fontWeight: "bold" }}>
              Confirmation: Delete {responseCount}{" "}
              {responseCount === 1 ? "lead" : "leads"}?
            </DialogTitle>
          ) : (
            <DialogTitle id="alert-dialog-title" style={{ fontWeight: "bold" }}>
              Confirmation:{" "}
              {job_type === "reassign_leads" ? "Reassign" : "Export"}{" "}
              {responseCount} {responseCount === 1 ? "lead" : "leads"}?
            </DialogTitle>
          )}

          {job_type === "delete_leads" && (
            <>
              <DialogContent>
                <FormControlLabel
                  style={{ alignItems: "flex-start" }}
                  control={
                    <Checkbox
                      checked={firstConditionChecked}
                      onChange={(e) => {
                        setFirstConditionChecked(e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Label>
                      I acknowledge that this action is irrecoverable and
                      confirm that I want to proceed with the action.
                    </Label>
                  }
                />

                {markedImportantStausList.length > 0 && (
                  <>
                    <FormControlLabel
                      style={{ alignItems: "flex-start", marginBottom: "20px" }}
                      control={
                        <Checkbox
                          checked={secondConditionChecked}
                          onChange={(e) => {
                            setSecondConditionChecked(e.target.checked);
                          }}
                        />
                      }
                      label={
                        <>
                          <Label style={{ marginBottom: "20px" }}>
                            By checking this box and running this job, you
                            acknowledge that the selected records with the
                            following statuses will be permanently deleted
                          </Label>
                          <InputWrapper>
                            <ColumnWrapper>
                              {markedImportantStausList.map((item) => {
                                return (
                                  <IconTextWrapper>
                                    <FiberManualRecord
                                      style={{
                                        width: "12px",
                                        height: "12px",
                                        marginTop: "3px",
                                        color: "gray",
                                      }}
                                    />
                                    <Detail>{item}</Detail>
                                  </IconTextWrapper>
                                );
                              })}
                            </ColumnWrapper>
                          </InputWrapper>
                        </>
                      }
                    />
                  </>
                )}
              </DialogContent>
            </>
          )}
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={startJob}
              disabled={job_type === "delete_leads" && !canStartDeleteJob()}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Backdrop
        open={loading}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        onClick={() => {
          setLoading(false);
        }}
      >
        <CircularProgress style={{ color: "white" }} />
      </Backdrop>
    </>
  );
};

export default JobRowComponent;
