import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Box, TextField } from "@mui/material";
const DatetimeFieldWidget = ({ value, handleChange, min, max }) => {
  return (
    <Box sx={{ width: "250px" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          minDateTime={min}
          maxDateTime={max}
          value={value}
          onChange={(event) => {
            handleChange(event);
          }}
          renderInput={(params) => <TextField {...params} />}
          label="Pick Date and Time"
        />
      </LocalizationProvider>
    </Box>
  );
};

export default DatetimeFieldWidget;
