import {
  Box,
  CircularProgress,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  dataRenderer,
  getColumnHeaderWidthByStringLength,
  hasReportAccess,
} from "../../Functions";
import { getPredefinedReportDetailsApi } from "../../Api";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
const Wrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 264px);`};
  background-color: white;
  display: flex;
  flex-direction: column;
`;
const AggregateReport = ({
  reportID,
  setRefresh,
  refresh,
  columnsForDatatable,
  setColumnsForDatatable,
  setColumns,
  row,
  setRow,
  lastRowData,
  setLastRowData,
  generateReportFilterPayload,
  checkIfFilterDisabled,
}) => {
  const componentRef = useRef(null);
  const [loading, setLoading] = useState(true);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));

  const onFirstDataRendered = (params) => {
    const autoSizeColumns = columnsForDatatable
      .filter((col) => col.autoSize)
      .map((col) => col.field);
    params.columnApi.autoSizeColumns(autoSizeColumns);
  };

  function snakeCaseToTitleCase(snakeCaseStr) {
    return snakeCaseStr
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const generateColumnsData = ({ data }) => {
    let tempList = [];
    data["data"].forEach((item) => {
      if (!tempList.includes(item[data["col_header"]])) {
        tempList.push(item[data["col_header"]]);
      }
    });
    tempList.unshift(data["row_header"]);
    tempList.push("Total");
    return tempList;
  };

  const getLeadList = async () => {
    setRow([]);
    let payload = {};
    let queryPayload = generateReportFilterPayload();
    if (Object.keys(queryPayload).length > 0) {
      payload["query"] = queryPayload;
    } else {
      if (checkIfFilterDisabled() === false) {
        let today = moment().startOf("day").toISOString();
        payload["query"] = {
          created_at: {
            value: {
              start: today,
              end: today,
            },
            op: "between",
          },
        };
      }
    }
    let response = await getPredefinedReportDetailsApi({
      reportID: reportID,
      payload: payload,
      queryParams: {},
    });

    if (Object.keys(response).length > 0) {
      let tempColumnData = generateColumnsData({ data: response });
      setColumns(tempColumnData);

      let columns = getColumnsForDatatable({
        list: tempColumnData,
        rowHeader: response["row_header"],
      });
      setColumnsForDatatable(columns);
      let tempData = generateTableData({
        data: response,
        columnList: tempColumnData,
      });
      setRow(tempData);
    } else {
      setRow([]);
      setColumnsForDatatable([]);
    }
  };

  const getRowSumTotal = ({ data, rowHeader }) => {
    let total = 0;
    Object.keys(data).forEach((key) => {
      if (key !== rowHeader) {
        if (data[key] !== null && data[key] !== "") {
          total += data[key];
        }
      }
    });
    return total;
  };

  const getColumnsForDatatable = ({ list, rowHeader }) => {
    const columns_list = [];
    let dataLength = list.length;
    list.forEach((key, idx) => {
      let tempObj = {
        field: key,
        headerName: snakeCaseToTitleCase(key),
        sortable: true,
        cellRenderer: (params) => {
          let value = dataRenderer(params.data[key]);
          return (
            <Tooltip title={value} placement="bottom-start">
              {value}
            </Tooltip>
          );
        },
      };
      if (key !== rowHeader) {
        if (key === "Total") {
          tempObj["cellClass"] = "ag-cell-last-column";
        } else {
          tempObj["cellStyle"] = { "text-align": "center" };
        }
      }

      if (idx === dataLength - 2) {
        tempObj["minWidth"] = getColumnHeaderWidthByStringLength(key);
        tempObj["flex"] = 1;
      } else {
        tempObj["autoSize"] = true;
        tempObj["maxWidth"] = 500;
      }
      columns_list.push(tempObj);
    });
    let len = columns_list.length;
    if (len > 1) {
      columns_list[0]["pinned"] = "left";
    }
    return columns_list;
  };

  const getLastRowData = ({ tableData, columnList, rowHeader }) => {
    let tempObj = {};
    columnList.forEach((column) => {
      if (column !== rowHeader) {
        tableData.forEach((data) => {
          if (data.hasOwnProperty(column)) {
            if (tempObj.hasOwnProperty(column)) {
              tempObj[column] += data[column];
            } else {
              tempObj[column] = data[column];
            }
          }
        });
      }
    });
    tempObj[rowHeader] = "Total";
    return tempObj;
  };

  const generateTableData = ({ data, columnList }) => {
    let newList = [];
    data["data"].forEach((item) => {
      let columnHeader = data["col_header"];
      let rowHeader = data["row_header"];
      let index = newList.findIndex(
        (obj) => obj[rowHeader] === item[rowHeader]
      );
      if (index !== -1) {
        let tempObj = { ...newList[index] };
        if (tempObj.hasOwnProperty(item[columnHeader])) {
          tempObj[item[columnHeader]] += item["count"];
        } else {
          tempObj[item[columnHeader]] = item["count"];
        }
        newList[index] = tempObj;
      } else {
        let tempObj = {
          [rowHeader]: item[rowHeader],
          [item[columnHeader]]: item["count"],
        };
        newList.push(tempObj);
      }
    });
    newList.forEach((obj) => {
      let total = getRowSumTotal({
        data: { ...obj },
        rowHeader: data["row_header"],
      });
      obj["Total"] = total;
    });
    let lastRowData = getLastRowData({
      tableData: newList,
      columnList: columnList,
      rowHeader: data["row_header"],
    });
    setLastRowData([lastRowData]);
    return newList;
  };

  const init = async () => {
    if (hasReportAccess()) {
      await getLeadList();
    }

    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  const refetch = async () => {
    setLoading(true);
    await getLeadList();
    setLoading(false);
    setRefresh(false);
  };

  useEffect(() => {
    if (refresh) {
      refetch();
    }
  }, [refresh]);

  return !loading ? (
    row.length > 0 ? (
      <Wrapper
        ref={componentRef}
        sx={{
          "& .ag-row-pinned": {
            fontWeight: "bold",
            backgroundColor: "#a2bfde",
          },
          "& .ag-cell-last-column": {
            fontWeight: "bold",
            backgroundColor: "#a2bfde",
            textAlign: "center",
          },

          "& .ag-header-cell-label": {
            justifyContent: "center",
          },
          "& .ag-theme-alpine .ag-layout-auto-height .ag-center-cols-clipper, .ag-theme-alpine .ag-layout-auto-height .ag-center-cols-container, .ag-theme-alpine .ag-layout-print .ag-center-cols-clipper, .ag-theme-alpine .ag-layout-print .ag-center-cols-container, .ag-theme-alpine-dark .ag-layout-auto-height .ag-center-cols-clipper, .ag-theme-alpine-dark .ag-layout-auto-height .ag-center-cols-container, .ag-theme-alpine-dark .ag-layout-print .ag-center-cols-clipper, .ag-theme-alpine-dark .ag-layout-print .ag-center-cols-container":
            {
              minHeight: "0px",
            },
        }}
      >
        <div className="ag-theme-alpine" style={{ width: "100%" }}>
          <AgGridReact
            domLayout="autoHeight"
            rowData={row}
            defaultColDef={defaultColDef}
            columnDefs={columnsForDatatable}
            animateRows={true}
            pagination={false}
            suppressColumnVirtualisation={true}
            suppressCellFocus
            pinnedBottomRowData={lastRowData}
            onFirstDataRendered={onFirstDataRendered}
          />
        </div>
      </Wrapper>
    ) : (
      <Box
        sx={{
          height: "calc(100vh - 217px)",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "white",
        }}
      >
        <Typography>No data found</Typography>
      </Box>
    )
  ) : (
    <Box
      sx={{
        height: "calc(100vh - 217px)",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "white",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default AggregateReport;
