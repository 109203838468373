import { Box, OutlinedInput, Typography, styled } from "@mui/material";
import React from "react";
import { Grid } from "@material-ui/core";
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;
const Header = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #a9a9a9;
  margin-bottom: 20px;
`;

const BillingInfo = ({ billingDetails, handleChange, showShippingAddress }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        overflowX: "hidden",
        "&::-webkit-scrollbar": {
          width: "0px",
        },
      }}
    >
      {showShippingAddress ? (
        <Header>Billing And Shipping Details</Header>
      ) : (
        <Header>Billing Details</Header>
      )}

      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <InputWrapper>
            <Label>Billing Name*</Label>
            <OutlinedInput
              style={{ height: "30px" }}
              inputProps={{
                min: 0,
                "aria-label": "weight",
                style: {
                  fontSize: "12px",
                },
              }}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              name="billing_name"
              required
              value={billingDetails["billing_name"]}
              placeholder="Enter value"
            />
          </InputWrapper>
        </Grid>
        <Grid item xs={12}>
          <InputWrapper>
            <Label>Billing Address</Label>
            <OutlinedInput
              multiline
              rows={3}
              inputProps={{
                min: 0,
                "aria-label": "weight",
                style: {
                  fontSize: "12px",
                },
              }}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              name="billing_address"
              value={billingDetails["billing_address"]}
              placeholder="Enter value"
            />
          </InputWrapper>
        </Grid>
        <Grid item xs={6}>
          <InputWrapper>
            <Label>Billing City</Label>
            <OutlinedInput
              style={{ height: "30px" }}
              inputProps={{
                min: 0,
                "aria-label": "weight",
                style: {
                  fontSize: "12px",
                },
              }}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              name="billing_city"
              value={billingDetails["billing_city"]}
              placeholder="Enter value"
            />
          </InputWrapper>
        </Grid>
        <Grid item xs={6}>
          <InputWrapper>
            <Label>Billing State</Label>
            <OutlinedInput
              style={{ height: "30px" }}
              inputProps={{
                min: 0,
                "aria-label": "weight",
                style: {
                  fontSize: "12px",
                },
              }}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              name="billing_state"
              value={billingDetails["billing_state"]}
              placeholder="Enter value"
            />
          </InputWrapper>
        </Grid>
        <Grid item xs={6}>
          <InputWrapper>
            <Label>Billing Country</Label>
            <OutlinedInput
              style={{ height: "30px" }}
              inputProps={{
                min: 0,
                "aria-label": "weight",
                style: {
                  fontSize: "12px",
                },
              }}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              name="billing_country"
              value={billingDetails["billing_country"]}
              placeholder="Enter value"
            />
          </InputWrapper>
        </Grid>
        <Grid item xs={6}>
          <InputWrapper>
            <Label>Billing Zipcode</Label>
            <OutlinedInput
              style={{ height: "30px" }}
              inputProps={{
                min: 0,
                "aria-label": "weight",
                style: {
                  fontSize: "12px",
                },
              }}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              name="billing_zipcode"
              value={billingDetails["billing_zipcode"]}
              placeholder="Enter value"
            />
          </InputWrapper>
        </Grid>
        <Grid item xs={6}>
          <InputWrapper>
            <Label>GST</Label>
            <OutlinedInput
              style={{ height: "30px" }}
              inputProps={{
                min: 0,
                "aria-label": "weight",
                style: {
                  fontSize: "12px",
                },
              }}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              name="billing_gst"
              value={billingDetails["billing_gst"]}
              placeholder="Enter value"
            />
          </InputWrapper>
        </Grid>

        {showShippingAddress && (
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <InputWrapper>
                <Label>Shipping Name*</Label>
                <OutlinedInput
                  style={{ height: "30px" }}
                  inputProps={{
                    min: 0,
                    "aria-label": "weight",
                    style: {
                      fontSize: "12px",
                    },
                  }}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  name="shipping_name"
                  required
                  value={billingDetails["shipping_name"]}
                  placeholder="Enter value"
                />
              </InputWrapper>
            </Grid>
            <Grid item xs={12}>
              <InputWrapper>
                <Label>Shipping Address</Label>
                <OutlinedInput
                  multiline
                  rows={3}
                  inputProps={{
                    min: 0,
                    "aria-label": "weight",
                    style: {
                      fontSize: "12px",
                    },
                  }}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  name="shipping_address"
                  value={billingDetails["shipping_address"]}
                  placeholder="Enter value"
                />
              </InputWrapper>
            </Grid>
            <Grid item xs={6}>
              <InputWrapper>
                <Label>Shipping City</Label>
                <OutlinedInput
                  style={{ height: "30px" }}
                  inputProps={{
                    min: 0,
                    "aria-label": "weight",
                    style: {
                      fontSize: "12px",
                    },
                  }}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  name="shipping_city"
                  value={billingDetails["shipping_city"]}
                  placeholder="Enter value"
                />
              </InputWrapper>
            </Grid>
            <Grid item xs={6}>
              <InputWrapper>
                <Label>Shipping State</Label>
                <OutlinedInput
                  style={{ height: "30px" }}
                  inputProps={{
                    min: 0,
                    "aria-label": "weight",
                    style: {
                      fontSize: "12px",
                    },
                  }}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  name="shipping_state"
                  value={billingDetails["shipping_state"]}
                  placeholder="Enter value"
                />
              </InputWrapper>
            </Grid>
            <Grid item xs={6}>
              <InputWrapper>
                <Label>Shipping Country</Label>
                <OutlinedInput
                  style={{ height: "30px" }}
                  inputProps={{
                    min: 0,
                    "aria-label": "weight",
                    style: {
                      fontSize: "12px",
                    },
                  }}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  name="shipping_country"
                  value={billingDetails["shipping_country"]}
                  placeholder="Enter value"
                />
              </InputWrapper>
            </Grid>
            <Grid item xs={6}>
              <InputWrapper>
                <Label>Shipping Zipcode</Label>
                <OutlinedInput
                  style={{ height: "30px" }}
                  inputProps={{
                    min: 0,
                    "aria-label": "weight",
                    style: {
                      fontSize: "12px",
                    },
                  }}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  name="shipping_zipcode"
                  value={billingDetails["shipping_zipcode"]}
                  placeholder="Enter value"
                />
              </InputWrapper>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default BillingInfo;
