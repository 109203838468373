import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { Box, Tooltip } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import { useMemo } from "react";
import { CircularProgress, Typography } from "@mui/material";
import PaginationComponent from "../pagination/PaginationComponent";
import { pageSize } from "../../Config";
import { getOrganizationContactsListApi } from "../../Api";
import { FormattedAgGridColumns } from "../../ColumnUtils";
import rootStore from "../../stores/RootStore";

const Container = styled(Box)`
  width: 100%;
  position: relative;
  ${`min-height: calc(100vh - 246px);`};
`;
const Wrapper = styled(Box)`
  display: flex;
`;

const DetailsWrapper = styled(Box)`
  height: fit-content;
  width: 100%;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;

const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
`;
const PaginationWrapper = styled(Box)`
  padding: 0px 20px;
  background-color: white;
`;
const TextHighlight = styled(Typography)`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  color: #3f51b5;
  cursor: pointer;
`;
const requiredColumns = ["phone", "city", "email", "title"];

const OrganizationContactsTab = (props) => {
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(pageSize);
  const [columns, setColumns] = useState([]);

  const getOrganizationContactList = async ({ page, page_size }) => {
    let response = await getOrganizationContactsListApi({
      organizationID: props.id,
      page: page,
      page_size: page_size,
    });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
      setCount(response.item_count);
    } else {
      setRow([]);
      setCount(0);
    }
  };

  const getContactColumns = async () => {
    let filteredColumns = [...rootStore.contactStore.columnsList].filter(
      (column) => requiredColumns.includes(column["column_name"])
    );
    let formattedColumns = FormattedAgGridColumns(filteredColumns);
    if (formattedColumns.length > 0) {
      let lastIndex = formattedColumns.length - 1;
      formattedColumns[lastIndex]["flex"] = 1;
    }
    setColumns(formattedColumns);
  };

  const init = async () => {
    setLoading(true);
    await getOrganizationContactList({ page: 0, page_size: pageSize });
    await getContactColumns();
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const handlePageSize = async (e) => {
    setLoading(true);
    setPage(0);
    setSize(e.target.value);
    await getOrganizationContactList({ page: 0, page_size: e.target.value });
    setLoading(false);
  };
  const handlePagination = async (e, page) => {
    setLoading(true);
    setPage(page - 1);
    setLoading(true);
    await getOrganizationContactList({ page: page - 1, page_size: size });
    setLoading(false);
  };

  const nameColumn = [
    {
      field: "name",
      headerName: "NAME",

      width: 230,
      cellRenderer: (params) => {
        let parts = [params.data.first_name, params.data.last_name].filter(
          Boolean
        );
        let fullName = parts.length ? parts.join(" ") : "-";
        return (
          <>
            <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
              <Tooltip title={fullName}>
                <TextHighlight>{fullName}</TextHighlight>
              </Tooltip>
            </Box>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Container>
        <Wrapper>
          <DetailsWrapper>
            {!loading ? (
              <TableWrapper>
                <div className="ag-theme-alpine" style={{ width: "100%" }}>
                  <AgGridReact
                    domLayout="autoHeight"
                    gridOptions={gridOptions}
                    rowData={row}
                    defaultColDef={defaultColDef}
                    columnDefs={nameColumn.concat(columns)}
                    animateRows={true}
                    suppressCellFocus
                    className="paginated-ag-grid"
                  />
                </div>
                <PaginationWrapper>
                  <PaginationComponent
                    page_no={page}
                    row={row}
                    page_size={size}
                    size={size}
                    count={count}
                    handlePageSize={handlePageSize}
                    handlePage={handlePagination}
                  />
                </PaginationWrapper>
              </TableWrapper>
            ) : (
              <TableWrapper
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </TableWrapper>
            )}
          </DetailsWrapper>
        </Wrapper>
      </Container>
    </>
  );
};

export default observer(OrganizationContactsTab);
