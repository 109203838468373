import {
  AppBar,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Modal,
  Toolbar,
} from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import {
  dataRenderer,
  getLocalizedText,
  getLocalTimeStringFromIsoDateString,
  getWorkTimingBreakChipColorTheme,
} from "../../../Functions";
const dialogWidth = 420;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const ChipText = styled(Typography)`
  font: normal normal 600 10px Open Sans;
`;
const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;
const ViewDayConfigure = ({ details, setOpen, open }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const renderBreakData = ({ data }) => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <ChipText style={{ fontSize: "12px" }}>{data["title"]}</ChipText>
        <ChipText>{`(${getLocalTimeStringFromIsoDateString(
          data["start_time"]
        )} - ${getLocalTimeStringFromIsoDateString(
          data["end_time"]
        )})`}</ChipText>
      </Box>
    );
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <AppBar
              component={"nav"}
              elevation={0}
              position="sticky"
              color="inherit"
              sx={{
                top: 0,
                bottom: "auto",
                width: "100%",
                right: 0,
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                }}
              >
                <HeaderWrapper>
                  <Header>Work Day Details</Header>
                </HeaderWrapper>
              </Toolbar>
            </AppBar>
            <Divider style={{ margin: "0px 16px" }} />
            <Container>
              <Box
                style={{
                  height: "100%",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>Start Time</Label>
                      <Detail>
                        {getLocalTimeStringFromIsoDateString(
                          details["start_time"]
                        )}
                      </Detail>
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>End Time</Label>
                      <Detail>
                        {getLocalTimeStringFromIsoDateString(
                          details["end_time"]
                        )}
                      </Detail>
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>End Time Buffer (Minutes)</Label>
                      <Detail>
                        {dataRenderer(details["end_time_buffer"])}
                      </Detail>
                    </InputWrapper>
                  </Grid>

                  {details["breaks"].length > 0 && (
                    <Grid item xs={12}>
                      <Box
                        component={"fieldset"}
                        sx={{
                          border: "1px solid #cccccc",
                          padding: "20px",
                          paddingTop: "12px",
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          flexWrap: "wrap",
                          maxHeight: "100px",
                          overflowY: "auto",
                        }}
                      >
                        <legend
                          style={{
                            width: "auto",
                            marginBottom: "0px",
                            font: "normal normal 600 12px Open Sans",
                            color: "#979797",
                          }}
                        >
                          Breaks
                        </legend>
                        {details["breaks"].map((obj, idx) => {
                          return (
                            <Chip
                              color={getWorkTimingBreakChipColorTheme({
                                breakData: obj,
                              })}
                              style={{
                                height: "fit-content",
                                padding: "8px",
                              }}
                              label={renderBreakData({ data: obj })}
                              key={idx}
                            />
                          );
                        })}
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Container>
            <AppBar
              position="static"
              color="inherit"
              elevation={0}
              sx={{
                top: "auto",
                bottom: 0,
                width: "100%",
                borderRadius: "0px 0px 8px 8px",
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                  width: dialogWidth,
                  position: "relative",
                }}
              >
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      color="inherit"
                      style={{
                        fontSize: "12px",
                        textTransform: "none",
                        fontWeight: "bold",
                      }}
                    >
                      {getLocalizedText("close")}
                    </Button>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default ViewDayConfigure;
