import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
} from "@material-ui/core";
import { ChevronRight, Delete, Edit, Visibility } from "@material-ui/icons";
import { FolderFilesColumnHeaders } from "../../Db";
import rootStore from "../../stores/RootStore";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { pageSize } from "../../Config";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { useNavigate, useParams } from "react-router-dom";
import AddFileSidePanel from "../../components/files_page_components/AddFileSidepanel";
import ViewRichTextDialog from "../../components/files_page_components/ViewRichTextDialog";
import {
  deleteDocumentApi,
  deleteFolderApi,
  getFolderFileListApi,
  updateDocumentApi,
} from "../../Api";
import PreviewPdfDialog from "../../components/files_page_components/PreviewPdfDialog";
import PreviewImageDialog from "../../components/files_page_components/PreviewImageDialog";
import { checkFeaturePermission } from "../../Functions";
import MessageWithLoadingStateCustomDialog from "../../components/alert_dialogue/MessageWithLoadingStateCustomDialog";
import FileUploadComponent from "../../components/file_upload_component/FileUploadComponent";
import CustomConfirmationDialog from "../../components/alert_dialogue/CustomConfirmationDialog";
import CustomLoadingDialog from "../../components/alert_dialogue/CustomLoadingDialog";
import CustomErrorMessageDialog from "../../components/alert_dialogue/CustomErrorMessageDialog";
const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 84px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;
const ProgressWrapper = styled(Box)`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: flex-end;
  min-height: 40px;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 236px);`};
  background-color: white;
  padding: 20px;
`;
const PaginationWrapper = styled(Box)`
  padding: 0px 20px;
  background-color: white;
`;

const TopSectionWrapper = styled(Box)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 12px 0px;
`;

const FilesDetailsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(pageSize);
  const [openAddFileSidepanel, setOpenAddFileSidepanel] = useState(false);
  const [viewRichText, setViewRichText] = useState(false);
  const [viewImageDialog, setViewImageDialog] = useState(false);
  const [viewPdfDialog, setViewPdfDialog] = useState(false);
  const [richTextData, setRichTextData] = useState("");
  const [selectedFileUrl, setSelectedFileUrl] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [selectedFileData, setSelectedFileData] = useState({});
  const [fetchingApi, setFetchingApi] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSubmitResponseDialog, setOpenSubmitResponseDialog] =
    useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [selectedFileID, setSelectedFileID] = useState("");
  const [initLoading, setInitLoading] = useState(true);
  const [
    showDeleteFolderConfirmationDialog,
    setShowDeleteFolderConfirmationDialog,
  ] = useState(false);
  const [openLoadingDialog, setOpenLoadingDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  const getFilesList = async ({ page, page_size }) => {
    setRow([]);
    let response = await getFolderFileListApi({
      folderID: id,
      page: page,
      page_size: page_size,
    });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
      setCount(response.item_count);
    } else {
      setRow([]);
      setCount(0);
    }
  };

  const init = async () => {
    setLoading(true);
    await getFilesList({ page: 0, page_size: pageSize });
    setLoading(false);
    setInitLoading(false);
  };

  const handlePageSize = async (e) => {
    setLoading(true);
    setPage(0);
    setSize(e.target.value);
    await getFilesList({ page: 0, page_size: e.target.value });
    setLoading(false);
  };
  const handlePagination = async (e, page) => {
    setLoading(true);
    setPage(page - 1);
    setLoading(true);
    await getFilesList({ page: page - 1, page_size: size });
    setLoading(false);
  };

  useEffect(() => {
    if (refresh === true) {
      init();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    init();
    rootStore.authStore.updateLeadDetailStatus();
  }, []);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const getFilePattern = (fileType) => {
    if (fileType.includes("image")) {
      return ".jpg,.png,.jpeg,.svg";
    } else if (fileType.includes("pdf")) {
      return ".pdf";
    }
    return "";
  };

  const handleFileUpload = async (event, selectedFileData) => {
    let file = event.target.files[0];
    const uploadFile = async () => {
      setFetchingApi(true);
      setOpenSubmitResponseDialog(true);
      let formData = new FormData();
      formData.append("title", selectedFileData.title);
      formData.append("desc", selectedFileData.desc);
      formData.append("file_type", selectedFileData.file_type);
      formData.append("file", file);
      formData.append("document_type", "file");
      let response = await updateDocumentApi({
        folderID: id,
        documentID: selectedFileData["id"],
        payload: formData,
      });
      if (!response.hasError()) {
        setIsSubmitFail(false);
        setRefresh(true);
        setSuccessMessage("File uploaded successfully!");
        setFetchingApi(false);
      } else {
        setErrorMessage(response.errorMessage);
        setIsSubmitFail(true);
        setFetchingApi(false);
      }
    };
    if (file !== null && file !== undefined) {
      uploadFile();
    }
  };

  const handleCustomColumnDelete = async () => {
    setShowConfirmationDialog(false);
    setFetchingApi(true);
    setOpenSubmitResponseDialog(true);
    let response = await deleteDocumentApi({
      folderID: id,
      documentID: selectedFileID,
    });
    if (!response.hasError()) {
      setIsSubmitFail(false);
      setRefresh(true);
      setSuccessMessage("File deleted successfully!");
      setFetchingApi(false);
    } else {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
      setFetchingApi(false);
    }
    setSelectedFileID("");
  };

  const handleDeleteFolder = async () => {
    setShowDeleteFolderConfirmationDialog(false);
    setFetchingApi(true);
    setOpenLoadingDialog(true);
    let response = await deleteFolderApi({
      folderID: id,
    });
    if (!response.hasError()) {
      setShowErrorDialog(false);
      setFetchingApi(false);
      setOpenLoadingDialog(false);
      navigate("/files");
    } else {
      setErrorMessage(response.errorMessage);
      setFetchingApi(false);
      setOpenLoadingDialog(false);
      setShowErrorDialog(true);
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "ACTIONS",
      flex: 1,
      width: 100,
      cellRenderer: (params) => {
        if (params.data.document_type !== null) {
          return (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                columnGap: "10px",
              }}
            >
              {checkFeaturePermission("files") && (
                <>
                  {params.data.document_type === "file" ? (
                    <FileUploadComponent
                      getFilePattern={() =>
                        getFilePattern(params.data.file_type)
                      }
                      handleFileUpload={(event) => {
                        handleFileUpload(event, params.data);
                      }}
                    />
                  ) : (
                    <IconButton
                      style={{ padding: "0px" }}
                      color="primary"
                      onClick={() => {
                        setSelectedFileData(params.data);
                        setIsEdit(true);
                        setOpenAddFileSidepanel(true);
                      }}
                    >
                      <Edit
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </IconButton>
                  )}
                </>
              )}
              <IconButton
                color="primary"
                style={{ padding: "0px" }}
                onClick={() => {
                  if (params.data.document_type === "file") {
                    if (params.data.file_type.includes("image")) {
                      setSelectedFileUrl(params.data.file);
                      setViewImageDialog(true);
                    } else if (params.data.file_type.includes("pdf")) {
                      setSelectedFileUrl(params.data.file);
                      setViewPdfDialog(true);
                    }
                  } else if (params.data.document_type === "text") {
                    setRichTextData(params.data.data);
                    setViewRichText(true);
                  }
                }}
              >
                <Visibility
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              </IconButton>
              {checkFeaturePermission("files") && (
                <IconButton
                  style={{ padding: "0px" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedFileID(params.data.id);
                    setShowConfirmationDialog(true);
                  }}
                >
                  <Delete
                    color="error"
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </IconButton>
              )}
            </Box>
          );
        } else {
          return (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                columnGap: "10px",
              }}
            >
              <IconButton style={{ padding: "0px" }} disabled={true}>
                <Edit
                  color="disabled"
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              </IconButton>
              <IconButton style={{ padding: "0px" }} disabled={true}>
                <Visibility
                  color="disabled"
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              </IconButton>

              {checkFeaturePermission("files") && (
                <IconButton
                  style={{ padding: "0px" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedFileID(params.data.id);
                    setShowConfirmationDialog(true);
                  }}
                >
                  <Delete
                    color="error"
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </IconButton>
              )}
            </Box>
          );
        }
      },
    },
  ];

  return (
    <>
      <ParentContainer>
        {!initLoading ? (
          <Container>
            <TopSectionWrapper>
              <TopWrapper>
                <Header
                  style={{ color: "#185DD2", cursor: "pointer" }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Folders
                </Header>
                <ChevronRight style={{ width: "16px", height: "16px" }} />
                <Header>{id}</Header>
              </TopWrapper>

              {checkFeaturePermission("files") && (
                <Button
                  disabled={row.length > 0 || loading}
                  style={{
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "120px",
                    height: "40px",
                    marginRight: "20px",
                    whiteSpace: "nowrap",
                  }}
                  onClick={() => {
                    setShowDeleteFolderConfirmationDialog(true);
                  }}
                  color="secondary"
                  variant="contained"
                >
                  <Delete style={{ width: "20px", height: "20px" }} /> Delete
                </Button>
              )}
              {checkFeaturePermission("files") && (
                <Button
                  onClick={() => {
                    setOpenAddFileSidepanel(true);
                  }}
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "160px",
                    height: "40px",
                  }}
                >
                  Add a File
                </Button>
              )}
            </TopSectionWrapper>
            <TableWrapper>
              {!loading ? (
                <div className="ag-theme-alpine" style={{ width: "100%" }}>
                  <AgGridReact
                    domLayout="autoHeight"
                    gridOptions={gridOptions}
                    rowData={row}
                    defaultColDef={defaultColDef}
                    columnDefs={FolderFilesColumnHeaders.concat(actionColumn)}
                    animateRows={true}
                    suppressCellFocus
                    pagination={false}
                    paginationPageSize={pageSize}
                    className="paginated-ag-grid"
                  />
                </div>
              ) : (
                <ProgressWrapper>
                  <CircularProgress />
                </ProgressWrapper>
              )}
            </TableWrapper>
            <PaginationWrapper>
              <PaginationComponent
                page_no={page}
                row={row}
                page_size={size}
                size={size}
                count={count}
                handlePageSize={handlePageSize}
                handlePage={handlePagination}
              />
            </PaginationWrapper>
          </Container>
        ) : (
          <Container
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Container>
        )}
      </ParentContainer>
      <Footer />
      {openAddFileSidepanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openAddFileSidepanel}
          onClose={() => {
            setOpenAddFileSidepanel(false);
            setIsEdit(false);
          }}
        >
          <AddFileSidePanel
            setOpen={setOpenAddFileSidepanel}
            open={openAddFileSidepanel}
            folderID={id}
            setRefresh={setRefresh}
            isEdit={isEdit}
            editableData={selectedFileData}
            setIsEdit={setIsEdit}
          />
        </Drawer>
      )}
      {viewRichText && (
        <ViewRichTextDialog
          open={viewRichText}
          setOpen={setViewRichText}
          textData={richTextData}
        />
      )}
      {viewPdfDialog && (
        <PreviewPdfDialog
          open={viewPdfDialog}
          setOpen={setViewPdfDialog}
          file={selectedFileUrl}
        />
      )}
      {viewImageDialog && (
        <PreviewImageDialog
          open={viewImageDialog}
          setOpen={setViewImageDialog}
          file={selectedFileUrl}
          isCreate={false}
        />
      )}

      {openSubmitResponseDialog && (
        <MessageWithLoadingStateCustomDialog
          open={openSubmitResponseDialog}
          setOpen={setOpenSubmitResponseDialog}
          successMessage={successMessage}
          errorMessage={errorMessage}
          loading={fetchingApi}
          isError={isSubmitFail}
        />
      )}
      {showConfirmationDialog && (
        <CustomConfirmationDialog
          message={"Are you sure you want to delete this file?"}
          open={showConfirmationDialog}
          setOpen={setShowConfirmationDialog}
          handleConfirm={() => {
            handleCustomColumnDelete();
          }}
        />
      )}

      {showDeleteFolderConfirmationDialog && (
        <CustomConfirmationDialog
          message={"Are you sure you want to delete this folder?"}
          open={showDeleteFolderConfirmationDialog}
          setOpen={setShowDeleteFolderConfirmationDialog}
          handleConfirm={() => {
            handleDeleteFolder();
          }}
        />
      )}
      {openLoadingDialog && <CustomLoadingDialog open={openLoadingDialog} />}
      {showErrorDialog && (
        <CustomErrorMessageDialog
          open={showErrorDialog}
          setOpen={setShowErrorDialog}
          message={errorMessage}
        />
      )}
    </>
  );
};

export default observer(FilesDetailsPage);
