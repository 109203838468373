import { Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import React from "react";
import styled from "styled-components";
import logo from "../../../assets/logo.png";
import { Menu } from "@material-ui/icons";
import { useState } from "react";
import { myTheme } from "../../../themeUtils";
import analytics from "../../../Analytics";
const Container = styled.div`
  display: flex;
  position: relative;
  height: 64px;
  width: 100%;
  box-shadow: 0px 3px 6px #0000000d;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  background-color: white;
  z-index: 999;
  @media (max-width: 700px) {
    height: fit-content;
    padding-top: 20px;
  }
`;
const Wrapper = styled.div`
  width: ${(e) => (e.props ? "95%" : "80%")};
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 700px) {
    flex-direction: column;
    width: 90vw;
  }
`;
const Left = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
`;
const Right = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 700px) {
    display: ${(e) => (e.flag ? "flex" : "none")};
    justify-content: flex-start;
    margin-bottom: 20px;
  }
`;
const Center = styled.div`
  display: flex;
  flex: 2;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: auto;
  @media (max-width: 700px) {
    display: ${(e) => (e.flag ? "flex" : "none")};
    justify-content: flex-start;
    padding: 8px 0px;
  }
`;
const Link = styled.a`
  text-decoration: none;
  color: black;
`;
const LinkItem = styled.p`
  font: normal normal 600 15px/20px poppins;
  margin: 0px;
  cursor: pointer;
  margin-right: 30px;
  color: black;
  @media (max-width: 700px) {
    margin-right: 12px;
    font: normal normal 600 16px poppins;
  }
`;
const Text = styled.p`
  margin: 0px;
  font: normal normal 500 15px/20px poppins;
  margin-right: 8px;
`;
const Image = styled.img`
  max-width: 180px;
  max-height: 48px;
  height: auto;
  width: auto;
  cursor: pointer;
  @media (max-width: 700px) {
    max-width: 150px;
    max-height: 48px;
    margin-bottom: 20px;
  }
`;
const MenuWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
  display: none;
  @media (max-width: 700px) {
    display: block;
  }
`;

const AppBar = (props) => {
  const [flag, setFlag] = useState(false);
  const handleMenu = () => {
    setFlag(!flag);
  };
  let navigate = useNavigate();
  return (
    <>
      <Container>
        <MenuWrapper onClick={handleMenu} flag={flag}>
          <Menu style={{ height: "34px", width: "34px" }} />
        </MenuWrapper>
        <Wrapper props={props.type === "login"}>
          <Left>
            <Image src={logo} alt="loading logo..." />
          </Left>
          {props.type !== "login" && (
            <Center flag={flag}>
              <Link href="#why-leadpixie">
                <LinkItem
                  onClick={() => {
                    analytics.triggerEvent(
                      4625000003,
                      "why_leadpixie_link_tap",
                      "landing_page",
                      "why_leadpixie_link",
                      {}
                    );
                  }}
                >
                  Why LeadPixie
                </LinkItem>
              </Link>
              <Link href="#pricing">
                <LinkItem
                  onClick={() => {
                    analytics.triggerEvent(
                      4625000004,
                      "pricing_link_tap",
                      "landing_page",
                      "pricing_link",
                      {}
                    );
                  }}
                >
                  Pricing
                </LinkItem>
              </Link>
              <Link href="#testimonials">
                <LinkItem
                  onClick={() => {
                    analytics.triggerEvent(
                      4625000005,
                      "testimonial_link_tap",
                      "landing_page",
                      "testimonial_link",
                      {}
                    );
                  }}
                >
                  Testimonials
                </LinkItem>
              </Link>
            </Center>
          )}
          {props.type !== "login" && (
            <Right flag={flag}>
              <Text>Have an account?</Text>
              <Button
                variant="outlined"
                style={{ color: myTheme.Button.colorBlue }}
                onClick={() => {
                  analytics.triggerEvent(
                    4625000002,
                    "signin_button_tap",
                    "landing_page",
                    "signin_button",
                    {}
                  );
                  navigate("/login");
                }}
              >
                SIGN IN
              </Button>
            </Right>
          )}
        </Wrapper>
      </Container>
    </>
  );
};

export default AppBar;
