import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { Box, TextField, styled } from "@mui/material";
const DateRangePickerWidget = ({
  minValue,
  maxValue,
  handleStartDate,
  handleEndDate,
  disabled,
}) => {
  const CustomizedDatePicker = styled(DatePicker)`
    & .MuiInputBase-input {
      font-size: 14px;
    }
  `;
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
      <Box sx={{ width: "100%" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CustomizedDatePicker
            maxDate={maxValue}
            label="Start Date"
            value={minValue}
            onChange={(event) => {
              handleStartDate(event);
            }}
            inputFormat="YYYY-MM-DD"
            renderInput={(params) => (
              <TextField sx={{ width: "100%" }} size="small" {...params} />
            )}
          />
        </LocalizationProvider>
      </Box>
      <Box sx={{ width: "100%" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CustomizedDatePicker
            disabled={disabled}
            label="End Date"
            value={maxValue}
            minDate={minValue}
            onChange={(event) => {
              handleEndDate(event);
            }}
            inputFormat="YYYY-MM-DD"
            renderInput={(params) => (
              <TextField sx={{ width: "100%" }} size="small" {...params} />
            )}
          />
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

export default DateRangePickerWidget;
