import React, { useEffect, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";

import styled from "styled-components";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import ProfileTab from "../../components/user_tabs/ProfileTab";
import UserActivityTab from "../../components/user_tabs/UserActivityTab";
import UserCallDetails from "../../components/user_tabs/UserCallDetails";
import { Button, IconButton, withStyles } from "@material-ui/core";
import whatsapp_icon from "../../assets/campaign_icons/whatsapp.png";

import { ChevronRight } from "@material-ui/icons";
import { fontWeight } from "@mui/system";
import { Divider } from "@mui/material";
import UserFileTab from "../../components/user_tabs/UserFileTab";
import DetailsTab from "../../components/project_tabs/DetailsTab";
import ProjectMemberTab from "../../components/project_tabs/ProjectMemberTab";
import ProjectTeamTab from "../../components/project_tabs/ProjectTeamTab";
import SourcesTab from "../../components/project_tabs/SourcesTab";
import StagesTab from "../../components/project_tabs/StagesTab";
import StatusTab from "../../components/project_tabs/StatusTab";
import StatusToStageTab from "../../components/project_tabs/StatusToStageTab";
import ColumnsTab from "../../components/project_tabs/ColumnsTab";
import JobsTab from "../../components/project_tabs/JobsTab";
import ProjectSettingTab from "../../components/project_tabs/ProjectSettingTab";
import { getAdminStatusesApi } from "../../Api";
const ParentContainer = styled.div`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled.div`
  ${`min-height: calc(100vh - 253px);`};
  margin: auto;
  margin-top: 70px;
  width: 95%;
`;
const Wrapper = styled.div`
  display: flex;
  position: relative;
  background-color: white;
  padding: 20px 20px 0px 20px;
  border: 2px solid #f9f9fc;
`;
const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 0px;
`;
const Text = styled.p`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Icon = styled.img`
  height: 42px;
  width: 42px;
  margin-right: 20px;
  cursor: pointer;
`;
const ProjectDetail = (props) => {
  const { filterStore } = rootStore;
  let navigate = useNavigate();
  let { id, name } = useParams();
  const [val, setVal] = useState(0);
  const [statusList, setStatusList] = useState([]);

  const CustomTab = withStyles({
    root: {
      backgroundColor: "white",
      color: "#4D4E4F",
      font: "normal normal 600 14px Open Sans",
      textTransform: "capitalize",
    },
    selected: {
      backgroundColor: "#E7EEFA",
      color: "#4079DA",
      font: "normal normal 600 14px Open Sans",
      textTransform: "capitalize",
    },
  })(Tab);
  const handleBreadCrump = () => {
    navigate(-1);
  };

  const getStatusList = async () => {
    try {
      let response = await getAdminStatusesApi(id);
      setStatusList(response.data);
    } catch (error) {
      console.log(error);
      setStatusList([]);
    }
  };
  useEffect(() => {
    getStatusList();
  }, []);

  return (
    <>
      <ParentContainer>
        <Container>
          <TopWrapper>
            <Text>Admin</Text>
            <ChevronRight style={{ width: "16px", height: "16px" }} />
            <Text
              style={{ color: "#185DD2", cursor: "pointer" }}
              onClick={handleBreadCrump}
            >
              Projects
            </Text>
            <ChevronRight style={{ width: "16px", height: "16px" }} />
            <Text>{name !== "null" ? name : id}</Text>
          </TopWrapper>
          <Row style={{ maxWidth: "90vw" }}>
            <Tabs
              variant="scrollable"
              value={val}
              textColor="primary"
              indicatorColor="primary"
              onChange={(event, newValue) => {
                setVal(newValue);
              }}
            >
              <CustomTab label="Project Settings" />
              <CustomTab label="Members" />
              <CustomTab label="Teams" />
              <CustomTab label="Sources" />
              <CustomTab label="Stages" />
              <CustomTab label="Statuses" />
              <CustomTab label="StatusToStage" />
              <CustomTab label="Columns" />
              <CustomTab label="Jobs" />
            </Tabs>
          </Row>
          <Wrapper>
            {(() => {
              if (val === 0) {
                return <ProjectSettingTab id={id} />;
              } else if (val === 1) {
                return <ProjectMemberTab id={id} />;
              } else if (val === 2) {
                return <ProjectTeamTab id={id} name={name} />;
              } else if (val === 3) {
                return <SourcesTab id={id} name={name} />;
              } else if (val === 4) {
                return <StagesTab id={id} name={name} />;
              } else if (val === 5) {
                return <StatusTab id={id} name={name} />;
              } else if (val === 6) {
                return <StatusToStageTab id={id} name={name} />;
              } else if (val === 7) {
                return <ColumnsTab id={id} name={name} />;
              } else if (val === 8) {
                return <JobsTab id={id} name={name} statusList={statusList} />;
              }
            })()}
          </Wrapper>
        </Container>
      </ParentContainer>
      <Footer />
    </>
  );
};

export default observer(ProjectDetail);
