import { TextField, styled } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
const CustomizedDateTimePicker = styled(DateTimePicker)`
  & .MuiInputBase-input {
    font-size: 12px;
    padding: 10.5px 14px;
    color: ${(e) => e.fieldColor};
  }
`;
const DateTimeField = ({
  value,
  onChange,
  required,
  disabled,
  fieldValueColor = "#4d4e4f",
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CustomizedDateTimePicker
        fieldColor={fieldValueColor}
        disabled={disabled}
        value={value}
        onChange={(event) => {
          onChange(event);
        }}
        inputFormat="YYYY-MM-DD HH:mm A"
        renderInput={(params) => (
          <TextField
            required={required}
            sx={{ width: "100%" }}
            size="small"
            {...params}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DateTimeField;