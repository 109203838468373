import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import NoteDetailsModal from "../notes_tab_modal/NoteDetailsModal";
import { styled } from "@mui/material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { StickyNote2 } from "@mui/icons-material";
import PaginationComponent from "../pagination/PaginationComponent";
import NoteCardWidget from "./card_widgets/NoteCardWidget";
import { observer } from "mobx-react-lite";
import rootStore from "../../stores/RootStore";

const Container = styled(Box)`
  width: 100%;
  position: relative;
  ${`min-height: calc(100vh - 343px);`};
`;
const Wrapper = styled(Box)`
  display: flex;
`;

const DetailsWrapper = styled(Box)`
  height: fit-content;
  width: 100%;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 265px);`};
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const NotesViewWidget = ({
  notesData,
  page,
  size,
  handlePagination,
  handlePageSize,
  count,
}) => {
  const [openView, setOpenView] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(notesData);
  }, [notesData]);
  return (
    <>
      <Container>
        <Wrapper>
          <DetailsWrapper>
            {data.length > 0 ? (
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                <Box
                  sx={{
                    "& .MuiTimeline-root": {
                      padding: "16px",
                      paddingRight: "0px",
                      paddingBottom: "0px",
                    },
                    "& .MuiTimelineItem-root:before": {
                      flex: 0,
                      padding: "0px",
                    },
                  }}
                >
                  <Timeline position="right">
                    <TableWrapper>
                      {data.map((noteData, idx) => {
                        return (
                          <TimelineItem>
                            <TimelineSeparator>
                              <TimelineDot>
                                <StickyNote2 />
                              </TimelineDot>
                              {idx !== data.length - 1 && <TimelineConnector />}
                            </TimelineSeparator>
                            <TimelineContent>
                              <NoteCardWidget
                                details={noteData}
                                key={idx}
                                handleView={() => {
                                  rootStore.userStore.updateSeenNoteIDs({
                                    noteID: noteData["id"],
                                  });
                                  setSelectedRow(noteData);
                                  setOpenView(true);
                                }}
                              />
                            </TimelineContent>
                          </TimelineItem>
                        );
                      })}
                    </TableWrapper>
                  </Timeline>
                </Box>
                <Box sx={{ padding: "0px 16px" }}>
                  <PaginationComponent
                    page_no={page}
                    row={data}
                    page_size={size}
                    size={size}
                    count={count}
                    handlePageSize={handlePageSize}
                    handlePage={handlePagination}
                  />
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  height: "calc(100vh - 456px)",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                No notes.
              </Box>
            )}
          </DetailsWrapper>
        </Wrapper>
      </Container>

      {openView && (
        <NoteDetailsModal
          setOpen={setOpenView}
          open={openView}
          data={selectedRow}
        />
      )}
    </>
  );
};

export default observer(NotesViewWidget);
