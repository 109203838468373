import { Visibility } from "@material-ui/icons";
import { Download } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import template_img from "../../../assets/template_image.png";
import { myTheme } from "../../../themeUtils";
const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 300px;
  height: auto;
  background-color: white;
  row-gap: 20px;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #bfbfbf;
  cursor: pointer;
  /* :hover {
    transform: scale(1.1);
  }
  transition: all 0.5s ease; */
`;
const Top = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;
const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 20px;
`;
const Text1 = styled.p`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;
const Text2 = styled.p`
  margin: 0px;
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
`;
const Text3 = styled.p`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
  color: ${myTheme.Button.colorBlue};
`;
const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  column-gap: 10px;
`;
const SpacedWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const CardComponent = () => {
  const navigate = useNavigate();
  return (
    <Container
      onClick={() => {
        navigate("/website-templates/preview");
      }}
    >
      <Top>
        <Image src={template_img} alt="loading..." />
      </Top>
      <Bottom>
        <Text1>SMS FS Website</Text1>
        <Text2>Static Website</Text2>
        {/* <SpacedWrapper>
          <Text3>Free</Text3>
        </SpacedWrapper> */}
        <ButtonWrapper>
          <Visibility color="primary" />
          <Download color="success" />
        </ButtonWrapper>
      </Bottom>
    </Container>
  );
};

export default CardComponent;
