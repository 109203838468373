import React, { useCallback, useState } from "react";
import { styled } from "@mui/material/styles";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Modal,
} from "@material-ui/core";
import Chip from "@mui/material/Chip";
import { addStatusApi, editStatusApi } from "../../Api";
import { useEffect } from "react";
import { myTheme } from "../../themeUtils";
import analytics from "../../Analytics";
import { getLocalizedText } from "../../Functions";
import { Typography } from "@mui/material";
import { Add } from "@material-ui/icons";

import MuiAlert from "@mui/material/Alert";
import ColorPickerModal from "../color_picker_modal/ColorPickerModal";
import { Rectangle } from "@mui/icons-material";
import { colorsList } from "../../Config";
import CustomFieldHeader from "../custom_tooltip_headers/CustomFieldHeader";
import CustomCheckboxHeader from "../custom_tooltip_headers/CustomCheckboxHeader";
import AddUpdateFieldDialog from "./AddUpdateFieldDialog";
import DraggableUpdateFieldChipComponent from "./draggable_chip_component/DraggableUpdateFieldChipComponent";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "10px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const Form = styled(Box)`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 450px;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Input = styled("input")`
  width: 100%;
  height: 30px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;
  border: 1px solid #bfbfbf;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const ButtonWrapper = styled(Box)`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;

const SubStatusContainer = styled(Box)`
  width: 100%;
  max-height: 80px;
  overflow-y: auto;
  border: none;
  /* margin-bottom: 20px; */
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;
  column-gap: 4px;
  padding: 4px 0px;
`;
const FlagText = styled(Typography)`
  margin: 0px;
  font: normal normal 500 12px Open Sans;
`;
const Message = styled(Typography)`
  font: normal normal normal 16px Open Sans;
  color: #4d4e4f;

  margin: 0px;
  text-align: center;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
`;

const defaultColor = "#4d4e4f";
const AddStatusModal = (props) => {
  const [user, setUser] = useState({ status: "", group: "" });
  const [subStatusList, setSubStatusList] = useState([]);
  const [flag, setFlag] = useState(false);
  const [isNotesMandatory, setIsNotesMandatory] = useState(false);
  const [selectedColumnList, setSelectedColumnList] = useState({});
  const [columnsList, setColumnsList] = useState([]);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [color, setColor] = useState("#4d4e4f");
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [isDeleteProtected, setIsDeleteProtect] = useState(false);
  const [openAddUpdateFieldDialog, setOpenAddUpdateFieldDialog] =
    useState(false);
  const handleClick = () => {
    setUser({ status: "", group: "" });
    setSubStatusList([]);
    props.setOpen(false);
    props.setIsEdit(false);
  };
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const editStatus = async () => {
    let data = {
      status: user.status,
      sub_status: subStatusList,
      status_id: props.editableData.id,
      is_visible_to_add: flag,
      notes_mandatory: isNotesMandatory,
      delete_protected: isDeleteProtected,
    };
    let tempList = [];
    Object.keys(selectedColumnList).forEach((key) => {
      let tempObj = {
        id: key,
        required: selectedColumnList[key]["required"],
        overwrite: selectedColumnList[key]["overwrite"],
        append: selectedColumnList[key]["append"],
      };
      tempList.push(tempObj);
    });
    data["update_fields"] = tempList;
    if (user.group !== "") {
      data["group"] = user.group;
    }
    if (color !== "") {
      data["color"] = color;
    }
    try {
      let response = await editStatusApi({
        id: props.id,
        data: data,
      });

      props.setRefresh(!props.refresh);
      setIsSubmitSuccess(true);
    } catch (error) {
      console.log(error);
      setIsSubmitFail(true);
      setErrorMessage(getLocalizedText("some_error_occured"));
    }
  };
  const addStatus = async () => {
    let data = {
      status: user.status,
      sub_status: subStatusList.length === 0 ? ["None"] : subStatusList,
      is_visible_to_add: flag,
      notes_mandatory: isNotesMandatory,
      delete_protected: isDeleteProtected,
    };
    let tempList = [];
    Object.keys(selectedColumnList).forEach((key) => {
      let tempObj = {
        id: key,
        required: selectedColumnList[key]["required"],
        overwrite: selectedColumnList[key]["overwrite"],
        append: selectedColumnList[key]["append"],
      };
      tempList.push(tempObj);
    });
    data["update_fields"] = tempList;
    if (user.group !== "") {
      data["group"] = user.group;
    }
    if (color !== "") {
      data["color"] = color;
    }
    try {
      let response = await addStatusApi({
        id: props.id,
        data: data,
      });

      props.setRefresh(!props.refresh);
      setIsSubmitSuccess(true);
    } catch (error) {
      console.log(error);
      setIsSubmitFail(true);
      setErrorMessage(getLocalizedText("some_error_occured"));
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (props.isEdit) {
      editStatus();
    } else {
      addStatus();
    }
  };
  const handleClose = () => {
    props.setOpen(false);
    props.setIsEdit(false);
  };
  const checkKeyDown = (e) => {
    if (e.code === "Enter") e.preventDefault();
  };
  const handleSubStatus = (e) => {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    setSubStatusList([...subStatusList, value]);
    e.target.value = "";
  };
  const handleDelete = (index) => {
    setSubStatusList(subStatusList.filter((e, i) => i !== index));
  };

  const getUniqueColorCodeListLength = () => {
    const uniqueColors = new Set(
      props.statusList
        .map((status) => status.color)
        .filter((color) => color !== null)
    );
    return Array.from(uniqueColors).length;
  };

  const setupDefaultColor = () => {
    const index = getUniqueColorCodeListLength();
    const resulatantColor =
      index > colorsList.length ? defaultColor : colorsList[index];
    setColor(resulatantColor);
  };

  const init = () => {
    let filteredColumns = [...rootStore.userStore.AllColumnsList].filter(
      (column) => !column["derived"] && column["editable"]
    );
    filteredColumns.sort((a, b) =>
      a["headerName"].toLowerCase().localeCompare(b["headerName"].toLowerCase())
    );
    setColumnsList(filteredColumns);
    if (props.isEdit === true) {
      analytics.triggerEvent(
        4625000213,
        "edit_status_form_load",
        "project_statuses_page",
        "",
        {}
      );
      setUser({
        status: props.editableData.status,
        group:
          props.editableData.group !== null ? props.editableData.group : "",
      });
      if (
        props.editableData.hasOwnProperty("color") &&
        props.editableData.color !== null
      ) {
        setColor(props.editableData.color);
      }
      setFlag(props.editableData.is_visible_to_add);
      setIsNotesMandatory(props.editableData.notes_mandatory);
      setIsDeleteProtect(props.editableData.delete_protected);
      setSubStatusList(props.editableData.sub_status);
      if (
        props.editableData.hasOwnProperty("update_fields") &&
        props.editableData.update_fields.length > 0
      ) {
        let tempList = {};
        props.editableData.update_fields.forEach((item) => {
          let index = filteredColumns.findIndex(
            (column) => column["id"] === item["column_meta"]["id"]
          );
          if (index !== -1) {
            tempList[item["column_meta"]["id"]] = {
              label: filteredColumns[index]["headerName"],
              required: item["required"],
              overwrite: item["overwrite"],
              append: item.hasOwnProperty("append") ? item["append"] : false,
            };
          }
        });
        setSelectedColumnList(tempList);
      }
    } else {
      analytics.triggerEvent(
        4625000209,
        "add_status_form_load",
        "project_statuses_page",
        "",
        {}
      );
      setupDefaultColor();
      setUser({ status: "", group: "" });
      setSubStatusList([]);
    }
  };
  useEffect(() => {
    init();
  }, []);

  const handleFlag = (e) => {
    setFlag(e.target.checked);
  };

  const canEditField = () => {
    if (props.isEdit === true) {
      if (props.editableData["default"] === true) {
        return false;
      }
    }
    return true;
  };

  const moveChip = useCallback(
    (dragKey, hoverKey) => {
      // Create a shallow copy of the selectedColumnList object
      const updatedChips = { ...selectedColumnList };

      // Convert the object into an array of [key, value] pairs
      const entries = Object.entries(updatedChips);

      // Find the index positions of the drag and hover keys
      const dragIndex = entries.findIndex(([key]) => key === dragKey);
      const hoverIndex = entries.findIndex(([key]) => key === hoverKey);

      // Remove the dragged entry from the array
      const [removed] = entries.splice(dragIndex, 1);

      // Insert the removed entry at the hover index
      entries.splice(hoverIndex, 0, removed);

      // Convert the array of entries back into an object
      const reorderedChips = Object.fromEntries(entries);

      // Update the selectedColumnList state with the reordered object
      setSelectedColumnList(reorderedChips);
    },
    [selectedColumnList]
  );

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          {!isSubmitSuccess ? (
            <Box>
              <Container>
                {props.isEdit ? (
                  <Header>{getLocalizedText("edit_details")}</Header>
                ) : (
                  <Header>{getLocalizedText("add_a_new_status")}</Header>
                )}
                <SubHeader>
                  {getLocalizedText("fill_in_the_below_details")}
                </SubHeader>
                <Divider style={{ margin: "16px 0px" }} />
                <Form
                  component={"form"}
                  onSubmit={handleSubmit}
                  onKeyDown={(e) => checkKeyDown(e)}
                >
                  <Grid
                    container
                    spacing={2}
                    style={{ height: "300px", overflowY: "auto" }}
                  >
                    <Grid item xs={12}>
                      <Box style={{ display: "flex", columnGap: "16px" }}>
                        <InputWrapper>
                          <CustomFieldHeader
                            header={getLocalizedText("status_name")}
                            tooltip={"Enter the name of the new lead status."}
                            required={true}
                          />
                          <Input
                            value={user.status}
                            required
                            type="text"
                            placeholder="Enter name here..."
                            name="status"
                            onChange={handleChange}
                            disabled={!canEditField()}
                          />
                        </InputWrapper>
                        <InputWrapper
                          style={{
                            width: "fit-content",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Rectangle
                            style={{
                              color: color,
                              cursor: "pointer",
                              width: "45px",
                              height: "auto",
                              marginBottom: "-7px",
                            }}
                            onClick={() => {
                              setOpenColorPicker(true);
                            }}
                          />
                        </InputWrapper>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <CustomFieldHeader
                          header={getLocalizedText("add_sub_status")}
                          tooltip={"Add sub statuses for this status."}
                          required={true}
                        />
                        <Input
                          type="text"
                          placeholder="Type something"
                          name="sub_status"
                          onKeyDown={handleSubStatus}
                          disabled={!canEditField()}
                        />
                        {subStatusList.length > 0 && (
                          <SubStatusContainer>
                            {subStatusList.map((e, i) => {
                              return (
                                <Chip
                                  disabled={!canEditField()}
                                  key={e}
                                  label={e}
                                  onDelete={() => handleDelete(i)}
                                />
                              );
                            })}
                          </SubStatusContainer>
                        )}
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <CustomFieldHeader
                          header={getLocalizedText("group")}
                          tooltip={"Enter the group this status belongs to."}
                        />
                        <Input
                          value={user.group}
                          type="text"
                          placeholder="Enter group here..."
                          name="group"
                          onChange={handleChange}
                          disabled={!canEditField()}
                        />
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container item spacing={1}>
                        <Grid item>
                          <FormControlLabel
                            disabled={!canEditField()}
                            control={
                              <Checkbox checked={flag} color="primary" />
                            }
                            label={
                              <CustomCheckboxHeader
                                header={getLocalizedText("visible")}
                                tooltip={
                                  "Specify if the status should be displayed in listing and details."
                                }
                              />
                            }
                            onChange={handleFlag}
                          />
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            disabled={!canEditField()}
                            control={
                              <Checkbox
                                checked={isNotesMandatory}
                                color="primary"
                              />
                            }
                            label={
                              <CustomCheckboxHeader
                                header={"Call Notes"}
                                tooltip={
                                  "Check to make the call notes field mandatory when submitting a call log."
                                }
                              />
                            }
                            onChange={(e) => {
                              setIsNotesMandatory(e.target.checked);
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isDeleteProtected}
                                color="primary"
                              />
                            }
                            label={
                              <CustomCheckboxHeader
                                header={"Delete Protection"}
                                tooltip={
                                  "Check to prevent deletion of leads associated with this status."
                                }
                              />
                            }
                            onChange={(e) => {
                              setIsDeleteProtect(e.target.checked);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Box
                        component={"fieldset"}
                        sx={{ border: "1px solid #cccccc", padding: "12px" }}
                      >
                        <legend
                          style={{
                            width: "auto",
                            marginBottom: "0px",
                            fontSize: "16px",
                          }}
                        >
                          Fields to be Updated
                        </legend>

                        {Object.keys(selectedColumnList).length > 0 && (
                          <Grid
                            item
                            xs={12}
                            container
                            spacing={2}
                            style={{
                              marginBottom: "8px",
                            }}
                          >
                            <DndProvider backend={HTML5Backend}>
                              {Object.keys(selectedColumnList).map(
                                (key, index) => {
                                  return (
                                    <Grid item key={key}>
                                      <DraggableUpdateFieldChipComponent
                                        handleDelete={() => {
                                          const filteredColumnList = {
                                            ...selectedColumnList,
                                          };
                                          delete filteredColumnList[key];
                                          setSelectedColumnList(
                                            filteredColumnList
                                          );
                                        }}
                                        chipKey={key}
                                        index={index}
                                        isDisabled={!canEditField()}
                                        isRequired={
                                          selectedColumnList[key]["required"]
                                        }
                                        label={selectedColumnList[key]["label"]}
                                        moveChip={moveChip}
                                      />
                                    </Grid>
                                  );
                                }
                              )}
                            </DndProvider>
                          </Grid>
                        )}
                        <Button
                          style={{
                            textTransform: "none",
                            fontWeight: "bold",
                            width: "100%",
                          }}
                          color="primary"
                          variant="text"
                          onClick={() => {
                            setOpenAddUpdateFieldDialog(true);
                          }}
                        >
                          <Add /> Add Update Field
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>

                  <ButtonWrapper>
                    {isSubmitFail && (
                      <ErrorAlert
                        onClose={(e) => {
                          e.stopPropagation();
                          setIsSubmitFail(false);
                        }}
                        severity="error"
                        sx={{ width: "100%" }}
                      >
                        {errorMessage}
                      </ErrorAlert>
                    )}
                    <Button
                      onClick={() => {
                        if (props.isEdit) {
                          analytics.triggerEvent(
                            4625000214,
                            "cancel_button_tap",
                            "edit_status_form",
                            "cancel_button",
                            {}
                          );
                        } else {
                          analytics.triggerEvent(
                            4625000210,
                            "cancel_button_tap",
                            "add_status_form",
                            "cancel_button",
                            {}
                          );
                        }
                        handleClick();
                      }}
                      type="button"
                      variant="contained"
                      color="default"
                      style={{
                        backgroundColor: "#EFEFF4",
                        textTransform: "none",
                        marginRight: "20px",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      {getLocalizedText("cancel")}
                    </Button>
                    <Button
                      onClick={() => {
                        if (props.isEdit) {
                          analytics.triggerEvent(
                            4625000215,
                            "submit_button_tap",
                            "edit_status_form",
                            "submit_button",
                            {}
                          );
                        } else {
                          analytics.triggerEvent(
                            4625000211,
                            "submit_button_tap",
                            "add_status_form",
                            "submit_button",
                            {}
                          );
                        }
                      }}
                      type="submit"
                      variant="contained"
                      id="call-log-btn"
                      style={{
                        textTransform: "none",
                        fontSize: "12px",
                        fontWeight: "bold",
                        ...myTheme.Button.btnBlue,
                      }}
                    >
                      {getLocalizedText("submit_details")}
                    </Button>
                  </ButtonWrapper>
                </Form>
              </Container>
            </Box>
          ) : (
            <Box>
              <MessageWrapper style={{ marginBottom: "0px" }}>
                {props.isEdit ? (
                  <Message>Status updated successfully!</Message>
                ) : (
                  <Message>Status created successfully!</Message>
                )}
                <Button
                  onClick={handleClose}
                  type="button"
                  variant="contained"
                  color="default"
                  style={{
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "100px",
                  }}
                >
                  {getLocalizedText("close")}
                </Button>
              </MessageWrapper>
            </Box>
          )}
        </ModelContainer>
      </Modal>
      {openColorPicker && (
        <ColorPickerModal
          color={color}
          setColor={setColor}
          open={openColorPicker}
          setOpen={setOpenColorPicker}
        />
      )}
      {openAddUpdateFieldDialog && (
        <AddUpdateFieldDialog
          open={openAddUpdateFieldDialog}
          canEditField={canEditField}
          handlePostAddUpdateField={(updateFieldData) => {
            if (
              !selectedColumnList.hasOwnProperty(updateFieldData["column_id"])
            ) {
              let index = columnsList.findIndex(
                (column) => column["id"] === updateFieldData["column_id"]
              );
              selectedColumnList[updateFieldData["column_id"]] = {
                label: columnsList[index]["headerName"],
                required: updateFieldData["is_required"],
                overwrite: updateFieldData["is_overwrite"],
                append: updateFieldData["is_append"],
              };
            }
          }}
          selectedColumnList={selectedColumnList}
          setOpen={setOpenAddUpdateFieldDialog}
          columnsList={columnsList}
        />
      )}
    </>
  );
};

export default observer(AddStatusModal);
