import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import bgImg from "../../../../assets/school_template_bg.jpg";
import React from "react";
import FormComponent from "./FormComponent";

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${bgImg})`,
  backgroundSize: "100% 100%",
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat",
  [theme.breakpoints.down("md")]: {
    minHeight: "calc(100vh - 106px)",
    padding: theme.spacing(2),
  },
  width: "100%",
  position: "relative",
  padding: theme.spacing(3),
  minHeight: "calc(100vh - 120px)",
}));
const BgLayer = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: "rgba(0, 0, 0, 0.6)",
  zIndex: "0",
}));

const Title = styled(Typography)(({ theme }) => ({
  component: "h1",

  color: "#ffffff",

  [[theme.breakpoints.between("xs", "sm")]]: {
    font: "normal normal 600 20px open sans",
  },
  [[theme.breakpoints.between("sm", "md")]]: {
    font: "normal normal 600 24px open sans",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    font: "normal normal 600 28px open sans",
  },
  [theme.breakpoints.up("lg")]: {
    font: "normal normal 600 40px open sans",
  },
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  component: "span",
  color: "#ffffff",
  width: "80%",
  [[theme.breakpoints.between("xs", "sm")]]: {
    font: "normal normal 500 12px open sans",
    component: "span",
  },
  [[theme.breakpoints.between("sm", "md")]]: {
    font: "normal normal 500 16px open sans",
    component: "span",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    font: "normal normal 500 20px open sans",
    component: "span",
  },
  [theme.breakpoints.up("lg")]: {
    font: "normal normal 500 24px open sans",
    component: "span",
  },
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  width: "90%",
  margin: "auto",
  height: "100%",
  zIndex: "1",
  position: "relative",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const TextContainer = styled(Box)(({ theme }) => ({
  width: "70%",
  display: "flex",
  height: "100%",
  flexDirection: "column",
  color: "#fff",
  rowGap: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    width: "100%",
    rowGap: theme.spacing(1),
  },
}));

const TopSection = ({ contentState, formState, primaryColor }) => {
  return (
    <Wrapper>
      <BgLayer />
      <InnerContainer>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8}>
            <TextContainer>
              <Title>{contentState.title}</Title>
              <SubTitle>{contentState.sub_title}</SubTitle>
            </TextContainer>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <FormComponent
              formState={formState}
              contentState={contentState}
              primaryColor={primaryColor}
            />
          </Grid>
        </Grid>
      </InnerContainer>
    </Wrapper>
  );
};

export default TopSection;
