import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import styled from "styled-components";
import "./style.css";
import { Grid } from "@mui/material";
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;
const Label = styled.span`
  font: normal normal normal 12px Open Sans;
  color: #4d4e4f;
`;
const ChildMultiSelectDropdown = (props) => {
  const [list, setList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelect = (selected) => {
    setSelectedOptions(selected);
    let newList = [];
    selected.map((val) => {
      newList.push(val.value);
    });
    props.setSelectedVal({
      ...props.selectedVal,
      [props.field]: newList,
    });
    props.setFilterList({
      ...props.filterList,
      [props.field]: {
        value: newList,
        type: props.filterType,
        dataType: props.dataType,
      },
    });
  };

  const generateGroupedList = (list) => {
    let newList = [];
    Object.keys(list).forEach((key) => {
      let tempObj = { label: key, options: [] };

      list[key].forEach((item) => {
        let optionObj = { label: item, value: item };
        tempObj.options.push(optionObj);
      });
      newList.push(tempObj);
    });
    return newList;
  };
  const handlePreselectedValue = (list) => {
    if (list.length > 0) {
      let temp_list = [];
      list.forEach((item) => {
        let temp_obj = { label: item, value: item };
        temp_list.push(temp_obj);
      });
      return temp_list;
    }
    return [];
  };
  const getList = async () => {
    let generatedList = generateGroupedList(props.list);
    setList(generatedList);
  };
  useEffect(() => {
    getList();
    if (
      props.selectedVal.hasOwnProperty(props.field) &&
      props.type === "report"
    ) {
      let prev_values = props.selectedVal[props.field];
      let modified_prev_values = handlePreselectedValue(prev_values);
      setSelectedOptions(modified_prev_values);
    }
  }, []);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px", // Font size for options
    }),
    control: (provided, state) => ({
      ...provided,
      fontSize: "12px", // Font size for the entire control container, including the search input
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "12px", // Font size for "no options" message
    }),
    loadingMessage: (provided, state) => ({
      ...provided,
      fontSize: "12px", // Font size for "loading" message
    }),
  };

  function truncate(str, n) {
    return str.length > n ? str.slice(0, n - 1) + "..." : str;
  }

  return (
    <>
      {list.length > 0 && (
        <Grid item xs={props.item["width"] === 1 ? 6 : 12}>
          <InputWrapper type={props.type}>
            <Label>
              {" "}
              {props.item["width"] === 1
                ? truncate(props.headerName, 20)
                : props.headerName}
            </Label>
            <Select
              isDisabled={props.readOnly === true}
              options={list}
              value={selectedOptions}
              onChange={handleSelect}
              isMulti
              closeMenuOnSelect={false}
              styles={customStyles}
            />
          </InputWrapper>
        </Grid>
      )}
    </>
  );
};

export default ChildMultiSelectDropdown;
