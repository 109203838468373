import React from "react";
import { StyleSheet, View, Text, Font } from "@react-pdf/renderer";

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/poppins/v21/pxiEyp8kv8JHgFVrFJA.ttf",
      fontWeight: 400,
      fontStyle: "normal",
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLGT9V1s.ttf",
      fontWeight: 500,
      fontStyle: "normal",
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLCz7V1s.ttf",
      fontWeight: 700,
      fontStyle: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  container: {},
  row: {
    flexDirection: "row",
    display: "flex",
    borderTop: "1px solid #D3D3D3",
    borderBottom: "1px solid #D3D3D3",
    paddingLeft: "16px",
  },
  mainCell: {
    textAlign: "left",
    width: "100%",
    padding: "4px 0px",
  },
  dataText: {
    width: "100%",
    fontSize: 8,
    fontWeight: 400,
    color: "black",
    fontFamily: "Poppins",
    textAlign: "center",
  },
});
const TableNoRows = ({ text }) => {
  return (
    <View style={styles.container} wrap={false}>
      <View style={styles.row}>
        <View style={styles.mainCell}>
          <Text style={{ ...styles.dataText }}>
            {text}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default TableNoRows;
