import { AppBar, Box, Divider, Toolbar } from "@material-ui/core";
import React from "react";
import { styled } from "@mui/material/styles";
import { getLocalizedText } from "../../../../Functions";
import {
  FormControl,
  Typography,
  OutlinedInput,
  IconButton,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";

const dialogWidth = 600;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
  overflow-y: auto;
  row-gap: 10px;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const Label = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const APIScreen = ({ apiKey, setApiKey, integrationData }) => {
  return (
    <>
      <Box>
        <AppBar
          component={"nav"}
          elevation={0}
          position="sticky"
          color="inherit"
          sx={{
            top: 0,
            bottom: "auto",
            width: "100%",
            right: 0,
          }}
        >
          <Toolbar
            style={{
              padding: "16px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <HeaderWrapper>
                <Header>{integrationData.title} Pull Configuration</Header>
                <SubHeader>
                  {" "}
                  {getLocalizedText("fill_in_the_below_details")}
                </SubHeader>
              </HeaderWrapper>
              {integrationData.help_link_url !== "" && (
                <Tooltip title="Help">
                  <IconButton
                    aria-label="help"
                    color="primary"
                    onClick={() => {
                      window.open(integrationData.help_link_url, "_blank");
                    }}
                  >
                    <HelpIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Toolbar>
        </AppBar>
        <Divider style={{ margin: "0px 16px" }} />
        <Container>
          <InputWrapper>
            <Label>API Key*</Label>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                placeholder="Enter value"
                required
                onChange={(e) => {
                  setApiKey(e.target.value);
                }}
                name="api_key"
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                value={apiKey}
              />
            </FormControl>
          </InputWrapper>
        </Container>
      </Box>
    </>
  );
};

export default APIScreen;
