import React from "react";
import { Box, OutlinedInput, styled } from "@mui/material";
import { MultiSelect } from "react-multi-select-component";

const Row = styled(Box)`
  display: flex;
  align-items: center;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`;

const ReportFilterValueFieldComponent = ({
  setFilter,
  filter,
  filterTypeListMeta,
  selectedColumn,
}) => {
  const handleChange = ({ e, data_type, filterType }) => {
    console.log(e.target.name, e.target.value);
    let value =
      data_type === "integer" ? parseInt(e.target.value) : e.target.value;
    setFilter({
      ...filter,
      [e.target.name]: {
        value: value,
        filter_type: filterType,
        data_type: data_type,
      },
    });
  };

  if (selectedColumn !== null) {
    if (
      selectedColumn.filter_type === "normal" ||
      selectedColumn.filter_type === ""
    ) {
      return (
        <InputWrapper>
          <OutlinedInput
            style={{ height: "30px" }}
            inputProps={{
              "aria-label": "weight",
              style: {
                fontSize: "12px",
              },
            }}
            type={
              selectedColumn.data_type === "email"
                ? "email"
                : selectedColumn.data_type === "datetime" ||
                  selectedColumn.data_type === "date"
                ? "date"
                : selectedColumn.data_type === "integer"
                ? "number"
                : "text"
            }
            name={selectedColumn.field}
            value={
              filter.hasOwnProperty(selectedColumn.field)
                ? filter[selectedColumn.field]["value"]
                : ""
            }
            placeholder={`Enter ${selectedColumn.headerName}`}
            onChange={(e) => {
              let data_type = selectedColumn.data_type;
              let filterType = selectedColumn.filter_type;
              handleChange({ e, data_type, filterType });
            }}
          />
        </InputWrapper>
      );
    } else if (selectedColumn.filter_type === "range") {
      if (selectedColumn.data_type === "integer") {
        return (
          <InputWrapper>
            <Row>
              <OutlinedInput
                type="number"
                name="start"
                placeholder="min"
                value={
                  filter.hasOwnProperty(selectedColumn.field)
                    ? filter[selectedColumn.field]["value"]["start"]
                    : ""
                }
                onChange={(e) => {
                  let start = parseInt(e.target.value);
                  let end =
                    filter.hasOwnProperty(selectedColumn.field) &&
                    filter[selectedColumn.field]["value"]["end"] !== ""
                      ? parseInt(filter[selectedColumn.field]["value"]["end"])
                      : "";

                  setFilter({
                    ...filter,
                    [selectedColumn.field]: {
                      value: { start: start, end },
                      filter_type: selectedColumn.filter_type,
                      data_type: selectedColumn.data_type,
                    },
                  });
                }}
                style={{
                  width: "100%",
                  marginRight: "10px",
                  height: "30px",
                }}
                inputProps={{
                  min: 0,
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
              />
              <OutlinedInput
                type="number"
                name="end"
                placeholder="max"
                value={
                  filter.hasOwnProperty(selectedColumn.field)
                    ? filter[selectedColumn.field]["value"]["end"]
                    : ""
                }
                disabled={
                  !filter.hasOwnProperty(selectedColumn.field) ||
                  (filter.hasOwnProperty(selectedColumn.field) &&
                    filter[selectedColumn.field]["value"]["start"] === "")
                }
                onChange={(e) => {
                  let start =
                    filter.hasOwnProperty(selectedColumn.field) &&
                    filter[selectedColumn.field]["value"]["start"] !== ""
                      ? parseInt(filter[selectedColumn.field]["value"]["start"])
                      : "";
                  let end = parseInt(e.target.value);

                  setFilter({
                    ...filter,
                    [selectedColumn.field]: {
                      value: { start: start, end: end },
                      filter_type: selectedColumn.filter_type,
                      data_type: selectedColumn.data_type,
                    },
                  });
                }}
                style={{
                  width: "100%",
                  marginRight: "10px",
                  height: "30px",
                }}
                inputProps={{
                  min: 0,
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
              />
            </Row>
          </InputWrapper>
        );
      }
    } else if (selectedColumn.filter_type === "list") {
      if (
        selectedColumn.filter_input === "" ||
        selectedColumn.filter_input === null
      ) {
        return (
          <InputWrapper>
            <OutlinedInput
              style={{ height: "30px" }}
              inputProps={{
                "aria-label": "weight",
                style: {
                  fontSize: "12px",
                },
              }}
              type={
                selectedColumn.data_type === "email"
                  ? "email"
                  : selectedColumn.data_type === "datetime" ||
                    selectedColumn.data_type === "date"
                  ? "date"
                  : selectedColumn.data_type === "integer"
                  ? "number"
                  : "text"
              }
              name={selectedColumn.field}
              placeholder={`enter ${selectedColumn.headerName}`}
              onChange={(e) => {
                let data_type = selectedColumn.data_type;
                let filterType = selectedColumn.filter_type;
                handleChange({ e, data_type, filterType });
              }}
            />
          </InputWrapper>
        );
      } else {
        let list = filterTypeListMeta["list"];
        if (filterTypeListMeta["type"] === "list") {
          list = list.map((str) => ({
            label: str,
            value: str,
          }));
        } else {
          list = list.map((obj) => ({
            label: obj["name"],
            value: obj["value"],
          }));
        }

        return (
          <Box
            sx={{
              "&": {
                width: "100%",
                fontSize: "12px",
                border: "none",
                alignItems: "center",
                color: "black",
              },
              "& .dropdown-heading": {
                height: "30px",
              },
              "& .options": {
                maxHeight: "100px",
              },
            }}
          >
            <MultiSelect
              options={list}
              value={
                filter.hasOwnProperty(selectedColumn.field)
                  ? filter[selectedColumn.field]["value"]
                  : []
              }
              onChange={(selected) => {
                setFilter({
                  ...filter,
                  [selectedColumn["field"]]: {
                    value: selected,
                    filter_type: selectedColumn["filter_type"],
                    data_type: selectedColumn["data_type"],
                  },
                });
              }}
            />
          </Box>
        );
      }
    }
  }
};

export default ReportFilterValueFieldComponent;
