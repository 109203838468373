import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import { Box, Drawer, Tooltip } from "@material-ui/core";
import { ChevronRight, Add } from "@material-ui/icons";
import { whatsappPageColumnHeaders } from "../../../Db";

import rootStore from "../../../stores/RootStore";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { pageSize } from "../../../Config";
import { styled } from "@mui/material/styles";
import { Typography, Button, IconButton } from "@mui/material";
import {
  createProjectWhatsAppPersonalTemplateApi,
  getProjectAdminWhatsAppTemplateListApi,
  updateProjectWhatsAppPersonalTemplateApi,
} from "../../../Api";

import { Preview, Edit } from "@mui/icons-material";
import AddWhatsappTemplateSidepanel from "../../../components/whatsapp_templates_page_components/AddWhatsappTemplateSidepanel";
import LoadingAnimationComponent from "../../../components/loading_component/LoadingAnimationComponent";
import WhatsappPreviewPopup from "../../../components/whatsapp_templates_page_components/message_preview/WhatsappPreviewPopup";

const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 84px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;
const ProgressWrapper = styled(Box)`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex: 1;
`;
const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const Row = styled(Box)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 16px 0px;
`;
const TableWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${`min-height: calc(100vh - 262px);`};
  background-color: white;
  position: relative;
`;

const ProjectPersonalWhatsappTemplates = () => {
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [editableData, setEditableData] = useState(null);
  const [refresh, setRefresh] = useState(false);

  //preview popup states
  const [previewOpen, setPreviewOpen] = useState(false);
  const [whatsappPreviewMessage, setWhatsappPreviewMessage] = useState("");

  //add sidebar states
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);

  const fetchWhatsappTemplates = async () => {
    let response = await getProjectAdminWhatsAppTemplateListApi();
    setRow(response);
  };

  const init = async () => {
    setLoading(true);
 await fetchWhatsappTemplates()
    setLoading(false);
  };

  const refetch=async()=>{
    setLoading(true);
    await fetchWhatsappTemplates()
       setLoading(false);
       setRefresh(false)
  }

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (refresh === true) {
     refetch()
    }
  }, [refresh]);

  const handleSubmit = async (data) => {
    setSubmitLoading(true);
    let payload = {
      template_title: data["template_title"],
      template_message: data["template_message"],
      enable_for_stages: [],
    };

    payload["enable_for_stages"] = data["enable_for_stages"].map((item) => item.value);

    if (isEditable) {
      //update api
      const response = await updateProjectWhatsAppPersonalTemplateApi({
        templateID: editableData.id,
        payload: payload,
      });

      if (response.hasError()) {
        setIsSubmitSuccess(false);
        setErrorMessage(response.errorMessage);
        setIsSubmitFail(true);
        setSubmitLoading(false);
      } else {
        setRefresh(true);
        setIsSubmitSuccess(true);
        setSubmitLoading(false);
      }
    } else {
      //create api
      const response = await createProjectWhatsAppPersonalTemplateApi({
        payload: payload,
      });

      if (response.hasError()) {
        setIsSubmitSuccess(false);
        setErrorMessage(response.errorMessage);
        setIsSubmitFail(true);
      } else {
        setRefresh(true);
        setIsSubmitSuccess(true);
      }
    }
  };

  const additionalColumns = [
    {
      field: "action",
      headerName: "ACTIONS",
      minWidth: 100,
      flex: 1,
      resizable: false,
      cellRenderer: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              gap: "10px",
            }}
          >
            <Tooltip title={"Edit"}>
              <IconButton
                style={{ padding: "0px", marginRight: "20px" }}
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setEditableData(params.data);
                  setIsEditable(true);
                  setIsSubmitSuccess(false);
                  setIsSubmitFail(false);
                  setErrorMessage("");
                  setSubmitLoading(false);
                  setOpen(true);
                }}
              >
                <Edit
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Preview"}>
              <IconButton
                style={{ padding: "0px" }}
                color="primary"
                onClick={(e) => {
                  setWhatsappPreviewMessage(params.data.message);
                  setPreviewOpen(true);
                }}
              >
                <Preview
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  return (
    <>
      <ParentContainer>
        <Container>
          <Row>
            <TopWrapper>
              <Header>Project Administration</Header>
              <ChevronRight style={{ width: "16px", height: "16px" }} />
              <Header>WhatsApp Templates</Header>
              <ChevronRight style={{ width: "16px", height: "16px" }} />
              <Header>Personal</Header>
            </TopWrapper>
            <Button
              onClick={() => {
                setIsSubmitSuccess(false);
                setIsSubmitFail(false);
                setErrorMessage("");
                setSubmitLoading(false);
                setIsEditable(false);
                setOpen(true);
              }}
              variant="contained"
              style={{
                backgroundColor: "#185DD2",
                color: "white",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                whiteSpace: "nowrap",
              }}
            >
              <Add />
              Create Template
            </Button>
          </Row>
          <TableWrapper>
            {!loading ? (
              <div className="ag-theme-alpine" style={{ width: "100%" }}>
                <AgGridReact
                  domLayout="autoHeight"
                  gridOptions={gridOptions}
                  rowData={row}
                  defaultColDef={defaultColDef}
                  columnDefs={whatsappPageColumnHeaders.concat(
                    additionalColumns
                  )}
                  animateRows={true}
                  suppressCellFocus
                  pagination={true}
                  paginationPageSize={pageSize}
                  className="paginated-ag-grid"
                  //   onRowClicked={(row) => {
                  //   }}
                />
              </div>
            ) : (
              <ProgressWrapper>
                <LoadingAnimationComponent size={"small"} />
              </ProgressWrapper>
            )}
          </TableWrapper>
        </Container>
      </ParentContainer>
      {open && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={open}
          onClose={() => {
           // setOpen(false);
          }}
        >
          <AddWhatsappTemplateSidepanel
            setRefresh={setRefresh}
            setOpen={setOpen}
            open={open}
            isEdit={isEditable}
            editableData={editableData}
            setEdit={setIsEditable}
            previewOpen={previewOpen}
            setPreviewOpen={setPreviewOpen}
            setWhatsappPreviewMessage={setWhatsappPreviewMessage}
            postSubmitCallback={handleSubmit}
            isSubmitSuccess={isSubmitSuccess}
            isSubmitFail={isSubmitFail}
            setIsSubmitFail={setIsSubmitFail}
            errorMessage={errorMessage}
            loading={submitLoading}
          />
        </Drawer>
      )}
      <Footer />
      {previewOpen && (
        <WhatsappPreviewPopup
          open={previewOpen}
          setOpen={setPreviewOpen}
          content={whatsappPreviewMessage}
          contentType={"text"}
        />
      )}
    </>
  );
};

export default observer(ProjectPersonalWhatsappTemplates);
