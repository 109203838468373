import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const UploadContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 2px normal #d3d3d3;
  background-color: #fafafa;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: border-color 0.3s;

  &:hover {
    border-color: #007bff;
  }
`;

const UploadButton = styled(Button)`
  color: white;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  border-radius: 50px;
  text-transform: none;
`;

const UploadInstructions = styled(Typography)`
  color: #6c757d;
  margin-top: 10px;
  font-size: 14px;
`;

const UploadDropContainer = (props) => {
  const [image, setImage] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    props.setFile(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/jpeg": [], "image/png": [] },
  });

  return (
    <UploadContainer
      {...getRootProps()}
      style={{ backgroundColor: isDragActive ? "#d3d3d3" : "#fafafa" }}
    >
      <input {...getInputProps()} />
      {!image ? (
        <>
          <UploadButton variant="contained" color="primary">
            Upload Image
          </UploadButton>
          <UploadInstructions>or drop a file</UploadInstructions>
        </>
      ) : (
        <img
          src={image}
          alt="Preview"
          style={{
            width: "100%",
            height: "auto",
            borderRadius: "10px",
            objectFit: "contain",
          }}
        />
      )}
    </UploadContainer>
  );
};

export default UploadDropContainer;
