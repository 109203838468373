import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Box, TextField, styled } from "@mui/material";
const CustomizedDateTimePicker = styled(DateTimePicker)`
  & .MuiInputBase-input {
    font-size: 14px;
  }
`;
const DateTimeRangePickerWidget = ({
  minValue,
  maxValue,
  handleMinDatetime,
  handleMaxDatetime,
  disabled,
}) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
      <Box sx={{ width: "100%" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CustomizedDateTimePicker
            maxDateTime={maxValue}
            value={minValue}
            onChange={(event) => {
              handleMinDatetime(event);
            }}
            inputFormat="YYYY-MM-DD HH:mm A"
            renderInput={(params) => (
              <TextField sx={{ width: "100%" }} size="small" {...params} />
            )}
            label="Start Datetime"
          />
        </LocalizationProvider>
      </Box>
      <Box sx={{ width: "100%" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CustomizedDateTimePicker
            minDateTime={minValue}
            value={maxValue}
            onChange={(event) => {
              handleMaxDatetime(event);
            }}
            inputFormat="YYYY-MM-DD HH:mm A"
            disabled={disabled}
            renderInput={(params) => (
              <TextField sx={{ width: "100%" }} size="small" {...params} />
            )}
            label="End Datetime"
          />
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

export default DateTimeRangePickerWidget;
