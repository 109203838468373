import { Close } from "@material-ui/icons";
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ReactAudioPlayer from "react-audio-player";
import styled from "styled-components";
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 50%;
`;
const ModelWrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
`;
const AudioModal = (props) => {
  const handleClose = () => {
    props.setOpen(false);
  };
  const handleClick = () => {
    props.setOpen(false);
  };
  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <Close
              onClick={handleClick}
              style={{
                height: "18px",
                width: "18px",
                position: "absolute",
                top: "20px",
                right: "30px",
                cursor: "pointer",
              }}
            />
            <ModelWrapper>
              <ReactAudioPlayer src={props.url} autoPlay={true} controls />
            </ModelWrapper>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default AudioModal;
