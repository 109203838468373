import { Card, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import { Add } from "@material-ui/icons";

const CardWrapper = styled(Card)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f9f9fc;
  align-items: center;
  border-radius: 8px;
`;
const AddChildCardWidget = ({ onAdd }) => {
  return (
    <CardWrapper>
      <Tooltip title={"Add"}>
        <IconButton style={{ padding: "0px" }} onClick={() => onAdd()}>
          <Add color="primary" />
        </IconButton>
      </Tooltip>
    </CardWrapper>
  );
};

export default AddChildCardWidget;
