import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Button } from "@material-ui/core";
import {
  AppBar,
  Box,
  CircularProgress,
  Grid,
  OutlinedInput,
  Toolbar,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {
  createAdminProjectMemberDialerConfigApi,
  getAdminProjectMemberDialerConfigApi,
  updateAdminProjectMemberDialerConfigApi,
} from "../../Api";
import rootStore from "../../stores/RootStore";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  ${`height: calc(100vh - 128px);`};
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: flex-end;
  column-gap: 10px;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  padding: 24px;
  gap: 20px;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0px;
  }
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const DialerConfigSidepanel = ({
  setOpen,
  setRefresh,
  userID,
  setShowConfirmationDialog,
}) => {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [details, setDetails] = useState({
    terminal_id: "",
    host_id: "",
    dni: "",
  });
  const [loading, setLoading] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const create = async () => {
    let formData = {
      user_id: userID,
      dialer: "teckinfo",
      xdata: { ...details },
    };

    let response = await createAdminProjectMemberDialerConfigApi({
      projectID: rootStore.authStore.projectId,
      payload: formData,
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };

  const update = async () => {
    let formData = {
      user_id: userID,
      dialer: "teckinfo",
      xdata: { ...details },
    };

    let response = await updateAdminProjectMemberDialerConfigApi({
      projectID: rootStore.authStore.projectId,
      payload: formData,
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };

  const handleSubmit = async () => {
    if (isUpdate) {
      await update();
    } else {
      await create();
    }
  };

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const init = async () => {
    let response = await getAdminProjectMemberDialerConfigApi({
      projectID: rootStore.authStore.projectId,
      payload: {
        user_id: userID,
        dialer: "teckinfo",
      },
    });
    if (response !== null) {
      if (
        response.hasOwnProperty("xdata") &&
        Object.keys(response["xdata"]).length > 0
      ) {
        setDetails(response["xdata"]);
      }
      setIsUpdate(true);
    }
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  return !loading ? (
    <Box role="presentation">
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          width: 500,
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <ModelHeader>Dialer Config</ModelHeader>
            {isUpdate && (
              <Button
                style={{
                  textTransform: "none",
                  fontSize: "14px",
                  fontWeight: "bold",
                  width: "fit-content",
                }}
                onClick={() => {
                  setShowConfirmationDialog(true);
                  handleClose();
                }}
                color="secondary"
                variant="outlined"
              >
                Delete
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      {!isSubmitSuccess ? (
        <ModelWrapper
          component={"form"}
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <InputContainer>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>Terminal ID*</Label>
                    <OutlinedInput
                      style={{ height: "30px" }}
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      onChange={handleChange}
                      name="terminal_id"
                      required
                      value={details["terminal_id"]}
                      placeholder="Enter value"
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>Host ID*</Label>
                    <OutlinedInput
                      style={{ height: "30px" }}
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      onChange={handleChange}
                      name="host_id"
                      required
                      value={details["host_id"]}
                      placeholder="Enter value"
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>DNI*</Label>
                    <OutlinedInput
                      style={{ height: "30px" }}
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      onChange={handleChange}
                      name="dni"
                      required
                      value={details["dni"]}
                      placeholder="Enter value"
                    />
                  </InputWrapper>
                </Grid>
              </Grid>
            </Box>
          </InputContainer>

          <AppBar
            position="fixed"
            color="inherit"
            elevation={0}
            sx={{ top: "auto", bottom: 0, width: 500, bgcolor: "#f9f9fc" }}
          >
            <Toolbar style={{ width: 500, position: "relative" }}>
              {isSubmitFail && (
                <ErrorAlert
                  onClose={(e) => {
                    e.stopPropagation();
                    setIsSubmitFail(false);
                  }}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  {errorMessage}
                </ErrorAlert>
              )}
              <ButtonWrapper>
                <Button
                  type="button"
                  onClick={handleClose}
                  variant="outlined"
                  color="primary"
                  style={{
                    textTransform: "none",
                    width: "120px",
                    fontWeight: "bold",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "none",
                    width: "120px",
                    fontWeight: "bold",
                  }}
                >
                  Submit
                </Button>
              </ButtonWrapper>
            </Toolbar>
          </AppBar>
        </ModelWrapper>
      ) : (
        <MessageWrapper>
          <Message>Dialer Credentials updated successfully!</Message>
          <Button
            onClick={handleClose}
            type="button"
            variant="contained"
            color="default"
            style={{
              marginTop: "20px",
              textTransform: "none",
              fontSize: "12px",
              fontWeight: "bold",
              width: "100px",
            }}
          >
            Close
          </Button>
        </MessageWrapper>
      )}
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: "500px",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default DialerConfigSidepanel;
