import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import { getLocalizedText } from "../../../../Functions";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  margin: auto;
  background-color: white;
  height: 100%;
  width: 600px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: 545px;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 16px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;

const ButtonWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  margin-top: 20px;
`;
const CodeBlock = styled(Typography)`
  font-family: monospace;
  font-size: 14px;
  color: #4d4e4f;
  background-color: #f5f5f5; // Light grey background for code block
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  white-space: pre-wrap;
  overflow-x: auto;
`;
const TextWrapper = styled(Box)`
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
`;
const CustomTab = styled(Tab)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: "#E7EEFA",
    color: "#4079DA",
    font: "normal normal 600 14px Open Sans",
    textTransform: "capitalize",
  },
  backgroundColor: "white",
  color: "#4D4E4F",
  font: "normal normal 600 14px Open Sans",
  textTransform: "capitalize",
}));
const URL = process.env.REACT_APP_API_BASE_URL;

const FormUrlInfoDialog = ({ open, setOpen, formData }) => {
  const [value, setValue] = React.useState("curl");

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const getCurlUrl = () => {
    const formPayloadData = {};
    Object.keys(formData["mapping"]).forEach((fieldName) => {
      formPayloadData[fieldName] = "";
    });
    const payloadData = {
      form_data: formPayloadData,
      api_key: "",
    };
    const payloadDataString = JSON.stringify(payloadData, null, 2); // Pretty print JSON
    const fullURL = `${URL}external/webhooks/${formData["id"]}/create`;
    return `curl -i -X POST ${fullURL} -H 'Content-Type: application/json' -d '${payloadDataString}'`;
  };

  const getReactCode = () => {
    const formPayloadData = {};

    Object.keys(formData["mapping"]).forEach((fieldName) => {
      formPayloadData[fieldName] = "";
    });
    const payloadData = {
      form_data: formPayloadData,
      api_key: "",
    };

    const fullURL = `${URL}external/webhooks/${formData["id"]}/create`;
    return `axios.post('${fullURL}', ${JSON.stringify(payloadData, null, 2)})
.then((response) => {
  console.log(response);
})
.catch(error => {
  console.error(error);
});`;
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModelContainer>
        <Container>
          <Box sx={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleTabChange}>
                  <CustomTab label="Curl" value="curl" />
                  <CustomTab label="React" value="react" />
                </TabList>
              </Box>
              <TabPanel value="curl" sx={{ padding: "5px" }}>
                <TextWrapper>
                  <CodeBlock component="pre">{getCurlUrl()}</CodeBlock>
                </TextWrapper>
              </TabPanel>
              <TabPanel value="react" sx={{ padding: "5px" }}>
                <TextWrapper>
                  <CodeBlock component="pre">{getReactCode()}</CodeBlock>
                </TextWrapper>
              </TabPanel>
            </TabContext>
          </Box>

          <ButtonWrapper>
            <Button
              onClick={() => setOpen(false)}
              variant="contained"
              color="inherit"
              style={{
                fontWeight: "bold",
                fontSize: "12px",
                textTransform: "none",
              }}
            >
              {getLocalizedText("close")}
            </Button>
          </ButtonWrapper>
        </Container>
      </ModelContainer>
    </Modal>
  );
};

export default FormUrlInfoDialog;
