import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { useEffect, useState } from "react";
import { moneyViewCollectOffersApi } from "../../Api";
import { Box } from "@material-ui/core";

const RefreshOffersDialog = (props) => {
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    moneyViewCollectOffers();
  }, []);

  const moneyViewCollectOffers = async () => {
    let flag = await moneyViewCollectOffersApi(props.data.id);
    props.setRefreshLeadDetails(true);
    setLoading(false);
    setHasError(!flag);
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={null}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {loading ? (
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress style={{ width: "24px", height: "24px" }} />
              <DialogContentText
                style={{
                  fontWeight: "bold",
                  color: "#4d4e4f",
                  marginLeft: "16px",
                }}
                id="alert-dialog-description"
              >
                Fetching new offers
              </DialogContentText>
            </Box>
          </DialogContent>
        ) : hasError ? (
          <DialogContent>
            <DialogContentText
              style={{ fontWeight: "bold", color: "red" }}
              id="alert-dialog-description"
            >
              New offers could not be fetched.
            </DialogContentText>
          </DialogContent>
        ) : (
          <DialogContent>
            <DialogContentText
              style={{ fontWeight: "bold", color: "#4d4e4f" }}
              id="alert-dialog-description"
            >
              New offers fetched successfully.
            </DialogContentText>
          </DialogContent>
        )}
        {
          <DialogActions>
            <Button
              color="primary"
              style={{ textTransform: "capitalize", fontWeight: "bold" }}
              onClick={() => {
                props.setOpen(false);
              }}
            >
              {loading ? "Cancel" : "Close"}
            </Button>
          </DialogActions>
        }
      </Dialog>
    </>
  );
};
export default RefreshOffersDialog;
