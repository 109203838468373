import React, { useState } from "react";
import styled from "styled-components";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  FormControl,
  makeStyles,
  MenuItem,
  Modal,
  Select,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import moment from "moment";
import { getStatusApi, submitCallApi } from "../../Api";
import { useEffect } from "react";
import { myTheme } from "../../themeUtils";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled.span`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled.span`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
  margin-bottom: 30px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Label = styled.span`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Input = styled.input`
  width: 428px;
  height: 30px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;

  border: 1px solid #bfbfbf;
  font: normal normal 500 16px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const TextArea = styled.textarea`
  width: 428px;
  height: 60px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #bfbfbf;
  color: #4d4e4f;

  resize: none;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const ButtonWrapper = styled.div`
  /* margin-top: 30px; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  width: 180px;
  @media (max-width: 700px) {
    width: 140px;
  }
`;
const SelectColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;

function ampm(time) {
  if (time.value !== "") {
    var hours = time.split(":")[0];
    var minutes = time.split(":")[1];
    var suffix = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    var displayTime = hours + ":" + minutes + " " + suffix;
    return displayTime;
  }
  return;
}
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const priorityList = [
  { name: "Low", value: "low" },
  { name: "Medium", value: "medium" },
  { name: "High", value: "high" },
];
const AddTicketModal = (props) => {
  const { authStore } = rootStore;
  const classes = useStyles();
  const [user, setUser] = useState({
    title: "",
    desc: "",
    priority: "",
  });

  const [statusList, setStatusList] = useState([]);
  const [audioFile, setAudioFile] = useState();
  let pid = authStore.projectId;
  const handleClick = () => {
    props.setOpen(false);
  };
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <Container>
              <Header>Create a new Ticket</Header>
              <SubHeader>Fill in the below details</SubHeader>
              <Form onSubmit={handleSubmit}>
                <InputWrapper>
                  <Label>Title*</Label>
                  <Input
                    required
                    type="text"
                    placeholder="Enter title here..."
                    name="title"
                    onChange={handleChange}
                  />
                </InputWrapper>
                <InputWrapper>
                  <Label>Description*</Label>
                  <TextArea
                    required
                    type="text"
                    placeholder="Enter note here..."
                    name="desc"
                    onChange={handleChange}
                  />
                </InputWrapper>
                <InputContainer>
                  <SelectColumn>
                    <Label>Select Priority</Label>
                    <FormControl
                      sx={{ m: 1, minWidth: 428 }}
                      classes={{
                        root: classes.quantityRoot,
                      }}
                    >
                      <Select
                        classes={{
                          icon: classes.icon,
                        }}
                        name="priority"
                        displayEmpty
                        disableUnderline
                        variant="outlined"
                        required
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        style={{
                          marginBottom: "20px",
                          width: "428px",
                          height: "30px",
                          fontSize: "12px",
                          fontWeight: "bold",
                          borderRadius: "0px",
                        }}
                      >
                        {priorityList.map((e) => {
                          {
                            /* console.log("status id: ", e.id); */
                          }
                          return (
                            <MenuItem id={e.value} value={e.value}>
                              {e.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </SelectColumn>
                </InputContainer>
                <ButtonWrapper>
                  <Button
                    onClick={handleClick}
                    type="button"
                    variant="contained"
                    color="default"
                    style={{
                      backgroundColor: "#EFEFF4",
                      textTransform: "none",
                      marginRight: "20px",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    id="call-log-btn"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      ...myTheme.Button.btnBlue,
                    }}
                  >
                    Submit Details
                  </Button>
                </ButtonWrapper>
              </Form>
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default observer(AddTicketModal);
