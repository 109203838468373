import {
  Add,
  Edit,
  Lock,
  VideoLabel,
  ViewModuleOutlined,
} from "@material-ui/icons";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import Footer from "../../components/landing_page_components/footer/Footer";
import "../summary/summary.css";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import {
  getDashboardListApi,
  getWidgetListByDashboardApi,
  rearrangeWidgetListApi,
} from "../../Api";
import { Chart, ArcElement } from "chart.js";
import {
  Box,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Button, IconButton, Menu, Tooltip } from "@material-ui/core";
import AddChartModalV2 from "../../components/chart_components/add_chart_modal/AddChartModalv2";
import {
  checkFeaturePermission,
  consoleLogger,
  isUnderWorkSchedule,
} from "../../Functions";
import NoAccessPermissionPage from "../no_access_page/NoAccessPermissionPage";
import NoAccessPage from "../no_access_page/NoAccessPage";
import analytics from "../../Analytics";
import AddDashboardModal from "../../components/chart_components/add_dashboard_modal/AddDashboardModal";
import EditDashboardModal from "../../components/chart_components/edit_dashboard_modal/EditDashboardModal";
import AddOwnerPrebuildDashboardModal from "../../components/chart_components/add_dashboard_modal/AddOwnerPrebuildDashboardModal";
import DashboardWidgetSlideshow from "../../components/dashboard_widget_slideshow/DashboardWidgetSlideshow";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DashboardSlider from "../../components/dashboard_slider/DashboardSlider";
import { GridView } from "@mui/icons-material";
import DashboardGridWidgets from "../../components/dashboard_grid_widgets/DashboardGridWidgets";
import DashboardSwitchComponent from "./dashboard_switch_component/DashboardSwitchComponent";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import LoadingAnimationComponent from "../../components/loading_component/LoadingAnimationComponent";
Chart.register(ArcElement);

const Container = styled(Box)`
  margin-top: 64px;
  background-color: #f9f9fc;
  width: 100%;
  ${`min-height: calc(100vh - 139px);`};
`;
const NoWidgetTextWrapper = styled(Box)`
  width: 100%;
  display: flex;
  ${`min-height: calc(100vh - 220px);`};
  justify-content: center;
  align-items: center;
`;
const Top = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  background-color: white;
  justify-content: space-between;
  gap: 20px;
`;
const TitleText = styled(Typography)`
  margin: 0px;
  font: normal normal 600 28px Open Sans;
  color: #4d4e4f;
`;
const TopLeftWrapper = styled(Box)`
  display: flex;
  column-gap: 20px;
  align-items: center;
  ${`width: calc(100% - 185px);`};
  justify-content: center;
`;
const Bottom = styled(Box)`
  padding: 20px;
  width: 100%;
`;
const Item = styled(Box)`
  height: 48vh;
  width: 100%;
  border: ${(e) => (e.props === "realtime" ? "1px solid #00A67E" : "none")};
  background-color: white;
`;
const Item2 = styled(Box)`
  width: 100%;
  background-color: white;
`;
const Text = styled(Typography)`
  font: normal normal 600 1vw Open Sans;
  margin: 0px;
  color: #4d4e4f;
`;
const TextLgHighlight = styled("a")`
  font: normal normal 600 18px Open Sans;
  margin: 0px;
  color: #3f51b5;
  text-decoration: underline;
`;
const TextLg = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  margin: 0px;
  color: #4d4e4f;
`;
const AddWidgetWrapper = styled(Box)`
  width: 100%;
  height: 48vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border: 2px dashed #aeaeae;
`;

const IconTextWrapper = styled(Box)`
  display: flex;
  align-items: center;
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: fit-content;
`;
const ITEM_HEIGHT = 48;
const Dashboard = () => {
  const { authStore, userStore } = rootStore;
  const [row, setRow] = useState([]);
  const [counterRow, setCounterRow] = useState([]);
  const [openAddWidget, setOpenAddWidget] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [refreshWidgets, setRefreshWidgets] = useState(false);
  const [currentDashboardData, setCurrentDashboardData] = useState({});
  const [dashboardList, setDashboardList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openAddDashboard, setOpenAddDashboard] = useState(false);
  const [loadingWidgets, setLoadingWidgets] = useState(false);
  const [openEditDashboard, setOpenEditDashboard] = useState(false);
  const [currentDashboardId, setCurrentDashboardId] = useState(""); //this extra id state for dashboard is created to render the current dashboard in dashboard dropdown
  const [openSlideShow, setOpenSlideShow] = useState(false);
  const [widgetListToSlideshow, setWidgetListToSlideshow] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const Open = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const AddDashboard = () => {
    let account_role =
      userStore.UserData !== null ? userStore.UserData["role"] : "";
    if (account_role === "owner" && authStore.projectList.length === 1) {
      let dashBoardNames = dashboardList.map((item) => item.name.toLowerCase());

      if (
        authStore.currentProject.hasOwnProperty("teams") &&
        authStore.currentProject["teams"].length === 1
      ) {
        return (
          <AddOwnerPrebuildDashboardModal
            open={openAddDashboard}
            setOpen={setOpenAddDashboard}
            dashBoardNames={dashBoardNames}
            refresh={refresh}
            setRefresh={setRefresh}
            setCurrentDashboardData={setCurrentDashboardData}
            setCurrentDashboardId={setCurrentDashboardId}
          />
        );
      }
    }
    return (
      <AddDashboardModal
        open={openAddDashboard}
        setOpen={setOpenAddDashboard}
        refresh={refresh}
        setRefresh={setRefresh}
        setCurrentDashboardData={setCurrentDashboardData}
        setCurrentDashboardId={setCurrentDashboardId}
      />
    );
  };

  const updateList = (list, dashboard_data) => {
    let tempItemList = [];
    let tempItem = null;
    if (list.length > 0) {
      list.map((item) => {
        if (item.visualization === "Funnel Chart") {
          tempItemList.push(item);
        }
      });
      if (tempItemList.length > 0) {
        tempItem = tempItemList[0];
      }
    }
    let filteredList = list.filter((e) => !tempItemList.includes(e));
    let newItem = { visualization: "add_item" };
    if (tempItem !== null) {
      if (tempItem.visualization === "Funnel Chart" && tempItem.idx % 2 === 0) {
        if (
          filteredList.length % 2 !== 0 &&
          dashboard_data["editable"] === true
        ) {
          filteredList.push(newItem);
        }
        tempItemList.map((item) => {
          filteredList.push(item);
        });
      } else if (
        tempItem.visualization === "Funnel Chart" &&
        tempItem.idx % 2 !== 0
      ) {
        if (
          filteredList.length % 2 !== 0 &&
          dashboard_data["editable"] === true
        ) {
          filteredList.push(newItem);
        }
        tempItemList.map((item) => {
          filteredList.push(item);
        });
      }
    } else {
      if (dashboard_data["editable"] === true) {
        filteredList.push(newItem);
      }
    }
    if (filteredList.length === 0 && dashboard_data["editable"] === true) {
      filteredList.push(newItem);
    }
    return filteredList;
  };

  const arePositionsConsecutive = (list) => {
    return list.every((item, index) => item.position === index);
  };

  const getWidgetsByDashboard = async (dashboard_data) => {
    let response = await getWidgetListByDashboardApi(dashboard_data["id"]);
    setWidgetListToSlideshow(response);
    let updated_list = updateList([...response], dashboard_data);

    updated_list.sort((a, b) => a.position - b.position);

    //console.log(updated_list);
    let counterList = updated_list.filter(
      (item) => item.widget_type === "counter"
    );
    let mainRows = updated_list.filter(
      (item) => item.widget_type !== "counter"
    );

    if (counterList.length > 4) {
      const remainingCounters = counterList.slice(4);
      counterList = counterList.slice(0, 4); //slice to keep only 4 in top list

      // add the remaining counters to the mainList
      mainRows = remainingCounters.concat(mainRows);
    }
    counterList.sort((a, b) => a.position - b.position);
    mainRows.sort((a, b) => a.position - b.position);

    let shouldSendSequence = false;
    //if positions are not consecutive in counter row (a widget has been removed), make them consecutive and send the new sequence
    if (!arePositionsConsecutive(counterList)) {
      counterList = counterList.map((item, index) => ({
        ...item,
        position: index,
      }));
      shouldSendSequence = true;
    }
    
    // adding dummy grid items for empty slots - if at least 1 widget is there
    if (counterList.length > 0) {
      counterList = counterList.concat(
        new Array(4 - counterList.length).fill({ visualization: "add_item" })
      );
    }

    // save new sequence after a widget was removed
    if (shouldSendSequence) {
      let tempList = [];
      counterList.forEach((obj, idx) => {
        if (obj["visualization"] !== "add_item") {
          let tempObj = { widget_id: obj["id"], sequence: idx };
          tempList.push(tempObj);
        }
      });
      mainRows.forEach((obj, idx) => {
        if (obj["visualization"] !== "add_item") {
          let tempObj = { widget_id: obj["id"], sequence: idx + 4 };
          tempList.push(tempObj);
        }
      });

      await rearrangeWidgetList({
        payload: { widget_sequences: tempList },
      });
    }

    setCounterRow(counterList);
    setRow(mainRows);
  };
  const getDashboardList = async () => {
    let response = await getDashboardListApi();
    return response;
  };

  const init = async () => {
    setLoading(true);
    let dashboard_list = await getDashboardList();
    setDashboardList(dashboard_list);
    if (dashboard_list.length > 0) {
      setCurrentDashboardData(dashboard_list[0]);
      setCurrentDashboardId(dashboard_list[0]["id"]);
      await fetchWidgets(dashboard_list[0]);
    }
    setLoading(false);
  };

  const fetchWidgets = async (dashboard_data) => {
    setLoadingWidgets(true);
    await getWidgetsByDashboard(dashboard_data);
    setLoadingWidgets(false);
  };
  useEffect(() => {
    if (refreshWidgets) {
      fetchWidgets(currentDashboardData);
      setRefreshWidgets(false);
    }
  }, [refreshWidgets]);

  //below function is written to refetch the dashboard list and widgets on edit or add dashboard , but we need to show the newly created or edited dashboard in the view

  const refetchDataOnDashboardUpdate = async () => {
    setLoading(true);
    let dashboard_list = await getDashboardList();
    setDashboardList(dashboard_list);
    if (dashboard_list.length > 0) {
      let index = dashboard_list.findIndex(
        (dashboard) => dashboard["id"] === currentDashboardData["id"]
      );
      if (index !== -1) {
        setCurrentDashboardData(dashboard_list[index]);
        await fetchWidgets(dashboard_list[index]);
      } else {
        setCurrentDashboardId(dashboard_list[0]["id"]);
        setCurrentDashboardData(dashboard_list[0]);
        await fetchWidgets(dashboard_list[0]);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (refresh) {
      refetchDataOnDashboardUpdate();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    analytics.triggerEvent(
      4625000079,
      "dashboard_page_load",
      "dashboard_page",
      "",
      {}
    );
    authStore.updateLeadDetailStatus();
    init();
  }, []);

  const handleDashboardChange = async (dashboardID) => {
    setCurrentDashboardId(dashboardID);
    let index = dashboardList.findIndex(
      (dashboard) => dashboard["id"] === dashboardID
    );
    if (index !== -1) {
      setCurrentDashboardData(dashboardList[index]);
      await fetchWidgets(dashboardList[index]);
    }
  };
  const isDashboardEditable = useCallback(() => {
    if (currentDashboardData.hasOwnProperty("editable")) {
      return currentDashboardData["editable"];
    }
    return false;
  }, [currentDashboardData]);
  const isSlideShowEnabled = () => {
    if (currentDashboardData.hasOwnProperty("slideshow_enabled")) {
      return currentDashboardData["slideshow_enabled"];
    }
    return false;
  };

  const canAddDashboard = () => {
    let counter = 0;
    dashboardList.forEach((dashboard) => {
      if (dashboard["dashboard_type"] === "custom") {
        counter += 1;
      }
    });
    return counter < 3;
  };
  const getDashboardName = () => {
    if (currentDashboardData.hasOwnProperty("name")) {
      return currentDashboardData["name"];
    }
    return "Dashboards";
  };

  const rearrangeWidgetList = async ({ payload }) => {
    let response = await rearrangeWidgetListApi({
      dashboard_id: currentDashboardData["id"],
      payload: payload,
    });
    if (response.hasError()) {
      consoleLogger(response.errorMessage);
    }
  };

  const onChange = async (sourceId, sourceIndex, targetIndex, targetId) => {
    if (sourceIndex === targetIndex) {
      return;
    }
    // Swap the positions in the row state based on drag-and-drop
    const newState = swap(row, sourceIndex, targetIndex);

    // Find the index of the 'add_item' widget
    let addItemIndex = newState.findIndex(
      (obj) => obj["visualization"] === "add_item"
    );

    // If 'add_item' exists and is not at the last index
    if (addItemIndex !== -1 && addItemIndex !== newState.length - 1) {
      return;
    }

    // updating the widget list ordering with new state
    setRow(newState);

    //generating payload for widget rearrange api call
    let tempList = [];
    newState.forEach((obj, idx) => {
      if (obj["visualization"] !== "add_item") {
        let tempObj = { widget_id: obj["id"], sequence: idx + 4 };
        tempList.push(tempObj);
      }
    });
    await rearrangeWidgetList({
      payload: { widget_sequences: tempList },
    });
  };
  const onCounterRowChange = async (
    sourceId,
    sourceIndex,
    targetIndex,
    targetId
  ) => {
    if (sourceIndex === targetIndex) {
      return;
    }
    // Find the index of the 'add_item' widget
    let addItemStartIndex = counterRow.findIndex(
      (obj) => obj["visualization"] === "add_item"
    );

    // If 'add_item' exists and is in either source or target index range
    if (addItemStartIndex !== -1) {
      if (
        sourceIndex >= addItemStartIndex ||
        targetIndex >= addItemStartIndex
      ) {
        return;
      }
    }

    // Swap the positions in the row state based on drag-and-drop
    const newState = swap(counterRow, sourceIndex, targetIndex);
    // updating the widget list ordering with new state
    setCounterRow(newState);

    //generating payload for widget rearrange api call
    let tempList = [];
    newState.forEach((obj, idx) => {
      if (obj["visualization"] !== "add_item") {
        let tempObj = { widget_id: obj["id"], sequence: idx };
        tempList.push(tempObj);
      }
    });
    await rearrangeWidgetList({
      payload: { widget_sequences: tempList },
    });
  };
  const getRowHeight = () => {
    return window.innerHeight * 0.48; // 48% of viewport height
  };
  const getCounterRowHeight = () => {
    return window.innerHeight * 0.24; // 24% of viewport height
  };

  const getWidgetLayoutHeight = () => {
    const numberOfItemsPerRow =
      rootStore.userStore.dashboardMode === "grid_three" ? 3 : 2;
    const totalItems = row.length;
    const numberOfRows = Math.ceil(totalItems / numberOfItemsPerRow);
    const heightPerRow = getRowHeight();
    return heightPerRow * numberOfRows;
  };
  const renderDashboardWidgets = () => {
    if (row.length > 0 || counterRow.length > 0) {
      if (
        rootStore.userStore.dashboardMode === "grid" ||
        rootStore.userStore.dashboardMode === "grid_three"
      ) {
        return (
          <>
            {counterRow.length > 0 && (
              <GridContextProvider onChange={onCounterRowChange}>
                <GridDropZone
                  id="items"
                  boxesPerRow={4}
                  rowHeight={getCounterRowHeight()}
                  style={{
                    height: getCounterRowHeight(),
                  }}
                >
                  {counterRow.map((e, i) => {
                    if (e.visualization === "add_item") {
                      if (isDashboardEditable()) {
                        return (
                          <GridItem key={i}>
                            <Box sx={{ padding: "10px" }}>
                              <AddWidgetWrapper
                                sx={{
                                  height: "calc(24vh - 20px)",
                                }}
                              >
                                <IconButton
                                  color="primary"
                                  onClick={() => {
                                    analytics.triggerEvent(
                                      4625000080,
                                      "add_widget_button_tap",
                                      "dashboard_page",
                                      "add_widget_button",
                                      {}
                                    );
                                    setOpenAddWidget(true);
                                  }}
                                >
                                  <Add
                                    style={{
                                      width: "8vh",
                                      height: "8vh",
                                    }}
                                  />
                                </IconButton>
                                <Text>Add Widget</Text>
                              </AddWidgetWrapper>
                            </Box>
                          </GridItem>
                        );
                      }
                    } else {
                      if (e.version === 2) {
                        return (
                          <GridItem
                            className="counter-grid-item"
                            key={e.id}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Box
                              sx={{
                                padding: "10px",
                                width: "100%",
                                maxWidth: "490px",
                              }}
                            >
                              <Item sx={{ height: "calc(24vh - 20px)" }}>
                                <DashboardGridWidgets
                                  isDashboardEditable={isDashboardEditable}
                                  setRefreshWidgets={setRefreshWidgets}
                                  widget={e}
                                  type={"counterRow"}
                                />
                              </Item>
                            </Box>
                          </GridItem>
                        );
                      }
                    }
                  })}
                </GridDropZone>
              </GridContextProvider>
            )}

            <GridContextProvider onChange={onChange}>
              <GridDropZone
                id="items"
                boxesPerRow={
                  rootStore.userStore.dashboardMode === "grid_three" ? 3 : 2
                }
                rowHeight={getRowHeight()}
                style={{ height: getWidgetLayoutHeight() }}
              >
                {row.map((e, i) => {
                  if (e.version === 2) {
                    return (
                      <GridItem key={e.id}>
                        <Box sx={{ padding: "10px" }}>
                          <Item sx={{ height: "calc(48vh - 20px)" }}>
                            <DashboardGridWidgets
                              isDashboardEditable={isDashboardEditable}
                              setRefreshWidgets={setRefreshWidgets}
                              widget={e}
                              type={"mainGrid"}
                            />
                          </Item>
                        </Box>
                      </GridItem>
                    );
                  } else if (e.visualization === "add_item") {
                    return (
                      <GridItem key={i}>
                        <Box sx={{ padding: "10px" }}>
                          <AddWidgetWrapper
                            sx={{ height: "calc(48vh - 20px)" }}
                          >
                            <IconButton
                              color="primary"
                              disabled={!isDashboardEditable()}
                              onClick={() => {
                                analytics.triggerEvent(
                                  4625000080,
                                  "add_widget_button_tap",
                                  "dashboard_page",
                                  "add_widget_button",
                                  {}
                                );
                                setOpenAddWidget(true);
                              }}
                            >
                              <Add
                                style={{
                                  width: "8vh",
                                  height: "8vh",
                                }}
                              />
                            </IconButton>
                            <Text>Add Widget</Text>
                          </AddWidgetWrapper>
                        </Box>
                      </GridItem>
                    );
                  }
                })}
              </GridDropZone>
            </GridContextProvider>
          </>
        );
      } else if (rootStore.userStore.dashboardMode === "theatre") {
        if (widgetListToSlideshow.length > 0) {
          return (
            <DashboardSlider
              widgetList={widgetListToSlideshow}
              isDashboardEditable={isDashboardEditable}
              setRefreshWidgets={setRefreshWidgets}
            />
          );
        } else {
          return (
            <NoWidgetTextWrapper style={{ flexDirection: "column" }}>
              <TextLg>No widgets found!</TextLg>
              <Button
                variant="text"
                color="primary"
                style={{
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
                onClick={() => {
                  analytics.triggerEvent(
                    4625000080,
                    "add_widget_button_tap",
                    "dashboard_page",
                    "add_widget_button",
                    {}
                  );
                  setOpenAddWidget(true);
                }}
                disabled={!isDashboardEditable()}
              >
                <Add style={{ marginRight: "6px" }} />
                <TextLg style={{ color: "unset" }}>Add widget</TextLg>
              </Button>
            </NoWidgetTextWrapper>
          );
        }
      }
    } else {
      return (
        <NoWidgetTextWrapper>
          <TextLg>
            No widgets found! Please contact your administrator for further
            assistance.
          </TextLg>
        </NoWidgetTextWrapper>
      );
    }
  };

  return (
    <>
      {checkFeaturePermission("dashboard") ? (
        isUnderWorkSchedule() ? (
          <>
            {!loading ? (
              <Container>
                <Top>
                  <TopLeftWrapper>
                    {dashboardList.length > 1 ? (
                      <DashboardSwitchComponent
                        dashboardList={dashboardList}
                        isDashboardEditable={isDashboardEditable}
                        handleEditClick={() => {
                          setOpenEditDashboard(true);
                        }}
                        currentDashboardID={currentDashboardId}
                        handleDashboardChange={(dashboardID) => {
                          handleDashboardChange(dashboardID);
                        }}
                      />
                    ) : currentDashboardData.hasOwnProperty("name") ? (
                      <IconTextWrapper style={{ columnGap: "10px" }}>
                        <TitleText>{getDashboardName()}</TitleText>
                        {isDashboardEditable() ? (
                          <Edit
                            onClick={() => {
                              setOpenEditDashboard(true);
                            }}
                            style={{
                              width: "18px",
                              cursor: "pointer",
                              height: "18px",
                            }}
                            color="primary"
                          />
                        ) : (
                          <Lock style={{ width: "18px", height: "18px" }} />
                        )}
                      </IconTextWrapper>
                    ) : (
                      <TitleText>Dashboards</TitleText>
                    )}
                  </TopLeftWrapper>
                  <ButtonWrapper>
                    <ToggleButtonGroup
                      size="small"
                      color="primary"
                      value={rootStore.userStore.dashboardMode}
                      exclusive
                      onChange={(event, newValue) => {
                        if (newValue !== null) {
                          rootStore.userStore.updateDashboardMode(newValue);
                        }
                      }}
                    >
                      <ToggleButton value="grid_three">
                        <Tooltip title="3x3 Grid View">
                          <ViewModuleOutlined />
                        </Tooltip>
                      </ToggleButton>
                      <ToggleButton value="grid">
                        <Tooltip title="2x2 Grid View">
                          <GridView />
                        </Tooltip>
                      </ToggleButton>
                      <ToggleButton value="theatre">
                        <Tooltip title="Theatre View">
                          <VideoLabel />
                        </Tooltip>
                      </ToggleButton>
                    </ToggleButtonGroup>

                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={Open ? "long-menu" : undefined}
                      aria-expanded={Open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleMenuClick}
                    >
                      <MoreVertIcon />
                    </IconButton>

                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        "aria-labelledby": "long-button",
                      }}
                      anchorEl={anchorEl}
                      open={Open}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      getContentAnchorEl={null}
                      PaperProps={{
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5,
                        },
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          setOpenAddDashboard(true);
                          handleClose();
                        }}
                        disabled={!canAddDashboard()}
                      >
                        Add Dashboard
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          analytics.triggerEvent(
                            4625000080,
                            "add_widget_button_tap",
                            "dashboard_page",
                            "add_widget_button",
                            {}
                          );
                          setOpenAddWidget(true);
                          handleClose();
                        }}
                        disabled={!isDashboardEditable()}
                      >
                        Add Widget
                      </MenuItem>
                      {isSlideShowEnabled() && (
                        <MenuItem
                          onClick={() => {
                            setOpenSlideShow(true);
                            handleClose();
                          }}
                        >
                          Start Slideshow
                        </MenuItem>
                      )}
                    </Menu>
                  </ButtonWrapper>
                </Top>
                {!loadingWidgets ? (
                  dashboardList.length > 0 ? (
                    <Bottom
                      sx={{
                        padding:
                          rootStore.userStore.dashboardMode === "grid" ||
                          rootStore.userStore.dashboardMode === "grid_three"
                            ? "20px 12px"
                            : "20px",
                      }}
                    >
                      {renderDashboardWidgets()}
                    </Bottom>
                  ) : (
                    <NoWidgetTextWrapper>
                      <TextLgHighlight
                        onClick={() => {
                          setOpenAddDashboard(true);
                        }}
                      >
                        Add a Dashboard to get started.
                      </TextLgHighlight>
                    </NoWidgetTextWrapper>
                  )
                ) : (
                  <NoWidgetTextWrapper>
                    <LoadingAnimationComponent size={"small"} />
                  </NoWidgetTextWrapper>
                )}
              </Container>
            ) : (
              <Container
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LoadingAnimationComponent size={"small"} />
              </Container>
            )}
            <Footer />
          </>
        ) : (
          <NoAccessPage />
        )
      ) : (
        <NoAccessPermissionPage />
      )}
      {openAddWidget && (
        <AddChartModalV2
          open={openAddWidget}
          setOpen={setOpenAddWidget}
          refresh={refreshWidgets}
          setRefresh={setRefreshWidgets}
          dashboard_id={currentDashboardData["id"]}
          counterRow={counterRow}
          row={row}
        />
      )}
      {openAddDashboard && <AddDashboard />}
      {openEditDashboard && (
        <EditDashboardModal
          open={openEditDashboard}
          setOpen={setOpenEditDashboard}
          refresh={refresh}
          setRefresh={setRefresh}
          data={currentDashboardData}
        />
      )}
      {openSlideShow && (
        <DashboardWidgetSlideshow
          open={openSlideShow}
          setOpen={setOpenSlideShow}
          widgetList={widgetListToSlideshow}
        />
      )}
    </>
  );
};
export default observer(Dashboard);
