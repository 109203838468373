import { Box, Card, IconButton, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import { Delete } from "@material-ui/icons";

const CardWrapper = styled(Card)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  padding: 16px;
  background-color: #f9f9fc;
  align-items: center;
  border-radius: 8px;
`;
const Text = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  margin: 0px;
  font-weight: 600;
  margin-bottom: 2px;
`;
const TextSm = styled(Typography)`
  font: normal normal normal 12px Open Sans;
  margin: 0px;
`;
const TextWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const PermissionCardWidget = ({ cardInfo, onRemove, canRemove }) => {
  return (
    <CardWrapper>
      <TextWrapper>
        <Text>{cardInfo["scope"]}</Text>
        <TextSm>{cardInfo["desc"]}</TextSm>
        {/* <TextSm>{cardInfo["desc"]}</TextSm> */}
      </TextWrapper>
      {canRemove && (
        <IconButton style={{ padding: "0px" }} onClick={() => onRemove()}>
          <Delete color="error" />
        </IconButton>
      )}
    </CardWrapper>
  );
};

export default PermissionCardWidget;
