import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Select,
} from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import {
  getLocalizedText,
  validateUnmaskColumnsAtProjectLevel,
} from "../../Functions";
import { DatePicker } from "antd";
import { ArrowForward, ArrowRightAltSharp } from "@material-ui/icons";
import { getMembersApi } from "../../Api";
import { MultiSelect } from "react-multi-select-component";
import "./createjob.css";
const SubHeader = styled.span`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 340px;
  width: 400px;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const DateRangeWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 80px;
  align-items: center;
`;
const DateRange = styled.div`
  display: flex;
  flex-direction: column;
`;
const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: -14px;
  margin-left: 8px;
`;
const StartDate = styled.p`
  width: fit-content;
  margin: 0px;
  font: normal normal 500 12px Open Sans;
  /* border: 1px solid gray; */
`;
const EndDate = styled.p`
  font: normal normal 500 12px Open Sans;
  margin: 0px;

  /* border: 1px solid gray; */

  width: fit-content;
`;
const Label = styled.span`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Info = styled.span`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Detail = styled.span`
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;
const Input = styled.input`
  width: 428px;
  height: 30px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;
  border: 1px solid #bfbfbf;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const TextArea = styled.textarea`
  width: 428px;
  height: 60px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #bfbfbf;
  color: #4d4e4f;
  resize: none;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  width: 180px;
  @media (max-width: 700px) {
    width: 140px;
  }
`;
const SelectColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;

function ampm(time) {
  if (time.value !== "") {
    var hours = time.split(":")[0];
    var minutes = time.split(":")[1];
    var suffix = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    var displayTime = hours + ":" + minutes + " " + suffix;
    return displayTime;
  }
  return;
}
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const dateList = [
  "Yesterday",
  "Today",
  "Last 7 Days",
  "Last 30 Days",
  "Last 90 Days",
  "Custom Range",
];
const FlagText = styled.p`
  margin: 0px;
  font: normal normal 500 12px Open Sans;
`;
const ExportJobForm = (props) => {
  const [jobType, setJobType] = useState(null);

  const getJobType = () => {
    props.typeList.map((item) => {
      if (item.value === props.item.type) {
        setJobType(item["label"]);
      }
    });
  };
  useEffect(() => {
    getJobType();
  }, []);

  const handleSelect = (selectedOptions) => {
    // Group the options by their base name (before "::")
    const groupedOptions = selectedOptions.reduce((acc, option) => {
      const baseName = option.value.split("::")[0];
      if (!acc[baseName]) {
        acc[baseName] = [];
      }
      acc[baseName].push(option);
      return acc;
    }, {});

    // Filter out the options with duplicate base names and keep only the last selected option
    const filteredOptions = Object.values(groupedOptions).map(
      (group) => group[group.length - 1]
    );

    //
    props.setSelected(filteredOptions);
  };

  return (
    <>
      <SubHeader>{getLocalizedText("job_summary")}</SubHeader>
      <Divider style={{ margin: "10px 0px" }} />
      <InputContainer>
        <InputWrapper style={{ marginBottom: "20px" }}>
          <Info>{getLocalizedText("job_type")} </Info>
          <Detail>{jobType !== null ? jobType : "-"}</Detail>
        </InputWrapper>
        <InputWrapper style={{ marginBottom: "20px" }}>
          <Info>{getLocalizedText("job_title")} </Info>
          <Detail>{props.item.title ? props.item.title : "-"}</Detail>
        </InputWrapper>
        <InputWrapper style={{ marginBottom: "20px" }}>
          <Info>{getLocalizedText("is_all_leads")} </Info>
          <Detail>{!props.isDateRange ? "True" : "False"}</Detail>
        </InputWrapper>
        {props.dateRange !== null && (
          <InputWrapper style={{ marginBottom: "20px" }}>
            <Info>{getLocalizedText("date_range")} </Info>
            {props.dateRange !== null ? (
              <Row>
                <Detail>{props.dateRange["start_date"]}</Detail>
                <ArrowForward style={{ width: "18px", height: "18px" }} />
                <Detail>{props.dateRange["end_date"]}</Detail>
              </Row>
            ) : (
              <Detail>-</Detail>
            )}
          </InputWrapper>
        )}
        <InputWrapper style={{ marginBottom: "20px" }}>
          <Label>{getLocalizedText("select_columns")}</Label>
          <MultiSelect
            hasSelectAll={true}
            options={props.list}
            value={props.selected}
            className="job_multi_select"
            onChange={handleSelect}
            labelledBy="Select Columns"
          />
        </InputWrapper>
        <InputWrapper>
          <Label
            style={{
              marginBottom: "0px",
            }}
          >
            {getLocalizedText("unmask_columns")}
          </Label>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              disabled={!validateUnmaskColumnsAtProjectLevel(props.role)}
              value={true}
              control={<Radio size="small" />}
              label={
                <Label style={{ fontSize: "12px" }}>
                  {getLocalizedText("yes")}
                </Label>
              }
              checked={props.unmask}
              onChange={() => {
                props.setUnmask(true);
              }}
            />
            <FormControlLabel
              disabled={!validateUnmaskColumnsAtProjectLevel(props.role)}
              value={false}
              checked={!props.unmask}
              onChange={() => {
                props.setUnmask(false);
              }}
              control={<Radio size="small" />}
              label={
                <Label style={{ fontSize: "12px" }}>
                  {getLocalizedText("no")}
                </Label>
              }
            />
          </RadioGroup>
        </InputWrapper>
      </InputContainer>
    </>
  );
};

export default observer(ExportJobForm);
