import { OutlinedInput } from "@mui/material";

import React from "react";

const TextFieldWidget = ({ handleChange, value, type }) => {
  return (
    <OutlinedInput
      style={{ height: "30px", width: "100%" }}
      inputProps={{
        min: 0,
        "aria-label": "weight",
        style: {
          fontSize: "12px",
        },
      }}
      value={value}
      type={type}
      placeholder={"Enter Value"}
      onChange={(e) => {
        handleChange(e.target.value);
      }}
    />
  );
};

export default TextFieldWidget;
