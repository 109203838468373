import { ChevronRight } from "@material-ui/icons";
import React from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import Footer from "../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import { Box, Typography } from "@mui/material";
import {
  Button,
  Drawer,
  IconButton,
  Tab,
  Tabs,
  withStyles,
} from "@material-ui/core";
import RawLeadDetailsPanel from "../../components/raw_lead_page_components/RawLeadDetailsPanel";
import AssignRawLeadsToProject from "../../components/raw_lead_page_components/AssignRawLeadsToProject";
import RawLeadsTab from "../../components/raw_lead_page_components/tabs/RawLeadsTab";
import RawLeadsImportsTab from "../../components/raw_lead_page_components/tabs/RawLeadsImportsTab";
import { getLocalizedText } from "../../Functions";
import ImportRawLeadModal from "../../components/upload_csv_modal/ImportRawLeadModal";
import { FileUpload, FilterList } from "@mui/icons-material";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import { useEffect } from "react";
import rootStore from "../../stores/RootStore";
import UpdateRawLeadModal from "../../components/upload_csv_modal/UpdateRawLeadModal";
import ExportRawLeadModal from "../../components/raw_lead_page_components/ExportRawLeadModal";
import RawLeadExportsTab from "../../components/raw_lead_page_components/tabs/RawLeadExportsTab";
import analytics from "../../Analytics";
const Container = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Wrapper = styled(Box)`
  ${`min-height: calc(100vh - 75px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;

const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
  justify-content: space-between;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;

const IconTextWrapper = styled(Box)`
  display: flex;
  align-items: center;
`;

const Row = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: white;
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-right: 10px;
`;

const CustomTab = withStyles({
  root: {
    backgroundColor: "white",
    color: "#4D4E4F",
    font: "normal normal 600 14px Open Sans",
    textTransform: "capitalize",
  },
  selected: {
    backgroundColor: "#E7EEFA",
    color: "#4079DA",
    font: "normal normal 600 14px Open Sans",
    textTransform: "capitalize",
  },
})(Tab);
const RawLeadsPage = () => {
  const [openDetailsPanel, setOpenDetailsPanel] = useState(false);
  const [leads, setLeads] = useState([]);
  const [openAssignLead, setOpenAssignLead] = useState(false);
  const [assignState, setAssignState] = useState(0);
  const [refreshAfterAssign, setRefreshAfterAssign] = useState(false);
  const [refreshAfterImport, setRefreshAfterImport] = useState(false);
  const [refreshAfterExport, setRefreshAfterExport] = useState(false);
  const [tabIdx, setTabIdx] = useState(0);
  const [openRawLead, setOpenRawLead] = useState(false);
  const [openUpdateRawLead, setOpenUpdateRawLead] = useState(false);
  const [openFilterPanel, setOpenFilterPanel] = useState(false);
  const [openExportRawLead, setOpenExportRawLead] = useState(false);
  useEffect(() => {
    analytics.triggerEvent(
      4625000124,
      "raw_leads_page_load",
      "raw_leads_page",
      "",
      {}
    );
    rootStore.authStore.updateLeadDetailStatus();
  }, []);
  return (
    <>
      <Container>
        <Wrapper>
          <TopWrapper>
            <IconTextWrapper>
              <Header>{getLocalizedText("raw_leads")}</Header>
              <ChevronRight style={{ width: "16px", height: "16px" }} />
            </IconTextWrapper>
          </TopWrapper>
          <Row
            style={{
              backgroundColor: "white",
              justifyContent: "space-between",
            }}
          >
            <Tabs
              value={tabIdx}
              textColor="primary"
              indicatorColor="primary"
              onChange={(event, newValue) => {
                if (newValue === 0) {
                  analytics.triggerEvent(
                    4625000125,
                    "raw_leads_tab_tap",
                    "raw_leads_page",
                    "raw_leads_tab",
                    {}
                  );
                } else if (newValue === 1) {
                  analytics.triggerEvent(
                    4625000126,
                    "imports_tab_tap",
                    "raw_leads_page",
                    "imports_tab",
                    {}
                  );
                } else if (newValue === 2) {
                  analytics.triggerEvent(
                    4625000127,
                    "exports_tab_tap",
                    "raw_leads_page",
                    "exports_tab",
                    {}
                  );
                }
                setTabIdx(newValue);
                setAssignState(0);
                setLeads([]);
              }}
            >
              <CustomTab label="Raw Leads" />
              <CustomTab label="Imports" />
              <CustomTab label="Exports" />
            </Tabs>
            <Row
              style={{ backgroundColor: "transparent", width: "fit-content" }}
            >
              {(() => {
                if (tabIdx === 0) {
                  return (
                    <ButtonWrapper>
                      <Button
                        onClick={() => {
                          analytics.triggerEvent(
                            4625000128,
                            "move_raw_leads_button_tap",
                            "raw_leads_tab",
                            "move_raw_leads_button",
                            {}
                          );
                          setOpenAssignLead(true);
                        }}
                        disabled={assignState < 1}
                        color="primary"
                        variant="contained"
                        style={{
                          textTransform: "none",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        {getLocalizedText("move_raw_leads")}
                      </Button>
                      <Button
                        onClick={() => {
                          analytics.triggerEvent(
                            4625000129,
                            "filter_raw_leads_button_tap",
                            "raw_leads_tab",
                            "filter_raw_leads_button",
                            {}
                          );
                          setOpenFilterPanel(true);
                        }}
                        disabled={assignState > 0}
                        color="primary"
                        variant="contained"
                        style={{
                          textTransform: "none",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        {getLocalizedText("filter_raw_leads")}
                      </Button>
                    </ButtonWrapper>
                  );
                } else if (tabIdx === 1) {
                  return (
                    <ButtonWrapper>
                      <Button
                        onClick={() => {
                          analytics.triggerEvent(
                            4625000140,
                            "update_raw_leads_button_tap",
                            "imports_tab",
                            "update_raw_leads_button",
                            {}
                          );
                          setOpenUpdateRawLead(true);
                        }}
                        color="primary"
                        variant="contained"
                        style={{
                          textTransform: "none",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        {getLocalizedText("update_raw_leads")}
                      </Button>
                      <Button
                        onClick={() => {
                          analytics.triggerEvent(
                            4625000141,
                            "import_raw_leads_button_tap",
                            "imports_tab",
                            "import_raw_leads_button",
                            {}
                          );
                          setOpenRawLead(true);
                        }}
                        color="primary"
                        variant="contained"
                        style={{
                          textTransform: "none",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        {getLocalizedText("import_raw_leads")}
                      </Button>
                    </ButtonWrapper>
                  );
                } else {
                  return (
                    <ButtonWrapper>
                      <Button
                        disabled={assignState > 0}
                        onClick={() => {
                          analytics.triggerEvent(
                            4625000152,
                            "export_raw_leads_button_tap",
                            "exports_tab",
                            "export_raw_leads_button",
                            {}
                          );
                          setOpenExportRawLead(true);
                        }}
                        color="primary"
                        variant="contained"
                        style={{
                          textTransform: "none",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        {getLocalizedText("export_raw_leads")}
                      </Button>
                    </ButtonWrapper>
                  );
                }
              })()}
            </Row>
          </Row>

          {(() => {
            if (tabIdx === 0) {
              return (
                <RawLeadsTab
                  setAssignState={setAssignState}
                  setLeads={setLeads}
                  refresh={refreshAfterAssign}
                  refreshAfterImport={refreshAfterImport}
                  openFilterPanel={openFilterPanel}
                  setOpenFilterPanel={setOpenFilterPanel}
                />
              );
            } else if (tabIdx === 1) {
              return <RawLeadsImportsTab refresh={refreshAfterImport} />;
            } else {
              return (
                <RawLeadExportsTab
                  refresh={refreshAfterExport}
                  setRefresh={setRefreshAfterExport}
                />
              );
            }
          })()}
        </Wrapper>
      </Container>
      <Footer />
      <Drawer
        anchor={"right"}
        open={openDetailsPanel}
        onClose={() => {
          setOpenDetailsPanel(false);
        }}
      >
        {
          <RawLeadDetailsPanel
            open={openDetailsPanel}
            setOpen={setOpenDetailsPanel}
          />
        }
      </Drawer>

      {openAssignLead && (
        <AssignRawLeadsToProject
          open={openAssignLead}
          setOpen={setOpenAssignLead}
          leads={leads}
          setRefresh={setRefreshAfterAssign}
          refresh={refreshAfterAssign}
        />
      )}
      {openRawLead && (
        <ImportRawLeadModal
          open={openRawLead}
          setOpen={setOpenRawLead}
          refresh={refreshAfterImport}
          setRefresh={setRefreshAfterImport}
        />
      )}
      {openUpdateRawLead && (
        <UpdateRawLeadModal
          open={openUpdateRawLead}
          setOpen={setOpenUpdateRawLead}
          refresh={refreshAfterImport}
          setRefresh={setRefreshAfterImport}
        />
      )}
      {openExportRawLead && (
        <ExportRawLeadModal
          open={openExportRawLead}
          setOpen={setOpenExportRawLead}
          setRefresh={setRefreshAfterExport}
        />
      )}
    </>
  );
};

export default observer(RawLeadsPage);
