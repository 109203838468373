import { Box, Chip, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { styled } from "@mui/material/styles";
import { InsertDriveFile, Visibility } from "@material-ui/icons";
import { IconButton, Tooltip } from "@material-ui/core";
const Text = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;
const TextSmall = styled(Typography)`
  font: normal normal 400 12px Open Sans;
  color: #4d4e4f;
`;
const TextWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: center;
`;
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed #185dd2;
  padding: 12px;
  width: 100%;
  height: 200px;
  margin: auto;
`;
const DragDropFieldComponent = ({
  acceptedFiles,
  setFile,
  file,
  setOpenPreview,
}) => {
  const [loading, setLoading] = useState(false);
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onprogress = () => setLoading(true);
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        setLoading(false);
        console.log("file::::", file);
        setFile(file);
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: acceptedFiles,
  });

  function truncate(str) {
    return str.length > 30 ? str.slice(0, 30 - 1) + "..." : str;
  }

  return (
    <Container {...getRootProps()}>
      <InsertDriveFile
        style={{ width: "50px", height: "50px", color: "#185DD2" }}
      />
      <Box component={"input"} {...getInputProps()} />
      {!loading ? (
        file === null ? (
          <TextWrapper>
            <Text>Drag 'n' drop some files here</Text>
            <TextSmall>
              Or,{" "}
              <span style={{ textDecoration: "underline", cursor: "pointer" }}>
                browse to upload.
              </span>
            </TextSmall>
          </TextWrapper>
        ) : (
          <TextWrapper style={{ flexDirection: "row", alignItems: "center" }}>
            <Chip
              variant="outlined"
              sx={{ borderRadius: "0px" }}
              label={
                <Tooltip title={file.name}>
                  <Text style={{ fontWeight: "normal" }}>
                    {truncate(file.name)}
                  </Text>
                </Tooltip>
              }
              onDelete={() => {
                setFile(null);
              }}
            />
            <IconButton
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                setOpenPreview(true);
              }}
            >
              <Visibility />
            </IconButton>
          </TextWrapper>
        )
      ) : (
        <TextWrapper>
          <Text>Loading, please wait...</Text>
        </TextWrapper>
      )}
    </Container>
  );
};

export default DragDropFieldComponent;
