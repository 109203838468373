import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, CircularProgress } from "@material-ui/core";
import {
  AppBar,
  Box,
  Grid,
  OutlinedInput,
  Toolbar,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";

import { useEffect } from "react";
import { getListApi } from "../../Api";
import rootStore from "../../stores/RootStore";
import Select from "react-select";
import { getSlug } from "../../Functions";
import { reportFilterOperatorMapList } from "../../Db";
import { Add, Cancel } from "@material-ui/icons";
import { Circle } from "@mui/icons-material";
import moment from "moment";
import ReportFilterValueFieldComponent from "./filter_report_components/ReportFilterValueFieldComponent";
import DateAndDateTimeFieldComponent from "./filter_report_components/DateAndDateTimeFieldComponent";
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for options
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for the entire control container, including the search input
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "no options" message
  }),
  loadingMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "loading" message
  }),
};

const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  gap: 20px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ChipLable = styled(Typography)`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
  margin-right: 4px;
`;
const ChipValue = styled(Typography)`
  margin: 0px;
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  word-wrap: break-word;
  width: 100%;
`;
const CustomChip = styled(Box)`
  display: flex;
  position: relative;
  padding: 20px;
  background-color: #e0e0e0;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  border-radius: 12px;
`;
const FilterListContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ${`max-height: calc(100vh - 400px);`};
  overflow-y: auto;
  column-gap: 10px;
  row-gap: 10px;
  padding: 0px 20px;
  ::-webkit-scrollbar {
    width: 5px;
    background-color: #efeff4;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #999;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #666;
  }
`;

const fieldsListToFetchDataManually = [
  "lead_owner",
  "caller",
  "lead_status",
  "lead_stage",
  "lead_source",
];
const FilterReportSidepanel = ({
  setOpen,
  availableFilterList,
  postSaveReportFilter,
  savedReportFilters,
  columnsList,
}) => {
  const [loading, setLoading] = useState(true);

  const [multiSelectFilterColumnValue, setMultiSelectFilterColumnValue] =
    useState(null);
  const [selectedFilterColumn, setSelectedFilterColumn] = useState(null);
  const [operatorList, setOperatorList] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [filterTypeListMeta, setFilterTypeListMeta] = useState({});
  const [filter, setFilter] = useState({});
  const [selectedFilterList, setSelectedFilterList] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    postSaveReportFilter(selectedFilterList);
  };

  const init = async () => {
    setSelectedFilterList(savedReportFilters);
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const getSelectFilterColumnOptions = () => {
    let tempSelectFilterOptions = [];
    if (availableFilterList.length > 0) {
      availableFilterList.forEach((entry) => {
        let index = columnsList.findIndex(
          (column) => column["field"] === entry
        );
        if (index !== -1) {
          tempSelectFilterOptions.push({
            label: columnsList[index]["headerName"],
            value: columnsList[index]["id"],
          });
        }
      });
    } else {
      let leadCreatedAtColumnIndex = columnsList.findIndex(
        (column) => column["field"] === "created_at"
      );
      if (leadCreatedAtColumnIndex !== -1) {
        tempSelectFilterOptions.push({
          label: columnsList[leadCreatedAtColumnIndex]["headerName"],
          value: columnsList[leadCreatedAtColumnIndex]["id"],
        });
      }
    }
    return tempSelectFilterOptions.filter((obj) =>
      canShowOptionInSelectColumnFilter(obj["value"])
    );
  };

  const getOperatorList = (item) => {
    if (item["data_type"] === "date" || item["data_type"] === "datetime") {
      return reportFilterOperatorMapList["date_or_datetime"];
    } else if (item["data_type"] === "string") {
      if (item["filter_type"] === "list") {
        return reportFilterOperatorMapList["list"];
      } else {
        return reportFilterOperatorMapList["string"];
      }
    } else if (item["data_type"] === "integer") {
      if (item["filter_type"] === "range") {
        return reportFilterOperatorMapList["integer_range"];
      } else if (item["filter_type"] === "list") {
        return reportFilterOperatorMapList["list"];
      } else {
        return reportFilterOperatorMapList["integer"];
      }
    }

    return [];
  };

  const getListDetailsForManualFields = (columnMeta) => {
    if (
      columnMeta["field"] === "lead_owner" ||
      columnMeta["field"] === "caller"
    ) {
      let tempList = [...rootStore.authStore.projectMemberList].map((obj) => ({
        name: obj["name"],
        value: obj["id"],
      }));
      return tempList;
    } else if (columnMeta["field"] === "lead_status") {
      let tempList = [...rootStore.authStore.statusList].map((obj) => ({
        name: obj["status"],
        value: obj["id"],
      }));
      return tempList;
    } else if (columnMeta["field"] === "lead_stage") {
      let tempList = [...rootStore.authStore.stageList].map((obj) => ({
        name: obj["stage"],
        value: obj["id"],
      }));
      return tempList;
    } else if (columnMeta["field"] === "lead_source") {
      let tempList = [...rootStore.authStore.sourcesList].map((obj) => ({
        name: obj["source"],
        value: obj["id"],
      }));
      return tempList;
    }
    return [];
  };

  const getListDetailsToRender = async (columnMeta) => {
    const filterInput = columnMeta["filter_input"];
    let tempType = "list";
    let tempList = [];

    if (fieldsListToFetchDataManually.includes(columnMeta["field"])) {
      tempType = "list_objects";
      tempList = getListDetailsForManualFields(columnMeta);
    } else {
      if (filterInput.charAt(0) === "/" || filterInput.startsWith("ep:")) {
        let slug = getSlug(filterInput);
        let response = await getListApi({ slug: slug });
        if (response !== null) {
          if (Array.isArray(response)) {
            tempType = "list";
            tempList = response;
          } else if (typeof response === "object") {
            if (Object.keys(response).length > 0) {
              tempType = response["type"];
              tempList = response["values"];
            }
          }
        }
      } else if (
        filterInput.charAt(0) !== "/" &&
        filterInput !== "" &&
        filterInput !== null
      ) {
        try {
          let parseJSON = JSON.parse(filterInput);
          if (Array.isArray(parseJSON)) {
            tempType = "list";
            tempList = parseJSON;
          } else if (typeof parseJSON === "object") {
            if (Object.keys(parseJSON).length > 0) {
              tempType = parseJSON["type"];
              tempList = parseJSON["values"];
            }
          }
        } catch (error) {
          if (filterInput.length > 0) {
            let list = filterInput.split(",");
            tempType = "list";
            tempList = list;
          }
        }
      }
    }
    return { list: tempList, type: tempType };
  };

  const isFilterValuePicked = () => {
    if (Object.keys(filter).length > 0) {
      let data = filter[selectedFilterColumn["field"]];
      if (data["filter_type"] === "list") {
        return data["value"].length > 0;
      } else if (data["filter_type"] === "range") {
        if (data["data_type"] === "date" || data["data_type"] === "datetime") {
          return data["value"] !== null;
        } else {
          return (
            data["value"].hasOwnProperty("start") &&
            data["value"].hasOwnProperty("end")
          );
        }
      } else {
        return data["value"] !== null && data["value"] !== "";
      }
    }
    return false;
  };

  const isAddButtonEnabled = () => {
    if (isFilterValuePicked()) {
      return selectedOperator !== null;
    }
    return false;
  };

  const handleAdd = () => {
    let tempList = [...selectedFilterList];
    let tempData = {
      column_id: selectedFilterColumn["id"],
      filter: filter,
      operator: selectedOperator,
    };
    let index = tempList.findIndex(
      (filterData) => filterData["column_id"] === tempData["column_id"]
    );
    if (index !== -1) {
      tempList[index] = tempData;
    } else {
      tempList.push(tempData);
    }
    setSelectedFilterList(tempList);
    setFilterTypeListMeta({});
    setOperatorList([]);
    setSelectedOperator(null);
    setSelectedFilterColumn(null);
    setMultiSelectFilterColumnValue(null);
    setFilter({});
  };

  const handleRemove = (columnID) => {
    let tempList = [...selectedFilterList];
    tempList = tempList.filter(
      (filterData) => filterData["column_id"] !== columnID
    );
    setSelectedFilterList(tempList);
  };

  const handleEdit = async (filterData) => {
    let index = columnsList.findIndex(
      (column) => column["id"] === filterData["column_id"]
    );
    if (index !== -1) {
      let columnMeta = columnsList[index];
      if (columnMeta["filter_type"] === "list") {
        let filterTypeListMeta = await getListDetailsToRender(columnMeta);

        setFilterTypeListMeta(filterTypeListMeta);
      }
      setFilter(filterData["filter"]);
      setSelectedOperator(filterData["operator"]);
      setSelectedFilterColumn(columnMeta);
      setMultiSelectFilterColumnValue({
        label: columnMeta["headerName"],
        value: columnMeta["id"],
      });
      let tempList = getOperatorList(columnMeta);
      setOperatorList(tempList);
    }
  };

  const canShowRelativeDateStringPicker = () => {
    let index = columnsList.findIndex(
      (column) => column["id"] === selectedFilterColumn["id"]
    );
    if (index !== -1) {
      return (
        columnsList[index]["data_type"] === "date" ||
        columnsList[index]["data_type"] === "datetime"
      );
    }

    return false;
  };

  const getStartAndEndDateBasedOnRelatveDateString = (filterDate) => {
    const now = moment();

    switch (filterDate) {
      case "Today":
        return [now.clone().startOf("day"), now.clone().endOf("day")];

      case "Yesterday":
        return [
          now.clone().subtract(1, "day").startOf("day"),
          now.clone().subtract(1, "day").endOf("day"),
        ];

      case "Last 7 Days":
        return [
          now.clone().subtract(7, "days").startOf("day"),
          now.clone().endOf("day"),
        ];

      case "Last 14 Days":
        return [
          now.clone().subtract(14, "days").startOf("day"),
          now.clone().endOf("day"),
        ];

      case "Last 30 Days":
        return [
          now.clone().subtract(30, "days").startOf("day"),
          now.clone().endOf("day"),
        ];

      case "This Week":
        return [
          now.clone().startOf("week").startOf("day"),
          now.clone().endOf("day"),
        ];

      case "Last Week":
        return [
          now.clone().subtract(1, "week").startOf("week").startOf("day"),
          now.clone().subtract(1, "week").endOf("week").endOf("day"),
        ];

      case "This Month":
        return [
          now.clone().startOf("month").startOf("day"),
          now.clone().endOf("day"),
        ];

      case "Last Month":
        return [
          now.clone().subtract(1, "month").startOf("month").startOf("day"),
          now.clone().subtract(1, "month").endOf("month").endOf("day"),
        ];
      default:
        return [now.clone().startOf("day"), now.clone().endOf("day")];
    }
  };

  const canShowOptionInSelectColumnFilter = (columnID) => {
    let index = selectedFilterList.findIndex(
      (obj) => obj["column_id"] === columnID
    );
    return index === -1;
  };

  const renderValueFieldComponent = () => {
    if (selectedFilterColumn === null || selectedOperator === null) {
      return (
        <OutlinedInput
          disabled
          placeholder="Enter Value"
          style={{
            width: "100%",
            height: "30px",
            fontSize: "12px",
          }}
        />
      );
    } else {
      if (canShowRelativeDateStringPicker()) {
        return (
          <DateAndDateTimeFieldComponent
            details={
              filter.hasOwnProperty(selectedFilterColumn["field"])
                ? filter[selectedFilterColumn["field"]]
                : { rolling_date_string: null, value: null }
            }
            handleChange={(selectedOption, rollingDateString, scope) => {
              let tempDateRange =
                scope === "rolling_date"
                  ? getStartAndEndDateBasedOnRelatveDateString(
                      selectedOption["value"]
                    )
                  : selectedOption;
              setFilter({
                ...filter,
                [selectedFilterColumn["field"]]: {
                  value: tempDateRange,
                  filter_type: "range",
                  data_type: selectedFilterColumn["data_type"],
                  rolling_date_string: rollingDateString,
                },
              });
            }}
          />
        );
      } else {
        return (
          <ReportFilterValueFieldComponent
            filter={filter}
            setFilter={setFilter}
            filterTypeListMeta={filterTypeListMeta}
            selectedColumn={selectedFilterColumn}
            selectedFilterList={selectedFilterList}
          />
        );
      }
    }
  };

  return !loading ? (
    <>
      <Box role="presentation">
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: 500,
            right: 0,
            bgcolor: "#f9f9fc",
          }}
        >
          <Toolbar>
            <ModelHeader>Reports Filter</ModelHeader>
          </Toolbar>
        </AppBar>

        <ModelWrapper component={"form"} onSubmit={handleSubmit}>
          <InputContainer>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  component={"fieldset"}
                  sx={{ border: "1px solid #cccccc", padding: "12px" }}
                >
                  <legend
                    style={{
                      width: "auto",
                      marginBottom: "0px",
                      fontSize: "12px",
                    }}
                  >
                    Filters
                  </legend>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <InputWrapper>
                        <Label>Select Column</Label>
                        <Select
                          isDisabled={selectedFilterList.length > 5}
                          options={getSelectFilterColumnOptions()}
                          value={multiSelectFilterColumnValue}
                          onChange={async (selectedOption) => {
                            if (selectedOption !== null) {
                              let index = columnsList.findIndex(
                                (column) =>
                                  column["id"] === selectedOption.value
                              );
                              if (index !== -1) {
                                let columnMeta = columnsList[index];
                                if (columnMeta["filter_type"] === "list") {
                                  let filterTypeListMeta =
                                    await getListDetailsToRender(columnMeta);

                                  setFilterTypeListMeta(filterTypeListMeta);
                                }
                                let tempList = getOperatorList(columnMeta);
                                setFilter({});
                                if (tempList.length > 0) {
                                  setSelectedOperator(tempList[0]);
                                } else {
                                  setSelectedOperator(null);
                                }
                                setOperatorList(tempList);
                                setMultiSelectFilterColumnValue(selectedOption);
                                setSelectedFilterColumn(columnMeta);
                              }
                            } else {
                              setFilterTypeListMeta({});
                              setOperatorList([]);
                              setSelectedOperator(null);
                              setSelectedFilterColumn(null);
                              setMultiSelectFilterColumnValue(null);
                              setFilter({});
                            }
                          }}
                          closeMenuOnSelect={true}
                          styles={customStyles}
                          isClearable
                        />
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={6}>
                      <InputWrapper>
                        <Label>Select Operator</Label>
                        <Select
                          isDisabled={
                            operatorList.length === 0 ||
                            multiSelectFilterColumnValue === null ||
                            selectedFilterList.length > 5
                          }
                          options={operatorList}
                          value={selectedOperator}
                          onChange={(selectedOption) => {
                            setSelectedOperator(selectedOption);
                          }}
                          closeMenuOnSelect={true}
                          styles={customStyles}
                          isClearable
                        />
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <Label>Filter Value</Label>

                        {renderValueFieldComponent()}
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        disabled={!isAddButtonEnabled()}
                        onClick={handleAdd}
                        variant="contained"
                        color="primary"
                        style={{ textTransform: "capitalize", width: "100%" }}
                      >
                        <Add /> Add Filter
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12}>
                {selectedFilterList.length > 0 && (
                  <FilterListContainer>
                    {selectedFilterList.map((item, i) => {
                      let index = columnsList.findIndex(
                        (column) => column["id"] === item["column_id"]
                      );
                      if (index !== -1) {
                        let columnMeta = columnsList[index];

                        if (columnMeta["filter_type"] === "list") {
                          let value =
                            item["filter"][columnMeta["field"]]["value"];
                          return (
                            <CustomChip
                              onClick={() => {
                                handleEdit(item);
                              }}
                              key={i}
                              style={{ flexDirection: "column" }}
                            >
                              <Cancel
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRemove(item["column_id"]);
                                }}
                                style={{
                                  position: "absolute",
                                  top: "2px",
                                  right: "2px",
                                  cursor: "pointer",
                                }}
                              />

                              <ChipLable>{`${columnMeta["headerName"]} ${item["operator"]["label"]}`}</ChipLable>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {value.map((list_item, i) => {
                                  return (
                                    <Box
                                      sx={{ display: "flex", gap: "4px" }}
                                      key={i}
                                    >
                                      <Circle
                                        style={{
                                          width: "8px",
                                          height: "8px",
                                          color: "gray",
                                          marginTop: "2px",
                                        }}
                                      />
                                      <ChipValue>
                                        {list_item["label"]}
                                      </ChipValue>
                                    </Box>
                                  );
                                })}
                              </Box>
                            </CustomChip>
                          );
                        } else if (columnMeta["filter_type"] === "range") {
                          let chipValueString = "";
                          if (
                            columnMeta["data_type"] === "date" ||
                            columnMeta["data_type"] === "datetime"
                          ) {
                            let start = moment(
                              item["filter"][columnMeta["field"]]["value"][0]
                            ).format("YYYY-MM-DD");
                            let end = moment(
                              item["filter"][columnMeta["field"]]["value"][1]
                            ).format("YYYY-MM-DD");
                            chipValueString = `${start} to ${end}`;
                          } else {
                            chipValueString = `${
                              item["filter"][columnMeta["field"]]["value"][
                                "start"
                              ]
                            } to ${
                              item["filter"][columnMeta["field"]]["value"][
                                "end"
                              ]
                            }`;
                          }
                          return (
                            <>
                              <CustomChip
                                onClick={() => {
                                  handleEdit(item);
                                }}
                              >
                                {columnMeta["field"] !== "created_at" && (
                                  <Cancel
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemove(item["column_id"]);
                                    }}
                                    style={{
                                      position: "absolute",
                                      top: "2px",
                                      right: "2px",
                                      cursor: "pointer",
                                    }}
                                  />
                                )}

                                <ChipLable>{`${columnMeta["headerName"]} ${item["operator"]["label"]}`}</ChipLable>
                                <ChipValue>{chipValueString}</ChipValue>
                              </CustomChip>
                            </>
                          );
                        } else {
                          return (
                            <>
                              <CustomChip
                                onClick={() => {
                                  handleEdit(item);
                                }}
                              >
                                <Cancel
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleRemove(item["column_id"]);
                                  }}
                                  style={{
                                    position: "absolute",
                                    top: "2px",
                                    right: "2px",
                                    cursor: "pointer",
                                  }}
                                />

                                <ChipLable>{`${columnMeta["headerName"]} ${item["operator"]["label"]}`}</ChipLable>
                                <ChipValue>
                                  {item["filter"][columnMeta["field"]]["value"]}
                                </ChipValue>
                              </CustomChip>
                            </>
                          );
                        }
                      }
                    })}
                  </FilterListContainer>
                )}
              </Grid>
            </Grid>
          </InputContainer>

          <AppBar
            position="fixed"
            color="inherit"
            elevation={0}
            sx={{ top: "auto", bottom: 0, width: 500 }}
          >
            <Toolbar style={{ width: 500, position: "relative" }}>
              <ButtonWrapper>
                <Button
                  onClick={handleClose}
                  type="button"
                  variant="contained"
                  color="default"
                  style={{
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  Save
                </Button>
              </ButtonWrapper>
            </Toolbar>
          </AppBar>
        </ModelWrapper>
      </Box>
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: 500,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default observer(FilterReportSidepanel);
