import {
  Box,
  Chip,
  IconButton,
  List,
  ListItem,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { AddBoxOutlined } from "@mui/icons-material";

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
  white-space: nowrap;
`;

const Row = styled(Box)`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const SectionFieldsPanel = ({ scope, setData, data }) => {
  const [option, setOption] = useState("");
  const handleDelete = (key) => {
    let list = [...data["key_points"]];

    list = list.filter((item) => item !== key);
    setData({ ...data, key_points: list });
  };
  const handleAdd = () => {
    let list = [...data["key_points"]];
    let index = data["key_points"].findIndex((item) => item === option);
    if (index === -1) {
      list.push(option);
    }
    setData({ ...data, key_points: list });
    setOption("");
  };
  useEffect(() => {}, [data]);
  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    console.log("file:::", file);
    setData({ ...data, [event.target.name]: file });
  };
  return (
    <List style={{ width: 300 }}>
      {data.hasOwnProperty("heading") && (
        <ListItem>
          <InputWrapper>
            <Label>Heading</Label>
            <OutlinedInput
              value={data["heading"]}
              name="heading"
              onChange={(e) => {
                let newData = {
                  ...data,
                  ["heading"]: e.target.value,
                };
                setData(newData);
              }}
              placeholder="Enter Value"
              style={{
                width: "100%",
                height: "30px",
                fontSize: "12px",
              }}
            />
          </InputWrapper>
        </ListItem>
      )}
      {data.hasOwnProperty("left_logo") && (
        <ListItem>
          <InputWrapper>
            <Label>Left Logo</Label>
            <OutlinedInput
              type="file"
              // value={data["left_logo"]}
              name="left_logo"
              inputProps={{
                accept: "image/png, image/gif, image/jpeg",
              }}
              onChange={handleLogoUpload}
              style={{
                width: "100%",
                height: "30px",
                fontSize: "12px",
              }}
            />
          </InputWrapper>
        </ListItem>
      )}

      {data.hasOwnProperty("right_logo") && (
        <ListItem>
          <InputWrapper>
            <Label>Right Logo</Label>
            <OutlinedInput
              type="file"
              accept="image/png, image/gif, image/jpeg"
              // value={data["right_logo"]}
              name="right_logo"
              onChange={handleLogoUpload}
              style={{
                width: "100%",
                height: "30px",
                fontSize: "12px",
              }}
            />
          </InputWrapper>
        </ListItem>
      )}

      {data.hasOwnProperty("bg") && (
        <ListItem>
          <InputWrapper>
            <Label>Background Image</Label>
            <OutlinedInput
              type="file"
              accept="image/png, image/gif, image/jpeg"
              // value={data["bg"]}
              name="bg"
              onChange={handleLogoUpload}
              style={{
                width: "100%",
                height: "30px",
                fontSize: "12px",
              }}
            />
          </InputWrapper>
        </ListItem>
      )}

      {data.hasOwnProperty("key_points") && (
        <ListItem>
          <InputWrapper>
            <Label>Options</Label>
            <Row>
              <OutlinedInput
                value={option}
                name="key_points"
                onChange={(e) => {
                  setOption(e.target.value);
                }}
                placeholder="Enter Value"
                style={{
                  width: "100%",
                  height: "30px",
                  fontSize: "12px",
                }}
              />
              <IconButton
                color="primary"
                disabled={option === ""}
                onClick={handleAdd}
              >
                <AddBoxOutlined />
              </IconButton>
            </Row>
            {data["key_points"].length > 0 && (
              <Row style={{ flexWrap: "wrap", padding: "4px", width: "100%" }}>
                {data["key_points"].map((item, i) => {
                  return (
                    <Chip
                      key={i}
                      label={item}
                      onDelete={() => handleDelete(item)}
                    />
                  );
                })}
              </Row>
            )}
          </InputWrapper>
        </ListItem>
      )}
      {data.hasOwnProperty("footer_text_left") && (
        <ListItem>
          <InputWrapper>
            <Label>Footer Text Left</Label>
            <OutlinedInput
              value={data["footer_text_left"]}
              name="footer_text_left"
              onChange={(e) => {
                let newData = {
                  ...data,
                  ["footer_text_left"]: e.target.value,
                };
                setData(newData);
              }}
              placeholder="Enter Value"
              style={{
                width: "100%",
                height: "30px",
                fontSize: "12px",
              }}
            />
          </InputWrapper>
        </ListItem>
      )}
      {data.hasOwnProperty("footer_text_right") && (
        <ListItem>
          <InputWrapper>
            <Label>Footer Text Right</Label>
            <OutlinedInput
              value={data["footer_text_right"]}
              name="footer_text_right"
              onChange={(e) => {
                let newData = {
                  ...data,
                  ["footer_text_right"]: e.target.value,
                };
                setData(newData);
              }}
              placeholder="Enter Value"
              style={{
                width: "100%",
                height: "30px",
                fontSize: "12px",
              }}
            />
          </InputWrapper>
        </ListItem>
      )}
    </List>
  );
};

export default SectionFieldsPanel;
