import {
  AppBar,
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Toolbar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import {
  getLocalizedText,
  getWorkTimingBreakChipColorTheme,
} from "../../../Functions";
import { Add } from "@material-ui/icons";
import AddBreakDialog from "./AddBreakDialog";
import { endTimeBufferList } from "../../../Db";
const dialogWidth = 420;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const ChipText = styled(Typography)`
  font: normal normal 600 10px Open Sans;
`;
const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const ConfigureWorkDay = ({
  setOpen,
  open,
  handleSave,
  dayConfiguration,
  defaultEndTimeBuffer,
}) => {
  const [details, setDetails] = useState({
    start_time: "",
    end_time: "",
    end_time_buffer: defaultEndTimeBuffer,
    breaks: [],
  });
  const [openAddBreakDialog, setOpenAddBreakDialog] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    handleSave(details);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (Object.keys(dayConfiguration).length > 0) {
      let tempObj = { ...dayConfiguration };
      if (
        tempObj.hasOwnProperty("end_time_buffer") &&
        tempObj["end_time_buffer"] !== null
      ) {
        tempObj["end_time_buffer"] = parseInt(
          dayConfiguration["end_time_buffer"]
        );
      } else {
        tempObj["end_time_buffer"] = defaultEndTimeBuffer;
      }
      console.log("temp obj:::", tempObj);
      setDetails(tempObj);
    }
  }, []);

  const renderBreakData = ({ data }) => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <ChipText style={{ fontSize: "12px" }}>{data["title"]}</ChipText>
        <ChipText>{`(${data["start_time"]} - ${data["end_time"]})`}</ChipText>
      </Box>
    );
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box component={"form"} onSubmit={handleSubmit}>
            <AppBar
              component={"nav"}
              elevation={0}
              position="sticky"
              color="inherit"
              sx={{
                top: 0,
                bottom: "auto",
                width: "100%",
                right: 0,
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                }}
              >
                <HeaderWrapper>
                  <Header>Configure Work Day</Header>
                  <SubHeader>
                    {" "}
                    {getLocalizedText("fill_in_the_below_details")}
                  </SubHeader>
                </HeaderWrapper>
              </Toolbar>
            </AppBar>
            <Divider style={{ margin: "0px 16px" }} />
            <Container>
              <Box
                style={{
                  height: "100%",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>{getLocalizedText("start_time")}*</Label>
                      <OutlinedInput
                        style={{ height: "30px" }}
                        inputProps={{
                          max: details.end_time,
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        required
                        value={details.start_time}
                        type="time"
                        placeholder="enter start time"
                        name="start_time"
                        onChange={handleChange}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>{getLocalizedText("end_time")}*</Label>
                      <OutlinedInput
                        style={{ height: "30px" }}
                        inputProps={{
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        required
                        value={details.end_time}
                        type="time"
                        placeholder="enter end_time"
                        name="end_time"
                        onChange={handleChange}
                      />
                    </InputWrapper>
                  </Grid>

                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>End Time Buffer (Additional Minutes)*</Label>
                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          displayEmpty
                          value={details.end_time_buffer}
                          onChange={handleChange}
                          required
                          disableUnderline
                          variant="outlined"
                          name="end_time_buffer"
                          style={{
                            width: "100%",
                            height: "30px",
                            fontSize: "12px",
                            borderRadius: "4px",
                          }}
                        >
                          {endTimeBufferList.map((buffer, idx) => {
                            return (
                              <MenuItem
                                value={buffer}
                                key={idx}
                                style={{ fontSize: "12px" }}
                              >
                                {buffer}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </InputWrapper>
                  </Grid>
                  {details["breaks"].length > 0 && (
                    <Grid item xs={12}>
                      <Box
                        component={"fieldset"}
                        sx={{
                          border: "1px solid #cccccc",
                          padding: "20px",
                          paddingTop: "12px",
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          flexWrap: "wrap",
                          maxHeight: "100px",
                          overflowY: "auto",
                        }}
                      >
                        <legend
                          style={{
                            width: "auto",
                            marginBottom: "0px",
                            font: "normal normal 600 12px Open Sans",
                            color: "#979797",
                          }}
                        >
                          Breaks
                        </legend>
                        {details["breaks"].map((obj, idx) => {
                          return (
                            <Chip
                              style={{
                                height: "fit-content",
                                padding: "8px",
                              }}
                              color={getWorkTimingBreakChipColorTheme({
                                breakData: obj,
                              })}
                              label={renderBreakData({ data: obj })}
                              key={idx}
                              onDelete={() => {
                                let tempList = [...details["breaks"]];
                                tempList = tempList.filter(
                                  (item) => item["title"] !== obj["title"]
                                );
                                setDetails({ ...details, breaks: tempList });
                              }}
                            />
                          );
                        })}
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Button
                      disabled={
                        details["start_time"] === "" ||
                        details["end_time"] === ""
                      }
                      style={{
                        textTransform: "none",
                        fontWeight: "bold",
                        width: "100%",
                      }}
                      color="primary"
                      variant="text"
                      onClick={() => {
                        setOpenAddBreakDialog(true);
                      }}
                    >
                      <Add /> Add Break
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Container>
            <AppBar
              position="static"
              color="inherit"
              elevation={0}
              sx={{
                top: "auto",
                bottom: 0,
                width: "100%",
                borderRadius: "0px 0px 8px 8px",
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                  width: dialogWidth,
                  position: "relative",
                }}
              >
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      color="inherit"
                      style={{
                        fontSize: "12px",
                        textTransform: "none",
                        fontWeight: "bold",
                      }}
                    >
                      {getLocalizedText("cancel")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        width: "fit-content",
                        textTransform: "none",
                      }}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Box>
        </ModelContainer>
      </Modal>
      {openAddBreakDialog && (
        <AddBreakDialog
          handleAdd={(data) => {
            let tempList = [...details["breaks"]];
            tempList.push(data);
            setDetails({ ...details, breaks: tempList });
            setOpenAddBreakDialog(false);
          }}
          open={openAddBreakDialog}
          setOpen={setOpenAddBreakDialog}
          breakList={details["breaks"]}
          dayStartTime={details["start_time"]}
          dayEndTime={details["end_time"]}
        />
      )}
    </>
  );
};

export default ConfigureWorkDay;
