import { Box, Grid } from "@material-ui/core";
import React from "react";
import { styled } from "@mui/material/styles";
import { dataRenderer, IsoToLocalTime } from "../../../Functions";
import { Button, Card, Typography } from "@mui/material";
import MDEditor from "@uiw/react-md-editor";
import { observer } from "mobx-react-lite";
import rootStore from "../../../stores/RootStore";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  max-height: 100px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  color: #979797;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  gap: 4px;
`;
const NoteCardWidget = ({ details, handleView }) => {
  const noteTextGetter = (raw_string) => {
    return raw_string.replace(/\r\n|\n/g, "\n");
  };
  const RenderNotes = () => {
    if (details["version"] === 2) {
      return (
        <div className="container" data-color-mode="light">
          <MDEditor.Markdown
            source={details.note}
            components={{
              mention: Mention,
            }}
          />
        </div>
      );
    } else {
      return (
        <Detail
          component="div"
          style={{
            whiteSpace: "pre-line",
          }}
        >
          {noteTextGetter(details.note)}
        </Detail>
      );
    }
  };

  const Mention = ({ children }) => {
    return (
      <span style={{ color: "#0969DA", fontWeight: "bold" }}>{children}</span>
    );
  };
  return (
    <Card
      sx={{
        borderRadius: "12px",
        boxShadow: "none",
        border: "1px solid rgb(218, 220, 224)",
        padding: "16px",
        paddingBottom: "8px",
        opacity: rootStore.userStore.seenNoteIds.includes(details["id"])
          ? "0.4"
          : "1",
      }}
    >
      <HeaderWrapper>
        <Header>{IsoToLocalTime(details.created_at)}</Header> -
        <Header>
          {details.created_by !== null
            ? dataRenderer(details.created_by.name)
            : "-"}
        </Header>
      </HeaderWrapper>

      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputWrapper>
              {details.note !== "" && details.note !== null ? (
                <RenderNotes />
              ) : (
                <Detail>-</Detail>
              )}
            </InputWrapper>
          </Grid>
        </Grid>
      </Container>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={handleView}
          variant="text"
          color={
            rootStore.userStore.seenNoteIds.includes(details["id"])
              ? "inherit"
              : "primary"
          }
          sx={{
            textTransform: "none",
            width: "fit-content",
            marginLeft: "auto",
          }}
        >
          View more
        </Button>
      </Box>
    </Card>
  );
};

export default observer(NoteCardWidget);
