import { getLocalizedErrorText } from "../Functions.js";

class ApiResult {
  constructor(status, data, statusCode = 200) {
    this._status = status;
    this._statusCode = statusCode;
    this._data = data;
  }

  get status() {
    return this._status;
  }

  get statusCode() {
    return this._statusCode;
  }

  hasError() {
    return this._status === "error";
  }
  get errorCode() {
    if (this._data.hasOwnProperty("code")) {
      return this._data["code"];
    }
    return "";
  }

  get data() {
    return this._data;
  }
  get errorMessage() {
    if (this._data.hasOwnProperty("code")) {
      let value = getLocalizedErrorText(this._data["code"]);
      if (value === null) {
        if (this._data.hasOwnProperty("message")) {
          if (this._data["message"] !== null && this._data["message"] !== "") {
            return this._data["message"];
          }
        }
      } else {
        return value;
      }
    }
    return "Some error occurred!";
  }
}

export default ApiResult;
