import { Box, Modal } from "@material-ui/core";
import styled from "styled-components";
import { Close } from "@material-ui/icons";
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 20px;
`;
const ModelWrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
`;
const Text = styled.p`
  font: normal normal 600 18px Open Sans;
  padding: 4px;
  margin: 0px;
`;
const NotesModal = (props) => {
  const handleClose = () => {
    props.setOpen(false);
  };
  const handleClick = () => {
    props.setOpen(false);
  };
  return (
    <>
      {
        <Modal
          open={props.open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModelContainer>
            <Box>
              <Close
                onClick={handleClick}
                style={{
                  height: "18px",
                  width: "18px",
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  cursor: "pointer",
                }}
              />
              <ModelWrapper>
                <Text>{props.textNote}</Text>
              </ModelWrapper>
            </Box>
          </ModelContainer>
        </Modal>
      }
    </>
  );
};

export default NotesModal;
