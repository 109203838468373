import React from "react";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import axios from "axios";
import { Button } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { pageSize } from "../../Config";
import rootStore from "../../stores/RootStore";
import styled from "styled-components";
const ButtonWrapper = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
`;
const Pagination = (props) => {
  const { userStore, authStore } = rootStore;
  let pid = authStore.projectId;
  let tid = authStore.teamId;
  let tabId = userStore.TabId;
  let page_size = props.page_size ? props.page_size : pageSize;
  let sorting = props.sorting
    ? props.sorting !== null
      ? props.sorting
      : null
    : null;
  if (sorting !== null) {
    delete sorting["flag"];
  }
  const handleNext = () => {
    props.setRow([]);
    props.setLoading(true);
    let timeStamp = +new Date();
    const updteUnAssignedLeads = async () => {
      let response = await axios({
        method: props.type ? "get" : "post",
        url: `${props.url}?offset=${
          (props.page + 1) * page_size
        }&limit=${page_size}`,

        headers: {
          X_TIMESTAMP: timeStamp,
          X_FEATURES: "",
          X_APPID: "1001",
          X_APPVERSION: "1.0.0.0",
          X_DEVICEID: timeStamp,
          X_DEVICEOS: "web",
          X_USERID: localStorage.getItem("uid"),
          X_TOKEN: localStorage.getItem("token"),
          X_PROJECTID: pid,
          X_TEAMID: tid,
        },
        data: props.data
          ? props.data
          : !props.type
          ? {
              tab_id: tabId,
              query: props.filters,
              sort_on: sorting,
            }
          : null,
      });
      props.setRow(response.data.items);
      props.setCount(response.data.item_count);
      props.setLoading(false);
      props.setPage(props.page + 1);
      userStore.updatePaging({ id: tabId, page: props.page + 1 });
    };
    updteUnAssignedLeads();
  };
  const handlePrev = () => {
    props.setRow([]);
    props.setLoading(true);
    let timeStamp = +new Date();
    const updteUnAssignedLeads = async () => {
      let response = await axios({
        method: props.type ? "get" : "post",
        url: `${props.url}?offset=${
          (props.page - 1) * page_size
        }&limit=${page_size}`,

        headers: {
          X_TIMESTAMP: timeStamp,
          X_FEATURES: "",
          X_APPID: "1001",
          X_APPVERSION: "1.0.0.0",
          X_DEVICEID: timeStamp,
          X_DEVICEOS: "web",
          X_USERID: localStorage.getItem("uid"),
          X_TOKEN: localStorage.getItem("token"),
          X_PROJECTID: pid,
          X_TEAMID: tid,
        },
        data: props.data
          ? props.data
          : !props.type
          ? {
              tab_id: tabId,
              query: props.filters,
              sort_on: sorting,
            }
          : null,
      });
      props.setRow(response.data.items);
      props.setCount(response.data.item_count);
      props.setLoading(false);
      props.setPage(props.page - 1);
      userStore.updatePaging({ id: tabId, page: props.page - 1 });
    };
    updteUnAssignedLeads();
  };
  const handleFirst = () => {
    props.setRow([]);
    props.setLoading(true);
    let timeStamp = +new Date();
    const updteUnAssignedLeads = async () => {
      let response = await axios({
        method: props.type ? "get" : "post",
        url: `${props.url}?offset=0&limit=${page_size}`,

        headers: {
          X_TIMESTAMP: timeStamp,
          X_FEATURES: "",
          X_APPID: "1001",
          X_APPVERSION: "1.0.0.0",
          X_DEVICEID: timeStamp,
          X_DEVICEOS: "web",
          X_USERID: localStorage.getItem("uid"),
          X_TOKEN: localStorage.getItem("token"),
          X_PROJECTID: pid,
          X_TEAMID: tid,
        },
        data: props.data
          ? props.data
          : !props.type
          ? {
              tab_id: tabId,
              query: props.filters,
              sort_on: sorting,
            }
          : null,
      });
      props.setRow(response.data.items);
      props.setCount(response.data.item_count);
      props.setLoading(false);
      props.setPage(0);
      userStore.updatePaging({ id: tabId, page: 0 });
    };
    updteUnAssignedLeads();
  };
  const handleLast = () => {
    props.setRow([]);
    props.setLoading(true);
    let timeStamp = +new Date();
    const updteUnAssignedLeads = async () => {
      let response = await axios({
        method: props.type ? "get" : "post",
        url: `${props.url}?offset=${
          (Math.ceil(props.count / page_size) - 1) * page_size
        }&limit=${page_size}`,

        headers: {
          X_TIMESTAMP: timeStamp,
          X_FEATURES: "",
          X_APPID: "1001",
          X_APPVERSION: "1.0.0.0",
          X_DEVICEID: timeStamp,
          X_DEVICEOS: "web",
          X_USERID: localStorage.getItem("uid"),
          X_TOKEN: localStorage.getItem("token"),
          X_PROJECTID: pid,
          X_TEAMID: tid,
        },
        data: props.data
          ? props.data
          : !props.type
          ? {
              tab_id: tabId,
              query: props.filters,
              sort_on: sorting,
            }
          : null,
      });
      props.setRow(response.data.items);
      props.setCount(response.data.item_count);
      props.setLoading(false);
      props.setPage(Math.ceil(props.count / page_size) - 1);
      userStore.updatePaging({
        id: tabId,
        page: Math.ceil(props.count / page_size) - 1,
      });
    };
    updteUnAssignedLeads();
  };
  return (
    <>
      <ButtonWrapper>
        <Button
          onClick={handleFirst}
          disabled={props.page === 0}
          variant="text"
        >
          <KeyboardDoubleArrowLeftIcon />
        </Button>
        <Button
          style={{ margin: "12px" }}
          onClick={handlePrev}
          disabled={props.page === 0}
          variant="outlined"
        >
          <ArrowBackIos />
        </Button>
        <Button
          style={{ margin: "12px" }}
          onClick={handleNext}
          disabled={
            props.row.length < page_size ||
            props.page + 1 >= Math.ceil(props.count / page_size)
          }
          variant="outlined"
        >
          <ArrowForwardIos />
        </Button>
        <Button
          onClick={handleLast}
          disabled={props.page + 1 >= Math.ceil(props.count / page_size)}
          variant="text"
        >
          <KeyboardDoubleArrowRightIcon />
        </Button>
      </ButtonWrapper>
    </>
  );
};

export default observer(Pagination);
