import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import { Box, Typography, FormControl, Select } from "@mui/material";
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
`;
const SelectField = (props) => {
  const [list, setList] = useState([]);
  const getList = () => {
    if (props.list.length > 0) {
      let val = props.list.split(",");
      setList(val);
    }
  };
  useEffect(() => {
    getList();
  }, []);
  return (
    <>
      <InputWrapper>
        <Label>
          Select {props.label}
          {props.required && <span>*</span>}
        </Label>
        <FormControl
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none", // Remove the border
            },
          }}
        >
          <Select
            displayEmpty
            disableUnderline
            variant="outlined"
            name={props.field}
            label="Select Member"
            onChange={(e) => props.handleChange(e)}
            style={{
              width: "100%",
              fontSize: "12px",
              height: "30px",
              borderRadius: "4px",
            }}
            readOnly={props.readOnly}
            value={
              props.value.hasOwnProperty(props.field)
                ? props.value[props.field]["value"]
                : ""
            }
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.23)", // Ensure border is defined
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(0, 0, 0, 0.23)",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#000", // Change the color on hover
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#1976d2", // Default MUI primary color on focus
                borderWidth: "2px", // Ensure it stands out clearly
              },
            }}
            required={props.required}
          >
            {list.map((e) => {
              return (
                <MenuItem id={e.id} value={e}>
                  {e}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </InputWrapper>
    </>
  );
};

export default SelectField;
