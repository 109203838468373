import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Toolbar,
} from "@material-ui/core";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import rootStore from "../../../stores/RootStore";
import { markWebformLeads } from "../../../Api";
import { getLocalizedText } from "../../../Functions";
import { LoadingButton } from "@mui/lab";
const dialogWidth = 420;
const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 16px;
  flex-direction: column;
  row-gap: 20px;
`;
const Message = styled(Typography)`
  font: normal normal normal 16px Open Sans;
  color: green;
  margin: 0px;
  text-align: center;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const WebformMarkAsMovedDialog = ({
  open,
  setOpen,
  setRefresh,
  webformID,
  data,
}) => {
  const projectList = [...rootStore.authStore.projectList];
  const [selectedProjectID, setSelectedProjectID] = useState("");

  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let index = projectList.findIndex(
      (project) => project["id"] === selectedProjectID
    );
    let projectData = projectList[index];
    let stat_ids = data.map((obj) => obj.id);
    let formData = {
      webform_detail_stat_ids: stat_ids,
      project_id: projectData["id"],
      project_name: projectData["name"],
    };
    let response = await markWebformLeads({
      webform_id: webformID,
      pid: projectData["id"],
      data: formData,
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
    setIsSubmitting(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const RenderSubmitButton = () => {
    if (isSubmitting) {
      return (
        <LoadingButton
          loading
          loadingIndicator="Submitting..."
          variant="contained"
          color="primary"
          style={{
            textTransform: "none",
            fontSize: "12px",
            fontWeight: "bold",
            width: "100%",
          }}
        >
          <span>Submitting...</span>
        </LoadingButton>
      );
    } else {
      return (
        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            width: "100%",
            textTransform: "none",
          }}
        >
          Submit
        </Button>
      );
    }
  };

  return (
    <>
      <Modal
        disableEnforceFocus={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!isSubmitSuccess ? (
          <ModelContainer>
            <Box component={"form"} onSubmit={handleSubmit}>
              <AppBar
                component={"nav"}
                elevation={0}
                position="sticky"
                color="inherit"
                sx={{
                  top: 0,
                  bottom: "auto",
                  width: "100%",
                  right: 0,
                }}
              >
                <Toolbar
                  style={{
                    padding: "16px",
                  }}
                >
                  <HeaderWrapper>
                    <Header>Mark records</Header>
                    <SubHeader>
                      {" "}
                      {getLocalizedText("fill_in_the_below_details")}
                    </SubHeader>
                  </HeaderWrapper>
                </Toolbar>
              </AppBar>
              <Divider style={{ margin: "0px 16px" }} />
              <Container>
                <Box
                  style={{
                    height: "100%",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <Label>Select Project</Label>
                        <FormControl sx={{ width: "100%" }}>
                          <Select
                            name="project"
                            displayEmpty
                            variant="outlined"
                            required
                            value={selectedProjectID}
                            onChange={(event) =>
                              setSelectedProjectID(event.target.value)
                            }
                            style={{
                              width: "100%",
                              height: "30px",
                              fontSize: "12px",
                              borderRadius: "0px",
                            }}
                          >
                            {projectList.map((project, index) => {
                              return (
                                <MenuItem key={index} value={project["id"]}>
                                  {project["name"]}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </InputWrapper>
                    </Grid>
                  </Grid>
                </Box>
              </Container>
              <AppBar
                position="static"
                color="inherit"
                elevation={0}
                sx={{ top: "auto", bottom: 0, width: "100%" }}
              >
                <Toolbar
                  style={{
                    padding: "16px",
                    width: dialogWidth,
                    position: "relative",
                  }}
                >
                  {isSubmitFail && (
                    <ErrorAlert
                      onClose={(e) => {
                        e.stopPropagation();
                        setIsSubmitFail(false);
                      }}
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      {errorMessage}
                    </ErrorAlert>
                  )}
                  <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item>
                      <Button
                        onClick={() => {
                          setOpen(false);
                        }}
                        variant="contained"
                        color="default"
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          textTransform: "none",
                        }}
                      >
                        {getLocalizedText("cancel")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <RenderSubmitButton />
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
            </Box>
          </ModelContainer>
        ) : (
          <ModelContainer
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MessageWrapper style={{ marginBottom: "0px", padding: "16px" }}>
              <Message>Records marked as moved successfully!</Message>

              <Button
                onClick={handleClose}
                type="button"
                variant="contained"
                color="default"
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "100px",
                }}
              >
                {getLocalizedText("close")}
              </Button>
            </MessageWrapper>
          </ModelContainer>
        )}
      </Modal>
    </>
  );
};

export default WebformMarkAsMovedDialog;
