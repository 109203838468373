import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import { Button } from "@material-ui/core";
import { styled } from "@mui/material/styles";

const Message = styled(Typography)(({ theme }) => ({
  font: "normal normal 400 16px Open Sans",
  color: "#4d4e4f",
}));
const CustomMessageDialog = (props) => {
  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => {
          props.setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: "20px",
            padding: "20px",
          }}
        >
          <DialogContent style={{ padding: "0px" }}>
            <DialogContentText id="alert-dialog-description">
              <Message>{props.message}</Message>
            </DialogContentText>
          </DialogContent>

          <DialogActions style={{ padding: "0px" }}>
            <Button
              color="primary"
              variant="contained"
              style={{
                backgroundColor: "#185DD2",
                textTransform: "capitalize",
                fontWeight: "bold",
                width: "100px",

                color: "white",
              }}
              onClick={() => {
                props.setOpen(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};
export default CustomMessageDialog;
