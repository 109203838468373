import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import Footer from "../../components/landing_page_components/footer/Footer";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import {
  Button,
  CircularProgress,
  IconButton,
  Snackbar,
  Tab,
  Tabs,
  Tooltip,
  Drawer,
  Backdrop,
  Box,
  Grid,
  Typography,
  tabsClasses,
  Chip,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { getCampaignLeadsListApi, getCampaignStatsApi } from "../../Api";
import { useNavigate, useParams } from "react-router-dom";
import { pageSize } from "../../Config";
import {
  IsoToLocalTime,
  capitalizeWord,
  dataRenderer,
  getCurrentEpochTime,
  getLocalizedText,
} from "../../Functions";
import { ChevronRight, Visibility } from "@material-ui/icons";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { CopyAll, Download } from "@mui/icons-material";
import { CSVLink } from "react-csv";
import MoveCampaignLeadModal from "../../components/market_modals/move_campaign_lead_modal/MoveCampaignLeadModal";
import CampaignRecordDataPanel from "../../components/market_modals/campaign_record_data_panel/CampaignRecordDataPanel";
import GenerateCSVConfirmationModal from "../../components/market_modals/generate_csv_modal/GenerateCSVConfirmationModal";
import { submissionScopeList } from "../../Db";

const Container = styled(Box)`
  margin-top: 70px;
  background-color: #f9f9fc;
  ${`min-height: calc(100vh - 145px);`};
`;
const Wrapper = styled(Box)`
  width: 95%;
  margin: auto;
  display: flex;
  flex-direction: column;
`;
const TabsWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  min-height: 40px;
`;

const Bottom = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 472px);`};
  background-color: white;
  overflow-x: auto;
`;
const Top = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 20px;
`;
const HeaderWrapper = styled(Box)`
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
`;
const Text = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
`;
const CustomTab = styled(Tab)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: "#E7EEFA",
    color: "#4079DA",
    font: "normal normal 600 14px Open Sans",
    textTransform: "capitalize",
  },
  backgroundColor: "white",
  color: "#4D4E4F",
  font: "normal normal 600 14px Open Sans",
  textTransform: "capitalize",
}));

const Row = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;
const Column = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const Item = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 10px;
  /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2); */
`;

const CardTitle = styled(Typography)`
  font: normal normal 600 16px Open Sans;
  color: #a1b3d3;
  margin-bottom: 12px;
`;

const TextLg = styled(Typography)`
  font: normal normal 600 20px Open Sans;
  color: #4d4e4f;
`;
const LabelTextLg = styled(Typography)`
  font: normal normal 500 20px Open Sans;
  color: #4d4e4f;
`;
const TextSm = styled(Typography)`
  font: normal normal 500 10px Open Sans;
  color: #4d4e4f;
`;
const ProgressWrapper = styled(Box)`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
const IconTextWrapper = styled(Box)`
  display: flex;
  align-items: center;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;
const ChipsWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  height: 100%;
`;

const CampaignStatsPage = () => {
  const navigate = useNavigate();
  const { id, campaignType } = useParams();
  const [tabValue, setTabValue] = useState("opened");
  const [data, setData] = useState({});
  const [row, setRow] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingListingData, setLoadingListingData] = useState(true);
  const [size, setSize] = useState(pageSize);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [csvData, setCSVData] = useState([]);
  const [csvHeaders, setCSVHeaders] = useState([]);
  const [fileName, setFileName] = useState("");
  const [showDownloadCsv, setShowDownloadCsv] = useState(false);
  const [isCsvDataLoading, setIsCsvDataLoading] = useState(false);
  const [openMoveLead, setOpenMoveLead] = useState(false);
  const [leads, setLeads] = useState([]);
  const [assignState, setAssignState] = useState(0);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [openInfoPanel, setOpenInfoPanel] = useState(false);
  const [openDownloadAllPopup, setOpenDownloadAllPopup] = useState(false);
  const [submissionScope, setSubmissionScope] = useState("today");

  const getCampaignStatsData = async () => {
    setLoading(true);
    let response = await getCampaignStatsApi(id);
    setData(response);
    setLoading(false);
  };
  useEffect(() => {
    getCampaignStatsData();
  }, []);

  useEffect(() => {
    getCampaignLeadsList({
      page_size: pageSize,
      page_no: 0,
      filter: submissionScope,
    });
  }, [tabValue]);

  const getCampaignLeadsList = async ({ page_size, page_no, filter }) => {
    setRow([]);
    setLoadingListingData(true);
    let response = await getCampaignLeadsListApi({
      campaign_id: id,
      page_size: page_size,
      page_no: page_no,
      scope: tabValue,
      filter: filter,
    });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
      setCount(response.item_count);
    } else {
      setCount(0);
    }
    setLoadingListingData(false);
  };

  const handlePageSize = async (e) => {
    setSize(e.target.value);
    setPage(0);
    await getCampaignLeadsList({
      page_size: e.target.value,
      page_no: 0,
      filter: submissionScope,
    });
  };
  const handlePagination = async (e, page) => {
    setPage(page - 1);
    await getCampaignLeadsList({
      page_size: size,
      page_no: page - 1,
      filter: submissionScope,
    });
  };

  const handleChip = async (scope) => {
    setSubmissionScope(scope);
    await getCampaignLeadsList({
      page_size: size,
      page_no: page,
      filter: scope,
    });
  };

  const getColumnHeadersToRender = () => {
    let columnList = [
      {
        field: "target",
        headerName: campaignType,

        width: 200,
        cellRenderer: (params) => {
          let value = dataRenderer(params.data.target);
          return (
            <Tooltip title={value}>
              <span className="csutable-cell-trucate">{value}</span>
            </Tooltip>
          );
        },
      },
    ];
    if (tabValue === "opened") {
      let tempObj = {
        field: "opened_on",
        headerName: "Opened On",

        minWidth: 200,
        flex: 1,
        resizable: false,
        cellRenderer: (params) => {
          let date = null;
          if (params.data.opened_on !== null) {
            date = IsoToLocalTime(params.data.opened_on);
          }
          return (
            <>
              <Tooltip title={date !== null ? date : "-"}>
                <span className="csutable-cell-trucate">
                  {date !== null ? date : "-"}
                </span>
              </Tooltip>
            </>
          );
        },
      };
      columnList.push(tempObj);
    } else if (tabValue === "clicked") {
      let tempObj = {
        field: "clicked_on",
        headerName: "Clicked On",

        minWidth: 200,
        flex: 1,
        resizable: false,

        cellRenderer: (params) => {
          let date = null;
          if (params.data.clicked_on !== null) {
            date = IsoToLocalTime(params.data.clicked_on);
          }
          return (
            <>
              <Tooltip title={date !== null ? date : "-"}>
                <span className="csutable-cell-trucate">
                  {date !== null ? date : "-"}
                </span>
              </Tooltip>
            </>
          );
        },
      };
      columnList.push(tempObj);
    } else if (tabValue === "submitted") {
      columnList[0]["headerCheckboxSelection"] = true;
      columnList[0]["checkboxSelection"] = true;
      let tempObj = {
        field: "submitted_on",
        headerName: "Submitted On",

        width: 200,
        cellRenderer: (params) => {
          let date = null;
          if (params.data.submitted_on !== null) {
            date = IsoToLocalTime(params.data.submitted_on);
          }
          return (
            <>
              <Tooltip title={date !== null ? date : "-"}>
                <span className="csutable-cell-trucate">
                  {date !== null ? date : "-"}
                </span>
              </Tooltip>
            </>
          );
        },
      };
      let actionColumn = {
        field: "data",
        headerName: "Data",

        minWidth: 200,
        flex: 1,
        resizable: true,
        cellRenderer: (params) => {
          let data = "";
          if (
            params.data.hasOwnProperty("submit_data") &&
            params.data["submit_data"] !== null
          ) {
            data = JSON.stringify(params.data["submit_data"]);
          }

          if (data === "") {
            return (
              <>
                <Tooltip title={data} placement="bottom-start">
                  <span>{data !== "" ? data : "-"}</span>
                </Tooltip>
              </>
            );
          } else {
            return (
              <IconTextWrapper>
                <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
                  <Visibility
                    color="primary"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      let data = {};
                      if (
                        params.data.hasOwnProperty("submit_data") &&
                        params.data["submit_data"] !== null
                      ) {
                        data = params.data["submit_data"];
                      }
                      setSelectedRowData(data);
                      setOpenInfoPanel(true);
                    }}
                  />
                  <CopyAll
                    color="primary"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      navigator.clipboard.writeText(data);
                      setOpenSnackBar(true);
                    }}
                  />
                </Box>
                <Tooltip
                  title={data !== null ? data : "-"}
                  placement="bottom-start"
                >
                  <span
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      flex: 1,
                    }}
                  >
                    {data !== "" ? data : "-"}
                  </span>
                </Tooltip>
              </IconTextWrapper>
            );
          }
        },
      };
      columnList.push(tempObj);
      columnList.push(actionColumn);
    }

    return columnList;
  };
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));

  function onSelectionChanged(event) {
    let selectedRowData = event.api.getSelectedNodes();
    let leads = selectedRowData.map((item) => item.data);
    setAssignState(leads.length);
    setLeads(leads);
  }

  const downloadAllRecords = async () => {
    let data = [];

    let page_index = 0; //initialize with 0 as we are taking the base index as 0
    while (data.length < count) {
      //above count is the state variable which gets set on tab change or first time page load with the first tab
      let response = await getCampaignLeadsListApi({
        campaign_id: id,
        page_size: 1000,
        page_no: page_index,
        scope: tabValue,
        filter: submissionScope,
      });
      if (Object.keys(response).length > 0) {
        data = data.concat(response.items);
        page_index++;
      } else {
        break;
      }
    }

    if (tabValue === "opened") {
      let fileName = `opened_report_${id}_${getCurrentEpochTime()}`;
      setFileName(fileName);
      setCSVHeaders([
        {
          label: campaignType,
          key: "target",
        },
        { label: "Opened On", key: "opened_on" },
      ]);

      data.forEach((item) => {
        if (item.opened_on !== null) {
          item["opened_on"] = IsoToLocalTime(item["opened_on"]);
        }
      });

      setCSVData(data);
    }
    if (tabValue === "clicked") {
      let fileName = `clicked_report_${id}_${getCurrentEpochTime()}`;
      setFileName(fileName);
      setCSVHeaders([
        {
          label: campaignType,
          key: "target",
        },
        { label: "Clicked On", key: "clicked_on" },
      ]);
      data.forEach((item) => {
        if (item.clicked_on !== null) {
          item["clicked_on"] = IsoToLocalTime(item["clicked_on"]);
        }
      });
      setCSVData(data);
    }
    if (tabValue === "submitted") {
      let fileName = `submitted_report_${id}_${getCurrentEpochTime()}`;
      setFileName(fileName);
      generateSubmittedHeaders(data);
    }

    setShowDownloadCsv(true);
    setIsCsvDataLoading(false);
  };

  const downloadSelectedRecords = async () => {
    let data = [...leads];
    if (tabValue === "opened") {
      let fileName = `opened_report_${id}_${getCurrentEpochTime()}`;
      setFileName(fileName);
      setCSVHeaders([
        {
          label: campaignType,
          key: "target",
        },
        { label: "Opened On", key: "opened_on" },
      ]);

      data.forEach((item) => {
        if (item.opened_on !== null) {
          item["opened_on"] = IsoToLocalTime(item["opened_on"]);
        }
      });

      setCSVData(data);
    }
    if (tabValue === "clicked") {
      let fileName = `clicked_report_${id}_${getCurrentEpochTime()}`;
      setFileName(fileName);
      setCSVHeaders([
        {
          label: campaignType,
          key: "target",
        },
        { label: "Clicked On", key: "clicked_on" },
      ]);
      data.forEach((item) => {
        if (item.clicked_on !== null) {
          item["clicked_on"] = IsoToLocalTime(item["clicked_on"]);
        }
      });
      setCSVData(data);
    }
    if (tabValue === "submitted") {
      let fileName = `submitted_report_${id}_${getCurrentEpochTime()}`;
      setFileName(fileName);
      generateSubmittedHeaders(data);
    }

    setShowDownloadCsv(true);
    setIsCsvDataLoading(false);
  };

  const handleGenerateCSV = async () => {
    if (leads.length > 0) {
      setIsCsvDataLoading(true);
      if (leads.length === row.length && count > leads.length) {
        // show popup
        setOpenDownloadAllPopup(true);
      } else {
        downloadSelectedRecords();
      }
    }
  };
  const generateSubmittedHeaders = (data) => {
    let csvHeader = [
      {
        label: campaignType,
        key: "target",
      },
      { label: "Submitted On", key: "submitted_on" },
    ];
    let csvData = [];
    let keys = [];
    data.forEach((item) => {
      let csvItem = {
        ...item,
      };
      if (csvItem.submitted_on !== null) {
        csvItem["submitted_on"] = IsoToLocalTime(csvItem["submitted_on"]);
      }
      delete csvItem["submit_data"];

      if (
        item["submit_data"] !== null &&
        item["submit_data"] !== "" &&
        item["submit_data"] !== undefined
      ) {
        Object.keys(item["submit_data"]).forEach((key) => {
          if (key.toLowerCase() !== "custom_params") {
            csvItem[key] = item["submit_data"][key];
            if (keys.includes(key) === false) {
              keys.push(key);
            }
          }
        });
      }
      csvData.push(csvItem);
    });
    keys.forEach((key) => {
      csvHeader.push({
        label: capitalizeWord(key),
        key: key,
      });
    });
    setCSVHeaders(csvHeader);
    setCSVData(csvData);
  };

  return (
    <>
      <Container>
        <Wrapper>
          <HeaderWrapper>
            <Text
              style={{ color: "#185DD2", cursor: "pointer" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              {getLocalizedText("campaigns")}
            </Text>
            <ChevronRight style={{ width: "16px", height: "16px" }} />
            <Text>{id}</Text>
          </HeaderWrapper>
          {!loading ? (
            <Top>
              <Grid container rowSpacing={3} columnSpacing={3}>
                <Grid item xs={3}>
                  <Item>
                    <CardTitle>Sent</CardTitle>
                    <Column style={{ rowGap: "4px" }}>
                      <Row>
                        <LabelTextLg>Total :</LabelTextLg>
                        <TextLg>
                          {data.hasOwnProperty("total_sends")
                            ? dataRenderer(data["total_sends"])
                            : "-"}
                        </TextLg>
                      </Row>
                      <Column>
                        <Row>
                          <TextSm>First Sent :</TextSm>
                          <TextSm>
                            {data.hasOwnProperty("first_send_at") &&
                            data["first_send_at"] !== null
                              ? IsoToLocalTime(data["first_send_at"])
                              : "-"}
                          </TextSm>
                        </Row>
                        <Row>
                          <TextSm>Last Sent :</TextSm>
                          <TextSm>
                            {data.hasOwnProperty("last_send_at") &&
                            data["last_send_at"] !== null
                              ? IsoToLocalTime(data["last_send_at"])
                              : "-"}
                          </TextSm>
                        </Row>
                      </Column>
                    </Column>
                  </Item>
                </Grid>
                <Grid item xs={3}>
                  <Item>
                    <CardTitle>Delivered</CardTitle>
                    <Column style={{ rowGap: "4px" }}>
                      <Row>
                        <LabelTextLg>Total :</LabelTextLg>
                        <TextLg>
                          {data.hasOwnProperty("total_delivered")
                            ? dataRenderer(data["total_delivered"])
                            : "-"}
                        </TextLg>
                      </Row>
                      <Column>
                        <Row>
                          <TextSm>First Delivered :</TextSm>
                          <TextSm>
                            {data.hasOwnProperty("first_delivered_at") &&
                            data["first_delivered_at"] !== null
                              ? IsoToLocalTime(data["first_delivered_at"])
                              : "-"}
                          </TextSm>
                        </Row>
                        <Row>
                          <TextSm>Last Delivered :</TextSm>
                          <TextSm>
                            {data.hasOwnProperty("last_delivered_at") &&
                            data["last_delivered_at"] !== null
                              ? IsoToLocalTime(data["last_delivered_at"])
                              : "-"}
                          </TextSm>
                        </Row>
                      </Column>
                    </Column>
                  </Item>
                </Grid>
                <Grid item xs={3}>
                  <Item>
                    <CardTitle>Opens</CardTitle>
                    <Column style={{ rowGap: "4px" }}>
                      <Row>
                        <LabelTextLg>Total :</LabelTextLg>
                        <TextLg>
                          {data.hasOwnProperty("total_opens")
                            ? dataRenderer(data["total_opens"])
                            : "-"}
                        </TextLg>
                      </Row>
                      <Column>
                        <Row>
                          <TextSm>First Opened :</TextSm>
                          <TextSm>
                            {data.hasOwnProperty("first_opened_at") &&
                            data["first_opened_at"] !== null
                              ? IsoToLocalTime(data["first_opened_at"])
                              : "-"}
                          </TextSm>
                        </Row>
                        <Row>
                          <TextSm>Last Opened :</TextSm>
                          <TextSm>
                            {data.hasOwnProperty("last_opened_at") &&
                            data["last_opened_at"] !== null
                              ? IsoToLocalTime(data["last_opened_at"])
                              : "-"}
                          </TextSm>
                        </Row>
                      </Column>
                    </Column>
                  </Item>
                </Grid>
                <Grid item xs={3}>
                  <Item>
                    <CardTitle>Clicks</CardTitle>
                    <Column style={{ rowGap: "4px" }}>
                      <Row>
                        <LabelTextLg>Total :</LabelTextLg>
                        <TextLg>
                          {data.hasOwnProperty("total_clicks")
                            ? dataRenderer(data["total_clicks"])
                            : "-"}
                        </TextLg>
                      </Row>
                      <Column>
                        <Row>
                          <TextSm>First Clicked :</TextSm>
                          <TextSm>
                            {data.hasOwnProperty("first_clicked_at") &&
                            data["first_clicked_at"] !== null
                              ? IsoToLocalTime(data["first_clicked_at"])
                              : "-"}
                          </TextSm>
                        </Row>
                        <Row>
                          <TextSm>Last Clicked :</TextSm>
                          <TextSm>
                            {data.hasOwnProperty("last_clicked_at") &&
                            data["last_clicked_at"] !== null
                              ? IsoToLocalTime(data["last_clicked_at"])
                              : "-"}
                          </TextSm>
                        </Row>
                      </Column>
                    </Column>
                  </Item>
                </Grid>
              </Grid>
            </Top>
          ) : (
            <Top style={{ justifyContent: "center" }}>
              <CircularProgress />
            </Top>
          )}

          <TabsWrapper style={{ padding: "0px", marginTop: "20px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <Tabs
                value={tabValue}
                textColor="primary"
                indicatorColor="primary"
                variant="fullWidth"
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    "&.Mui-disabled": { opacity: 0.3 },
                  },
                  bgcolor: "white",
                }}
                onChange={(event, newValue) => {
                  setShowDownloadCsv(false);
                  setCSVData([]);
                  setCSVHeaders([]);
                  setTabValue(newValue);
                  setAssignState(0);
                  setLeads([]);
                }}
              >
                <CustomTab
                  label={getLocalizedText("opened")}
                  value={"opened"}
                />
                <CustomTab
                  label={getLocalizedText("clicked")}
                  value={"clicked"}
                />
                <CustomTab
                  label={getLocalizedText("submitted")}
                  value={"submitted"}
                />
              </Tabs>
              <ChipsWrapper>
                {submissionScopeList.map((data, index) => {
                  return (
                    <Chip
                      key={index}
                      color={
                        data.value === submissionScope ? "primary" : "default"
                      }
                      label={data.label}
                      onClick={() => {
                        handleChip(data.value);
                      }}
                    />
                  );
                })}
              </ChipsWrapper>
            </Box>
            <ButtonWrapper>
              {tabValue === "submitted" && (
                <Button
                  onClick={() => {
                    setOpenMoveLead(true);
                  }}
                  disabled={assignState < 1}
                  color="primary"
                  variant="contained"
                  style={{
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  Move Campaign Leads
                </Button>
              )}
              {!showDownloadCsv ? (
                <Button
                  disabled={assignState < 1}
                  onClick={handleGenerateCSV}
                  color="primary"
                  variant="contained"
                  style={{
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  Generate CSV
                </Button>
              ) : (
                <CSVLink
                  headers={csvHeaders}
                  filename={fileName}
                  style={{ display: "flex", alignItems: "center" }}
                  data={csvData}
                >
                  <IconButton color="primary">
                    <Download />
                  </IconButton>
                </CSVLink>
              )}
            </ButtonWrapper>
          </TabsWrapper>
          <Bottom>
            <TableWrapper>
              {!loadingListingData ? (
                <div className="ag-theme-alpine" style={{ width: "100%" }}>
                  <AgGridReact
                    domLayout="autoHeight"
                    gridOptions={gridOptions}
                    loadingOverlayComponent={<CircularProgress />}
                    rowData={row}
                    defaultColDef={defaultColDef}
                    columnDefs={getColumnHeadersToRender()}
                    animateRows={true}
                    rowSelection="multiple"
                    rowMultiSelectWithClick
                    onSelectionChanged={onSelectionChanged}
                    onRowClicked={(row) => {
                      if (row.event.defaultPrevented) {
                        return null;
                      }
                    }}
                  />
                </div>
              ) : (
                <ProgressWrapper>
                  <CircularProgress />
                </ProgressWrapper>
              )}
            </TableWrapper>
            <PaginationComponent
              page_no={page}
              row={row}
              page_size={size}
              size={size}
              count={count}
              handlePageSize={handlePageSize}
              handlePage={handlePagination}
            />
          </Bottom>
        </Wrapper>
      </Container>
      <Footer />
      <Snackbar
        open={openSnackBar}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => {
          setOpenSnackBar(false);
        }}
        message="Copied to clipboard!"
      />
      <Backdrop
        open={isCsvDataLoading}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress style={{ color: "white" }} />
      </Backdrop>
      {openMoveLead && (
        <MoveCampaignLeadModal
          setOpen={setOpenMoveLead}
          open={openMoveLead}
          leads={leads}
          id={id}
          campaignType={campaignType}
          count={count}
        />
      )}
      {openInfoPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openInfoPanel}
          onClose={() => {
            setSelectedRowData({});
            setOpenInfoPanel(false);
          }}
        >
          <CampaignRecordDataPanel data={selectedRowData} />
        </Drawer>
      )}
      {openDownloadAllPopup && (
        <GenerateCSVConfirmationModal
          open={openDownloadAllPopup}
          setOpen={setOpenDownloadAllPopup}
          totalLeadCount={count}
          handleConfirm={(checked) => {
            setOpenDownloadAllPopup(false);
            if (checked) {
              downloadAllRecords();
            } else {
              downloadSelectedRecords();
            }
          }}
          handleCancel={() => {
            setOpenDownloadAllPopup(false);
            setIsCsvDataLoading(false);
          }}
        />
      )}
    </>
  );
};

export default observer(CampaignStatsPage);
