import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
`;

const FieldNameWithActionsWidget = ({
  handleEdit,
  handleRemove,
  fieldName,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); // Correct anchor element
  };

  return (
    <Wrapper>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={isOpen ? "long-menu" : undefined}
        aria-expanded={isOpen ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        style={{ padding: "0px" }}
      >
        <MoreVertIcon style={{ width: "20px", height: "20px" }} />
      </IconButton>
      <Label>{fieldName}</Label>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: "fit-content",
          },
        }}
      >
        <MenuItem
          key={"edit"}
          onClick={() => {
            handleEdit();
            handleClose();
          }}
        >
          <span style={{ color: "#4d4e4f", fontSize: "12px" }}>Edit</span>
        </MenuItem>
        <MenuItem
          key={"remove"}
          onClick={() => {
            handleRemove();
            handleClose();
          }}
        >
          <span style={{ color: "#4d4e4f", fontSize: "12px" }}>Remove</span>
        </MenuItem>
      </Menu>
    </Wrapper>
  );
};

export default FieldNameWithActionsWidget;
