import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  makeStyles,
  Select,
} from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { getLocalizedText, validateDisabledSubFeature } from "../../Functions";
import { DatePicker } from "antd";
import { ArrowForward, ArrowRightAltSharp } from "@material-ui/icons";
import {
  getAdminMembersApi,
  getMembersApi,
  getTargetMembersApi,
} from "../../Api";
const Header = styled.span`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
  margin-bottom: 20px;
`;
const SubHeader = styled.span`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 340px;
  width: 400px;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const DateRangeWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 80px;
  align-items: center;
`;
const DateRange = styled.div`
  display: flex;
  flex-direction: column;
`;
const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: -14px;
  margin-left: 8px;
`;
const StartDate = styled.p`
  width: fit-content;
  margin: 0px;
  font: normal normal 500 12px Open Sans;
  /* border: 1px solid gray; */
`;
const EndDate = styled.p`
  font: normal normal 500 12px Open Sans;
  margin: 0px;

  /* border: 1px solid gray; */

  width: fit-content;
`;
const Label = styled.span`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Info = styled.span`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Detail = styled.span`
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;
const Input = styled.input`
  width: 428px;
  height: 30px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;
  border: 1px solid #bfbfbf;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const TextArea = styled.textarea`
  width: 428px;
  height: 60px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #bfbfbf;
  color: #4d4e4f;
  resize: none;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  width: 180px;
  @media (max-width: 700px) {
    width: 140px;
  }
`;
const SelectColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;

function ampm(time) {
  if (time.value !== "") {
    var hours = time.split(":")[0];
    var minutes = time.split(":")[1];
    var suffix = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    var displayTime = hours + ":" + minutes + " " + suffix;
    return displayTime;
  }
  return;
}
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const dateList = [
  "Yesterday",
  "Today",
  "Last 7 Days",
  "Last 30 Days",
  "Last 90 Days",
  "Custom Range",
];
const FlagText = styled.p`
  margin: 0px;
  font: normal normal 500 12px Open Sans;
`;
const ReassignJobForm = (props) => {
  const { userStore } = rootStore;
  const [member, setMember] = useState();
  const [jobType, setJobType] = useState(null);
  const classes = useStyles();
  const handleFlag = (e) => {
    props.setFlag(e.target.checked);
  };

  const getJobType = () => {
    props.typeList.map((item) => {
      if (item.value === props.item.type) {
        setJobType(item["label"]);
      }
    });
  };
  useEffect(() => {
    getJobType();
    const getTeamMembers = async () => {
      try {
        let response = await getAdminMembersApi(props.id);
        setMember(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getTeamMembers();
  }, []);
  return (
    <>
      <SubHeader>{getLocalizedText("job_summary")}</SubHeader>
      <Divider style={{ margin: "10px 0px" }} />
      <InputContainer>
        <InputWrapper style={{ marginBottom: "20px" }}>
          <Info>{getLocalizedText("job_type")} </Info>
          <Detail>{jobType !== null ? jobType : "-"}</Detail>
        </InputWrapper>
        <InputWrapper style={{ marginBottom: "20px" }}>
          <Info>{getLocalizedText("job_title")} </Info>
          <Detail>{props.item.title ? props.item.title : "-"}</Detail>
        </InputWrapper>
        <InputWrapper style={{ marginBottom: "20px" }}>
          <Info>{getLocalizedText("date_range")} </Info>
          {props.dateRange !== null ? (
            <Row>
              <Detail>{props.dateRange["start_date"]}</Detail>
              <ArrowForward style={{ width: "18px", height: "18px" }} />
              <Detail>{props.dateRange["end_date"]}</Detail>
            </Row>
          ) : (
            <Detail>-</Detail>
          )}
        </InputWrapper>
        <SelectColumn>
          <Label>{getLocalizedText("assign_leads_to")}</Label>
          <FormControl
            sx={{ minWidth: 400 }}
            classes={{
              root: classes.quantityRoot,
            }}
          >
            <Select
              classes={{
                icon: classes.icon,
              }}
              value={
                props.jobInput.hasOwnProperty("user_id")
                  ? props.jobInput["user_id"]
                  : ""
              }
              name="user_id"
              required
              displayEmpty
              disableUnderline
              variant="outlined"
              onChange={(e) => {
                props.setJobInput({
                  ...props.jonInput,
                  user_id: e.target.value,
                });
              }}
              style={{
                marginBottom: "20px",
                width: "400px",
                height: "30px",
                fontSize: "12px",
                fontWeight: "bold",
                borderRadius: "0px",
              }}
            >
              {member &&
                member.map((e) => {
                  {
                    /* console.log("status id: ", e.id); */
                  }
                  return (
                    <MenuItem id={e.id} value={e.user_id}>
                      {e.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </SelectColumn>
        {validateDisabledSubFeature("erase_history") && (
          <FormControlLabel
            control={<Checkbox color="primary" />}
            label={<FlagText>{getLocalizedText("erase_history")}</FlagText>}
            onChange={handleFlag}
          />
        )}
      </InputContainer>
    </>
  );
};

export default observer(ReassignJobForm);
