import React from "react";
import { Menu, MenuItem } from "@mui/material";
import rootStore from "../../stores/RootStore";

const WhatsappContextMenu = ({
  anchor,
  handleMenuClick,
  handleClose,
  handleWhatsapp,
  messageTemplates,
}) => {
  const open = Boolean(anchor);

  return (
    <Menu
      style={{
        display: "flex",
        flexDirection: "column",
      }}
      id="long-menu"
      MenuListProps={{
        "aria-labelledby": "long-button",
        style: {
          maxHeight: "60vh",
          overflowY: "auto",
        },
      }}
      disableScrollLock
      anchorEl={anchor}
      open={open}
      onClose={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          borderRadius: "0px",
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: "100%",
            right: 14,
            transform: "translateX(-50%)",
            width: 0,
            height: 0,
            borderLeft: "5px solid transparent",
            borderRight: "5px solid transparent",
            borderTop: "5px solid white",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "left", vertical: "bottom" }}
      anchorOrigin={{ horizontal: "left", vertical: "top" }}
    >
      <MenuItem
        onClick={handleWhatsapp}
        style={{
          fontSize: "14px",
        }}
      >
        Open WhatsApp
      </MenuItem>

      {messageTemplates.map((message, index) => (
        <MenuItem
          onClick={() => handleMenuClick(message["id"])}
          style={{
            fontSize: "14px",
          }}
          key={index}
        >
          {message["template_title"]}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default WhatsappContextMenu;
