import React from "react";
import moment from "moment";
import { DatePicker } from "antd";
import { Box, OutlinedInput, styled } from "@mui/material";
import { MultiSelect } from "react-multi-select-component";
import rootStore from "../../../stores/RootStore";

const Row = styled(Box)`
  display: flex;
  align-items: center;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`;

const CreateViewTabFilterValueFiledComponent = ({
  setFilter,
  filter,
  filterTypeListMeta,
  selectedColumn,
  selectedFilterList,
}) => {
  const columnsList = [...rootStore.userStore.AllColumnsList];
  const { RangePicker } = DatePicker;

  const handleChange = ({ e, data_type, filterType }) => {
    console.log(e.target.name, e.target.value);
    let value =
      data_type === "integer" ? parseInt(e.target.value) : e.target.value;
    setFilter({
      ...filter,
      [e.target.name]: {
        value: value,
        filter_type: filterType,
        data_type: data_type,
      },
    });
  };

  const getParentFieldValue = (parent_field_info) => {
    let index = columnsList.findIndex(
      (column) => column.id === parent_field_info.id
    );
    if (index !== -1) {
      let target_column = columnsList[index];
      let filterIndex = selectedFilterList.findIndex(
        (filterData) => filterData["column_id"] === target_column["id"]
      );

      if (filterIndex !== -1) {
        return {
          value:
            selectedFilterList[filterIndex]["filter"][target_column["field"]],
          field_data: target_column,
        };
      }
    }
    return null;
  };

  if (selectedColumn !== null) {
    if (
      selectedColumn.filter_type === "normal" ||
      selectedColumn.filter_type === ""
    ) {
      return (
        <InputWrapper>
          <OutlinedInput
            style={{ height: "30px" }}
            inputProps={{
              "aria-label": "weight",
              style: {
                fontSize: "12px",
              },
            }}
            type={
              selectedColumn.data_type === "email"
                ? "email"
                : selectedColumn.data_type === "datetime" ||
                  selectedColumn.data_type === "date"
                ? "date"
                : selectedColumn.data_type === "integer"
                ? "number"
                : "text"
            }
            name={selectedColumn.field}
            value={
              filter.hasOwnProperty(selectedColumn.field)
                ? filter[selectedColumn.field]["value"]
                : ""
            }
            placeholder={`Enter ${selectedColumn.headerName}`}
            onChange={(e) => {
              let data_type = selectedColumn.data_type;
              let filterType = selectedColumn.filter_type;
              handleChange({ e, data_type, filterType });
            }}
          />
        </InputWrapper>
      );
    } else if (selectedColumn.filter_type === "range") {
      if (
        selectedColumn.data_type === "datetime" ||
        selectedColumn.data_type === "date"
      ) {
        return (
          <InputWrapper>
            <RangePicker
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
              placeholder={["From", "To"]}
              size="small"
              style={{
                height: "30px",
              }}
              value={
                filter.hasOwnProperty(selectedColumn.field)
                  ? [
                      moment(
                        filter[selectedColumn.field]["value"]["start"],
                        "YYYY-MM-DD"
                      ),
                      moment(
                        filter[selectedColumn.field]["value"]["end"],
                        "YYYY-MM-DD"
                      ),
                    ]
                  : [null, null]
              }
              onChange={(value) => {
                let d1 = moment(value[0]).format("YYYY-MM-DD");
                let d2 = moment(value[1]).format("YYYY-MM-DD");

                setFilter({
                  ...filter,
                  [selectedColumn.field]: {
                    value: { start: d1, end: d2 },
                    filter_type: selectedColumn.filter_type,
                    data_type: selectedColumn.data_type,
                  },
                });
              }}
            />
          </InputWrapper>
        );
      } else if (selectedColumn.data_type === "integer") {
        return (
          <InputWrapper>
            <Row>
              <OutlinedInput
                type="number"
                name="start"
                placeholder="min"
                value={
                  filter.hasOwnProperty(selectedColumn.field)
                    ? filter[selectedColumn.field]["value"]["start"]
                    : ""
                }
                onChange={(e) => {
                  let start = parseInt(e.target.value);
                  let end =
                    filter.hasOwnProperty(selectedColumn.field) &&
                    filter[selectedColumn.field]["value"]["end"] !== ""
                      ? parseInt(filter[selectedColumn.field]["value"]["end"])
                      : "";

                  setFilter({
                    ...filter,
                    [selectedColumn.field]: {
                      value: { start: start, end },
                      filter_type: selectedColumn.filter_type,
                      data_type: selectedColumn.data_type,
                    },
                  });
                }}
                style={{
                  width: "100%",
                  marginRight: "10px",
                  height: "30px",
                }}
                inputProps={{
                  min: 0,
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
              />
              <OutlinedInput
                type="number"
                name="end"
                placeholder="max"
                value={
                  filter.hasOwnProperty(selectedColumn.field)
                    ? filter[selectedColumn.field]["value"]["end"]
                    : ""
                }
                disabled={
                  !filter.hasOwnProperty(selectedColumn.field) ||
                  (filter.hasOwnProperty(selectedColumn.field) &&
                    filter[selectedColumn.field]["value"]["start"] === "")
                }
                onChange={(e) => {
                  let start =
                    filter.hasOwnProperty(selectedColumn.field) &&
                    filter[selectedColumn.field]["value"]["start"] !== ""
                      ? parseInt(filter[selectedColumn.field]["value"]["start"])
                      : "";
                  let end = parseInt(e.target.value);

                  setFilter({
                    ...filter,
                    [selectedColumn.field]: {
                      value: { start: start, end: end },
                      filter_type: selectedColumn.filter_type,
                      data_type: selectedColumn.data_type,
                    },
                  });
                }}
                style={{
                  width: "100%",
                  marginRight: "10px",
                  height: "30px",
                }}
                inputProps={{
                  min: 0,
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
              />
            </Row>
          </InputWrapper>
        );
      }
    } else if (selectedColumn.filter_type === "list") {
      if (
        selectedColumn.filter_input === "" ||
        selectedColumn.filter_input === null
      ) {
        return (
          <InputWrapper>
            <OutlinedInput
              style={{ height: "30px" }}
              inputProps={{
                "aria-label": "weight",
                style: {
                  fontSize: "12px",
                },
              }}
              type={
                selectedColumn.data_type === "email"
                  ? "email"
                  : selectedColumn.data_type === "datetime" ||
                    selectedColumn.data_type === "date"
                  ? "date"
                  : selectedColumn.data_type === "integer"
                  ? "number"
                  : "text"
              }
              name={selectedColumn.field}
              placeholder={`enter ${selectedColumn.headerName}`}
              onChange={(e) => {
                let data_type = selectedColumn.data_type;
                let filterType = selectedColumn.filter_type;
                handleChange({ e, data_type, filterType });
              }}
            />
          </InputWrapper>
        );
      } else {
        let list = filterTypeListMeta["list"];

        if (selectedColumn["map_to_parent_selection"]) {
          let parent_field_info = selectedColumn.child_of;
          let parent_data = getParentFieldValue(parent_field_info); //getting the data of parent field including value
          let parent_value = parent_data !== null ? parent_data["value"] : null;

          let child_render_list = [];
          if (parent_value !== null) {
            let child_data = JSON.parse(selectedColumn.filter_input);
            child_render_list = child_data.hasOwnProperty(
              parent_value["value"][0]["value"]
            )
              ? child_data[parent_value["value"][0]["value"]]
              : [];
          }
          list = child_render_list.map((str) => ({
            label: str,
            value: str,
          }));
        } else {
          if (filterTypeListMeta["type"] === "list") {
            list = list.map((str) => ({
              label: str,
              value: str,
            }));
          } else {
            list = list.map((obj) => ({
              label: obj["name"],
              value: obj["value"],
            }));
          }
        }

        return (
          <Box
            sx={{
              "&": {
                width: "100%",
                fontSize: "12px",
                border: "none",
                alignItems: "center",
                color: "black",
              },
              "& .dropdown-heading": {
                height: "30px",
              },
              "& .options": {
                maxHeight: "100px",
              },
            }}
          >
            <MultiSelect
              options={list}
              value={
                filter.hasOwnProperty(selectedColumn.field)
                  ? filter[selectedColumn.field]["value"]
                  : []
              }
              onChange={(selected) => {
                setFilter({
                  ...filter,
                  [selectedColumn["field"]]: {
                    value: selected,
                    filter_type: selectedColumn["filter_type"],
                    data_type: selectedColumn["data_type"],
                  },
                });
              }}
            />
          </Box>
        );
      }
    }
  }
};

export default CreateViewTabFilterValueFiledComponent;
