import React, { useState } from "react";
import styled from "styled-components";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  makeStyles,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Tab,
  Tabs,
  withStyles,
} from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import { Close } from "@material-ui/icons";
import moment from "moment";
import {
  createJobApi,
  editReportApi,
  getAdminColumnsApi,
  getAdminStagesApi,
  getAllColsApi,
  getRoleApiAdmin,
  getStatusApi,
  submitCallApi,
  submitReportApi,
} from "../../Api";
import { useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import { DatePicker } from "antd";
import MainFilterComponent from "../filter_modal/MainFilterComponent";
import ReportFilterComponent from "../filter_modal/ReportFilterComponent";
import { toJS } from "mobx";
import DeleteJobForm from "../job_forms/DeleteJobForm";
import ReassignJobFom from "../job_forms/ReassignJobFom";
import JobFilterComponent from "../filter_modal/JobFilterComponent";
import { myTheme } from "../../themeUtils";
import { getLocalDateTime, getLocalizedText } from "../../Functions";
import ExportJobForm from "../job_forms/ExportJobForm";
import { Alert, CircularProgress } from "@mui/material";
import DialerExportJobForm from "../job_forms/DialerExportJobForm";
import { LeadFilterTypeList } from "../../Config";
import DialerExportJobSummary from "../job_forms/DialerExportJobSummary";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;

const Header = styled.span`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled.span`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 320px;
  width: 400px;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Label = styled.span`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Text = styled.p`
  font: normal normal 600 16px Open Sans;
  color: #4d4e4f;
  margin: 0px;
`;
const Input = styled.input`
  width: 100%;
  height: 30px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;
  border: 1px solid #bfbfbf;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const TextArea = styled.textarea`
  width: 100%;
  height: 60px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #bfbfbf;
  color: #4d4e4f;
  resize: none;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  width: 180px;
  @media (max-width: 700px) {
    width: 140px;
  }
`;
const SelectColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;
const Message = styled.p`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  margin-bottom: 12px;
`;
const MessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  flex-direction: column;
`;
function ampm(time) {
  if (time.value !== "") {
    var hours = time.split(":")[0];
    var minutes = time.split(":")[1];
    var suffix = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    var displayTime = hours + ":" + minutes + " " + suffix;
    return displayTime;
  }
  return;
}
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const CustomTab = withStyles({
  root: {
    backgroundColor: "white",
    color: "#4D4E4F",
    font: "normal normal 600 14px Open Sans",
    textTransform: "capitalize",
  },
  selected: {
    backgroundColor: "#E7EEFA",
    color: "#4079DA",
    font: "normal normal 600 14px Open Sans",
    textTransform: "capitalize",
  },
})(Tab);
const type_list = [
  { value: "reassign_leads", label: "Reassign Leads" },
  { value: "delete_leads", label: "Delete Leads" },
  { value: "export_leads", label: "Export Leads" },
];
const CreateJobModal = (props) => {
  const { authStore, userStore } = rootStore;
  const classes = useStyles();
  const [typeList, setTypeList] = useState([]);
  const [user, setUser] = useState({
    title: "",
    desc: "",
    type: "",
  });

  const { RangePicker } = DatePicker;
  const [dateRangeReport, setDateRangeReport] = useState({});
  const [selected, setSelected] = useState([]);
  const [list, setList] = useState([]);
  const [erase, setErase] = useState(false);
  const [stepCount, setStepCount] = useState(0);
  const [dateRangeVal, setDateRangeVal] = useState(null);
  const [jobInput, setJobInput] = useState({});
  const [numRange, setNumRange] = useState({});
  const [selectedVal, setSelectedVal] = useState({});
  const [filters, setFilters] = useState({});
  const [dateRange, setDateRange] = useState({});
  const [flag, setFlag] = useState(false);
  const [isDateRange, setIsDateRange] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showSelectColumnAlert, setShowSelectColumnAlert] = useState(false);
  const [unmask, setUnmask] = useState(false);
  const [role, setRole] = useState(null);
  const [firstConditionChecked, setFirstConditionChecked] = useState(false);
  const [secondConditionChecked, setSecondConditionChecked] = useState(false);
  const [isBothCondtionCheckRequired, setIsBothConditionCheckRequired] =
    useState(false);
  const [agentList, setAgentList] = useState([]);
  const [autodialerCampaignType, setAutodialerCampaignType] = useState("");
  const [leadFilterType, setLeadFilterType] = useState("All Leads");
  const [maxLeadCount, setMaxLeadCount] = useState("");
  const [showAgentSelectionAlert, setShowAgentSelectionAlert] = useState(false);
  let pid = authStore.projectId;
  const [dialerCampaignType, setDialerCampaignType] = useState("");
  const [adminColumns, setAdminColumns] = useState([]);
  const [stageList, setStageList] = useState([]);
  const [loading, setLoading] = useState(true);

  //******************Delete Protected related functions****** */
  const isStatusDeleteProtected = () => {
    if (
      selectedVal.hasOwnProperty("lead_status") &&
      selectedVal["lead_status"].length > 0
    ) {
      for (let i = 0; i < selectedVal["lead_status"].length; i++) {
        let item = selectedVal["lead_status"][i];
        let index = props.statusList.findIndex(
          (status) => status["status"] === item
        );
        if (index !== -1) {
          return props.statusList[index]["delete_protected"];
        }
      }
    } else {
      for (let i = 0; i < props.statusList.length; i++) {
        if (props.statusList[i]["delete_protected"] === true) {
          return true;
        }
      }
    }
    return false;
  };

  const isStageDeleteProtected = () => {
    if (
      selectedVal.hasOwnProperty("lead_stage") &&
      selectedVal["lead_stage"].length > 0
    ) {
      for (let i = 0; i < selectedVal["lead_stage"].length; i++) {
        let item = selectedVal["lead_stage"][i];
        let index = stageList.findIndex((stage) => stage["stage"] === item);
        if (index !== -1) {
          return stageList[index]["delete_protected"];
        }
      }
    } else {
      for (let i = 0; i < stageList.length; i++) {
        if (stageList[i]["delete_protected"] === true) {
          return true;
        }
      }
    }
    return false;
  };

  const isDeleteProtected = () => {
    return isStatusDeleteProtected() || isStageDeleteProtected();
  };

  //***********************END********************************* */

  const handleClick = () => {
    props.setOpen(false);
  };
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  const validateDateRange = () => {
    return dateRangeVal !== null;
  };
  const handleNext = () => {
    setStepCount(stepCount + 1);
  };
  const handlePrev = () => {
    setStepCount(stepCount - 1);
  };

  const canCreateDeleteJob = () => {
    if (isBothCondtionCheckRequired) {
      return firstConditionChecked && secondConditionChecked;
    }
    return firstConditionChecked;
  };

  const createJob = async () => {
    userStore.setReportFilters({
      filters,
      dateRange,
      numRange,
      selectedVal,
    });
    let data = {
      title: user.title,
      desc: user.desc,
      input: {
        filter: userStore.reportFilters,
        ...jobInput,
        ...dateRangeVal,
      },
      job_type: user.type,
    };
    if (user.type === "reassign_leads") {
      data.input["erase_history"] = erase;
    } else if (user.type === "export_leads") {
      let column_list = [];
      selected.forEach((option) => {
        column_list.push(option.value);
      });
      data.input["columns"] = column_list;
      data.input["all_leads"] = !isDateRange;
      data.input["unmask_columns"] = unmask;
    } else if (isExportDialer()) {
      let user_list = [];
      agentList.forEach((agent) => {
        user_list.push(agent.user.name);
      });
      data["input"]["filter"] = {
        ...userStore.reportFilters,
        lead_owner: user_list,
      };
      if (authStore.currentProject["external_dialer"] === "mcube") {
        data["job_type"] = "export_leads_dialer_mcube";
      } else if (authStore.currentProject["external_dialer"] === "ozonetel") {
        data["job_type"] = "export_leads_dialer_ozonetel";
      }

      data.input["agent_list"] = agentList;
      data.input["leads_date_filter_type"] = leadFilterType;
      data.input["max_leads_per_agent"] = parseInt(maxLeadCount);
      data.input["auto_dialer_campaign_type"] = dialerCampaignType;
    }

    try {
      let response = await createJobApi({ id: props.id, data: data });
      userStore.resetReportFilters();
      setFlag(true);
    } catch (error) {
      console.log(error);
    }
  };

  const submitForm = async () => {
    if (user.type === "delete_leads") {
      if (isDeleteProtected()) {
        props.setShowDeleteProtectedAlert(true);
        props.setOpen(false);
      } else {
        await createJob();
      }
    } else {
      await createJob();
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (stepCount === 0 || stepCount === 1) {
      if (stepCount === 0) {
        if (user.type !== "export_leads" && !isExportDialer()) {
          if (validateDateRange()) {
            setShowAlert(false);
            handleNext();
          } else {
            setShowAlert(true);
          }
        } else {
          if (user.type === "export_leads") {
            if (isDateRange === true) {
              if (validateDateRange()) {
                setShowAlert(false);
                handleNext();
              } else {
                setShowAlert(true);
              }
            } else {
              handleNext();
            }
          } else {
            if (leadFilterType !== "All Leads") {
              if (validateDateRange()) {
                setShowAlert(false);
                handleNext();
              } else {
                setShowAlert(true);
              }
            } else {
              handleNext();
            }
          }
        }
      } else {
        if (user.type === "export_leads") {
          if (selected.length === 0) {
            setShowSelectColumnAlert(true);
          } else {
            setShowSelectColumnAlert(false);
            handleNext();
          }
        } else {
          setShowSelectColumnAlert(false);
          handleNext();
        }
      }
    } else if (stepCount === 2) {
      if (isExportDialer()) {
        if (agentList.length > 0) {
          setShowAgentSelectionAlert(false);
          handleNext();
        } else {
          setShowAgentSelectionAlert(true);
        }
      } else {
        submitForm();
      }
    } else {
      submitForm();
    }
  };
  const updateList = (val) => {
    let newList = [];
    let filterList = [];
    val.map((e) => {
      if (
        e.field !== "lead_created_at" &&
        e.field !== "lead_creator" &&
        rootStore.userStore.isColumnVisible(e)
      ) {
        if (e.data_type === "datetime") {
          if (e.system === true) {
            let obj1 = {
              label: e.headerName,
              value: e.field,
            };
            let obj2 = {
              label: e.headerName + "::DateTime",
              value: e.field + "::datetime",
            };
            newList.push(obj1);
            newList.push(obj2);
          } else {
            let newObj = {
              label: e.headerName,
              value: e.field,
            };
            newList.push(newObj);
          }
        } else {
          let newObj = {
            label: e.headerName,
            value: e.field,
          };
          newList.push(newObj);
          filterList.push(newObj);
        }
      }
      if (e.filterable) {
        let newObj = {
          label: e.headerName,
          value: e.field,
        };
        filterList.push(newObj);
      }
    });
    return { newList, filterList };
  };
  const getProjectRole = async () => {
    try {
      let response = await getRoleApiAdmin(props.id);
      setRole(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const canShowExportDialerOption = () => {
    let dialer = authStore.currentProject["external_dialer"];
    return dialer === "mcube" || dialer === "ozonetel";
  };

  const getDialerProviderName = () => {
    let dialer = authStore.currentProject["external_dialer"];
    if (dialer === "mcube") {
      return "MCube";
    } else if (dialer === "ozonetel") {
      return "Ozonetel";
    } else {
      return dialer;
    }
  };

  const setupTypeList = () => {
    let role = authStore.getCurrentProjectRole();
    if (role === "lead") {
      let new_list = [{ value: "export_leads", label: "Export Leads" }];
      if (canShowExportDialerOption()) {
        new_list.push({
          value: "export_lead_for_dialer",
          label: "Export Lead for Dialer",
        });
      }
      setTypeList(new_list);
      setUser({ ...user, type: "export_leads" });
    } else {
      let new_list = [...type_list];
      if (canShowExportDialerOption()) {
        new_list.push({
          value: "export_lead_for_dialer",
          label: "Export Lead for Dialer",
        });
      }
      setTypeList(new_list);
      setUser({ ...user, type: "reassign_leads" });
    }
  };
  const getStagesList = async () => {
    try {
      let response = await getAdminStagesApi(props.id);
      setStageList(response.data);
    } catch (error) {
      setStageList([]);
    }
  };

  const getColumns = async () => {
    try {
      let response = await getAdminColumnsApi({
        pid: props.id,
        page: 0,
        page_size: 200,
      });
      setAdminColumns(response.data.items);
      let modifiedList = updateList([...response.data.items]);
      setList(modifiedList.newList);
    } catch (error) {
      console.log(error);
    }
  };
  const init = async () => {
    setupTypeList();
    await getProjectRole();
    await getColumns();
    await getStagesList();
    setUnmask(false);
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);
  const handleStep = () => {
    if (stepCount === 0) {
      setStepCount(1);
    } else {
      setStepCount(0);
    }
  };
  function disabledDate(current) {
    if (user.type === "delete_leads") {
      return (
        current &&
        (current > moment().endOf("month") ||
          current < moment().subtract(1, "month").startOf("month"))
      );
    } else {
      return current && current > moment().endOf("day");
    }
  }

  const isExportDialer = () => {
    return user.type === "export_lead_for_dialer";
  };

  const resetDialerExportJob = () => {
    setLeadFilterType("All Leads");
    setAgentList([]);
    setAutodialerCampaignType("");
    setMaxLeadCount(null);
    setShowAgentSelectionAlert(false);
  };

  return (
    <>
      <Modal
        open={props.open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          {!loading ? (
            <Box>
              <Container>
                {!flag ? (
                  <Form onSubmit={handleSubmit}>
                    {props.type === "edit" ? (
                      <Header>{getLocalizedText("edit_job")}</Header>
                    ) : (
                      <Header>{getLocalizedText("create_a_new_job")}</Header>
                    )}

                    {(() => {
                      if (stepCount === 0) {
                        return (
                          <>
                            <SubHeader>
                              {getLocalizedText("fill_in_the_below_details")}
                            </SubHeader>
                            <Divider style={{ margin: "10px 0px" }} />
                            <InputContainer>
                              <InputWrapper style={{ marginTop: "12px" }}>
                                <Label>{getLocalizedText("title")}*</Label>
                                <Input
                                  required
                                  type="text"
                                  placeholder="Enter title"
                                  name="title"
                                  value={user.title}
                                  onChange={handleChange}
                                />
                              </InputWrapper>
                              <InputWrapper>
                                <Label>{getLocalizedText("description")}</Label>
                                <TextArea
                                  value={user.desc}
                                  type="text"
                                  placeholder="Enter description"
                                  name="desc"
                                  onChange={handleChange}
                                />
                              </InputWrapper>
                              <SelectColumn>
                                <Label>{getLocalizedText("select_type")}</Label>
                                <FormControl
                                  sx={{ minWidth: 400 }}
                                  classes={{
                                    root: classes.quantityRoot,
                                  }}
                                >
                                  <Select
                                    classes={{
                                      icon: classes.icon,
                                    }}
                                    value={user.type}
                                    name="type"
                                    displayEmpty
                                    disableUnderline
                                    variant="outlined"
                                    required
                                    onChange={(e) => {
                                      handleChange(e);
                                      setShowAlert(false);
                                      setShowSelectColumnAlert(false);
                                      setDateRangeVal(null);
                                      setSelected([]);
                                      resetDialerExportJob();
                                    }}
                                    style={{
                                      marginBottom: "20px",
                                      width: "400px",
                                      height: "30px",
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                      borderRadius: "0px",
                                    }}
                                  >
                                    {typeList.map((e, i) => {
                                      {
                                        /* console.log("status id: ", e.id); */
                                      }
                                      return (
                                        <MenuItem id={i} value={e.value}>
                                          {e.label}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </SelectColumn>
                              {user.type === "export_leads" && (
                                <InputWrapper
                                  style={{
                                    width: "100%",
                                    flexDirection: "row",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                  >
                                    <FormControlLabel
                                      value={false}
                                      control={<Radio size="small" />}
                                      label={
                                        <Label style={{ fontSize: "12px" }}>
                                          {getLocalizedText(
                                            "all_leads",
                                            "project"
                                          )}
                                        </Label>
                                      }
                                      checked={!isDateRange}
                                      onChange={() => {
                                        setIsDateRange(false);
                                        setDateRangeVal(null);
                                      }}
                                    />
                                    <FormControlLabel
                                      value={true}
                                      checked={isDateRange}
                                      onChange={() => {
                                        setIsDateRange(true);
                                        setDateRangeVal(null);
                                      }}
                                      control={<Radio size="small" />}
                                      label={
                                        <Label style={{ fontSize: "12px" }}>
                                          {getLocalizedText(
                                            "leads_created_between",
                                            "project"
                                          )}
                                        </Label>
                                      }
                                    />
                                  </RadioGroup>
                                </InputWrapper>
                              )}

                              {isExportDialer() && (
                                <SelectColumn>
                                  <Label>
                                    {getLocalizedText(
                                      "select_leads_filter_type",
                                      "project"
                                    )}
                                  </Label>
                                  <FormControl
                                    sx={{ m: 1, minWidth: 400 }}
                                    classes={{
                                      root: classes.quantityRoot,
                                    }}
                                  >
                                    <Select
                                      classes={{
                                        icon: classes.icon,
                                      }}
                                      value={leadFilterType}
                                      displayEmpty
                                      disableUnderline
                                      variant="outlined"
                                      onChange={(e) => {
                                        setLeadFilterType(e.target.value);
                                        setDateRangeVal(null);
                                      }}
                                      required
                                      style={{
                                        marginBottom: "20px",
                                        width: "400px",
                                        height: "30px",
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        borderRadius: "0px",
                                      }}
                                    >
                                      {LeadFilterTypeList.map((e, i) => {
                                        return (
                                          <MenuItem id={i} value={e.label}>
                                            {getLocalizedText(e.key, "project")}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </SelectColumn>
                              )}

                              <Row>
                                {props.type === "edit" ? (
                                  dateRangeVal !== null && (
                                    <InputWrapper
                                      style={{ marginBottom: "20px" }}
                                    >
                                      <Label>
                                        {getLocalizedText("select_date_range")}
                                      </Label>
                                      <RangePicker
                                        disabledDate={disabledDate}
                                        placeholder={["From", "To"]}
                                        getPopupContainer={(triggerNode) => {
                                          return triggerNode.parentNode;
                                        }}
                                        style={{
                                          height: "30px",
                                          width: "400px",
                                        }}
                                        // value={dateRangeVal}
                                        defaultValue={[
                                          moment(
                                            dateRangeVal["start_date"],
                                            "YYYY-MM-DD"
                                          ),
                                          moment(
                                            dateRangeVal["end_date"],
                                            "YYYY-MM-DD"
                                          ),
                                        ]}
                                        onChange={(value) => {
                                          let d1 = moment(value[0]).format(
                                            "YYYY-MM-DD"
                                          );
                                          let d2 = moment(value[1]).format(
                                            "YYYY-MM-DD"
                                          );
                                          setDateRangeReport({
                                            ...dateRangeReport,
                                            start_date: d1,
                                            end_date: d2,
                                          });
                                        }}
                                      />
                                    </InputWrapper>
                                  )
                                ) : (
                                  <InputWrapper
                                    style={{ marginBottom: "20px" }}
                                  >
                                    <Label>
                                      {getLocalizedText("select_date_range")}
                                    </Label>
                                    <RangePicker
                                      disabledDate={disabledDate}
                                      disabled={
                                        (user.type === "export_leads" &&
                                          !isDateRange) ||
                                        (isExportDialer() &&
                                          leadFilterType === "All Leads")
                                      }
                                      placeholder={["From", "To"]}
                                      getPopupContainer={(triggerNode) => {
                                        return triggerNode.parentNode;
                                      }}
                                      value={
                                        dateRangeVal && [
                                          moment(
                                            dateRangeVal["start_date"],
                                            "YYYY-MM-DD"
                                          ),
                                          moment(
                                            dateRangeVal["end_date"],
                                            "YYYY-MM-DD"
                                          ),
                                        ]
                                      }
                                      defaultValue={
                                        dateRangeVal && [
                                          moment(
                                            dateRangeVal["start_date"],
                                            "YYYY-MM-DD"
                                          ),
                                          moment(
                                            dateRangeVal["end_date"],
                                            "YYYY-MM-DD"
                                          ),
                                        ]
                                      }
                                      style={{ height: "30px", width: "400px" }}
                                      // value={dateRangeVal}
                                      onChange={(value) => {
                                        let d1 = moment(value[0]).format(
                                          "YYYY-MM-DD"
                                        );
                                        let d2 = moment(value[1]).format(
                                          "YYYY-MM-DD"
                                        );
                                        setDateRangeVal({
                                          ...dateRangeVal,
                                          start_date: d1,
                                          end_date: d2,
                                        });
                                        setDateRangeReport({
                                          ...dateRangeReport,
                                          start_date: d1,
                                          end_date: d2,
                                        });
                                      }}
                                    />
                                  </InputWrapper>
                                )}
                              </Row>
                            </InputContainer>
                          </>
                        );
                      } else if (stepCount === 1) {
                        if (user.type === "delete_leads" || isExportDialer()) {
                          return (
                            <>
                              <SubHeader>
                                {getLocalizedText("select_filters")}
                              </SubHeader>
                              <Divider style={{ margin: "10px 0px" }} />
                              <JobFilterComponent
                                isExportDialer={isExportDialer}
                                dateRange={dateRange}
                                setDateRange={setDateRange}
                                numRange={numRange}
                                selectedVal={selectedVal}
                                setSelectedVal={setSelectedVal}
                                filters={filters}
                                setFilters={setFilters}
                                type={"edit"}
                                id={props.id}
                                adminColumns={adminColumns}
                              />
                            </>
                          );
                        } else {
                          return (
                            <>
                              {(() => {
                                if (user.type === "reassign_leads") {
                                  return (
                                    <ReassignJobFom
                                      typeList={typeList}
                                      flag={erase}
                                      setFlag={setErase}
                                      item={user}
                                      jobInput={jobInput}
                                      setJobInput={setJobInput}
                                      dateRange={dateRangeVal}
                                      id={props.id}
                                    />
                                  );
                                } else if (user.type === "export_leads") {
                                  return (
                                    <ExportJobForm
                                      typeList={typeList}
                                      isDateRange={isDateRange}
                                      flag={erase}
                                      setFlag={setErase}
                                      item={user}
                                      jobInput={jobInput}
                                      setJobInput={setJobInput}
                                      dateRange={dateRangeVal}
                                      list={list}
                                      setSelected={setSelected}
                                      selected={selected}
                                      unmask={unmask}
                                      setUnmask={setUnmask}
                                      role={role}
                                    />
                                  );
                                }
                              })()}
                            </>
                          );
                        }
                      } else if (stepCount === 2) {
                        if (user.type === "delete_leads") {
                          return (
                            <DeleteJobForm
                              typeList={typeList}
                              item={user}
                              jobInput={jobInput}
                              setJobInput={setJobInput}
                              dateRange={dateRangeVal}
                              firstConditionChecked={firstConditionChecked}
                              setFirstConditionChecked={
                                setFirstConditionChecked
                              }
                              secondConditionChecked={secondConditionChecked}
                              setSecondConditionChecked={
                                setSecondConditionChecked
                              }
                              selectedVal={selectedVal}
                              setIsBothConditionCheckRequired={
                                setIsBothConditionCheckRequired
                              }
                              statusList={props.statusList}
                            />
                          );
                        } else if (isExportDialer()) {
                          return (
                            <DialerExportJobForm
                              setAutodialerCampaignType={
                                setAutodialerCampaignType
                              }
                              maxLeadCount={maxLeadCount}
                              setMaxLeadCount={setMaxLeadCount}
                              provider={getDialerProviderName()}
                              setAgentList={setAgentList}
                              autodialerCampaignType={autodialerCampaignType}
                              agentList={agentList}
                              setDialerCampaignType={setDialerCampaignType}
                            />
                          );
                        } else {
                          return (
                            <>
                              <SubHeader>
                                {getLocalizedText("select_filters")}
                              </SubHeader>
                              <Divider style={{ margin: "10px 0px" }} />
                              <JobFilterComponent
                                isExportDialer={isExportDialer}
                                dateRange={dateRange}
                                setDateRange={setDateRange}
                                numRange={numRange}
                                selectedVal={selectedVal}
                                setSelectedVal={setSelectedVal}
                                filters={filters}
                                setFilters={setFilters}
                                type={"edit"}
                                id={props.id}
                                adminColumns={adminColumns}
                              />
                            </>
                          );
                        }
                      } else {
                        return (
                          <DialerExportJobSummary
                            typeList={typeList}
                            agentList={agentList}
                            maxLeadCount={maxLeadCount}
                            item={user}
                            role={role}
                            dateRange={dateRangeVal}
                            isExportDialer={isExportDialer}
                          />
                        );
                      }
                    })()}
                    {showAlert && (
                      <Alert
                        style={{
                          width: "100%",
                          padding: "0px 20px",
                        }}
                        severity="warning"
                      >
                        {getLocalizedText("please_select_a_date_range")}
                      </Alert>
                    )}

                    {showSelectColumnAlert && (
                      <Alert
                        style={{
                          width: "100%",
                          padding: "0px 20px",
                        }}
                        severity="warning"
                      >
                        {getLocalizedText("please_select_atleast_one_column")}
                      </Alert>
                    )}

                    {showAgentSelectionAlert && (
                      <Alert
                        style={{
                          width: "100%",
                          padding: "0px 20px",
                        }}
                        severity="warning"
                      >
                        {getLocalizedText("please_select_atleast_one_agent")}
                      </Alert>
                    )}
                    <ButtonWrapper>
                      <Button
                        onClick={() => {
                          if (isExportDialer()) {
                            if (
                              stepCount === 1 ||
                              stepCount === 2 ||
                              stepCount === 3
                            ) {
                              handlePrev();
                            } else {
                              handleClose();
                            }
                          } else {
                            if (stepCount === 1 || stepCount === 2) {
                              handlePrev();
                            } else {
                              handleClose();
                            }
                          }
                        }}
                        type="button"
                        variant="outlined"
                        style={{
                          textTransform: "none",
                          marginRight: "20px",
                          fontSize: "12px",
                          fontWeight: "bold",
                          width: "100px",
                          ...myTheme.Button.btnLight,
                        }}
                      >
                        {isExportDialer()
                          ? stepCount === 1 ||
                            stepCount === 2 ||
                            stepCount === 3
                            ? getLocalizedText("prev")
                            : getLocalizedText("cancel")
                          : stepCount === 1 || stepCount === 2
                          ? getLocalizedText("prev")
                          : getLocalizedText("cancel")}
                      </Button>
                      <Button
                        // onClick={
                        //   stepCount === 0 || stepCount === 1
                        //     ? handleNext
                        //     : handleSubmit
                        // }
                        disabled={
                          stepCount === 2 &&
                          user.type === "delete_leads" &&
                          !canCreateDeleteJob()
                        }
                        color="primary"
                        type="submit"
                        variant="contained"
                        // id="call-log-btn"
                        style={{
                          textTransform: "none",
                          fontSize: "12px",
                          fontWeight: "bold",
                          width: "100px",
                        }}
                      >
                        {isExportDialer()
                          ? stepCount === 0 ||
                            stepCount === 1 ||
                            stepCount === 2
                            ? getLocalizedText("next")
                            : getLocalizedText("submit")
                          : stepCount === 0 || stepCount === 1
                          ? getLocalizedText("next")
                          : getLocalizedText("submit")}
                      </Button>
                    </ButtonWrapper>
                  </Form>
                ) : (
                  <>
                    <MessageWrapper>
                      <Message style={{ textAlign: "center" }}>
                        {getLocalizedText(
                          "job_has_been_created_successfully_please_check_the_status_in_the_jobs_listing"
                        )}
                      </Message>
                      <Button
                        variant="text"
                        onClick={() => {
                          props.setRefresh(!props.refresh);
                          props.setOpen(false);
                        }}
                        style={{
                          width: "fit-content",
                          fontSize: "12px",
                          fontWeight: "bold",
                          margin: "auto",

                          ...myTheme.Button.btnBlue,
                        }}
                      >
                        {getLocalizedText("ok")}
                      </Button>
                    </MessageWrapper>
                  </>
                )}
              </Container>
            </Box>
          ) : (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "400px",
                width: "320px",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </ModelContainer>
      </Modal>
    </>
  );
};

export default observer(CreateJobModal);
