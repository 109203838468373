import React, { useState } from "react";
import styled from "styled-components";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  FormControl,
  makeStyles,
  MenuItem,
  Modal,
  Select,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import moment from "moment";
import { getStatusApi, submitCallApi } from "../../Api";
import { useEffect } from "react";
import { myTheme } from "../../themeUtils";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled.span`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled.span`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
  margin-bottom: 30px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Label = styled.span`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Input = styled.input`
  width: 200px;
  height: 30px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;

  border: 1px solid #bfbfbf;
  font: normal normal 600 16px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const TextArea = styled.textarea`
  width: 428px;
  height: 60px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #bfbfbf;
  color: #4d4e4f;

  resize: none;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 600 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const ButtonWrapper = styled.div`
  /* margin-top: 30px; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  width: 180px;
  @media (max-width: 700px) {
    width: 140px;
  }
`;
const SelectColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;

function ampm(time) {
  if (time.value !== "") {
    var hours = time.split(":")[0];
    var minutes = time.split(":")[1];
    var suffix = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    var displayTime = hours + ":" + minutes + " " + suffix;
    return displayTime;
  }
  return;
}
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const ActivityLogModal = (props) => {
  const { authStore } = rootStore;
  const classes = useStyles();
  const [user, setUser] = useState({
    notes: "",
    status: "",
    activity_type: "",
  });
  const [statusList, setStatusList] = useState([]);
  const [activityList, setActivityList] = useState([
    "reading",
    "writing",
    "simulation",
  ]);
  const [audioFile, setAudioFile] = useState();
  let pid = authStore.projectId;
  const handleClick = () => {
    props.setOpen(false);
  };
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // let newDate;
    // if (
    //   user.next_followup_on_date !== "" &&
    //   user.next_followup_on_time !== ""
    // ) {
    //   let newTime = ampm(user.next_followup_on_time);
    //   newDate = user.next_followup_on_date + "," + newTime;
    //   newDate = moment(newDate).format("MMMM Do YYYY, h:mm a");
    //   newDate = moment(newDate, "MMMM Do YYYY, h:mm a").unix();
    // } else if (
    //   user.next_followup_on_date !== "" &&
    //   user.next_followup_on_time === ""
    // ) {
    //   newDate = user.next_followup_on_date;
    //   newDate = moment(newDate).format("MMMM Do YYYY");
    //   newDate = moment(newDate, "MMMM Do YYYY").unix();
    // } else {
    //   newDate = null;
    // }
    // document.getElementById("call-log-btn").innerText = "Loading...";
    // let timeStamp = +new Date();
    // let formData = new FormData();
    // formData.append("notes", user.notes);
    // newDate !== undefined &&
    //   newDate !== null &&
    //   formData.append("next_followup_on", newDate);
    // formData.append("status", user.status);
    // formData.append("sub_status", user.sub_status);
    // audioFile !== undefined && formData.append("audio", audioFile);
    // const sendCallLog = async () => {
    //   let response = await submitCallApi({ id: props.lid, data: formData });
    //   document.getElementById("call-log-btn").innerText = "Submitted";
    //   document.getElementById("call-log-btn").style.backgroundColor = "green";
    //   document.getElementById("call-log-btn").style.color = "white";
    //   setTimeout(() => {
    //     props.setRefresh(!props.refresh);
    //     props.setOpen(false);
    //   }, 800);
    // };
    // sendCallLog();
  };
  const handleClose = () => {
    props.setOpen(false);
  };
  //   const handleAudio = (e) => {
  //     setAudioFile(e.target.files[0]);
  //     console.log(e.target.files[0]);
  //   };
  useEffect(() => {
    const getStatusList = async () => {
      let response = await getStatusApi();
      setStatusList(response.data);
      console.log(response.data);
    };
    getStatusList();
  }, []);
  // console.log(user);
  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            {/* <Close
              onClick={handleClick}
              style={{
                height: "18px",
                width: "18px",
                position: "absolute",
                top: "20px",
                right: "30px",
                cursor: "pointer",
              }}
            /> */}
            <Container>
              <Header>Submit Activity</Header>
              <SubHeader>Fill in the below details</SubHeader>
              <Form onSubmit={handleSubmit}>
                <InputContainer>
                  <Row>
                    {activityList && (
                      <SelectColumn style={{ marginRight: "28px" }}>
                        <Label>Activity Type</Label>
                        <FormControl
                          sx={{ m: 1, minWidth: 200 }}
                          classes={{
                            root: classes.quantityRoot,
                          }}
                        >
                          <Select
                            classes={{
                              icon: classes.icon,
                            }}
                            name="activity_type"
                            displayEmpty
                            disableUnderline
                            variant="outlined"
                            required
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            style={{
                              marginBottom: "20px",
                              width: "200px",
                              height: "30px",
                              fontSize: "12px",
                              fontWeight: "bold",
                              borderRadius: "0px",
                            }}
                          >
                            {activityList.map((e) => {
                              {
                                /* console.log("status id: ", e.id); */
                              }
                              return (
                                <MenuItem id={e} value={e}>
                                  {e}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </SelectColumn>
                    )}
                    {statusList.length > 0 && (
                      <SelectColumn>
                        <Label>Status</Label>
                        <FormControl
                          sx={{ m: 1, minWidth: 200 }}
                          classes={{
                            root: classes.quantityRoot,
                          }}
                        >
                          <Select
                            classes={{
                              icon: classes.icon,
                            }}
                            name="status"
                            displayEmpty
                            disableUnderline
                            variant="outlined"
                            required
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            style={{
                              marginBottom: "20px",
                              width: "200px",
                              height: "30px",
                              fontSize: "12px",
                              fontWeight: "bold",
                              borderRadius: "0px",
                            }}
                          >
                            {statusList.map((e) => {
                              {
                                /* console.log("status id: ", e.id); */
                              }
                              return (
                                <MenuItem id={e.id} value={e.status}>
                                  {e.status}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </SelectColumn>
                    )}
                  </Row>

                  <InputWrapper>
                    <Label>Add Note*</Label>
                    <TextArea
                      required
                      type="text"
                      placeholder="Enter note here..."
                      name="notes"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </InputContainer>
                <ButtonWrapper>
                  <Button
                    onClick={handleClick}
                    type="button"
                    variant="contained"
                    color="default"
                    style={{
                      backgroundColor: "#EFEFF4",
                      textTransform: "none",
                      marginRight: "20px",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    id="call-log-btn"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      ...myTheme.Button.btnBlue,
                    }}
                  >
                    Submit Details
                  </Button>
                </ButtonWrapper>
              </Form>
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default observer(ActivityLogModal);
