import { Box, Button, Grid, Modal } from "@material-ui/core";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { getLocalizedText } from "../../Functions";
import { OutlinedInput, Typography } from "@mui/material";
import { myTheme } from "../../themeUtils";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  width: 420px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 16px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
  margin-bottom: 16px;
`;
const Form = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const ButtonWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  margin-top: 30px;
`;

const DialogWithCommentField = ({ open, setOpen, handleSubmitButton }) => {
  const [comments, setComments] = useState("");
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    handleSubmitButton(comments);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <Container>
              <Header>Comments*</Header>
              <Form component={"form"} onSubmit={handleFormSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <OutlinedInput
                        multiline={true}
                        rows={3}
                        inputProps={{
                          min: 0,
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        value={comments}
                        name={"comments"}
                        type={"text"}
                        required
                        placeholder={"Enter Value"}
                        onChange={(e) => setComments(e.target.value)}
                      />
                    </InputWrapper>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ButtonWrapper>
                      <Button
                        onClick={() => {
                          setOpen(false);
                        }}
                        variant="contained"
                        color="default"
                        style={{
                          fontWeight: "bold",

                          fontSize: "12px",
                          marginRight: "10px",
                          textTransform: "none",
                        }}
                      >
                        {getLocalizedText("cancel")}
                      </Button>
                      <Button
                        onClick={() => {}}
                        type="submit"
                        variant="contained"
                        style={{
                          fontSize: "12px",

                          fontWeight: "bold",
                          width: "fit-content",
                          textTransform: "none",
                          ...myTheme.Button.btnBlue,
                        }}
                      >
                        Submit
                      </Button>
                    </ButtonWrapper>
                  </Grid>
                </Grid>
              </Form>
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default DialogWithCommentField;
