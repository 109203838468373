import React, { useState }  from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";

import { Box, IconButton, styled, Tab, Tabs, tabsClasses, Tooltip, Typography } from "@mui/material";
import { AddBoxOutlined, ChevronRight } from "@material-ui/icons";
import rootStore from "../../stores/RootStore";
import StatusTab from "../../components/project_tabs/StatusTab";
import { useEffect } from "react";
import analytics from "../../Analytics";
const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  height: 100%;
  margin: auto;
  margin-top: 70px;
  width: 95%;
`;
const Wrapper = styled(Box)`
  display: flex;
  position: relative;
  ${`min-height: calc(100vh - 188px);`};
  background-color: white;
  padding: 20px 20px 0px 20px;
  border: 2px solid #f9f9fc;
`;
const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 0px;
`;
const Text = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
`;
const CustomTab = styled(Tab)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: "#E7EEFA",
    color: "#4079DA",
    font: "normal normal 600 14px Open Sans",
    textTransform: "capitalize",
  },
  backgroundColor: "white",
  color: "#4D4E4F",
  font: "normal normal 600 14px Open Sans",
  textTransform: "capitalize",
}));

const AdminProjectStatuses = () => {
  const { authStore } = rootStore;
  const project_id = authStore.projectId;
  const [tabValue, setTabValue] = useState("visible");
  const [openAddStatusSidepanel, setOpenAddStatusSidepanel] = useState(false);

  useEffect(() => {
    analytics.triggerEvent(
      4625000207,
      "project_statuses_page_load",
      "project_statuses_page",
      "",
      {}
    );
    authStore.updateLeadDetailStatus();
  }, []);
  return (
    <>
      <ParentContainer>
        <Container>
          <TopWrapper>
            <Text>Project Administration</Text>
            <ChevronRight style={{ width: "16px", height: "16px" }} />
            <Text>Statuses</Text>
          </TopWrapper>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "20px",
              bgcolor: "white",
              paddingRight: "20px",
            }}
          >

          <Tabs
            value={tabValue}
            textColor="primary"
            indicatorColor="primary"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                "&.Mui-disabled": { opacity: 0.3 },
              },
              bgcolor: "white",
            }}
            onChange={(event, newValue) => {
              setTabValue(newValue);
            }}
          >
            <CustomTab label={"Visible"} value={"visible"} />
            <CustomTab label={"Hidden"} value={"hidden"} />
          </Tabs>
          <Tooltip title={"Add Status"}>
              <IconButton
                style={{ padding: "0px" }}
                color="primary"
                onClick={() => {
                  analytics.triggerEvent(
                4625000208,
                "add_button_tap",
                "project_statuses_page",
                "add_button",
                {}
              );
                  setOpenAddStatusSidepanel(true);
                }}
              >
                <AddBoxOutlined style={{ width: "30px", height: "30px" }} />
              </IconButton>
            </Tooltip>
          </Box>
          <Wrapper>
            <StatusTab id={project_id} tabValue={tabValue} open={openAddStatusSidepanel} setOpen={setOpenAddStatusSidepanel}/>
          </Wrapper>
        </Container>
      </ParentContainer>
      <Footer />
    </>
  );
};

export default observer(AdminProjectStatuses);
