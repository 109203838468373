import React, { useEffect } from "react";
import { useState } from "react";
import "./tabs.css";
import { observer } from "mobx-react-lite";
import { ImportHistoryColumns, JobListColumns } from "../../Db";
import { getJobsApi } from "../../Api";
import PaginationComponent from "../pagination/PaginationComponent";
import { pageSize } from "../../Config";
import {
  Box,
  CircularProgress,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import JobRowComponent from "../import_row_component/JobRowComponent";
import CreateJobModal from "../create_job_modal/CreateJobModal";
import { AddBoxOutlined } from "@material-ui/icons";
import CustomMessageDialog from "../alert_dialogue/CustomMessageDialog";
import { canShowJobs } from "../../Functions";
const Container = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 234px);`};
  position: relative;
`;
const Wrapper = styled(Box)`
  display: flex;
`;

const DetailsWrapper = styled(Box)`
  height: fit-content;
  width: 100%;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
  overflow-x: auto;
  border: 2px solid #e0e0e0;
`;
const AddBtnWrapper = styled(Box)`
  position: absolute;
  right: 0px;
  top: 0px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
  @media (max-width: 700px) {
    position: unset;
    margin-left: 12px;
  }
`;

const PaginationWrapper = styled(Box)`
  padding: 0px 20px;
`;
const EmptyTableWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const EmptyText = styled(Typography)`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
  /* text-align: center; */
  color: #4d4e4f;
  margin-top: 50px;
`;
const JobsTab = (props) => {
  const [row, setRow] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(pageSize);
  const [openImport, setOpenImport] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showDeleteProtectedAlert, setShowDeleteProtectedAlert] =
    useState(false);

  const getJobsList = async ({ page, page_size }) => {
    setRow([]);
    let response = await getJobsApi({
      id: props.id,
      page: page,
      page_size: page_size,
    });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
      setCount(response.item_count);
    } else {
      setRow([]);
      setCount(0);
    }
  };

  const init = async () => {
    if (canShowJobs()) {
      await getJobsList({ page: 0, page_size: pageSize });
    }
    setLoading(false);
  };

  const refetch = async () => {
    setLoading(true);
    await getJobsList({ page: 0, page_size: pageSize });
    setLoading(false);
    setRefresh(false);
  };

  useEffect(() => {
    init();
  }, []);
  useEffect(() => {
    if (refresh) {
      refetch();
    }
  }, [refresh]);

  const handlePageSize = async (e) => {
    setLoading(true);
    setSize(e.target.value);
    setPage(0);
    await getJobsList({ page: 0, page_size: e.target.value });
    setLoading(false);
  };
  const handlePagination = async (e, page) => {
    setLoading(true);
    setPage(page - 1);
    await getJobsList({ page: page - 1, page_size: size });
    setLoading(false);
  };
  return !loading ? (
    <>
      <Container>
        <AddBtnWrapper>
          <AddBoxOutlined
            onClick={() => {
              setOpenImport(true);
            }}
            style={{
              width: "40px",
              height: "40px",
            }}
          />
        </AddBtnWrapper>

        <Wrapper>
          <DetailsWrapper>
            <TableWrapper>
              {row.length > 0 ? (
                <TableContainer
                  component={Paper}
                  sx={{
                    width: "100%",
                    display: "table",
                    tableLayout: "fixed",
                  }}
                >
                  <Table size="small" aria-label="a dense table">
                    <TableHead
                      style={{ backgroundColor: "#EFEFF4", height: "40px" }}
                    >
                      <TableRow>
                        {JobListColumns.map((e) => {
                          return (
                            <TableCell className="textContainer">
                              {e.headerName}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.map((e, i) => {
                        return (
                          <JobRowComponent
                            statusList={props.statusList}
                            pid={props.id}
                            item={e}
                            columns={ImportHistoryColumns}
                            idx={i}
                          />
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <>
                  <TableContainer
                    component={Paper}
                    style={{ width: "100%", overflowX: "auto" }}
                  >
                    <Table size="small" aria-label="a dense table">
                      <TableHead
                        style={{ backgroundColor: "#EFEFF4", height: "40px" }}
                      >
                        <TableRow>
                          {JobListColumns.map((e) => {
                            return (
                              <TableCell className="textContainer">
                                {e.headerName}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody></TableBody>
                    </Table>
                  </TableContainer>
                  <EmptyTableWrapper>
                    <EmptyText>No rows</EmptyText>
                  </EmptyTableWrapper>
                </>
              )}
            </TableWrapper>
            <PaginationWrapper>
              <PaginationComponent
                page_no={page}
                row={row}
                page_size={size}
                size={size}
                count={count}
                handlePageSize={handlePageSize}
                handlePage={handlePagination}
              />
            </PaginationWrapper>
          </DetailsWrapper>
        </Wrapper>
      </Container>
      {openImport && (
        <CreateJobModal
          id={props.id}
          open={openImport}
          setOpen={setOpenImport}
          refresh={refresh}
          setRefresh={setRefresh}
          statusList={props.statusList}
          setShowDeleteProtectedAlert={setShowDeleteProtectedAlert}
        />
      )}
      {showDeleteProtectedAlert && (
        <CustomMessageDialog
          open={showDeleteProtectedAlert}
          setOpen={setShowDeleteProtectedAlert}
          message={
            "Leads are delete-protected. Unprotect or remove them to proceed with deletion."
          }
        />
      )}
    </>
  ) : (
    <TableWrapper
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </TableWrapper>
  );
};

export default observer(JobsTab);
