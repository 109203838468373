import { styled } from "@mui/system";
import { Typography, Box } from "@mui/material";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(2),
  },
}));

const StyledCaption = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  variant: "caption",
  component: "span",
  color: "#424343",

  [[theme.breakpoints.between("xs", "sm")]]: {
    font: "normal normal 400 8px open sans",
  },
  [[theme.breakpoints.between("sm", "md")]]: {
    font: "normal normal 400 10px open sans",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    font: "normal normal 400 12px open sans",
  },
  [theme.breakpoints.up("lg")]: {
    font: "normal normal 400 16px open sans",
  },
}));

const FooterComponent = ({ contentState }) => {
  return (
    <Container>
      <StyledCaption>
        {contentState.footer_text}
        {contentState.pp_link !== "" ? (
          <a href={contentState.pp_link} target="_blank">
            Privacy Policy
          </a>
        ) : (
          "Privacy Policy"
        )}
      </StyledCaption>
    </Container>
  );
};

export default FooterComponent;
