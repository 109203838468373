import React from "react";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import NumberRangeWidget from "./components/NumberRangeWidget";
import TextFieldWidget from "./components/TextFieldWidget";
import MultiInputWidget from "./components/MultiInputWidget";
import DatePickerWidget from "./components/DatePickerWidget";
import DateTimePickerWidget from "./components/DateTimePickerWidget";
import DateRangePickerWidget from "./components/DateRangePickerWidget";
import DateTimeRangePickerWidget from "./components/DateTimeRangePickerWidget";

const ValueWidget = ({
  inputValue,
  setInputValue,
  operator,
  columnMeta,
  setIsSubmitFail,
}) => {
  const handleChange = ({ val }) => {
    setInputValue({
      ...inputValue,
      [operator]: { value: val, data_type: columnMeta["data_type"] },
    });
    setIsSubmitFail(false);
  };

  useEffect(() => {}, [operator]);

  if (columnMeta["data_type"] === "string") {
    if (
      operator === "equals" ||
      operator === "not equals" ||
      operator === "contains" ||
      operator === "not contains" ||
      operator === "starts with"
    ) {
      return (
        <TextFieldWidget
          value={
            inputValue.hasOwnProperty(operator)
              ? inputValue[operator]["value"]
              : ""
          }
          handleChange={(value) => {
            handleChange({ val: value });
          }}
          type={"text"}
        />
      );
    } else if (operator === "in" || operator === "not in") {
      return (
        <MultiInputWidget
          type={"text"}
          value={
            inputValue.hasOwnProperty(operator)
              ? inputValue[operator]["value"]
              : []
          }
          handleAdd={(value) => {
            let tempList = inputValue.hasOwnProperty(operator)
              ? [...inputValue[operator]["value"]]
              : [];
            if (!tempList.includes(value)) {
              tempList.push(value);
            }
            handleChange({ val: tempList });
          }}
          handleDelete={(value) => {
            let tempList = inputValue.hasOwnProperty(operator)
              ? [...inputValue[operator]["value"]]
              : [];

            tempList = tempList.filter((str) => str !== value);
            handleChange({ val: tempList });
          }}
        />
      );
    }
  } else if (columnMeta["data_type"] === "integer") {
    if (operator === "equals" || operator === "not equals") {
      return (
        <TextFieldWidget
          value={
            inputValue.hasOwnProperty(operator)
              ? inputValue[operator]["value"]
              : ""
          }
          handleChange={(value) => {
            handleChange({ val: value });
          }}
          type={"number"}
        />
      );
    } else if (operator === "between" || operator === "not between") {
      return (
        <NumberRangeWidget
          minValue={
            inputValue.hasOwnProperty(operator)
              ? inputValue[operator]["value"]["start"]
              : ""
          }
          maxValue={
            inputValue.hasOwnProperty(operator)
              ? inputValue[operator]["value"]["end"]
              : ""
          }
          handleMinValue={(event) => {
            let start = parseInt(event.target.value);
            let end =
              inputValue.hasOwnProperty(operator) &&
              inputValue[operator]["value"]["end"] !== ""
                ? parseInt(inputValue[operator]["value"]["end"])
                : "";
            setInputValue({
              ...inputValue,
              [operator]: {
                value: { start: start, end },
                data_type: columnMeta["data_type"],
              },
            });
            setIsSubmitFail(false);
          }}
          disabled={
            !inputValue.hasOwnProperty(operator) ||
            (inputValue.hasOwnProperty(operator) &&
              inputValue[operator]["value"]["start"] === "")
          }
          handleMaxValue={(event) => {
            let start =
              inputValue.hasOwnProperty(operator) &&
              inputValue[operator]["value"]["start"] !== ""
                ? parseInt(inputValue[operator]["value"]["start"])
                : "";
            let end = parseInt(event.target.value);
            setInputValue({
              ...inputValue,
              [operator]: {
                value: { start: start, end: end },
                data_type: columnMeta["data_type"],
              },
            });
            setIsSubmitFail(false);
          }}
        />
      );
    } else if (operator === "in" || operator === "not in") {
      return (
        <MultiInputWidget
          type={"number"}
          value={
            inputValue.hasOwnProperty(operator)
              ? inputValue[operator]["value"]
              : []
          }
          handleAdd={(value) => {
            let tempList = inputValue.hasOwnProperty(operator)
              ? [...inputValue[operator]["value"]]
              : [];
            if (!tempList.includes(value)) {
              tempList.push(value);
            }
            handleChange({ val: tempList });
          }}
          handleDelete={(value) => {
            let tempList = inputValue.hasOwnProperty(operator)
              ? [...inputValue[operator]["value"]]
              : [];

            tempList = tempList.filter((str) => str !== value);
            handleChange({ val: tempList });
          }}
        />
      );
    }
  } else if (columnMeta["data_type"] === "date") {
    if (operator === "equals" || operator === "not equals") {
      return (
        <DatePickerWidget
          value={
            inputValue.hasOwnProperty(operator)
              ? inputValue[operator]["value"]
              : null
          }
          handleChange={(event) => handleChange({ val: event })}
        />
      );
    } else {
      return (
        <DateRangePickerWidget
          maxValue={
            inputValue.hasOwnProperty(operator)
              ? inputValue[operator]["value"]["end"]
              : null
          }
          minValue={
            inputValue.hasOwnProperty(operator)
              ? inputValue[operator]["value"]["start"]
              : null
          }
          handleStartDate={(event) => {
            let end = inputValue.hasOwnProperty(operator)
              ? inputValue[operator]["value"]["end"]
              : null;
            let tempObj = { end: end };
            tempObj["start"] = event;

            setInputValue({
              ...inputValue,
              [operator]: {
                value: tempObj,
                data_type: columnMeta["data_type"],
              },
            });
          }}
          handleEndDate={(event) => {
            let start = inputValue.hasOwnProperty(operator)
              ? inputValue[operator]["value"]["start"]
              : null;
            let tempObj = { start: start };
            tempObj["end"] = event;
            setInputValue({
              ...inputValue,
              [operator]: {
                value: tempObj,
                data_type: columnMeta["data_type"],
              },
            });
          }}
          disabled={!inputValue.hasOwnProperty(operator)}
        />
      );
    }
  } else if (columnMeta["data_type"] === "datetime") {
    if (operator === "equals" || operator === "not equals") {
      return (
        <DateTimePickerWidget
          value={
            inputValue.hasOwnProperty(operator)
              ? inputValue[operator]["value"]
              : null
          }
          handleChange={(event) => handleChange({ val: event })}
        />
      );
    } else {
      return (
        <DateTimeRangePickerWidget
          maxValue={
            inputValue.hasOwnProperty(operator)
              ? inputValue[operator]["value"]["end"]
              : null
          }
          minValue={
            inputValue.hasOwnProperty(operator)
              ? inputValue[operator]["value"]["start"]
              : null
          }
          handleMinDatetime={(event) => {
            let end = inputValue.hasOwnProperty(operator)
              ? inputValue[operator]["value"]["end"]
              : null;
            let tempObj = { end: end };
            tempObj["start"] = event;

            setInputValue({
              ...inputValue,
              [operator]: {
                value: tempObj,
                data_type: columnMeta["data_type"],
              },
            });
          }}
          handleMaxDatetime={(event) => {
            let start = inputValue.hasOwnProperty(operator)
              ? inputValue[operator]["value"]["start"]
              : null;
            let tempObj = { start: start };
            tempObj["end"] = event;
            setInputValue({
              ...inputValue,
              [operator]: {
                value: tempObj,
                data_type: columnMeta["data_type"],
              },
            });
          }}
          disabled={!inputValue.hasOwnProperty(operator)}
        />
      );
    }
  }
};

export default observer(ValueWidget);
