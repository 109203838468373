import React, { useEffect, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { ChevronRight, Search, Visibility } from "@material-ui/icons";
import { DataGrid } from "@mui/x-data-grid";
import { getAdminProjectApi } from "../../Api";
import spinner from "../../assets/images/gifs/spinner.gif";
import { ProjectSettingColumns } from "../../Db";
const Spinner = styled.img`
  height: 120px;
  width: 180px;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;
const ParentContainer = styled.div`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled.div`
  ${`min-height: calc(100vh - 84px);`};

  margin: auto;
  padding-top: 70px;
  width: 95%;
`;

const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
`;
const Header = styled.p`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: #efeff4;
  height: 40px;
  width: 100%;
  padding: 8px 20px;
  border-radius: 8px;
`;
const TableWrapper = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
  background-color: white;
  padding: 0px 20px;
`;
const SearchInput = styled("input")(({ theme }) => ({
  height: "40px",
  border: "none",
  outline: "none",
  width: "100%",
  color: "#4D4E4F",
  font: "normal normal 500 14px Open Sans",
  padding: "2px",
  backgroundColor: "#efeff4",
  borderRadius: "8px",
  "&::placeholder": {
    fontSize: "14px",
    color: "#2B2B2B",
  },
}));
const TopBar = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  padding: 20px;
`;
const Text = styled.p`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;
const ProjectSetting = (props) => {
  const [row, setRow] = useState();
  const [query, setQuery] = useState("");
  let navigate = useNavigate();
  useEffect(() => {
    props.setShowBar(true);
    const getProjectList = async () => {
      try {
        let response = await getAdminProjectApi();
        setRow(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getProjectList();
  }, []);

  const actionColumn = [
    {
      field: "action",
      headerName: <Text>Actions</Text>,
      sortable: false,
      width: 90,
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <Visibility
              style={{ marginRight: "12px", width: "18px", height: "18px" }}
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/project-detail/${params.row.name}/${params.id}/`);
              }}
            />
          </>
        );
      },
    },
  ];
  return (
    <>
      <ParentContainer>
        <Container>
          <TopWrapper>
            <Header>Admin</Header>
            <ChevronRight style={{ width: "16px", height: "16px" }} />
            <Header>Projects</Header>
          </TopWrapper>
          <TopBar>
            <SearchWrapper>
              <Search
                style={{
                  width: "22px",
                  height: "22px",
                  color: "#4D4E4F",
                  marginRight: "4px",
                }}
              />
              <SearchInput
                placeholder="Search"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </SearchWrapper>
            {/* <Button
              onClick={() => {
                setOpen(true);
              }}
              variant="contained"
              color="primary"
              style={{
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                width: "160px",
                marginLeft: "20px",
                height: "40px",
              }}
            >
              <Add style={{ width: "18px", height: "18px" }} />
              Add Project
            </Button> */}
          </TopBar>
          <TableWrapper>
            {row ? (
              <DataGrid
                autoHeight={true}
                rows={row.filter((val) => {
                  if (query === "") {
                    return val;
                  } else if (
                    val.name.toLowerCase().includes(query.toLowerCase())
                  ) {
                    return val;
                  }
                })}
                columns={ProjectSettingColumns.concat(actionColumn)}
                checkboxSelection={false}
                style={{ cursor: "pointer" }}
                hideFooter={true}
                disableColumnMenu={true}
                onRowClick={(e) => {
                  navigate(`/project-detail/${e.row.name}/${e.id}/`);
                }}
              />
            ) : (
              <SpinnerWrapper>
                <Spinner src={spinner} alt="loading..." />
              </SpinnerWrapper>
            )}
          </TableWrapper>
        </Container>
      </ParentContainer>
      <Footer />
    </>
  );
};

export default observer(ProjectSetting);
