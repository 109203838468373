import { AppBar, Box, Divider, Modal, Toolbar } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { dataRenderer } from "../../../../Functions";
import rootStore from "../../../../stores/RootStore";
import { Close } from "@material-ui/icons";
import ColumnMappingDialog from "../ColumnMappingDialog";
import {
  getFacebookPageAllFormData,
  getFacebookPageConfiguredFormData,
} from "../../../../Api";
const dialogWidth = 600;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
`;

const ViewPageConfigDialog = ({ open, setOpen, pageID }) => {
  const [selectedFormData, setSelectedFormData] = useState("");
  const [openColumnMappingDialog, setOpenColumnMappingDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const columns = [
    {
      field: "sl_no",
      headerName: "S.NO",
      width: 70,

      valueGetter: "node.rowIndex + 1",
    },
    {
      field: "name",
      headerName: "FORM NAME",
      flex: 1,

      cellRenderer: (params) => {
        let value = dataRenderer(params.data.name);
        return (
          <Tooltip title={value} placement="bottom-start">
            <span style={{ whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
              {value}
            </span>
          </Tooltip>
        );
      },
    },

    {
      field: "actions",
      headerName: "ACTIONS",
      width: 100,

      cellRenderer: (params) => {
        if (params.data.is_connected) {
          return (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Button
                onClick={() => {
                  setSelectedFormData(params.data);
                  setOpenColumnMappingDialog(true);
                }}
                variant="contained"
                color="primary"
                sx={{
                  textTransform: "none",
                  fontSize: "10px",
                }}
              >
                Configure
              </Button>
            </Box>
          );
        } else {
          return (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Button
                onClick={() => {
                  setSelectedFormData(params.data);
                  setOpenColumnMappingDialog(true);
                }}
                variant="outlined"
                color="primary"
                sx={{
                  textTransform: "none",
                  fontSize: "10px",
                }}
              >
                Connect
              </Button>
            </Box>
          );
        }
      },
    },
  ];

  const getFacebookPageList = async () => {
    let response = await getFacebookPageAllFormData({ pageID: pageID });
    let configured_forms_response = await getFacebookPageConfiguredFormData({
      pageID: pageID,
    });
    if (configured_forms_response.length > 0) {
      response = response.map((entry1) => {
        let index = configured_forms_response.findIndex(
          (entry2) => entry2["id"] === entry1["id"]
        );
        if (index !== -1) {
          entry1 = { ...configured_forms_response[index], is_connected: true };
        } else {
          entry1["is_connected"] = false;
        }
        return entry1;
      });
    } else {
      response = response.map((entry) => {
        entry["is_connected"] = false;
        return entry;
      });
    }
    setData(response);
  };

  const init = async () => {
    await getFacebookPageList();
    setLoading(false);
  };

  const refetch = async () => {
    await getFacebookPageList();
    setRefresh(false);
  };

  useEffect(() => {
    if (refresh) {
      refetch();
    }
  }, [refresh]);

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Modal
        disableEnforceFocus
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <AppBar
              component={"nav"}
              elevation={0}
              position="sticky"
              color="inherit"
              sx={{
                top: 0,
                bottom: "auto",
                width: "100%",
                right: 0,
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                }}
              >
                <HeaderWrapper>
                  <Header>FB Form configuration</Header>

                  <IconButton
                    style={{ padding: "0px" }}
                    color="primary"
                    onClick={handleClose}
                  >
                    <Close />
                  </IconButton>
                </HeaderWrapper>
              </Toolbar>
            </AppBar>
            <Divider style={{ margin: "0px 16px" }} />
            <Container>
              {!loading ? (
                <Box
                  style={{
                    height: "300px",
                    overflowY: "auto",
                  }}
                >
                  <div className="ag-theme-alpine" style={{ width: "100%" }}>
                    <AgGridReact
                      domLayout="autoHeight"
                      gridOptions={gridOptions}
                      rowData={data}
                      defaultColDef={defaultColDef}
                      columnDefs={columns}
                      animateRows={true}
                      suppressCellFocus
                    />
                  </div>
                </Box>
              ) : (
                <Box
                  style={{
                    height: "300px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
      {openColumnMappingDialog && (
        <ColumnMappingDialog
          open={openColumnMappingDialog}
          projectID={rootStore.authStore.projectId}
          setOpen={setOpenColumnMappingDialog}
          setRefresh={setRefresh}
          formData={selectedFormData}
          pageID={pageID}
        />
      )}
    </>
  );
};

export default ViewPageConfigDialog;
