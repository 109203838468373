import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  AppBar,
  Box,
  Toolbar,
  Typography,
  IconButton,
  MenuItem,
  Menu,
} from "@mui/material";
import { Grid } from "@material-ui/core";
import { IsoToLocalDate, dataRenderer } from "../../Functions";
import TableComponent from "../add_quote_sidepanel/view_quote_sidepanel/table_component/TableComponent";
import { Edit } from "@material-ui/icons";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PaymentsLogsDialog from "./screens/PaymentsLogsDialog";

const ModelWrapper = styled(Box)`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 24px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 8px;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Header = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #a9a9a9;
  margin-bottom: 8px;
`;

const DetailWrapper = styled(Box)`
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;
const DetailLabel = styled(Typography)`
  color: #a9a9a9;
  font: normal normal normal 12px Open Sans;
  width: 150px;
`;
const SwitchAndTextWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ViewInvoiceSidepanel = ({
  scope,
  details,
  onClickCallback,
  setOpenRecordPayment,
  isRecordPaymentDisabled,
  isInvoiceEditDisabled,
}) => {
  const [openPayments, setOpenPayments] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const multilineTextGetter = (raw_string) => {
    return raw_string.replace(/\r\n|\n/g, "\n");
  };
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ width: 500 }}>
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: 500,
            right: 0,
            bgcolor: "#f9f9fc",
          }}
        >
          <Toolbar>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <ModelHeader>Invoice Details</ModelHeader>
              {scope === "lead" && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: "8px",
                  }}
                >
                  <Button
                    onClick={() => {
                      onClickCallback(details);
                    }}
                    disabled={isInvoiceEditDisabled(details.id)}
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={{
                      textTransform: "none",
                      fontSize: "14px",
                      fontWeight: "bold",
                      width: "fit-content",
                    }}
                  >
                    <Edit
                      style={{
                        fontSize: "16px",
                        marginRight: "4px",
                      }}
                    />
                    Edit
                  </Button>
                  <Button
                    onClick={() => {
                      setOpenRecordPayment(details["id"]);
                    }}
                    disabled={isRecordPaymentDisabled(details.id)}
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={{
                      textTransform: "none",
                      fontSize: "14px",
                      fontWeight: "bold",
                      width: "fit-content",
                    }}
                  >
                    <DownloadForOfflineOutlinedIcon
                      style={{
                        fontSize: "16px",
                        marginRight: "4px",
                      }}
                    />
                    Record Payment
                  </Button>
                  <IconButton onClick={(event) => handleMenuClick(event)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    style={{ display: "flex", flexDirection: "column" }}
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose}
                    PaperProps={{
                      style: {
                        width: "fit-content",
                      },
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        setOpenPayments(true);
                        handleMenuClose();
                      }}
                    >
                      Payments
                    </MenuItem>
                  </Menu>
                </Box>
              )}
              {scope === "invoices" && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: "8px",
                  }}
                >
                  <Button
                    onClick={() => {
                      setOpenPayments(true);
                    }}
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={{
                      textTransform: "none",
                      fontSize: "14px",
                      fontWeight: "bold",
                      width: "fit-content",
                    }}
                  >
                    Payments
                  </Button>
                </Box>
              )}
            </Box>
          </Toolbar>
        </AppBar>
        <ModelWrapper>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputWrapper>
                <Label>Title</Label>
                <Detail>{dataRenderer(details["schema"].invoice_title)}</Detail>
              </InputWrapper>
            </Grid>
            <Grid item xs={6}>
              <InputWrapper>
                <Label>Invoice</Label>
                <Detail>{dataRenderer(details.invoice_number)}</Detail>
              </InputWrapper>
            </Grid>
            <Grid item xs={6}>
              <InputWrapper>
                <Label>Invoice Date</Label>
                <Detail>
                  {details.invoice_date !== null
                    ? IsoToLocalDate(details.invoice_date)
                    : "-"}
                </Detail>
              </InputWrapper>
            </Grid>
            <Grid item xs={6}>
              <InputWrapper>
                <Label>Expiry Date</Label>
                <Detail>
                  {details.expiry_date !== null
                    ? IsoToLocalDate(details.expiry_date)
                    : "-"}
                </Detail>
              </InputWrapper>
            </Grid>
            <Grid item xs={6}>
              <InputWrapper>
                <Label>Place of Supply</Label>
                <Detail>
                  {details.schema.place_of_supply !== null
                    ? dataRenderer(details.schema.place_of_supply)
                    : "-"}
                </Detail>
              </InputWrapper>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Header>Products or Services</Header>
                <TableComponent
                  isReadOnly={false}
                  tableData={
                    details["schema"]["items"] !== null &&
                    details["schema"]["items"].length > 0
                      ? details["schema"]["items"]
                      : []
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  gap: "8px",
                }}
              >
                <DetailWrapper>
                  <DetailLabel>Sub Total (INR) :</DetailLabel>
                  <Detail>
                    {dataRenderer(details["schema"].amount_details.sub_total)}
                  </Detail>
                </DetailWrapper>

                <DetailWrapper>
                  <SwitchAndTextWrapper>
                    <DetailLabel>Additonal Discount (INR) :</DetailLabel>
                  </SwitchAndTextWrapper>
                  <Detail>
                    {dataRenderer(
                      details["schema"].amount_details.additional_discount
                    )}
                  </Detail>
                </DetailWrapper>

                <DetailWrapper>
                  <DetailLabel>Additonal Tax (INR) :</DetailLabel>
                  <Detail>
                    {dataRenderer(
                      details["schema"].amount_details.additional_tax
                    )}
                  </Detail>
                </DetailWrapper>

                <DetailWrapper>
                  <DetailLabel>Adjustments (INR) :</DetailLabel>
                  <Detail>
                    {dataRenderer(details["schema"].amount_details.adjustments)}
                  </Detail>
                </DetailWrapper>

                <DetailWrapper>
                  <DetailLabel>Grand Total (INR) :</DetailLabel>
                  <Detail>
                    {dataRenderer(details["schema"].amount_details.grand_total)}
                  </Detail>
                </DetailWrapper>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Header>Billing Details</Header>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Billing Name</Label>
                      <Detail>
                        {dataRenderer(
                          details["schema"].billing_details.billing_name
                        )}
                      </Detail>
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Billing Address</Label>
                      <Detail
                        component="div"
                        style={{
                          whiteSpace: "pre-line",
                        }}
                      >
                        {multilineTextGetter(
                          dataRenderer(
                            details["schema"].billing_details.billing_address
                          )
                        )}
                      </Detail>
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>Billing City</Label>
                      <Detail>
                        {dataRenderer(
                          details["schema"].billing_details.billing_city
                        )}
                      </Detail>
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>Billing State</Label>
                      <Detail>
                        {dataRenderer(
                          details["schema"].billing_details.billing_state
                        )}
                      </Detail>
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>Billing Country</Label>
                      <Detail>
                        {dataRenderer(
                          details["schema"].billing_details.billing_country
                        )}
                      </Detail>
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>Billing Zipcode</Label>
                      <Detail>
                        {dataRenderer(
                          details["schema"].billing_details.billing_zipcode
                        )}
                      </Detail>
                    </InputWrapper>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Header>Shipping Details</Header>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Shipping Name</Label>
                      <Detail>
                        {dataRenderer(
                          details["schema"].billing_details.shipping_name
                        )}
                      </Detail>
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Shipping Address</Label>
                      <Detail
                        component="div"
                        style={{
                          whiteSpace: "pre-line",
                        }}
                      >
                        {multilineTextGetter(
                          dataRenderer(
                            details["schema"].billing_details.shipping_address
                          )
                        )}
                      </Detail>
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>Shipping City</Label>
                      <Detail>
                        {dataRenderer(
                          details["schema"].billing_details.shipping_city
                        )}
                      </Detail>
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>Shipping State</Label>
                      <Detail>
                        {dataRenderer(
                          details["schema"].billing_details.shipping_state
                        )}
                      </Detail>
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>Shipping Country</Label>
                      <Detail>
                        {dataRenderer(
                          details["schema"].billing_details.shipping_country
                        )}
                      </Detail>
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>Shipping Zipcode</Label>
                      <Detail>
                        {dataRenderer(
                          details["schema"].billing_details.shipping_zipcode
                        )}
                      </Detail>
                    </InputWrapper>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Header>Additional Details</Header>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Terms and Conditions</Label>
                      <Detail
                        component="div"
                        style={{
                          whiteSpace: "pre-line",
                        }}
                      >
                        {multilineTextGetter(
                          dataRenderer(
                            details["schema"].additional_details
                              .terms_and_conditions
                          )
                        )}
                      </Detail>
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Additional Information</Label>
                      <Detail
                        component="div"
                        style={{
                          whiteSpace: "pre-line",
                        }}
                      >
                        {multilineTextGetter(
                          dataRenderer(
                            details["schema"].additional_details
                              .additional_information
                          )
                        )}
                      </Detail>
                    </InputWrapper>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </ModelWrapper>
      </Box>
      {openPayments && (
        <PaymentsLogsDialog
          open={openPayments}
          setOpen={setOpenPayments}
          leadID={details["lead"]["id"]}
          invoiceID={details["id"]}
        />
      )}
    </>
  );
};

export default ViewInvoiceSidepanel;
