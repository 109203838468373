import React, { useEffect } from "react";
import { useState } from "react";
import { Box } from "@material-ui/core";
import {
  getAdminProjectDataApi,
  getProjectAdminWorkTimingTemplateListApi,
} from "../../Api";
import { IsoToLocalTime } from "../../Functions";
import { Edit } from "@material-ui/icons";
import AddProjectModal from "../admin_setting_modals/AddProjectModal";
import EditWorkSchedule from "../admin_setting_modals/EditWorkSchedule";
import rootStore from "../../stores/RootStore";
import analytics from "../../Analytics";
import { styled, Typography } from "@mui/material";
import ProjectWorkTimingWidget from "./project_setting_components/ProjectWorkTimingWidget";
import UpdateProjectWorkTimingTemplateDialog from "./project_setting_components/UpdateProjectWorkTimingTemplateDialog";
import LoadingAnimationComponent from "../loading_component/LoadingAnimationComponent";

const Container = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 258px);`};

  position: relative;
`;
const Wrapper = styled(Box)`
  display: flex;
`;

const InfoWrapper = styled(Box)`
  width: 200px;
  height: fit-content;
  margin-bottom: 12px;
`;
const SectionWrapper = styled(Box)`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  background-color: #f9f9fc;
  padding: 20px;
`;
const Label = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  margin-bottom: 8px;
  color: #979799;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;
const Row = styled(Box)`
  display: flex;
  flex-wrap: wrap;
`;
const SectionsContainer = styled(Box)`
  width: 100%;
  background-color: white;
  border-radius: 10px;
  display: flex;
  height: 100%;
  column-gap: 20px;
  row-gap: 20px;
`;
const DetailsHeader = styled(Typography)`
  font: normal normal 600 16px Open Sans;
  color: #979797;
  text-transform: uppercase;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  column-gap: 20px;
  margin-bottom: 20px;
`;

const ProjectSettingTab = (props) => {
  const { userStore, authStore } = rootStore;
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [editWork, setEditWork] = useState(false);
  const [loading, setLoading] = useState(true);
  const [workTimingTemplates, setWorkTimingTemplates] = useState([]);
  const [selectedTemplateData, setSelectedTemplateData] = useState({});
  const [openUpdateTemplateDialog, setOpenUpdateTemplateDialog] =
    useState(false);

  const getProjectData = async () => {
    try {
      let response = await getAdminProjectDataApi(props.id);
      setData(response.data);
      if (
        response.data.hasOwnProperty("work_timings") &&
        response.data["work_timings"] !== null
      ) {
        setSelectedTemplateData(response.data["work_timings"]);
      } else {
        setSelectedTemplateData({});
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getWorkTimingTemplateList = async () => {
    let response = await getProjectAdminWorkTimingTemplateListApi({
      projectID: rootStore.authStore.projectId,
    });
    setWorkTimingTemplates(response);
  };

  const init = async () => {
    if (canEditDetails()) {
      await getProjectData();
    }
    await getWorkTimingTemplateList();
    setLoading(false);
  };
  const refetch = async () => {
    await getProjectData();
    setRefresh(false);
  };
  useEffect(() => {
    if (refresh) {
      refetch();
    }
  }, [refresh]);
  useEffect(() => {
    init();
  }, []);

  const canEditDetails = () => {
    let account_role =
      userStore.UserData !== null ? userStore.UserData.role : "";
    let project_role = authStore.getCurrentProjectRole();
    if (account_role === "owner") {
      return true;
    } else {
      if (project_role !== "member" && project_role !== "") {
        return true;
      }
      return false;
    }
  };

  return !loading ? (
    <>
      <Container>
        <Wrapper>
          <SectionsContainer>
            <SectionWrapper>
              <HeaderWrapper>
                <DetailsHeader>{`BASIC INFORMATION`}</DetailsHeader>
                {canEditDetails() && (
                  <Edit
                    color="primary"
                    style={{
                      cursor: "pointer",
                      height: "20px",
                      width: "20px",
                    }}
                    onClick={() => {
                      analytics.triggerEvent(
                        4625000168,
                        "edit_details_button_tap",
                        "project_setting_page",
                        "edit_details_button",
                        {}
                      );
                      setIsEdit(true);
                      setOpen(true);
                    }}
                  />
                )}
              </HeaderWrapper>
              <Row style={{ flexWrap: "wrap", rowGap: "20px" }}>
                <InfoWrapper>
                  <Label>Name</Label>
                  <Detail>{data !== null ? data["name"] : "-"}</Detail>
                </InfoWrapper>
                <InfoWrapper>
                  <Label>Created At</Label>
                  <Detail>
                    {data !== null ? IsoToLocalTime(data["created_at"]) : "-"}
                  </Detail>
                </InfoWrapper>
                <InfoWrapper>
                  <Label>Date Format</Label>
                  <Detail>{data !== null ? data["date_format"] : "-"}</Detail>
                </InfoWrapper>
                <InfoWrapper>
                  <Label>Dialer</Label>
                  <Detail>
                    {data !== null ? data["external_dialer"] : "-"}
                  </Detail>
                </InfoWrapper>
                <InfoWrapper>
                  <Label>Calling Mode</Label>
                  <Detail>{data !== null ? data["calling_mode"] : "-"}</Detail>
                </InfoWrapper>
              </Row>
            </SectionWrapper>

            <SectionWrapper>
              <DetailsHeader style={{ marginBottom: "20px" }}>
                Work Days and Timings
              </DetailsHeader>

              <ProjectWorkTimingWidget
                details={selectedTemplateData}
                canShowEdit={workTimingTemplates.length > 0 && canEditDetails()}
                setOpenUpdateTemplateDialog={setOpenUpdateTemplateDialog}
              />
            </SectionWrapper>
          </SectionsContainer>
        </Wrapper>
      </Container>
      {open && (
        <AddProjectModal
          setOpen={setOpen}
          open={open}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          editableData={data}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}
      {editWork && (
        <EditWorkSchedule
          setOpen={setEditWork}
          open={editWork}
          data={data}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}

      {openUpdateTemplateDialog && (
        <UpdateProjectWorkTimingTemplateDialog
          open={openUpdateTemplateDialog}
          setOpen={setOpenUpdateTemplateDialog}
          setRefresh={setRefresh}
          templateData={selectedTemplateData}
          templateList={workTimingTemplates}
        />
      )}
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "calc(100vh - 258px)",
      }}
    >
      <LoadingAnimationComponent size={"small"} />
    </Box>
  );
};

export default ProjectSettingTab;
