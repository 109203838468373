// import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import analytics from "../../Analytics";

const Label = styled(Typography)`
  font: normal normal 600 14px Open Sans;
`;

const DeleteIndividualLeadConfirmationDialog = ({
  setOpen,
  open,
  handleConfirm,
  isDeleteProtected,
}) => {
  const [conditionChecked, setConditionChecked] = useState(false);

  useEffect(() => {
    analytics.triggerEvent(
      4625000253,
      "delete_lead_confirmation_form_load",
      "lead_details_page",
      "",
      {}
    );
  }, []);

  return (
    <Dialog
      disableEnforceFocus
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ fontWeight: "bold" }}>
        Confirmation: Delete lead
      </DialogTitle>

      <DialogContent>
        <FormControlLabel
          style={{ alignItems: "flex-start" }}
          control={
            <Checkbox
              style={{ paddingTop: "0px" }}
              checked={conditionChecked}
              onChange={(e) => {
                setConditionChecked(e.target.checked);
              }}
            />
          }
          label={
            isDeleteProtected() ? (
              <Label>
                This lead is delete protected. Please check this box to confirm
                and proceed with the deletion.
              </Label>
            ) : (
              <Label>
                This action is irreversible. Do you want to continue?
              </Label>
            )
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          style={{ textTransform: "capitalize", fontWeight: "bold" }}
          onClick={() => {
            analytics.triggerEvent(
              4625000254,
              "cancel_button_tap",
              "delete_lead_confirmation_form",
              "cancel_button",
              {}
            );
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          style={{ textTransform: "capitalize", fontWeight: "bold" }}
          onClick={() => {
            analytics.triggerEvent(
              4625000255,
              "confirm_button_tap",
              "delete_lead_confirmation_form",
              "confirm_button",
              {}
            );
            handleConfirm();
          }}
          autoFocus
          disabled={!conditionChecked}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteIndividualLeadConfirmationDialog;
