import React from "react";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { Box } from "@material-ui/core";
import { Typography } from "@mui/material";
import { dataRenderer } from "../../Functions";
const Card = styled(Box)`
  display: flex;
  padding: 16px;
  width: 100%;
  height: 160px;
  background-color: white;
  border-radius: 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;
const TextLg = styled(Typography)`
  margin: 0px;
  font: normal normal 600 20px Open Sans;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #4d4e4f;
  width: 100%;
`;

const TextMd = styled(Typography)`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #c4c4c2;
`;

const AttendanceSummaryCardWidget = ({ data }) => {
  return (
    <Card
      style={{
        height: "100%",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          justifyContent: "space-between",
          gap: "8px",
        }}
      >
        <TextMd>{data["title"]}</TextMd>
        <TextLg>{dataRenderer(data["value"])}</TextLg>
      </Box>
    </Card>
  );
};

export default observer(AttendanceSummaryCardWidget);
