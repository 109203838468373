import { Close } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Footer from "../../components/landing_page_components/footer/Footer";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { Box, Button, IconButton, Typography, styled } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import analytics from "../../Analytics";
import { dataRenderer, getIsoString, hasReportAccess } from "../../Functions";
import { Download, FilterAlt } from "@mui/icons-material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import CsvDownloader from "react-csv-downloader";
import NoAccessPermissionPage from "../no_access_page/NoAccessPermissionPage";
import AggregateReport from "../../components/report_page_components/AggregateReport";
import PaginatedNonAggregateReport from "../../components/report_page_components/PaginatedNonAggregateReport";
import { getPredefinedReportDetailsApi } from "../../Api";
import CustomLoadingDialog from "../../components/alert_dialogue/CustomLoadingDialog";
import FilterReportSidepanel from "../../components/report_page_components/FilterReportSidepanel";
import { Drawer } from "@material-ui/core";
import moment from "moment";
const Container = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Wrapper = styled(Box)`
  ${`min-height: calc(100vh - 75px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 28px Open Sans;
  color: #4d4e4f;
`;
const ReportDetailsPage = () => {
  const { authStore } = rootStore;
  const { id } = useParams();
  const storedColumnsList = [...rootStore.userStore.AllColumnsList];
  const [columnsList, setColumnsList] = useState([]);
  const [row, setRow] = useState([]);
  const [columnsForDatatable, setColumnsForDatatable] = useState([]);
  const [columns, setColumns] = useState([]);
  let navigate = useNavigate();
  const location = useLocation();
  const receivedProps = location.state;
  const [openReportDateFilter, setOpenReportDateFilter] = useState(false);
  const [reportFilters, setReportFilters] = useState([]);
  const [refresh, setRefresh] = useState(false);

  //state variables related aggregate report
  const [lastRowData, setLastRowData] = useState([]);

  //state variables related non aggregate report
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [canDownloadNonAggregateReport, setCanDownloadNonAggregateReport] =
    useState(false);
  const [generatedNonAggregateReportData, setGeneratedNonAggregateReportData] =
    useState([]);
  const [generatingNonAggregateReport, setGeneratingNonAggregateReport] =
    useState(false);

  const checkAndUpdateColumnMetaWithPrimaryEntity = () => {
    const primaryEntity = getPrimaryEntity();
    const availableFilterList = getAvailableFilterListForReport();
    let tempColumnMetaList = [...storedColumnsList];
    if (primaryEntity === "call_detail") {
      let index = availableFilterList.findIndex((entry) => entry === "caller");
      if (index !== -1) {
        let columnMetaIndex = tempColumnMetaList.findIndex(
          (column) => column["field"] === "lead_owner"
        );
        if (columnMetaIndex !== -1) {
          let tempMetaData = { ...tempColumnMetaList[columnMetaIndex] };
          tempMetaData["headerName"] = "Caller";
          tempMetaData["field"] = "caller";
          tempColumnMetaList[columnMetaIndex] = tempMetaData;
        }
      }
    }

    // setting filter type as range for all date and datetime columns -- as we are giving rolling date options
    tempColumnMetaList.forEach((column) => {
      if (
        column["data_type"] === "date" ||
        column["data_type"] === "datetime"
      ) {
        column["filter_type"] = "range";
      }
    });

    setColumnsList(tempColumnMetaList);
  };

  //below function is being used to have created_at set as todays date by default in filters
  const checkAndInitReportFilter = () => {
    if (reportFilters.length === 0) {
      let createdAtIndex = storedColumnsList.findIndex(
        (column) => column["field"] === "created_at"
      );
      if (createdAtIndex !== -1) {
        let today = moment().startOf("day");
        let createdAtFilterData = {
          column_id: storedColumnsList[createdAtIndex]["id"],
          filter: {
            created_at: {
              value: [today, today],
              filter_type: "range",
              data_type: "datetime",
              rolling_date_string: {
                label: "Today",
                value: "Today",
              },
            },
          },
          operator: {
            label: "Between",
            value: "between",
          },
        };
        setReportFilters([createdAtFilterData]);
      }
    }
  };

  useEffect(() => {
    analytics.triggerEvent(
      4625000095,
      "report_details_page_load",
      "report_details_page",
      "",
      {}
    );
    checkAndUpdateColumnMetaWithPrimaryEntity();
    if (checkIfFilterDisabled() === false) {
      checkAndInitReportFilter();
    }
    authStore.updateLeadDetailStatus();
  }, []);
  const getReportTitle = () => {
    if (receivedProps.hasOwnProperty("report_data")) {
      return dataRenderer(receivedProps["report_data"]["name"]);
    }
    return "";
  };

  const getAvailableFilterListForReport = () => {
    if (receivedProps.hasOwnProperty("report_data")) {
      if (
        receivedProps["report_data"].hasOwnProperty("available_filters") &&
        receivedProps["report_data"]["available_filters"] !== null
      ) {
        return receivedProps["report_data"]["available_filters"];
      }
    }
    return [];
  };

  const checkIfFilterDisabled = () => {
    if (receivedProps.hasOwnProperty("report_data")) {
      if (receivedProps["report_data"].hasOwnProperty("available_filters")) {
        return receivedProps["report_data"]["available_filters"] === null;
      }
    }
    return false;
  };

  // below function is to get primary entity to track available filters for report from column meta list
  const getPrimaryEntity = () => {
    if (receivedProps.hasOwnProperty("report_data")) {
      if (
        receivedProps["report_data"].hasOwnProperty("primary_entity") &&
        receivedProps["report_data"]["primary_entity"] !== null
      ) {
        return receivedProps["report_data"]["primary_entity"];
      }
    }
    return "field"; // if primary entity not present or null return default value as field
  };

  const isAggregateReport = () => {
    return receivedProps?.report_data?.aggregate ?? false;
  };

  const getColumnsForDownloadFile = () => {
    return columns.map((str) => ({ displayName: str, id: str }));
  };

  const handleGenerateNonAggregateReport = async () => {
    setGeneratingNonAggregateReport(true);
    let numberOfPages = Math.ceil(count / 100);
    let tempData = [];
    let payload = {};
    let queryPayload = generateReportFilterPayload();
    if (Object.keys(queryPayload).length > 0) {
      payload["query"] = queryPayload;
    } else {
      if (checkIfFilterDisabled() === false) {
        let today = moment().startOf("day").toISOString();
        payload["query"] = {
          created_at: {
            value: {
              start: today,
              end: today,
            },
            op: "between",
          },
        };
      }
    }
    const maxPageIndex = 50; // to allow only 5000 entries to download
    for (let i = 0; i < numberOfPages; i++) {
      if (i + 1 <= maxPageIndex) {
        let queryParams = {
          offset: i * 100,
          limit: 100,
        };
        let response = await getPredefinedReportDetailsApi({
          reportID: id,
          payload: payload,
          queryParams: queryParams,
        });
        if (Object.keys(response).length > 0) {
          if (
            response["items"].hasOwnProperty("data") &&
            response["items"]["data"] !== null
          ) {
            tempData = tempData.concat(response["items"]["data"]);
          }
        }
      }
    }
    setGeneratedNonAggregateReportData(tempData);
    setCanDownloadNonAggregateReport(true);
    setGeneratingNonAggregateReport(false);
  };

  const RenderGenerateAndDownloadIconWidget = () => {
    if (isAggregateReport()) {
      if (row.length === 0) {
        return (
          <IconButton color="primary" disabled={true}>
            <Download />
          </IconButton>
        );
      } else {
        return (
          <CsvDownloader
            filename={`report_${uuidv4()}`}
            datas={row.concat(lastRowData)}
            columns={getColumnsForDownloadFile()}
          >
            <IconButton color="primary">
              <Download />
            </IconButton>
          </CsvDownloader>
        );
      }
    } else {
      if (canDownloadNonAggregateReport) {
        return (
          <CsvDownloader
            filename={`report_${uuidv4()}`}
            datas={generatedNonAggregateReportData}
            columns={getColumnsForDownloadFile()}
          >
            <IconButton color="primary">
              <Download />
            </IconButton>
          </CsvDownloader>
        );
      } else {
        return (
          <Button
            disabled={count === 0}
            onClick={() => {
              handleGenerateNonAggregateReport();
            }}
            sx={{ textTransform: "none", fontWeight: "bold" }}
            color="primary"
            variant="contained"
          >
            Generate CSV
          </Button>
        );
      }
    }
  };

  const generateReportFilterPayload = () => {
    let normalFields = {};
    let customFields = {};
    reportFilters.forEach((item) => {
      let filterData = item["filter"];
      let value = null;
      Object.keys(filterData).map((key) => {
        if (
          filterData[key]["filter_type"] === "normal" ||
          filterData[key]["filter_type"] === ""
        ) {
          if (
            filterData[key]["data_type"] === "date" ||
            filterData[key]["data_type"] === "datetime"
          ) {
            value = getIsoString(filterData[key]["value"]);
          } else {
            value = filterData[key]["value"];
          }
        } else if (filterData[key]["filter_type"] === "range") {
          if (
            filterData[key]["data_type"] === "date" ||
            filterData[key]["data_type"] === "datetime"
          ) {
            let d1 = filterData[key]["value"][0].toISOString();
            let d2 = filterData[key]["value"][1].toISOString();
            value = {
              start: d1,
              end: d2,
            };
          } else if (filterData[key]["data_type"] === "integer") {
            let num1 = filterData[key]["value"]["start"];
            let num2 = filterData[key]["value"]["end"];
            value = {
              start: num1,
              end: num2,
            };
          }
        } else if (filterData[key]["filter_type"] === "list") {
          let tempList = [];
          filterData[key]["value"].forEach((obj) => {
            tempList.push(obj["value"]);
          });
          value = tempList;
        }
      });
      const fieldName = Object.keys(filterData)[0];

      let index = columnsList.findIndex(
        (column) => column["field"] === fieldName
      );
      if (index !== -1) {
        if (columnsList[index]["is_custom_param"]) {
          let newKey = fieldName.replace("custom_params_", "");
          customFields[newKey] = {
            value: value,
            op: item["operator"]["value"],
          };
        } else {
          normalFields[fieldName] = {
            value: value,
            op: item["operator"]["value"],
          };
        }
      }
    });

    if (Object.keys(customFields).length > 0) {
      normalFields["custom_params"] = customFields;
    }
    return normalFields;
  };

  return hasReportAccess() ? (
    <>
      <Container>
        <Wrapper>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "flex-end",
              justifyContent: "space-between",
              margin: "16px 0px",
            }}
          >
            <Header>{getReportTitle()}</Header>
            <Box
              sx={{
                display: "flex",
                width: "fit-content",
                alignItems: "center",
              }}
            >
              <IconButton
                disabled={checkIfFilterDisabled() === true}
                color="primary"
                onClick={() => {
                  setOpenReportDateFilter(true);
                }}
              >
                <FilterAlt />
              </IconButton>

              <RenderGenerateAndDownloadIconWidget />
              <IconButton
                color="primary"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <Close />
              </IconButton>
            </Box>
          </Box>

          {(() => {
            if (isAggregateReport()) {
              return (
                <AggregateReport
                  columnsForDatatable={columnsForDatatable}
                  lastRowData={lastRowData}
                  refresh={refresh}
                  reportID={id}
                  row={row}
                  setColumns={setColumns}
                  setColumnsForDatatable={setColumnsForDatatable}
                  setLastRowData={setLastRowData}
                  setRefresh={setRefresh}
                  setRow={setRow}
                  generateReportFilterPayload={generateReportFilterPayload}
                  checkIfFilterDisabled={checkIfFilterDisabled}
                />
              );
            } else {
              return (
                <PaginatedNonAggregateReport
                  columnsForDatatable={columnsForDatatable}
                  refresh={refresh}
                  reportID={id}
                  row={row}
                  setColumns={setColumns}
                  setColumnsForDatatable={setColumnsForDatatable}
                  setRefresh={setRefresh}
                  setRow={setRow}
                  page={page}
                  setPage={setPage}
                  count={count}
                  setCount={setCount}
                  setGeneratedNonAggregateReportData={
                    setGeneratedNonAggregateReportData
                  }
                  setCanDownloadNonAggregateReport={
                    setCanDownloadNonAggregateReport
                  }
                  generateReportFilterPayload={generateReportFilterPayload}
                  checkIfFilterDisabled={checkIfFilterDisabled}
                />
              );
            }
          })()}
        </Wrapper>
      </Container>
      <Footer />
      {generatingNonAggregateReport && (
        <CustomLoadingDialog open={generatedNonAggregateReportData} />
      )}

      {openReportDateFilter && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openReportDateFilter}
          onClose={() => {
            setOpenReportDateFilter(false);
          }}
        >
          <FilterReportSidepanel
            setOpen={setOpenReportDateFilter}
            availableFilterList={getAvailableFilterListForReport()}
            postSaveReportFilter={(filterPayload) => {
              setReportFilters(filterPayload);
              setRefresh(true);
              setOpenReportDateFilter(false);
            }}
            savedReportFilters={reportFilters}
            columnsList={columnsList}
          />
        </Drawer>
      )}
    </>
  ) : (
    <NoAccessPermissionPage />
  );
};

export default observer(ReportDetailsPage);
