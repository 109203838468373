import moment from "moment";
import { Tooltip } from "@material-ui/core";
import { columnWidth } from "./Config";
import ColumnHeader from "./components/column_header/ColumnHeader";
import rootStore from "./stores/RootStore";
import styled from "styled-components";
import {
  tabsDateHandler,
  dateTimeHandler,
  getLocalDateTime,
  IsoToLocalDate,
  IsoToLocalTime,
  getNumberOfDaysInCurrentStage,
  isLeadRotten,
  getDateTimeDescription,
  getDateDescription,
  getLeadListingAvatarIconColor,
  getLeadListingAvatarTooltipText,
  getTooltipOfLeadColor,
  dataRenderer,
} from "./Functions";
import { myTheme } from "./themeUtils";
import { Box, Chip } from "@mui/material";
import { AutoAwesome } from "@mui/icons-material";
import {
  AccountCircle,
  PhoneCallback,
  PriorityHigh,
  ReportProblem,
} from "@material-ui/icons";
import { sortableFieldsListForLeads } from "./Db";
import LeadListingColumnHeaderRenderCompoent from "./components/column_header/LeadListingColumnHeaderRenderCompoent";
const Text = styled.p`
  margin: 0px;
  font: normal normal 600 12px poppins;
  color: #4d4e4f;
`;
const TextHyperLink = styled.a`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  color: ${myTheme.Button.colorBlue};
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
`;
const ColorStrip = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 4px;
`;
function truncate(str, n) {
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
}
const getLocalTimeFromIso = (isoDateString) => {
  if (isoDateString !== null && isoDateString !== undefined) {
    return moment.utc(isoDateString).local().format("h:mm A");
  } else {
    return "-";
  }
};
const FormattedColumns = (val) => {
  const { authStore } = rootStore;
  let newColumnHeaders = [];
  val.map((e) => {
    if (rootStore.userStore.isColumnVisible(e)) {
      if (e["is_custom_param"]) {
        let newColumn = {
          field: e.field,
          colId: e.id,
          sortable: false,
          headerName: e["headerName"],
          headerComponent: LeadListingColumnHeaderRenderCompoent,
          headerComponentParams: { columnMeta: e },
          width: e.columnSize
            ? columnWidth[e.columnSize]
            : columnWidth["medium"],

          cellRenderer: (params) => {
            let parsedData = params.data.custom_params;
            if (typeof parsedData !== "object") {
              parsedData = JSON.parse(parsedData);
            }
            let component;
            if (Object.keys(parsedData).length !== 0) {
              component = Object.keys(parsedData).map((key) => {
                let compare_field = e.field.replace("custom_params_", "");
                if (key === compare_field) {
                  if (parsedData[key] !== null) {
                    if (e.is_link === true) {
                      let link_str = parsedData[key];
                      return (
                        <Tooltip title={link_str} placement="bottom-start">
                          <TextHyperLink
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              window.open(link_str, "_blank");
                            }}
                          >
                            {link_str}
                          </TextHyperLink>
                        </Tooltip>
                      );
                    } else {
                      if (e.data_type === "datetime") {
                        let value = "";
                        if (
                          parsedData[key] !== undefined &&
                          parsedData[key] !== null &&
                          parsedData[key] !== ""
                        ) {
                          if (e["show_relative_times_in_listing"] === true) {
                            value = getDateTimeDescription(parsedData[key]);
                          } else {
                            value = IsoToLocalTime(parsedData[key]);
                          }
                        }

                        return (
                          <Tooltip title={value}>
                            <span className="csutable-cell-trucate">
                              {value !== "" ? value : "-"}
                            </span>
                          </Tooltip>
                        );
                      } else if (e.data_type === "date") {
                        let value = "";

                        if (
                          parsedData[key] !== undefined &&
                          parsedData[key] !== null &&
                          parsedData[key] !== ""
                        ) {
                          if (e["show_relative_times_in_listing"] === true) {
                            value = getDateDescription(parsedData[key]);
                          } else {
                            value = IsoToLocalDate(parsedData[key]);
                          }
                        }
                        return (
                          <Tooltip title={value}>
                            <span className="csutable-cell-trucate">
                              {value !== "" ? value : "-"}
                            </span>
                          </Tooltip>
                        );
                      } else {
                        let value = dataRenderer(parsedData[key]);
                        return (
                          <Tooltip title={value} placement="bottom-start">
                            <span className="csutable-cell-trucate">
                              {value}
                            </span>
                          </Tooltip>
                        );
                      }
                    }
                  } else {
                    if (e.data_type === "string") {
                      return (
                        <Tooltip title={"-"}>
                          <span className="csutable-cell-trucate">-</span>
                        </Tooltip>
                      );
                    } else if (e.data_type === "integer") {
                      return (
                        <Tooltip title={0}>
                          <span className="csutable-cell-trucate">{0}</span>
                        </Tooltip>
                      );
                    } else if (
                      e.data_type === "date" ||
                      e.data_type === "datetime"
                    ) {
                      return (
                        <Tooltip title={"-"}>
                          <span className="csutable-cell-trucate">-</span>
                        </Tooltip>
                      );
                    }
                  }
                }
              });
            } else {
              if (e.data_type === "string") {
                return (
                  <Tooltip title={"-"}>
                    <span className="csutable-cell-trucate">-</span>
                  </Tooltip>
                );
              } else if (e.data_type === "integer") {
                return (
                  <Tooltip title={0}>
                    <span className="csutable-cell-trucate">{0}</span>
                  </Tooltip>
                );
              } else if (e.data_type === "date" || e.data_type === "datetime") {
                return (
                  <Tooltip title={"-"}>
                    <span className="csutable-cell-trucate">-</span>
                  </Tooltip>
                );
              }
            }
            if (component !== undefined) {
              return component;
            }
          },
          sequence: e.sequence,
        };
        newColumnHeaders.push(newColumn);
      } else {
        if (e.is_link === true) {
          let field = e.field;
          let newColumn = {
            field: e.field,
            colId: e.id,
            sortable: false,
            headerName: e["headerName"],
            headerComponent: LeadListingColumnHeaderRenderCompoent,
            headerComponentParams: { columnMeta: e },
            width: e.columnSize
              ? columnWidth[e.columnSize]
              : columnWidth["medium"],

            cellRenderer: (params) => {
              let link_str =
                params.data[field] !== null ? params.data[field] : "";

              return (
                <Tooltip title={link_str} placement="bottom-start">
                  <TextHyperLink
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      window.open(link_str, "_blank");
                    }}
                  >
                    {link_str}
                  </TextHyperLink>
                </Tooltip>
              );
            },
            sequence: e.sequence,
          };
          newColumnHeaders.push(newColumn);
        } else {
          if (e.data_type === "datetime") {
            let field = e.field;
            let newColumn = {
              field: e.field,
              colId: e.id,
              sortable: false,
              headerName: e["headerName"],
              headerComponent: LeadListingColumnHeaderRenderCompoent,
              headerComponentParams: { columnMeta: e },
              width: e.columnSize
                ? columnWidth[e.columnSize]
                : columnWidth["medium"],

              cellRenderer: (params) => {
                let value = "";
                if (
                  params.data[field] !== undefined &&
                  params.data[field] !== null &&
                  params.data[field] !== ""
                ) {
                  if (e["show_relative_times_in_listing"] === true) {
                    value = getDateTimeDescription(params.data[field]);
                  } else {
                    value = IsoToLocalTime(params.data[field]);
                  }
                }

                return (
                  <Tooltip title={value}>
                    <span className="csutable-cell-trucate">
                      {value !== "" ? value : "-"}
                    </span>
                  </Tooltip>
                );
              },
              sequence: e.sequence,
            };
            newColumnHeaders.push(newColumn);
          } else if (e.data_type === "date") {
            let field = e.field;
            let newColumn = {
              field: e.field,
              colId: e.id,
              sortable: false,
              headerName: e["headerName"],
              headerComponent: LeadListingColumnHeaderRenderCompoent,
              headerComponentParams: { columnMeta: e },
              width: e.columnSize
                ? columnWidth[e.columnSize]
                : columnWidth["medium"],

              cellRenderer: (params) => {
                let value = "";
                if (
                  params.data[field] !== undefined &&
                  params.data[field] !== null &&
                  params.data[field] !== ""
                ) {
                  if (e["show_relative_times_in_listing"] === true) {
                    value = getDateDescription(params.data[field]);
                  } else {
                    value = IsoToLocalDate(params.data[field]);
                  }
                }

                return (
                  <Tooltip title={value}>
                    <span className="csutable-cell-trucate">
                      {value !== "" ? value : "-"}
                    </span>
                  </Tooltip>
                );
              },
              sequence: e.sequence,
            };
            newColumnHeaders.push(newColumn);
          } else {
            let newColumn = {
              field: e.field,
              colId: e.id,
              headerName: e["headerName"],
              headerComponent: LeadListingColumnHeaderRenderCompoent,
              headerComponentParams: { columnMeta: e },
              sortable: false,
              width: e.columnSize
                ? columnWidth[e.columnSize]
                : columnWidth["medium"],

              cellRenderer: (params) => {
                if (e.field === "lead_stage") {
                  let color_value = "";
                  let index = authStore.stageList.findIndex(
                    (stageObj) => stageObj.stage === params.data[e.field]
                  );
                  if (index !== -1) {
                    color_value = authStore.stageList[index]["color"];
                  }
                  return (
                    <Tooltip title={params.data[e.field]}>
                      <span
                        className="csutable-cell-trucate"
                        style={{
                          color: color_value !== null ? color_value : "",
                        }}
                      >
                        {params.data[e.field]}
                      </span>
                    </Tooltip>
                  );
                } else if (e.field === "lead_status") {
                  let color_value = "";
                  let index = authStore.statusList.findIndex(
                    (status) => status.status === params.data[e.field]
                  );
                  if (index !== -1) {
                    color_value = authStore.statusList[index]["color"];
                  }
                  return (
                    <Tooltip title={params.data[e.field]}>
                      <span
                        className="csutable-cell-trucate"
                        style={{
                          color: color_value !== null ? color_value : "",
                        }}
                      >
                        {params.data[e.field]}
                      </span>
                    </Tooltip>
                  );
                } else if (
                  e.field === "lead_owner" ||
                  e.field === "lead_creator" ||
                  e.field === "team"
                ) {
                  let value =
                    params.data[e.field] !== null &&
                    params.data[e.field] !== undefined
                      ? params.data[e.field]["name"]
                      : "";
                  return (
                    <Tooltip title={value} placement="bottom-start">
                      <span className="csutable-cell-trucate">
                        {value !== "" ? value : "-"}
                      </span>
                    </Tooltip>
                  );
                } else {
                  let value =
                    params.data[e.field] !== null &&
                    params.data[e.field] !== undefined
                      ? params.data[e.field]
                      : "";
                  return (
                    <Tooltip title={value} placement="bottom-start">
                      <span className="csutable-cell-trucate">
                        {value !== "" ? value : "-"}
                      </span>
                    </Tooltip>
                  );
                }
              },
            };
            newColumnHeaders.push(newColumn);
          }
        }
      }
    }
  });
  let len = newColumnHeaders.length;
  newColumnHeaders.forEach((column, index) => {
    if (index !== len - 1) {
      column["maxWidth"] = 500;
    }
  });
  return newColumnHeaders;
};

const FormattedColumnsFilters = ({ columnMetaList, scope = "filter_page" }) => {
  const { authStore } = rootStore;
  let newColumnHeaders = [];
  columnMetaList.map((e) => {
    if (rootStore.userStore.isColumnVisible(e)) {
      if (e["is_custom_param"]) {
        let newColumn = {
          field: e.field,
          colId: e.id,
          sortable: false,
          headerName: e.headerName,
          headerComponent: LeadListingColumnHeaderRenderCompoent,
          headerComponentParams: { columnMeta: e, scope: scope },
          width: e.columnSize
            ? columnWidth[e.columnSize]
            : columnWidth["medium"],

          cellRenderer: (params) => {
            let parsedData = params.data.custom_params;
            if (typeof parsedData !== "object") {
              parsedData = JSON.parse(parsedData);
            }
            let component;
            if (Object.keys(parsedData).length !== 0) {
              component = Object.keys(parsedData).map((key) => {
                let compare_field = e.field.replace("custom_params_", "");
                if (key === compare_field) {
                  if (parsedData[key] !== null) {
                    if (e.is_link === true) {
                      let link_str = parsedData[key];
                      return (
                        <Tooltip title={link_str} placement="bottom-start">
                          <TextHyperLink
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              window.open(link_str, "_blank");
                            }}
                          >
                            {link_str}
                          </TextHyperLink>
                        </Tooltip>
                      );
                    } else {
                      if (e.data_type === "datetime") {
                        let value = "";
                        if (
                          parsedData[key] !== undefined &&
                          parsedData[key] !== null &&
                          parsedData[key] !== ""
                        ) {
                          if (e["show_relative_times_in_listing"] === true) {
                            value = getDateTimeDescription(parsedData[key]);
                          } else {
                            value = IsoToLocalTime(parsedData[key]);
                          }
                        }
                        return (
                          <Tooltip title={value}>
                            <span className="csutable-cell-trucate">
                              {value !== "" ? value : "-"}
                            </span>
                          </Tooltip>
                        );
                      } else if (e.data_type === "date") {
                        let value = "";

                        if (
                          parsedData[key] !== undefined &&
                          parsedData[key] !== null &&
                          parsedData[key] !== ""
                        ) {
                          if (e["show_relative_times_in_listing"] === true) {
                            value = getDateDescription(parsedData[key]);
                          } else {
                            value = IsoToLocalDate(parsedData[key]);
                          }
                        }
                        return (
                          <Tooltip title={value}>
                            <span className="csutable-cell-trucate">
                              {value !== "" ? value : "-"}
                            </span>
                          </Tooltip>
                        );
                      } else {
                        let value = dataRenderer(parsedData[key]);
                        return (
                          <Tooltip title={value} placement="bottom-start">
                            <span className="csutable-cell-trucate">
                              {value}
                            </span>
                          </Tooltip>
                        );
                      }
                    }
                  } else {
                    if (e.data_type === "string") {
                      return (
                        <Tooltip title={"-"}>
                          <span className="csutable-cell-trucate">-</span>
                        </Tooltip>
                      );
                    } else if (e.data_type === "integer") {
                      return (
                        <Tooltip title={0}>
                          <span className="csutable-cell-trucate">{0}</span>
                        </Tooltip>
                      );
                    } else if (
                      e.data_type === "date" ||
                      e.data_type === "datetime"
                    ) {
                      return (
                        <Tooltip title={"-"}>
                          <span className="csutable-cell-trucate">-</span>
                        </Tooltip>
                      );
                    }
                  }
                }
              });
            } else {
              if (e.data_type === "string") {
                return (
                  <Tooltip title={"-"}>
                    <span className="csutable-cell-trucate">-</span>
                  </Tooltip>
                );
              } else if (e.data_type === "integer") {
                return (
                  <Tooltip title={0}>
                    <span className="csutable-cell-trucate">{0}</span>
                  </Tooltip>
                );
              } else if (e.data_type === "date" || e.data_type === "datetime") {
                return (
                  <Tooltip title={"-"}>
                    <span className="csutable-cell-trucate">-</span>
                  </Tooltip>
                );
              }
            }
            if (component !== undefined) {
              return component;
            }
          },
          sequence: e.sequence,
        };
        newColumnHeaders.push(newColumn);
      } else {
        if (e.is_link === true) {
          let field = e.field;
          let newColumn = {
            field: e.field,
            colId: e.id,
            sortable: false,
            headerName: e.headerName,
            headerComponent: LeadListingColumnHeaderRenderCompoent,
            headerComponentParams: { columnMeta: e, scope: scope },
            width: e.columnSize
              ? columnWidth[e.columnSize]
              : columnWidth["medium"],

            cellRenderer: (params) => {
              let link_str =
                params.data[field] !== null ? params.data[field] : "";

              return (
                <Tooltip title={link_str} placement="bottom-start">
                  <TextHyperLink
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      window.open(link_str, "_blank");
                    }}
                  >
                    {link_str}
                  </TextHyperLink>
                </Tooltip>
              );
            },
            sequence: e.sequence,
          };
          newColumnHeaders.push(newColumn);
        } else {
          if (e.data_type === "datetime") {
            let field = e.field;
            let newColumn = {
              field: e.field,
              colId: e.id,
              sortable: false,
              headerName: e.headerName,
              headerComponent: LeadListingColumnHeaderRenderCompoent,
              headerComponentParams: { columnMeta: e, scope: scope },
              width: e.columnSize
                ? columnWidth[e.columnSize]
                : columnWidth["medium"],

              cellRenderer: (params) => {
                let value = "";
                if (
                  params.data[field] !== undefined &&
                  params.data[field] !== null &&
                  params.data[field] !== ""
                ) {
                  if (e["show_relative_times_in_listing"] === true) {
                    value = getDateTimeDescription(params.data[field]);
                  } else {
                    value = IsoToLocalTime(params.data[field]);
                  }
                }

                return (
                  <Tooltip title={value}>
                    <span className="csutable-cell-trucate">
                      {value !== "" ? value : "-"}
                    </span>
                  </Tooltip>
                );
              },
              sequence: e.sequence,
            };
            newColumnHeaders.push(newColumn);
          } else if (e.data_type === "date") {
            let field = e.field;
            let newColumn = {
              field: e.field,
              colId: e.id,
              sortable: false,
              headerName: e.headerName,
              headerComponent: LeadListingColumnHeaderRenderCompoent,
              headerComponentParams: { columnMeta: e, scope: scope },
              width: e.columnSize
                ? columnWidth[e.columnSize]
                : columnWidth["medium"],

              cellRenderer: (params) => {
                let value = "";
                if (
                  params.data[field] !== undefined &&
                  params.data[field] !== null &&
                  params.data[field] !== ""
                ) {
                  if (e["show_relative_times_in_listing"] === true) {
                    value = getDateDescription(params.data[field]);
                  } else {
                    value = IsoToLocalDate(params.data[field]);
                  }
                }

                return (
                  <Tooltip title={value}>
                    <span className="csutable-cell-trucate">
                      {value !== "" ? value : "-"}
                    </span>
                  </Tooltip>
                );
              },
              sequence: e.sequence,
            };
            newColumnHeaders.push(newColumn);
          } else {
            let newColumn = {
              field: e.field,
              colId: e.id,
              headerName: e.headerName,
              headerComponent: LeadListingColumnHeaderRenderCompoent,
              headerComponentParams: { columnMeta: e, scope: scope },
              sortable: false,
              width: e.columnSize
                ? columnWidth[e.columnSize]
                : columnWidth["medium"],

              cellRenderer: (params) => {
                if (e.field === "lead_stage") {
                  let color_value = "";
                  let index = authStore.stageList.findIndex(
                    (stageObj) => stageObj.stage === params.data[e.field]
                  );
                  if (index !== -1) {
                    color_value = authStore.stageList[index]["color"];
                  }
                  return (
                    <Tooltip title={params.data[e.field]}>
                      <span
                        className="csutable-cell-trucate"
                        style={{
                          color: color_value !== null ? color_value : "",
                        }}
                      >
                        {params.data[e.field]}
                      </span>
                    </Tooltip>
                  );
                } else if (e.field === "lead_status") {
                  let color_value = "";
                  let index = authStore.statusList.findIndex(
                    (status) => status.status === params.data[e.field]
                  );
                  if (index !== -1) {
                    color_value = authStore.statusList[index]["color"];
                  }
                  return (
                    <Tooltip title={params.data[e.field]}>
                      <span
                        className="csutable-cell-trucate"
                        style={{
                          color: color_value !== null ? color_value : "",
                        }}
                      >
                        {params.data[e.field]}
                      </span>
                    </Tooltip>
                  );
                } else if (
                  e.field === "lead_owner" ||
                  e.field === "lead_creator" ||
                  e.field === "team"
                ) {
                  let value =
                    params.data[e.field] !== null &&
                    params.data[e.field] !== undefined
                      ? params.data[e.field]["name"]
                      : "";
                  return (
                    <Tooltip title={value} placement="bottom-start">
                      <span className="csutable-cell-trucate">
                        {value !== "" ? value : "-"}
                      </span>
                    </Tooltip>
                  );
                } else {
                  let value =
                    params.data[e.field] !== null &&
                    params.data[e.field] !== undefined
                      ? params.data[e.field]
                      : "";
                  return (
                    <Tooltip title={value} placement="bottom-start">
                      <span className="csutable-cell-trucate">
                        {value !== "" ? value : "-"}
                      </span>
                    </Tooltip>
                  );
                }
              },
            };
            newColumnHeaders.push(newColumn);
          }
        }
      }
    }
  });
  let len = newColumnHeaders.length;
  newColumnHeaders.forEach((column, index) => {
    if (index !== len - 1) {
      column["maxWidth"] = 500;
    }
  });
  return newColumnHeaders;
};

const FormattedReportColumns = (val) => {
  const { authStore } = rootStore;
  let newColumnHeaders = [];
  val.map((e) => {
    if (e["is_custom_param"]) {
      let newColumn = {
        field: e.field,
        sortable: false,
        headerName: <Text> {e.headerName}</Text>,
        width: e.columnSize ? columnWidth[e.columnSize] : columnWidth["medium"],
        renderCell: (params) => {
          let parsedData = params.row.custom_params;
          if (typeof parsedData !== "object") {
            parsedData = JSON.parse(parsedData);
          }
          let component;
          if (Object.keys(parsedData).length !== 0) {
            component = Object.keys(parsedData).map((key) => {
              let compare_field = e.field.replace("custom_params_", "");
              if (key === compare_field) {
                if (parsedData[key] !== null) {
                  if (e.is_link === true) {
                    let link_str = parsedData[key];
                    return (
                      <TextHyperLink
                        href={link_str}
                        target="_blank"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {link_str.slice(0, 16) + "..."}
                      </TextHyperLink>
                    );
                  } else {
                    if (e.data_type === "datetime") {
                      let date = null;
                      if (parsedData[key] !== null && parsedData[key] !== "") {
                        date = getLocalDateTime(parsedData[key]);
                      }
                      return (
                        <Tooltip title={date}>
                          <span className="csutable-cell-trucate">
                            {date !== null ? date : "-"}
                          </span>
                        </Tooltip>
                      );
                    } else if (e.data_type === "date") {
                      let date;
                      if (parsedData[key] !== null && parsedData[key] !== "") {
                        date = getLocalDateTime(parsedData[key]);
                      }
                      return (
                        <Tooltip title={date}>
                          <span className="csutable-cell-trucate">
                            {date !== null ? date : "-"}
                          </span>
                        </Tooltip>
                      );
                    } else {
                      return (
                        <Tooltip title={parsedData[key]}>
                          <span className="csutable-cell-trucate">
                            {parsedData[key]}
                          </span>
                        </Tooltip>
                      );
                    }
                  }
                } else {
                  if (e.data_type === "string") {
                    return (
                      <Tooltip title={"-"}>
                        <span className="csutable-cell-trucate">-</span>
                      </Tooltip>
                    );
                  } else if (e.data_type === "integer") {
                    return (
                      <Tooltip title={0}>
                        <span className="csutable-cell-trucate">{0}</span>
                      </Tooltip>
                    );
                  } else if (
                    e.data_type === "date" ||
                    e.data_type === "datetime"
                  ) {
                    return (
                      <Tooltip title={"-"}>
                        <span className="csutable-cell-trucate">-</span>
                      </Tooltip>
                    );
                  }
                }
              }
            });
          } else {
            if (e.data_type === "string") {
              return (
                <Tooltip title={"-"}>
                  <span className="csutable-cell-trucate">-</span>
                </Tooltip>
              );
            } else if (e.data_type === "integer") {
              return (
                <Tooltip title={0}>
                  <span className="csutable-cell-trucate">{0}</span>
                </Tooltip>
              );
            } else if (e.data_type === "date" || e.data_type === "datetime") {
              return (
                <Tooltip title={"-"}>
                  <span className="csutable-cell-trucate">-</span>
                </Tooltip>
              );
            }
          }
          if (component !== undefined) {
            return component;
          }
        },
        sequence: e.sequence,
      };
      newColumnHeaders.push(newColumn);
    } else {
      if (e.is_link === true) {
        let field = e.field;
        let newColumn = {
          field: e.field,
          sortable: false,
          headerName: <Text> {e.headerName}</Text>,
          width: e.columnSize
            ? columnWidth[e.columnSize]
            : columnWidth["medium"],

          renderCell: (params) => {
            let link_str = params.row[field] !== null ? params.row[field] : "";

            return (
              <TextHyperLink
                href={link_str}
                target="_blank"
                onClick={(e) => e.stopPropagation()}
              >
                {link_str.slice(0, 16) + "..."}
              </TextHyperLink>
            );
          },
          sequence: e.sequence,
        };
        newColumnHeaders.push(newColumn);
      } else {
        if (e.data_type === "datetime") {
          let field = e.field;
          let newColumn = {
            field: e.field,
            sortable: false,
            headerName: <Text> {e.headerName}</Text>,
            width: e.columnSize
              ? columnWidth[e.columnSize]
              : columnWidth["medium"],

            renderCell: (params) => {
              let date = null;
              if (params.row[field] !== null && params.row[field] !== "") {
                date = getLocalDateTime(params.row[field]);
              }
              return (
                <Tooltip title={date}>
                  <span className="csutable-cell-trucate">
                    {date !== null ? date : "-"}
                  </span>
                </Tooltip>
              );
            },
            sequence: e.sequence,
          };
          newColumnHeaders.push(newColumn);
        } else if (e.data_type === "date") {
          let field = e.field;
          let newColumn = {
            field: e.field,
            sortable: false,
            headerName: <Text> {e.headerName}</Text>,
            width: e.columnSize
              ? columnWidth[e.columnSize]
              : columnWidth["medium"],

            renderCell: (params) => {
              let date;
              if (params.row[field] !== null && params.row[field] !== "") {
                date = getLocalDateTime(params.row[field]);
              }
              return (
                <Tooltip title={date}>
                  <span className="csutable-cell-trucate">
                    {date !== null ? date : "-"}
                  </span>
                </Tooltip>
              );
            },
            sequence: e.sequence,
          };
          newColumnHeaders.push(newColumn);
        } else {
          let newColumn = {
            field: e.field,
            headerName: <Text> {e.headerName}</Text>,
            sortable: false,
            width: e.columnSize
              ? columnWidth[e.columnSize]
              : columnWidth["medium"],

            renderCell: (params) => {
              if (e.field === "lead_stage") {
                let color_value = "";
                let index = authStore.stageList.findIndex(
                  (stageObj) => stageObj.stage === params.row[e.field]
                );
                if (index !== -1) {
                  color_value = authStore.stageList[index]["color"];
                }
                return (
                  <Tooltip title={params.row[e.field]}>
                    <span
                      className="csutable-cell-trucate"
                      style={{
                        color: color_value !== null ? color_value : "",
                      }}
                    >
                      {params.row[e.field]}
                    </span>
                  </Tooltip>
                );
              } else if (e.field === "lead_status") {
                let color_value = "";
                let index = authStore.statusList.findIndex(
                  (status) => status.status === params.row[e.field]
                );
                if (index !== -1) {
                  color_value = authStore.statusList[index]["color"];
                }
                return (
                  <Tooltip title={params.row[e.field]}>
                    <span
                      className="csutable-cell-trucate"
                      style={{
                        color: color_value !== null ? color_value : "",
                      }}
                    >
                      {params.row[e.field]}
                    </span>
                  </Tooltip>
                );
              } else {
                return (
                  <Tooltip title={params.row[e.field]}>
                    <span className="csutable-cell-trucate">
                      {params.row[e.field]}
                    </span>
                  </Tooltip>
                );
              }
            },
          };
          newColumnHeaders.push(newColumn);
        }
      }
    }
  });
  return newColumnHeaders;
};

const FormattedRawLeadColumns = (val) => {
  let newColumnHeaders = [];
  val.map((e) => {
    if (!e.column_name.includes("custom_params_")) {
      if (e.data_type === "datetime") {
        let field = e.column_name;
        let newColumn = {
          field: e.column_name,
          sortable: false,
          headerName: e.display_name.toUpperCase(),
          filterable: e.filterable,
          cellRenderer: (params) => {
            let date = null;
            if (
              params.data[field] !== null &&
              params.data[field] !== "" &&
              params.data[field] !== undefined
            ) {
              date = IsoToLocalTime(params.data[field]);
            }
            return (
              <Tooltip title={date} placement="bottom-start">
                <span className="csutable-cell-trucate">
                  {date !== null ? date : "-"}
                </span>
              </Tooltip>
            );
          },
          sequence: e.sequence,
        };
        newColumnHeaders.push(newColumn);
      } else if (e.data_type === "date") {
        let field = e.column_name;
        let newColumn = {
          field: e.column_name,
          sortable: false,
          headerName: e.display_name.toUpperCase(),
          filterable: e.filterable,

          cellRenderer: (params) => {
            let date = null;
            if (
              params.data[field] !== null &&
              params.data[field] !== "" &&
              params.data[field] !== undefined
            ) {
              date = IsoToLocalDate(params.data[field]);
            }
            return (
              <Tooltip title={date} placement="bottom-start">
                <span className="csutable-cell-trucate">
                  {date !== null ? date : "-"}
                </span>
              </Tooltip>
            );
          },
          sequence: e.sequence,
        };
        newColumnHeaders.push(newColumn);
      } else if (e.data_type === "boolean") {
        let newColumn = {
          field: e.column_name,
          headerName: e.display_name.toUpperCase(),
          filterable: e.filterable,
          cellRenderer: (params) => {
            let value =
              params.data[e.column_name] !== null &&
              params.data[e.column_name] !== "" &&
              params.data[e.column_name] !== undefined
                ? params.data[e.column_name] === true
                  ? "True"
                  : "False"
                : "-";
            return (
              <Tooltip title={value} placement="bottom-start">
                <span className="csutable-cell-trucate">
                  {value !== "-" ? (
                    <Chip
                      label={value}
                      color={value === "True" ? "success" : "warning"}
                    />
                  ) : (
                    "-"
                  )}
                </span>
              </Tooltip>
            );
          },
        };
        newColumnHeaders.push(newColumn);
      } else {
        let newColumn = {
          field: e.column_name,
          headerName: e.display_name.toUpperCase(),
          filterable: e.filterable,
          cellRenderer: (params) => {
            return (
              <Tooltip
                title={params.data[e.column_name]}
                placement="bottom-start"
              >
                <span className="csutable-cell-trucate">
                  {params.data[e.column_name] !== null &&
                  params.data[e.column_name] !== "" &&
                  params.data[e.column_name] !== undefined
                    ? params.data[e.column_name]
                    : "-"}
                </span>
              </Tooltip>
            );
          },
        };
        newColumnHeaders.push(newColumn);
      }
    }
  });
  return newColumnHeaders;
};

const FormattedAgGridColumns = (val) => {
  let newColumnHeaders = [];
  val.map((e) => {
    if (e.is_visible) {
      if (e.data_type === "datetime") {
        let field = e.column_name;
        let newColumn = {
          field: e.column_name,
          sortable: false,
          headerName: e.display_name.toUpperCase(),
          filterable: e.filterable,
          cellRenderer: (params) => {
            let date = null;
            if (
              params.data[field] !== null &&
              params.data[field] !== "" &&
              params.data[field] !== undefined
            ) {
              date = IsoToLocalTime(params.data[field]);
            }
            return (
              <Tooltip title={date} placement="bottom-start">
                <span className="csutable-cell-trucate">
                  {date !== null ? date : "-"}
                </span>
              </Tooltip>
            );
          },
          sequence: e.sequence,
        };
        newColumnHeaders.push(newColumn);
      } else if (e.data_type === "date") {
        let field = e.column_name;
        let newColumn = {
          field: e.column_name,
          sortable: false,
          headerName: e.display_name.toUpperCase(),
          filterable: e.filterable,

          cellRenderer: (params) => {
            let date = null;
            if (
              params.data[field] !== null &&
              params.data[field] !== "" &&
              params.data[field] !== undefined
            ) {
              date = IsoToLocalDate(params.data[field]);
            }
            return (
              <Tooltip title={date} placement="bottom-start">
                <span className="csutable-cell-trucate">
                  {date !== null ? date : "-"}
                </span>
              </Tooltip>
            );
          },
          sequence: e.sequence,
        };
        newColumnHeaders.push(newColumn);
      } else if (e.data_type === "boolean") {
        let newColumn = {
          field: e.column_name,
          headerName: e.display_name.toUpperCase(),
          filterable: e.filterable,
          cellRenderer: (params) => {
            let value =
              params.data[e.column_name] !== null &&
              params.data[e.column_name] !== "" &&
              params.data[e.column_name] !== undefined
                ? params.data[e.column_name] === true
                  ? "True"
                  : "False"
                : "-";
            return (
              <Tooltip title={value} placement="bottom-start">
                <span className="csutable-cell-trucate">
                  {value !== "-" ? (
                    <Chip
                      label={value}
                      color={value === "True" ? "success" : "warning"}
                    />
                  ) : (
                    "-"
                  )}
                </span>
              </Tooltip>
            );
          },
        };
        newColumnHeaders.push(newColumn);
      } else {
        let newColumn = {
          field: e.column_name,
          headerName: e.display_name.toUpperCase(),
          filterable: e.filterable,
          cellRenderer: (params) => {
            return (
              <Tooltip
                title={params.data[e.column_name]}
                placement="bottom-start"
              >
                <span className="csutable-cell-trucate">
                  {params.data[e.column_name] !== null &&
                  params.data[e.column_name] !== "" &&
                  params.data[e.column_name] !== undefined
                    ? params.data[e.column_name]
                    : "-"}
                </span>
              </Tooltip>
            );
          },
        };
        newColumnHeaders.push(newColumn);
      }
    }
  });
  return newColumnHeaders;
};

// ----------------------functions and column defination for fresh_lead and followup icons in leads listing pages---------------

const isThisHour = (dateTimeString) => {
  const dateTime = moment(dateTimeString);
  const startOfCurrentHour = moment().startOf("hour");
  const endOfCurrentHour = moment().startOf("hour").add(1, "hour");

  return dateTime.isBetween(startOfCurrentHour, endOfCurrentHour, null, "[]");
};

const isToday = (dateTimeString) => {
  const dateTime = moment(dateTimeString);
  return dateTime.isSame(moment(), "day");
};

const isMissedToday = (dateTimeString) => {
  const dateTime = moment(dateTimeString);
  return (
    dateTime.isSame(moment(), "day") && dateTime.isBefore(moment(), "hour")
  );
};

const isPastTimeToday = (dateTimeString) => {
  const dateTime = moment(dateTimeString);
  return dateTime.isBefore(moment());
};
const isFutureTimeToday = (dateTimeString) => {
  const dateTime = moment(dateTimeString);
  return dateTime.isAfter(moment());
};

const renderFollowupIcon = (data) => {
  if (
    data.hasOwnProperty("next_follow_up_on") &&
    data["next_follow_up_on"] !== null &&
    data["next_follow_up_on"] !== ""
  ) {
    if (isToday(data["next_follow_up_on"])) {
      if (isThisHour(data["next_follow_up_on"])) {
        return (
          <Tooltip title={"Followup this hour"}>
            <PhoneCallback fontSize="small" style={{ color: "#4caf50" }} />
          </Tooltip>
        );
      } else if (isMissedToday(data["next_follow_up_on"])) {
        return (
          <Tooltip title={"Followup missed"}>
            <PhoneCallback fontSize="small" color="error" />
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title={"Followup today"}>
            <PhoneCallback fontSize="small" color="primary" />
          </Tooltip>
        );
      }
    }
  }
  return <PhoneCallback fontSize="small" color="disabled" />;
};

const renderPriorityLeadIcon = (data) => {
  if (
    data.hasOwnProperty("hard_update_by") &&
    data["hard_update_by"] !== null &&
    data["hard_update_by"] !== ""
  ) {
    if (isToday(data["hard_update_by"])) {
      if (data["priority_lead"] === true) {
        if (isPastTimeToday(data["hard_update_by"])) {
          let titleString = `High Priority - Process by ${getLocalTimeFromIso(
            data["hard_update_by"]
          )}, today.`;
          return (
            <Tooltip title={titleString}>
              <PriorityHigh fontSize="small" color="error" />
            </Tooltip>
          );
        } else if (isFutureTimeToday(data["hard_update_by"])) {
          let titleString = `High Priority - Process by ${getLocalTimeFromIso(
            data["hard_update_by"]
          )}, today.`;
          return (
            <Tooltip title={titleString}>
              <PriorityHigh fontSize="small" color="primary" />
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title={"Normal Priority"}>
              <PriorityHigh fontSize="small" color="disabled" />
            </Tooltip>
          );
        }
      }
    }
  }
  return (
    <Tooltip title={"Normal Priority"}>
      <PriorityHigh fontSize="small" color="disabled" />
    </Tooltip>
  );
};

const renderAvatarIcon = (data) => {
  return (
    <Tooltip title={getLeadListingAvatarTooltipText({ data: data })}>
      <AccountCircle
        fontSize="small"
        style={{
          color: getLeadListingAvatarIconColor({ data: data }),
        }}
      />
    </Tooltip>
  );
};

const renderRottenLead = (data) => {
  if (isLeadRotten(data) === true) {
    return (
      <Tooltip
        title={`Rotting for ${getNumberOfDaysInCurrentStage(data)} Day(s)`}
      >
        <ReportProblem fontSize="small" color="error" />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={"Rotten Lead"}>
        <ReportProblem fontSize="small" color="disabled" />
      </Tooltip>
    );
  }
};

const leadListingIconsColumn = [
  {
    field: "icons",
    headerName: "",
    sorting: false,
    width: 180,
    resizable: false,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    cellStyle: { position: "relative", border: "none" },
    cellRenderer: (params) => {
      return (
        <Box sx={{ width: "100%", height: "100%" }}>
          <IconWrapper
            style={{
              height: "100%",
              justifyContent: "center",
            }}
          >
            {renderAvatarIcon(params.data)}
            {renderFollowupIcon(params.data)}
            {renderPriorityLeadIcon(params.data)}
            {renderRottenLead(params.data)}
          </IconWrapper>
          <Tooltip title={getTooltipOfLeadColor(params.data.color)}>
            <ColorStrip
              style={{
                backgroundColor:
                  params.data.color !== null &&
                  params.data.color !== "" &&
                  params.data.color !== "nil"
                    ? params.data.color
                    : "transparent",
              }}
            />
          </Tooltip>
        </Box>
      );
    },
  },
];
// -------------------------------------END-------------------------------------------------
export {
  FormattedColumns,
  FormattedReportColumns,
  FormattedColumnsFilters,
  FormattedRawLeadColumns,
  leadListingIconsColumn,
  FormattedAgGridColumns,
};
