import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";

const FormContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  boxSizing: "border-box",
  padding: theme.spacing(3),
  boxShadow: "0px 0px 50px 0px rgba(7, 14, 39, 0.1)",
  backgroundColor: "#fff",
}));
const Header = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 24px Poppins",
  textAlign: "center",
  width: "100%",
  marginBottom: theme.spacing(2),
  variant: "h6",
  component: "span",

  [[theme.breakpoints.between("xs", "sm")]]: {
    font: "normal normal 600 16px Poppins",
  },
  [[theme.breakpoints.between("sm", "md")]]: {
    font: "normal normal 600 16px Poppins",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    font: "normal normal 600 20px Poppins",
  },
  [theme.breakpoints.up("lg")]: {
    font: "normal normal 600 24px Poppins",
  },
}));

const InputContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

const FieldWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

const Label = styled(Typography)(({ theme }) => ({
  font: "normal normal 400 12px Poppins",
  color: "#5a6066",
  variant: "body1",
  component: "span",
  [[theme.breakpoints.between("xs", "md")]]: {
    font: "normal normal 400 10px Poppins",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    font: "normal normal 400 10px Poppins",
  },
  [theme.breakpoints.up("lg")]: {
    font: "normal normal 400 12px Poppins",
  },
}));

const OutlinedInputComponent = styled(OutlinedInput)(({ theme }) => ({
  lineHeight: "16px",
  margin: 0,
  fontSize: "16px",
  color: "#7c7d7c",
  borderColor: "#bbbbbb",
  border: "1px solid rgba(0, 0, 0, 0.1)",
  width: "100%",
  backgroundColor: "#fff",
  height: "40px",
  ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    padding: "7px 16px",
  },
}));

const SelectComponent = styled(Select)(({ theme }) => ({
  padding: `0px`,
  margin: 0,
  fontSize: "16px",
  borderColor: "#bbbbbb",
  border: "1px solid rgba(0, 0, 0, 0.1)",
  width: "100%",
  backgroundColor: "#fff",
  ".css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select":
    {
      minHeight: "0px",
    },
}));

const DisclaimerWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  columnGap: "10px",
  width: "100%",
  marginBottom: theme.spacing(2),
}));

const SubHeader = styled(Typography)(({ theme }) => ({
  font: "normal normal 500 12px Poppins",
  textAlign: "left",
  color: "#353535",
  variant: "caption",
  component: "span",
  [[theme.breakpoints.between("xs", "md")]]: {
    font: "normal normal 500 8px Poppins",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    font: "normal normal 500 10px Poppins",
  },
  [theme.breakpoints.up("lg")]: {
    font: "normal normal 500 12px Poppins",
  },
}));

const CountryCode = styled(Typography)(({ theme }) => ({
  font: "normal normal 400 14px Poppins",
  textAlign: "center",
  color: "#5a6066",
  vaiant: "h6",
  component: "p",
}));

const FormComponent = ({ formState, contentState, primaryColor }) => {
  const [isCheckedDisclaimer, setIsCheckedDisclaimer] = useState(false);

  const getLabelName = (meta) => {
    if (meta["required"] === true) {
      return `${meta["label"]}*`;
    } else {
      return meta["label"];
    }
  };

  return (
    <FormContainer>
      <Header
        sx={{
          color: primaryColor,
        }}
      >
        {contentState.form_title}
      </Header>
      <InputContainer component="form" onSubmit={(e) => e.preventDefault()}>
        <Grid container spacing={2}>
          {formState.map((fieldMeta, index) => {
            if (fieldMeta["field_type"] === "dropdown") {
              return (
                <Grid item xs={12} sm={12} md={12}>
                  <FieldWrapper>
                    <Label>{getLabelName(fieldMeta)}</Label>
                    <SelectComponent
                      name={fieldMeta["field_name"]}
                      fullWidth
                      required={fieldMeta["required"]}
                      input={
                        <OutlinedInputComponent
                          required={fieldMeta["required"]}
                        />
                      }
                      displayEmpty
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                    >
                      {fieldMeta["inputs"].map((item) => {
                        return <MenuItem value={item}>{item}</MenuItem>;
                      })}
                    </SelectComponent>
                  </FieldWrapper>
                </Grid>
              );
            } else if (fieldMeta["field_type"] === "text") {
              return (
                <Grid item xs={12} sm={12} md={12}>
                  <FieldWrapper>
                    <Label>{getLabelName(fieldMeta)}</Label>
                    <OutlinedInputComponent
                      required={fieldMeta["required"]}
                      placeholder={fieldMeta["label"]}
                      type="text"
                      name={fieldMeta["field_name"]}
                    />
                  </FieldWrapper>
                </Grid>
              );
            } else if (fieldMeta["field_type"] === "integer") {
              return (
                <Grid item xs={12} sm={12} md={12}>
                  <FieldWrapper>
                    <Label>{getLabelName(fieldMeta)}</Label>
                    <OutlinedInputComponent
                      required={fieldMeta["required"]}
                      placeholder={fieldMeta["label"]}
                      type="number"
                      name={fieldMeta["field_name"]}
                    />
                  </FieldWrapper>
                </Grid>
              );
            } else if (fieldMeta["field_type"] === "phone") {
              return (
                <Grid item xs={12} sm={12} md={12}>
                  <FieldWrapper>
                    <Label>{getLabelName(fieldMeta)}</Label>
                    <OutlinedInputComponent
                      required={fieldMeta["required"]}
                      placeholder={fieldMeta["label"]}
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "^[0-9]{10}$",
                        maxLength: 10,
                      }}
                      name={fieldMeta["field_name"]}
                      startAdornment={
                        <InputAdornment position="start">
                          <CountryCode>+91</CountryCode>
                        </InputAdornment>
                      }
                    />
                  </FieldWrapper>
                </Grid>
              );
            } else if (fieldMeta["field_type"] === "email") {
              return (
                <Grid item xs={12} sm={12} md={12}>
                  <FieldWrapper>
                    <Label>{getLabelName(fieldMeta)}</Label>
                    <OutlinedInputComponent
                      required={fieldMeta["required"]}
                      placeholder={fieldMeta["label"]}
                      name={fieldMeta["field_name"]}
                      type="email"
                    />
                  </FieldWrapper>
                </Grid>
              );
            }
          })}
          <Grid item xs={12}>
            <DisclaimerWrapper>
              <FormControlLabel
                sx={{ alignItems: "start" }}
                onChange={() => {
                  setIsCheckedDisclaimer(!isCheckedDisclaimer);
                }}
                control={
                  <Checkbox
                    color="primary"
                    checked={isCheckedDisclaimer}
                    sx={{ paddingTop: "0px" }}
                  />
                }
                label={<SubHeader>{contentState["disclaimer"]}</SubHeader>}
              />
            </DisclaimerWrapper>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Button
              disabled={!isCheckedDisclaimer}
              variant="contained"
              fullWidth
              type="submit"
              style={{
                textTransform: "none",
                color: "white",
                font: "normal normal 700 16px open sans",
              }}
            >
              SUBMIT
            </Button>
          </Grid>
        </Grid>
      </InputContainer>
    </FormContainer>
  );
};

export default FormComponent;
