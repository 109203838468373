import {
  Box,
  Dialog,
  CircularProgress,
  DialogContent,
  DialogContentText,
} from "@mui/material";

const CustomLoadingDialog = (props) => {
  return (
    <>
      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ padding: "0px" }}>
          <DialogContentText id="alert-dialog-description">
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                width: "200px",
                padding: "20px",
                alignItems: "center",
                height: "60xp",
              }}
            >
              <CircularProgress />
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default CustomLoadingDialog;
