import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { CircularProgress, Drawer, Grid } from "@material-ui/core";
import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import MuiAlert from "@mui/material/Alert";
import { getLocalizedText, getSlug } from "../../../Functions";
import { getListApi } from "../../../Api";
import ParentValueCardWidget from "./ParentValueCardWidget";
import ConfigureChildColumnSidepanel from "./ConfigureChildColumnSidepanel";
import ViewColumnSidepanel from "../../view_column_sidepanel/ViewColumnSidepanel";
import { LoadingButton } from "@mui/lab";
const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;
const TableWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
`;

const Text = styled(Typography)`
  font: normal normal 500 14px Open Sans;
`;
const Wrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;

const ParentFieldConfigurationSidepanel = ({
  setOpen,
  childrenConfig,
  setChildrenConfig,
  filterInput,
  columnsList,
  columnGroupsList,
  projectID,
  parentFieldData,
  selectedParentColumnGroup,
  parentFieldExistingData,
  childrenToRemove,
  setChildrenToRemove,
}) => {
  const [loading, setLoading] = useState(true);
  const [tempChildrenConfig, setTempChildrenConfig] = useState({});
  const [selectedChildColumnData, setSelectedChildColumnData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [openChildConfigPanel, setOpenChildConfigPanel] = useState(false);
  const [selectedParentValue, setSelectedParentValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [openInfoSidepanel, setOpenInfoSidepanel] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const getListDetails = async (filter_input) => {
    let tempType = "list";
    let tempList = [];
    if (filter_input.charAt(0) === "/" || filter_input.startsWith("ep:")) {
      let slug = getSlug(filter_input);
      let response = await getListApi({ slug: slug });
      if (response !== null) {
        if (Array.isArray(response)) {
          tempType = "list";
          tempList = response;
        } else if (typeof response === "object") {
          if (Object.keys(response).length > 0) {
            tempType = response["type"];
            tempList = response["values"];
          }
        }
      }
    } else if (
      filter_input.charAt(0) !== "/" &&
      filter_input !== "" &&
      filter_input !== null
    ) {
      try {
        let parseJSON = JSON.parse(filter_input);
        if (Array.isArray(parseJSON)) {
          tempType = "list";
          tempList = parseJSON;
        } else if (typeof parseJSON === "object") {
          if (Object.keys(parseJSON).length > 0) {
            tempType = parseJSON["type"];
            tempList = parseJSON["values"];
          }
        }
      } catch (error) {
        if (filter_input.length > 0) {
          let list = filter_input.split(",");
          tempType = "list";
          tempList = list;
        }
      }
    }
    return { list: tempList, type: tempType };
  };

  const init = async () => {
    let tempOptionsList = [];
    let tempListDetails = await getListDetails(filterInput);
    if (tempListDetails["type"] === "list") {
      tempListDetails["list"].forEach((str) => {
        let tempObj = { label: str, value: str };
        tempOptionsList.push(tempObj);
      });
    } else {
      tempListDetails["list"].forEach((obj) => {
        let tempObj = {
          label: obj["name"],
          value: obj["value"],
        };
        tempOptionsList.push(tempObj);
      });
    }
    let tempConfig = {};
    tempOptionsList.forEach((option) => {
      if (childrenConfig.hasOwnProperty(option["value"])) {
        tempConfig[option["value"]] = childrenConfig[option["value"]];
      } else {
        tempConfig[option["value"]] = [];
      }
    });
    setTempChildrenConfig(tempConfig);
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setChildrenConfig(tempChildrenConfig);
    setOpen(false);
  };

  const handleRemoveChildColumnLocally = ({ childData }) => {
    let tempChildToRemoveList = [...childrenToRemove];
    let index = tempChildToRemoveList.findIndex(
      (col) => col["column_id"] === childData["column_id"]
    );
    if (index === -1) {
      let tempChildData = {
        ...childData,
        child_of: null,
        filter_input: "",
        map_to_parent_selection: false,
      };
      tempChildToRemoveList.push(tempChildData);
    }
    setChildrenToRemove(tempChildToRemoveList);
    let tempList = { ...tempChildrenConfig };

    for (let parentValue in tempList) {
      let tempColumnList = [...tempList[parentValue]];
      tempColumnList = tempColumnList.filter(
        (column) => column["column_id"] !== childData["column_id"]
      );
      tempList[parentValue] = tempColumnList;
    }
    setTempChildrenConfig(tempList);
  };

  const getDataToShowInViewPanel = () => {
    let tempObj = { ...selectedChildColumnData };
    if (tempObj["_update_required"]) {
      tempObj["headerName"] = tempObj["display_name"];
    }
    return tempObj;
  };

  //callling this function in post add and post edit callback
  const updateChildrenToRemove = ({ columnID }) => {
    let index = childrenToRemove.findIndex(
      (entry) => entry["column_id"] === columnID
    );
    if (index !== -1) {
      let tempChildrenToRemove = [...childrenToRemove].filter(
        (entry) => entry["column_id"] !== columnID
      );
      setChildrenToRemove(tempChildrenToRemove);
    }
  };

  return !loading ? (
    <>
      <Box
        role="presentation"
        sx={{ width: 500 }}
        component={"form"}
        onSubmit={handleSubmit}
      >
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: 500,
            right: 0,
            bgcolor: "#f9f9fc",
          }}
        >
          <Toolbar style={{ padding: "16px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <ModelHeader>Parent Child Configuration</ModelHeader>
            </Box>
          </Toolbar>
        </AppBar>
        {Object.keys(tempChildrenConfig).length > 0 ? (
          <TableWrapper>
            <Grid container spacing={2}>
              {Object.keys(tempChildrenConfig).map((key, index) => {
                const cardInfo = {
                  parent_value: key,
                  child_list: tempChildrenConfig[key],
                };
                return (
                  <Grid item xs={12} key={index}>
                    <ParentValueCardWidget
                      cardInfo={cardInfo}
                      onRemove={(childData) => {
                        handleRemoveChildColumnLocally({
                          childData: childData,
                        });
                      }}
                      onAdd={() => {
                        setSelectedParentValue(key);
                        setIsEdit(false);
                        setOpenChildConfigPanel(true);
                      }}
                      onEdit={(childData) => {
                        setSelectedParentValue(key);
                        setSelectedChildColumnData(childData);
                        setIsEdit(true);
                        setOpenChildConfigPanel(true);
                      }}
                      onView={(childData) => {
                        setSelectedChildColumnData(childData);
                        setOpenInfoSidepanel(true);
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </TableWrapper>
        ) : (
          <Wrapper>
            <Text>No data.</Text>
          </Wrapper>
        )}
        <AppBar
          position="fixed"
          color="inherit"
          elevation={0}
          sx={{ top: "auto", bottom: 0, width: 500 }}
        >
          <Toolbar
            style={{ width: 500, padding: "16px", position: "relative" }}
          >
            {isSubmitFail && (
              <ErrorAlert
                onClose={(e) => {
                  e.stopPropagation();
                  setIsSubmitFail(false);
                }}
                severity="error"
                sx={{ width: "100%" }}
              >
                {errorMessage}
              </ErrorAlert>
            )}
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={6}>
                <Button
                  onClick={() => {
                    setOpen(false);
                  }}
                  variant="contained"
                  color="inherit"
                  style={{
                    fontSize: "12px",
                    textTransform: "none",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  {getLocalizedText("cancel")}
                </Button>
              </Grid>
              <Grid item xs={6}>
                {isSaving ? (
                  <LoadingButton
                    loading
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: "none",
                      width: "100%",
                    }}
                  >
                    <span> {getLocalizedText("save")}</span>
                  </LoadingButton>
                ) : (
                  <Button
                    disabled={Object.keys(tempChildrenConfig).length === 0}
                    color="primary"
                    type="submit"
                    variant="contained"
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "fit-content",
                      textTransform: "none",
                      width: "100%",
                    }}
                  >
                    {getLocalizedText("save")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
      {openChildConfigPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openChildConfigPanel}
        >
          <ConfigureChildColumnSidepanel
            childrenToRemove={childrenToRemove}
            setChildrenToRemove={setChildrenToRemove}
            selectedParentValue={selectedParentValue}
            parentFieldData={parentFieldData}
            parentFieldExistingData={parentFieldExistingData}
            columnGroupsList={columnGroupsList}
            columnsList={columnsList}
            editableData={selectedChildColumnData}
            id={projectID}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            setOpen={setOpenChildConfigPanel}
            handlePostSubmitAddColumn={async (columnData) => {
              let tempList = { ...tempChildrenConfig };
              if (tempList.hasOwnProperty(selectedParentValue)) {
                tempList[selectedParentValue].push(columnData);
              } else {
                tempList[selectedParentValue] = [columnData];
              }

              setTempChildrenConfig(tempList);
              updateChildrenToRemove({ columnID: columnData["column_id"] });
              setSelectedChildColumnData({});
              setSelectedParentValue("");
              setOpenChildConfigPanel(false);
            }}
            handlePostSubmitEditColumn={async (columnData) => {
              let tempList = { ...tempChildrenConfig };
              if (tempList.hasOwnProperty(selectedParentValue)) {
                let tempColumnList = [...tempList[selectedParentValue]];
                let index = tempColumnList.findIndex(
                  (column) => column["column_id"] === columnData["column_id"]
                );
                if (index !== -1) {
                  tempColumnList[index] = columnData;
                } else {
                  tempColumnList.push(columnData);
                }
                tempList[selectedParentValue] = tempColumnList;

                setTempChildrenConfig(tempList);
                updateChildrenToRemove({ columnID: columnData["column_id"] });
              }
              setSelectedChildColumnData({});
              setSelectedParentValue("");
              setOpenChildConfigPanel(false);
            }}
            selectedParentColumnGroup={selectedParentColumnGroup}
          />
        </Drawer>
      )}

      {openInfoSidepanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openInfoSidepanel}
          onClose={() => {
            setOpenInfoSidepanel(false);
          }}
        >
          <ViewColumnSidepanel
            data={getDataToShowInViewPanel()}
            handleEdit={() => {}}
            handleDelete={() => {}}
            scope="add_children_form"
          />
        </Drawer>
      )}
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: 500,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default observer(ParentFieldConfigurationSidepanel);
