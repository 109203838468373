import { action, makeObservable, observable } from "mobx";
import { makePersistable } from "mobx-persist-store";

class QuotesPageState {
  quotesChipIndex = 0;
  constructor() {
    makeObservable(this, {
      quotesChipIndex: observable,
      updateQuotesChipIndex: action,
      init: action,
      resetStore: action,
    });

    makePersistable(this, {
      name: QuotesPageState,
      storage: window.localStorage,
      expireIn: 86400000,
      removeOnExpiration: true,
      stringify: true,
      debugMode: false,
      properties: ["quotesChipIndex"],
    });
  }

  updateQuotesChipIndex = (index) => {
    this.quotesChipIndex = index;
  };

  resetStore = () => {
    this.quotesChipIndex = 0;
  };

  init = async () => {
    
  };
}

export default QuotesPageState;
