import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
} from "@material-ui/core";
import React, { useState } from "react";
import styled from "styled-components";
import { Divider } from "@mui/material";

const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: 450px;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 20px;
`;
const ModelWrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
`;
const Header = styled.p`
  margin: 0px;
  font: normal normal 600 18px Open Sans;
`;
const SubHeader = styled.p`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
  color: #5a5a5a;
`;

const FlagText = styled.p`
  margin: 0px;
  font: normal normal 500 12px Open Sans;
`;
const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const LeadsAssignModal = (props) => {
  const [canDownloadAll, setCanDownloadAll] = useState(false);

  return (
    <>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <ModelWrapper>
              <Header>{`Generate CSV For Selected Rows`}</Header>

              <Divider style={{ margin: "10px 0px" }} />
              <SubHeader>
                Are you sure you want to generate the csv for selected rows?
              </SubHeader>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={canDownloadAll}
                    onChange={(e) => {
                      setCanDownloadAll(e.target.checked);
                    }}
                  />
                }
                label={
                  <FlagText>{`Check this to download all ${props.totalLeadCount} Lead(s)`}</FlagText>
                }
              />
              <ButtonWrapper>
                <Button
                  color="primary"
                  style={{ textTransform: "capitalize", fontWeight: "bold" }}
                  onClick={props.handleCancel}
                >
                  No
                </Button>
                <Button
                  color="primary"
                  style={{ textTransform: "capitalize", fontWeight: "bold" }}
                  onClick={() => {
                    props.handleConfirm(canDownloadAll);
                  }}
                  autoFocus
                >
                  Yes
                </Button>
              </ButtonWrapper>
            </ModelWrapper>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default LeadsAssignModal;
