import React, { useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import Footer from "../../components/landing_page_components/footer/Footer";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { billingSectionColumns } from "../../Db";
import { IconButton } from "@material-ui/core";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { pageSize } from "../../Config";
import { Box, Grid, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { Attachment } from "@material-ui/icons";
import { getInvoiceListApi, getSubscriptionDetailsApi } from "../../Api";
import PlanCardWidget from "../../components/billing_page_components/PlanCardWidget";
import BillCardWidget from "../../components/billing_page_components/BillCardWidget";
import LoadingAnimationComponent from "../../components/loading_component/LoadingAnimationComponent";
const Container = styled(Box)`
  margin-top: 64px;
  background-color: #f9f9fc;
  ${`min-height: calc(100vh - 140px);`};
`;
const Wrapper = styled(Box)`
  width: 95%;
  margin: auto;
  display: flex;
  flex-direction: column;
`;

const ProgressWrapper = styled(Box)`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  min-height: 40px;
  padding: 20px 0px;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  align-items: center;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 28px Open Sans;
  color: #4d4e4f;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 446px);`};
  background-color: white;
  padding: 20px;
`;
const PaginationWrapper = styled(Box)`
  padding: 0px 20px;
  background-color: white;
`;

const Top = styled(Box)`
  width: 100%;
  margin-bottom: 24px;
`;

const BillingSection = () => {
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(pageSize);
  const subscriptionData = rootStore.subscriptionState.subscritionDetails;
  const [initLoading, setInitLoading] = useState(true);

  const getInvoiceList = async ({ page, page_size }) => {
    setLoading(true);
    setRow([]);
    let response = await getInvoiceListApi({
      page: page,
      page_size: page_size,
    });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
      setCount(response.item_count);
    } else {
      setRow([]);
      setCount(0);
    }
    setLoading(false);
  };

  const init = async () => {
    await getInvoiceList({ page: 0, page_size: pageSize });
    setInitLoading(false);
  };

  const handlePageSize = async (e) => {
    setPage(0);
    setSize(e.target.value);
    await getInvoiceList({ page: 0, page_size: e.target.value });
  };
  const handlePagination = async (e, page) => {
    setPage(page - 1);

    await getInvoiceList({ page: page - 1, page_size: size });
  };

  useEffect(() => {
    if (refresh === true) {
      init();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    init();
    rootStore.authStore.updateLeadDetailStatus();
  }, []);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const fileColumn = [
    {
      field: "invoice_file",
      headerName: "INVOICE FILE",
      flex: 1,
      minWidth: 100,
      cellRenderer: (params) => {
        return (
          <Box sx={{ height: "100%", width: "100%" }}>
            <IconButton
              onClick={() => {
                window.open(params.data.invoice_file, "_blank");
              }}
              style={{
                width: "20px",
                height: "20px",
                padding: "0px",
              }}
            >
              <Attachment
                style={{
                  cursor: "pointer",
                  width: "20px",
                  height: "20px",
                }}
                color="primary"
              />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const getPastDueAmount = useCallback(() => {
    if (row.length > 0) {
      if (row[0]["balance_due"] !== null) {
        return row[0]["balance_due"];
      }
    }
    return null;
  }, [row]);
  return !initLoading ? (
    <>
      <Container>
        <Wrapper>
          <Row>
            <HeaderWrapper>
              <Header>Billing</Header>
            </HeaderWrapper>
          </Row>

          <Top>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <PlanCardWidget details={subscriptionData} />
              </Grid>
              <Grid item xs={6}>
                <BillCardWidget
                  details={subscriptionData}
                  dueAmount={getPastDueAmount()}
                />
              </Grid>
            </Grid>
          </Top>

          <TableWrapper>
            {!loading ? (
              <div className="ag-theme-alpine" style={{ width: "100%" }}>
                <AgGridReact
                  domLayout="autoHeight"
                  gridOptions={gridOptions}
                  rowData={row}
                  defaultColDef={defaultColDef}
                  columnDefs={billingSectionColumns.concat(fileColumn)}
                  animateRows={true}
                  suppressCellFocus
                  pagination={false}
                  paginationPageSize={pageSize}
                />
              </div>
            ) : (
              <ProgressWrapper>
                <LoadingAnimationComponent size={"small"} />
              </ProgressWrapper>
            )}
          </TableWrapper>
          <PaginationWrapper>
            <PaginationComponent
              page_no={page}
              row={row}
              page_size={size}
              size={size}
              count={count}
              handlePageSize={handlePageSize}
              handlePage={handlePagination}
            />
          </PaginationWrapper>
        </Wrapper>
      </Container>
      <Footer />
    </>
  ) : (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <LoadingAnimationComponent size={"small"} />
    </Box>
  );
};

export default observer(BillingSection);
