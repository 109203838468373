import {
  AppBar,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  Modal,
  Select,
  Toolbar,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { getLocalizedText } from "../../../Functions";
import { myTheme } from "../../../themeUtils";
import rootStore from "../../../stores/RootStore";
import { leadFormQuestionTypeList, optionFieldsList } from "../../../Db";
import {
  createLeadFormSectionQuestionApi,
  updateLeadFormSectionQuestionApi,
} from "../../../Api";
import MuiAlert from "@mui/material/Alert";
const dialogWidth = 420;
const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const FlagText = styled(Typography)`
  margin: 0px;
  font: normal normal 500 12px Open Sans;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 16px;
  flex-direction: column;
  row-gap: 20px;
`;
const Message = styled(Typography)`
  font: normal normal normal 16px Open Sans;
  color: #4d4e4f;
  margin: 0px;
  text-align: center;
`;
const AddQuestionDialog = ({
  open,
  setOpen,
  handleAddQuestion,
  isEdit,
  setIsEdit,
  handleEditQuestion,
  questionData,
  selectedSectionID,
  sectionList,
  selectedQuestionID,
  formData,
}) => {
  const allColumnsList = [...rootStore.userStore.AllColumnsList];
  const classes = useStyles();
  const [columnsList, setColumnsList] = useState([]);
  const [details, setDetails] = useState({
    question: "",
    desc: "",
    column_id: "",
    question_type: "",
    required: false,
  });
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const create = async () => {
    let sequence = -1;

    let tempList = [...sectionList];
    let index = tempList.findIndex(
      (section) => section["id"] === selectedSectionID
    );
    if (index !== -1) {
      let sectionData = tempList[index];

      sequence = sectionData["questions"].length;
    }

    let payload = {
      ...details,
      sequence: sequence,
    };
    let response = await createLeadFormSectionQuestionApi({
      formID: formData["id"],
      sectionID: selectedSectionID,
      payload: payload,
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setIsSubmitSuccess(true);
      let tempObj = { ...details, sequence: sequence, id: response.data.id };
      handleAddQuestion(tempObj);
    }
  };

  const update = async () => {
    let sequence = -1;
    let tempSectionList = [...sectionList];
    let index = tempSectionList.findIndex(
      (section) => section["id"] === selectedSectionID
    );

    if (index !== -1) {
      let tempSectionData = tempSectionList[index];
      let questionIndex = tempSectionData["questions"].findIndex(
        (question) => question["id"] === selectedQuestionID
      );
      if (questionIndex !== -1) {
        let questionData = tempSectionData["questions"][questionIndex];
        sequence = questionData["sequence"];
      }
    }

    let payload = {
      ...details,
      sequence: sequence,
    };
    let response = await updateLeadFormSectionQuestionApi({
      formID: formData["id"],
      sectionID: selectedSectionID,
      questionID: selectedQuestionID,
      payload: payload,
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setIsSubmitSuccess(true);
      let tempObj = { ...details, sequence: sequence, id: response.data.id };
      handleEditQuestion(tempObj);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsFetching(true);
    if (isEdit) {
      await update();
    } else {
      await create();
    }
    setIsFetching(false);
  };
  const handleClose = () => {
    setIsEdit(false);
    setOpen(false);
  };
  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const canShowColumnInSelectColumn = ({ columnMeta }) => {
    for (let i = 0; i < sectionList.length; i++) {
      let columnIDIndex = sectionList[i]["questions"].findIndex(
        (question) => question["column_id"] === columnMeta["id"]
      );
      if (columnIDIndex !== -1) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    let filteredColumns = allColumnsList.filter(
      (column) =>
        (isEdit && questionData["column_id"] === column["id"]) ||
        (column["is_form_field"] &&
          canShowColumnInSelectColumn({ columnMeta: column }))
    );
    filteredColumns.sort((a, b) =>
      a["headerName"].toLowerCase().localeCompare(b["headerName"].toLowerCase())
    );
    setColumnsList(filteredColumns);
    if (isEdit) {
      let tempObj = {
        question: questionData["question"],
        desc: questionData["desc"],
        column_id: questionData["column_id"],
        question_type: questionData["question_type"],
        required: questionData["required"],
      };
      setDetails(tempObj);
    }
  }, []);

  const canShowQuestionType = (questionType) => {
    let index = columnsList.findIndex(
      (column) => column["id"] === details["column_id"]
    );
    if (index !== -1) {
      let columnMeta = columnsList[index];
      if (columnMeta["filter_type"] === "list") {
        if (columnMeta["parent"] === true) {
          let filteredOptionFieldList = optionFieldsList.filter(
            (option) =>
              option !== "checkbox" && option !== "checkbox_with_other"
          );
          return filteredOptionFieldList.includes(questionType);
        } else {
          return optionFieldsList.includes(questionType);
        }
      } else if (
        columnMeta["data_type"] === "date" ||
        columnMeta["data_type"] === "datetime"
      ) {
        return questionType === columnMeta["data_type"];
      } else if (columnMeta["data_type"] === "string") {
        return questionType === "string";
      } else if (columnMeta["data_type"] === "integer") {
        return questionType === "number";
      } else {
        return !optionFieldsList.includes(questionType);
      }
    }
    return false;
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!isSubmitSuccess ? (
          <ModelContainer>
            <Box component={"form"} onSubmit={handleSubmit}>
              <AppBar
                component={"nav"}
                elevation={0}
                position="sticky"
                color="inherit"
                sx={{
                  top: 0,
                  bottom: "auto",
                  width: "100%",
                  right: 0,
                }}
              >
                <Toolbar
                  style={{
                    padding: "16px",
                  }}
                >
                  <HeaderWrapper>
                    {isEdit ? (
                      <Header>Edit Question</Header>
                    ) : (
                      <Header>Add a new question</Header>
                    )}
                    <SubHeader>
                      {getLocalizedText("fill_in_the_below_details")}
                    </SubHeader>
                  </HeaderWrapper>
                </Toolbar>
              </AppBar>
              <Divider style={{ margin: "0px 16px" }} />
              <Container>
                <Box
                  style={{
                    height: "100%",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <Label>Question*</Label>
                        <OutlinedInput
                          style={{ height: "30px" }}
                          inputProps={{
                            min: 0,
                            "aria-label": "weight",
                            style: {
                              fontSize: "12px",
                            },
                          }}
                          onChange={handleChange}
                          value={details.question}
                          required
                          type={"text"}
                          name="question"
                          placeholder={"Enter Value"}
                        />
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <Label>Column*</Label>
                        <FormControl
                          sx={{ width: "100%" }}
                          classes={{
                            root: classes.quantityRoot,
                          }}
                        >
                          <Select
                            classes={{
                              icon: classes.icon,
                            }}
                            value={details.column_id}
                            name="column_id"
                            displayEmpty
                            disableUnderline
                            variant="outlined"
                            required
                            onChange={(e) => {
                              setDetails({
                                ...details,
                                column_id: e.target.value,
                                question_type: "",
                              });
                            }}
                            style={{
                              width: "100%",
                              height: "30px",
                              fontSize: "12px",
                              fontWeight: "bold",
                              borderRadius: "0px",
                            }}
                          >
                            {columnsList.map((column, index) => {
                              return (
                                <MenuItem
                                  id={index}
                                  key={index}
                                  value={column.id}
                                >
                                  {column.headerName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <Label>Question Type*</Label>
                        <FormControl
                          sx={{ width: "100%" }}
                          classes={{
                            root: classes.quantityRoot,
                          }}
                        >
                          <Select
                            classes={{
                              icon: classes.icon,
                            }}
                            disabled={details.column_id === ""}
                            value={details.question_type}
                            name="question_type"
                            displayEmpty
                            disableUnderline
                            variant="outlined"
                            required
                            onChange={handleChange}
                            style={{
                              width: "100%",
                              height: "30px",
                              fontSize: "12px",
                              fontWeight: "bold",
                              borderRadius: "0px",
                            }}
                          >
                            {leadFormQuestionTypeList.map((type, index) => {
                              if (canShowQuestionType(type.value)) {
                                return (
                                  <MenuItem
                                    id={index}
                                    key={index}
                                    value={type.value}
                                  >
                                    {type.label}
                                  </MenuItem>
                                );
                              }
                            })}
                          </Select>
                        </FormControl>
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={details.required}
                            color="primary"
                          />
                        }
                        label={<FlagText>Required</FlagText>}
                        onChange={(e) => {
                          setDetails({
                            ...details,
                            required: e.target.checked,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <Label>Description*</Label>
                        <OutlinedInput
                          multiline
                          rows={3}
                          inputProps={{
                            min: 0,
                            "aria-label": "weight",
                            style: {
                              fontSize: "12px",
                            },
                          }}
                          onChange={handleChange}
                          value={details.desc}
                          name="desc"
                          type={"text"}
                          required
                          placeholder={"Enter Value"}
                        />
                      </InputWrapper>
                    </Grid>
                  </Grid>
                </Box>
              </Container>
              <AppBar
                position="static"
                color="inherit"
                elevation={0}
                sx={{ top: "auto", bottom: 0, width: "100%" }}
              >
                <Toolbar
                  style={{
                    padding: "16px",
                    width: dialogWidth,
                    position: "relative",
                  }}
                >
                  {isSubmitFail && (
                    <ErrorAlert
                      onClose={(e) => {
                        e.stopPropagation();
                        setIsSubmitFail(false);
                      }}
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      {errorMessage}
                    </ErrorAlert>
                  )}
                  <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item>
                      <Button
                        onClick={() => {
                          setOpen(false);
                        }}
                        variant="contained"
                        color="default"
                        style={{
                          fontSize: "12px",
                          textTransform: "none",
                          fontWeight: "bold",
                        }}
                      >
                        {getLocalizedText("cancel")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        disabled={isFetching}
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          width: "fit-content",
                          textTransform: "none",
                        }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
            </Box>
          </ModelContainer>
        ) : (
          <ModelContainer
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MessageWrapper style={{ marginBottom: "0px", padding: "16px" }}>
              {isEdit ? (
                <Message>Question updated successfully!</Message>
              ) : (
                <Message>Question created successfully!</Message>
              )}

              <Button
                onClick={handleClose}
                type="button"
                variant="contained"
                color="default"
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "100px",
                }}
              >
                {getLocalizedText("close")}
              </Button>
            </MessageWrapper>
          </ModelContainer>
        )}
      </Modal>
    </>
  );
};

export default AddQuestionDialog;
