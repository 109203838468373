import React, { useState } from "react";
import styled from "styled-components";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { Box, Divider, Modal, TextField } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import moment from "moment";
import { Button } from "@mui/material";
import { useEffect } from "react";
import { DatePicker } from "antd";
import "./style.css";
import spinner from "../../assets/images/gifs/spinner.gif";
import { getFilteredLeadsApi } from "../../Api";
import MultiselectDropdown from "../multiselect_dropdown/MultiselectDropdown";
import NewMultiselectDropdown from "../multiselect_dropdown/NewMultiselectDropdown";
import { useNavigate } from "react-router-dom";
import { pageSize } from "../../Config";
import { toJS } from "mobx";
const Spinner = styled.img`
  height: 120px;
  width: 180px;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;
const Title = styled.span`
  font: normal normal 600 24px Poppins;
  text-align: center;
`;
const Label = styled.span`
  font: normal normal normal 12px Poppins;
`;
const Input = styled.input`
  width: 360px;
  height: 50px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #e0deca;
  font: normal normal 600 16px Poppins;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal normal 16px Poppins;
    color: #b5b5b5;
    text-transform: lowercase;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;

const Row = styled.div`
  display: flex;
  /* width: 100%;
  margin-bottom: 10px; */
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  justify-content: center;
  align-items: center;
`;
const InputContainer = styled.div`
  display: flex;
  max-width: 570px;
  flex-wrap: wrap;
  @media (max-width: 700px) {
    max-width: 380px;
  }
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px;
`;
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

// this component is being used as global filter as well as to create target segement
const MainFilterModal = (props) => {
  const { authStore, userStore } = rootStore;
  const { RangePicker } = DatePicker;
  const [dateRange, setDateRange] = useState({});
  const [numRange, setNumRange] = useState({});
  const [selectedVal, setSelectedVal] = useState({});
  const allCols = [...rootStore.userStore.AllColumnsList];
  const [filters, setFilters] = useState();
  const [segmentName, setSegmentName] = useState("");

  let pid = authStore.projectId;
  let navigate = useNavigate();

  const handleClick = () => {
    props.setOpen(false);
    props.setType(false);
  };
  const handleChange = ({ e, dataType }) => {
    let value =
      dataType === "integer" ? parseInt(e.target.value) : e.target.value;
    setFilters({ ...filters, [e.target.name]: value });
  };
  const handleClose = () => {
    props.setOpen(false);
    props.setType(false);
  };
  const handleFilter = (e) => {
    userStore.setGlobalFilters({ filters, dateRange, numRange, selectedVal });
    navigate("/");
    props.setLoading(true);
    props.setPage(0);
    const getFilteredLeads = async () => {
      let response = await getFilteredLeadsApi(pageSize);
      props.setRow(response.data.items);
      props.setCount(response.data.item_count);
      props.setLoading(false);
      props.setIsFiltered(true);
      props.setOpen(false);
    };
    getFilteredLeads();
  };
  const handleSegment = () => {
    userStore.setSegmentsList({
      segmentName,
      filters,
      dateRange,
      numRange,
      selectedVal,
    });
    props.setOpen(false);
    props.setType(false);
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <Close
              onClick={handleClick}
              style={{
                height: "18px",
                width: "18px",
                position: "absolute",
                top: "20px",
                right: "30px",
                cursor: "pointer",
              }}
            />
            <Container>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                {props.type && (
                  <>
                    <Title>Create Segment</Title>
                    <InputWrapper
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: "12px",
                      }}
                    >
                      <Label style={{ fontSize: "16px", marginRight: "8px" }}>
                        Segment Name :
                      </Label>
                      <TextField
                        onChange={(e) => {
                          setSegmentName(...segmentName, e.target.value);
                        }}
                        id="segment_name"
                        variant="standard"
                        style={{ width: "200px", fontSize: "16px" }}
                      />
                    </InputWrapper>
                    <Divider />
                  </>
                )}
                <InputContainer>
                  {allCols.length > 0 ? (
                    allCols.map((item) => {
                      if (item.filterable) {
                        if (item.filter_type === "normal") {
                          return (
                            <InputWrapper>
                              <Label>{item.headerName}</Label>
                              <Input
                                type={
                                  item.data_type === "integer"
                                    ? "number"
                                    : "text"
                                }
                                name={item.field}
                                placeholder={`enter ${item.headerName}`}
                                style={{ width: "170px" }}
                                onChange={(e) => {
                                  let dataType = item.data_type;
                                  handleChange({ e, dataType });
                                }}
                              />
                            </InputWrapper>
                          );
                        } else if (item.filter_type === "range") {
                          if (item.data_type === "datetime") {
                            return (
                              <InputWrapper>
                                <Label>{item.headerName}</Label>
                                <RangePicker
                                  placeholder={["From", "To"]}
                                  getPopupContainer={(triggerNode) => {
                                    return triggerNode.parentNode;
                                  }}
                                  style={{ height: "50px", width: "350px" }}
                                  onChange={(value) => {
                                    let d1 = moment(value[0]).format(
                                      "YYYY-MM-DD"
                                    );
                                    let d2 = moment(value[1]).format(
                                      "YYYY-MM-DD"
                                    );
                                    setDateRange({
                                      ...dateRange,
                                      [item.field]: { start: d1, end: d2 },
                                    });
                                  }}
                                />
                              </InputWrapper>
                            );
                          } else if (item.data_type === "integer") {
                            return (
                              <InputWrapper>
                                <Label>{item.headerName}</Label>
                                <Row>
                                  <Input
                                    type="number"
                                    name="start"
                                    placeholder="min"
                                    onChange={(e) => {
                                      let start = parseInt(e.target.value);
                                      setNumRange({
                                        ...numRange,
                                        [item.field]: { start: start },
                                      });
                                    }}
                                    style={{
                                      width: "80px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  <Input
                                    type="number"
                                    name="end"
                                    placeholder="max"
                                    onChange={(e) => {
                                      let start = parseInt(
                                        numRange[item.field]["start"]
                                      );
                                      let end = parseInt(e.target.value);
                                      setNumRange({
                                        ...numRange,
                                        [item.field]: {
                                          start: start,
                                          end: end,
                                        },
                                      });
                                    }}
                                    style={{ width: "80px" }}
                                  />
                                </Row>
                              </InputWrapper>
                            );
                          }
                        } else if (item.filter_type === "list") {
                          if (item.filter_input.charAt(0) === "/") {
                            let ep = item.filter_input.slice(1);
                            return (
                              <MultiselectDropdown
                                ep={ep}
                                headerName={item.headerName}
                                setSelectedVal={setSelectedVal}
                                selectedVal={selectedVal}
                                field={item.field}
                              />
                            );
                          } else if (item.filter_input.charAt(0) !== "/") {
                            return (
                              <NewMultiselectDropdown
                                list={item.filter_input}
                                field={item.field}
                                headerName={item.headerName}
                                setSelectedVal={setSelectedVal}
                                selectedVal={selectedVal}
                              />
                            );
                          }
                        }
                      }
                    })
                  ) : (
                    <SpinnerWrapper>
                      <Spinner src={spinner} alt="loading..." />
                    </SpinnerWrapper>
                  )}
                </InputContainer>
                <ButtonWrapper>
                  <Button
                    onClick={props.type ? handleSegment : handleFilter}
                    style={{
                      width: "fit-content",
                      backgroundColor: "#185DD2",
                      color: "white",
                    }}
                    variant="filled"
                  >
                    {props.type ? "Create" : "Apply"}
                  </Button>
                </ButtonWrapper>
              </Form>
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default observer(MainFilterModal);
