import React, { useEffect, useState } from "react";
import { Modal } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { Done } from "@material-ui/icons";
const ModalContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: 420px;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 20px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  column-gap: 10px;
  margin-top: 20px;
`;
const Message = styled(Typography)`
  font: normal normal 500 14px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Title = styled(Typography)`
  font: normal normal 600 20px Open Sans;
  color: #4d4e4f;
`;
const TimerText = styled(Typography)`
  font: normal normal 500 10px Open Sans;
  color: #4d4e4f;
`;
const RoundBox = styled(Box)`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SwitchProjectToRedirectDialog = (props) => {
  const [counter, setCounter] = useState(5);
  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      props.handleContinue();
    }
    return () => clearInterval(timer);
  }, [counter]);

  const RenderButton = () => {
    if (counter > 0) {
      return (
        <Button
          onClick={props.handleCancel}
          variant="contained"
          color="primary"
          style={{
            textTransform: "none",
            margin: "auto",

            display: "flex",
            justifyContent: "space-between",
          }}
          startIcon={
            <RoundBox>
              <TimerText>{counter}</TimerText>
            </RoundBox>
          }
        >
          Cancel
        </Button>
      );
    } else {
      return (
        <Button
          variant="contained"
          color="primary"
          disabled
          style={{
            backgroundColor: "#1976d2",
            color: "white",
            margin: "auto",

            textTransform: "none",
            textAlign: "right",
            display: "flex",
            justifyContent: "space-between",
          }}
          startIcon={
            props.isLoading ? (
              <CircularProgress
                style={{
                  color: "white",
                  height: "20px",
                  width: "20px",
                }}
              />
            ) : (
              <Done
                style={{
                  color: "white",
                  height: "20px",
                  width: "20px",
                }}
              />
            )
          }
        >
          {props.isLoading ? "Switching" : "Swtiched"}
        </Button>
      );
    }
  };
  return (
    <Modal
      open={props.open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContainer>
        <Wrapper>
          <Title>Confirmation</Title>
          <Divider style={{ margin: "10px 0px" }} />
          <Message>
            To see lead details, project switch is required. Do you want to
            switch project?
          </Message>
          <ButtonWrapper>
            <RenderButton />
          </ButtonWrapper>
        </Wrapper>
      </ModalContainer>
    </Modal>
  );
};

export default observer(SwitchProjectToRedirectDialog);
