import React from "react";
import RadioGroupOptionWidget from "./RadioGroupOptionWidget";
import RadioGroupWithOtherOptionWidget from "./RadioGroupWithOtherOptionWidget";
import CheckboxOptionWidget from "./CheckboxOptionWidget";
import DropdownOptionWidget from "./DropdownOptionWidget";
import CheckboxWithOtherOptionWidget from "./CheckboxWithOtherOptionWidget";

const RenderOptionFields = ({
  columnMeta,
  questionData,
  inputData,
  setInputData,
  prefilledData,
  setPrefilledData,
  listFieldInputMeta,
  isLeadFormDisabled,
  getFieldInputMeta,
}) => {
 
  const fieldInputMeta = getFieldInputMeta({
    columnMeta: columnMeta,
    inputMeta: listFieldInputMeta[columnMeta["field"]],
  });
  if (questionData["question_type"] === "radio") {
    return (
      <RadioGroupOptionWidget
        disabled={isLeadFormDisabled}
        list={fieldInputMeta.list}
        type={fieldInputMeta.type}
        value={
          prefilledData.hasOwnProperty(columnMeta["field"])
            ? prefilledData[columnMeta["field"]]["value"]
            : ""
        }
        handleChange={(value) => {
          setInputData({
            ...inputData,
            [columnMeta["field"]]: {
              value: value,
              type: questionData["question_type"],
            },
          });
          setPrefilledData({
            ...prefilledData,
            [columnMeta["field"]]: {
              value: value,
              type: questionData["question_type"],
            },
          });
        }}
      />
    );
  } else if (questionData["question_type"] === "radio_with_other") {
    return (
      <RadioGroupWithOtherOptionWidget
        disabled={isLeadFormDisabled}
        list={fieldInputMeta.list}
        type={fieldInputMeta.type}
        fieldData={
          prefilledData.hasOwnProperty(columnMeta["field"])
            ? prefilledData[columnMeta["field"]]
            : {}
        }
        value={
          prefilledData.hasOwnProperty(columnMeta["field"])
            ? prefilledData[columnMeta["field"]]["value"]
            : ""
        }
        handleChange={(value, otherValue) => {
          setInputData({
            ...inputData,
            [columnMeta["field"]]: {
              value: value,
              type: questionData["question_type"],
              other_value: otherValue,
            },
          });
          setPrefilledData({
            ...prefilledData,
            [columnMeta["field"]]: {
              value: value,
              type: questionData["question_type"],
              other_value: otherValue,
            },
          });
        }}
      />
    );
  } else if (questionData["question_type"] === "checkbox") {
    return (
      <CheckboxOptionWidget
        disabled={isLeadFormDisabled}
        list={fieldInputMeta.list}
        type={fieldInputMeta.type}
        value={
          prefilledData.hasOwnProperty(columnMeta["field"])
            ? prefilledData[columnMeta["field"]]["value"]
            : []
        }
        handleChange={(value) => {
          setInputData({
            ...inputData,
            [columnMeta["field"]]: {
              value: value,
              type: questionData["question_type"],
            },
          });
          setPrefilledData({
            ...prefilledData,
            [columnMeta["field"]]: {
              value: value,
              type: questionData["question_type"],
            },
          });
        }}
      />
    );
  } else if (questionData["question_type"] === "checkbox_with_other") {
    return (
      <CheckboxWithOtherOptionWidget
        disabled={isLeadFormDisabled}
        list={fieldInputMeta.list}
        type={fieldInputMeta.type}
        fieldData={
          prefilledData.hasOwnProperty(columnMeta["field"])
            ? prefilledData[columnMeta["field"]]
            : {}
        }
        value={
          prefilledData.hasOwnProperty(columnMeta["field"])
            ? prefilledData[columnMeta["field"]]["value"]
            : []
        }
        handleChange={(value, otherValue) => {
          setInputData({
            ...inputData,
            [columnMeta["field"]]: {
              value: value,
              type: questionData["question_type"],
              other_value: otherValue,
            },
          });
          setPrefilledData({
            ...prefilledData,
            [columnMeta["field"]]: {
              value: value,
              type: questionData["question_type"],
              other_value: otherValue,
            },
          });
        }}
      />
    );
  } else if (questionData["question_type"] === "dropdown") {
    return (
      <DropdownOptionWidget
        disabled={isLeadFormDisabled}
        list={fieldInputMeta.list}
        type={fieldInputMeta.type}
        value={
          prefilledData.hasOwnProperty(columnMeta["field"])
            ? prefilledData[columnMeta["field"]]["value"]
            : ""
        }
        handleChange={(event) => {
          setInputData({
            ...inputData,
            [columnMeta["field"]]: {
              value: event.target.value,
              type: questionData["question_type"],
            },
          });
          setPrefilledData({
            ...prefilledData,
            [columnMeta["field"]]: {
              value: event.target.value,
              type: questionData["question_type"],
            },
          });
        }}
      />
    );
  }
};

export default RenderOptionFields;
