import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  MenuItem,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { getColumnsForMapping, getRawLeadsColumnsApi } from "../../Api";
import { Divider, Select } from "@material-ui/core";
import { getLocalizedText } from "../../Functions";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: fit-content;
`;
const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  max-height: 400px;
  overflow-y: auto;
  width: 400px;
  padding: 10px 0px;
`;
const RowWrapper = styled(Box)`
  display: flex;
  column-gap: 20px;
  width: 100%;
`;
const CheckBoxTextWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 190px;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 18px Open Sans;
`;
const SubHeader = styled(Typography)`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
  color: #5a5a5a;
`;
const Label = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
`;

const ColumnWrapper = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;
const ColumnHeader = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  width: 50%;
`;
const MapRawLeadColumnToProject = (props) => {
  const [rawLeadColumns, setRawLeadColumns] = useState([]);
  const [projectColumns, setProjectColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const getRawLeadColumns = async () => {
    let response = await getRawLeadsColumnsApi();
    return response;
  };
  const getProjectColumnsForMapping = async () => {
    let response = await getColumnsForMapping(props.projectId);
    return response;
  };
  const initRawToProjectList = (rawColumnList, projectColumnList) => {
    let initList = {};
    rawColumnList.forEach((column) => {
      let index = projectColumnList.findIndex(
        (col) => col["field"] === column["column_name"]
      );
      let tempObj = {
        raw_lead: {
          id: column["id"],
          field: column["column_name"],
          display_name: column["display_name"],
        },
        project_lead: {},
        checked: false,
      };

      if (index !== -1) {
        tempObj["project_lead"] = {
          id: projectColumnList[index]["id"],
          field: projectColumnList[index]["field"],
          display_name: projectColumnList[index]["headerName"],
          data_type: projectColumnList[index]["data_type"],
          is_custom_param: projectColumnList[index]["is_custom_param"],
        };
      }
      initList[column["id"]] = tempObj;
    });
    return initList;
  };
  const setUp = async () => {
    let rawColumns = await getRawLeadColumns();
    let projectColumns = await getProjectColumnsForMapping();
    let new_init_list = initRawToProjectList(rawColumns, projectColumns);
    props.setProjectColumnList(projectColumns);
    props.setRawToProjectList(new_init_list);
    setRawLeadColumns(rawColumns);
    setProjectColumns(projectColumns);
    setLoading(false);
  };

  const getRawFieldHeaderName = (field) => {
    let index = rawLeadColumns.findIndex(
      (column) => column["column_name"] === field
    );
    if (index !== -1) {
      return rawLeadColumns[index]["display_name"];
    } else {
      return "";
    }
  };

  useEffect(() => {
    setUp();
  }, []);

  const isAlreadySelectedProjectField = (field) => {
    let flag = false;
    Object.keys(props.rawToProjectList).forEach((item) => {
      if (
        props.rawToProjectList[item]["project_lead"].hasOwnProperty("field") &&
        props.rawToProjectList[item]["project_lead"]["field"] === field
      ) {
        flag = true;
      }
    });
    return flag;
  };

  //below function is to get list of similar datatype fields based on rawlead column field

  const getSimilarDatatypeFieldList = (field_id) => {
    let index = rawLeadColumns.findIndex((column) => column["id"] === field_id);
    if (index !== -1) {
      let raw_field_data_type = rawLeadColumns[index]["data_type"];
      return projectColumns.filter(
        (column) => column["data_type"] === raw_field_data_type
      );
    } else {
      return [];
    }
  };

  return !loading ? (
    <Container>
      <Header>{getLocalizedText("move_to")}</Header>
      <SubHeader>{getLocalizedText("please_fill_the_below_details")}</SubHeader>
      <Divider style={{ margin: "10px 0px" }} />
      <ColumnWrapper>
        <ColumnHeader>{getLocalizedText("raw_lead_field")}</ColumnHeader>
        <ColumnHeader>{getLocalizedText("project_lead_field")}</ColumnHeader>
      </ColumnWrapper>
      <Wrapper>
        {Object.keys(props.rawToProjectList).map((item) => {
          return (
            <RowWrapper>
              <CheckBoxTextWrapper>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        props.setShowAlert(false);
                        props.setShowRequiredColumnAlert(false);
                        props.setRawToProjectList({
                          ...props.rawToProjectList,
                          [item]: {
                            ...props.rawToProjectList[item],
                            checked: e.target.checked,
                          },
                        });
                      }}
                      size="small"
                      checked={props.rawToProjectList[item]["checked"]}
                    />
                  }
                  label={
                    <Label>
                      {getRawFieldHeaderName(
                        props.rawToProjectList[item]["raw_lead"]["field"]
                      )}
                    </Label>
                  }
                />
              </CheckBoxTextWrapper>

              <Select
                value={
                  props.rawToProjectList[item]["project_lead"].hasOwnProperty(
                    "field"
                  )
                    ? props.rawToProjectList[item]["project_lead"]["field"]
                    : ""
                }
                required={props.rawToProjectList[item]["checked"]}
                label="Select Member"
                onChange={(e) => {
                  let index = projectColumns.findIndex(
                    (column) => column["field"] === e.target.value
                  );

                  props.setRawToProjectList({
                    ...props.rawToProjectList,
                    [item]: {
                      ...props.rawToProjectList[item],
                      project_lead: {
                        id: projectColumns[index]["id"],
                        field: e.target.value,
                        display_name: projectColumns[index]["headerName"],
                        data_type: projectColumns[index]["data_type"],
                        is_custom_param:
                          projectColumns[index]["is_custom_param"],
                      },
                    },
                  });
                }}
                style={{
                  width: "190px",
                  height: "30px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  borderRadius: "0px",
                }}
              >
                {getSimilarDatatypeFieldList(item).map((e, i) => {
                  return (
                    <MenuItem
                      disabled={isAlreadySelectedProjectField(e["field"])}
                      key={i}
                      style={{ fontSize: "12px" }}
                      id={e.id}
                      value={e.field}
                    >
                      <span style={{ color: "red" }}> {e.required && "*"}</span>{" "}
                      {e.headerName}
                    </MenuItem>
                  );
                })}
              </Select>
            </RowWrapper>
          );
        })}
      </Wrapper>
    </Container>
  ) : (
    <Container
      style={{
        height: "420px",
        width: "400px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Container>
  );
};

export default MapRawLeadColumnToProject;
