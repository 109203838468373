import React from "react";
import styled from "styled-components";
const Container = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 234px);`};

  position: relative;
  /* margin-top: 70px; */
`;
const Wrapper = styled.div`
  display: flex;
`;

const DetailsWrapper = styled.div`
  height: fit-content;
  width: 100%;
  background: white;
  border-radius: 10px;
  /* padding: 12px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;
const TableWrapper = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
`;
const DetailsTab = () => {
  return (
    <Container>
      <Wrapper>
        <DetailsWrapper>
          <TableWrapper></TableWrapper>
        </DetailsWrapper>
      </Wrapper>
    </Container>
  );
};

export default DetailsTab;
