import { TextField, styled } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import React from "react";
const CustomizedDateTimePicker = styled(DateTimePicker)`
  & .MuiInputBase-input {
    font-size: 12px;
    padding: 10.5px 14px;
    color: ${(e) => e.fieldColor};
  }
`;
const NextFollowUpDateTimeField = ({
  item,
  value,
  inputData,
  required,
  disabled,
  handleChange,
  getTimeOnNextFollowupDateChange,
  fieldValueColor = "#4d4e4f",
}) => {
  const [manualDateInput, setManualDateInput] = React.useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CustomizedDateTimePicker
        fieldColor={fieldValueColor}
        disabled={disabled}
        value={value}
        inputFormat="YYYY-MM-DD HH:mm A"
        renderInput={(params) => (
          <TextField
            onInput={() => {
              setManualDateInput(true);
            }} // set manual input
            required={required}
            sx={{ width: "100%" }}
            size="small"
            {...params}
          />
        )}
        onChange={(event) => {
          let value = null;

          if (manualDateInput) {
            value = event;
          } else {
            let momentValue = moment(event.toISOString());
            const lastDate =
              inputData.hasOwnProperty(item.field) &&
              inputData[item.field]["value"] !== null
                ? inputData[item.field]["value"].startOf("day")
                : null;
            const newDate = moment(event.toISOString()).startOf("day"); // Set to the start of the day to ignore time
            // Check if time is missing or zero (midnight)
            if (
              lastDate === null ||
              (lastDate !== null && !newDate.isSame(lastDate, "day"))
            ) {
              // Get the default time based on the selected date
              let time = getTimeOnNextFollowupDateChange(event.toISOString());

              // Set the date with the default time (from your custom logic)
              value = momentValue
                .hours(time.hours())
                .minutes(time.minutes())
                .seconds(0);
            } else {
              // If time is already set, use the event value
              value = momentValue;
            }
          }
          handleChange({
            fieldName: item["field"],
            fieldValue: value,
            dataType: item["data_type"],
          });
          setManualDateInput(false);      //reset manual input flag
        }}
        required={required}
      />
    </LocalizationProvider>
  );
};

export default NextFollowUpDateTimeField;
