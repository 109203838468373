import { Box, Card, Divider, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import QuestionCard from "./QuestionCard";
import rootStore from "../../../stores/RootStore";
import { Button } from "@material-ui/core";
import { Add, AddBoxOutlined, Delete, Edit, Save } from "@material-ui/icons";
import { myTheme } from "../../../themeUtils";
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
`;
const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 20px;
`;
const TitleText = styled(Typography)`
  font: normal normal 400 16px Open Sans;
  color: white;
`;

const Strip = styled(Box)`
  height: fit-content;
  padding: 12px 20px;
  width: 100%;
  background-color: #26599e;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TextLgHighlight = styled(Typography)`
  font: normal normal 600 16px Open Sans;
  margin: 0px;
`;

const SectionCard = ({
  title,
  questions,
  inputData,
  setInputData,
  listFieldInputMeta,
  handleAddQuestion,
  handleSectionDelete,
  handleSectionEdit,
  handleQuestionEdit,
  handleQuestionDelete,
  
}) => {
  const columnsList = [...rootStore.userStore.AllColumnsList];
  return (
    <Card
      sx={{
        borderRadius: "12px",
        boxShadow: "none",
        border: "1px solid rgb(218, 220, 224)",
      }}
    >
      <Strip>
        <TitleText>{title}</TitleText>
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          {questions.length > 0 && (
            <Tooltip title={"Add Question"}>
              <AddBoxOutlined
                style={{ color: "white", cursor: "pointer" }}
                onClick={handleAddQuestion}
              />
            </Tooltip>
          )}
          <Tooltip title={"Edit Section"}>
            <Edit
              style={{ color: "white", cursor: "pointer" }}
              onClick={handleSectionEdit}
            />
          </Tooltip>
          <Tooltip title={"Delete Section"}>
            <Delete
              style={{ color: "white", cursor: "pointer" }}
              onClick={handleSectionDelete}
            />
          </Tooltip>
         
        </Box>
      </Strip>
      <Container>
        {questions.length > 0 ? (
          <Wrapper>
            {questions.map((questionData, index) => {
              let column_index = columnsList.findIndex(
                (column) => column["id"] === questionData["column_id"]
              );
              return (
                <>
                  <QuestionCard
                    handleQuestionEdit={() =>
                      handleQuestionEdit(questionData["id"])
                    }
                    inputData={inputData}
                    setInputData={setInputData}
                    questionData={questionData}
                    listFieldInputMeta={listFieldInputMeta}
                    columnMeta={
                      column_index !== -1 ? columnsList[column_index] : {}
                    }
                    key={index}
                    handleQuestionDelete={() => {
                      handleQuestionDelete(questionData["id"]);
                    }}
                  />
                </>
              );
            })}
          </Wrapper>
        ) : (
          <Wrapper style={{ justifyContent: "center", alignItems: "center" }}>
            <Button
              onClick={handleAddQuestion}
              variant="text"
              style={{
                textTransform: "none",
                fontSize: "16px",
                color: myTheme.Button.btnBlue.backgroundColor,
              }}
              color="primary"
            >
              <Add
                style={{
                  marginRight: "6px",
                  color: myTheme.Button.btnBlue.backgroundColor,
                }}
              />
              <TextLgHighlight>Add Question</TextLgHighlight>
            </Button>
          </Wrapper>
        )}
      </Container>
    </Card>
  );
};

export default SectionCard;
