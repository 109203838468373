import React, { useEffect, useState } from "react";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { Box, Chip, Menu, MenuItem, Typography, styled } from "@mui/material";
import { myTheme } from "../../themeUtils";
import { Add } from "@material-ui/icons";

const Text = styled(Typography)`
  margin: 0px;
  font: normal normal 500 12px Open Sans;
  color: ${myTheme.Button.colorBlue};
  white-space: nowrap;
`;
const ViewGroupChipComponent = () => {
  const groupsList = [...rootStore.leadStore._tabGroups];
  const [anchorE, setAnchorE] = useState(null);
  const [groupsToShowAsChip, setGroupsToShowAsChip] = useState([]);
  const [groupsToShowInMenu, setGroupsToShowInMenu] = useState([]);

  const init = () => {
    let tempList = [];
    let otherList = [];
    let index = groupsList.findIndex(
      (group) => group["name"] === rootStore.leadStore._selectedGroupName
    );
    if (index !== -1) {
      tempList.push(groupsList[index]);
    }
    groupsList.forEach((group) => {
      let index = tempList.findIndex((item) => item["id"] === group["id"]);
      if (index === -1) {
        if (tempList.length < 3) {
          tempList.push(group);
        } else {
          let index = otherList.findIndex((item) => item["id"] === group["id"]);
          if (index === -1) {
            otherList.push(group);
          }
        }
      }
    });
    setGroupsToShowAsChip(tempList);
    setGroupsToShowInMenu(otherList);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    let index = groupsToShowInMenu.findIndex(
      (group) => group["name"] === rootStore.leadStore._selectedGroupName
    );
    if (index !== -1) {
      init();
    }
  }, [rootStore.leadStore._selectedGroupName]);
  const Open = Boolean(anchorE);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "8px",
        flexWrap: "wrap",
      }}
    >
      {groupsToShowAsChip.map((group, index) => {
        return (
          <Chip
            onClick={() => {
              rootStore.leadStore.updateSelectedGroupName(group["name"]);
              rootStore.leadStore.updateTabID(
                rootStore.leadStore.groupsToTabs[group["name"]][0]
              );
            }}
            style={{
              fontSize: "10px",
              cursor: "pointer",
            }}
            key={index}
            color={
              group["name"] === rootStore.leadStore._selectedGroupName
                ? "primary"
                : "default"
            }
            label={group["name"]}
          />
        );
      })}
      {groupsToShowInMenu.length > 0 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={(event) => {
            setAnchorE(event.currentTarget);
          }}
        >
          <Add
            color="primary"
            style={{
              width: "12px",
              height: "12px",
              padding: "0px",
            }}
          />
          <Text>{groupsToShowInMenu.length} more</Text>
        </Box>
      )}
      <Menu
        disableScrollLock={true}
        style={{
          display: "flex",
          flexDirection: "column",
        }}
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorE}
        open={Open}
        onClose={() => {
          setAnchorE(null);
        }}
        PaperProps={{
          style: {
            width: "fit-content",
          },
        }}
      >
        {groupsToShowInMenu.map((group, index) => {
          return (
            <MenuItem
              onClick={() => {
                rootStore.leadStore.updateSelectedGroupName(group["name"]);
                rootStore.leadStore.updateTabID(
                  rootStore.leadStore.groupsToTabs[group["name"]][0]
                );
                setAnchorE(null);
              }}
              style={{
                fontSize: "10px",
              }}
              key={index}
            >
              {group["name"]}
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

export default observer(ViewGroupChipComponent);
