import {
  AppBar,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  Modal,
  Select,
  Toolbar,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { getLocalizedText } from "../../Functions";
import {
  CircularProgress,
  MenuItem,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { myTheme } from "../../themeUtils";
import MuiAlert from "@mui/material/Alert";
import { addFolderPermissionApi } from "../../Api";
import rootStore from "../../stores/RootStore";
import { MultiSelect } from "react-multi-select-component";
const dialogWidth = 420;
const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 16px;
  flex-direction: column;
  row-gap: 20px;
`;
const Message = styled(Typography)`
  font: normal normal normal 16px Open Sans;
  color: green;
  margin: 0px;
  text-align: center;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const scopeList = [
  {
    label: "Account",
    value: "account",
  },
  {
    label: "Project",
    value: "project",
  },
  {
    label: "Team",
    value: "team",
  },
  {
    label: "User",
    value: "user",
  },
];
const AddPermissionModal = (props) => {
  const classes = useStyles();
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [scope, setScope] = useState("");
  const [selectedProjectID, setSelectedProjectID] = useState("");
  const projectList = [...rootStore.authStore.projectList];
  const [multiSelectProjectList, setMultiSelectProjectList] = useState([]);
  const [multiSelectTeamList, setMultiSelectTeamList] = useState([]);
  const [multiSelectMemberList, setMultiSelectMemberList] = useState([]);
  const [selectedProjectList, setSelectedProjectList] = useState([]);
  const [selectedTeamList, setSelectedTeamList] = useState([]);
  const [selectedMemberList, setSelectedMemberList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const generatePermissionList = () => {
    let tempList = [];
    if (scope === "account") {
      let tempObj = {
        scope: "account",
        target: rootStore.userStore.UserData["account"]["id"],
      };
      tempList.push(tempObj);
    } else if (scope === "project") {
      selectedProjectList.forEach((item) => {
        let tempObj = { scope: "project", target: item["value"] };
        tempList.push(tempObj);
      });
    } else if (scope === "team") {
      selectedTeamList.forEach((item) => {
        let tempObj = { scope: "team", target: item["value"] };
        tempList.push(tempObj);
      });
    } else if (scope === "user") {
      selectedMemberList.forEach((item) => {
        let tempObj = { scope: "user", target: item["value"] };
        tempList.push(tempObj);
      });
    }
    return tempList;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let permissionList = generatePermissionList();
    let payload = { permissions: permissionList };
    let response = await addFolderPermissionApi({
      folderID: props.folderID,
      payload: payload,
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      props.setRefresh(true);
      setIsSubmitSuccess(true);
    }
    setIsSubmitting(false);
  };
  const handleClose = () => {
    props.setOpen(false);
  };

  const updateList = (list) => {
    let tempList = [];
    list.forEach((item) => {
      let tempObj = { label: item["name"], value: item["id"] };
      tempList.push(tempObj);
    });
    return tempList;
  };

  const updateTeamList = (list) => {
    let tempList = [];
    list.forEach((item) => {
      if (item["id"] !== "All") {
        let tempObj = { label: item["name"], value: item["id"] };
        tempList.push(tempObj);
      }
    });
    return tempList;
  };

  const handleScope = async (e) => {
    let value = e.target.value;
    setScope(value);
    setSelectedProjectID("");
    setMultiSelectTeamList([]);
    if (value === "project") {
      let multiselect_project_list = updateList(projectList);
      setMultiSelectProjectList(multiselect_project_list);
    } else {
      setMultiSelectProjectList([]);
    }
  };
  const init = async () => {
    let updatedList = updateList([...props.memberList]);
    setMultiSelectMemberList(updatedList);
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!isSubmitting ? (
          !isSubmitSuccess ? (
            <ModelContainer>
              {!loading ? (
                <Box component={"form"} onSubmit={handleSubmit}>
                  <AppBar
                    component={"nav"}
                    elevation={0}
                    position="sticky"
                    color="inherit"
                    sx={{
                      top: 0,
                      bottom: "auto",
                      width: "100%",
                      right: 0,
                    }}
                  >
                    <Toolbar
                      style={{
                        padding: "16px",
                      }}
                    >
                      <HeaderWrapper>
                        <Header>Add a permission</Header>
                        <SubHeader>
                          {" "}
                          {getLocalizedText("fill_in_the_below_details")}
                        </SubHeader>
                      </HeaderWrapper>
                    </Toolbar>
                  </AppBar>
                  <Divider style={{ margin: "0px 16px" }} />
                  <Container
                    style={{
                      height: "220px",
                      overflowY: "auto",
                    }}
                  >
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <InputWrapper>
                            <Label>Scope*</Label>
                            <FormControl
                              sx={{ width: "100%" }}
                              classes={{
                                root: classes.quantityRoot,
                              }}
                            >
                              <Select
                                classes={{
                                  icon: classes.icon,
                                }}
                                displayEmpty
                                required
                                onChange={handleScope}
                                name="deal_value_currency"
                                disableUnderline
                                variant="outlined"
                                value={scope}
                                style={{
                                  width: "100%",
                                  height: "30px",
                                  fontSize: "12px",

                                  borderRadius: "0px",
                                }}
                              >
                                {scopeList.map((item, i) => {
                                  return (
                                    <MenuItem
                                      value={item.value}
                                      key={i}
                                      style={{ fontSize: "12px" }}
                                    >
                                      {item.label}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </InputWrapper>
                        </Grid>
                        {scope === "account" && (
                          <Grid item xs={12}>
                            <InputWrapper>
                              <Label>Target</Label>
                              <OutlinedInput
                                style={{ height: "30px" }}
                                inputProps={{
                                  min: 0,
                                  "aria-label": "weight",
                                  style: {
                                    fontSize: "12px",
                                  },
                                }}
                                disabled
                                value={"All Users"}
                                type={"text"}
                              />
                            </InputWrapper>
                          </Grid>
                        )}
                        {scope === "team" && (
                          <Grid item container spacing={2}>
                            <Grid item xs={6}>
                              <InputWrapper>
                                <Label>Project</Label>
                                <FormControl
                                  sx={{ width: "100%" }}
                                  classes={{
                                    root: classes.quantityRoot,
                                  }}
                                >
                                  <Select
                                    classes={{
                                      icon: classes.icon,
                                    }}
                                    displayEmpty
                                    required
                                    onChange={(e) => {
                                      setSelectedProjectID(e.target.value);
                                      setSelectedTeamList([]);
                                      let index = projectList.findIndex(
                                        (project) =>
                                          project["id"] === e.target.value
                                      );

                                      if (index !== -1) {
                                        let multiselect_team_list =
                                          updateTeamList(
                                            projectList[index]["teams"]
                                          );
                                        setMultiSelectTeamList(
                                          multiselect_team_list
                                        );
                                      }
                                    }}
                                    name="deal_value_currency"
                                    disableUnderline
                                    variant="outlined"
                                    value={selectedProjectID}
                                    style={{
                                      width: "100%",
                                      height: "30px",
                                      fontSize: "12px",

                                      borderRadius: "0px",
                                    }}
                                  >
                                    {projectList.map((item, i) => {
                                      return (
                                        <MenuItem
                                          value={item.id}
                                          key={i}
                                          style={{ fontSize: "12px" }}
                                        >
                                          {item.name}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </InputWrapper>
                            </Grid>
                            <Grid item xs={6}>
                              <InputWrapper>
                                <Label>Target</Label>
                                <MultiSelect
                                  hasSelectAll={false}
                                  options={multiSelectTeamList}
                                  value={selectedTeamList}
                                  className={"global_filter_multi_select"}
                                  onChange={(e) => {
                                    setSelectedTeamList(e);
                                  }}
                                  labelledBy="Select Team"
                                />
                              </InputWrapper>
                            </Grid>
                          </Grid>
                        )}
                        {scope === "project" && (
                          <Grid item xs={12}>
                            <InputWrapper>
                              <Label>Target</Label>
                              <MultiSelect
                                hasSelectAll={false}
                                options={multiSelectProjectList}
                                value={selectedProjectList}
                                className={"global_filter_multi_select"}
                                onChange={(e) => {
                                  setSelectedProjectList(e);
                                }}
                                labelledBy="Select Team"
                              />
                            </InputWrapper>
                          </Grid>
                        )}
                        {scope === "user" && (
                          <Grid item xs={12}>
                            <InputWrapper>
                              <Label>Target</Label>
                              <MultiSelect
                                hasSelectAll={false}
                                options={multiSelectMemberList}
                                value={selectedMemberList}
                                className={"global_filter_multi_select"}
                                onChange={(e) => {
                                  setSelectedMemberList(e);
                                }}
                                labelledBy="Select Team"
                              />
                            </InputWrapper>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Container>
                  <AppBar
                    position="static"
                    color="inherit"
                    elevation={0}
                    sx={{ top: "auto", bottom: 0, width: "100%" }}
                  >
                    <Toolbar
                      style={{
                        padding: "16px",
                        width: dialogWidth,
                        position: "relative",
                      }}
                    >
                      {isSubmitFail && (
                        <ErrorAlert
                          onClose={(e) => {
                            e.stopPropagation();
                            setIsSubmitFail(false);
                          }}
                          severity="error"
                          sx={{ width: "100%" }}
                        >
                          {errorMessage}
                        </ErrorAlert>
                      )}
                      <Grid container spacing={2} justifyContent="flex-end">
                        <Grid item>
                          <Button
                            onClick={() => {
                              props.setOpen(false);
                            }}
                            variant="contained"
                            color="default"
                            style={{
                              fontSize: "12px",
                              textTransform: "none",
                              fontWeight: "bold",
                            }}
                          >
                            {getLocalizedText("cancel")}
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            onClick={() => {}}
                            type="submit"
                            variant="contained"
                            style={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              width: "fit-content",
                              textTransform: "none",
                              ...myTheme.Button.btnBlue,
                            }}
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </Toolbar>
                  </AppBar>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    width: dialogWidth,
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </ModelContainer>
          ) : (
            <ModelContainer
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MessageWrapper style={{ marginBottom: "0px", padding: "16px" }}>
                <Message>Permission added successfully!</Message>

                <Button
                  onClick={handleClose}
                  type="button"
                  variant="contained"
                  color="default"
                  style={{
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "100px",
                  }}
                >
                  {getLocalizedText("close")}
                </Button>
              </MessageWrapper>
            </ModelContainer>
          )
        ) : (
          <ModelContainer>
            <Box
              sx={{
                display: "flex",
                width: dialogWidth,
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
              }}
            >
              <CircularProgress />
            </Box>
          </ModelContainer>
        )}
      </Modal>
    </>
  );
};

export default AddPermissionModal;
