import React, { useEffect, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { ChevronRight, Send } from "@material-ui/icons";
import profile_logo from "../../assets/profile_placeholder.png";
import rootStore from "../../stores/RootStore";
import { getLocalizedText } from "../../Functions";
import { Box, Typography } from "@mui/material";
import profile_placeholder from "../../assets/profile_placeholder.png";
import PreviewImageDialog from "../../components/files_page_components/PreviewImageDialog";
import UserProfileImageComponent from "../../components/file_upload_component/user_profile_image/UserProfileImageComponent";
import UserProfileImageWithUploadComponent from "../../components/file_upload_component/user_profile_image/UserProfileImageWithUploadComponent";
import MessageWithLoadingStateCustomDialog from "../../components/alert_dialogue/MessageWithLoadingStateCustomDialog";
import { uploadAccountUserProfileImageApi } from "../../Api";
import { dataRenderer, IsoToLocalDate } from "../../Functions";
const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 84px);`};

  margin: auto;
  padding-top: 70px;
  width: 95%;
`;
const Text1 = styled(Typography)`
  margin: 0px;
  font: normal normal 600 28px Open Sans;
  color: #4d4e4f;
`;
const Text2 = styled(Typography)`
  margin: 0px;
  font: normal normal 600 16px Open Sans;
  color: #4d4e4f;
  margin-bottom: 10px;
`;
const Text3 = styled(Typography)`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #363636;
`;
const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const Column = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;

  row-gap: 20px;
`;
const BottomWrapper = styled(Box)`
  width: 100%;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  column-gap: 20px;
`;
const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
`;
const FirstSection = styled(Box)`
  display: flex;
  flex: 1;
  width: 100%;
  background-color: white;
  flex-direction: column;
  align-items: center;
`;
const SecondSection = styled(Box)`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  background-color: #f9f9fc;
  padding: 20px;
`;
const ThirdSection = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 20px;
  background-color: #f9f9fc;
`;
const DetailsHeader = styled(Typography)`
  font: normal normal 600 16px Open Sans;
  color: #979797;
  margin-bottom: 20px;
`;
const InfoWrapper = styled(Box)`
  width: 50%;
  padding: 12px 0px;
  height: fit-content;
`;
const Label = styled(Box)`
  font: normal normal 600 12px Open Sans;
  margin-bottom: 8px;
  color: #979799;
`;
const Detail = styled(Box)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const ImageWrapper = styled(Box)`
  display: flex;
  width: fit-content;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;
  width: 200px;
  height: 200px;
`;
const AdminSettingPage = () => {
  const projectList = rootStore.authStore.projectList;
  const accountData = rootStore.userStore.UserData;
  const [uploadingImage, setUploadingImage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSubmitResponseDialog, setOpenSubmitResponseDialog] =
    useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [viewProfileImage, setViewProfileImage] = useState(false);
  const getProfileImageUrl = () => {
    if (accountData !== null) {
      if (
        accountData.hasOwnProperty("profile_pic") &&
        accountData["profile_pic"] !== null
      ) {
        return accountData["profile_pic"];
      }
    }

    return null;
  };

  const getProjectRole = (project) => {
    if (project.hasOwnProperty("role")) {
      let roleDetails = project["role"];
      if (roleDetails.hasOwnProperty("role")) {
        return roleDetails["role"];
      }
    }
    return "-";
  };

  const handleProfileImageUpload = async (event) => {
    let file = event.target.files[0];
    const uploadFile = async () => {
      setUploadingImage(true);
      setOpenSubmitResponseDialog(true);
      let formData = new FormData();
      formData.append("profile_pic", file);
      let response = await uploadAccountUserProfileImageApi({
        payload: formData,
      });
      if (!response.hasError()) {
        setIsSubmitFail(false);
        await rootStore.userStore.fetchUserData();
        setSuccessMessage("Image uploaded successfully!");
        setUploadingImage(false);
      } else {
        setErrorMessage(response.errorMessage);
        setIsSubmitFail(true);
        setUploadingImage(false);
      }
    };
    if (file !== null && file !== undefined) {
      uploadFile();
    }
  };

  useEffect(() => {}, [rootStore.userStore.UserData]);

  return (
    <>
      <ParentContainer>
        <Container>
          <TopWrapper>
            <Header>Settings</Header>
            <ChevronRight style={{ width: "16px", height: "16px" }} />
            <Header>Profile</Header>
          </TopWrapper>
          {accountData !== null && (
            <Wrapper>
              <Text1>Hello, {accountData.name}</Text1>
              <BottomWrapper>
                <FirstSection>
                  <ImageWrapper>
                    <UserProfileImageWithUploadComponent
                      handleUpload={handleProfileImageUpload}
                      url={getProfileImageUrl()}
                      handleImageClick={() => {
                        setViewProfileImage(true);
                      }}
                      canShowUpload={true}
                      scope={"settings"}
                    />
                  </ImageWrapper>
                  <Text2>{accountData.name}</Text2>
                  <Text3>{accountData.phone}</Text3>
                </FirstSection>
                <SecondSection>
                  <DetailsHeader>
                    {getLocalizedText("profile_details")}
                  </DetailsHeader>
                  <Row style={{ flexWrap: "wrap" }}>
                    <InfoWrapper>
                      <Label>{getLocalizedText("name")}</Label>
                      <Detail>{dataRenderer(accountData.name)}</Detail>
                    </InfoWrapper>
                    <InfoWrapper>
                      <Label>{getLocalizedText("role")}</Label>
                      <Detail>{dataRenderer(accountData.role)}</Detail>
                    </InfoWrapper>
                    <InfoWrapper>
                      <Label>{getLocalizedText("email")}</Label>
                      <Detail>{dataRenderer(accountData.email)}</Detail>
                    </InfoWrapper>
                    <InfoWrapper>
                      <Label>{getLocalizedText("phone")}</Label>
                      <Detail>{dataRenderer(accountData.phone)}</Detail>
                    </InfoWrapper>
                    <InfoWrapper>
                      <Label>Employee ID</Label>
                      <Detail>{dataRenderer(accountData.employee_id)}</Detail>
                    </InfoWrapper>
                    <InfoWrapper>
                      <Label>Group</Label>
                      <Detail>{dataRenderer(accountData.group)}</Detail>
                    </InfoWrapper>
                    <InfoWrapper>
                      <Label>Designation</Label>
                      <Detail>{dataRenderer(accountData.designation)}</Detail>
                    </InfoWrapper>
                    <InfoWrapper>
                      <Label>Bank Name</Label>
                      <Detail>{dataRenderer(accountData.bank_name)}</Detail>
                    </InfoWrapper>
                    <InfoWrapper>
                      <Label>Bank Account Number</Label>
                      <Detail>
                        {dataRenderer(accountData.bank_account_number)}
                      </Detail>
                    </InfoWrapper>
                    <InfoWrapper>
                      <Label>Bank Account Name</Label>
                      <Detail>
                        {dataRenderer(accountData.bank_account_name)}
                      </Detail>
                    </InfoWrapper>
                    <InfoWrapper>
                      <Label>IFSC Code</Label>
                      <Detail>{dataRenderer(accountData.ifsc_code)}</Detail>
                    </InfoWrapper>
                    <InfoWrapper>
                      <Label>PAN Number</Label>
                      <Detail>{dataRenderer(accountData.pan_number)}</Detail>
                    </InfoWrapper>
                    <InfoWrapper>
                      <Label>DOB</Label>
                      <Detail>
                        {accountData.hasOwnProperty("dob") &&
                        accountData.dob !== null &&
                        accountData.dob !== ""
                          ? IsoToLocalDate(accountData.dob)
                          : "-"}
                      </Detail>
                    </InfoWrapper>
                  </Row>
                </SecondSection>
                <Column>
                  <ThirdSection>
                    <DetailsHeader>
                      {getLocalizedText("account_details")}
                    </DetailsHeader>
                    <InfoWrapper>
                      <Label>{getLocalizedText("account_name")}</Label>
                      <Detail>{accountData.account.name}</Detail>
                    </InfoWrapper>
                    <InfoWrapper>
                      <Label>{getLocalizedText("account_owner")}</Label>
                      <Detail>
                        {accountData.account.owner !== null &&
                          accountData.account.owner.name}
                      </Detail>
                    </InfoWrapper>
                    <InfoWrapper>
                      <Label>{getLocalizedText("owner_email")}</Label>
                      <Detail>
                        {accountData.account.owner !== null &&
                          accountData.account.owner.email}
                      </Detail>
                    </InfoWrapper>
                  </ThirdSection>

                  <ThirdSection>
                    <DetailsHeader>
                      {getLocalizedText("projects")}
                    </DetailsHeader>
                    {projectList.length > 0 &&
                      projectList.map((project) => {
                        return (
                          <Row
                            style={{
                              margin: "0px",
                              padding: "0px",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Send
                              style={{
                                width: "12px",
                                height: "12px",
                                marginRight: "4px",
                              }}
                            />
                            <Detail>
                              {`${project["name"]} (${getProjectRole(
                                project
                              )})`}
                            </Detail>
                          </Row>
                        );
                      })}
                  </ThirdSection>
                </Column>
              </BottomWrapper>
            </Wrapper>
          )}
        </Container>
      </ParentContainer>
      <Footer />
      {openSubmitResponseDialog && (
        <MessageWithLoadingStateCustomDialog
          open={openSubmitResponseDialog}
          setOpen={setOpenSubmitResponseDialog}
          successMessage={successMessage}
          errorMessage={errorMessage}
          loading={uploadingImage}
          isError={isSubmitFail}
        />
      )}
      {viewProfileImage && (
        <PreviewImageDialog
          open={viewProfileImage}
          setOpen={setViewProfileImage}
          file={getProfileImageUrl()}
          isCreate={false}
        />
      )}
    </>
  );
};

export default observer(AdminSettingPage);
