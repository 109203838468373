import { Grid, IconButton, Modal } from "@material-ui/core";
import React from "react";
import { styled } from "@mui/material/styles";
import { AppBar, Box, Toolbar } from "@mui/material";
import MDEditor from "@uiw/react-md-editor";
import { Close } from "@material-ui/icons";

const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  padding: 16px;
  padding-top: 0px;
  max-height: 80vh;
  height: fit-content;
  overflow: auto;
`;
const ContentWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 80vw;
`;

const ViewRichTextDialog = ({ textData, setOpen, open }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <AppBar
            component={"nav"}
            position="sticky"
            color="inherit"
            elevation={0}
            style={{
              height: "50px",
              top: 0,
              bottom: "auto",
              width: "100%",
            }}
          >
            <Toolbar
              style={{
                padding: "0px",
                width: "100%",
                position: "relative",
              }}
            >
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <IconButton onClick={handleClose} style={{ padding: "0px" }}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <ContentWrapper>
            <div className="container" data-color-mode="light">
              <MDEditor.Markdown source={textData} />
            </div>
          </ContentWrapper>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default ViewRichTextDialog;
