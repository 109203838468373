import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { Box, Button, Modal } from "@material-ui/core";
import { myTheme } from "../../themeUtils";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { getMemberDetailByPhoneApi } from "../../Api";
import { getLocalizedText } from "../../Functions";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled.span`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
  margin-bottom: 20px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Label = styled.span`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Input = styled.input`
  width: 320px;
  height: 30px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;

  border: 1px solid #bfbfbf;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;

const ButtonWrapper = styled.div`
  /* margin-top: 30px; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const GetMemberByPhoneModal = (props) => {
  const [showAlert, setShowAlert] = useState(false);
  const handleChange = (e) => {
    props.setPhone(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const getUserDetailByPhone = async () => {
      try {
        let response = await getMemberDetailByPhoneApi(props.id, props.phone);
        let data = response.data;
        if (Object.keys(data.user).length > 0) {
          props.setMemberData(data);
          props.setOpen(false);
          props.setOpenMemberInfoModal(true);
        } else {
          setShowAlert(true);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getUserDetailByPhone();
  };
  const handleClose = () => {
    props.setPhone("");
    props.setOpen(false);
  };

  useEffect(() => {
    props.setPhone("");
  }, []);

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <Container>
              <Header>Get Member by Phone</Header>
              <Form onSubmit={handleSubmit}>
                <InputContainer>
                  <InputWrapper>
                    <Label>Phone*</Label>
                    <Input
                      pattern="^(\+|00)[1-9][0-9 \-\(\)\.]{7,32}$"
                      value={props.phone}
                      required
                      type="text"
                      placeholder="+91-1231231231"
                      name="phone"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </InputContainer>
                <ButtonWrapper>
                  <Button
                    onClick={handleClose}
                    type="button"
                    variant="contained"
                    color="default"
                    style={{
                      backgroundColor: "#EFEFF4",
                      textTransform: "none",
                      marginRight: "20px",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    id="call-log-btn"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                      ...myTheme.Button.btnBlue,
                    }}
                  >
                    {getLocalizedText("next")}
                  </Button>
                </ButtonWrapper>
              </Form>
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
      <Dialog
        open={showAlert}
        onClose={() => {
          setShowAlert(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText
            style={{ fontWeight: "bold", fontSize: "18px" }}
            id="alert-dialog-description"
          >
            User does not exist, Do you want to creat a new one?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ marginTop: "-20px" }}>
          <Button
            onClick={() => {
              setShowAlert(false);
            }}
          >
            No
          </Button>
          <Button
            onClick={() => {
              setShowAlert(false);
              // props.setOpen(false);
              props.setOpenNewUserModal(true);
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default observer(GetMemberByPhoneModal);
