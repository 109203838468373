import { Divider } from "@mui/material";
import React from "react";

import styled from "styled-components";
import Footer from "../../components/landing_page_components/footer/Footer";
import CardComponent from "../../components/website_templates_components/card_component/CardComponent";

const Container = styled.div`
  margin-top: 64px;
  background-color: #f9f9fc;
  ${`min-height: calc(100vh - 140px);`};
`;
const Wrapper = styled.div`
  width: 95%;
  margin: auto;
  display: flex;
  flex-direction: column;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  padding: 20px 0px;
`;
const Header = styled.p`
  margin: 0px;
  font: normal normal 600 28px Open Sans;
  color: #4d4e4f;
`;
const TemplatesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  margin: auto;
  padding: 30px;
  background-color: white;
  row-gap: 30px;
  column-gap: 30px;
`;

const WebsiteTemplates = () => {
  return (
    <>
      <Container>
        <Wrapper>
          <HeaderWrapper>
            <Header>Choose your Custom Template</Header>
          </HeaderWrapper>
          <Divider />
          <TemplatesWrapper>
            <CardComponent />
            <CardComponent />
            <CardComponent />
            <CardComponent />
            <CardComponent />
            <CardComponent />
          </TemplatesWrapper>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
};

export default WebsiteTemplates;
