import { Box, Card, Divider, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import QuestionCard from "./QuestionCard";
import rootStore from "../../../stores/RootStore";
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
`;
const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 20px;
`;
const TitleText = styled(Typography)`
  font: normal normal 400 16px Open Sans;
  color: white;
`;

const Strip = styled(Box)`
  height: fit-content;
  padding: 12px 20px;
  width: 100%;
`;

const SectionCard = ({
  title,
  questions,
  inputData,
  setInputData,
  prefilledData,
  setPrefilledData,
  listFieldInputMeta,
  isLeadFormDisabled,
  getFieldInputMeta
}) => {
  const columnsList = [...rootStore.userStore.AllColumnsList];
  return (
    <Card
      sx={{
        borderRadius: "12px",
        boxShadow: "none",
        border: "1px solid rgb(218, 220, 224)",
      }}
    >
      <Strip
        style={{
          backgroundColor: isLeadFormDisabled ? "#E0E0E0" : "#26599e",
        }}
      >
        <TitleText>{title}</TitleText>
      </Strip>
      <Container>
        <Wrapper>
          {questions.map((questionData, index) => {
            let column_index = columnsList.findIndex(
              (column) => column["id"] === questionData["column_id"]
            );
            return (
              <>
                <QuestionCard
                  isLeadFormDisabled={isLeadFormDisabled}
                  inputData={inputData}
                  setInputData={setInputData}
                  questionData={questionData}
                  prefilledData={prefilledData}
                  setPrefilledData={setPrefilledData}
                  listFieldInputMeta={listFieldInputMeta}
                  columnMeta={
                    column_index !== -1 ? columnsList[column_index] : {}
                  }
                  key={index}
                  getFieldInputMeta={({columnMeta,inputMeta})=>getFieldInputMeta({columnMeta,inputMeta})}
                />
              </>
            );
          })}
        </Wrapper>
      </Container>
    </Card>
  );
};

export default SectionCard;
