import { ArrowBackIos, ArrowForwardIos, Edit, Lock } from "@material-ui/icons";
import { Box, IconButton, Typography, styled, Tooltip } from "@mui/material";
import React from "react";

const IconTextWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0px 12px;
  overflow: hidden;
`;
const Text = styled(Typography)`
  font: normal normal 600 28px Open Sans;
  margin: 0px;
  color: #4d4e4f;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: center;
`;

const DashboardSwitchComponent = ({
  isDashboardEditable,
  handleEditClick,
  dashboardList,
  currentDashboardID,
  handleDashboardChange,
}) => {
  const isBackButtonDisabled = () => {
    let index = dashboardList.findIndex(
      (dashboard) => dashboard["id"] === currentDashboardID
    );
    if (index !== -1) {
      return index === 0;
    } else {
      return true;
    }
  };

  const isForwardButtonDisabled = () => {
    let index = dashboardList.findIndex(
      (dashboard) => dashboard["id"] === currentDashboardID
    );
    if (index !== -1) {
      return index === dashboardList.length - 1;
    } else {
      return true;
    }
  };

  const handleNextButtonTap = () => {
    let index = dashboardList.findIndex(
      (dashboard) => dashboard["id"] === currentDashboardID
    );
    if (index !== -1) {
      handleDashboardChange(dashboardList[index + 1]["id"]);
    }
  };
  const handlePrevButtonTap = () => {
    let index = dashboardList.findIndex(
      (dashboard) => dashboard["id"] === currentDashboardID
    );
    if (index !== -1) {
      handleDashboardChange(dashboardList[index - 1]["id"]);
    }
  };
  return (
    <Wrapper>
      <IconButton
        color="primary"
        disabled={isBackButtonDisabled()}
        onClick={handlePrevButtonTap}
      >
        <ArrowBackIos style={{ width: "22px", height: "22px" }} />
      </IconButton>

      {dashboardList.map((dashboard, index) => {
        if (dashboard["id"] === currentDashboardID) {
          return (
            <IconTextWrapper key={index}>
              <Tooltip title={dashboard["name"]}>
                <Text>{dashboard["name"]}</Text>
              </Tooltip>
              {isDashboardEditable() ? (
                <Edit
                  onClick={handleEditClick}
                  style={{
                    width: "22px",
                    cursor: "pointer",
                    height: "22px",
                  }}
                  color="primary"
                />
              ) : (
                <Lock style={{ width: "22px", height: "22px" }} />
              )}
            </IconTextWrapper>
          );
        }
      })}
      <IconButton
        color="primary"
        disabled={isForwardButtonDisabled()}
        onClick={handleNextButtonTap}
      >
        <ArrowForwardIos style={{ width: "22px", height: "22px" }} />
      </IconButton>
    </Wrapper>
  );
};

export default DashboardSwitchComponent;
