import React, { useMemo } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import {
  Add,
  ChevronRight,
  Edit,
  Notifications,
  ViewColumn,
} from "@material-ui/icons";
import rootStore from "../../stores/RootStore";
import { useEffect } from "react";
import {
  Backdrop,
  Box,
  IconButton,
  Tooltip,
  Typography,
  Switch,
  tabsClasses,
  Tab,
  Tabs,
  Button,
} from "@mui/material";
import { CircularProgress, Drawer, Chip } from "@material-ui/core";
import { smartViewRoleList } from "../../Config";
import { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  deleteProjectAdministrationViewApi,
  getProjectAdministrationReOrederedViewsApi,
  getAllProjectAdministrationViewsApi,
  getAssignedProjectAdministrationViewsApi,
  addProjectAdministrationViewApi,
} from "../../Api";
import {
  ProjectAdminSmartViewColumnHeaders,
  ProjectAdminUnassignedTabViewColumnHeaders,
} from "../../Db";
import CustomErrorMessageDialog from "../../components/alert_dialogue/CustomErrorMessageDialog";
import ViewTabColumnSelectionModal from "../../components/view_tab_select_column/ViewTabColumnSelectionModal";
import SuccessMessageDialog from "../../components/alert_dialogue/SuccessMessageDialog";
import { FilterAlt } from "@mui/icons-material";
import ViewTabFilterColumnSelectionModal from "../../components/view_tab_select_column/ViewTabFilterColumnSelectionModal";
import ViewTabNotificationColumnSelectionModal from "../../components/view_tab_select_column/ViewTabNotificationColumnSelectionModal";
import CreateViewTabSidepanel from "../../components/view_tab_sidepanels/CreateViewTabSidepanel";

const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  height: 100%;
  margin: auto;
  margin-top: 70px;
  width: 95%;
`;

const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 0px;
`;
const Text = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
`;
const CustomTab = styled(Tab)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: "#E7EEFA",
    color: "#4079DA",
    font: "normal normal 600 14px Open Sans",
    textTransform: "capitalize",
  },
  backgroundColor: "white",
  color: "#4D4E4F",
  font: "normal normal 600 14px Open Sans",
  textTransform: "capitalize",
}));

const Row = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Bottom = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 226px);`};
  background-color: white;
  overflow-x: auto;
`;
const ChipsWrapper = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  column-gap: 20px;
  height: 100%;
  margin-bottom: 10px;
`;

const CustomSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-disabled": {
    "&.Mui-checked": {
      color: "#556cd6", // set the color of the switch when it is checked
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#556cd6", // set the background color of the switch when it is checked
    },
  },
}));

const AdminProjectSetting = () => {
  const { authStore } = rootStore;
  const project_id = authStore.projectId;
  const [selectedRole, setSelectedRole] = useState("manager");
  const [assignedTabsList, setAssignedTabsList] = useState([]);
  const [unassignedTabsList, setUnassignedTabsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingBackdrop, setLoadingBackdrop] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [openSelectColumn, setOpenSelectColumn] = useState(false);
  const [openFilterColumn, setOpenFilterColumn] = useState(false);
  const [openNotificationColumn, setOpenNotificationColumn] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [tabValue, setTabValue] = useState("assigned");
  const [openCreateViewSidepanel, setOpenCreateViewSidepanel] = useState(false);
  const [ViewTabFormScope, setViewTabFormScope] = useState("");

  useEffect(() => {
    getViewList();
  }, [selectedRole]);

  const getViewList = async () => {
    setAssignedTabsList([]);
    setUnassignedTabsList([]);
    setLoading(true);
    let allResponse = await getAllProjectAdministrationViewsApi({
      projectRole: selectedRole,
    });
    let assignedResponse = await getAssignedProjectAdministrationViewsApi({
      projectRole: selectedRole,
    });
    setAssignedTabsList(assignedResponse);

    let assigendTabIds = assignedResponse.map((e) => e["tab"]["id"]);
    let unassignedTabs = [];

    allResponse.forEach((tab) => {
      if (assigendTabIds.includes(tab["id"]) === false) {
        unassignedTabs.push(tab);
      }
    });
    setUnassignedTabsList(unassignedTabs);
    setLoading(false);
  };

  const getReOrderedViewList = async ({ role, tabSequences }) => {
    setAssignedTabsList([]);
    setLoading(true);
    let payload = { role: role, tab_sequences: tabSequences };
    let response = await getProjectAdministrationReOrederedViewsApi({
      projectId: project_id,
      payload: payload,
    });
    setAssignedTabsList(response);
    setLoading(false);
  };

  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  useEffect(() => {
    authStore.updateLeadDetailStatus();
  }, []);

  const onRowDragEnd = async (event) => {
    const gridApi = event.api;
    let draggedItem = event.node.data;
    let newOrderList = [];
    gridApi.forEachNode((node, i) => {
      let tempObj = { tab_id: node.data.tab.id, sequence: i };
      newOrderList.push(tempObj);
    });

    //*********below getting the prev index and new index to check if there is any order change happened or not */

    let prevIndex = assignedTabsList.findIndex(
      (data) => data["tab"]["id"] === draggedItem["tab"]["id"]
    );
    let newIndex = newOrderList.findIndex(
      (data) => data["tab_id"] === draggedItem["tab"]["id"]
    );

    if (prevIndex !== newIndex) {
      await getReOrderedViewList({
        role: selectedRole,
        tabSequences: newOrderList,
      });
    }
  };

  const handleAdd = async (id) => {
    setLoadingBackdrop(true);
    let payload = { role: selectedRole, tab_id: id };
    let response = await addProjectAdministrationViewApi({
      tabID: id,
      payload,
    });
    if (response.hasError()) {
      setLoadingBackdrop(false);

      setErrorMessage(response.errorMessage);
      setOpenErrorDialog(true);
    } else {
      setLoadingBackdrop(false);
      await getViewList();
    }
  };
  const handleRemove = async (id) => {
    setLoadingBackdrop(true);
    let payload = { role: selectedRole, tab_id: id };
    let response = await deleteProjectAdministrationViewApi({
      tabID: id,
      payload,
    });
    if (response.hasError()) {
      setLoadingBackdrop(false);

      setErrorMessage(response.errorMessage);
      setOpenErrorDialog(true);
    } else {
      setLoadingBackdrop(false);
      await getViewList();
    }
  };

  const assignActionColumn = [
    {
      field: "actions",
      headerName: "Actions",
      resizable: false,
      minWidth: 70,
      flex: 1,
      cellRenderer: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip title={"Activate"}>
              <CustomSwitch
                checked={true}
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemove(params.data.tab.id);
                }}
              />
            </Tooltip>
            <Tooltip title={"Edit"}>
              <IconButton
                color="primary"
                onClick={() => {
                  setViewTabFormScope("edit");
                  setSelectedRowData(params.data);
                  setOpenCreateViewSidepanel(true);
                }}
              >
                <Edit
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Select Columns"}>
              <IconButton
                color="primary"
                onClick={() => {
                  setSelectedRowData(params.data);
                  setOpenSelectColumn(true);
                }}
              >
                <ViewColumn
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const unAssignactionColumn = [
    {
      field: "actions",
      headerName: "Actions",
      resizable: false,
      minWidth: 130,
      flex: 1,
      cellRenderer: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip title={"Deactivate"}>
              <CustomSwitch
                checked={false}
                onClick={(e) => {
                  e.stopPropagation();
                  handleAdd(params.data.id);
                }}
              />
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const postSelectColumnSubmitCallback = (data) => {
    let tempRow = [...assignedTabsList];
    let index = tempRow.findIndex((row) => row.id === data.id);
    if (index !== -1) {
      tempRow[index] = data;
      setAssignedTabsList(tempRow);
    }
    setSuccessMessage("Select columns set successfully!");
    setOpenSuccessDialog(true);
  };

  const postEditCallback = (data) => {
    setLoading(true);

    let tempRow = [...assignedTabsList];
    let index = tempRow.findIndex((row) => row.id === data.id);
    if (index !== -1) {
      tempRow[index] = data;
      setAssignedTabsList(tempRow);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const postCreateCallback = async (data) => {
    await getViewList();
  };

  return (
    <>
      <ParentContainer>
        <Container>
          <TopWrapper>
            <Text>Project Administration</Text>
            <ChevronRight style={{ width: "16px", height: "16px" }} />
            <Text>View Tabs</Text>
          </TopWrapper>
          <Row>
            <ChipsWrapper>
              {smartViewRoleList.map((data) => {
                return (
                  <Chip
                    key={data.value}
                    color={selectedRole === data.value ? "primary" : "default"}
                    label={data.label}
                    onClick={() => {
                      setSelectedRole(data.value);
                    }}
                  />
                );
              })}
            </ChipsWrapper>
            <Button
              onClick={() => {
                setViewTabFormScope("create");
                setOpenCreateViewSidepanel(true);
              }}
              variant="contained"
              color="primary"
              style={{
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                whiteSpace: "nowrap",
                marginRight: "16px",
              }}
            >
              <Add />
              Create View
            </Button>
            <Tooltip title={"Notification Columns"}>
              <IconButton
                color="primary"
                onClick={() => {
                  setOpenNotificationColumn(true);
                }}
              >
                <Notifications
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Filter Columns"}>
              <IconButton
                color="primary"
                onClick={() => {
                  setOpenFilterColumn(true);
                }}
              >
                <FilterAlt
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Row>

          <Tabs
            value={tabValue}
            textColor="primary"
            indicatorColor="primary"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                "&.Mui-disabled": { opacity: 0.3 },
              },
              bgcolor: "white",
            }}
            onChange={(event, newValue) => {
              setTabValue(newValue);
            }}
          >
            <CustomTab label={"Assigned"} value={"assigned"} />
            <CustomTab label={"Un-Assigned"} value={"unassigned"} />
          </Tabs>

          <Bottom>
            {(() => {
              if (loading) {
                return (
                  <TableWrapper
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </TableWrapper>
                );
              } else {
                if (tabValue === "assigned") {
                  return (
                    <TableWrapper>
                      <div
                        className="ag-theme-alpine"
                        style={{ width: "100%" }}
                      >
                        <AgGridReact
                          onRowDragEnd={onRowDragEnd}
                          rowDragManaged={true}
                          domLayout="autoHeight"
                          suppressCellFocus
                          gridOptions={gridOptions}
                          loadingOverlayComponent={<CircularProgress />}
                          rowData={assignedTabsList}
                          defaultColDef={defaultColDef}
                          columnDefs={ProjectAdminSmartViewColumnHeaders.concat(
                            assignActionColumn
                          )}
                          animateRows={true}
                        />
                      </div>
                    </TableWrapper>
                  );
                } else {
                  return (
                    <TableWrapper>
                      <div
                        className="ag-theme-alpine"
                        style={{ width: "100%" }}
                      >
                        <AgGridReact
                          domLayout="autoHeight"
                          suppressCellFocus
                          gridOptions={gridOptions}
                          loadingOverlayComponent={<CircularProgress />}
                          rowData={unassignedTabsList}
                          defaultColDef={defaultColDef}
                          columnDefs={ProjectAdminUnassignedTabViewColumnHeaders.concat(
                            unAssignactionColumn
                          )}
                          animateRows={true}
                        />
                      </div>
                    </TableWrapper>
                  );
                }
              }
            })()}
          </Bottom>
        </Container>
      </ParentContainer>
      <Footer />
      <Backdrop
        open={loadingBackdrop}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress style={{ color: "white" }} />
      </Backdrop>
      {openErrorDialog && (
        <CustomErrorMessageDialog
          open={openErrorDialog}
          setOpen={setOpenErrorDialog}
          message={errorMessage}
        />
      )}
      {openSuccessDialog && (
        <SuccessMessageDialog
          open={openSuccessDialog}
          setOpen={setOpenSuccessDialog}
          message={successMessage}
        />
      )}

      {openSelectColumn && (
        <ViewTabColumnSelectionModal
          open={openSelectColumn}
          setOpen={setOpenSelectColumn}
          tabData={selectedRowData}
          postSelectColumnSubmitCallback={postSelectColumnSubmitCallback}
        />
      )}
      {openFilterColumn && (
        <ViewTabFilterColumnSelectionModal
          open={openFilterColumn}
          setOpen={setOpenFilterColumn}
          postSubmitCallback={() => {
            setSuccessMessage("Filter columns set successfully!");
            setOpenSuccessDialog(true);
          }}
        />
      )}
      {openNotificationColumn && (
        <ViewTabNotificationColumnSelectionModal
          open={openNotificationColumn}
          setOpen={setOpenNotificationColumn}
          postSubmitCallback={() => {
            setSuccessMessage("Notification columns set successfully!");
            setOpenSuccessDialog(true);
          }}
        />
      )}
      {openCreateViewSidepanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openCreateViewSidepanel}
          onClose={() => {
            setViewTabFormScope("");
            setOpenCreateViewSidepanel(false);
          }}
        >
          <CreateViewTabSidepanel
            setOpen={setOpenCreateViewSidepanel}
            postCreateCallback={postCreateCallback}
            editableData={selectedRowData}
            scope={ViewTabFormScope}
            setScope={setViewTabFormScope}
            postEditCallback={postEditCallback}
          />
        </Drawer>
      )}
    </>
  );
};

export default observer(AdminProjectSetting);
