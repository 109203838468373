import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import profile_placeholder from "../../../assets/profile_placeholder.png";
import { CircularProgress } from "@material-ui/core";
const ImageWrapper = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 1px solid #a7a6a8;
  padding: 0px;
  position: relative;
  background-color: white;
`;
const Image = styled("img")`
  aspect-ratio: 1/1;
  width: 100%;
  height: auto;
  border-radius: 50%;
  object-fit: cover;
`;

const UserProfileImageComponent = ({ url, handleImageClick }) => {
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <ImageWrapper>
        <Image
          src={url}
          alt={profile_placeholder}
          onLoad={handleImageLoad}
          onClick={handleImageClick}
        />
        {loading && (
          <CircularProgress
            color="primary"
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              margin: "auto",
            }}
          />
        )}
      </ImageWrapper>
    </Box>
  );
};

export default UserProfileImageComponent;
