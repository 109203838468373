import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

import React from "react";

const RadioGroupOptionWidget = ({
  list,
  type,
  handleChange,
  value,
  disabled,
}) => {
  if (type === "list") {
    return (
      <RadioGroup>
        {list.map((str, index) => {
          return (
            <FormControlLabel
              disabled={disabled}
              key={index}
              value={str}
              checked={str === value}
              control={<Radio />}
              label={str}
              onChange={(event) => handleChange(event.target.value)}
            />
          );
        })}
      </RadioGroup>
    );
  } else {
    return (
      <RadioGroup>
        {list.map((obj, index) => {
          return (
            <FormControlLabel
              disabled={disabled}
              key={index}
              value={obj.value}
              checked={obj.value === value}
              control={<Radio />}
              label={obj.name}
              onChange={(event) => handleChange(event.target.value)}
            />
          );
        })}
      </RadioGroup>
    );
  }
};

export default RadioGroupOptionWidget;
