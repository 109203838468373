import React, { useEffect, useRef, useState } from "react";
import rootStore from "../../stores/RootStore";
import styled from "styled-components";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import NewLeadTab from "../../components/home_tabs/NewLeadTab";
import FilteredTab from "../../components/home_tabs/FilteredTab";
import { Assignment, ChevronRight, Clear, Group } from "@material-ui/icons";
import spinner from "../../assets/images/gifs/spinner.gif";
import FilterModal from "../../components/filter_modal/FilterModal";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  getAllColsApi,
  getDataApi,
  getProjectApi,
  getRoleApi,
  getTabsApi,
} from "../../Api";
import { pageSize } from "../../Config";
import {
  Badge,
  Box,
  Button,
  Drawer,
  Grid,
  withStyles,
} from "@material-ui/core";
import { toJS } from "mobx";
import { PersonAddAlt } from "@mui/icons-material";
import CreateLeadComponent from "../../components/create_lead_modal/CreatLeadComponent";
import Footer from "../../components/landing_page_components/footer/Footer";
import {
  checkFeaturePermission,
  consoleLogger,
  getLocalizedText,
  isUnderWorkSchedule,
} from "../../Functions";
import AlertDialogue from "../../components/alert_dialogue/AlertDialogue";
import NoAccessPermissionPage from "../no_access_page/NoAccessPermissionPage";
import NoAccessPage from "../no_access_page/NoAccessPage";
import analytics from "../../Analytics";
import { Chip } from "@mui/material";
const Container = styled.div`
  min-height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9fc;
  justify-content: center;
  margin-top: 70px;
  width: 100%;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: ${(e) => (e.params ? "flex-end" : "space-between")};
  width: 95%;
  min-height: 40px;
`;

const EmptyDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${`min-height: calc(100vh - 139px);`};
  font: normal normal Bold 22px Open Sans;
`;
const Spinner = styled.img`
  height: 120px;
  width: 180px;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
  ${`min-height: calc(100vh - 148px);`};
  width: 100%;
`;

const TeamFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  /* min-width: 100px; */
  height: 100%;
  padding: 6px;
`;
const MemberSelect = styled.select`
  width: fit-content;
  border: 1px solid gray;
  height: fit-content;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  font: normal normal bold 14px Open Sans;
`;
const Option = styled.option`
  height: 40px;
  cursor: pointer;
  border: none;
  outline: none;
  border-bottom: 1px solid gray;
  :focus {
    outline: none;
  }
`;
const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;
const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
const Text = styled.p`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const TabLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;
const TabLabelText = styled.p`
  white-space: nowrap;
  margin: 0px;
`;
const ActiveCounterText = styled.p`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: white;
`;
const InActiveCounterText = styled.p`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #828282;
`;
const ITEM_HEIGHT = 48;

const Home = (props) => {
  let uid = localStorage.getItem("uid");
  const { userStore, filterStore, authStore } = rootStore;
  const [open, setOpen] = useState(false);
  const [assignState, setAssignState] = useState(0);
  const [isClicked, setIsClicked] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [tabsList, setTabsList] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [openSelectCol, setOpenSelectCol] = useState(false);
  const allColumns = [...rootStore.userStore.AllColumnsList];
  const [isError, setIsError] = useState(false);
  const [refreshLeads, setRefreshLeads] = useState(false);
  const [team, setTeam] = useState();
  const [openDeleteLead, setOpenDeleteLead] = useState(false);
  const [loadingTabItemCount, setLoadingTabItemCount] = useState(true);
  let navigate = useNavigate();
  let pid = authStore.projectId;
  //menu icon states**************************

  const [anchorEl, setAnchorEl] = React.useState(null);
  const Open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColumns = () => {
    setOpenSelectCol(true);
    handleClose();
  };

  useEffect(() => {
    if (localStorage.getItem("token") === null) {
      navigate("/intro");
    } else {
      props.setShowBar(true);

      const getTabs = async () => {
        try {
          let response = await getTabsApi(pid);
          setTabsList(response.data);
          userStore.setTabId(response.data[filterStore.TabValue].id);
          userStore.updateDefaultSelectColumns(
            response.data[filterStore.TabValue].selected_columns
          );
          userStore.setSearchColumns(
            response.data[filterStore.TabValue].search_columns
          );
        } catch (error) {
          if (error.response.request.status !== 401) {
            setIsError(true);
          }
        }
      };

      if (pid !== null) {
        getTabs();
      }
    }
  }, [pid]);
  const CustomTab = withStyles({
    root: {
      backgroundColor: "white",
      color: "#4D4E4F",
      font: "normal normal bold 14px Open Sans",
      textTransform: "capitalize",
    },
    selected: {
      backgroundColor: "#E7EEFA",
      color: "#4079DA",
      font: "normal normal bold 14px Open Sans",
      textTransform: "capitalize",
    },
  })(Tab);

  useEffect(() => {
    analytics.triggerEvent(4625000022, "leads_page_load", "leads_page", "", {});
    authStore.updateLeadDetailStatus();
  }, []);

  const RenderTabLabel = (props) => {
    if (props.id === userStore.TabId) {
      if (!loadingTabItemCount) {
        let flag = userStore.tabInfo.hasOwnProperty(props.id);
        if (flag) {
          let count = userStore.tabInfo[props.id]["count"];

          return (
            <TabLabelWrapper>
              <Chip
                style={{ height: "22px" }}
                color="primary"
                label={<ActiveCounterText>{count}</ActiveCounterText>}
              />
              <TabLabelText>{props.name}</TabLabelText>
            </TabLabelWrapper>
          );
        } else {
          return <TabLabelText>{props.name}</TabLabelText>;
        }
      } else {
        let flag = userStore.tabInfo.hasOwnProperty(props.id);
        if (flag) {
          let count = userStore.tabInfo[props.id]["count"];

          return (
            <TabLabelWrapper>
              <Chip
                style={{ height: "22px" }}
                color="primary"
                label={<ActiveCounterText>{count}</ActiveCounterText>}
              />
              <TabLabelText>{props.name}</TabLabelText>
            </TabLabelWrapper>
          );
        } else {
          return <TabLabelText>{props.name}</TabLabelText>;
        }
      }
    } else {
      let flag = userStore.tabInfo.hasOwnProperty(props.id);
      if (flag) {
        let count = userStore.tabInfo[props.id]["count"];

        return (
          <TabLabelWrapper>
            <Chip
              style={{ height: "22px" }}
              color="default"
              label={<InActiveCounterText>{count}</InActiveCounterText>}
            />
            <TabLabelText>{props.name}</TabLabelText>
          </TabLabelWrapper>
        );
      } else {
        return <TabLabelText>{props.name}</TabLabelText>;
      }
    }
  };

  return (
    <>
      {checkFeaturePermission("leads") ? (
        isUnderWorkSchedule() ? (
          <>
            <Container>
              <Wrapper>
                <TopWrapper>
                  {!props.isFiltered ? (
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Text>{getLocalizedText("leads", "project")}</Text>
                          <ChevronRight
                            style={{ width: "16px", height: "16px" }}
                          />
                        </Box>
                      </Grid>
                      {authStore.currentProject["external_dialer"] !==
                        "default" && (
                        <Grid item xs={4}>
                          <Text
                            style={{ textAlign: "center", color: "#21589F" }}
                          >{`You are in "${authStore.getCallingMode()}" calling mode.`}</Text>
                        </Grid>
                      )}
                    </Grid>
                  ) : (
                    <Text style={{ fontSize: "28px" }}>Search Results</Text>
                  )}
                </TopWrapper>
              </Wrapper>
              {authStore.projectId && (
                <Wrapper
                  params={props.isFiltered}
                  style={{ backgroundColor: "white" }}
                >
                  {!props.isFiltered && (
                    <div style={{ width: "60vw" }}>
                      <Tabs
                        variant="scrollable"
                        // scrollButtons="desktop"
                        value={filterStore.TabValue}
                        textColor="primary"
                        indicatorColor="primary"
                        onChange={(event, newValue) => {
                          setLoadingTabItemCount(true);
                          analytics.triggerEvent(
                            4625000023,
                            "leads_tab_tap",
                            "leads_page",
                            "leads_tab",
                            { order: tabsList[newValue]["name"] }
                          );
                          filterStore.updateTabValue(newValue);

                          userStore.setTabId(tabsList[newValue].id);
                          userStore.updateDefaultSelectColumns(
                            tabsList[newValue].selected_columns
                          );
                          userStore.setSearchColumns(
                            tabsList[newValue].search_columns
                          );
                        }}
                      >
                        {tabsList &&
                          tabsList.map((e, i) => {
                            return (
                              <CustomTab
                                label={
                                  <RenderTabLabel id={e.id} name={e.name} />
                                }
                                id={e.id}
                                value={i}
                                onClick={(e) => {
                                  consoleLogger(e.target.id);
                                }}
                              />
                            );
                          })}
                      </Tabs>
                    </div>
                  )}
                  <MenuWrapper>
                    {checkFeaturePermission("assign_leads") && (
                      <IconButton
                        disabled={assignState < 1}
                        onClick={() => {
                          analytics.triggerEvent(
                            4625000024,
                            "assign_leads_button_tap",
                            "leads_page",
                            "assign_leads_button",
                            {}
                          );
                          authStore.updateLastActiveTime();
                          setOpen(true);
                          handleClose();
                        }}
                      >
                        <Assignment style={{ cursor: "pointer" }} />
                      </IconButton>
                    )}
                    {!props.isFiltered && (
                      <IconButton
                        onClick={() => {
                          analytics.triggerEvent(
                            4625000025,
                            "create_lead_button_tap",
                            "leads_page",
                            "create_lead_button",
                            {}
                          );
                          authStore.updateLastActiveTime();
                          setOpenDrawer(!openDrawer);
                        }}
                      >
                        <PersonAddAlt style={{ cursor: "pointer" }} />
                      </IconButton>
                    )}

                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={Open ? "long-menu" : undefined}
                      aria-expanded={Open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <MoreVertIcon />
                    </IconButton>

                    <Menu
                      style={{ display: "flex", flexDirection: "column" }}
                      id="long-menu"
                      MenuListProps={{
                        "aria-labelledby": "long-button",
                      }}
                      anchorEl={anchorEl}
                      open={Open}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5,
                          width: "20ch",
                        },
                      }}
                    >
                      {!props.isFiltered && (
                        <MenuItem
                          onClick={() => {
                            analytics.triggerEvent(
                              4625000026,
                              "select_columns_button_tap",
                              "leads_page",
                              "select_columns_button",
                              {}
                            );
                            authStore.updateLastActiveTime();
                            handleColumns();
                          }}
                        >
                          Select Columns
                        </MenuItem>
                      )}
                      {checkFeaturePermission("lead_details_delete") && (
                        <MenuItem
                          disabled={assignState < 1}
                          onClick={() => {
                            analytics.triggerEvent(
                              4625000027,
                              "delete_leads_button_tap",
                              "leads_page",
                              "delete_leads_button",
                              {}
                            );
                            authStore.updateLastActiveTime();
                            setOpenDeleteLead(true);
                            setAnchorEl(null);
                          }}
                        >
                          Delete Leads
                        </MenuItem>
                      )}
                    </Menu>
                  </MenuWrapper>
                </Wrapper>
              )}
              <Wrapper>
                {tabsList.length > 0 && userStore.ColumnHeaders.length > 0 ? (
                  (() => {
                    if (!props.isFiltered) {
                      return (
                        <NewLeadTab
                          setOpen={setOpen}
                          isClicked={isClicked}
                          setIsClicked={setIsClicked}
                          setIsFiltered={props.setIsFiltered}
                          setAssignState={setAssignState}
                          open={open}
                          openSelectCol={openSelectCol}
                          setOpenSelectCol={setOpenSelectCol}
                          allColumns={allColumns}
                          refresh={refreshLeads}
                          openDeleteLead={openDeleteLead}
                          setOpenDeleteLead={setOpenDeleteLead}
                          setLoadingTabItemCount={setLoadingTabItemCount}
                        />
                      );
                    } else if (props.isFiltered) {
                      return (
                        <FilteredTab
                          row={props.row}
                          loading={props.loading}
                          setLoading={props.setLoading}
                          page={props.page}
                          setPage={props.setPage}
                          setRow={props.setRow}
                          count={props.count}
                          setCount={props.setCount}
                          setIsClicked={setIsClicked}
                          setAssignState={setAssignState}
                          open={open}
                          setOpen={setOpen}
                          openSelectCol={openSelectCol}
                          setOpenSelectCol={setOpenSelectCol}
                          allColumns={allColumns}
                          openDeleteLead={openDeleteLead}
                          setOpenDeleteLead={setOpenDeleteLead}
                        />
                      );
                    }
                  })()
                ) : authStore.projectId ? (
                  <SpinnerWrapper>
                    <Spinner src={spinner} alt="loading..." />
                  </SpinnerWrapper>
                ) : (
                  <EmptyDiv>
                    Something went wrong! Please contact your administrator.
                  </EmptyDiv>
                )}
              </Wrapper>
            </Container>
            <Footer />
          </>
        ) : (
          <NoAccessPage />
        )
      ) : (
        <NoAccessPermissionPage />
      )}
      {openFilter && <FilterModal open={openFilter} setOpen={setOpenFilter} />}
      <Drawer
        anchor={"right"}
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
      >
        {
          <CreateLeadComponent
            open={openDrawer}
            setOpen={setOpenDrawer}
            refresh={refreshLeads}
            setRefresh={setRefreshLeads}
          />
        }
      </Drawer>
      {isError && <AlertDialogue open={isError} setOpen={setIsError} />}
    </>
  );
};

export default observer(Home);
