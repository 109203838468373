import { Box, Grid } from "@material-ui/core";
import React from "react";
import { styled } from "@mui/material/styles";
import { FormControl, MenuItem, Typography, Select } from "@mui/material";
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
  padding-bottom: 0px;
  overflow-y: auto;
  row-gap: 10px;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const Label = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Header = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #a9a9a9;
`;
const DefaultParameterScreen = ({
  selectedDefaultParameterMapping,
  setSelectedDefaultParameterMapping,
  memberList,
  statusList,
  stageList,
  sourceList,
}) => {
  const handleChange = (event) => {
    setSelectedDefaultParameterMapping({
      ...selectedDefaultParameterMapping,
      [event.target.name]: event.target.value,
    });
  };

  console.log(selectedDefaultParameterMapping);

  return (
    <>
      <Box>
        <Container>
          <Header>Default Parameters</Header>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputWrapper>
                <Label>Select Lead Owner*</Label>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    displayEmpty
                    required
                    onChange={(event) => {
                      let tempObj = { ...selectedDefaultParameterMapping };
                      tempObj["lead_owner_id"] = event.target.value;
                      let index = memberList.findIndex(
                        (member) => member["user_id"] === event.target.value
                      );
                      if (index !== -1) {
                        tempObj["team_id"] = memberList[index]["team"]["id"];
                      } else {
                        tempObj["team_id"] = "";
                      }
                      setSelectedDefaultParameterMapping(tempObj);
                    }}
                    name="lead_owner_id"
                    disableUnderline
                    variant="outlined"
                    value={selectedDefaultParameterMapping["lead_owner_id"]}
                    style={{
                      width: "100%",
                      height: "30px",
                      fontSize: "12px",
                      borderRadius: "0px",
                    }}
                  >
                    {memberList.map((item, idx) => {
                      return (
                        <MenuItem
                          value={item.user_id}
                          key={idx}
                          style={{ fontSize: "12px" }}
                        >
                          {item["name"]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </InputWrapper>
            </Grid>
            <Grid item xs={6}>
              <InputWrapper>
                <Label>Select Lead Source*</Label>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    displayEmpty
                    required
                    onChange={handleChange}
                    name="lead_source_id"
                    disableUnderline
                    variant="outlined"
                    value={selectedDefaultParameterMapping["lead_source_id"]}
                    style={{
                      width: "100%",
                      height: "30px",
                      fontSize: "12px",
                      borderRadius: "0px",
                    }}
                  >
                    {sourceList.map((item, idx) => {
                      return (
                        <MenuItem
                          value={item.id}
                          key={idx}
                          style={{ fontSize: "12px" }}
                        >
                          {item["source"]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </InputWrapper>
            </Grid>
            <Grid item xs={6}>
              <InputWrapper>
                <Label>Select Lead Stage*</Label>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    displayEmpty
                    required
                    onChange={handleChange}
                    name="lead_stage_id"
                    disableUnderline
                    variant="outlined"
                    value={selectedDefaultParameterMapping["lead_stage_id"]}
                    style={{
                      width: "100%",
                      height: "30px",
                      fontSize: "12px",
                      borderRadius: "0px",
                    }}
                  >
                    {stageList.map((item, idx) => {
                      return (
                        <MenuItem
                          value={item.id}
                          key={idx}
                          style={{ fontSize: "12px" }}
                        >
                          {item["stage"]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </InputWrapper>
            </Grid>
            <Grid item xs={6}>
              <InputWrapper>
                <Label>Select Lead Status*</Label>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    displayEmpty
                    required
                    onChange={handleChange}
                    name="lead_status_id"
                    disableUnderline
                    variant="outlined"
                    value={selectedDefaultParameterMapping["lead_status_id"]}
                    style={{
                      width: "100%",
                      height: "30px",
                      fontSize: "12px",
                      borderRadius: "0px",
                    }}
                  >
                    {statusList.map((item, idx) => {
                      return (
                        <MenuItem
                          value={item.id}
                          key={idx}
                          style={{ fontSize: "12px" }}
                        >
                          {item["status"]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </InputWrapper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default DefaultParameterScreen;
