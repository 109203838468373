import React from "react";
import { Box, AppBar as MuiAppbar, Toolbar } from "@mui/material";
import { styled } from "@mui/system";
import logo_placeholder from "../../../../assets/logo_placeholder.png";

const StyledAppBar = styled(MuiAppbar)(({ theme }) => ({
  backgroundColor: "#fff",
  // position: "sticky",
  position: "static",
  width: "100%",
  [[theme.breakpoints.between("xs", "lg")]]: {},
  [theme.breakpoints.up("lg")]: {},
}));

const LogoLeftImg = styled("img")(({ theme }) => ({
  maxHeight: "28px",
  width: "auto",
  [[theme.breakpoints.between("xs", "md")]]: {
    maxWidth: "45%",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    maxWidth: "45%",
  },
  [theme.breakpoints.up("lg")]: {
    maxWidth: "40%",
  },
}));

const Wrapper = styled(Box)(({ theme }) => ({
  width: "95%",
  display: "flex",
  alignItems: "center",
  height: "64px",
  margin: "auto",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    paddingLeft: theme.spacing(2),
  },
}));

const AppBar = ({ logoURL }) => {
  return (
    <React.Fragment>
      <StyledAppBar elevation={0}>
        <Toolbar
          disableGutters
          style={{ height: "fit-content", width: "100%" }}
        >
          <Wrapper>
            <LogoLeftImg
              src={logoURL !== "" ? logoURL : logo_placeholder}
              alt="Left logo"
            />
          </Wrapper>
        </Toolbar>
      </StyledAppBar>
    </React.Fragment>
  );
};

export default AppBar;
