import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Button } from "@material-ui/core";
import { styled } from "@mui/material/styles";

const Message = styled(Typography)(({ theme }) => ({
  font: "normal normal 400 16px Open Sans",
  color: "#4d4e4f",
}));
const CustomAlertMessageDialog = ({
  title,
  open,
  handleClose,
  handleCloseOutside,
  message,
}) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseOutside}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",

            padding: "20px",
          }}
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ padding: "0px", fontWeight: "bold" }}
          >
            {title}
          </DialogTitle>
          <DialogContent style={{ padding: "0px" }}>
            <DialogContentText id="alert-dialog-description">
              <Message>{message}</Message>
            </DialogContentText>
          </DialogContent>

          <DialogActions style={{ padding: "0px", marginTop: "20px" }}>
            <Button
              color="primary"
              variant="contained"
              style={{
                backgroundColor: "#185DD2",
                textTransform: "capitalize",
                fontWeight: "bold",
                width: "100px",

                color: "white",
              }}
              onClick={handleClose}
            >
              Close
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};
export default CustomAlertMessageDialog;
