import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getLocalizedText } from "../../Functions";
import { myTheme } from "../../themeUtils";

const AlertDialogue = (props) => {
  const navigate = useNavigate();
  const handleClose = () => {
    props.setOpen(false);
  };
  useEffect(() => {
    props.setOpen(true);
  }, []);
  const handleAlert = () => {
    localStorage.clear();
    navigate("/login");
  };
  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{
            color: "red",
            fontSize: "18px",
            fontWeight: "bold",
            paddingBottom: "0px",
          }}
        >
          {`${getLocalizedText("some_error_occurred")}. ${getLocalizedText(
            "please_contact_your_administrator"
          )}`}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ margin: "auto" }}>
        <Button
          variant="contained"
          style={{ marginTop: "-20px", ...myTheme.Button.btnBlue }}
          onClick={handleClose}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialogue;
