import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Tooltip,
  Typography,
} from "@mui/material";
import { Button } from "@material-ui/core";
import { styled } from "@mui/material/styles";

const Message = styled(Typography)(({ theme }) => ({
  font: "normal normal 400 16px Open Sans",
  color: "#4d4e4f",
}));
const TestCampaignDialog = (props) => {
  function truncate(str, n) {
    return str.length > n ? str.slice(0, n - 1) + "..." : str;
  }
  return (
    <>
      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          style={{
            padding: "20px",
            display: "flex",
            rowGap: "10px",
            flexDirection: "column",
            width: "440px",
            height: "116px",
          }}
        >
          {props.isLoading ? (
            <>
              <DialogTitle id="alert-dialog-title" style={{ padding: "0px" }}>
                {props.loadingTitle}
              </DialogTitle>
              <Divider />
              <DialogContent style={{ padding: "0px", overflow: "visible" }}>
                <DialogContentText
                  style={{
                    display: "flex",
                    columnGap: "10px",
                    alignItems: "center",
                    height: "fit-content",
                  }}
                  id="alert-dialog-description"
                >
                  <CircularProgress style={{ width: "22px", height: "22px" }} />
                  <Tooltip title={props.loadingMessage}>
                    <Message>{truncate(props.loadingMessage, 45)}</Message>
                  </Tooltip>
                </DialogContentText>
              </DialogContent>
            </>
          ) : (
            <DialogContent style={{ padding: "0px" }}>
              <DialogContentText id="alert-dialog-description">
                {props.isTestSuccess ? (
                  <Message>{props.successMessage}</Message>
                ) : (
                  <Message style={{ color: "#ff0000" }}>
                    {props.errorMessage}
                  </Message>
                )}
              </DialogContentText>
            </DialogContent>
          )}

          {!props.isLoading && (
            <DialogActions style={{ padding: "0px" }}>
              <Button
                color="primary"
                variant="contained"
                style={{
                  backgroundColor: "#185DD2",
                  textTransform: "capitalize",
                  fontWeight: "bold",
                  color: "white",
                  width: "100px",
                }}
                onClick={() => {
                  props.setOpen(false);
                }}
              >
                Close
              </Button>
            </DialogActions>
          )}
        </Box>
      </Dialog>
    </>
  );
};
export default TestCampaignDialog;
