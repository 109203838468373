import { AddBoxOutlined } from "@mui/icons-material";
import { Box, IconButton, OutlinedInput } from "@mui/material";
import React, { useState } from "react";

const InputFieldWithAddButton = ({ formState, setFormState, fieldMeta }) => {
  const [value, setValue] = useState("");
  const handleAdd = () => {
    let tempList = [...formState];
    let index = tempList.findIndex((field) => field["id"] === fieldMeta["id"]);
    let inputList = tempList[index]["inputs"];
    if (!inputList.includes(value)) {
      inputList.push(value);
    }
    tempList[index]["inputs"] = inputList;
    setFormState(tempList);
    setValue("");
  };
  return (
    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
      <OutlinedInput
        style={{ height: "30px", width: "100%" }}
        inputProps={{
          min: 0,
          "aria-label": "weight",
          style: {
            fontSize: "12px",
          },
        }}
        value={value}
        type="text"
        placeholder="Type something"
        name="inputs"
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      <IconButton
        color="primary"
        style={{ padding: "0px" }}
        disabled={value === ""}
        onClick={handleAdd}
      >
        <AddBoxOutlined
          style={{
            width: "auto",
            height: "30px",
          }}
        />
      </IconButton>
    </Box>
  );
};

export default InputFieldWithAddButton;
