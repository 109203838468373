import React, { useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import { Grid } from "@material-ui/core";
import {
  AppBar,
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  dataRenderer,
  getLocalTimeStringFromIsoDateString,
  getWorkTimingBreakChipColorTheme,
} from "../../Functions";
import { daysList, daywiseWorkTimeColumnHeaders } from "../../Db";
import { Visibility } from "@material-ui/icons";
import ViewDayConfigure from "./dialogs/ViewDayConfigure";
import { AgGridReact } from "ag-grid-react";
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 8px;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  gap: 20px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;
const ChipText = styled(Typography)`
  font: normal normal 600 10px Open Sans;
`;
const DetailsHeader = styled(Typography)`
  font: normal normal 600 16px Open Sans;
  color: #979797;
  margin-bottom: 8px;
`;
const ViewWorkDayTemplateComponent = ({ details }) => {
  const [selectedDayData, setSelectedDayData] = useState({});
  const [openViewDayData, setOpenViewDayData] = useState(false);
  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const renderBreakData = ({ data }) => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <ChipText style={{ fontSize: "12px" }}>{data["title"]}</ChipText>
        <ChipText>{`${getLocalTimeStringFromIsoDateString(
          data["start_time"]
        )} - ${getLocalTimeStringFromIsoDateString(
          data["end_time"]
        )}`}</ChipText>
      </Box>
    );
  };

  const renderBoolValue = (value) => {
    if (value !== undefined && value !== null && value !== "") {
      if (value === false) {
        return (
          <Chip color="warning" label={"No"} style={{ width: "fit-content" }} />
        );
      } else {
        return (
          <Chip
            color="success"
            label={"Yes"}
            style={{ width: "fit-content" }}
          />
        );
      }
    }
    return "-";
  };
  const getDayData = (dayName) => {
    let newKey = dayName + "_timings";

    if (details.hasOwnProperty(newKey)) {
      return details[newKey];
    }
    return {};
  };

  const generateDayWiseData = () => {
    let tempList = [];
    daysList.forEach((item, i) => {
      if (details["workday_" + item["value"]] === true) {
        let tempObj = getDayData(item["value"]);
        tempObj["day"] = item["name"];
        tempObj["can_view"] = true;
        tempList.push(tempObj);
      } else {
        let tempObj = {
          day: item["name"],
          can_view: false,
        };
        tempList.push(tempObj);
      }
    });
    return tempList;
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "ACTIONS",
      minWidth: 100,
      flex: 1,
      cellRenderer: (params) => {
        return (
          <IconButton
            disabled={params.data.can_view === false}
            onClick={() => {
              setSelectedDayData(params.data);
              setOpenViewDayData(true);
            }}
            color="primary"
            style={{ padding: "0px" }}
          >
            <Visibility
              style={{
                width: "20px",
                height: "20px",
              }}
            />
          </IconButton>
        );
      },
    },
  ];

  const RenderDaysAndTimings = () => {
    if (Object.keys(details).length > 0) {
      if (details["daywise"] === true) {
        let tempList = generateDayWiseData();
        return (
          <Grid container item spacing={1}>
            <Grid item xs={12}>
              <div className="ag-theme-alpine" style={{ width: "100%" }}>
                <AgGridReact
                  domLayout="autoHeight"
                  gridOptions={gridOptions}
                  rowData={tempList}
                  defaultColDef={defaultColDef}
                  columnDefs={daywiseWorkTimeColumnHeaders.concat(actionColumn)}
                  animateRows={true}
                  suppressCellFocus
                />
              </div>
            </Grid>
          </Grid>
        );
      } else {
        return (
          <Grid container item spacing={1}>
            {daysList.map((item, i) => {
              return (
                <Grid item xs={4} key={i}>
                  <FormControlLabel
                    style={{ marginRight: "0px" }}
                    key={i}
                    checked={details["workday_" + item["value"]] === true}
                    control={<Checkbox color={"primary"} size="small" />}
                    label={
                      <Detail
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        {item["name"]}
                      </Detail>
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
        );
      }
    }
  };

  return (
    <Box role="presentation">
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          width: 500,
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar>
          <ModelHeader>Work Day Template Details</ModelHeader>
        </Toolbar>
      </AppBar>
      <ModelWrapper>
        <InputContainer>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputWrapper>
                <Label>Name</Label>
                <Detail>{dataRenderer(details["name"])}</Detail>
              </InputWrapper>
            </Grid>
            <Grid item xs={12}>
              <InputWrapper>
                <Label>Auto Logout at the end of the day</Label>
                <Detail>{renderBoolValue(details["auto_logout"])}</Detail>
              </InputWrapper>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>Daywise Timings</Label>
                    <Detail>{renderBoolValue(details["daywise"])}</Detail>
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <DetailsHeader> Days And Timings</DetailsHeader>
                    <RenderDaysAndTimings />
                  </InputWrapper>
                </Grid>
                {details.hasOwnProperty("daywise") &&
                  details["daywise"] === false && (
                    <Grid container spacing={2} item>
                      <Grid item xs={6}>
                        <InputWrapper>
                          <Label>Start Time</Label>
                          <Detail>
                            {details.hasOwnProperty("timings_all") &&
                            Object.keys(details.timings_all).length > 0
                              ? getLocalTimeStringFromIsoDateString(
                                  details.timings_all["start_time"]
                                )
                              : "-"}
                          </Detail>
                        </InputWrapper>
                      </Grid>
                      <Grid item xs={6}>
                        <InputWrapper>
                          <Label>End Time</Label>
                          <Detail>
                            {details.hasOwnProperty("timings_all") &&
                            Object.keys(details.timings_all).length > 0
                              ? getLocalTimeStringFromIsoDateString(
                                  details.timings_all["end_time"]
                                )
                              : "-"}
                          </Detail>
                        </InputWrapper>
                      </Grid>
                      <Grid item xs={6}>
                        <InputWrapper>
                          <Label>End Time Buffer (Minutes)</Label>
                          <Detail>
                            {details.hasOwnProperty("timings_all") &&
                            Object.keys(details.timings_all).length > 0
                              ? dataRenderer(
                                  details.timings_all["end_time_buffer"]
                                )
                              : "-"}
                          </Detail>
                        </InputWrapper>
                      </Grid>
                      {details.hasOwnProperty("timings_all") &&
                        Object.keys(details.timings_all).length > 0 &&
                        details.timings_all.breaks.length > 0 && (
                          <Grid item xs={12}>
                            <InputWrapper>
                              <DetailsHeader>Breaks</DetailsHeader>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "8px",
                                  flexWrap: "wrap",
                                  maxHeight: "100px",
                                  overflowY: "auto",
                                }}
                              >
                                {details.timings_all.breaks.map((obj, idx) => {
                                  return (
                                    <Chip
                                      color={getWorkTimingBreakChipColorTheme({
                                        breakData: obj,
                                      })}
                                      style={{
                                        height: "fit-content",
                                        padding: "8px",
                                      }}
                                      label={renderBreakData({ data: obj })}
                                      key={idx}
                                    />
                                  );
                                })}
                              </Box>
                            </InputWrapper>
                          </Grid>
                        )}
                    </Grid>
                  )}
              </Grid>
            </Grid>
          </Grid>
        </InputContainer>
      </ModelWrapper>
      {openViewDayData && (
        <ViewDayConfigure
          details={selectedDayData}
          open={openViewDayData}
          setOpen={setOpenViewDayData}
        />
      )}
    </Box>
  );
};

export default ViewWorkDayTemplateComponent;
