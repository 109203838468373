import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Modal,
  Select,
  IconButton,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import rootStore from "../../stores/RootStore";
import { getMembersApiV2, leadAssignApi } from "../../Api";
import {
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  Radio,
  Divider,
  Avatar,
  OutlinedInput,
  CircularProgress,
} from "@mui/material";
import { myTheme } from "../../themeUtils";
import {
  getISODateTimeByTimeSlot,
  getLocalizedText,
  getTimeSlotsForPriorityLead,
  validateDisabledSubFeature,
  checkFeaturePermission,
} from "../../Functions";
import moment from "moment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MemberFilterModal from "./MemberFilterModal";
import { Typography } from "antd";
import profile_image from "../../assets/profile_placeholder.png";

const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 30px;
`;
const ModelWrapper = styled.div`
  width: 420px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
`;
const Header = styled.p`
  margin: 0px;
  font: normal normal 600 18px Open Sans;
`;
const SubHeader = styled.p`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
  color: #5a5a5a;
`;
// const Button = styled.button`
//   width: 218px;
//   height: 50px;
//   border: none;
//   border-radius: 90px;
//   padding: 12px;
//   background-color: #185dd2;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   color: #ffffff;
//   cursor: pointer;
//   text-transform: uppercase;
//   font: normal normal 600 16px/25px Open Sans;
//   margin: auto;
//   margin-top: 30px;
// `;
const FlagText = styled.p`
  margin: 0px;
  font: normal normal 500 12px Open Sans;
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top:20px;
`;
const ProfileImage = styled("img")`
  aspect-ratio: 1/1;
  width: 100%;
  height: auto;
  border-radius: 50%;
  object-fit: cover;
`;
const ImageWrapper = styled(Avatar)`
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 1px solid #a7a6a8;
  padding: 0px;
  margin-right: 8px;
  position: relative;
  background-color: white;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LeadAssignModal = (props) => {
  const [member, setMember] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [memberName, setMemberName] = useState("");
  const [isAssign, setIsAssign] = useState(false);
  const { authStore, userStore } = rootStore;
  const [flag, setFlag] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]);
  const [timeSlot, setTimeSlot] = useState("");
  const [filterPopupOpen, setFilterPopupOpen] = useState(false);
  const [comments, setComments] = useState("");
  //member filters
  const [selectedRolesFilter, setSelectedRolesFilter] = useState([]);
  const [selectedTeamsFilter, setSelectedTeamsFilter] = useState([]);
  const [initLoading, setInitLoading] = useState(true);

  const handleChange = (event) => {
    setMemberName(event.target.value);
  };
  const [status, setStatus] = useState(false);

  const handleAssign = async () => {
    document.getElementById("assign-btn").innerText = "Loading...";
    let payload = {
      lead_ids: props.leads,
      assign_to: memberName,
      erase_history: flag,
    };
    if (timeSlot !== "") {
      let dateTimeString = getISODateTimeByTimeSlot(timeSlot);
      payload["hard_update_by"] = dateTimeString;
    }
    if (comments !== "") {
      payload["comments"] = comments;
    }
    try {
      let response = await leadAssignApi({
        payload: payload,
      });
      document.getElementById("assign-btn").style.backgroundColor = "green";
      document.getElementById("assign-btn").innerText = "Assigned";
      setIsAssign(true);
      setStatus(false);
      setTimeout(() => {
        props.setRefresh(!props.refresh);
        props.setOpen(false);
      }, [800]);
    } catch (error) {
      console.log(error);
    }
  };

  const getTeamMembers = async () => {
    let response = await getMembersApiV2();
    setMember(response);
    setFilteredMembers(response);
  };
  const init = async () => {
    let time_slots = getTimeSlotsForPriorityLead();
    setTimeSlots(time_slots);
    await getTeamMembers();
    setInitLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const handleFlag = (e) => {
    setFlag(e.target.checked);
  };

  const handleFilterSubmit = (teamsFilterValue, rolesFilterValue) => {
    //resetting selections and applying filters for members
    setMemberName("");
    setSelectedRolesFilter(rolesFilterValue);
    setSelectedTeamsFilter(teamsFilterValue);

    let member_list = member;

    //apply team filter
    if (teamsFilterValue.length !== 0) {
      if (!teamsFilterValue.find((x) => x.value === "All")) {
        const selectedTeamIDs = teamsFilterValue.map((x) => x.value);
        member_list = member_list.filter((x) =>
          selectedTeamIDs.includes(x.team.id)
        );
      }
    }

    //apply role filter
    if (rolesFilterValue.length !== 0) {
      const selectedRoleValues = rolesFilterValue.map((x) => x.value);
      member_list = member_list.filter((x) =>
        selectedRoleValues.includes(x.role)
      );
    }
    setFilteredMembers(member_list);
  };

  const canHaveDetailedMemberList = () => {
    if (userStore.UserData !== null) {
      let accountRole = userStore.UserData.role;
      let projectRole = authStore.getCurrentProjectRole();

      if (accountRole === "admin" || accountRole === "owner") {
        return true;
      } else {
        if (projectRole === "manager") {
          return true;
        }
        return false;
      }
    }
    return false;
  };
  return (
    <>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            {!initLoading ? (
              <ModelWrapper>
                <Header>{getLocalizedText("assign_lead_to")}</Header>
                <SubHeader>
                  {getLocalizedText("select_from_below_dropdown")}
                </SubHeader>
                <Divider style={{ margin: "10px 0px" }} />
                <InputLabel
                  id="demo-simple-select-label"
                  style={{
                    font: "normal normal 600 10px Open Sans",
                    color: "#4d4e4f",
                  }}
                >
                  {getLocalizedText("select_member")}
                </InputLabel>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  {canHaveDetailedMemberList() ? (
                    <FormControl
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Select
                        variant="outlined"
                        value={memberName}
                        renderValue={(value) =>
                          filteredMembers.find((x) => x.id === value).name
                        }
                        onChange={(e) => {
                          e.stopPropagation();
                          handleChange(e);
                        }}
                        style={{
                          width: "100%",
                          font: "normal normal 500 12px Open Sans",
                          height: "30px",
                        }}
                      >
                        {filteredMembers.map((e) => {
                          return (
                            <MenuItem
                              component={Paper}
                              square={false}
                              id={e.id}
                              value={e.id}
                              sx={{
                                margin: "1px",
                                marginBottom: "4px",
                                padding: 1,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              {" "}
                              <Radio
                                checked={e.id === memberName}
                                onChange={(e) => {
                                  e.stopPropagation();
                                }}
                                value={memberName}
                                name="radio-buttons"
                              />
                              <ImageWrapper>
                                <ProfileImage
                                  src={
                                    e.profile_pic != null
                                      ? e.profile_pic
                                      : profile_image
                                  }
                                  alt="loading..."
                                />
                              </ImageWrapper>
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Box
                                  sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      width: "fit-content",
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      textAlign: "left",
                                    }}
                                  >
                                    {e.name}
                                  </Typography>
                                  <Typography
                                    style={{
                                      width: "fit-content",
                                      fontSize: "12px",
                                      textAlign: "left",
                                      color: "#b7b7b7",
                                    }}
                                  >
                                    Not Set:{" "}
                                    {e.leads_count.hasOwnProperty(
                                      "not_set_leads"
                                    ) &&
                                    e.leads_count["not_set_leads"] !== null &&
                                    e.leads_count["not_set_leads"] !== -1
                                      ? e.leads_count["not_set_leads"]
                                      : "-"}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      width: "fit-content",
                                      fontSize: "12px",
                                      textAlign: "left",
                                      color: "#b7b7b7",
                                    }}
                                  >
                                    Role: {e.role}
                                  </Typography>
                                  <Typography
                                    style={{
                                      width: "fit-content",
                                      fontSize: "12px",
                                      textAlign: "right",
                                      color: "#b7b7b7",
                                    }}
                                  >
                                    Team: {e.team.name}
                                  </Typography>
                                </Box>
                              </Box>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  ) : (
                    <FormControl
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Select
                        variant="outlined"
                        value={memberName}
                        onChange={handleChange}
                        style={{
                          width: "100%",

                          font: "normal normal 500 12px Open Sans",
                          height: "30px",
                        }}
                      >
                        {filteredMembers.map((e) => {
                          return (
                            <MenuItem
                              id={e.id}
                              value={e.id}
                              style={{ fontSize: "12px" }}
                            >
                              {e.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                  {checkFeaturePermission("assign_leads_member_filter") && (
                    <IconButton
                      size="medium"
                      onClick={() => {
                        setFilterPopupOpen(true);
                      }}
                      style={{
                        padding: "0px",
                      }}
                    >
                      <FilterAltIcon color="primary" />
                    </IconButton>
                  )}
                </Box>
                <Box
                  component={"fieldset"}
                  sx={{
                    marginBottom: "20px",
                  }}
                >
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <legend
                        style={{
                          width: "auto",
                          marginBottom: "0px",
                          fontSize: "12px",
                        }}
                      >
                        Mark as Priority
                      </legend>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <InputLabel
                          style={{
                            font: "normal normal 600 10px Open Sans",
                            color: "#4d4e4f",
                          }}
                        >
                          Process by
                        </InputLabel>

                        <Select
                          name="process_by"
                          displayEmpty
                          variant="outlined"
                          value={timeSlot}
                          onChange={(e) => {
                            setTimeSlot(e.target.value);
                          }}
                          style={{
                            width: "100%",
                            font: "normal normal 500 12px Open Sans",
                            height: "30px",
                          }}
                        >
                          {timeSlots.map((e) => {
                            return (
                              <MenuItem id={e} value={e}>
                                {e}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
       
                <Box
                 
                >
                  <InputWrapper>
                    <InputLabel
                      id="demo-simple-select-label"
                      style={{
                        font: "normal normal 600 10px Open Sans",
                        color: "#4d4e4f",
                      }}
                    >
                      Comments
                    </InputLabel>
                    <OutlinedInput
                      multiline={true}
                      rows={3}
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      value={comments}
                      name={"comments"}
                      type={"text"}
                      placeholder={"Enter Value"}
                      onChange={(e) => setComments(e.target.value)}
                    />
                  </InputWrapper>
                </Box>
                {validateDisabledSubFeature("erase_history") && (
                  <FormControlLabel
                    control={<Checkbox color="primary" />}
                    label={
                      <FlagText>{getLocalizedText("erase_history")}</FlagText>
                    }
                    onChange={handleFlag}
                  />
                )}
                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      props.setOpen(false);
                    }}
                    variant="contained"
                    color="default"
                    style={{
                      width: "100%",
                      font: "normal normal 600 10px Open Sans",
                      marginRight: "10px",
                      textTransform: "none",
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                  <Button
                    id="assign-btn"
                    onClick={handleAssign}
                    variant="contained"
                    color="primary"
                    disabled={memberName === ""}
                    style={{
                      font: "normal normal 600 10px Open Sans",
                      width: "100%",
                      textTransform: "none",
                    }}
                  >
                    {getLocalizedText("assign")}
                  </Button>
                </ButtonWrapper>
              </ModelWrapper>
            ) : (
              <ModelWrapper
                style={{
                  height: "300px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </ModelWrapper>
            )}
          </Box>
        </ModelContainer>
      </Modal>

      {filterPopupOpen && (
        <MemberFilterModal
          open={filterPopupOpen}
          setOpen={setFilterPopupOpen}
          handleSubmit={handleFilterSubmit}
          selectedTeamsFilter={selectedTeamsFilter}
          selectedRolesFilter={selectedRolesFilter}
          setSelectedTeamsFilter={setSelectedTeamsFilter}
          setSelectedRolesFilter={setSelectedRolesFilter}
        />
      )}
    </>
  );
};

export default LeadAssignModal;
