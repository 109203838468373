import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

const ErrorMessageDialog = (props) => {
  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => {
          props.setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText
            style={{ fontWeight: "bold", color: "red" }}
            id="alert-dialog-description"
          >
            {props.message}
          </DialogContentText>
        </DialogContent>
        {
          <DialogActions>
            <Button
              color="primary"
              style={{ textTransform: "capitalize", fontWeight: "bold" }}
              onClick={() => {
                props.setOpen(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        }
      </Dialog>
    </>
  );
};
export default ErrorMessageDialog;
