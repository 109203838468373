import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, Grid, Modal } from "@material-ui/core";
import { Typography, Divider } from "@mui/material";
import { MultiSelect } from "react-multi-select-component";
import rootStore from "../../stores/RootStore";

const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "fit-content",
  textAlign: "left",
  margin: "auto",
  backgroundColor: "white",
  height: "fit-content",
  "&::-webkit-scrollbar": {
    width: "0.2em",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px #ffffff",
  },
});

const InputContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "fit-content",
  width: "320px",
  rowGap: "20px",
});

const Label = styled(Typography)({
  font: "normal normal normal 10px Open Sans",
  color: "#4d4e4f",
});

const InputWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const ModelContainer = styled(Box)({
  position: "absolute",
  top: "0px",
  bottom: "0px",
  left: "0px",
  right: "0px",
  margin: "auto",
  height: "fit-content",
  width: "fit-content",
  border: "none",
  outline: "none",
  backgroundColor: "white",
  boxShadow: "0px 3px 6px #0000000d",
  borderRadius: "8px",
  padding: "30px",
  border: "1px solid #c5d7f1",
  boxShadow: "0px 3px 20px #185dd21f",
});

const ButtonWrapper = styled(Box)({
  marginTop: "20px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  columnGap: "10px",
});

const Header = styled(Typography)({
  font: "normal normal 600 18px Open Sans",
  color: "#4d4e4f",
  margin: "0px",
});

const SubHeader = styled(Typography)({
  font: "normal normal normal 14px Open Sans",
  color: "#6f6f6f",
  margin: "0px",
  marginTop: "2px",
});

const MemberFilterModal = (props) => {
  const rolesList = [
    {
      label: "Lead",
      value: "lead",
    },
    {
      label: "Member",
      value: "member",
    },
    {
      label: "Manager",
      value: "manager",
    },
  ];
  const [selectedRolesFilter, setSelectedRolesFilter] = useState(props.selectedRolesFilter);
  const [selectedTeamsFilter, setSelectedTeamsFilter] = useState(props.selectedTeamsFilter);
  const [teamList, setTeamList] = useState([]);
  
  const handleClose = () => {
    props.setOpen(false);
  };

  const handleSubmit = () => {
    props.handleSubmit(selectedTeamsFilter, selectedRolesFilter);
    props.setOpen(false);
  };

  const handleChange = (name, value) => {
    //
  };

  useEffect(() => {
    setup();
  }, []);

  const setup = () => {
    const teams = rootStore.authStore.currentProject["teams"];
    const teamList = teams.map((team) => ({
      label: team.name,
      value: team.id,
    }));
    setTeamList(teamList);
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Header>Filter members</Header>
          <SubHeader>Select filters from below dropdowns</SubHeader>
          <Divider style={{ margin: "10px 0px" }} />
          <Box>
            <Container
              component={"form"}
              onSubmit={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleSubmit();
              }}
            >
              <InputContainer>
                <Grid item container spacing={2}>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Team</Label>
                      <MultiSelect
                        hasSelectAll={false}
                        options={teamList}
                        value={selectedTeamsFilter}
                        className={"global_filter_multi_select"}
                        onChange={(e) => {
                            setSelectedTeamsFilter(e);
                        }}
                        labelledBy="Select Team"
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Role</Label>
                      <MultiSelect
                        hasSelectAll={false}
                        options={rolesList}
                        value={selectedRolesFilter}
                        className={"global_filter_multi_select"}
                        onChange={(e) => {
                            setSelectedRolesFilter(e);
                        }}
                        labelledBy="Select Role"
                      />
                    </InputWrapper>
                  </Grid>
                </Grid>
              </InputContainer>
              <ButtonWrapper>
                <Button
                  onClick={handleClose}
                  type="button"
                  variant="contained"
                  color="inherit"
                  style={{
                    textTransform: "none",

                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "none",

                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  Submit
                </Button>
              </ButtonWrapper>
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default MemberFilterModal;
