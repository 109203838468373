import { action, makeObservable, observable } from "mobx";
import { makePersistable } from "mobx-persist-store";

class AttendancePageState {
  attendanceChipIndex = 0;
  constructor() {
    makeObservable(this, {
      attendanceChipIndex: observable,
      updateAttendanceChipIndex: action,
      init: action,
      resetStore: action,
    });

    makePersistable(this, {
      name: AttendancePageState,
      storage: window.localStorage,
      expireIn: 86400000,
      removeOnExpiration: true,
      stringify: true,
      debugMode: false,
      properties: ["attendanceChipIndex"],
    });
  }

  updateAttendanceChipIndex = (index) => {
    this.attendanceChipIndex = index;
  };

  resetStore = () => {
    this.attendanceChipIndex = 0;
  };

  init = async () => {
    
  };
}

export default AttendancePageState;
