import { Button, makeStyles } from "@material-ui/core";
import { Pagination, PaginationItem } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { getLocalizedText } from "../../Functions";

const Counter = styled.div`
  font: normal normal 600 14px Open Sans;
  display: flex;
  width: fit-content;
  height: fit-content;
  color: #4d4e4f;
  padding: 6px;
`;
const TableFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
  @media (max-width: 700px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;
const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root.Mui-selected": {
      color: "white",
      backgroundColor: "#4D4E4F",
      margin: "0px",
      borderRadius: "0px",
      height: "25px",
      minWidth: "20px",
    },
    "& .MuiPaginationItem-root": {
      margin: "0px",
      borderRadius: "0px",
      height: "25px",
      minWidth: "20px",
    },
  },
}));
const PaginationWithoutPageSize = (props) => {
  const classes = useStyles();
  const PrevButton = () => {
    return (
      <Button
        variant="text"
        color="default"
        style={{
          fontWeight: "bold",
          width: "65px",
          fontSize: "12px",
          height: "25px",
        }}
      >
        {getLocalizedText("prev")}
      </Button>
    );
  };
  const NextButton = () => {
    return (
      <Button
        variant="text"
        color="primary"
        style={{
          fontWeight: "bold",
          width: "65px",
          fontSize: "12px",
          height: "25px",
        }}
      >
        {getLocalizedText("next")}
      </Button>
    );
  };
  return (
    <TableFooter>
      <Row>
        <Counter>{props.counterText}</Counter>
        <Pagination
          classes={{ ul: classes.ul }}
          renderItem={(item) => (
            <PaginationItem
              components={{
                previous: PrevButton,
                next: NextButton,
              }}
              {...item}
            />
          )}
          variant="outlined"
          shape="rounded"
          count={Math.ceil(props.count / props.page_size)}
          color="primary"
          onChange={(e, page) => {
            props.handlePage(e, page);
          }}
          page={props.page_no + 1}
        />
      </Row>
    </TableFooter>
  );
};

export default PaginationWithoutPageSize;
