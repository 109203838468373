import React from "react";
import { styled } from "@mui/material/styles";
import { AppBar, Box, Chip, Toolbar, Typography } from "@mui/material";
import { Grid, Tooltip } from "@material-ui/core";
import { IsoToLocalDate, dataRenderer } from "../../Functions";
import { AgGridReact } from "ag-grid-react";
import { useMemo } from "react";
import { dealsTabProductsColumnHeaders } from "../../Db";

const ModelWrapper = styled(Box)`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 24px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 8px;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Text = styled(Typography)`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;
const SectionHeader = styled(Typography)`
  font: normal normal 600 16px Open Sans;
  color: #979797;
  margin-bottom: 16px;
`;

const ViewDealSidepanel = ({ details }) => {
  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };
  const nameColumn = [
    {
      field: "name",
      headerName: "NAME",
      width: 230,
      cellRenderer: (params) => {
        let value = "-";
        if (params.data.item !== null) {
          value = dataRenderer(params.data.item);
        } else {
          value = dataRenderer(params.data.product.name);
        }
        return (
          <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
            <Tooltip title={value}>
              <Text>{value}</Text>
            </Tooltip>
          </Box>
        );
      },
    },
  ];
  return (
    <Box sx={{ width: 500 }}>
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          width: 500,
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar>
          <ModelHeader>Deal Details</ModelHeader>
        </Toolbar>
      </AppBar>
      <ModelWrapper>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Deal Name</Label>
              <Detail>{dataRenderer(details.name)}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Deal Value</Label>
              {details.deal_value_currency !== undefined &&
              details.deal_value_currency !== null &&
              details.deal_value_currency !== "" ? (
                <Detail>{`${dataRenderer(
                  details.deal_value_currency
                )} ${dataRenderer(details.deal_value)}`}</Detail>
              ) : (
                <Detail>{dataRenderer(details.deal_value)}</Detail>
              )}
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Priority</Label>
              {(() => {
                if (details.priority.toLowerCase() === "low") {
                  return (
                    <Chip
                      color="default"
                      label={details.priority}
                      style={{ width: "fit-content", fontWeight: "bold" }}
                    />
                  );
                } else if (details.priority.toLowerCase() === "medium") {
                  return (
                    <Chip
                      color="warning"
                      label={details.priority}
                      style={{ width: "fit-content", fontWeight: "bold" }}
                    />
                  );
                } else if (details.priority.toLowerCase() === "high") {
                  return (
                    <Chip
                      color="success"
                      label={details.priority}
                      style={{ width: "fit-content", fontWeight: "bold" }}
                    />
                  );
                }
              })()}
            </InputWrapper>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <SectionHeader>Products</SectionHeader>
              <div className="ag-theme-alpine" style={{ width: "100%" }}>
                <AgGridReact
                  domLayout="autoHeight"
                  gridOptions={gridOptions}
                  rowData={
                    details.deal_items !== null ? details.deal_items : []
                  }
                  defaultColDef={defaultColDef}
                  columnDefs={nameColumn.concat(dealsTabProductsColumnHeaders)}
                  animateRows={true}
                  suppressCellFocus
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </ModelWrapper>
    </Box>
  );
};

export default ViewDealSidepanel;
