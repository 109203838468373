import { Box, styled } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import rootStore from "../../stores/RootStore";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import { observer } from "mobx-react-lite";
import LeadListingPaginationComponentV1 from "../pagination/LeadListingPaginationComponentV1";
import { leadListingRowBuffer } from "../../Config";
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 232px);`};
  background-color:white;
`;
const LeadsDatatableWidget = ({
  tableData,
  tableColumns,
  page,
  size,
  count,
  handleSelectionChange,
  onCellClicked,
  handlePageSize,
  handlePagination,
}) => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const defaultColDef = useMemo(() => ({
    resizable: true,
    suppressMovable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };
  useEffect(() => {
    setData(tableData);
  }, [tableData]);
  return (
    <Box sx={{ width: "100%", flexDirection: "column" }}>
      <TableWrapper
        sx={{
          "& .ag-checkbox-input-wrapper input": {
            cursor: "pointer",
          },
        }}
      >
        <div className="ag-theme-alpine" style={{ width: "100%" }}>
          <AgGridReact
            domLayout="autoHeight"
            gridOptions={gridOptions}
            rowData={data}
            defaultColDef={defaultColDef}
            columnDefs={tableColumns}
            animateRows={true}
            suppressCellFocus
            pagination={false}
            paginationPageSize={size}
            onRowClicked={(row) => {
              if (row.event.defaultPrevented) {
                return null;
              }
              rootStore.authStore.updateLastActiveTime();
              let uid = localStorage.getItem("uid");

              navigate(`/users/${uid}/leads/${row.data.id}`, {
                state: {
                  lead_status: null,
                  scope: null,
                },
              });
            }}
            rowSelection="multiple"
            onSelectionChanged={handleSelectionChange}
            rowBuffer={leadListingRowBuffer}
            suppressColumnVirtualisation={true}
            onCellClicked={onCellClicked}
          />
        </div>
      </TableWrapper>

      <LeadListingPaginationComponentV1
        pageNumber={page}
        row={data}
        pageSize={size}
        count={count}
        handlePageSize={handlePageSize}
        handlePage={handlePagination}
        pageSizeOptionList={rootStore.userStore.leadListingPageSizeOptions}
      />
    </Box>
  );
};

export default observer(LeadsDatatableWidget);
