import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  Tooltip,
} from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";

import rootStore from "../../stores/RootStore";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { pageSize } from "../../Config";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { getOrganizationsListApi } from "../../Api";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import ExpandableSearchbar from "../../components/expandable_searchbar/ExpandableSearchbar";
import CreateOrganizationSidepanel from "../../components/organizations_page_sidepanels/CreateOrganizationSidepanel";
import { useNavigate } from "react-router-dom";
import { FormattedAgGridColumns } from "../../ColumnUtils";
import { dataRenderer } from "../../Functions";
const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 84px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;
const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: flex-end;
  min-height: 40px;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 236px);`};
  background-color: white;
  padding: 20px;
  padding-bottom: 0px;
`;

const PaginationWrapper = styled(Box)`
  padding: 0px 20px;
  background-color: white;
`;
const TextHighlight = styled(Typography)`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  color: #3f51b5;
  cursor: pointer;
`;
const requiredColumns = ["city", "state", "website"];

const OrganizationsPage = () => {
  const navigate = useNavigate();
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [openCreateOrganizationPanel, setOpenCreateOrganizationPanel] =
    useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [loadingData, setLoadingData] = useState(true);
  const [columns, setColumns] = useState([]);

  const getOrganizationsList = async ({ query }) => {
    setLoadingData(true);
    let payload = query !== "" ? { query: query } : null;
    let response = await getOrganizationsListApi({
      page: rootStore.organizationStore.listingInfo.page_number,
      page_size: rootStore.organizationStore.listingInfo.page_size,
      payload: payload,
    });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
      rootStore.organizationStore.updateCount(response.item_count);
    } else {
      setRow([]);
      rootStore.organizationStore.updateCount(0);
    }
    setLoadingData(false);
  };

  const getOrganizationColumns = async () => {
    await rootStore.organizationStore.fetchColumns();
    let filteredColumns = [...rootStore.organizationStore.columnsList].filter(
      (column) => requiredColumns.includes(column["column_name"])
    );
    let formattedColumns = FormattedAgGridColumns(filteredColumns);
    if (formattedColumns.length > 0) {
      let lastIndex = formattedColumns.length - 1;
      formattedColumns[lastIndex]["flex"] = 1;
    }
    setColumns(formattedColumns);
  };

  const init = async () => {
    setLoading(true);
    await getOrganizationsList({
      query: searchQuery,
    });
    await getOrganizationColumns();
    setLoading(false);
  };

  useEffect(() => {
    if (refresh === true) {
      getOrganizationsList({
        query: searchQuery,
      });
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    init();
    rootStore.authStore.updateLeadDetailStatus();
  }, []);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const handlePageSize = async (e) => {
    rootStore.organizationStore.udpatePageNumber(0);
    rootStore.organizationStore.updatePageSize(e.target.value);
    await getOrganizationsList({
      query: searchQuery,
    });
  };
  const handlePagination = async (e, page) => {
    rootStore.organizationStore.udpatePageNumber(page - 1);

    await getOrganizationsList({
      query: searchQuery,
    });
  };

  const handleSearchQuery = async (query) => {
    rootStore.organizationStore.udpatePageNumber(0);
    rootStore.organizationStore.updatePageSize(pageSize);
    await getOrganizationsList({
      query: query,
    });
  };

  const nameColumn = [
    {
      field: "name",
      headerName: "NAME",
      width: 230,
      cellRenderer: (params) => {
        let value = dataRenderer(params.data.name);
        return (
          <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
            <Tooltip title={value}>
              <TextHighlight>{value}</TextHighlight>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  return !loading ? (
    <>
      <ParentContainer>
        <Container>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
              marginY: "16px",
            }}
          >
            <TopWrapper>
              <Header>Organizations</Header>
              <ChevronRight style={{ width: "16px", height: "16px" }} />
            </TopWrapper>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "16px",
              }}
            >
              <ExpandableSearchbar
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                handleSearch={(query) => {
                  handleSearchQuery(query);
                }}
              />

              <Button
                onClick={() => {
                  setOpenCreateOrganizationPanel(true);
                }}
                variant="contained"
                color="primary"
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "160px",
                  height: "40px",
                }}
              >
                Create Organization
              </Button>
            </Box>
          </Box>
          {!loadingData ? (
            <TableWrapper>
              <div className="ag-theme-alpine" style={{ width: "100%" }}>
                <AgGridReact
                  domLayout="autoHeight"
                  gridOptions={gridOptions}
                  rowData={row}
                  defaultColDef={defaultColDef}
                  columnDefs={nameColumn.concat(columns)}
                  animateRows={true}
                  suppressCellFocus
                  pagination={false}
                  paginationPageSize={
                    rootStore.organizationStore.listingInfo.page_size
                  }
                  className="paginated-ag-grid"
                  onRowClicked={(row) => {
                    if (row.event.defaultPrevented) {
                      return null;
                    }
                    navigate(`/organizations/${row.data.id}`);
                  }}
                />
              </div>
            </TableWrapper>
          ) : (
            <TableWrapper
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </TableWrapper>
          )}
          <PaginationWrapper>
            <PaginationComponent
              page_no={rootStore.organizationStore.listingInfo.page_number}
              row={row}
              page_size={rootStore.organizationStore.listingInfo.page_size}
              size={rootStore.organizationStore.listingInfo.page_size}
              count={rootStore.organizationStore.listingInfo.count}
              handlePageSize={handlePageSize}
              handlePage={handlePagination}
            />
          </PaginationWrapper>
        </Container>
      </ParentContainer>
      <Footer />
      {openCreateOrganizationPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openCreateOrganizationPanel}
          onClose={() => {
            setOpenCreateOrganizationPanel(false);
          }}
        >
          <CreateOrganizationSidepanel
            setRefresh={setRefresh}
            setOpen={setOpenCreateOrganizationPanel}
            open={openCreateOrganizationPanel}
          />
        </Drawer>
      )}
    </>
  ) : (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default OrganizationsPage;
