import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import { Box, Drawer, Tooltip } from "@material-ui/core";
import { ChevronRight, AddBoxOutlined, Add } from "@material-ui/icons";
import { whatsappPageColumnHeaders } from "../../../Db";
import {
  syncAdminAccountWhatsAppBusinessApi,
  getAdminAccountWhatsAppBusinessTemplateListApi,
} from "../../../Api";
import rootStore from "../../../stores/RootStore";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { pageSize } from "../../../Config";
import { styled } from "@mui/material/styles";
import { Typography, Button, Chip, IconButton } from "@mui/material";

import { Preview } from "@mui/icons-material";
import LoadingAnimationComponent from "../../../components/loading_component/LoadingAnimationComponent";
import WhatsappPreviewPopup from "../../../components/whatsapp_templates_page_components/message_preview/WhatsappPreviewPopup";
import AddWhatsappBusinessTemplateSidepanel from "../../../components/whatsapp_templates_page_components/AddWhatsappBusinessTemplateSidepanel";

const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 84px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;
const ProgressWrapper = styled(Box)`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex: 1;
`;
const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const Row = styled(Box)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 16px 0px;
`;
const TableWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${`min-height: calc(100vh - 262px);`};
  background-color: white;
  position: relative;
`;
const AddBtnWrapper = styled(Box)`
  position: absolute;
  right: 5px;
  top: 5px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
`;
const TextHighlight = styled(Typography)`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  color: #3f51b5;
  cursor: pointer;
`;

const ProjectBusinessWhatsappTemplates = () => {
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [editableData, setEditableData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [whatsappPreviewMessage, setWhatsappPreviewMessage] = useState("");

  const init = async () => {
    setLoading(true);
    const response = await getAdminAccountWhatsAppBusinessTemplateListApi();
    setRow(response);
    //(rootStore.authStore.whatsAppTemplateList);
    setLoading(false);
  };

  const handleMetaWABARefresh = async () => {
    await syncAdminAccountWhatsAppBusinessApi();
  };

  useEffect(() => {
    init();
  }, []);

  const additionalColumns = [
    {
      field: "for_marketing",
      headerName: "FOR MARKETING",
      width: 160,
      cellRenderer: (params) => {
        if (
          params.data.for_marketing !== undefined &&
          params.data.for_marketing !== null &&
          params.data.for_marketing !== ""
        ) {
          if (params.data.for_marketing === true) {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="success"
                label={"Yes"}
              />
            );
          } else {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="warning"
                label={"No"}
              />
            );
          }
        } else {
          return "-";
        }
      },
    },
    {
      field: "for_transactional",
      headerName: "FOR TRANSACTIONAL",
      width: 160,
      cellRenderer: (params) => {
        if (
          params.data.for_transactional !== undefined &&
          params.data.for_transactional !== null &&
          params.data.for_transactional !== ""
        ) {
          if (params.data.for_transactional === true) {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="success"
                label={"Yes"}
              />
            );
          } else {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="warning"
                label={"No"}
              />
            );
          }
        } else {
          return "-";
        }
      },
    },
    {
      field: "action",
      headerName: "ACTIONS",
      minWidth: 100,
      flex: 1,
      resizable: false,
      cellRenderer: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              gap: "10px",
            }}
          >
            <Tooltip title={"Preview"}>
              <IconButton
                style={{ padding: "0px" }}
                color="primary"
                onClick={(e) => {
                  setWhatsappPreviewMessage(params.data.message);
                  setPreviewOpen(true);
                }}
              >
                <Preview
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  return (
    <>
      <ParentContainer>
        <Container>
          <Row>
            <TopWrapper>
              <Header>Project Administration</Header>
              <ChevronRight style={{ width: "16px", height: "16px" }} />
              <Header>WhatsApp Templates</Header>
              <ChevronRight style={{ width: "16px", height: "16px" }} />
              <Header>Business</Header>
            </TopWrapper>
          </Row>
          <TableWrapper>
            {!loading ? (
              <div className="ag-theme-alpine" style={{ width: "100%" }}>
                <AgGridReact
                  domLayout="autoHeight"
                  gridOptions={gridOptions}
                  rowData={row}
                  defaultColDef={defaultColDef}
                  columnDefs={whatsappPageColumnHeaders.concat(
                    additionalColumns
                  )}
                  animateRows={true}
                  suppressCellFocus
                  pagination={true}
                  paginationPageSize={pageSize}
                  className="paginated-ag-grid"
                  //   onRowClicked={(row) => {
                  //   }}
                />
              </div>
            ) : (
              <ProgressWrapper>
                <LoadingAnimationComponent size={"small"} />
              </ProgressWrapper>
            )}
          </TableWrapper>
        </Container>
      </ParentContainer>
      {open && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={open}
          onClose={() => {
            // setOpen(false);
          }}
        >
          <AddWhatsappBusinessTemplateSidepanel
            setOpen={setOpen}
            isEdit={isEditable}
            editableData={editableData}
            previewOpen={previewOpen}
            setPreviewOpen={setPreviewOpen}
          />
        </Drawer>
      )}
      <Footer />
      {previewOpen && (
        <WhatsappPreviewPopup
          open={previewOpen}
          setOpen={setPreviewOpen}
          content={whatsappPreviewMessage}
          contentType={"text"}
        />
      )}
    </>
  );
};

export default observer(ProjectBusinessWhatsappTemplates);
