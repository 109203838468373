import { Box, Modal } from '@material-ui/core';
import React,{useState} from 'react'
import styled from 'styled-components';
import LoginRight from '../loginRight/LoginRight';
import { Close } from "@material-ui/icons";
import Otp from '../Otp/Otp'
import { Auth } from '../../Db';

const ModelContainer=styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
    height: fit-content;
    width: fit-content;
    border:none;
    outline:none;
    background-color: white;
    box-shadow: 0px 3px 6px #0000000D;
    border-radius: 10px;
    padding: 50px;
    `
const ModelWrapper=styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
`


const MobileModel = (props) => {
    const [status,setStatus]=useState(false);
    const handleClick=()=>{
        props.setOpen(false);
        setStatus(false);
      }
  return (
    <>
        <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
        <Box>
        <Close onClick={handleClick} style={{height:"18px",width:"18px",position:"absolute",top:"20px",right:"30px",cursor:"pointer"}}/>
        <ModelWrapper>
        {!status?<LoginRight setStatus={setStatus} data={Auth[0].update}/>:<Otp/>}
        </ModelWrapper>
        </Box>
        </ModelContainer>
      </Modal>
    </>
  )
}

export default MobileModel
