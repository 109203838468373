import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import { Box, CircularProgress, Drawer } from "@material-ui/core";
import { AddBoxOutlined, ChevronRight, Edit } from "@material-ui/icons";
import { adminProjectWorkdayColumnHeaders } from "../../Db";

import rootStore from "../../stores/RootStore";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { pageSize } from "../../Config";
import { styled } from "@mui/material/styles";
import { IconButton, Tooltip, Typography } from "@mui/material";
import CreateWorkDayTemplateComponent from "../../components/workday_template_page_components/CreateWorkDayTemplateComponent";
import { getProjectAdminWorkTimingTemplateListApi } from "../../Api";
import ViewWorkDayTemplateComponent from "../../components/workday_template_page_components/ViewWorkDayTemplateComponent";
const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 84px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;

const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 185px);`};
  background-color: white;
  padding: 20px;
  position: relative;
`;

const AddBtnWrapper = styled(Box)`
  position: absolute;
  right: 24px;
  top: 24px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
`;
const AdminProjectWorkDayTemplates = () => {
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [openCreateWorkdayPanel, setOpenCreateWorkdayPanel] = useState(false);
  const [openViewWorkdayPanel, setOpenViewWorkdayPanel] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const getWorkDayTemplateList = async () => {
    setRow([]);
    let response = await getProjectAdminWorkTimingTemplateListApi({
      projectID: rootStore.authStore.projectId,
    });
    setRow(response);
  };

  const init = async () => {
    setLoading(true);
    await getWorkDayTemplateList();
    setLoading(false);
  };

  useEffect(() => {
    if (refresh === true) {
      init();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    init();
    rootStore.authStore.updateLeadDetailStatus();
  }, []);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const actionColumn = [
    {
      field: "actions",
      headerName: "ACTIONS",
      resizable: false,
      minWidth: 130,
      flex: 1,
      cellRenderer: (params) => {
        return (
          <Tooltip title={"Edit"}>
            <IconButton
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedRowData(params.data);
                setIsEdit(true);
                setOpenCreateWorkdayPanel(true);
              }}
            >
              <Edit
                style={{
                  width: "18px",
                  height: "18px",
                  cursor: "pointer",
                }}
              />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <ParentContainer>
        {!loading ? (
          <Container>
            <Row>
              <TopWrapper>
                <Header>Project Administration</Header>
                <ChevronRight style={{ width: "16px", height: "16px" }} />
                <Header>Work Day Templates</Header>
              </TopWrapper>
            </Row>
            <TableWrapper>
              <AddBtnWrapper
                onClick={() => {
                  setOpenCreateWorkdayPanel(true);
                }}
              >
                <AddBoxOutlined
                  style={{
                    width: "40px",
                    height: "40px",
                  }}
                />
              </AddBtnWrapper>
              <div className="ag-theme-alpine" style={{ width: "100%" }}>
                <AgGridReact
                  domLayout="autoHeight"
                  gridOptions={gridOptions}
                  rowData={row}
                  defaultColDef={defaultColDef}
                  columnDefs={adminProjectWorkdayColumnHeaders.concat(
                    actionColumn
                  )}
                  animateRows={true}
                  suppressCellFocus
                  pagination={false}
                  paginationPageSize={pageSize}
                  className="paginated-ag-grid"
                  onRowClicked={(row) => {
                    if (row.event.defaultPrevented) {
                      return null;
                    }
                    setSelectedRowData(row.data);
                    setOpenViewWorkdayPanel(true);
                  }}
                />
              </div>
            </TableWrapper>
          </Container>
        ) : (
          <Container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Container>
        )}
      </ParentContainer>
      <Footer />
      {openCreateWorkdayPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openCreateWorkdayPanel}
          onClose={() => {
            setIsEdit(false);
            setOpenCreateWorkdayPanel(false);
          }}
        >
          <CreateWorkDayTemplateComponent
            setRefresh={setRefresh}
            setOpen={setOpenCreateWorkdayPanel}
            open={openCreateWorkdayPanel}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            editableData={selectedRowData}
          />
        </Drawer>
      )}
      {openViewWorkdayPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openViewWorkdayPanel}
          onClose={() => {
            setOpenViewWorkdayPanel(false);
          }}
        >
          <ViewWorkDayTemplateComponent details={selectedRowData} />
        </Drawer>
      )}
    </>
  );
};

export default observer(AdminProjectWorkDayTemplates);
