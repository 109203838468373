import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const Detail = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;

const Label = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const IconTextWrapper = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 4px;
  margin-bottom: 4px;
`;
const ColumnWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  max-height: 100px;
  overflow-y: auto;
`;

const DeleteLeadConfirmationDialog = (props) => {
  const { authStore } = rootStore;
  const [loading, setLoading] = useState(true);
  const [markedImportantStausList, setMarkedImportantStatusList] = useState([]);
  const [isBothCondtionCheckRequired, setIsBothConditionCheckRequired] =
    useState(false);
  const [firstConditionChecked, setFirstConditionChecked] = useState(false);
  const [secondConditionChecked, setSecondConditionChecked] = useState(false);
  const statusList = authStore.statusList;

  const generateStatusListMarkedImportant = () => {
    let temp_list = [];
    statusList.forEach((status_obj) => {
      if (status_obj["mark_as_important"] === true) {
        temp_list.push(status_obj["status"]);
      }
    });
    return temp_list;
  };

  const canConfirmDeleteLeads = () => {
    if (isBothCondtionCheckRequired) {
      return firstConditionChecked && secondConditionChecked;
    }
    return firstConditionChecked;
  };

  useEffect(() => {
    let new_list = generateStatusListMarkedImportant();
    setMarkedImportantStatusList(new_list);
    if (new_list.length > 0) {
      setIsBothConditionCheckRequired(true);
    }
    setLoading(false);
  }, []);
  return (
    <Dialog
      disableEnforceFocus
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ fontWeight: "bold" }}>
        Confirmation: Delete leads
      </DialogTitle>

      {!loading ? (
        <DialogContent>
          <FormControlLabel
            style={{ alignItems: "flex-start" }}
            control={
              <Checkbox
                style={{ paddingTop: "0px" }}
                checked={firstConditionChecked}
                onChange={(e) => {
                  setFirstConditionChecked(e.target.checked);
                }}
              />
            }
            label={
              <Label>
                I acknowledge that this action is irrecoverable and confirm that
                I want to proceed with the action.
              </Label>
            }
          />

          {markedImportantStausList.length > 0 && (
            <>
              <FormControlLabel
                style={{ alignItems: "flex-start", marginBottom: "20px" }}
                control={
                  <Checkbox
                    style={{ paddingTop: "0px" }}
                    checked={secondConditionChecked}
                    onChange={(e) => {
                      setSecondConditionChecked(e.target.checked);
                    }}
                  />
                }
                label={
                  <>
                    <Label style={{ marginBottom: "20px" }}>
                      By checking this box and performing this action, you
                      acknowledge that the selected records, including those
                      with important statuses listed below, will be deleted as
                      well.
                    </Label>
                    <InputWrapper>
                      <ColumnWrapper>
                        {markedImportantStausList.map((item) => {
                          return (
                            <IconTextWrapper>
                              <FiberManualRecord
                                style={{
                                  width: "12px",
                                  height: "12px",
                                  marginTop: "3px",
                                  color: "gray",
                                }}
                              />
                              <Detail>{item}</Detail>
                            </IconTextWrapper>
                          );
                        })}
                      </ColumnWrapper>
                    </InputWrapper>
                  </>
                }
              />
            </>
          )}
        </DialogContent>
      ) : (
        <DialogContent
          style={{
            width: "468px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress />
        </DialogContent>
      )}
      <DialogActions>
        <Button
          color="primary"
          style={{ textTransform: "capitalize", fontWeight: "bold" }}
          onClick={() => {
            props.setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          style={{ textTransform: "capitalize", fontWeight: "bold" }}
          onClick={props.handleConfirm}
          autoFocus
          disabled={!canConfirmDeleteLeads()}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(DeleteLeadConfirmationDialog);
