import { Upload } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import React, { useRef } from "react";

const InputField = styled("input")`
  width: 200px;
`;

const FileUploadWidget = ({ value, handleChange, disabled }) => {
  const inputRef = useRef(null);
  if (value !== null) {
    return (
      <Box sx={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
        <Typography>
          {value["name"].length > 20
            ? value["name"].slice(0, 20 - 1) + "..."
            : value["name"]}
        </Typography>
        <IconButton
          disabled={disabled}
          color="primary"
          style={{ padding: "0px" }}
          onClick={() => {
            inputRef.current.click();
          }}
        >
          <Upload />
        </IconButton>
        <InputField
          disabled={disabled}
          style={{ display: "none" }}
          ref={inputRef}
          type="file"
          onChange={(event) => {
            handleChange(event);
          }}
          multiple={false}
        />
      </Box>
    );
  } else {
    return (
      <InputField
        disabled={disabled}
        ref={inputRef}
        type="file"
        onChange={(event) => {
          handleChange(event);
        }}
        style={{
          width: "200px",
          fontSize: "12px",
          padding: "2px",
          backgroundColor: "#EFEFF4",
          border: "none",
          borderRadius: "6px",
        }}
        multiple={false}
      />
    );
  }
};

export default FileUploadWidget;
