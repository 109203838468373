import React, { useState } from "react";
import styled from "styled-components";
import { DataGrid } from "@mui/x-data-grid";
import { observer } from "mobx-react-lite";
import PaginationComponent from "../pagination/PaginationComponent";
import { pageSize } from "../../Config";
import { getFilterColsApiV2, getImportLeadListApi } from "../../Api";
import { useEffect } from "react";
import rootStore from "../../stores/RootStore";
import { FormattedColumns, FormattedReportColumns } from "../../ColumnUtils";

const Container = styled.div`
  background-color: white;
  width: 100%;
  height: 100%;
`;
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 100%;
`;
const PaginationWrapper = styled.div`
  padding: 0px 20px;
`;
const TableWrapper = styled.div`
  ${`min-height: calc(100vh - 350px);`};
  width: 100%;
`;
const ImportLeadsTable = (props) => {
  const { userStore } = rootStore;
  const [row, setRow] = useState([]);
  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(pageSize);
  const [loading, setLoading] = useState(true);

  const getColumns = async () => {
    try {
      let response = await getFilterColsApiV2();
      let filterColumnsList = response.data;
      let allColumnList = userStore.AllColumnsList;
      let newColumnsList = [];
      filterColumnsList.map((item) => {
        allColumnList.map((column) => {
          if (column["field"] === item["field"]) {
            newColumnsList.push(column);
          }
        });
      });

      let columnsList = FormattedReportColumns(newColumnsList);
      setColumns(columnsList);
    } catch (error) {
      console.log(error);
      setColumns([]);
    }
  };

  const getLeads = async ({ page_no, page_size }) => {
    setLoading(true);
    let response = await getImportLeadListApi({
      id: props.id,
      page_no: page_no,
      page_size: page_size,
    });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
      setCount(response.item_count);
    } else {
      setRow([]);
      setCount(0);
    }
    setLoading(false);
  };

  const handlePageSize = async (e) => {
    setSize(e.target.value);
    setPage(0);
    await getLeads({ page_no: 0, page_size: e.target.value });
  };
  const handlePage = async (e, page) => {
    setPage(page - 1);
    await getLeads({ page_no: page - 1, page_size: size });
  };
  const setup = async () => {
    await getColumns();
    await getLeads({ page_no: 0, page_size: pageSize });
  };
  useEffect(() => {
    setup();
  }, []);
  return (
    <>
      <Container>
        <Wrapper>
          <>
            <TableWrapper>
              <DataGrid
                autoHeight={true}
                rows={row}
                columns={columns}
                pageSize={size}
                checkboxSelection={false}
                style={{ cursor: "pointer" }}
                hideFooter={true}
                loading={loading}
                disableColumnMenu={true}
              />
            </TableWrapper>
            <PaginationWrapper>
              <PaginationComponent
                page_no={page}
                row={row}
                page_size={size}
                size={size}
                count={count}
                handlePageSize={handlePageSize}
                handlePage={handlePage}
              />
            </PaginationWrapper>
          </>
        </Wrapper>
      </Container>
    </>
  );
};

export default observer(ImportLeadsTable);
