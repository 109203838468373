import React, { useEffect, useState } from "react";
import Appbar from "../../components/appbar/Appbar";
import Footer from "../../components/landing_page_components/footer/Footer";

import styled from "styled-components";
import badge from "../../assets/content_asset/icons/account_badge.png";
import { ArrowForwardIos } from "@material-ui/icons";
import MobileModel from "../../components/mobileupdate_modal/MobileModel";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
const Container = styled.div`
  width: 100%;
  margin-top: 70px;
  ${`min-height:calc(100vh - 139px)`}
`;
const Wrapper = styled.div`
  width: 90%;
  margin: auto;
  display: flex;
`;
const Left = styled.div`
  flex: 3;
`;
const Text1 = styled.span`
  font: normal normal normal 24px/35px Open Sans;
`;
const Text4 = styled.span`
  font: normal normal normal 12px/40px Open Sans;
`;
const Right = styled.div`
  flex: 9;
  display: flex;
  flex-direction: column;
  padding-top: 60px;
`;
const DetailsWrapper = styled.div`
  height: fit-content;
  width: 100%;
  background: #f5f9ff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 38px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 40px;
`;
const DetailsHeader = styled.span`
  font: normal normal 600 16px/25px Open Sans;
`;
const InfoWrapper = styled.div`
  height: 50px;
  width: 50%;
  margin-bottom: 30px;
`;
const Label = styled.div`
  font: normal normal normal 12px Open Sans;
`;
const Detail = styled.div`
  font: normal normal normal 16px Open Sans;
`;
const TextUpgrade = styled.div`
  font: normal normal 600 14px Open Sans;
  color: #185dd2;
  margin-left: 30px;
  cursor: pointer;
`;
const Badge = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 15px;
  left: 0px;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const Profile = (props) => {
  const navigate = useNavigate();
  const { userStore, authStore } = rootStore;
  const [update, openUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    openUpdate(true);
  };
  useEffect(() => {
    props.setShowBar(true);
  });
  return (
    <>
      <Container>
        <Wrapper>
          <Left>
            <Column>
              <Text4>Home / Profile</Text4>
              <Text1>Hello, {userStore.Basic[0].name}</Text1>
              <Column style={{ position: "relative", paddingLeft: "34px" }}>
                <Badge src={badge} alt="loading..." />
                <DetailsHeader style={{ marginBottom: "12px" }}>
                  Account Details
                </DetailsHeader>
                <TextUpgrade style={{ margin: "0px" }}>
                  Profile Information
                </TextUpgrade>
                <ArrowForwardIos
                  style={{
                    position: "absolute",
                    right: "4px",
                    bottom: "20px",
                    color: "#185DD2",
                  }}
                />
              </Column>
            </Column>
          </Left>
          <Right>
            <DetailsWrapper>
              <Row style={{ marginBottom: "40px" }}>
                <DetailsHeader>Account Details</DetailsHeader>
                {/* <TextUpgrade>EDIT</TextUpgrade> */}
              </Row>
              <Row>
                <InfoWrapper>
                  <Label>Name</Label>
                  <Detail>{userStore.Basic[0].name}</Detail>
                </InfoWrapper>
                <InfoWrapper>
                  <Label>Phone Number</Label>
                  <Row>
                    <Detail>{userStore.Basic[0].phone}</Detail>
                    {/* <TextUpgrade onClick={handleClick}>UPDATE</TextUpgrade> */}
                  </Row>
                </InfoWrapper>
              </Row>
              <Row>
                <InfoWrapper>
                  <Label>Email-ID</Label>
                  <Detail>{userStore.Basic[0].email}</Detail>
                </InfoWrapper>
                <InfoWrapper>
                  <Label>Role</Label>
                  <Row>
                    <Detail></Detail>
                  </Row>
                </InfoWrapper>
              </Row>
            </DetailsWrapper>
          </Right>
        </Wrapper>
      </Container>
      <MobileModel open={update} setOpen={openUpdate} />
      <Footer />
    </>
  );
};

export default observer(Profile);
