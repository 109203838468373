import { Drawer, Tooltip } from "@material-ui/core";
import { Delete, Visibility } from "@material-ui/icons";
import { Backdrop, CircularProgress, TableCell, TableRow } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { removeSegmentApi } from "../../Api";
import { SegmentListColumns } from "../../Db";
import { IsoToLocalDate, dataRenderer } from "../../Functions";
import "../../pages/import_history/import.css";
import SegmentInfoPanel from "../market_modals/SegmentInfoPanel";
import analytics from "../../Analytics";
import CustomErrorMessageDialog from "../alert_dialogue/CustomErrorMessageDialog";

const SegmentRowComponent = ({ item, idx, setRefresh }) => {
  const { id } = item;
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setData(item);
  }, []);

  const removeSegment = async () => {
    setIsLoading(true);
    let response = await removeSegmentApi(id);
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsLoading(false);
      setOpenErrorDialog(true);
    } else {
      setRefresh(true);
      setIsLoading(false);
    }
  };

  return (
    <>
      <TableRow
        onClick={() => {
          setOpen(true);
        }}
        key={idx}
        style={{
          width: "100%",
          cursor: "pointer",
          backgroundColor: idx % 2 === 0 ? "white" : "#F9F9FC",
        }}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        {data &&
          SegmentListColumns.concat([{ field: "action" }]).map((key) => {
            if (key["field"] === "action") {
              return (
                <TableCell
                  className="textContainer"
                  style={{
                    display: "flex",
                    justiyContent: "center",
                    alignItems: "center",
                    height: "33px",
                    columnGap: "10px",
                  }}
                  align="center"
                >
                  <Visibility
                    onClick={(e) => {
                      e.stopPropagation();
                      analytics.triggerEvent(
                        4625000241,
                        "view_button_tap",
                        "segments_page",
                        "view_button",
                        {}
                      );
                      setOpen(true);
                    }}
                    style={{
                      width: "18px",
                      height: "18px",
                    }}
                    color="primary"
                  />
                  <Delete
                    onClick={(e) => {
                      e.stopPropagation();
                      analytics.triggerEvent(
                        4625000241,
                        "view_button_tap",
                        "segments_page",
                        "view_button",
                        {}
                      );
                      removeSegment();
                    }}
                    style={{
                      width: "18px",
                      height: "18px",
                    }}
                    color="error"
                  />
                </TableCell>
              );
            } else if (key["field"] === "project") {
              let project_name =
                data[key["field"]] !== null ? data[key["field"]]["name"] : "-";
              return (
                <Tooltip title={project_name}>
                  <TableCell
                    className="textContainer"
                    style={{
                      width: "150px",
                      height: "33px",
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                      color: "#414141",
                    }}
                  >
                    {project_name}
                  </TableCell>
                </Tooltip>
              );
            } else if (key["field"] === "creator") {
              let name =
                data[key["field"]] !== null ? data[key["field"]]["name"] : "-";
              return (
                <Tooltip title={name}>
                  <TableCell
                    className="textContainer"
                    style={{
                      width: "150px",
                      height: "33px",
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                      color: "#414141",
                    }}
                  >
                    {name}
                  </TableCell>
                </Tooltip>
              );
            } else if (key["field"] === "created_at") {
              let date = IsoToLocalDate(data[key["field"]]);
              return (
                <Tooltip title={date}>
                  <TableCell
                    className="textContainer"
                    style={{
                      width: "150px",
                      height: "33px",
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                      color: "#414141",
                    }}
                  >
                    {date}
                  </TableCell>
                </Tooltip>
              );
            } else {
              return (
                <Tooltip title={data[key["field"]]}>
                  <TableCell
                    className="textContainer"
                    style={{
                      width: "150px",
                      height: "33px",
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                      color: "#414141",
                    }}
                  >
                    {dataRenderer(data[key["field"]])}
                  </TableCell>
                </Tooltip>
              );
            }
          })}
      </TableRow>
      {open && (
        <Drawer
          anchor={"right"}
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          {<SegmentInfoPanel setOpen={setOpen} open={open} id={data.id} />}
        </Drawer>
      )}
      <Backdrop
        open={isLoading}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress style={{ color: "white" }} />
      </Backdrop>

      {openErrorDialog && (
        <CustomErrorMessageDialog
          open={openErrorDialog}
          setOpen={setOpenErrorDialog}
          message={errorMessage}
        />
      )}
    </>
  );
};

export default observer(SegmentRowComponent);
