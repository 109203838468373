import {
  Box,
  CircularProgress,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import {
  dataRenderer,
  getColumnHeaderWidthByStringLength,
  hasReportAccess,
} from "../../Functions";
import PaginationWithoutPageSize from "../pagination/PaginationWithoutPageSize";
import { getPredefinedReportDetailsApi } from "../../Api";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
const Wrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 264px);`};
  background-color: white;
  display: flex;
  flex-direction: column;
`;
const PaginatedNonAggregateReport = ({
  reportID,
  setRefresh,
  refresh,
  columnsForDatatable,
  setColumnsForDatatable,
  setColumns,
  row,
  setRow,
  page,
  setPage,
  count,
  setCount,
  setGeneratedNonAggregateReportData,
  setCanDownloadNonAggregateReport,
  generateReportFilterPayload,
  checkIfFilterDisabled,
}) => {
  const [loading, setLoading] = useState(true);
  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));

  const onFirstDataRendered = (params) => {
    const autoSizeColumns = columnsForDatatable
      .filter((col) => col.autoSize)
      .map((col) => col.field);
    params.columnApi.autoSizeColumns(autoSizeColumns);
  };

  const getLeadList = async ({ page }) => {
    setRow([]);
    let payload = {};
    let queryPayload = generateReportFilterPayload();
    if (Object.keys(queryPayload).length > 0) {
      payload["query"] = queryPayload;
    } else {
      if (checkIfFilterDisabled() === false) {
        let today = moment().startOf("day").toISOString();
        payload["query"] = {
          created_at: {
            value: {
              start: today,
              end: today,
            },
            op: "between",
          },
        };
      }
    }
    let queryParams = {
      offset: page * 100,
      limit: 100,
    };
    let response = await getPredefinedReportDetailsApi({
      reportID: reportID,
      payload: payload,
      queryParams: queryParams,
    });

    if (Object.keys(response).length > 0) {
      setCount(response["item_count"]);
      if (
        response["items"].hasOwnProperty("data") &&
        response["items"]["data"] !== null &&
        response["items"]["data"].length > 0
      ) {
        let tempColumnHeaderStrings = Object.keys(response["items"]["data"][0]);
        setColumns(tempColumnHeaderStrings);
        let tempColumns = generateColumnsForDatatable(
          response["items"]["data"][0]
        );
        setColumnsForDatatable(tempColumns);
        setRow(response["items"]["data"]);
      } else {
        setRow([]);
        setColumnsForDatatable([]);
      }
    } else {
      setRow([]);
      setColumnsForDatatable([]);
    }
  };

  const generateColumnsForDatatable = (data) => {
    const columns_list = [];
    let dataLength = Object.keys(data).length;

    Object.keys(data).forEach((key, idx) => {
      let newObj = {
        field: key,
        headerName: key,
        cellRenderer: (params) => {
          let data = null;
          if (
            params.data[key] !== null &&
            params.data[key] !== "" &&
            params.data[key] !== undefined
          ) {
            if (typeof params.data[key] === "boolean") {
              if (params.data[key] === true) {
                data = "True";
              } else {
                data = "False";
              }
            } else {
              data = params.data[key];
            }
          }
          let value = dataRenderer(data);
          return (
            <Tooltip title={value} placement="bottom-start">
              <span className="csutable-cell-trucate">{value}</span>
            </Tooltip>
          );
        },
      };
      if (idx === dataLength - 1) {
        newObj["minWidth"] = getColumnHeaderWidthByStringLength(key);
        newObj["flex"] = 1;
      } else {
        newObj["autoSize"] = true;
        newObj["maxWidth"] = 500;
      }
      columns_list.push(newObj);
    });

    return columns_list;
  };

  const init = async () => {
    if (hasReportAccess()) {
      await getLeadList({ page: 0 });
    }

    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  const refetch = async () => {
    setGeneratedNonAggregateReportData([]);
    setCanDownloadNonAggregateReport(false);
    setLoading(true);
    await getLeadList({ page: 0 });
    setLoading(false);
    setRefresh(false);
  };

  useEffect(() => {
    if (refresh) {
      refetch();
    }
  }, [refresh]);

  const getPaginationCounterText = () => {
    let total = count !== 0 ? count : 0;
    let itemsPerPage = 100;
    let min = total !== 0 ? page * itemsPerPage + 1 : 0;
    let max =
      (page + 1) * itemsPerPage > total ? total : (page + 1) * itemsPerPage;
    return `${min}-${max} of ${total}`;
  };

  const handlePagination = async (e, page) => {
    setLoading(true);
    setPage(page - 1);
    await getLeadList({ page: page - 1 });
    setLoading(false);
  };

  return !loading ? (
    row.length > 0 ? (
      <Wrapper>
        <div className="ag-theme-alpine" style={{ width: "100%" }}>
          <AgGridReact
            domLayout="autoHeight"
            rowData={row}
            defaultColDef={defaultColDef}
            columnDefs={columnsForDatatable}
            animateRows={true}
            pagination={false}
            suppressColumnVirtualisation={true}
            suppressCellFocus
            onFirstDataRendered={onFirstDataRendered}
          />
        </div>
        <Box sx={{ padding: "0px 16px" }}>
          <PaginationWithoutPageSize
            page_no={page}
            row={row}
            count={count}
            handlePage={handlePagination}
            counterText={getPaginationCounterText()}
            page_size={100}
          />
        </Box>
      </Wrapper>
    ) : (
      <Box
        sx={{
          height: "calc(100vh - 217px)",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "white",
        }}
      >
        <Typography>No data found</Typography>
      </Box>
    )
  ) : (
    <Box
      sx={{
        height: "calc(100vh - 217px)",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "white",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default PaginatedNonAggregateReport;
