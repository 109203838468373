import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, Grid } from "@material-ui/core";
import { AppBar, Box, OutlinedInput, Toolbar, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { myTheme } from "../../themeUtils";
import { templateList } from "../../Db";
import TemplateCard from "./TemplateCard";
import { useNavigate } from "react-router-dom";
import { getLocalizedText } from "../../Functions";
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 10px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  gap: 20px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CreateLandingPagePanel = (props) => {
  const navigate = useNavigate();
  const [details, setDetails] = useState({
    name: "",
    template: "book_demo",
    desc: "",
  });

  const handleContinue = async (e) => {
    e.preventDefault();
    navigate(
      `/landing-page/create?name=${details.name}&template=${details.template}&desc=${details.desc}`
    );
  };
  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  return (
    <Box role="presentation">
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          width: 500,
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar>
          <ModelHeader>Create Landing Page</ModelHeader>
        </Toolbar>
      </AppBar>
      <ModelWrapper component={"form"} onSubmit={handleContinue}>
        <InputContainer>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputWrapper>
                <Label>Name*</Label>
                <OutlinedInput
                  style={{ height: "30px" }}
                  required
                  inputProps={{
                    min: 0,
                    "aria-label": "weight",
                    style: {
                      fontSize: "12px",
                    },
                  }}
                  value={details.name}
                  name={"name"}
                  type={"text"}
                  placeholder={"Enter Value"}
                  onChange={handleChange}
                />
              </InputWrapper>
            </Grid>
            <Grid item xs={12}>
              <InputWrapper>
                <Label>Description</Label>
                <OutlinedInput
                  multiline
                  rows={3}
                  inputProps={{
                    min: 0,
                    "aria-label": "weight",
                    style: {
                      fontSize: "12px",
                    },
                  }}
                  value={details.desc}
                  name={"desc"}
                  type={"text"}
                  placeholder={"Enter Value"}
                  onChange={handleChange}
                />
              </InputWrapper>
            </Grid>
            <Grid item xs={12}>
              <InputWrapper>
                <Label>Template</Label>
                <Grid container spacing={2}>
                  {templateList.map((template, index) => {
                    return (
                      <Grid item xs={6} key={index}>
                        <TemplateCard
                          details={details}
                          key={index}
                          template={template}
                          handleClick={() => {
                            setDetails({
                              ...details,
                              template: template["value"],
                            });
                          }}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </InputWrapper>
            </Grid>
          </Grid>
        </InputContainer>

        <AppBar
          position="fixed"
          color="inherit"
          elevation={0}
          sx={{ top: "auto", bottom: 0, width: 500 }}
        >
          <Toolbar style={{ width: 500, position: "relative" }}>
            <ButtonWrapper>
              <Button
                onClick={() => {
                  props.setOpen(false);
                }}
                variant="contained"
                color="default"
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textTransform: "none",
                  width: "100%",
                }}
              >
                {getLocalizedText("cancel")}
              </Button>
              <Button
                onClick={() => {}}
                type="submit"
                variant="contained"
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  width: "100%",
                  textTransform: "none",
                  ...myTheme.Button.btnBlue,
                }}
              >
                Continue
              </Button>
            </ButtonWrapper>
          </Toolbar>
        </AppBar>
      </ModelWrapper>
    </Box>
  );
};

export default observer(CreateLandingPagePanel);
