import React, { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Select,
  makeStyles,
} from "@material-ui/core";
import {
  AppBar,
  Box,
  MenuItem,
  OutlinedInput,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { myTheme } from "../../../themeUtils";
import {
  getLocalizedText,
  validateDisabledSubFeature,
} from "../../../Functions";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import DraggableChipComponent from "../draggable_chip_component/DraggableChipComponent";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import CustomFieldHeader from "../../custom_tooltip_headers/CustomFieldHeader";
import { MultiSelect } from "react-multi-select-component";
import { v4 as uuidv4 } from "uuid";

const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  gap: 20px;
`;
const CustomizedDatePicker = styled(DatePicker)`
  & .MuiInputBase-input {
    font-size: 12px;
    padding: 7.5px 14px;
  }
`;
const CustomizedDateTimePicker = styled(DateTimePicker)`
  & .MuiInputBase-input {
    font-size: 12px;
    padding: 7.5px 14px;
  }
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const ConfigureChildColumnSidepanel = ({
  setIsEdit,
  isEdit,
  setOpen,
  handlePostSubmitAddColumn,
  handlePostSubmitEditColumn,
  editableData,
  columnsList,
  columnGroupsList,
  parentFieldData,
  selectedParentColumnGroup,
  parentFieldExistingData,
  selectedParentValue,
}) => {
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState({
    display_name: "",
    group: "",
    filterable: false,
    editable: false,
    is_visible: false,
    data_type: "",
    enable_for_create: false,
    show_in_edit_form: false,
    set_title_case: false,
    is_form_field: false,
    hint: "",
    max: "",
    min: "",
  });
  const dataTypeList = ["string", "integer", "date", "datetime", "boolean"];
  const filterTypeList = {
    string: ["normal", "list"],
    integer: ["normal", "range", "list"],
    date: ["normal", "range"],
    datetime: ["normal", "range"],
    boolean: ["normal"],
  };
  const [inputList, setInputList] = useState([]);
  const [filterType, setFilterType] = useState("");
  const [disabledFields, setDisabledFields] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [childColumnsList, setChildColumnsList] = useState([]);
  const [selectedChildColumnID, setSelectedChildColumnID] = useState("");

  const moveChip = useCallback(
    (dragIndex, hoverIndex) => {
      const updatedChips = [...inputList];
      const [removed] = updatedChips.splice(dragIndex, 1);
      updatedChips.splice(hoverIndex, 0, removed);
      setInputList(updatedChips);
    },
    [inputList]
  );

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
  };

  const generatePayload = () => {
    let data = {
      display_name: details.display_name,
      is_visible: details.is_visible,
      filterable: details.filterable,
      editable: details.editable,
      required: false, //sending required value as false for child column
      enable_for_create: details.enable_for_create,
      show_in_edit_form: details.show_in_edit_form,
      data_type: details.data_type,
      set_title_case: details.set_title_case,
      is_form_field: details.is_form_field,
      child_of: parentFieldExistingData["id"],
    };

    let tempList = selectedGroups.map((group) => group["value"]);
    data["group"] = tempList.join(",");

    data["filter_type"] = filterType;
    data["filter_input"] = inputList.toString();

    if (details.hint !== "") {
      data["hint"] = details.hint;
    }

    if (details.data_type === "integer") {
      data["min"] = details.min !== "" ? parseInt(details.min) : null;
      data["max"] = details.max !== "" ? parseInt(details.max) : null;
    } else if (
      details.data_type === "date" ||
      details.data_type === "datetime"
    ) {
      data["min"] = details.min !== "" ? details.min : null;
      data["max"] = details.max !== "" ? details.max : null;
    }

    return data;
  };

  // _update_required, _update_type are helper variables to track the children data for update, create, delete api calls on save

  const addColumn = async () => {
    let field = "custom_params_" + details.display_name.replace(" ", "_");
    let payload = generatePayload();
    payload["field"] = field;
    payload["column_id"] = uuidv4();
    payload["_update_required"] = true;
    payload["_update_type"] = "add";

    handlePostSubmitAddColumn(payload);
  };
  const editColumn = async () => {
    let payload = generatePayload();
    let index = childColumnsList.findIndex(
      (column) => column["id"] === selectedChildColumnID
    );
    if (index !== -1) {
      payload["column_id"] = childColumnsList[index].id;
      payload["field"] = childColumnsList[index].field;
      payload["_update_type"] = "edit";
    } else {
      let field = "custom_params_" + details.display_name.replace(" ", "_");
      payload["field"] = field;
    }
    payload["_update_required"] = true;
    handlePostSubmitEditColumn(payload);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (isEdit || selectedChildColumnID !== "custom") {
      await editColumn();
    } else {
      await addColumn();
    }
    setIsSubmitting(false);
  };

  const setupEditableData = ({ columnData }) => {
    let tempObj = {
      display_name: columnData["_update_required"]
        ? columnData.display_name
        : columnData.headerName,
      is_visible: parentFieldData.is_visible,
      filterable: parentFieldData.filterable,
      editable: parentFieldData.editable,
      data_type: columnData.data_type,

      enable_for_create: parentFieldData.enable_for_create,
      show_in_edit_form: parentFieldData.show_in_edit_form,
      is_form_field: parentFieldData.is_form_field,
      hint: columnData.hint !== null ? columnData.hint : "",
      set_title_case:
        parentFieldData.hasOwnProperty("set_title_case") &&
        parentFieldData.set_title_case === true
          ? true
          : false,
    };
    setSelectedGroups(selectedParentColumnGroup);

    if (columnData.data_type === "integer") {
      tempObj["min"] = columnData.min !== null ? columnData.min : "";
      tempObj["max"] = columnData.max !== null ? columnData.max : "";
    } else if (
      columnData.data_type === "date" ||
      columnData.data_type === "datetime"
    ) {
      tempObj["min"] =
        columnData.min !== null && columnData.min !== ""
          ? moment(columnData.min)
          : "";
      tempObj["max"] =
        columnData.max !== null && columnData.max !== ""
          ? moment(columnData.max)
          : "";
    }
    setDetails(tempObj);

    if (columnData.hasOwnProperty("filter_type")) {
      setFilterType(columnData.filter_type);
    }
    if (isEdit) {
      if (columnData.filter_input !== "" && columnData.filter_input !== null) {
        let newList = columnData.filter_input.split(",");
        setInputList(newList);
      }
    }
  };

  const init = async () => {
    let filteredColumnList = [];

    if (isEdit === true) {
      let index = columnsList.findIndex(
        (column) => column["id"] === editableData["column_id"]
      );
      if (index !== -1) {
        filteredColumnList.push(columnsList[index]);
      }
    } else {
      columnsList.forEach((column) => {
        if (
          column["child_of"] !== null &&
          column["child_of"].hasOwnProperty("id") &&
          column["child_of"]["id"] === parentFieldExistingData["id"]
        ) {
          filteredColumnList.push(column);
        }
      });
    }
    filteredColumnList.sort((a, b) =>
      a["headerName"].toLowerCase().localeCompare(b["headerName"].toLowerCase())
    );

    setChildColumnsList(filteredColumnList);

    if (isEdit === true) {
      setSelectedChildColumnID(editableData["column_id"]);
      setupEditableData({ columnData: editableData });
    }

    setDisabledList({ columnData: editableData });
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  const checkKeyDown = (e) => {
    if (e.code === "Enter") e.preventDefault();
  };
  const handleFilterInput = (e) => {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    if (!inputList.includes(value)) {
      setInputList([...inputList, value]);
    }
    e.target.value = "";
  };
  const handleDelete = (input) => {
    let tempList = inputList.filter((item) => item !== input);
    setInputList(tempList);
  };
  const setDisabledList = ({ columnData }) => {
    if (isEdit) {
      if (columnData["derived"]) {
        setDisabledFields([
          "group",
          "column_name",
          "enable_for_create",
          "show_in_edit_form",
          "is_visible",
          "data_type",
        ]);
      } else if (columnData["system"]) {
        setDisabledFields(["column_name", "group", "data_type"]);
      }
    }
  };

  const renderMinMaxFields = () => {
    if (details.data_type === "integer") {
      return (
        <Grid item xs={12}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <InputWrapper>
              <CustomFieldHeader
                header={"Min"}
                tooltip={"Specify the minimum value allowed for this column."}
              />
              <OutlinedInput
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                  max: details.max,
                }}
                value={details.min}
                type={"number"}
                placeholder="Enter value"
                name="min"
                onChange={handleChange}
              />
            </InputWrapper>

            <InputWrapper>
              <CustomFieldHeader
                header={"Max"}
                tooltip={"Specify the maximum value allowed for this column."}
              />
              <OutlinedInput
                style={{ height: "30px" }}
                inputProps={{
                  min: details.min,
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                required={details.min !== ""}
                value={details.max}
                type={"number"}
                placeholder="Enter value"
                name="max"
                onChange={handleChange}
              />
            </InputWrapper>
          </Box>
        </Grid>
      );
    } else if (details.data_type === "date") {
      return (
        <Grid item xs={12}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <InputWrapper>
              <CustomFieldHeader
                header={"Min"}
                tooltip={"Specify the minimum value allowed for this column."}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomizedDatePicker
                  maxDate={details.max !== "" ? details.max : null}
                  value={details.min !== "" ? details.min : null}
                  onChange={(event) => {
                    setDetails({ ...details, min: event });
                  }}
                  inputFormat="YYYY-MM-DD"
                  renderInput={(params) => (
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </InputWrapper>
            <InputWrapper>
              <CustomFieldHeader
                header={"Max"}
                tooltip={"Specify the maximum value allowed for this column."}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomizedDatePicker
                  disabled={details.min === ""}
                  value={details.max !== "" ? details.max : null}
                  minDate={details.min !== "" ? details.min : null}
                  onChange={(event) => {
                    setDetails({ ...details, max: event });
                  }}
                  inputFormat="YYYY-MM-DD"
                  renderInput={(params) => (
                    <TextField
                      required={details.min !== ""}
                      sx={{ width: "100%" }}
                      size="small"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </InputWrapper>
          </Box>
        </Grid>
      );
    } else if (details.data_type === "datetime") {
      return (
        <Grid item xs={12}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <InputWrapper>
              <CustomFieldHeader
                header={"Min"}
                tooltip={"Specify the minimum value allowed for this column."}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomizedDateTimePicker
                  maxDateTime={details.max !== "" ? details.max : null}
                  value={details.min !== "" ? details.min : null}
                  onChange={(event) => {
                    setDetails({ ...details, min: event });
                  }}
                  inputFormat="YYYY-MM-DD HH:mm A"
                  renderInput={(params) => (
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </InputWrapper>

            <InputWrapper>
              <CustomFieldHeader
                header={"Max"}
                tooltip={"Specify the maximum value allowed for this column."}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomizedDateTimePicker
                  value={details.max !== "" ? details.max : null}
                  minDateTime={details.min !== "" ? details.min : null}
                  onChange={(event) => {
                    setDetails({ ...details, max: event });
                  }}
                  inputFormat="YYYY-MM-DD HH:mm A"
                  disabled={details.min === ""}
                  renderInput={(params) => (
                    <TextField
                      required={details.min !== ""}
                      sx={{ width: "100%" }}
                      size="small"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </InputWrapper>
          </Box>
        </Grid>
      );
    } else {
      return (
        <Grid item xs={12}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <InputWrapper>
              <CustomFieldHeader
                header={"Min"}
                tooltip={"Specify the minimum value allowed for this column."}
              />
              <OutlinedInput
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                type="text"
                placeholder="Enter value"
                name="min"
                disabled
              />
            </InputWrapper>

            <InputWrapper>
              <CustomFieldHeader
                header={"Max"}
                tooltip={"Specify the maximum value allowed for this column."}
              />
              <OutlinedInput
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                type="text"
                disabled
                placeholder="Enter value"
                name="max"
              />
            </InputWrapper>
          </Box>
        </Grid>
      );
    }
  };

  const setupPrefillDataForCustomOption = () => {
    let tempObj = {
      display_name: "",
      is_visible: parentFieldData.is_visible,
      filterable: parentFieldData.filterable,
      editable: parentFieldData.editable,
      data_type: "",

      enable_for_create: parentFieldData.enable_for_create,
      show_in_edit_form: parentFieldData.show_in_edit_form,
      is_form_field: parentFieldData.is_form_field,
      hint: "",
      min: "",
      max: "",
      set_title_case:
        parentFieldData.hasOwnProperty("set_title_case") &&
        parentFieldData.set_title_case === true
          ? true
          : false,
    };
    setDetails(tempObj);
    setSelectedGroups(selectedParentColumnGroup);
    setInputList([]);
  };

  return !loading ? (
    <>
      <Box role="presentation">
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: 500,
            right: 0,
            bgcolor: "#f9f9fc",
          }}
        >
          <Toolbar>
            {isEdit ? (
              <ModelHeader>{`${getLocalizedText(
                "edit_child_column_for_parent_option"
              )} "${selectedParentValue}"`}</ModelHeader>
            ) : (
              <ModelHeader>{`${getLocalizedText(
                "add_child_column_for_parent_option"
              )} "${selectedParentValue}"`}</ModelHeader>
            )}
          </Toolbar>
        </AppBar>

        <ModelWrapper
          component={"form"}
          onSubmit={handleSubmit}
          onKeyDown={(e) => checkKeyDown(e)}
        >
          <InputContainer>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputWrapper>
                  <CustomFieldHeader
                    header={"Select Child Column"}
                    tooltip={"Specify child column."}
                    required={true}
                  />
                  <FormControl
                    sx={{ width: "100%" }}
                    classes={{
                      root: classes.quantityRoot,
                    }}
                  >
                    <Select
                      classes={{
                        icon: classes.icon,
                      }}
                      disabled={isEdit}
                      value={selectedChildColumnID}
                      name="child_column"
                      displayEmpty
                      disableUnderline
                      variant="outlined"
                      required={!isEdit}
                      onChange={(e) => {
                        if (e.target.value === "custom") {
                          setupPrefillDataForCustomOption();
                        } else {
                          let index = childColumnsList.findIndex(
                            (column) => column["id"] === e.target.value
                          );
                          if (index !== -1) {
                            setupEditableData({
                              columnData: childColumnsList[index],
                            });
                          }
                        }

                        setSelectedChildColumnID(e.target.value);
                      }}
                      style={{
                        width: "100%",
                        height: "30px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        borderRadius: "0px",
                      }}
                    >
                      <MenuItem value={"custom"}>Custom</MenuItem>
                      {childColumnsList.map((obj, idx) => {
                        return (
                          <MenuItem key={idx} value={obj.id}>
                            {obj.headerName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper>
                  <CustomFieldHeader
                    header={getLocalizedText("column_name")}
                    tooltip={"Enter the name of the new lead column."}
                    required={true}
                  />
                  <OutlinedInput
                    style={{ height: "30px" }}
                    inputProps={{
                      min: 0,
                      "aria-label": "weight",
                      style: {
                        fontSize: "12px",
                      },
                      pattern: "^[a-zA-Z0-9_ ]+$",
                    }}
                    value={details.display_name}
                    disabled={disabledFields.includes("column_name")}
                    required
                    type="text"
                    placeholder="Enter name here..."
                    name="display_name"
                    onChange={handleChange}
                  />
                </InputWrapper>
              </Grid>
              {!disabledFields.includes("group") && (
                <Grid item xs={12}>
                  <InputWrapper>
                    <CustomFieldHeader
                      header={getLocalizedText("group_name")}
                      tooltip={"Select the group this column belongs to."}
                    />

                    <MultiSelect
                      hasSelectAll={false}
                      options={columnGroupsList}
                      value={selectedGroups}
                      className={"chart_multi_select"}
                      onChange={(e) => {
                        setSelectedGroups(e);
                      }}
                      labelledBy="Select Group"
                      disabled
                    />
                  </InputWrapper>
                </Grid>
              )}

              {dataTypeList && (
                <Grid item xs={6}>
                  <InputWrapper>
                    <CustomFieldHeader
                      header={getLocalizedText("select_data_type")}
                      tooltip={"Specify the column's data type."}
                      required={true}
                    />
                    <FormControl
                      sx={{ width: "100%" }}
                      classes={{
                        root: classes.quantityRoot,
                      }}
                      disabled={isEdit === true}
                    >
                      <Select
                        classes={{
                          icon: classes.icon,
                        }}
                        value={details.data_type}
                        name="data_type"
                        displayEmpty
                        disableUnderline
                        variant="outlined"
                        required
                        onChange={(e) => {
                          setDetails({
                            ...details,
                            data_type: e.target.value,
                            min: "",
                            max: "",
                            set_title_case: false,
                          });
                          setFilterType(filterTypeList[e.target.value][0]);
                          setInputList([]);
                        }}
                        style={{
                          width: "100%",
                          height: "30px",
                          fontSize: "12px",
                          fontWeight: "bold",
                          borderRadius: "0px",
                        }}
                      >
                        {dataTypeList.map((e) => {
                          return (
                            <MenuItem id={e} value={e}>
                              {e}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </InputWrapper>
                </Grid>
              )}
              <Grid item xs={6}>
                <InputWrapper>
                  <CustomFieldHeader
                    header={getLocalizedText("select_filter_type")}
                    tooltip={"Specify the column's filter type."}
                  />
                  <FormControl
                    sx={{ width: "100%" }}
                    classes={{
                      root: classes.quantityRoot,
                    }}
                  >
                    <Select
                      value={filterType}
                      classes={{
                        icon: classes.icon,
                      }}
                      name="filter_type"
                      displayEmpty
                      disableUnderline
                      variant="outlined"
                      onChange={(e) => {
                        setFilterType(e.target.value);
                        setInputList([]);
                      }}
                      style={{
                        width: "100%",
                        height: "30px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        borderRadius: "0px",
                      }}
                    >
                      {filterTypeList[details.data_type] &&
                        filterTypeList[details.data_type].map((e) => {
                          return (
                            <MenuItem id={e} value={e}>
                              {e}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </InputWrapper>
              </Grid>

              <Grid item xs={12}>
                <InputWrapper
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <CustomFieldHeader
                    header={getLocalizedText("is_visible")}
                    tooltip={
                      "Specify if the column should be displayed in listing and details."
                    }
                    required={true}
                    style={{ marginBottom: "0px", minWidth: "140px" }}
                  />
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="yes"
                      control={
                        <Radio
                          size="small"
                          disabled
                          checked={details.is_visible}
                          onChange={(e) => {
                            setDetails({ ...details, is_visible: true });
                          }}
                        />
                      }
                      label={
                        <Label style={{ fontSize: "12px" }}>
                          {getLocalizedText("yes")}
                        </Label>
                      }
                    />
                    <FormControlLabel
                      value="no"
                      control={
                        <Radio
                          size="small"
                          disabled
                          checked={!details.is_visible}
                          onChange={(e) => {
                            setDetails({ ...details, is_visible: false });
                          }}
                        />
                      }
                      label={
                        <Label style={{ fontSize: "12px" }}>
                          {getLocalizedText("no")}
                        </Label>
                      }
                    />
                  </RadioGroup>
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <CustomFieldHeader
                    header={getLocalizedText("filterable")}
                    tooltip={"Specify if this column is filterable."}
                    required={true}
                    style={{ marginBottom: "0px", minWidth: "140px" }}
                  />
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="yes"
                      control={
                        <Radio
                          size="small"
                          disabled
                          checked={details.filterable}
                          onChange={(e) => {
                            setDetails({ ...details, filterable: true });
                          }}
                        />
                      }
                      label={
                        <Label style={{ fontSize: "12px" }}>
                          {getLocalizedText("yes")}
                        </Label>
                      }
                    />
                    <FormControlLabel
                      value="no"
                      control={
                        <Radio
                          size="small"
                          disabled
                          checked={!details.filterable}
                          onChange={(e) => {
                            setDetails({ ...details, filterable: false });
                          }}
                        />
                      }
                      label={
                        <Label style={{ fontSize: "12px" }}>
                          {getLocalizedText("no")}
                        </Label>
                      }
                    />
                  </RadioGroup>
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <CustomFieldHeader
                    header={getLocalizedText("editable")}
                    tooltip={"Specify if this column is editable."}
                    required={true}
                    style={{ marginBottom: "0px", minWidth: "140px" }}
                  />
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="yes"
                      control={
                        <Radio
                          size="small"
                          disabled
                          checked={details.editable}
                          onChange={(e) => {
                            setDetails({ ...details, editable: true });
                          }}
                        />
                      }
                      label={
                        <Label style={{ fontSize: "12px" }}>
                          {getLocalizedText("yes")}
                        </Label>
                      }
                    />
                    <FormControlLabel
                      value="no"
                      control={
                        <Radio
                          size="small"
                          disabled
                          checked={!details.editable}
                          onChange={(e) => {
                            setDetails({ ...details, editable: false });
                          }}
                        />
                      }
                      label={
                        <Label style={{ fontSize: "12px" }}>
                          {getLocalizedText("no")}
                        </Label>
                      }
                    />
                  </RadioGroup>
                </InputWrapper>
              </Grid>

              <Grid item xs={12}>
                <InputWrapper
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <CustomFieldHeader
                    header={"Show in Create Form"}
                    tooltip={"Include this column in the create form."}
                    required={true}
                    style={{ marginBottom: "0px", minWidth: "140px" }}
                  />
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="yes"
                      control={
                        <Radio
                          size="small"
                          disabled
                          checked={details.enable_for_create}
                          onChange={(e) => {
                            setDetails({ ...details, enable_for_create: true });
                          }}
                        />
                      }
                      label={
                        <Label style={{ fontSize: "12px" }}>
                          {getLocalizedText("yes")}
                        </Label>
                      }
                    />
                    <FormControlLabel
                      value="no"
                      control={
                        <Radio
                          size="small"
                          disabled
                          checked={!details.enable_for_create}
                          onChange={(e) => {
                            setDetails({
                              ...details,
                              enable_for_create: false,
                            });
                          }}
                        />
                      }
                      label={
                        <Label style={{ fontSize: "12px" }}>
                          {getLocalizedText("no")}
                        </Label>
                      }
                    />
                  </RadioGroup>
                </InputWrapper>
              </Grid>

              <Grid item xs={12}>
                <InputWrapper
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <CustomFieldHeader
                    header={"Show in Edit Form"}
                    tooltip={"Include this column in the edit form."}
                    required={true}
                    style={{ marginBottom: "0px", minWidth: "140px" }}
                  />
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="yes"
                      control={
                        <Radio
                          size="small"
                          disabled
                          checked={details.show_in_edit_form}
                          onChange={(e) => {
                            setDetails({ ...details, show_in_edit_form: true });
                          }}
                        />
                      }
                      label={
                        <Label style={{ fontSize: "12px" }}>
                          {getLocalizedText("yes")}
                        </Label>
                      }
                    />
                    <FormControlLabel
                      value="no"
                      control={
                        <Radio
                          size="small"
                          disabled
                          checked={!details.show_in_edit_form}
                          onChange={(e) => {
                            setDetails({
                              ...details,
                              show_in_edit_form: false,
                            });
                          }}
                        />
                      }
                      label={
                        <Label style={{ fontSize: "12px" }}>
                          {getLocalizedText("no")}
                        </Label>
                      }
                    />
                  </RadioGroup>
                </InputWrapper>
              </Grid>

              <Grid item xs={12}>
                <InputWrapper
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <CustomFieldHeader
                    header={"Title Case"}
                    tooltip={
                      "Specify if the value of this column should be converted to title case."
                    }
                    style={{ marginBottom: "0px", minWidth: "140px" }}
                  />
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="yes"
                      control={
                        <Radio
                          size="small"
                          disabled
                          checked={details.set_title_case}
                          onChange={(e) => {
                            setDetails({ ...details, set_title_case: true });
                          }}
                        />
                      }
                      label={
                        <Label style={{ fontSize: "12px" }}>
                          {getLocalizedText("yes")}
                        </Label>
                      }
                    />
                    <FormControlLabel
                      value="no"
                      control={
                        <Radio
                          size="small"
                          disabled
                          checked={!details.set_title_case}
                          onChange={(e) => {
                            setDetails({ ...details, set_title_case: false });
                          }}
                        />
                      }
                      label={
                        <Label style={{ fontSize: "12px" }}>
                          {getLocalizedText("no")}
                        </Label>
                      }
                    />
                  </RadioGroup>
                </InputWrapper>
              </Grid>

              {validateDisabledSubFeature("lead_forms") && (
                <Grid item xs={12}>
                  <InputWrapper
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <CustomFieldHeader
                      header={"Is Form Field"}
                      tooltip={
                        "Include this column in create question in lead form."
                      }
                      style={{ marginBottom: "0px", minWidth: "140px" }}
                    />
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="yes"
                        control={
                          <Radio
                            disabled
                            size="small"
                            checked={details.is_form_field}
                            onChange={(e) => {
                              setDetails({ ...details, is_form_field: true });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("yes")}
                          </Label>
                        }
                      />
                      <FormControlLabel
                        value="no"
                        control={
                          <Radio
                            disabled
                            size="small"
                            checked={!details.is_form_field}
                            onChange={(e) => {
                              setDetails({ ...details, is_form_field: false });
                            }}
                          />
                        }
                        label={
                          <Label style={{ fontSize: "12px" }}>
                            {getLocalizedText("no")}
                          </Label>
                        }
                      />
                    </RadioGroup>
                  </InputWrapper>
                </Grid>
              )}

              <Grid item xs={12}>
                <InputWrapper>
                  <CustomFieldHeader
                    header={"Add Options"}
                    tooltip={
                      "Add available options for this column, if applicable."
                    }
                    required={true}
                  />
                  <OutlinedInput
                    style={{ height: "30px", width: "100%" }}
                    inputProps={{
                      min: 0,
                      "aria-label": "weight",
                      style: {
                        fontSize: "12px",
                      },
                    }}
                    type="text"
                    placeholder="Type something"
                    name="sub_status"
                    onKeyDown={handleFilterInput}
                    disabled={filterType !== "list"}
                  />

                  <Grid container spacing={2} style={{ padding: "12px 0px" }}>
                    <DndProvider backend={HTML5Backend}>
                      {inputList.map((chip, index) => {
                        return (
                          <Grid item key={index} style={{ width: "100%" }}>
                            <DraggableChipComponent
                              index={index}
                              label={chip}
                              moveChip={moveChip}
                              handleDelete={() => handleDelete(chip)}
                            />
                          </Grid>
                        );
                      })}
                    </DndProvider>
                  </Grid>
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper>
                  <CustomFieldHeader
                    header={"Hint Text"}
                    tooltip={
                      "Hint text to show in create/edit form for this column."
                    }
                  />
                  <OutlinedInput
                    style={{ height: "30px" }}
                    inputProps={{
                      min: 0,
                      "aria-label": "weight",
                      style: {
                        fontSize: "12px",
                      },
                    }}
                    value={details.hint}
                    type="text"
                    placeholder="Enter value"
                    name="hint"
                    onChange={handleChange}
                  />
                </InputWrapper>
              </Grid>
              {renderMinMaxFields()}
            </Grid>
          </InputContainer>
          <AppBar
            position="fixed"
            color="inherit"
            elevation={0}
            sx={{ top: "auto", bottom: 0, width: 500 }}
          >
            <Toolbar style={{ width: 500, position: "relative" }}>
              <ButtonWrapper>
                <Button
                  onClick={() => {
                    handleClose();
                  }}
                  type="button"
                  variant="contained"
                  color="default"
                  style={{
                    backgroundColor: "#EFEFF4",
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  {getLocalizedText("cancel")}
                </Button>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  id="call-log-btn"
                  style={{
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "100%",
                    ...myTheme.Button.btnBlue,
                  }}
                >
                  {getLocalizedText("submit")}
                </Button>
              </ButtonWrapper>
            </Toolbar>
          </AppBar>
        </ModelWrapper>
      </Box>
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: 500,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default observer(ConfigureChildColumnSidepanel);
