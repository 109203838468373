import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AddBoxOutlined, PlayCircleOutline } from "@material-ui/icons";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import spinner from "../../assets/images/gifs/spinner.gif";
import { callsColumns } from "../../Db";
import { DataGrid } from "@mui/x-data-grid";
import CallLogModal from "../call_log_modal/CallLogModal";
import AudioModal from "../audio_modal/AudioModal";
import NotesModal from "../notes_modal/NotesModal";
import { callLogsLimit, pageSize } from "../../Config";
import Pagination from "../pagination/Pagination";
import { getLeadCallsApi, getStatusApi } from "../../Api";
import PageSizeDropdown from "../page_size_dropdown/PageSizeDropdown";
import PaginationComponent from "../pagination/PaginationComponent";
import ViewCallLogDetails from "../call_log_modal/ViewCallLogDetails";
import CallInfoDialog from "../call/CallInfoDialog";
import { callInfoSheetScope } from "../../utils/enum";
import analytics from "../../Analytics";
import PaginationWithoutPageSize from "../pagination/PaginationWithoutPageSize";

const Container = styled.div`
  width: 100%;
  position: relative;
  ${`min-height: calc(100vh - 234px);`};
`;
const Wrapper = styled.div`
  display: flex;
`;

const DetailsWrapper = styled.div`
  height: fit-content;
  width: 100%;
  background: white;
  border-radius: 10px;
  /* padding: 12px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;

const Spinner = styled.img`
  height: 120px;
  width: 180px;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
  width: 100%;
  ${`min-height: calc(100vh - 148px);`};
`;

const AddBtnWrapper = styled.div`
  position: absolute;
  right: 0px;
  top: 9px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
  @media (max-width: 700px) {
    position: unset;
    margin-left: 12px;
  }
`;
const TableWrapper = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
`;
const UserCallDetails = ({
  uid,
  lid,
  setRefreshLeadDetails,
  isUpdateStatusDisabled,
  leadData,
}) => {
  const [open, setOpen] = useState(false);

  const [count, setCount] = useState(0);
  const [row, setRow] = useState([]);
  const [currData, setCurrData] = useState(null);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);

  const [viewLog, setViewLog] = useState(false);
  const { authStore, phoneCallState } = rootStore;
  const [openCallInfo, setOpenCallInfo] = useState(false);
  const [callInfoScope, setCallInfoScope] = useState(null);
  const [refreshCallLogAfterAdd, setRefreshCallLogAfterAdd] = useState(false);
  const actionColumn = [
    {
      field: "audio",
      headerName: "Audio",
      width: 100,
      sortable: false,

      renderCell: (params) => {
        return <>{params.row.audio && <PlayCircleOutline />}</>;
      },
    },
  ];

  const canOpenCallInfoDialog = () => {
    let leadInfo = {};
    if (phoneCallState.callStatus.hasOwnProperty("lead")) {
      leadInfo = phoneCallState.callStatus["lead"];
    }
    if (
      leadInfo.hasOwnProperty("id") &&
      leadInfo["id"] !== null &&
      leadInfo["id"] !== ""
    ) {
      if (authStore.leadDetailStatus["lead_id"] !== leadInfo["id"]) {
        return true;
      }
    }
    return false;
  };
  const handleClick = () => {
    if (phoneCallState.hasPendingCallLog()) {
      if (canOpenCallInfoDialog()) {
        setOpenCallInfo(true);
        setCallInfoScope(callInfoSheetScope.lead_details_call_log);
      } else {
        setOpen(true);
      }
    } else {
      setOpen(true);
    }
  };
  const getCallLogData = async ({ page_no }) => {
    setLoading(true);
    setRow([]);
    let data = await rootStore.authStore.getLeadCallDetails({
      leadID: lid,
      offset: page_no,
    });
    if (data.items !== null && data.item_count !== null) {
      setRow(data.items);
      setCount(data.item_count);
    } else {
      setRow([]);
      setCount(0);
    }
    setLoading(false);
  };

  const fetchFirstPageCallLogs = async () => {
    setLoading(true);
    await rootStore.authStore.fetchLeadCallLogs({
      leadID: lid,
      offset: 0,
    });
    let items = rootStore.authStore.currentLeadInfo.call_logs.items;
    let item_count = rootStore.authStore.currentLeadInfo.call_logs.item_count;
    if (items !== null && item_count !== null) {
      setRow(items);
      setCount(item_count);
    } else {
      setRow([]);
      setCount(0);
    }
    setLoading(false);
  };
  const init = async () => {
    let offset =
      rootStore.authStore.currentLeadInfo.call_logs.start_index !== null
        ? rootStore.authStore.currentLeadInfo.call_logs.start_index
        : 0;
    setPage(offset);
    if (offset === 0) {
      await fetchFirstPageCallLogs();
    } else {
      await getCallLogData({ page_no: offset });
    }
  };

  const refetch = async () => {
    await getCallLogData({ page_no: page });
    setRefreshCallLogAfterAdd(false);
  };

  const handlePagination = async (e, page) => {
    setPage(page - 1);
    await getCallLogData({ page_no: page - 1 });
  };

  useEffect(() => {
    analytics.triggerEvent(
      4625000051,
      "call_logs_tab_load",
      "lead_details_page",
      "",
      {}
    );
    init();
  }, []);

  useEffect(() => {
    if (refreshCallLogAfterAdd) {
      refetch();
    }
  }, [refreshCallLogAfterAdd]);
  return (
    <>
      <Container>
        <AddBtnWrapper>
          <AddBoxOutlined
            color={isUpdateStatusDisabled() ? "disabled" : "primary"}
            onClick={() => {
              authStore.updateLastActiveTime();
              if (isUpdateStatusDisabled()) {
                return null;
              } else {
                analytics.triggerEvent(
                  4625000063,
                  "add_call_log_button_tap",
                  "call_logs_tab",
                  "add_call_log_button",
                  {}
                );
                handleClick();
              }
            }}
            style={{
              width: "40px",
              height: "40px",
            }}
          />
        </AddBtnWrapper>
        <Wrapper>
          <DetailsWrapper>
            <TableWrapper>
              <DataGrid
                autoHeight={true}
                rows={row}
                columns={callsColumns.concat(actionColumn)}
                pageSize={callLogsLimit}
                checkboxSelection={false}
                style={{ cursor: "pointer" }}
                hideFooter={true}
                loading={loading}
                disableColumnMenu={true}
                disableSelectionOnClick={true}
                onRowClick={(params) => {
                  analytics.triggerEvent(
                    4625000067,
                    "call_log_row_tap",
                    "call_logs_tab",
                    "",
                    {}
                  );
                  setCurrData(params.row);
                  setViewLog(true);
                }}
              />
            </TableWrapper>

            <PaginationWithoutPageSize
              page_no={page}
              row={row}
              count={count}
              handlePage={handlePagination}
              counterText={rootStore.authStore.getLeadCallLogsPaginationCounterText()}
              page_size={callLogsLimit}
            />
          </DetailsWrapper>
        </Wrapper>
      </Container>

      {open ? (
        <CallLogModal
          scope={"call_log_tab"}
          open={open}
          setOpen={setOpen}
          lid={lid}
          uid={uid}
          setRefreshCallLogAfterAdd={setRefreshCallLogAfterAdd}
          setRefreshLeadDetails={setRefreshLeadDetails}
          leadData={leadData}
        />
      ) : null}
      {/* {openAudio && audioUrl && (
        <AudioModal url={audioUrl} open={openAudio} setOpen={setOpenAudio} />
      )}
      {viewNote && (
        <NotesModal open={viewNote} setOpen={setViewNote} textNote={textNote} />
      )} */}
      {viewLog && (
        <ViewCallLogDetails
          setOpen={setViewLog}
          open={viewLog}
          data={currData}
          setData={setCurrData}
        />
      )}
      {openCallInfo && (
        <CallInfoDialog
          open={openCallInfo}
          setOpen={setOpenCallInfo}
          scope={callInfoScope}
          openCallLogCallback={async () => {
            setOpen(true);
            setOpenCallInfo(false);
          }}
        />
      )}
    </>
  );
};

export default observer(UserCallDetails);
