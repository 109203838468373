import { Add, ArrowRightAltSharp, History } from "@material-ui/icons";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import spinner from "../../assets/images/gifs/spinner.gif";
import { v4 as uuid } from "uuid";

import styled from "styled-components";
import Footer from "../../components/landing_page_components/footer/Footer";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { ImportHistoryColumns, SupportSectionColumns } from "../../Db";
import { getImportHistoryApi, refreshImportRowApi } from "../../Api";
import { Button, Tooltip } from "@material-ui/core";
import Pagination from "../../components/pagination/Pagination";
import UploadModal from "../../components/upload_csv_modal/UploadModal";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { pageSize } from "../../Config";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ImportRowComponent from "../../components/import_row_component/ImportRowComponent";
import AddTicketModal from "../../components/add_ticket_modal/AddTicketModal";
const Container = styled.div`
  margin-top: 64px;
  background-color: #f9f9fc;
  ${`min-height: calc(100vh - 221px);`};
`;
const Wrapper = styled.div`
  width: 95%;
  margin: auto;
  /* background-color: white; */
  display: flex;
  flex-direction: column;
  /* padding-top: 20px; */
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
`;

const Spinner = styled.img`
  height: 120px;
  width: 180px;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  min-height: 40px;
  padding: 20px 0px;
`;

const get_leads_url = process.env.REACT_APP_API_BASE_URL;
const Text = styled.p`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;
const TopWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Header = styled.p`
  margin: 0px;
  font: normal normal 600 28px Open Sans;
  /* text-align: center; */
  color: #4d4e4f;
`;
const TableWrapper = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
  background-color: white;
  overflow-x: auto;
  border: 2px solid #e0e0e0;
`;
const PaginationWrapper = styled.div`
  padding: 0px 20px;
`;
const EmptyTableWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const EmptyText = styled.p`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
  /* text-align: center; */
  color: #4d4e4f;
  margin-top: 50px;
`;
const SerialRow = [
  {
    field: "id",
    headerName: <Text>ID</Text>,
    sortable: false,

    width: 70,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.id}>
            <span className="csutable-cell-trucate">{params.row.id}</span>
          </Tooltip>
        </>
      );
    },
  },
];
const SupportSection = (props) => {
  const { authStore, userStore } = rootStore;
  const [row, setRow] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(pageSize);
  const [openImport, setOpenImport] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  let pid = authStore.projectId;
  let url = `${get_leads_url}projects/${pid}/leads/import/history/`;

  useEffect(() => {
    // setRow();
    setLoading(true);
    const getSummary = async () => {
      let response = await getImportHistoryApi({ page: page, page_size: size });
      //   setRow(response.data.items);
      //   setCount(response.data.item_count);
      //   setLoading(false);
    };
    getSummary();
  }, [refresh]);

  const handlePageSize = async (e) => {
    setSize(e.target.value);
    setRow();
    setPage(0);
    setLoading(true);
    let response = await getImportHistoryApi({
      page: 0,
      page_size: e.target.value,
    });
    setRow(response.data.items);
    setCount(response.data.item_count);
    setLoading(false);
  };
  const handlePagination = async (e, page) => {
    setRow();
    setPage(page - 1);
    setLoading(true);
    let response = await getImportHistoryApi({
      page: page - 1,
      page_size: size,
    });
    setRow(response.data.items);
    setCount(response.data.item_count);
    setLoading(false);
  };
  return (
    <>
      <Container>
        <Wrapper>
          <Row>
            <TopWrapper>
              <Header>Support</Header>
            </TopWrapper>
            <Button
              onClick={() => {
                setOpenImport(true);
              }}
              variant="contained"
              color="primary"
              style={{
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              <Add style={{ width: "18px", height: "18px" }} /> Create Ticket
            </Button>
            {/* <History style={{ height: "34px", width: "34px" }} /> */}
          </Row>
          {row ? (
            <Bottom>
              <TableWrapper>
                <DataGrid
                  autoHeight={true}
                  rows={row}
                  columns={SupportSectionColumns}
                  pageSize={size}
                  checkboxSelection={false}
                  style={{ cursor: "pointer" }}
                  hideFooter={true}
                  //   loading={loading}
                  disableColumnMenu={true}
                  disableSelectionOnClick={true}
                  //   onCellClick={handleCellClick}
                />
              </TableWrapper>
              <PaginationWrapper>
                <PaginationComponent
                  page_no={page}
                  row={row}
                  page_size={size}
                  size={size}
                  count={count}
                  handlePageSize={handlePageSize}
                  handlePage={handlePagination}
                />
              </PaginationWrapper>
            </Bottom>
          ) : (
            <SpinnerWrapper>
              <Spinner src={spinner} alt="loading..." />
            </SpinnerWrapper>
          )}
        </Wrapper>
      </Container>
      <Footer />
      {openImport && (
        <AddTicketModal
          open={openImport}
          setOpen={setOpenImport}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}
    </>
  );
};

export default observer(SupportSection);
