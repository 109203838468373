import { AppBar, Box, Button, Grid, Toolbar } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { getLocalizedText } from "../../../Functions";
import { CircularProgress, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {
  createLeadNoteApi,
  getUsersToMentionInLeadNoteApi,
} from "../../../Api";
import { LoadingButton } from "@mui/lab";
import MDEditor, { commands } from "@uiw/react-md-editor";
import analytics from "../../../Analytics";
import UserToMentionDialog from "./dialogs/UserToMentionDialogs";
const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  margin: auto;
  background-color: white;
  height: 100%;
  padding: 16px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;

const AddNoteCardWidget = ({
  leadID,
  setRefresh,
  setOpen,
  setShowSnackBar,
}) => {
  const [note, setNote] = useState("");
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openUserPickerDialog, setOpenUserPickerDialog] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const hiddenTextareaRef = useRef(null);
  const editorRef = useRef(null);
  const [memberList, setMemberList] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let mentions = getUniqueMentionsFromMarkedText();
    let tempMentionsPayload = {};
    mentions.forEach((str, idx) => {
      let index = memberList.findIndex((member) => member["name"] === str);
      if (index !== -1) {
        let key = "mentions_" + (idx + 1);
        tempMentionsPayload[key] = memberList[index]["id"];
      }
    });

    let payload = { note: note, version: 2, ...tempMentionsPayload };
    let response = await createLeadNoteApi({
      leadID: leadID,
      payload: payload,
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setRefresh(true);
      setNote("");
      setShowSnackBar(true);
      setOpen(false);
    }
    setIsSubmitting(false);
  };

  const init = async () => {
    let response = await getUsersToMentionInLeadNoteApi({ leadID: leadID });
    setMemberList(response);
    setLoading(false);
  };
  useEffect(() => {
    init();
    analytics.triggerEvent(
      4625000074,
      "add_note_form_load",
      "notes_tab",
      "",
      {}
    );
  }, []);

  const handleUserSelection = (value) => {
    const lastChar = note.charAt(note.length - 1);
    let newStr = note.slice(0, -1);
    newStr =
      newStr + "<mention>" + lastChar + value["name"] + "</mention>" + " ";
    setNote(newStr);
    setOpenUserPickerDialog(false);
    setAnchor(null);
  };

  function isLastCharacterAtSign(str) {
    // Trim any trailing whitespace or newline characters from the string
    const trimmedStr = str.trim();

    // Check if the last character is '@' and is preceded by a space or newline
    const lastChar = trimmedStr.charAt(trimmedStr.length - 1);
    const secondLastChar = trimmedStr.charAt(trimmedStr.length - 2);

    return (
      lastChar === "@" &&
      (secondLastChar === " " ||
        secondLastChar === "\n" ||
        trimmedStr.length === 1)
    );
  }
  const getUniqueMentionsFromMarkedText = () => {
    // Regular expression to match content inside <mark> tags
    const markPattern = /<mention>(.*?)<\/mention>/g;
    // Regular expression to match mentions (e.g., @username)
    const mentionPattern = /@(\w[\w\s-]*)/g;
    const mentionSet = new Set();
    let match;

    // Loop through all <mark> tag matches
    while ((match = markPattern.exec(note)) !== null) {
      const markedText = match[1]; // Extracted text inside <mark> tags
      let mentionMatch;
      // Find mentions within the extracted text
      while ((mentionMatch = mentionPattern.exec(markedText)) !== null) {
        mentionSet.add(mentionMatch[1].trim()); // Add unique mentions to the Set
      }
    }

    // Convert the Set to an array
    return Array.from(mentionSet);
  };

  // Function to calculate cursor position
  const getCursorPosition = (index, textarea) => {
    const lines = textarea.value.substr(0, index).split("\n");
    const lineCount = lines.length;
    const lastLine = lines[lineCount - 1];
    const charWidth = 8; // Approximate character width
    const lineHeight = 18; // Approximate line height

    return {
      top: (lineCount - 1) * lineHeight,
      left: lastLine.length * charWidth,
    };
  };

  return !loading ? (
    <Box
      component={"form"}
      onSubmit={handleSubmit}
      sx={{
        border: "1px solid rgb(218, 220, 224)",
      }}
    >
      <Container>
        <Box
          style={{
            height: "100%",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputWrapper>
                <Label>Note*</Label>
                <div className="container" data-color-mode="light">
                  <MDEditor
                    ref={editorRef}
                    value={note}
                    onChange={(value) => {
                      let mentionsCount = getUniqueMentionsFromMarkedText();
                      if (mentionsCount < 1) {
                        // Detect "@" and show popup
                        const mentionIndex = value.lastIndexOf("@");
                        if (mentionIndex !== -1) {
                          if (isLastCharacterAtSign(value)) {
                            // Update hidden textarea content to match editor

                            hiddenTextareaRef.current.value = value;
                            const cursorPosition = getCursorPosition(
                              mentionIndex,
                              hiddenTextareaRef.current
                            );

                            // Get the position of the editor

                            const editorRect =
                              editorRef.current.textarea.getBoundingClientRect();
                            setAnchor({
                              top: editorRect.top + cursorPosition.top,
                              left: editorRect.left + cursorPosition.left,
                            });

                            setOpenUserPickerDialog(true);
                          }
                        }
                      }
                      setNote(value);
                    }}
                    preview="edit"
                    extraCommands={[commands.fullscreen]}
                    commands={[
                      commands.bold,
                      commands.italic,
                      commands.strikethrough,
                      commands.hr,
                      commands.group(
                        [
                          commands.title1,
                          commands.title2,
                          commands.title3,
                          commands.title4,
                          commands.title5,
                          commands.title6,
                        ],
                        {
                          name: "title",
                          groupName: "title",
                          buttonProps: { "aria-label": "Insert title" },
                        }
                      ),
                      commands.divider,
                      commands.link,
                      commands.quote,
                      commands.code,
                      commands.codeBlock,
                      commands.divider,
                      commands.unorderedListCommand,
                      commands.orderedListCommand,
                      commands.checkedListCommand,
                    ]}
                  />
                  <textarea
                    ref={hiddenTextareaRef}
                    style={{
                      position: "absolute",
                      opacity: 0,
                      height: 0,
                      width: 0,
                    }}
                    readOnly
                  />
                  {openUserPickerDialog && (
                    <UserToMentionDialog
                      anchor={anchor}
                      handleClose={() => {
                        setOpenUserPickerDialog(false);
                        setAnchor(null);
                      }}
                      handleMenuClick={(value) => {
                        handleUserSelection(value);
                      }}
                      itemList={memberList}
                    />
                  )}
                </div>
              </InputWrapper>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <AppBar
        position="static"
        color="inherit"
        elevation={0}
        sx={{ top: "auto", bottom: 0, width: "100%" }}
      >
        <Toolbar
          style={{
            padding: "16px",
            width: "100%",
            position: "relative",
          }}
        >
          {isSubmitFail && (
            <ErrorAlert
              onClose={(e) => {
                e.stopPropagation();
                setIsSubmitFail(false);
              }}
              severity="error"
              sx={{ width: "100%" }}
            >
              {errorMessage}
            </ErrorAlert>
          )}
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button
                onClick={() => {
                  setOpen(false);
                  analytics.triggerEvent(
                    4625000076,
                    "cancel_button_tab",
                    "add_note_form",
                    "cancel_button",
                    {}
                  );
                }}
                variant="contained"
                color="default"
                style={{
                  fontSize: "12px",
                  textTransform: "none",
                  fontWeight: "bold",
                }}
              >
                {getLocalizedText("cancel")}
              </Button>
            </Grid>
            <Grid item>
              {isSubmitting ? (
                <LoadingButton
                  loading
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "none",
                    width: "fit-content",
                  }}
                >
                  <span> {getLocalizedText("submit")}</span>
                </LoadingButton>
              ) : (
                <Button
                  onClick={() => {
                    analytics.triggerEvent(
                      4625000075,
                      "submit_button_tab",
                      "add_note_form",
                      "submit_button",
                      {}
                    );
                  }}
                  disabled={note === ""}
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "fit-content",
                    textTransform: "none",
                  }}
                >
                  Add
                </Button>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  ) : (
    <Box
      sx={{
        width: "100%",
        height: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default AddNoteCardWidget;
