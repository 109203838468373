import React, { forwardRef, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import {
  Button,
  FormControl,
  Radio,
  Select,
  makeStyles,
} from "@material-ui/core";
import { Box, Divider, MenuItem, Modal, Typography } from "@mui/material";
import { getLocalizedText } from "../../../Functions";
import { myTheme } from "../../../themeUtils";
import rootStore from "../../../stores/RootStore";
import {
  addPredefinedWidgetApi,
  createDashboardApi,
  createPrebuildDashboardApi,
  getPredefinedWidgetsForPrebuiltDashboradsApi,
} from "../../../Api";
import MuiAlert from "@mui/material/Alert";
import ErrorMessageDialog from "../../alert_dialogue/ErrorMessageDialog";

const ErrorAlert = forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      variant="filled"
      {...props}
      color="error"
      style={{
        backgroundColor: "rgba(249,86,104,1)",
        color: "#F9E1E1",
        width: "400px",
        position: "absolute",
        bottom: "75px",
      }}
    />
  );
});

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;

const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const Form = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 400px;
  row-gap: 20px;
  margin-bottom: 60px;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const Row = styled(Box)`
  display: flex;
  flex-direction: row;
`;
const SelectColumn = styled("div")`
  display: flex;
  flex-direction: column;
  width: 250px;
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const Label = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;

const Input = styled("input")`
  width: 100%;
  height: 30px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;
  border: 1px solid #bfbfbf;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 600 12px Open Sans;
    color: #e1e1e1;
  }
`;
const TextArea = styled("textarea")`
  width: 100%;
  height: 60px;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #bfbfbf;
  color: #4d4e4f;

  resize: none;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 600 12px Open Sans;
    color: #e1e1e1;
  }
`;
const ButtonWrapper = styled(Box)`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const AddOwnerPrebuildDashboardModal = (props) => {
  const { authStore } = rootStore;
  const [formData, setFormData] = useState({});
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedValue, setSelectedValue] = useState("dropdown");
  const [predefinedList, setPredefinedList] = useState([]);
  const [selectedPrebuildDashboard, setSelectedPrebuildDashboard] =
    useState(null);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);

  const classes = useStyles();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedValue === "dropdown") {
      if (
        props.dashBoardNames.includes(
          selectedPrebuildDashboard["name"].toLowerCase()
        )
      ) {
        setIsError(true);
        setErrorMessage("Dashboard already exist!");
      } else {
        setIsError(false);
        let payload = { ...formData };
        payload.name = selectedPrebuildDashboard["name"];
        payload.predefined_dashboard_id = selectedPrebuildDashboard["id"];
        payload.project_id = authStore.projectId;
        let response = await createPrebuildDashboardApi(payload);
        if (response.hasError()) {
          setOpenErrorDialog(true);
        } else {
          const widgets = selectedPrebuildDashboard["widgets"];
          for (const data of widgets) {
            if (data["reco_for_multi_projects"]) {
              if (authStore.projectList.length > 1) {
                //  if the user has more than 1 project
                await createWidget(response.data["id"], data);
              }
            } else if (data["reco_for_multi_teams"]) {
              if (
                authStore.currentProject.hasOwnProperty("teams") &&
                authStore.currentProject["teams"].length > 1 &&
                authStore.projectList.length === 1
              ) {
                // if the user has 1 project and more than 1 team
                await createWidget(response.data["id"], data);
              }
            } else {
              if (
                authStore.currentProject.hasOwnProperty("teams") &&
                authStore.currentProject["teams"].length === 1 &&
                authStore.projectList.length === 1
              ) {
                // if the user has 1 project and  1 team
                await createWidget(response.data["id"], data);
              }
            }
          }
          props.setCurrentDashboardData(response.data);
          props.setCurrentDashboardId(response.data["id"]);

          props.setRefresh(true);
          props.setOpen(false);
        }
      }
    } else {
      let response = await createDashboardApi(formData);
      if (response.hasError()) {
        setOpenErrorDialog(true);
      } else {
        props.setCurrentDashboardData(response.data);
        props.setCurrentDashboardId(response.data["id"]);
        props.setRefresh(true);
        props.setOpen(false);
      }
    }
  };

  const createWidget = async (dashboardId, data) => {
    let widgetPayload = {
      title: data.name,
      predefined_widget_id: data.id,
      version: 2,
      input: {},
    };
    let widgetResponse = await addPredefinedWidgetApi({
      dashboard_id: dashboardId,
      data: widgetPayload,
    });
    // if (widgetResponse.hasError()) {
    //   setIsError(true);
    //   setErrorMessage(widgetResponse.errorMessage);
    // } else {
    //   setIsError(false);
    //   props.setRefresh(true);
    //   props.setOpen(false);
    // }
  };

  useEffect(() => {
    const setup = async () => {
      let response = await getPredefinedWidgetsForPrebuiltDashboradsApi();
      setPredefinedList(response.data);
    };
    setup();
  }, []);

  return (
    <>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <Container>
              <Form component={"form"} onSubmit={handleSubmit}>
                <Header>Add a new dashboard</Header>
                <SubHeader>Fill in the below details</SubHeader>
                <Divider style={{ margin: "10px 0px" }} />
                <InputContainer>
                  <InputWrapper>
                    <Row>
                      <Radio
                        checked={selectedValue === "dropdown"}
                        onChange={(event) => {
                          setSelectedValue(event.target.value);
                        }}
                        value="dropdown"
                        name="radio-buttons-dropdown"
                      />
                      <SelectColumn style={{ width: "100%" }}>
                        <Label>Select Name*</Label>
                        <FormControl
                          sx={{ m: 1, minWidth: 200 }}
                          classes={{
                            root: classes.quantityRoot,
                          }}
                        >
                          <Select
                            classes={{
                              icon: classes.icon,
                            }}
                            name="prebuild_dashboard"
                            value={selectedPrebuildDashboard}
                            displayEmpty
                            disableUnderline
                            variant="outlined"
                            disabled={selectedValue !== "dropdown"}
                            required={selectedValue === "dropdown"}
                            onChange={(e) => {
                              setSelectedPrebuildDashboard(e.target.value);
                            }}
                            style={{
                              width: "100%",
                              height: "30px",
                              fontSize: "12px",
                              fontWeight: "bold",
                              borderRadius: "0px",
                            }}
                          >
                            {predefinedList.map((item, i) => {
                              return (
                                <MenuItem
                                  style={{
                                    fontSize: "12px",
                                    height: "30px",
                                  }}
                                  key={i}
                                  id={i}
                                  value={item}
                                >
                                  {item["name"]}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </SelectColumn>
                    </Row>
                    <Box
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "10px",
                        // marginBottom: "10px",
                      }}
                    >
                      <Divider style={{ width: "150px" }}>OR</Divider>
                    </Box>
                    <Row>
                      <Radio
                        checked={selectedValue === "textfield"}
                        onChange={(event) => {
                          setSelectedValue(event.target.value);
                        }}
                        value="textfield"
                        name="radio-buttons-text"
                      />
                      <InputWrapper style={{ width: "100%" }}>
                        <Label>Name*</Label>
                        <Input
                          required
                          type="text"
                          placeholder="Enter title"
                          disabled={selectedValue === "dropdown"}
                          name="name"
                          onChange={handleChange}
                        />
                      </InputWrapper>
                    </Row>
                  </InputWrapper>
                  <InputWrapper>
                    <Label>Description</Label>
                    <TextArea
                      type="text"
                      placeholder="Enter description"
                      name="description"
                      onChange={handleChange}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <Label>Project</Label>
                    <Input
                      value={rootStore.authStore.currentProject["name"]}
                      readOnly
                    />
                  </InputWrapper>
                </InputContainer>
                {isError && (
                  <ErrorAlert
                    onClose={() => {
                      setIsError(false);
                      setErrorMessage("");
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {errorMessage}
                  </ErrorAlert>
                )}
                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      handleClose();
                    }}
                    type="button"
                    variant="outlined"
                    style={{
                      textTransform: "none",
                      marginRight: "20px",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100px",
                      ...myTheme.Button.btnLight,
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    // id="call-log-btn"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100px",
                    }}
                  >
                    {getLocalizedText("submit")}
                  </Button>
                </ButtonWrapper>
              </Form>
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
      {openErrorDialog && (
        <ErrorMessageDialog
          open={openErrorDialog}
          setOpen={setOpenErrorDialog}
        />
      )}
    </>
  );
};

export default observer(AddOwnerPrebuildDashboardModal);
