import {
  AppBar,
  Box,
  Divider,
  Toolbar,
  Button,
  Modal,
} from "@material-ui/core";
import { myTheme } from "../../../../themeUtils";
import React, { useState, useCallback, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { getLocalizedText } from "../../../../Functions";
import { createWhatsappBAApi, updateWhatsappBAApi } from "../../../../Api";
import MuiAlert from "@mui/material/Alert";
import { Grid, FormControl, Typography, OutlinedInput } from "@mui/material";
import { businessWhatsappProviderData } from "../../../../Db";

const dialogWidth = 600;
const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
  overflow-y: auto;
  row-gap: 10px;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const Label = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 16px;
  flex-direction: column;
  row-gap: 20px;
`;
const Message = styled(Typography)`
  font: normal normal normal 16px Open Sans;
  color: green;
  margin: 0px;
  text-align: center;
`;

const WhatsappProviderDialog = ({
  open,
  setOpen,
  providerData,
  formData,
  setRefresh,
  setRefreshType,
}) => {
  const [details, setDetails] = useState({
    ep: "",
    api_key: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

  const init = () => {
    if (formData?.xdata !== null && formData?.xdata !== undefined) {
      setDetails((prevDetails) => ({
        ...prevDetails,
        ...formData.xdata,
        api_key: providerData?.status === "Connected" ? "*************************" : "",
        ep: formData?.ep ?? "",
      }));
    }
  };

  useEffect(() => {
    init();
  }, []);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setDetails((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let payload = {};
    let xdata = {};
    let response = {};

    Object.keys(details).forEach((key) => {
      if (details[key] !== "") {
        if (key !== "api_key" && key !== "ep") {
          xdata[key] = details[key];
        }
      }
    });

    payload["xdata"] = xdata;
    if (providerData["provider_name"] !== "Meta WABA") {
      payload["ep"] = details["ep"];
    }

    const index = businessWhatsappProviderData.findIndex((x) => {
      return x.provider_name === providerData["provider_name"];
    });

    if (index !== 1) {
      payload["provider_name"] = businessWhatsappProviderData[index]["name"];
    }
    
    if (providerData["status"] === "Connected") {
      response = await updateWhatsappBAApi({
        wabaID: formData["id"],
        payload: payload,
      });
    } else {
      payload["api_key"] = details["api_key"];
      response = await createWhatsappBAApi({ payload: payload });
    }

    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setIsSubmitFail(false);
      setIsSubmitSuccess(true);
      setRefreshType("whatsapp");
      setRefresh(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderFormFields = () => {
    if (providerData["name"] === "Carpus IT") {
      return (
        <>
          <InputWrapper>
            <Label>{getLocalizedText("url")}*</Label>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                placeholder="Enter value"
                required
                onChange={handleChange}
                name="ep"
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                value={details.ep || ""}
              />
            </FormControl>
          </InputWrapper>
          <InputWrapper>
            <Label>{getLocalizedText("api_key")}*</Label>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                placeholder="Enter value"
                required
                onChange={handleChange}
                name="api_key"
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                value={details.api_key || ""}
                disabled={providerData["status"] === "Connected"}
              />
            </FormControl>
          </InputWrapper>
          <InputWrapper>
            <Label>{getLocalizedText("from_number")}*</Label>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                placeholder="Enter value"
                required
                onChange={handleChange}
                name="from"
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                value={details.from || ""}
              />
            </FormControl>
          </InputWrapper>
        </>
      );
    } else if (providerData["name"] === "Meta WABA") {
      return (
        <>
          <InputWrapper>
            <Label>{getLocalizedText("api_key")}*</Label>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                placeholder="Enter value"
                required
                onChange={handleChange}
                name="api_key"
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                value={details.api_key || ""}
                disabled={providerData["status"] === "Connected"}
              />
            </FormControl>
          </InputWrapper>
          <InputWrapper>
            <Label>{getLocalizedText("whatsapp_business_id")}*</Label>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                placeholder="Enter value"
                required
                onChange={handleChange}
                name="whatsapp_business_id"
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                value={details.whatsapp_business_id || ""}
              />
            </FormControl>
          </InputWrapper>
          <InputWrapper>
            <Label>{getLocalizedText("whatsapp_phone_number")}*</Label>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                placeholder="Enter value"
                required
                onChange={handleChange}
                name="whatsapp_phone_number"
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                value={details.whatsapp_phone_number || ""}
              />
            </FormControl>
          </InputWrapper>
        </>
      );
    } else if (providerData["name"] === "TextLocal") {
      return (
        <>
          <InputWrapper>
            <Label>{getLocalizedText("url")}*</Label>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                placeholder="Enter value"
                required
                onChange={handleChange}
                name="ep"
                value={details.ep || ""}
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "url",
                  style: { fontSize: "12px" },
                }}
              />
            </FormControl>
          </InputWrapper>

          <InputWrapper>
            <Label>{getLocalizedText("api_key")}*</Label>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                placeholder="Enter value"
                required
                onChange={handleChange}
                name="api_key"
                value={details.api_key}
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "api_key",
                  style: { fontSize: "12px" },
                }}
                disabled={providerData["status"] === "Connected"}
              />
            </FormControl>
          </InputWrapper>

          <InputWrapper>
            <Label>{getLocalizedText("application_id")}*</Label>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                placeholder="Enter value"
                required
                onChange={handleChange}
                name="application_id"
                value={details.application_id}
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "application_id",
                  style: { fontSize: "12px" },
                }}
              />
            </FormControl>
          </InputWrapper>
        </>
      );
    } else {
      return (
        <>
          <InputWrapper>
            <Label>{getLocalizedText("url")}*</Label>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                placeholder="Enter value"
                required
                onChange={handleChange}
                name="ep"
                value={details.ep || ""}
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "url",
                  style: { fontSize: "12px" },
                }}
              />
            </FormControl>
          </InputWrapper>

          <InputWrapper>
            <Label>{getLocalizedText("api_key")}*</Label>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                placeholder="Enter value"
                required
                onChange={handleChange}
                name="api_key"
                value={details.api_key}
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "api_key",
                  style: { fontSize: "12px" },
                }}
                disabled={providerData["status"] === "Connected"}
              />
            </FormControl>
          </InputWrapper>

          <InputWrapper>
            <Label>{getLocalizedText("application_id")}*</Label>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                placeholder="Enter value"
                required
                onChange={handleChange}
                name="application_id"
                value={details.application_id}
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "application_id",
                  style: { fontSize: "12px" },
                }}
              />
            </FormControl>
          </InputWrapper>
        </>
      );
    }
  };

  const renderInputField = (labelKey, fieldName) => (
    <InputWrapper>
      <Label>{getLocalizedText(labelKey)}*</Label>
      <FormControl sx={{ width: "100%" }}>
        <OutlinedInput
          placeholder="Enter value"
          required
          onChange={handleChange}
          name={fieldName}
          value={details[fieldName] || ""}
          style={{ height: "30px" }}
          inputProps={{
            "aria-label": fieldName,
            style: { fontSize: "12px" },
          }}
        />
      </FormControl>
    </InputWrapper>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {!isSubmitSuccess ? (
        <ModelContainer>
          <Box component="form" onSubmit={handleSubmit}>
            <AppBar
              component="nav"
              elevation={0}
              position="sticky"
              color="inherit"
              sx={{ top: 0, bottom: "auto", width: "100%", right: 0 }}
            >
              <Toolbar style={{ padding: "16px" }}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <HeaderWrapper>
                    <Header>{providerData["name"]}</Header>
                    <SubHeader>
                      {getLocalizedText("fill_in_the_below_details")}
                    </SubHeader>
                  </HeaderWrapper>
                </Box>
              </Toolbar>
            </AppBar>
            <Divider style={{ margin: "0px 16px" }} />
            <Container>{renderFormFields()}</Container>
            <AppBar
              position="static"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: "100%" }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                  width: dialogWidth,
                  position: "relative",
                }}
              >
                {isSubmitFail && (
                  <ErrorAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setIsSubmitFail(false);
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {errorMessage}
                  </ErrorAlert>
                )}
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      color="default"
                      style={{
                        fontSize: "12px",
                        textTransform: "none",
                        fontWeight: "bold",
                      }}
                    >
                      {getLocalizedText("cancel")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        width: "fit-content",
                        textTransform: "none",
                        ...myTheme.Button.btnBlue,
                      }}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Box>
        </ModelContainer>
      ) : (
        <ModelContainer
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MessageWrapper style={{ marginBottom: "0px", padding: "16px" }}>
            <Message>Saved successfully!</Message>

            <Button
              onClick={handleClose}
              type="button"
              variant="contained"
              color="default"
              style={{
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              {getLocalizedText("close")}
            </Button>
          </MessageWrapper>
        </ModelContainer>
      )}
    </Modal>
  );
};

export default WhatsappProviderDialog;
