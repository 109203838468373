import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Edit, Visibility } from "@material-ui/icons";

const TableHeaderCell = styled(TableCell)`
  font-size: 12px;
  padding: 8px 12px;
  white-space: nowrap;
`;
const TableDataCell = styled(TableCell)`
  font-size: 12px;
  padding: 8px 12px;
  white-space: nowrap;
`;
const FixedTableHeaderCell = styled(TableHeaderCell)`
  position: sticky;
  right: 0;
  z-index: 100;
`;

const FixedTableDataCell = styled(TableDataCell)`
  position: sticky;
  right: 0;
  z-index: 99;
  background-color: white;
`;
export default function TableComponent({
  tableData,
  setSelectedProductData,
  setOpenEditProductModal,
  setOpenViewProductModal,
}) {
  return (
    <TableContainer
      component={Paper}
      style={{
        height: "calc(100vh - 494px)",
        overflowY: "auto",
        zIndex: "0",
        width: "100%",
      }}
      sx={{
        "&::-webkit-scrollbar": {
          width: "6px", // Customize scrollbar width
          height: "6px", // Customize scrollbar height for horizontal scroll
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#888", // Color of the scrollbar thumb
          borderRadius: "10px", // Roundness of the scrollbar thumb
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#555", // Color of the scrollbar thumb on hover
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#f1f1f1", // Color of the scrollbar track
        },
      }}
    >
      <Table stickyHeader sx={{ width: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableHeaderCell>Item</TableHeaderCell>
            <TableHeaderCell align="right">Rate</TableHeaderCell>
            <TableHeaderCell align="right">Qty</TableHeaderCell>
            <TableHeaderCell align="right">Discount (%)</TableHeaderCell>
            <TableHeaderCell align="right">Amount</TableHeaderCell>
            <FixedTableHeaderCell align="right">Actions</FixedTableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.length > 0 ? (
            tableData.map((row, i) => (
              <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableDataCell component="th" scope="row">
                  {row["item"]["name"]}
                </TableDataCell>
                <TableDataCell align="right">{row["rate"]}</TableDataCell>
                <TableDataCell align="right">{row["quantity"]}</TableDataCell>
                <TableDataCell align="right">{row["discount"]}</TableDataCell>
                <TableDataCell align="right">{row["amount"]}</TableDataCell>
                <FixedTableDataCell align="right">
                  <Visibility
                    onClick={() => {
                      setSelectedProductData(row);
                      setOpenViewProductModal(true);
                    }}
                    style={{
                      width: "18px",
                      height: "18px",
                      cursor: "pointer",
                      marginRight: "8px",
                    }}
                    color="primary"
                  />
                  <Edit
                    onClick={() => {
                      setSelectedProductData(row);
                      setOpenEditProductModal(true);
                    }}
                    style={{
                      width: "18px",
                      height: "18px",
                      cursor: "pointer",
                    }}
                    color={"primary"}
                  />
                </FixedTableDataCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableDataCell
                style={{ textAlign: "center", height: "calc(100vh - 552px)" }}
                colSpan={7}
              >
                no items.
              </TableDataCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
