import { Box, Button, Modal } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Close } from "@material-ui/icons";
import { Transfer } from "antd";
import "antd/dist/antd.css";
import "../../styles/multiselect.css";
import { getColumnsForTabApi, setColumnsForTabApi } from "../../Api";
import { Alert } from "@mui/material";
import { MenuOutlined } from "@ant-design/icons";
import { DndProvider, useDrop, useDrag } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import analytics from "../../Analytics";
import { consoleLogger, getLocalizedText } from "../../Functions";
import rootStore from "../../stores/RootStore";
const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 30px 50px;
  @media (max-width: 700px) {
    height: 80vh;
    overflow-y: scroll;
  }
`;
const ModelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  position: unset;
`;
const ColumnsWrapper = styled.div`
  /* display: flex; */
  margin-bottom: 20px;
`;
const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  > .label {
    display: inline-block;
    max-width: calc(100% - 20px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &.drop-over-downward {
    border-bottom: 2px dashed #1890ff;
  }
  &.drop-over-upward {
    border-top: 2px dashed #1890ff;
  }
`;

const ModalHeader = styled.p`
  font: normal normal 700 24px Open Sans;
  margin-bottom: 20px;
  @media (max-width: 700px) {
    font: normal normal 700 18px Open Sans;
  }
`;

const ColumnSelectionModal = (props) => {
  const [data, setData] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const columnsList = [...rootStore.userStore.AllColumnsList];
  const handleClick = () => {
    props.setOpen(false);
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  const getSelectedColumns = () => {
    let index = rootStore.leadStore._tabsDetails.findIndex(
      (tab) => tab["tab"]["id"] === rootStore.leadStore.tabID
    );
    if (index !== -1) {
      let roleBasedSelectedColumns =
        rootStore.leadStore._tabsDetails[index]["selected_columns"];
      if (roleBasedSelectedColumns.length > 0) {
        return roleBasedSelectedColumns;
      } else {
        let generalSelectedColumns =
          rootStore.leadStore._tabsDetails[index]["tab"]["selected_columns"];
        return generalSelectedColumns;
      }
    }
    return [];
  };

  const setColumnsForTab = async (data) => {
    try {
      let response = await setColumnsForTabApi({
        tabId: rootStore.leadStore.tabID,
        data: data,
      });
      consoleLogger(response.data);
      props.setOpen(false);
      props.setRefresh(true);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    analytics.triggerEvent(
      4625000077,
      "select_columns_form_load",
      "leads_page",
      "",
      {}
    );
    let newData = [];
    if (columnsList.length > 0) {
      columnsList.forEach((e) => {
        if (rootStore.userStore.isColumnVisible(e)) {
          let newObj = {
            key: e.field,
            title: e.headerName,
          };
          newData.push(newObj);
        }
      });
      setData(newData);
      const getColumnsList = async () => {
        let targetKeys = [];
        try {
          let response = await getColumnsForTabApi(rootStore.leadStore.tabID);
          if (response.data.length > 0) {
            response.data.forEach((item) => {
              columnsList.forEach((key) => {
                if (rootStore.userStore.isColumnVisible(key)) {
                  if (key["field"] === item) {
                    targetKeys.push(key["field"]);
                  }
                }
              });
            });
          }
        } catch (error) {
          if (error.response.request.status === 404) {
            let selectedColumns = getSelectedColumns();
            selectedColumns.forEach((e) => {
              newData.forEach((item) => {
                if (item["key"] === e.field) {
                  targetKeys.push(e.field);
                }
              });
            });
          }
        }
        setTargetKeys(targetKeys);
      };
      getColumnsList();
    }
  }, []);
  const handleKeys = (keys) => {
    setShowAlert(false);
    setTargetKeys(Array.from(new Set(keys)));
  };
  const handleSubmit = () => {
    if (targetKeys.length > 0) {
      setShowAlert(false);
      let columnListToSet = [];
      targetKeys.forEach((item) => {
        columnsList.forEach((key) => {
          if (key["field"] === item) {
            if (!columnListToSet.includes(item)) {
              columnListToSet.push(key["field"]);
            }
          }
        });
      });
      setColumnsForTab(columnListToSet);
    } else {
      setShowAlert(true);
    }
  };

  //order select column related functions

  // change order
  const moveRow = async (dragIndex, hoverIndex) => {
    const clonedList = targetKeys;
    const el = clonedList.splice(dragIndex, 1)[0];
    clonedList.splice(hoverIndex, 0, el);
    handleKeys(clonedList);
  };

  //end//

  return (
    <>
      {
        <Modal
          open={props.open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModelContainer>
            <Box>
              <Close
                onClick={handleClick}
                style={{
                  height: "18px",
                  width: "18px",
                  position: "absolute",
                  top: "20px",
                  right: "30px",
                  cursor: "pointer",
                }}
              />
              <ModelWrapper>
                <ModalHeader>
                  {getLocalizedText("select_columns_to_display")}
                </ModalHeader>
                <ColumnsWrapper>
                  {data.length > 0 && (
                    <DndProvider backend={HTML5Backend}>
                      <Transfer
                        dataSource={data}
                        listStyle={{
                          width: "fit-content",
                          height: 300,
                        }}
                        titles={[
                          getLocalizedText("columns_available"),
                          getLocalizedText("columns_in_use"),
                        ]}
                        targetKeys={targetKeys}
                        onChange={handleKeys}
                        render={(it) => (
                          <DraggableItem
                            index={targetKeys.findIndex(
                              (key) => key === it.key
                            )}
                            label={it.title}
                            moveRow={moveRow}
                          />
                        )}
                      />
                    </DndProvider>
                  )}
                </ColumnsWrapper>
                {showAlert && (
                  <Alert
                    style={{
                      width: "100%",
                      padding: "0px 20px",
                    }}
                    severity="warning"
                  >
                    {getLocalizedText("please_have_atleast_one_column_in_use")}
                  </Alert>
                )}
                <Button
                  onClick={() => {
                    analytics.triggerEvent(
                      4625000078,
                      "apply_button_tap",
                      "select_columns_form",
                      "apply_button",
                      {}
                    );
                    handleSubmit();
                  }}
                  variant="contained"
                  style={{
                    marginTop: "20px",
                    backgroundColor: "blue",
                    color: "white",
                    width: "fit-content",
                    margin: "auto",
                  }}
                >
                  {getLocalizedText("apply")}
                </Button>
              </ModelWrapper>
            </Box>
          </ModelContainer>
        </Modal>
      }
    </>
  );
};

const type = "DraggableItem";

const DraggableItem = ({ index, label, moveRow }) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ` drop-over-downward` : ` drop-over-upward`,
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(ref));

  return (
    <ItemWrapper
      key={label}
      ref={ref}
      className={`${isOver ? dropClassName : ""}`}
    >
      <span className="label">{label}</span>
      {index !== -1 && (
        <span ref={drag}>
          <MenuOutlined />
        </span>
      )}
    </ItemWrapper>
  );
};

export default ColumnSelectionModal;
