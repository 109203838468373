import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import TabularWidget from "../dashboard_widget_slideshow/widgets/TabularWidget";
import OneGroupBarGraphWidget from "../dashboard_widget_slideshow/widgets/OneGroupBarGraphWidget";
import TwoGroupBarGraphWidget from "../dashboard_widget_slideshow/widgets/TwoGroupBarGraphWidget";
import {
  getAccountUserDetailsApi,
  getWidgetDataApiv2,
  removeWidgetApiv2,
} from "../../Api";
import { CircularProgress } from "@material-ui/core";
import rootStore from "../../stores/RootStore";
import {
  canFetchWidgetData,
  getCombinedWidgetAndAccountID,
} from "../../Functions";
import { observer } from "mobx-react-lite";
import TopPerformerWidget from "../dashboard_widget_slideshow/widgets/TopPerformerWidget";
import CallActivityTabularWidget from "../dashboard_widget_slideshow/widgets/CallActivityTabularWidget";
import CheckinActivityTabularWidget from "../dashboard_widget_slideshow/widgets/CheckinActivityTabularWidget";
import CounterGridWidget from "../dashboard_widget_slideshow/widgets/CounterGridWidget";
import CounterWidget from "../dashboard_widget_slideshow/widgets/CounterWidget";

const WidgetWrapper = styled(Box)`
  height: 100%;
  width: 100%;
  background-color: white;
`;

const DashboardGridWidgets = ({
  widget,
  setRefreshWidgets,
  isDashboardEditable,
  type,
}) => {
  const [widgetData, setWidgetData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const fetchWidgetData = async () => {
    setWidgetData(null);
    try {
      let response_data = null;

      if (widget["client_widget"] === true) {
        if (widget["widget_type"] === "top_performer") {
          let response = await getAccountUserDetailsApi({
            userID: widget["input"]["user_id"],
          });
          response_data = response;
        }
      } else {
        let response = await getWidgetDataApiv2({ id: widget.id });
        response_data = response.data;
      }
      rootStore.userStore.updateWidgetData({
        id: getCombinedWidgetAndAccountID(widget.id),
        data: response_data,
      });
      setWidgetData(response_data);
    } catch (error) {
      console.log(error);
      rootStore.userStore.updateWidgetData({
        id: getCombinedWidgetAndAccountID(widget.id),
        data: null,
      });
    }
  };

  const init = async () => {
    setLoading(true);
    if (
      canFetchWidgetData({
        widgetID: widget["id"],
        frequencyString: widget["refresh_frequency"],
      })
    ) {
      await fetchWidgetData();
    } else {
      setWidgetData(
        rootStore.userStore.widgetData[
          getCombinedWidgetAndAccountID(widget.id)
        ]["data"]
      );
    }
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, [widget.id]);

  const refetch = async () => {
    setLoading(true);
    await fetchWidgetData();
    setLoading(false);
    setRefresh(false);
  };
  useEffect(() => {
    if (refresh) {
      refetch();
    }
  }, [refresh]);

  const handleRemove = async (widget) => {
    try {
      let response = await removeWidgetApiv2({ id: widget.id });
      setRefreshWidgets(true);
    } catch (error) {
      console.log(error);
    }
  };

  const RenderWidget = () => {
    if (loading) {
      <WidgetWrapper
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </WidgetWrapper>;
    } else {
      if (widget.version === 2) {
        if (widget["widget_type"] === "tabular") {
          if (widget["title"] === "Call Activity Of All Project Users") {
            return (
              <WidgetWrapper>
                <CallActivityTabularWidget
                  data={widgetData}
                  widgetConfig={widget}
                  handleRefresh={() => {
                    setRefresh(true);
                  }}
                  type={"grid"}
                  handleRemove={(widget) => {
                    handleRemove(widget);
                  }}
                  isDashboardEditable={isDashboardEditable}
                />
              </WidgetWrapper>
            );
          } else if (
            widget["title"] === "Checkin Activity Of All Project Users"
          ) {
            return (
              <WidgetWrapper>
                <CheckinActivityTabularWidget
                  data={widgetData}
                  widgetConfig={widget}
                  handleRefresh={() => {
                    setRefresh(true);
                  }}
                  type={"grid"}
                  handleRemove={(widget) => {
                    handleRemove(widget);
                  }}
                  isDashboardEditable={isDashboardEditable}
                />
              </WidgetWrapper>
            );
          } else {
            return (
              <WidgetWrapper>
                <TabularWidget
                  data={widgetData}
                  widgetConfig={widget}
                  handleRefresh={() => {
                    setRefresh(true);
                  }}
                  type={"grid"}
                  handleRemove={(widget) => {
                    handleRemove(widget);
                  }}
                  isDashboardEditable={isDashboardEditable}
                />
              </WidgetWrapper>
            );
          }
        } else if (widget["widget_type"] === "top_performer") {
          return (
            <WidgetWrapper>
              <TopPerformerWidget
                data={widgetData}
                widgetConfig={widget}
                handleRefresh={() => {
                  setRefresh(true);
                }}
                type={"grid"}
                handleRemove={(widget) => {
                  handleRemove(widget);
                }}
                isDashboardEditable={isDashboardEditable}
              />
            </WidgetWrapper>
          );
        } else if (widget["widget_type"] === "counter") {
          return (
            <WidgetWrapper>
              {type === "mainGrid" ? (
                <CounterWidget
                  data={widgetData}
                  widgetConfig={widget}
                  handleRefresh={() => {
                    setRefresh(true);
                  }}
                  type={"grid"}
                  handleRemove={(widget) => {
                    handleRemove(widget);
                  }}
                  isDashboardEditable={isDashboardEditable}
                />
              ) : (
                <CounterGridWidget
                  data={widgetData}
                  widgetConfig={widget}
                  handleRefresh={() => {
                    setRefresh(true);
                  }}
                  type={"grid"}
                  handleRemove={(widget) => {
                    handleRemove(widget);
                  }}
                  isDashboardEditable={isDashboardEditable}
                />
              )}
            </WidgetWrapper>
          );
        } else {
          let groups = widget["xdata"]["group_by"];
          let size = groups.length;
          if (size === 1) {
            return (
              <WidgetWrapper>
                <OneGroupBarGraphWidget
                  data={widgetData}
                  widgetConfig={widget}
                  handleRefresh={() => {
                    setRefresh(true);
                  }}
                  type={"grid"}
                  handleRemove={(widget) => {
                    handleRemove(widget);
                  }}
                  isDashboardEditable={isDashboardEditable}
                />
              </WidgetWrapper>
            );
          } else if (size === 2) {
            return (
              <WidgetWrapper>
                <TwoGroupBarGraphWidget
                  data={widgetData}
                  widgetConfig={widget}
                  handleRefresh={() => {
                    setRefresh(true);
                  }}
                  type={"grid"}
                  handleRemove={(widget) => {
                    handleRemove(widget);
                  }}
                  isDashboardEditable={isDashboardEditable}
                />
              </WidgetWrapper>
            );
          }
        }
      }
    }
  };

  return <RenderWidget />;
};

export default observer(DashboardGridWidgets);
