import React from "react";
import { styled } from "@mui/material/styles";
import { Typography, Box, Modal } from "@mui/material";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import {
  Chart,
  ArcElement,
  LinearScale,
  BarController,
  BarElement,
  Tooltip,
  Legend,
  CategoryScale,
} from "chart.js";
import { Close } from "@material-ui/icons";
Chart.register(
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels
);
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: auto;
  background-color: white;
  width: fit-content;
  height: fit-content;
  padding: 24px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const Wrapper = styled(Box)`
  ${`height: calc(100vh - 88px);`};
  ${`width: calc(100vw - 48px);`};
`;

const ViewBarGraphDialog = ({ open, setOpen, title, data }) => {
  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <Container>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  marginBottom: "16px",
                }}
              >
                <Header>{title}</Header>
                <Close
                  color="primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpen(false)}
                />
              </Box>
              {data !== null ? (
                <Wrapper>
                  <Bar
                    data={data}
                    options={{
                      scales: {
                        y: {
                          beginAtZero: true,
                          grace: "10%",
                        },
                      },
                      maintainAspectRatio: false,
                      responsive: true,
                      plugins: {
                        legend: {
                          display: false,
                        },
                        datalabels: {
                          offset: -2,
                          anchor: "end",
                          align: "top",
                          color: "#A5A8AC",
                          font: {
                            size: "14px",
                          },
                          formatter: (value, context) =>
                            context.chart.data.datasets[context.datasetIndex]
                              .data[context.dataIndex],
                        },
                      },
                    }}
                  />
                </Wrapper>
              ) : (
                <Wrapper
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography>No data found!</Typography>
                </Wrapper>
              )}
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default ViewBarGraphDialog;
