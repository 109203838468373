import React, { useEffect } from "react";
import { useState } from "react";

import styled from "styled-components";
import "../../project_tabs/tabs.css";
import { observer } from "mobx-react-lite";
import { getJobsApi, getRawLeadJobsApi } from "../../../Api";
import PaginationComponent from "../../pagination/PaginationComponent";
import { pageSize } from "../../../Config";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { RawLeadExportJobColumns } from "../../../Db";
import RawLeadJobRowComponent from "../../import_row_component/RawLeadJobRowComponent";
import LoadingAnimationComponent from "../../loading_component/LoadingAnimationComponent";

const TableWrapper = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
  overflow-x: auto;
  border: 2px solid #e0e0e0;
  background-color: white;
`;

const PaginationWrapper = styled.div`
  padding: 0px 20px;
`;
const EmptyTableWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const EmptyText = styled.p`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
  color: #4d4e4f;
  margin-top: 50px;
`;

const RawLeadExportsTab = (props) => {
  const [row, setRow] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(pageSize);
  const [loading, setLoading] = useState(true);

  const getJobsList = async ({ page, page_size }) => {
    setRow([]);
    setLoading(true);
    let response = await getRawLeadJobsApi({
      page: page,
      page_size: page_size,
    });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
      setCount(response.item_count);
    } else {
      setRow([]);
      setCount(0);
    }
    setLoading(false);
  };

  const init = async () => {
    await getJobsList({ page: 0, page_size: pageSize });
  };
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (props.refresh === true) {
      init();
      props.setRefresh(false);
    }
  }, [props.refresh]);

  const handlePageSize = async (e) => {
    setSize(e.target.value);
    setPage(0);
    await getJobsList({ page: 0, page_size: e.target.value });
  };
  const handlePagination = async (e, page) => {
    setPage(page - 1);
    await getJobsList({ page: page - 1, page_size: size });
  };
  return (
    <>
      <TableWrapper>
        {!loading ? (
          row.length > 0 ? (
            <TableContainer
              component={Paper}
              sx={{
                width: "100%",
                display: "table",
                tableLayout: "fixed",
              }}
            >
              <Table size="small" aria-label="a dense table">
                <TableHead
                  style={{ backgroundColor: "#EFEFF4", height: "60px" }}
                >
                  <TableRow>
                    {RawLeadExportJobColumns.map((e) => {
                      return (
                        <TableCell className="textContainer">
                          {e.headerName}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.map((e, i) => {
                    return <RawLeadJobRowComponent item={e} idx={i} />;
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <>
              <TableContainer
                component={Paper}
                style={{ width: "100%", overflowX: "auto" }}
              >
                <Table size="small" aria-label="a dense table">
                  <TableHead
                    style={{ backgroundColor: "#EFEFF4", height: "60px" }}
                  >
                    <TableRow>
                      {RawLeadExportJobColumns.map((e) => {
                        return (
                          <TableCell className="textContainer">
                            {e.headerName}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody></TableBody>
                </Table>
              </TableContainer>
              <EmptyTableWrapper>
                <EmptyText>No rows</EmptyText>
              </EmptyTableWrapper>
            </>
          )
        ) : (
          <EmptyTableWrapper style={{ paddingTop: "50px" }}>
            <LoadingAnimationComponent size={"small"} />
          </EmptyTableWrapper>
        )}
      </TableWrapper>
      <PaginationWrapper>
        <PaginationComponent
          page_no={page}
          row={row}
          page_size={size}
          size={size}
          count={count}
          handlePageSize={handlePageSize}
          handlePage={handlePagination}
        />
      </PaginationWrapper>
    </>
  );
};

export default observer(RawLeadExportsTab);
