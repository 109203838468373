import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Button } from "@material-ui/core";
import {
  AppBar,
  Box,
  CircularProgress,
  Toolbar,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {
  createLeadQuotationApi,
  editLeadQuotationApi,
  getQuotationNumberApi,
  getQuoteTemplateListApi,
  reviseLeadQuotationApi,
} from "../../Api";
import QuoteAndProuductInfo from "./screens/QuoteAndProuductInfo";
import AddProductModal from "./dialogs/AddProductModal";
import EditProductModal from "./dialogs/EditProductModal";
import ViewProductModal from "./dialogs/ViewProductModal";
import BillingInfo from "./screens/BillingInfo";
import AdditionalInfo from "./screens/AdditionalInfo";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { embedVariableValuesInMessageString } from "../../Functions";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const WarningAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="warning"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  ${`height: calc(100vh - 128px);`};
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: flex-end;
  column-gap: 10px;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  padding: 24px;
  gap: 20px;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0px;
  }
`;

const AddQuoteSidePanel = ({
  setOpen,
  activeDealDetails,
  leadID,
  leadData,
  setRefresh,
  existingQuoteData,
  scope,
  setQuoteScope,
}) => {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [details, setDetails] = useState({
    title: "",
    quote_date: null,
    expiry_date: null,
  });
  const [openAddProductModal, setOpenAddProductModal] = useState(false);
  const [openEditProductModal, setOpenEditProductModal] = useState(false);
  const [openViewProductModal, setOpenViewProductModal] = useState(false);
  const [selectedProductData, setSelectedProductData] = useState({});
  const [productAddedList, setProductAddedList] = useState([]);
  const [quoteList, setQuoteList] = useState([]);
  const [selectedQuoteID, setSelectedQuoteID] = useState("");
  const [loading, setLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [quotationNumber, setQuotationNumber] = useState("");
  const [amountDetails, setAmountDetails] = useState({
    sub_total: "",
    additional_discount: "",
    additional_tax: "",
    adjustments: "",
    grand_total: "",
  });
  const [billingDetails, setBillingDetails] = useState({
    billing_name: "",
    billing_address: "",
    billing_city: "",
    billing_state: "",
    billing_country: "",
    billing_zipcode: "",
    billing_gst: "",
    shipping_name: "",
    shipping_address: "",
    shipping_city: "",
    shipping_state: "",
    shipping_country: "",
    shipping_zipcode: "",
  });
  const [additionalDetails, setAdditionalDetails] = useState({
    terms_and_conditions: "",
    additional_information: "",
  });
  const [placeOfSupply, setPlaceOfSupply] = useState("");
  const [showShippingAddress, setShowShippingAddress] = useState(false);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [showAdditionalInformation, setShowAdditionalInformation] =
    useState(false);

  //tax reset handling on place_of_supply change
  const [showItemTaxWarning, setShowItemTaxWarning] = useState(false);
  const [itemTaxFlag, setItemTaxFlag] = useState(false);
  const [totalItemsReset, setTotalItemsReset] = useState(0);

  const isIGST = () => {
    let index = quoteList.findIndex((quote) => quote["id"] === selectedQuoteID);
    if (index !== -1) {
      let quoteData = quoteList[index]["schema"];
      if (quoteData.hasOwnProperty("company_details")) {
        if (quoteData["company_details"].hasOwnProperty("company_state")) {
          if (quoteData["company_details"]["company_state"] === placeOfSupply) {
            return false;
          } else {
            return true;
          }
        }
      }
    }
    return false;
  };

  const handleClose = () => {
    setOpen(false);
    setQuoteScope("");
  };
  const getValidIntegerValue = (value) => {
    if (value !== "" && value !== null) {
      return parseInt(value);
    }
    return "";
  };
  const getValidFloatValue = (value) => {
    if (value !== "" && value !== null) {
      const floatValue = parseFloat(value);
      return floatValue.toFixed(2);
    }
    return "";
  };

  const getCompanyDetails = () => {
    let index = quoteList.findIndex((quote) => quote["id"] === selectedQuoteID);
    return quoteList[index]["schema"]["company_details"];
  };

  const createQuote = async (payload) => {
    let response = await createLeadQuotationApi({
      payload: payload,
      leadID: leadID,
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };

  const editQuote = async (payload) => {
    let response = await editLeadQuotationApi({
      payload: payload,
      leadID: leadID,
      quoteID: existingQuoteData["id"],
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };

  const handleSubmit = async () => {
    let formData = {
      quote_date: details.quote_date,
      expiry_date: details.expiry_date,
      quotation_template_id: selectedQuoteID,
    };
    if (activeDealDetails.hasOwnProperty("id")) {
      formData["deal_id"] = activeDealDetails["id"];
    }
    let tempQuotationSchema = {
      quote_title: details.title,
    };
    let itemList = [];

    productAddedList.forEach((productItem) => {
      let tempObj = {
        rate: getValidIntegerValue(productItem["rate"]),
        amount: getValidFloatValue(productItem["amount"]),
        quantity: getValidIntegerValue(productItem["quantity"]),
        discount: getValidIntegerValue(productItem["discount"]),
        tax: getValidIntegerValue(productItem["tax"]),
        desc: productItem["desc"],
      };
      tempObj["name"] = productItem["item"]["name"];
      itemList.push(tempObj);
    });
    tempQuotationSchema["items"] = itemList;
    let tempAmountDetails = {};
    Object.keys(amountDetails).forEach((key) => {
      tempAmountDetails[key] = getValidFloatValue(amountDetails[key]);
    });
    tempQuotationSchema["amount_details"] = tempAmountDetails;
    tempQuotationSchema["billing_details"] = billingDetails;

    tempQuotationSchema["additional_details"] = additionalDetails;
    tempQuotationSchema["company_details"] = getCompanyDetails();
    tempQuotationSchema["place_of_supply"] = placeOfSupply;
    formData["quote_schema"] = tempQuotationSchema;
    formData["amount"] = parseFloat(tempAmountDetails["grand_total"]);
    if (scope === "edit" || scope === "revise" || scope === "to_invoice") {
      await editQuote(formData);
    } else {
      await createQuote(formData);
    }
  };
  const getQuotationList = async () => {
    let response = await getQuoteTemplateListApi();
    setQuoteList(response);
    if (scope === "edit" || scope === "revise" || scope === "to_invoice") {
      let index = response.findIndex(
        (quote) => quote["id"] === existingQuoteData["quotation_template_id"]
      );
      if (index !== -1) {
        let quoteData = response[index]["schema"];
        setShowShippingAddress(
          quoteData["billing_details"]["show_shipping_address"]
        );
        setShowAdditionalInformation(
          quoteData["additional_details"]["show_additional_information"]
        );
        setShowTermsAndConditions(
          quoteData["additional_details"]["show_terms_and_conditions"]
        );
      }
    }
  };

  const getQuotationNumber = async () => {
    let response = await getQuotationNumberApi();
    if (response.hasOwnProperty("quotation_number")) {
      setQuotationNumber(response["quotation_number"]);
    }
  };
  const initBillingSectionPrefill = (selectedTemplateSchema) => {
    setBillingDetails({
      billing_name: "",
      billing_address: "",
      billing_city: "",
      billing_state: "",
      billing_country: "",
      billing_zipcode: "",
      billing_gst: "",
      shipping_name: "",
      shipping_address: "",
      shipping_city: "",
      shipping_state: "",
      shipping_country: "",
      shipping_zipcode: "",
    });
    let tempBillingDetails = {};
    for (var key in selectedTemplateSchema["address_variables"]) {
      if (selectedTemplateSchema["address_variables"][key] != "") {
        tempBillingDetails[key] = "";
        const value = embedVariableValuesInMessageString({
          messageString: selectedTemplateSchema["address_variables"][key],
          leadData: leadData,
        });
        tempBillingDetails[key] = value;
      }
    }
    setBillingDetails(tempBillingDetails);
  };

  const initCreateData = async () => {
    await getQuotationList();
    await getQuotationNumber();
    let tempObj = { ...details };
    tempObj["quote_date"] = moment().local();
    setDetails(tempObj);
    let itemsList = checkAndSetupActiveDealItems();
    setProductAddedList(itemsList);
    updateCalculatedAmount(itemsList);
  };

  const checkAndSetupActiveDealItems = () => {
    let newList = [];
    if (
      activeDealDetails.hasOwnProperty("deal_items") &&
      activeDealDetails["deal_items"] !== null
    ) {
      activeDealDetails["deal_items"].forEach((product) => {
        let tempObj = { desc: "" };
        tempObj["tax"] = product["tax"];
        tempObj["discount"] = product["discount"];
        tempObj["rate"] = product["rate"];
        tempObj["amount"] = product["amount"];
        tempObj["quantity"] = product["quantity"];
        tempObj["item"] = {
          id: uuidv4(),
        };
        if (product["item"] !== null) {
          tempObj["item"]["name"] = product["item"];
        } else {
          tempObj["item"]["name"] = product["product"]["name"];
        }
        tempObj["discount"] = product.hasOwnProperty("discount")
          ? product["discount"]
          : "";
        newList.push(tempObj);
      });
    }
    return newList;
  };

  const initEditableData = async () => {
    await getQuotationList();
    setQuotationNumber(existingQuoteData["quote_number"]);
    setSelectedQuoteID(existingQuoteData["quotation_template_id"]);
    setBillingDetails(existingQuoteData["quote_schema"]["billing_details"]);
    let updatedAmountDetails = updateTotalAmount({
      type: "init",
      amountDetails: existingQuoteData["quote_schema"]["amount_details"],
    });
    setAmountDetails(updatedAmountDetails);
    setAdditionalDetails(
      existingQuoteData["quote_schema"]["additional_details"]
    );
    let tempObj = {
      title: existingQuoteData["quote_schema"]["quote_title"],
      quote_date:
        existingQuoteData["quote_date"] !== null &&
        existingQuoteData["quote_date"] !== ""
          ? existingQuoteData["quote_date"]
          : null,
      expiry_date:
        existingQuoteData["expiry_date"] !== null &&
        existingQuoteData["expiry_date"] !== ""
          ? existingQuoteData["expiry_date"]
          : null,
    };
    setDetails(tempObj);
    setPlaceOfSupply(
      existingQuoteData["quote_schema"]["place_of_supply"] !== null &&
        existingQuoteData["quote_schema"]["place_of_supply"] !== ""
        ? existingQuoteData["quote_schema"]["place_of_supply"]
        : null
    );
    let tempList = [...existingQuoteData["quote_schema"]["items"]];
    tempList.forEach((item) => {
      item["item"] = { name: item["name"], id: uuidv4() };
      item["desc"] =
        item.hasOwnProperty("desc") && item["desc"] !== null
          ? item["desc"]
          : "";
    });

    setProductAddedList(tempList);
  };

  const checkAndIntegrateTemplateItems = ({
    templateData,
    productAddedList,
  }) => {
    if (
      templateData.schema.hasOwnProperty("items") &&
      templateData.schema["items"] !== null &&
      templateData.schema["items"].length > 0
    ) {
      let tempList = [...productAddedList];
      let templateItemList = [];

      templateData.schema["items"].forEach((item) => {
        let tempObj = {};
        tempObj["tax"] = item["tax"];
        tempObj["discount"] = item["discount"];
        tempObj["rate"] = item["rate"];
        tempObj["amount"] = item["amount"];
        tempObj["quantity"] = item["quantity"];
        tempObj["item"] = {
          name: item["name"],
          id: uuidv4(),
        };
        tempObj["desc"] =
          item.hasOwnProperty("desc") && item["desc"] !== null
            ? item["desc"]
            : "";
        templateItemList.push(tempObj);
      });
      tempList = tempList.concat(templateItemList);
      setProductAddedList(tempList);
      updateCalculatedAmount(tempList);
    }
  };

  const setup = async () => {
    if (scope === "edit" || scope === "revise" || scope === "to_invoice") {
      await initEditableData();
    } else {
      await initCreateData();
    }
    setLoading(false);
  };

  useEffect(() => {
    setup();
  }, []);

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const updateCalculatedAmount = (itemList) => {
    let tempObj = { ...amountDetails };
    let amount = 0;
    itemList.forEach((item) => {
      amount += parseFloat(item["amount"]);
    });
    tempObj["sub_total"] = amount.toFixed(2);

    let totalAmount = amount;
    let taxedAmount =
      amountDetails["additional_tax"] !== null &&
      amountDetails["additional_tax"] !== ""
        ? parseFloat(amountDetails["additional_tax"])
        : 0;
    let discountedAmount =
      amountDetails["additional_discount"] !== null &&
      amountDetails["additional_discount"] !== ""
        ? parseFloat(amountDetails["additional_discount"])
        : 0;

    totalAmount = totalAmount + taxedAmount;
    totalAmount = totalAmount - discountedAmount;
    //calc difference to auto adjust
    let roundedDifference = parseFloat(
      (Math.round(totalAmount) - totalAmount).toFixed(2)
    );
    tempObj["adjustments"] = roundedDifference;

    tempObj["grand_total"] = parseFloat(
      totalAmount + roundedDifference
    ).toFixed(2);

    setAmountDetails(tempObj);
  };

  const updateTotalAmount = ({ type, amountDetails }) => {
    let totalAmount = parseFloat(amountDetails["sub_total"]);
    let taxedAmount =
      amountDetails["additional_tax"] !== null &&
      amountDetails["additional_tax"] !== ""
        ? parseFloat(amountDetails["additional_tax"])
        : 0;
    let discountedAmount =
      amountDetails["additional_discount"] !== null &&
      amountDetails["additional_discount"] !== ""
        ? parseFloat(amountDetails["additional_discount"])
        : 0;
    let adjustmentAmount =
      amountDetails["adjustments"] !== null &&
      amountDetails["adjustments"] !== ""
        ? parseFloat(amountDetails["adjustments"])
        : 0;

    totalAmount = totalAmount + taxedAmount;
    totalAmount = totalAmount - discountedAmount;

    let roundedDifference = 0;
    if (type === "adjustments" || type === "init") {
      //using entered adjustment amount if it was init or from text field
      totalAmount = totalAmount + adjustmentAmount;
    } else {
      // done only if not changing adjustments directly or not init
      //calc difference to auto adjust
      roundedDifference = parseFloat(
        (Math.round(totalAmount) - totalAmount).toFixed(2)
      );
      amountDetails["adjustments"] = roundedDifference;
    }

    amountDetails["grand_total"] = parseFloat(
      totalAmount + roundedDifference
    ).toFixed(2);
    return amountDetails;
  };

  const canShowFormStepWithTermsAndAdditionalInfo = () => {
    return (
      showTermsAndConditions === true || showAdditionalInformation === true
    );
  };

  const RenderButton = () => {
    if (activeStep === 0) {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "20px",
            width: "fit-content",
          }}
        >
          <Button
            type="button"
            onClick={handleClose}
            variant="outlined"
            color="primary"
            style={{
              textTransform: "none",
              width: "120px",
              fontWeight: "bold",
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={selectedQuoteID === ""}
            type="submit"
            variant="contained"
            color="primary"
            style={{
              textTransform: "none",
              width: "120px",
              fontWeight: "bold",
            }}
          >
            Next
          </Button>
        </Box>
      );
    } else if (activeStep === 1) {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "20px",
            width: "fit-content",
          }}
        >
          <Button
            onClick={handlePrevious}
            type="button"
            variant="outlined"
            color="primary"
            style={{
              textTransform: "none",
              width: "120px",
              fontWeight: "bold",
            }}
          >
            Prev
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{
              textTransform: "none",
              width: "120px",
              fontWeight: "bold",
            }}
          >
            {canShowFormStepWithTermsAndAdditionalInfo() ? "Next" : "Submit"}
          </Button>
        </Box>
      );
    } else if (activeStep === 2) {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "20px",
            width: "fit-content",
          }}
        >
          <Button
            onClick={handlePrevious}
            type="button"
            variant="outlined"
            color="primary"
            style={{
              textTransform: "none",
              width: "120px",
              fontWeight: "bold",
            }}
          >
            Prev
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{
              textTransform: "none",
              width: "120px",
              fontWeight: "bold",
            }}
          >
            Submit
          </Button>
        </Box>
      );
    }
  };

  const getHeaderText = () => {
    if (scope === "edit") {
      return "Edit Quote";
    } else if (scope === "revise") {
      return "Revise Quote";
    } else if (scope === "to_invoice") {
      return "Edit Invoice";
    } else {
      return "Create Quote";
    }
  };
  const getSuccessMessageText = () => {
    if (scope === "edit") {
      return "Quote edited successfully!";
    } else if (scope === "revise") {
      return "Quote revised successfully!";
    } else if (scope === "to_invoice") {
      return "Invoice edited successfully!";
    } else {
      return "Quote created successfully!";
    }
  };

  const removeTemplateItemsFromList = ({ templateData }) => {
    let newList = [];
    if (
      templateData.schema.hasOwnProperty("items") &&
      templateData.schema["items"] !== null &&
      templateData.schema["items"].length > 0
    ) {
      productAddedList.forEach((item) => {
        let index = templateData.schema["items"].findIndex(
          (obj) => obj["name"] === item["item"]["name"]
        );
        if (index === -1) {
          newList.push(item);
        }
      });
    }
    return newList;
  };

  return !loading ? (
    <>
      <Box role="presentation">
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: 500,
            right: 0,
            bgcolor: "#f9f9fc",
          }}
        >
          <Toolbar>
            <ModelHeader>{getHeaderText()}</ModelHeader>
          </Toolbar>
        </AppBar>
        {!isSubmitSuccess ? (
          <ModelWrapper
            component={"form"}
            onSubmit={(e) => {
              e.preventDefault();
              const maxStepIndex = canShowFormStepWithTermsAndAdditionalInfo()
                ? 2
                : 1;
              if (activeStep < maxStepIndex) {
                if (itemTaxFlag) {
                  setShowItemTaxWarning(true);
                } else {
                  handleNext();
                }
              } else {
                handleSubmit();
              }
            }}
          >
            <InputContainer>
              {(() => {
                if (activeStep === 0) {
                  return (
                    <QuoteAndProuductInfo
                      details={details}
                      isFormDisabled={selectedQuoteID === ""}
                      productAddedList={productAddedList}
                      placeOfSupply={placeOfSupply}
                      setOpenAddProductModal={setOpenAddProductModal}
                      setOpenEditProductModal={setOpenEditProductModal}
                      setOpenViewProductModal={setOpenViewProductModal}
                      setSelectedProductData={setSelectedProductData}
                      handleAmountDetails={(event) => {
                        let tempObj = {
                          ...amountDetails,
                          [event.target.name]: event.target.value,
                        };
                        let updatedAmountDetails = updateTotalAmount({
                          type: event.target.name,
                          amountDetails: tempObj,
                        });

                        setAmountDetails(updatedAmountDetails);
                      }}
                      handleChange={(name, value) => {
                        setDetails({ ...details, [name]: value });
                      }}
                      handlePlaceOfSupply={(value) => {
                        setPlaceOfSupply(value);
                        //reset tax value for all items and set flag
                        if (productAddedList.length !== 0) {
                          let newProductList = [...productAddedList];
                          newProductList = newProductList.map((item) => {
                            let discount =
                              item.hasOwnProperty("discount") &&
                              item["discount"] !== null &&
                              item["discount"] !== undefined &&
                              item["discount"] !== ""
                                ? parseInt(item["discount"])
                                : 0;
                            let rate =
                              item.hasOwnProperty("rate") &&
                              item["rate"] !== null &&
                              item["rate"] !== undefined &&
                              item["rate"] !== ""
                                ? parseInt(item["rate"])
                                : 0;
                            let quantity =
                              item.hasOwnProperty("quantity") &&
                              item["quantity"] !== null &&
                              item["quantity"] !== undefined &&
                              item["quantity"] !== ""
                                ? parseInt(item["quantity"])
                                : 0;
                            let amount = rate * quantity;

                            let discountedAmount = (amount * discount) / 100;
                            amount = (amount - discountedAmount).toFixed(2);

                            item["tax"] = "";
                            item["amount"] = amount;
                            return item;
                          });
                          setProductAddedList(newProductList);
                          setItemTaxFlag(true);
                        }
                      }}
                      amountDetails={amountDetails}
                      handleQuote={(quoteID) => {
                        setSelectedQuoteID(quoteID);
                        let index = quoteList.findIndex(
                          (quote) => quote["id"] === quoteID
                        );
                        let quoteData = quoteList[index]["schema"];
                        let tempItemList = checkAndSetupActiveDealItems();
                        setProductAddedList(tempItemList);
                        updateCalculatedAmount(tempItemList);
                        checkAndIntegrateTemplateItems({
                          templateData: quoteList[index],
                          productAddedList: tempItemList,
                        });

                        //check if all items have tax values and set flag
                        if (
                          tempItemList.every(
                            (x) =>
                              x.tax !== "" &&
                              x.tax !== null &&
                              x.tax !== undefined
                          )
                        ) {
                          setItemTaxFlag(false);
                        } else {
                          setItemTaxFlag(true);
                        }

                        //prefill subject
                        const subject =
                          quoteData["subject"] !== null &&
                          quoteData["subject"] !== undefined
                            ? quoteData["subject"]
                            : "";
                        setDetails({ ...details, title: subject });

                        //set prefill if available in selected template
                        if (
                          quoteList[index]["schema"].hasOwnProperty(
                            "address_variables"
                          )
                        ) {
                          initBillingSectionPrefill(quoteData);
                          //else reset
                        } else {
                          setBillingDetails({
                            billing_name: "",
                            billing_address: "",
                            billing_city: "",
                            billing_state: "",
                            billing_country: "",
                            billing_zipcode: "",
                            billing_gst: "",
                            shipping_name: "",
                            shipping_address: "",
                            shipping_city: "",
                            shipping_state: "",
                            shipping_country: "",
                            shipping_zipcode: "",
                          });
                        }
                        setShowShippingAddress(
                          quoteData["billing_details"]["show_shipping_address"]
                        );
                        setShowAdditionalInformation(
                          quoteData["additional_details"][
                            "show_additional_information"
                          ]
                        );
                        setShowTermsAndConditions(
                          quoteData["additional_details"][
                            "show_terms_and_conditions"
                          ]
                        );

                        let tempAdditionalDetails = {
                          terms_and_conditions: "",
                          additional_information: "",
                        };
                        if (
                          quoteData["additional_details"][
                            "show_terms_and_conditions"
                          ]
                        ) {
                          tempAdditionalDetails["terms_and_conditions"] =
                            quoteData["additional_details"][
                              "terms_and_conditions"
                            ];
                        }
                        if (
                          quoteData["additional_details"][
                            "show_additional_information"
                          ]
                        ) {
                          tempAdditionalDetails["additional_information"] =
                            quoteData["additional_details"][
                              "additional_information"
                            ];
                        }
                        setAdditionalDetails(tempAdditionalDetails);
                      }}
                      quoteList={quoteList}
                      selectedQuoteID={selectedQuoteID}
                      quotationNumber={quotationNumber}
                      scope={scope}
                    />
                  );
                } else if (activeStep === 1) {
                  return (
                    <BillingInfo
                      billingDetails={billingDetails}
                      handleChange={(name, value) => {
                        setBillingDetails({ ...billingDetails, [name]: value });
                      }}
                      showShippingAddress={showShippingAddress}
                    />
                  );
                } else if (activeStep === 2) {
                  return (
                    <AdditionalInfo
                      additionalInformation={additionalDetails}
                      handleChange={(name, value) => {
                        setAdditionalDetails({
                          ...additionalDetails,
                          [name]: value,
                        });
                      }}
                      showAdditionalInformation={showAdditionalInformation}
                      showTermsAndConditions={showTermsAndConditions}
                    />
                  );
                }
              })()}
            </InputContainer>

            <AppBar
              position="fixed"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: 500, bgcolor: "#f9f9fc" }}
            >
              <Toolbar style={{ width: 500, position: "relative" }}>
                {isSubmitFail && (
                  <ErrorAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setIsSubmitFail(false);
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {errorMessage}
                  </ErrorAlert>
                )}
                {showItemTaxWarning && (
                  <WarningAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setShowItemTaxWarning(false);
                    }}
                    severity="warning"
                    sx={{ width: "100%" }}
                  >
                    Please update tax for all items before proceeding.
                  </WarningAlert>
                )}
                <ButtonWrapper>
                  <RenderButton />
                </ButtonWrapper>
              </Toolbar>
            </AppBar>
          </ModelWrapper>
        ) : (
          <MessageWrapper>
            <Message>{getSuccessMessageText()}</Message>
            <Button
              onClick={handleClose}
              type="button"
              variant="contained"
              color="default"
              style={{
                marginTop: "20px",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              Close
            </Button>
          </MessageWrapper>
        )}
      </Box>
      {openAddProductModal && (
        <AddProductModal
          open={openAddProductModal}
          setOpen={setOpenAddProductModal}
          productAddedList={productAddedList}
          setProductAddedList={setProductAddedList}
          updateCalculatedAmount={(productList) =>
            updateCalculatedAmount(productList)
          }
          igst={isIGST()}
        />
      )}
      {openEditProductModal && (
        <EditProductModal
          open={openEditProductModal}
          setOpen={setOpenEditProductModal}
          productAddedList={productAddedList}
          setProductAddedList={setProductAddedList}
          editableProduct={selectedProductData}
          setEditableProduct={setSelectedProductData}
          updateCalculatedAmount={(productList) =>
            updateCalculatedAmount(productList)
          }
          igst={isIGST()}
          setTotalItemsReset={setTotalItemsReset}
          totalItemsReset={totalItemsReset}
          itemTaxFlag={itemTaxFlag}
          setItemTaxFlag={setItemTaxFlag}
          setShowItemTaxWarning={setShowItemTaxWarning}
        />
      )}
      {openViewProductModal && (
        <ViewProductModal
          open={openViewProductModal}
          setOpen={setOpenViewProductModal}
          data={selectedProductData}
          setData={setSelectedProductData}
        />
      )}
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: 500,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default AddQuoteSidePanel;
