import { FormControl, Select, makeStyles } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled.span`
  font: normal normal normal 10px Open Sans;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const SingleSelectNew = (props) => {
  const classes = useStyles();
  const [list, setList] = useState([]);
  const [listType, setListType] = useState(null);

  useEffect(() => {
    try {
      let parseJSON = JSON.parse(props.list);
      if (Array.isArray(parseJSON)) {
        setListType("list");
        setList(parseJSON);
      } else if (typeof parseJSON === "object") {
        if (Object.keys(parseJSON).length > 0) {
          setListType(parseJSON["type"]);
          setList(parseJSON["values"]);
        }
      }
    } catch (error) {
      if (props.list.length > 0) {
        let val = props.list.split(",");
        setListType("list");
        setList(val);
      }
    }
  }, [props.item.id]);
  return (
    <>
      {list.length > 0 && (
        <FormControl
          sx={{ width: "100%" }}
          classes={{
            root: classes.quantityRoot,
          }}
        >
          <Select
            classes={{
              icon: classes.icon,
            }}
            disabled={props.disabled}
            displayEmpty
            name={props.field}
            variant="outlined"
            onChange={(e) => props.handleChange(e)}
            style={{
              width: "100%",
              fontSize: "12px",
              height: "30px",
            }}
            value={
              props.selectedVal.hasOwnProperty(props.field)
                ? props.selectedVal[props.field]["value"]
                : ""
            }
            required={props.required}
          >
            {list.map((item, index) => {
              if (listType !== null) {
                if (listType === "list") {
                  return (
                    <MenuItem id={index} key={index} value={item}>
                      {item}
                    </MenuItem>
                  );
                } else if (listType === "list_objects") {
                  return (
                    <MenuItem id={index} key={index} value={item.value}>
                      {item.name}
                    </MenuItem>
                  );
                }
              }
            })}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default SingleSelectNew;
