import React, { useEffect, useState } from "react";
import styled from "styled-components";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import spinner from "../../assets/images/gifs/spinner.gif";
import { CheckInColumns } from "../../Db";
import { DataGrid } from "@mui/x-data-grid";
import { pageSize } from "../../Config";
import Pagination from "../pagination/Pagination";
import { getLeadCallsApi, getLeadCheckInsApi, getStatusApi } from "../../Api";
import PageSizeDropdown from "../page_size_dropdown/PageSizeDropdown";
import PaginationComponent from "../pagination/PaginationComponent";
import ViewCallLogDetails from "../call_log_modal/ViewCallLogDetails";
import ViewCheckInModal from "../check_in_modal/ViewCheckInModal";
import { Drawer } from "@material-ui/core";
import analytics from "../../Analytics";

const Container = styled.div`
  width: 100%;
  position: relative;
  ${`min-height: calc(100vh - 234px);`};
`;
const Wrapper = styled.div`
  display: flex;
`;

const DetailsWrapper = styled.div`
  height: fit-content;
  width: 100%;
  background: white;
  border-radius: 10px;
  /* padding: 12px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;

const Spinner = styled.img`
  height: 120px;
  width: 180px;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
  width: 100%;
  ${`min-height: calc(100vh - 148px);`};
`;

const AddBtnWrapper = styled.div`
  position: absolute;
  right: 0px;
  top: 9px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
  @media (max-width: 700px) {
    position: unset;
    margin-left: 12px;
  }
`;
const TableWrapper = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
`;
const UserCheckInTab = ({ uid, lid }) => {
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState();
  const [row, setRow] = useState();
  const [currData, setCurrData] = useState(null);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [viewLog, setViewLog] = useState(false);
  const [size, setSize] = useState(pageSize);

  useEffect(() => {
    setRow();
    setLoading(true);
    const getLeadCallRecords = async () => {
      let response = await getLeadCheckInsApi({
        id: lid,
      });
      setRow(response.data);
      // setCount(response.data.item_count);
      setLoading(false);
    };
    getLeadCallRecords();
  }, [refresh]);
  // const handlePageSize = async (e) => {
  //   setSize(e.target.value);
  //   setRow();
  //   setPage(0);
  //   setLoading(true);
  //   let response = await getLeadCheckInsApi({
  //     id: lid,
  //     page: 0,
  //     page_size: e.target.value,
  //   });
  //   setRow(response.data.items);
  //   setCount(response.data.item_count);
  //   setLoading(false);
  // };
  // const handlePagination = async (e, page) => {
  //   setRow();
  //   setPage(page - 1);
  //   setLoading(true);
  //   let response = await getLeadCheckInsApi({
  //     id: lid,
  //     page: page - 1,
  //     page_size: size,
  //   });
  //   setRow(response.data.items);
  //   setCount(response.data.item_count);
  //   setLoading(false);
  // };
  useEffect(() => {
    analytics.triggerEvent(
      4625000054,
      "checkins_tab_load",
      "lead_details_page",
      "",
      {}
    );
  }, []);
  return (
    <>
      {row ? (
        <Container>
          <Wrapper>
            <DetailsWrapper>
              <TableWrapper>
                <DataGrid
                  autoHeight={true}
                  rows={row}
                  columns={CheckInColumns}
                  pageSize={size}
                  checkboxSelection={false}
                  style={{ cursor: "pointer" }}
                  hideFooter={true}
                  loading={loading}
                  disableColumnMenu={true}
                  disableSelectionOnClick={true}
                  onRowClick={(params) => {
                    setCurrData(params.row);
                    setViewLog(true);
                  }}
                />
              </TableWrapper>
              {/* <PaginationComponent
                page_no={page}
                row={row}
                page_size={size}
                size={size}
                count={count}
                handlePageSize={handlePageSize}
                handlePage={handlePagination}
              /> */}
            </DetailsWrapper>
          </Wrapper>
        </Container>
      ) : (
        <SpinnerWrapper>
          <Spinner src={spinner} alt="loading..." />
        </SpinnerWrapper>
      )}

      <Drawer
        anchor={"right"}
        open={viewLog}
        onClose={() => {
          setViewLog(false);
        }}
      >
        {<ViewCheckInModal data={currData} setData={setCurrData} />}
      </Drawer>
    </>
  );
};

export default observer(UserCheckInTab);
