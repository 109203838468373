import React, { useEffect, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import { Box, Tab, Tabs, withStyles } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import rootStore from "../../stores/RootStore";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import BasicInfoTab from "../../components/organization_page_components/BasicInfoTab";
import OrganizationContactsTab from "../../components/organization_page_components/OrganizationContactsTab";
const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 84px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;
const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CustomTab = withStyles({
  root: {
    backgroundColor: "white",
    color: "#4D4E4F",
    font: "normal normal 600 14px Open Sans",
    textTransform: "capitalize",
  },
  selected: {
    backgroundColor: "#E7EEFA",
    color: "#4079DA",
    font: "normal normal 600 14px Open Sans",
    textTransform: "capitalize",
  },
})(Tab);

const ContactsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    rootStore.authStore.updateLeadDetailStatus();
  }, []);

  return (
    <>
      <ParentContainer>
        <Container>
          <Row>
            <TopWrapper>
              <Header
                style={{ color: "#185DD2", cursor: "pointer" }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Organizations
              </Header>
              <ChevronRight style={{ width: "16px", height: "16px" }} />
              <Header>{id}</Header>
            </TopWrapper>
          </Row>
          <Tabs
            value={tabIndex}
            textColor="primary"
            indicatorColor="primary"
            onChange={(event, newValue) => {
              setTabIndex(newValue);
            }}
          >
            <CustomTab label={"Basic Information"} />
            <CustomTab label={"Contacts"} />
          </Tabs>
          {(() => {
            if (tabIndex === 0) {
              return <BasicInfoTab id={id} />;
            } else if (tabIndex === 1) {
              return <OrganizationContactsTab id={id} />;
            }
          })()}
        </Container>
      </ParentContainer>
      <Footer />
    </>
  );
};

export default observer(ContactsPage);
