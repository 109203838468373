import React, { useMemo } from "react";
import { styled } from "@mui/material/styles";
import { AppBar, Box, Chip, Toolbar, Tooltip, Typography } from "@mui/material";
import { Grid } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import {
  dataRenderer,
  getLocalizedText,
  IsoToLocalTime,
} from "../../../Functions";

const ModelWrapper = styled(Box)`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 24px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 8px;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const SectionHeader = styled(Typography)`
  font: normal normal 600 16px Open Sans;
  color: #979797;
  margin-bottom: 16px;
`;

const ViewAdminStageDirectiveSidepanel = ({ details }) => {
  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const columns = [
    {
      field: "column",
      headerName: "COLUMN",
      minWidth: 200,
      cellRenderer: (params) => {
        let value = dataRenderer(params.data.column_meta.name);
        return (
          <Tooltip>
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "overwrite",
      headerName: "OVERWRITE",
      width: 120,

      cellRenderer: (params) => {
        if (params.data.overwrite === null) {
          return "-";
        } else {
          if (params.data.overwrite === true) {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="success"
                label={"Yes"}
              />
            );
          } else {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="warning"
                label={"No"}
              />
            );
          }
        }
      },
    },
    {
      field: "append",
      headerName: "APPEND",
      width: 100,

      cellRenderer: (params) => {
        if (
          params.data.hasOwnProperty("append") &&
          params.data.append !== null
        ) {
          if (params.data.append === true) {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="success"
                label={"Yes"}
              />
            );
          } else {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="warning"
                label={"No"}
              />
            );
          }
        } else {
          return "-";
        }
      },
    },
    {
      field: "required",
      headerName: "REQUIRED",
      width: 120,
      cellRenderer: (params) => {
        if (params.data.required === null) {
          return "-";
        } else {
          if (params.data.required === true) {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="success"
                label={"Yes"}
              />
            );
          } else {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="warning"
                label={"No"}
              />
            );
          }
        }
      },
    },
  ];

  return (
    <Box sx={{ width: 500 }}>
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          width: 500,
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <ModelHeader>Details</ModelHeader>
          </Box>
        </Toolbar>
      </AppBar>
      <ModelWrapper>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Stage</Label>
              <Detail>
                {details["lead_stage"] !== null
                  ? dataRenderer(details["lead_stage"]["stage"])
                  : "-"}
              </Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>On Status</Label>
              <Detail>
                {details["lead_status"] !== null
                  ? dataRenderer(details["lead_status"]["status"])
                  : "-"}
              </Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Jump To Stage</Label>
              <Detail>
                {details["jump_to"] !== null
                  ? dataRenderer(details["jump_to"]["stage"])
                  : "-"}
              </Detail>
            </InputWrapper>
          </Grid>

          <Grid item xs={6}>
            <InputWrapper>
              <Label>{getLocalizedText("created_at")}</Label>
              <Detail>{IsoToLocalTime(details.created_at)}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>{getLocalizedText("created_by")}</Label>
              <Detail>
                {details.created_by !== null
                  ? dataRenderer(details.created_by.name)
                  : "-"}
              </Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>{getLocalizedText("updated_at")}</Label>
              <Detail>{IsoToLocalTime(details.updated_at)}</Detail>
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>{getLocalizedText("updated_by")}</Label>
              <Detail>
                {details.updated_by !== null
                  ? dataRenderer(details.updated_by.name)
                  : "-"}
              </Detail>
            </InputWrapper>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <SectionHeader>
                {getLocalizedText("fields_to_update")}
              </SectionHeader>
              <div className="ag-theme-alpine" style={{ width: "100%" }}>
                <AgGridReact
                  domLayout="autoHeight"
                  gridOptions={gridOptions}
                  rowData={
                    details.update_fields !== null ? details.update_fields : []
                  }
                  defaultColDef={defaultColDef}
                  columnDefs={columns}
                  animateRows={true}
                  suppressCellFocus
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </ModelWrapper>
    </Box>
  );
};

export default ViewAdminStageDirectiveSidepanel;
