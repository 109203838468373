import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { NumericFormat } from "react-number-format";
const InputField = styled(TextField)``;
const NumberFieldWidget = ({ handleChange, value, regex, disabled }) => {
  return (
    <NumericFormat
      disabled={disabled}
      value={value}
      onChange={(event) => {
        handleChange(event);
      }}
      pattern={regex}
      placeholder="Your answer"
      style={{ width: "50%" }}
      customInput={InputField}
    />
  );
};

export default NumberFieldWidget;
