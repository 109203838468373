import React, { useRef } from "react";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { CircularProgress, Divider, IconButton } from "@material-ui/core";
import {
  Avatar,
  Box,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import TableComponent from "../../chart_components/dashboard_chart_v2/tabular_widget/TableComponent";
import { Fullscreen, Refresh } from "@material-ui/icons";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import rootStore from "../../../stores/RootStore";
import {
  generateHourListFromHourlyLogs,
  getCombinedWidgetAndAccountID,
  getLocalDateTime,
  getTableSize,
} from "../../../Functions";
import moment from "moment";
import TableWidgetFullScreenModal from "../../full_screen_modal/TableWidgetFullScreenModal";
const ITEM_HEIGHT = 48;

const Container = styled(Box)`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  border: 1px solid #eceef2;
`;

const ChartBottom = styled(Box)`
  width: 100%;
  height: 100%;
`;

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
const Text = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
  text-align: left;
  color: #4d4e4f;
`;
const Text2 = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  margin: 0px;
  color: #bbbab6;
`;
const TextSm = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  margin: 0px;
  text-align: left;
  color: #4d4e4f;
`;
const CallActivityTabularWidget = (props) => {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([]);
  const componentRef = useRef(null);
  const [openFullScreenDialog, setOpenFullScreenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const Open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const ChartBottomSection = () => {
    if (!loading) {
      if (tableData.length > 0) {
        return (
          <Wrapper>
            <TableComponent
              row={tableData}
              columns={columns}
              pageSize={getTableSize({
                containerHeight: getContainerHeight(),
              })}
              type={"widget"}
            />
          </Wrapper>
        );
      } else {
        return (
          <Wrapper>
            <Typography>No data found!</Typography>
          </Wrapper>
        );
      }
    } else {
      return (
        <Wrapper>
          <CircularProgress />
        </Wrapper>
      );
    }
  };

  const getContainerHeight = () => {
    if (componentRef.current) {
      const height = componentRef.current.getBoundingClientRect().height;
      return height;
    }
    return 0;
  };

  const getColorForHourBoxWidget = (value) => {
    if (value == 0) {
      return "#f7fafd";
    } else if (value <= 5) {
      return "#abc6eb";
    } else if (value <= 25) {
      return "#6596da";
    } else {
      return "#26599E";
    }
  };

  const formatCallValue = (value) => {
    if (value > 99) {
      return "99+";
    }
    return value;
  };

  const getLastColumnWidth = (numberOfTiles) => {
    return numberOfTiles * 40 + 50; //here 34 -> 24(square grid size) + spacing between grid is 10, 50 is extra spacing
  };

  const setupColumns = ({ data, hourList }) => {
    const columns_list = [];

    if (data.length > 0) {
      Object.keys(data[0]).forEach((key, i) => {
        if (key !== "hour_logs") {
          let newObj = {
            field: key,
            headerName: key,
            cellRenderer: (params) => {
              let data = null;
              if (
                params.data[key] !== null &&
                params.data[key] !== "" &&
                params.data[key] !== undefined
              ) {
                if (typeof params.data[key] === "boolean") {
                  if (params.data[key] === true) {
                    data = "True";
                  } else {
                    data = "False";
                  }
                } else {
                  data = params.data[key];
                }
              }
              return (
                <span className="csutable-cell-trucate">
                  {data !== null ? data : "-"}
                </span>
              );
            },
          };
          newObj["autoSize"] = true;

          columns_list.push(newObj);
        }
      });

      let lastColumn = {
        field: "hour_logs",
        headerName: "Calls By Hour",
        minWidth: getLastColumnWidth(hourList.length),
        flex: 1,
        cellRenderer: (params) => {
          let hourLogs = params.data.hasOwnProperty("hour_logs")
            ? params.data.hour_logs
            : [];
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "16px",
                width: "fit-content",
                height: "100%",
              }}
            >
              {hourList.map((hourValue, idx) => {
                let index = hourLogs.findIndex(
                  (hour) => parseInt(hour["hour"]) === hourValue
                );
                if (index !== -1) {
                  let hourLogData = hourLogs[index];

                  return (
                    <Tooltip
                      title={
                        <Box>
                          <Typography sx={{ fontSize: "12px" }}>
                            {`Total Calls : ${hourLogData["total_calls"]}`}
                          </Typography>
                          <Typography sx={{ fontSize: "12px" }}>
                            {`Total Call Duration(m) : ${hourLogData["total_call_duration"]}`}
                          </Typography>
                        </Box>
                      }
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <TextSm>
                          {moment({ hour: hourValue % 24 }).format("h A")}
                        </TextSm>
                        <Avatar
                          key={idx}
                          variant="square"
                          sx={{
                            fontWeight: "bold",
                            width: "24px",
                            height: "24px",
                            fontSize: "10px",
                            backgroundColor: getColorForHourBoxWidget(
                              hourLogData["total_calls"]
                            ),
                            borderRadius: "4px",
                          }}
                        >
                          {formatCallValue(hourLogData["total_calls"])}
                        </Avatar>
                      </Box>
                    </Tooltip>
                  );
                } else {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <TextSm>
                        {moment({ hour: hourValue % 24 }).format("h A")}
                      </TextSm>
                      <Avatar
                        key={idx}
                        variant="square"
                        sx={{
                          fontWeight: "bold",
                          width: "24px",
                          height: "24px",
                          fontSize: "10px",
                          backgroundColor: "#f7fafd",
                          borderRadius: "4px",
                          border: "1px solid #dde2eb",
                        }}
                      >
                        &nbsp;
                      </Avatar>
                    </Box>
                  );
                }
              })}
            </Box>
          );
        },
      };
      columns_list.push(lastColumn);
    }

    return columns_list;
  };
  const combineHourlyAndDailyLog = ({ data }) => {
    let tempDailyLog = data["daily_log"];
    let tempHourlyLog = data["hourly_logs"];
    let userHoursDataMapping = {};

    tempHourlyLog.forEach((log) => {
      if (userHoursDataMapping.hasOwnProperty(log["User Name"])) {
        let userHourData = userHoursDataMapping[log["User Name"]];
        if (userHourData.hasOwnProperty(log["Hour"])) {
          userHourData[log["Hour"]].totalCalls += log["Total Calls"];
          userHourData[log["Hour"]].totalCallDuration +=
            log["Total Call Duration(m)"];
        } else {
          userHourData[log["Hour"]] = {
            totalCalls: log["Total Calls"],
            totalCallDuration: log["Total Call Duration(m)"],
          };
        }
      } else {
        userHoursDataMapping[log["User Name"]] = {
          [log["Hour"]]: {
            totalCalls: log["Total Calls"],
            totalCallDuration: log["Total Call Duration(m)"],
          },
        };
      }
    });

    tempDailyLog.forEach((log) => {
      if (userHoursDataMapping.hasOwnProperty(log["User Name"])) {
        let tempList = [];
        Object.keys(userHoursDataMapping[log["User Name"]]).forEach((key) => {
          let hourData = userHoursDataMapping[log["User Name"]][key];
          let tempObj = {
            hour: key,
            total_calls: hourData.totalCalls,
            total_call_duration: hourData.totalCallDuration,
          };
          tempList.push(tempObj);
        });
        log["hour_logs"] = tempList;
      }
    });
    return tempDailyLog;
  };

  const initData = () => {
    if (props.data !== null) {
      if (
        typeof props.data === "object" &&
        props.data.hasOwnProperty("daily_log") &&
        props.data.hasOwnProperty("hourly_logs")
      ) {
        const hourList = generateHourListFromHourlyLogs(
          props.data["hourly_logs"]
        );
        let tempData = combineHourlyAndDailyLog({ data: props.data });

        let columns = setupColumns({ data: tempData, hourList: hourList });
        setColumns(columns);
        setTableData(tempData);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, [props.widgetConfig.id, rootStore.userStore.dashboardMode]);
  return (
    <>
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "12px 20px",
            backgroundColor: "#F8F8F8",
          }}
        >
          <Text
            sx={{
              fontSize:
                rootStore.userStore.dashboardMode === "grid_three"
                  ? "16px"
                  : "18px",
            }}
          >
            {props.widgetConfig.title}
          </Text>
          {(props.type === "slider" || props.type === "grid") && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                style={{ padding: "0px" }}
                onClick={() => {
                  setOpenFullScreenDialog(true);
                }}
              >
                <Fullscreen />
              </IconButton>
              <IconButton
                onClick={handleMenuClick}
                aria-label="more"
                id="long-button"
                aria-controls={Open ? "long-menu" : undefined}
                aria-expanded={Open ? "true" : undefined}
                aria-haspopup="true"
                style={{ padding: "0px" }}
              >
                <MoreVertIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        <Divider sx={{ backgroundColor: "#eceef2" }} />

        <ChartBottom ref={componentRef}>
          <ChartBottomSection />
        </ChartBottom>
        <Divider sx={{ backgroundColor: "#eceef2" }} />

        {(props.type === "slider" || props.type === "grid") && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "12px 20px",
            }}
          >
            {rootStore.userStore.widgetData.hasOwnProperty(
              getCombinedWidgetAndAccountID(props.widgetConfig.id)
            ) && (
              <Text2>
                Updated at{" "}
                {getLocalDateTime(
                  rootStore.userStore.widgetData[
                    getCombinedWidgetAndAccountID(props.widgetConfig.id)
                  ]["last_updated"]
                )}
              </Text2>
            )}
            <IconButton
              onClick={() => {
                props.handleRefresh();
              }}
              style={{ padding: "0px" }}
            >
              <Refresh />
            </IconButton>
          </Box>
        )}
      </Container>
      <Menu
        style={{ display: "flex", flexDirection: "column" }}
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={Open}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "10ch",
          },
        }}
      >
        <MenuItem
          style={{ fontSize: "12px" }}
          onClick={() => {
            props.handleRemove(props.widgetConfig);
            handleMenuClose();
          }}
          disabled={!props.isDashboardEditable()}
        >
          Remove
        </MenuItem>
      </Menu>
      {openFullScreenDialog && (
        <TableWidgetFullScreenModal
          open={openFullScreenDialog}
          setOpen={setOpenFullScreenDialog}
          title={props.widgetConfig.title}
          columns={columns}
          tableData={tableData}
        />
      )}
    </>
  );
};

export default observer(CallActivityTabularWidget);
