import React, { useEffect, useState } from "react";
import SignupForm from "../../components/signupForm/SignupForm";
import bg from "../../assets/background/bg_gradient.png";
import styled from "styled-components";
import Appbar from "../../components/appbar/Appbar";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 90px;
  background-image:url(${bg});
  background-color:#F5F9FF;
  background-size: 100% 100%;
  height: 100vh;
`;
const SignupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  text-align: center;
  height: fit-content;
`;
const Header = styled.span`
  font: normal normal 600 24px Poppins;
`;

const Signup = () => {
  let navigate=useNavigate();
 const [activeStep,setActiveStep]=useState(0);
 useEffect(()=>{
  if(localStorage.getItem('token')){
    navigate('/');
  }
 },[])
  return (
    <>
      <Appbar remove={true}/>
      <Container>
        <SignupWrapper>
          <SignupForm/>
        </SignupWrapper>
      </Container>
    </>
  );
};

export default Signup;
