import admin_gif from "../../assets/images/gifs/lead_gif.gif";
import LoginCarousel from "../Carousel/Carousel";
import logo from "../../assets/logo.png";
import styled from "styled-components";
//this section is a quick into or features description with carousel

const Container = styled.div`
  display: flex;
  height: fit-content;
  width: 80%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 20px;
  margin: auto;
  height: 100%;
  justify-content: center;
`;
const Header = styled.span`
  font: normal normal 700 22px Poppins;
  width: 100%;
  margin: 0px;
  margin-bottom: 20px;
`;
const Subheader = styled.span`
  font: normal normal 400 16px Poppins;
  /* width: 70%; */
  margin: 0px;
  margin-bottom: 50px;
`;
const Slider = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;
const GifWrapper = styled.div`
  width: 500px;
  height: 500px;
  margin-top: -70px;
  @media (max-width: 1420px) {
    width: 400px;
    height: 400px;
  }
`;
const Gif = styled.img`
  height: 100%;
  width: 100%;
  display: unset;
`;
const UpdateGif = styled.div`
  height: 100%;
  width: 100%;
`;
const Image = styled.img`
  max-width: 180px;
  max-height: 48px;
  height: auto;
  width: auto;
  margin: 30px 0px 0px 100px;
  cursor: pointer;
`;

const LoginLeft = () => {
  let currIndex = -1;
  return (
    <>
      <Image src={logo} alt="loading..." />
      <Container>
        <Header>Welcome to the Future of Marketing and Sales Automation</Header>
        <Subheader>
          Our lead management platform is designed to streamline your marketing
          and sales efforts, helping you prioritize leads, distribute workflows
          and close more deals, resulting in increased revenue.
        </Subheader>
        <GifWrapper>
          <Gif src={admin_gif} alt="loading..." />
        </GifWrapper>
      </Container>
    </>
  );
};

export default LoginLeft;
