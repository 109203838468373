import { Button, Tooltip } from "@material-ui/core";
import { PlayArrow } from "@material-ui/icons";
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  TableCell,
  TableRow,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import {
  getRawLeadExportJobDownloadFileApi,
  getRawLeadJobCountApi,
  getRawLeadJobStatusApi,
  startRawLeadJobApi,
} from "../../Api";
import { RawLeadExportJobColumns } from "../../Db";
import { IsoToLocalTime } from "../../Functions";
import "../../pages/import_history/import.css";
import { Download } from "@mui/icons-material";
import analytics from "../../Analytics";

const SpinnerWrapper = styled.div`
  display: flex;
  padding-top: 8px;
  justify-content: center;
  align-items: center;
  margin: 0px 2px;
`;

const RawLeadJobRowComponent = ({ item, idx, pid }) => {
  const [data, setData] = useState();
  const { id } = item;
  const [status, setStatus] = useState("");
  const [open, setOpen] = useState(false);
  const MINUTE_MS = 30000;
  const [responseCount, setResponseCount] = useState(null);
  const [loading, setLoading] = useState(false);

  const getJobStatus = async () => {
    let response = await getRawLeadJobStatusApi({ jid: id });
    if (Object.keys(response).length > 0) {
      setData(response);
      setStatus(response["status"]);
    }
  };
  const getResponseCount = async () => {
    let response = await getRawLeadJobCountApi({ jid: id }); //jid is job id
    if (response !== null) {
      setResponseCount(response["item_count"]);
      setOpen(true);
    }
  };
  const startJob = async () => {
    let response = await startRawLeadJobApi({ jid: id });
    if (Object.keys(response).length > 0) {
      setData(response);
      setStatus(response["status"]);
      setOpen(false);
    }
  };
  const handlePlay = (e) => {
    e.stopPropagation();
    getResponseCount();
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (status === "queued" || status === "processing") {
        getJobStatus();
      }
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, [status]);

  useEffect(() => {
    setData(item);
    setStatus(item["status"]);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownload = () => {
    setLoading(true);
    const DownloadExportFile = async () => {
      let response = await getRawLeadExportJobDownloadFileApi({ jid: id });
      if (Object.keys(response).length > 0) {
        const url = response.artifact;
        const link = document.createElement("a");
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      setLoading(false);
    };
    DownloadExportFile();
  };
  return (
    <>
      <TableRow
        key={idx}
        style={{
          width: "100%",
          cursor: "pointer",
          backgroundColor: idx % 2 === 0 ? "white" : "#F9F9FC",
        }}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        {data &&
          RawLeadExportJobColumns.map((key) => {
            if (key["field"] === "action") {
              return (
                <TableCell
                  className="textContainer"
                  style={{
                    display: "flex",
                    justiyContent: "center",
                    alignItems: "center",
                    height: "33px",
                    width: "70px",
                  }}
                >
                  {(() => {
                    if (status === "queued" || status === "processing") {
                      return (
                        <>
                          <SpinnerWrapper onClick={(e) => e.stopPropagation()}>
                            <CircularProgress
                              style={{ width: "18px", height: "18px" }}
                            />
                          </SpinnerWrapper>
                          <SpinnerWrapper>
                            <Tooltip title="Download">
                              <Download
                                color="disabled"
                                style={{
                                  width: "18px",
                                  height: "18px",
                                }}
                              />
                            </Tooltip>
                          </SpinnerWrapper>
                        </>
                      );
                    } else if (status === "completed") {
                      return (
                        <>
                          <SpinnerWrapper onClick={(e) => e.stopPropagation()}>
                            <Tooltip title="Start">
                              <PlayArrow
                                color="disabled"
                                style={{ width: "18px", height: "18px" }}
                              />
                            </Tooltip>
                          </SpinnerWrapper>
                          <SpinnerWrapper
                            onClick={() => {
                              analytics.triggerEvent(
                                4625000159,
                                "download_button_tap",
                                "exports_tab",
                                "download_button",
                                {}
                              );
                              handleDownload();
                            }}
                          >
                            <Tooltip title="Download">
                              <Download
                                color="primary"
                                style={{
                                  width: "18px",
                                  height: "18px",
                                }}
                              />
                            </Tooltip>
                          </SpinnerWrapper>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <SpinnerWrapper
                            onClick={(e) => {
                              analytics.triggerEvent(
                                4625000158,
                                "play_button_tap",
                                "exports_tab",
                                "play_button",
                                {}
                              );
                              handlePlay(e);
                            }}
                          >
                            <Tooltip title="Start">
                              <PlayArrow
                                style={{
                                  width: "18px",
                                  height: "18px",
                                  color: "#5cb85c",
                                }}
                              />
                            </Tooltip>
                          </SpinnerWrapper>
                          <SpinnerWrapper>
                            <Tooltip title="Download">
                              <Download
                                color="disabled"
                                style={{
                                  width: "18px",
                                  height: "18px",
                                }}
                              />
                            </Tooltip>
                          </SpinnerWrapper>
                        </>
                      );
                    }
                  })()}
                </TableCell>
              );
            } else if (
              key["field"] === "start_time" ||
              key["field"] === "end_time" ||
              key["field"] === "created_at"
            ) {
              let datetime = IsoToLocalTime(data[key["field"]]);
              return (
                <Tooltip title={datetime}>
                  <TableCell
                    className="textContainer"
                    style={{
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                      color: "#414141",
                      width: "200px",
                    }}
                  >
                    {datetime}
                  </TableCell>
                </Tooltip>
              );
            } else {
              return (
                <Tooltip title={data[key["field"]]}>
                  <TableCell
                    className="textContainer"
                    style={{
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                      color: "#414141",
                      width: "200px",
                    }}
                  >
                    {data[key["field"]]}
                  </TableCell>
                </Tooltip>
              );
            }
          })}
      </TableRow>
      {responseCount !== null && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ fontWeight: "bold" }}>
            Confirmation: Export {responseCount}{" "}
            {responseCount === 1 ? "lead" : "leads"}?
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={startJob}>Confirm</Button>
          </DialogActions>
        </Dialog>
      )}

      <Backdrop
        open={loading}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        onClick={() => {
          setLoading(false);
        }}
      >
        <CircularProgress style={{ color: "white" }} />
      </Backdrop>
    </>
  );
};

export default RawLeadJobRowComponent;
