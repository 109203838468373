import { Box, IconButton } from "@mui/material";
import React, { useRef } from "react";
import { styled } from "@mui/material/styles";
import { AddPhotoAlternate, NoteAdd } from "@material-ui/icons";
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed #185dd2;
  width: 80px;
  height: 80px;
  border-radius: 12px;
`;
const AddFileInputComponent = ({
  acceptedFiles,
  handleChange,
  scope,
  disabled,
}) => {
  const inputRef = useRef(null);

  return (
    <Container>
      <IconButton
        color="primary"
        onClick={() => {
          inputRef.current.click();
        }}
      >
        {scope === "image" ? <AddPhotoAlternate /> : <NoteAdd />}
      </IconButton>
      <input
        style={{ display: "none" }}
        type="file"
        ref={inputRef}
        accept={acceptedFiles}
        onClick={() => {
          inputRef.current.value = "";
        }}
        onChange={(event) => handleChange(event)}
      />
    </Container>
  );
};

export default AddFileInputComponent;
