import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, Grid, makeStyles } from "@material-ui/core";
import {
  AppBar,
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Toolbar,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { currencylist, dealPriorityList } from "../../Db";
import { createLeadDealApi, getProductsListApi } from "../../Api";
import AddProductModal from "./add_product_modal/AddProductModal";
import TableComponent from "./table_component/TableComponent";
import EditProductModal from "./edit_product_modal/EditProductModal";
import ViewProductModal from "./view_product_modal/ViewProductModal";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  gap: 20px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const AddDealSidePanel = (props) => {
  const classes = useStyles();
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [details, setDetails] = useState({
    name: "",
    deal_value: "",
    deal_value_currency: "INR",
    priority: "Medium",
  });
  const [openAddProductModal, setOpenAddProductModal] = useState(false);
  const [openEditProductModal, setOpenEditProductModal] = useState(false);
  const [openViewProductModal, setOpenViewProductModal] = useState(false);
  const [selectedProductData, setSelectedProductData] = useState({});
  const [productAddedList, setProductAddedList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleClose = () => {
    props.setOpen(false);
  };
  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const createProduct = async () => {
    let rawData = { ...details };
    let payload = {
      name: rawData.name,
      deal_value_currency: rawData.deal_value_currency,
      priority: rawData.priority,
    };

    payload["deal_value"] = parseInt(rawData["deal_value"]);
    let dealItems = [];
    productAddedList.forEach((productItem) => {
      let tempObj = {
        rate: productItem["rate"],
        amount: productItem["amount"],
        quantity: productItem["quantity"],
      };
      if (productItem["product"]["type"] === "others") {
        tempObj["item"] = productItem["product"]["name"];
      } else {
        tempObj["product_id"] = productItem["product"]["id"];
      }
      dealItems.push(tempObj);
    });
    payload["deal_items"] = dealItems;
    let response = await createLeadDealApi({
      payload: payload,
      leadID: props.leadID,
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      props.setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    createProduct();
  };
  const getProductsList = async () => {
    let response = await getProductsListApi({
      page: 0,
      page_size: 100,
    });
    if (Object.keys(response).length > 0) {
      setProductList(response.items);
    } else {
      setProductList([]);
    }
  };
  const init = async () => {
    await getProductsList();
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  return !loading ? (
    <>
      <Box role="presentation">
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: 500,
            right: 0,
            bgcolor: "#f9f9fc",
          }}
        >
          <Toolbar>
            <ModelHeader>Add Deal</ModelHeader>
          </Toolbar>
        </AppBar>
        {!isSubmitSuccess ? (
          <ModelWrapper component={"form"} onSubmit={handleSubmit}>
            <InputContainer>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>Name*</Label>
                    <OutlinedInput
                      style={{ height: "30px" }}
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      onChange={handleChange}
                      name="name"
                      required
                      value={details["name"]}
                      placeholder="Enter value"
                    />
                  </InputWrapper>
                </Grid>

                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>Deal Value*</Label>
                    <OutlinedInput
                      style={{ height: "30px" }}
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      onChange={handleChange}
                      name="deal_value"
                      required
                      value={details["deal_value"]}
                      placeholder="Enter value"
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>Deal Currency*</Label>
                    <FormControl
                      sx={{ width: "100%" }}
                      classes={{
                        root: classes.quantityRoot,
                      }}
                    >
                      <Select
                        classes={{
                          icon: classes.icon,
                        }}
                        displayEmpty
                        required
                        onChange={handleChange}
                        name="deal_value_currency"
                        disableUnderline
                        variant="outlined"
                        value={details["deal_value_currency"]}
                        style={{
                          width: "100%",
                          height: "30px",
                          fontSize: "12px",

                          borderRadius: "0px",
                        }}
                      >
                        {currencylist.map((item, i) => {
                          return (
                            <MenuItem
                              value={item.code}
                              key={i}
                              style={{ fontSize: "12px" }}
                            >
                              {item.code}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </InputWrapper>
                </Grid>

                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>Priority*</Label>
                    <FormControl
                      sx={{ width: "100%" }}
                      classes={{
                        root: classes.quantityRoot,
                      }}
                    >
                      <Select
                        classes={{
                          icon: classes.icon,
                        }}
                        displayEmpty
                        required
                        onChange={handleChange}
                        name="priority"
                        disableUnderline
                        variant="outlined"
                        value={details["priority"]}
                        style={{
                          width: "100%",
                          height: "30px",
                          fontSize: "12px",
                          borderRadius: "0px",
                        }}
                      >
                        {dealPriorityList.map((item, i) => {
                          return (
                            <MenuItem
                              value={item}
                              key={i}
                              style={{ fontSize: "12px" }}
                            >
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <TableComponent
                      isReadOnly={false}
                      tableData={productAddedList}
                      setOpen={setOpenAddProductModal}
                      setSelectedProductData={setSelectedProductData}
                      setOpenEditProductModal={setOpenEditProductModal}
                      setOpenViewProductModal={setOpenViewProductModal}
                    />
                  </InputWrapper>
                </Grid>
              </Grid>
            </InputContainer>

            <AppBar
              position="fixed"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: 500 }}
            >
              <Toolbar style={{ width: 500, position: "relative" }}>
                {isSubmitFail && (
                  <ErrorAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setIsSubmitFail(false);
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {errorMessage}
                  </ErrorAlert>
                )}
                <ButtonWrapper>
                  <Button
                    onClick={handleClose}
                    type="button"
                    variant="contained"
                    color="default"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      backgroundColor: "#185DD2",
                      color: "white",
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    Submit
                  </Button>
                </ButtonWrapper>
              </Toolbar>
            </AppBar>
          </ModelWrapper>
        ) : (
          <MessageWrapper>
            <Message>Deal added successfully!</Message>
            <Button
              onClick={handleClose}
              type="button"
              variant="contained"
              color="default"
              style={{
                marginTop: "20px",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              Close
            </Button>
          </MessageWrapper>
        )}
      </Box>
      {openAddProductModal && (
        <AddProductModal
          open={openAddProductModal}
          setOpen={setOpenAddProductModal}
          productAddedList={productAddedList}
          setProductAddedList={setProductAddedList}
          productList={productList}
        />
      )}
      {openEditProductModal && (
        <EditProductModal
          open={openEditProductModal}
          setOpen={setOpenEditProductModal}
          productAddedList={productAddedList}
          setProductAddedList={setProductAddedList}
          productList={productList}
          editableProduct={selectedProductData}
          setEditableProduct={setSelectedProductData}
        />
      )}
      {openViewProductModal && (
        <ViewProductModal
          open={openViewProductModal}
          setOpen={setOpenViewProductModal}
          data={selectedProductData}
          setData={setSelectedProductData}
        />
      )}
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: 500,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default AddDealSidePanel;
