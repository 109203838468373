import React, { useRef } from "react";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { CircularProgress, Divider } from "@material-ui/core";
import { Box, Typography } from "@mui/material";
import rootStore from "../../../stores/RootStore";
import { AgGridReact } from "ag-grid-react";
import { useMemo } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  getColumnHeaderWidthByStringLength,
  getTableSize,
} from "../../../Functions";
const Container = styled(Box)`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  border: 1px solid #eceef2;
`;

const ChartBottom = styled(Box)`
  width: 100%;
  height: 100%;
`;

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
const Text = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
  text-align: left;
  color: #4d4e4f;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const AutoPagingTabularWidget = (props) => {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([]);
  const componentRef = useRef(null);
  const intervalRef = useRef(null);
  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));

  const gridRef = useRef(null);

  const onFirstDataRendered = (params) => {
    const autoSizeColumns = columns
      .filter((col) => col.autoSize)
      .map((col) => col.field);
    params.columnApi.autoSizeColumns(autoSizeColumns);
  };
  const onGridReady = (params) => {
    gridRef.current = params.api;
  };

  useEffect(() => {
    // Function to change pages
    const autoPageChange = () => {
      if (gridRef.current) {
        const api = gridRef.current;
        const currentPage = api.paginationGetCurrentPage();
        const totalPages = api.paginationGetTotalPages();

        if (currentPage + 1 >= totalPages) {
          clearInterval(intervalRef.current); // Stop the loop when reaching the last page
          intervalRef.current = null; // Clear reference
        } else {
          api.paginationGoToPage(currentPage + 1);
        }
      }
    };

    const interval = setInterval(autoPageChange, 15000); // Change page every 15 seconds

    // Clean up the interval when the component is unmounted
    return () => clearInterval(interval);
  }, []);

  const ChartBottomSection = () => {
    if (!loading) {
      if (tableData.length > 0) {
        return (
          <Wrapper>
            <TableWrapper
              style={{ width: "100%", height: "100%" }}
              sx={{
                "& .ag-root-wrapper": {
                  border: "none",
                },
                ".ag-paging-page-summary-panel": {
                  display: "none",
                },
                ".table-widget-pagination .ag-paging-panel": {
                  justifyContent: "flex-end",
                },
              }}
            >
              <div
                className="ag-theme-alpine"
                style={{ width: "100%", height: "100%", overflowY: "auto" }}
              >
                <AgGridReact
                  ref={gridRef}
                  onGridReady={onGridReady}
                  rowData={tableData}
                  defaultColDef={defaultColDef}
                  columnDefs={columns}
                  animateRows={true}
                  pagination={true}
                  paginationPageSize={getTableSize({
                    containerHeight: getContainerHeight(),
                  })}
                  onFirstDataRendered={onFirstDataRendered}
                  className="table-widget-pagination"
                />
              </div>
            </TableWrapper>
          </Wrapper>
        );
      } else {
        return (
          <Wrapper>
            <Typography>No data found!</Typography>
          </Wrapper>
        );
      }
    } else {
      return (
        <Wrapper>
          <CircularProgress />
        </Wrapper>
      );
    }
  };

  const getContainerHeight = () => {
    if (componentRef.current) {
      const height = componentRef.current.getBoundingClientRect().height;
      return height;
    }
    return 0;
  };

  const setupColumns = (data) => {
    const columns_list = [];
    let dataLength = Object.keys(data).length;
    Object.keys(data).forEach((key, idx) => {
      let newObj = {
        field: key,
        headerName: key,
        cellRenderer: (params) => {
          let data = null;
          if (
            params.data[key] !== null &&
            params.data[key] !== "" &&
            params.data[key] !== undefined
          ) {
            if (typeof params.data[key] === "boolean") {
              if (params.data[key] === true) {
                data = "True";
              } else {
                data = "False";
              }
            } else {
              data = params.data[key];
            }
          }
          return (
            <span className="csutable-cell-trucate">
              {data !== null ? data : "-"}
            </span>
          );
        },
      };

      if (idx === dataLength - 1) {
        newObj["minWidth"] = getColumnHeaderWidthByStringLength(key);
        newObj["flex"] = 1;
      } else {
        newObj["autoSize"] = true;
      }

      columns_list.push(newObj);
    });
    return columns_list;
  };
  const initData = () => {
    if (props.data !== null) {
      if (props.data.length > 0) {
        let columns = setupColumns(props.data[0]);
        setColumns(columns);
        setTableData(props.data);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, [props.widgetConfig.id, rootStore.userStore.dashboardMode]);
  return (
    <>
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "12px 20px",
            backgroundColor: "#F8F8F8",
          }}
        >
          <Text
            sx={{
              fontSize:
                rootStore.userStore.dashboardMode === "grid_three"
                  ? "16px"
                  : "18px",
            }}
          >
            {props.widgetConfig.title}
          </Text>
        </Box>
        <Divider sx={{ backgroundColor: "#eceef2" }} />
        <ChartBottom ref={componentRef}>
          <ChartBottomSection />
        </ChartBottom>
      </Container>
    </>
  );
};

export default observer(AutoPagingTabularWidget);
