import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import Appbar from "./school_template_components/Appbar";
import TopSection from "./school_template_components/TopSection";
import FooterComponent from "./school_template_components/FooterComponent";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
}));

const SchoolTemplate = ({ logoURL, contentState, formState, primaryColor }) => {
  return (
    <Container>
      <Appbar logoURL={logoURL} />
      <TopSection
        formState={formState}
        contentState={contentState}
        primaryColor={primaryColor}
      />
      <FooterComponent contentState={contentState} />
    </Container>
  );
};

export default SchoolTemplate;
