import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { CopyAll } from "@mui/icons-material";
import { Snackbar } from "@material-ui/core";
import { useState } from "react";
const Container = styled(Box)`
  height: fit-content;
  min-height: 114px;
`;
const TextWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;
const Title = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const Item = styled(Box)`
  display: flex;
  width: 100%;
  height: fit-content;
  background-color: white;
  padding: 10px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
`;
const Link = styled(Typography)`
  font: normal normal 500 18px Open Sans;
  color: #4d4e4f;
`;
const IconTextWrapper = styled(Box)`
  display: flex;
  align-items: center;
`;
const QRImage = styled("img")`
  width: 100%;
  height: 100%;
`;
const WebFormCardWidget = ({ data }) => {
  const [openSnackBar, setOpenSnackBar] = useState(false);

  return (
    <>
      <Container>
        <Item>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <TextWrapper>
                <Title>{data.name}</Title>
                <IconTextWrapper>
                  <CopyAll
                    color="primary"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigator.clipboard.writeText(data.short_link);
                      setOpenSnackBar(true);
                    }}
                  />
                  <Tooltip title={data.short_link} placement="bottom-start">
                    <span
                      style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        flex: 1,
                      }}
                    >
                      {data.short_link}
                    </span>
                  </Tooltip>
                </IconTextWrapper>
              </TextWrapper>
            </Grid>
            <Grid item xs={3}>
              <QRImage src={data.qr_code} alt="loading...." />
            </Grid>
          </Grid>
        </Item>
      </Container>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => {
          setOpenSnackBar(false);
        }}
        message="Copied to clipboard!"
      />
    </>
  );
};

export default WebFormCardWidget;
