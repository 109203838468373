import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography, Card, CardActionArea } from "@mui/material";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import analytics from "../../Analytics";
import rootStore from "../../stores/RootStore";

const Container = styled(Box)(({ theme }) => ({}));

const StickyIconsSidebar = ({
  canEditLead,
  isUpdateStatusDisabled,
  setWhatsAppContextAnchor,
  handleWhatsapp,
  handleAddCallLog,
  messageTemplates,
}) => {
  const { authStore } = rootStore;

  return (
    <>
      <Container
        sx={{
          position: "fixed",
          top: "50vh",
          right: 0,
          height: "25vh",
          width: "60px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "1rem",
          zIndex: 10,
        }}
      >
        <Card
          sx={{
            display: "flex",
            width: "200px",
            height: "60px",
            transform: "translate(75px,0px)",
            alignItems: "center",
            margin: "2px",
            marginBottom: "6px",
            textDecoration: "none",
            transition: "transform 0.6s",
            "&:hover": {
              transform: "translate(-40px,0px)",
            },
          }}
        >
          <CardActionArea
            disabled={isUpdateStatusDisabled()}
            sx={{ width: "100%", height: "100%" }}
            onClick={handleAddCallLog}
          >
            <Box
              sx={{
                opacity: !isUpdateStatusDisabled() ? 1 : 0.4, //dulling when disabled
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <AddIcCallIcon
                color="primary"
                aria-label="call_logs"
                sx={{
                  width: "60px",
                }}
                fontSize="large"
              />
              <Typography
                variant="body1"
                sx={{
                  width: "100%",
                  color: "#000",
                  textAlign: "left",
                }}
              >
                Add Call Log
              </Typography>
            </Box>
          </CardActionArea>{" "}
        </Card>
        <Card
          sx={{
            display: "flex",
            width: "200px",
            height: "60px",
            transform: "translate(75px,0px)",
            alignItems: "center",
            margin: "2px",
            marginBottom: "6px",
            textDecoration: "none",
            transition: "transform 0.6s",
            "&:hover": {
              transform: "translate(-40px,0px)",
            },
          }}
        >
          <CardActionArea
            disabled={!canEditLead()}
            sx={{ width: "100%", height: "100%" }}
            onClick={(e) => {
              analytics.triggerEvent(
                4625000039,
                "whatsapp_button_tap",
                "lead_details_page",
                "whatsapp_button",
                {}
              );
              authStore.updateLastActiveTime();
              if (messageTemplates.length > 0) {
                setWhatsAppContextAnchor(e.currentTarget);
              } else {
                handleWhatsapp();
              }
            }}
          >
            <Box
              sx={{
                opacity: canEditLead() ? 1 : 0.4, //dulling when disabled
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <WhatsAppIcon
                color="primary"
                aria-label="call_logs"
                sx={{
                  width: "60px",
                  color: "#25d267",
                }}
                fontSize="large"
              />
              <Typography
                variant="body1"
                sx={{
                  width: "100%",
                  color: "#000",
                  textAlign: "left",
                }}
              >
                WhatsApp
              </Typography>
            </Box>
          </CardActionArea>{" "}
        </Card>
      </Container>
    </>
  );
};

export default StickyIconsSidebar;
