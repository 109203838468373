import React, { useCallback, useRef } from "react";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import {
  Chart,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import { useState } from "react";
import { StackedBarConfig } from "../../../Config";
import rootStore from "../../../stores/RootStore";
import { observer } from "mobx-react-lite";
import {
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from "@material-ui/core";
import {
  consoleLogger,
  getStageColorCode,
  getStatusColorCode,
  getCombinedWidgetAndAccountID,
  getLocalDateTime,
} from "../../../Functions";
import { Box, Menu, MenuItem } from "@mui/material";
import { Fullscreen, Refresh } from "@material-ui/icons";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FullScreenModal from "../../full_screen_modal/FullScreenModal";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ViewBarGraphDialog from "./view_bargraph_dialog/ViewBarGraphDialog";

Chart.register(
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels
);
const ITEM_HEIGHT = 48;

const Container = styled(Box)`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  border: 1px solid #eceef2;
`;

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
`;
const Text = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
  text-align: left;
  color: #4d4e4f;
`;
const Text2 = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  margin: 0px;
  color: #bbbab6;
`;
const TwoGroupBarGraphWidget = (props) => {
  const { userStore } = rootStore;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [openFullScreenDialog, setOpenFullScreenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const Open = Boolean(anchorEl);
  const chartRef = useRef();
  const [viewSelectedBarGraph, setViewSelectedBarGraph] = useState(false);
  const [selectedBarGraphData, setSelectedBarGraphData] = useState({
    label: "",
    data: null,
  });
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const generateRandomColor = (val) => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    userStore.updateColorsList({ key: val, color: color });
    return color;
  };

  function buildDataset(response, v2Response) {
    let labels = getLabels(response, v2Response["xdata"]["group_by"][0]);
    let dataset = [];
    let keyName = v2Response["xdata"]["group_by"][1];
    let columns = getLabels(response, keyName); // give me all the unique values for the dataset
    let aggregateBy = v2Response["xdata"].hasOwnProperty(
      "aggregate_by_col_names"
    )
      ? v2Response["xdata"]["aggregate_by_col_names"]
      : "count"; //here if xdata has the aggregate_by_col_names then it will use that else by default it will use "count"
    columns.forEach((column) => {
      let color;
      if (keyName === "lead_stage") {
        color = getStageColorCode(column);
      } else if (keyName === "lead_status") {
        color = getStatusColorCode(column);
      } else {
        if (userStore.ColorsList.hasOwnProperty(column)) {
          color = userStore.ColorsList[column];
        } else {
          color = generateRandomColor(column);
        }
      }
      let set = {
        label: column,
        data: [],
        backgroundColor: color,
      };
      let entires = response.filter((item) => item[keyName] === column);
      labels.forEach((label) => {
        let key = v2Response["xdata"]["group_by"][0];
        let count = 0;
        entires.forEach((entry) => {
          if (entry[key] === label) {
            count += entry[aggregateBy];
          }
        });
        set["data"].push(count);
      });
      dataset.push(set);
    });
    return dataset;
  }

  function getLabels(response, key) {
    let labels = [];
    response.map((item) => {
      let value = item[key];
      if (labels.includes(value) == false) {
        labels.push(value);
      }
    });
    return labels;
  }

  const updateData = (response, v2Response) => {
    let newObj = {
      labels: [],
      datasets: [],
    };

    if (response.length > 0) {
      newObj.labels = getLabels(response, v2Response["xdata"]["group_by"][0]);
      newObj.datasets = buildDataset(response, v2Response);
    } else {
      newObj = {};
    }
    consoleLogger("result", newObj);
    return newObj;
  };
  const ChartBottomSection = useCallback(() => {
    if (Object.keys(data).length === 0) {
      return (
        <Wrapper>
          <Typography>No data found!</Typography>
        </Wrapper>
      );
    } else {
      let options = { ...StackedBarConfig.options };
      options["plugins"]["datalabels"]["font"] = {
        size: getCountLabelFontSize(),
      };
      options["plugins"]["datalabels"]["color"] = "#A5A8AC";
      return (
        <Wrapper>
          <Bar
            ref={chartRef}
            data={data}
            options={options}
            onClick={handleClick}
          />
        </Wrapper>
      );
    }
  }, [data, openFullScreenDialog]);

  const handleClick = (event) => {
    if (props.type !== "slideshow") {
      if (getElementAtEvent(chartRef.current, event).length > 0) {
        let dataPoint = getElementAtEvent(chartRef.current, event)[0].index;

        const newDataset = {
          labels: data["datasets"].map((dataset) => dataset.label),
          datasets: [
            {
              label: "Count",
              data: [],
              backgroundColor: [],
            },
          ],
        };

        data["datasets"].forEach((dataset) => {
          newDataset.datasets[0].data.push(dataset.data[dataPoint]); // Add data for the specific index
          newDataset.datasets[0].backgroundColor.push(dataset.backgroundColor);
        });
        let tempObj = { label: data["labels"][dataPoint], data: newDataset };
        setSelectedBarGraphData(tempObj);
        setViewSelectedBarGraph(true);
      }
    }
  };

  const getCountLabelFontSize = () => {
    if (openFullScreenDialog === true) {
      return "14px";
    } else {
      if (rootStore.userStore.dashboardMode === "grid_three") {
        return "9px";
      } else if (rootStore.userStore.dashboardMode === "grid") {
        return "11px";
      } else {
        return "14px";
      }
    }
  };

  const getData = () => {
    if (props.data !== null) {
      let groups = props.widgetConfig["xdata"]["group_by"];
      if (props.data.length > 0) {
        props.data.map((item) => {
          groups.map((key) => {
            if (item[key] === null) {
              item[key] = "NA";
            }
          });
        });
      }

      let modifiedData = updateData(props.data, props.widgetConfig);

      setData(modifiedData);
    }
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [props.widgetConfig.id, rootStore.userStore.dashboardMode]);
  return (
    <>
      {!loading ? (
        <Container type={props.type}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "12px 20px",
              backgroundColor: "#F8F8F8",
            }}
          >
            <Text
              sx={{
                fontSize:
                  rootStore.userStore.dashboardMode === "grid_three"
                    ? "16px"
                    : "18px",
              }}
            >
              {props.widgetConfig.title}
            </Text>

            {(props.type === "slider" || props.type === "grid") && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  style={{ padding: "0px" }}
                  onClick={() => {
                    setOpenFullScreenDialog(true);
                  }}
                >
                  <Fullscreen />
                </IconButton>
                <IconButton
                  onClick={handleMenuClick}
                  aria-label="more"
                  id="long-button"
                  aria-controls={Open ? "long-menu" : undefined}
                  aria-expanded={Open ? "true" : undefined}
                  aria-haspopup="true"
                  style={{ padding: "0px" }}
                >
                  <MoreVertIcon />
                </IconButton>
              </Box>
            )}
          </Box>
          <Divider sx={{ backgroundColor: "#eceef2" }} />
          <ChartBottomSection />
          <Divider sx={{ backgroundColor: "#eceef2" }} />

          {(props.type === "slider" || props.type === "grid") && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "12px 20px",
              }}
            >
              {rootStore.userStore.widgetData.hasOwnProperty(
                getCombinedWidgetAndAccountID(props.widgetConfig.id)
              ) && (
                <Text2>
                  Updated at{" "}
                  {getLocalDateTime(
                    rootStore.userStore.widgetData[
                      getCombinedWidgetAndAccountID(props.widgetConfig.id)
                    ]["last_updated"]
                  )}
                </Text2>
              )}
              <IconButton
                onClick={() => {
                  props.handleRefresh();
                }}
                style={{ padding: "0px" }}
              >
                <Refresh />
              </IconButton>
            </Box>
          )}
        </Container>
      ) : (
        <Container style={{ alignItems: "center" }}>
          <CircularProgress />
        </Container>
      )}
      <Menu
        style={{ display: "flex", flexDirection: "column" }}
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={Open}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "10ch",
          },
        }}
      >
        <MenuItem
          style={{ fontSize: "12px" }}
          onClick={() => {
            props.handleRemove(props.widgetConfig);
            handleMenuClose();
          }}
          disabled={!props.isDashboardEditable()}
        >
          Remove
        </MenuItem>
      </Menu>
      {openFullScreenDialog && (
        <FullScreenModal
          open={openFullScreenDialog}
          setOpen={setOpenFullScreenDialog}
          myComponent={ChartBottomSection}
          title={props.widgetConfig.title}
        />
      )}
      {viewSelectedBarGraph && (
        <ViewBarGraphDialog
          open={viewSelectedBarGraph}
          setOpen={setViewSelectedBarGraph}
          data={selectedBarGraphData["data"]}
          title={`${props.widgetConfig.title} for ${selectedBarGraphData["label"]}`}
        />
      )}
    </>
  );
};

export default observer(TwoGroupBarGraphWidget);
