import { Box, Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";

const CustomYesNoDialog = ({
  handleNo,
  handleYes,
  handleClose,
  message,
  open,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box style={{ padding: "20px" }}>
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            fontSize: "18px",
            padding: "0",
            paddingBottom: "16px",
          }}
        >
          {message}
        </DialogTitle>
        <DialogActions style={{ padding: 0 }}>
          <Button
            onClick={handleYes}
            autoFocus
            type="button"
            variant="outlined"
            color="primary"
            style={{
              textTransform: "none",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            Yes
          </Button>
          <Button
            type="button"
            variant="contained"
            onClick={handleNo}
            color="primary"
            style={{
              textTransform: "none",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            No
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default CustomYesNoDialog;
