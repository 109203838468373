import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  makeStyles,
  Select,
} from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import { observer } from "mobx-react-lite";
import { DataGrid } from "@mui/x-data-grid";
import {
  AutoDialerCampaignColumnHeaders,
  OzonetelDialerAgentListColumnHeaders,
} from "../../Db";
import { getDialerExportJobAgentListApi } from "../../Api";
import { autoDialerCampaignTypeList } from "../../Config";
import rootStore from "../../stores/RootStore";
import { getLocalizedText } from "../../Functions";

const SubHeader = styled.span`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 340px;
  width: 400px;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Label = styled.span`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Input = styled.input`
  width: 400px;
  height: 30px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;
  border: 1px solid #bfbfbf;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const SelectColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  @media (max-width: 700px) {
    width: 280px;
  }
`;

const TableWrapper = styled.div`
  width: 100%;
  /* max-height: 144px; */
  /* overflow-y: auto; */
  height: 174px;
`;

function ampm(time) {
  if (time.value !== "") {
    var hours = time.split(":")[0];
    var minutes = time.split(":")[1];
    var suffix = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    var displayTime = hours + ":" + minutes + " " + suffix;
    return displayTime;
  }
  return;
}

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const DialerExportJobForm = (props) => {
  const classes = useStyles();
  const { authStore } = rootStore;
  const [agentList, setAgentList] = useState([]);
  const [campaignType, setCampaignType] = useState("");
  const [selectedAgent, setSelectedAgent] = useState([]);
  const [loading, setLoading] = useState(true);
  const getAgentList = async (campaign_type) => {
    let payload = {
      dialer: props.provider,
      scope: campaign_type,
    };
    let response = await getDialerExportJobAgentListApi(payload);
    return response;
  };
  const handleCampaign = async (e) => {
    if (e.target.value === "agent_wise") {
      props.setDialerCampaignType("agentwise_equally");
    }
    setCampaignType(e.target.value);
    props.setAutodialerCampaignType(e.target.value);
    let agent_list = await getAgentList(e.target.value);
    setAgentList(agent_list);
  };
  useEffect(() => {
    initAgentList();
  }, []);

  const initAgentList = async () => {
    if (props.autodialerCampaignType !== "") {
      setCampaignType(props.autodialerCampaignType);
      let agent_list = await getAgentList(props.autodialerCampaignType);
      setAgentList(agent_list);
      let id_list = [];
      props.agentList.forEach((agent) => {
        id_list.push(agent.id);
      });
      setSelectedAgent(id_list);
    } else {
      setSelectedAgent([]);
      setAgentList([]);
    }
    setLoading(false);
  };

  return (
    <>
      <SubHeader>{getLocalizedText("fill_in_the_below_details")}</SubHeader>
      <Divider style={{ margin: "10px 0px" }} />
      {!loading ? (
        <InputContainer>
          <SelectColumn>
            <Label>
              {getLocalizedText("select_auto_dialer_campaign_type")}
            </Label>
            <FormControl
              sx={{ m: 1, minWidth: 400 }}
              classes={{
                root: classes.quantityRoot,
              }}
            >
              <Select
                classes={{
                  icon: classes.icon,
                }}
                name="segment"
                displayEmpty
                disableUnderline
                variant="outlined"
                required
                value={campaignType}
                onChange={(e) => {
                  handleCampaign(e);
                }}
                style={{
                  marginBottom: "20px",
                  width: "400px",
                  height: "30px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  borderRadius: "0px",
                }}
              >
                {autoDialerCampaignTypeList.map((e) => {
                  return (
                    <MenuItem id={e} value={e.value}>
                      {e.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </SelectColumn>
          {campaignType === "agent_wise" && (
            <InputWrapper>
              <Label>{getLocalizedText("max_leads_per_agent")}*</Label>
              <Input
                required
                type="number"
                placeholder="Enter number of leads"
                value={props.maxLeadCount}
                onChange={(e) => {
                  props.setMaxLeadCount(e.target.value);
                }}
              />
            </InputWrapper>
          )}
          {campaignType === "agent_wise" && (
            <TableWrapper>
              <Label>{getLocalizedText("select_agents")}</Label>
              <DataGrid
                rows={agentList}
                columns={
                  authStore.currentProject["external_dialer"] === "ozonetel"
                    ? OzonetelDialerAgentListColumnHeaders
                    : AutoDialerCampaignColumnHeaders
                }
                checkboxSelection
                selectionModel={selectedAgent}
                style={{ cursor: "pointer" }}
                hideFooter={true}
                disableColumnMenu={true}
                onSelectionModelChange={(selectedRowsID) => {
                  setSelectedAgent(selectedRowsID);
                  const selectedData = selectedRowsID.map((rowId) => {
                    const row = agentList.find((r) => r.id === rowId);
                    return row;
                  });
                  props.setAgentList(selectedData);
                }}
              />
            </TableWrapper>
          )}
        </InputContainer>
      ) : (
        <InputContainer
          style={{
            height: "400px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </InputContainer>
      )}
    </>
  );
};

export default observer(DialerExportJobForm);
