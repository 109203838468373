import React, { useState } from "react";
import styled from "styled-components";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 435px;
  text-align: left;
  margin: auto;
  padding: 20px 50px;
  background-color: white;
  height: fit-content;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;
const Header = styled.span`
  font: normal normal 600 24px Poppins;
`;
const SubHeader = styled.span`
  font: normal normal normal 15px Poppins;
  color: #6f6f6f;
  margin-top: 2px;
  margin-bottom: 10px;
`;
const Form = styled.div`
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled.form`
  display: flex;
  flex-direction: column;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Label = styled.span`
  font: normal normal normal 12px Poppins;
`;
const Input = styled.input`
  width: 335px;
  height: 50px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 3px 10px #0000001a;
  font: normal normal 600 16px Poppins;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal normal 16px Poppins;
    color: #b5b5b5;
  }
`;
const ButtonWrapper = styled.div`
  margin-top: 30px;
`;
const Button = styled.button`
  width: 335px;
  margin: auto;
  padding: 6px 12px;
  font: normal normal 600 16px Poppins;
  background-color: #185dd2;
  color: white;
  border: none;
  border-radius: 90px;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 3px 6px #0000000d;
`;

const SignupForm = (props) => {
  const { userStore } = rootStore;
  const [user, setUser] = useState({ name: "", email: "" });
  const handleClick = () => {
    props.setActiveStep(1);
    userStore.createUser({ name: user.name, email: user.email });
  };
  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  return (
    <>
      <Container>
        <Header>Create account to become a teacher</Header>
        <SubHeader>Fill in the below details</SubHeader>
        <Form>
          <InputContainer>
            <InputWrapper>
              <Label>Full Name*</Label>
              <Input
                type="text"
                placeholder="enter your name"
                name="name"
                onChange={onChange}
              />
            </InputWrapper>
            <InputWrapper>
              <Label>Email-ID*</Label>
              <Input
                type="email"
                placeholder="enter your email"
                name="email"
                onChange={onChange}
              />
            </InputWrapper>
            <InputWrapper>
              <Label>Company*</Label>
              <Input
                type="email"
                placeholder="enter your email"
                name="email"
                onChange={onChange}
              />
            </InputWrapper>
          </InputContainer>
          <ButtonWrapper>
            <Button onClick={handleClick}>Continue</Button>
          </ButtonWrapper>
        </Form>
      </Container>
    </>
  );
};

export default observer(SignupForm);
