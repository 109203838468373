import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { DatePicker } from "antd";
import MultiselectDropdown from "../../multiselect_dropdown/MultiselectDropdown";
import NewMultiselectDropdown from "../../multiselect_dropdown/NewMultiselectDropdown";
import { useEffect } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { consoleLogger } from "../../../Functions";

const Label = styled.span`
  font: normal normal normal 12px Open Sans;
  color: black;
`;
const Input = styled.input`
  width: 100%;
  height: 30px;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  color: #4d4e4f;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal normal 12px Open Sans;
    color: #b5b5b5;
    text-transform: lowercase;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  text-align: left;
  margin: auto;
  background-color: white;
  height: 320px;

  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;
const InputContainer = styled.div`
  display: flex;
  width: fit-content;
  flex-wrap: wrap;
  width: 400px;
  gap: 20px;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 190px;
  overflow: hidden;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const ExportRawLeadFilterScreen = (props) => {
  const { RangePicker } = DatePicker;

  const handleChange = ({ e, dataType }) => {
    let value =
      dataType === "integer" ? parseInt(e.target.value) : e.target.value;
    props.setFilters({
      ...props.filters,
      [e.target.name]: { value: value, type: dataType },
    });
  };

  function truncate(str, n) {
    return str.length > n ? str.slice(0, n - 1) + "..." : str;
  }

  useEffect(() => {
    consoleLogger(props.columnsList);
  }, []);

  return (
    <>
      <Container>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {props.columnsList.length > 0 ? (
            <InputContainer>
              {props.columnsList.map((item) => {
                if (item.filterable) {
                  if (item.filter_type === "normal") {
                    if (item.data_type !== "boolean") {
                      if (
                        (item.data_type === "date" ||
                          item.data_type === "datetime") &&
                        props.filters.hasOwnProperty(item.column_name)
                      ) {
                        let value = props.filters[item.column_name]["value"];
                        return (
                          <InputWrapper>
                            <Label>{truncate(item.display_name, 20)}</Label>
                            <Input
                              type={
                                item.data_type === "email"
                                  ? "email"
                                  : item.data_type === "datetime" ||
                                    item.data_type === "date"
                                  ? "date"
                                  : item.data_type === "integer"
                                  ? "number"
                                  : "text"
                              }
                              name={item.column_name}
                              value={value}
                              placeholder={`enter ${item.display_name}`}
                              //   style={{ width: "170px" }}
                              onChange={(e) => {
                                let dataType = item.data_type;
                                handleChange({ e, dataType });
                              }}
                            />
                          </InputWrapper>
                        );
                      }
                      return (
                        <InputWrapper>
                          <Label>{truncate(item.display_name, 20)}</Label>
                          <Input
                            type={
                              item.data_type === "email"
                                ? "email"
                                : item.data_type === "datetime" ||
                                  item.data_type === "date"
                                ? "date"
                                : item.data_type === "integer"
                                ? "number"
                                : "text"
                            }
                            name={item.column_name}
                            value={
                              props.filters.hasOwnProperty(item.column_name)
                                ? props.filters[item.column_name]["value"]
                                : ""
                            }
                            placeholder={`enter ${item.display_name}`}
                            onChange={(e) => {
                              let dataType = item.data_type;
                              handleChange({ e, dataType });
                            }}
                          />
                        </InputWrapper>
                      );
                    } else {
                      return (
                        <InputWrapper
                          style={{
                            padding: "0px 4px",
                          }}
                        >
                          <Label>{truncate(item.display_name, 20)}</Label>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio size="small" />}
                              checked={
                                props.filters.hasOwnProperty(item.column_name)
                                  ? props.filters[item.column_name]["value"] ===
                                    true
                                    ? true
                                    : false
                                  : false
                              }
                              label={
                                <Label style={{ fontSize: "12px" }}>True</Label>
                              }
                              onChange={() => {
                                props.setFilters({
                                  ...props.filters,
                                  [item.column_name]: {
                                    value: true,
                                    type: item.data_type,
                                  },
                                });
                              }}
                            />
                            <FormControlLabel
                              value={false}
                              checked={
                                props.filters.hasOwnProperty(item.column_name)
                                  ? props.filters[item.column_name]["value"] ===
                                    false
                                    ? true
                                    : false
                                  : false
                              }
                              onChange={() => {
                                props.setFilters({
                                  ...props.filters,
                                  [item.column_name]: {
                                    value: false,
                                    type: item.data_type,
                                  },
                                });
                              }}
                              control={<Radio size="small" />}
                              label={
                                <Label style={{ fontSize: "12px" }}>
                                  False
                                </Label>
                              }
                            />
                          </RadioGroup>
                        </InputWrapper>
                      );
                    }
                  } else if (item.filter_type === "range") {
                    if (
                      item.data_type === "datetime" ||
                      item.data_type === "date"
                    ) {
                      {
                        return (
                          <InputWrapper style={{ width: "100%" }}>
                            <Label>{truncate(item.display_name, 20)}</Label>
                            <RangePicker
                              placeholder={["From", "To"]}
                              getPopupContainer={(triggerNode) => {
                                return triggerNode.parentNode;
                              }}
                              defaultValue={
                                props.dateRange.hasOwnProperty(
                                  item.column_name
                                ) && [
                                  moment(
                                    props.dateRange[item["column_name"]][
                                      "start"
                                    ],
                                    "YYYY-MM-DD"
                                  ),
                                  moment(
                                    props.dateRange[item["column_name"]]["end"],
                                    "YYYY-MM-DD"
                                  ),
                                ]
                              }
                              style={{ height: "30px", width: "100%" }}
                              onChange={(value) => {
                                let d1 = moment(value[0]).format("YYYY-MM-DD");
                                let d2 = moment(value[1]).format("YYYY-MM-DD");
                                props.setDateRange({
                                  ...props.dateRange,
                                  [item.column_name]: { start: d1, end: d2 },
                                });
                              }}
                            />
                          </InputWrapper>
                        );
                      }
                    } else if (item.data_type === "integer") {
                      return (
                        <InputWrapper style={{ width: "100%" }}>
                          <Label>{truncate(item.display_name, 20)}</Label>
                          <Row>
                            <Input
                              type="number"
                              name="start"
                              placeholder="min"
                              value={
                                props.numRange.hasOwnProperty(item.column_name)
                                  ? props.numRange[item.column_name]["start"]
                                  : ""
                              }
                              onChange={(e) => {
                                let start = parseInt(e.target.value);
                                props.setNumRange({
                                  ...props.numRange,
                                  [item.column_name]: { start: start },
                                });
                              }}
                              style={{
                                marginRight: "16px",
                              }}
                            />
                            <Input
                              type="number"
                              name="end"
                              placeholder="max"
                              value={
                                props.numRange.hasOwnProperty(item.column_name)
                                  ? props.numRange[item.column_name]["end"]
                                  : ""
                              }
                              onChange={(e) => {
                                let start = parseInt(
                                  props.numRange[item.column_name]["start"]
                                );
                                let end = parseInt(e.target.value);
                                props.setNumRange({
                                  ...props.numRange,
                                  [item.column_name]: {
                                    start: start,
                                    end: end,
                                  },
                                });
                              }}
                            />
                          </Row>
                        </InputWrapper>
                      );
                    }
                  } else if (item.filter_type === "list") {
                    if (
                      item.filter_input.charAt(0) === "/" ||
                      item.filter_input.startsWith("ep:")
                    ) {
                      return (
                        <MultiselectDropdown
                          item={item}
                          type="report"
                          headerName={item.display_name}
                          setSelectedVal={props.setSelectedVal}
                          selectedVal={props.selectedVal}
                          field={item.column_name}
                        />
                      );
                    } else if (
                      item.filter_input !== "" &&
                      item.filter_input.charAt(0) !== "/"
                    ) {
                      return (
                        <NewMultiselectDropdown
                          type="report"
                          list={item.filter_input}
                          field={item.column_name}
                          headerName={item.display_name}
                          setSelectedVal={props.setSelectedVal}
                          selectedVal={props.selectedVal}
                        />
                      );
                    } else if (
                      item.filter_input === "" ||
                      item.filter_input === null
                    ) {
                      return (
                        <InputWrapper>
                          <Label>{truncate(item.display_name, 20)}</Label>
                          <Input
                            type={
                              item.data_type === "email"
                                ? "email"
                                : item.data_type === "datetime" ||
                                  item.data_type === "date"
                                ? "date"
                                : item.data_type === "integer"
                                ? "number"
                                : "text"
                            }
                            name={item.column_name}
                            placeholder={`enter ${item.display_name}`}
                            //   style={{ width: "170px" }}
                            onChange={(e) => {
                              let dataType = item.data_type;
                              handleChange({ e, dataType });
                            }}
                          />
                        </InputWrapper>
                      );
                    }
                  }
                }
              })}
            </InputContainer>
          ) : (
            <InputContainer
              style={{
                height: "100%",
                width: "400px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </InputContainer>
          )}
        </Form>
      </Container>
    </>
  );
};

export default observer(ExportRawLeadFilterScreen);
