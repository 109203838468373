import { Box } from "@material-ui/core";
import React from "react";
import ColumnMappingScreen from "../external_webform_column_mapping_screens/ColumnMappingScreen";

import DefaultParameterScreen from "../external_webform_column_mapping_screens/DefaultParameterScreen";
import { Divider } from "@mui/material";

const DefaultParamsAndMappingScreen = ({
  formData,
  formAndProjectColumnMapping,
  setFormAndProjectColumnMapping,
  selectedDefaultParameterMapping,
  setSelectedDefaultParameterMapping,
  selectedProjectID,
  projectColumnList,
  memberList,
  statusList,
  stageList,
  sourceList,
  isAlreadySelectedProjectField
}) => {
  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <DefaultParameterScreen
        formData={formData}
        selectedDefaultParameterMapping={selectedDefaultParameterMapping}
        setSelectedDefaultParameterMapping={setSelectedDefaultParameterMapping}
        memberList={memberList}
        sourceList={sourceList}
        stageList={stageList}
        statusList={statusList}
      />
      <Divider sx={{ margin: "16px" }} />
      <ColumnMappingScreen
        formAndProjectColumnMapping={formAndProjectColumnMapping}
        formData={formData}
        projectColumnList={projectColumnList}
        projectID={selectedProjectID}
        setFormAndProjectColumnMapping={setFormAndProjectColumnMapping}
      isAlreadySelectedProjectField={isAlreadySelectedProjectField}
      />
    </Box>
  );
};

export default DefaultParamsAndMappingScreen;
