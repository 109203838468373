import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Grid, AppBar, Box, Divider, Toolbar, Typography } from "@mui/material";
import { capitalizeWord, dataRenderer } from "../../../Functions";

const ModelWrapper = styled(Box)`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 24px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
`;

const Title = styled(Typography)(({ theme }) => ({
  font: "normal normal 400 14px Open Sans",
  color: "#5A606680",
}));
const Desc = styled(Typography)(({ theme }) => ({
  font: "normal normal 500 14px Open Sans",
  color: "#5A6066",
  wordWrap: "break-word",
}));

const CampaignRecordDataPanel = ({ data }) => {
  const [details, setDetails] = useState({});

  function sortObjectByKeys(obj) {
    const sortedKeys = Object.keys(obj).sort();
    const sortedObj = {};
    sortedKeys.forEach((key) => {
      sortedObj[key] = obj[key];
    });

    return sortedObj;
  }

  useEffect(() => {
    let sortedData = sortObjectByKeys(data);
    setDetails(sortedData);
  }, []);
  return (
    <Box sx={{ width: 500 }}>
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          width: 500,
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar>
          <ModelHeader>Record Details</ModelHeader>
        </Toolbar>
      </AppBar>
      <ModelWrapper>
        {Object.keys(details).map((key, i) => {
          return (
            <Box key={i}>
              <Box
                style={{
                  marginTop: "8px",
                  marginBottom: "8px",
                }}
              >
                <Grid container alignItems="center">
                  <Grid item xs={4}>
                    <Title>{capitalizeWord(key)}</Title>
                  </Grid>
                  <Grid item xs={1}>
                    <Divider
                      orientation="vertical"
                      sx={{ height: "20px", color: "#3535354D" }}
                    />
                  </Grid>
                  <Grid item xs={7} paddingLeft={"8px"}>
                    <Desc>{dataRenderer(details[key])}</Desc>
                  </Grid>
                </Grid>
              </Box>
              <Divider orientation="horizontal" />
            </Box>
          );
        })}
      </ModelWrapper>
    </Box>
  );
};

export default CampaignRecordDataPanel;
