import React from "react";
import { Modal } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { Box, Button, Divider, OutlinedInput, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { myTheme } from "../../../themeUtils";
import { useState } from "react";
import rootStore from "../../../stores/RootStore";
const ModalContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 16px;
  margin: auto;
  height: fit-content;
  width: 264px;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 20px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  column-gap: 10px;
  margin-top: 20px;
`;
const Label = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;

const SaveFilterModal = (props) => {
  const [filterName, setFilterName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    rootStore.userStore.updateSavedFilterTemplateList({
      fitlerName: filterName,
      viewId: props.viewId,
      filters: props.filters,
    });
    rootStore.userStore.updateCurrentLoadedFilter({
      name: filterName,
      viewId: props.viewId,
      filters: props.filters,
    });
    props.setOpen(false);
  };
  return (
    <Modal
      open={props.open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContainer>
        <Wrapper component={"form"} onSubmit={handleSubmit}>
          <Header>Save Filter</Header>
          <Divider style={{ margin: "10px 0px" }} />
          <InputContainer>
            <InputWrapper>
              <Label>Filter Name</Label>
              <OutlinedInput
                onChange={(e) => {
                  setFilterName(e.target.value);
                }}
                value={filterName}
                placeholder="Enter Name"
                style={{
                  width: "100%",
                  height: "30px",
                  fontSize: "12px",
                }}
              />
            </InputWrapper>
          </InputContainer>
          <ButtonWrapper>
            <ButtonWrapper>
              <Button
                onClick={() => {
                  props.setOpen(false);
                }}
                variant="contained"
                color="inherit"
                style={{
                  font: "normal normal 600 10px Open Sans",
                  textTransform: "none",
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                style={{
                  font: "normal normal 600 10px Open Sans",
                  width: "fit-content",
                  textTransform: "none",
                  ...myTheme.Button.btnBlue,
                }}
              >
                Save
              </Button>
            </ButtonWrapper>
          </ButtonWrapper>
        </Wrapper>
      </ModalContainer>
    </Modal>
  );
};

export default observer(SaveFilterModal);
