import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Toolbar,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { OutlinedInput, Typography } from "@mui/material";
import { getLocalizedText } from "../../../../Functions";

const dialogWidth = 420;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const AddNewMappingFieldDialog = ({
  open,
  setOpen,
  handlePostAdd,
  isEdit,
  seiIsEdit,
  editableFieldName,
  handlePostEdit,
}) => {
  const [fieldName, setFieldName] = useState("");

  const handleSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (isEdit) {
      handlePostEdit(fieldName);
    } else {
      handlePostAdd(fieldName);
    }
  };
  const handleClose = () => {
    seiIsEdit(false);
    setOpen(false);
  };

  useEffect(() => {
    if (isEdit) {
      setFieldName(editableFieldName);
    }
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box component={"form"} onSubmit={handleSubmit}>
            <AppBar
              component={"nav"}
              elevation={0}
              position="sticky"
              color="inherit"
              sx={{
                top: 0,
                bottom: "auto",
                width: "100%",
                right: 0,
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                }}
              >
                <HeaderWrapper>
                  {isEdit ? (
                    <Header>Edit Field</Header>
                  ) : (
                    <Header>Create A New Field</Header>
                  )}
                </HeaderWrapper>
              </Toolbar>
            </AppBar>
            <Divider style={{ margin: "0px 16px" }} />
            <Container>
              <Box
                style={{
                  height: "100%",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Field Name*</Label>
                      <OutlinedInput
                        style={{ height: "30px" }}
                        inputProps={{
                          min: 0,
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        onChange={(e) => {
                          setFieldName(e.target.value);
                        }}
                        value={fieldName}
                        required
                        type={"text"}
                        name="title"
                        placeholder={"Enter Value"}
                      />
                    </InputWrapper>
                  </Grid>
                </Grid>
              </Box>
            </Container>
            <AppBar
              position="static"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: "100%" }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                  width: dialogWidth,
                  position: "relative",
                }}
              >
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      onClick={() => {
                        setOpen(false);
                      }}
                      variant="contained"
                      color="default"
                      style={{
                        fontSize: "12px",
                        textTransform: "none",
                        fontWeight: "bold",
                      }}
                    >
                      {getLocalizedText("cancel")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        width: "fit-content",
                        textTransform: "none",
                      }}
                    >
                      {isEdit ? "Update" : "Add"}
                    </Button>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default AddNewMappingFieldDialog;
