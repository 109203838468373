import React from "react";
import { styled } from "@mui/material/styles";
import logo from "../../assets/logo.png";
import { Box } from "@mui/material";
import rootStore from "../../stores/RootStore";
const Container = styled(Box)`
  display: flex;
  height: 64px;
  width: 100%;
  box-shadow: 0px 3px 6px #0000000d;
  position: sticky;
  top: 0px;
  left: 0px;
  right: 0px;
  background-color: white;
  z-index: 999;
  @media (max-width: 700px) {
    height: fit-content;
  }
`;
const Wrapper = styled(Box)`
  width: 80%;
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 700px) {
    flex-direction: column;
    width: 90vw;
    padding: 12px 0px;
  }
`;
const LogoWrapper = styled(Box)`
  display: flex;
  width: fit-content;
  justify-content: center;
  flex-direction: column;
`;

const Logo = styled("img")`
  max-height: 22px;
  width: auto;
  cursor: pointer;
`;
const FormAppbar = () => {
  const getLogoSource = () => {
    if (rootStore.userStore.UserData !== null) {
      if (rootStore.userStore.UserData.hasOwnProperty("account")) {
        let account_info = rootStore.userStore.UserData["account"];
        let logo_url = account_info.hasOwnProperty("logo_url")
          ? account_info["logo_url"]
          : "";
        return logo_url !== null && logo_url !== "" ? logo_url : logo;
      }
    }
    return logo;
  };
  return (
    <>
      <Container>
        <Wrapper>
          <LogoWrapper>
            <Logo src={getLogoSource()} alt="loading..." />
          </LogoWrapper>
        </Wrapper>
      </Container>
    </>
  );
};

export default FormAppbar;
