import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, FormControl, makeStyles, Select } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import { observer } from "mobx-react-lite";
import { DataGrid } from "@mui/x-data-grid";
import { AutoDialerCampaignColumnHeaders } from "../../Db";
import { getAgentListApi } from "../../Api";
const Header = styled.span`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
  margin-bottom: 20px;
`;
const SubHeader = styled.span`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
  margin-bottom: 30px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 340px;
  width: 428px;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const DateRangeWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 80px;
  align-items: center;
`;
const DateRange = styled.div`
  display: flex;
  flex-direction: column;
`;
const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: -14px;
  margin-left: 8px;
`;
const StartDate = styled.p`
  width: fit-content;
  margin: 0px;
  font: normal normal 500 12px Open Sans;
  /* border: 1px solid gray; */
`;
const EndDate = styled.p`
  font: normal normal 500 12px Open Sans;
  margin: 0px;

  /* border: 1px solid gray; */

  width: fit-content;
`;
const Label = styled.span`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Info = styled.span`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Detail = styled.span`
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;
const Input = styled.input`
  width: 428px;
  height: 30px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;
  border: 1px solid #bfbfbf;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const TextArea = styled.div`
  width: 428px;
  height: 60px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #bfbfbf;
  color: #4d4e4f;
  resize: none;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  width: 180px;
  @media (max-width: 700px) {
    width: 140px;
  }
`;
const SelectColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;

const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

function ampm(time) {
  if (time.value !== "") {
    var hours = time.split(":")[0];
    var minutes = time.split(":")[1];
    var suffix = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    var displayTime = hours + ":" + minutes + " " + suffix;
    return displayTime;
  }
  return;
}

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const AutoDialerCampaign = (props) => {
  const classes = useStyles();
  const [agentList, setAgentList] = useState([]);
  const [campaignType, setCampaignType] = useState("");
  const getAgentList = async (campaign_type) => {
    let payload = {
      dialer: props.provider,
      scope: campaign_type,
    };
    let response = await getAgentListApi(payload);
    setAgentList(response);
  };
  const handleCampaign = (e) => {
    setCampaignType(e.target.value);
    props.setAutodialerCampaignType(e.target.value);
    getAgentList(e.target.value);
  };
  useEffect(() => {
    props.setAgentList([]);
    props.setAutodialerCampaignType("");
  }, []);

  return (
    <>
      <InputContainer>
        <SelectColumn>
          <Label>Select Auto Dialer Campaign Type</Label>
          <FormControl
            sx={{ m: 1, minWidth: 428 }}
            classes={{
              root: classes.quantityRoot,
            }}
          >
            <Select
              classes={{
                icon: classes.icon,
              }}
              name="segment"
              displayEmpty
              disableUnderline
              variant="outlined"
              required
              onChange={(e) => {
                handleCampaign(e);
              }}
              style={{
                marginBottom: "20px",
                width: "428px",
                height: "30px",
                fontSize: "12px",
                fontWeight: "bold",
                borderRadius: "0px",
              }}
            >
              {props.autoDialerCampaignTypeList.map((e) => {
                return (
                  <MenuItem id={e} value={e.value}>
                    {e.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </SelectColumn>
        {campaignType === "agent_wise" && (
          <TableWrapper>
            <Label>Select Agents</Label>
            <DataGrid
              autoHeight={true}
              rows={agentList}
              columns={AutoDialerCampaignColumnHeaders}
              checkboxSelection
              style={{ cursor: "pointer" }}
              hideFooter={true}
              disableColumnMenu={true}
              onSelectionModelChange={(selectedRowsID) => {
                const selectedData = selectedRowsID.map((rowId) => {
                  const row = agentList.find((r) => r.id === rowId);
                  return row;
                });
                props.setAgentList(selectedData);
              }}
            />
          </TableWrapper>
        )}
      </InputContainer>
    </>
  );
};

export default observer(AutoDialerCampaign);
