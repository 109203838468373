import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, Grid } from "@material-ui/core";
import {
  AppBar,
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  Select as MuiSelect,
  OutlinedInput,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MuiAlert from "@mui/material/Alert";
import { taskPriorityList, taskStatusList } from "../../Db";
import { LoadingButton } from "@mui/lab";
import { getLocalizedText } from "../../Functions";
import { createTaskApi, editTaskApi, getTaskTypeListApi } from "../../Api";
import moment from "moment";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  gap: 20px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CustomizedDateTimePicker = styled(DateTimePicker)`
  & .MuiInputBase-input {
    font-size: 12px;
    padding: 7.5px 14px;
  }
`;

const CreateTaskComponent = ({
  setOpen,
  setRefresh,
  setIsEdit,
  isEdit,
  editableData,
}) => {
  const [loading, setLoading] = useState(true);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [taskTypeList, setTaskTypeList] = useState([]);
  const [details, setDetails] = useState({
    title: "",
    start_time: null,
    end_time: null,
    priority: "Medium",
    task_type_id: "",
    desc: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setIsEdit(false);
    setOpen(false);
  };
  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const createTask = async (payload) => {
    setIsSubmitting(true);
    let response = await createTaskApi({
      payload: payload,
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
    setIsSubmitting(false);
  };

  const editTask = async (payload) => {
    setIsSubmitting(true);
    let response = await editTaskApi({
      taskID: editableData["id"],
      payload: payload,
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
    setIsSubmitting(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let payload = { ...details };

    if (details["start_time"] !== null && details["end_time"] !== null) {
      if (isStartBeforeOrEqualEnd(details["start_time"], details["end_time"])) {
        if (isEdit) {
          await editTask(payload);
        } else {
          await createTask(payload);
        }
      } else {
        setErrorMessage("Invalid Start and End Time!");
        setIsSubmitFail(true);
      }
    } else {
      if (isEdit) {
        await editTask(payload);
      } else {
        await createTask(payload);
      }
    }
  };

  const getTaskTypeList = async () => {
    let response = await getTaskTypeListApi();
    setTaskTypeList(response);
  };

  const init = async () => {
    await getTaskTypeList();
    if (isEdit) {
      let tempObj = {
        title:
          editableData.hasOwnProperty("title") && editableData["title"] !== null
            ? editableData["title"]
            : "",
        desc:
          editableData.hasOwnProperty("desc") && editableData["desc"] !== null
            ? editableData["desc"]
            : "",
        remarks:
          editableData.hasOwnProperty("remarks") &&
          editableData["remarks"] !== null
            ? editableData["remarks"]
            : "",
        task_status:
          editableData.hasOwnProperty("task_status") &&
          editableData["task_status"] !== null
            ? editableData["task_status"]
            : "",
        priority:
          editableData.hasOwnProperty("priority") &&
          editableData["priority"] !== null
            ? editableData["priority"]
            : "Medium",
        start_time:
          editableData.hasOwnProperty("start_time") &&
          editableData["start_time"] !== null
            ? moment(editableData["start_time"])
            : null,
        end_time:
          editableData.hasOwnProperty("end_time") &&
          editableData["end_time"] !== null
            ? moment(editableData["end_time"])
            : null,
      };

      if (
        editableData.hasOwnProperty("task_type") &&
        editableData["task_type"] !== null
      ) {
        tempObj["task_type_id"] = editableData["task_type"]["id"];
      }
      setDetails(tempObj);
    }
    setLoading(false);
  };

  function isStartBeforeOrEqualEnd(start, end) {
    const startTime = moment(start.toISOString());
    const endTime = moment(end.toISOString());
    return startTime.isSameOrBefore(endTime);
  }

  const getEndTimeForPrefill = (taskID, startTime) => {
    let endTime = null;
    if (taskID !== null) {
      const taskTypeDetails = taskTypeList.find((x) => x.id === taskID);

      if (
        startTime !== null &&
        taskTypeDetails !== null &&
        taskTypeDetails !== undefined
      ) {
        endTime = moment(startTime).add(
          taskTypeDetails["default_duration"],
          "minutes"
        );
      }
    }
    return endTime != null ? moment(endTime.toISOString()) : null;
  };

  useEffect(() => {
    init();
  }, []);

  return !loading ? (
    <Box role="presentation">
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          width: 500,
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar>
          {isEdit ? (
            <ModelHeader>Edit Task</ModelHeader>
          ) : (
            <ModelHeader>Create Task</ModelHeader>
          )}
        </Toolbar>
      </AppBar>
      {!isSubmitSuccess ? (
        <ModelWrapper component={"form"} onSubmit={handleSubmit}>
          <InputContainer>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputWrapper>
                  <Label>Title*</Label>
                  <OutlinedInput
                    style={{ height: "30px" }}
                    inputProps={{
                      min: 0,
                      "aria-label": "weight",
                      style: {
                        fontSize: "12px",
                      },
                    }}
                    onChange={handleChange}
                    name="title"
                    required
                    value={details["title"]}
                    placeholder="Enter value"
                  />
                </InputWrapper>
              </Grid>
              {isEdit === false && (
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>Task Type*</Label>
                    <FormControl sx={{ width: "100%" }}>
                      <MuiSelect
                        displayEmpty
                        required
                        name="task_type_id"
                        onChange={(e) => {
                          const endTime = getEndTimeForPrefill(
                            e.target.value,
                            details.start_time
                          );
                          setDetails({
                            ...details,
                            task_type_id: e.target.value,
                            end_time: endTime,
                          });
                        }}
                        disableUnderline
                        variant="outlined"
                        value={details["task_type_id"]}
                        style={{
                          width: "100%",
                          height: "30px",
                          fontSize: "12px",
                          borderRadius: "0px",
                        }}
                      >
                        {taskTypeList.map((item, i) => {
                          return (
                            <MenuItem
                              value={item["id"]}
                              key={i}
                              style={{ fontSize: "12px" }}
                            >
                              {item["task_name"]}
                            </MenuItem>
                          );
                        })}
                      </MuiSelect>
                    </FormControl>
                  </InputWrapper>
                </Grid>
              )}
              <Grid item xs={isEdit ? 12 : 6}>
                <InputWrapper>
                  <Label>Task Priority</Label>
                  <FormControl sx={{ width: "100%" }}>
                    <MuiSelect
                      displayEmpty
                      onChange={handleChange}
                      name="priority"
                      disableUnderline
                      variant="outlined"
                      value={details["priority"]}
                      style={{
                        width: "100%",
                        height: "30px",
                        fontSize: "12px",
                        borderRadius: "0px",
                      }}
                    >
                      {taskPriorityList.map((item, i) => {
                        return (
                          <MenuItem
                            value={item}
                            key={i}
                            style={{ fontSize: "12px" }}
                          >
                            {item}
                          </MenuItem>
                        );
                      })}
                    </MuiSelect>
                  </FormControl>
                </InputWrapper>
              </Grid>

              <Grid item xs={6}>
                <InputWrapper>
                  <Label>{getLocalizedText("start_time")}</Label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CustomizedDateTimePicker
                      value={details.start_time}
                      onChange={(event) => {
                        const startTime = moment(event.toISOString());
                        const endTime = getEndTimeForPrefill(
                          details.task_type_id,
                          startTime
                        );
                        setDetails({
                          ...details,
                          start_time: startTime,
                          end_time: endTime,
                        });
                      }}
                      inputFormat="YYYY-MM-DD HH:mm A"
                      renderInput={(params) => (
                        <TextField
                          sx={{ width: "100%" }}
                          size="small"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </InputWrapper>
              </Grid>
              <Grid item xs={6}>
                <InputWrapper>
                  <Label>{getLocalizedText("end_time")}</Label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CustomizedDateTimePicker
                      value={details.end_time}
                      onChange={(event) => {
                        setDetails({
                          ...details,
                          end_time: moment(event.toISOString()),
                        });
                      }}
                      inputFormat="YYYY-MM-DD HH:mm A"
                      disabled={details.start_time === null}
                      renderInput={(params) => (
                        <TextField
                          required={details.start_time !== null}
                          sx={{ width: "100%" }}
                          size="small"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </InputWrapper>
              </Grid>
              {isEdit === false && (
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>Description</Label>
                    <OutlinedInput
                      multiline
                      rows={3}
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      onChange={handleChange}
                      name="desc"
                      value={details["desc"]}
                      placeholder="Enter value"
                    />
                  </InputWrapper>
                </Grid>
              )}
            </Grid>
          </InputContainer>

          <AppBar
            position="fixed"
            color="inherit"
            elevation={0}
            sx={{ top: "auto", bottom: 0, width: 500 }}
          >
            <Toolbar style={{ width: 500, position: "relative" }}>
              {isSubmitFail && (
                <ErrorAlert
                  onClose={(e) => {
                    e.stopPropagation();
                    setIsSubmitFail(false);
                  }}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  {errorMessage}
                </ErrorAlert>
              )}
              <ButtonWrapper>
                <Button
                  onClick={handleClose}
                  type="button"
                  variant="contained"
                  color="default"
                  style={{
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  Cancel
                </Button>
                {isSubmitting ? (
                  <LoadingButton
                    loading
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: "none",
                      width: "100%",
                    }}
                  >
                    <span>Submit</span>
                  </LoadingButton>
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    Submit
                  </Button>
                )}
              </ButtonWrapper>
            </Toolbar>
          </AppBar>
        </ModelWrapper>
      ) : (
        <MessageWrapper>
          {isEdit ? (
            <Message>Task updated successfully!</Message>
          ) : (
            <Message>Task created successfully!</Message>
          )}

          <Button
            onClick={handleClose}
            type="button"
            variant="contained"
            color="default"
            style={{
              marginTop: "20px",
              textTransform: "none",
              fontSize: "12px",
              fontWeight: "bold",
              width: "100px",
            }}
          >
            Close
          </Button>
        </MessageWrapper>
      )}
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "500px",
        height: "100%",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default CreateTaskComponent;
