import React from "react";
import RadioGroupOptionWidget from "./RadioGroupOptionWidget";
import RadioGroupWithOtherOptionWidget from "./RadioGroupWithOtherOptionWidget";
import CheckboxOptionWidget from "./CheckboxOptionWidget";
import DropdownOptionWidget from "./DropdownOptionWidget";
import CheckboxWithOtherOptionWidget from "./CheckboxWithOtherOptionWidget";

const RenderOptionFields = ({
  columnMeta,
  questionData,
  inputData,
  setInputData,
  listFieldInputMeta,
}) => {
  if (questionData["question_type"] === "radio") {
    return (
      <RadioGroupOptionWidget
            list={listFieldInputMeta.hasOwnProperty(columnMeta['field'])?listFieldInputMeta[columnMeta["field"]].list:[]}
            type={listFieldInputMeta.hasOwnProperty(columnMeta['field'])?listFieldInputMeta[columnMeta["field"]].type:'list'}
        value={
          inputData.hasOwnProperty(columnMeta["field"])
            ? inputData[columnMeta["field"]]["value"]
            : ""
        }
        handleChange={(value) => {
          setInputData({
            ...inputData,
            [columnMeta["field"]]: {
              value: value,
              type: questionData["question_type"],
            },
          });
        }}
      />
    );
  } else if (questionData["question_type"] === "radio_with_other") {
    return (
      <RadioGroupWithOtherOptionWidget
        list={listFieldInputMeta.hasOwnProperty(columnMeta['field'])?listFieldInputMeta[columnMeta["field"]].list:[]}
        type={listFieldInputMeta.hasOwnProperty(columnMeta['field'])?listFieldInputMeta[columnMeta["field"]].type:'list'}
        fieldData={
          inputData.hasOwnProperty(columnMeta["field"])
            ? inputData[columnMeta["field"]]
            : {}
        }
        value={
          inputData.hasOwnProperty(columnMeta["field"])
            ? inputData[columnMeta["field"]]["value"]
            : ""
        }
        handleChange={(value, otherValue) => {
          setInputData({
            ...inputData,
            [columnMeta["field"]]: {
              value: value,
              type: questionData["question_type"],
              other_value: otherValue,
            },
          });
        }}
      />
    );
  } else if (questionData["question_type"] === "checkbox") {
    return (
      <CheckboxOptionWidget
             list={listFieldInputMeta.hasOwnProperty(columnMeta['field'])?listFieldInputMeta[columnMeta["field"]].list:[]}
             type={listFieldInputMeta.hasOwnProperty(columnMeta['field'])?listFieldInputMeta[columnMeta["field"]].type:'list'}
        value={
          inputData.hasOwnProperty(columnMeta["field"])
            ? inputData[columnMeta["field"]]["value"]
            : []
        }
        handleChange={(value) => {
          setInputData({
            ...inputData,
            [columnMeta["field"]]: {
              value: value,
              type: questionData["question_type"],
            },
          });
        }}
      />
    );
  } else if (questionData["question_type"] === "checkbox_with_other") {
    return (
      <CheckboxWithOtherOptionWidget
            list={listFieldInputMeta.hasOwnProperty(columnMeta['field'])?listFieldInputMeta[columnMeta["field"]].list:[]}
            type={listFieldInputMeta.hasOwnProperty(columnMeta['field'])?listFieldInputMeta[columnMeta["field"]].type:'list'}
        fieldData={
          inputData.hasOwnProperty(columnMeta["field"])
            ? inputData[columnMeta["field"]]
            : {}
        }
        value={
          inputData.hasOwnProperty(columnMeta["field"])
            ? inputData[columnMeta["field"]]["value"]
            : []
        }
        handleChange={(value, otherValue) => {
          setInputData({
            ...inputData,
            [columnMeta["field"]]: {
              value: value,
              type: questionData["question_type"],
              other_value: otherValue,
            },
          });
        }}
      />
    );
  } else if (questionData["question_type"] === "dropdown") {
    console.log('list field inuput meta::',listFieldInputMeta, "column field name:::",columnMeta['field'])
    return (
      <DropdownOptionWidget
             list={listFieldInputMeta.hasOwnProperty(columnMeta['field'])?listFieldInputMeta[columnMeta["field"]].list:[]}
             type={listFieldInputMeta.hasOwnProperty(columnMeta['field'])?listFieldInputMeta[columnMeta["field"]].type:'list'}
        value={
          inputData.hasOwnProperty(columnMeta["field"])
            ? inputData[columnMeta["field"]]["value"]
            : ""
        }
        handleChange={(event) => {
          setInputData({
            ...inputData,
            [columnMeta["field"]]: {
              value: event.target.value,
              type: questionData["question_type"],
            },
          });
        }}
      />
    );
  }
};

export default RenderOptionFields;
