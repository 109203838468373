import { Box, OutlinedInput } from "@mui/material";
import React from "react";

const NumberRangeWidget = ({
  minValue,
  maxValue,
  handleMaxValue,
  handleMinValue,
  disabled,
}) => {
  return (
    <Box sx={{ display: "flex", width: "100%", gap: "16px" }}>
      <OutlinedInput
        style={{ height: "30px", width: "100%" }}
        type="number"
        name="start"
        placeholder="min"
        value={minValue}
        onChange={(e) => handleMinValue(e)}
      />
      <OutlinedInput
        style={{ height: "30px", width: "100%" }}
        type="number"
        name="end"
        placeholder="max"
        value={maxValue}
        disabled={disabled}
        onChange={(e) => handleMaxValue(e)}
      />
    </Box>
  );
};

export default NumberRangeWidget;
