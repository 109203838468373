import { Box, Button, Modal } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CircularProgress, Divider } from "@mui/material";
import { getLocalizedText, getSlug } from "../../Functions";
import { OutlinedInput } from "@mui/material";
import { getListApi } from "../../Api";
import Select from "react-select";
import rootStore from "../../stores/RootStore";
import { filterOperatorMapList } from "../../Db";
import { observer } from "mobx-react-lite";
import LeadScopeValueFieldComponent from "./LeadScopeValueFieldComponent";

const ModelContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: 550px;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  padding: 20px;
`;
const ModelWrapper = styled.form`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
`;
const Header = styled.p`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled.p`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const Label = styled.p`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for options
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for the entire control container, including the search input
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "no options" message
  }),
  loadingMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "loading" message
  }),
};
const LeadScopeDialog = (props) => {
  const allColumnsList = [...rootStore.userStore.AllColumnsList];
  const [columnList, setColumnList] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [selectColumnValue, setSelectColumnValue] = useState(null);
  const [operatorList, setOperatorList] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [filterTypeListMeta, setFilterTypeListMeta] = useState({});
  const [filter, setFilter] = useState({});
  const [initLoading, setInitLoading] = useState(true);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let tempData = {
      filter: filter,
      operator: selectedOperator,
    };
    rootStore.leadStore.updateLeadTabFilter(tempData);
    handleClose();
  };
  const handleClose = () => {
    props.setOpen(false);
  };
  const init = async () => {
    let filteredColumnList = [];
    allColumnsList.forEach((item) => {
      if (item.filterable === true && item.is_multiline === false) {
        filteredColumnList.push(item);
      }
    });

    filteredColumnList.sort((a, b) =>
      a["headerName"].toLowerCase().localeCompare(b["headerName"].toLowerCase())
    );
    setColumnList(filteredColumnList);
    if (Object.keys(rootStore.leadStore.leadScopeFilter).length > 0) {
      await setupPrefillData({ columnList: filteredColumnList });
    }
    setInitLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  const getOperatorList = (item) => {
    if (item["parent"] === true) {
      return filterOperatorMapList["parent"];
    } else {
      if (item["child_of"] !== null) {
        return filterOperatorMapList["child"];
      } else {
        if (item["data_type"] === "date" || item["data_type"] === "datetime") {
          if (item["filter_type"] === "range") {
            return filterOperatorMapList["date_range"];
          } else {
            return filterOperatorMapList["date"];
          }
        } else if (item["data_type"] === "string") {
          if (item["filter_type"] === "list") {
            return filterOperatorMapList["list"];
          } else {
            return filterOperatorMapList["string"];
          }
        } else if (item["data_type"] === "integer") {
          if (item["filter_type"] === "range") {
            return filterOperatorMapList["integer_range"];
          } else if (item["filter_type"] === "list") {
            return filterOperatorMapList["list"];
          } else {
            return filterOperatorMapList["integer"];
          }
        }
      }
    }
    return [];
  };

  const getListDetailsToRender = async (filter_input) => {
    let tempType = "list";
    let tempList = [];
    if (filter_input.charAt(0) === "/" || filter_input.startsWith("ep:")) {
      let slug = getSlug(filter_input);
      let response = await getListApi({ slug: slug });
      if (response !== null) {
        if (Array.isArray(response)) {
          tempType = "list";
          tempList = response;
        } else if (typeof response === "object") {
          if (Object.keys(response).length > 0) {
            tempType = response["type"];
            tempList = response["values"];
          }
        }
      }
    } else if (
      filter_input.charAt(0) !== "/" &&
      filter_input !== "" &&
      filter_input !== null
    ) {
      try {
        let parseJSON = JSON.parse(filter_input);
        if (Array.isArray(parseJSON)) {
          tempType = "list";
          tempList = parseJSON;
        } else if (typeof parseJSON === "object") {
          if (Object.keys(parseJSON).length > 0) {
            tempType = parseJSON["type"];
            tempList = parseJSON["values"];
          }
        }
      } catch (error) {
        if (filter_input.length > 0) {
          let list = filter_input.split(",");
          tempType = "list";
          tempList = list;
        }
      }
    }
    return { list: tempList, type: tempType };
  };

  const getSelectColumnOption = () => {
    return columnList
      .filter(
        (column) => column["parent"] === false && column["child_of"] === null
      )
      .map((column) => ({
        label: column["headerName"],
        value: column["id"],
      }));
  };

  const setupPrefillData = async ({ columnList }) => {
    let filterData = rootStore.leadStore.leadScopeFilter["filter"];
    for (const key in filterData) {
      let index = columnList.findIndex((column) => column["field"] === key);
      if (index !== -1) {
        let columnMeta = columnList[index];
        if (columnMeta["filter_type"] === "list") {
          let filterTypeListMeta = await getListDetailsToRender(
            columnMeta["filter_input"]
          );

          setFilterTypeListMeta(filterTypeListMeta);
        }
        let tempList = getOperatorList(columnMeta);
        setOperatorList(tempList);
        setSelectedColumn(columnMeta);
        setSelectColumnValue({
          label: columnMeta["headerName"],
          value: columnMeta["id"],
        });
      }
    }
    setFilter(filterData);
    setSelectedOperator(rootStore.leadStore.leadScopeFilter["operator"]);
  };

  const isFilterValuePicked = () => {
    if (Object.keys(filter).length > 0) {
      let data = filter[selectedColumn["field"]];
      if (data["filter_type"] === "list") {
        return data["value"].length > 0;
      } else if (data["filter_type"] === "range") {
        return (
          data["value"].hasOwnProperty("start") &&
          data["value"].hasOwnProperty("end")
        );
      } else {
        return data["value"] !== null && data["value"] !== "";
      }
    }
    return false;
  };

  const isSubmitButtonEnabled = () => {
    if (isFilterValuePicked()) {
      return selectedOperator !== null;
    }
    return false;
  };

  return (
    <>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer
          style={{
            width: "400px",
          }}
        >
          {!initLoading ? (
            <Box>
              <ModelWrapper onSubmit={handleSubmit}>
                <HeaderWrapper>
                  <Header>Lead Scope</Header>
                  <SubHeader>Set the scope for leads in all views</SubHeader>
                </HeaderWrapper>
                <Divider style={{ margin: "10px 0px" }} />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    width: "100%",
                  }}
                >
                  <InputWrapper>
                    <Label>Select Column</Label>
                    <Select
                      options={getSelectColumnOption()}
                      value={selectColumnValue}
                      onChange={async (selectedOption) => {
                        if (selectedOption !== null) {
                          let index = columnList.findIndex(
                            (column) => column["id"] === selectedOption.value
                          );
                          if (index !== -1) {
                            let columnMeta = columnList[index];
                            if (columnMeta["filter_type"] === "list") {
                              let filterTypeListMeta =
                                await getListDetailsToRender(
                                  columnMeta["filter_input"]
                                );

                              setFilterTypeListMeta(filterTypeListMeta);
                            }
                            let tempList = getOperatorList(columnMeta);
                            setFilter({});
                            setSelectedOperator(null);
                            setOperatorList(tempList);
                            setSelectColumnValue(selectedOption);
                            setSelectedColumn(columnMeta);
                          }
                        } else {
                          setFilterTypeListMeta({});
                          setOperatorList([]);
                          setSelectedOperator(null);
                          setSelectedColumn(null);
                          setSelectColumnValue(null);
                          setFilter({});
                        }
                      }}
                      closeMenuOnSelect={true}
                      styles={customStyles}
                      isClearable
                    />
                  </InputWrapper>

                  <InputWrapper>
                    <Label>Select Operator</Label>
                    <Select
                      isDisabled={
                        operatorList.length === 0 || selectColumnValue === null
                      }
                      options={operatorList}
                      value={selectedOperator}
                      onChange={(selectedOption) => {
                        setSelectedOperator(selectedOption);
                      }}
                      closeMenuOnSelect={true}
                      styles={customStyles}
                      isClearable
                    />
                  </InputWrapper>

                  <InputWrapper>
                    <Label>Filter Value</Label>
                    {selectColumnValue === null ? (
                      <OutlinedInput
                        disabled
                        placeholder="Enter Value"
                        style={{
                          width: "100%",
                          height: "30px",
                          fontSize: "12px",
                        }}
                      />
                    ) : (
                      <LeadScopeValueFieldComponent
                        filter={filter}
                        setFilter={setFilter}
                        filterTypeListMeta={filterTypeListMeta}
                        selectedColumn={selectedColumn}
                      />
                    )}
                  </InputWrapper>
                </Box>
                <ButtonWrapper>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    disabled={
                      Object.keys(rootStore.leadStore.leadScopeFilter)
                        .length === 0
                    }
                    onClick={() => {
                      rootStore.leadStore.resetLeadTabFilter();
                      handleClose();
                    }}
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "fit-content",
                      textTransform: "none",
                    }}
                  >
                    Clear Scope
                  </Button>
                  <Box
                    sx={{
                      display: "flex",

                      gap: "16px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        props.setOpen(false);
                      }}
                      variant="contained"
                      color="inherit"
                      style={{
                        fontSize: "12px",
                        textTransform: "none",
                        fontWeight: "bold",
                      }}
                    >
                      {getLocalizedText("cancel")}
                    </Button>

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!isSubmitButtonEnabled()}
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        width: "fit-content",
                        textTransform: "none",
                      }}
                    >
                      Submit
                    </Button>
                  </Box>
                </ButtonWrapper>
              </ModelWrapper>
            </Box>
          ) : (
            <Box
              style={{
                width: "100%",
                height: "160px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </ModelContainer>
      </Modal>
    </>
  );
};

export default observer(LeadScopeDialog);
