import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import { getContactDetailsApi } from "../../Api";
import {
  IsoToLocalDate,
  IsoToLocalTime,
  checkFeaturePermission,
  getLocalizedText,
} from "../../Functions";
import { myTheme } from "../../themeUtils";
import { useNavigate } from "react-router";
import rootStore from "../../stores/RootStore";

const ModelWrapper = styled(Box)`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 24px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 8px;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const MessageWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  justify-content: center;
  align-items: center;
`;

const Message = styled(Typography)`
  font: normal normal normal 20px Open Sans;
  color: gray;
  margin: 0px;
`;
const BasicContactDetailsSidepanel = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const columnsList = [...rootStore.contactStore.columnsList];

  const getColumnValue = (field, data_type, data) => {
    if (data.hasOwnProperty(field)) {
      let value = data[field];
      if (value !== null && value !== "") {
        if (data_type === "date") {
          value = IsoToLocalDate(value);
        } else if (data_type === "datetime") {
          value = IsoToLocalTime(value);
        }
        if (field === "creator") {
          return value !== null ? value["name"] : "";
        }
      }
      return value !== null ? value : "";
    } else {
      return "";
    }
  };
  const generateDataToRender = ({ data }) => {
    let tempList = [];
    columnsList.forEach((column) => {
      let item = {
        display_name: column["display_name"],
        value: getColumnValue(column["column_name"], column["data_type"], data),
        column_name: column["column_name"],
      };
      tempList.push(item);
    });
    tempList.sort((a, b) => {
      let A = a.display_name.toLowerCase();
      let B = b.display_name.toLowerCase();
      if (A < B) {
        return -1;
      }
      if (A > B) {
        return 1;
      }
      return 0;
    });

    return tempList;
  };

  const getContactDetails = async () => {
    let response = await getContactDetailsApi({ contactID: props.contactID });
    return response;
  };
  const init = async () => {
    let details = await getContactDetails();

    let generatedData = generateDataToRender({
      data: details,
    });
    setDataList(generatedData);
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);
  return !loading ? (
    dataList.length > 0 ? (
      <Box sx={{ width: 500 }}>
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: 500,
            right: 0,
            bgcolor: "#f9f9fc",
          }}
        >
          <Toolbar>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <ModelHeader>Contact Details</ModelHeader>
              {checkFeaturePermission("contacts") && (
                <Button
                  onClick={() => {
                    navigate(`/contacts/${props.contactID}`);
                  }}
                  variant="outlined"
                  color="primary"
                  style={{
                    color: myTheme.Button.colorBlue,
                    textTransform: "none",
                  }}
                >
                  Go to contact
                </Button>
              )}
            </Box>
          </Toolbar>
        </AppBar>
        <ModelWrapper>
          <Grid container spacing={3}>
            {dataList.map((data) => {
              return (
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>{data["display_name"]}</Label>
                    <Detail>
                      {data["value"] !== "" ? data["value"] : "-"}
                    </Detail>
                  </InputWrapper>
                </Grid>
              );
            })}
          </Grid>
        </ModelWrapper>
      </Box>
    ) : (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: 500,
          height: "100%",
        }}
      >
        <MessageWrapper>
          <Message>No Data found.</Message>
          <Button
            onClick={() => {
              props.setOpen(false);
            }}
            color="primary"
            variant="outlined"
            style={{
              width: "fit-content",
              textTransform: "none",
            }}
          >
            {getLocalizedText("close")}
          </Button>
        </MessageWrapper>
      </Box>
    )
  ) : (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 500,
        height: "100%",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default BasicContactDetailsSidepanel;
