import { CircularProgress, Divider } from "@mui/material";
import React from "react";
import { useState } from "react";
import ReactDOM from "react-dom/client";

import styled from "styled-components";
import Footer from "../../components/landing_page_components/footer/Footer";

const Container = styled.div`
  margin-top: 64px;
  background-color: #f9f9fc;
  ${`min-height: calc(100vh - 140px);`};
`;
const Wrapper = styled.div`
  width: 95%;
  margin: auto;
  display: flex;
  flex-direction: column;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 40px;
  padding: 20px 0px;
`;
const Header = styled.p`
  margin: 0px;
  font: normal normal 600 28px Open Sans;
  color: #4d4e4f;
`;
const IframeWrapper = styled.div`
  display: flex;
  width: 100%;
  ${`min-height: calc(100vh - 240px);`};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
`;

const WebsiteTemplatePreview = () => {
  const [loading, setLoading] = useState(true);
  return (
    <>
      <Container>
        <Wrapper>
          <HeaderWrapper>
            <Header>Preview of the selected template</Header>
          </HeaderWrapper>
          <Divider />
          <IframeWrapper>
            {!loading ? null : (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "0px",
                  bottom: "0px",
                  left: "0px",
                  right: "0px",
                  margin: "auto",
                }}
              />
            )}
            <iframe
              onLoad={() => {
                setLoading(false);
              }}
              src="https://fs-website-sms.netlify.app/"
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                border: "none",
              }}
            />
          </IframeWrapper>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
};

export default WebsiteTemplatePreview;
