import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { Grid } from "@material-ui/core";
import { IsoToLocalDate, IsoToLocalTime } from "../../Functions";
import { Edit } from "@material-ui/icons";
import { useState } from "react";
import { useEffect } from "react";
import rootStore from "../../stores/RootStore";

const Label = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  margin-bottom: 8px;
  color: #979799;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const SectionWrapper = styled(Box)`
  padding: 16px;
  background: #f9f9fc 0% 0% no-repeat padding-box;
  flex-grow: 1;
  flex-direction: column;
  position: relative;
`;
const IconWrapper = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
`;
const OrganizationDetailsWidget = ({ details, setOpenEditPanel }) => {
  const [dataList, setDataList] = useState([]);
  const columnsList = rootStore.organizationStore.columnsList;
  const getColumnValue = (field, data_type) => {
    let data = details;

    if (data.hasOwnProperty(field)) {
      let value = data[field];
      if (value !== null && value !== "") {
        if (data_type === "date") {
          value = IsoToLocalDate(value);
        } else if (data_type === "datetime") {
          value = IsoToLocalTime(value);
        }
      }
      return value !== null ? value : "";
    } else {
      return "";
    }
  };
  const generateDataToRender = () => {
    let tempList = [];
    columnsList.forEach((column) => {
      let item = {
        display_name: column["display_name"],
        value: getColumnValue(column["column_name"], column["data_type"]),
        column_name: column["column_name"],
      };
      tempList.push(item);
    });
    tempList.sort((a, b) => {
      let A = a.display_name.toLowerCase();
      let B = b.display_name.toLowerCase();
      if (A < B) {
        return -1;
      }
      if (A > B) {
        return 1;
      }
      return 0;
    });

    setDataList(tempList);
  };
  useEffect(() => {
    generateDataToRender();
  }, []);

  return (
    <>
      <SectionWrapper>
        <IconWrapper>
          <Edit
            onClick={() => {
              setOpenEditPanel(true);
            }}
            color="primary"
            style={{ cursor: "pointer" }}
          />
        </IconWrapper>
        <Grid container spacing={3}>
          {dataList.map((data) => {
            return (
              <Grid item xs={12} md={4} lg={3} xl={2}>
                <InputWrapper>
                  <Label>{data["display_name"]}</Label>
                  <Detail>{data["value"] !== "" ? data["value"] : "-"}</Detail>
                </InputWrapper>
              </Grid>
            );
          })}
        </Grid>
      </SectionWrapper>
    </>
  );
};

export default OrganizationDetailsWidget;
