import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { AddBoxOutlined } from "@mui/icons-material";
import { Edit, Visibility } from "@material-ui/icons";

const TableHeaderCell = styled(TableCell)`
  font-size: 12px;
  padding: 8px 12px;
`;
const TableDataCell = styled(TableCell)`
  font-size: 12px;
  padding: 8px 12px;
`;
export default function TableComponent({
  tableData,
  setOpen,
  setSelectedProductData,
  setOpenEditProductModal,
  setOpenViewProductModal,
  isReadOnly,
}) {
  return (
    <TableContainer
      component={Paper}
      style={{
        maxHeight: "200px",
        overflowY: "auto",
        zIndex: "0",
        width: "100%",
      }}
    >
      <Table stickyHeader sx={{ width: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableHeaderCell>Name of item</TableHeaderCell>
            <TableHeaderCell align="right">Rate</TableHeaderCell>
            <TableHeaderCell align="right">Quantity</TableHeaderCell>
            <TableHeaderCell align="right">Amount</TableHeaderCell>
            {!isReadOnly && (
              <TableHeaderCell align="right">
                <AddBoxOutlined
                  onClick={() => {
                    setOpen(true);
                  }}
                  color="primary"
                  style={{
                    cursor: "pointer",
                    marginBottom: "-8px",
                  }}
                />
              </TableHeaderCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.length > 0 ? (
            tableData.map((row, i) => (
              <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableDataCell component="th" scope="row">
                  {row["product"]["name"]}
                </TableDataCell>
                <TableDataCell align="right">{row["rate"]}</TableDataCell>
                <TableDataCell align="right">{row["quantity"]}</TableDataCell>
                <TableDataCell align="right">{row["amount"]}</TableDataCell>
                <TableDataCell align="right">
                  <Visibility
                    onClick={() => {
                      setSelectedProductData(row);
                      setOpenViewProductModal(true);
                    }}
                    style={{
                      width: "18px",
                      height: "18px",
                      cursor: "pointer",
                      marginRight: "8px",
                    }}
                    color="primary"
                  />
                  {!isReadOnly && (
                    <Edit
                      onClick={() => {
                        setSelectedProductData(row);
                        setOpenEditProductModal(true);
                      }}
                      style={{
                        width: "18px",
                        height: "18px",
                        cursor: "pointer",
                      }}
                      color="primary"
                    />
                  )}
                </TableDataCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableHeaderCell colSpan={5} style={{ textAlign: "center" }}>
                no items.
              </TableHeaderCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
