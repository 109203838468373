import { Box, Card, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import ChildCardWidget from "./ChildCardWidget";
import AddChildCardWidget from "./AddChildCardWidget";
import { Grid } from "@material-ui/core";

const CardWrapper = styled(Card)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  padding: 16px;
  background-color: #f9f9fc;
  align-items: center;
  border-radius: 8px;
`;
const Text = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  margin: 0px;
  font-weight: 600;
  margin-bottom: 2px;
`;
const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const ParentValueCardWidget = ({
  cardInfo,
  onRemove,
  onEdit,
  onView,
  onAdd,
}) => {
  return (
    <CardWrapper>
      <Wrapper>
        <Text>Value : {cardInfo["parent_value"]}</Text>
        <Box sx={{ width: "100%" }}>
          <Grid container spacing={2}>
            {cardInfo["child_list"].map((childData, idx) => {
              return (
                <Grid item xs={6} key={idx}>
                  <ChildCardWidget
                    cardInfo={childData}
                    onEdit={() => onEdit(childData)}
                    onRemove={() => onRemove(childData)}
                    onView={() => onView(childData)}
                    
                  />
                </Grid>
              );
            })}
            <Grid item xs={6}>
              <AddChildCardWidget onAdd={() => onAdd()} />
            </Grid>
          </Grid>
        </Box>
      </Wrapper>
    </CardWrapper>
  );
};

export default ParentValueCardWidget;
