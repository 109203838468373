import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { FormControl, makeStyles, Modal, Select } from "@material-ui/core";
import { CurrencyRupeeRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  InputAdornment,
  OutlinedInput,
  Typography,
  MenuItem,
  Divider,
} from "@mui/material";
import { useEffect } from "react";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;
const InputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 320px;
  row-gap: 20px;
`;
const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;

const ButtonWrapper = styled(Box)`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
  margin: 0px;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin: 0px;
  margin-top: 2px;
`;

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const EditProductModal = (props) => {
  const classes = useStyles();
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedProductData, setSelectedProductData] = useState({
    rate: "",
    quantity: "",
  });
  const [customProductName, setCustomProductName] = useState("");

  const handleClose = () => {
    props.setOpen(false);
    props.setEditableProduct({});
  };
  const handleChange = (e) => {
    setSelectedProductData({
      ...selectedProductData,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddProduct = () => {
    let newObj = { ...props.editableProduct };
    let rate =
      selectedProductData["rate"] !== ""
        ? parseInt(selectedProductData["rate"])
        : 0;
    let quantity = parseInt(selectedProductData["quantity"]);

    let amount = rate * quantity;
    newObj["rate"] = rate;
    newObj["quantity"] = quantity;
    newObj["amount"] = amount;
    if (selectedProduct === "others") {
      newObj["product"]["name"] = customProductName;
    }
    let newList = [...props.productAddedList];
    let index = props.productAddedList.findIndex(
      (productItem) => productItem["product"]["id"] === newObj["product"]["id"]
    );
    newList[index] = newObj;
    props.setProductAddedList(newList);
    props.setOpen(false);
  };

  useEffect(() => {
    if (props.editableProduct["product"]["type"] === "others") {
      setSelectedProduct("others");
      setCustomProductName(props.editableProduct["product"]["name"]);
    } else {
      setSelectedProduct(props.editableProduct["product"]["id"]);
    }
    setSelectedProductData({
      rate: props.editableProduct["rate"],
      quantity: props.editableProduct["quantity"],
    });
  }, []);

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Header>Edit product</Header>
          <SubHeader>Fill in the below details</SubHeader>
          <Divider style={{ margin: "10px 0px" }} />
          <Box>
            <Container
              component={"form"}
              onSubmit={(e) => {
                e.stopPropagation();
                handleAddProduct();
              }}
            >
              <InputContainer>
                <InputWrapper>
                  <Label>Select Product*</Label>
                  <FormControl
                    sx={{ minWidth: 200 }}
                    classes={{
                      root: classes.quantityRoot,
                    }}
                  >
                    <Select
                      classes={{
                        icon: classes.icon,
                      }}
                      required
                      disabled
                      readOnly
                      displayEmpty
                      disableUnderline
                      variant="outlined"
                      name="product"
                      value={selectedProduct}
                      style={{
                        width: "100%",
                        height: "30px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        borderRadius: "0px",
                      }}
                    >
                      {props.productList.map((item, i) => {
                        return (
                          <MenuItem
                            key={i}
                            id={i}
                            value={item.id}
                            style={{ fontSize: "12px" }}
                          >
                            {item.name}
                          </MenuItem>
                        );
                      })}
                      <MenuItem value={"others"}>Others</MenuItem>
                    </Select>
                  </FormControl>
                </InputWrapper>
                {selectedProduct === "others" && (
                  <InputWrapper>
                    <Label>Name*</Label>
                    <OutlinedInput
                      required
                      type="text"
                      placeholder="Name"
                      onChange={(e) => {
                        setCustomProductName(e.target.value);
                      }}
                      name="name"
                      value={customProductName}
                      style={{ height: "30px" }}
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        min: 1,
                        "aria-label": "weight",
                        style: {
                          padding: "0px 12px",
                          fontSize: "12px",
                        },
                      }}
                    />
                  </InputWrapper>
                )}
                <InputWrapper>
                  <Label>Quantity*</Label>

                  <OutlinedInput
                    required
                    type="number"
                    placeholder="Quantity"
                    onChange={handleChange}
                    name="quantity"
                    value={selectedProductData["quantity"]}
                    style={{ height: "30px" }}
                    id="outlined-adornment-weight"
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      min: 1,
                      "aria-label": "weight",
                      style: {
                        padding: "0px 12px",
                        fontSize: "12px",
                      },
                    }}
                  />
                </InputWrapper>
                <InputWrapper>
                  <Label>Rate</Label>
                  <OutlinedInput
                    type="number"
                    placeholder="Rate"
                    name="rate"
                    onChange={handleChange}
                    value={selectedProductData["rate"]}
                    style={{ height: "30px" }}
                    id="outlined-adornment-weight"
                    startAdornment={
                      <InputAdornment position="start">
                        <CurrencyRupeeRounded
                          style={{ width: "18px", height: "18px" }}
                        />
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      min: 0,
                      "aria-label": "weight",
                      style: {
                        padding: "0px",
                        fontSize: "12px",
                      },
                    }}
                  />
                </InputWrapper>
              </InputContainer>
              <ButtonWrapper>
                <Button
                  onClick={handleClose}
                  type="button"
                  variant="contained"
                  color="inherit"
                  style={{
                    textTransform: "none",

                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "none",

                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  Add
                </Button>
              </ButtonWrapper>
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default EditProductModal;
