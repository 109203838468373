import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";

const FormContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  boxSizing: "border-box",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "42px",
  color: "#fff",
  textAlign: "left",
  fontWeight: 500,
  fontFamily: "Poppins",
  marginBottom: theme.spacing(2),
  textTransform: "uppercase",
  [theme.breakpoints.down("md")]: {
    fontSize: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "20px",
  },
}));

const InputContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

const FieldWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

const Label = styled(Typography)(({ theme }) => ({
  font: "normal normal 500 14px Poppins",
  color: "#fff",
  variant: "body1",
  component: "span",
  marginBottom: "10px",
  [[theme.breakpoints.between("xs", "md")]]: {
    font: "normal normal 500 14px Poppins",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    font: "normal normal 500 14px Poppins",
  },
  [theme.breakpoints.up("lg")]: {
    font: "normal normal 500 14px Poppins",
  },
}));

const OutlinedInputComponent = styled(OutlinedInput)(({ theme }) => ({
  lineHeight: "16px",
  margin: 0,
  fontSize: "16px",
  color: "#fff",
  border: "1px solid rgba(0, 0, 0, 0.1)",
  width: "100%",
  height: "40px",
  borderColor: theme.palette.primary.main,
  ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    padding: "7px 16px",
  },
}));

const CountryCode = styled(Typography)(({ theme }) => ({
  font: "normal normal 400 14px Poppins",
  textAlign: "center",
  color: "#fff",
  vaiant: "h6",
  component: "p",
}));

const DisclaimerWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  columnGap: "10px",
  width: "100%",
  marginBottom: theme.spacing(2),
}));

const SubHeader = styled(Typography)(({ theme }) => ({
  font: "normal normal 500 12px Poppins",
  textAlign: "left",
  color: "#fff",
  variant: "caption",
  component: "span",
  [[theme.breakpoints.between("xs", "md")]]: {
    font: "normal normal 500 8px Poppins",
  },
  [[theme.breakpoints.between("md", "lg")]]: {
    font: "normal normal 500 10px Poppins",
  },
  [theme.breakpoints.up("lg")]: {
    font: "normal normal 500 12px Poppins",
  },
}));

const FormComponent = ({ formState, contentState }) => {
  const [isCheckedDisclaimer, setIsCheckedDisclaimer] = useState(false);

  const getLabelName = (meta) => {
    if (meta["required"] === true) {
      return `${meta["label"]}*`;
    } else {
      return meta["label"];
    }
  };

  return (
    <FormContainer>
      <Title>{contentState.form_title}</Title>
      <InputContainer component="form" onSubmit={(e) => e.preventDefault()}>
        <Grid container spacing={2}>
          {formState.map((fieldMeta, index) => {
            if (fieldMeta["field_type"] === "text") {
              return (
                <Grid item xs={12} sm={12} md={12}>
                  <FieldWrapper>
                    <Label>{getLabelName(fieldMeta)}</Label>
                    <OutlinedInputComponent
                      required={fieldMeta["required"]}
                      placeholder={fieldMeta["label"]}
                      type="text"
                      name={fieldMeta["field_name"]}
                      autoComplete="off"
                    />
                  </FieldWrapper>
                </Grid>
              );
            } else if (fieldMeta["field_type"] === "phone") {
              return (
                <Grid item xs={12} sm={12} md={12}>
                  <FieldWrapper>
                    <Label>{getLabelName(fieldMeta)}</Label>
                    <OutlinedInputComponent
                      required={fieldMeta["required"]}
                      placeholder={fieldMeta["label"]}
                      name={fieldMeta["field_name"]}
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "^[0-9]{10}$",
                        maxLength: 10,
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <CountryCode>+91</CountryCode>
                        </InputAdornment>
                      }
                    />
                  </FieldWrapper>
                </Grid>
              );
            } else if (fieldMeta["field_type"] === "email") {
              return (
                <Grid item xs={12} sm={12} md={12}>
                  <FieldWrapper>
                    <Label>{getLabelName(fieldMeta)}</Label>
                    <OutlinedInputComponent
                      required={fieldMeta["required"]}
                      placeholder={fieldMeta["label"]}
                      name={fieldMeta["field_name"]}
                      autoComplete="off"
                      type="email"
                    />
                  </FieldWrapper>
                </Grid>
              );
            } else if (fieldMeta["field_type"] === "integer") {
              return (
                <Grid item xs={12} sm={12} md={12}>
                  <FieldWrapper>
                    <Label>{getLabelName(fieldMeta)}</Label>
                    <OutlinedInputComponent
                      required={fieldMeta["required"]}
                      placeholder={fieldMeta["label"]}
                      type="number"
                      name={fieldMeta["field_name"]}
                    />
                  </FieldWrapper>
                </Grid>
              );
            }
          })}
          <Grid item xs={12}>
            <DisclaimerWrapper>
              <FormControlLabel
                sx={{ alignItems: "start" }}
                onChange={() => {
                  setIsCheckedDisclaimer(!isCheckedDisclaimer);
                }}
                control={
                  <Checkbox
                    color="primary"
                    checked={isCheckedDisclaimer}
                    sx={{ paddingTop: "0px", color: "white" }}
                  />
                }
                label={<SubHeader>{contentState["disclaimer"]}</SubHeader>}
              />
            </DisclaimerWrapper>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Button
              disabled={!isCheckedDisclaimer}
              variant="contained"
              fullWidth
              type="submit"
              style={{
                textTransform: "none",
                color: isCheckedDisclaimer ? "white" : "gray",
                border: isCheckedDisclaimer ? "none" : "1px solid gray",
                font: "normal normal 700 16px open sans",
              }}
            >
              SUBMIT
            </Button>
          </Grid>
        </Grid>
      </InputContainer>
    </FormContainer>
  );
};

export default FormComponent;
