import React from "react";
import { Modal } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { Box, Button, Divider, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { myTheme } from "../../themeUtils";
const ModalContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 16px;
  margin: auto;
  height: fit-content;
  width: 264px;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 20px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  column-gap: 10px;
  margin-top: 20px;
`;
const Message = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Title = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;

const SaveFilterConfirmationDialog = (props) => {
  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContainer>
        <Wrapper>
          <Title>Confirmation</Title>
          <Divider style={{ margin: "10px 0px" }} />
          <Message>
            Do you want to create a new one or update the existing one?
          </Message>
          <ButtonWrapper>
            <ButtonWrapper>
              <Button
                onClick={props.handleNew}
                variant="contained"
                color="inherit"
                style={{
                  font: "normal normal 600 10px Open Sans",
                  textTransform: "none",
                }}
              >
                Create New
              </Button>
              <Button
                onClick={props.handleUpdate}
                variant="contained"
                style={{
                  font: "normal normal 600 10px Open Sans",
                  width: "fit-content",
                  textTransform: "none",
                  ...myTheme.Button.btnBlue,
                }}
              >
                Update Existing
              </Button>
            </ButtonWrapper>
          </ButtonWrapper>
        </Wrapper>
      </ModalContainer>
    </Modal>
  );
};

export default observer(SaveFilterConfirmationDialog);
