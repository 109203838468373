import { TextField } from "@mui/material";

import React from "react";

const TextFieldWidget = ({ handleChange, value, regex }) => {
  return (
    <TextField
      inputProps={{
        pattern: regex,
      }}
      variant="standard"
      placeholder="Your answer"
      style={{ width: "100%" }}
      value={value}
      onChange={(event) => handleChange(event)}
    />
  );
};

export default TextFieldWidget;
