import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Grid, makeStyles, Button } from "@material-ui/core";
import { AppBar, Box, Toolbar, Typography, OutlinedInput } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import PreviewIcon from "@mui/icons-material/Preview";
import { MultiSelect } from "react-multi-select-component";
import CustomFieldHeader from "../custom_tooltip_headers/CustomFieldHeader";
import rootStore from "../../stores/RootStore";
import InputFileViewComponent from "../add_product_sidepanel/components/InputFileViewComponent";
import AddFileInputComponent from "../add_product_sidepanel/components/AddFileInputComponent";
import PreviewImageDialog from "../files_page_components/PreviewImageDialog";

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  ${`height: calc(100vh - 128px);`};
  padding-top: 0px;
  overflow-y: auto;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  column-gap: 10px;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  padding: 24px;
  gap: 20px;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const AddWhatsappTemplateSidepanel = ({
  setRefresh,
  setOpen,
  open,
  isEdit,
  editableData,
  setEdit,
  previewOpen,
  setPreviewOpen,
  setWhatsappPreviewMessage,
  postSubmitCallback,
  isSubmitSuccess,
  isSubmitFail,
  setIsSubmitFail,
  errorMessage,
  loading,
  selectedImages,
  setSelectedImages,
  setSelectedFileUrl,
  selectedFileUrl,
}) => {
  const classes = useStyles();
  const [details, setDetails] = useState({
    template_title: "",
    template_message: "",
    // for_marketing: "No",
    // for_transactional: "No",
    // lead_stage: "",
    // lead_status: "",
    whatsapp_provider: "",
    enable_for_stages: [],
  });

  const [viewImageDialog, setViewImageDialog] = useState(false);

  const [stagesList, setStagesList] = useState([]);

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      setSelectedImages((prevImages) => [...prevImages, ...files]);
    }
  };
  const handleImagePreview = (image) => {
    const objectURL = URL.createObjectURL(image);
    setSelectedFileUrl(objectURL);
    setViewImageDialog(true);
  };
  const handleRemoveImage = (index) => {
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  useEffect(() => {
    setup();
  }, []);

  const setup = () => {
    const stagesList = rootStore.authStore.stageList.map((item) => ({
      label: item["stage"],
      value: item["id"],
    }));
    setStagesList(stagesList);

    if (isEdit) {
      let newDetails = {
        template_title:
          editableData.template_title !== null
            ? editableData.template_title
            : "",
        template_message:
          editableData.message !== null ? editableData.message : "",
        // for_marketing: editableData.for_marketing ? "Yes" : "No",
        // for_transactional: editableData.for_transactional ? "Yes" : "No",
        // lead_stage: editableData.lead_stage,
        // lead_status: editableData.lead_status,
        whatsapp_provider:
          editableData.whatsapp_provider !== null
            ? editableData.whatsapp_provider.name
            : "",
        enable_for_stages: [],
      };
      if (editableData.enable_for_stages !== null) {
        newDetails["enable_for_stages"] = editableData.enable_for_stages.map(
          (stageID) => {
            let index = stagesList.findIndex((item) => item.value === stageID);
            if (index !== -1) {
              return stagesList[index];
            }
          }
        );
      }

      setDetails(newDetails);
    }
  };

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    postSubmitCallback(details, selectedImages);
  };

  return (
    <Box role="presentation">
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          width: 500,
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <ModelHeader>
              {isEdit ? "Edit template" : "Create Template"}
            </ModelHeader>
            <Button
              disabled={details["template_message"] === ""}
              onClick={() => {
                setWhatsappPreviewMessage(details["template_message"]);
                setPreviewOpen(true);
              }}
              variant="outlined"
              color="primary"
              size="small"
              style={{
                fontSize: "11px",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <PreviewIcon />
              Preview
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      {!isSubmitSuccess ? (
        <ModelWrapper
          component={"form"}
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <InputContainer>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputWrapper>
                  <CustomFieldHeader
                    header={"Template Title"}
                    tooltip={"Enter the name of the new WhatsApp template"}
                    required={true}
                  />
                  <OutlinedInput
                    style={{ height: "30px" }}
                    inputProps={{
                      min: 0,
                      "aria-label": "weight",
                      style: {
                        fontSize: "12px",
                      },
                    }}
                    onChange={handleChange}
                    name="template_title"
                    required
                    value={details["template_title"]}
                    placeholder="Enter value"
                  />
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper>
                  <CustomFieldHeader
                    header={"Message"}
                    tooltip={`To personalize your WhatsApp message, you can include lead-specific fields by using double curly braces {{FieldName}}, such as {{Name}}. For system fields like dates, use double square brackets [[FieldName]], such as [[Date]]. These placeholders will be automatically replaced with the respective values when the message is sent.`}
                    required={true}
                  />
                  <OutlinedInput
                    multiline
                    rows={20}
                    inputProps={{
                      min: 0,
                      "aria-label": "weight",
                      style: {
                        fontSize: "12px",
                      },
                    }}
                    onChange={handleChange}
                    name="template_message"
                    required
                    value={details["template_message"]}
                    placeholder="Dear {{Name}}! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus interdum dui vitae nisi [[Date]], ac convallis sem scelerisque."
                  />
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper>
                  <Label>Enable For Stages</Label>
                  <Box
                    sx={{
                      "& .dropdown-content": {
                        zIndex: 3,
                      },
                    }}
                  >
                    <MultiSelect
                      hasSelectAll={true}
                      options={stagesList}
                      value={details["enable_for_stages"]}
                      className={"chart_multi_select"}
                      onChange={(selected) => {
                        setDetails({ ...details, enable_for_stages: selected });
                      }}
                      labelledBy="Select Group"
                    />
                  </Box>
                </InputWrapper>
              </Grid>
              {/* <Grid item xs={6}>
                <InputWrapper>
                  <Label>Lead Status</Label>

                  <FormControl
                    sx={{ width: "100%" }}
                    classes={{
                      root: classes.quantityRoot,
                    }}
                  >
                    <Select
                      classes={{
                        icon: classes.icon,
                      }}
                      displayEmpty
                      onChange={handleChange}
                      name="lead_status"
                      required
                      value={details["lead_status"]}
                      disableUnderline
                      variant="outlined"
                      style={{
                        width: "100%",
                        height: "30px",
                        fontSize: "12px",
                        borderRadius: "4px",
                      }}
                    >
                      {rootStore.authStore.statusList?.map((item, i) => {
                        return (
                          <MenuItem
                            value={item.id}
                            key={i}
                            style={{ fontSize: "12px" }}
                          >
                            {item.status}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </InputWrapper>
              </Grid> */}
              {/* <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Box>
                      <Label>For Marketing</Label>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="for_marketing"
                        value={details["for_marketing"]}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value={"Yes"}
                          control={<Radio size="small" />}
                          label={
                            <Label style={{ fontSize: "12px" }}>
                              {getLocalizedText("yes")}
                            </Label>
                          }
                        />
                        <FormControlLabel
                          value={"No"}
                          control={<Radio size="small" />}
                          label={
                            <Label style={{ fontSize: "12px" }}>
                              {getLocalizedText("no")}
                            </Label>
                          }
                        />
                      </RadioGroup>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Label>For Transactional</Label>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="for_transactional"
                        value={details["for_transactional"]}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value={"Yes"}
                          control={<Radio size="small" />}
                          label={
                            <Label style={{ fontSize: "12px" }}>
                              {getLocalizedText("yes")}
                            </Label>
                          }
                        />
                        <FormControlLabel
                          value={"No"}
                          control={<Radio size="small" />}
                          label={
                            <Label style={{ fontSize: "12px" }}>
                              {getLocalizedText("no")}
                            </Label>
                          }
                        />
                      </RadioGroup>
                    </Box>
                  </Grid>
                </Grid>
              </Grid> */}
            </Grid>
          </InputContainer>

          <AppBar
            position="fixed"
            color="inherit"
            elevation={0}
            sx={{ top: "auto", bottom: 0, width: 500, bgcolor: "#f9f9fc" }}
          >
            <Toolbar style={{ width: 500, position: "relative" }}>
              {isSubmitFail && (
                <ErrorAlert
                  onClose={(e) => {
                    e.stopPropagation();
                    setIsSubmitFail(false);
                  }}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  {errorMessage}
                </ErrorAlert>
              )}

              <ButtonWrapper>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "20px",
                    width: "fit-content",
                  }}
                >
                  <Button
                    type="button"
                    onClick={handleClose}
                    variant="outlined"
                    color="primary"
                    style={{
                      textTransform: "none",
                      width: "120px",
                      fontWeight: "bold",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    color="primary"
                    style={{
                      textTransform: "none",
                      width: "120px",
                      fontWeight: "bold",
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </ButtonWrapper>
            </Toolbar>
          </AppBar>
        </ModelWrapper>
      ) : (
        <MessageWrapper>
          <Message>
            {isEdit
              ? "Template edited successfully!"
              : "Template created successfully!"}
          </Message>
          <Button
            onClick={handleClose}
            type="button"
            variant="contained"
            color="default"
            style={{
              marginTop: "20px",
              textTransform: "none",
              fontSize: "12px",
              fontWeight: "bold",
              width: "100px",
            }}
          >
            Close
          </Button>
        </MessageWrapper>
      )}
      {viewImageDialog && (
        <PreviewImageDialog
          open={viewImageDialog}
          setOpen={setViewImageDialog}
          file={selectedFileUrl}
          isCreate={false}
        />
      )}
    </Box>
  );
};

export default AddWhatsappTemplateSidepanel;
