import React from "react";
import { styled } from "@mui/material/styles";
import {
  AppBar,
  Box,
  Divider,
  Grid,
  Modal,
  Toolbar,
  Typography,
  Button,
} from "@mui/material";
const dialogWidth = 420;
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  max-height: 300px;
  padding: 16px;
  overflow-y: auto;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  width: 100%;
  column-gap: 10px;
`;
const Label = styled(Typography)`
  font: normal normal 500 14px Open Sans;
  color: #4d4e4f;
  margin-bottom: 0px;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const CircleIcon = styled(Box)`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-top: 5px;
`;

const ViewTaskInfoDialog = (props) => {
  const handleClose = () => {
    props.setOpen(false);
  };

  const getColor = (eventType) => {
    if (eventType === "holiday") {
      return "green";
    } else if (eventType === "leave") {
      return "red";
    } else {
      return "blue";
    }
  };

  return (
    <>
      <Modal
        style={{ borderRadius: "20px" }}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box component={"form"}>
            <AppBar
              component={"nav"}
              elevation={0}
              position="sticky"
              color="inherit"
              sx={{
                top: 0,
                bottom: "auto",
                width: "100%",
                right: 0,
                borderRadius: "8px",
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                }}
              >
                <HeaderWrapper>
                  <Header>Event Information</Header>
                </HeaderWrapper>
              </Toolbar>
            </AppBar>
            <Divider style={{ margin: "0px 16px" }} />
            <Container>
              <Box>
                <Grid container spacing={2}>
                  {props.eventList.map((item, index) => {
                    return (
                      <Grid item xs={12} key={index}>
                        <InputWrapper>
                          <CircleIcon
                            style={{
                              backgroundColor: getColor(item["type"]),
                            }}
                          />
                          <Label>{item["title"]}</Label>
                        </InputWrapper>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Container>
            <AppBar
              position="static"
              color="inherit"
              elevation={0}
              sx={{
                top: "auto",
                bottom: 0,
                width: "100%",
                borderRadius: "8px",
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                  width: dialogWidth,
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "fit-content",
                      textTransform: "none",
                    }}
                    color="inherit"
                  >
                    Close
                  </Button>
                </Box>
              </Toolbar>
            </AppBar>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default ViewTaskInfoDialog;
