import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";
import {
  IsoToLocalDate,
  IsoToLocalTime,
  dataRenderer,
  dateHandler,
  dateTimeHandler,
} from "../../Functions";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect } from "react";
import analytics from "../../Analytics";

const ModelWrapper = styled(Box)(({ theme }) => ({
  width: "500px",
  margin: "auto",
  display: "flex",
  position: "relative",
  /* padding: 30px, */
  flexDirection: "column",
  height: "95vh",
  paddingTop: "0px",
}));
const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  padding: "30px 0px 30px 16px",
  marginBottom: "20px",
  backgroundColor: "#f9f9fc",
}));

const ModelHeader = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 18px Open Sans",
  variant: "span",
  color: "#4d4e4f",
}));

const Title = styled(Typography)(({ theme }) => ({
  font: "normal normal 400 14px Open Sans",
  color: "#5A606680",
}));
const Desc = styled(Typography)(({ theme }) => ({
  font: "normal normal 500 14px Open Sans",
  color: "#5A6066",
  wordWrap: "break-word",
}));

const Column = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  overflowX: "auto",
  padding: "0px 16px 0px 16px",
}));

const fixedFieldsList = [
  "phone",
  "name",
  "city",
  "email",
  "source",
  "created_by",
  "created_at",
  "custom_params",
];

const RawLeadDetailsPanel = (props) => {
  const identifyDateFormat = (dateString) => {
    if (!isNaN(dateString)) {
      const m = moment(parseInt(dateString));
      if (m.isValid()) {
        return "epoch";
      }
    } else {
      const m = moment(dateString);
      if (m.isValid()) {
        return "ISO";
      }
    }
    return "unknown";
  };

  const dateFieldHandler = ({ item, type }) => {
    let value = null;
    let dateStringType = identifyDateFormat(item);
    if (item !== null) {
      if (dateStringType !== "unknown") {
        if (dateStringType === "epoch") {
          if (type === "datetime") {
            value = dateTimeHandler(item);
          } else {
            value = dateHandler(item);
          }
        } else {
          if (type === "datetime") {
            value = IsoToLocalTime(item);
          } else {
            value = IsoToLocalDate(item);
          }
        }
      }
    }
    return value;
  };

  const getFieldValue = (field_meta) => {
    let custom_data = props.data.hasOwnProperty("custom_params")
      ? props.data["custom_params"]
      : {};
    let key = field_meta["field"];
    let value = null;
    if (key.includes("custom_params_")) {
      let new_key = key.replace("custom_params_", "");

      value = custom_data.hasOwnProperty(new_key) ? custom_data[new_key] : null;
    } else {
      value = props.data.hasOwnProperty(key) ? props.data[key] : null;
    }

    if (
      field_meta.data_type === "datetime" ||
      field_meta.data_type === "date"
    ) {
      value = dateFieldHandler({
        item: value,
        type: field_meta.data_type,
      });
    } else if (field_meta.data_type === "boolean") {
      value =
        value !== null && value !== ""
          ? value === true
            ? "True"
            : "False"
          : "";
    }

    return value;
  };

  useEffect(() => {
    analytics.triggerEvent(
      4625000139,
      "raw_leads_information_form_load",
      "raw_leads_tab",
      "",
      {}
    );
  }, []);

  return (
    <>
      <Box role="presentation">
        <ModelWrapper>
          <HeaderWrapper>
            <Info color="primary" style={{ marginRight: "8px" }} />
            <ModelHeader>Information</ModelHeader>
          </HeaderWrapper>
          <Column>
            <Box>
              <Box
                style={{
                  marginTop: "8px",
                  marginBottom: "8px",
                }}
              >
                <Grid container alignItems="center">
                  <Grid item xs={4}>
                    <Title>Phone</Title>
                  </Grid>
                  <Grid item xs={1}>
                    <Divider
                      orientation="vertical"
                      sx={{ height: "20px", color: "#3535354D" }}
                    />
                  </Grid>
                  <Grid item xs={7} paddingLeft={"8px"}>
                    <Desc>{dataRenderer(props.data["phone"])}</Desc>
                  </Grid>
                </Grid>
              </Box>
              <Divider orientation="horizontal" />
              <Box
                style={{
                  marginTop: "8px",
                  marginBottom: "8px",
                }}
              >
                <Grid container alignItems="center">
                  <Grid item xs={4}>
                    <Title>Name</Title>
                  </Grid>
                  <Grid item xs={1}>
                    <Divider
                      orientation="vertical"
                      sx={{ height: "20px", color: "#3535354D" }}
                    />
                  </Grid>
                  <Grid item xs={7} paddingLeft={"8px"}>
                    <Desc>{dataRenderer(props.data["name"])}</Desc>
                  </Grid>
                </Grid>
              </Box>
              <Divider orientation="horizontal" />
              <Box
                style={{
                  marginTop: "8px",
                  marginBottom: "8px",
                }}
              >
                <Grid container alignItems="center">
                  <Grid item xs={4}>
                    <Title>City</Title>
                  </Grid>
                  <Grid item xs={1}>
                    <Divider
                      orientation="vertical"
                      sx={{ height: "20px", color: "#3535354D" }}
                    />
                  </Grid>
                  <Grid item xs={7} paddingLeft={"8px"}>
                    <Desc>{dataRenderer(props.data["city"])}</Desc>
                  </Grid>
                </Grid>
              </Box>
              <Divider orientation="horizontal" />
              <Box
                style={{
                  marginTop: "8px",
                  marginBottom: "8px",
                }}
              >
                <Grid container alignItems="center">
                  <Grid item xs={4}>
                    <Title>Email</Title>
                  </Grid>
                  <Grid item xs={1}>
                    <Divider
                      orientation="vertical"
                      sx={{ height: "20px", color: "#3535354D" }}
                    />
                  </Grid>
                  <Grid item xs={7} paddingLeft={"8px"}>
                    <Desc>{dataRenderer(props.data["email"])}</Desc>
                  </Grid>
                </Grid>
              </Box>
              <Divider orientation="horizontal" />
              <Box
                style={{
                  marginTop: "8px",
                  marginBottom: "8px",
                }}
              >
                <Grid container alignItems="center">
                  <Grid item xs={4}>
                    <Title>Source</Title>
                  </Grid>
                  <Grid item xs={1}>
                    <Divider
                      orientation="vertical"
                      sx={{ height: "20px", color: "#3535354D" }}
                    />
                  </Grid>
                  <Grid item xs={7} paddingLeft={"8px"}>
                    <Desc>{dataRenderer(props.data["source"])}</Desc>
                  </Grid>
                </Grid>
              </Box>
              <Divider orientation="horizontal" />
              <Box
                style={{
                  marginTop: "8px",
                  marginBottom: "8px",
                }}
              >
                <Grid container alignItems="center">
                  <Grid item xs={4}>
                    <Title>Created by</Title>
                  </Grid>
                  <Grid item xs={1}>
                    <Divider
                      orientation="vertical"
                      sx={{ height: "20px", color: "#3535354D" }}
                    />
                  </Grid>
                  <Grid item xs={7} paddingLeft={"8px"}>
                    <Desc>
                      {dataRenderer(props.data["created_by"]["name"])}
                    </Desc>
                  </Grid>
                </Grid>
              </Box>
              <Divider orientation="horizontal" />
              <Box
                style={{
                  marginTop: "8px",
                  marginBottom: "8px",
                }}
              >
                <Grid container alignItems="center">
                  <Grid item xs={4}>
                    <Title>Created At</Title>
                  </Grid>
                  <Grid item xs={1}>
                    <Divider
                      orientation="vertical"
                      sx={{ height: "20px", color: "#3535354D" }}
                    />
                  </Grid>
                  <Grid item xs={7} paddingLeft={"8px"}>
                    <Desc>
                      {props.data.hasOwnProperty("created_at") &&
                      props.data["created_at"] !== null
                        ? IsoToLocalTime(props.data["created_at"])
                        : "-"}
                    </Desc>
                  </Grid>
                </Grid>
              </Box>
              <Divider orientation="horizontal" />
            </Box>

            {props.columns.map((column, i) => {
              if (!fixedFieldsList.includes(column["field"])) {
                let value = getFieldValue(column);

                return (
                  <Box key={i}>
                    <Box
                      style={{
                        marginTop: "8px",
                        marginBottom: "8px",
                      }}
                    >
                      <Grid container alignItems="center">
                        <Grid item xs={4}>
                          <Title>{column["headerName"]}</Title>
                        </Grid>
                        <Grid item xs={1}>
                          <Divider
                            orientation="vertical"
                            sx={{ height: "20px", color: "#3535354D" }}
                          />
                        </Grid>
                        <Grid item xs={7} paddingLeft={"8px"}>
                          <Desc>{dataRenderer(value)}</Desc>
                        </Grid>
                      </Grid>
                    </Box>
                    <Divider orientation="horizontal" />
                  </Box>
                );
              }
            })}
          </Column>
        </ModelWrapper>
      </Box>
    </>
  );
};

export default observer(RawLeadDetailsPanel);
