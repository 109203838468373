// Import the functions you need from the SDKs you need
import { getAnalytics, logEvent } from "firebase/analytics";
import { consoleLogger } from "./Functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

class Analytics {
  triggerEvent(
    eventID,
    counter,
    kingdom,
    phylum,
    {
      order = "",
      family = "",
      genus = "",
      species = "",
      value1 = 0,
      value2 = 0,
    }
  ) {
    const analytics = getAnalytics();
    let payload = {
      event_id: eventID,
      counter: counter,
      kingdom: kingdom,
      phylum: phylum,
      order: order,
      family: family,
      genus: genus,
      species: species,
      value1: value1,
      value2: value2,
    };
    consoleLogger("trigger event payload:::", payload);
    logEvent(analytics, counter, payload);
  }

  screenView = (screenName, screenClass) => {
    logEvent(analytics, "screen_view", {
      firebase_screen: screenName,
      firebase_screen_class: screenClass,
    });
  };
}

const analytics = new Analytics();

export default analytics;
