import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Toolbar,
} from "@material-ui/core";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { getLocalizedText } from "../../Functions";
import { MenuItem, Typography } from "@mui/material";
import CustomCheckboxHeader from "../custom_tooltip_headers/CustomCheckboxHeader";
const dialogWidth = 420;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const AddUpdateFieldDialog = ({
  open,
  setOpen,
  handlePostAddUpdateField,
  canEditField,
  selectedColumnList,
  columnsList,
}) => {
  const classes = useStyles();
  const [details, setDetails] = useState({
    column_id: "",
    is_overwrite: false,
    is_required: false,
    is_append: false,
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    handlePostAddUpdateField(details);
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const canShowIsAppendField = () => {
    let index = columnsList.findIndex(
      (column) => column["id"] === details.column_id
    );
    if (index !== -1) {
      return columnsList[index]["is_multiline"] === true;
    }
    return false;
  };

  const RenderOverWriteAndAppendField = () => {
    if (canShowIsAppendField()) {
      return (
        <Grid item xs={12}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              marginTop: "16px",
            }}
          >
            <FormControlLabel
              disabled={!canEditField()}
              control={
                <Checkbox
                  disabled={details.column_id === ""}
                  checked={details.is_required}
                  size="small"
                  onChange={(e) => {
                    setDetails({
                      ...details,
                      is_required: e.target.checked,
                    });
                  }}
                  style={{ paddingRight: "8px" }}
                />
              }
              label={
                <CustomCheckboxHeader
                  header={"Required"}
                  tooltip={"Specify if this column is mandatory in forms."}
                />
              }
            />
            <Box
              sx={{
                border: "1px solid #cccccc",
                padding: "0px 12px",
                width: "100%",
              }}
            >
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  control={
                    <Radio
                      size="small"
                      checked={details.is_overwrite}
                      onChange={() => {
                        setDetails({
                          ...details,
                          is_overwrite: true,
                          is_append: false,
                        });
                      }}
                    />
                  }
                  label={
                    <CustomCheckboxHeader
                      header={"Overwrite"}
                      tooltip={
                        "Specify if this column value can be overwritten."
                      }
                    />
                  }
                />
                <FormControlLabel
                  control={
                    <Radio
                      size="small"
                      checked={details.is_append}
                      onChange={() => {
                        setDetails({
                          ...details,
                          is_append: true,
                          is_overwrite: false,
                        });
                      }}
                    />
                  }
                  label={
                    <CustomCheckboxHeader
                      header={"Append"}
                      tooltip={"Specify if this column value can be appended."}
                    />
                  }
                />
              </RadioGroup>
            </Box>
          </Box>
        </Grid>
      );
    } else {
      return (
        <Grid container item xs={12}>
          <Grid item>
            <FormControlLabel
              disabled={!canEditField()}
              control={
                <Checkbox
                  disabled={details.column_id === ""}
                  checked={details.is_required}
                  size="small"
                  onChange={(e) => {
                    setDetails({
                      ...details,
                      is_required: e.target.checked,
                    });
                  }}
                  style={{ paddingRight: "8px" }}
                />
              }
              label={
                <CustomCheckboxHeader
                  header={"Required"}
                  tooltip={"Specify if this column is mandatory in forms."}
                />
              }
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              disabled={!canEditField()}
              control={
                <Checkbox
                  size="small"
                  disabled={details.column_id === ""}
                  checked={details.is_overwrite}
                  onChange={(e) => {
                    setDetails({
                      ...details,
                      is_overwrite: e.target.checked,
                    });
                  }}
                  style={{ paddingRight: "8px" }}
                />
              }
              label={
                <CustomCheckboxHeader
                  header={"Overwrite"}
                  tooltip={"Specify if this column value can be overwritten."}
                />
              }
            />
          </Grid>
        </Grid>
      );
    }
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box component={"form"} onSubmit={handleSubmit}>
            <AppBar
              component={"nav"}
              elevation={0}
              position="sticky"
              color="inherit"
              sx={{
                top: 0,
                bottom: "auto",
                width: "100%",
                right: 0,
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                }}
              >
                <HeaderWrapper>
                  <Header>Add Update Field</Header>
                  <SubHeader>
                    {" "}
                    {getLocalizedText("fill_in_the_below_details")}
                  </SubHeader>
                </HeaderWrapper>
              </Toolbar>
            </AppBar>
            <Divider style={{ margin: "0px 16px" }} />
            <Container>
              <Box
                style={{
                  height: "100%",
                }}
              >
                <Grid container item>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Select Field*</Label>

                      <FormControl
                        sx={{ width: "100%" }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                          columnGap: "8px",
                        }}
                        classes={{
                          root: classes.quantityRoot,
                        }}
                      >
                        <Select
                          classes={{
                            icon: classes.icon,
                          }}
                          disabled={!canEditField()}
                          name="selected_column"
                          value={details["column_id"]}
                          displayEmpty
                          disableUnderline
                          variant="outlined"
                          onChange={(e) => {
                            setDetails({
                              column_id: e.target.value,
                              is_overwrite: false,
                              is_required: false,
                              is_append: false,
                            });
                          }}
                          required
                          style={{
                            width: "100%",
                            height: "30px",
                            fontSize: "12px",
                            borderRadius: "0px",
                          }}
                        >
                          {columnsList.map((e) => {
                            if (
                              !Object.keys(selectedColumnList).includes(e.id)
                            ) {
                              return (
                                <MenuItem id={e.id} value={e.id}>
                                  {e.headerName}
                                </MenuItem>
                              );
                            }
                          })}
                        </Select>
                      </FormControl>
                    </InputWrapper>
                  </Grid>
                  <Grid container item xs={12} alignItems="flex-end">
                    <RenderOverWriteAndAppendField />
                  </Grid>
                </Grid>
              </Box>
            </Container>
            <AppBar
              position="static"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: "100%" }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                  width: dialogWidth,
                  position: "relative",
                }}
              >
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      onClick={() => {
                        setOpen(false);
                      }}
                      variant="contained"
                      color="default"
                      style={{
                        fontSize: "12px",
                        textTransform: "none",
                        fontWeight: "bold",
                      }}
                    >
                      {getLocalizedText("cancel")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        width: "fit-content",
                        textTransform: "none",
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default AddUpdateFieldDialog;
