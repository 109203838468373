import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  Select,
  makeStyles,
} from "@material-ui/core";
import {
  AppBar,
  Box,
  Button,
  MenuItem,
  OutlinedInput,
  Toolbar,
  Typography,
} from "@mui/material";
import { formFieldTypeList } from "../../Db";
import InputFieldWithAddButton from "./InputFieldWithAddButton";
const drawerWidth = 300;
const ModelWrapper = styled(Box)`
  width: ${drawerWidth}px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  /* padding: 24px; */
  gap: 20px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const FlagText = styled(Typography)`
  margin: 0px;
  font: normal normal 500 12px Open Sans;
`;

const SubStatusContainer = styled(Box)`
  width: 100%;
  max-height: 80px;
  overflow-y: auto;
  border: none;
  /* margin-bottom: 20px; */
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;
  column-gap: 4px;
  padding: 4px 0px;
`;

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid hsl(0, 0%, 80%)",
      borderRadius: "4px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid hsl(0, 0%, 80%)",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid hsl(0, 0%, 80%)",
      borderRadius: "4px",
    },
  },
  icon: {
    color: "hsl(0, 0%, 80%)",
  },
});

const EditFormComponent = ({
  formState,
  setFormState,
  setContentState,
  contentState,
}) => {
  const classes = useStyles();

  return (
    <>
      <InputContainer>
        <Grid container spacing={2}>
          {contentState.hasOwnProperty("form_title") && (
            <Grid item xs={12}>
              <InputWrapper>
                <Label>Form Title*</Label>
                <OutlinedInput
                  style={{ height: "30px" }}
                  inputProps={{
                    min: 0,
                    "aria-label": "weight",
                    style: {
                      fontSize: "12px",
                    },
                  }}
                  onChange={(e) => {
                    setContentState({
                      ...contentState,
                      form_title: e.target.value,
                    });
                  }}
                  name="form_title"
                  required
                  value={contentState["form_title"]}
                  placeholder="Enter value"
                />
              </InputWrapper>
            </Grid>
          )}
          {contentState.hasOwnProperty("thankyou_message") && (
            <Grid item xs={12}>
              <InputWrapper>
                <Label>Thank You Message*</Label>
                <OutlinedInput
                  style={{ height: "30px" }}
                  inputProps={{
                    min: 0,
                    "aria-label": "weight",
                    style: {
                      fontSize: "12px",
                    },
                  }}
                  onChange={(e) => {
                    setContentState({
                      ...contentState,
                      thankyou_message: e.target.value,
                    });
                  }}
                  name="thankyou_message"
                  required
                  value={contentState["thankyou_message"]}
                  placeholder="Enter value"
                />
              </InputWrapper>
            </Grid>
          )}
          {formState.map((fieldMeta, index) => {
            return (
              <Grid item xs={12}>
                <Box
                  component={"fieldset"}
                  sx={{
                    border: "1px solid #cccccc",
                    padding: "12px",
                    paddingRight: "0px",
                  }}
                >
                  <legend
                    style={{
                      width: "auto",
                      marginBottom: "0px",
                      fontSize: "16px",
                    }}
                  >
                    Group
                  </legend>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <Label>Label*</Label>
                        <OutlinedInput
                          style={{ height: "30px" }}
                          inputProps={{
                            min: 0,
                            "aria-label": "weight",
                            style: {
                              fontSize: "12px",
                            },
                          }}
                          onChange={(e) => {
                            let tempList = [...formState];
                            let index = tempList.findIndex(
                              (field) => field["id"] === fieldMeta["id"]
                            );

                            tempList[index]["label"] = e.target.value;

                            setFormState(tempList);
                          }}
                          name="label"
                          required
                          value={fieldMeta["label"]}
                          placeholder="Enter value"
                        />
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <Label>Field*</Label>
                        <FormControl
                          sx={{ width: "100%" }}
                          classes={{
                            root: classes.quantityRoot,
                          }}
                        >
                          <Select
                            classes={{
                              icon: classes.icon,
                            }}
                            value={fieldMeta["field_type"]}
                            name="field_type"
                            displayEmpty
                            disableUnderline
                            variant="outlined"
                            required
                            onChange={(e) => {
                              let tempList = [...formState];
                              let index = tempList.findIndex(
                                (field) => field["id"] === fieldMeta["id"]
                              );
                              tempList[index]["field_type"] = e.target.value;
                              tempList[index]["inputs"] = [];

                              setFormState(tempList);
                            }}
                            style={{
                              width: "100%",
                              height: "30px",
                              fontSize: "12px",
                              borderRadius: "0px",
                            }}
                          >
                            {formFieldTypeList.map((item, index) => {
                              return (
                                <MenuItem
                                  style={{ fontSize: "12px" }}
                                  id={index}
                                  value={item.value}
                                >
                                  {item.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={fieldMeta["required"]}
                            color="primary"
                          />
                        }
                        label={<FlagText>Required</FlagText>}
                        onChange={(e) => {
                          let tempList = [...formState];
                          let index = tempList.findIndex(
                            (field) => field["id"] === fieldMeta["id"]
                          );

                          tempList[index]["required"] = e.target.checked;

                          setFormState(tempList);
                        }}
                      />
                    </Grid>
                    {fieldMeta["field_type"] === "dropdown" && (
                      <Grid item xs={12}>
                        <Grid item xs={12}>
                          <InputWrapper>
                            <Label>Inputs*</Label>

                            <InputFieldWithAddButton
                              formState={formState}
                              setFormState={setFormState}
                              fieldMeta={fieldMeta}
                            />
                            {fieldMeta["inputs"].length > 0 && (
                              <SubStatusContainer>
                                {fieldMeta["inputs"].map((e, i) => {
                                  return (
                                    <Chip
                                      key={e}
                                      label={e}
                                      onDelete={() => {
                                        let tempList = [...formState];
                                        let index = tempList.findIndex(
                                          (field) =>
                                            field["id"] === fieldMeta["id"]
                                        );
                                        let inputList = tempList[index][
                                          "inputs"
                                        ].filter((str) => str !== e);
                                        tempList[index]["inputs"] = inputList;

                                        setFormState(tempList);
                                      }}
                                    />
                                  );
                                })}
                              </SubStatusContainer>
                            )}
                          </InputWrapper>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Grid>
            );
          })}
          {contentState.hasOwnProperty("disclaimer") && (
            <Grid item xs={12}>
              <InputWrapper>
                <Label>Disclaimer*</Label>
                <OutlinedInput
                  multiline
                  rows={3}
                  inputProps={{
                    min: 0,
                    "aria-label": "weight",
                    style: {
                      fontSize: "12px",
                    },
                  }}
                  onChange={(e) => {
                    setContentState({
                      ...contentState,
                      disclaimer: e.target.value,
                    });
                  }}
                  name="disclaimer"
                  required
                  value={contentState["disclaimer"]}
                  placeholder="Enter value"
                />
              </InputWrapper>
            </Grid>
          )}
        </Grid>
      </InputContainer>
    </>
  );
};

export default EditFormComponent;
