import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import LeftSection from "./book_demo_components/LeftSection";
import RightSection from "./book_demo_components/RightSection";

const StyledContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
}));

const SectionWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  minHeight: "100vh",
  [theme.breakpoints.down("md")]: {
    minHeight: "100vh",
    flexDirection: "column",
  },
}));

const BookDemoTemplate = ({
  contentState,
  formState,
  primaryColor,
  secondaryColor,
  logoURL,
  gradientColor,
}) => {
  return (
    <>
      <StyledContainer disableGutters fixed maxWidth="false">
        <SectionWrapper>
          <LeftSection
            gradientColor={gradientColor}
            contentState={contentState}
            formState={formState}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            logoURL={logoURL}
          />

          <RightSection
            contentState={contentState}
            secondaryColor={secondaryColor}
            formState={formState}
          />
        </SectionWrapper>
      </StyledContainer>
    </>
  );
};

export default BookDemoTemplate;
