import React from "react";
import { Modal } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
const ModalContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 20px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 20px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  column-gap: 10px;
`;
const Message = styled(Typography)`
  font: normal normal 500 18px Open Sans;
  color: #4d4e4f;
`;

const CreateWebFormAlertDialog = (props) => {
  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContainer>
        <Wrapper>
          <Message>{props.message}</Message>
          <ButtonWrapper>
            <ButtonWrapper>
              <Button
                onClick={() => {
                  props.setOpen(false);
                }}
                variant="contained"
                color="primary"
                style={{
                  font: "normal normal 600 14px Open Sans",
                  textTransform: "none",
                }}
              >
                Ok
              </Button>
            </ButtonWrapper>
          </ButtonWrapper>
        </Wrapper>
      </ModalContainer>
    </Modal>
  );
};

export default CreateWebFormAlertDialog;
