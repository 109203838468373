import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import { Box, Button } from "@material-ui/core";
import { styled } from "@mui/material/styles";
const MessageWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const Message = styled(Typography)(({ theme }) => ({
  font: "normal normal 400 16px Open Sans",
  color: "#4d4e4f",
}));
const CustomErrorAlertWithContinue = (props) => {
  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => {
          props.setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <MessageWrapper>
              <Message style={{ color: "#ff0000" }}>
                {props.errorMessage}
              </Message>
              <Message>{props.confirmMessage}</Message>
            </MessageWrapper>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            color="inherit"
            variant="contained"
            style={{
              textTransform: "capitalize",
              fontWeight: "bold",
              width: "100px",
            }}
            onClick={() => {
              props.setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            style={{
              backgroundColor: "#185DD2",
              textTransform: "capitalize",
              fontWeight: "bold",
              width: "100px",
              color: "white",
            }}
            onClick={props.handleConfirm}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default CustomErrorAlertWithContinue;
