import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
} from "@material-ui/core";
import { myTheme } from "../../themeUtils";
import { Info, LocationOn, PermMedia } from "@material-ui/icons";
import FirstScreen from "./screens/user_checkin_screens/FirstScreen";
import SecondScreen from "./screens/user_checkin_screens/SecondScreen";
import { useNavigate } from "react-router-dom";
import ViewImageFile from "../view_file_components/ViewImageFile";
import { useState } from "react";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  padding: 30px;
  background-color: white;
  height: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;

const Header = styled.span`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;
const SpacedItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 28px;
  column-gap: 20px;
  width: 428px;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
`;

const Column = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const SectionHeader = styled.span`
  font: normal normal 600 14px Open Sans;
  color: #979799;
  margin-bottom: 20px;
`;

const ViewUserCheckinModal = (props) => {
  const [fileData, setFileData] = useState({});
  const [openFile, setOpenFile] = useState(false);
  const navigate = useNavigate();
  const uid = localStorage.getItem("uid");
  const handleLocation = (value) => {
    if (value !== null) {
      let a = value;
      let array = a.split(",");
      let b = array[0];
      let c = array[1];
      window.open(`https://www.google.com/maps/?q=${b},${c}`, "_blank");
    }
  };
  return (
    <>
      <Container>
        <SpacedItems>
          <Row>
            <Info color="primary" />
            <Header>Info</Header>
          </Row>
          <Row>
            <Button
              onClick={() => handleLocation(props.data.latlong)}
              variant="outlined"
              color="primary"
              style={{ color: myTheme.Button.colorBlue, textTransform: "none" }}
            >
              <Row>
                <LocationOn color="error" style={{ cursor: "pointer" }} />
                Show in Map
              </Row>
            </Button>
          </Row>
        </SpacedItems>
        <Form>
          {props.data !== null ? (
            <>
              <FirstScreen data={props.data} />
              <SecondScreen data={props.data} />
              {props.data.hasOwnProperty("files") &&
                Object.keys(props.data.files).length > 0 && (
                  <>
                    <Divider style={{ margin: "10px 0px" }} />
                    <InputContainer style={{ paddingBottom: "20px" }}>
                      <InputWrapper style={{ width: "100%" }}>
                        <SectionHeader>CheckIn Files</SectionHeader>
                        <Column>
                          {Object.keys(props.data.files).map((item) => {
                            if (
                              props.data.files[item]["content_type"].includes(
                                "image"
                              )
                            ) {
                              return (
                                <IconButton
                                  onClick={() => {
                                    setFileData(props.data.files[item]);
                                    setOpenFile(true);
                                  }}
                                >
                                  <PermMedia />
                                </IconButton>
                              );
                            }
                          })}
                        </Column>
                      </InputWrapper>
                    </InputContainer>
                  </>
                )}
            </>
          ) : (
            <CircularProgress />
          )}
        </Form>
      </Container>
      {openFile && (
        <ViewImageFile open={openFile} setOpen={setOpenFile} data={fileData} />
      )}
    </>
  );
};

export default observer(ViewUserCheckinModal);
