import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  Grid,
  Modal,
  Typography,
} from "@material-ui/core";
import { Info } from "@material-ui/icons";
import {
  dataRenderer,
  getLocalizedText,
  getLocalTimeFromIsoString,
  IsoToLocalTime,
} from "../../Functions";
import ReactAudioPlayer from "react-audio-player";
import rootStore from "../../stores/RootStore";
import CallSummaryPanel from "../call/CallSummaryPanel";
import analytics from "../../Analytics";
import { Divider, styled } from "@mui/material";
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  ${`width: calc(50vw - 60px);`}
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const Form = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled(Box)`
  width: 100%;
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 500 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const ButtonWrapper = styled(Box)`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
`;

const ViewCallLogDetails = (props) => {
  const { phoneCallState } = rootStore;
  const [externalAudioUrl, setExternalAudioUrl] = useState(null);
  const [showSummaryButton, setShowSummaryButton] = useState(false);
  const [openSummary, setOpenSummary] = useState(false);
  const handleClose = () => {
    props.setData(null);
    props.setOpen(false);
  };

  useEffect(() => {
    analytics.triggerEvent(
      4625000068,
      "call_log_information_form_load",
      "call_logs_tab",
      "",
      {}
    );

    if (props.data !== null) {
      let dialerLog = {};

      if (props.data["dialer_log"] !== null) {
        dialerLog = props.data["dialer_log"];
      }
      let externalUrl = "";

      if (Object.keys(dialerLog).length > 0) {
        let summary = {};
        if (dialerLog.hasOwnProperty("summary_response")) {
          if (dialerLog["summary_response"] !== null) {
            summary = dialerLog["summary_response"];
            setShowSummaryButton(true);
          }
        }

        externalUrl = phoneCallState.getExternalDialerAudioUrl(summary);
        if (externalUrl !== "") {
          setExternalAudioUrl(externalUrl);
        }
      }
    }
  }, []);

  const noteTextGetter = (raw_string) => {
    return raw_string.replace(/\r\n|\n/g, "\n");
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        disableBackdropClick
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            {props.data !== null ? (
              <Container>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Row>
                    <Info color="primary" style={{ marginRight: "8px" }} />
                    <Header>{getLocalizedText("info")}</Header>
                  </Row>
                </Box>
                <Divider sx={{ margin: "16px 0px" }} />
                <Form>
                  <InputContainer>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <InputWrapper>
                          <Label>{getLocalizedText("caller_name")}</Label>
                          <Detail>
                            {dataRenderer(props.data.caller.name)}
                          </Detail>
                        </InputWrapper>
                      </Grid>
                      <Grid item xs={6}>
                        <InputWrapper>
                          <Label>{getLocalizedText("status")}</Label>{" "}
                          <Detail>
                            {dataRenderer(props.data.lead_status)}
                          </Detail>
                        </InputWrapper>
                      </Grid>
                      <Grid item xs={6}>
                        <InputWrapper>
                          <Label>{getLocalizedText("sub_status")}</Label>
                          <Detail>
                            {dataRenderer(props.data.lead_sub_status)}
                          </Detail>
                        </InputWrapper>
                      </Grid>
                      <Grid item xs={6}>
                        <InputWrapper>
                          <Label>{getLocalizedText("next_follow_up_on")}</Label>
                          <Detail>
                            {props.data.next_followup_on !== null
                              ? IsoToLocalTime(props.data.next_followup_on)
                              : "-"}
                          </Detail>
                        </InputWrapper>
                      </Grid>
                      <Grid item xs={12}>
                        <InputWrapper>
                          <Label>{getLocalizedText("call_notes")}</Label>
                          {props.data.notes !== "" &&
                          props.data.notes !== null ? (
                            <Typography
                              component="div"
                              style={{
                                whiteSpace: "pre-line",
                              }}
                            >
                              {noteTextGetter(props.data.notes)}
                            </Typography>
                          ) : (
                            <Detail>-</Detail>
                          )}
                        </InputWrapper>
                      </Grid>
                      <Grid item xs={12}>
                        <InputWrapper>
                          <Label>{getLocalizedText("customer_notes")}</Label>
                          {props.data.customer_notes !== "" &&
                          props.data.customer_notes !== null ? (
                            <Typography
                              component="div"
                              style={{
                                whiteSpace: "pre-line",
                              }}
                            >
                              {noteTextGetter(props.data.customer_notes)}
                            </Typography>
                          ) : (
                            <Detail>-</Detail>
                          )}
                        </InputWrapper>
                      </Grid>
                      <Grid item xs={6}>
                        <InputWrapper>
                          <Label>{getLocalizedText("call_start_time")}</Label>
                          <Detail>
                            {getLocalTimeFromIsoString(
                              props.data.call_start_time
                            )}
                          </Detail>
                        </InputWrapper>
                      </Grid>
                      <Grid item xs={6}>
                        <InputWrapper>
                          <Label>{getLocalizedText("call_end_time")}</Label>
                          <Detail>
                            {getLocalTimeFromIsoString(
                              props.data.call_end_time
                            )}
                          </Detail>
                        </InputWrapper>
                      </Grid>
                      <Grid item xs={6}>
                        <InputWrapper>
                          <Label>{getLocalizedText("call_durations")}</Label>
                          <Detail>
                            {props.data.call_duration !== null
                              ? `${props.data.call_duration}`
                              : "-"}
                          </Detail>
                        </InputWrapper>
                      </Grid>
                      <Grid item xs={6}>
                        {props.data.audio !== null && (
                          <InputWrapper>
                            <Label>{getLocalizedText("audio")}</Label>
                            <ReactAudioPlayer
                              src={props.data.audio}
                              autoPlay={false}
                              controls
                            />
                          </InputWrapper>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        {externalAudioUrl !== null && (
                          <InputWrapper>
                            <Label>{getLocalizedText("audio")}</Label>
                            <ReactAudioPlayer
                              src={externalAudioUrl}
                              autoPlay={false}
                              controls
                            />
                          </InputWrapper>
                        )}
                      </Grid>
                    </Grid>
                  </InputContainer>

                  <ButtonWrapper>
                    {showSummaryButton && (
                      <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          setOpenSummary(true);
                        }}
                        style={{
                          width: "120px",
                          textTransform: "none",
                          fontSize: "12px",
                          fontWeight: "bold",
                          marginRight: "8px",
                        }}
                      >
                        {getLocalizedText("call_summary")}
                      </Button>
                    )}
                    <Button
                      onClick={() => {
                        analytics.triggerEvent(
                          4625000069,
                          "close_button_tap",
                          "call_log_information_form",
                          "close_button",
                          {}
                        );
                        handleClose();
                      }}
                      type="button"
                      variant="outlined"
                      color="primary"
                      style={{
                        width: "120px",
                        textTransform: "none",
                        fontSize: "12px",
                        fontWeight: "bold",
                        marginLeft: showSummaryButton ? "8px" : "0px",
                      }}
                    >
                      {getLocalizedText("close")}
                    </Button>
                  </ButtonWrapper>
                </Form>
              </Container>
            ) : (
              <CircularProgress />
            )}
          </Box>
        </ModelContainer>
      </Modal>
      {openSummary && (
        <Drawer
          anchor={"right"}
          open={openSummary}
          onClose={() => {
            setOpenSummary(false);
          }}
        >
          {
            <CallSummaryPanel
              setOpen={openSummary}
              open={setOpenSummary}
              data={props.data["dialer_log"]["summary_response"]}
            />
          }
        </Drawer>
      )}
    </>
  );
};

export default observer(ViewCallLogDetails);
