import { Button, Drawer, Tooltip } from "@material-ui/core";
import { Equalizer, PlayArrow, Speed, Visibility } from "@material-ui/icons";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  TableCell,
  TableRow,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { CampaignListColumnsHeaders } from "../../Db";
import { IsoToLocalDate, dataRenderer } from "../../Functions";
import "../../pages/import_history/import.css";
import {
  getCampaignDetailsApi,
  getCampaignItemCountApi,
  startCampaignApi,
  testCampaignApi,
} from "../../Api";
import CampaignInfoPanel from "../market_modals/CampaignInfoPanel";
import analytics from "../../Analytics";
import rootStore from "../../stores/RootStore";
import TestCampaignDialog from "../alert_dialogue/TestCampaignDialog";
import { useNavigate } from "react-router-dom";

const CampaignRowComponent = ({ item, idx, setRefresh }) => {
  const [data, setData] = useState({});
  const [status, setStatus] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openTestCampaignDialog, setOpenTestCampaignDialog] = useState(false);
  const [isTestSuccess, setIsTestSuccess] = useState(false);
  const [itemCount, setItemCount] = useState(null);
  const navigate = useNavigate();
  const { id, campaign_type } = item;
  const [open, setOpen] = useState(false);
  const MINUTE_MS = 30000;

  const getUpdatedData = async () => {
    let response = await getCampaignDetailsApi(id);
    setData(response);
    if (response.hasOwnProperty("status")) {
      let temp_status = response["status"] !== null ? response["status"] : "";
      setStatus(temp_status);
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (
        status.toLowerCase() === "queued" ||
        status.toLowerCase() === "processing"
      ) {
        getUpdatedData();
      }
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, [status]);
  useEffect(() => {
    setData(item);
    let temp_status = item["status"] !== null ? item["status"] : "";
    setStatus(temp_status);
  }, []);

  const getItemCount = async () => {
    let response = await getCampaignItemCountApi(id);
    if (response.hasOwnProperty("count")) {
      setItemCount(response["count"]);
    } else {
      setItemCount(null);
    }
  };

  const startCompaign = async () => {
    let response = await startCampaignApi(data.id);
    setRefresh(true);
    setOpenConfirmDialog(false);
  };
  const handleStart = async () => {
    await getItemCount();
    setOpenConfirmDialog(true);
  };

  const testCampaign = async () => {
    let response = await testCampaignApi(id);
    if (response.hasError()) {
      setIsTestSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsLoading(false);
    } else {
      setIsTestSuccess(true);
      setIsLoading(false);
    }
  };

  const handleTestCampaign = async () => {
    setIsLoading(true);
    setOpenTestCampaignDialog(true);
    setTimeout(() => {
      testCampaign();
    }, 5000);
  };

  return (
    <>
      <TableRow
        onClick={() => {
          setOpen(true);
        }}
        key={idx}
        style={{
          width: "100%",
          cursor: "pointer",
          backgroundColor: idx % 2 === 0 ? "white" : "#F9F9FC",
        }}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        {Object.keys(data).length > 0 &&
          CampaignListColumnsHeaders.concat([{ field: "action" }]).map(
            (key) => {
              if (key["field"] === "action") {
                return (
                  <TableCell
                    className="textContainer"
                    style={{
                      display: "flex",
                      justiyContent: "center",
                      alignItems: "center",
                      height: "33px",
                      columnGap: "10px",
                    }}
                    align="center"
                  >
                    <Tooltip title={"View"}>
                      <Visibility
                        onClick={(e) => {
                          e.stopPropagation();
                          analytics.triggerEvent(
                            4625000250,
                            "view_button_tap",
                            "campaigns_page",
                            "view_button",
                            {}
                          );
                          setOpen(true);
                        }}
                        style={{
                          width: "18px",
                          height: "18px",
                        }}
                        color="primary"
                      />
                    </Tooltip>
                    <Tooltip title={"Start Campaign"}>
                      {status.toLowerCase() === "completed" ? (
                        <PlayArrow
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                          color="disabled"
                        />
                      ) : status.toLowerCase() === "processing" ||
                        status.toLowerCase() === "queued" ? (
                        <CircularProgress
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                        />
                      ) : (
                        <PlayArrow
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                          color="primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            analytics.triggerEvent(
                              4625000251,
                              "start_button_tap",
                              "campaigns_page",
                              "start_button",
                              {}
                            );
                            if (
                              data["campaign_type"].toLowerCase() === "offline"
                            ) {
                              startCompaign();
                            } else {
                              handleStart();
                            }
                          }}
                        />
                      )}
                    </Tooltip>
                    <Tooltip title={"Test Campaign"}>
                      {status.toLowerCase() === "created" &&
                      data["campaign_type"].toLowerCase() === "email" ? (
                        <Speed
                          onClick={(e) => {
                            e.stopPropagation();
                            handleTestCampaign();
                          }}
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                          color="primary"
                        />
                      ) : (
                        <Speed
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                          color="disabled"
                        />
                      )}
                    </Tooltip>

                    {status.toLowerCase() !== "completed" &&
                    status.toLowerCase() !== "processing" ? (
                      <Tooltip title={"Stats"}>
                        <Equalizer
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                          color="disabled"
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title={"Stats"}>
                        <Equalizer
                          onClick={(e) => {
                            e.stopPropagation();
                            if (
                              data["campaign_type"].toLowerCase() === "offline"
                            ) {
                              navigate(
                                `/campaigns/offline/${id}/${campaign_type}`
                              );
                            } else {
                              navigate(`/campaigns/${id}/${campaign_type}`);
                            }
                          }}
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                          color="primary"
                        />
                      </Tooltip>
                    )}
                  </TableCell>
                );
              } else if (key["field"] === "created_by") {
                let name =
                  data[key["field"]] !== null
                    ? data[key["field"]]["name"]
                    : "-";
                return (
                  <Tooltip title={name}>
                    <TableCell
                      className="textContainer"
                      style={{
                        width: "150px",
                        height: "33px",
                        fontSize: "14px",
                        fontFamily: "Open Sans",
                        color: "#414141",
                      }}
                    >
                      {name}
                    </TableCell>
                  </Tooltip>
                );
              } else if (key["field"] === "segment") {
                let name =
                  data[key["field"]] !== null
                    ? data[key["field"]]["title"]
                    : "-";
                return (
                  <Tooltip title={name}>
                    <TableCell
                      className="textContainer"
                      style={{
                        width: "150px",
                        height: "33px",
                        fontSize: "14px",
                        fontFamily: "Open Sans",
                        color: "#414141",
                      }}
                    >
                      {name}
                    </TableCell>
                  </Tooltip>
                );
              } else if (key["field"] === "campaign_provider") {
                let name =
                  data[key["field"]] !== null
                    ? data[key["field"]]["campaign_provider_name"]
                    : "-";
                return (
                  <Tooltip title={name}>
                    <TableCell
                      className="textContainer"
                      style={{
                        width: "150px",
                        height: "33px",
                        fontSize: "14px",
                        fontFamily: "Open Sans",
                        color: "#414141",
                      }}
                    >
                      {name}
                    </TableCell>
                  </Tooltip>
                );
              } else if (
                key["field"] === "created_at" ||
                key["field"] === "started_at" ||
                key["field"] === "completed_at"
              ) {
                let date =
                  data[key["field"]] !== null && data[key["field"]] !== ""
                    ? IsoToLocalDate(data[key["field"]])
                    : "-";
                return (
                  <Tooltip title={date}>
                    <TableCell
                      className="textContainer"
                      style={{
                        width: "150px",
                        height: "33px",
                        fontSize: "14px",
                        fontFamily: "Open Sans",
                        color: "#414141",
                      }}
                    >
                      {date}
                    </TableCell>
                  </Tooltip>
                );
              } else {
                return (
                  <Tooltip title={data[key["field"]]}>
                    <TableCell
                      className="textContainer"
                      style={{
                        width: "150px",
                        height: "33px",
                        fontSize: "14px",
                        fontFamily: "Open Sans",
                        color: "#414141",
                      }}
                    >
                      {dataRenderer(data[key["field"]])}
                    </TableCell>
                  </Tooltip>
                );
              }
            }
          )}
      </TableRow>
      <Dialog
        open={openConfirmDialog}
        onClose={() => {
          setOpenConfirmDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {campaign_type.toLowerCase() === "email" ? (
          <DialogTitle id="alert-dialog-title" style={{ fontWeight: "bold" }}>
            Confirmation: Sending emails to {itemCount}{" "}
            {itemCount === 1 ? "lead" : "leads"}?
          </DialogTitle>
        ) : (
          <DialogTitle id="alert-dialog-title" style={{ fontWeight: "bold" }}>
            Confirmation: Running campaign for {itemCount}{" "}
            {itemCount === 1 ? "lead" : "leads"}?
          </DialogTitle>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              setOpenConfirmDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              startCompaign();
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {open && (
        <Drawer
          anchor={"right"}
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          {<CampaignInfoPanel setOpen={setOpen} open={open} id={data.id} />}
        </Drawer>
      )}

      {openTestCampaignDialog && (
        <TestCampaignDialog
          open={openTestCampaignDialog}
          setOpen={setOpenTestCampaignDialog}
          isLoading={isLoading}
          loadingTitle={"Test Run"}
          loadingMessage={`Sending test email to ${rootStore.userStore.UserData.email}`}
          successMessage={"Test Email Successfully Sent"}
          errorMessage={errorMessage}
          isTestSuccess={isTestSuccess}
        />
      )}
    </>
  );
};

export default observer(CampaignRowComponent);
