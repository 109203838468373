import { Box } from "@material-ui/core";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { findClosestMatchColumn } from "../../../../Functions";
import { Button, Typography } from "@mui/material";
import Select from "react-select";
import { Add } from "@material-ui/icons";
import AddNewMappingFieldDialog from "../external_webform_config_dialogs/AddNewMappingFieldDailog";
import FieldNameWithActionsWidget from "./widgets/FieldNameWithActionsWidget";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  margin: auto;
  background-color: white;
  height: 100%;
  padding: 16px 0px;
  padding-bottom: 0px;
  overflow-y: auto;
  row-gap: 10px;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const RowWrapper = styled(Box)`
  display: flex;
  column-gap: 10px;
  width: 100%;
`;

const ColumnWrapper = styled(Box)`
  display: flex;
  align-items: center;
  padding: 0px;
  margin-top: 16px;
  width: 100%;
`;
const ColumnHeader = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  width: 100%;
`;
const Header = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #a9a9a9;
`;
const ColumnMappingScreen = ({
  projectColumnList,
  formAndProjectColumnMapping,
  setFormAndProjectColumnMapping,
  isAlreadySelectedProjectField,
}) => {
  const [openAddFieldDialog, setOpenAddFieldDialog] = useState(false);
  const [selectedFieldName, setSelectedFieldName] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      fontWieght: "bold",
      // Font size for options
    }),
    menuList: (base) => ({
      ...base,
      minHeight: "200px", // your desired height
    }),
    control: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      width: "284px",
      fontWieght: "bold",
      // Font size for the entire control container, including the search input
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      // Font size for "no options" message
    }),
    loadingMessage: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      // Font size for "loading" message
    }),
  };

  const getProjectColumnsForDropdown = () => {
    let tempList = [];
    projectColumnList.forEach((column) => {
      let tempObj = {
        label: column.headerName,
        value: column.field,
        isDisabled: isAlreadySelectedProjectField(column["field"]),
        required: column.required,
      };
      tempList.push(tempObj);
    });
    return tempList;
  };

  const canUseColumnForMapping = ({
    mappedDataList,
    destProjectColumnField,
  }) => {
    for (const value of Object.values(mappedDataList)) {
      if (
        value["project_lead"].hasOwnProperty("field") &&
        value["project_lead"]["field"] === destProjectColumnField &&
        value["mapped"] === true
      ) {
        return false;
      }
    }
    return true;
  };

  const handleAddNewField = (fieldName) => {
    let tempFormAndProjectColumnMapping = { ...formAndProjectColumnMapping };
    if (!tempFormAndProjectColumnMapping.hasOwnProperty(fieldName)) {
      let projectLead = {};
      let mapped = false;
      let filteredProjectColumnList = projectColumnList.filter((col) =>
        canUseColumnForMapping({
          mappedDataList: tempFormAndProjectColumnMapping,
          destProjectColumnField: col["field"],
        })
      );
      let index = findClosestMatchColumn(fieldName, filteredProjectColumnList);
      if (index !== -1) {
        mapped = true;
        projectLead = {
          id: filteredProjectColumnList[index]["id"],
          field: filteredProjectColumnList[index]["field"],
          display_name: filteredProjectColumnList[index]["headerName"],
          data_type: filteredProjectColumnList[index]["data_type"],
          is_custom_param: filteredProjectColumnList[index]["is_custom_param"],
          label: filteredProjectColumnList[index].headerName,
          value: filteredProjectColumnList[index].field,
          isDisabled: isAlreadySelectedProjectField(
            filteredProjectColumnList[index].field
          ),
          required: filteredProjectColumnList[index].required,
        };
      }

      let tempObj = {
        form_lead: {
          field: fieldName,
          display_name: fieldName,
        },
        project_lead: projectLead,
        mapped: mapped,
      };
      tempFormAndProjectColumnMapping[fieldName] = tempObj;
    }
    setFormAndProjectColumnMapping(tempFormAndProjectColumnMapping);
    setOpenAddFieldDialog(false);
  };

  const handleEditField = (fieldName) => {
    let tempFormAndProjectColumnMapping = { ...formAndProjectColumnMapping };
    if (tempFormAndProjectColumnMapping.hasOwnProperty(selectedFieldName)) {
      console.log("doing edit field::");
      let tempFieldMappingData =
        tempFormAndProjectColumnMapping[selectedFieldName];
      tempFormAndProjectColumnMapping[fieldName] = tempFieldMappingData;
      tempFieldMappingData["form_lead"]["field"] = fieldName;
      tempFieldMappingData["form_lead"]["display_name"] = fieldName;
      delete tempFormAndProjectColumnMapping[selectedFieldName];
    }
    setFormAndProjectColumnMapping(tempFormAndProjectColumnMapping);
    setSelectedFieldName("");
    setOpenAddFieldDialog(false);
  };

  const handleDeleteField = (fieldName) => {
    let tempFormAndProjectColumnMapping = { ...formAndProjectColumnMapping };
    delete tempFormAndProjectColumnMapping[fieldName];
    setFormAndProjectColumnMapping(tempFormAndProjectColumnMapping);
  };

  return (
    <>
      <Box sx={{ padding: "0px 16px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Header>Field Mapping</Header>

          {Object.keys(formAndProjectColumnMapping).length > 0 && (
            <Button
              onClick={() => {
                setSelectedFieldName("");
                setIsEdit(false);
                setOpenAddFieldDialog(true);
              }}
              variant="text"
              color="primary"
              style={{
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                display: "Flex",
                alignItems: "center",
              }}
            >
              <Add
                style={{ height: "20px", width: "20px", marginRight: "6px" }}
              />{" "}
              Map Field
            </Button>
          )}
        </Box>

        {Object.keys(formAndProjectColumnMapping).length > 0 ? (
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <ColumnWrapper>
              <ColumnHeader>Form Field</ColumnHeader>
              <ColumnHeader>Project Field</ColumnHeader>
            </ColumnWrapper>
            <Container>
              {Object.keys(formAndProjectColumnMapping).map((item, index) => {
                return (
                  <RowWrapper>
                    <FieldNameWithActionsWidget
                      fieldName={
                        formAndProjectColumnMapping[item]["form_lead"]["field"]
                      }
                      handleEdit={() => {
                        setSelectedFieldName(item);
                        setIsEdit(true);
                        setOpenAddFieldDialog(true);
                      }}
                      handleRemove={() => {
                        handleDeleteField(item);
                      }}
                    />
                    <Select
                      menuPosition="fixed"
                      key={index}
                      maxMenuHeight={150}
                      value={formAndProjectColumnMapping[item]["project_lead"]}
                      required={formAndProjectColumnMapping[item]["checked"]}
                      onChange={(e) => {
                        if (e === null) {
                          setFormAndProjectColumnMapping({
                            ...formAndProjectColumnMapping,
                            [item]: {
                              ...formAndProjectColumnMapping[item],
                              project_lead: {},
                              mapped: false,
                            },
                          });
                        } else {
                          let index = projectColumnList.findIndex(
                            (column) => column["field"] === e.value
                          );

                          setFormAndProjectColumnMapping({
                            ...formAndProjectColumnMapping,
                            [item]: {
                              ...formAndProjectColumnMapping[item],
                              mapped: true,
                              project_lead: {
                                ...e,
                                id: projectColumnList[index]["id"],
                                field: projectColumnList[index]["field"],
                                display_name:
                                  projectColumnList[index]["headerName"],
                                data_type:
                                  projectColumnList[index]["data_type"],
                                is_custom_param:
                                  projectColumnList[index]["is_custom_param"],
                              },
                            },
                          });
                        }
                      }}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      options={getProjectColumnsForDropdown()}
                      closeMenuOnSelect={true}
                      isClearable
                      styles={customStyles}
                      menuShouldScrollIntoView={false}
                      formatOptionLabel={(option) => {
                        if (option.required) {
                          return (
                            <Typography sx={{ fontSize: "12px" }}>
                              <span style={{ color: "red" }}>*</span>
                              {option.label}
                            </Typography>
                          );
                        } else {
                          return (
                            <Typography sx={{ fontSize: "12px" }}>
                              {option.label}
                            </Typography>
                          );
                        }
                      }}
                    ></Select>
                  </RowWrapper>
                );
              })}
            </Container>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Button
              onClick={() => {
                setOpenAddFieldDialog(true);
              }}
              variant="text"
              color="primary"
              style={{
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                display: "Flex",
                alignItems: "center",
              }}
            >
              <Add
                style={{ height: "20px", width: "20px", marginRight: "6px" }}
              />{" "}
              Map Field
            </Button>
          </Box>
        )}
      </Box>

      {openAddFieldDialog && (
        <AddNewMappingFieldDialog
          open={openAddFieldDialog}
          setOpen={setOpenAddFieldDialog}
          handlePostAdd={(fieldName) => {
            handleAddNewField(fieldName);
          }}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          editableFieldName={selectedFieldName}
          handlePostEdit={(fieldName) => {
            handleEditField(fieldName);
          }}
        />
      )}
    </>
  );
};

export default ColumnMappingScreen;
