import React, { useState } from "react";
import { styled } from "@mui/system";
import { Grid, Modal, makeStyles } from "@material-ui/core";
import {
  Box,
  Button,
  OutlinedInput,
  Typography,
  Divider,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

import { indianStatesList, quotationCountriesList } from "../../Db";

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "fit-content",
  textAlign: "left",
  margin: "auto",
  backgroundColor: "white",
  height: "fit-content",
  "&::-webkit-scrollbar": {
    width: "0.2em",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px #ffffff",
  },
});

const InputContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "fit-content",
  width: "320px",
  rowGap: "20px",
});

const Label = styled(Typography)({
  font: "normal normal normal 10px Open Sans",
  color: "#4d4e4f",
});

const InputWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const ModelContainer = styled(Box)({
  position: "absolute",
  top: "0px",
  bottom: "0px",
  left: "0px",
  right: "0px",
  margin: "auto",
  height: "fit-content",
  width: "fit-content",
  border: "none",
  outline: "none",
  backgroundColor: "white",
  boxShadow: "0px 3px 6px #0000000d",
  borderRadius: "8px",
  padding: "30px",
  border: "1px solid #c5d7f1",
  boxShadow: "0px 3px 20px #185dd21f",
});

const ButtonWrapper = styled(Box)({
  marginTop: "20px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  columnGap: "10px",
});

const Header = styled(Typography)({
  font: "normal normal 600 18px Open Sans",
  color: "#4d4e4f",
  margin: "0px",
});

const SubHeader = styled(Typography)({
  font: "normal normal normal 14px Open Sans",
  color: "#6f6f6f",
  margin: "0px",
  marginTop: "2px",
});

const ConfigureShippingAddressModal = (props) => {
  const classes = useStyles();
  const [shippingDetails, setShippingDetails] = useState({
    shipping_name: props.addressConfig["shipping_name"],
    shipping_address: props.addressConfig["shipping_address"],
    shipping_city: props.addressConfig["shipping_city"],
    shipping_state: props.addressConfig["shipping_state"],
    shipping_country: props.addressConfig["shipping_country"],
    shipping_zipcode: props.addressConfig["shipping_zipcode"],
  });

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleSubmit = () => {
    props.onSubmitCallback(shippingDetails);
    props.setOpen(false);
  };

  const handleChange = (name, value) => {
    setShippingDetails({
      ...shippingDetails,
      [name]: value,
    });
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Header>Shipping Address</Header>
          <SubHeader>Fill in the below details</SubHeader>
          <Divider style={{ margin: "10px 0px" }} />
          <Box>
            <Container
              component={"form"}
              onSubmit={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleSubmit();
              }}
            >
              <InputContainer>
                <Grid item container spacing={2}>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Shipping Name</Label>
                      <OutlinedInput
                        style={{ height: "30px" }}
                        inputProps={{
                          min: 0,
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                        name="shipping_name"
                        // required
                        value={shippingDetails["shipping_name"]}
                        placeholder="Enter value"
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Shipping Address</Label>
                      <OutlinedInput
                        multiline
                        rows={3}
                        inputProps={{
                          min: 0,
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                        name="shipping_address"
                        value={shippingDetails["shipping_address"]}
                        placeholder="Enter value"
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>Shipping Country</Label>
                      <FormControl
                        sx={{ width: "100%" }}
                        classes={{
                          root: classes.quantityRoot,
                        }}
                      >
                        <Select
                          classes={{
                            icon: classes.icon,
                          }}
                          displayEmpty
                          onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                          }
                          name="shipping_country"
                          value={shippingDetails["shipping_country"]}
                          disableUnderline
                          variant="outlined"
                          style={{
                            width: "100%",
                            height: "30px",
                            fontSize: "12px",
                            borderRadius: "4px",
                          }}
                        >
                          {quotationCountriesList.map((item, i) => {
                            return (
                              <MenuItem
                                value={item}
                                key={i}
                                style={{ fontSize: "12px" }}
                              >
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>Shipping State</Label>
                      <FormControl
                        sx={{ width: "100%" }}
                        classes={{
                          root: classes.quantityRoot,
                        }}
                      >
                        <Select
                          classes={{
                            icon: classes.icon,
                          }}
                          displayEmpty
                          onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                          }
                          name="shipping_state"
                          value={shippingDetails["shipping_state"]}
                          disableUnderline
                          variant="outlined"
                          style={{
                            width: "100%",
                            height: "30px",
                            fontSize: "12px",
                            borderRadius: "4px",
                          }}
                        >
                          {shippingDetails["shipping_country"] === "India" &&
                            indianStatesList.map((item, i) => {
                              return (
                                <MenuItem
                                  value={item}
                                  key={i}
                                  style={{ fontSize: "12px" }}
                                >
                                  {item}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>Shipping City</Label>
                      <OutlinedInput
                        style={{ height: "30px" }}
                        inputProps={{
                          min: 0,
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                        name="shipping_city"
                        value={shippingDetails["shipping_city"]}
                        placeholder="Enter value"
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>Shipping Zipcode</Label>
                      <OutlinedInput
                        style={{ height: "30px" }}
                        inputProps={{
                          min: 0,
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                        name="shipping_zipcode"
                        value={shippingDetails["shipping_zipcode"]}
                        placeholder="Enter value"
                      />
                    </InputWrapper>
                  </Grid>
                </Grid>
              </InputContainer>
              <ButtonWrapper>
                <Button
                  onClick={handleClose}
                  type="button"
                  variant="contained"
                  color="inherit"
                  style={{
                    textTransform: "none",

                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "none",

                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  Submit
                </Button>
              </ButtonWrapper>
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default ConfigureShippingAddressModal;
