import { styled } from "@mui/material/styles";
import { useMemo, useState } from "react";
import { useEffect } from "react";
import {
  getAdminStagesApi,
  getProjectAdministrationReOrederedStagesApi,
} from "../../Api";
import { Edit } from "@material-ui/icons";
import AddStageModal from "../admin_setting_modals/AddStageModal";
import analytics from "../../Analytics";
import {
  Box,
  CircularProgress,
  Drawer,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AdminStageColumns, AdminStageHiddenColumns } from "../../Db";
import { Chip } from "@mui/material";
import { dataRenderer } from "../../Functions";
import ViewAdminStageSidepanel from "../admin_setting_modals/view_sidepanels/ViewAdminStageSidepanel";

const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 262px);`};
  position: relative;
`;

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          style={{ fontSize: "10px" }}
          variant="caption"
          component="div"
          color="text.secondary"
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const StagesTab = (props) => {
  const [row, setRow] = useState([]);
  const [visibleRow, setVisibleRow] = useState([]);
  const [hiddenRow, setHiddenRow] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [editableData, setEditableData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openDetailSidepanel, setOpenDetailSidepanel] = useState(false);

  const getStagesList = async () => {
    setRow([]);
    setVisibleRow([]);
    setHiddenRow([]);
    setLoading(true);
    try {
      let response = await getAdminStagesApi(props.id);
      //let response = mockResponse; //await getAdminStagesApi(props.id);
      setRow(response.data);
      //split row data based on "is_visible" key for visible/hidden tabs
      let visibleData = response.data.filter((x) => x.is_visible);
      setVisibleRow(visibleData);

      let hiddenData = response.data.filter((x) => !x.is_visible);
      setHiddenRow(hiddenData);
      setLoading(false);
    } catch (error) {
      setRow([]);
      setLoading(false);
      console.log(error);
    }
  };
  const init = async () => {
    await getStagesList();
  };
  useEffect(() => {
    init();
  }, []);
  useEffect(() => {
    if (refresh) {
      init();
      setRefresh(false);
    }
  }, [refresh]);

  const getReOrderedStageList = async ({ payload }) => {
    setRow([]);
    setVisibleRow([]);
    setLoading(true);
    let response = await getProjectAdministrationReOrederedStagesApi({
      projectId: props.id,
      payload: payload,
    });
    setRow(response);
    let visibleData = response.filter((x) => x.is_visible);
    setVisibleRow(visibleData);
    let hiddenData = response.filter((x) => !x.is_visible);
    setHiddenRow(hiddenData);

    setLoading(false);
  };

  const onRowDragEnd = async (event) => {
    const gridApi = event.api;
    let draggedItem = event.node.data;
    let newOrderList = [];
    gridApi.forEachNode((node, i) => {
      let tempObj = { id: node.data.id, rank: i + 1 };
      newOrderList.push(tempObj);
    });

    let prevIndex = visibleRow.findIndex(
      (data) => data["id"] === draggedItem["id"]
    );
    let newIndex = newOrderList.findIndex(
      (data) => data["id"] === draggedItem["id"]
    );

    if (prevIndex !== newIndex) {
      await getReOrderedStageList({
        payload: {
          stage_ranks: newOrderList,
        },
      });
    }
  };
  const actionColumn = [
    // {
    //   field: "probability",
    //   headerName: "PROBABILITY",
    //   width: 130,
    //   cellRenderer: (params) => {
    //     if (
    //       params.data.probability !== null &&
    //       params.data.probability !== ""
    //     ) {
    //       return <CircularProgressWithLabel value={params.data.probability} />;
    //     } else {
    //       return "-";
    //     }
    //   },
    // },
    {
      field: "mark_as_won",
      headerName: "MARK AS WON",
      width: 130,
      cellRenderer: (params) => {
        if (
          params.data.mark_as_won !== undefined &&
          params.data.mark_as_won !== null &&
          params.data.mark_as_won !== ""
        ) {
          if (params.data.mark_as_won === true) {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="success"
                label={"Yes"}
              />
            );
          } else {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="warning"
                label={"No"}
              />
            );
          }
        } else {
          return "-";
        }
      },
    },
    {
      field: "mark_as_lost",
      headerName: "MARK AS LOST",
      width: 130,
      cellRenderer: (params) => {
        if (
          params.data.mark_as_lost !== undefined &&
          params.data.mark_as_lost !== null &&
          params.data.mark_as_lost !== ""
        ) {
          if (params.data.mark_as_lost === true) {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="success"
                label={"Yes"}
              />
            );
          } else {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="warning"
                label={"No"}
              />
            );
          }
        } else {
          return "-";
        }
      },
    },
    {
      field: "mark_as_closed",
      headerName: "MARK AS CLOSED",
      width: 130,
      cellRenderer: (params) => {
        if (
          params.data.mark_as_closed !== undefined &&
          params.data.mark_as_closed !== null &&
          params.data.mark_as_closed !== ""
        ) {
          if (params.data.mark_as_closed === true) {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="success"
                label={"Yes"}
              />
            );
          } else {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="warning"
                label={"No"}
              />
            );
          }
        } else {
          return "-";
        }
      },
    },
    {
      field: "rotting_in",
      headerName: "ROTTING IN (DAYS)",

      width: 180,
      cellRenderer: (params) => {
        let value = dataRenderer(params.data.rotting_in);
        return (
          <>
            <Tooltip title={value}>
              <span className="csutable-cell-trucate">{value}</span>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "ACTIONS",
      minWidth: 100,
      flex: 1,
      resizable: false,
      cellRenderer: (params) => {
        return (
          <Edit
            onClick={(e) => {
              analytics.triggerEvent(
                4625000203,
                "edit_button_tap",
                "project_stages_page",
                "edit_button",
                {}
              );
              e.stopPropagation();
              e.preventDefault();
              setEditableData(params.data);
              setIsEdit(true);
              props.setOpen(true);
            }}
            style={{
              marginRight: "20px",
              width: "18px",
              height: "18px",
              cursor: "pointer",
            }}
            color="primary"
          />
        );
      },
    },
  ];

  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));

  return (
    <>
      {!loading ? (
        <TableWrapper>
          <div className="ag-theme-alpine" style={{ width: "100%" }}>
            {(() => {
              if (props.tabValue === "visible") {
                return (
                  <>
                    <AgGridReact
                      onRowDragEnd={onRowDragEnd}
                      rowDragManaged={true}
                      domLayout="autoHeight"
                      suppressCellFocus
                      gridOptions={gridOptions}
                      loadingOverlayComponent={<CircularProgress />}
                      rowData={visibleRow}
                      defaultColDef={defaultColDef}
                      columnDefs={AdminStageColumns.concat(actionColumn)}
                      animateRows={true}
                      onRowClicked={(row) => {
                        if (row.event.defaultPrevented) {
                          return null;
                        }
                        setEditableData(row.data);
                        setOpenDetailSidepanel(true);
                      }}
                    />
                  </>
                );
              } else {
                return (
                  <>
                    <AgGridReact
                      domLayout="autoHeight"
                      suppressCellFocus
                      gridOptions={gridOptions}
                      loadingOverlayComponent={<CircularProgress />}
                      rowData={hiddenRow}
                      defaultColDef={defaultColDef}
                      columnDefs={AdminStageHiddenColumns.concat(actionColumn)}
                      animateRows={true}
                      onRowClicked={(row) => {
                        if (row.event.defaultPrevented) {
                          return null;
                        }
                        setEditableData(row.data);
                        setOpenDetailSidepanel(true);
                      }}
                    />
                  </>
                );
              }
            })()}
          </div>
        </TableWrapper>
      ) : (
        <TableWrapper
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </TableWrapper>
      )}
      {props.open && (
        <AddStageModal
          id={props.id}
          setOpen={props.setOpen}
          open={props.open}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          editableData={editableData}
          refresh={refresh}
          setRefresh={setRefresh}
          stageList={row}
        />
      )}

      {openDetailSidepanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openDetailSidepanel}
          onClose={() => {
            setOpenDetailSidepanel(false);
          }}
        >
          <ViewAdminStageSidepanel
            details={editableData}
            handleEdit={() => {
              setIsEdit(true);
              props.setOpen(true);
              setOpenDetailSidepanel(false);
            }}
          />
        </Drawer>
      )}
    </>
  );
};
export default StagesTab;
