import React from "react";
import { Menu, MenuItem } from "@mui/material";

const UserToMentionDialog = ({
  anchor,
  handleMenuClick,
  handleClose,
  itemList,
}) => {
  const open = Boolean(anchor);
  return (
    <Menu
      style={{
        display: "flex",
        flexDirection: "column",
      }}
      id="long-menu"
      MenuListProps={{
        "aria-labelledby": "long-button",
      }}
      anchorReference="anchorPosition"
      anchorPosition={
        anchor !== null ? { top: anchor.top, left: anchor.left } : undefined
      }
      open={open}
      onClose={handleClose}
      slotProps={{
        paper: {
          style: {
            width: "fit-content",
          },
        },
      }}
    >
      {itemList.map((item, index) => (
        <MenuItem
          onClick={() => handleMenuClick(item)}
          style={{
            fontSize: "10px",
          }}
          key={index}
        >
          {item.name}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default UserToMentionDialog;
