import React from "react";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { Box, Typography } from "@mui/material";
import LeadFormComponent from "../lead_form_components/LeadFormComponent";
import rootStore from "../../stores/RootStore";

const Container = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 334px);`};
  padding-bottom: 20px;
`;

const Wrapper = styled(Box)`
  width: 100%;
`;
const Message = styled(Typography)`
  font: normal normal 500 14px Open Sans;
  color: #4d4e4f;
`;
const LeadFormTab = ({
  leadData,
  leadID,
  setRefreshLeadDetails,
  isLeadFormDisabled,
}) => {
  const formMeta = { ...rootStore.authStore.leadForms };
  return (
    <>
      {Object.keys(formMeta).length > 0 ? (
        <Container>
          <Wrapper>
            <LeadFormComponent
              isLeadFormDisabled={isLeadFormDisabled}
              formMeta={formMeta}
              leadData={leadData}
              leadID={leadID}
              setRefreshLeadDetails={setRefreshLeadDetails}
            />
          </Wrapper>
        </Container>
      ) : (
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Message>No data found.</Message>
        </Container>
      )}
    </>
  );
};

export default observer(LeadFormTab);
