import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, Grid } from "@material-ui/core";
import {
  AppBar,
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Toolbar,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {
  getLocalizedText,
  getWorkTimingBreakChipColorTheme,
} from "../../Functions";
import { daysList, endTimeBufferList } from "../../Db";
import {
  createProjectAdminWorkdayTemplateApi,
  updateProjectAdminWorkdayTemplateApi,
} from "../../Api";
import rootStore from "../../stores/RootStore";
import moment from "moment";
import { Add, Settings } from "@material-ui/icons";
import ConfigureWorkDay from "./dialogs/ConfigureWorkDay";
import AddBreakDialog from "./dialogs/AddBreakDialog";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  gap: 20px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DetailsHeader = styled(Typography)`
  font: normal normal 600 16px Open Sans;
  color: #979797;
  margin-bottom: 8px;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;
const ChipText = styled(Typography)`
  font: normal normal 600 10px Open Sans;
`;
const CreateWorkDayTemplateComponent = (props) => {
  const defaultEndTimeBuffer = 10;
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [details, setDetails] = useState({
    name: "",
    work_end_time: "",
    work_start_time: "",
    end_time_buffer: defaultEndTimeBuffer,
    auto_logout: false,
    daywise: false,
  });
  const [dayData, setDayData] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });
  const [openConfigureWorkDay, setOpenConfigureWorkDay] = useState(false);
  const [dayWiseConfiguration, setDayWiseConfiguration] = useState({});
  const [selectedDay, setSelectedDay] = useState("");
  const [commonBreaks, setCommonBreaks] = useState([]);
  const [openAddBreakDialog, setOpenAddBreakDialog] = useState(false);

  const handleClose = () => {
    props.setIsEdit(false);
    props.setOpen(false);
  };
  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const createWorkDayTemplate = async () => {
    let payload = generatePayload();
    let response = await createProjectAdminWorkdayTemplateApi({
      projectID: rootStore.authStore.projectId,
      payload: payload,
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      props.setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };

  const isAllDaywiseDataMapped = () => {
    for (let key in dayData) {
      if (dayData[key] === true) {
        if (dayWiseConfiguration.hasOwnProperty(key)) {
          if (Object.keys(dayWiseConfiguration[key]).length === 0) {
            setErrorMessage(
              "Timings are not configured for one or more days. Please select the common timings option instead."
            );
            return false;
          }
        } else {
          setErrorMessage(
            "Timings are not configured for one or more days. Please select the common timings option instead."
          );
          return false;
        }
      }
    }
    return true;
  };

  const generateDaywisePayloadData = () => {
    let tempList = {};
    for (let key in dayData) {
      if (dayWiseConfiguration.hasOwnProperty(key)) {
        let tempObj = { ...dayWiseConfiguration[key] };
        if (Object.keys(tempObj).length > 0) {
          tempObj["start_time"] = changeTimeToISO(tempObj["start_time"]);
          tempObj["end_time"] = changeTimeToISO(tempObj["end_time"]);
          tempObj["breaks"].map((obj) => {
            obj["start_time"] = changeTimeToISO(obj["start_time"]);
            obj["end_time"] = changeTimeToISO(obj["end_time"]);
          });
        }
        let newKey = key + "_timings";
        tempList[newKey] = tempObj;
      } else {
        let newKey = key + "_timings";
        tempList[newKey] = {};
      }
    }
    return tempList;
  };

  const updateWorkDayTemplate = async () => {
    let payload = generatePayload();
    let response = await updateProjectAdminWorkdayTemplateApi({
      projectID: rootStore.authStore.projectId,
      payload: payload,
      templateID: props.editableData["id"],
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      props.setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };

  const isAtleastOneDayChecked = () => {
    return Object.values(dayData).some((value) => value === true);
  };
  const changeTimeToISO = (value) => {
    const isoString = moment(
      `1970-01-01 ${value}`,
      "YYYY-MM-DD HH:mm"
    ).toISOString();
    return isoString;
  };

  const getLocalTimeFromIso = (isoDateString) => {
    if (isoDateString !== null && isoDateString !== undefined) {
      return moment.utc(isoDateString).local().format("HH:mm");
    } else {
      return "-";
    }
  };

  const generatePayload = () => {
    let tempDayData = {};
    for (let key in dayData) {
      let newKey = "workday_" + key;
      tempDayData[newKey] = dayData[key];
    }
    let dayWiseData = generateDaywisePayloadData();
    let payload = {
      name: details["name"],
      auto_logout: details["auto_logout"],
      daywise: details["daywise"],
      ...tempDayData,
      ...dayWiseData,
    };

    if (details["daywise"] === false) {
      let tempBreakList = [];
      commonBreaks.forEach((obj) => {
        let tempObj = { ...obj };
        tempObj["start_time"] = changeTimeToISO(obj["start_time"]);
        tempObj["end_time"] = changeTimeToISO(obj["end_time"]);
        tempBreakList.push(tempObj);
      });
      let tempObj = {
        start_time:
          details["work_start_time"] !== ""
            ? changeTimeToISO(details["work_start_time"])
            : null,
        end_time:
          details["work_end_time"] !== ""
            ? changeTimeToISO(details["work_end_time"])
            : null,
        breaks: tempBreakList,
        end_time_buffer: parseInt(details["end_time_buffer"]),
      };
      payload["timings_all"] = tempObj;
    } else {
      payload["timings_all"] = {};
    }
    return payload;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isAtleastOneDayChecked()) {
      if (details["daywise"] === true) {
        if (isAllDaywiseDataMapped()) {
          setIsSubmitFail(false);
          if (props.isEdit === true) {
            await updateWorkDayTemplate();
          } else {
            await createWorkDayTemplate();
          }
        } else {
          setIsSubmitFail(true);
        }
      } else {
        if (props.isEdit === true) {
          await updateWorkDayTemplate();
        } else {
          await createWorkDayTemplate();
        }
      }
    } else {
      setErrorMessage(
        "Please select at least one day before saving the work timing template."
      );
      setIsSubmitFail(true);
    }
  };

  const initDayWiseConfiguration = (dayData) => {
    let newList = {};
    for (let key in dayData) {
      if (dayData[key] === true) {
        let newKey = key + "_timings";
        let tempObj = { ...props.editableData[newKey] };

        if (Object.keys(tempObj).length > 0) {
          if (
            tempObj.hasOwnProperty("end_time_buffer") &&
            tempObj["end_time_buffer"] !== null
          ) {
            tempObj["end_time_buffer"] = parseInt(tempObj["end_time_buffer"]);
          } else {
            tempObj["end_time_buffer"] = defaultEndTimeBuffer;
          }
          tempObj["start_time"] = getLocalTimeFromIso(tempObj["start_time"]);
          tempObj["end_time"] = getLocalTimeFromIso(tempObj["end_time"]);
          tempObj["breaks"] = tempObj["breaks"].map((obj) => {
            return {
              ...obj,
              start_time: getLocalTimeFromIso(obj["start_time"]),
              end_time: getLocalTimeFromIso(obj["end_time"]),
              secure_break:
                obj.hasOwnProperty("secure_break") &&
                obj["secure_break"] !== null
                  ? obj["secure_break"]
                  : false,
            };
          });
        }
        newList[key] = tempObj;
      }
    }
    setDayWiseConfiguration(newList);
  };

  useEffect(() => {
    if (props.isEdit === true) {
      let tempDayData = {
        monday:
          props.editableData.workday_monday !== null
            ? props.editableData.workday_monday
            : false,
        tuesday:
          props.editableData.workday_tuesday !== null
            ? props.editableData.workday_tuesday
            : false,
        wednesday:
          props.editableData.workday_wednesday !== null
            ? props.editableData.workday_wednesday
            : false,
        thursday:
          props.editableData.workday_thursday !== null
            ? props.editableData.workday_thursday
            : false,
        friday:
          props.editableData.workday_friday !== null
            ? props.editableData.workday_friday
            : false,
        saturday:
          props.editableData.workday_saturday !== null
            ? props.editableData.workday_saturday
            : false,
        sunday:
          props.editableData.workday_sunday !== null
            ? props.editableData.workday_sunday
            : false,
      };
      setDayData(tempDayData);

      let tempDetails = {
        name: props.editableData.name !== null ? props.editableData.name : "",
        auto_logout:
          props.editableData.auto_logout !== null
            ? props.editableData.auto_logout
            : false,
        daywise:
          props.editableData.daywise !== null
            ? props.editableData.daywise
            : false,
      };
      if (props.editableData["daywise"] === true) {
        initDayWiseConfiguration(tempDayData);
      } else {
        if (Object.keys(props.editableData["timings_all"]).length > 0) {
          let work_end_time =
            props.editableData["timings_all"]["end_time"] !== null
              ? getLocalTimeFromIso(
                  props.editableData["timings_all"]["end_time"]
                )
              : "";
          let work_start_time =
            props.editableData["timings_all"]["start_time"] !== null
              ? getLocalTimeFromIso(
                  props.editableData["timings_all"]["start_time"]
                )
              : "";
          let end_time_buffer =
            props.editableData["timings_all"].hasOwnProperty(
              "end_time_buffer"
            ) && props.editableData["timings_all"]["end_time_buffer"] !== null
              ? parseInt(props.editableData["timings_all"]["end_time_buffer"])
              : defaultEndTimeBuffer;
          tempDetails["work_start_time"] = work_start_time;
          tempDetails["work_end_time"] = work_end_time;
          tempDetails["end_time_buffer"] = end_time_buffer;

          let tempCommonBreaks = [];
          props.editableData["timings_all"]["breaks"].forEach((obj) => {
            let tempObj = { ...obj };
            tempObj["start_time"] = getLocalTimeFromIso(obj["start_time"]);
            tempObj["end_time"] = getLocalTimeFromIso(obj["end_time"]);
            tempObj["secure_break"] =
              obj.hasOwnProperty("secure_break") && obj["secure_break"] !== null
                ? obj["secure_break"]
                : false;
            tempCommonBreaks.push(tempObj);
          });
          setCommonBreaks(tempCommonBreaks);
        }
      }
      setDetails(tempDetails);
    }
  }, []);

  const renderBreakData = ({ data }) => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <ChipText style={{ fontSize: "12px" }}>{data["title"]}</ChipText>
        <ChipText>{`(${data["start_time"]} - ${data["end_time"]})`}</ChipText>
      </Box>
    );
  };

  const isDayConfigured = (key) => {
    if (details["daywise"] === false) {
      return true;
    }
    if (dayWiseConfiguration.hasOwnProperty(key)) {
      return Object.keys(dayWiseConfiguration[key]).length > 0;
    }
    return false;
  };

  return (
    <Box role="presentation">
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          width: 500,
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar>
          {props.isEdit === true ? (
            <ModelHeader>Edit Work Day Template</ModelHeader>
          ) : (
            <ModelHeader>Create Work Day Template</ModelHeader>
          )}
        </Toolbar>
      </AppBar>
      {!isSubmitSuccess ? (
        <ModelWrapper component={"form"} onSubmit={handleSubmit}>
          <InputContainer>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputWrapper>
                  <Label>Name*</Label>
                  <OutlinedInput
                    style={{ height: "30px" }}
                    inputProps={{
                      min: 0,
                      "aria-label": "weight",
                      style: {
                        fontSize: "12px",
                      },
                    }}
                    onChange={handleChange}
                    name="name"
                    required
                    value={details["name"]}
                    placeholder="Enter value"
                  />
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper>
                  <FormControlLabel
                    checked={details["auto_logout"]}
                    control={
                      <Checkbox
                        size="medium"
                        onChange={(event) => {
                          setDetails({
                            ...details,
                            auto_logout: event.target.checked,
                          });
                        }}
                      />
                    }
                    label={
                      <Detail style={{ fontWeight: "500" }}>
                        Auto Logout at the end of the day
                      </Detail>
                    }
                  />
                </InputWrapper>
              </Grid>

              <Grid item xs={12}>
                <Box
                  component={"fieldset"}
                  sx={{
                    border: "1px solid #cccccc",
                    padding: "20px",
                    paddingTop: "0px",
                  }}
                >
                  <legend
                    style={{
                      width: "auto",
                      marginBottom: "0px",
                      font: "normal normal 600 16px Open Sans",
                      color: "#979797",
                    }}
                  >
                    Work Days
                  </legend>
                  <Grid container spacing={1}>
                    {daysList.map((item, i) => {
                      return (
                        <Grid item xs={4}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <FormControlLabel
                              style={{ marginRight: "0px" }}
                              key={i}
                              checked={dayData[item["value"]]}
                              control={
                                <Checkbox
                                  color={"primary"}
                                  size="small"
                                  onChange={(event) => {
                                    setDayData({
                                      ...dayData,
                                      [item["value"]]: event.target.checked,
                                    });
                                    if (details["daywise"] === true) {
                                      if (event.target.checked) {
                                        setDayWiseConfiguration({
                                          ...dayWiseConfiguration,
                                          [item["value"]]: {},
                                        });
                                      } else {
                                        let tempList = {
                                          ...dayWiseConfiguration,
                                        };
                                        if (
                                          tempList.hasOwnProperty(item["value"])
                                        ) {
                                          delete tempList[item["value"]];
                                          setDayWiseConfiguration(tempList);
                                        }
                                      }
                                    }
                                  }}
                                />
                              }
                              label={
                                <Detail
                                  style={{
                                    fontSize: "12px",
                                  }}
                                >
                                  {item["name"]}
                                </Detail>
                              }
                            />
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box
                  component={"fieldset"}
                  sx={{
                    border: "1px solid #cccccc",
                    padding: "20px",
                    paddingTop: "0px",
                  }}
                >
                  <legend
                    style={{
                      width: "auto",
                      marginBottom: "0px",
                      font: "normal normal 600 16px Open Sans",
                      color: "#979797",
                    }}
                  >
                    Work Timings
                  </legend>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <RadioGroup row>
                          <FormControlLabel
                            control={
                              <Radio
                                size="small"
                                checked={!details["daywise"]}
                                onChange={() => {
                                  setDetails({
                                    ...details,
                                    daywise: false,
                                    work_end_time: "",
                                    work_start_time: "",
                                  });
                                  setDayWiseConfiguration({});
                                  setCommonBreaks([]);
                                }}
                              />
                            }
                            label={
                              <Detail style={{ fontWeight: "500" }}>
                                Common Timings
                              </Detail>
                            }
                          />
                          <FormControlLabel
                            control={
                              <Radio
                                size="small"
                                checked={details["daywise"]}
                                onChange={() => {
                                  setDetails({
                                    ...details,
                                    daywise: true,
                                    work_end_time: "",
                                    work_start_time: "",
                                  });
                                  setDayWiseConfiguration({});
                                  setCommonBreaks([]);
                                }}
                              />
                            }
                            label={
                              <Detail style={{ fontWeight: "500" }}>
                                Daywise Timings
                              </Detail>
                            }
                          />
                        </RadioGroup>
                      </InputWrapper>
                    </Grid>
                    {details["daywise"] &&
                      daysList.map((item, i) => {
                        return (
                          <Grid item xs={4}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <Detail
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                {item["name"]}
                              </Detail>
                              <IconButton
                                disabled={dayData[item["value"]] === false}
                                color={
                                  isDayConfigured(item["value"])
                                    ? "primary"
                                    : "error"
                                }
                                style={{ padding: "0px" }}
                                onClick={() => {
                                  setSelectedDay(item["value"]);

                                  setOpenConfigureWorkDay(true);
                                }}
                              >
                                <Settings
                                  style={{ height: "18px", width: "18px" }}
                                />
                              </IconButton>
                            </Box>
                          </Grid>
                        );
                      })}
                    {details["daywise"] === false && (
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <InputWrapper>
                            <Label>{getLocalizedText("start_time")}*</Label>
                            <OutlinedInput
                              style={{ height: "30px" }}
                              inputProps={{
                                max: details.work_end_time,
                                "aria-label": "weight",
                                style: {
                                  fontSize: "12px",
                                },
                              }}
                              required
                              value={details.work_start_time}
                              type="time"
                              placeholder="enter start time"
                              name="work_start_time"
                              onChange={handleChange}
                            />
                          </InputWrapper>
                        </Grid>
                        <Grid item xs={6}>
                          <InputWrapper>
                            <Label>{getLocalizedText("end_time")}*</Label>
                            <OutlinedInput
                              style={{ height: "30px" }}
                              inputProps={{
                                "aria-label": "weight",
                                style: {
                                  fontSize: "12px",
                                },
                              }}
                              required
                              value={details.work_end_time}
                              type="time"
                              placeholder="enter end_time"
                              name="work_end_time"
                              onChange={handleChange}
                            />
                          </InputWrapper>
                        </Grid>
                        <Grid item xs={12}>
                          <InputWrapper>
                            <Label>End Time Buffer (Additional Minutes)*</Label>
                            <FormControl sx={{ width: "100%" }}>
                              <Select
                                displayEmpty
                                value={details.end_time_buffer}
                                onChange={handleChange}
                                required
                                disableUnderline
                                variant="outlined"
                                name="end_time_buffer"
                                style={{
                                  width: "100%",
                                  height: "30px",
                                  fontSize: "12px",
                                  borderRadius: "4px",
                                }}
                              >
                                {endTimeBufferList.map((buffer, idx) => {
                                  return (
                                    <MenuItem
                                      value={buffer}
                                      key={idx}
                                      style={{ fontSize: "12px" }}
                                    >
                                      {buffer}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </InputWrapper>
                        </Grid>

                        {commonBreaks.length > 0 && (
                          <Grid item xs={12}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                                flexWrap: "wrap",
                                maxHeight: "calc(100vh - 675px)",
                                overflowY: "auto",
                              }}
                            >
                              {commonBreaks.map((obj, idx) => {
                                return (
                                  <Chip
                                    style={{
                                      height: "fit-content",
                                      padding: "8px",
                                    }}
                                    color={getWorkTimingBreakChipColorTheme({
                                      breakData: obj,
                                    })}
                                    label={renderBreakData({ data: obj })}
                                    key={idx}
                                    onDelete={() => {
                                      let tempList = [...commonBreaks];
                                      tempList = tempList.filter(
                                        (item) => item["title"] !== obj["title"]
                                      );
                                      setCommonBreaks(tempList);
                                    }}
                                  />
                                );
                              })}
                            </Box>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Button
                            style={{
                              textTransform: "none",
                              fontWeight: "bold",
                              width: "100%",
                            }}
                            disabled={
                              details["work_start_time"] === "" ||
                              details["work_end_time"] === ""
                            }
                            color="primary"
                            variant="text"
                            onClick={() => {
                              setOpenAddBreakDialog(true);
                            }}
                          >
                            <Add /> Add Break
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </InputContainer>

          <AppBar
            position="fixed"
            color="inherit"
            elevation={0}
            sx={{ top: "auto", bottom: 0, width: 500 }}
          >
            <Toolbar style={{ width: 500, position: "relative" }}>
              {isSubmitFail && (
                <ErrorAlert
                  onClose={(e) => {
                    e.stopPropagation();
                    setIsSubmitFail(false);
                  }}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  {errorMessage}
                </ErrorAlert>
              )}
              <ButtonWrapper>
                <Button
                  onClick={handleClose}
                  type="button"
                  variant="contained"
                  color="default"
                  style={{
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    backgroundColor: "#185DD2",
                    color: "white",
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  Submit
                </Button>
              </ButtonWrapper>
            </Toolbar>
          </AppBar>
        </ModelWrapper>
      ) : (
        <MessageWrapper>
          {props.isEdit === true ? (
            <Message>Work Day Template Updated Successfully!</Message>
          ) : (
            <Message>Work Day Template Created Successfully!</Message>
          )}
          <Button
            onClick={handleClose}
            type="button"
            variant="contained"
            color="default"
            style={{
              marginTop: "20px",
              textTransform: "none",
              fontSize: "12px",
              fontWeight: "bold",
              width: "100px",
            }}
          >
            Close
          </Button>
        </MessageWrapper>
      )}
      {openConfigureWorkDay && (
        <ConfigureWorkDay
          open={openConfigureWorkDay}
          setOpen={setOpenConfigureWorkDay}
          dayConfiguration={
            dayWiseConfiguration.hasOwnProperty(selectedDay)
              ? dayWiseConfiguration[selectedDay]
              : {}
          }
          handleSave={(data) => {
            setDayWiseConfiguration({
              ...dayWiseConfiguration,
              [selectedDay]: data,
            });
            setSelectedDay("");
            setOpenConfigureWorkDay(false);
          }}
          defaultEndTimeBuffer={defaultEndTimeBuffer}
        />
      )}
      {openAddBreakDialog && (
        <AddBreakDialog
          handleAdd={(data) => {
            let tempList = [...commonBreaks];
            let index = tempList.findIndex(
              (obj) => obj["title"] === data["title"]
            );
            if (index === -1) {
              tempList.push(data);
              setCommonBreaks(tempList);
            }
            setOpenAddBreakDialog(false);
          }}
          open={openAddBreakDialog}
          setOpen={setOpenAddBreakDialog}
          breakList={commonBreaks}
          dayStartTime={details["work_start_time"]}
          dayEndTime={details["work_end_time"]}
        />
      )}
    </Box>
  );
};

export default CreateWorkDayTemplateComponent;
