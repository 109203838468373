import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { Info } from "@mui/icons-material";
import { dataRenderer, dateTimeHandler } from "../../Functions";
import {
  getRawLeadImportLogUrlApi,
  refetchRawLeadImportRowDataApi,
} from "../../Api";
import { MoreHoriz } from "@material-ui/icons";
import { RawLeadsImportHistoryColumns } from "../../Db";
import analytics from "../../Analytics";

const ModelWrapper = styled(Box)(({ theme }) => ({
  width: "500px",
  margin: "auto",
  display: "flex",
  position: "relative",
  flexDirection: "column",
  height: "100vh",
  paddingTop: "0px",
}));
const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  padding: "30px 16px 30px 16px",
  marginBottom: "20px",
  backgroundColor: "#f9f9fc",
  justifyContent: "space-between",
  alignItems: "center",
}));

const ModelHeader = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 18px Open Sans",
  variant: "span",
  color: "#4d4e4f",
}));

const Title = styled(Typography)(({ theme }) => ({
  font: "normal normal 400 14px Open Sans",
  color: "#5A606680",
}));
const Desc = styled(Typography)(({ theme }) => ({
  font: "normal normal 500 14px Open Sans",
  color: "#5A6066",
  wordWrap: "break-word",
}));

const Column = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  overflowX: "auto",
  padding: "0px 16px 0px 16px",
}));

const IconTextWrapper = styled(Box)`
  display: flex;
  align-items: center;
`;

const RawLeadImportDetailsPanel = (props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = React.useState({});
  const getImportDetails = async () => {
    try {
      let response = await refetchRawLeadImportRowDataApi({ id: props.id });
      setData(response.data);
    } catch (error) {
      console.log(error);
      setData({});
    }
  };
  const setup = async () => {
    await getImportDetails();
    setLoading(false);
  };
  React.useEffect(() => {
    analytics.triggerEvent(
      4625000151,
      "import_details_form_load",
      "imports_tab",
      "",
      {}
    );
    setup();
  }, []);
  const handleDownloadLogFile = async (scope) => {
    let response = await getRawLeadImportLogUrlApi({
      id: props.id,
      scope: scope,
    });
    if (response.url !== "") {
      window.open(response.url, "_blank");
    }
  };

  function snakeCaseToTitleCase(snakeCaseStr) {
    if (snakeCaseStr === "import_type") {
      return "Job Type";
    } else if (snakeCaseStr === "imported_by") {
      return "Creator";
    } else {
      return snakeCaseStr
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    }
  }
  return (
    <>
      <Box role="presentation">
        {!loading ? (
          <ModelWrapper>
            <HeaderWrapper>
              <IconTextWrapper>
                <Info color="primary" style={{ marginRight: "8px" }} />
                <ModelHeader>Job Details</ModelHeader>
              </IconTextWrapper>
            </HeaderWrapper>
            <Column>
              {RawLeadsImportHistoryColumns.map((key, i) => {
                if (
                  key["field"] === "original_file" ||
                  key["field"] === "error_log" ||
                  key["field"] === "full_log"
                ) {
                  return (
                    <Box key={i}>
                      <Box
                        style={{
                          marginTop: "8px",
                          marginBottom: "8px",
                        }}
                      >
                        <Grid container alignItems="center">
                          <Grid item xs={4}>
                            <Title>{snakeCaseToTitleCase(key["field"])}</Title>
                          </Grid>
                          <Grid item xs={1}>
                            <Divider
                              orientation="vertical"
                              sx={{ height: "20px", color: "#3535354D" }}
                            />
                          </Grid>
                          <Grid item xs={7} paddingLeft={"8px"}>
                            <Desc>
                              {" "}
                              <Tooltip title="Download">
                                <MoreHoriz
                                  style={{ cursor: "pointer" }}
                                  color="primary"
                                  onClick={() => {
                                    handleDownloadLogFile(key["field"]);
                                  }}
                                />
                              </Tooltip>
                            </Desc>
                          </Grid>
                        </Grid>
                      </Box>
                      <Divider orientation="horizontal" />
                    </Box>
                  );
                } else if (
                  key["field"] === "start_time" ||
                  key["field"] === "end_time"
                ) {
                  let temp_time;
                  if (data[key["field"]] !== null) {
                    temp_time = dateTimeHandler(data[key["field"]]);
                  }
                  return (
                    <Box key={i}>
                      <Box
                        style={{
                          marginTop: "8px",
                          marginBottom: "8px",
                        }}
                      >
                        <Grid container alignItems="center">
                          <Grid item xs={4}>
                            <Title>{snakeCaseToTitleCase(key["field"])}</Title>
                          </Grid>
                          <Grid item xs={1}>
                            <Divider
                              orientation="vertical"
                              sx={{ height: "20px", color: "#3535354D" }}
                            />
                          </Grid>
                          <Grid item xs={7} paddingLeft={"8px"}>
                            <Desc>
                              {" "}
                              {data[key["field"]] !== null ? temp_time : "-"}
                            </Desc>
                          </Grid>
                        </Grid>
                      </Box>
                      <Divider orientation="horizontal" />
                    </Box>
                  );
                } else if (key["field"] === "import_type") {
                  let value = data[key["field"]];
                  return (
                    <Box key={i}>
                      <Box
                        style={{
                          marginTop: "8px",
                          marginBottom: "8px",
                        }}
                      >
                        <Grid container alignItems="center">
                          <Grid item xs={4}>
                            <Title>{snakeCaseToTitleCase(key["field"])}</Title>
                          </Grid>
                          <Grid item xs={1}>
                            <Divider
                              orientation="vertical"
                              sx={{ height: "20px", color: "#3535354D" }}
                            />
                          </Grid>
                          <Grid item xs={7} paddingLeft={"8px"}>
                            <Desc>
                              {value === "raw_leads" ? "Create" : "Update"}
                            </Desc>
                          </Grid>
                        </Grid>
                      </Box>
                      <Divider orientation="horizontal" />
                    </Box>
                  );
                } else {
                  return (
                    <Box key={i}>
                      <Box
                        style={{
                          marginTop: "8px",
                          marginBottom: "8px",
                        }}
                      >
                        <Grid container alignItems="center">
                          <Grid item xs={4}>
                            <Title>{snakeCaseToTitleCase(key["field"])}</Title>
                          </Grid>
                          <Grid item xs={1}>
                            <Divider
                              orientation="vertical"
                              sx={{ height: "20px", color: "#3535354D" }}
                            />
                          </Grid>
                          <Grid item xs={7} paddingLeft={"8px"}>
                            <Desc>{data[key["field"]]}</Desc>
                          </Grid>
                        </Grid>
                      </Box>
                      <Divider orientation="horizontal" />
                    </Box>
                  );
                }
              })}
            </Column>
          </ModelWrapper>
        ) : (
          <ModelWrapper
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <CircularProgress />
          </ModelWrapper>
        )}
      </Box>
    </>
  );
};

export default RawLeadImportDetailsPanel;
