import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  leadTaskTabColumnHeaders,
  taskPriorityList,
  taskStatusList,
} from "../../../Db";
import { dataRenderer, IsoToLocalTime } from "../../../Functions";
import { Box, Chip, IconButton, Tooltip } from "@mui/material";
import DropdownWidget from "./DropdownWidget";
import { Edit } from "@material-ui/icons";
import TaskIcon from "@mui/icons-material/Task";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#797876",
    backgroundColor: theme.palette.common.white,
    fontSize: 12,
    fontWeight: 700,
    textTransform: "uppercase",
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#797876",
    fontSize: 12,
    fontWeight: 600,
  },
}));

const TableComponent = ({
  tableData,
  handleEditButton,
  canEditLead,
  handleStatusUpdate,
  handlePriorityUpdate,
  handleCloseTask,
}) => {
  const [statusHoveredRowID, setStatusHoveredRowID] = React.useState("");
  const [priorityHoveredRowID, setPriorityHoveredRowID] = React.useState("");

  const renderChipData = (chipValue) => {
    if (chipValue !== null && chipValue !== "") {
      if (chipValue === "Low") {
        return (
          <Chip
            size="small"
            style={{ fontWeight: "bold", width: "100px", fontSize: "12px" }}
            color="default"
            label={chipValue}
          />
        );
      } else if (chipValue === "Medium") {
        return (
          <Chip
            size="small"
            style={{ fontWeight: "bold", width: "100px", fontSize: "12px" }}
            color="warning"
            label={chipValue}
          />
        );
      } else if (chipValue === "High") {
        return (
          <Chip
            size="small"
            style={{ fontWeight: "bold", width: "100px", fontSize: "12px" }}
            color="error"
            label={chipValue}
          />
        );
      }
    } else {
      return "-";
    }
  };

  const taskStatusRenderer = ({ rowData }) => {
    if (canEditLead() && rowData["task_status"] === "Open") {
      if (statusHoveredRowID === rowData.id) {
        return (
          <DropdownWidget
            onChange={(value) => {
              handleStatusUpdate(rowData.id, value);
            }}
            options={taskStatusList}
            value={rowData["task_status"]}
          />
        );
      }
    }

    return dataRenderer(rowData["task_status"]);
  };

  const taskPriorityRenderer = ({ rowData }) => {
    if (canEditLead() && rowData["task_status"] === "Open") {
      if (priorityHoveredRowID === rowData.id) {
        return (
          <DropdownWidget
            onChange={(value) => {
              handlePriorityUpdate(rowData.id, value);
            }}
            options={taskPriorityList}
            value={rowData["priority"]}
          />
        );
      }
    }
    return renderChipData(rowData["priority"]);
  };

  return (
    <TableContainer component={Paper} sx={{ borderRadius: "16px" }}>
      <Table sx={{ width: "100%" }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {leadTaskTabColumnHeaders.map((colData, idx) => {
              return (
                <StyledTableCell key={idx} align="left">
                  {colData["headerName"]}
                </StyledTableCell>
              );
            })}
            <StyledTableCell align="left">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.length > 0 ? (
            tableData.map((data, idx) => (
              <TableRow
                key={idx}
                sx={{
                  opacity: data["task_status"] !== "Open" ? "0.4" : "1",
                }}
              >
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{
                    textDecoration:
                      data["task_status"] !== "Open" ? "line-through" : "none",
                  }}
                >
                  {dataRenderer(data["title"])}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {data["task_type"] !== null
                    ? dataRenderer(data["task_type"]["task_name"])
                    : "-"}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {data["start_time"] !== null
                    ? IsoToLocalTime(data["start_time"])
                    : "-"}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {data["end_time"] !== null
                    ? IsoToLocalTime(data["end_time"])
                    : "-"}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    width: "200px",
                  }}
                  align="left"
                  onMouseEnter={() => {
                    setStatusHoveredRowID(data["id"]);
                  }}
                  onMouseLeave={() => {
                    setStatusHoveredRowID("");
                  }}
                >
                  {taskStatusRenderer({ rowData: data })}
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  onMouseEnter={() => {
                    setPriorityHoveredRowID(data["id"]);
                  }}
                  onMouseLeave={() => {
                    setPriorityHoveredRowID("");
                  }}
                >
                  {taskPriorityRenderer({ rowData: data })}
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Tooltip title={"Edit Task"}>
                      <IconButton
                        disabled={
                          !canEditLead() || data["task_status"] !== "Open"
                        }
                        color="primary"
                        size="small"
                        onClick={() => {
                          handleEditButton(data);
                        }}
                      >
                        <Edit style={{ width: "20px", height: "20px" }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"Close Task"}>
                      <IconButton
                        disabled={
                          !canEditLead() || data["task_status"] !== "Open"
                        }
                        color="primary"
                        onClick={() => {
                          handleCloseTask(data);
                        }}
                      >
                        <TaskIcon
                          style={{
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </StyledTableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <StyledTableCell
                style={{ textAlign: "center", height: "100px" }}
                colSpan={7}
              >
                no tasks.
              </StyledTableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableComponent;
