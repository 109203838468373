import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Checkbox, Divider, FormControlLabel } from "@material-ui/core";
import moment from "moment";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { getLocalizedText } from "../../Functions";
import { ArrowForward, FiberManualRecord } from "@material-ui/icons";
const Header = styled.span`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
  margin-bottom: 20px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  overflow-y: auto;
  width: 400px;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const SubHeader = styled.span`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;

const Label = styled.span`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Info = styled.span`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Detail = styled.span`
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const IconTextWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
  margin-bottom: 4px;
`;

const DeleteJobForm = (props) => {
  const { userStore } = rootStore;
  const [jobType, setJobType] = useState(null);
  const [canShowSecondContion, setCanShowSecondCondition] = useState(false);
  const [markedImportantStausList, setMarkedImportantStatusList] = useState([]);

  const getJobType = () => {
    props.typeList.map((item) => {
      if (item.value === props.item.type) {
        setJobType(item["label"]);
      }
    });
  };
  useEffect(() => {
    let temp_list = generateStatusListMarkedImportant();
    setMarkedImportantStatusList(temp_list);
    if (temp_list.length > 0) {
      setCanShowSecondCondition(true);
      props.setIsBothConditionCheckRequired(true);
    }
    getJobType();
  }, []);

  const generateStatusListMarkedImportant = () => {
    let temp_list = [];
    if (
      props.selectedVal.hasOwnProperty("lead_status") &&
      props.selectedVal["lead_status"].length > 0
    ) {
      props.selectedVal["lead_status"].forEach((item) => {
        let index = props.statusList.findIndex(
          (status_obj) => status_obj["status"] === item
        );
        if (index !== -1) {
          if (props.statusList[index]["mark_as_important"] === true) {
            temp_list.push(props.statusList[index]["status"]);
          }
        }
      });
    } else {
      props.statusList.forEach((status_obj) => {
        if (status_obj["mark_as_important"] === true) {
          temp_list.push(status_obj["status"]);
        }
      });
    }
    return temp_list;
  };

  return (
    <>
      <SubHeader>{getLocalizedText("job_summary")}</SubHeader>
      <Divider style={{ margin: "10px 0px" }} />
      <InputContainer>
        <InputWrapper style={{ marginBottom: "20px" }}>
          <Info>{getLocalizedText("job_type")} </Info>
          <Detail>{jobType !== null ? jobType : "-"}</Detail>
        </InputWrapper>
        <InputWrapper style={{ marginBottom: "20px" }}>
          <Info>{getLocalizedText("job_title")} </Info>
          <Detail>{props.item.title ? props.item.title : "-"}</Detail>
        </InputWrapper>
        <InputWrapper style={{ marginBottom: "20px" }}>
          <Info>{getLocalizedText("date_range")} </Info>
          {props.dateRange !== null ? (
            <Row>
              <Detail>{props.dateRange["start_date"]}</Detail>
              <ArrowForward style={{ width: "18px", height: "18px" }} />
              <Detail>{props.dateRange["end_date"]}</Detail>
            </Row>
          ) : (
            <Detail>-</Detail>
          )}
        </InputWrapper>

        <FormControlLabel
          style={{ alignItems: "flex-start" }}
          control={
            <Checkbox
              checked={props.firstConditionChecked}
              onChange={(e) => {
                props.setFirstConditionChecked(e.target.checked);
              }}
            />
          }
          label={
            <Label>
              {getLocalizedText(
                "i_acknowledge_that_this_action_is_irrecoverable_and_confirm_that_i_want_to_proceed_with_the_action"
              )}
            </Label>
          }
        />

        {canShowSecondContion && (
          <>
            <FormControlLabel
              style={{ alignItems: "flex-start", marginBottom: "20px" }}
              control={
                <Checkbox
                  checked={props.secondConditionChecked}
                  onChange={(e) => {
                    props.setSecondConditionChecked(e.target.checked);
                  }}
                />
              }
              label={
                <>
                  <Label>
                    {getLocalizedText(
                      "by_checking_this_box_and_running_this_job_you_acknowledge_that_the_selected_records_with_the_following_statuses_will_be_permanently_deleted"
                    )}
                  </Label>
                  <InputWrapper style={{ marginTop: "20px" }}>
                    {/* <Label>Lead Status</Label> */}
                    {markedImportantStausList.map((item) => {
                      return (
                        <IconTextWrapper>
                          <FiberManualRecord
                            style={{
                              width: "12px",
                              height: "12px",
                              marginTop: "3px",
                              color: "gray",
                            }}
                          />
                          <Detail>{item}</Detail>
                        </IconTextWrapper>
                      );
                    })}
                  </InputWrapper>
                </>
              }
            />
          </>
        )}
      </InputContainer>
    </>
  );
};

export default observer(DeleteJobForm);
