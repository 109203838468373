import React, { useState } from "react";
import { styled } from "@mui/material/styles";

import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Modal,
  Select as MuiSelect,
} from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import {
  addAdminMemberApi,
  getAdminMembersApi,
  getAdminUsersApi,
  getProjectAdminWorkTimingTemplateListApi,
  getTeamApi,
} from "../../Api";
import { useEffect } from "react";
import { myTheme } from "../../themeUtils";
import { getLocalizedText } from "../../Functions";
import analytics from "../../Analytics";
import { Typography } from "@mui/material";
import Select from "react-select";
import MuiAlert from "@mui/material/Alert";
import CustomFieldHeader from "../custom_tooltip_headers/CustomFieldHeader";
import CustomCheckboxHeader from "../custom_tooltip_headers/CustomCheckboxHeader";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "10px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 320px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const Form = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;

const ButtonWrapper = styled(Box)`
  /* margin-top: 30px; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for options
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for the entire control container, including the search input
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "no options" message
  }),
  loadingMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "loading" message
  }),
};
const AddMemberDirectly = (props) => {
  const classes = useStyles();
  const [teamList, setTeamList] = useState([]);
  const [user, setUser] = useState({
    name: "",
    role: "member",
    team: "",
  });
  const [freshMemberList, setFreshMemberList] = useState([]);
  const [existingMemberList, setExistingMemberList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isExclude, setIsExclude] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const roleList = ["manager", "member", "lead"];
  const projectList = [...rootStore.authStore.projectList];
  const [workTimingTemplates, setWorkTimingTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const handleClick = () => {
    props.setOpen(false);
  };
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const addMember = async () => {
      try {
        let data = {
          user_id: user.name,
          role: user.role,
          team_id: user.team,
          work_timings_template_id:
            selectedTemplate !== null ? selectedTemplate["value"] : null,
        };
        let response = await addAdminMemberApi({ id: props.id, data: data });
        props.setRefresh(!props.refresh);
        props.setOpen(false);
      } catch (error) {
        setErrorMessage(getLocalizedText("some_error_occurred"));
        setIsError(true);
        console.log(error);
      }
    };
    addMember();
  };
  const handleClose = () => {
    props.setOpen(false);
  };
  useEffect(() => {
    analytics.triggerEvent(
      4625000178,
      "add_member_form_load",
      "project_members_page",
      "",
      {}
    );
    init();
  }, []);
  const getTeams = async () => {
    try {
      let response = await getTeamApi(props.id);
      setTeamList(response);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllUsers = async () => {
    try {
      let response = await getAdminUsersApi();
      let activeUsers = response.data.filter((user) => user.active);
      if (activeUsers.length > 0) {
        if (props.existUsers.length > 0) {
          activeUsers = activeUsers.filter(
            (e1) => !props.existUsers.some((e2) => e2.user_id === e1.id)
          );
        }
      }
      return activeUsers;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const getAdminMembersList = async (projectID) => {
    try {
      let response = await getAdminMembersApi(projectID);
      return response.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const getWorkTimingTemplateList = async () => {
    let response = await getProjectAdminWorkTimingTemplateListApi({
      projectID: rootStore.authStore.projectId,
    });
    setWorkTimingTemplates(response);
  };

  const fetchAdminProjectMembersAndUpdateStore = async () => {
    let tempList = {};
    for (let i = 0; i < projectList.length; i++) {
      if (projectList[i]["id"] === props.id) {
        tempList[projectList[i]["id"]] = props.existUsers;
      } else {
        let member_list = await getAdminMembersList(projectList[i]["id"]);
        tempList[projectList[i]["id"]] = member_list;
      }
    }
    rootStore.userStore.initProjectMemberMapping(tempList);
  };
  const init = async () => {
    if (rootStore.userStore.projectMemberMapping === null) {
      await fetchAdminProjectMembersAndUpdateStore();
    }
    let all_project_members_idlist = [];
    Object.keys(rootStore.userStore.projectMemberMapping).forEach((key) => {
      let temp_member_list = rootStore.userStore.projectMemberMapping[key];
      temp_member_list.forEach((member) => {
        if (!all_project_members_idlist.includes(member["user_id"])) {
          all_project_members_idlist.push(member["user_id"]);
        }
      });
    });
    let all_users_list = await getAllUsers();
    if (all_users_list.length > 0) {
      await getTeams();
      let fresh_member_list = [];
      let existing_member_list = [];
      all_users_list.forEach((user) => {
        if (!all_project_members_idlist.includes(user["id"])) {
          let tempObj = { ...user, hasProject: false };
          fresh_member_list.push(tempObj);
        } else {
          let tempObj = { ...user, hasProject: true };
          existing_member_list.push(tempObj);
        }
      });
      setFreshMemberList(fresh_member_list);
      setExistingMemberList(existing_member_list);
    }
    await getWorkTimingTemplateList();
    setLoading(false);
  };

  const getMemberListToRender = () => {
    if (isExclude) {
      return freshMemberList;
    } else {
      return freshMemberList.concat(existingMemberList);
    }
  };

  const getTemplateListOptions = () => {
    let tempList = [...workTimingTemplates];
    return tempList.map((obj) => ({
      label: obj["name"],
      value: obj["id"],
    }));
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          {!loading ? (
            <Box>
              <Container>
                <Header>{getLocalizedText("add_a_new_member")}</Header>
                <SubHeader>
                  {getLocalizedText("fill_in_the_below_details")}
                </SubHeader>
                <Divider style={{ margin: "16px 0px" }} />
                <Form component={"form"} onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box
                        component={"fieldset"}
                        sx={{
                          border: "1px solid #cccccc",
                          padding: "12px",
                        }}
                      >
                        <legend
                          style={{
                            width: "auto",
                            marginBottom: "0px",
                            fontSize: "16px",
                          }}
                        >
                          Group
                        </legend>

                        <InputWrapper>
                          <CustomFieldHeader
                            header={getLocalizedText("select_member")}
                            tooltip={"Choose a user to add to this project."}
                            required={true}
                          />
                          <FormControl
                            sx={{ width: "100%" }}
                            classes={{
                              root: classes.quantityRoot,
                            }}
                          >
                            <MuiSelect
                              classes={{
                                icon: classes.icon,
                              }}
                              name="name"
                              displayEmpty
                              disableUnderline
                              variant="outlined"
                              required
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              style={{
                                width: "100%",
                                height: "30px",
                                fontSize: "12px",
                                fontWeight: "bold",
                                borderRadius: "0px",
                              }}
                            >
                              {getMemberListToRender().map((e) => {
                                if (
                                  e.hasProject === false &&
                                  isExclude === false
                                ) {
                                  return (
                                    <MenuItem id={e.id} value={e.id}>
                                      <span style={{ color: "red" }}>*</span>
                                      {e.name}
                                    </MenuItem>
                                  );
                                } else {
                                  return (
                                    <MenuItem id={e.id} value={e.id}>
                                      {e.name}
                                    </MenuItem>
                                  );
                                }
                              })}
                            </MuiSelect>
                          </FormControl>
                        </InputWrapper>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isExclude}
                              onChange={(e) => {
                                setIsExclude(e.target.checked);
                              }}
                            />
                          }
                          label={
                            <CustomCheckboxHeader
                              header={"Skip listing existing members"}
                              tooltip={
                                "Check to exclude existing members from the project."
                              }
                            />
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <CustomFieldHeader
                          header={getLocalizedText("select_role")}
                          tooltip={"Choose the role assigned to the member."}
                          required={true}
                        />
                        <FormControl
                          sx={{ width: "100%" }}
                          classes={{
                            root: classes.quantityRoot,
                          }}
                        >
                          <MuiSelect
                            classes={{
                              icon: classes.icon,
                            }}
                            name="role"
                            value={user.role}
                            displayEmpty
                            disableUnderline
                            variant="outlined"
                            required
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            style={{
                              width: "100%",
                              height: "30px",
                              fontSize: "12px",
                              fontWeight: "bold",
                              borderRadius: "0px",
                            }}
                          >
                            {roleList.map((e) => {
                              return (
                                <MenuItem id={e} value={e}>
                                  {e}
                                </MenuItem>
                              );
                            })}
                          </MuiSelect>
                        </FormControl>
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <CustomFieldHeader
                          header={getLocalizedText("select_team")}
                          tooltip={
                            "Choose the team to which the member belongs."
                          }
                          required={true}
                        />
                        <FormControl
                          sx={{ width: "100%" }}
                          classes={{
                            root: classes.quantityRoot,
                          }}
                        >
                          <MuiSelect
                            classes={{
                              icon: classes.icon,
                            }}
                            name="team"
                            displayEmpty
                            disableUnderline
                            variant="outlined"
                            required
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            style={{
                              width: "100%",
                              height: "30px",
                              fontSize: "12px",
                              fontWeight: "bold",
                              borderRadius: "0px",
                            }}
                          >
                            {teamList.map((e) => {
                              return (
                                <MenuItem id={e.id} value={e.id}>
                                  {e.name}
                                </MenuItem>
                              );
                            })}
                          </MuiSelect>
                        </FormControl>
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <InputWrapper>
                        <CustomFieldHeader
                          header={"Select Work Timing Template"}
                          tooltip={
                            "Choose the work timing assigned to the member."
                          }
                          required={false}
                        />

                        <Select
                          menuPosition="fixed"
                          options={getTemplateListOptions()}
                          value={selectedTemplate}
                          onChange={(selectedOption) => {
                            setSelectedTemplate(selectedOption);
                          }}
                          closeMenuOnSelect={true}
                          styles={customStyles}
                          isClearable
                        />
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <ButtonWrapper>
                        {isError && (
                          <ErrorAlert
                            onClose={(e) => {
                              e.stopPropagation();
                              setIsError(false);
                            }}
                            severity="error"
                            sx={{ width: "100%" }}
                          >
                            {errorMessage}
                          </ErrorAlert>
                        )}
                        <Button
                          onClick={() => {
                            analytics.triggerEvent(
                              4625000179,
                              "cancel_button_tap",
                              "add_member_form",
                              "cancel_button",
                              {}
                            );
                            handleClick();
                          }}
                          type="button"
                          variant="contained"
                          color="default"
                          style={{
                            backgroundColor: "#EFEFF4",
                            textTransform: "none",
                            marginRight: "20px",
                            fontSize: "12px",
                            fontWeight: "bold",
                          }}
                        >
                          {getLocalizedText("cancel")}
                        </Button>
                        <Button
                          onClick={() => {
                            analytics.triggerEvent(
                              4625000180,
                              "submit_button_tap",
                              "add_member_form",
                              "submit_button",
                              {}
                            );
                          }}
                          type="submit"
                          variant="contained"
                          id="call-log-btn"
                          style={{
                            textTransform: "none",
                            fontSize: "12px",
                            fontWeight: "bold",
                            ...myTheme.Button.btnBlue,
                          }}
                        >
                          {getLocalizedText("submit_details")}
                        </Button>
                      </ButtonWrapper>
                    </Grid>
                  </Grid>
                </Form>
              </Container>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "320px",
                height: "380px",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </ModelContainer>
      </Modal>
    </>
  );
};

export default observer(AddMemberDirectly);
