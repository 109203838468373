import { Box, Card, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { dataRenderer } from "../../../../../Functions";
import rootStore from "../../../../../stores/RootStore";
import { Edit } from "@material-ui/icons";
const Title = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  color: #ffb234;
`;
const Description = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  text-align: left;
  word-wrap: break-word;
`;
const Text = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  color: #979797;
  text-align: left;
`;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  height: 100%;
  gap: 8px;
`;

const PurchaseCategoryCard = ({
  details,
  setOpenUpdatePurchaseCategoryDialog,
}) => {
  const categoryList = [...rootStore.customerStore.purchaseCategoryList];
  const getDescription = () => {
    if (details["purchase_category"] !== null) {
      let index = categoryList.findIndex(
        (category) => category["id"] === details["purchase_category"]["id"]
      );
      if (index !== -1) {
        return categoryList[index]["desc"];
      }
    }
    return "Purchase categories classify customers based on the type of purchase they make, from physical products to various service offerings.";
  };
  return (
    <Card
      sx={{
        bgcolor: "white",
        width: "100%",
        height: "100%",
      }}
    >
      <Wrapper>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Title>Purchase Category</Title>
          <IconButton
            style={{ padding: "0px" }}
            onClick={() => {
              setOpenUpdatePurchaseCategoryDialog(true);
            }}
            color="primary"
          >
            <Edit style={{ width: "18px", height: "18px" }} />
          </IconButton>
        </Box>

        <Text>
          {details["purchase_category"] !== null
            ? dataRenderer(details["purchase_category"]["category"])
            : "-"}
        </Text>

        <Description>{getDescription()}</Description>
      </Wrapper>
    </Card>
  );
};

export default PurchaseCategoryCard;
