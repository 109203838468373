import React from "react";
import TeckInfoCallDialog from "./TeckInfoCallDialog";
import { Call } from "@material-ui/icons";
import { Box, Fab, Typography } from "@mui/material";
import { useState } from "react";
import { styled } from "@mui/material/styles";

const Text = styled(Typography)`
  font: normal normal 600 16px Open Sans;
  text-transform: none;
`;

const IconTextWrapper = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 6px;
`;

const TeckInfoCallActionButton = () => {
  const [open, setOpen] = useState(true);
  return (
    <>
      <Fab
        onClick={() => {
          setOpen(true);
        }}
        variant="extended"
        size="medium"
        color="primary"
        aria-label="add"
        style={{
          position: "fixed",
          bottom: "30px",
          left: "80px",
          zIndex: "99990",
        }}
      >
        <IconTextWrapper>
          <Call style={{ width: "20px", height: "20px" }} />
          <Text>Phone</Text>
        </IconTextWrapper>
      </Fab>
      <TeckInfoCallDialog open={open} setOpen={setOpen} />
    </>
  );
};

export default TeckInfoCallActionButton;
