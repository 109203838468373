import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  makeStyles,
  Modal,
  Select,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import rootStore from "../../stores/RootStore";
import { getLocalizedText } from "../../Functions";
import {
  Alert,
  Checkbox,
  FormControlLabel,
  MenuItem,
  OutlinedInput,
  styled,
  Typography,
} from "@mui/material";
import { myTheme } from "../../themeUtils";
import MuiAlert from "@mui/material/Alert";
import { updateMultipleLeadStatusApi } from "../../Api";
import analytics from "../../Analytics";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  width: 420px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const Form = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 300px;
  justify-content: space-between;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;

const BottomWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;
const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 16px;
  flex-direction: column;
  row-gap: 20px;
`;
const Message = styled(Typography)`
  font: normal normal normal 16px Open Sans;
  color: green;
  margin: 0px;
  text-align: center;
`;
const CheckboxLabel = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const MultiLeadUpdateStatusModal = (props) => {
  const [subStatusList, setSubStatusList] = useState([]);
  const classes = useStyles();
  const [user, setUser] = useState({
    status: "",
    sub_status: "",
    comments: "",
  });

  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const statusList = [...rootStore.authStore.statusList];
  const [conditionChecked, setConditionChecked] = useState(false);

  const updateLeadStatus = async () => {
    let payload = {
      ...user,
      lead_ids: props.selectedLeadIdsData,
    };

    let response = await updateMultipleLeadStatusApi({
      payload: payload,
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      props.setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    await updateLeadStatus();
  };
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const handleClose = () => {
    props.setOpen(false);
  };

  useEffect(() => {
    analytics.triggerEvent(
      4625000279,
      "update_status_form_load",
      "leads_page",
      "",
      { order: props.scope }
    );
  }, []);

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!isSubmitSuccess ? (
          <ModelContainer>
            <Box>
              <Container>
                <Header>
                  {getLocalizedText("update_lead_status", "project")}
                </Header>
                <SubHeader>
                  {getLocalizedText("select_from_below_dropdown")}
                </SubHeader>
                <Divider style={{ margin: "16px 0px" }} />
                <Form onSubmit={handleUpdate} component={"form"}>
                  <Box
                    style={{
                      height: "100%",
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <InputWrapper>
                          <Label>{getLocalizedText("status")}*</Label>
                          <FormControl
                            sx={{ width: "100%" }}
                            classes={{
                              root: classes.quantityRoot,
                            }}
                          >
                            <Select
                              classes={{
                                icon: classes.icon,
                              }}
                              name="status"
                              value={user.status}
                              displayEmpty
                              disableUnderline
                              variant="outlined"
                              required
                              onChange={(e) => {
                                setIsSubmitFail(false);
                                statusList.forEach((item) => {
                                  if (e.target.value === item.status) {
                                    let newList = item.sub_status;
                                    setSubStatusList(newList);

                                    if (newList.length > 0) {
                                      if (newList.includes("None")) {
                                        setUser({
                                          ...user,
                                          status: e.target.value,
                                          sub_status: "None",
                                        });
                                      } else {
                                        setUser({
                                          ...user,
                                          status: e.target.value,
                                          sub_status: newList[0],
                                        });
                                      }
                                    } else {
                                      setUser({
                                        ...user,
                                        status: e.target.value,
                                        sub_status: "",
                                      });
                                    }
                                  }
                                });
                              }}
                              style={{
                                width: "100%",
                                height: "30px",
                                fontSize: "12px",
                                fontWeight: "bold",
                                borderRadius: "0px",
                              }}
                            >
                              {statusList.map((e) => {
                                if (e["is_not_set"] === false) {
                                  return (
                                    <MenuItem id={e.id} value={e.status}>
                                      {e.status}
                                    </MenuItem>
                                  );
                                }
                              })}
                            </Select>
                          </FormControl>
                        </InputWrapper>
                      </Grid>

                      <Grid item xs={6}>
                        <InputWrapper>
                          <Label>{getLocalizedText("sub_status")}*</Label>
                          <FormControl
                            sx={{ width: "100%" }}
                            classes={{
                              root: classes.quantityRoot,
                            }}
                          >
                            <Select
                              classes={{
                                icon: classes.icon,
                              }}
                              name="sub_status"
                              displayEmpty
                              variant="outlined"
                              required
                              value={user.sub_status}
                              onChange={handleChange}
                              style={{
                                width: "100%",
                                height: "30px",
                                fontSize: "12px",
                                fontWeight: "bold",
                                borderRadius: "0px",
                              }}
                            >
                              {subStatusList.length > 0 &&
                                subStatusList.map((e) => {
                                  return (
                                    <MenuItem id={e} value={e}>
                                      {e}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </InputWrapper>
                      </Grid>

                      <Grid item xs={12}>
                        <InputWrapper>
                          <Label>Comments*</Label>
                          <OutlinedInput
                            multiline={true}
                            rows={3}
                            inputProps={{
                              min: 0,
                              "aria-label": "weight",
                              style: {
                                fontSize: "12px",
                              },
                            }}
                            required
                            value={user.comments}
                            name={"comments"}
                            type={"text"}
                            placeholder={"Enter Value"}
                            onChange={handleChange}
                          />
                        </InputWrapper>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          style={{ alignItems: "flex-start" }}
                          control={
                            <Checkbox
                              size="small"
                              style={{ paddingTop: "0px" }}
                              checked={conditionChecked}
                              onChange={(e) => {
                                setConditionChecked(e.target.checked);
                              }}
                            />
                          }
                          label={
                            <CheckboxLabel>
                              I acknowledge that required fields per Stage
                              Directives will not be updated during bulk lead
                              status changes.
                            </CheckboxLabel>
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <BottomWrapper>
                        {isSubmitFail && (
                          <ErrorAlert
                            onClose={(e) => {
                              e.stopPropagation();
                              setIsSubmitFail(false);
                            }}
                            severity="error"
                            sx={{ width: "100%" }}
                          >
                            {errorMessage}
                          </ErrorAlert>
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "flex-end",
                            gap: "16px",
                          }}
                        >
                          <Button
                            onClick={() => {
                              analytics.triggerEvent(
                                4625000280,
                                "cancel_button_tap",
                                "update_status_form",
                                "cancel_button",
                                { order: props.scope }
                              );
                              props.setOpen(false);
                            }}
                            variant="contained"
                            color="default"
                            style={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              textTransform: "none",
                            }}
                          >
                            {getLocalizedText("cancel")}
                          </Button>
                          <Button
                            disabled={!conditionChecked}
                            onClick={() => {
                              analytics.triggerEvent(
                                4625000281,
                                "update_button_tap",
                                "update_status_form",
                                "update_button",
                                { order: props.scope }
                              );
                            }}
                            type="submit"
                            variant="contained"
                            color="primary"
                            style={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              width: "fit-content",
                              textTransform: "none",
                            }}
                          >
                            Update
                          </Button>
                        </Box>
                      </BottomWrapper>
                    </Grid>
                  </Grid>
                </Form>
              </Container>
            </Box>
          </ModelContainer>
        ) : (
          <ModelContainer
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MessageWrapper style={{ marginBottom: "0px" }}>
              <Message>Status updated successfully!</Message>

              <Button
                onClick={handleClose}
                type="button"
                variant="contained"
                color="default"
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "100px",
                }}
              >
                {getLocalizedText("close")}
              </Button>
            </MessageWrapper>
          </ModelContainer>
        )}
      </Modal>
    </>
  );
};

export default MultiLeadUpdateStatusModal;
