import { styled } from "@mui/material/styles";
import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  CircularProgress,
  Tooltip,
  FormControl,
  MenuItem,
  Select,
  IconButton,
  Typography,
  Avatar,
  Snackbar,
} from "@mui/material";
import { Grid } from "@material-ui/core";
import SectionCard from "./components/SectionCard";
import { myTheme } from "../../themeUtils";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import {
  editContactApi,
  editLeadApi,
  getListApi,
  getPhoneApi,
} from "../../Api";
import { getLocalizedText, getSlug } from "../../Functions";
import { titleCase } from "title-case";
import MessageWithLoadingStateCustomDialog from "../alert_dialogue/MessageWithLoadingStateCustomDialog";
import MuiAlert from "@mui/material/Alert";
import { optionFieldsList } from "../../Db";
import moment from "moment";
import { CheckCircle, Search } from "@material-ui/icons";
import { NearbyError, SdCardAlert } from "@mui/icons-material";
import CustomLoadingDialog from "../alert_dialogue/CustomLoadingDialog";
const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: space-between;
  row-gap: 20px;
  margin: auto;
  ${`min-height: calc(100vh - 334px);`};
`;
const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  row-gap: 20px;
`;

const TopWrapper = styled(Box)`
  width: "100%";
`;

const LeadFormComponent = ({
  formMeta,
  leadID,
  leadData,
  setRefreshLeadDetails,
  isLeadFormDisabled,
}) => {
  const columnsList = [...rootStore.userStore.AllColumnsList];
  const [inputData, setInputData] = useState({});
  const [prefilledData, setPrefilledData] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSubmitResponseDialog, setOpenSubmitResponseDialog] =
    useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [listFieldInputMeta, setListFieldInputMeta] = useState({});
  const [loading, setLoading] = useState(true);
  const [allQuestionList, setAllQuestionList] = useState([]);
  const [selectedQuestionID, setSelectedQuestionID] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const inRange = (num, min, max) => num >= min && num <= max;
  const isValidIntegerFieldValues = ({ questionList }) => {
    for (let i = 0; i < questionList.length; i++) {
      let index = columnsList.findIndex(
        (column) => column["id"] === questionList[i]["column_id"]
      );
      if (index !== -1) {
        let columnMeta = columnsList[index];
        if (prefilledData.hasOwnProperty(columnMeta["field"])) {
          if (columnMeta["data_type"] === "integer") {
            if (
              prefilledData[columnMeta["field"]]["value"] !== "" &&
              prefilledData[columnMeta["field"]]["value"] !== null &&
              columnMeta["min"] !== null &&
              columnMeta["max"] !== null &&
              columnMeta["min"] !== "" &&
              columnMeta["max"] !== ""
            ) {
              if (
                !inRange(
                  parseFloat(prefilledData[columnMeta["field"]]["value"]),
                  parseFloat(columnMeta["min"]),
                  parseFloat(columnMeta["max"])
                )
              ) {
                setErrorMessage(
                  `Please ensure the '${questionList[i]["question"]}' value is between ${columnMeta["min"]} and ${columnMeta["max"]}.`
                );
                return false;
              }
            }
          }
        }
      }
    }

    return true;
  };

  const isAllRequiredQuestionsInSectionFilled = ({ questionList }) => {
    for (let i = 0; i < questionList.length; i++) {
      if (questionList[i]["required"] == true) {
        let index = columnsList.findIndex(
          (column) => column["id"] === questionList[i]["column_id"]
        );
        if (index !== -1) {
          let columnMeta = columnsList[index];
          if (prefilledData.hasOwnProperty(columnMeta["field"])) {
            if (
              prefilledData[columnMeta["field"]]["value"] === "" ||
              prefilledData[columnMeta["field"]]["value"] === null ||
              (Array.isArray(prefilledData[columnMeta["field"]]["value"]) &&
                prefilledData[columnMeta["field"]]["value"].length === 0)
            ) {
              setErrorMessage(
                `Kindly fill in the required field '${questionList[i]["question"]}'.`
              );
              return false;
            }
          } else {
            setErrorMessage(
              `Kindly fill in the required field '${questionList[i]["question"]}'.`
            );
            return false;
          }
        }
      }
    }
    return true;
  };

  const isAllRequiredColumnsFilled = () => {
    for (let i = 0; i < allQuestionList.length; i++) {
      let index = columnsList.findIndex(
        (column) => column["id"] === allQuestionList[i]["column_id"]
      );
      if (index !== -1) {
        let columnMeta = columnsList[index];

        if (columnMeta["required"] == true) {
          if (prefilledData.hasOwnProperty(columnMeta["field"])) {
            if (
              prefilledData[columnMeta["field"]]["value"] === "" ||
              prefilledData[columnMeta["field"]]["value"] === null ||
              (Array.isArray(prefilledData[columnMeta["field"]]["value"]) &&
                prefilledData[columnMeta["field"]]["value"].length === 0)
            ) {
              setErrorMessage(
                `Kindly fill in the required field '${allQuestionList[i]["question"]}'.`
              );
              return false;
            }
          } else {
            setErrorMessage(
              `Kindly fill in the required field '${allQuestionList[i]["question"]}'.`
            );
            return false;
          }
        }
      }
    }
    return true;
  };

  const getContactData = () => {
    let tempList = {};
    for (const key in inputData) {
      let index = columnsList.findIndex((column) => column["field"] === key);
      if (index !== -1) {
        let columnData = columnsList[index];
        if (columnData["contact_column"] !== null) {
          tempList[columnData["contact_column"]] =
            inputData[columnData["field"]];
        }
      }
    }
    return tempList;
  };

  const generateLeadPayload = (data) => {
    let tempList = {};
    let customFields = {};

    for (const key in data) {
      let index = columnsList.findIndex((column) => column["field"] === key);
      if (index !== -1) {
        let columnMeta = columnsList[index];
        let value = data[key]["value"];

        if (data[key]["type"] === "radio_with_other") {
          if (data[key]["value"] === "other_value") {
            value = data[key]["other_value"];
          }
        } else if (data[key]["type"] === "checkbox") {
          value = data[key]["value"].join(",");
        } else if (data[key]["type"] === "checkbox_with_other") {
          let tempList = data[key]["value"];
          let index = tempList.findIndex((str) => str === "other_value");
          if (index !== -1) {
            tempList = data[key]["value"].filter(
              (str) => str !== "other_value"
            );
            let other_value = data[key]["other_value"];
            if (other_value !== "" && !tempList.includes(other_value)) {
              tempList.push(other_value);
            }
          }
          value = tempList.join(",");
        } else {
          if (
            columnMeta["data_type"] === "date" ||
            columnMeta["data_type"] === "datetime"
          ) {
            value = data[key]["value"].toISOString();
          } else if (columnMeta["data_type"] === "integer") {
            value = parseInt(data[key]["value"]);
          } else {
            value = data[key]["value"];
            if (columnMeta.hasOwnProperty("set_title_case")) {
              if (columnMeta["set_title_case"] === true) {
                value = titleCase(data[key]["value"]);
              }
            }
          }
        }

        if (columnMeta["is_custom_param"]) {
          let newKey = key.replace("custom_params_", "");
          customFields[newKey] = value;
        } else {
          tempList[key] = value;
        }
      }
    }

    if (Object.keys(customFields).length > 0) {
      tempList["custom_params"] = customFields;
    }
    return tempList;
  };

  const generateContactPayloadData = (data) => {
    let contactFields = {};
    for (const key in data) {
      let index = columnsList.findIndex(
        (column) => column["contact_column"] === key
      );
      if (index !== -1) {
        let columnMeta = columnsList[index];
        let value = data[key]["value"];
        if (data[key]["type"] === "radio_with_other") {
          if (data[key]["value"] === "other_value") {
            value = data[key]["other_value"];
          }
        } else if (data[key]["type"] === "checkbox") {
          value = data[key]["value"].join(",");
        } else if (data[key]["type"] === "checkbox_with_other") {
          let tempList = data[key]["value"];
          let index = tempList.findIndex((str) => str === "other_value");
          if (index !== -1) {
            tempList = data[key]["value"].filter(
              (str) => str !== "other_value"
            );
            let other_value = data[key]["other_value"];
            if (other_value !== "" && !tempList.includes(other_value)) {
              tempList.push(other_value);
            }
          }
          value = tempList.join(",");
        } else {
          if (
            columnMeta["data_type"] === "date" ||
            columnMeta["data_type"] === "datetime"
          ) {
            value = data[key]["value"].toISOString();
          } else if (columnMeta["data_type"] === "integer") {
            value = parseInt(data[key]["value"]);
          } else {
            value = data[key]["value"];

            if (columnMeta.hasOwnProperty("set_title_case")) {
              if (columnMeta["set_title_case"] === true) {
                value = titleCase(data[key]["value"]);
              }
            }
          }
        }

        contactFields[key] = value;
      }
    }
    return contactFields;
  };

  const submitEditLead = async ({ canResetStepper }) => {
    let payload = generateLeadPayload(inputData);
    payload["updated_fields"] = {
      ...payload,
    };
    try {
      let response = await editLeadApi({
        id: leadID,
        data: payload,
      });
      setSuccessMessage("Lead data saved successfully!");
      setIsSubmitFail(false);
      setRefreshLeadDetails(true);
      setSubmitting(false);
      setInputData({});
      if (canResetStepper) {
        setActiveStep(0);
      }
    } catch (error) {
      setErrorMessage(getLocalizedText("some_error_occurred"));
      setIsSubmitFail(true);
      setSubmitting(false);
    }
  };

  const handleSubmit = async () => {
    if (Object.keys(inputData).length > 0) {
      if (isAllRequiredColumnsFilled()) {
        setSubmitting(true);
        setOpenSubmitResponseDialog(true);
        let contactData = getContactData();
        if (Object.keys(contactData).length > 0) {
          if (
            leadData["contact_id"] !== null &&
            leadData["contact_id"] !== ""
          ) {
            let contactPayload = generateContactPayloadData(contactData);
            let updateContactResponse = await editContactApi({
              contactID: leadData["contact_id"],
              payload: contactPayload,
            });
            if (!updateContactResponse.hasError()) {
              await submitEditLead({ canResetStepper: true });
            } else {
              setErrorMessage(updateContactResponse.errorMessage);
              setIsSubmitFail(true);
              setSubmitting(false);
            }
          }
        } else {
          await submitEditLead({ canResetStepper: true });
        }
      } else {
        setShowAlert(true);
      }
    } else {
      setErrorMessage("Kindly make a modification before saving the form.");
      setShowAlert(true);
    }
  };

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };
  const getFormFieldColumnList = () => {
    let tempList = [];
    formMeta["sections"].forEach((section) => {
      section["questions"].forEach((question) => {
        let index = columnsList.findIndex(
          (column) => column["id"] === question["column_id"]
        );
        if (index !== -1) {
          let columnData = columnsList[index];
          columnData["question_type"] = question["question_type"];
          tempList.push(columnsList[index]);
        }
      });
    });
    return tempList;
  };

  const getLeadPhone = async () => {
    try {
      let response = await getPhoneApi(leadData["id"]);
      return response.data;
    } catch (error) {
      return "";
    }
  };

  const getFieldValue = ({ data, columnMeta, fieldName }) => {
    if (columnMeta["question_type"] === "checkbox") {
      let tempValue =
        data[fieldName] !== "" && data[fieldName] !== null
          ? String(data[fieldName]).split(",")
          : [];

      return tempValue;
    } else if (
      columnMeta["question_type"] === "date" ||
      columnMeta["question_type"] === "datetime"
    ) {
      let tempValue = data[fieldName] !== "" ? moment(data[fieldName]) : null;
      return tempValue;
    } else {
      return data[fieldName];
    }
  };

  const checkAndGetParentFieldValue = ({ parentColumnID }) => {
    let parentColumnIndex = columnsList.findIndex(
      (column) => column["id"] === parentColumnID
    );
    if (parentColumnIndex !== -1) {
      let parentColumnMeta = columnsList[parentColumnIndex];
      let tempLeadData = leadData;
      let key = parentColumnMeta["field"];
      if (parentColumnMeta["is_custom_param"]) {
        tempLeadData = leadData.hasOwnProperty("custom_params")
          ? JSON.parse(leadData["custom_params"])
          : {};
        key = parentColumnMeta["field"].replace("custom_params_", "");
      }
      if (
        tempLeadData.hasOwnProperty(key) &&
        tempLeadData[key] !== null &&
        tempLeadData[key] !== ""
      ) {
        return tempLeadData[key];
      }
    }
    return null;
  };
  const getFieldInputMeta = ({ columnMeta, inputMeta }) => {
    if (columnMeta["map_to_parent_selection"] === true) {
      let list = [];
      let parentValue = checkAndGetParentFieldValue({
        parentColumnID: columnMeta["child_of"]["id"],
      });

      if (inputMeta.hasOwnProperty("list")) {
        if (inputMeta["list"].hasOwnProperty(parentValue)) {
          list = inputMeta["list"][parentValue];
        } else {
          let tempInputList = [];
          for (let parentValue in inputMeta["list"]) {
            inputMeta["list"][parentValue].forEach((str) => {
              if (!tempInputList.includes(str)) {
                tempInputList.push(str);
              }
            });
          }
          list = tempInputList;
        }
      }
      return { type: "list", list: list };
    } else {
      return inputMeta;
    }
  };

  const getFieldWithOtherValueData = ({
    data,
    columnMeta,
    fieldName,
    listMeta,
  }) => {
    const inputMetaData = getFieldInputMeta({
      columnMeta: columnMeta,
      inputMeta: listMeta,
    });

    let value = { value: data[fieldName] };
    if (columnMeta["question_type"] === "checkbox_with_other") {
      let tempValue =
        data[fieldName] !== "" && data[fieldName] !== null
          ? String(data[fieldName]).split(",")
          : [];

      let tempList = [];

      tempValue.forEach((str) => {
        if (inputMetaData["type"] === "list") {
          if (!inputMetaData["list"].includes(str)) {
            tempList.push(str);
          }
        } else {
          let index = inputMetaData["list"].findIndex(
            (obj) => obj["value"] === str
          );
          if (index === -1) {
            tempList.push(str);
          }
        }
      });
      if (tempList.length > 0) {
        tempValue.push("other_value");
        value["value"] = tempValue;
        value["other_value"] = tempList[0];
      } else {
        value["value"] = tempValue;
      }
    } else {
      if (data[fieldName] !== null && data[fieldName] !== "") {
        if (inputMetaData["type"] === "list") {
          let index = inputMetaData["list"].findIndex(
            (str) => str === data[fieldName]
          );
          if (index === -1) {
            if (inputMetaData["list"].length > 0) {
              value["value"] = "other_value";
              value["other_value"] = data[fieldName];
            }
          }
        } else {
          let index = inputMetaData["list"].findIndex(
            (obj) => obj["value"] === data[fieldName]
          );
          if (index === -1) {
            if (inputMetaData["list"].length > 0) {
              value["value"] = "other_value";
              value["other_value"] = data[fieldName];
            }
          }
        }
      }
    }
    return value;
  };
  const initInputData = async ({ columnMetaList, listFieldInputMeta }) => {
    let customData = leadData.hasOwnProperty("custom_params")
      ? JSON.parse(leadData["custom_params"])
      : {};
    let tempList = {};
    for (let i = 0; i < columnMetaList.length; i++) {
      let columnMeta = columnMetaList[i];
      let value = { type: columnMeta["question_type"] };
      if (
        columnMeta["question_type"] === "checkbox" ||
        columnMeta["question_type"] === "checkbox_with_other"
      ) {
        value["value"] = [];
      } else if (
        columnMeta["question_type"] === "date" ||
        columnMeta["question_type"] === "datetime"
      ) {
        value["value"] = null;
      } else {
        value["value"] = "";
      }

      if (columnMeta["is_custom_param"] === true) {
        let fieldName = columnMeta["field"].replace("custom_params_", "");
        if (customData.hasOwnProperty(fieldName)) {
          if (
            columnMeta["question_type"] === "checkbox_with_other" ||
            columnMeta["question_type"] === "radio_with_other"
          ) {
            if (listFieldInputMeta.hasOwnProperty(columnMeta["field"])) {
              let fieldWithOtherData = getFieldWithOtherValueData({
                data: customData,
                columnMeta: columnMeta,
                fieldName: fieldName,
                listMeta: listFieldInputMeta[columnMeta["field"]],
              });
              value["value"] = fieldWithOtherData["value"];
              if (fieldWithOtherData.hasOwnProperty("other_value")) {
                value["other_value"] = fieldWithOtherData["other_value"];
              }
            }
          } else {
            value["value"] = getFieldValue({
              data: customData,
              columnMeta: columnMeta,
              fieldName: fieldName,
            });
          }
        }
      } else {
        if (columnMeta["field"] === "phone") {
          let phone = await getLeadPhone();
          value["value"] = phone;
        } else {
          if (leadData.hasOwnProperty(columnMeta["field"])) {
            if (
              columnMeta["question_type"] === "checkbox_with_other" ||
              columnMeta["question_type"] === "radio_with_other"
            ) {
              if (listFieldInputMeta.hasOwnProperty(columnMeta["field"])) {
                let fieldWithOtherData = getFieldWithOtherValueData({
                  data: leadData,
                  columnMeta: columnMeta,
                  fieldName: columnMeta["field"],
                  listMeta: listFieldInputMeta[columnMeta["field"]],
                });
                value["value"] = fieldWithOtherData["value"];
                if (fieldWithOtherData.hasOwnProperty("other_value")) {
                  value["other_value"] = fieldWithOtherData["other_value"];
                }
              }
            } else {
              value["value"] = getFieldValue({
                data: leadData,
                columnMeta: columnMeta,
                fieldName: columnMeta["field"],
              });
            }
          }
        }
      }
      tempList[columnMeta["field"]] = value;
    }
    setPrefilledData(tempList);
    setLoading(false);
  };
  const getListDetailsToRender = async (columnMeta) => {
    const filter_input = columnMeta["filter_input"];
    let tempType = "list";
    let tempList = [];
    if (filter_input.charAt(0) === "/" || filter_input.startsWith("ep:")) {
      let slug = getSlug(filter_input);
      let response = await getListApi({ slug: slug });
      if (response !== null) {
        if (Array.isArray(response)) {
          tempType = "list";
          tempList = response;
        } else if (typeof response === "object") {
          if (Object.keys(response).length > 0) {
            tempType = response["type"];
            tempList = response["values"];
          }
        }
      }
    } else if (
      filter_input.charAt(0) !== "/" &&
      filter_input !== "" &&
      filter_input !== null
    ) {
      try {
        let parseJSON = JSON.parse(filter_input);
        if (Array.isArray(parseJSON)) {
          tempType = "list";
          tempList = parseJSON;
        } else if (typeof parseJSON === "object") {
          if (columnMeta["map_to_parent_selection"] === true) {
            tempType = "parent_child_mapping";
            tempList = parseJSON;
          } else {
            if (Object.keys(parseJSON).length > 0) {
              tempType = parseJSON["type"];
              tempList = parseJSON["values"];
            }
          }
        }
      } catch (error) {
        if (filter_input.length > 0) {
          let list = filter_input.split(",");
          tempType = "list";
          tempList = list;
        }
      }
    }
    return { list: tempList, type: tempType };
  };

  const getListFieldInputMeta = async ({ columnMetaList }) => {
    let tempList = {};
    for (let i = 0; i < columnMetaList.length; i++) {
      let columnMeta = columnMetaList[i];
      if (optionFieldsList.includes(columnMeta["question_type"])) {
        let metaData = await getListDetailsToRender(columnMeta);
        tempList[columnMeta["field"]] = metaData;
      }
    }
    return tempList;
  };

  const getAllQuestionList = () => {
    let tempList = [];
    formMeta["sections"].forEach((section) => {
      section["questions"].forEach((question) => {
        tempList.push(question);
      });
    });
    return tempList;
  };

  const init = async () => {
    let questionList = getAllQuestionList();
    setAllQuestionList(questionList);
    let columnMetaList = getFormFieldColumnList();
    let listFieldInputMeta = await getListFieldInputMeta({
      columnMetaList: columnMetaList,
    });
    setListFieldInputMeta(listFieldInputMeta);
    initInputData({
      columnMetaList: columnMetaList,
      listFieldInputMeta: listFieldInputMeta,
    });
  };
  useEffect(() => {
    init();
  }, []);

  const moveToSectionHandler = (id) => {
    const section = document.getElementById(id);
    if (section) {
      const sectionTop = section.offsetTop;
      const scrollToPosition = sectionTop;

      window.scrollTo({
        top: scrollToPosition,
        behavior: "smooth",
      });
    }
    setIsSearch(false);
  };

  const handleSearch = async () => {
    let step = 0;
    for (let i = 0; i < formMeta["sections"].length; i++) {
      const section = formMeta["sections"][i];
      for (let j = 0; j < section.questions.length; j++) {
        const question = section.questions[j];
        if (question.id === selectedQuestionID) {
          step = i;
          break;
        }
      }
    }
    await handleNextButtonCallback({ step: step });
    setIsSearch(true);
  };
  useLayoutEffect(() => {
    if (isSearch) {
      moveToSectionHandler(selectedQuestionID);
    }
  }, [isSearch]);

  const RenderButton = () => {
    if (formMeta["sections"].length === 1) {
      return (
        <Button
          disabled={isLeadFormDisabled}
          type="submit"
          variant="contained"
          style={{
            textTransform: "none",
            width: "120px",
            fontWeight: "bold",
            backgroundColor: isLeadFormDisabled
              ? myTheme.Button.btnDisabled.backgroundColor
              : myTheme.Button.btnBlue.backgroundColor,
            color: isLeadFormDisabled
              ? myTheme.Button.btnDisabled.color
              : myTheme.Button.btnBlue.color,
          }}
        >
          Save
        </Button>
      );
    } else {
      if (activeStep === 0) {
        return (
          <Button
            type="submit"
            variant="contained"
            style={{
              textTransform: "none",
              width: "120px",
              fontWeight: "bold",
              backgroundColor: myTheme.Button.btnBlue.backgroundColor,
              color: myTheme.Button.btnBlue.color,
            }}
          >
            Next
          </Button>
        );
      } else if (activeStep < formMeta["sections"].length - 1) {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "20px",
              width: "fit-content",
            }}
          >
            <Button
              type="button"
              onClick={handlePrevious}
              variant="contained"
              style={{
                textTransform: "none",
                width: "120px",
                fontWeight: "bold",
                backgroundColor: myTheme.Button.btnLight.backgroundColor,
                color: myTheme.Button.btnLight.color,
              }}
            >
              Prev
            </Button>
            <Button
              type="submit"
              variant="contained"
              style={{
                textTransform: "none",
                width: "120px",
                fontWeight: "bold",
                backgroundColor: myTheme.Button.btnBlue.backgroundColor,
                color: myTheme.Button.btnBlue.color,
              }}
            >
              Next
            </Button>
          </Box>
        );
      } else if (activeStep === formMeta["sections"].length - 1) {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "20px",
              width: "fit-content",
            }}
          >
            <Button
              onClick={handlePrevious}
              type="button"
              variant="contained"
              style={{
                textTransform: "none",
                width: "120px",
                fontWeight: "bold",
                backgroundColor: myTheme.Button.btnLight.backgroundColor,
                color: myTheme.Button.btnLight.color,
              }}
            >
              Prev
            </Button>
            <Button
              disabled={isLeadFormDisabled}
              type="submit"
              variant="contained"
              style={{
                textTransform: "none",
                width: "120px",
                fontWeight: "bold",
                backgroundColor: isLeadFormDisabled
                  ? myTheme.Button.btnDisabled.backgroundColor
                  : myTheme.Button.btnBlue.backgroundColor,
                color: isLeadFormDisabled
                  ? myTheme.Button.btnDisabled.color
                  : myTheme.Button.btnBlue.color,
              }}
            >
              Save
            </Button>
          </Box>
        );
      }
    }
  };

  const handleNextButtonCallback = async ({ step }) => {
    if (isLeadFormDisabled === false) {
      if (Object.keys(inputData).length > 0) {
        if (isAllRequiredColumnsFilled()) {
          setIsSaving(true);
          let contactData = getContactData();
          if (Object.keys(contactData).length > 0) {
            if (
              leadData["contact_id"] !== null &&
              leadData["contact_id"] !== ""
            ) {
              let contactPayload = generateContactPayloadData(contactData);
              let updateContactResponse = await editContactApi({
                contactID: leadData["contact_id"],
                payload: contactPayload,
              });
              if (!updateContactResponse.hasError()) {
                await submitEditLead({ canResetStepper: false });
              } else {
                setErrorMessage(updateContactResponse.errorMessage);
                setShowAlert(true);
              }
            }
          } else {
            await submitEditLead({ canResetStepper: false });
          }
          setShowSnackbar(true);
          setIsSaving(false);
          setActiveStep(step);
        } else {
          setShowAlert(true);
        }
      } else {
        setActiveStep(step);
      }
    } else {
      setActiveStep(step);
    }
  };

  const handleFormSubmitCallback = () => {
    if (formMeta["allow_partial_section_completion"] === false) {
      let sectionData = formMeta["sections"][activeStep];

      if (
        isAllRequiredQuestionsInSectionFilled({
          questionList: sectionData["questions"],
        })
      ) {
        if (
          isValidIntegerFieldValues({
            questionList: sectionData["questions"],
          })
        ) {
          if (activeStep === formMeta["sections"].length - 1) {
            handleSubmit();
          } else {
            handleNextButtonCallback({ step: activeStep + 1 });
          }
        } else {
          setShowAlert(true);
        }
      } else {
        setShowAlert(true);
      }
    } else {
      if (activeStep === formMeta["sections"].length - 1) {
        handleSubmit();
      } else {
        handleNextButtonCallback({ step: activeStep + 1 });
      }
    }
  };

  const isValueFilled = (value) => {
    return (
      value !== null &&
      value !== "" &&
      (!Array.isArray(value) || (Array.isArray(value) && value.length > 0))
    );
  };

  const getStepIcon = ({ questionList, index }) => {
    let filledCount = 0;
    for (let i = 0; i < questionList.length; i++) {
      let index = columnsList.findIndex(
        (column) => column["id"] === questionList[i]["column_id"]
      );
      if (index !== -1) {
        let columnMeta = columnsList[index];
        if (prefilledData.hasOwnProperty(columnMeta["field"])) {
          if (isValueFilled(prefilledData[columnMeta["field"]]["value"])) {
            filledCount += 1;
          }
        }
      }
    }
    if (isLeadFormDisabled) {
      return (
        <Avatar
          sx={{
            width: "20px",
            height: "20px",
            backgroundColor: "#E0E0E0",
            color: "#A6A6A6",
            fontSize: "12px",
          }}
        >
          {index + 1}
        </Avatar>
      );
    } else {
      if (index <= activeStep) {
        if (filledCount === 0) {
          return (
            <Avatar
              sx={{
                width: "20px",
                height: "20px",
                backgroundColor: "#f50057",
                color: "white",
                fontSize: "12px",
              }}
            >
              {index + 1}
            </Avatar>
          );
        } else if (filledCount < questionList.length && filledCount > 0) {
          return (
            <Avatar
              sx={{
                width: "20px",
                height: "20px",
                backgroundColor: "#ff9800",
                color: "white",
                fontSize: "12px",
              }}
            >
              {index + 1}
            </Avatar>
          );
        } else if (filledCount === questionList.length) {
          return (
            <Avatar
              sx={{
                width: "20px",
                height: "20px",
                backgroundColor: "#26599e",
                color: "white",
                fontSize: "12px",
              }}
            >
              {index + 1}
            </Avatar>
          );
        }
      } else {
        return (
          <Avatar
            sx={{
              width: "20px",
              height: "20px",
              backgroundColor: "#9E9E9E",
              color: "white",
              fontSize: "12px",
            }}
          >
            {index + 1}
          </Avatar>
        );
      }
    }
  };

  return (
    <>
      {!loading ? (
        <Container
          component={"form"}
          onSubmit={(e) => {
            e.preventDefault();
            handleFormSubmitCallback();
          }}
        >
          <Wrapper>
            <TopWrapper>
              <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={6}>
                  <Stepper activeStep={activeStep}>
                    {formMeta["sections"].map((sectionData, index) => {
                      return (
                        <Step key={index}>
                          <Tooltip title={sectionData["title"]}>
                            <StepLabel
                              StepIconComponent={() =>
                                getStepIcon({
                                  questionList: sectionData["questions"],
                                  index: index,
                                })
                              }
                            />
                          </Tooltip>
                        </Step>
                      );
                    })}
                  </Stepper>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      width: "100%",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "flex-end",
                      paddingLeft: "12px",
                      border: "1px solid rgb(218, 220, 224)",
                      borderRadius: "8px",
                    }}
                  >
                    <FormControl
                      sx={{
                        width: "100%",
                        height: "30px",
                        backgroundColor: "white",
                        borderRadius: "8px",
                      }}
                    >
                      <Select
                        disabled={isLeadFormDisabled}
                        variant="standard"
                        disableUnderline={true}
                        style={{
                          width: "100%",
                          height: "30px",
                          font: "normal normal 400 12px Poppins",
                        }}
                        sx={{
                          "& .MuiSelect-select:focus": {
                            backgroundColor: "white",
                          },
                        }}
                        value={selectedQuestionID}
                        onChange={(event) =>
                          setSelectedQuestionID(event.target.value)
                        }
                        MenuProps={{
                          sx: { width: "400px" },
                          disableScrollLock: true,
                        }}
                        inputProps={{
                          "aria-label": "Without label",
                          style: { borderColor: "transparent" },
                        }}
                        renderValue={
                          selectedQuestionID !== ""
                            ? undefined
                            : () => (
                                <Typography sx={{ fontSize: "16px" }}>
                                  Search Question
                                </Typography>
                              )
                        }
                        displayEmpty
                      >
                        {allQuestionList.map((question, index) => {
                          return (
                            <MenuItem
                              value={question["id"]}
                              key={index}
                              style={{
                                whiteSpace: "normal",
                                font: "normal normal 400 12px Poppins",
                              }}
                            >
                              {question["question"]}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <IconButton
                      disabled={selectedQuestionID === "" || isLeadFormDisabled}
                      color="primary"
                      onClick={() => {
                        if (selectedQuestionID !== "") {
                          handleSearch();
                        }
                      }}
                    >
                      <Search />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </TopWrapper>
            {formMeta["sections"].map((sectionData, index) => {
              if (activeStep === index) {
                return (
                  <SectionCard
                    isLeadFormDisabled={isLeadFormDisabled}
                    title={sectionData["title"]}
                    questions={sectionData["questions"]}
                    key={index}
                    inputData={inputData}
                    setInputData={setInputData}
                    prefilledData={prefilledData}
                    setPrefilledData={setPrefilledData}
                    listFieldInputMeta={listFieldInputMeta}
                    getFieldInputMeta={({ columnMeta, inputMeta }) =>
                      getFieldInputMeta({ columnMeta, inputMeta })
                    }
                  />
                );
              }
            })}
          </Wrapper>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              position: "relative",
            }}
          >
            {showAlert && (
              <ErrorAlert
                onClose={(e) => {
                  e.stopPropagation();
                  setShowAlert(false);
                }}
                severity="error"
                sx={{ width: "100%" }}
              >
                {errorMessage}
              </ErrorAlert>
            )}
            <RenderButton />
          </Box>
        </Container>
      ) : (
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <CircularProgress color="primary" />
        </Container>
      )}
      {openSubmitResponseDialog && (
        <MessageWithLoadingStateCustomDialog
          open={openSubmitResponseDialog}
          setOpen={setOpenSubmitResponseDialog}
          successMessage={successMessage}
          errorMessage={errorMessage}
          loading={submitting}
          isError={isSubmitFail}
        />
      )}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => {
          setShowSnackbar(false);
        }}
        message="Data Saved!"
      />
      {isSaving && <CustomLoadingDialog open={isSaving} />}
    </>
  );
};

export default observer(LeadFormComponent);
