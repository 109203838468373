import { Box, Checkbox, FormControlLabel } from "@mui/material";

import React from "react";

const CheckboxOptionWidget = ({
  list,
  type,
  handleChange,
  value,
  disabled,
}) => {
  if (type === "list") {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", rowGap: "8px" }}>
        {list.map((str, index) => {
          return (
            <FormControlLabel
              disabled={disabled}
              key={index}
              control={
                <Checkbox
                  checked={value.includes(str)}
                  onChange={(e) => {
                    let tempList = [...value];
                    if (e.target.checked) {
                      if (!tempList.includes(e.target.value)) {
                        tempList.push(e.target.value);
                      }
                    } else {
                      tempList = tempList.filter(
                        (item) => item !== e.target.value
                      );
                    }
                    handleChange(tempList);
                  }}
                />
              }
              value={str}
              label={str}
            />
          );
        })}
      </Box>
    );
  } else {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", rowGap: "8px" }}>
        {list.map((obj, index) => {
          return (
            <FormControlLabel
              disabled={disabled}
              key={index}
              control={
                <Checkbox
                  checked={value.includes(obj.value)}
                  onChange={(e) => {
                    let tempList = [...value];
                    if (e.target.checked) {
                      if (!tempList.includes(e.target.value)) {
                        tempList.push(e.target.value);
                      }
                    } else {
                      tempList = tempList.filter(
                        (item) => item !== e.target.value
                      );
                    }
                    handleChange(tempList);
                  }}
                />
              }
              value={obj.value}
              label={obj.name}
            />
          );
        })}
      </Box>
    );
  }
};

export default CheckboxOptionWidget;
