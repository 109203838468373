import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  makeStyles,
  Modal,
  Select,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import rootStore from "../../stores/RootStore";
import { getLocalizedText } from "../../Functions";
import { MenuItem, OutlinedInput, Typography } from "@mui/material";
import { myTheme } from "../../themeUtils";
import MuiAlert from "@mui/material/Alert";
import { updateMultipleLeadStageApi } from "../../Api";
import analytics from "../../Analytics";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  width: 420px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const Form = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 300px;
  justify-content: space-between;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;

const ButtonWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;
const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 16px;
  flex-direction: column;
  row-gap: 20px;
`;
const Message = styled(Typography)`
  font: normal normal normal 16px Open Sans;
  color: green;
  margin: 0px;
  text-align: center;
`;
const MessageSm = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const MultiLeadUpdateStageModal = (props) => {
  const classes = useStyles();
  const [details, setDetails] = useState({
    stage: "",
    comments: "",
  });
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [stageList, setStageList] = useState([]);

  const updateStage = async () => {
    let payload = {
      lead_stage_id: details.stage,
      lead_ids: props.selectedLeadIdsData,
    };
    if (details.comments !== "") {
      payload["comments"] = details.comments;
    }
    let response = await updateMultipleLeadStageApi({
      payload: payload,
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      props.setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();

    await updateStage();
  };
  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  const handleClose = () => {
    props.setOpen(false);
  };
  const init = () => {
    let sortedStageList = [...rootStore.authStore.stageList].sort(
      (a, b) => a.rank - b.rank
    );
    setStageList(sortedStageList);
  };

  useEffect(() => {
    analytics.triggerEvent(
      4625000256,
      "update_stage_form_load",
      "leads_page",
      "",
      { order: props.scope }
    );
    init();
  }, []);

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!isSubmitSuccess ? (
          <ModelContainer>
            <Box>
              <Container>
                <Header>Update Lead Stage</Header>
                <SubHeader>
                  {getLocalizedText("select_from_below_dropdown")}
                </SubHeader>
                <Divider style={{ margin: "16px 0px" }} />
                <Form component={"form"} onSubmit={handleUpdate}>
                  <Box
                    style={{
                      height: "100%",
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <InputWrapper>
                          <Label>Stage</Label>
                          <FormControl
                            sx={{ width: "100%" }}
                            classes={{
                              root: classes.quantityRoot,
                            }}
                          >
                            <Select
                              classes={{
                                icon: classes.icon,
                              }}
                              name="stage"
                              displayEmpty
                              variant="outlined"
                              required
                              value={details.stage}
                              onChange={handleChange}
                              style={{
                                width: "100%",
                                height: "30px",
                                fontSize: "12px",
                                fontWeight: "bold",
                                borderRadius: "0px",
                              }}
                            >
                              {stageList.map((e) => {
                                return (
                                  <MenuItem id={e} value={e.id}>
                                    {e.stage}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </InputWrapper>
                      </Grid>
                      <Grid item xs={12}>
                        <InputWrapper>
                          <Label>Comments</Label>
                          <OutlinedInput
                            multiline={true}
                            rows={3}
                            inputProps={{
                              min: 0,
                              "aria-label": "weight",
                              style: {
                                fontSize: "12px",
                              },
                            }}
                            value={details.comments}
                            name={"comments"}
                            type={"text"}
                            placeholder={"Enter Value"}
                            onChange={handleChange}
                          />
                        </InputWrapper>
                      </Grid>
                    </Grid>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <ButtonWrapper>
                        {isSubmitFail && (
                          <ErrorAlert
                            onClose={(e) => {
                              e.stopPropagation();
                              setIsSubmitFail(false);
                            }}
                            severity="error"
                            sx={{ width: "100%" }}
                          >
                            {errorMessage}
                          </ErrorAlert>
                        )}
                        <Button
                          onClick={() => {
                            analytics.triggerEvent(
                              4625000257,
                              "cancel_button_tap",
                              "update_stage_form",
                              "cancel_button",
                              { order: props.scope }
                            );
                            props.setOpen(false);
                          }}
                          variant="contained"
                          color="default"
                          style={{
                            fontSize: "12px",
                            marginRight: "10px",
                            textTransform: "none",
                          }}
                        >
                          {getLocalizedText("cancel")}
                        </Button>
                        <Button
                          onClick={() => {
                            analytics.triggerEvent(
                              4625000258,
                              "update_button_tap",
                              "update_stage_form",
                              "update_button",
                              { order: props.scope }
                            );
                          }}
                          type="submit"
                          variant="contained"
                          style={{
                            fontWeight: "bold",
                            width: "fit-content",
                            textTransform: "none",
                            ...myTheme.Button.btnBlue,
                          }}
                        >
                          Update
                        </Button>
                      </ButtonWrapper>
                    </Grid>
                  </Grid>
                </Form>
              </Container>
            </Box>
          </ModelContainer>
        ) : (
          <ModelContainer
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MessageWrapper style={{ marginBottom: "0px" }}>
              <Message>Stage updated successfully!</Message>

              <Button
                onClick={() => {
                  analytics.triggerEvent(
                    4625000259,
                    "close_button_tap",
                    "update_stage_form",
                    "close_button",
                    { order: props.scope }
                  );
                  handleClose();
                }}
                type="button"
                variant="contained"
                color="default"
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "100px",
                }}
              >
                {getLocalizedText("close")}
              </Button>
            </MessageWrapper>
          </ModelContainer>
        )}
      </Modal>
    </>
  );
};

export default MultiLeadUpdateStageModal;
