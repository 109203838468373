import { Drawer } from "@material-ui/core";
import { Map } from "@material-ui/icons";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { getDayCheckInsApi, getUserDayCheckInsApi } from "../../Api";
import { CheckInColumns, leadFileColumns } from "../../Db";
import ViewUserCheckinModal from "../check_in_modal/ViewUserCheckinModal";
const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  position: relative;
  /* padding: 20px; */
`;
const AddBtnWrapper = styled.div`
  position: absolute;
  right: 14px;
  top: 14px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
  @media (max-width: 700px) {
    position: unset;
    margin-left: 12px;
  }
`;
const CheckinTable = (props) => {
  const [row, setRow] = useState();
  const [currData, setCurrData] = useState(null);
  const [viewLog, setViewLog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [wayPoint, setWayPoint] = useState(null);
  const latLongGenerator = (value) => {
    let a = value;
    let array = a.split(",");
    let b = array[0];
    let c = array[1];
    return { start: b, end: c };
  };
  const wayPointGenerator = (val) => {
    let value = [...val];
    if (value.length > 2) {
      let tempList = value;
      let result = "https://www.google.com/maps/dir/?api=1";
      let start = tempList.pop();
      start = latLongGenerator(start.latlong);
      let dest = tempList.shift();
      dest = latLongGenerator(dest.latlong);
      tempList = tempList.reverse();
      result += `&origin=${start.start},${start.end}&destination=${dest.start},${dest.end}&waypoints=`;
      if (tempList.length > 0) {
        let str = "";
        tempList.map((item) => {
          let src = latLongGenerator(item.latlong);
          str += `${src.start},${src.end}|`;
        });
        result += str;
      }
      result = result.slice(0, -1);
      setWayPoint(result);
    } else if (value.length === 2) {
      let tempList = value;
      let result = "https://www.google.com/maps/dir/?api=1";
      let start = tempList.pop();
      start = latLongGenerator(start.latlong);
      let dest = tempList.shift();
      dest = latLongGenerator(dest.latlong);
      result += `&origin=${start.start},${start.end}&destination=${dest.start},${dest.end}`;
      setWayPoint(result);
    } else if (value.length === 1) {
      let tempList = value;
      let start = tempList.pop();
      start = latLongGenerator(start.latlong);
      let result = `https://www.google.com/maps/?q=${start.start},${start.end}`;
      setWayPoint(result);
    }
  };
  useEffect(() => {
    setWayPoint(null);
    setRow([]);
    setLoading(true);
    const getCheckinHistory = async () => {
      try {
        let response = await getDayCheckInsApi(props.checkinDate);
        setRow(response.data);
        wayPointGenerator(response.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    const getUserCheckinHistory = async () => {
      try {
        let response = await getUserDayCheckInsApi(
          props.checkinDate,
          props.pid,
          props.id
        );
        setRow(response.data);
        wayPointGenerator(response.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    if (props.id) {
      getUserCheckinHistory();
    } else {
      getCheckinHistory();
    }
  }, [props.checkinDate]);
  const handleWayPoint = () => {
    window.open(wayPoint, "_blank");
  };
  return (
    <>
      <Container>
        <AddBtnWrapper>
          <Map
            color={wayPoint !== null ? "primary" : "disabled"}
            onClick={wayPoint !== null ? handleWayPoint : null}
            style={{
              width: "28px",
              height: "28px",
            }}
          />
        </AddBtnWrapper>
        {row && (
          <DataGrid
            // autoHeight={true}
            rows={row}
            columns={CheckInColumns}
            //   pageSize={size}
            checkboxSelection={false}
            style={{ cursor: "pointer" }}
            hideFooter={true}
            loading={loading}
            disableColumnMenu={true}
            disableSelectionOnClick={true}
            onRowClick={(params) => {
              setCurrData(params.row);
              setViewLog(true);
            }}
          />
        )}
      </Container>
      <Drawer
        anchor={"right"}
        open={viewLog}
        onClose={() => {
          setViewLog(false);
        }}
      >
        {<ViewUserCheckinModal data={currData} setData={setCurrData} />}
      </Drawer>
    </>
  );
};

export default CheckinTable;
