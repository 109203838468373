import { Box, FormControl, MenuItem, Select } from "@mui/material";
import React from "react";

const DropdownWidget = ({
  onChange,
  value,
  options,
  fontSize = "12px",
  height = "30px",
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <FormControl sx={{ width: "100%" }}>
        <Select
          displayEmpty
          required
          onChange={(event) => onChange(event.target.value)}
          disableUnderline
          variant="outlined"
          value={value}
          style={{
            width: "100%",
            height: height,
            fontSize: fontSize,
            borderRadius: "0px",
          }}
        >
          {options.map((item, i) => {
            return (
              <MenuItem value={item} key={i} style={{ fontSize: fontSize }}>
                {item}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default DropdownWidget;
